import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import Page from "../../global/structure/Page";

import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import { useSnackbar } from "notistack";

const initialState = {
  transferGroup: {},
  options: {
    loaded: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TRANSFER_GROUP":
      return { ...state, transferGroup: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    default:
      throw new Error();
  }
}

export default function TransferPaymentPage() {
  const { id } = useParams();
  const { api, user } = useContext(AppContext);

  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  const csvColumns = [
    { label: t("title"), key: "providerTitle" },
    { label: t("invoiceNumber"), key: "providerNumber" },
    { label: t("businessName"), key: "creditorName" },
    { label: t("creditorIBAN"), key: "creditorIBAN" },
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    {
      label: t("accountingAccount"),
      key: "ProviderInvoice.Provider.accountingAccount",
    },
  ];

  const transferColumns = [
    { key: "concept", label: t("concept"), sortType: "string" },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => value.toFixed(2) + "€",
    },
    { key: "signatureDate", label: t("signatureDate"), sortType: "string" },
    { key: "creditorIBAN", label: t("creditorIBAN"), sortType: "string" },
    { key: "creditorName", label: t("businessName"), sortType: "string" },
    {
      key: "ProviderInvoice.title",
      label: t("providerInvoice"),
      sortType: "string",
      renderFunction: (value, item) =>
        value ? (
          <ButtonLink
            to={"/app/provider-invoice/" + item.providerInvoiceId}
            size="small"
          >
            {value || t("invoice")}
          </ButtonLink>
        ) : (
          t("deleted")
        ),
    },
    {
      key: "ProviderInvoice.invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "string",
      renderFunction: (value) => (value !== null ? value : t("deleted")),
    },
    {
      key: "ProviderInvoice.Provider.accountingAccount",
      label: t("accountingAccount"),
      sortType: "string",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    getTransferPaymentGroup();
  }, []);

  const getTransferPaymentGroup = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    let params = {
      include: ["Provider", "ProviderInvoice", "TransferPayment", "User"],
    };
    api
      .get("/transfer-payment-group/" + id, { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        response.data.TransferPayments.forEach((transfer) => {
          transfer.providerTitle = transfer.ProviderInvoice?.title;
          transfer.providerNumber = transfer.ProviderInvoice?.invoiceNumber;
        });
        dispatch({ type: "SET_TRANSFER_GROUP", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const downloadXml = () => {
    api
      .get(`/transfer-payment-groups/${state.transferGroup.id}/xml`)
      .then((response) => {
        if (typeof response.data === "object") {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        //Valid XML
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/xml",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = fileURL.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  return (
    <Page browserTitle={t("transferPayments")} paper={true}>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item flexGrow={1}>
            <Typography variant="h4">{t("transferPaymentGroup")}</Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ExportButton
              data={exportDataParse(
                state.transferGroup?.TransferPayments || [],
                csvColumns
              )}
              display={user.hasAction("EXPORT_DATA")}
              options={[{ name: "XML", onClick: downloadXml }]}
              type="icon"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("date")}:
          </Typography>
          <Typography variant="body"> {state.transferGroup.date}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("createdBy")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {state.transferGroup.User?.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{t("debtor")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("name")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {state.transferGroup.debtorName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("nif")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {state.transferGroup.debtorNIF}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("IBAN")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {state.transferGroup.debtorIBAN}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("BIC")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {state.transferGroup.debtorBIC}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{t("transferPayments")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("totalAmount")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {localeFormat(
              state.transferGroup.TransferPayments?.reduce(
                (total, transfer) => total + transfer.amount,
                0
              )
            )}
            €
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("totalTransfers")}:
          </Typography>
          <Typography variant="body">
            {" "}
            {state.transferGroup.TransferPayments?.length}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={transferColumns}
            data={state.transferGroup.TransferPayments || []}
            options={state.options}
          ></CustomTable>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            {t("back")}
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
}
