import { Link, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Chip,
  FormControlLabel,
  Switch,
} from "@mui/material";

// Icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import StoreIcon from "@mui/icons-material/Store";
import PersonIcon from "@mui/icons-material/Person";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";

// Components & Utils
import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";
import CustomSelect from "../../Inputs/CustomSelect";
import PhotoInput from "../../Inputs/PhotoInput";
import TextInput from "../../Inputs/TextInput";
import { SERVER } from "../../../utils/API";
import PasswordInput from "../../Inputs/PasswordInput";
import ButtonLink from "../../Inputs/ButtonLink";
import ConfirmDialog from "../../ConfirmDialog";

import { CUSTOMER_ROLE_ID } from "../../../data/constants";

const initialState = {
  apiKeyVisible: false,
  centers: [],
  form: {
    apiKey: "",
    center: "",
    centers: [],
    customer: "",
    customers: [],
    name: "",
    newPassword: "",
    password: "",
    repeatPassword: "",
    roleId: "",
    mfaRequired: false,
    mfaActive: false,
    imageURL: "",
  },
  inputError: {
    name: false,
    newPassword: false,
    password: false,
    repeatPassword: false,
    roleId: false,
  },
  user: {
    centers: [],
    customers: [],
  },
  setPasswordDialog: {
    isOpen: false,
    showPassword: false,
  },
  confirmDialog: {
    open: false,
  },
  source: "/img/default.jpg",
  roles: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_PASSWORD_INPUTS":
      return {
        ...state,
        form: {
          ...state.form,
          newPassword: "",
          repeatPassword: "",
        },
      };
    case "SET_USER":
      return { ...state, form: action.payload, user: action.payload };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "ADD_CENTER":
      return {
        ...state,
        form: {
          ...state.form,
          centers: [...state.form.centers, state.form.center],
          center: "",
        },
      };
    case "ADD_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          customers: [...state.form.customers, state.form.customer],
          customer: null,
        },
      };
    case "REMOVE_CENTER":
      return {
        ...state,
        form: {
          ...state.form,
          centers: state.form.centers.filter((item) => item !== action.payload),
        },
      };
    case "REMOVE_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          customers: state.form.customers.filter(
            (item) => item !== action.payload
          ),
        },
      };
    case "SET_CONFIRM_DIALOG":
      return { ...state, confirmDialog: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: { ...state.inputError, [action.payload]: true },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: { ...state.inputError, [action.payload]: false },
      };
    case "SET_SOURCE":
      return { ...state, source: action.payload };
    case "OPEN_SETPASSWORD_DIALOG":
      return {
        ...state,
        setPasswordDialog: { ...state.setPasswordDialog, isOpen: true },
      };
    case "CLOSE_SETPASSWORD_DIALOG":
      return {
        ...state,
        setPasswordDialog: { ...state.setPasswordDialog, isOpen: false },
      };
    case "VIEW_SETPASSWORD":
      return {
        ...state,
        setPasswordDialog: {
          ...state.setPasswordDialog,
          showPassword: !state.setPasswordDialog.showPassword,
        },
      };
    case "TOGGLE_API_KEY_VISIBILITY":
      return {
        ...state,
        apiKeyVisible: !state.apiKeyVisible,
      };
    default:
      throw new Error();
  }
}

export default function UserPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams();
  const [t] = useTranslation("users");
  const [tErrors] = useTranslation("errors");

  //Initial useEffect
  useEffect(() => {
    document.title = t("userPage");

    getUser();
    getRoles();
    getCenters();
    getImage();
  }, []);

  /* BACKEND CALLS */

  const getUser = () => {
    let params = {
      include: ["ApiKey", "Center", "Customer", "Role", "Action", "UserMFA"],
    };
    api
      .get("/users/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.ApiKey?.key
            ? (response.data.apiKey = response.data.ApiKey.key)
            : (response.data.apiKey = "");
          response.data.Centers
            ? (response.data.centers = response.data.Centers.map(
                (center) => center.id
              ))
            : (response.data.centers = []);
          response.data.Customers.length !== 0
            ? (response.data.customers = response.data.Customers)
            : (response.data.customers = []);
          if (response.data.UserMFA) {
            response.data.mfaRequired = response.data.UserMFA.isRequired;
            response.data.mfaActive = response.data.UserMFA.isActive;
            delete response.data.UserMFA;
          }
          dispatch({ type: "SET_USER", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getImage = () => {
    api
      .get("/users/" + id + "/profile-image", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        const fileURL = URL.createObjectURL(response.data);
        dispatch({
          type: "SET_INPUT",
          payload: { inputname: "imageURL", value: fileURL },
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getRoles = () => {
    api
      .get("/roles")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ROLES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};

      state.form.name !== "" && (data.name = state.form.name);
      state.form.roleId !== "" && (data.roleId = state.form.roleId);
      data.active = state.form.active;

      api
        .post("/users/edit/" + id, data)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("userEditSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const addCenter = () => {
    let found = false;
    if (state.form.center) {
      state.form.centers.forEach((center) => {
        if (center === state.form.center) {
          found = true;
        }
      });
      if (!found) {
        api
          .post("/users/" + id + "/centers/add", { id: state.form.center })
          .then((response) => {
            if (response.data.error) {
              console.log(response.data.error);
              enqueueSnackbar(response.data.error, { variant: "error" });
            } else {
              enqueueSnackbar(response.data.msg, { variant: "success" });
              dispatch({ type: "ADD_CENTER" });
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(error.toString(), { variant: "error" });
          });
      } else
        enqueueSnackbar(t("repeatedCenterWarning"), { variant: "warning" });
    } else enqueueSnackbar(t("invalidCenter"), { variant: "error" });
  };

  const deleteCenter = (center) => {
    api
      .delete("/users/" + id + "/centers/" + center)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "REMOVE_CENTER", payload: center });
          enqueueSnackbar(response.data.msg, { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const savePhoto = (photo) => {
    let formData = new FormData();

    let file = new File(photo, "profilePhoto");

    formData.append("profilePhoto", file);

    api
      .post("/users/" + id + "/profile-image", formData)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: { inputname: "hasFile", value: true },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deletePhoto = () => {
    api
      .delete("/users/" + id + "/profile-image")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: { inputname: "hasFile", value: false },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetPassword = () => {
    //validate passwords
    if (state.form.newPassword === state.form.repeatPassword) {
      //Set password
      api
        .post(`/users/${id}/set-password`, {
          newPassword: state.form.newPassword,
        })
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("passwordSetCorrectly"), { variant: "success" });
            //Close dialog and reset inputs
            closeSetPasswordDialog();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("passwordsMustBeEquals"), { variant: "error" });
    }
  };

  const createApiKey = () => {
    api
      .post(`/users/${id}/create-api-key`)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        } else {
          handleInputChange({
            target: {
              name: "apiKey",
              value: response.data.key,
            },
          });
          enqueueSnackbar(t("apiKeyCreated"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteApiKey = () => {
    api
      .delete(`/users/${id}/delete-api-key`)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        } else {
          handleInputChange({
            target: {
              name: "apiKey",
              value: "",
            },
          });
          enqueueSnackbar(t("apiKeyDeleted"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const manageMFA = ({ requireMFA, restoreMFA }) => {
    const data = {
      requireMFA,
      restoreMFA,
    };
    api
      .post(`/users/${id}/manage-mfa`, data)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          enqueueSnackbar(t("mfaUpdated"), { variant: "success" });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const restoreMFA = () =>
    manageMFA({ requireMFA: state.form.mfaRequired, restoreMFA: true });
  const requireMFA = (requireMFA) =>
    manageMFA({ requireMFA, restoreMFA: false });

  /* HANDLERS */

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({ type: "SET_INPUT_ERROR_TRUE", payload: name });
  };

  const setInputErrorFalse = (name) => {
    dispatch({ type: "SET_INPUT_ERROR_FALSE", payload: name });
  };

  const showSetPasswordDialog = () => {
    dispatch({ type: "OPEN_SETPASSWORD_DIALOG" });
  };

  const closeSetPasswordDialog = () => {
    dispatch({ type: "CLOSE_SETPASSWORD_DIALOG" });
    resetPasswordInputs();
  };

  const resetPasswordInputs = () => {
    dispatch({ type: "RESET_PASSWORD_INPUTS" });
  };

  const handleApiKeyVisibilityChange = () => {
    dispatch({ type: "TOGGLE_API_KEY_VISIBILITY" });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: { ...state.confirmDialog, open: state },
    });
  };

  const openConfirmRestoreMFA = () => setConfirmDialogState(true);

  /* VALIDATORS */

  const validateForm = () => {
    let isValid = true;

    let fields = ["name", "roleId"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(field + " is required.", { variant: "error" });
        isValid = false;
      }
    });

    if (Number(state.form.roleId) === 0) {
      setInputErrorTrue("roleId");
      enqueueSnackbar("Select a valid role.", { variant: "error" });
      isValid = false;
    }

    if (state.form.newPassword && state.form.newPassword !== "") {
      if (state.form.newPassword !== state.form.repeatPassword) {
        setInputErrorTrue("repeatPassword");
        enqueueSnackbar("Passwords must match.", { variant: "error" });
        isValid = false;
      }

      if (
        state.form.newPassword.length < 8 ||
        state.form.newPassword.length > 32
      ) {
        setInputErrorTrue("newPassword");
        enqueueSnackbar("Password must be between 8 and 32 characters.", {
          variant: "error",
        });
        isValid = false;
      }
    }

    return isValid;
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("editUser")}</Typography>
          </Grid>

          <Grid item container xs={12} md={4} columnSpacing={3}>
            <PhotoInput
              id={id}
              savePhoto={savePhoto}
              deletePhoto={deletePhoto}
              hasPhoto={state.form.hasFile}
              source={state.form.imageURL}
            />
          </Grid>
          <Grid item container md={8} spacing={2}>
            <Grid item xs={12} md={6}>
              {user.hasAction("EDIT_USERS") ? (
                <TextInput
                  error={state.inputError.name}
                  helperText={
                    state.inputError.name ? t("nameMustNotBeBlank") : ""
                  }
                  label={t("name")}
                  value={state.form.name}
                  onChange={handleInputChange}
                  name="name"
                />
              ) : (
                <Typography variant="body1">
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    component="span"
                  >
                    {t("name")}:{" "}
                  </Typography>
                  {state.form.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {user.hasAction("EDIT_USERS") &&
              state.form.roleId !== CUSTOMER_ROLE_ID ? (
                <CustomSelect
                  error={state.inputError.roleId}
                  helperText={
                    state.inputError.roleId ? t("roleMustNotBeBlank") : ""
                  }
                  value={state.form.roleId}
                  name="roleId"
                  onChange={handleInputChange}
                  label={t("role")}
                  options={state.roles
                    .map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                />
              ) : (
                <Typography
                  variant="body1"
                  // component={Link}
                  // to={"/app/role/" + state.form.roleId}
                >
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    component="span"
                  >
                    {t("role")}:{" "}
                  </Typography>
                  {state.form.Role?.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {user.hasAction("EDIT_USERS") ? (
                <FormControlLabel
                  label={state.form.active ? t("active") : t("inactive")}
                  control={
                    <Switch
                      checked={!!state.form.active}
                      onChange={(e) => {
                        handleInputChange({
                          target: { name: "active", value: e.target.checked },
                        });
                      }}
                    />
                  }
                />
              ) : (
                <Typography variant="body1">
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    component="span"
                  >
                    {t("state")}:{" "}
                  </Typography>
                  {state.form.active ? (
                    <Chip label={t("active")} color="success" size="small" />
                  ) : (
                    <Chip label={t("inactive")} color="error" size="small" />
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {user.hasAction("MANAGE_MFA") &&
                state.user.roleId !== CUSTOMER_ROLE_ID && (
                  <FormControlLabel
                    label={t("mfaRequired")}
                    control={
                      <Switch
                        checked={!!state.form.mfaRequired}
                        onChange={(e) => {
                          handleInputChange({
                            target: {
                              name: "mfaRequired",
                              value: e.target.checked,
                            },
                          });
                          requireMFA(e.target.checked);
                        }}
                      />
                    }
                  />
                )}
            </Grid>
            {user.hasAction("VIEW_USER_API_KEY") &&
              (state.form.apiKey === "" ? (
                user.hasAction("MANAGE_USER_API_KEY") && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={createApiKey}
                    >
                      {t("createApiKey")}
                    </Button>
                  </Grid>
                )
              ) : (
                <Grid container item xs={12} alignItems="center">
                  <Grid item>
                    <Typography variant="body1" fontWeight="medium">
                      {t("apiKey")}:
                      <Typography
                        variant="body1"
                        component="span"
                        marginLeft={1}
                      >
                        {state.apiKeyVisible
                          ? state.form.apiKey
                          : "********-****-****-****-************"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Tooltip
                      title={state.apiKeyVisible ? t("hide") : t("show")}
                      placement="top"
                    >
                      <IconButton
                        name="visibility"
                        onClick={handleApiKeyVisibilityChange}
                      >
                        {state.apiKeyVisible ? (
                          <VisibilityOffIcon fontSize="small" />
                        ) : (
                          <VisibilityIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={t("copyApiKey")} placement="top">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(state.form.apiKey);
                          enqueueSnackbar(t("apiKeyCopied"), {
                            variant: "success",
                          });
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    {user.hasAction("MANAGE_USER_API_KEY") && (
                      <>
                        <Tooltip title={t("regenerateApiKey")} placement="top">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={createApiKey}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={t("deleteApiKey")}
                          placement="top"
                          onClick={deleteApiKey}
                        >
                          <IconButton color="error" size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                </Grid>
              ))}
            {user.hasAction("MANAGE_MFA") && state.form.mfaActive && (
              <Grid item xs={12} sm="auto">
                <Button variant="contained" onClick={openConfirmRestoreMFA}>
                  {t("restoreMFA")}
                </Button>
              </Grid>
            )}
            {user.hasAction("EDIT_USERS") && (
              <Grid item xs={12} sm="auto">
                <Button variant="contained" onClick={showSetPasswordDialog}>
                  {t("setPassword")}
                </Button>
              </Grid>
            )}
          </Grid>

          {state.form.verifiedAt && (
            <Grid item>
              <Typography variant="body1">
                {t("verifiedAt")}: {state.form.verifiedAt}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Centers */}
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <StoreIcon />
                {t("centers")}
              </Typography>
            </Grid>
            {user.hasAction("EDIT_USERS") &&
              state.form.roleId !== CUSTOMER_ROLE_ID && (
                <Grid item container spacing={2}>
                  <Grid item xs={10}>
                    <CenterSelect
                      value={state.form.center}
                      onChange={handleInputChange}
                      name="center"
                    />
                  </Grid>
                  <Grid item>
                    <Button startIcon={<AddIcon />} onClick={addCenter}>
                      {t("addCenter")}
                    </Button>
                  </Grid>
                </Grid>
              )}
          </Grid>

          {user.hasAction("EDIT_USERS") && (
            <Grid item xs={12}>
              {state.form.centers.map((id) => {
                let center = state.centers.filter((item) => item.id === id)[0];

                return (
                  <List key={id}>
                    <Card variant="outlined" xs={6}>
                      <CardContent>
                        <Grid item container xs={12}>
                          <Grid item>
                            <Typography variant="body">
                              {center?.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <IconButton onClick={() => deleteCenter(id)}>
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </List>
                );
              })}
              {state.form.centers.length == 0 && t("noCenters")}
            </Grid>
          )}

          {/* Customer */}
          {state.user?.customers?.length === 1 && (
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <PersonIcon />
                  {t("customer")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {user.hasPage("CUSTOMERS") ? (
                  <ButtonLink
                    to={"/app/customer/" + state.user?.customers[0]?.id}
                  >
                    {state.user?.customers[0]?.fullName}
                  </ButtonLink>
                ) : (
                  <Typography variant="body1" fontWeight="bold">
                    {t("name") + ": "}
                    <Typography variant="body1" component="span">
                      {state.user?.customers[0]?.fullName}
                    </Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item container spacing={1} xs={12} justifyContent="flex-end">
            <Grid item>
              <Button variant="text" onClick={() => history.goBack()}>
                {t("back")}
              </Button>
            </Grid>
            {user.hasAction("EDIT_USERS") && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      {/* Set password dialog */}
      <Dialog
        open={state.setPasswordDialog.isOpen}
        onClose={closeSetPasswordDialog}
      >
        <DialogTitle>
          <Grid item container justifyContent="space-between">
            <Grid item>{t("setPassword")}</Grid>
            <Grid item>
              <IconButton onClick={closeSetPasswordDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                text={t("newPassword")}
                name="newPassword"
                value={state.form.newPassword}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                text={t("repeatPassword")}
                name="repeatPassword"
                value={state.form.repeatPassword}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={resetPassword}>
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Restore MFA Confirm Dialog */}
      <ConfirmDialog
        open={state.confirmDialog.open}
        setOpen={setConfirmDialogState}
        onConfirm={(confirmed) => {
          confirmed && restoreMFA();
        }}
        title={t("restoreMFA")}
      >
        <Typography variant="body1">{t("restoreMFAConfirm")}</Typography>
      </ConfirmDialog>
    </Container>
  );
}
