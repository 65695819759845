import { useEffect } from "react";

import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import Footer from "./Footer.js";
import Content from "./Content.js";

import theme from "../../../../theme/themeDark";

const Page = ({ children }) => {
  document.body.classList.add("black");
  document.body.classList.remove("default");

  useEffect(() => {
    // Change Title
    document.title = "Necesito Un Trastero";

    // Change favicon
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/favicon_nut.ico";
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth={false} sx={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
        <Content>{children}</Content>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default Page;
