import { Stack } from "@mui/material";
import { useContext, useRef } from "react";
import PropTypes from "prop-types";

import { FloorPlanContext } from "../../../FloorPlanPage";
import ActionButtons from "./ActionButtons";
import FormTitle from "./FormTitle";
import GeneralCollapsible from "../ItemCollapsibles/GeneralCollapsible";
import ImageCollapsible from "../ItemCollapsibles/ImageCollapsible";
import PositionCollapsible from "../ItemCollapsibles/PositionCollapsible";
import SizeCollapsible from "../ItemCollapsibles/SizeCollapsible";

const ImageForm = (props) => {
  const { item } = props;

  const { selectTile, editTile, setEditItem } = useContext(FloorPlanContext);

  const setName = (name) => setEditItem({ ...item, name });
  const setX = (x) => setEditItem({ ...item, x });
  const setY = (y) => setEditItem({ ...item, y });
  const setWidth = (width) => setEditItem({ ...item, width });
  const setHeight = (height) => setEditItem({ ...item, height });
  const setImage = (image) => setEditItem({ ...item, image });
  const imageRef = useRef(null);

  const imageURL =
    typeof item.image === "object" ? URL.createObjectURL(item.image) : item.image;

  const onEdit = async () => {
    await editTile({
      ...item,
      id: item.id,
      name: item.name,
      x: item.x,
      y: item.y,
      width: item.image === item.image ? item.width : imageRef.current?.naturalWidth,
      height: item.image === item.image ? item.height : imageRef.current?.naturalHeight,
      image: item.image === item.image ? item.image : new File([item.image], item.image.name),
    });
    selectTile(null);
  };

  const imageSizes = getImageSizes(item.image, item, item.width, item.height, imageRef);
  return (
    <Stack spacing={2}>
      <FormTitle shape={item.shape} name={item.name} />
      <GeneralCollapsible name={item.name} setName={setName} />
      <PositionCollapsible x={item.x} setX={setX} y={item.y} setY={setY} />
      <SizeCollapsible
        height={item.height}
        setHeight={setHeight}
        width={item.width}
        setWidth={setWidth}
      />
      <ImageCollapsible
        item={item}
        imageURL={imageURL}
        imageRef={imageRef}
        setImage={setImage}
        sizes={imageSizes}
      />
      <ActionButtons onCancel={() => selectTile(null)} onSave={onEdit} />
    </Stack>
  );
};

const getImageSizes = (image, item, width, height, imageRef) => {
  const selectedImageWidh =
    image === item.image ? width : imageRef.current?.naturalWidth;
  const selectedImageHeight =
    image === item.image ? height : imageRef.current?.naturalHeight;

  const imageWidth =
    selectedImageWidh < selectedImageHeight
      ? 100
      : 100 * (selectedImageWidh / selectedImageHeight);
  const imageHeight =
    selectedImageWidh > selectedImageHeight
      ? 100
      : 100 * (selectedImageHeight / selectedImageWidh);
  return { width: imageWidth, height: imageHeight };
};

ImageForm.propTypes = {
  item: PropTypes.object,
};

export default ImageForm;
