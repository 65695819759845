import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import AppContext from "../../../../../context/AppContext";

import Page from "../../../../global/structure/Page";
import Filters from "../../../../global/structure/Filters";
import Select from "../../../../global/inputs/Select";

import { CustomTable } from "../../../../CustomTable";
import SearchButton from "../../../../Inputs/SearchButton";

import { HistoricalInvoicingNonPayments } from "../../Charts/NonPaymentsChart";
import { HistoricalReturnedReceipts } from "../../Charts/NonPaymentsChart";
import { HistoricalInvoicingSankey } from "../../Charts/NonPaymentsChart";

const initialState = {
  bankingTransactions: [],
  billingErrors: [],
  neverUnpaidItems: [],
  nonPaidItems: [],
  recuperatedItems: [],
};

const thisYear = new Date().getFullYear();
const yearsArray = Array.from(
  { length: new Date().getFullYear() - 2015 },
  (_, index) => 2016 + index
);

function GeneralTab() {
  const [t] = useTranslation("dashboard");
  const { api } = useContext(AppContext);
  const [items, setItems] = useState(initialState);
  const [bankingTransactions, setBankingTransactions] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [year, setYear] = useState(thisYear);

  const handleSearch = async () => {
    setLoaded(false);
    getBankingTransactions();
    getInvoices();
  };

  const getInvoices = async () => {
    const params = {
      dateFrom: `${year}-01-01`,
      dateUntil: `${year + 1}-01-01`,
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "id",
            "number",
            "items",
            "billingError",
            "uncollectible",
          ],
        },
        {
          model: "NonPayment",
          attributes: ["id", "recuperationDate"],
        },
        {
          model: "Merchantable",
          attributes: ["id", "merchantableTypeId"],
        },
        {
          model: "InvoiceItem",
          attributes: ["id", "units", "pricePerUnit", "concept"],
        },
      ],
      include: ["NonPayment", "Merchantable"],
    };

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          const recuperatedItems = [];
          const neverUnpaidItems = [];
          const nonPaidItems = [];
          const billingErrors = [];
          response.data.forEach((invoice) => {
            if (invoice.billingError)
              invoice.items.forEach((item) => {
                billingErrors.push(getItemValues(item, invoice));
              });
            else if (invoice.NonPayments.length > 0) {
              if (
                invoice.NonPayments.some((np) => np.recuperationDate === null)
              )
                invoice.items.forEach((item) => {
                  nonPaidItems.push(getItemValues(item, invoice));
                });
              else
                invoice.items.forEach((item) => {
                  recuperatedItems.push(getItemValues(item, invoice));
                });
            } else
              invoice.items.forEach((item) => {
                neverUnpaidItems.push(getItemValues(item, invoice));
              });
          });
          setItems({
            ...items,
            recuperatedItems: recuperatedItems,
            neverUnpaidItems: neverUnpaidItems,
            nonPaidItems: nonPaidItems,
            billingErrors: billingErrors,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        console.log(error);
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const getBankingTransactions = async () => {
    const params = {
      dateFrom: `${year}-01-01`,
      dateUntil: `${year + 1}-01-01`,
      concept: "GASTOS IMPAG.DÉBITOS",
    };
    api
      .get("/banking-transactions", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else setBankingTransactions(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        console.log(error);
      });
  };

  const COLUMNS = [
    {
      key: "Invoice.number",
      label: t("invoiceNumber"),
      sortType: "number",
    },
    {
      key: "date",
      label: t("date"),
      sortType: "string",
    },
    {
      key: "concept",
      label: t("concept"),
      sortType: "string",
    },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
    },
    {
      key: "billingError",
      label: t("billingError"),
      sortType: "other",
      renderFunction: (value) => (value ? t("yes") : t("no")),
    },
    {
      key: "Invoice.NonPayments",
      label: t("paid"),
      sortType: "other",
      renderFunction: (value, item) => {
        return value.some((np) => np.recuperationDate === null)
          ? t("no")
          : t("yes");
      },
    },
    {
      key: "Invoice",
      label: t("recuperable"),
      sortType: "other",
      renderFunction: (value, item) => {
        if (!value.NonPayments.some((np) => np.recuperationDate === null))
          return "";
        if (item.Invoice.uncollectible || item.billingError)
          return t("uncollectible");
        return t("collectible");
      },
    },
  ];

  const noData =
    !items.bankingTransactions.length &&
    !items.billingErrors.length &&
    !items.neverUnpaidItems.length &&
    !items.recuperatedItems.length;

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filters
            filters={[
              <Select
                label={t("year")}
                value={year}
                onChange={(e) => setYear(e.target.value)}
                options={yearsArray.map((y) => ({ value: y, label: y }))}
                disabled={!loaded}
              />,
              <SearchButton loading={!loaded} onClick={handleSearch} />,
            ]}
          />
        </Grid>
        {loaded ? (
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <HistoricalInvoicingNonPayments
                recuperatedItems={items.recuperatedItems}
                neverUnpaidItems={items.neverUnpaidItems}
                nonPaidItems={[...items.nonPaidItems, ...items.billingErrors]}
              />
            </Grid>
            <Grid item xs={6}>
              <HistoricalReturnedReceipts
                bankingTransactions={bankingTransactions}
                items={[
                  ...items.billingErrors,
                  ...items.neverUnpaidItems,
                  ...items.nonPaidItems,
                  ...items.recuperatedItems,
                ]}
              />
            </Grid>
            <Grid item xs={12} marginTop={1} marginBottom={1}>
              {noData ? (
                t("noData")
              ) : (
                <HistoricalInvoicingSankey invoiceItemsObject={items} />
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomTable
                columns={COLUMNS}
                data={[
                  ...items.billingErrors,
                  ...items.nonPaidItems,
                  ...items.recuperatedItems,
                  ...items.neverUnpaidItems,
                ].sort((a, b) => a.Invoice.number - b.Invoice.number)}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={"100%"}
            width={"100%"}
            minHeight="90vh"
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Page>
  );
}

const getItemValues = (item, invoice) => {
  return {
    ...item,
    invoiceNumber: invoice.number,
    amount: Number((item.units * item.pricePerUnit).toFixed(2)),
    date: invoice.issueDate,
    billingError: invoice.billingError,
    Invoice: invoice,
  };
};

export default GeneralTab;
