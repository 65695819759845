import AppContext from "../../../../context/AppContext";
import React, { useState, useContext, useEffect } from "react";

import { Button, ButtonGroup, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { getParams, generateURL } from "../../../../utils/url";

import ExportButton, {
  exportDataParse,
} from "../../../global/inputs/ExportButton";
import Page from "../../../global/structure/Page";
import Filters from "../../../global/structure/Filters";
import Select from "../../../global/inputs/Select";

import CustomDate from "../../../Inputs/CustomDate";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";
import ItemsSummary from "../../../ItemsSummary";

import { CustomTable } from "../../../CustomTable";
import { localeFormat } from "../../../../utils/format";

import {
  ActionsCell,
  TransferPaymentsContext,
  useQuery,
} from "../TransferPaymentsPage";

const filtersQuery = [
  { name: "fromDate", type: "date" },
  { name: "untilDate", type: "date" },
  { name: "importMin", type: "number" },
  { name: "importMax", type: "number" },
  { name: "debtorIBAN", type: "string" },
];

export const initialFilters = {
  fromDate: "",
  untilDate: "",
  importMin: "",
  importMax: "",
  debtorIBAN: "",
};

const initialLoaded = true;
const initialTransferGroups = [];

export default function TransferGroupTab() {
  const { api, user } = useContext(AppContext);
  const { transferAccounts } = useContext(TransferPaymentsContext);

  const history = useHistory();
  const query = useQuery();

  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");

  const [filters, setFilters] = useState(initialFilters);
  const [loaded, setLoaded] = useState(initialLoaded);
  const [transferGroups, setTransferGroups] = useState(initialTransferGroups);

  useEffect(() => {
    const params = getParams(query, filtersQuery);
    setFilters({ ...filters, ...params });
  }, []);

  const GROUP_SUMMARY = [
    {
      translatedText: t("numberOfReceipts"),
      value: localeFormat(transferGroups.length),
    },
    {
      translatedText: t("numberOfTransfers"),
      value: localeFormat(
        transferGroups?.length > 0
          ? transferGroups.reduce(
              (total, element) => total + element.numberOfTransfers,
              0
            )
          : 0
      ),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          transferGroups?.length > 0
            ? transferGroups.reduce(
                (total, element) => total + element.amount,
                0
              )
            : 0
        ) + "€",
    },
  ];

  const GROUPS_COLUMNS = [
    { key: "date", label: t("date"), sortType: "string" },
    { key: "debtorName", label: t("debtorName"), sortType: "string" },
    { key: "debtorNIF", label: t("debtorNIF"), sortType: "string" },
    { key: "debtorIBAN", label: t("debtorIBAN"), sortType: "string" },
    {
      key: "numberOfTransfers",
      label: t("numberOfTransfers"),
      sortType: "number",
    },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => <ActionsCell item={item} />,
    },
  ];

  const GROUPS_CSV = [
    { key: "date", label: t("date") },
    { key: "debtorName", label: t("debtorName") },
    { key: "debtorNIF", label: t("debtorNIF") },
    { key: "debtorIBAN", label: t("debtorIBAN") },
    { key: "numberOfTransfers", label: t("numberOfTransfers") },
    { key: "amount", label: t("amount") },
  ];

  const handleFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const getTransferPaymentGroups = () => {
    setLoaded(false);
    updateURL();

    let params = {
      include: ["Provider", "ProviderInvoice", "TransferPayment"],
    };

    filters.debtorIBAN != "" && (params.debtorIBAN = filters.debtorIBAN);
    filters.fromDate != "" && (params.dateFrom = filters.fromDate);
    filters.untilDate != "" && (params.dateUntil = filters.untilDate);
    filters.importMax != "" && (params.importMax = filters.importMax);
    filters.importMin != "" && (params.importMin = filters.importMin);

    api
      .get("/transfer-payment-groups", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noResults"), { variant: "warning" });
          response.data.forEach((transferGroup) => {
            transferGroup.numberOfTransfers =
              transferGroup.TransferPayments?.length;
            transferGroup.amount = Number(transferGroup?.amount?.toFixed(2));
          });
          setTransferGroups(response.data);
          // const url = generateURL("/app/transfer-payments", filters);
          // history.push(url);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const updateURL = () => {
    const url = generateURL("/app/transfer-payments", { tab: 0, ...filters });
    history.push(url);
  };

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <CustomDate
                name="fromDate"
                label={t("fromDate")}
                value={filters.fromDate}
                onChange={handleFilters}
              />,
              <CustomDate
                name="untilDate"
                label={t("untilDate")}
                value={filters.untilDate}
                onChange={handleFilters}
              />,
              <TextInput
                name="importMin"
                label={t("importMin")}
                type="number"
                value={filters.importMin}
                onChange={handleFilters}
              />,
              <TextInput
                name="importMax"
                label={t("importMax")}
                type="number"
                value={filters.importMax}
                onChange={handleFilters}
              />,
              <Select
                label={t("transferAccount")}
                name="debtorIBAN"
                options={[
                  { value: "", label: t("all") },
                  ...transferAccounts
                    .map((account) => ({
                      value: account.IBAN,
                      label: account.IBAN,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
                ]}
                value={filters.debtorIBAN}
                onChange={handleFilters}
              />,
              <ButtonGroup variant="contained">
                <Button>{t("reset")}</Button>
                <ExportButton
                  data={exportDataParse(transferGroups, GROUPS_CSV)}
                  display={user.hasAction("EXPORT_DATA")}
                />
                <SearchButton onClick={getTransferPaymentGroups} />
              </ButtonGroup>,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <ItemsSummary gridItems={GROUP_SUMMARY} />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={GROUPS_COLUMNS}
            data={transferGroups}
            options={{
              loaded: loaded,
              rowlink: "transfer-payment-group",
            }}
          ></CustomTable>
        </Grid>
      </Grid>
    </Page>
  );
}
