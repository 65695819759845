import { useTranslation } from "react-i18next";

import Select from "../global/inputs/Select";

const CenterTypesSelect = (props) => {
  const [t] = useTranslation("centers");

  const CENTER_TYPES = [
    {
      label: t("none"),
      value: "",
    },
    {
      label: t("NUT"),
      value: 0,
    },
    {
      label: t("franchise"),
      value: 1,
    },
    {
      label: t("other"),
      value: 2,
    },
  ];

  return (
    <Select
      autoWidth
      label={t("centerTypes")}
      options={CENTER_TYPES}
      {...props}
    />
  );
};

export default CenterTypesSelect;
