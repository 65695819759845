import {
  BOOKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  GENERAL_VAT_ES,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
} from "../../../../data/constants";
import { localeFormat } from "../../../../utils/format";

function buildRenderData({ data, tileRender, tileEvents, textMode }) {
  const BOX_COLORS = {
    [BOOKED_BOX_STATE_ID]: "gold",
    [FREE_BOX_STATE_ID]: "blue",
    [OCCUPIED_BOX_STATE_ID]: "red",
    [UNAVAILABLE_BOX_STATE_ID]: "grey",
  };
  if (!data || !data.FloorPlanLayers) return null;
  const layers = data.FloorPlanLayers.map((layer) => ({
    id: layer.id,
    name: layer.name,
    floorPlanId: data.id,
    z: layer.z,
    tiles: layer.FloorPlanTiles.sort((tile1, tile2) => tile1.z - tile2.z).map(
      (tile) => {
        let color = tile.color;
        if (tile.Boxes?.length > 0) {
          tile.text = getBoxText(tile.Boxes[0], textMode);
          color = BOX_COLORS[tile.Boxes[0].state];
          if (
            tile.Boxes[0].state === FREE_BOX_STATE_ID &&
            tile.Boxes[0]?.Contracts.length > 0 &&
            tile.Boxes[0]?.Contracts.some(
              (contract) => new Date(contract.startDate) > new Date()
            )
          ) {
            color = BOX_COLORS[OCCUPIED_BOX_STATE_ID];
          }
        }
        return {
          ...tile,
          floorPlanLayerId: layer.id,
          color: color,
          render: tileRender,
          onClick: getTileOnClick(tileEvents, tile),
        };
      }
    ),
    ...layer.props,
  }));

  return {
    width: data.width,
    height: data.height,
    backgroundColor: data.backgroundColor,
    layers: layers,
    ...data.props,
  };
}

const getTileOnClick = (tileEvents, tile) => {
  if (tile.shape === "Rect" && !tile.Boxes?.length) return () => {};
  return tileEvents[tile.shape] || (() => {});
};

const getBoxText = (box, textMode) => {
  let text;
  switch (textMode) {
    case "name":
      text = box.name;
      break;
    case "meters":
      text = localeFormat(box.meters) + " m2";
      break;
    case "price":
      text = localeFormat(box.price * (1 + GENERAL_VAT_ES / 100)) + "€";
      break;
    default:
      break;
  }
  return text;
};

export default buildRenderData;
