import { getDates } from "../../../../utils/chartUtils";
import { useTranslation } from "react-i18next";

import { NewChartContainer } from "./NewChartContainer";
import { BAR } from "../../../../data/constants";

const dataGetters = {
  amount: (items) => items.reduce((acc, item) => acc + item.amount, 0),
};

export const InvoicingByIssueDate = ({ invoiceItems, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");

  const groupBy = [
    {
      propName: "issueMonth",
      valueCalculator: (item) => item.issueMonth,
      labels: getDates(startDate, endDate, "month"),
    },
  ];
  const config = {
    propName: t("amount"),
    valueCalculator: dataGetters["amount"],
    stacked: true,
  };

  return (
    <NewChartContainer
      title={t("invoicingHistory")}
      chart={BAR}
      groupBy={groupBy}
      config={config}
      data={invoiceItems}
    />
  );
};
