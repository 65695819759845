import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

//Material UI
import {
  Button,
  Container,
  Grid,
  Typography,
  IconButton,
  Paper,
  ButtonGroup,
  Tooltip,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

//Components & others
import AppContext from "../../../context/AppContext";
import { CustomTable } from "../../CustomTable";
import CustomDate from "../../Inputs/CustomDate";
import TextInput from "../../Inputs/TextInput";
import ConfirmDialog from "../../ConfirmDialog";
import { localeFormat } from "../../../utils/format";
import SearchButton from "../../Inputs/SearchButton";
import ItemsSummary from "../../ItemsSummary";
import CustomButton from "../../Inputs/CustomButton";
import ActionIconButtons from "../../global/ActionIconButtons";
import Dialog from "../../global/Dialog";
import Filters from "../../global/structure/Filters";
import Page from "../../global/structure/Page";

const initialState = {
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  confirmDeleteReceiptDialog: {
    isOpen: false,
    option: 0,
    receipt: {
      id: "",
      concept: "",
      customerName: "",
      amount: "",
    },
  },
  remittance: {
    name: "",
    id: "",
    Receipts: [],
    amount: "",
    comments: "",
    sentAt: "",
  },
  form: {
    Receipts: [],
    comments: "",
  },
  id: null,
  options: {},
  addReceiptDialog: {
    isOpen: false,
  },
  receipts: [],
  searchText: "",
  selectedReceipts: [],
  filtersReceipts: {
    customerName: "",
    dateFrom: "",
    dateUntil: "",
  },
  receiptsLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    case "SET_CONFIRM_DELETE_RECEIPT_DIALOG":
      return {
        ...state,
        confirmDeleteReceiptDialog: {
          ...state.confirmDeleteReceiptDialog,
          isOpen: action.payload.isOpen,
          receipt: action.payload.receipt,
        },
      };
    case "SET_CONFIRM_DELETE_RECEIPT_DIALOG_OPTION":
      return {
        ...state,
        confirmDeleteReceiptDialog: {
          ...state.confirmDeleteReceiptDialog,
          option: action.payload,
        },
      };
    case "SET_FILTER_RECEIPT":
      return {
        ...state,
        filtersReceipts: {
          ...state.filtersReceipts,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_REMITTANCE":
      return {
        ...state,
        remittance: action.payload,
        form: action.payload,
      };
    case "OPEN_ADD_RECEIPT_DIALOG":
      return {
        ...state,
        addReceiptDialog: { ...state.addReceiptDialog, isOpen: true },
      };
    case "CLOSE_ADD_RECEIPT_DIALOG":
      return {
        ...state,
        addReceiptDialog: { ...state.addReceiptDialog, isOpen: false },
      };
    case "SET_RECEIPTS":
      return { ...state, receipts: action.payload };
    case "SET_SEARCH_TEXT":
      return { ...state, searchText: action.payload };
    case "SET_SELECTED_RECEIPTS":
      return { ...state, selectedReceipts: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_RECEIPTS_LOADING":
      return { ...state, receiptsLoading: action.payload };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_CONFIRM_DELETE_RECEIPT_DIALOG":
      return {
        ...state,
        confirmDeleteReceiptDialog: initialState.confirmDeleteReceiptDialog,
      };
    case "RESET_FILTERS_ADD_RECEIPT_DIALOG":
      return {
        ...state,
        filtersReceipts: initialState.filtersReceipts,
      };
    default:
      throw new Error("Action not found in reducer.");
  }
}

export default function RemittancePage() {
  const { api, user } = useContext(AppContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("remittances");
  const [tErrors] = useTranslation("errors");
  const { enqueueSnackbar } = useSnackbar();

  let { id } = useParams();

  const RECEIPT_COLUMNS = [
    { label: t("concept"), key: "concept", sortType: "string" },
    { label: t("customerName"), key: "customerName", sortType: "string" },
    {
      label: t("accountingAccount"),
      key: "Customer.accountingAccount",
      sortType: "string",
    },

    {
      label: t("amount"),
      key: "amount",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { label: t("iban"), key: "iban", sortType: "string" },
    { label: "BIC", key: "bic", sortType: "string" },
    {
      label: t("actions"),
      key: "id",
      sortType: "other",
      renderFunction: (value, item) =>
        !state.remittance?.sentAt &&
        user.hasAction("EDIT_REMITTANCES") && (
          <Tooltip title={t("deleteReceipt")} placement="top">
            <IconButton
              color="error"
              onClick={() =>
                openConfirmDeleteReceiptDialog({
                  id: value,
                  concept: item.concept,
                  customerName: item.customerName,
                  amount: item.amount,
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
    },
  ];

  const ADD_RECEIPT_DATAGRID_COLUMNS = [
    { field: "concept", headerName: t("concept"), width: 150 },
    { field: "customerName", headerName: t("customerName"), width: 200 },
    { field: "amount", headerName: t("amount"), width: 150 },
    { field: "iban", headerName: t("iban"), width: 220 },
    { field: "dueDate", headerName: t("dueDate"), width: 150 },
    { field: "signatureDate", headerName: t("signatureDate"), width: 150 },
  ];

  const CSV_RECEIPT_HEADERS = [
    { label: t("customerName"), key: "customerName" },
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    { label: t("iban"), key: "iban" },
    { label: t("dueDate"), key: "dueDate" },
    { label: t("accountingAccount"), key: "Customer.accountingAccount" },
  ];

  const REMITTANCE_ITEMS_SUMMARY = [
    {
      translatedText: t("remittanceName"),
      value: state.remittance.name,
    },
    {
      translatedText: t("numberOfReceipts"),
      value: state.remittance.Receipts?.length,
    },
    {
      translatedText: t("totalAmount"),
      value: localeFormat(state.remittance.amount) + "€",
    },
    {
      translatedText: t("dueDate"),
      value: state.remittance.dueDate,
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("remittancePage");

    getRemittance();
    getReceipts();
  }, []);

  // Backend calls

  const getRemittance = () => {
    let params = {
      include: ["Customer", "Receipt"],
    };

    api
      .get("/remittances/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_REMITTANCE", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getReceipts = () => {
    let params = {
      include: ["Remittance"],
    };

    state.filtersReceipts.customerName !== "" &&
      (params.customerName = state.filtersReceipts.customerName);
    state.filtersReceipts.dateFrom !== "" &&
      (params.dateFrom = state.filtersReceipts.dateFrom);
    state.filtersReceipts.dateUntil !== "" &&
      (params.dateUntil = state.filtersReceipts.dateUntil);

    //Include only receipts which are not in a remittance
    params.isNotInRemittance = true;

    dispatch({ type: "SET_RECEIPTS_LOADING", payload: true });

    api
      .get("/receipts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_RECEIPTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_RECEIPTS_LOADING", payload: false })
      );
  };

  const removeReceiptFromRemittance = (id) => {
    let receipt = state.remittance.Receipts.find(
      (receipt) => receipt.id === id
    );
    receipt.remittanceId = null;

    api
      .post("/receipts/edit/" + id, receipt)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("receiptRemovedSuccessfully"), {
            variant: "success",
          });
          const updatedRemittance = {
            ...state.remittance,
            Receipts: state.remittance.Receipts.filter(
              (receipt) => receipt.id !== id
            ),
          };
          dispatch({ type: "SET_REMITTANCE", payload: updatedRemittance });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addReceipt = () => {
    if (state.selectedReceipts.length === 0) {
      enqueueSnackbar(t("noSelectedReceipts"), { variant: "error" });
      return;
    }
    const selectedReceipts = state.selectedReceipts.map((id) =>
      state.receipts.find((receipt) => receipt.id === id)
    );

    const receiptData = selectedReceipts.map((receipt) => ({
      id: receipt.id,
      remittanceId: state.remittance.id,
    }));

    Promise.all(
      receiptData.map((data) => api.post(`/receipts/edit/${data.id}`, data))
    )
      .then((responses) => {
        const errors = responses.filter((response) => response.data.error);
        if (errors.length) {
          enqueueSnackbar(errors[0].data.error, { variant: "error" });
        } else {
          if (responses.length === 1) {
            enqueueSnackbar(t("receiptAddedSuccessfully"), {
              variant: "success",
            });
          } else if (responses.length > 1) {
            enqueueSnackbar(t("receiptsAddedSuccessfully"), {
              variant: "success",
            });
          }
          closeAddReceiptDialog();
          getRemittance();
          getReceipts();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    const { comments } = state.form;
    api
      .post("/remittances/edit/" + id, { comments })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("editedRemittanceSuccessfully"), {
            variant: "success",
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const remit = () => {
    api
      .post("/remittances/remit/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("remittedSuccessfully"), { variant: "success" });
          getRemittance();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteReceipt = (id) => {
    api
      .delete("/receipts/" + id)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("receiptDeletedSuccessfully"), {
            variant: "success",
          });
          getRemittance();
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const downloadXML = () => {
    api
      .get("/remittances/" + id + "/xml")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const xml = response.data;

          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([xml], { type: "text/plain" })
          );
          a.download = state.remittance.name + ".xml";

          // Append anchor to body
          document.body.appendChild(a);
          a.click();

          // Remove anchor from body
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  // Handlers

  const confirmRemit = (e) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("remit"),
        isOpen: true,
        childrenText: t("remitConfirmQuestion"),
        callback: (confirmed) => {
          confirmed && remit();
          resetConfirmDialog();
        },
      },
    });
  };

  const closeAddReceiptDialog = () => {
    dispatch({ type: "CLOSE_ADD_RECEIPT_DIALOG" });
  };

  const handleConfirmDeleteReceiptDialogOptionChange = (e) => {
    dispatch({
      type: "SET_CONFIRM_DELETE_RECEIPT_DIALOG_OPTION",
      payload: Number(e.target.value),
    });
  };

  const handleDeleteReceipt = () => {
    if (state.confirmDeleteReceiptDialog.option === 0) {
      removeReceiptFromRemittance(state.confirmDeleteReceiptDialog.receipt.id);
    } else if (state.confirmDeleteReceiptDialog.option === 1) {
      deleteReceipt(state.confirmDeleteReceiptDialog.receipt.id);
    }
    resetConfirmDeleteReceiptDialog();
  };

  const handleFilterReceiptChange = (e) => {
    dispatch({
      type: "SET_FILTER_RECEIPT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleSearchChange = (e) => {
    dispatch({ type: "SET_SEARCH_TEXT", payload: e.target.value });
  };

  const resetFilterAddReceiptsDialog = () => {
    dispatch({ type: "RESET_FILTERS_ADD_RECEIPT_DIALOG" });
  };

  const openAddReceiptDialog = () => {
    dispatch({ type: "OPEN_ADD_RECEIPT_DIALOG" });
  };

  const openConfirmDeleteReceiptDialog = (receipt) => {
    dispatch({
      type: "SET_CONFIRM_DELETE_RECEIPT_DIALOG",
      payload: {
        isOpen: true,
        receipt,
      },
    });
  };

  const resetConfirmDeleteReceiptDialog = () => {
    dispatch({ type: "RESET_CONFIRM_DELETE_RECEIPT_DIALOG" });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const selectReceipts = (ids) => {
    dispatch({ type: "SET_SELECTED_RECEIPTS", payload: ids });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const actions = [
    {
      title: t("remit"),
      icon: <SendIcon />,
      onClick: confirmRemit,
      disabled: Boolean(
        state.remittance.sentAt || state.form.Receipts?.length === 0
      ),
      display: user.hasAction("EDIT_REMITTANCES"),
    },
    {
      title: t("downloadXml"),
      onClick: downloadXML,
      icon: <DownloadIcon />,
      disabled: Boolean(state.form.Receipts?.length === 0),
    },
    {
      title: t("downloadCsv"),
      icon: <ArticleIcon />,
      disabled: Boolean(state.form.Receipts?.length === 0),
      csv: {
        headers: CSV_RECEIPT_HEADERS,
        data: state.form?.Receipts || [],
        filename: state.remittance?.name + "_" + t("receipts") + ".csv",
      },
    },
    {
      display: user.hasAction("EDIT_REMITTANCES"),
      title: t("addReceipt"),
      disabled: Boolean(state.remittance.sentAt),
      icon: <NoteAddIcon />,
      onClick: openAddReceiptDialog,
      color: "success",
    },
  ];

  const getFilteredReceipts = () => {
    const receipts = state.form.Receipts;
    const searchText = state.searchText.toLowerCase().trim();

    return receipts.filter((receipt) => {
      const { concept, customerName, iban, bic, amount } = receipt;
      const formattedAmount = localeFormat(amount);

      return [concept, customerName, formattedAmount, iban, bic]
        .map(String)
        .some((field) => field.toLowerCase().includes(searchText));
    });
  };

  return (
    <Page browserTitle={t("box")}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item>
              <Typography variant="h4">{t("editRemittance")}</Typography>
            </Grid>
            <Grid item flexGrow={1} marginLeft={2}>
              {state.remittance.sentAt ? (
                <Chip
                  color="success"
                  label={t("sentAt") + ": " + state.remittance.sentAt}
                  size="small"
                />
              ) : (
                <Chip color="warning" label={t("pending")} size="small" />
              )}
            </Grid>
            <Grid item xs={12} sm="auto">
              <ActionIconButtons actions={actions} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ItemsSummary gridItems={REMITTANCE_ITEMS_SUMMARY} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              multiline
              rows={4}
              label={t("comments")}
              name="comments"
              value={state.form.comments}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Filters
              filters={[
                <TextInput
                  label={t("search")}
                  value={state.searchText}
                  name="searchText"
                  onChange={handleSearchChange}
                />,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              data={getFilteredReceipts()}
              columns={RECEIPT_COLUMNS}
              options={state.options}
            />
          </Grid>

          <Grid item container xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={submitForm}>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* Add Receipt dialog */}
      <Dialog
        open={state.addReceiptDialog.isOpen}
        onClose={closeAddReceiptDialog}
        title={t("addReceipt")}
        actions={
          <CustomButton color="primary" onClick={addReceipt}>
            {t("add")}
          </CustomButton>
        }
        maxWidth="lg"
      >
        <Grid item container spacing={3} marginTop={1}>
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput
                label={t("customerName")}
                onChange={handleFilterReceiptChange}
                name="customerName"
                value={state.filtersReceipts.customerName}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <CustomDate
                InputLabelProps={{ shrink: true }}
                onChange={handleFilterReceiptChange}
                name="dateFrom"
                label={t("dateFrom")}
                value={state.filtersReceipts.dateFrom}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <CustomDate
                InputLabelProps={{ shrink: true }}
                onChange={handleFilterReceiptChange}
                name="dateUntil"
                label={t("dateUntil")}
                value={state.filtersReceipts.dateUntil}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ButtonGroup variant="contained" color="primary">
                <CustomButton onClick={resetFilterAddReceiptsDialog}>
                  {t("reset")}
                </CustomButton>
                <SearchButton
                  onClick={getReceipts}
                  loading={state.receiptsLoading}
                >
                  {t("search")}
                </SearchButton>
              </ButtonGroup>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ height: 400, width: "100%" }}>
              <DataGrid
                columns={ADD_RECEIPT_DATAGRID_COLUMNS}
                rows={state.receipts}
                checkboxSelection
                selectionModel={state.selectReceipts}
                onRowSelectionModelChange={(ids) => {
                  selectReceipts(ids);
                }}
                loading={state.receiptsLoading}
              />
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      {/* Confirm delete receipt dialog */}
      <Dialog
        open={state.confirmDeleteReceiptDialog.isOpen}
        onClose={resetConfirmDeleteReceiptDialog}
        title={t("deleteReceipt")}
        actions={
          <>
            <CustomButton
              onClick={resetConfirmDeleteReceiptDialog}
              color="error"
            >
              {t("cancel")}
            </CustomButton>
            <CustomButton onClick={handleDeleteReceipt}>
              {t("confirm")}
            </CustomButton>
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {t("pleaseSelectOneOfTheOptions")}
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>
                {`${
                  state.confirmDeleteReceiptDialog.receipt.concept
                } (${localeFormat(
                  state.confirmDeleteReceiptDialog.receipt.amount
                )}€) - ${
                  state.confirmDeleteReceiptDialog.receipt.customerName
                }`}
              </FormLabel>
              <RadioGroup
                value={state.confirmDeleteReceiptDialog.option}
                onChange={handleConfirmDeleteReceiptDialogOptionChange}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio size="small" />}
                  label={t("removeReceiptFromRemittance")}
                />
                <FormControlLabel
                  value={1}
                  control={<Radio size="small" />}
                  label={t("deleteReceipt")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Dialog>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}
