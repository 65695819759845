/**
 * @param {Array} sets
 *    An array of arrays of numbers
 * @returns {Array} resultSets
 * @description This function takes an array of arrays and returns an
 *    array of arrays where each element is the percentage of the total
 *    of the corresponding element in the input arrays.
 * @example
 *    valueToPercentage([[1, 2, 3], [4, 5, 6], [7, 8, 9]]);
 *    returns [[10, 20, 30], [40, 50, 60], [70, 80, 90]]
 */
export const valueToPercentage = (sets) => {
  try {
    let resultSets = [];
    let maxLength = sets.reduce(
      (max, set) => (set.length > max ? set.length : max),
      0
    );
    sets.forEach((set) => resultSets.push([]));
    for (let i = 0; i < maxLength; i++) {
      let total = 0;
      sets.forEach((set) => {
        total += set[i] || 0;
      });
      sets.forEach((set, index) => {
        if (total === 0) resultSets[index].push(0);
        else resultSets[index].push((set[i] / total) * 100);
      });
    }
    return resultSets;
  } catch (e) {
    console.log(e);
  }
};

export const removeDuplicatesObjectArray = (array, key) => {
  return array.filter(
    (item, index, self) => index === self.findIndex((t) => t[key] === item[key])
  );
};
