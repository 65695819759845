import { Link, Menu, MenuItem } from "@mui/material";
import CustomButton from "../../../Inputs/CustomButton";
import { useTranslation } from "react-i18next";

function DoorManager(props) {
  const [t] = useTranslation("floorPlans");
  const {
    menuPosition,
    selectedTile,
    setMenuPosition,
    setSelectedTile,
    openDoor,
  } = props;

  if (!menuPosition || !selectedTile || selectedTile.shape !== "Door")
    return <></>;

  const handleCloseMenu = () => {
    setSelectedTile(null);
    setMenuPosition(null);
  };

  const handleClick = async () => {
    await openDoor(selectedTile?.DoorFloorPlanTile?.DoorId);
    handleCloseMenu();
  };

  return (
    <Menu
      open={menuPosition}
      onClose={handleCloseMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition.mouseY !== null && menuPosition.mouseX !== null
          ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={handleClick}>{t("openDoor")}</MenuItem>
    </Menu>
  );
}

export default DoorManager;
