import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Contexts
import AppContext from "../../../context/AppContext";

// Icons
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

// Custom components
import buildRenderData from "./FloorPlanRender/BuildRenderData";
import CenterSelect from "../../Inputs/CenterSelect";
import Button from "../../Inputs/CustomButton";
import Dialog from "../../global/Dialog";
import FloorPlanRender from "./FloorPlanRender/FloorPlanRender";
import Page from "../../global/structure/Page";
import Select from "../../global/inputs/Select";
import TextInput from "../../Inputs/TextInput";

// Managers
import BoxManager from "./Managers/BoxManager";
import CameraManager from "./Managers/CameraManager";
import DoorManager from "./Managers/DoorManager";

// Others
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

const defaultDialogData = {
  loading: false,
  openDialog: false,
  name: "",
  width: 500,
  height: 500,
  backgroundColor: "",
};

const FloorPlansPage = () => {
  const { api, user } = useContext(AppContext);

  const [t] = useTranslation("floorPlans");
  const [tErrors] = useTranslation("error");

  // Center and floor plan selected to render
  const [centerId, setCenterId] = useState(null);
  const [selectedFloorPlan, setSelectedFloorPlan] = useState(null);

  // Floor plans data list
  const [floorPlans, setFloorPlans] = useState([]);

  // Dialog data to add floor plan
  const [dialogData, setDialogData] = useState(defaultDialogData);

  // Data for interact with tiles
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedTile, setSelectedTile] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [textMode, setTextMode] = useState("");

  useEffect(() => {
    //setSelectedFloorPlan(null);
    //setSelectedTile(null);
    //setFloorPlans([]);
    if (centerId) getFloorPlans();
  }, [centerId]);

  const getFloorPlans = () => {
    const params = {
      centerId: centerId,
      include: ["FloorPlanLayer", "FloorPlanTile", "Box"],
    };
    api
      .get(`/floor-plans`, { params })
      .then((res) => {
        if (res.data.error)
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        else {
          setFloorPlans(res.data);
          res.data.length > 0 && setSelectedFloorPlan(res.data[0].id);
        }
      })
      .catch((err) => {
        enqueueSnackbar(tErrors(err), { variant: "error" });
      });
  };

  const openDoor = (doorId) => {
    return new Promise((resolve, reject) => {
      if (!doorId) {
        enqueueSnackbar(t("doorIdNotDefined"), { variant: "error" });
        reject(t("doorIdNotDefined"));
        return;
      }
      api
        .post(`/access-control/door/${doorId}/open`, { interval: 3 })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
            reject(response.data.error);
            return;
          } else if (response.data === "El dispositivo no existe") {
            enqueueSnackbar(response.data, { variant: "error" });
            reject(response.data);
            return;
          } else {
            enqueueSnackbar(t("doorOpenedSuccessfully"), {
              variant: "success",
            });
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          setSelectedTile(null);
          reject(error);
          return;
        });
    });
  };

  const handleAddFloorPlan = () => {
    if (
      !centerId ||
      !dialogData.name ||
      !dialogData.width ||
      !dialogData.height
    ) {
      enqueueSnackbar(t("unexpectedError"), { variant: "error" });
      return;
    }
    setDialogData({ ...dialogData, loading: true });
    const data = {
      centerId: centerId,
      name: dialogData.name,
      width: dialogData.width,
      height: dialogData.height,
      backgroundColor: dialogData.backgroundColor,
    };
    api
      .post(`/floor-plans/create`, data)
      .then((res) => {
        if (res.data.error)
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        else getFloorPlans();
      })
      .catch((err) => {
        enqueueSnackbar(tErrors(err), { variant: "error" });
      })
      .finally(() => {
        setDialogData(defaultDialogData);
      });
  };

  const handleBoxTileClick = (event, tile) => {
    event.evt.preventDefault();
    const { clientX, clientY } = event.evt;
    setSelectedTile(tile);
    setMenuPosition({
      mouseX: clientX,
      mouseY: clientY,
    });
  };

  const handleCameraTileClick = (event, tile) => {
    event.evt.preventDefault();
    if (tile.SurveillanceCameras && tile.SurveillanceCameras.length > 0) {
      setCameras(tile.SurveillanceCameras);
    }
  };

  const handleDoorTileClick = (event, tile) => {
    event.evt.preventDefault();
    const { clientX, clientY } = event.evt;
    setSelectedTile(tile);
    setMenuPosition({
      mouseX: clientX,
      mouseY: clientY,
    });
  };

  const handleCloseCameraStream = (cameraId) => {
    setCameras(cameras.filter((camera) => camera.id !== cameraId));
  };

  const tileEvents = {
    Rect: handleBoxTileClick,
    Camera: handleCameraTileClick,
    Door: handleDoorTileClick,
  };

  const selectedPlan =
    floorPlans.length && selectedFloorPlan
      ? floorPlans.find((floorPlan) => floorPlan.id === selectedFloorPlan)
      : null;

  return (
    <Page title={t("floorPlans")}>
      <Grid container spacing={2}>
        <Grid item>
          <CenterSelect
            onChange={(e) => {
              setCenterId(e.target.value);
            }}
          />
        </Grid>
        {floorPlans && floorPlans.length > 0 && (
          <>
            <Grid item>
              <Select
                label={t("floorPlan")}
                value={selectedFloorPlan}
                options={floorPlans.map((floorPlan) => ({
                  value: floorPlan.id,
                  label: floorPlan.name,
                }))}
                onChange={(e) => setSelectedFloorPlan(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Select
                label={t("Texto")}
                value={textMode}
                options={[
                  { label: "Nada", value: "" },
                  { label: "Nombre", value: "name" },
                  { label: "Metros", value: "meters" },
                  { label: "Precio", value: "price" },
                ]}
                onChange={(e) => setTextMode(e.target.value)}
              />
            </Grid>
          </>
        )}
        {user.hasAction("EDIT_FLOOR_PLANS") && (
          <>
            <Grid item>
              <Button
                startIcon={<AddIcon />}
                disabled={!centerId}
                onClick={() => {
                  setDialogData({ ...dialogData, openDialog: true });
                }}
              >
                {t("addFloorPlan")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<EditIcon />}
                disabled={!selectedPlan}
                component={Link}
                to={`/app/floor-plan/${selectedPlan?.id}`}
              >
                {t("editFloorPlan")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        {selectedPlan && (
          <Grid item xs={12}>
            <FloorPlanRender
              data={buildRenderData({
                data: selectedPlan,
                tileEvents: tileEvents,
                textMode,
              })}
            />
          </Grid>
        )}
      </Grid>
      <BoxManager
        menuPosition={menuPosition}
        selectedTile={selectedTile}
        setMenuPosition={setMenuPosition}
        setSelectedTile={setSelectedTile}
      />
      <CameraManager cameras={cameras} onClose={handleCloseCameraStream} />
      <DoorManager
        menuPosition={menuPosition}
        selectedTile={selectedTile}
        setMenuPosition={setMenuPosition}
        setSelectedTile={setSelectedTile}
        openDoor={openDoor}
      />
      <Dialog
        open={dialogData.openDialog}
        onClose={() => setDialogData({ ...dialogData, openDialog: false })}
        actions={
          <Button
            disabled={
              dialogData.loading ||
              dialogData.name === "" ||
              dialogData.width === "" ||
              dialogData.height === ""
            }
            color="success"
            onClick={handleAddFloorPlan}
          >
            {t("add")}
          </Button>
        }
        title={t("addFloorPlan")}
        children={
          <Grid container spacing={2} marginTop={1}>
            <Grid item>
              <TextInput
                label={t("name")}
                value={dialogData.name}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item>
              <TextInput
                type="number"
                label={t("width")}
                value={dialogData.width}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    width: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item>
              <TextInput
                type="number"
                label={t("height")}
                value={dialogData.height}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    height: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        }
      />
    </Page>
  );
};

export default FloorPlansPage;
