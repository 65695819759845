import { Box } from "@mui/material";

const Content = ({ children }) => {
  return <Box sx={styles.page}>{children}</Box>;
};

const styles = {
  page: {
    alignItems: "center",
    color: "#fff",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "2.8125rem",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
};

export default Content;
