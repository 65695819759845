import { Grid, Typography, Slider, Tooltip, IconButton } from "@mui/material";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CenterReportContext from "../CenterReportContext";
import AppContext from "../../../../../context/AppContext";

import { ChartContainer } from "../../Charts/ChartContainer";
import { localeFormat } from "../../../../../utils/format";
import { LocationPercentile } from "../../Charts/CenterReport";
import CenterMap from "../../Components/CenterMap";
import Filters from "../../../../global/structure/Filters";
import InformationBox from "../../Components/InformationBox";
import SearchButton from "../../../../Inputs/SearchButton";
import Select from "../../../../global/inputs/Select";

import CircleIcon from "@mui/icons-material/Circle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

import {
  // Contract states
  PENDING_CONTRACT_STATE_ID,
  ACTIVE_CONTRACT_STATE_ID,
  ENDED_CONTRACT_STATE_ID,

  // Customer types
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  COMPANY_CUSTOMER_TYPE_ID,
} from "../../../../../data/constants";

const initialFilters = {
  customerTypeId: [],
  state: [],
  isKeyAccount: [],
  maxDistance: 100,
};

const contractStateNames = {
  [PENDING_CONTRACT_STATE_ID]: "pending",
  [ACTIVE_CONTRACT_STATE_ID]: "active",
  [ENDED_CONTRACT_STATE_ID]: "finished",
};

function LocationsTab() {
  const [t] = useTranslation("dashboard");
  const { api } = useContext(AppContext);
  const { center, filters } = useContext(CenterReportContext);
  const [locationFilters, setLocationFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getRoutes = () => {
    setLocationFilters({ ...locationFilters, maxDistance: 100 });
    setLoading(true);
    api
      .get("/locations/customer-center-routes", {
        params: { centerId: filters.center, ...locationFilters },
      })
      .then((response) => {
        if (!Array.isArray(response.data)) {
          setLoading(false);
          return;
        }
        const parsedData = response.data
          .filter(
            (item) =>
              item.Route &&
              item.Customer &&
              item.Customer.latitude &&
              item.Customer.longitude
          )
          .map((item) => ({
            customer: item.Customer,
            customerId: item.customerId,
            meters: item.meters,
            price: item.pricePerMeter * item.meters || 0,
            contractStateId: item.state || "",
            contractStateName: t(contractStateNames[item.state]) || "",
            cusomterTypeId: item.Customer?.CustomerType?.id || "",
            cusomterTypeName: item.Customer?.CustomerType?.name || "",
            drivingDistance: item.Route?.drivingDistance || "",
            drivingDuration: item.Route?.drivingDuration || "",
            walkingDistance: item.Route?.walkingDistance || "",
            walkingDuration: item.Route?.walkingDuration || "",
            isActiveContract: item.state === ACTIVE_CONTRACT_STATE_ID,
            isKeyAccount:
              item.Customer?.isKeyAccount !== undefined
                ? item.Customer?.isKeyAccount
                : false,
            distanceToCenter:
              distanceBetweenCoordinates(
                center.latitude,
                center.longitude,
                item.Customer.latitude,
                item.Customer.longitude
              ) || null,
          }));
        const maxDistance = Number(
          parsedData
            .reduce((acc, item) => Math.max(acc, item.distanceToCenter), 0)
            .toFixed(2)
        );
        setLocationFilters({ ...locationFilters, maxDistance });
        setData(parsedData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterChange = (e) => {
    setLocationFilters({ ...locationFilters, [e.target.name]: e.target.value });
  };

  const maxDistanceToCenter = Number(
    data
      .reduce((acc, item) => Math.max(acc, item.distanceToCenter), 0)
      .toFixed(2)
  );
  const maxDistance =
    maxDistanceToCenter > 100000 ? 100000 : maxDistanceToCenter;

  const filterDistance = (locationFilters.maxDistance / 100) * maxDistance;
  const filteredData = data.filter((item) => {
    return (
      item.distanceToCenter <=
      (filterDistance > maxDistance ? maxDistance : filterDistance)
    );
  });

  const filteredCustomers = filteredData.map((item) => ({
    ...item.customer,
    item: item,
  }));

  const totalContracts = filteredData.length;

  const {
    averageCenterDistance,
    averageDrivingDistance,
    averageDrivingDuration,
    averageWalkingDistance,
    averageWalkingDuration,
    averageDistanceToCenter,
  } = getAverageValues(filteredData);

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <Select
              name="customerTypeId"
              label={t("customerType")}
              multiple
              options={[
                { value: INDIVIDUAL_CUSTOMER_TYPE_ID, label: t("individual") },
                { value: COMPANY_CUSTOMER_TYPE_ID, label: t("company") },
              ]}
              onChange={handleFilterChange}
              value={locationFilters.customerTypeId}
              disabled={loading || !filters.center}
            />,
            <Select
              name="state"
              label={t("activeContract")}
              multiple
              options={[
                { value: ACTIVE_CONTRACT_STATE_ID, label: t("active") },
                { value: ENDED_CONTRACT_STATE_ID, label: t("finished") },
              ]}
              onChange={handleFilterChange}
              value={locationFilters.state}
              disabled={loading || !filters.center}
            />,
            <Select
              name="isKeyAccount"
              label={t("keyAccount")}
              multiple
              options={[
                { value: 1, label: t("yes") },
                { value: 0, label: t("no") },
              ]}
              onChange={handleFilterChange}
              value={locationFilters.isKeyAccount}
              disabled={loading || !filters.center}
            />,
            <SearchButton
              onClick={getRoutes}
              loading={loading || !filters.center}
            />,
          ]}
        />
      </Grid>
      <Grid item container spacing={2} marginTop={1} marginLeft={1}>
        <Grid item alignContent={"center"}>
          <Typography>{t("maxDistance") + ":"}</Typography>
        </Grid>
        <Grid item xs={3} marginLeft={1}>
          <Slider
            value={locationFilters.maxDistance}
            name="maxDistance"
            disabled={loading || !filters.center || !data.length}
            onChange={handleFilterChange}
            valueLabelDisplay="auto"
            min={0}
            max={100}
            valueLabelFormat={(value) => {
              return `${localeFormat(((value / 100) * maxDistance) / 1000)} km`;
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={2}
      >
        <Grid item>
          <InformationBox
            mainValue={totalContracts}
            title={t("totalContracts")}
            borderRadius={1}
            height={150}
            width={275}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={localeFormat(averageCenterDistance / 1000) + " km"}
            title={t("averageCenterDistance")}
            borderRadius={1}
            height={150}
            width={275}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={localeFormat(averageDrivingDistance / 1000) + " km"}
            title={t("averageDrivingDistance")}
            borderRadius={1}
            height={150}
            width={275}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={
              formatSecondsToMinutes(localeFormat(averageDrivingDuration)) +
              " min"
            }
            title={t("averageDrivingDuration")}
            borderRadius={1}
            height={150}
            width={275}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={localeFormat(averageWalkingDistance / 1000) + " km"}
            title={t("averageWalkingDistance")}
            borderRadius={1}
            height={150}
            width={275}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={
              formatSecondsToMinutes(localeFormat(averageWalkingDuration)) +
              " min"
            }
            title={t("averageWalkingDuration")}
            borderRadius={1}
            height={160}
            width={240}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <LocationPercentile contracts={filteredData} />
      </Grid>
      {center.id !== undefined && (
        <Grid item xs={6}>
          <ChartContainer
            title={t("customersLocations")}
            chart={
              <CenterMap
                customers={filteredCustomers.map((customer) => {
                  return {
                    active: true,
                    longitude: customer.longitude,
                    latitude: customer.latitude,
                    name: customer.fullName || "",
                    description: customer.fullName || "",
                    id: customer.id,
                    color:
                      customer.CustomerType.id === 1 ? "#4264fb" : "#e69e19",
                  };
                })}
                center={{
                  name: center.name,
                  latitude: center.latitude,
                  longitude: center.longitude,
                }}
                filterRange={
                  (filterDistance > maxDistance
                    ? maxDistance
                    : filterDistance) / 1000
                }
                averageDistance={averageDistanceToCenter / 1000}
              />
            }
          >
            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              marginBottom={2}
            >
              <Grid item>
                <Tooltip title={t("individual")}>
                  <IconButton size="small">
                    <CircleIcon style={{ color: "#4264fb" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t("company")}>
                  <IconButton size="small">
                    <CircleIcon style={{ color: "#e69e19" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t("filterRange")}>
                  <IconButton size="small">
                    <PanoramaFishEyeIcon style={{ color: "#808080" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t("averageDistance")}>
                  <IconButton size="small">
                    <PanoramaFishEyeIcon style={{ color: "#FF0000" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </ChartContainer>
        </Grid>
      )}
    </Grid>
  );
}

const getAverageValues = (data) => {
  const totalContracts = data.length || 1;
  const rFn = (prop) => (acc, item) => acc + Number(item[prop]);
  const totalCenterDistance = data.reduce(rFn("distanceToCenter"), 0);
  const totalDrivingDistance = data.reduce(rFn("drivingDistance"), 0);
  const totalDrivingDuration = data.reduce(rFn("drivingDuration"), 0);
  const totalWalkingDistance = data.reduce(rFn("walkingDistance"), 0);
  const totalWalkingDuration = data.reduce(rFn("walkingDuration"), 0);
  const totalDistanceToCenter = data.reduce(rFn("distanceToCenter"), 0);

  return {
    averageCenterDistance: totalCenterDistance / totalContracts,
    averageDrivingDistance: totalDrivingDistance / totalContracts,
    averageDrivingDuration: totalDrivingDuration / totalContracts,
    averageWalkingDistance: totalWalkingDistance / totalContracts,
    averageWalkingDuration: totalWalkingDuration / totalContracts,
    averageDistanceToCenter: totalDistanceToCenter / totalContracts,
  };
};

const formatSecondsToMinutes = (seconds) => {
  const parsedSeconds = Number(seconds.replace(".", "").replace(",", "."));
  const minutes = Math.floor(parsedSeconds / 60);
  return minutes;
};

const distanceBetweenCoordinates = (lat1, lon1, lat2, lon2) => {
  if (!lat1 || !lon1 || !lat2 || !lon2) {
    return 0;
  }
  const R = 6371e3; // Radius of the Earth in meters
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // Distance in meters
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export default LocationsTab;
