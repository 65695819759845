import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ButtonLink from "../../../Inputs/ButtonLink";
import InvoiceStateChip from "../../../InvoiceStateChip";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { localeFormat } from "../../../../utils/format";
import { today } from "../../../../utils/date";
import {
  EXPIRED_INVOICE_STATE_ID,
  ISSUED_INVOICE_STATE_ID,
} from "../../../../data/constants";

function InvoiceCard({
  invoice,
  beingProcessedIds,
  functions = {},
  selectedInvoicesIds,
  setSelectedInvoicesIds,
}) {
  const [t] = useTranslation("invoices");
  const item = invoice || {};

  const handleNonPayment = (error) => {
    functions.createNonPayment({
      reason: 6,
      comment: "Error al cobrar tarjeta tokenizada:" + error?.toString(),
      date: today(),
      invoiceId: item.id,
    });
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setSelectedInvoicesIds([...selectedInvoicesIds, item.id]);
    } else {
      setSelectedInvoicesIds(
        selectedInvoicesIds.filter((id) => id !== item.id)
      );
    }
  };

  const beingProcessed = beingProcessedIds.includes(invoice.id);

  const allowedStates = [ISSUED_INVOICE_STATE_ID, EXPIRED_INVOICE_STATE_ID];
  const notAllowedPayment = !allowedStates.includes(invoice.state);

  const hasNonPayments = invoice.NonPayments.length !== 0;
  const hasPendingNonPayments =
    hasNonPayments && invoice.NonPayments.some((np) => !np.recuperationDate);
  const isSelected = selectedInvoicesIds.includes(item.id);

  return (
    <Card variant="outlined" style={{ borderRadius: "10px" }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ minHeight: "75px" }}
      >
        <Grid
          item
          marginLeft={2}
          xs={0.5}
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <Checkbox
            checked={isSelected}
            onChange={handleCheckBox}
            disabled={notAllowedPayment || beingProcessed}
          />
        </Grid>
        <Grid
          item
          xs={1.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ButtonLink variant="text" to={"/app/invoice/" + item.id}>
            {item.number}
          </ButtonLink>
        </Grid>
        <Grid item xs={2}>
          <ButtonLink variant="text" to={"/app/customer/" + item.Customer?.id}>
            {item.customerName}
          </ButtonLink>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <Typography variant="body2">{item.payCard}</Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ textAlign: "center" }}>
          <Typography variant="body2">{item.issueDate}</Typography>
        </Grid>
        <Grid item xs={1.25} sx={{ textAlign: "center" }}>
          <Typography variant="body2">
            {localeFormat(item.totalAmount || 0) + "€"}
          </Typography>
        </Grid>
        <Grid item xs={1.25} sx={{ textAlign: "center" }}>
          <InvoiceStateChip state={item.state} />
        </Grid>
        <Grid
          item
          container
          xs={1.5}
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <ActionsComponent
            invoice={item}
            tryPayment={functions.tryPaymentHandler}
            beingProcessed={beingProcessed}
            hasNonPayment={hasPendingNonPayments}
            notAllowedPayment={notAllowedPayment}
            handleNonPayment={handleNonPayment}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

function ActionsComponent({
  beingProcessed,
  hasNonPayment,
  invoice,
  tryPayment,
  notAllowedPayment,
  handleNonPayment,
}) {
  const history = useHistory();
  const [t] = useTranslation("invoices");

  return (
    <>
      <Grid item xs={4}>
        {beingProcessed ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Tooltip title={t("charge")} placement="top">
            <IconButton
              disabled={beingProcessed || notAllowedPayment}
              onClick={(e) => {
                e.stopPropagation();
                tryPayment(invoice.id);
              }}
            >
              <CreditCardIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={4}>
        <Tooltip title={t("goNonPayments")} placement="top">
          <IconButton
            disabled={beingProcessed}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/app/non-payment/${invoice.Customer?.id}`);
            }}
          >
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title={t("createNonPayment")} placement="top">
          <IconButton
            disabled={beingProcessed || hasNonPayment}
            onClick={(e) => {
              e.stopPropagation();
              handleNonPayment();
            }}
          >
            <MoneyOffIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
}

export default InvoiceCard;
