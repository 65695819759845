import React from "react";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";

/**
 * InformationBox Component
 *
 * This component renders a square box with an optional white background and shadow.
 * It displays a main value and an optional secondary value in the center, with an
 * optional title at the top. The size of the box can be customized.
 *
 * Props:
 * - mainValue (string | { text: string, color: string }): The main value to be displayed
 *   in large text in the center. If an object is provided, it can include a `text` and `color`.
 * - secondaryValue (string | { text: string, color: string }) (optional): The secondary
 *   value to be displayed in smaller text below the main value. If an object is provided,
 *   it can include a `text` and `color`.
 * - title (string | { text: string, color: string }) (optional): A small text to be displayed
 *   at the top of the box. If an object is provided, it can include a `text` and `color`.
 * - paper (boolean) (optional): If true, the box will have a white background and shadow.
 *   Defaults to true.
 * - width (number) (optional): The width of the box. Defaults to 200.
 * - height (number) (optional): The height of the box. Defaults to 200.
 * - borderRadius (number) (optional): The border radius of the box. Defaults to 4.
 */
function InformationBox({
  mainValue,
  secondaryValue,
  title,
  paper = true,
  width = 200,
  height = 200,
  borderRadius,
  units,
  loading,
}) {
  return (
    <Box
      component={paper ? Paper : "div"}
      elevation={paper ? 3 : 0}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: width,
        height: height,
        bgcolor: paper ? "white" : "transparent",
        boxShadow: paper ? 3 : 0,
        position: "relative",
        padding: 2,
        borderRadius: borderRadius,
      }}
    >
      {title && (
        <Typography
          variant="subtitle2"
          sx={{
            position: "absolute",
            top: 20,
            textAlign: "center",
          }}
          color={title?.color}
        >
          {title?.text ?? title}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h4" color={mainValue?.color}>
              {mainValue?.text ?? mainValue}
              {units && (
                <Typography
                  component="span"
                  variant="h6"
                  color="GrayText"
                  sx={{ marginLeft: 0.5, fontSize: "0.5em" }}
                >
                  {units}
                </Typography>
              )}
            </Typography>
            {secondaryValue && (
              <Typography variant="h6" color={secondaryValue?.color}>
                {secondaryValue?.text ?? secondaryValue}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default InformationBox;
