import { FloorPlanContext } from "../FloorPlanPage";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../global/inputs/Select";

function AssociationsSelect(props) {
  const [t] = useTranslation("floorPlans");
  const { label, options, value } = props;
  const { editItem, setTileAssociation } = useContext(FloorPlanContext);

  const [selectedValue, setSelectedValue] = useState(value || "");

  const handleOnChange = async (e) => {
    setSelectedValue(e.target.value);
    await setTileAssociation(editItem?.id, e.target.value)
      .then(() => {})
      .catch((error) => {
        setSelectedValue(value || "");
      });
  };

  return (
    <Select
      searchable
      label={label || ""}
      value={selectedValue}
      options={[{ value: "", label: t("none") }, ...(options || [])]}
      onChange={handleOnChange}
    />
  );
}

export default AssociationsSelect;
