export const formatTime = (minutes) => {
  if (!minutes || typeof minutes !== "number") return "0";
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = minutes % 60;

  let res = "";
  days > 0 && (res += `${days}d `);
  hours > 0 && (res += `${hours}h `);
  mins > 0 && (res += `${mins}m`);
  return res;
};
