import { Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";
import { FloorPlanContext } from "../../../FloorPlanPage";
import AssociationsSelect from "../../AssociationsSelect";

const CameraCollapsible = (props) => {
  const [t] = useTranslation("floorPlans");
  const { camera } = props;
  const { cameras } = useContext(FloorPlanContext);

  return (
    <ItemCollapsibleTemplate title={t("camera")}>
      <Grid container>
        <Grid item>
          <AssociationsSelect
            label={""}
            value={camera || ""}
            options={cameras.map((camera) => ({
              value: camera.id,
              label: camera.name,
            }))}
          />
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

CameraCollapsible.propTypes = {};

export default CameraCollapsible;
