import { useContext, useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  Box,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../../context/AppContext";
import CenterSelect from "../../../Inputs/CenterSelect";
import Filters from "../../../global/structure/Filters";
import SearchButton from "../../../Inputs/SearchButton";
import {
  formatDate,
  getMonths,
  toCumulativeArray,
} from "../../../../utils/chartUtils";
import Select from "../../../global/inputs/Select";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import Dialog from "../../../global/Dialog";
import { localeFormat } from "../../../../utils/format";
import ButtonLink from "../../../Inputs/ButtonLink";
import { TrendingDown, TrendingUp } from "@mui/icons-material";

const HistoricalStats = () => {
  const [t] = useTranslation("dashboard");
  const { api } = useContext(AppContext);

  // Raw data
  const [centers, setCenters] = useState([]);

  // Filters
  const [centerFilter, setCenterFilter] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [untilDate, setUntilDate] = useState("");
  const [statFilter, setStatFilter] = useState("moveIns");
  const [cumulative, setCumulative] = useState(false);
  const [unitsFilter, setUnitsFilter] = useState("contracts");

  // Table
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  // Dialog
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    data: [],
  });

  // CSV
  const [csvHeaders, setCsvHeaders] = useState([]);

  const CONTRACT_DATA_GRID_COLUMNS = [
    {
      field: "isMoveIn",
      headerName: "",
      width: 75,
      renderCell: ({ value }) =>
        value ? <TrendingUp color="success" /> : <TrendingDown color="error" />,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell: ({ value, row }) => {
        const id = value.split("-")[0];
        return (
          <ButtonLink to={`/app/contract/${id}`} target="_blank">
            {row.publicId}
          </ButtonLink>
        );
      },
    },
    {
      field: "customerName",
      headerName: t("customer"),
      flex: 2,
    },
    {
      field: "boxName",
      headerName: t("box"),
      flex: 2,
    },
    {
      field: "meters",
      headerName: t("meters"),
      flex: 1,
      valueFormatter: ({ value }) => localeFormat(value),
    },
    {
      field: "price",
      headerName: t("price"),
      flex: 1,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
  ];

  useEffect(() => {
    if (unitsFilter === "occupancy") {
      setCumulative(true);
      setStatFilter("difference");
    }
  }, [unitsFilter]);

  const searchHandler = () => {
    setRows([]);
    setLoading(true);
    Promise.all([getCenters(), getContracts(), getBoxes()]).then((values) => {
      calcData(values[0], values[1], values[2]);
      setLoading(false);
    });
  };

  const getContracts = () => {
    return new Promise((resolve, reject) => {
      let params = {
        centerId: centerFilter,
        include: ["Box", "Customer"],
      };

      api.get("/contracts", { params }).then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error.msg, { variant: "error" });
          return reject(response.data.error.msg);
        }
        resolve(response.data);
      });
    });
  };

  const getBoxes = () => {
    return new Promise((resolve, reject) => {
      let params = {
        centers: centerFilter,
      };

      api.get("/boxes", { params }).then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error.msg, { variant: "error" });
          return reject(response.data.error.msg);
        }
        resolve(response.data);
      });
    });
  };

  const getCenters = () => {
    return new Promise((resolve, reject) => {
      api.get("/centers").then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error.msg, { variant: "error" });
          return reject(response.data.error.msg);
        }
        resolve(response.data);
      });
    });
  };

  const groupByCenters = (rawCenters, rawContracts, rawBoxes) => {
    const centers = rawCenters
      .filter((center) => centerFilter.includes(center.id.toString()))
      .map((center) => ({
        id: center.id,
        name: center.name,
        boxes: [],
        contracts: [],
      }));

    // Assign each contract to its center
    rawContracts.forEach((contract) => {
      let center = centers.find(
        (center) => center.id === contract.Box.centerId
      );
      center.contracts.push(contract);
    });

    // Assign each Box to its center
    rawBoxes.forEach((box) => {
      let center = centers.find((center) => center.id === box.centerId);
      center.boxes.push(box);
    });

    return centers;
  };

  const groupByMonths = (contracts, months, key) => {
    const dates = months.map((date) => ({
      ...date,
      contracts: [],
    }));

    contracts.forEach((contract) => {
      let date = new Date(contract[key]);
      let month = dates.find(
        (d) => d.year === date.getFullYear() && d.month === date.getMonth() + 1
      );

      month?.contracts.push(contract);
    });

    return dates;
  };

  /* Get min date from array of dates */
  const getMinDate = (dates) => {
    if (dates.length === 0) return;
    let min = dates?.reduce((a, b) => (a < b ? a : b));
    return min;
  };

  const calcData = (rawCenters, rawContracts, rawBoxes) => {
    let date = new Date();
    date.setMonth(date.getMonth() + 3);
    const minDate = getMinDate(rawContracts.map((c) => c.startDate));
    const maxDate = formatDate(date);

    const months = getMonths(minDate, maxDate);

    // Aggregate data
    let centers = groupByCenters(rawCenters, rawContracts, rawBoxes);

    // Group move-ins and move-outs by month
    centers = centers.map((center) => {
      let moveInsByMonth = groupByMonths(center.contracts, months, "startDate");

      let moveOutsByMonth = groupByMonths(center.contracts, months, "endDate");

      // Calc center meters
      let totalMeters = center.boxes.reduce((sum, box) => sum + box.meters, 0);

      return { ...center, moveInsByMonth, moveOutsByMonth, totalMeters };
    });

    let rowTotals = {
      id: 0,
      name: t("total"),
      data: new Array(months.length).fill(0),
    };

    const sumTotalMeters = centers.reduce(
      (sum, center) => sum + center.totalMeters,
      0
    );
    // Summarize
    centers = centers.map((center) => {
      let calcCallback = (contracts) => {
        let calcOptions = {
          contracts: (contracts) => contracts.length,
          meters: (contracts) =>
            contracts.reduce((sum, c) => sum + c.meters, 0),
          invoicing: (contracts) =>
            contracts.reduce((sum, c) => sum + c.meters * c.pricePerMeter, 0),
          occupancy: (contracts) =>
            contracts.reduce((sum, c) => sum + c.meters, 0),
        };
        let data = calcOptions[unitsFilter](contracts);
        return data;
      };

      let moveInsData = center.moveInsByMonth.map((contracts) => {
        return calcCallback(contracts.contracts);
      });

      let moveOutsData = center.moveOutsByMonth.map((contracts) => {
        return calcCallback(contracts.contracts);
      });

      // Calc difference
      let differenceData = [];
      for (let i in months) {
        differenceData.push(moveInsData[i] - moveOutsData[i]);
      }

      const dataOptions = {
        moveIns: moveInsData,
        moveOuts: moveOutsData,
        difference: differenceData,
      };

      let data = dataOptions[statFilter];

      // Occupancy
      if (unitsFilter === "occupancy") {
        data = data.map((meters) => (meters / center.totalMeters) * 100);
      }

      // Cumulative
      if (cumulative) {
        data = toCumulativeArray(data);
      }

      // Calc row totals
      for (let i in data) {
        if (unitsFilter === "occupancy") {
          rowTotals.data[i] +=
            (isNaN(data[i]) ? 0 : data[i]) *
            (center.totalMeters / sumTotalMeters);
        } else {
          rowTotals.data[i] += data[i];
        }
      }

      // Totals Column
      if (!cumulative) {
        let total = 0;
        months.forEach((month, i) => {
          let isBetween = true;
          let m =
            month.year +
            "-" +
            (month.month < 10 ? "0" + month.month : month.month);
          if (fromDate !== "" && m < fromDate) isBetween = false;
          if (untilDate !== "" && m > untilDate) isBetween = false;

          if (isBetween) total += data[i];
        });
        center.total = total;
      }

      return { ...center, data };
    });
    setCenters(centers);

    let total = 0;
    let rows = [...centers, rowTotals].map((center) => {
      let row = {
        id: center.id,
        center: center.name,
      };
      months.forEach((month, i) => {
        row[month.year + "-" + month.month] = Number(center.data[i].toFixed(2));
      });
      // Total column
      if (!cumulative && center.id !== 0) {
        row.total = Number(center.total.toFixed(2));
        total += row.total;
      } else if (center.id === 0) row.total = total;
      return row;
    });

    setRows(rows);

    // Columns
    let columns = [
      { field: "center", headerName: t("center"), width: 200 },
      ...months
        .filter((month) => {
          let isBetween = true;
          let m =
            month.year +
            "-" +
            (month.month < 10 ? "0" + month.month : month.month);
          if (fromDate !== "" && m < fromDate) isBetween = false;
          if (untilDate !== "" && m > untilDate) isBetween = false;
          return isBetween;
        })
        .map((month) => ({
          field: month.year + "-" + month.month,
          type: "number",
          valueFormatter: ({ value }) => numberFormatter(value),
        })),
    ];

    // Parse column format from MUI DataGrid to CSV
    let csvColumns = columns.map((column) => ({
      key: column.field,
      label: column.headerName,
    }));
    setCsvHeaders(csvColumns);

    // Total column
    if (!cumulative)
      columns.push({
        field: "total",
        headerName: t("total"),
        valueFormatter: ({ value }) => numberFormatter(value),
        type: "number",
      });

    setColumns(columns);
  };

  const getContractsByMonth = (data, field, isMoveIn) => {
    const [fromYear, fromMonth] = fromDate.split("-").map(Number);
    const [untilYear, untilMonth] = untilDate.split("-").map(Number);
    let contractsData =
      field === "total"
        ? data.reduce(
            (acc, month) => {
              const includeContracts =
                (fromYear ? month.year >= fromYear : true) &&
                (untilYear ? month.year <= untilYear : true) &&
                (fromMonth ? month.month >= fromMonth : true) &&
                (untilMonth ? month.month <= untilMonth : true);
              return {
                contracts: includeContracts
                  ? [...acc.contracts, ...month.contracts]
                  : [...acc.contracts],
              };
            },
            { contracts: [] }
          )
        : data.find((item) => item.year + "-" + item.month === field);
    return contractsData?.contracts?.map((contract) => ({
      id: contract.id + (isMoveIn ? "-in" : "-out"),
      boxName: contract.Box.name,
      customerName: contract.Customer.fullName,
      price: contract.price,
      publicId: contract.publicId,
      meters: contract.meters,
      isMoveIn,
    }));
  };

  const handleCellClick = (params) => {
    if (params.id === 0 || params.field === "center") return;

    const title = `${t("contracts")} - ${params.row.center} - ${params.field}`;

    const center = centers.find((center) => center.id === params.row.id);

    const moveInContracts = getContractsByMonth(
      center.moveInsByMonth,
      params.field,
      true
    );
    const moveOutContracts = getContractsByMonth(
      center.moveOutsByMonth,
      params.field,
      false
    );

    const contracts =
      statFilter === "moveIns"
        ? moveInContracts
        : statFilter === "moveOuts"
        ? moveOutContracts
        : [...moveInContracts, ...moveOutContracts];

    setDialog({
      open: true,
      title,
      data: contracts,
    });
  };

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  return (
    <Container sx={{ height: "100%", padding: 2 }} maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filters
            filters={[
              <CenterSelect
                value={centerFilter}
                onChange={(e) => setCenterFilter(e.target.value)}
                multiple
                name="center"
              />,
              <Select
                options={[
                  { label: t("contracts"), value: "contracts" },
                  { label: t("meters"), value: "meters" },
                  { label: t("invoicing"), value: "invoicing" },
                  { label: t("occupancy"), value: "occupancy" },
                ]}
                value={unitsFilter}
                onChange={(e) => {
                  setUnitsFilter(e.target.value);
                }}
              />,
              <Select
                options={[
                  { label: t("moveIns"), value: "moveIns" },
                  { label: t("moveOuts"), value: "moveOuts" },
                  { label: t("difference"), value: "difference" },
                ]}
                disabled={unitsFilter === "occupancy"}
                value={statFilter}
                onChange={(e) => {
                  setStatFilter(e.target.value);
                }}
              />,
              <FormControlLabel
                disabled={unitsFilter === "occupancy"}
                control={
                  <Checkbox
                    checked={cumulative}
                    onClick={(e) => setCumulative(e.target.checked)}
                  />
                }
                label={t("cumulative")}
              />,
              <TextField
                type="month"
                size="small"
                value={fromDate}
                label={t("dateFrom")}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFromDate(e.target.value)}
              />,
              <TextField
                type="month"
                size="small"
                value={untilDate}
                label={t("dateUntil")}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setUntilDate(e.target.value)}
              />,
              <ButtonGroup variant="contained">
                <ButtonCSV data={rows} headers={csvHeaders} />
                <SearchButton onClick={searchHandler} loading={loading} />
              </ButtonGroup>,
            ]}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: "200px" }}>
          <Paper sx={{ height: "100%" }}>
            <DataGrid
              density="compact"
              columns={columns}
              rows={rows}
              loading={loading}
              getCellClassName={getCellClass}
              getRowClassName={getRowClass}
              sx={tableStyle}
              onCellClick={handleCellClick}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        title={dialog.title}
        maxWidth="md"
      >
        <Box style={{ height: 400, width: "100%" }}>
          <DataGrid
            columns={CONTRACT_DATA_GRID_COLUMNS}
            rows={dialog.data}
            density="compact"
          />
        </Box>
      </Dialog>
    </Container>
  );
};

const numberFormatter = (value) => {
  if (isNaN(value)) return "-";
  return value.toFixed(2);
};

const tableStyle = {
  [`.${gridClasses.cell}.positive`]: {
    color: "green",
  },
  [`.${gridClasses.cell}.negative`]: {
    color: "red",
  },
  [`.${gridClasses.cell}.positiveBold`]: {
    color: "green",
    fontWeight: "bold",
  },
  [`.${gridClasses.cell}.negativeBold`]: {
    color: "red",
    fontWeight: "bold",
  },
  [`.${gridClasses.row}.bold`]: {
    fontWeight: "bold",
  },
};

const getCellClass = (params) => {
  if (params.value > 0) {
    return params.field === "total" ? "positiveBold" : "positive";
  }
  if (params.value < 0) {
    return params.field === "total" ? "negativeBold" : "negative";
  }
};

const getRowClass = (params) => {
  if (params.id === 0) return "bold";
};

export default HistoricalStats;
