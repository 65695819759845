import { Box, Grid, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import CenterReportContext from "../CenterReportContext";
import { getMonths, sumArray } from "../../../../../utils/chartUtils";
import { formatDate } from "../../../../../utils/date";
import { localeFormat } from "../../../../../utils/format";
import { useTranslation } from "react-i18next";
import ButtonCSV from "../../../../Inputs/ButtonCSV";

const BANK_COMMISION_IDS = [110, 111, 112, 113, 114, 115];
const CAPEX_IDS = [40, 162];
const COST_OF_GOOD_SOLD_IDS = [158, 168, 188];
const DD_AND_CLOSING_IDS = [101, 102, 160, 193, 194, 195];
const FURNITURE_IDS = [48, 133, 161, 163, 192];
const GENERAL_OPEX_IDS = [128, 129, 130, 132];
const GOOGLE_IDS = [123];
const INFORMATIC_IDS = [104, 159, 177];
const INSURANCE_IDS = [107, 109];
const MAINTEINANCE_IDS = [94, 95, 96, 185];
const MARKETING_IDS = [43, 117, 119, 120, 121, 125, 173, 182, 196];
const NOTARY_IDS = [103];
const OTHER_IDS = [81, 82, 86, 154, 155, 156, 164, 170, 176, 197, 199];
const PROFESSIONAL_SERVICES_IDS = [97, 98, 99, 186];
const PROPERTY_EXPENSES_IDS = [127, 134, 183];
const PURCHASE_PRICE_IDS = [47];
const RENT_IDS = [89, 90, 91];
const RENTING_IDS = [92, 172];
const ROYALTY_IDS = [166];
const TALUS_IDS = [189];
const TAXES_IDS = [149, 150, 151, 181];
const TRAVEL_IDS = [135, 137, 138, 139, 141, 143, 145, 146, 148, 198];
const WAGES_SALARY_IDS = [77, 78, 79, 80, 83, 85];

const expenseGroups = {
  BANK_COMMISION_IDS,
  CAPEX_IDS,
  COST_OF_GOOD_SOLD_IDS,
  DD_AND_CLOSING_IDS,
  FURNITURE_IDS,
  GENERAL_OPEX_IDS,
  GOOGLE_IDS,
  INFORMATIC_IDS,
  INSURANCE_IDS,
  MAINTEINANCE_IDS,
  MARKETING_IDS,
  NOTARY_IDS,
  OTHER_IDS,
  PROFESSIONAL_SERVICES_IDS,
  PROPERTY_EXPENSES_IDS,
  PURCHASE_PRICE_IDS,
  RENT_IDS,
  RENTING_IDS,
  ROYALTY_IDS,
  TALUS_IDS,
  TAXES_IDS,
  TRAVEL_IDS,
  WAGES_SALARY_IDS,
};

const generalExpensesGroups = {
  COST_OF_GOOD_SOLD_IDS,
  RENTING_IDS,
  RENT_IDS,
  TRAVEL_IDS,
  PROFESSIONAL_SERVICES_IDS,
  BANK_COMMISION_IDS,
  MARKETING_IDS,
  GENERAL_OPEX_IDS,
  OTHER_IDS,
  FURNITURE_IDS,
  GOOGLE_IDS,
  NOTARY_IDS,
  INFORMATIC_IDS,
};

const propertyRelatedGroups = {
  MAINTEINANCE_IDS,
  TAXES_IDS,
  INSURANCE_IDS,
  PROPERTY_EXPENSES_IDS,
};

const aicGroups = {
  PURCHASE_PRICE_IDS,
  DD_AND_CLOSING_IDS,
  CAPEX_IDS,
};

const getMinDate = (providerInvoices, invoiceItems) => {
  let dates = [
    ...providerInvoices.map((p) => p.date),
    ...invoiceItems.map((i) => i.Invoice.issueDate),
  ];
  return dates.sort()[0];
};

const PnLTab = () => {
  const { providerInvoices, invoiceItems } = useContext(CenterReportContext);
  const [t] = useTranslation("dashboardCenterReport");

  const minDate = getMinDate(providerInvoices, invoiceItems);
  const dates = getMonths(minDate, formatDate(new Date()));

  let months = [];

  let totals = {
    year: "total",
    month: "-",
    invoicedAmount: 0,
    propertyRelatedExpenses: 0,
    generalExpenses: 0,
    ebitda: 0,
    aic: 0,
    unleveredCashFlow: 0,
  };
  // Init totals
  Object.keys(expenseGroups).forEach((group) => (totals[group + "amount"] = 0));

  dates.map((date) => {
    // Assing provider invoices to each month
    date.providerInvoices = providerInvoices.filter(
      (providerInvoice) =>
        new Date(providerInvoice.date).getFullYear() === date.year &&
        new Date(providerInvoice.date).getMonth() + 1 === date.month
    );

    // Assing invoice items to each month
    date.invoiceItems = invoiceItems.filter(
      (invoiceItem) =>
        new Date(invoiceItem.Invoice.issueDate).getFullYear() === date.year &&
        new Date(invoiceItem.Invoice.issueDate).getMonth() + 1 === date.month
    );

    // Group invoices by expense type and calc amount
    Object.keys(expenseGroups).forEach((group) => {
      date[group] = date.providerInvoices.filter((pi) =>
        expenseGroups[group].includes(pi.expenseTypeId)
      );
    });

    // TODO
    // All provider invoices with other categories are included here
    date["UNASIGNED_PROVIDER_INVOICES"] = date.providerInvoices.filter(
      (pi) => !Object.values(expenseGroups).flat().includes(pi.expenseTypeId)
    );

    // Calc values
    date.invoicedAmount = date.invoiceItems.reduce(
      (sum, item) => sum + item.units * item.pricePerUnit,
      0
    );
    totals.invoicedAmount += date.invoicedAmount;

    Object.keys(expenseGroups).forEach((group) => {
      date[group + "amount"] = sumArray(
        date[group].map((pi) => pi.centerAmount)
      );
      totals[group + "amount"] += date[group + "amount"];
    });

    date.propertyRelatedExpenses = sumArray(
      Object.keys(propertyRelatedGroups).map((group) => date[group + "amount"])
    );
    totals.propertyRelatedExpenses += date.propertyRelatedExpenses;

    date.generalExpenses = sumArray(
      Object.keys(generalExpensesGroups).map((group) => date[group + "amount"])
    );
    totals.generalExpenses += date.generalExpenses;

    date.ebitda =
      date.invoicedAmount - date.propertyRelatedExpenses - date.generalExpenses;
    totals.ebitda += date.ebitda;

    date.aic = sumArray(
      Object.keys(aicGroups).map((group) => date[group + "amount"])
    );
    totals.aic += date.aic;

    date.unleveredCashFlow = date.ebitda - date.aic;
    totals.unleveredCashFlow += date.unleveredCashFlow;
  });

  months = dates;

  let dataProps = [
    "year",
    "month",
    "invoicedAmount",
    ...Object.keys(generalExpensesGroups).map((k) => k + "amount"),
    "generalExpenses",
    ...Object.keys(propertyRelatedGroups).map((k) => k + "amount"),
    "propertyRelatedExpenses",
    "ebitda",
    ...Object.keys(aicGroups).map((k) => k + "amount"),
    "aic",
    "unleveredCashFlow",
  ];

  const getCsvHeaders = (dates) => {
    let headers = [];
    headers.push({ key: "name" });
    dates.forEach((date) => {
      headers.push({ key: date.year + "-" + date.month });
    });
    headers.push({ key: "total" });
    return headers;
  };

  const parseDataToCSV = (dates) => {
    let data = [];

    for (let prop of dataProps) {
      let line = {};

      line.name = t(prop);

      dates.forEach((date) => {
        line[date.year + "-" + date.month] = localeFormat(date[prop]);
      });

      line.total = localeFormat(totals[prop]);
      data.push(line);
    }
    return data;
  };

  let csvHeaders = getCsvHeaders(months);
  let dataParsed = parseDataToCSV(months);

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12}>
        <Grid item>
          <ButtonCSV data={dataParsed} headers={csvHeaders} />
        </Grid>
      </Grid>
      <Grid container item direction="row" wrap="nowrap">
        <Grid item xs="auto">
          <FirstColumn />
        </Grid>
        <Grid item xs overflow="auto">
          <Box display="flex" overflow={"auto"}>
            {dates.map((date) => (
              <Column data={date} />
            ))}
          </Box>
        </Grid>

        <Grid item xs="auto">
          <Column data={totals} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Column = ({ data }) => {
  return (
    <Box>
      <Stack width="110px">
        <Typography variant="body1" align="right">
          {data.year}
        </Typography>
        <Typography variant="body1" align="right">
          {data.month}
        </Typography>

        <Cell bold>{data.invoicedAmount}</Cell>

        {Object.keys(generalExpensesGroups).map((group) => (
          <Cell>{data[group + "amount"]}</Cell>
        ))}
        <Cell bold>{data.generalExpenses}</Cell>

        {Object.keys(propertyRelatedGroups).map((group) => (
          <Cell>{data[group + "amount"]}</Cell>
        ))}
        <Cell bold>{data.propertyRelatedExpenses}</Cell>

        <Cell bold>{data.ebitda}</Cell>

        {Object.keys(aicGroups).map((group) => (
          <Cell>{data[group + "amount"]}</Cell>
        ))}
        <Cell bold>{data.aic}</Cell>

        <Cell bold>{data.unleveredCashFlow}</Cell>
      </Stack>
    </Box>
  );
};

const FirstColumn = () => {
  const [t] = useTranslation("dashboardCenterReport");
  return (
    <Box>
      <Stack width="300px">
        <Typography variant="body1" align="right">
          {t("year")}
        </Typography>
        <Typography variant="body1" align="right">
          {t("month")}
        </Typography>

        <Cell bold>{t("invoicedAmount")}</Cell>

        {Object.keys(generalExpensesGroups).map((group) => (
          <Cell>{t(group)}</Cell>
        ))}
        <Cell bold>{t("generalExpenses")}</Cell>

        {Object.keys(propertyRelatedGroups).map((group) => (
          <Cell>{t(group)}</Cell>
        ))}
        <Cell bold>{t("propertyRelatedExpenses")}</Cell>

        <Cell bold>{t("ebitda")}</Cell>

        {Object.keys(aicGroups).map((group) => (
          <Cell>{t(group)}</Cell>
        ))}
        <Cell bold>{t("aic")}</Cell>

        <Cell bold>{t("unleveredCashFlow")}</Cell>
      </Stack>
    </Box>
  );
};

const Cell = ({ children, bold = false }) => {
  let value = children;
  const isNumber = !isNaN(Number(value));
  if (isNumber) value = localeFormat(value);

  return (
    <Typography
      padding={0.2}
      variant="body1"
      align={isNumber ? "right" : "left"}
      fontWeight={bold ? "bold" : undefined}
      borderBottom={"1px solid"}
    >
      {value}
    </Typography>
  );
};

export default PnLTab;
