import {
  ANNUAL_PERIODICITY_ID,
  BIANNUAL_PERIODICITY_ID,
  MONTHLY_PERIODICITY_ID,
  QUARTERLY_PERIODICITY_ID,
} from "../data/constants";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const today = () => {
  return formatDate(new Date());
};

export const daysDifference = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const daysDiff = Math.ceil(timeDiff / oneDay);
  return daysDiff;
};

export const thisMonth = () => {
  let date = new Date();
  let first = formatDate(date.setUTCDate(1));
  let last = formatDate(date.setUTCDate(daysOfMonth(date.getMonth() + 1)));
  return [first, last];
};

export const thisYear = () => {
  let date = new Date();

  date.setUTCDate(1);
  date.setUTCMonth(0);
  let first = formatDate(date);

  date.setUTCDate(31);
  date.setUTCMonth(11);
  let last = formatDate(date);

  return [first, last];
};

export const firstDateCurrentMonth = () => {
  const today = new Date();

  return formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
};

export const lastDateCurrentMonth = () => {
  const today = new Date();

  return formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
};

export const lastMonthDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 30);
  return formatDate(date);
};

export const lastYear = () => {
  let date = new Date();

  date.setUTCFullYear(date.getUTCFullYear() - 1);

  date.setUTCDate(1);
  date.setUTCMonth(0);
  let first = formatDate(date);

  date.setUTCDate(31);
  date.setUTCMonth(11);
  let last = formatDate(date);

  return [first, last];
};

export const formatDate = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let day = date.getDate();
  if (day < 10) day = "0" + day;

  return date.getFullYear() + "-" + month + "-" + day;
};

export const removeTime = (date) => {
  if (typeof date === "string") return date.split("T")[0];
  else return formatDate(date);
};

export const formatDateTime = (date) => {
  if (typeof date === "string") date = new Date(date);
  return (
    formatDate(date) +
    " " +
    String(date.getHours()).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0")
  );
};

export const formatDayMonthYear = (date) => {
  if (typeof date === "string") date = new Date(date);
  return (
    String(date.getDate()).padStart(2, "0") +
    "/" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "/" +
    date.getFullYear()
  );
};

export const daysOfMonth = (month) => {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 2:
      return 28;
    default:
      return 30;
  }
};

export const getMonthsFromPeriodicityId = (periodicityId) => {
  switch (periodicityId) {
    case MONTHLY_PERIODICITY_ID:
      return 1;
    case QUARTERLY_PERIODICITY_ID:
      return 3;
    case BIANNUAL_PERIODICITY_ID:
      return 6;
    case ANNUAL_PERIODICITY_ID:
      return 12;
    default:
      return 1;
  }
};

export const getMinDate = (dates) => {
  if (dates.length === 0) return;
  let min = dates?.reduce((a, b) => (a < b ? a : b));
  return min;
};

export const getAge = (date) => {
  if (!date) return null;
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const dateDiff = (date1, date2) => {
  if (!date1) return 0;
  const value1 = new Date(date1 || "");
  const value2 = new Date(date2 || "");

  const diffTime = Math.abs(value2 - value1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
