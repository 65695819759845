import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { colors as colorGenerator } from "../Components/ColorGenerator";

import { ChartContainer } from "./ChartContainer";
import { colors } from "../Components/ColorGenerator";
import { getDates, getMonths, sumArray } from "../../../../utils/chartUtils";

import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ClassIcon from "@mui/icons-material/Class";
import PaymentIcon from "@mui/icons-material/Payment";
import { NewChartContainer } from "./NewChartContainer";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { localeFormat } from "../../../../utils/format";
import {
  // Box states
  BLOCKED_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,

  // Expense types
  ELECTRICITY_EXPENSE_TYPE_ID,
  LIQUIDATION_EXPENSE_TYPE_ID,
  PHONE_EXPENSE_TYPE_ID,
  SUPPLY_EXPENSE_TYPE_ID,
  WATER_EXPENSE_TYPE_ID,

  // Payment methods
  CASH_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  CONSOLIDATION_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,

  // Periodicities
  MONTHLY_PERIODICITY_ID,
  QUARTERLY_PERIODICITY_ID,
  BIANNUAL_PERIODICITY_ID,
  ANNUAL_PERIODICITY_ID,

  // Charts index
  BAR,
  DOUGHNUT,
  LINE,
  PIE,
} from "../../../../data/constants";
import { getAge } from "../../../../utils/date";
import Select from "../../../global/inputs/Select";

const TOGGLE_BOXES = "boxes";
const TOGGLE_METERS = "meters";

const FREE_COLOR = colorGenerator.green;
const OCCUPIED_COLOR = colorGenerator.red;
const UNAVAILABLE_COLOR = "#DFDFDF";
const BLOCKED_COLOR = "#000000";
const BOOKED_COLOR = "#E8CA4D";
const TYPES = [
  { layer: "total" },
  { layer: "free" },
  { layer: "occupied" },
  { layer: "blocked" },
  { layer: "unavailable" },
  { layer: "booked" },
];

const dataGetters = {
  contracts: (items) => items.length,
};

const getChartValue = (selected, contracts, invoiced, meters) => {
  switch (selected) {
    case "contracts":
      return contracts;
    case "invoiced":
      return invoiced;
    case "meters":
      return meters;
    default:
      return [];
  }
};

export const HistoricalContractVariation = ({
  contracts,
  startDate,
  endDate,
}) => {
  const [t] = useTranslation("dashboard");
  const [selected, setSelected] = useState("contracts");

  const dates = getMonths(
    startDate
      ? startDate
      : new Date(Math.min(...contracts.map((c) => new Date(c.startDate)))),
    endDate ? endDate : Date.now()
  );

  const labels = dates.map((date) => date.year + "-" + date.month);

  let newMeters = new Array(dates.length).fill(0);
  let cancelledMeters = new Array(dates.length).fill(0);
  let differenceMeters = new Array(dates.length).fill(0);

  let newContracts = new Array(dates.length).fill(0);
  let cancelledContracts = new Array(dates.length).fill(0);
  let differenceContracts = new Array(dates.length).fill(0);

  let newInvoiced = new Array(dates.length).fill(0);
  let cancelledInvoiced = new Array(dates.length).fill(0);
  let differenceInvoiced = new Array(dates.length).fill(0);

  dates.forEach((date, index) => {
    // Move-ins
    const moveIns = contracts.filter(
      (c) =>
        new Date(c.startDate).getFullYear() === date.year &&
        new Date(c.startDate).getMonth() + 1 === date.month
    );
    newContracts[index] = moveIns.length;
    newMeters[index] = moveIns.reduce(
      (sum, contract) => sum + contract.meters,
      0
    );
    newInvoiced[index] = moveIns.reduce(
      (sum, contract) => sum + contract.meters * contract.pricePerMeter,
      0
    );

    // Move-outs
    const moveOuts = contracts.filter(
      (c) =>
        c.endDate !== null &&
        new Date(c.endDate).getFullYear() === date.year &&
        new Date(c.endDate).getMonth() + 1 === date.month
    );
    cancelledContracts[index] = -moveOuts.length;
    cancelledMeters[index] = -moveOuts.reduce(
      (sum, contract) => sum + contract.meters,
      0
    );
    cancelledInvoiced[index] = -moveOuts.reduce(
      (sum, contract) => sum + contract.meters * contract.pricePerMeter,
      0
    );

    // Difference: move-ins + move-outs
    differenceMeters[index] = newMeters[index] + cancelledMeters[index];
    differenceContracts[index] =
      newContracts[index] + cancelledContracts[index];
    differenceInvoiced[index] = newInvoiced[index] + cancelledInvoiced[index];
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: t("difference"),
        type: "line",
        data: getChartValue(
          selected,
          differenceContracts,
          differenceInvoiced,
          differenceMeters
        ),
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        tension: 0.3,
      },
      {
        label: t("moveIns"),
        data: getChartValue(selected, newContracts, newInvoiced, newMeters),
        backgroundColor: colors.green,
      },
      {
        label: t("moveOuts"),
        data: getChartValue(
          selected,
          cancelledContracts,
          cancelledInvoiced,
          cancelledMeters
        ),
        backgroundColor: colors.red,
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (dataPoint) => {
            const index = chartData.labels.indexOf(dataPoint.label);
            if (index === -1)
              return "An error has ocurred, please contact the developer";
            if (dataPoint.dataset.label === t("difference"))
              return `${t("contracts")}: ${differenceContracts[index]}\n ${t(
                "invoiced"
              )}: ${localeFormat(differenceInvoiced[index])}€\n ${t(
                "meters"
              )}: ${localeFormat(differenceMeters[index])}m²`;
            else if (dataPoint.dataset.label === t("moveIns"))
              return `${t("contracts")}: ${newContracts[index]}\n ${t(
                "invoiced"
              )}: ${localeFormat(newInvoiced[index])}€\n ${t(
                "meters"
              )}: ${localeFormat(newMeters[index])}m²`;
            else if (dataPoint.dataset.label === t("moveOuts"))
              return `${t("contracts")}: ${cancelledContracts[index]}\n ${t(
                "invoiced"
              )}: ${localeFormat(cancelledInvoiced[index])}€\n ${t(
                "meters"
              )}: ${localeFormat(cancelledMeters[index])}m²`;
            else return "An error has ocurred, please contact the developer";
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("moveInsAndMoveOuts")}
      chart={<Bar data={chartData} options={options} />}
    >
      <Grid item margin={2}>
        <ToggleButtonGroup exclusive value={selected}>
          <ToggleButton
            value={"contracts"}
            onClick={() => setSelected("contracts")}
          >
            <Tooltip title={t("contracts")}>
              <DescriptionIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value={"invoiced"}
            onClick={() => setSelected("invoiced")}
          >
            <Tooltip title={t("invoiced")}>
              <AttachMoneyIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={"meters"} onClick={() => setSelected("meters")}>
            <Tooltip title={t("meters")}>
              <SquareFootIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </ChartContainer>
  );
};

export const ExtendedHistoricalInvoicing = ({
  contracts,
  startDate,
  endDate,
  objective,
  invoiceItems,
}) => {
  const [t] = useTranslation("dashboard");
  const minDate = startDate
    ? startDate
    : new Date(Math.min(...contracts.map((c) => new Date(c.startDate))));
  const maxDate = endDate != "" ? endDate : new Date();

  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let difference = new Array(dates.length).fill(0);
  let invoiced = new Array(dates.length).fill(0);

  dates.forEach((date, index) => {
    // New contracts
    let newM = contracts
      .filter(
        (c) =>
          new Date(c.startDate).getFullYear() === date.year &&
          new Date(c.startDate).getMonth() + 1 === date.month
      )
      .reduce(
        (sum, contract) => sum + contract.meters * contract.pricePerMeter,
        0
      );

    // Cancellations
    let oldM = contracts
      .filter(
        (c) =>
          c.endDate !== null &&
          new Date(c.endDate).getFullYear() === date.year &&
          new Date(c.endDate).getMonth() + 1 === date.month
      )
      .reduce(
        (sum, contract) => sum + contract.meters * contract.pricePerMeter,
        0
      );
    difference[index] = newM - oldM;
  });

  labels.forEach((label, index) => {
    let date = new Date(label);
    let items = invoiceItems.filter(
      (item) =>
        new Date(item.Invoice.issueDate).getFullYear() === date.getFullYear() &&
        new Date(item.Invoice.issueDate).getMonth() === date.getMonth()
    );
    invoiced[index] = sumArray(
      items.map((item) => item.units * item.pricePerUnit)
    );
  });

  let accumulated = [difference[0]];
  // Calct accumulated
  let acc = difference[0];
  for (let i = 1; i < dates.length; i++) {
    acc += difference[i];
    accumulated.push(acc);
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: t("theoreticalInvoicing"),
        data: accumulated,
        type: "line",
        backgroundColor: colors.primary,
        borderColor: colors.primary,
      },
      {
        label: t("realInvoicing"),
        data: invoiced,
        backgroundColor: colors.green,
        borderColor: colors.green,
      },
    ],
  };

  objective &&
    chartData.datasets.push({
      label: t("objective"),
      data: new Array(dates.length).fill(objective),
      type: "line",
      borderColor: colors.yellow,
      backgroundColor: colors.yellow,
    });

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  };

  return (
    <ChartContainer
      title={t("invoicing") + " €"}
      subtitle={`${t("currentInvoicing")}: ${Number(
        accumulated[accumulated.length - 1]?.toFixed(2) || 0
      ).toLocaleString("es-CL")} €`}
      chart={<Bar data={chartData} options={options} />}
    ></ChartContainer>
  );
};

export const HistoricalPricePerMeter = ({
  contracts,
  startDate,
  endDate,
  objective,
}) => {
  const [t] = useTranslation("dashboard");
  const minDate = startDate
    ? startDate
    : new Date(Math.min(...contracts.map((c) => new Date(c.startDate))));
  const maxDate = endDate ? endDate : new Date();

  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let differenceInvoicing = new Array(dates.length).fill(0);
  let differenceMeters = new Array(dates.length).fill(0);

  dates.forEach((date, index) => {
    // New contracts
    let newContracts = contracts.filter(
      (c) =>
        new Date(c.startDate).getFullYear() === date.year &&
        new Date(c.startDate).getMonth() + 1 === date.month
    );
    let newinvoicing = newContracts.reduce(
      (sum, contract) => sum + contract.meters * contract.pricePerMeter,
      0
    );

    let newMeters = newContracts.reduce(
      (sum, contract) => sum + contract.meters,
      0
    );

    // Cancellations
    let oldContracts = contracts.filter(
      (c) =>
        c.endDate !== null &&
        new Date(c.endDate).getFullYear() === date.year &&
        new Date(c.endDate).getMonth() + 1 === date.month
    );
    let oldinvoicing = oldContracts.reduce(
      (sum, contract) => sum + contract.meters * contract.pricePerMeter,
      0
    );
    let oldMeters = oldContracts.reduce(
      (sum, contract) => sum + contract.meters,
      0
    );
    differenceInvoicing[index] = newinvoicing - oldinvoicing;
    differenceMeters[index] = newMeters - oldMeters;
  });

  let accumulatedInvoicing = [differenceInvoicing[0]];
  let accumulatedMeters = [differenceMeters[0]];
  // Calct accumulated
  let accI = differenceInvoicing[0];
  let accM = differenceMeters[0];
  let pricePerMeter = [accI / accM];

  for (let i = 1; i < dates.length; i++) {
    accI += differenceInvoicing[i];
    accM += differenceMeters[i];
    accumulatedInvoicing.push(accI);
    accumulatedMeters.push(accM);
    pricePerMeter.push(accumulatedInvoicing[i] / accumulatedMeters[i]);
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: t("averagePrice"),
        data: pricePerMeter,
        borderColor: colors.primary,
        backgroundColor: colors.primary,
      },
    ],
  };

  objective &&
    chartData.datasets.push({
      label: t("objective"),
      data: new Array(dates.length).fill(objective),
      borderColor: colors.yellow,
      backgroundColor: colors.yellow,
    });

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("pricePerMeter") + " €"}
      subtitle={`${t("currentPrice")}: ${Number(
        pricePerMeter[pricePerMeter.length - 1]?.toFixed(2) || 0
      ).toLocaleString("es-CL")} €`}
      chart={<Line data={chartData} options={options} />}
    ></ChartContainer>
  );
};

export const HistoricalOccupancy = ({
  contracts,
  startDate,
  endDate,
  boxes,
  objective,
}) => {
  const [t] = useTranslation("dashboard");
  const minDate = startDate
    ? startDate
    : new Date(Math.min(...contracts.map((c) => new Date(c.startDate))));
  const maxDate = endDate ? endDate : new Date();

  const dates = getMonths(minDate, maxDate);

  const totalMeters = boxes.reduce((sum, box) => (sum += box.meters), 0);

  const labels = dates.map((date) => date.year + "-" + date.month);

  let differenceMeters = new Array(labels.length).fill(0);
  let objectiveData = new Array(labels.length).fill(objective);

  dates.forEach((date, index) => {
    // Move-ins
    let moveIns = contracts.filter(
      (c) =>
        new Date(c.startDate).getFullYear() === date.year &&
        new Date(c.startDate).getMonth() + 1 === date.month
    );

    let newMeters = moveIns.reduce(
      (sum, contract) => sum + contract.Box.meters,
      0
    );

    // Move-outs
    let moveOuts = contracts.filter(
      (c) =>
        c.endDate !== null &&
        new Date(c.endDate).getFullYear() === date.year &&
        new Date(c.endDate).getMonth() + 1 === date.month
    );
    let oldMeters = moveOuts.reduce(
      (sum, contract) => sum + contract.Box.meters,
      0
    );
    differenceMeters[index] = newMeters - oldMeters;
  });

  // Calculate meters accumulated
  let accumulatedMeters = [];
  let accM = 0;
  for (let i = 0; i < dates.length; i++) {
    accM += differenceMeters[i];
    accumulatedMeters.push((accM / totalMeters) * 100);
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: t("occupation"),
        data: accumulatedMeters,
        borderColor: colors.primary,
        backgroundColor: colors.primary,
      },
    ],
  };

  // Add objective if specified
  objective &&
    chartData.datasets.push({
      label: t("objective"),
      data: objectiveData,
      borderColor: colors.yellow,
      backgroundColor: colors.yellow,
    });

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("occupation") + " %"}
      subtitle={`${t("currentOcupation")}: ${Number(
        accumulatedMeters[accumulatedMeters.length - 1]?.toFixed(2) || 0
      ).toLocaleString("es-CL")} %`}
      chart={<Line data={chartData} options={options} />}
    ></ChartContainer>
  );
};

export const BoxDistribution = ({
  useClusters,
  clusters,
  boxes,
  contracts,
}) => {
  const [t] = useTranslation("dashboard");
  contracts = contracts.filter((c) => c.endDate === null);

  //Necessary data:
  let labels;
  let totalBox;
  let ocupiedBoxes;
  let freeBoxes;

  const updateInfo = (size, state) => {
    state === 0 ? freeBoxes[size]++ : ocupiedBoxes[size]++;
    totalBox[size]++;
  };

  if (useClusters) {
    //Initialize info:
    labels = [];
    totalBox = [];
    ocupiedBoxes = [];
    freeBoxes = [];

    //Prepare data:
    clusters?.forEach((cluster) => {
      labels.push(cluster.name);

      const totalBoxes = boxes.filter(
        (box) => box.meters >= cluster.minSize && box.meters <= cluster.maxSize
      );
      const totalOcupied = totalBoxes.filter((box) => box.state === 1);
      const totalFree = totalBoxes.filter((box) => box.state === 0);

      totalBox.push(totalBoxes.length);
      ocupiedBoxes.push(totalOcupied.length);
      freeBoxes.push(totalFree.length);
    });
  } else {
    //Initialize info:
    labels = [
      "0 - 1 m",
      "1 - 2 m",
      "2 - 3 m",
      "3 - 4 m",
      "5 - 6 m",
      "7 - 8 m",
      "7 - 9 m",
      "9 - 10 m",
      "10 > m",
    ];
    totalBox = [0, 0, 0, 0, 0, 0, 0];
    ocupiedBoxes = [0, 0, 0, 0, 0, 0, 0];
    freeBoxes = [0, 0, 0, 0, 0, 0, 0];

    //Prepare data:
    boxes.forEach((box) => {
      box.meters >= 0 && box.meters <= 1 && updateInfo(0, box.state);
      box.meters >= 1 && box.meters <= 2 && updateInfo(1, box.state);
      box.meters >= 3 && box.meters <= 4 && updateInfo(2, box.state);
      box.meters >= 5 && box.meters <= 6 && updateInfo(3, box.state);
      box.meters >= 7 && box.meters <= 8 && updateInfo(4, box.state);
      box.meters >= 9 && box.meters <= 10 && updateInfo(5, box.state);
      box.meters > 10 && updateInfo(6, box.state);
    });
  }

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: t("totalBoxes"),
        data: totalBox,
        borderColor: colors.primary,
        backgroundColor: colors.primary,
        stack: "Stack 0",
      },
      {
        label: t("ocupiedBoxes"),
        data: ocupiedBoxes,
        borderColor: colors.red,
        backgroundColor: colors.red,
        stack: "Stack 1",
      },
      {
        label: t("freeBoxes"),
        data: freeBoxes,
        borderColor: colors.green,
        backgroundColor: colors.green,
        stack: "Stack 1",
      },
    ],
  };

  return (
    <ChartContainer
      title={t("boxDistribution")}
      chart={<Bar data={data} options={options} />}
    ></ChartContainer>
  );
};

export const CenterOccupancyDetails = ({ boxes, contracts }) => {
  const [t] = useTranslation("dashboard");
  const [toggleValue, setToggleValue] = useState(TOGGLE_BOXES);

  // Get active contracts
  contracts = contracts.filter((c) => c.state === 1);

  /**
   * Functions to sum the number of boxes or number of meters depending
   * on toggleValue
   */
  let calcCallback =
    toggleValue === TOGGLE_BOXES
      ? (total) => (total += 1)
      : (total, box) => (total += box.meters);

  const total = boxes.reduce(calcCallback, 0);
  const free = boxes
    .filter((box) => box.state === FREE_BOX_STATE_ID)
    .reduce(calcCallback, 0);
  const occupied = boxes
    .filter((box) => box.state === OCCUPIED_BOX_STATE_ID)
    .reduce(calcCallback, 0);
  const unavailable = boxes
    .filter((box) => box.state === UNAVAILABLE_BOX_STATE_ID)
    .reduce(calcCallback, 0);
  const blocked = boxes
    .filter((box) => box.state === BLOCKED_BOX_STATE_ID)
    .reduce(calcCallback, 0);

  const booked = boxes
    .filter((box) => box.state === BOOKED_BOX_STATE_ID)
    .reduce(calcCallback, 0);

  const values = { total, occupied, free, unavailable, blocked, booked };

  const getPercentage = (type) => {
    if (!values["total"] || !values[type]) return 0;
    return ((values[type] * 100) / values["total"]).toFixed(1);
  };

  const data = {
    labels: [
      t("free"),
      t("occupied"),
      t("unavailable"),
      t("blocked"),
      t("booked"),
    ],
    datasets: [
      {
        data: [
          values.free,
          values.occupied,
          values.unavailable,
          values.blocked,
          values.booked,
        ],
        backgroundColor: [
          FREE_COLOR,
          OCCUPIED_COLOR,
          UNAVAILABLE_COLOR,
          BLOCKED_COLOR,
          BOOKED_COLOR,
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: t("occupationState"),
    },
  };

  return (
    <ChartContainer title={t("occupancy")} chart={<></>}>
      <>
        <Grid item xs={12} marginBottom={2}>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={toggleValue}
            exclusive
            onChange={(e) => {
              setToggleValue(e.target.value);
            }}
            aria-label="Platform"
          >
            <ToggleButton value={TOGGLE_BOXES}>{t("boxes")}</ToggleButton>
            <ToggleButton value={TOGGLE_METERS}>{t("meters")}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {TYPES.map((type) => (
                <Grid item xs={12}>
                  <Paper elevation={2}>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      padding={2}
                    >
                      <Grid item>
                        <Typography variant="body1">
                          {t(type.layer) + ": "}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {localeFormat(values[type.layer]) +
                            (toggleValue === TOGGLE_BOXES ? "" : " m²") +
                            (type.layer !== "total"
                              ? " (" +
                                localeFormat(getPercentage(type.layer)) +
                                " %)"
                              : "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Doughnut data={data} options={options} />
          </Grid>
        </Grid>
      </>
    </ChartContainer>
  );
};

export const CustomerAgeDistribution = ({ customers }) => {
  const [t] = useTranslation("dashboard");
  const labels = [
    " > 65",
    "61 - 65",
    "56 - 60",
    "51 - 55",
    "46 - 50",
    "41 - 45",
    "36 - 40",
    "31 - 35",
    "26 - 30",
    "21 - 25",
    " < 20",
  ];

  const customerData = customers
    .filter((c) => c.birthdate !== null)
    .map((customer) => ({
      name: customer.name,
      gender: customer.gender,
      age: getAge(customer.birthdate),
    }));

  const customerCounts = labels.map((label) => {
    if (label === " < 20") {
      return customerData.filter((customer) => customer.age <= 20).length;
    } else if (label === " > 65") {
      return customerData.filter((customer) => customer.age > 65).length;
    } else {
      const [min, max] = label.split(" - ");
      return customerData.filter((customer) => {
        const age = customer.age;
        return age >= parseInt(min, 10) && age <= parseInt(max, 10);
      }).length;
    }
  });

  const mansCounts = labels.map((label) => {
    if (label === " < 20") {
      return customerData
        .filter((c) => Number(c.gender) === 1)
        .filter((customer) => customer.age <= 20).length;
    } else if (label === " > 65") {
      return customerData
        .filter((c) => Number(c.gender) === 1)
        .filter((customer) => customer.age > 65).length;
    } else {
      const [min, max] = label.split(" - ");
      return customerData
        .filter((c) => Number(c.gender) === 1)
        .filter((customer) => {
          const age = customer.age;
          return age >= parseInt(min, 10) && age <= parseInt(max, 10);
        }).length;
    }
  });

  const womansCounts = labels.map((label) => {
    if (label === " < 20") {
      return customerData
        .filter((c) => Number(c.gender) === 2)
        .filter((customer) => customer.age <= 20).length;
    } else if (label === " > 65") {
      return customerData
        .filter((c) => Number(c.gender) === 2)
        .filter((customer) => customer.age > 65).length;
    } else {
      const [min, max] = label.split(" - ");
      return customerData
        .filter((c) => Number(c.gender) === 2)
        .filter((customer) => {
          const age = customer.age;
          return age >= parseInt(min, 10) && age <= parseInt(max, 10);
        }).length;
    }
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("allCustomers"),
        data: customerCounts,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 0",
      },
      {
        label: t("men"),
        data: mansCounts,
        borderColor: colors.primary,
        backgroundColor: colors.primary,
        hidden: true,
        stack: "Stack 1",
      },
      {
        label: t("women"),
        data: womansCounts,
        borderColor: colors.red,
        backgroundColor: colors.red,
        hidden: true,
        stack: "Stack 1",
      },
    ],
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <ChartContainer
      title={t("ageDistribution")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid
        item
        container
        xs={12}
        marginLeft={2}
        marginTop={1}
        marginBottom={2}
      >
        <Grid item xs={3}>
          <Typography variant="body1" fontWeight="bold">
            {t("averageAge")}:{" "}
            {customerData.length
              ? (
                  customerData.reduce(
                    (total, cust) => total + Number(cust.age),
                    0
                  ) / customerData.length
                ).toFixed()
              : 0}{" "}
            {t("years")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" fontWeight="bold">
            {t("calculationOnThe")}{" "}
            {customers.length
              ? ((customerData.length * 100) / customers.length).toFixed()
              : 100}
            % {t("ofCustomers")}
          </Typography>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const CustomerGenderDetails = ({ customers }) => {
  const [t] = useTranslation("dashboard");

  const companyData = customers?.filter(
    (customer) => Number(customer.customerTypeId) === 2
  );

  const individualData = customers
    ?.filter((customer) => Number(customer.customerTypeId) === 1)
    .map((customer) => ({
      name: customer.name,
      gender: Number(customer.gender),
    }));

  const countOfMen = individualData?.filter((c) => c.gender === 1).length;
  const countOfWomen = individualData?.filter((c) => c.gender === 2).length;

  const others = individualData?.filter((c) => c.gender === 0).length;

  const data = {
    labels: [t("men"), t("women"), t("company"), t("notSpecified")],
    datasets: [
      {
        data: [countOfMen, countOfWomen, companyData?.length, others],
        backgroundColor: [
          colors.primary,
          colors.red,
          colors.secondary,
          colors.green,
        ],
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: t("occupationState"),
    },
  };

  return (
    <ChartContainer title={t("customerTypology")} chart={<></>}>
      <Grid container spacing={1} marginTop={1}>
        <Grid item xs={12} marginBottom={1}>
          <Typography variant="body1">
            {t("the")}{" "}
            {individualData.length
              ? localeFormat(
                  (individualData.filter((e) => e.gender !== 0).length * 100) /
                    individualData.length
                )
              : 100}
            {"% "}
            {t("individualClientsHaveGenderAssigned")}
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid item xs={6}>
            <Doughnut data={data} options={options} />
          </Grid>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const HistoricalExpenses = ({ invoices }) => {
  const [t] = useTranslation("dashboard");

  // Get months
  const invoicesDates = invoices.map((invoice) => invoice.date).sort();
  const minDate = invoicesDates[0];
  const maxDate = invoicesDates[invoicesDates.length - 1];
  let dates = getMonths(new Date(minDate), new Date(maxDate));

  // Group by months
  dates = dates.map((date) => {
    date.invoices = invoices.filter(
      (invoice) =>
        new Date(invoice.date).getFullYear() === date.year &&
        new Date(invoice.date).getMonth() + 1 === date.month
    );
    date.amount = date.invoices.reduce(
      (sum, invoice) => sum + invoice.centerAmount,
      0
    );
    return date;
  });

  // Calc data
  const labels = dates.map((date) => date.year + "-" + date.month);
  const monthsAmount = dates.map((date) => date.amount);

  const data = {
    labels,
    datasets: [
      {
        label: t("expenses"),
        data: monthsAmount,
        backgroundColor: colors.red,
      },
    ],
  };
  const options = {};

  return (
    <ChartContainer
      chart={<Bar data={data} options={options} />}
    ></ChartContainer>
  );
};

export const SupplyExpenses = ({ invoices }) => {
  const [t] = useTranslation("dashboard");
  const supplyExpenses = [
    SUPPLY_EXPENSE_TYPE_ID,
    WATER_EXPENSE_TYPE_ID,
    ELECTRICITY_EXPENSE_TYPE_ID,
    PHONE_EXPENSE_TYPE_ID,
  ];
  const filterFunction = (toFilter, filter) =>
    toFilter.filter((i) => i.expenseTypeId === filter);
  const centerAmountReduce = (toReduce) =>
    toReduce.reduce((sum, invoice) => sum + invoice.centerAmount, 0);
  const filteredInvoices = invoices.filter((invoice) =>
    supplyExpenses.includes(invoice.expenseTypeId)
  );

  // Get months
  const invoicesDates = filteredInvoices.map((invoice) => invoice.date).sort();
  const minDate = invoicesDates[0];
  const maxDate = invoicesDates[invoicesDates.length - 1];
  let dates = getMonths(new Date(minDate), new Date(maxDate));

  // Group by months
  dates = dates.map((date) => {
    date.invoices = filteredInvoices.filter(
      (invoice) =>
        new Date(invoice.date).getFullYear() === date.year &&
        new Date(invoice.date).getMonth() + 1 === date.month
    );
    return date;
  });

  // Calc data
  let waterExpenses = [];
  let electricityExpenses = [];
  let phoneExpenses = [];

  const labels = dates.map((date) => date.year + "-" + date.month);

  dates.forEach((date) => {
    const tmpWaterExpenses = filterFunction(
      date.invoices,
      WATER_EXPENSE_TYPE_ID
    );
    const tmpElectricityExpenses = filterFunction(
      date.invoices,
      ELECTRICITY_EXPENSE_TYPE_ID
    );
    const tmpPhoneExpenses = filterFunction(
      date.invoices,
      PHONE_EXPENSE_TYPE_ID
    );
    waterExpenses.push(centerAmountReduce(tmpWaterExpenses));
    electricityExpenses.push(centerAmountReduce(tmpElectricityExpenses));
    phoneExpenses.push(centerAmountReduce(tmpPhoneExpenses));
  });

  const data = {
    labels,
    datasets: [
      {
        label: t("electricity"),
        data: electricityExpenses,
        backgroundColor: "#E8CA4D",
      },
      {
        label: t("water"),
        data: waterExpenses,
        backgroundColor: colors.secondary,
      },

      {
        label: t("phone"),
        data: phoneExpenses,
        backgroundColor: colors.red,
      },
    ],
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("supplyExpenses")}
      chart={<Bar data={data} options={options} />}
    ></ChartContainer>
  );
};

export const HistoricalInvoiceItems = ({
  invoiceItems,
  series,
  paymentMethods,
  merchantableTypes,
}) => {
  const [t] = useTranslation("dashboard");
  const [selected, setSelected] = useState("serie");
  const parsedInvoiceItems = invoiceItems.map((item) => ({
    ...item,
    serie: {
      id: item.Invoice.InvoiceSerie.id,
      value: item.Invoice.InvoiceSerie.name,
    },
    paymentMethod: {
      id: item.Invoice.PaymentMethod.id,
      value: item.Invoice.PaymentMethod.name,
    },
    merchantableType:
      item.Merchantable && item.Merchantable.MerchantableType
        ? {
            id: item.Merchantable.MerchantableType.id,
            value: item.Merchantable.MerchantableType.name,
          }
        : {
            id: merchantableTypes[merchantableTypes.length - 1].id,
            value: merchantableTypes[merchantableTypes.length - 1].name,
          },
    billingError: { value: item.Invoice.billingError },
    notBillingError: { value: !item.Invoice.billingError },
  }));

  const special = invoiceItems.find(
    (item) => !item.Merchantable || !item.Merchantable.MerchantableType
  );

  const functionToParse = (item) => ({
    id: item.id,
    name: item.name,
    value: item.name,
  });
  const invoiceItemsDataSets = {
    serie: series.map(functionToParse),
    paymentMethod: paymentMethods.map(functionToParse),
    merchantableType: merchantableTypes.map(functionToParse),
    billingError: [
      { id: 1, name: t("notBillingError"), value: false },
      { id: 2, name: t("billingError"), value: true },
    ],
  };

  const dataSetsColors = [
    colors.primary,
    colors.secondary,
    colors.red,
    colors.brown,
    colors.yellow,
    colors.orange,
    colors.purple,
    colors.blue,
    colors.green,
    colors.turquoise,
  ];

  const dates = getMonths(
    new Date(
      Math.min(...parsedInvoiceItems.map((c) => new Date(c.Invoice.issueDate)))
    ),
    Date.now()
  );

  const labels = dates.map((date) => date.year + "-" + date.month);
  let dataSets = [];

  const dataSetsData = {};

  // Get the data for each data set
  const dataSetsOptions = invoiceItemsDataSets[selected];

  // Initialize data sets
  dataSetsOptions.forEach((option) => {
    dataSetsData[option.name] = new Array(dates.length).fill(0);
  });

  dates.forEach((date, index) => {
    // Get the invoice items of the month
    const monthInvoiceItems = parsedInvoiceItems.filter(
      (item) =>
        new Date(item.Invoice.issueDate).getFullYear() === date.year &&
        new Date(item.Invoice.issueDate).getMonth() + 1 === date.month
    );

    // Fill data sets
    dataSetsOptions.forEach((option) => {
      dataSetsData[option.name][index] = monthInvoiceItems
        .filter((item) => item[selected].value === option.value)
        .reduce((sum, item) => sum + item.units * item.pricePerUnit, 0);
    });
  });

  dataSetsOptions.forEach((option) => {
    dataSets.push({
      label: option.name,
      data: dataSetsData[option.name],
      backgroundColor:
        dataSetsColors[
          dataSets.length > dataSetsColors.length
            ? dataSets.length % dataSetsColors.length
            : dataSets.length
        ],
    });
  });

  const chartData = {
    labels,
    datasets: dataSets,
  };

  const options = {
    elements: { point: { radius: 0 } },
    aspectRatio: 3,
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("invoiceItemsReport")}
      chart={<Bar data={chartData} options={options} />}
    >
      <Grid item margin={2}>
        <ToggleButtonGroup size="small" exclusive value={selected}>
          <ToggleButton value={"serie"} onClick={() => setSelected("serie")}>
            <Tooltip title={t("serie")}>
              <ClassIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value={"paymentMethod"}
            onClick={() => setSelected("paymentMethod")}
          >
            <Tooltip title={t("paymentMethod")}>
              <PaymentIcon />
            </Tooltip>
          </ToggleButton>

          <ToggleButton
            value={"merchantableType"}
            onClick={() => setSelected("merchantableType")}
          >
            <Tooltip title={t("merchantableType")}>
              <ShoppingBasketIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value={"billingError"}
            onClick={() => setSelected("billingError")}
          >
            <Tooltip title={t("billingError")}>
              <ReportGmailerrorredIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </ChartContainer>
  );
};

export const SerieDoughnut = ({ invoiceItems, series }) => {
  const [t] = useTranslation("dashboard");

  const parsedInvoiceItems = invoiceItems.map((item) => ({
    ...item,
    serie: item.Invoice.InvoiceSerie.name,
    amount: item.units * item.pricePerUnit,
  }));
  const labels = series.map((serie) => serie.name);
  const totalAmount = parsedInvoiceItems.reduce(
    (sum, item) => sum + Math.abs(item.amount),
    0
  );

  let dataSetsData = [];
  series.forEach((serie) => {
    dataSetsData.push(
      parsedInvoiceItems
        .filter((item) => item.serie === serie.name)
        .reduce((sum, item) => sum + Math.abs(item.amount), 0)
    );
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetsData,
        backgroundColor: [
          colors.primary,
          colors.secondary,
          colors.red,
          colors.brown,
          colors.yellow,
          colors.orange,
          colors.purple,
          colors.blue,
          colors.green,
        ],
      },
    ],
  };
  const options = {
    responsive: true,

    title: {
      display: true,
      text: t("invoicingBySerie"),
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(
              Math.abs(value.parsed)
            )}€ (${localeFormat(
              (Math.abs(value.parsed) / totalAmount) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("invoicingBySerie")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};

export const PaymentMethodDoughnut = ({ invoiceItems, paymentMethods }) => {
  const [t] = useTranslation("dashboard");

  const parsedInvoiceItems = invoiceItems.map((item) => ({
    ...item,
    paymentMethod: item.Invoice.PaymentMethod.name,
    amount: item.units * item.pricePerUnit,
  }));
  const labels = paymentMethods.map((method) => method.name);
  const totalAmount = parsedInvoiceItems.reduce(
    (sum, item) => sum + Math.abs(item.amount),
    0
  );

  let dataSetsData = [];
  paymentMethods.forEach((method) => {
    dataSetsData.push(
      parsedInvoiceItems
        .filter((item) => item.paymentMethod === method.name)
        .reduce((sum, item) => sum + Math.abs(item.amount), 0)
    );
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetsData,
        backgroundColor: [
          colors.primary,
          colors.secondary,
          colors.red,
          colors.brown,
          colors.yellow,
          colors.orange,
          colors.purple,
          colors.blue,
          colors.green,
        ],
      },
    ],
  };
  const options = {
    responsive: true,
    title: {
      display: true,
      text: t("invoicingByPaymentMethod"),
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(
              Math.abs(value.parsed)
            )}€ (${localeFormat(
              (Math.abs(value.parsed) / totalAmount) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("invoicingByPaymentMethod")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};

export const MerchantableTypeDoughnut = ({
  invoiceItems,
  merchantableTypes,
}) => {
  const [t] = useTranslation("dashboard");

  const parsedInvoiceItems = invoiceItems.map((item) => ({
    ...item,
    merchantableType:
      item.Merchantable && item.Merchantable.MerchantableType
        ? item.Merchantable.MerchantableType.name
        : merchantableTypes[merchantableTypes.length - 1].name,
    amount: item.units * item.pricePerUnit,
  }));
  const labels = merchantableTypes.map((type) => type.name);
  const totalAmount = parsedInvoiceItems.reduce(
    (sum, item) => sum + Math.abs(item.amount),
    0
  );

  let dataSetsData = [];
  merchantableTypes.forEach((type) => {
    dataSetsData.push(
      parsedInvoiceItems
        .filter((item) => item.merchantableType === type.name)
        .reduce((sum, item) => sum + Math.abs(item.amount), 0)
    );
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetsData,
        backgroundColor: [
          colors.primary,
          colors.secondary,
          colors.red,
          colors.brown,
          colors.yellow,
          colors.orange,
          colors.purple,
          colors.blue,
          colors.green,
          colors.turquoise,
        ],
      },
    ],
  };
  const options = {
    responsive: true,
    title: {
      display: true,
      text: t("invoicingByMerchantableType"),
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(
              Math.abs(value.parsed)
            )}€ (${localeFormat(
              (Math.abs(value.parsed) / totalAmount) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("invoicingByMerchantableType")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};

export const ExpensesTypeDoughnut = ({ invoices }) => {
  const [t] = useTranslation("dashboard");

  const labels = [];
  const dataSetData = [];
  const dataSetColors = [
    colors.primary,
    colors.secondary,
    colors.red,
    colors.brown,
    colors.yellow,
    colors.orange,
    colors.purple,
    colors.blue,
    colors.green,
  ];

  invoices.forEach((invoice) => {
    if (!labels.includes(invoice.ExpenseType.name)) {
      labels.push(invoice.ExpenseType.name);
    }
  });

  labels.forEach((label) => {
    const data = invoices
      .filter((invoice) => invoice.ExpenseType.name === label)
      .reduce((sum, invoice) => sum + Math.abs(invoice.centerAmount), 0);
    dataSetData.push(data);
  });

  const totalAmount = invoices.reduce(
    (sum, invoice) => sum + Math.abs(invoice.centerAmount),
    0
  );

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetData,
        backgroundColor: dataSetColors,
      },
    ],
  };
  const options = {
    aspectRatio: 4,
    responsive: true,
    title: {
      display: true,
      text: t("expensesTypes"),
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(value.parsed)}€ (${localeFormat(
              (value.parsed / totalAmount) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("expensesTypes")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};

export const HistoricalBillingError = ({ invoiceItems }) => {
  const [t] = useTranslation("dashboard");
  const minDate = new Date(
    Math.min(...invoiceItems.map((i) => new Date(i.Invoice.issueDate)))
  );
  const maxDate = new Date();

  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let billingError = new Array(dates.length).fill(0);

  labels.forEach((label, index) => {
    let date = new Date(label);
    let items = invoiceItems
      .filter(
        (item) =>
          new Date(item.Invoice.issueDate).getFullYear() ===
            date.getFullYear() &&
          new Date(item.Invoice.issueDate).getMonth() === date.getMonth()
      )
      .filter((item) => item.Invoice.billingError);
    billingError[index] = sumArray(
      items.map((item) => item.units * item.pricePerUnit)
    );
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: t("billingError"),
        data: billingError,
        backgroundColor: colors.red,
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("billingErrorHistory")}
      chart={<Bar data={chartData} options={options} />}
    />
  );
};

export const HistoricalNonPayments = ({ nonPayments, recuperations }) => {
  const [t] = useTranslation("dashboard");

  const minDate = new Date(
    Math.min(
      ...nonPayments.map((i) => new Date(i.date)),
      ...recuperations.map((i) => new Date(i.date))
    )
  );
  const maxDate = new Date();
  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let nonPaymentsData = new Array(dates.length).fill(0);
  let recuperationsData = new Array(dates.length).fill(0);

  labels.forEach((label, index) => {
    let date = new Date(label);
    let nonPaymentsItems = nonPayments.filter(
      (item) =>
        new Date(item.date).getFullYear() === date.getFullYear() &&
        new Date(item.date).getMonth() === date.getMonth()
    );
    let recuperationsItems = recuperations.filter(
      (item) =>
        new Date(item.date).getFullYear() === date.getFullYear() &&
        new Date(item.date).getMonth() === date.getMonth()
    );
    nonPaymentsData[index] = sumArray(
      nonPaymentsItems.map((item) => item.amount)
    );
    recuperationsData[index] = sumArray(
      recuperationsItems.map((item) => item.amount)
    );
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: t("nonPayments"),
        data: nonPaymentsData,
        borderColor: colors.red,
        backgroundColor: colors.red,
        stack: "Stack 0",
      },
      {
        label: t("recuperations"),
        data: recuperationsData,
        borderColor: colors.green,
        backgroundColor: colors.green,
        stack: "Stack 1",
      },
    ],
  };

  const options = {
    aspectRatio: 4,
    responsive: true,
  };

  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: t("totalBoxes"),
  //       data: totalBox,
  //       borderColor: colors.primary,
  //       backgroundColor: colors.primary,
  //       stack: "Stack 0",
  //     },
  //     {
  //       label: t("ocupiedBoxes"),
  //       data: ocupiedBoxes,
  //       borderColor: colors.red,
  //       backgroundColor: colors.red,
  //       stack: "Stack 1",
  //     },
  //     {
  //       label: t("freeBoxes"),
  //       data: freeBoxes,
  //       borderColor: colors.green,
  //       backgroundColor: colors.green,
  //       stack: "Stack 1",
  //     },
  //   ],
  // };

  return (
    <ChartContainer
      title={t("nonPayments")}
      chart={<Bar data={chartData} options={options} />}
    />
  );
};

export const HistoricalCenterReceipts = ({ receipts }) => {
  const [t] = useTranslation("dashboard");

  const minDate = new Date(
    Math.min(...receipts.map((i) => new Date(i.dueDate)))
  );
  const maxDate = new Date();
  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let returnedReceipts = new Array(dates.length).fill(0);
  let paidReceipts = new Array(dates.length).fill(0);
  let returnPercentage = new Array(dates.length).fill(0);
  let returnFee = new Array(dates.length).fill(0);
  let feeOverReturned = new Array(dates.length).fill(0);
  let feeOverTotal = new Array(dates.length).fill(0);

  labels.forEach((label, index) => {
    let date = new Date(label);
    let receiptsItems = receipts.filter(
      (item) =>
        new Date(item.dueDate).getFullYear() === date.getFullYear() &&
        new Date(item.dueDate).getMonth() === date.getMonth()
    );
    returnedReceipts[index] = sumArray(
      receiptsItems
        .filter((r) => r.returnedAt !== null)
        .map((item) => item.amount)
    );
    paidReceipts[index] = sumArray(
      receiptsItems
        .filter((r) => r.returnedAt === null)
        .map((item) => item.amount)
    );

    returnPercentage[index] =
      (returnedReceipts[index] /
        (paidReceipts[index] + returnedReceipts[index])) *
      100;

    returnFee[index] = sumArray(
      receiptsItems.map((item) =>
        item.commissionCost ? item.commissionCost * -1 : 0
      )
    );
    feeOverReturned[index] = (returnFee[index] / returnedReceipts[index]) * 100;
    feeOverTotal[index] =
      (returnFee[index] / (returnedReceipts[index] + paidReceipts[index])) *
      100;
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: t("feeOverReturned") + " (%)",
        type: "line",
        data: feeOverReturned,
        borderColor: colors.purple,
        backgroundColor: colors.purple,
        stack: "Stack 1",
        yAxisID: "y2",
        tension: 0.3,
      },
      {
        label: t("feeOverTotal") + " (%)",
        type: "line",
        data: feeOverTotal,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 1",
        yAxisID: "y2",
        tension: 0.3,
      },
      {
        label: t("returnPercentage") + " (%)",
        type: "line",
        data: returnPercentage,
        borderColor: colors.red,
        backgroundColor: colors.red,
        stack: "Stack 1",
        yAxisID: "y3",
        tension: 0.3,
      },
      {
        label: t("returnedReceipts") + " (€)",
        data: returnedReceipts,
        backgroundColor: colors.orange,
        stack: "Stack 0",
        yAxisID: "y1",
      },
      {
        label: t("paidReceipts") + " (€)",
        data: paidReceipts,
        backgroundColor: colors.green,
        stack: "Stack 0",
        yAxisID: "y1",
      },
      {
        label: t("returnFee") + " (€)",
        data: returnFee,
        backgroundColor: colors.red,
        stack: "Stack 1",
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    aspectRatio: 4,
    responsive: true,
    elements: { point: { radius: 0 } },
    scales: {
      y1: {
        type: "linear",
        display: true,
        position: "left",
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
      },
      y3: {
        type: "linear",
        display: true,
        position: "right",
      },
    },
    plugins: {
      tooltip: {
        interaction: {
          mode: "index",
          intersect: false,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("returnedReceipts")}
      chart={<Bar data={chartData} options={options} />}
    />
  );
};

export const SignedContractsDoughnut = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const labels = [
    t("notSigned"),
    t("digitalSignature"),
    t("physicalSignature"),
  ];

  const totalContracts = contracts.length;

  let dataSetsData = [0, 0, 0];
  contracts.forEach((contract) => {
    if (contract.isSigned) {
      if (contract.hasDigitalSignature === null) dataSetsData[2] += 1;
      else dataSetsData[1] += 1;
    } else {
      dataSetsData[0] += 1;
    }
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetsData,
        backgroundColor: [colors.red, colors.primary, colors.secondary],
      },
    ],
  };
  const options = {
    plugins: {
      // legend: {
      //   display: true,
      //   position: "right",
      // },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(
              Math.abs(value.parsed)
            )} (${localeFormat(
              (Math.abs(value.parsed) / totalContracts) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("signedContracts")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};

export const ContractsPaymentMethodDoughnut = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const labels = [
    t("cash"),
    t("receipt"),
    t("paycard"),
    t("transfer"),
    t("consolidation"),
    t("tokenizedPaycard"),
  ];

  const totalContracts = contracts.length;

  let dataSetsData = [0, 0, 0, 0, 0, 0];
  contracts.forEach((contract) => {
    switch (contract.paymentMethod) {
      case CASH_PAYMENT_METHOD_ID:
        dataSetsData[0] += 1;
        break;
      case RECEIPT_PAYMENT_METHOD_ID:
        dataSetsData[1] += 1;
        break;
      case PAYCARD_PAYMENT_METHOD_ID:
        dataSetsData[2] += 1;
        break;
      case TRANSFER_PAYMENT_METHOD_ID:
        dataSetsData[3] += 1;
        break;
      case CONSOLIDATION_PAYMENT_METHOD_ID:
        dataSetsData[4] += 1;
        break;
      case TOKENIZED_PAYCARD_PAYMENT_METHOD_ID:
        dataSetsData[5] += 1;
        break;
    }
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetsData,
        backgroundColor: [
          colors.red,
          colors.primary,
          colors.secondary,
          colors.brown,
          colors.yellow,
          colors.orange,
        ],
      },
    ],
  };
  const options = {
    plugins: {
      // legend: {
      //   display: true,
      //   position: "right",
      // },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(
              Math.abs(value.parsed)
            )} (${localeFormat(
              (Math.abs(value.parsed) / totalContracts) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("contractsPaymentMethod")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};

export const ContractsPeriodicitiesDoughnut = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const labels = [t("monthly"), t("quarterly"), t("biannual"), t("annual")];

  const totalContracts = contracts.length;

  let dataSetsData = [0, 0, 0, 0];
  contracts.forEach((contract) => {
    switch (contract.periodicity) {
      case MONTHLY_PERIODICITY_ID:
        dataSetsData[0] += 1;
        break;
      case QUARTERLY_PERIODICITY_ID:
        dataSetsData[1] += 1;
        break;
      case BIANNUAL_PERIODICITY_ID:
        dataSetsData[2] += 1;
        break;
      case ANNUAL_PERIODICITY_ID:
        dataSetsData[3] += 1;
        break;
    }
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSetsData,
        backgroundColor: [
          colors.red,
          colors.primary,
          colors.secondary,
          colors.brown,
        ],
      },
    ],
  };
  const options = {
    plugins: {
      // legend: {
      //   display: true,
      //   position: "right",
      // },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.label}: ${localeFormat(
              Math.abs(value.parsed)
            )} (${localeFormat(
              (Math.abs(value.parsed) / totalContracts) * 100
            )}%)`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("contractsPeriodicities")}
      chart={<Doughnut data={data} options={options} />}
    ></ChartContainer>
  );
};
export const LocationPercentile = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [selectedMeasure, setSelectedMeasure] = useState("drivingDistance");
  const [selectedValue, setSelectedValue] = useState("contracts");
  const valueGetters = {
    contracts: (items) => items.length,
    invoicing: (items) => items.reduce((sum, item) => sum + item.price, 0),
    meters: (items) => items.reduce((sum, item) => sum + item.meters, 0),
  };

  const sortedContracts = contracts.sort(
    (a, b) => a[selectedMeasure] - b[selectedMeasure]
  );

  const maxValue = contracts.length
    ? contracts[contracts.length - 1][selectedMeasure]
    : 0;

  const labels = Array.from({ length: 20 }, (_, i) => (maxValue / 20) * i);

  const groupBy = [
    {
      propName: t("absoluteRange"),
      valueCalculator: (item) => {
        const value = item[selectedMeasure];

        for (let i = 0; i < labels.length; i++) {
          if (value <= labels[i]) {
            return `${0} - ${(labels[i] / 1000).toFixed(1)} km`;
          }
        }

        return `${0} - ${(labels[labels.length - 1] / 1000).toFixed(1)} km`;
      },
    },
  ];

  const maxConfigValue = valueGetters[selectedValue](sortedContracts);
  const config = {
    propName: t(selectedValue),
    accumulated: true,
    valueCalculator: (elements) => {
      const total = valueGetters[selectedValue](elements);
      return (total * 100) / maxConfigValue;
    },
  };

  const options = {
    tension: 0.2,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.parsed.y;
            return `${context.dataset.label}: ${value.toFixed(2)}%`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  };

  return (
    <NewChartContainer
      title={t("salesByPlatform")}
      data={sortedContracts}
      groupBy={groupBy}
      config={config}
      chart={LINE}
      options={options}
    >
      <Grid
        item
        container
        spacing={2}
        marginTop={1}
        marginBottom={1}
        justifyContent={"space-between"}
        paddingLeft={1}
        paddingRight={1}
        xs={12}
      >
        <Grid item>
          <Select
            width={null}
            label={t("value")}
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
            options={[
              { value: "contracts", label: t("contracts") },
              { value: "invoicing", label: t("invoicing") },
              { value: "meters", label: t("meters") },
            ]}
          />
        </Grid>
        <Grid item>
          <Select
            width={null}
            label={t("measure")}
            value={selectedMeasure}
            onChange={(e) => setSelectedMeasure(e.target.value)}
            options={[
              { value: "drivingDistance", label: t("drivingDistance") },
              { value: "walkingDistance", label: t("walkingDistance") },
              { value: "drivingDuration", label: t("drivingDuration") },
              { value: "walkingDuration", label: t("walkingDuration") },
              { value: "distanceToCenter", label: t("distance") },
            ]}
          />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalMovements = ({
  boxes,
  contracts,
  startDate,
  endDate,
}) => {
  const [t] = useTranslation("dashboard");

  const parsedData = [];
  const totalActivePerMonths = {};
  const dateLabels = getDates(startDate, endDate, "months");

  contracts.forEach((contract) => {
    const contractStartDate = contract.notFormattedStartDate;
    const contractEndDate = contract.notFormattedEndDate;
    const contractMoveIn = new Date(contract.activeMonths[0]);
    const contractMoveOut = new Date(
      contract.activeMonths[contract.activeMonths.length - 1]
    );

    contract.activeMonths.forEach((date) => {
      if (totalActivePerMonths[date]) {
        totalActivePerMonths[date] += 1;
      } else totalActivePerMonths[date] = 1;
    });

    dateLabels.forEach((date) => {
      const parsedDate = new Date(date);
      if (
        (contractStartDate <= parsedDate && parsedDate <= contractEndDate) ||
        (contractMoveIn <= parsedDate && parsedDate <= contractMoveOut)
      ) {
        parsedData.push({
          1: date,
        });
        parsedData.push({
          2: date,
        });
      }
    });
  });

  const groupBy = [
    {
      propName: t("moveInsAndMoveOuts"),
      valueCalculator: (element) =>
        element["1"] ? element["1"] : element["2"],
      labels: dateLabels,
    },
    {
      propName: t("type"),
      valueCalculator: (element) =>
        element["1"] ? t("basedOnNumberOfBoxes") : t("basedOnActiveContracts"),
    },
  ];

  const config = {
    propName: "value",
    valueCalculator: (items, label) => {
      const totalActivePerMonth = totalActivePerMonths[label];
      const totalBoxes = boxes.length;
      const type = items.length && items[0]["1"] ? 1 : 2;
      if (type === 2)
        return totalActivePerMonth > 0
          ? items.length / totalActivePerMonth
          : items.length;
      else return totalBoxes > 0 ? items.length / totalBoxes : items.length;
    },
  };

  return (
    <NewChartContainer
      title={t("historicalMovements")}
      data={parsedData}
      groupBy={groupBy}
      config={config}
      chart={LINE}
    ></NewChartContainer>
  );
};
