import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import UploadIcon from "@mui/icons-material/Upload";

import Button from "../../../../../Inputs/CustomButton";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

const ImageCollapsible = (props) => {
  const { item, imageURL, setImage, imageRef, sizes } = props;
  const [t] = useTranslation("floorPlans");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <ItemCollapsibleTemplate title={t("image")}>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid item marginTop={1} marginLeft={1}>
          <img
            ref={imageRef}
            src={imageURL}
            style={{
              maxWidth: `${sizes.width}px`,
              maxHeight: `${sizes.height}px`,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            startIcon={<UploadIcon />}
            variant="outlined"
            color="primary"
            component="label"
          >
            {t("chooseImage")}
            <input
              type="file"
              hidden
              name="file"
              onChange={handleImageChange}
            />
          </Button>
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

ImageCollapsible.propTypes = {
  item: PropTypes.object,
  onInputChange: PropTypes.func,
};

export default ImageCollapsible;
