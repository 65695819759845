import { FormControl, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dialog from "../../../global/Dialog";

import CustomButton from "../../../Inputs/CustomButton";
import TextInput from "../../../Inputs/TextInput";

import { RolesContext } from "../RolesPage";

const DuplicateRolDialog = (props) => {
  const { data, isOpen, onClose } = props;
  const { duplicateRole } = useContext(RolesContext);

  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("roles");

  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  const [descriptionError, setDescriptionError] = useState(false);
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (data) {
      setColor(data.color);
      setDescription(data.description);
      setName(data.name);
    }
  }, [data]);

  const validate = () => {
    let isValid = true;

    if (name === "") {
      setNameError(true);
      enqueueSnackbar("Name is required.", { variant: "error" });
      isValid = false;
    }

    if (description === "") {
      setDescriptionError(true);
      enqueueSnackbar("Description is required.", { variant: "error" });
      isValid = false;
    }

    if (isValid) {
      duplicateRole(data.id, {
        color: color,
        description: description,
        name: name,
      });
      close();
    }
  };

  const close = () => {
    setColor("");
    setDescription("");
    setName("");
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => close()}
      maxWidth="md"
      title={t("duplicateRol")}
      actions={
        <CustomButton onClick={validate} disabled={name === ""}>
          {t("duplicate")}
        </CustomButton>
      }
    >
      <Grid item container spacing={2}>
        <Grid item xs={10}>
          <TextInput
            label={t("name")}
            onChange={(e) => {
              setNameError(false);
              setName(e.target.value);
            }}
            value={name}
            error={nameError}
            helperText={nameError ? t("nameNotBlankHelper") : ""}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined">
            <input
              type="color"
              onChange={(e) => setColor(e.target.value)}
              value={color}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label={t("description")}
            multiline
            rows={4}
            onChange={(e) => {
              setDescriptionError(false);
              setDescription(e.target.value);
            }}
            value={description}
            name="description"
            error={descriptionError}
            helperText={descriptionError ? t("descriptionNotBlankHelper") : ""}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

DuplicateRolDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DuplicateRolDialog;
