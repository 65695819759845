import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LanguageSelect from "./Inputs/LanguageSelect";
import Link2 from "./Inputs/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import React, { useContext, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// Icons
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CallIcon from "@mui/icons-material/Call";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EuroIcon from "@mui/icons-material/Euro";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FolderIcon from "@mui/icons-material/Folder";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InfoIcon from "@mui/icons-material/Info";
import LayersIcon from "@mui/icons-material/Layers";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import MailIcon from "@mui/icons-material/Mail";
import Map from "@mui/icons-material/Map";
import MeetingRoom from "@mui/icons-material/MeetingRoom";
import MenuIcon from "@mui/icons-material/Menu";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PeopleIcon from "@mui/icons-material/People";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import StoreIcon from "@mui/icons-material/Store";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import ViewListIcon from "@mui/icons-material/ViewList";

const DRAWER_WIDTH = 240;

// Transition open drawer
const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Transition close drawer
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  /*"&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[500],
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: theme.palette.grey[600],
  },*/
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerListItem = ({ to, title, icon, tooltip }) => {
  return (
    <Tooltip
      title={<Typography variant="body1">{title}</Typography>}
      placement="right"
      disableHoverListener={tooltip}
    >
      <ListItem component={Link} to={to} sx={{ color: "inherit" }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </Tooltip>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function NavBar({ unsetToken, isDrawerOpen, setIsDrawerOpen }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("navbar");

  // Language
  const [language, setLanguage] = React.useState(user?.language || "US");

  useEffect(() => {
    user?.language && setLanguage(user.language);
  }, [user]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // ID refers to Page id in database
  const drawerLinks = [
    { id: 1, to: "/app/boxes", title: t("boxes"), icon: <MeetingRoom /> },
    { id: 2, to: "/app/calls", title: t("calls"), icon: <CallIcon /> },
    { id: 3, to: "/app/centers", title: t("centers"), icon: <StoreIcon /> },
    {
      id: 4,
      to: "/app/contracts",
      title: t("contracts"),
      icon: <DescriptionIcon />,
    },
    {
      id: 5,
      to: "/app/customers",
      title: t("customers"),
      icon: <EmojiPeopleIcon />,
    },
    {
      id: 6,
      to: "/app/dashboard",
      title: t("dashboard"),
      icon: <DashboardIcon />,
    },
    {
      id: 7,
      to: "/app/documents",
      title: t("documents"),
      icon: <FolderIcon />,
    },
    {
      id: 8,
      to: "/app/invoices",
      title: t("invoices"),
      icon: (
        <Box paddingLeft={0.6}>
          <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
        </Box>
      ),
    },
    {
      id: 9,
      to: "/app/merchantables",
      title: t("merchantables"),
      icon: <ShoppingBasketIcon />,
    },
    {
      id: 10,
      to: "/app/provider-invoices",
      title: t("providerInvoices"),
      icon: <ViewListIcon />,
    },
    {
      id: 11,
      to: "/app/providers",
      title: t("providers"),
      icon: <LocalParkingIcon />,
    },
    {
      id: 12,
      to: "/app/receipts",
      title: t("receipts"),
      icon: <ReceiptIcon />,
    },
    {
      id: 13,
      to: "/app/remittances",
      title: t("remittances"),
      icon: <AccountBalanceIcon />,
    },
    {
      id: 14,
      to: "/app/roles",
      title: t("roles"),
      icon: <SupervisedUserCircleIcon />,
    },
    { id: 15, to: "/app/users", title: t("users"), icon: <PeopleIcon /> },
    { id: 16, to: "/app/maps", title: t("maps"), icon: <Map /> },
    {
      id: 17,
      to: "/app/settings",
      title: t("settings"),
      icon: <SettingsIcon />,
    },
    {
      id: 18,
      to: "/app/occupancy",
      title: t("occupancy"),
      icon: <EqualizerIcon />,
    },
    {
      id: 19,
      to: "/app/invoicing",
      title: t("invoicing"),
      icon: <ReceiptLongIcon />,
    },
    {
      id: 20,
      to: "/app/banking-transactions",
      title: t("bankingTransactions"),
      icon: <CurrencyExchangeIcon />,
    },
    {
      id: 21,
      to: "/app/non-payments",
      title: t("nonPayments"),
      icon: <MoneyOffIcon />,
    },
    {
      id: 22,
      to: "/app/settlements",
      title: t("settlements"),
      icon: <LocalAtmIcon />,
    },
    {
      id: 23,
      to: "/app/mass-emails",
      title: t("massEmails"),
      icon: <MailIcon />,
    },
    {
      id: 24,
      to: "/app/expense-types",
      title: t("expenseTypes"),
      icon: <EuroIcon />,
    },
    {
      id: 25,
      to: "/app/transfer-payments",
      title: t("transferPayments"),
      icon: <SyncAltIcon />,
    },
    {
      id: 26,
      to: "/app/acquisitions",
      title: t("acquisitions"),
      icon: <ApartmentIcon />,
    },
    {
      id: 27,
      to: "/app/revenue-management",
      title: t("revenueManagement"),
      icon: <PriceChangeIcon />,
    },
    {
      id: 29,
      to: "/app/invoice-emailing",
      title: t("invoiceEmailing"),
      icon: <ForwardToInboxIcon />,
    },
    {
      id: 30,
      to: "/app/floor-plans",
      title: t("floorPlans"),
      icon: <LayersIcon />,
    },
    {
      id: 31,
      to: "/app/cameras",
      title: t("cameras"),
      icon: <VideocamIcon />,
    },
    {
      id: 32,
      to: "/app/promotions",
      title: t("promotions"),
      icon: <LocalOfferIcon />,
    },
    {
      id: 33,
      to: "/app/access-control",
      title: t("accessControl"),
      icon: <RoomPreferencesIcon />,
    },
  ];

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevValue) => !prevValue);
  };

  const updateUserLanguage = (value) => {
    const data = { language: value };
    if (user) {
      api.post("/users/" + user.id + "/language", data).catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar position="fixed" open={isDrawerOpen}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              marginRight: "36px",
              ...(isDrawerOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box flexGrow={1}>
            <Link2 to="/app">
              <Typography component="h1" variant="h6" color="white">
                NUT
                <Typography component="span" variant="h6" color="yellow">
                  {process.env.NODE_ENV === "development"
                    ? " (DEV)"
                    : user?.Role?.id === 11
                    ? process.env.REACT_APP_ENV === "testing" && " (TEST)"
                    : ""}
                </Typography>
              </Typography>
            </Link2>
          </Box>
          <LanguageSelect
            value={language}
            onChange={(value) => {
              updateUserLanguage(value);
              setLanguage(value);
            }}
            //defaultValue={user?.language}
          />

          {/* <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton> */}
          <Tooltip title={"Version: " + process.env.REACT_APP_VERSION}>
            <IconButton color="inherit">
              <InfoIcon />
            </IconButton>
          </Tooltip>

          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={openMenu}
            color="inherit"
          >
            <Typography>{user?.name}</Typography>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            color="inherit"
          >
            <MenuItem component={Link} to={"/app/profile"} onClick={closeMenu}>
              <AccountCircle />
              <Typography variant="button">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={unsetToken}>
              <ExitToAppIcon />
              <Typography variant="button">Logout</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={isDrawerOpen}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {user?.Role.Pages.map((page) => {
            //get the first coincidence
            let { id, to, title, icon } = drawerLinks.find(
              (link) => link.id === page.id
            );
            return (
              <DrawerListItem
                key={id}
                to={to}
                title={title}
                icon={icon}
                tooltip={isDrawerOpen}
              />
            );
          })}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
