import { useLocation } from "react-router-dom";

import { Button } from "@mui/material";
import { getParams } from "../../../utils/url";

import Page from "./Components/Page.js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddPaycardPage = () => {
  const query = useQuery();
  const FILTERS = [
    { name: "token", type: "string" },
    { name: "signature", type: "string" },
  ];

  const params = getParams(query, FILTERS);
  let link =
    process.env.NODE_ENV === "production"
      ? "https://sis.redsys.es/sis/realizarPago"
      : "https://sis-t.redsys.es:25443/sis/realizarPago";
  return (
    <Page>
      <form name="from" action={link} method="POST">
        <input
          type="hidden"
          name="Ds_SignatureVersion"
          value="HMAC_SHA256_V1"
        />
        <input
          type="hidden"
          name="Ds_MerchantParameters"
          value={params.token}
        />
        <input
          type="hidden"
          name="Ds_Signature"
          value={params.signature.replace(/ /g, "+").replace(/\\/g, "/")}
        />
        <ContinueButton />
      </form>
    </Page>
  );
};

const ContinueButton = () => {
  return (
    <Button
      type="submit"
      variant="contained"
      sx={{
        borderRadius: "8px",
        minHeight: "52px",
      }}
    >
      Añadir forma de pago
    </Button>
  );
};

export default AddPaycardPage;
