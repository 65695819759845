import { Grid, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { getParams } from "../../../../utils/url";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppContext from "../../../../context/AppContext";
import CreateRolDialog from "../Dialogs/CreateRolDialog";
import DuplicateRolDialog from "../Dialogs/DuplicateRolDialog";
import Filters from "../../../global/structure/Filters";
import Page from "../../../global/structure/Page";

import CustomButton from "../../../Inputs/CustomButton";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";

import { CustomTable } from "../../../CustomTable";
import { RolesContext } from "../RolesPage";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  filters: {
    autoSearch: false,
    role: "",
  },

  selectedRole: "",

  isCreateDialogOpen: false,
  isDuplicateDialogOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CREATE_MODAL":
      return { ...state, isCreateDialogOpen: !state.isCreateDialogOpen };
    case "SET_DUPLICATE_MODAL":
      return {
        ...state,
        isDuplicateDialogOpen: !state.isDuplicateDialogOpen,
        selectedRole: action.payload,
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

function RolesTab() {
  const { user } = useContext(AppContext);
  const { getRoles, isLoading, roles } = useContext(RolesContext);

  const [t] = useTranslation("roles");
  const query = useQuery();

  const FILTERS = [
    { name: "autoSearch", type: "boolean" },
    { name: "role", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  useEffect(() => {
    if (state.filters.autoSearch) getRoles(state.filters);
  }, [state.filters.autoSearch]);

  const ROLE_COLUMNS = [
    { label: t("name"), key: "name", sortType: "string" },
    { label: t("description"), key: "description", sortType: "string" },
    {
      label: t("actions"),
      key: "id",
      sortType: "other",
      renderFunction: (value, item) => (
        <Tooltip title={t("duplicate")}>
          <IconButton
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              handleDuplicateRolDialog(item);
            }}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });
    return isFound;
  };

  const search = () => {
    if (state.filters.autoSearch === true) getRoles(state.filters);
    else
      dispatch({
        type: "SET_FILTER",
        payload: {
          inputname: "autoSearch",
          value: true,
        },
      });
  };

  const handleChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleCreateRolDialog = () => {
    dispatch({ type: "SET_CREATE_MODAL" });
  };

  const handleDuplicateRolDialog = (item) => {
    dispatch({ type: "SET_DUPLICATE_MODAL", payload: item });
  };

  return (
    <Page>
      <Grid item container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                label={t("name")}
                name="role"
                value={state.filters.role}
                onChange={(e) => handleChange(e)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") search();
                }}
              />,
            ]}
          />
        </Grid>
        <Grid item>
          <SearchButton onClick={search} loading={isLoading} />
        </Grid>
        {hasAction("CREATE_ROLES") && (
          <Grid item>
            <CustomButton
              onClick={handleCreateRolDialog}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="success"
            >
              {t("createRol")}
            </CustomButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomTable
            data={roles}
            columns={ROLE_COLUMNS}
            options={{ rowlink: "role", loaded: !isLoading }}
          ></CustomTable>
        </Grid>
      </Grid>
      <CreateRolDialog
        isOpen={state.isCreateDialogOpen}
        onClose={handleCreateRolDialog}
      />
      <DuplicateRolDialog
        isOpen={state.isDuplicateDialogOpen}
        onClose={handleDuplicateRolDialog}
        data={state.selectedRole}
      />
    </Page>
  );
}

export default RolesTab;
