import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { decode as base64_decode } from "base-64";

import { getParams } from "../../../utils/url";

import API from "../../../utils/API";
import Page from "./Components/Page.js";

import useToken from "../../../hooks/useToken";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UrlOKPage = () => {
  const { unsetToken, getToken } = useToken();
  const api = API(getToken, unsetToken);

  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  const [t] = useTranslation("app");
  const [tErrors] = useTranslation("errors");

  const FILTERS = [
    { name: "Ds_SignatureVersion", type: "string" },
    { name: "Ds_MerchantParameters", type: "string" },
    { name: "Ds_Signature", type: "string" },
  ];

  const params = getParams(query, FILTERS);

  useEffect(() => {
    createTemporalPayCard();
    const redirectTimeout = setTimeout(() => {
      window.location.href = "https://necesitountrastero.es/";
    }, 5000);
  }, []);

  const createTemporalPayCard = () => {
    let decodedData = base64_decode(params.Ds_MerchantParameters);
    decodedData = JSON.parse(decodedData);

    const data = {
      cofId: decodedData.Ds_Merchant_Cof_Txnid,
      expirationDate: decodedData.Ds_ExpiryDate,
      id: decodedData.Ds_Order,
      mask: decodedData.Ds_Card_Number,
      token: decodedData.Ds_Merchant_Identifier,
    };

    api
      .post("/pay-cards/temporal-paycard", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("success"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Page>
      {
        "Operación realizada con éxito. \n En cinco segundos se le redirigirá a la página principal."
      }
    </Page>
  );
};

export default UrlOKPage;
