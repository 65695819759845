import { Image } from "react-konva";
import { useImage } from "react-konva-utils";

const DOOR_IMAGE = "/img/floor-plan/door-icon.png";

const Door = (props) => {
  const {
    x,
    y,
    width,
    onClick,
    tileRef,
    onTransformEnd,
    onDragEnd,
    draggable,
  } = props;

  let [image] = useImage(DOOR_IMAGE);

  return (
    <Image
      ref={tileRef}
      x={x}
      y={y}
      width={width}
      height={width}
      image={image}
      onClick={onClick}
      onTransformEnd={onTransformEnd}
      onDragEnd={onDragEnd}
      draggable={draggable}
    />
  );
};

export default Door;
