import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const ActionButtons = (props) => {
  const { onSave, onCancel } = props;

  const [t] = useTranslation("floorPlans");

  return (
    <Grid container>
      <Grid container item spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button color="primary" onClick={onCancel}>
            {t("cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onSave}>
            {t("save")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
