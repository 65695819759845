import {
  Box,
  Card,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CenterReportContext from "../CenterReportContext";

import { CustomTable } from "../../../../CustomTable";
import { localeFormat } from "../../../../../utils/format";
import ButtonLink from "../../../../Inputs/ButtonLink";
import CenterMap from "../../Components/CenterMap";

import SquareIcon from "@mui/icons-material/Square";
import RectangleIcon from "@mui/icons-material/Rectangle";

import {
  CustomerAgeDistribution,
  CustomerGenderDetails,
} from "../../Charts/CenterReport";
import { ChartContainer } from "../../Charts/ChartContainer";

function CustomersTab() {
  const { center, customers } = useContext(CenterReportContext);
  const [t] = useTranslation("dashboard");

  const parsedCustomers = customers.map((customer) => {
    return {
      ...customer,
      age: customer.birthdate
        ? new Date().getFullYear() - new Date(customer.birthdate).getFullYear()
        : 0,
      type: customer.customerTypeId === 1 ? t("individual") : t("company"),
      hasLocation: customer.latitude && customer.longitude !== null ? 1 : 0,
      isKeyAccount: customer.isKeyAccount ? 1 : 0,
      gender: customer.gender !== null ? customer.gender : -1,
      boxes: customer.Contracts ? customer.Contracts.length : 0,
      meters: customer.Contracts
        ? customer.Contracts.reduce((acum, e) => acum + Number(e.meters), 0)
        : 0,
      income: customer.Contracts
        ? customer.Contracts.reduce(
            (acum, e) => acum + Number(e.meters) * Number(e.pricePerMeter),
            0
          )
        : 0,
    };
  });

  const CUSTOMER_COLUMNS = [
    {
      key: "fullName",
      label: t("customer"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={`/app/customer/${item.id}`}>{value}</ButtonLink>
      ),
    },
    {
      key: "age",
      label: t("age"),
      sortType: "number",
      renderFunction: (value) => {
        if (value === 0) return "";
        return value;
      },
    },
    {
      key: "customerTypeId",
      label: t("type"),
      sortType: "number",
      renderFunction: (value, item) => {
        return item.type;
      },
    },
    {
      key: "hasLocation",
      label: t("hasLocation"),
      sortType: "number",
      renderFunction: (value) => {
        return value === 1 ? "✔" : "";
      },
    },
    {
      key: "isKeyAccount",
      label: t("isKeyAccount"),
      sortType: "number",
      renderFunction: (value) => {
        return value === 1 ? "✔" : "";
      },
    },
    {
      key: "gender",
      label: t("gender"),
      sortType: "number",
      renderFunction: (value) => {
        if (value === -1) return "";
        return value === 1 ? t("men") : t("women");
      },
    },
    {
      key: "boxes",
      label: t("boxes"),
      sortType: "number",
    },
    {
      key: "meters",
      label: t("meters"),
      sortType: "number",
      renderFunction: (value) => {
        return localeFormat(value) + " m²";
      },
    },
    {
      key: "income",
      label: t("income"),
      sortType: "number",
      renderFunction: (value) => {
        return localeFormat(value) + " €";
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <CustomerAgeDistribution customers={customers} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <CustomerGenderDetails customers={customers} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable columns={CUSTOMER_COLUMNS} data={parsedCustomers} />
      </Grid>
      {center.id !== undefined && (
        <Grid item xs={12} lg={6}>
          <ChartContainer
            title={t("customersLocations")}
            chart={
              <CenterMap
                customers={customers.map((customer) => {
                  return {
                    active: true,
                    longitude: customer.longitude,
                    latitude: customer.latitude,
                    name: customer.fullName,
                    description: customer.fullName,
                    id: customer.id,
                    color:
                      customer.customerTypeId === 1 ? "#4264fb" : "#e69e19",
                  };
                })}
                center={{
                  name: center.name,
                  latitude: center.latitude,
                  longitude: center.longitude,
                }}
              />
            }
          >
            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              marginBottom={2}
            >
              <Grid item>
                <Tooltip title={t("individual")}>
                  <IconButton size="small">
                    <SquareIcon style={{ color: "#4264fb" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t("company")}>
                  <IconButton size="small">
                    <SquareIcon style={{ color: "#e69e19" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </ChartContainer>
        </Grid>
      )}
    </Grid>
  );
}

export default CustomersTab;
