import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import * as d3 from "d3";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";

import { NewChartContainer } from "./NewChartContainer";
import { ChartContainer } from "./ChartContainer";
import { colors } from "../Components/ColorGenerator";
import { getMonths, sumArray } from "../../../../utils/chartUtils";
import { getDates } from "../../../../utils/chartUtils";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import EuroIcon from "@mui/icons-material/Euro";

import DateLabelsToggle from "../Components/DateLabelsToggle";

import { localeFormat } from "../../../../utils/format";
import {
  // Payment methods
  CASH_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  CONSOLIDATION_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,

  // Nonpayment reasons
  UNPAID_REASON_ID,
  RETURNED_RECEIPT_REASON_ID,
  DENIED_CARD_REASON_ID,
  UNREALIZED_TRANSFER_REASON_ID,

  // Merchantable types
  COMMISSION_MERCHANTABLE_TYPE_ID,

  // Charts index
  BAR,
  DOUGHNUT,
  LINE,
  PIE,
} from "../../../../data/constants";

const dataSetsColors = [
  colors.primary,
  colors.secondary,
  colors.red,
  colors.brown,
  colors.yellow,
  colors.orange,
  colors.purple,
  colors.blue,
  colors.green,
  colors.turquoise,
];

const dataGetters = {
  invoices: (items) => items.length,
  amount: (items) => items.reduce((acc, item) => acc + item.amount, 0),
};

export const HistoricalInvoicingNonPayments = ({
  recuperatedItems: rawRecuperatedItems,
  neverUnpaidItems: rawNeverUnpaidItems,
  nonPaidItems: rawNonPaidItems,
}) => {
  const [t] = useTranslation("dashboard");

  const allDataSorted = [
    ...rawRecuperatedItems.map((i) => i.Invoice.issueDate),
    ...rawNeverUnpaidItems.map((i) => i.Invoice.issueDate),
    ...rawNonPaidItems.map((i) => i.Invoice.issueDate),
  ].sort();

  const minDate = allDataSorted[0];
  const maxDate = allDataSorted[allDataSorted.length - 1];

  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let paidInvoices = new Array(dates.length).fill(0);
  let nonPayments = new Array(dates.length).fill(0);
  let recuperated = new Array(dates.length).fill(0);

  labels.forEach((label, index) => {
    let date = new Date(label);

    let recuperatedItems = rawRecuperatedItems.filter(
      (item) =>
        new Date(item.Invoice.issueDate).getFullYear() === date.getFullYear() &&
        new Date(item.Invoice.issueDate).getMonth() === date.getMonth()
    );

    let neverUnpaidItems = rawNeverUnpaidItems.filter(
      (item) =>
        new Date(item.Invoice.issueDate).getFullYear() === date.getFullYear() &&
        new Date(item.Invoice.issueDate).getMonth() === date.getMonth()
    );

    let nonPaidItems = rawNonPaidItems.filter(
      (item) =>
        new Date(item.Invoice.issueDate).getFullYear() === date.getFullYear() &&
        new Date(item.Invoice.issueDate).getMonth() === date.getMonth()
    );

    paidInvoices[index] = sumArray(
      neverUnpaidItems.map((item) => item.units * item.pricePerUnit)
    );

    nonPayments[index] = sumArray(
      nonPaidItems.map((item) => item.units * item.pricePerUnit)
    );

    recuperated[index] = sumArray(
      recuperatedItems.map((item) => item.units * item.pricePerUnit)
    );
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: t("paidInvoices"),
        data: paidInvoices,
        backgroundColor: colors.primary,
        stack: "Stack 0",
      },
      {
        label: t("recuperated"),
        data: recuperated,
        backgroundColor: colors.green,
        stack: "Stack 0",
      },
      {
        label: t("nonPayments"),
        data: nonPayments,
        backgroundColor: colors.red,
        stack: "Stack 0",
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) =>
            `${value.dataset.label}: ${localeFormat(value.raw)}€`,
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("invoicing")}
      chart={<Bar data={chartData} options={options} />}
    />
  );
};

export const HistoricalReturnedReceipts = ({ items, bankingTransactions }) => {
  const [t] = useTranslation("dashboard");

  const sortedItems = items.map((e) => e.date).sort();
  const sortedBankingTransactions = bankingTransactions
    .map((e) => e.valueDate)
    .sort();

  const minDate = getMinDateValue(sortedItems[0], sortedBankingTransactions[0]);
  const maxDate = getMaxDateValue(
    sortedItems[sortedItems.length - 1],
    sortedBankingTransactions[sortedBankingTransactions.length - 1]
  );

  const dates = getMonths(minDate, maxDate);
  const labels = dates.map((date) => date.year + "-" + date.month);

  let commissionsData = new Array(dates.length).fill(0);
  let invoicedData = new Array(dates.length).fill(0);

  labels.forEach((label, index) => {
    let date = new Date(label);
    let itemsData = items.filter(
      (item) =>
        new Date(item.date).getFullYear() === date.getFullYear() &&
        new Date(item.date).getMonth() === date.getMonth()
    );
    let bankingTransactionsData = bankingTransactions.filter(
      (item) =>
        new Date(item.valueDate).getFullYear() === date.getFullYear() &&
        new Date(item.valueDate).getMonth() === date.getMonth()
    );
    commissionsData[index] = sumArray(
      bankingTransactionsData.map((transaction) => Math.abs(transaction.import))
    );

    invoicedData[index] = sumArray(
      itemsData
        .filter(
          (e) =>
            e.Merchantable &&
            e.Merchantable.merchantableTypeId ===
              COMMISSION_MERCHANTABLE_TYPE_ID
        )
        .map((item) => item.amount)
    );
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: t("commissions"),
        data: commissionsData,
        borderColor: colors.red,
        backgroundColor: colors.red,
        stack: "Stack 0",
      },
      {
        label: t("invoiced"),
        data: invoicedData,
        borderColor: colors.green,
        backgroundColor: colors.green,
        stack: "Stack 1",
      },
    ],
  };

  const options = {
    responsive: true,
  };

  return (
    <ChartContainer
      title={t("commissions")}
      chart={<Bar data={chartData} options={options} />}
    />
  );
};

export const HistoricalInvoicingSankey = ({ invoiceItemsObject }) => {
  const [t] = useTranslation("dashboard");
  const svgRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const nonPaidItems = invoiceItemsObject.nonPaidItems;
    const recuperatedItems = invoiceItemsObject.recuperatedItems;
    const neverUnpaidItems = invoiceItemsObject.neverUnpaidItems;
    const billingErrors = invoiceItemsObject.billingErrors;
    const getAmount = (acc, it) => acc + it.amount;
    const getPercentage = (value) => (value / totalAmount) * 100;

    const totalAmount = [
      ...nonPaidItems,
      ...recuperatedItems,
      ...neverUnpaidItems,
      ...billingErrors,
    ].reduce(getAmount, 0);

    const totalPaid = [...recuperatedItems, ...neverUnpaidItems].reduce(
      getAmount,
      0
    );

    const totalNonPaid = [...nonPaidItems, ...billingErrors].reduce(
      getAmount,
      0
    );

    const totalNonPayments = nonPaidItems.reduce(getAmount, 0);

    const totalBillingError = billingErrors.reduce(getAmount, 0);

    const totalUncollectible = nonPaidItems
      .filter((it) => it.Invoice.uncollectible)
      .reduce(getAmount, 0);

    const totalCollectible = nonPaidItems
      .filter((it) => !it.Invoice.uncollectible)
      .reduce(getAmount, 0);

    const data = {
      nodes: [
        {
          node: 0,
          name: `${t("invoices")} ${localeFormat(totalAmount)}€ (${localeFormat(
            getPercentage(totalAmount)
          )}%)`,
        },
        {
          node: 1,
          name: `${t("notPaid")} ${localeFormat(totalNonPaid)}€ (${localeFormat(
            getPercentage(totalNonPaid)
          )}%)`,
        },
        {
          node: 2,
          name: `${t("paid")} ${localeFormat(totalPaid)}€ (${localeFormat(
            getPercentage(totalPaid)
          )}%)`,
        },
        {
          node: 3,
          name: `${t("nonPayments")} ${localeFormat(
            totalNonPayments
          )} (${localeFormat(getPercentage(totalNonPayments))}%)`,
        },
        {
          node: 4,
          name: `${t("billingErrors")} ${localeFormat(
            totalBillingError
          )}€ (${localeFormat(getPercentage(totalBillingError))}%)`,
        },
        {
          node: 5,
          name: `${t("uncollectible")} ${localeFormat(
            totalUncollectible
          )}€ (${localeFormat(getPercentage(totalUncollectible))}%)`,
        },
        {
          node: 6,
          name: `${t("collectible")} ${localeFormat(
            totalCollectible
          )}€ (${localeFormat(getPercentage(totalCollectible))}%)`,
        },
      ],
      links: [
        {
          source: 0,
          target: 1,
          value: [...nonPaidItems, ...billingErrors].reduce(getAmount, 0),
        },
        {
          source: 0,
          target: 2,
          value: [...recuperatedItems, ...neverUnpaidItems].reduce(
            getAmount,
            0
          ),
        },
        { source: 1, target: 3, value: nonPaidItems.reduce(getAmount, 0) },
        { source: 1, target: 4, value: billingErrors.reduce(getAmount, 0) },
        {
          source: 3,
          target: 5,
          value: nonPaidItems
            .filter((it) => it.Invoice.uncollectible)
            .reduce(getAmount, 0),
        },
        {
          source: 3,
          target: 6,
          value: nonPaidItems
            .filter((it) => !it.Invoice.uncollectible)
            .reduce(getAmount, 0),
        },
      ],
    };

    const margin = { top: 15, right: 15, bottom: 15, left: 15 };

    // Obtener dimensiones del contenedor
    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerWidth * 0.35; // Proporción altura/ancho ajustable

    const svg = d3.select(svgRef.current);

    // Limpiar el svg antes de renderizar
    svg.selectAll("*").remove();

    svg
      .attr("width", containerWidth)
      .attr("height", containerHeight)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const sankey = d3Sankey()
      .nodeWidth(36)
      .nodePadding(40)
      .extent([
        [1, 1],
        [
          containerWidth - 1 - margin.right,
          containerHeight - 6 - margin.bottom,
        ],
      ]);

    const { nodes, links } = sankey({
      nodes: data.nodes.map((d) => Object.assign({}, d)),
      links: data.links.map((d) => Object.assign({}, d)),
    });

    svg
      .append("g")
      .selectAll(".link")
      .data(links)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", sankeyLinkHorizontal())
      .style("stroke-width", (d) => Math.max(1, d.width))
      .style("stroke", "#999")
      .style("fill", "none")
      .style("stroke-opacity", 0.2)
      .on("mouseover", function () {
        d3.select(this).style("stroke-opacity", 0.3);
      })
      .on("mouseout", function () {
        d3.select(this).style("stroke-opacity", 0.2);
      });

    const node = svg
      .append("g")
      .selectAll(".node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.x0},${d.y0})`)
      .call(
        d3
          .drag()
          .subject((d) => d)
          .on("start", function (event) {
            this.parentNode.appendChild(this);
          })
      );

    node
      .append("rect")
      .attr("height", (d) => d.y1 - d.y0)
      .attr("width", sankey.nodeWidth())
      .style("fill", (d) => color(d.name))
      .style("stroke", (d) => d3.rgb(color(d.name)).darker(2));

    node
      .append("text")
      .attr("x", -6)
      .attr("y", (d) => (d.y1 - d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "end")
      .text((d) => d.name)
      .filter((d) => d.x0 < containerWidth / 2)
      .attr("x", 6 + sankey.nodeWidth())
      .attr("text-anchor", "start");
  }, [invoiceItemsObject]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
        padding: "7px",
      }}
    >
      <svg ref={svgRef}></svg>
    </div>
  );
};

export const HistoricalRecuperations = ({ items, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");
  const [period, setPeriod] = useState("months");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("recuperationDate"),
      valueCalculator: (item) =>
        item?.recuperationDate
          ? item?.recuperationDates[period]
          : t("notRecuperated"),
      labels: getDates(startDate, endDate, period),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value, a) => {
            return `${value.dataset.label}: ${localeFormat(
              Math.abs(value.parsed.y)
            )}${dataType !== "invoices" ? `€` : ""}`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalRecuperations")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const RecuperatedByResume = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("recuperatedBy"),
      valueCalculator: (item) => item?.recuperatedByName || t("notRecuperated"),
      colorGetter: (elements) =>
        Array.from(
          { length: elements.length },
          (_, i) => dataSetsColors[i % dataSetsColors.length]
        ),
    },
  ];

  const config = {
    order: "desc",
    sortedBy: t("recuperatedBy"),
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            const total = value.dataset.data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            return `${value.label}: ${localeFormat(Math.abs(value.parsed))}${
              dataType !== "invoices" ? `€` : ""
            } (${localeFormat((Math.abs(value.parsed) / total) * 100)}%)`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("recuperatedByResume")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={PIE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalRecuperatedBy = ({ items, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");
  const [period, setPeriod] = useState("months");

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("recuperationDate"),
      valueCalculator: (item) =>
        item?.recuperationDate
          ? item?.recuperationDates[period]
          : t("notRecuperated"),
      labels: getDates(startDate, endDate, period),
    },
    {
      propName: t("recuperatedBy"),
      valueCalculator: (item) => item?.recuperatedByName || t("notRecuperated"),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(
              Math.abs(value.parsed.y)
            )}${dataType !== "invoices" ? `€` : ""}`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalRecuperatedBy")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const CenterNonPayments = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("center"),
      valueCalculator: (item) => item?.centerName || "NOTASSIGNED",
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    sortedBy: "value",
    order: "desc",
  };

  const options = {
    aspectRatio: 2,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(Math.abs(value.parsed.y))}${
              dataType !== "invoices" ? `€` : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("centerNonPayments")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalRecuperationMethod = ({ items, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");
  const [period, setPeriod] = useState("months");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("recuperationDate"),
      valueCalculator: (item) =>
        item?.recuperationDate
          ? item?.recuperationDates[period]
          : t("notRecuperated"),
      labels: getDates(startDate, endDate, period),
    },
    {
      propName: t("recuperationMethod"),
      valueCalculator: (item) => item?.recuperationMethodName || "NOTASSIGNED",
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(
              Math.abs(value.parsed.y)
            )}${dataType !== "invoices" ? `€` : ""}`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalRecuperationMethod")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const PaymentMethodsSankey = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const svgRef = useRef();
  const containerRef = useRef();
  const pmFilter = (id) => (it) => it.recuperationMethod === id;
  const getAmount = (acc, it) => acc + it.amount;

  useEffect(() => {
    const reasons = [
      { reasonId: UNPAID_REASON_ID, source: 0 },
      { reasonId: RETURNED_RECEIPT_REASON_ID, source: 1 },
      { reasonId: DENIED_CARD_REASON_ID, source: 2 },
      { reasonId: UNREALIZED_TRANSFER_REASON_ID, source: 3 },
    ];

    const paymentMethods = [
      { methodId: CASH_PAYMENT_METHOD_ID, target: 4 },
      { methodId: RECEIPT_PAYMENT_METHOD_ID, target: 5 },
      { methodId: PAYCARD_PAYMENT_METHOD_ID, target: 6 },
      { methodId: TRANSFER_PAYMENT_METHOD_ID, target: 7 },
      { methodId: CONSOLIDATION_PAYMENT_METHOD_ID, target: 8 },
      { methodId: TOKENIZED_PAYCARD_PAYMENT_METHOD_ID, target: 9 },
    ];

    const linksArray = [];
    const nodesArray = [];
    const values = Array.from({ length: 10 }, () => 0);

    values.forEach((_, index) => {
      if (index < 4) {
        values[index] = items
          .filter((it) => it.reason === index)
          .reduce(getAmount, 0);
      } else {
        values[index] = items
          .filter((it) => it.recuperationMethod === index - 3)
          .reduce(getAmount, 0);
      }
    });
    reasons.forEach(({ reasonId, source }) => {
      const filteredItems = items.filter((it) => it.reason === reasonId);
      paymentMethods.forEach(({ methodId, target }) => {
        const value = filteredItems
          .filter(pmFilter(methodId))
          .reduce(getAmount, 0);

        if (value > 0) {
          linksArray.push({ source, target, value });
        }
      });
    });

    values[0] > 0 && nodesArray.push({ node: 0, name: t("unpaidItems") });
    values[1] > 0 &&
      nodesArray.push({ node: 1, name: t("returnedReceiptItems") });
    values[2] > 0 && nodesArray.push({ node: 2, name: t("deniedCardItems") });
    values[3] > 0 &&
      nodesArray.push({ node: 3, name: t("unrealizedTransferItems") });
    values[4] > 0 && nodesArray.push({ node: 4, name: t("cashItems") });
    values[5] > 0 && nodesArray.push({ node: 5, name: t("receiptItems") });
    values[6] > 0 && nodesArray.push({ node: 6, name: t("paycardItems") });
    values[7] > 0 && nodesArray.push({ node: 7, name: t("transferItems") });
    values[8] > 0 &&
      nodesArray.push({ node: 8, name: t("consolidationItems") });
    values[9] > 0 &&
      nodesArray.push({ node: 9, name: t("tokenizedPaycardItems") });

    const data = {
      nodes: [...nodesArray],
      links: [...linksArray],
    };

    const margin = { top: 15, right: 15, bottom: 15, left: 15 };

    // Obtener dimensiones del contenedor
    const containerWidth = containerRef.current?.clientWidth;
    const containerHeight = containerWidth * 0.7; // Proporción altura/ancho ajustable

    const svg = d3.select(svgRef.current);

    // Limpiar el svg antes de renderizar
    svg.selectAll("*").remove();

    svg
      .attr("width", containerWidth)
      .attr("height", containerHeight)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const sankey = d3Sankey()
      .nodeWidth(36)
      .nodePadding(40)
      .extent([
        [1, 1],
        [
          containerWidth - 1 - margin.right,
          containerHeight - 6 - margin.bottom,
        ],
      ]);

    const { nodes, links } = sankey({
      nodes: data.nodes.map((d) => Object.assign({}, d)),
      links: data.links.map((d) => Object.assign({}, d)),
    });

    svg
      .append("g")
      .selectAll(".link")
      .data(links)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", sankeyLinkHorizontal())
      .style("stroke-width", (d) => Math.max(1, d.width))
      .style("stroke", "#999")
      .style("fill", "none")
      .style("stroke-opacity", 0.2)
      .on("mouseover", function () {
        d3.select(this).style("stroke-opacity", 0.3);
      })
      .on("mouseout", function () {
        d3.select(this).style("stroke-opacity", 0.2);
      });

    const node = svg
      .append("g")
      .selectAll(".node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.x0},${d.y0})`)
      .call(
        d3
          .drag()
          .subject((d) => d)
          .on("start", function (event) {
            this.parentNode.appendChild(this);
          })
      );

    node
      .append("rect")
      .attr("height", (d) => d.y1 - d.y0)
      .attr("width", sankey.nodeWidth())
      .style("fill", (d) => color(d.name))
      .style("stroke", (d) => d3.rgb(color(d.name)).darker(2));

    node
      .append("text")
      .attr("x", -6)
      .attr("y", (d) => (d.y1 - d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "end")
      .text((d) => d.name)
      .filter((d) => d.x0 < containerWidth / 2)
      .attr("x", 6 + sankey.nodeWidth())
      .attr("text-anchor", "start");
  }, [items]);

  return (
    <div ref={containerRef}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export const HistoricalNonPayment = ({ items, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");
  const [period, setPeriod] = useState("months");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("nonPaymentDate"),
      valueCalculator: (item) =>
        item?.nonPaymentDate
          ? item?.nonPaymentDates[period]
          : t("notNonPayment"),
      labels: getDates(startDate, endDate, period),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(
              Math.abs(value.parsed.y)
            )}${dataType !== "invoices" ? `€` : ""}`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalNonPayment")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalInvoiceIssueDate = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const sortedItems = [...items].sort((a, b) => {
    return new Date(a.issueDate) - new Date(b.issueDate);
  });

  const groupBy = [
    {
      propName: t("invoiceIssueDate"),
      valueCalculator: (item) => item.issueDate,
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(Math.abs(value.parsed.y))}${
              dataType !== "invoices" ? `€` : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalInvoiceIssueDate")}
      data={sortedItems}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const NonPaymentBeforeContractEndPie = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("nonPaymentBeforeContractEnd"),
      valueCalculator: (item) =>
        item.nonPaymentBeforeContractEnd ? t("before") : t("after"),
      colorGetter: (elements) => [colors.orange, colors.turquoise],
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 2,
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(Math.abs(value.parsed))}${
              dataType !== "invoices" ? `€` : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("nonPaymentBeforeContractEnd")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={PIE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const NonPaymentBeforeContractEndBar = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("nonPaymentBeforeContractEnd"),
      valueCalculator: (item) =>
        item.nonPaymentBeforeContractEnd ? t("before") : t("after"),
    },
    {
      propName: t("paidAt"),
      valueCalculator: (item) =>
        item.paidAt !== "" ? t("paid") : t("notPaid"),
      colorGetter: (label) => (label === t("paid") ? colors.green : colors.red),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 2,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(value.raw)} %`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("nonPaymentBeforeContractEnd")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
      percentage={true}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalNonPaymentGap = ({ items, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [period, setPeriod] = useState("months");

  const groupBy = [
    {
      propName: t("nonPaymentDate"),
      valueCalculator: (item) =>
        item?.nonPaymentDate
          ? item?.nonPaymentDates[period]
          : t("notNonPayment"),
      labels: getDates(startDate, endDate, period),
      colorGetter: (elements) => [colors.orange],
    },
  ];

  const config = {
    propName: t("nonPaymentGap"),
    valueCalculator: (items) =>
      items.reduce((acc, e) => acc + e.nonPaymentGap, 0) / items.length,
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(
              Math.abs(value.parsed.y)
            )} ${t("days")}`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalNonPaymentGap")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={LINE}
    >
      <Grid item marginLeft={2} marginBottom={2}>
        <Typography fontStyle={"italic"} fontSize={13}>
          {t("nonPaymentGapMeaning")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent={"flex-end"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const NonPaymentReasonsPie = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("nonPaymentReason"),
      valueCalculator: (item) => item.reasonName,
      colorGetter: (elements) =>
        Array.from(
          { length: elements.length },
          (_, i) => dataSetsColors[i % dataSetsColors.length]
        ),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 2,
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(Math.abs(value.parsed))}${
              dataType !== "invoices" ? `€` : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("percentageOfNonPaymentsByPaymentMethod")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={PIE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const NonPaymentReasonsBar = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("nonPaymentReason"),
      valueCalculator: (item) => item.reasonName,
    },
    {
      propName: t("paidAt"),
      valueCalculator: (item) =>
        item.paidAt !== "" ? t("paid") : t("notPaid"),
      colorGetter: (label) => (label === t("paid") ? colors.green : colors.red),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 2,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(value.raw)} %`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("percentageOfNonPaymentsByPaymentMethod")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      percentage={true}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const RecoveringEntityPie = ({ items }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("recuperatedBy"),
      valueCalculator: (item) =>
        item.recuperatedByName === "customer" ? t("customer") : t("company"),
      colorGetter: (elements) => [colors.turquoise, colors.orange],
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 2,
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            const total = value.dataset.data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            return `${value.label}: ${localeFormat(Math.abs(value.parsed))}${
              dataType !== "invoices" ? `€` : ""
            } (${localeFormat((Math.abs(value.parsed) / total) * 100)}%)`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("recoveringEntity")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={PIE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalRecuperationByRecuperationDelay = ({
  items,
  startDate,
  endDate,
}) => {
  const [t] = useTranslation("dashboard");

  const groupBy = [
    {
      propName: t("recuperationDate"),
      valueCalculator: (item) => item?.recuperationDates["months"],
      labels: getDates(startDate, endDate, "months"),
    },
  ];

  const config = {
    propName: t("recuperationDelay"),
    valueCalculator: (items) => {
      const divisor = items.length ? items.length : 1;
      return (
        items.reduce((acc, e) => acc + e.recuperationDelay / 1440, 0) / divisor
      );
    },
    sorted: true,
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(
              Math.abs(value.parsed.y)
            )} ${t("days")}`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalRecuperationByRecuperationDelay")}
      data={items}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={LINE}
    />
  );
};

const getMinDateValue = (date1, date2) => {
  if (!date1 && !date2) return new Date();
  if (!date1) return date2;
  if (!date2) return date1;
  return date1 < date2 ? date1 : date2;
};

const getMaxDateValue = (date1, date2) => {
  if (!date1 && !date2) return new Date();
  if (!date1) return date2;
  if (!date2) return date1;
  return date1 > date2 ? date1 : date2;
};
