import { useEffect, useRef } from "react";
import { Transformer } from "react-konva";
import Tile from "../TileShapes/Tile";

const TileEdit = (props) => {
  let { tile, onEditTile } = props;

  const MIN_RESIZE_LIMIT = 5;

  const transformerRef = useRef(null);
  const tileRef = useRef(null);

  const keepRatio = ["Image", "Text", "Door", "Camera"].includes(tile.shape);

  const onTransformEndHandler = () => {
    const node = tileRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset tile scale
    node.scaleX(1);
    node.scaleY(1);

    /**
     * Update tile position (x, y) and size (width, heigt)
     * when transfromer changes
     */
    onEditTile({
      ...tile,
      x: Math.round(node.x()),
      y: Math.round(node.y()),
      width: Math.round(node.width() * scaleX),
      height: Math.round(node.height() * scaleY),
    });
  };

  /**
   * Update position (x, y) on drag
   */
  const onDragEndHandler = (e) => {
    onEditTile({
      ...tile,
      x: Math.round(e.target.x()),
      y: Math.round(e.target.y()),
    });
  };

  useEffect(() => {
    // we need to attach transformer manually
    transformerRef.current?.nodes([tileRef.current]);
    transformerRef.current?.getLayer().batchDraw();
    tileRef.current?.scaleX(1);
    tileRef.current?.scaleY(1);
  });

  return (
    <>
      <Tile
        draggable
        onDragEnd={onDragEndHandler}
        onTransformEnd={onTransformEndHandler}
        tile={{ ...tile, opacity: 0.4 }}
        tileRef={tileRef}
      />

      <Transformer
        ref={transformerRef}
        flipEnabled={false}
        rotateEnabled={false}
        keepRatio={keepRatio}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (Math.abs(newBox.width) < MIN_RESIZE_LIMIT || Math.abs(newBox.height) < MIN_RESIZE_LIMIT) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </>
  );
};

export default TileEdit;
