import {
  ButtonGroup,
  Card,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  List,
  CardContent,
} from "@mui/material";
import React from "react";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/chartUtils";

import CheckIcon from "@mui/icons-material/Check";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import ConfirmDialog from "../../ConfirmDialog";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import Dialog from "../../global/Dialog";
import TextInput from "../../Inputs/TextInput";
import SearchButton from "../../Inputs/SearchButton";
import { CustomTable } from "../../CustomTable";

function reducer(state, action) {
  switch (action.type) {
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          isOpen: action.payload.isOpen,
          selectedTransaction: action.payload.selectedTransaction,
          comments: action.payload.selectedTransaction?.comments || "",
        },
      };
    case "SET_COMMENTS_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          comments: action.payload,
        },
      };
    case "SET_BANK_ACCOUNTS":
      return { ...state, bankAccounts: action.payload };
    case "SET_BANKING_TRANSACTIONS":
      return { ...state, bankingTransactions: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_DATES":
      return {
        ...state,
        filters: {
          ...state.filters,
          dateFrom: action.payload.dateFrom,
          dateUntil: action.payload.dateUntil,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    default:
      throw new Error("Action not found in reducer");
  }
}

const DEFAULT_BANK_ACCOUNT_ID = 1; //Id of Banc Sabadell

const initialState = {
  filters: {
    bankAccountId: DEFAULT_BANK_ACCOUNT_ID,
    dateFrom: "",
    dateUntil: "",
    concept: "",
    importMin: "",
    importMax: "",
    orderBy: "",
    order: "",
  },
  options: {
    loaded: true,
  },
  bankAccounts: [],
  bankingTransactions: [],
  bankingTransactionComment: "",
  selectedBankingTransactions: [],
  confirmDialog: {
    isOpen: false,
    selectedTransaction: [],
    comments: "",
  },
};

const BankingTransactionsDialog = (props) => {
  const {
    providerBankingTransactions,
    open,
    providerInvoice,
    setOpen,
    setBankingTransactions,
  } = props;

  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [t] = useTranslation("bankingTransactions");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  //Set default filter dates:
  useEffect(() => {
    setDefaultDates();
  }, [open]);

  const BANKING_TRANSACTIONS_COLUMNS = [
    { key: "transactionDate", label: t("transactionDate"), sortType: "string" },
    { key: "concept", label: t("concept"), sortType: "string" },
    { key: "valueDate", label: t("valueDate"), sortType: "string" },
    {
      key: "import",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => value?.toLocaleString() + "€",
    },
    { key: "comments", label: t("comments"), sortType: "string" },
    {
      key: "auditDate",
      label: t("audited"),
      sortType: "string",
      renderFunction: (value) => value && <CheckIcon color="success" />,
    },
    {
      key: "hasFile",
      label: t("file"),
      sortType: "number",
      renderFunction: (value, item) =>
        value ? (
          <InsertDriveFileIcon
            color="success"
            onClick={(e) => {
              e.preventDefault();
              showFile(item);
            }}
          />
        ) : (
          <InsertDriveFileIcon color="disabled" />
        ),
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Button
          color="primary"
          onClick={() => {
            setConfirmDialogState(item);
          }}
        >
          {t("add")}
        </Button>
      ),
    },
  ];

  const setDefaultDates = () => {
    if (providerInvoice.paidAt) {
      const paidAt = new Date(providerInvoice.paidAt);

      const dateUntil = new Date(paidAt.setDate(paidAt.getDate() + 7));
      const dateFrom = new Date(paidAt.setDate(paidAt.getDate() - 7));

      dispatch({
        type: "SET_DATES",
        payload: {
          dateFrom: formatDate(dateFrom),
          dateUntil: formatDate(dateUntil),
        },
      });
    } else {
      const date = new Date(providerInvoice.date);

      const dateUntil = new Date(date.setDate(date.getDate() + 7));
      const dateFrom = new Date(date.setDate(date.getDate() - 7));

      dispatch({
        type: "SET_DATES",
        payload: {
          dateFrom: formatDate(dateFrom),
          dateUntil: formatDate(dateUntil),
        },
      });
    }
  };

  const editBankingTransaction = () => {
    let form = { comments: state.confirmDialog.comments };
    api
      .post(
        "/banking-transactions/" + state.confirmDialog.selectedTransaction.id,
        form
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar("comment saved", { variant: "success" });
        addBankingTransaction(response.data.result);
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const addBankingTransaction = (transaction) => {
    api
      .post(
        "/banking-transactions/provider-invoice/" +
          transaction.id +
          "/" +
          providerInvoice.id
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar("added correctly", { variant: "success" });
        providerBankingTransactions.push(transaction);
        setBankingTransactions(providerBankingTransactions);

        dispatch({
          type: "SET_BANKING_TRANSACTIONS",
          payload: state.bankingTransactions.filter(
            (b) => transaction.id != b.id
          ),
        });

        resetConfirmDialog();
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const showFile = (transaction) => {
    api
      .get("/banking-transactions/" + transaction.id + "/upload-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  //Initial use effect:
  useEffect(() => {
    getBankAccounts();
  }, []);

  const getBankAccounts = () => {
    api
      .get("/bank-accounts")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_BANK_ACCOUNTS", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getBankingTransactions = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: [
        "BankAccount",
        "Invoice",
        "ProviderInvoice",
        "Remittance",
        "User",
      ],
    };

    state.filters.concept !== "" && (params.concept = state.filters.concept);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.importMin !== "" &&
      (params.importMin = Number(state.filters.importMin));
    state.filters.importMax !== "" &&
      (params.importMax = Number(state.filters.importMax));
    state.filters.bankAccountId !== "" &&
      (params.bankAccountId = state.filters.bankAccountId);

    api
      .get("/banking-transactions", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });

        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        if (response.data.length > 0) {
          response.data.forEach((transaction) => {
            transaction.auditDate == null && (transaction.auditDate = "");
          });

          //filter not selected banking transactions:
          let data = [];
          response.data.forEach((item) => {
            const exist = providerInvoice.BankingTransactions.some(
              (bt) => bt.id == item.id
            );
            if (!exist) data.push(item);
          });

          dispatch({ type: "SET_BANKING_TRANSACTIONS", payload: data });
        } else
          enqueueSnackbar(t("noBankingTransactions"), { variant: "warning" });
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_BANKING_TRANSACTIONS", payload: [] });
  };

  const closeDialog = () => {
    resetFilters();
    setOpen(state.selectedBankingTransactions);
  };

  const setConfirmDialogState = (bankingTransaction) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        isOpen: true,
        selectedTransaction: bankingTransaction,
      },
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        isOpen: false,
        selectedTransaction: [],
      },
    });
  };

  const changeConfirmDialog = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        isOpen: state,
      },
    });
  };

  return (
    <Container>
      <Dialog
        open={open}
        onClose={closeDialog}
        title={t("bankingTransactions")}
        maxWidth="xl"
      >
        <Grid item container xs={12} spacing={1} rowSpacing={2}>
          <Grid item>
            <TextInput
              label={t("concept")}
              name="concept"
              value={state.filters.concept}
              onChange={handleChangeFilter}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  getBankingTransactions();
                }
              }}
            />
          </Grid>
          <Grid item>
            <CustomDate
              label={t("dateFrom")}
              name="dateFrom"
              value={state.filters.dateFrom}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item>
            <CustomDate
              label={t("dateUntil")}
              name="dateUntil"
              value={state.filters.dateUntil}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item>
            <TextInput
              label={t("importMin")}
              name="importMin"
              value={state.filters.importMin}
              onChange={handleChangeFilter}
              type="number"
            />
          </Grid>
          <Grid item>
            <TextInput
              label={t("importMax")}
              name="importMax"
              value={state.filters.importMax}
              onChange={handleChangeFilter}
              type="number"
            />
          </Grid>
          <Grid item>
            <CustomSelect
              label={t("bankAccount")}
              name="bankAccountId"
              value={state.filters.bankAccountId}
              onChange={handleChangeFilter}
              options={[
                ...state.bankAccounts?.map((account) => ({
                  value: account.id,
                  label: account.name,
                })),
              ]}
            />
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={resetFilters}>{t("reset")}</Button>
              <SearchButton
                onClick={getBankingTransactions}
                loading={!state.options.loaded}
                disabled={
                  state.filters.bankAccountId.length === 0 ? true : false
                }
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={BANKING_TRANSACTIONS_COLUMNS}
              options={state.options}
              data={state.bankingTransactions}
            ></CustomTable>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <Divider />{" "}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("selectedTransactions") + ": "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {providerBankingTransactions?.map((transaction) => (
              <List key={transaction.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography>{transaction.concept}</Typography>
                  </CardContent>
                </Card>
              </List>
            ))}
          </Grid>
        </Grid>
      </Dialog>

      <ConfirmDialog
        title={state.confirmDialog.selectedTransaction?.concept || ""}
        open={state.confirmDialog.isOpen}
        setOpen={changeConfirmDialog}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={(confirmed) => {
          confirmed ? editBankingTransaction() : resetConfirmDialog();
        }}
      >
        <TextField
          fullWidth
          multiline
          variant="outlined"
          label={t("comment")}
          name={"comment"}
          value={state.confirmDialog.comments}
          onChange={(e) =>
            dispatch({
              type: "SET_COMMENTS_CONFIRM_DIALOG",
              payload: e.target.value,
            })
          }
          rows={2}
          InputLabelProps={{ shrink: true }}
        />
      </ConfirmDialog>
    </Container>
  );
};

export default BankingTransactionsDialog;
