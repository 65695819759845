import { Box, Typography } from "@mui/material";
import { ITEM_ICONS } from "../../Utilities";

const FormTitle = (props) => {
  const { shape, name } = props;
  const icon = ITEM_ICONS[shape];
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {icon}
      <Typography variant="h5" marginLeft={1}>{name}</Typography>
    </Box>
  );
};

export default FormTitle;
