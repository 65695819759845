import { Stack } from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import GeneralCollapsible from "../ItemCollapsibles/GeneralCollapsible";
import PositionCollapsible from "../ItemCollapsibles/PositionCollapsible";
import CameraCollapsible from "../ItemCollapsibles/CameraCollapsible";
import SizeCollapsible from "../ItemCollapsibles/SizeCollapsible";
import { FloorPlanContext } from "../../../FloorPlanPage";
import ActionButtons from "./ActionButtons";
import FormTitle from "./FormTitle";

const CameraForm = (props) => {
  const { item } = props;

  const { selectTile, setEditItem, editTile } = useContext(FloorPlanContext);

  const setName = (name) => setEditItem({ ...item, name });
  const setX = (x) => setEditItem({ ...item, x });
  const setY = (y) => setEditItem({ ...item, y });
  const setWidth = (width) => setEditItem({ ...item, width });

  const onEdit = async () => {
    await editTile({
      id: item.id,
      name: item.name,
      x: item.x,
      y: item.y,
      width: item.width,
    });
    selectTile(null);
  };

  return (
    <Stack spacing={2}>
      <FormTitle shape={item.shape} name={item.name} />
      <GeneralCollapsible name={item.name} setName={setName} />
      <CameraCollapsible />
      <PositionCollapsible x={item.x} setX={setX} y={item.y} setY={setY} />
      <SizeCollapsible radius={item.width} setRadius={setWidth} />
      <ActionButtons onCancel={() => selectTile(null)} onSave={onEdit} />
    </Stack>
  );
};

CameraForm.propTypes = {
  item: PropTypes.object,
};

export default CameraForm;
