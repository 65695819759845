import { Container, Grid, Icon, Typography } from "@mui/material";
import HandymanIcon from "@mui/icons-material/Handyman";

const MaintenancePage = ({ message }) => {
  return (
    <Container>
      <Grid container sx={{ height: "100vh" }} padding={2}>
        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item>
            <Icon sx={{ fontSize: "5rem" }}>
              <HandymanIcon sx={{ fontSize: "5rem" }} />
            </Icon>
          </Grid>
          <Grid item>
            <Typography variant="h5">{message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MaintenancePage;
