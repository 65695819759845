import { localeFormat } from "../../../utils/format";
import { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components & utils
import { generateURL } from "../../../utils/url";
import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";

import Page from "../../global/structure/Page";
import TabPanel from "../../global/structure/TabPanel";

import NonPaymentsTab from "./NonPaymentsTabs/NonPaymentsTab";
import RecuperationsTab from "./NonPaymentsTabs/RecuperationsTab";
import ManagementTab from "./NonPaymentsTabs/ManagementTab";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  currentTab: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function NonPaymentsPage() {
  const { user } = useContext(AppContext);

  const [t] = useTranslation("nonPayments");

  const query = useQuery();
  const history = useHistory();

  const initState = (state) => ({
    ...state,
    currentTab: parseInt(query.get("tab") ?? state.currentTab),
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const INVOICE_COLUMNS = [
    {
      key: "Customer.fullName",
      label: t("customerName"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/customer/" + item.customerId}>{value}</ButtonLink>
      ),
    },
    {
      key: "number",
      label: t("invoice"),
      sortType: "number",
      renderFunction: (value, item) => item.InvoiceSerie.name + value,
    },
    {
      key: "baseAmount",
      label: t("base"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "totalAmount",
      label: t("total"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "issueDate",
      label: t("issueDate"),
      sortType: "date",
    },
    {
      key: "nonPaymentDate",
      label: t("nonPaymentDate"),
      sortType: "date",
    },
  ];

  const CSV_HEADERS = [
    { label: t("customerName"), key: "name" },
    { label: t("lastCommentDate"), key: "lastCommentDate" },
    { label: t("totalDebt"), key: "totalDebt" },
    { label: t("pendingInvoices"), key: "Invoices.length" },
  ];

  const CSV_INVOICE_HEADERS = [
    { label: t("customerName"), key: "customerName" },
    { label: t("number"), key: "number" },
    { label: t("base"), key: "baseAmount" },
    { label: t("total"), key: "total" },
    { label: t("issueDate"), key: "issueDate" },
    { label: t("nonPaymentDate"), key: "nonPaymentDate" },
    { label: t("recuperationDate"), key: "recuperationDate" },
  ];

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "customerName", type: "string" },
    { name: "invoiceNumber", type: "string" },
    { name: "centerIds", type: "array" },
    { name: "hasNonPayments", type: "boolean" },
    { name: "number", type: "number" },
    { name: "dateUntil", type: "string" },
    { name: "dateFrom", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "recuperateUntil", type: "string" },
    { name: "recuperateFrom", type: "string" },
    { name: "uncollectible", type: "string" },
    { name: "billingError", type: "string" },
    { name: "recuperatorName", type: "string" },
    { name: "recuperateFrom", type: "string" },
    { name: "recuperateUntil", type: "string" },
  ];

  const handleTabChange = (event, value) => {
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: value,
    });
  };

  let tabs = [
    {
      label: t("nonPayments"),
      children: (
        <NonPaymentsTab
          currentTab={state.currentTab}
          history={history}
          CSV_INVOICE_HEADERS={CSV_INVOICE_HEADERS}
          INVOICE_COLUMNS={INVOICE_COLUMNS}
          FILTERS={FILTERS}
        />
      ),
    },
    {
      label: t("recuperations"),
      children: (
        <RecuperationsTab
          currentTab={state.currentTab}
          history={history}
          CSV_INVOICE_HEADERS={CSV_INVOICE_HEADERS}
          INVOICE_COLUMNS={INVOICE_COLUMNS}
          FILTERS={FILTERS}
        />
      ),
    },
  ];
  if (user.hasAction("VIEW_NONPAYMENT_MANAGEMENT")) {
    tabs.unshift({
      label: t("gestion"),
      children: (
        <ManagementTab
          currentTab={state.currentTab}
          history={history}
          CSV_HEADERS={CSV_HEADERS}
          FILTERS={FILTERS}
        />
      ),
    });
  }

  return (
    <Page browserTitle={t("nonPayments")} title={t("nonPayments")} paper={true}>
      <TabPanel
        selectedTab={state.currentTab}
        onChange={handleTabChange}
        tabs={tabs}
      />
    </Page>
  );
}
