import { useTranslation } from "react-i18next";
import { useEffect, useContext } from "react";

import AppContext from "../../../context/AppContext";

import { CallsPanel } from "./Panels/CallsPanel";
import { CashFlowPanel } from "./Panels/CashFlowPanel";
import { CenterReportPanel } from "./CenterReport/CenterReportPanel";
import { InvoicingPanel } from "./Invoicing/InvoicingPanel";
import { MeterPricePanel } from "./Panels/MeterPricePanel";
import { ProviderInvoicesPanel } from "./Panels/ProviderInvoicesPanel";
import { TrackingPanel } from "./Panels/TrackingPanel";
import CenterHistoricalPanel from "./Panels/CenterHistoricalPanel";
import Centers from "./Centers/Centers";
import ContractReportPanel from "./Panels/ContractReportPanel";
import Contracts from "./Contracts/Contracts";
import CustomersPanel from "./Panels/CustomersPanel";
import DashboardTabs from "./DashboardTabs";
import HistoricalStats from "./HistoricalStats/HistoricalStats";
import Marketing from "./Marketing/Marketing";
import NonPayments from "./NonPayments/NonPayments";
import OccupancyPanel from "./Panels/OccupancyPanel";
import ProviderInvoices from "./ProviderInvoices/ProviderInvoices";

const Dashboard = () => {
  const [t] = useTranslation("dashboard");
  const { user } = useContext(AppContext);

  //Initial useEffect
  useEffect(() => {
    document.title = t("dashboard");
  }, []);

  const PANELS = [
    {
      tabLabel: t("cashFlow"),
      content: <CashFlowPanel />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_CASH_FLOW"),
    },
    {
      tabLabel: t("timeMachine"),
      content: <OccupancyPanel />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_TIME_MACHINE"),
    },
    {
      tabLabel: t("centerReport"),
      content: <CenterReportPanel />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_CENTER_REPORT"),
    },
    {
      tabLabel: t("tracking"),
      content: <TrackingPanel />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_TRACKING"),
    },
    {
      tabLabel: t("centerHistorical"),
      content: <CenterHistoricalPanel />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_CENTER_HISTORICAL"),
    },
    {
      tabLabel: t("historicalStats"),
      content: <HistoricalStats />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_HISTORICAL_STATS"),
    },
    {
      tabLabel: t("centers"),
      content: <Centers />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_CENTERS"),
    },
    {
      tabLabel: t("contracts"),
      content: <Contracts />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_CONTRACTS"),
    },
    {
      tabLabel: t("nonPayments"),
      content: <NonPayments />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_NON_PAYMENTS"),
    },
    {
      tabLabel: t("marketing"),
      content: <Marketing />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_MARKETING"),
    },
    {
      tabLabel: t("invoicing"),
      content: <InvoicingPanel />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_INVOICING"),
    },
    {
      tabLabel: t("providerInvoices"),
      content: <ProviderInvoices />,
      action:
        user.hasAction("VIEW_DASHBOARD_ALL") ||
        user.hasAction("VIEW_DASHBOARD_PROVIDER_INVOICES"),
    },
  ].filter(Boolean);

  return <DashboardTabs panels={PANELS} />;
};

export default Dashboard;
