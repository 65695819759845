import DoorFrontIcon from "@mui/icons-material/DoorFront";
import PanoramaOutlinedIcon from "@mui/icons-material/PanoramaOutlined";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import TitleIcon from "@mui/icons-material/Title";
import VideocamIcon from "@mui/icons-material/Videocam";

import RectangleForm from "./PropertiesEditor/ItemForms/RectangleForm";
import ImageForm from "./PropertiesEditor/ItemForms/ImageForm";
import CameraForm from "./PropertiesEditor/ItemForms/CameraForm";
import TextForm from "./PropertiesEditor/ItemForms/TextForm";
import PolygonForm from "./PropertiesEditor/ItemForms/PolygonForm";
import DoorForm from "./PropertiesEditor/ItemForms/DoorForm";

export const DEFAULT_VALUES = {
  Rect: {
    shape: "Rect",
    x: "100",
    y: "100",
    width: "100",
    height: "100",
  },
  Image: {
    shape: "Image",
    x: "100",
    y: "100",
  },
  Polygon: {
    shape: "Polygon",
    x: "100",
    y: "100",
    points: [100, 100, 200, 100, 200, 200, 100, 200],
  },
  Text: {
    fontSize: "42",
    shape: "Text",
    text: "Text",
    x: "100",
    y: "100",
  },
  Door: { shape: "Door", x: "100", y: "100", doorId: "" },
  Camera: { shape: "Camera", x: "100", y: "100", cameraId: "" },
};

export const ITEM_ICONS = {
  Image: <PanoramaOutlinedIcon />,
  Rect: <RectangleOutlinedIcon />,
  Polygon: <TimelineIcon />,
  Text: <TitleIcon />,
  Door: <DoorFrontIcon />,
  Camera: <VideocamIcon />,
};

export const ITEM_SHAPES = [
  { value: "Rect", label: "rectangle" },
  { value: "Image", label: "image" },
  { value: "Polygon", label: "polygon" },
  { value: "Text", label: "text" },
  { value: "Door", label: "door" },
  { value: "Camera", label: "camera" },
];

export const ITEM_FORMS = {
  Image: <ImageForm />,
  Rect: <RectangleForm />,
  Polygon: <PolygonForm />,
  Text: <TextForm />,
  Door: <DoorForm />,
  Camera: <CameraForm />,
};
