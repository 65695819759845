import { Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CentersContext } from "../Centers";

import Filters from "../../../../global/structure/Filters";
import Page from "../../../../global/structure/Page";
import Select from "../../../../global/inputs/Select";
import Tabs from "../../../../global/structure/Tabs";

import InformationBox from "../../Components/InformationBox";

import { localeFormat } from "../../../../../utils/format";

import { CenterContractsScatter } from "../../Charts/CentersCharts";

import {
  NUT_CENTER_TYPE_ID,
  FRANCHISE_CENTER_TYPE_ID,
  OTHER_CENTER_TYPE_ID,
} from "../../../../../data/constants";

function ContractsTab() {
  const [t] = useTranslation("dashboard");
  const { filteredCenters } = useContext(CentersContext);

  return (
    <Page browserTitle={t("centers") + " | " + t("contracts")}>
      <Grid container spacing={3}>
        <Filters filters={[]} />
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <CenterContractsScatter centers={filteredCenters} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}

export default ContractsTab;
