import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import SellIcon from "@mui/icons-material/Sell";

function DataTypeToggle({
  contracts = true,
  invoicing = true,
  meters = true,
  pricePerMeter = true,
  value,
  onChange,
}) {
  const [t] = useTranslation("dashboard");

  const onChangeProtection = (event, newValue) => {
    if (newValue === null) return;
    onChange(event, newValue);
  };

  return (
    <ToggleButtonGroup exclusive value={value} onChange={onChangeProtection}>
      {contracts && (
        <ToggleButton size="small" value="contracts">
          <Tooltip title={t("contracts")}>
            <DescriptionIcon />
          </Tooltip>
        </ToggleButton>
      )}
      {invoicing && (
        <ToggleButton size="small" value="invoicing">
          <Tooltip title={t("invoicing")}>
            <AttachMoneyIcon />
          </Tooltip>
        </ToggleButton>
      )}
      {meters && (
        <ToggleButton size="small" value="meters">
          <Tooltip title={t("meters")}>
            <SquareFootIcon />
          </Tooltip>
        </ToggleButton>
      )}
      {pricePerMeter && (
        <ToggleButton size="small" value="pricePerMeter">
          <Tooltip title={t("pricePerMeter")}>
            <SellIcon />
          </Tooltip>
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}

export default DataTypeToggle;
