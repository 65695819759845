import { Box, Paper } from "@mui/material";
import { Group, Layer, Stage } from "react-konva";
import { useContext, useRef, useState } from "react";
import Tile from "./TileShapes/Tile";
import { FloorPlanContext } from "./FloorPlanPage";
import TileEdit from "./TileShapes/TileEdit";

const FloorPlan = (props) => {
  const { floorPlan, editTile, onEditTile } = props;
  const { selectTile } = useContext(FloorPlanContext);

  const [stageProps, setStageProps] = useState({
    x: 1,
    y: 1,
    scale: 1,
  });

  const ZOOM_RATIO = 0.9;

  const containerRef = useRef(null);

  const width = containerRef.current?.offsetWidth || 0;
  const height = containerRef.current?.offsetHeight || 0;

  const handleZoom = (event) => {
    event.evt.preventDefault();
    const stage = event.target.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const direction = event.evt.deltaY > 0 ? 1 : -1;
    const newScale =
      direction > 0 ? oldScale * ZOOM_RATIO : oldScale / ZOOM_RATIO;

    const mousePointToX = (pointer.x - stage.x()) / oldScale;
    const mousePointToY = (pointer.y - stage.y()) / oldScale;

    const newPosX = pointer.x - mousePointToX * newScale;
    const newPosY = pointer.y - mousePointToY * newScale;

    setStageProps({
      x: newPosX,
      y: newPosY,
      scale: newScale,
    });
  };

  return (
    <Paper>
      <Box height={600} ref={containerRef}>
        <Stage
          width={width}
          height={height}
          x={stageProps.x}
          y={stageProps.y}
          scaleX={stageProps.scale}
          scaleY={stageProps.scale}
          onWheel={handleZoom}
          draggable
        >
          <Layer>
            {floorPlan.FloorPlanLayers?.map((layer) => (
              <Group key={layer.id}>
                {layer.FloorPlanTiles?.map((tile) => {
                  return (
                    <Tile
                      key={tile.id}
                      tile={tile}
                      onClick={() => {
                        selectTile(tile.id);
                      }}
                    />
                  );
                })}
              </Group>
            ))}
          </Layer>
          <Layer>
            {editTile && <TileEdit tile={editTile} onEditTile={onEditTile} />}
          </Layer>
        </Stage>
      </Box>
    </Paper>
  );
};

export default FloorPlan;
