import { useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import GeoJSON from "geojson";
import mapToken from "../../../../data/mapboxToken";

mapboxgl.accessToken = mapToken;

function createGeoJSONCircle(center, radiusInKm, points = 64) {
  const coords = {
    latitude: center.latitude,
    longitude: center.longitude,
  };
  const kmInDegree = 110.574;

  const ret = [];
  const distanceX =
    radiusInKm / (kmInDegree * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = radiusInKm / kmInDegree;

  for (let i = 0; i < points; i++) {
    const theta = (i / points) * (2 * Math.PI);
    const x = distanceX * Math.cos(theta);
    const y = distanceY * Math.sin(theta);
    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [ret],
    },
  };
}

function CenterMap({ customers, center, filterRange, averageDistance }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) map.current.remove();

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.longitude, center.latitude],
      zoom: 9,
    });

    map.current.on("load", () => {
      new mapboxgl.Marker()
        .setLngLat([center.longitude, center.latitude])
        .addTo(map.current)
        .setPopup(new mapboxgl.Popup().setHTML(center.name));

      const geoJSONData = GeoJSON.parse(customers, {
        Point: ["latitude", "longitude"],
        include: ["color"],
      });

      map.current.addSource("customers", {
        type: "geojson",
        data: geoJSONData,
      });

      map.current.addLayer({
        id: "customer-points",
        type: "circle",
        source: "customers",
        paint: {
          "circle-color": ["get", "color"],
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#ffffff",
        },
      });

      if (filterRange) {
        const circleFeature = createGeoJSONCircle(center, filterRange);

        map.current.addSource("circle-area-range", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [circleFeature],
          },
        });

        map.current.addLayer({
          id: "circle-layer-range",
          type: "fill",
          source: "circle-area-range",
          paint: {
            "fill-color": "rgba(160, 160, 160, 0.2)",
            "fill-outline-color": "#808080",
          },
        });
      }

      if (averageDistance) {
        const circleFeature = createGeoJSONCircle(center, averageDistance);

        map.current.addSource("circle-area-avg", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [circleFeature],
          },
        });

        map.current.addLayer({
          id: "circle-layer-avg",
          type: "fill",
          source: "circle-area-avg",
          paint: {
            "fill-color": "rgba(0, 0, 0, 0)",
            "fill-outline-color": "#FF0000",
          },
        });
      }
    });
  }, [customers, center]);

  return <div ref={mapContainer} style={{ width: "100%", height: "400px" }} />;
}

export default CenterMap;
