import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { FloorPlanContext } from "../../../FloorPlanPage";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";
import Select from "../../../../../global/inputs/Select";

const BoxCollapsible = (props) => {
  const [t] = useTranslation("floorPlans");
  const { box } = props;
  const { boxes } = useContext(FloorPlanContext);
  const { editItem, setEditItem, setTileAssociation } =
    useContext(FloorPlanContext);

  const [selectedValue, setSelectedValue] = useState(box?.id || "");

  const handleOnChange = async (e) => {
    setSelectedValue(e.target.value);
    await setTileAssociation(editItem?.id, e.target.value)
      .then(() => {})
      .catch((error) => {
        setSelectedValue(box?.id || "");
      });
    setTileNameFromBox(e.target.value);
  };

  const setTileNameFromBox = (boxId) => {
    let box = boxes.find((b) => b.id == boxId);
    if (box) {
      setEditItem({ ...editItem, name: box.name });
    }
  };

  return (
    <ItemCollapsibleTemplate title={t("box")}>
      <Grid container spacing={1}>
        <Grid item>
          <Select
            searchable
            value={selectedValue}
            options={[
              { value: "", label: t("none") },
              ...boxes.map((box) => ({
                value: box.id,
                label: box.name,
              })),
            ]}
            onChange={handleOnChange}
          />
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

BoxCollapsible.propTypes = {};

export default BoxCollapsible;
