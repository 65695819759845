import { Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FloorPlanContext } from "../../../FloorPlanPage";
import AssociationsSelect from "../../AssociationsSelect";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

const BoxCollapsible = (props) => {
  const [t] = useTranslation("floorPlans");
  const { door } = props;
  const { doors } = useContext(FloorPlanContext);

  return (
    <ItemCollapsibleTemplate title={t("door")}>
      <Grid container spacing={1}>
        <Grid item>
          <AssociationsSelect
            label={""}
            value={door || ""}
            options={doors
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((door) => ({
                value: door.id,
                label: door.name,
              }))}
          />
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

BoxCollapsible.propTypes = {};

export default BoxCollapsible;
