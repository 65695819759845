import React, { useContext, useEffect, useReducer, createContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { generateURL } from "../../../utils/url";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AppContext from "../../../context/AppContext";
import Page from "../../global/structure/Page";
import Tabs from "../../global/structure/Tabs";

import ActionsTab from "./Tabs/ActionsTab";
import RolesTab from "./Tabs/RolesTab";
import PagesTab from "./Tabs/PagesTab";

export const RolesContext = createContext();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_ACTION_ROLE":
      return { ...state, roles: action.payload };
    case "ADD_PAGE_ROLE":
      return { ...state, roles: action.payload };
    case "REMOVE_ACTION_ROLE":
      return { ...state, roles: action.payload };
    case "REMOVE_PAGE_ROLE":
      return { ...state, roles: action.payload };
    case "SET_ACTIONS":
      return { ...state, actions: action.payload };
    case "SET_LOADED":
      return { ...state, isLoading: !state.isLoading };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_PAGES":
      return { ...state, pages: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const initialState = {
  actions: [],
  roles: [],
  pages: [],

  isLoading: false,
};

export default function RolesPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();

  const [tErrors] = useTranslation("errors");
  const [t] = useTranslation("roles");

  const initState = (state) => ({
    ...state,
    currentTab: parseInt(query.get("tab")),
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  //Initial useEffect
  useEffect(() => {
    getActions();
    getPages();
  }, []);

  const getRoles = (filters) => {
    dispatch({ type: "SET_LOADED" });

    //Change url parameters
    const url = generateURL("/app/roles", { tab: 0, ...filters });
    history.push(url);

    let params = { include: ["Action", "Page"], name: filters.role };

    api
      .get("/roles", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        if (response.data.length === 0) {
          enqueueSnackbar(t("noRoles"), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_ROLES", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getPages = () => {
    api
      .get("/pages")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        if (response.data.length === 0) {
          enqueueSnackbar(t("noPages"), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_PAGES", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getActions = () => {
    api
      .get("/actions")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        if (response.data.length === 0) {
          enqueueSnackbar(t("noActions"), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_ACTIONS", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const createRole = (data) => {
    api
      .post("/roles/create", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({
          type: "SET_ROLES",
          payload: [...state.roles, response.data],
        });
        enqueueSnackbar(t("roleCreateSuccess"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const editRolePage = (value, role, page) => {
    const data = { id: page.id };
    if (value === true) createRolePage(role.id, page, data);
    else deleteRolePage(role.id, data);
  };

  const createRolePage = (roleId, page, data) => {
    api
      .post("/roles/" + roleId + "/page", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        let roles = state.roles;
        roles.forEach((item) => {
          if (item.id === roleId) {
            item.Pages.push(page);
          }
        });
        dispatch({
          type: "ADD_PAGE_ROLE",
          payload: roles,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const deleteRolePage = (roleId, data) => {
    api
      .delete("/roles/" + roleId + "/page", {
        data,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        let roles = state.roles;
        roles.forEach((item) => {
          if (item.id === roleId) {
            item.Pages = item.Pages.filter((arrPage) => arrPage.id !== data.id);
          }
        });
        dispatch({
          type: "REMOVE_PAGE_ROLE",
          payload: roles,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const editRoleAction = (value, role, action) => {
    let data = { id: action.id };
    if (value === true) createRoleAction(role.id, action, data);
    else deleteRoleAction(role.id, data);
  };

  const createRoleAction = (roleId, action, data) => {
    api
      .post("/roles/" + roleId + "/action", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        let roles = state.roles;
        roles.forEach((item, i) => {
          if (item.id === roleId) {
            item.Actions.push(action);
          }
        });
        dispatch({
          type: "ADD_ACTION_ROLE",
          payload: roles,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const deleteRoleAction = (roleId, data) => {
    api
      .delete("/roles/" + roleId + "/action", { data })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        let roles = state.roles;
        roles.forEach((item) => {
          if (item.id === roleId) {
            item.Actions = item.Actions.filter((item) => item.id !== data.id);
            dispatch({
              type: "REMOVE_ACTION_ROLE",
              payload: roles,
            });
          }
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const duplicateRole = (selectedRoleId, data) => {
    api
      .post("/roles/duplicate/" + selectedRoleId, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({
          type: "SET_ROLES",
          payload: [...state.roles, response.data],
        });
        enqueueSnackbar(t("roleDuplicatedSuccess"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const handleChangeTab = (newTab) => {
    history.push(generateURL("/app/roles", { tab: newTab }));
  };

  return (
    <RolesContext.Provider
      value={{
        ...state,
        createRole,
        duplicateRole,
        editRoleAction,
        editRolePage,
        getRoles,
      }}
    >
      <Page browserTitle={t("rolesPage")} paper={true} title={t("roles")}>
        <Tabs
          currentTab={parseInt(query.get("tab"))}
          onTabChange={(event, value) => handleChangeTab(value)}
          tabs={[
            {
              label: t("roles"),
              content: <RolesTab />,
            },
            {
              label: t("pages"),
              content: <PagesTab />,
            },
            {
              label: t("actions"),
              content: <ActionsTab />,
            },
          ]}
        />
      </Page>
    </RolesContext.Provider>
  );
}
