import React, { useContext, createContext, useEffect, useReducer } from "react";
import AppContext from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import InvoicingTab from "./Tabs/InvoicingTab";

export const InvoicingContext = createContext();

const initialState = {
  customers: [],
  customerTypes: [],
  invoiceSeries: [],
  merchantableTypes: [],
  paymentMethods: [],

  isLoading: false,

  invoicingTabData: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER_TYPES":
      return { ...state, customerTypes: action.payload };
    case "SET_INVOICING_TAB_DATA":
      return {
        ...state,
        invoicingTabData: action.payload,
      };
    case "SET_LOADING":
      return { ...state, isLoading: !state.isLoading };
    case "SET_MERCHANTABLE_TYPES":
      return { ...state, merchantableTypes: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_SERIES":
      return { ...state, invoiceSeries: action.payload };
    default:
      throw new Error();
  }
}

export const InvoicingPanel = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  const CENTER_TYPES = {
    0: t("NUT"),
    1: t("franchise"),
    2: t("other"),
  };

  useEffect(() => {
    getCustomers();
    getCustomerTypes();
    getInvoiceSeries();
    getMerchantableTypes();
    getPaymentMethods();
  }, []);

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_CUSTOMERS", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomerTypes = () => {
    api
      .get("/customer-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CUSTOMER_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_SERIES", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_MERCHANTABLE_TYPES", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_PAYMENT_METHODS", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoicingTabData = (filters) => {
    dispatch({ type: "SET_LOADING" });

    const params = {
      include: [
        "CustomerType",
        "InvoiceSerie",
        "MerchantableType",
        "PaymentMethod",
      ],
      ...filters,
    };

    api
      .get("/invoice-items", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        if (response.data.length === 0) {
          enqueueSnackbar(t("noData"), { variant: "warning" });
          return;
        }
        let invoicesId = [];
        const parsedData = response.data.map((item) => {
          const isAdded = invoicesId.includes(item.invoiceId);
          !isAdded && invoicesId.push(item.invoiceId);
          return {
            
            billingError: item.Invoice.billingError,
            center: item.Merchantable.Center?.name,
            centerType: CENTER_TYPES[item.Merchantable.Center?.type],
            customer: item.Invoice.Customer?.fullName,
            customerType: item.Invoice.Customer?.CustomerType.name,
            concept: item.concept,
            id: item.id,
            invoice: item.Invoice.number,
            issueDate: item.Invoice.issueDate,
            issueMonth: item.Invoice.issueDate.slice(0, 7),
            serie: item.Invoice.InvoiceSerie?.name,
            state: item.state,
            paymentMethod: item.Invoice.PaymentMethod?.name,
            amount: item.amount,
            pricePerUnit: item.pricePerUnit,
            merchantableType: item.Merchantable.MerchantableType?.name,
            units: item.units,
            isKeyAccount: item.Invoice.Customer?.isKeyAccount,
          };
        });

        parsedData.totalAmount = parsedData.reduce(
          (acc, item) => acc + item.amount,
          0
        );
        parsedData.numberOfItems = parsedData.length;
        parsedData.numberOfInvoices = invoicesId.length;

        dispatch({ type: "SET_INVOICING_TAB_DATA", payload: parsedData });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING" });
      });
  };

  return (
    <InvoicingContext.Provider value={{ state, getInvoicingTabData }}>
      <Page browserTitle={t("invoicing")} paper={true}>
        <Tabs
          tabs={[
            {
              label: t("invoicing"),
              content: <InvoicingTab />,
            },
          ]}
        />
      </Page>
    </InvoicingContext.Provider>
  );
};
