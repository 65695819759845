// Mui
import {
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
  Grid,
  Fade,
  CircularProgress,
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import GetAppIcon from "@mui/icons-material/GetApp";

// Others
import { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom
import { CustomTable } from "../../CustomTable";
import ItemsSummary from "../../ItemsSummary";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";

// Context
import AppContext from "../../../context/AppContext";

// Utils
import { downloadFile } from "../../../utils/file";
import { getMonthsFromPeriodicityId } from "../../../utils/date";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";

// Inputs
import ButtonLink from "../../Inputs/ButtonLink";
import CenterSelect from "../../Inputs/CenterSelect";
import CreateButton from "../../Inputs/CreateButton";
import CustomDate from "../../Inputs/CustomDate";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import SearchButton from "../../Inputs/SearchButton";
import Select from "../../global/inputs/Select";
import TextInput from "../../Inputs/TextInput";

// Constants
import {
  ACTIVE_CONTRACT_STATE_ID,
  CUSTOMER_ROLE_ID,
  ENDED_CONTRACT_STATE_ID,
  GENERAL_VAT_ES,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  PENDING_CONTRACT_STATE_ID,
} from "../../../data/constants";
import ContractStateBox from "./ContractStateBox";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  actionLoading: false,
  boxes: [],
  contracts: [],
  customer: "",
  customers: [],
  filters: {
    autoSearch: "false",
    boxId: "",
    center: [],
    customerId: "",
    dateFrom: "",
    dateOfPayment: "",
    dateUntil: "",
    isFrozen: "",
    isKeyAccount: "",
    isSigned: "",
    order: "desc",
    orderBy: "startDate",
    paymentMethod: [],
    periodicity: [],
    state: [PENDING_CONTRACT_STATE_ID, ACTIVE_CONTRACT_STATE_ID],
    text: "",
  },
  paymentMethods: [],
  periodicities: [],
  options: {
    rowlink: "contract",
    loaded: true,
  },
  paymentDays: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_PERIODS":
      return { ...state, periodicities: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_PAYMENT_DAY":
      return { ...state, paymentDays: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ACTION_LOADING":
      const index = state.contracts.findIndex(
        (contract) => contract.id === action.payload.id
      );

      return {
        ...state,
        contracts: [
          ...state.contracts.slice(0, index),
          {
            ...state.contracts[index],
            [action.payload.actionType]: action.payload.loading,
          },
          ...state.contracts.slice(index + 1),
        ],
        actionLoading: !state.actionLoading,
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER_FILTER":
      return { ...state, customer: action.payload };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters, customer: "" };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function ContractsPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("contracts");
  const [tErrors] = useTranslation("errors");

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "boxId", type: "number" },
    { name: "center", type: "array" },
    { name: "customerId", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateOfPayment", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "isFrozen", type: "number" },
    { name: "isKeyAccount", type: "boolean" },
    { name: "order", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "paymentMethod", type: "array" },
    { name: "periodicity", type: "array" },
    { name: "state", type: "array" },
    { name: "text", type: "string" },
    { name: "isSigned", type: "boolean" },
  ];

  const CONTRACT_COLUMNS = [
    { label: "ID", key: "publicId" },
    {
      label: t("center"),
      key: "Box.Center.name",
      sortType: "string",
      renderFunction: (value, item) =>
        user.Role?.id && user.Role?.id !== CUSTOMER_ROLE_ID ? (
          <ButtonLink to={"/app/center/" + item.Box.centerId} size="small">
            {value}
          </ButtonLink>
        ) : (
          <Typography variant="body2">{value}</Typography>
        ),
    },
    {
      label: t("box"),
      key: "Box.name",
      sortType: "string",
      renderFunction: (value, item) =>
        user.Role?.id && user.Role?.id !== CUSTOMER_ROLE_ID ? (
          <ButtonLink to={"/app/box/" + item.Box?.id} size="small">
            {value}
          </ButtonLink>
        ) : (
          <Typography variant="body2">{value}</Typography>
        ),
    },
    {
      label: t("customer"),
      key: "Customer.name",
      sortType: "string",
      renderFunction: (value, item) =>
        user.Role?.id && user.Role?.id !== CUSTOMER_ROLE_ID ? (
          <ButtonLink to={"/app/customer/" + item.Customer?.id} size="small">
            {Number(item.Customer?.customerTypeId) ===
            INDIVIDUAL_CUSTOMER_TYPE_ID
              ? value + " " + item.Customer?.surnames
              : value}
          </ButtonLink>
        ) : (
          <Typography variant="body2">{value}</Typography>
        ),
    },
    { label: t("startDate"), key: "startDate", sortType: "string" },
    { label: t("endDate"), key: "endDate", sortType: "string" },
    {
      label: t("createDate"),
      key: "createdAt",
      sortType: "string",
      renderFunction: (value) => value.split("T")[0],
    },
    {
      label: t("periodicity"),
      key: "Periodicity.name",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    { label: t("payDay"), key: "dateOfPayment", sortType: "number" },
    {
      label: t("finalPrice"),
      key: "finalPrice",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      label: t("formOfPayment"),
      key: "PaymentMethod.name",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      label: t("state"),
      key: "state",
      sortType: "other",
      renderFunction: (value, item) => (
        <ContractStateBox
          state={value}
          isFrozen={item?.isFrozen}
          hasFreezePermission={user.hasAction("FREEZE_CONTRACT")}
        />
      ),
    },
    {
      label: t("signed"),
      key: "isSigned",
      sortType: "other",
      renderFunction: (value, item) =>
        value ? (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title={t("hasDigitalSignature")}>
                <CheckIcon
                  color={item.hasDigitalSignature ? "success" : "disabled"}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("hasSignedFile")}>
                <CheckIcon
                  color={item.hasSignedFile ? "success" : "disabled"}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Tooltip title={t("isNotSigned")}>
              <ClearIcon color="error" />
            </Tooltip>
          </Grid>
        ),
    },
    {
      label: t("actions"),
      key: "actions",
      sortType: "other",
      renderFunction: (value, item) => (
        <ButtonGroup>
          {user.Role?.id && user.Role?.id !== CUSTOMER_ROLE_ID && (
            <Tooltip title={t("emailPdf")}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  emailPdf(item.id);
                }}
              >
                <EmailIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t("downloadPdf")}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
                downloadPdf(item.id);
              }}
              disabled={state.actionLoading}
            >
              {item.downloadPdf ? (
                <Fade in={item.downloadPdf}>
                  <CircularProgress size="1.5rem" />
                </Fade>
              ) : (
                <GetAppIcon />
              )}
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  // Provisional column for user: JOAQUINA (94), Aurora (168) and Dolors (145)
  if ([94, 145, 168].includes(user.id)) {
    CONTRACT_COLUMNS.push({
      label: "numero de tarjeta - caducidad",
      key: "paycard",
      sortType: "string",
      renderFunction: (value, item) => {
        return item.PayCard?.number
          ? item.PayCard?.number +
              " - " +
              item.PayCard?.expirationMonth +
              "/" +
              item.PayCard?.expirationYear
          : "";
      },
    });
  }

  const CSV_HEADERS = [
    { label: "ID", key: "publicId" },
    { label: t("center"), key: "center" },
    { label: t("customer"), key: "customer" },
    { label: t("customerEmails"), key: "customerEmails" },
    { label: t("box"), key: "box" },
    { label: t("createDate"), key: "createdAt" },
    { label: t("startDate"), key: "startDate" },
    { label: t("endDate"), key: "endDate" },
    { label: t("dateOfPayment"), key: "dateOfPayment" },
    { label: t("periodicity"), key: "periodicityName" },
    { label: t("meters"), key: "meters" },
    { label: t("pricePerMeter"), key: "pricePerMeter", sortType: "string" },
    {
      label: t("pricePerMeterWithVAT"),
      key: "formattedPricePerMeterWithVAT",
      sortType: "string",
    },
    { label: t("price"), key: "formattedPrice", sortType: "string" },
    {
      label: t("priceWithVAT"),
      key: "formattedPriceWithVAT",
      sortType: "string",
    },
    { label: t("formOfPayment"), key: "formOfPayment" },
    { label: t("frozen"), key: "frozen" },
    { label: t("state"), key: "stateName" },
    { label: t("signed"), key: "signed" },
    { label: t("createdBy"), key: "createdBy" },
    { label: t("comments"), key: "comments" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });
  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const ITEMS_SUMMARY = [
    {
      translatedText: t("numberOfContracts"),
      value: localeFormat(state.contracts?.length),
    },
    {
      translatedText: t("totalMeters"),
      value:
        localeFormat(
          state.contracts.reduce((total, element) => total + element.meters, 0)
        ) + " m²",
    },
    {
      translatedText: t("monthlyAmount"),
      value:
        localeFormat(
          state.contracts.reduce(
            (total, element) =>
              total + element.meters * element.pricePerMeterWithVAT,
            0
          )
        ) + " €",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    getPaymentMethods();
    getBoxes();
    getPeriodicities();
    getPaymentDays();
    getCustomers();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getContracts();
  }, [state.filters.autoSearch]);

  const getContracts = () => {
    //Change url parameters:
    const url = generateURL("/app/contracts", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["Box", "Center", "Customer", "CustomerEmail", "User"],
    };

    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);

    state.filters.center.length && (params.centerId = state.filters.center);
    state.filters.boxId !== "" && (params.boxId = state.filters.boxId);
    state.filters.text !== "" && (params.text = state.filters.text);

    state.filters.state.length > 0 && (params.state = state.filters.state);
    state.filters.periodicity !== "" &&
      (params.periodicity = state.filters.periodicity);
    state.filters.paymentMethod !== "" &&
      (params.paymentMethod = state.filters.paymentMethod);
    state.filters.dateOfPayment !== "" &&
      (params.dateOfPayment = state.filters.dateOfPayment);
    state.filters.customerId !== "" &&
      (params.customerId = state.filters.customerId);
    state.filters.isFrozen &&
      (params.isFrozen = state.filters.isFrozen === 1 ? 1 : 0);
    state.filters.isKeyAccount !== "" &&
      (params.isKeyAccount = state.filters.isKeyAccount);
    state.filters.isSigned !== "" && (params.isSigned = state.filters.isSigned);

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          response.data.forEach((contract) => {
            switch (contract.state) {
              case PENDING_CONTRACT_STATE_ID:
                contract.stateName = t("pending");
                break;
              case ACTIVE_CONTRACT_STATE_ID:
                contract.stateName = t("active");
                break;
              case ENDED_CONTRACT_STATE_ID:
                contract.stateName = t("ended");
                break;
              default:
                contract.stateName = "?";
            }

            contract.pricePerMeterWithVAT =
              contract.pricePerMeter * (1 + GENERAL_VAT_ES / 100);
            contract.formattedPricePerMeterWithVAT = localeFormat(
              contract.pricePerMeter * (1 + GENERAL_VAT_ES / 100)
            );
            contract.priceWithVAT = contract.price * (1 + GENERAL_VAT_ES / 100);
            contract.formattedPriceWithVAT = localeFormat(
              contract.price * (1 + GENERAL_VAT_ES / 100)
            );
            contract.formattedPrice = localeFormat(contract.price);
            contract.frozen = contract.isFrozen ? t("yes") : t("no");
            contract.signed =
              contract.hasDigitalSignature || contract.hasSignedFile
                ? t("yes")
                : t("no");
            contract.center = contract.Box?.Center?.name;
            contract.customer = contract.Customer?.fullName;
            contract.customerEmails = contract.Customer?.CustomerEmails?.map(
              (email) => email.email
            )?.join("; ");
            contract.box = contract.Box?.name;
            contract.periodicityName = t(contract.Periodicity?.name);
            contract.formOfPayment = contract.PaymentMethod?.name;
            contract.createdBy = contract.creator?.name;
          });

          if (response.data.length === 0) {
            enqueueSnackbar(t("noContracts"), {
              variant: "info",
              preventDuplicate: true,
            });
          }

          response.data.forEach(
            (contract) =>
              (contract.finalPrice =
                contract.pricePerMeter *
                contract.meters *
                (1 + contract.taxes / 100) *
                getMonthsFromPeriodicityId(contract.periodicity))
          );

          dispatch({
            type: "SET_CONTRACTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (state.filters.customerId) {
            const customer = response.data.find(
              (customer) => customer.id === Number(state.filters.customerId)
            );
            dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
          }

          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getContracts();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBoxes = () => {
    api
      .get("/boxes")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_BOXES",
              payload: response.data,
            });
          } else {
            enqueueSnackbar(t("noBoxes"), { variant: "info" });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPeriodicities = () => {
    api
      .get("/periods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_PERIODS",
              payload: response.data,
            });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const days = response.data.map((day) => day.day);
          dispatch({ type: "SET_PAYMENT_DAY", payload: days });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const emailPdf = (id) => {
    dispatch({
      type: "SET_ACTION_LOADING",
      payload: { id: id, actionType: "emailPdf", loading: true },
    });
    api
      .post("/contracts/" + id + "/send-email")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSent"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_ACTION_LOADING",
          payload: { id: id, actionType: "emailPdf", loading: false },
        });
      });
  };

  const downloadPdf = (id) => {
    dispatch({
      type: "SET_ACTION_LOADING",
      payload: { id: id, actionType: "downloadPdf", loading: true },
    });
    api
      .get("/contracts/" + id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/pdf",
          });
          downloadFile(file);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_ACTION_LOADING",
          payload: { id: id, actionType: "downloadPdf", loading: false },
        });
      });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_CONTRACTS", payload: [] });
  };

  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  return (
    <Page title={t("contracts")} browserTitle={t("contractsPage")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                id="nameFilter"
                label={t("search")}
                value={state.filters.text}
                name="text"
                onChange={handleChangeFilter}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <CustomDate
                label={t("dateFrom")}
                InputLabelProps={{ shrink: true }}
                value={state.filters.dateFrom}
                onChange={handleChangeFilter}
                name="dateFrom"
                inputProps={{
                  // only needs the first 16 characters in the date string
                  min: new Date().toISOString().slice(0, 16),
                }}
              />,
              <CustomDate
                label={t("dateUntil")}
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateUntil}
                onChange={handleChangeFilter}
                name="dateUntil"
              />,
              user.Role?.id && user.Role?.id !== CUSTOMER_ROLE_ID && (
                <Autocomplete
                  size="large"
                  sx={{ minWidth: "200px" }}
                  options={state.customers}
                  getOptionLabel={(customer) => customer.fullName || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.nif === value.nif || null
                  }
                  filterOptions={filterOptions}
                  value={state.customer || null}
                  onChange={(e, customer) => {
                    handleChangeFilter({
                      target: {
                        value: customer ? customer.id : "",
                        name: "customerId",
                      },
                    });
                    dispatch({
                      type: "SET_CUSTOMER_FILTER",
                      payload: customer,
                    });
                  }}
                  name="customer"
                  renderInput={(params) => (
                    <TextInput {...params} size="small" label={t("customer")} />
                  )}
                />
              ),
              <CenterSelect
                multiple
                name="center"
                onChange={(e) => {
                  handleChangeFilter(e);
                  handleChangeFilter({ target: { name: "boxId", value: "" } });
                }}
                value={state.filters.center}
              />,
              <Select
                searchable
                name="boxId"
                label={t("box")}
                value={state.filters.boxId}
                options={state.boxes
                  .filter(
                    (box) =>
                      state.filters.center !== "" &&
                      Number(state.filters.center) === box.centerId
                  )
                  .map((box) => ({
                    value: box.id,
                    label: box.name,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                disabled={state.filters.center?.length !== 1}
                onChange={handleChangeFilter}
              />,
              <Select
                name="periodicity"
                onChange={handleChangeFilter}
                label={t("periodicity")}
                value={state.filters.periodicity}
                multiple
                options={state.periodicities.map((periodicity) => ({
                  value: periodicity.id,
                  label: t(periodicity.name),
                }))}
              />,
              <Select
                name="dateOfPayment"
                onChange={handleChangeFilter}
                label={t("dateOfPayment")}
                value={state.filters.dateOfPayment}
                options={[
                  { value: "", label: t("all") },
                  { value: -1, label: t("notAssigned") },
                  ...state.paymentDays?.map((day) => ({
                    value: day,
                    label: day,
                  })),
                ]}
              />,
              <Select
                name="paymentMethod"
                onChange={handleChangeFilter}
                label={t("formOfPayment")}
                value={state.filters.paymentMethod}
                multiple
                options={state.paymentMethods.map((method) => ({
                  value: method.id,
                  label: t(method.name),
                }))}
              />,
              <Select
                name="state"
                onChange={handleChangeFilter}
                label={t("state")}
                value={state.filters.state}
                options={[
                  { value: PENDING_CONTRACT_STATE_ID, label: t("pending") },
                  { value: ACTIVE_CONTRACT_STATE_ID, label: t("active") },
                  { value: ENDED_CONTRACT_STATE_ID, label: t("ended") },
                ]}
                multiple
              />,
              <Select
                name="isFrozen"
                onChange={handleChangeFilter}
                label={t("frozenContract")}
                value={state.filters.isFrozen}
                options={[
                  { value: 0, label: t("all") },
                  { value: 1, label: t("frozen") },
                  { value: 2, label: t("notFrozen") },
                ]}
              />,
              <Select
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
                label={t("keyAccount")}
                name="isKeyAccount"
                onChange={handleChangeFilter}
                value={state.filters.isKeyAccount}
              />,
              <Select
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
                label={t("signed")}
                name="isSigned"
                onChange={handleChangeFilter}
                value={state.filters.isSigned}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ExportButton
              data={exportDataParse(state.contracts, CSV_HEADERS)}
              display={user.hasAction("EXPORT_DATA")}
            />
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            {user.hasAction("EDIT_CONTRACT_TYPES") && (
              <ButtonLink to={"/app/contract-types"}>
                {t("manageContractTypes")}
              </ButtonLink>
            )}

            {user.hasAction("RESOLVE_CANCELLATION_REQUESTS") && (
              <ButtonLink to={"/app/cancellation-requests"}>
                {t("cancellationRequests")}
              </ButtonLink>
            )}

            <ButtonLink to={"/app/altas-bajas"}>
              {t("moveInsAndMoveOuts")}
            </ButtonLink>
          </ButtonGroup>
        </Grid>

        <Grid item>
          <CreateButton
            action={"CREATE_CONTRACTS"}
            label={t("createContract")}
            link={"/contract/create"}
          />
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary gridItems={ITEMS_SUMMARY} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={CONTRACT_COLUMNS}
            data={state.contracts?.map((contract) => {
              contract.actions = "";

              return contract;
            })}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/contracts", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
