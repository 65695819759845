//import CameraStream from "../../pages/Cameras/CameraStraem";
import Dialog from "../Dialog";

const CameraStreamDialog = (props) => {
  const { open, onClose, camera } = props;

  return (
    <Dialog
      title={"cameras " + camera?.name}
      open={open}
      onClose={onClose}
      draggable
      hideBackdrop
    >
      {/*<CameraStream camera={camera} height="200px" width="300px"  />*/}
    </Dialog>
  );
};

export default CameraStreamDialog;
