import accessControl from "./accessControl.json";
import acquisitions from "./acquisitions.json";
import app from "./app.json";
import bankingTransactions from "./bankingTransactions.json";
import banks from "./banks.json";
import boxes from "./boxes.json";
import calls from "./calls.json";
import cameras from "./cameras.json";
import centers from "./centers.json";
import contracts from "./contracts.json";
import country from "./country.json";
import customers from "./customers.json";
import dashboard from "./dashboard.json";
import dashboardCenterReport from "./dashboard/centerReport.json";
import documents from "./documents.json";
import errors from "./errors.json";
import expenseTypes from "./expenseTypes.json";
import floorPlans from "./floorPlans.json";
import invoices from "./invoices.json";
import landing from "./landing.json";
import login from "./login.json";
import merchantables from "./merchantables.json";
import navbar from "./navbar.json";
import nonPayments from "./nonPayments.json";
import occupancy from "./occupancy.json";
import others from "./others.json";
import profile from "./profile.json";
import promotion from "./promotion.json";
import providerInvoices from "./providerInvoices.json";
import providers from "./providers.json";
import receipts from "./receipts.json";
import register from "./register.json";
import remittances from "./remittances.json";
import revenueManagement from "./revenueManagement.json";
import roles from "./roles.json";
import settings from "./settings.json";
import settlements from "./settlements.json";
import transferPayments from "./transferPayments.json";
import users from "./users.json";

const translations = {
  accessControl,
  acquisitions,
  app,
  bankingTransactions,
  banks,
  boxes,
  calls,
  cameras,
  centers,
  contracts,
  country,
  customers,
  dashboard,
  dashboardCenterReport,
  documents,
  errors,
  expenseTypes,
  floorPlans,
  invoices,
  landing,
  login,
  merchantables,
  navbar,
  nonPayments,
  occupancy,
  others,
  profile,
  promotion,
  providerInvoices,
  providers,
  receipts,
  register,
  remittances,
  revenueManagement,
  roles,
  settings,
  settlements,
  transferPayments,
  users,
};

export default translations;
