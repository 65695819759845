import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import GeneralTab from "./Tabs/GeneralTab";

const Contracts = () => {
  const [t] = useTranslation("dashboard");

  return (
    <Page paper>
      <Tabs
        tabs={[
          {
            label: t("general"),
            content: (
              <Grid item>
                <GeneralTab />
              </Grid>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default Contracts;
