import { ButtonGroup, Grid } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { MarketingContext } from "../Marketing";
import AppContext from "../../../../../context/AppContext";

import Filters from "../../../../global/structure/Filters";
import Page from "../../../../global/structure/Page";
import Select from "../../../../global/inputs/Select";

import { CustomTable } from "../../../../CustomTable";
import { localeFormat } from "../../../../../utils/format";
import BoxClusterChip from "../../../../BoxClusterChip";
import ButtonLink from "../../../../Inputs/ButtonLink";
import CenterTypesSelect from "../../../../Inputs/CenterTypeSelect";
import CustomButton from "../../../../Inputs/CustomButton";
import DateInput from "../../../../Inputs/CustomDate";
import InformationBox from "../../Components/InformationBox";

import {
  DIRECT_TRAFFIC_SOURCE,
  NO_VALUE_SOURCE,
  OFFLINE_SOURCE,
  ORGANIC_SEARCH_SOURCE,
  OTHER_CAMPAIGNS_SOURCE,
  PAID_SEARCH_SOURCE,
  REFERRALS_SOURCE,
  SOCIAL_MEDIA_SOURCE,
} from "../../../../../data/constants";
import {
  firstDateCurrentMonth,
  formatDate,
  getAge,
} from "../../../../../utils/date";
import {
  HiringLeadTime,
  HistoricalPricePerMeter,
  HistoricalSalesByPaymentMethod,
  SalesByAges,
  SalesByCenter,
  SalesByClusters,
  SalesByHours,
  SalesByPlatform,
  SalesBySource,
  SalesByWeekDay,
  SalesHistory,
} from "../../Charts/MarketingCharts";
import {
  // roles
  CUSTOMER_ROLE_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  WEB_USER_ID,

  // platforms
  APP_SOURCE_ID,
  ERP_SOURCE_ID,
  WEB_SOURCE_ID,

  // genders
  FEMALE_GENDER_ID,
  MALE_GENDER_ID,
  OTHER_GENDER_ID,

  // payment methods
  CASH_PAYMENT_METHOD_ID,
  CONSOLIDATION_PAYMENT_METHOD_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
} from "../../../../../data/constants";
import ExportButton, {
  exportDataParse,
} from "../../../../global/inputs/ExportButton";

const SOURCES = [
  { id: 0, name: "erp" },
  { id: 1, name: "web" },
  { id: 2, name: "app" },
];

const HUBSPOT_SOURCES = [
  { id: DIRECT_TRAFFIC_SOURCE, name: DIRECT_TRAFFIC_SOURCE },
  { id: "", name: "notAssigned" },
  { id: OFFLINE_SOURCE, name: OFFLINE_SOURCE },
  { id: ORGANIC_SEARCH_SOURCE, name: ORGANIC_SEARCH_SOURCE },
  { id: OTHER_CAMPAIGNS_SOURCE, name: OTHER_CAMPAIGNS_SOURCE },
  { id: PAID_SEARCH_SOURCE, name: PAID_SEARCH_SOURCE },
  { id: REFERRALS_SOURCE, name: REFERRALS_SOURCE },
  { id: SOCIAL_MEDIA_SOURCE, name: SOCIAL_MEDIA_SOURCE },
];

const gendersNames = {
  1: "male",
  2: "female",
  3: "other",
};

const paymentMethodsNames = {
  1: "cash",
  2: "receipt",
  3: "paycard",
  4: "transfer",
  5: "consolidation",
  6: "tokenizedPaycard",
};

const initialFilters = {
  endDate: "",
  isKeyAccount: "",
  centerTypeId: "",
  customerTypeId: "",
  customerGenderId: "",
  clusters: [],
  sourceIds: [],
  ageRanges: [],
  centerIds: [],
  provinceIds: [],
  hubSpotOriginSource: [],
  autonomousCommunityIds: [],
  startDate: firstDateCurrentMonth(),
};

function Sales() {
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");

  const { api, user } = useContext(AppContext);
  const data = useContext(MarketingContext);

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [contracts, setContracts] = useState([]);

  const sourcesNames = {
    0: t("erp"),
    1: t("web"),
    2: t("app"),
  };

  const paymentMethodNames = {
    [CASH_PAYMENT_METHOD_ID]: "cash",
    [RECEIPT_PAYMENT_METHOD_ID]: "receipt",
    [PAYCARD_PAYMENT_METHOD_ID]: "paycard",
    [TRANSFER_PAYMENT_METHOD_ID]: "transfer",
    [CONSOLIDATION_PAYMENT_METHOD_ID]: "consolidation",
    [TOKENIZED_PAYCARD_PAYMENT_METHOD_ID]: "tokenizedPaycard",
  };

  const getContracts = async () => {
    try {
      if (!validateSearchParams()) return;
      setLoading(true);

      let centerIds = undefined;
      let filteredCenters = data.centers;
      if (filters.centerTypeId !== "") {
        const filteredCenterIds = filterCentersByType(
          data.centers,
          filters.centerTypeId
        );
        filteredCenters = data.centers.filter((center) =>
          filteredCenterIds.includes(center.id)
        );
      }

      if (filters.centerIds.length)
        filteredCenters = filteredCenters.filter((center) =>
          filters.centerIds.includes(center.id)
        );

      if (filters.autonomousCommunityIds.length) {
        const filteredProvinceIds = data.provinces
          .filter((province) =>
            filters.autonomousCommunityIds.includes(province.parentId)
          )
          .map((province) => province.id);
        filteredCenters = filteredCenters.filter((center) =>
          filteredProvinceIds.includes(center?.City?.regionId)
        );
      }

      if (filters.provinceIds.length) {
        const filteredProvinceIds = filters.provinceIds;
        filteredCenters = filteredCenters.filter((center) =>
          filteredProvinceIds.includes(center?.City?.regionId)
        );
      }

      if (
        filters.centerTypeId !== "" ||
        filters.centerIds.length ||
        filters.autonomousCommunityIds.length ||
        filters.provinceIds.length
      )
        centerIds = filteredCenters.map((center) => center.id);

      const params = {
        include: ["Box", "Customer", "User", "CustomerEmail", "HubSpotContact"],
        createdAt: { min: filters.startDate, max: filters.endDate },
        centerId: centerIds,
        customerTypeId: filters.customerTypeId || undefined,
        customerGenderId:
          filters.customerTypeId === INDIVIDUAL_CUSTOMER_TYPE_ID
            ? filters.customerGenderId || undefined
            : undefined,
        isKeyAccount:
          filters.isKeyAccount === "" ? undefined : filters.isKeyAccount,
        clusters: filters.clusters.length ? filters.clusters : undefined,
      };

      api
        .get("/contracts", { params })
        .then(async (response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            let parsedContracts = parseContracts(response.data, data, t);

            if (filters.hubSpotOriginSource.length) {
              parsedContracts = parsedContracts.filter((contract) =>
                filters.hubSpotOriginSource.includes(
                  contract.hubSpotOriginSource
                )
              );
            }

            if (
              filters.ageRanges.length &&
              filters.customerTypeId === INDIVIDUAL_CUSTOMER_TYPE_ID
            ) {
              parsedContracts = filterContractsByAgeRange(parsedContracts);
            }

            if (filters.sourceIds.length) {
              parsedContracts = parsedContracts.filter((contract) =>
                filters.sourceIds.includes(contract.source)
              );
            }

            setContracts(parsedContracts);
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => setLoading(false));
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.toString(), { variant: "error" });
    }
  };

  const filterCentersByType = (centers, typeId) =>
    centers
      .filter((center) => center.type === typeId)
      .map((center) => center.id);

  const filterContractsByAgeRange = (contracts) => {
    return contracts.filter((contract) =>
      filters.ageRanges.some((rangeId) => {
        const ageRange = data.ageRanges.find(
          (range) => range.value === rangeId
        );
        return (
          ageRange &&
          contract.customerAge >= ageRange.range.min &&
          contract.customerAge <= ageRange.range.max
        );
      })
    );
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const filteredData = filterData(data, filters);
  const numberOfContracts = contracts.length;
  const invoicing =
    numberOfContracts > 0
      ? contracts.reduce((acc, contract) => {
          return acc + contract.pricePerMeter * contract.meters;
        }, 0)
      : 0;
  const totalMeters =
    numberOfContracts > 0
      ? contracts.reduce((acc, contract) => {
          return acc + contract.meters;
        }, 0)
      : 0;
  const averagePricePerMeter =
    numberOfContracts > 0 ? invoicing / totalMeters : 0;

  const DATA_GRID_COLUMNS = [
    {
      key: "publicId",
      label: t("id"),
      renderFunction: (value, item) =>
        user.hasAction("VIEW_CONTRACTS") ? (
          <ButtonLink to={"/app/contract/" + item.id}>{value}</ButtonLink>
        ) : (
          value
        ),
    },
    {
      key: "createdAt",
      label: t("creationDate"),
      valueGetter: (value) => value.slice(0, 10),
    },
    {
      key: "startDate",
      label: t("startDate"),
      valueGetter: (value) => value || "",
    },
    {
      key: "endDate",
      label: t("endDate"),
      valueGetter: (value) => value || "",
    },
    { key: "paymentMethodName", label: t("paymentMethod") },
    {
      key: "pricePerMeter",
      label: t("pricePerMeter") + "(€)",
      sortType: "number",
    },
    { key: "meters", label: t("meters") + "(m²)", sortType: "number" },
    { key: "boxName", label: t("box") },
    {
      key: "clusterName",
      label: t("cluster"),
      valueGetter: (value) => value || "",
      renderFunction: (value) => (value ? <BoxClusterChip name={value} /> : ""),
    },
    { key: "centerName", label: t("center") },
    { key: "provinceName", label: t("province") },
    {
      key: "autonomousCommunityName",
      label: t("autonomousCommunity"),
    },
    { key: "customerAge", label: t("age"), sortType: "number" },
    { key: "customerGender", label: t("gender") },
    { key: "isKeyAccount", label: t("keyAccount") },
    {
      key: "source",
      label: t("source"),
      valueGetter: (value, item) => sourcesNames[value],
    },
    {
      key: "hubSpotOriginSourceName",
      label: t("hubSpotOriginSource"),
      sortType: "string",
    },
  ];

  const parseFunction = (element) => ({
    label: t(element.name),
    value: element.id,
  });

  const validateSearchParams = () => {
    if (!filters.startDate) {
      enqueueSnackbar(t("startDateRequired"), { variant: "error" });
      return false;
    }
    return true;
  };

  return (
    <Page>
      <Grid container spacing={3}>
        <Grid item marginTop={1} marginBottom={1}>
          <Filters
            filters={[
              <DateInput
                label={t("startDate")}
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
              />,
              <DateInput
                label={t("endDate")}
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
              />,
              <Select
                multiple
                searchable
                label={t("autonomousCommunities")}
                name="autonomousCommunityIds"
                value={filters.autonomousCommunityIds}
                onChange={handleFilterChange}
                options={filteredData.autonomousCommunities.map(parseFunction)}
              />,
              <Select
                multiple
                searchable
                label={t("province")}
                name="provinceIds"
                value={filters.provinceIds}
                onChange={handleFilterChange}
                options={filteredData.provinces.map(parseFunction)}
              />,
              <CenterTypesSelect
                name="centerTypeId"
                value={filters.centerTypeId}
                style={{ minWidth: 240 }}
                onChange={handleFilterChange}
              />,
              <Select
                multiple
                searchable
                label={t("center")}
                name="centerIds"
                value={filters.centerIds}
                onChange={handleFilterChange}
                options={filteredData.centers.map(parseFunction)}
              />,
              <Select
                multiple
                label={t("clusters")}
                name="clusters"
                value={filters.clusters}
                onChange={handleFilterChange}
                options={data.boxClusters}
              />,
              <Select
                label={t("customerType")}
                name="customerTypeId"
                value={filters.customerTypeId}
                onChange={handleFilterChange}
                options={data.customerTypes}
              />,
              <Select
                disabled={
                  filters.customerTypeId !== INDIVIDUAL_CUSTOMER_TYPE_ID
                }
                label={t("gender")}
                name="customerGenderId"
                value={
                  filters.customerTypeId === INDIVIDUAL_CUSTOMER_TYPE_ID
                    ? filters.customerGenderId
                    : ""
                }
                onChange={handleFilterChange}
                options={data.genders}
              />,
              <Select
                disabled={
                  filters.customerTypeId !== INDIVIDUAL_CUSTOMER_TYPE_ID
                }
                multiple
                label={t("age")}
                name="ageRanges"
                value={
                  filters.customerTypeId === INDIVIDUAL_CUSTOMER_TYPE_ID
                    ? filters.ageRanges
                    : []
                }
                onChange={handleFilterChange}
                options={data.ageRanges}
              />,
              <Select
                label={t("keyAccounts")}
                name="isKeyAccount"
                value={filters.isKeyAccount}
                onChange={handleFilterChange}
                options={data.keyAccounts}
              />,
              <Select
                label={t("platform")}
                name="sourceIds"
                multiple
                value={filters.sourceIds}
                onChange={handleFilterChange}
                options={SOURCES.map(parseFunction)}
              />,
              <Select
                label={t("hubSpotOriginSource")}
                name="hubSpotOriginSource"
                multiple
                value={filters.hubSpotOriginSource}
                onChange={handleFilterChange}
                options={HUBSPOT_SOURCES.map(parseFunction)}
              />,
              <ButtonGroup variant="contained">
                <ExportButton
                  data={exportDataParse(contracts, DATA_GRID_COLUMNS)}
                  display={user.hasAction("EXPORT_DATA")}
                />
                <CustomButton
                  onClick={getContracts}
                  loading={loading || !data.loaded}
                >
                  {t("search")}
                </CustomButton>
              </ButtonGroup>,
            ]}
          />
        </Grid>
        <Grid item container spacing={2} justifyContent={"center"}>
          <Grid item>
            <InformationBox
              title={t("contracts")}
              mainValue={localeFormat(numberOfContracts)}
              height={170}
              width={240}
            />
          </Grid>
          <Grid item>
            <InformationBox
              title={t("meters")}
              mainValue={localeFormat(totalMeters)}
              units={"m²"}
              height={170}
              width={240}
            />
          </Grid>
          <Grid item>
            <InformationBox
              title={t("invoicing")}
              mainValue={localeFormat(invoicing)}
              units={"€"}
              height={170}
              width={240}
            />
          </Grid>
          <Grid item>
            <InformationBox
              title={t("averagePricePerMeter")}
              mainValue={localeFormat(averagePricePerMeter)}
              units={"€/m²"}
              height={170}
              width={240}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} xl={6}>
            <SalesHistory
              contracts={contracts}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <HistoricalPricePerMeter
              contracts={contracts}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <HistoricalSalesByPaymentMethod
              contracts={contracts}
              startDate={filters.startDate}
              endDate={filters.endDate}
              paymentMethodNames={paymentMethodNames}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <HiringLeadTime
              contracts={contracts}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <SalesByCenter contracts={contracts} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SalesByPlatform
              contracts={contracts}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SalesByAges contracts={contracts} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SalesByClusters contracts={contracts} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SalesByWeekDay contracts={contracts} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SalesByHours contracts={contracts} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SalesBySource contracts={contracts} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            allRowsOption={false}
            columns={DATA_GRID_COLUMNS}
            data={contracts}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

const filterData = (data, filters) => {
  if (!data.loaded)
    return {
      centers: [],
      autonomousCommunities: [],
      provinces: [],
    };
  let centers = data.centers;
  let autonomousCommunities = data.autonomousCommunities;
  let provinces = data.provinces;

  if (filters.centerTypeId || filters.centerTypeId === 0) {
    centers = centers.filter((center) => center.type === filters.centerTypeId);
  }

  if (filters.autonomousCommunityIds.length > 0) {
    provinces = provinces.filter((p) =>
      filters.autonomousCommunityIds.includes(p.parentId)
    );
    centers = centers.filter((c) => {
      if (!c?.City) return false;
      return provinces.some((p) => p.id === c?.City.regionId);
    });
  }
  if (filters.provinceIds.length > 0) {
    centers = centers.filter((c) => {
      if (!c?.City) return false;
      return filters.provinceIds.includes(c?.City.regionId);
    });
  }
  return {
    centers,
    autonomousCommunities,
    provinces,
  };
};

const getContractSource = (contract) => {
  if (!contract.creator) return 0;
  const { id: userId, roleId } = contract.creator;

  if (userId === WEB_USER_ID) return WEB_SOURCE_ID;
  else if (roleId == CUSTOMER_ROLE_ID) return APP_SOURCE_ID;
  else return ERP_SOURCE_ID;
};

const parseContracts = (contracts, data, t) => {
  return contracts.map((contract, i) => {
    const center = data.centers.find(
      (center) => center.id === contract.Box.centerId
    );
    const boxCluster = contract?.Box?.BoxCluster || null;
    let province = null;
    let ac = null;
    if (center?.City) {
      province = data.provinces.find(
        (province) => province.id === center?.City.regionId
      );
      ac = data.autonomousCommunities.find((ac) => ac.id === province.parentId);
    }

    const hubSpotContact =
      contract.Customer.CustomerEmails.find(
        (email) => email.HubSpotContact !== null
      )?.HubSpotContact || null;
    const source = getContractSource(contract);
    const sourcesNames = {
      0: "ERP",
      1: "WEB",
      2: "APP",
    };

    return {
      id: contract.id,
      meters: contract.meters,
      pricePerMeter: contract.pricePerMeter,
      price: contract.pricePerMeter * contract.meters,
      createdAt: contract.createdAt,
      createdAtDates: {
        days: contract.createdAt.slice(0, 10),
        months: contract.createdAt.slice(0, 7),
        years: contract.createdAt.slice(0, 4),
      },
      startDate: contract.startDate,
      center: center,
      province: province,
      customerAge: getAge(contract.Customer.birthdate),
      source: source,
      sourceName: sourcesNames[source],
      aC: ac,
      publicId: contract.publicId,
      endDate: contract.endDate,
      paymentMethod: contract.paymentMethod,
      paymentMethodName: paymentMethodsNames[contract.paymentMethod],
      boxName: contract.Box.name,
      cluster: boxCluster,
      clusterName: boxCluster?.name || "",
      centerName: center?.name || "",
      provinceName: province?.name || "",
      autonomousCommunityName: ac?.name || "",
      customerGender: gendersNames[contract.Customer.gender],
      isKeyAccount: contract.Customer.isKeyAccount ? t("yes") : t("no"),
      hubSpotContact: hubSpotContact,
      hubSpotOriginSource: hubSpotContact
        ? hubSpotContact.hs_analytics_source
        : "",
      hubSpotOriginSourceName: hubSpotContact
        ? t(hubSpotContact.hs_analytics_source)
        : "",
    };
  });
};

export default Sales;
