import { Text as TextKonva } from "react-konva";

const Text = (props) => {
  const {
    x,
    y,
    text,
    fontSize,
    color,
    onClick,
    tileRef,
    onTransformEnd,
    onDragEnd,
    draggable,
  } = props;

  return (
    <TextKonva
      ref={tileRef}
      onClick={onClick}
      x={x}
      y={y}
      text={text}
      fontSize={fontSize}
      fill={color || "black"}
      onTransformEnd={onTransformEnd}
      onDragEnd={onDragEnd}
      draggable={draggable}
    />
  );
};

export default Text;
