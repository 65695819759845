import { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// mui
import { ButtonGroup, Grid, Typography, Tooltip } from "@mui/material";

// Icons
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

// components & utils

import { CustomTable } from "../../../CustomTable";
import { getParams, generateURL } from "../../../../utils/url";
import { localeFormat } from "../../../../utils/format";
import AppContext from "../../../../context/AppContext";
import Button from "../../../Inputs/CustomButton";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import CenterSelect from "../../../Inputs/CenterSelect";
import CustomSelect from "../../../Inputs/CustomSelect";
import Filters from "../../../global/structure/Filters";
import ItemsSummary from "../../../ItemsSummary";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  nonPaymentInvoices: [],
  filters: {
    autoSearch: "false",
    billingError: "",
    centerIds: [],
    customerName: "",
    hasNonPayments: true,
    invoiceNumber: "",
    uncollectible: "",

    orderBy: "",
    order: "",

    dateFrom: "",
    dateUntil: "",
    number: "",
  },
  options: {
    loaded: true,
    rowlink: "non-payment",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_NONPAYMENT_INVOICES":
      return { ...state, nonPaymentInvoices: action.payload };
    case "SET_LOADED_TRUE":
      return {
        ...state,
        options: { ...state.options, loaded: true },
      };
    case "SET_LOADED_FALSE":
      return {
        ...state,
        options: { ...state.options, loaded: false },
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_FILTERS":
      return {
        ...state,
        filters: initialState.filters,
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};
  
function NonPaymentsTab(props) {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { currentTab, history, CSV_INVOICE_HEADERS, INVOICE_COLUMNS, FILTERS } =
    props;

  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const query = useQuery();

  const INVOICE_NON_PAYMENTS_COLUMNS = [
    ...INVOICE_COLUMNS,
    {
      key: "uncollectible",
      label: t("collectible/uncollectibleInvoice"),
      sortType: "other",
      renderFunction: (value) =>
        value ? (
          <Typography color={"error"}>{t("uncollectible")}</Typography>
        ) : (
          <Typography color={"success"}>{t("collectible")}</Typography>
        ),
    },
    {
      key: "billingError",
      label: t("incorrectlyIssued"),
      sortType: "other",
      renderFunction: (value, item) =>
        value ? (
          <Tooltip title={t("incorrectlyIssued")} placement="top">
            <ReportGmailerrorredIcon />
          </Tooltip>
        ) : (
          <></>
        ),
    },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  useEffect(() => {
    if (state.filters.autoSearch === "true") {
      getInvoices();
    }
  }, [state.filters.autoSearch]);

  const getInvoices = () => {
    updateQuery();
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = { include: ["NonPayment"] };

    state.filters.number !== "" && (params.number = state.filters.number);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.centerIds.length !== 0 &&
      (params.centerIds = state.filters.centerIds);
    state.filters.billingError !== "" &&
      (params.billingError = state.filters.billingError === "yes" ? 1 : 0);
    state.filters.uncollectible !== "" &&
      (params.uncollectible =
        state.filters.uncollectible === "uncollectible" ? 1 : 0);

    api
      .get("/invoices-non-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          let nonPaymentsInvoices = response.data.filter(
            (data) =>
              data.NonPayments.length !== 0 &&
              data.NonPayments[data.NonPayments.length - 1].recuperationDate ===
                null
          );

          nonPaymentsInvoices.forEach(
            (invoice) =>
              (invoice.nonPaymentDate =
                invoice.NonPayments[
                  invoice.NonPayments.length - 1
                ].createdAt.split("T")[0])
          );

          dispatch({
            type: "SET_NONPAYMENT_INVOICES",
            payload: nonPaymentsInvoices,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const NON_PAYMENTS_SUMMARY = [
    {
      translatedText: t("invoiceNumber"),
      value: state.nonPaymentInvoices?.length,
    },
    {
      translatedText: t("total"),
      value:
        localeFormat(
          state.nonPaymentInvoices?.reduce(
            (value, element) => value + element.totalAmount,
            0
          )
        ).toString() + " €",
    },
    {
      translatedText: t("totalCollectable"),
      value:
        localeFormat(
          state.nonPaymentInvoices?.reduce(
            (value, element) =>
              value + (!element.uncollectible ? element.totalAmount : 0),
            0
          )
        ).toString() + " €",
    },
    {
      translatedText: t("totalUncollectable"),
      value:
        localeFormat(
          state.nonPaymentInvoices?.reduce(
            (value, element) =>
              value + (element.uncollectible ? element.totalAmount : 0),
            0
          )
        ).toString() + " €",
    },
  ];

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const updateQuery = () => {
    let params = {
      tab: currentTab,
      ...state.filters,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  const search = () => {
    if (state.filters.autoSearch === "true") {
      getInvoices();
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
    }
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              label={t("number/name")}
              value={state.filters.number}
              name="number"
              onChange={handleChangeFilter}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <CenterSelect
              multiple
              name="centerIds"
              onChange={handleChangeFilter}
              value={state.filters.centerIds}
            />,
            <TextInput
              label={t("dateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateFrom}
              onChange={handleChangeFilter}
              name="dateFrom"
            />,
            <TextInput
              label={t("dateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateUntil}
              onChange={handleChangeFilter}
              name="dateUntil"
            />,
            <CustomSelect
              name="uncollectible"
              label={t("collectible/uncollectibleInvoice")}
              value={state.filters.uncollectible}
              onChange={handleChangeFilter}
              options={[
                {
                  label: t("all"),
                  value: "",
                },
                {
                  label: t("uncollectible"),
                  value: "uncollectible",
                },
                {
                  label: t("collectible"),
                  value: "collectible",
                },
              ]}
            />,
            <CustomSelect
              name="billingError"
              label={t("incorrectlyIssued")}
              value={state.filters.billingError}
              onChange={handleChangeFilter}
              options={[
                {
                  label: t("all"),
                  value: "",
                },
                {
                  label: t("yes"),
                  value: "yes",
                },
                {
                  label: t("no"),
                  value: "no",
                },
              ]}
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ButtonGroup variant="contained">
          <Button onClick={resetFilters}>{t("reset")}</Button>
          <ButtonCSV
            data={state.nonPaymentInvoices}
            headers={CSV_INVOICE_HEADERS}
            filename={t("nonPaymentInvoices")}
          />
          <SearchButton onClick={search} loading={!state.options.loaded} />
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <ItemsSummary gridItems={NON_PAYMENTS_SUMMARY} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={INVOICE_NON_PAYMENTS_COLUMNS}
          data={state.nonPaymentInvoices}
          sortBy={state.filters.orderBy}
          sort={state.filters.order}
          options={{
            rowlink: user.hasPage("INVOICES") ? "invoice" : null,
            loaded: state.options.loaded,
          }}
          onSortCallback={(orderBy, order) => {
            handleOrderChange(orderBy, order);
            const url = generateURL("/app/non-payments", {
              ...state.filters,
              orderBy,
              order,
            });
            history.push(url);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default NonPaymentsTab;
