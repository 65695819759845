import {
  Container,
  Checkbox,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper as MUIPaper,
} from "@mui/material";
import { useContext } from "react";

import AppContext from "../../../../context/AppContext";
import Page from "../../../global/structure/Page";

import { RolesContext } from "../RolesPage";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

function PagesTab() {
  const { user } = useContext(AppContext);
  const { editRolePage, pages, roles } = useContext(RolesContext);

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });
    return isFound;
  };

  const roleHasPage = (role, pageId) => {
    let hasPage = false;
    role.Pages?.forEach((page) => {
      if (page.id === pageId) hasPage = true;
    });

    return hasPage;
  };

  return (
    <Page>
      <Container maxWidth={false}>
        <TableContainer component={MUIPaper} sx={{ maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 4,
                    backgroundColor: "white",
                  }}
                ></TableCell>
                {roles?.map((role) => (
                  <TableCell
                    key={role.id}
                    align="left"
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 3,
                      backgroundColor: "white",
                    }}
                  >
                    {role.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pages.length !== 0 &&
                pages.map((page, i) => (
                  <TableRow key={page.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                      }}
                    >
                      {page.name}
                    </TableCell>
                    {roles.map((role, j) => (
                      <TableCell align="left" key={role.id + page.id}>
                        {hasAction("EDIT_PAGES") ? (
                          <Checkbox
                            id={"check" + i + j}
                            name={"check" + role.id + page.id}
                            checked={roleHasPage(role, page.id)}
                            onChange={(e) => {
                              editRolePage(e.target.checked, role, page);
                            }}
                            inputProps={{
                              "aria-label": "primary checkbox" + i + j,
                            }}
                            sx={{
                              left: "auto",
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          />
                        ) : roleHasPage(role, page.id) ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default PagesTab;
