import { useContext, useReducer, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";

// Material UI
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  Typography,
  TextField,
  Tooltip,
  FormControlLabel,
  Icon,
} from "@mui/material";

// Icons
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import MailIcon from "@mui/icons-material/Mail";
import MoneyOffCsredIcon from "@mui/icons-material/MoneyOffCsred";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Components & Utils
import { CustomTable } from "../../CustomTable";
import { formatDateTime } from "../../../utils/date";
import {
  formatHiddenPayCardNumber,
  formatPhoneNumber,
  localeFormat,
  removeSpaces,
} from "../../../utils/format";
import AppContext from "../../../context/AppContext";
import ConfirmDialog from "../../ConfirmDialog";
import CreditCardForm from "../../CreditCardForm";
import CustomButton from "../../Inputs/CustomButton";
import CustomerForm from "../../pages/Customers/CustomerForm";
import CustomerUserDialog from "../../Inputs/CustomerUserDialog";
import CustomSelect from "../../Inputs/CustomSelect";
import GroupIcon from "@mui/icons-material/Group";
import InvoiceStateChip from "../../InvoiceStateChip";
import PhoneInput from "../../Inputs/PhoneInput";
import TextInput from "../../Inputs/TextInput";

import {
  ACTIVE_CONTRACT_STATE_ID,
  CUSTOMER_ROLE_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  SPAIN_COUNTRY_ID,
} from "../../../data/constants";
import BillingAddressForm from "./BillingAddressForm";
import BillingAddressCard from "./BillingAddressCard";
import ContractStateBox from "../Contracts/ContractStateBox";
import { validateEmail, validatePhoneNumber } from "../../../utils/validate";
import ActionIconButtons from "../../global/ActionIconButtons";
import LabeledText from "../../global/LabeledText";
import InfoCard from "../../global/InfoCard";
import Dialog from "../../global/Dialog";
import PaycardListComponent from "./PaycardListComponent";
import AccessControlDialog from "../AccessControl/AccessControlDialog";

const initialState = {
  newAddress: {
    address: "",
    city: "",
    name: "",
    nif: "",
    postalCode: "",
    tinCountry: "",
    residenceCountry: "",
    idType: "",
    isDefault: true,
  },
  confirmDialog: {
    cancelText: "",
    childrenText: "",
    confirmText: "",
    isOpen: false,
    title: "",
    callback: () => {},
  },
  dialog: {
    open: false,
    onClose: () => {},
    actions: null,
    children: null,
    title: "",
    maxWidth: "sm",
    currentDialogType: "",
  },
  form: {
    addresses: [],
    birthdate: "",
    cards: [],
    city: null,
    cityId: "",
    customerComments: [],
    customerTypeId: "",
    defaultIBANId: "",
    defaultPayCardId: "",
    defaultTokenizedPayCardId: "",
    emails: [],
    gender: "",
    ibans: [],
    idType: "",
    invoiceComments: "",
    invoicingGroups: [],
    invoicingType: "",
    isKeyAccount: false,
    name: "",
    nif: "",
    personaNonGrata: false,
    phones: [],
    postalCode: "",
    residenceCountry: null,
    residenceCountryId: "",
    surnames: "",
    tinCountry: null,
    tinCountryId: "",
    virtualInvoicing: false,
  },
  inputError: {
    address: false,
    birthdate: false,
    city: false,
    gender: false,
    name: false,
    nif: false,
    surnames: false,
  },
  customerUserDialog: false,
  newComment: "",
  newEmail: { email: "", contact: "" },
  newIban: "",
  newInvoicingGroup: "",
  newPhone: { number: "", contact: "" },
  selectedUserEmail: { email: "" },
  contracts: [],
  invoices: [],
  invoicingTypes: [],
  loading: false,
  isAccessDialogOpen: false,
  showInactiveBillingAddresses: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM":
      return { ...state, form: action.payload };
    case "SET_INVOICING_TYPES":
      return { ...state, invoicingTypes: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_DIALOG":
      return {
        ...state,
        dialog: {
          open: action.payload.open,
          onClose: action.payload.onClose,
          actions: action.payload.actions,
          children: action.payload.children,
          title: action.payload.title,
          currentDialogType: action.payload.currentDialogType,
        },
      };
    case "RESET_DIALOG":
      return {
        ...state,
        dialog: initialState.dialog,
      };
    case "CUSTOMER_USER_DIALOG":
      return { ...state, customerUserDialog: action.payload };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_NEW_COMMENT":
      return { ...state, newComment: action.payload };
    case "SET_EMAIL_INPUT":
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PHONE_INPUT":
      return {
        ...state,
        newPhone: {
          ...state.newPhone,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_NEW_INPUTS":
      return {
        ...state,
        [action.payload]: initialState[action.payload],
      };
    case "ADD_ADDRESS":
      return {
        ...state,
        form: {
          ...state.form,
          addresses: [...state.form.addresses, action.payload],
        },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_ADDRESS_INPUT":
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SELECTED_USER_EMAIL":
      return {
        ...state,
        selectedUserEmail: {
          email: action.payload.value,
        },
      };
    case "SET_IBAN_INPUT":
      return { ...state, newIban: action.payload };
    case "SET_INVOICING_GROUP_INPUT":
      return { ...state, newInvoicingGroup: action.payload };
    case "SET_INPUT_ERROR":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "TOGGLE_SHOW_INACTIVE_BILLING_ADDRESSES":
      return {
        ...state,
        showInactiveBillingAddresses: !state.showInactiveBillingAddresses,
      };
    case "SET_SHOW_INACTIVE_BILLING_ADDRESSES":
      return {
        ...state,
        showInactiveBillingAddresses: action.payload,
      };
    case "RESET_FORM":
      return { ...state, [action.payload]: initialState[action.payload] };
    case "TOGGLE_LOADING":
      return { ...state, loading: !state.loading };
    case "SET_ACCESS_DIALOG_OPEN":
      return { ...state, isAccessDialogOpen: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const validChars =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

const passwordLength = 15;

export default function CustomerPage() {
  const { api, user } = useContext(AppContext);
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("customers");
  const [tErrors] = useTranslation("errors");

  const CONTRACT_COLUMNS = [
    { label: "ID", key: "publicId" },
    {
      label: t("center"),
      key: "Box.Center.name",
      sortKey: "Box.Center.name",
      sortType: "string",
    },
    {
      label: t("box"),
      key: "Box.name",
      sortKey: "Box.name",
      sortType: "string",
    },
    { label: t("startDate"), key: "startDate", sortType: "string" },
    { label: t("endDate"), key: "endDate", sortType: "string" },
    {
      key: "PaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
    },
    {
      label: t("periodicity"),
      key: "Periodicity.name",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      label: t("price"),
      key: "totalPrice",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      label: t("state"),
      key: "state",
      sortType: "other",
      renderFunction: (value, item) => (
        <ContractStateBox
          state={value}
          isFrozen={item?.isFrozen}
          hasFreezePermission={user.hasAction("FREEZE_CONTRACT")}
        />
      ),
    },
  ];

  const INVOICING_COLUMNS = [
    {
      key: "InvoiceSerie.name",
      label: t("serie"),
      sortType: "string",
    },
    {
      key: "number",
      label: t("number"),
      sortType: "number",
    },
    {
      key: "issueDate",
      label: t("issueDate"),
      sortType: "string",
    },
    {
      key: "customerName",
      label: t("customerName"),
      sortType: "string",
    },
    {
      key: "totalAmount",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "PaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("customerPage");

    getCustomer();
    getContracts();
    user.hasAction("VIEW_INVOICES") && getInvoices();
    getInvoicingTypes();
  }, []);

  /* BACKEND CALLS */

  const getCustomer = () => {
    let params = {
      include: [
        "AccessControlUser",
        "BillingAddress",
        "City",
        "Country",
        "CustomerComment",
        "IBAN",
        "PayCard",
        "PostalCode",
        "User",
      ],
    };

    api
      .get("/customers/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const {
            CustomerEmails,
            BillingAddresses,
            PayCards,
            CustomerPhoneNumbers,
            CustomerComments,
            IBANs,
            City,
            PostalCode,
            Country,
            InvoicingGroups,
            ...restData
          } = response.data;

          const payload = {
            email:
              CustomerEmails && CustomerEmails.length === 1
                ? CustomerEmails[0]
                : null,
            emails: CustomerEmails,
            addresses: BillingAddresses,
            cards: PayCards,
            phones: CustomerPhoneNumbers,
            customerComments: CustomerComments,
            ibans: IBANs,
            city: City,
            postalCode: PostalCode?.number || "",
            invoicingGroups: InvoicingGroups,
            ...restData,
          };

          dispatch({ type: "SET_FORM", payload });

          if (payload.emails.length > 0) {
            handleSetSelectedUserEmail(payload.emails[0].email);
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContracts = () => {
    let params = {
      include: ["Box", "Center", "Customer"],
      customerId: id,
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const contracts = response.data.map((contract) => ({
            ...contract,
            endDate: contract.endDate ?? "",
            totalPrice: contract.price * (1 + contract.taxes / 100),
          }));

          dispatch({ type: "SET_CONTRACTS", payload: contracts });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoices = () => {
    let params = {
      include: ["PaymentMethod"],
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "id",
            "number",
            "issueDate",
            "customerName",
            "totalAmount",
            "PaymentMethod.name",
            "state",
            "billingError",
          ],
        },
        {
          model: "PaymentMethod",
          attributes: ["name"],
        },
      ],
      customerId: id,
    };
    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoicingTypes = () => {
    api
      .get("/invoicing-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else
          dispatch({ type: "SET_INVOICING_TYPES", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteCard = (cardId) => {
    if (cardId === state.form.defaultPayCardId)
      return enqueueSnackbar(t("cannotDeleteDefault"), { variant: "warning" });

    const customerId = id;

    api
      .delete(`/customers/${customerId}/pay-cards/${cardId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "cards",
              value: state.form.cards.filter(
                (formCard) => formCard.id !== cardId
              ),
            },
          });
          enqueueSnackbar(t("cardDeleted"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addCreditCard = (card) => {
    const { name, number, expiry } = card;
    const expirationMonth = expiry.substring(0, 2);
    const expirationYear = expiry.substring(3);

    const creditCardExists = state.form.cards?.some((payCard) => {
      return (
        payCard.number === number &&
        payCard.expirationMonth === expirationMonth &&
        payCard.expirationYear === expirationYear
      );
    });
    if (creditCardExists)
      return enqueueSnackbar(t("creditCardExist"), { variant: "warning" });

    let form = {
      name,
      number,
      expirationMonth,
      expirationYear,
      cvv: null,
    };

    dispatch({ type: "TOGGLE_LOADING" });

    api
      .post("/customers/" + id + "/paycard", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "cards",
              value: [...state.form.cards, response.data],
            },
          });

          //If is the first, set it as the default pay card
          if (!state.form.defaultPayCardId)
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "defaultPayCardId",
                value: response.data.id,
              },
            });

          handleCloseDialog();
          enqueueSnackbar(t("cardAdded"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
  };

  const addBillingAddress = () => {
    let form = state.newAddress;

    form.residenceCountryId = state.newAddress.residenceCountry.id;
    form.tinCountryId = state.newAddress.tinCountry.id;

    if (form.residenceCountryId === SPAIN_COUNTRY_ID) {
      form.cityId = state.newAddress.city.id;
    } else {
      delete form.postalCode;
    }

    dispatch({ type: "TOGGLE_LOADING" });

    api
      .post("/customers/" + id + "/address", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          // If the address is set as default, update the other addresses
          if (response.data.isDefault) {
            const billingAddresses = state.form.addresses.map((address) => {
              address.isDefault = false;
              return address;
            });

            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "addresses",
                value: billingAddresses,
              },
            });
          }

          // Add the new address to the list
          dispatch({
            type: "ADD_ADDRESS",
            payload: response.data,
          });
          enqueueSnackbar(t("billingAddressAddedSuccessfully"), {
            variant: "success",
          });
          handleCloseDialog("newAddress");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
  };

  const setAsDefaultAddress = (addressId) => {
    const customerId = id;
    api
      .post(`/customers/${customerId}/billing-addresses/${addressId}/default`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("defaultBillingAddressSet"), {
            variant: "success",
          });
          const billingAddresses = state.form.addresses.map((address) => {
            if (address.id === addressId) {
              address.isDefault = true;
            } else {
              address.isDefault = false;
            }
            return address;
          });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "addresses",
              value: billingAddresses,
            },
          });
        }
      });
  };

  const beforeCreateUser = () => {
    // Check for emails, if many emails, open dialog to select one
    if (!state.form.emails.length)
      return enqueueSnackbar(t("cantCreateUserWithoutEmail"), {
        variant: "warning",
      });

    handleOpenDialog("userEmail", "selectedUserEmail", t("createUser"));
  };

  const createUser = () => {
    const params = {
      name: state.selectedUserEmail.email,
      password: Array.from(
        { length: passwordLength },
        () => validChars[Math.floor(Math.random() * validChars.length)]
      ).join(""),
      roleId: CUSTOMER_ROLE_ID,
      language: "ES",
      isCustomerUser: true,
      customerId: state.form.id,
    };

    api
      .post("/users/create", params)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: { inputname: "Users", value: [response.data] },
          });
          enqueueSnackbar(t("userCreatedSuccessfully"), { variant: "success" });
          handleCloseDialog("userEmail");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addPhone = () => {
    let { number, contact } = state.newPhone;

    number = number.trim();
    contact = contact.trim();

    if (
      validatePhoneNumber(
        number,
        t,
        state.form.phones.map((phone) => phone.number)
      )
    ) {
      const form = { number, contact };

      dispatch({ type: "TOGGLE_LOADING" });

      api
        .post("/customers/" + id + "/phone-number", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            handleCloseDialog("newPhone");
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "phones",
                value: [...state.form.phones, response.data],
              },
            });
            enqueueSnackbar(t("phoneAdded"), { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
    }
  };

  const deletePhone = (phoneNumberId) => {
    if (state.form.phones.length === 1) {
      return enqueueSnackbar(t("lastPhone"), { variant: "warning" });
    }

    const customerId = id;

    api
      .delete("/customers/" + customerId + "/phone-numbers/" + phoneNumberId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("phoneDeleted"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "phones",
              value: state.form.phones.filter(
                (phone) => phone.id !== phoneNumberId
              ),
            },
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const submitForm = () => {
    if (validateForm()) {
      let form = {};

      const customerAttributes = [
        "address",
        "birthdate",
        "cityId",
        "company",
        "customerTypeId",
        "gender",
        "idType",
        "invoiceComments",
        "invoicingType",
        "isKeyAccount",
        "name",
        "nif",
        "personaNonGrata",
        "postalCode",
        "residenceCountryId",
        "surnames",
        "tinCountryId",
        "type",
        "userId",
        "virtualInvoicing",
      ];

      customerAttributes.forEach((key) => {
        if (state.form[key] !== "" && state.form[key] !== undefined) {
          form[key] = state.form[key];
        }
      });

      dispatch({ type: "TOGGLE_LOADING" });

      api
        .post(`/customers/${id}/edit`, form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("customerEditSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
    }
  };

  const addComment = () => {
    if (state.newComment === "")
      return enqueueSnackbar(t("commentCannotBeEmpty"), { variant: "warning" });

    const comment = {
      text: state.newComment,
    };

    api
      .post("/customers/" + id + "/comment", comment)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "customerComments",
              value: [...state.form.customerComments, response.data],
            },
          });
          dispatch({
            type: "SET_NEW_COMMENT",
            payload: "",
          });
          enqueueSnackbar(t("commentAdded"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addEmail = () => {
    const { email, contact } = state.newEmail;

    const trimmedEmail = email.trim();
    const trimmedContact = contact.trim();

    if (
      validateEmail(
        trimmedEmail,
        t,
        state.form.emails.map((email) => email.email)
      )
    ) {
      const form = {
        email: trimmedEmail,
        contact: trimmedContact,
      };

      dispatch({ type: "TOGGLE_LOADING" });

      api
        .post("/customers/" + id + "/email", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            handleCloseDialog("newEmail");
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "emails",
                value: [...state.form.emails, response.data],
              },
            });
            if (state.selectedUserEmail.email === "") {
              handleSetSelectedUserEmail(response.data.email);
            }
            enqueueSnackbar(t("emailAdded"), { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
    }
  };

  const deleteEmail = (emailId) => {
    if (state.form.emails.length === 1)
      return enqueueSnackbar(t("lastEmail"), { variant: "warning" });

    const customerId = id;

    api
      .delete(`/customers/${customerId}/emails/${emailId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "emails",
              value: state.form.emails.filter(
                (formEmail) => formEmail.id !== emailId
              ),
            },
          });
          enqueueSnackbar(t("emailDeleted"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteIBAN = (ibanId) => {
    if (ibanId === state.form.defaultIBANId)
      return enqueueSnackbar(t("cannotDeleteDefault"), { variant: "warning" });

    const customerId = id;
    // Change to customer controller route
    api
      .delete(`/customers/${customerId}/ibans/${ibanId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("ibanDeleted"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "ibans",
              value: state.form.ibans.filter(
                (formIban) => formIban.id !== ibanId
              ),
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addIban = () => {
    const newIbanNumber = removeSpaces(state.newIban);
    const ibanExists = state.form.ibans.some(
      (iban) => removeSpaces(iban.number) === newIbanNumber
    );
    if (ibanExists)
      return enqueueSnackbar(t("ibanAlreadyExists"), { variant: "warning" });

    const iban = {
      number: newIbanNumber,
    };

    dispatch({ type: "TOGGLE_LOADING" });

    api
      .post("/customers/" + id + "/iban", iban)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          handleCloseDialog("newIban");
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "ibans",
              value: [...state.form.ibans, response.data],
            },
          });

          //If is the first:
          if (state.form.ibans.length === 0 && !state.form.defaultIBANId)
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "defaultIBANId",
                value: response.data.id,
              },
            });
          enqueueSnackbar(t("ibanAdded"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
  };

  // TODO: Change backend to standardize
  const addInvoicingGroup = () => {
    if (!validateInvoicingGroup()) return;

    let group = {
      name: state.newInvoicingGroup,
      CustomerId: id,
    };
    dispatch({ type: "TOGGLE_LOADING" });

    api
      .post("/invoicing-groups/create", group)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("invoicingGroupAdded"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "invoicingGroups",
              value: [...state.form.invoicingGroups, response.data],
            },
          });
          handleCloseDialog("newInvoicingGroup");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "TOGGLE_LOADING" }));
  };

  const deleteInvoicingGroup = (group) => {
    api
      .delete("/invoicing-groups/" + group.id + "/delete")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("invoicingGroupDeleted"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "invoicingGroups",
              value: state.form.invoicingGroups.filter(
                (invoicingGroup) => invoicingGroup.name != group.name
              ),
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setActiveStatusBillingAddress = (address) => {
    const addressId = address.id;

    api
      .post("/billing-addresses/" + addressId + "/set-active-status", {
        active: !address.active,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const newAddresses = state.form.addresses.map((item) => {
            if (item.id === address.id) {
              item.active = !item.active;
            }
            return item;
          });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "addresses",
              value: newAddresses,
            },
          });
          enqueueSnackbar(t("billingAddressStatusChanged"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const changeDefaultIban = (e, updateContractIBAN) => {
    const data = {
      updateContractIBAN: updateContractIBAN,
    };

    const ibanId = e.target.value;
    const customerId = id;

    api
      .post(`/customers/${customerId}/ibans/${ibanId}/default`, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("defaultIbanChanged"), { variant: "success" });
          handleInputChange(e);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const changeDefaultPayCard = (e, updateContractPayCard) => {
    const data = {
      updateContractPayCard: updateContractPayCard,
    };

    const customerId = id;
    const paycardId = e.target.value;

    api
      .post(`/customers/${customerId}/pay-cards/${paycardId}/default`, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("defaultCardChanged"), { variant: "success" });
          handleInputChange(e);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* HANDLERS */

  const handleSetAccessDialogOpen = (value) => {
    dispatch({ type: "SET_ACCESS_DIALOG_OPEN", payload: value });
  };

  const handleSetDialog = (dialog) => {
    dispatch({
      type: "SET_DIALOG",
      payload: {
        ...dialog,
        open: true,
      },
    });
  };

  const handleSetSelectedUserEmail = (email) => {
    dispatch({
      type: "SET_SELECTED_USER_EMAIL",
      payload: { value: email },
    });
  };

  const handleOpenDialog = (currentDialogType, formInputName, title) => {
    const dialog = {
      currentDialogType,
      onClose: () => handleCloseDialog(formInputName),
      title,
    };

    handleSetDialog(dialog);
  };

  const handleCloseDialog = (form = "") => {
    if (form) {
      dispatch({
        type: "RESET_FORM",
        payload: form,
      });
    }
    dispatch({ type: "RESET_DIALOG" });
  };

  const openCustomerUserDialog = () => {
    dispatch({
      type: "CUSTOMER_USER_DIALOG",
      payload: true,
    });
  };

  const closeCustomerUserDialog = (edited) => {
    if (edited) getCustomer();
    dispatch({
      type: "CUSTOMER_USER_DIALOG",
      payload: false,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirmDialog = (
    title,
    childrenText,
    callback,
    confirmText = t("confirm"),
    cancelText = t("cancel")
  ) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title,
        confirmText,
        cancelText,
        childrenText,
        isOpen: true,
        callback: (confirmed) => {
          callback(confirmed);
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmEmail = (email) => {
    const childrenText =
      email.email + (email.contact ? " - " + email.contact : "");
    openConfirmDialog(
      t("deleteEmailQuestion"),
      childrenText,
      (confirmed) => confirmed && deleteEmail(email?.id)
    );
  };

  const openConfirmPhone = (phone) => {
    const childrenText =
      formatPhoneNumber(phone.number) +
      (phone.contact ? " - " + phone.contact : "");
    openConfirmDialog(
      t("deletePhoneQuestion"),
      childrenText,
      (confirmed) => confirmed && deletePhone(phone.id)
    );
  };

  const openConfirmCreditCard = (card) => {
    openConfirmDialog(
      t("deleteCreditCardQuestion"),
      formatHiddenPayCardNumber(card.number),
      (confirmed) => confirmed && deleteCard(card.id)
    );
  };

  const openConfirmIban = (iban) => {
    openConfirmDialog(
      t("deleteIbanQuestion"),
      iban.number,
      (confirmed) => confirmed && deleteIBAN(iban.id)
    );
  };

  const openConfirmInvoicingGroups = (group) => {
    openConfirmDialog(
      t("deleteInvoicingGroupQuestion"),
      group.name,
      (confirmed) => confirmed && deleteInvoicingGroup(group)
    );
  };

  const openConfirmChangeDefaultIban = (e) => {
    openConfirmDialog(
      t("changeDefaultIban"),
      t("changeDefaultIbanQuestion"),
      (confirmed) => changeDefaultIban(e, confirmed),
      t("updateContracts"),
      t("doNotUpdateContracts")
    );
  };

  const openConfirmChangeDefaultPayCard = (e) => {
    openConfirmDialog(
      t("changeDefaultCard"),
      t("changeDefaultCardQuestion"),
      (confirmed) => changeDefaultPayCard(e, confirmed),
      t("updateContracts"),
      t("doNotUpdateContracts")
    );
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value,
      },
    });
  };

  const handleModalInputChange =
    (type, onlyValue = false) =>
    (e) => {
      let payload;
      if (onlyValue) {
        payload = e.target.value;
      } else {
        payload = {
          inputname: e.target.name,
          value: e.target.value,
        };
      }
      dispatch({ type, payload });
    };

  const handleNewComment = (e) => {
    dispatch({
      type: "SET_NEW_COMMENT",
      payload: e.target.value,
    });
  };

  const toggleInactiveBillingAddresses = () => {
    dispatch({ type: "TOGGLE_SHOW_INACTIVE_BILLING_ADDRESSES" });
  };

  const handleEmailInputChange = handleModalInputChange("SET_EMAIL_INPUT");
  const handlePhoneInputChange = handleModalInputChange("SET_PHONE_INPUT");
  const handleAddressInputChange = handleModalInputChange("SET_ADDRESS_INPUT");
  const handleIbanInputChange = handleModalInputChange("SET_IBAN_INPUT", true);
  const handleInvoicingGroupsInputChange = handleModalInputChange(
    "SET_INVOICING_GROUP_INPUT",
    true
  );
  const handleSelectedUserEmailInputChange = handleModalInputChange(
    "SET_SELECTED_USER_EMAIL"
  );

  const setInputError = (name, value) => {
    dispatch({
      type: "SET_INPUT_ERROR",
      payload: {
        inputname: name,
        value: value,
      },
    });
  };

  /* VALIDATORS */

  const validateForm = () => {
    let isValid = true;
    const fields = ["name", "nif", "address", "tinCountry", "residenceCountry"];

    fields.forEach((field) => {
      if (!state.form[field]) {
        setInputError(field, true);
        enqueueSnackbar(t(field) + " " + t("isRequired"), {
          variant: "error",
        });
        isValid = false;
      } else setInputError(field, false);
    });

    if (Number(state.form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID) {
      const individualFields = ["surnames"];

      individualFields.forEach((field) => {
        if (state.form[field] === "" || state.form[field] === null) {
          setInputError(field, true);
          enqueueSnackbar(t(field) + " " + t("isRequired"), {
            variant: "error",
          });
          isValid = false;
        } else setInputError(field, false);
      });
    }

    return isValid;
  };

  const validateInvoicingGroup = () => {
    if (!state.newInvoicingGroup) {
      enqueueSnackbar(t("groupInvalid"), { variant: "error" });
      return false;
    }

    const invoicingGroupExists = state.form.invoicingGroups.some(
      (group) => group.name === state.newInvoicingGroup
    );

    if (invoicingGroupExists) {
      enqueueSnackbar(t("groupAlreadyExists"), { variant: "error" });
      return false;
    }

    return true;
  };

  /* OTHERS */

  const getVisibleAddresses = () => {
    return state.form.addresses
      ?.filter(
        (address) => address.active || state.showInactiveBillingAddresses
      )
      ?.sort((a, b) => b.active - a.active);
  };

  const getInvoicingTypeName = () => {
    return state.invoicingTypes.find(
      (type) => type.id === state.form.invoicingType
    )?.name;
  };

  const getDialogOnClick = (dialogType) => {
    switch (dialogType) {
      case "email":
        return addEmail;
      case "phone":
        return addPhone;
      case "address":
        return addBillingAddress;
      case "iban":
        return addIban;
      case "invoicingGroups":
        return addInvoicingGroup;
      case "userEmail":
        return createUser;
      default:
        return () => {};
    }
  };

  const addAddressButtonDisabled = () => {
    const {
      name,
      tinCountry,
      residenceCountry,
      address: addressField,
      postalCode,
      city,
      idType,
      nif,
    } = state.newAddress;

    const isSpainAndMissingDetails =
      residenceCountry?.id === SPAIN_COUNTRY_ID && (!postalCode || !city);
    const isMissingBasicDetails =
      !name ||
      !tinCountry ||
      !residenceCountry ||
      !addressField ||
      !idType ||
      !nif;

    return isSpainAndMissingDetails || isMissingBasicDetails;
  };

  const getDialogDisabled = (dialogType) => {
    switch (dialogType) {
      case "email":
        return !state.newEmail.email;
      case "phone":
        return !state.newPhone.number;
      case "address":
        return addAddressButtonDisabled();
      case "iban":
        return !state.newIban;
      case "invoicingGroups":
        return !state.newInvoicingGroup;
      case "userEmail":
        return !state.selectedUserEmail.email;
      default:
        return false;
    }
  };

  const actions = [
    {
      title: t("accessControl"),
      icon: <RoomPreferencesIcon />,
      onClick: () => handleSetAccessDialogOpen(true),
      display: user.hasAction("MANAGE_ACCESS_CONTROL_USERS"),
    },
    {
      title: t("createUser"),
      icon: <PersonAddIcon />,
      onClick: beforeCreateUser,
      display:
        user.hasAction("CREATE_USERS") &&
        (!state.form.Users || state.form.Users.length === 0),
    },
    {
      title: t("createContract"),
      icon: <DescriptionIcon />,
      link: "/app/contract/create?customerId=" + id,
      display: user.hasAction("CREATE_CONTRACTS"),
      color: "success",
    },
    {
      title: t("createInvoice"),
      icon: <RequestQuoteIcon />,
      link: "/app/invoice/create?customerId=" + id,
      display: user.hasAction("CREATE_INVOICES"),
      color: "success",
    },
    {
      title: t("viewNonPayments"),
      icon: <MoneyOffCsredIcon />,
      link: "/app/non-payment/" + id,
      display:
        user.hasAction("VIEW_NONPAYMENT_MANAGEMENT") &&
        user.hasPage("NONPAYMENTS"),
    },
    {
      title: t("viewUser"),
      icon: <GroupIcon />,
      onClick: openCustomerUserDialog,
      display: user.hasAction("VIEW_USERS") && state.form.Users?.length !== 0,
    },
  ];

  const getInfoArray = (data, fields) => {
    return fields.map((field) => ({
      label: t(field.label),
      value: field.value(data) || "---",
    }));
  };

  const emailFields = [
    { label: "email", value: (data) => data.email },
    { label: "contact", value: (data) => data.contact },
  ];

  const phoneFields = [
    { label: "number", value: (data) => formatPhoneNumber(data.number) },
    { label: "contact", value: (data) => data.contact },
  ];

  const payCardFields = [
    {
      label: "number",
      value: (data) => formatHiddenPayCardNumber(data.number),
    },
    {
      label: "validThru",
      value: (data) => data.expirationMonth + "/" + data.expirationYear,
    },
    {
      label: "activeContracts",
      value: (data) =>
        state.contracts?.filter(
          (contract) =>
            contract.paycardId === data.id &&
            contract.state === ACTIVE_CONTRACT_STATE_ID
        ).length,
    },
  ];

  const ibanFields = [
    { label: "number", value: (data) => data.number },
    {
      label: "activeContracts",
      value: (data) =>
        state.contracts?.filter(
          (contract) =>
            contract.ibanId === data.id &&
            contract.state === ACTIVE_CONTRACT_STATE_ID
        ).length,
    },
  ];

  const invoicingGroupFields = [{ label: "name", value: (data) => data.name }];
  const customerUser = state.form.Users?.length ? state.form.Users[0] : null;

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={1} alignItems="center">
            <Grid item flexGrow={1}>
              <Typography variant="h4">{t("editCustomer")}</Typography>
            </Grid>
            <Grid item>
              <ActionIconButtons actions={actions} />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <CustomerForm
                customer={state.form}
                handleInputChange={handleInputChange}
                canEdit={user.hasAction("EDIT_CUSTOMERS")}
                helperText={state.inputError}
              />
            </Grid>
          </Grid>
          {user.hasAction("EDIT_CUSTOMERS") ? (
            <Grid item xs={12}>
              <CustomSelect
                label={t("invoicingType")}
                value={
                  state.invoicingTypes.length !== 0
                    ? state.form.invoicingType
                    : ""
                }
                name="invoicingType"
                onChange={handleInputChange}
                options={state.invoicingTypes.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <LabeledText
                label={t("invoicingType")}
                value={getInvoicingTypeName()}
              />
            </Grid>
          )}
          {user.hasAction("EDIT_INVOICE_COMMENTS") && (
            <Grid item xs={12}>
              <TextInput
                label={t("invoiceComments")}
                multiline
                rows={4}
                name="invoiceComments"
                onChange={handleInputChange}
                value={state.form.invoiceComments}
              />
            </Grid>
          )}
          <Grid item container xs={12}>
            {user.hasAction("SET_VIRTUAL_INVOICING") && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="virtualInvoicing"
                      checked={Boolean(state.form.virtualInvoicing)}
                      onClick={handleInputChange}
                    />
                  }
                  label={t("virtualInvoicing")}
                />
              </Grid>
            )}

            {user.hasAction("MANAGE_KEY_ACCOUNTS") ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="isKeyAccount"
                      checked={Boolean(state.form.isKeyAccount)}
                      onClick={handleInputChange}
                    />
                  }
                  label={t("keyAccount")}
                />
              </Grid>
            ) : (
              <Grid item>
                <LabeledText
                  label={t("keyAccount")}
                  value={state.form.isKeyAccount ? t("yes") : t("no")}
                />
              </Grid>
            )}

            {user.hasAction("EDIT_CUSTOMER_PERSONA_NON_GRATA") ? (
              <Grid item container alignItems="center" xs={12}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="personaNonGrata"
                        checked={Boolean(state.form.personaNonGrata)}
                        onClick={handleInputChange}
                      />
                    }
                    label={t("personaNonGrata")}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      !Boolean(state.form.personaNonGrata)
                        ? t("markAsPersonaNonGrata")
                        : t("markAsPersonaGrata")
                    }
                  >
                    <Icon size="small" color="disabled">
                      <HelpIcon />
                    </Icon>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <LabeledText
                  label={t("personaNonGrata")}
                  value={state.form.personaNonGrata ? t("yes") : t("no")}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* Emails */}
          <CustomerInfoSection
            title={t("emails") + " *"}
            titleIcon={<MailIcon />}
            onAdd={() => handleOpenDialog("email", "newEmail", t("addEmail"))}
            displayAddButton={user.hasAction("EDIT_CUSTOMERS")}
            addButtonText={t("addEmail")}
          >
            <Grid item xs={12}>
              {state.form?.emails?.map((email) => (
                <List key={email.id}>
                  <InfoCard
                    infoArray={getInfoArray(email, emailFields)}
                    displayDelete={user.hasAction("EDIT_CUSTOMERS")}
                    onDelete={() => openConfirmEmail(email)}
                  />
                </List>
              ))}
              {state.form.emails == 0 && (
                <Typography variant="body1">{t("noEmails")}</Typography>
              )}
            </Grid>
          </CustomerInfoSection>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* Phones */}
          <CustomerInfoSection
            title={t("phoneNumbers")}
            titleIcon={<PhoneIcon />}
            onAdd={() => handleOpenDialog("phone", "newPhone", t("addPhone"))}
            displayAddButton={user.hasAction("EDIT_CUSTOMERS")}
            addButtonText={t("addPhone")}
          >
            <Grid item xs={12}>
              {state.form.phones.map((phone) => (
                <List key={phone.id}>
                  <InfoCard
                    infoArray={getInfoArray(phone, phoneFields)}
                    displayDelete={user.hasAction("EDIT_CUSTOMERS")}
                    onDelete={() => openConfirmPhone(phone)}
                  />
                </List>
              ))}
              {state.form.phones.length == 0 && (
                <Typography variant="body1">{t("noPhones")}</Typography>
              )}
            </Grid>
          </CustomerInfoSection>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* Credit cards */}
          <CustomerInfoSection
            title={t("creditCards")}
            titleIcon={<CreditCardIcon />}
            onAdd={() => handleOpenDialog("card", undefined, t("addCard"))}
            displayAddButton={user.hasAction("EDIT_CUSTOMERS")}
            addButtonText={t("addCreditCards")}
          >
            <Grid item xs={12}>
              <CustomSelect
                label={t("defaultPayCard")}
                value={state.form.defaultPayCardId || ""}
                onChange={openConfirmChangeDefaultPayCard}
                name="defaultPayCardId"
                options={state.form.cards
                  .filter((card) => card.isToken === false)
                  .map((card) => ({
                    label: card.legacyMask,
                    value: card.id,
                    disabled: card.isExpired,
                  }))}
              />
            </Grid>
            <PaycardListComponent
              paycards={state.form.cards.filter(
                (card) => card.isToken === false
              )}
              defaultPaycardId={state.form.defaultPayCardId}
              onDelete={openConfirmCreditCard}
            />
          </CustomerInfoSection>

          {/* Tokenized cards */}
          <CustomerInfoSection
            title={t("tokenizedCards")}
            titleIcon={<CreditCardIcon />}
          >
            <Grid item xs={12}>
              <CustomSelect
                label={t("defaultTokenizedPayCard")}
                value={state.form.defaultTokenizedPayCardId || ""}
                onChange={openConfirmChangeDefaultPayCard}
                name="defaultTokenizedPayCardId"
                options={state.form.cards
                  .filter((card) => card.isToken === true)
                  .map((card) => ({
                    label: card.mask ?? "**** **** **** ****",
                    value: card.id,
                    disabled: card.isExpired,
                  }))}
              />
            </Grid>
            <PaycardListComponent
              paycards={state.form.cards.filter(
                (card) => card.isToken === true
              )}
              defaultPaycardId={state.form.defaultTokenizedPayCardId}
              deletable={false}
            />
          </CustomerInfoSection>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* IBANs */}
          <CustomerInfoSection
            title={t("ibans")}
            titleIcon={<CreditCardIcon />}
            onAdd={() => handleOpenDialog("iban", "newIban", t("addIban"))}
            displayAddButton={user.hasAction("EDIT_CUSTOMERS")}
            addButtonText={t("addIban")}
          >
            <Grid item xs={12}>
              <CustomSelect
                label={t("defaultIBAN")}
                value={state.form.defaultIBANId || ""}
                onChange={openConfirmChangeDefaultIban}
                name="defaultIBANId"
                options={state.form.ibans.map((iban) => ({
                  label: iban.number,
                  value: iban.id,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              {state.form.ibans.map((iban) => (
                <List key={iban.id}>
                  <InfoCard
                    infoArray={getInfoArray(iban, ibanFields)}
                    displayDelete={user.hasAction("EDIT_CUSTOMERS")}
                    onDelete={() => openConfirmIban(iban)}
                    isDefault={iban.id === state.form.defaultIBANId}
                  />
                </List>
              ))}
              {state.form.ibans.length == 0 && (
                <Typography variant="body1">{t("noIbans")}</Typography>
              )}
            </Grid>
          </CustomerInfoSection>

          {/* Billing address */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <CustomerInfoSection
            title={t("billingAddress")}
            titleIcon={<ContactMailIcon />}
            onAdd={() =>
              handleOpenDialog("address", "newAddress", t("addAddress"))
            }
            displayAddButton={user.hasAction("CREATE_BILLING_ADDRESSES")}
            addButtonText={t("addBillingAddress")}
          >
            <Grid item xs={12}>
              {getVisibleAddresses()?.map((address) => (
                <List key={address.id}>
                  <BillingAddressCard
                    billingAddress={address}
                    onVisibilityChange={setActiveStatusBillingAddress}
                    setAsDefault={setAsDefaultAddress}
                  />
                </List>
              ))}
              {state.form.addresses?.length == 0 && (
                <Typography variant="body1">{t("noAddresses")}</Typography>
              )}
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Grid item>
                <Tooltip
                  title={
                    state.showInactiveBillingAddresses
                      ? t("hideInactiveBillingAddresses")
                      : t("showInactiveBillingAddresses")
                  }
                >
                  <IconButton onClick={toggleInactiveBillingAddresses}>
                    {state.showInactiveBillingAddresses ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </CustomerInfoSection>

          {/* Invoicing groups */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <CustomerInfoSection
            title={t("invoicingGroups")}
            titleIcon={<ReceiptLongIcon />}
            onAdd={() =>
              handleOpenDialog(
                "invoicingGroups",
                "newInvoicingGroup",
                t("addInvoicingGroup")
              )
            }
            displayAddButton={user.hasAction("EDIT_CUSTOMERS")}
            addButtonText={t("addInvoicingGroup")}
          >
            <Grid item xs={12}>
              {state.form.invoicingGroups?.map((group) => (
                <List key={group.id}>
                  <InfoCard
                    infoArray={getInfoArray(group, invoicingGroupFields)}
                    displayDelete={user.hasAction("EDIT_CUSTOMERS")}
                    onDelete={() => openConfirmInvoicingGroups(group)}
                  />
                </List>
              ))}
              {state.form.invoicingGroups.length == 0 && (
                <Typography variant="body1">{t("noGroups")}</Typography>
              )}
            </Grid>
          </CustomerInfoSection>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Contracts */}
          <CustomerInfoSection title={t("contracts")}>
            <Grid item xs={12}>
              <CustomTable
                xs={12}
                columns={CONTRACT_COLUMNS}
                data={state.contracts}
                options={{
                  rowlink: "contract",
                }}
              />
            </Grid>
          </CustomerInfoSection>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {user.hasAction("VIEW_INVOICES") && (
            <CustomerInfoSection title={t("invoices")}>
              <Grid item xs={12}>
                <CustomTable
                  columns={INVOICING_COLUMNS}
                  data={state.invoices}
                  options={{
                    rowlink: "invoice",
                  }}
                  fullWidth
                />
              </Grid>
            </CustomerInfoSection>
          )}

          {user.hasAction("VIEW_INVOICES") && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          {user.hasAction("VIEW_CUSTOMER_COMMENTS") && (
            <>
              <CustomerInfoSection title={t("comments")}>
                <Grid item xs={12}>
                  <Box maxHeight="320px" overflow="auto">
                    <Grid container spacing={2}>
                      <Grid item container spacing={1} xs={12}>
                        {Array.isArray(state.form.customerComments) &&
                          state.form.customerComments
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .map((comment, index) => (
                              <Grid item xs={12} key={index}>
                                <Box bgcolor="#f1f2f2" borderRadius={2} p={2}>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        {formatDateTime(comment.createdAt) +
                                          " - " +
                                          comment.authorName || t("system")}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          wordWrap: "break-word",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {comment.text}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            ))}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {user.hasAction("EDIT_CUSTOMER_COMMENTS") && (
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} md={10}>
                        <TextField
                          multiline
                          variant="outlined"
                          label={t("newComment")}
                          name="newComment"
                          value={state.newComment}
                          onChange={handleNewComment}
                          fullWidth
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CustomButton
                          onClick={addComment}
                          fullWidth
                          disabled={!state.newComment}
                        >
                          {t("addComment")}
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </CustomerInfoSection>
            </>
          )}
          <Grid item container xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {user.hasAction("EDIT_CUSTOMERS") && (
              <Grid item>
                <CustomButton onClick={submitForm} loading={state.loading}>
                  {t("save")}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={state.dialog.open}
        onClose={state.dialog.onClose}
        actions={
          state.dialog.currentDialogType !== "card" && (
            <CustomButton
              onClick={getDialogOnClick(state.dialog.currentDialogType)}
              color="success"
              startIcon={<AddCircleOutlineIcon />}
              loading={state.loading}
              disabled={getDialogDisabled(state.dialog.currentDialogType)}
            >
              {state.dialog.currentDialogType === "userEmail"
                ? t("create")
                : t("add")}
            </CustomButton>
          )
        }
        title={state.dialog.title}
        maxWidth={state.dialog.maxWidth}
      >
        <Grid container spacing={2}>
          {/* EMAIL DIALOG */}
          {state.dialog.currentDialogType === "email" && (
            <>
              <Grid item xs={12}>
                <TextInput
                  label={t("email")}
                  name="email"
                  variant="outlined"
                  onChange={handleEmailInputChange}
                  value={state.newEmail.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t("contact")}
                  name="contact"
                  variant="outlined"
                  onChange={handleEmailInputChange}
                  value={state.newEmail.contact}
                />
              </Grid>
            </>
          )}

          {/* PHONE DIALOG */}
          {state.dialog.currentDialogType === "phone" && (
            <>
              <Grid item xs={12}>
                <PhoneInput
                  inputProps={{
                    label: t("phoneNumber"),
                    name: "number",
                    onChange: (e) => {
                      handlePhoneInputChange(e);
                    },
                  }}
                  value={state.newPhone.number}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t("contact")}
                  name="contact"
                  variant="outlined"
                  onChange={handlePhoneInputChange}
                  value={state.newPhone.contact}
                />
              </Grid>
            </>
          )}

          {/* CREDIT CARD DIALOG */}
          {state.dialog.currentDialogType === "card" && (
            <Grid item xs={12}>
              <CreditCardForm
                loading={state.loading}
                onSubmit={addCreditCard}
              />
            </Grid>
          )}

          {/* IBAN DIALOG */}
          {state.dialog.currentDialogType === "iban" && (
            <Grid item xs={12}>
              <TextInput
                label={t("newIban")}
                name="newIban"
                value={state.newIban}
                onChange={handleIbanInputChange}
              />
            </Grid>
          )}

          {/* BILLING ADDRESS DIALOG */}
          {state.dialog.currentDialogType === "address" && (
            <Grid item xs={12}>
              <BillingAddressForm
                address={state.newAddress}
                handleInputChange={handleAddressInputChange}
              />
            </Grid>
          )}

          {/* INVOICING GROUPS DIALOG */}
          {state.dialog.currentDialogType === "invoicingGroups" && (
            <Grid item xs={12}>
              <TextInput
                label={t("name")}
                name="name"
                value={state.newInvoicingGroup}
                onChange={handleInvoicingGroupsInputChange}
              />
            </Grid>
          )}

          {/* USER EMAIL DIALOG */}
          {state.dialog.currentDialogType === "userEmail" && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {t("selectEmailForUser")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("emails")}
                  value={state.selectedUserEmail.email}
                  onChange={handleSelectedUserEmailInputChange}
                  options={state.form.emails?.map((email) => ({
                    value: email.email,
                    label: email.email,
                  }))}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>

      <CustomerUserDialog
        open={state.customerUserDialog}
        customerUser={customerUser}
        emails={state.form.emails}
        onClose={closeCustomerUserDialog}
      />

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={resetConfirmDialog}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>

      {user.hasAction("MANAGE_ACCESS_CONTROL_USERS") && (
        <AccessControlDialog
          accessControlUserId={state.form.AccessControlUser?.id}
          customerId={id}
          open={state.isAccessDialogOpen}
          onClose={() => handleSetAccessDialogOpen(false)}
          accessControlUserName={state.form.fullName}
        />
      )}
    </Container>
  );
}

const CustomerInfoSection = (props) => {
  const { title, titleIcon, onAdd, displayAddButton, addButtonText, children } =
    props;

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item>
        <Typography
          variant="h6"
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          {titleIcon}
          {title}
        </Typography>
      </Grid>
      {displayAddButton && (
        <Grid item flex={1} justifyContent="flex-end" display="flex">
          <Button startIcon={<AddIcon />} onClick={onAdd}>
            {addButtonText}
          </Button>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

CustomerInfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.element,
  onAdd: PropTypes.func,
  displayAddButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  children: PropTypes.element.isRequired,
};
