import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Phone from "@mui/icons-material/Phone";
import PaymentIcon from "@mui/icons-material/Payment";
import MailIcon from "@mui/icons-material/Mail";

import AppContext from "../../../context/AppContext";
import CustomSelect from "../../Inputs/CustomSelect";
import CenterSelect from "../../Inputs/CenterSelect";
import CityInput from "../../Inputs/CityInput";
import ConfirmDialog from "../../ConfirmDialog";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import TextInput from "../../Inputs/TextInput";
import CountryInput from "../../Inputs/CountryInput";
import { SPAIN_COUNTRY_ID } from "../../../data/constants";
import Select from "../../global/inputs/Select";

const initialState = {
  form: {
    address: "",
    brand: "",
    businessName: "",
    centerId: "",
    cif: "",
    city: null,
    countryId: SPAIN_COUNTRY_ID,
    comments: "",
    defaultIBAN: "",
    defaultPaymentMethodId: "",
    defaultPersonalIncome: 0,
    defaultTransferAccountId: "",
    defaultVatPercentage: 0,
    emails: [],
    ibans: [],
    regionId: 0,
    paymentDelay: 0,
    postalCode: "",
    phones: [],
  },
  inputError: {
    address: false,
    brand: false,
    businessName: false,
    centerId: false,
    cif: false,
    countryId: false,
    comments: false,
    defaultPersonalIncome: false,
    defaultVatPercentage: false,
    regionId: false,
    postalCode: false,
  },
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  centers: [],
  countries: [],
  regions: [],
  id: null,
  newContactEmail: "",
  newContactPhone: "",
  newEmail: "",
  newIban: "",
  newPhone: "",
  emailModal: { isOpen: false },
  ibanModal: { isOpen: false },
  phoneModal: { isOpen: false },
  providerPaymentMethods: [],
  saved: true,
  transferAccounts: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PROVIDER":
      return {
        ...state,
        provider: action.payload.provider,
        form: action.payload.provider,
      };
    case "SET_PAYMENT_METHODS":
      return {
        ...state,
        providerPaymentMethods: action.payload,
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload.centers };
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload };
    case "SET_REGIONS":
      return { ...state, regions: action.payload.regions };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_EMAILS":
      return {
        ...state,
        form: {
          ...state.form,
          emails: action.payload,
        },
      };
    case "SET_PHONES":
      return {
        ...state,
        form: {
          ...state.form,
          phones: action.payload,
        },
      };
    case "ADD_IBAN":
      return {
        ...state,
        form: { ...state.form, ibans: [...state.form.ibans, action.payload] },
        newIban: initialState.newIban,
      };
    case "DELETE_IBAN":
      return {
        ...state,
        form: {
          ...state.form,
          ibans: [
            ...state.form.ibans.filter((item) => item !== action.payload),
          ],
        },
      };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "ADD_EMAIL":
      return {
        ...state,
        form: { ...state.form, emails: [...state.form.emails, action.payload] },
        newEmail: initialState.newEmail,
        newContactEmail: initialState.newContactEmail,
      };
    case "DELETE_EMAIL":
      return {
        ...state,
        form: {
          ...state.form,
          emails: [
            ...state.form.emails.filter((item) => item !== action.payload),
          ],
        },
      };
    case "SET_NEW_EMAIL":
      return { ...state, newEmail: action.payload };
    case "SET_NEW_CONTACT_EMAIL":
      return { ...state, newContactEmail: action.payload };
    case "ADD_PHONE":
      return {
        ...state,
        form: { ...state.form, phones: [...state.form.phones, action.payload] },
        newPhone: initialState.newPhone,
        newContactPhone: initialState.newContactPhone,
      };
    case "DELETE_PHONE":
      return {
        ...state,
        form: {
          ...state.form,
          phones: [
            ...state.form.phones.filter((phone) => phone !== action.payload),
          ],
        },
      };
    case "SET_NEW_PHONE":
      return { ...state, newPhone: action.payload };
    case "SET_NEW_CONTACT_PHONE":
      return { ...state, newContactPhone: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_SAVED_VALUE":
      return { ...state, saved: action.payload.value };
    case "SET_TRANSFER_ACCOUNTS":
      return { ...state, transferAccounts: action.payload };
    default:
      throw new Error();
  }
}

export default function CreateProviderPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("providers");
  const [tErrors] = useTranslation("errors");

  const handleInputChange = (e) => {
    setInputErrorFalse();
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("createProvider");

    getCenters();
    getCountries();
    getRegions();
    getProviderPaymentMethods();
    getTransferAccounts();
  }, []);

  const getRegions = () => {
    api
      .get("/regions")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_REGIONS",
            payload: {
              regions: response.data,
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTERS",
            payload: {
              centers: response.data,
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCountries = () => {
    api
      .get("/countries")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_COUNTRIES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        console.log(error);
      });
  };

  const getProviderPaymentMethods = () => {
    api
      .get("/providers/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_TRANSFER_ACCOUNTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};

      state.form.brand !== "" && (data.brand = state.form.brand);
      state.form.businessName !== "" &&
        (data.businessName = state.form.businessName);
      state.form.cif !== "" && (data.cif = state.form.cif);
      state.form.address !== "" && (data.address = state.form.address);
      state.form.defaultVatPercentage !== "" &&
        (data.defaultVatPercentage = state.form.defaultVatPercentage);
      state.form.defaultPersonalIncome !== "" &&
        (data.defaultPersonalIncome = state.form.defaultPersonalIncome);
      state.form.comments !== "" && (data.comments = state.form.comments);
      state.form.postalCode !== "" && (data.postalCode = state.form.postalCode);
      state.form.city && (data.cityId = state.form.city.id);
      state.form.centerId !== "" && (data.centerId = state.form.centerId);
      state.form.country && (data.countryId = state.form.country.id);
      state.form.defaultPaymentMethodId !== "" &&
        (data.defaultPaymentMethodId = state.form.defaultPaymentMethodId);
      data.ibans = state.form.ibans;
      data.createdBy = user.id;
      state.form.paymentDelay !== "" &&
        (data.paymentDelay = state.form.paymentDelay);
      state.form.defaultIBAN !== "" &&
        (data.defaultIBAN = state.form.defaultIBAN);
      state.form.defaultTransferAccountId !== "" &&
        (data.defaultTransferAccountId = state.form.defaultTransferAccountId);

      data.emails = state.form.emails;
      //TODO: Country code can be of different lengths, find another method
      data.phones = state.form.phones.map((phone) => {
        phone.countryCode = phone.number?.substring(0, 3);
        return phone;
      });

      dispatch({ type: "SET_SAVED_VALUE", payload: { value: false } });
      api
        .post("/providers", data)
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.msg);
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("providerCreateSuccess"), { variant: "success" });
            history.goBack();
          }
          dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } });
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } });
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = [
      "brand",
      "businessName",
      "cif",
      "countryId",
      "defaultVatPercentage",
      "defaultPersonalIncome",
    ];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    return isValid;
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const validateEmail = (value1) => {
    let isValid = true;

    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (!value1 || value1 === "" || !re.test(value1)) {
      isValid = false;
    }

    return isValid;
  };

  const handleChangeEmail = (email) => {
    dispatch({
      type: "SET_NEW_EMAIL",
      payload: email,
    });
  };

  const handleChangeEmailContact = (contact) => {
    dispatch({
      type: "SET_NEW_CONTACT_EMAIL",
      payload: contact,
    });
  };

  const handleChangePhone = (phone) => {
    dispatch({
      type: "SET_NEW_PHONE",
      payload: phone,
    });
  };

  const handleChangePhoneContact = (contact) => {
    dispatch({
      type: "SET_NEW_CONTACT_PHONE",
      payload: contact,
    });
  };

  const addIban = () => {
    if (state.newIban === "") {
      enqueueSnackbar(t("IBANIsEmpty"), { variant: "error" });
      return;
    }
    //Check if the iban has spaces and return a warning if it does
    if (/\s/.test(state.newIban))
      enqueueSnackbar(t("noSpacesAllowed"), { variant: "warning" });
    else {
      if (
        state.form.ibans.filter((item) => item.number === state.newIban)
          .length === 0 &&
        state.newIban !== ""
      ) {
        api
          .get("/iban/" + state.newIban + "/validate")
          .then((response) => {
            if (response.data.error)
              enqueueSnackbar(response.data.error, { variant: "error" });
            else {
              if (response.data.isValid) {
                const iban = {
                  number: state.newIban,
                };

                dispatch({ type: "ADD_IBAN", payload: iban });
                handleCloseIbanModal();
              } else {
                enqueueSnackbar(t("invalidIBAN"), { variant: "warning" });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(error, { variant: "error" });
          })
          .finally(() => {
            // If is the first iban, put it in default:
            if (state.form.ibans.length === 0)
              dispatch({
                type: "SET_INPUT",
                payload: {
                  inputname: "defaultIBAN",
                  value: state.newIban,
                },
              });
          });
      } else {
        enqueueSnackbar(t("IBANIsRepeated"), { variant: "error" });
      }
    }
  };

  const openConfirmIBAN = (iban) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteIBANQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: iban.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteIban(iban);
          resetConfirmDialog();
        },
      },
    });
  };

  const deleteIban = (iban) => {
    dispatch({ type: "DELETE_IBAN", payload: iban });
  };

  const handleInputChangeIban = (e) => {
    dispatch({ type: "SET_NEW_IBAN", payload: e.target.value });
  };

  const addEmail = () => {
    const isEmailRepeated = state.form.emails.some(
      (email) => email.email == state.newEmail
    );

    if (isEmailRepeated)
      enqueueSnackbar(t("emailRepeated"), { variant: "warning" });
    else if (!validateEmail(state.newEmail))
      enqueueSnackbar(t("invalidEmail"), { variant: "warning" });
    else {
      dispatch({
        type: "ADD_EMAIL",
        payload: {
          email: state.newEmail,
          contact: state.newContactEmail,
        },
      });
      dispatch({ type: "SET_NEW_EMAIL", payload: "" });
      dispatch({ type: "SET_NEW_CONTACT_EMAIL", payload: "" });
      handleCloseEmailModal();
    }
  };

  const openConfirmEmail = (email) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteEmailQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          email.email + (email.contact !== null ? " - " + email.contact : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmail(email);
          resetConfirmDialog();
        },
      },
    });
  };

  const deleteEmail = (item) => {
    dispatch({
      type: "DELETE_EMAIL",
      payload: item,
    });
  };

  const addPhone = () => {
    const isPhoneRepeated = state.form.phones.some(
      (phone) => phone.number == state.newPhone
    );

    if (isPhoneRepeated)
      enqueueSnackbar(t("repeatedPhone"), { variant: "warning" });
    else if (state.newPhone.length < 6)
      enqueueSnackbar(t("invalidPhone"), { variant: "warning" });
    else {
      dispatch({
        type: "ADD_PHONE",
        payload: {
          number: state.newPhone,
          contact: state.newContactPhone,
        },
      });
      dispatch({
        type: "SET_NEW_PHONE",
        payload: state.newPhone.split(" ")[0],
      });
      dispatch({ type: "SET_NEW_CONTACT_PHONE", payload: "" });
      handleClosePhoneModal();
    }
  };

  const openConfirmPhone = (phone) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deletePhoneQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: phone.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deletePhone(phone);
          resetConfirmDialog();
        },
      },
    });
  };

  const deletePhone = (item) => {
    dispatch({
      type: "DELETE_PHONE",
      payload: item,
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const handleOpenPhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: true,
      },
    });
  };

  const handleClosePhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: false,
      },
    });
    dispatch({
      type: "SET_NEW_PHONE",
      payload: "",
    });
    dispatch({
      type: "SET_NEW_CONTACT_PHONE",
      payload: "",
    });
  };

  const handleCloseEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: false,
      },
    });
    dispatch({
      type: "SET_NEW_CONTACT_EMAIL",
      payload: "",
    });
  };

  const handleOpenEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: true,
      },
    });
  };

  const handleOpenIbanModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "ibanModal",
        value: true,
      },
    });
  };

  const handleCloseIbanModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "ibanModal",
        value: false,
      },
    });
    dispatch({
      type: "SET_NEW_IBAN",
      payload: "",
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createProvider")}</Typography>
          </Grid>

          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={6}>
              <TextInput
                error={state.inputError.brand}
                helperText={
                  state.inputError.brand
                    ? t("brand") + " " + t("inputError")
                    : ""
                }
                label={t("brand")}
                value={state.form.brand}
                onChange={handleInputChange}
                name="brand"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                error={state.inputError.businessName}
                helperText={
                  state.inputError.businessName
                    ? t("businessName") + " " + t("inputError")
                    : ""
                }
                label={t("businessName")}
                value={state.form.businessName}
                onChange={handleInputChange}
                name="businessName"
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                error={state.inputError.cif}
                helperText={
                  state.inputError.cif ? t("cif") + " " + t("inputError") : ""
                }
                label={t("cif")}
                value={state.form.cif}
                onChange={handleInputChange}
                name="cif"
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CountryInput
                label={t("country")}
                name="country"
                value={state.form.country}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                error={state.inputError.address}
                helperText={
                  state.inputError.address
                    ? t("address") + " " + t("inputError")
                    : ""
                }
                label={t("address")}
                value={state.form.address}
                onChange={handleInputChange}
                name="address"
              />
            </Grid>
            <Grid item xs={8} sm={4}>
              <CityInput
                label={t("city")}
                name="city"
                value={state.form.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <PostalCodeInput
                value={state.form.postalCode}
                onChange={handleInputChange}
                label={t("postalCode")}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextInput
                error={state.inputError.defaultVatPercentage}
                helperText={
                  state.inputError.defaultVatPercentage
                    ? t("defaultVatPercentage") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("defaultVatPercentage")}
                type="number"
                value={state.form.defaultVatPercentage}
                onChange={handleInputChange}
                name="defaultVatPercentage"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextInput
                error={state.inputError.defaultPersonalIncome}
                helperText={
                  state.inputError.defaultPersonalIncome
                    ? t("defaultPersonalIncome") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("defaultPersonalIncome")}
                type="number"
                value={state.form.defaultPersonalIncome}
                onChange={handleInputChange}
                name="defaultPersonalIncome"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CenterSelect
                error={state.inputError.center}
                helperText={
                  state.inputError.center
                    ? t("center") + " " + t("inputError")
                    : ""
                }
                name="centerId"
                value={state.form.centerId}
                onChange={handleInputChange}
                autoWidth
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                label={t("defaultPaymentMethod")}
                value={state.form.defaultPaymentMethodId}
                onChange={handleInputChange}
                name="defaultPaymentMethodId"
                options={[
                  { label: "-", value: "" },
                  ...state.providerPaymentMethods.map((method) => ({
                    label: t(method.name),
                    value: method.id,
                  })),
                ]}
                shrink={true}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                options={[
                  { value: null, label: t("none") },
                  ...state.transferAccounts.map((account) => ({
                    value: account.id,
                    label: account.accountName,
                  })),
                ]}
                name="defaultTransferAccountId"
                value={state.form.defaultTransferAccountId}
                onChange={handleInputChange}
                label={t("defaultTransferAccount")}
                autoWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                label={t("paymentDelay")}
                value={state.form.paymentDelay}
                onChange={handleInputChange}
                name="paymentDelay"
                options={[
                  { value: "0", label: "0" },
                  { value: "15", label: "15" },
                  { value: "30", label: "30" },
                  { value: "60", label: "60" },
                ]}
                shrink={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={state.inputError.comments}
                helperText={
                  state.inputError.comments
                    ? t("comments") + " " + t("inputError")
                    : ""
                }
                label={t("comments")}
                multiline
                rows={4}
                value={state.form.comments}
                onChange={handleInputChange}
                name="comments"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Email */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <MailIcon />
                  {t("emails")}
                </Typography>
              </Grid>
              <Grid item flex={1} justifyContent="flex-end" display="flex">
                <Button startIcon={<AddIcon />} onClick={handleOpenEmailModal}>
                  {t("addEmail")}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {state.form.emails?.map((email) => (
                <List key={email.email}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("address") + ": "}
                          </Typography>
                          <Typography variant="body">{email?.email}</Typography>
                          <Grid item xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {email.contact ? email.contact : "---"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          flex={1}
                          alignItems="flex-start"
                          justifyContent="flex-end"
                          display="flex"
                        >
                          {user.hasAction("EDIT_PROVIDERS") && (
                            <IconButton onClick={() => openConfirmEmail(email)}>
                              <HighlightOffIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.emails.length == 0 && t("noEmails")}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Phones */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Phone />
                  {t("phoneNumbers")}
                </Typography>
              </Grid>
              {user.hasAction("EDIT_PROVIDERS") && (
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenPhoneModal}
                  >
                    {t("addPhone")}
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              {state.form.phones?.map((phone) => (
                <List key={phone.number}>
                  <Card key={phone.id} variant="outlined" xs={6}>
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("number") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone?.number}
                          </Typography>
                          <Grid item xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone.contact ? phone.contact : "---"}
                          </Typography>
                        </Grid>
                        {user.hasAction("EDIT_PROVIDERS") && (
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <IconButton onClick={() => openConfirmPhone(phone)}>
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.phones.length == 0 && t("noPhones")}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* IBANs */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <PaymentIcon />
                  {t("IBANs")}
                </Typography>
              </Grid>
              <Grid item flex={1} justifyContent="flex-end" display="flex">
                <Button startIcon={<AddIcon />} onClick={handleOpenIbanModal}>
                  {t("addIban")}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CustomSelect
                label={t("defaultIBAN")}
                value={state.form.defaultIBAN}
                onChange={handleInputChange}
                name="defaultIBAN"
                options={[
                  { value: "", label: t("none") },
                  ...state.form.ibans.map((iban) => ({
                    label: iban.number,
                    value: iban.number,
                  })),
                ]}
                shrink={true}
              />
            </Grid>
            <Grid item xs={12}>
              {state.form.ibans?.map((iban) => (
                <List key={iban.number}>
                  <Card variant="outlined" xs={6}>
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("number") + ": "}
                          </Typography>
                          <Typography variant="body">{iban?.number}</Typography>
                        </Grid>
                        {user.hasAction("EDIT_PROVIDERS") && (
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <IconButton onClick={() => openConfirmIBAN(iban)}>
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.ibans.length == 0 && t("noIbans")}
            </Grid>

            <Grid item container spacing={1} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button onClick={() => history.goBack()}>{t("back")}</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  disabled={!state.saved}
                  onClick={submitForm}
                >
                  {!state.saved ? t("creating") : t("create")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Confirm Dialog */}
        <ConfirmDialog
          title={state.confirmDialog.title}
          open={state.confirmDialog.isOpen}
          setOpen={setConfirmDialogState}
          confirmText={state.confirmDialog.confirmText}
          cancelText={state.confirmDialog.cancelText}
          onConfirm={state.confirmDialog.callback}
        >
          <Typography variant="body2" color="initial">
            {state.confirmDialog.childrenText}
          </Typography>
        </ConfirmDialog>
      </Paper>

      {/* Add email dialog*/}
      <Dialog
        open={state.emailModal.isOpen}
        onClose={handleCloseEmailModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addEmail")}
          <IconButton onClick={handleCloseEmailModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInput
                label={t("email")}
                name="email"
                variant="outlined"
                onChange={(e) => {
                  handleChangeEmail(e.target.value);
                }}
                value={state.newEmail}
              />
            </Grid>
            <Grid item xs={10}>
              <TextInput
                label={t("contact")}
                name="newContactEmail"
                variant="outlined"
                onChange={(e) => {
                  handleChangeEmailContact(e.target.value);
                }}
                value={state.newContactEmail}
              />
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" color="primary" onClick={addEmail}>
                <AddCircleOutlineOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Add phone dialog*/}
      <Dialog
        open={state.phoneModal.isOpen}
        onClose={handleClosePhoneModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addPhoneNumber")}
          <IconButton onClick={handleClosePhoneModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PhoneInput
                inputProps={{
                  label: t("phoneNumber"),
                  name: "phone",
                  onChange: (e) => {
                    handleChangePhone(e.target.value);
                  },
                }}
                value={state.newPhone}
              />
            </Grid>
            <Grid item xs={10}>
              <TextInput
                label={t("contact")}
                name="contactPhone"
                onChange={(e) => {
                  handleChangePhoneContact(e.target.value);
                }}
                value={state.newContactPhone}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={addPhone} color="primary">
                <AddCircleOutlineOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.ibanModal.isOpen}
        onClose={handleCloseIbanModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addIban")}
          <IconButton onClick={handleCloseIbanModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={2}>
            <Grid item>
              <TextInput
                label={t("newIban")}
                name="newIban"
                value={state.newIban}
                onChange={(e) => {
                  handleInputChangeIban(e);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  addIban(state.newIban);
                }}
              >
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
