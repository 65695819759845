import { colors } from "../components/pages/Dashboard/Components/ColorGenerator";

export const days = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const quarters = ["T1", "T2", "T3", "T4"];

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getContractState = (
  contract,
  startDate = new Date(),
  endDate = new Date()
) => {
  let state = -1;
  if (contract.startDate) {
    state = 1;
    let contractStartDate = new Date(contract.startDate);
    let contractEndDate = new Date(contract.endDate);

    if (contractStartDate > startDate) state = 0;
    if (contractEndDate <= endDate) state = 2;
  }
  return state;
};

export const getActiveContractMonths = (contract) => {
  let months = [];
  const startDate = contract.startDate
    ? new Date(contract.startDate)
    : new Date();
  const endDate = contract.endDate ? new Date(contract.endDate) : new Date();

  while (startDate <= endDate) {
    months.push(
      `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(
        2,
        "0"
      )}`
    );
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return months;
};

export const distinctValues = (array) => {
  return [...new Set(array)];
};

export const count = (array, values) => {
  let c = [];
  values.forEach((v) => {
    c.push(array.filter((a) => a === v).length);
  });
  return c;
};

export const sumArray = (array) => {
  let sum = 0;
  array.forEach((a) => (sum += a));
  return sum;
};

export const percentage = (array, values) => {
  let percentages = [];
  let countValues = count(array, values);

  countValues.forEach((countValue) => {
    let percentageValue = (countValue / array.length) * 100;
    percentages.push(percentageValue.toFixed(2));
  });

  return percentages;
};

// Converts an array of numbers to percentages
export const toPercentage = (array) => {
  let sum = 0;
  array.forEach((item) => (sum += item));

  return array.map((item) => (item / sum) * 100);
};

export const mean = (array) => {
  let sum = 0;
  array.forEach((v) => (sum += v));
  return sum / array.length;
};

export const formatDate = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let day = date.getDate();
  if (day < 10) day = "0" + day;

  return date.getFullYear() + "-" + month + "-" + day;
};

export const getDataArray = (items, labels, period, valueGetter) => {
  return labels.map((label) => {
    const labelItems = items.filter((item) => {
      const itemDate = new Date(item.date);
      const labelDate = new Date(label);
      if (period === "years")
        return itemDate.getFullYear() === labelDate.getFullYear();
      else if (period === "months")
        return (
          itemDate.getMonth() === labelDate.getMonth() &&
          itemDate.getFullYear() === labelDate.getFullYear()
        );
      else if (period === "days")
        return (
          itemDate.getDate() === labelDate.getDate() &&
          itemDate.getMonth() === labelDate.getMonth() &&
          itemDate.getFullYear() === labelDate.getFullYear()
        );
      else return false;
    });
    return valueGetter(labelItems);
  });
};

export const getMonths = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  let months = [];

  startDate.setDate(1);
  endDate.setDate(1);

  // Prevent infinite loop
  if (endDate.getTime() < startDate.getTime()) return months;

  while (startDate.getTime() <= endDate.getTime()) {
    months.push({
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
    });
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return months;
};

export const ranges = (min, max, n) => {
  const range = max - min;
  const divisionRange = range / n;
  let ranges = [];
  for (let i = divisionRange; i < max; i += divisionRange) {
    ranges.push(i);
  }
  return [min, ...ranges, max];
};

export const splitConcat = (array) => {
  return [].concat.apply([], array);
};

export const toCumulativeArray = (array) => {
  let sum;
  return array.map(((sum = 0), (n) => (sum += n)));
};

// This function generates an array of labels based on the data and the configuration.
// Config object should have the following properties:
// - key: the key to be used for the label
// - callBack: a function to be called for each label
export const getLabels = (data, config) => {
  const labels = [];
  for (let i = 0; i < data.length; i++) {
    labels.push(config.callBack(data[i][config.key]));
  }
  return labels;
};

// ================================================================================================================

/**
 * Generates an array of formatted dates between a start and end date, based on the specified period.
 *
 * @function getDates
 * @param {string|Date} start - The starting date of the range. Can be a string or Date object.
 * @param {string|Date} [end] - The ending date of the range. Can be a string or Date object. If not provided, defaults to the current date.
 * @param {string} [period="month"] - The period for which dates are generated. Can be "day", "month", or "year".
 *        - "day" or "days": Generates daily dates.
 *        - "month" or "months": Generates monthly dates in the format "YYYY-MM".
 *        - "year" or "years": Generates yearly dates in the format "YYYY".
 *
 * @throws {Error} If an invalid period is provided.
 *
 * @returns {Array<string>} - An array of formatted date strings representing the range between `start` and `end` based on the specified period.
 *
 * Example usage:
 *  getDates('2022-01-01', '2022-01-10', 'day');
 *  // Returns ["2022-01-01", "2022-01-02", ..., "2022-01-10"]
 *
 *  getDates('2022-01-01', '2022-12-31', 'month');
 *  // Returns ["2022-01", "2022-02", ..., "2022-12"]
 *
 *  getDates('2020', '2025', 'year');
 *  // Returns ["2020", "2021", "2022", "2023", "2024", "2025"]
 */
export const getDates = (start, end, period = "month") => {
  let startDate = new Date(start);
  let endDate = end ? new Date(end) : new Date();

  let dates = [];

  if (period === "day" || period === "days") {
    while (startDate <= endDate) {
      let formattedDate = startDate.toISOString().split("T")[0];
      dates.push(formattedDate);
      startDate.setDate(startDate.getDate() + 1);
    }
  } else if (period === "month" || period === "months") {
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    while (startDate <= endDate) {
      let formattedDate = `${startDate.getFullYear()}-${String(
        startDate.getMonth() + 1
      ).padStart(2, "0")}`;
      dates.push(formattedDate);
      startDate.setMonth(startDate.getMonth() + 1);
    }
  } else if (period === "year" || period === "years") {
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setMonth(0);
    endDate.setDate(0);

    while (startDate <= endDate) {
      let formattedDate = `${startDate.getFullYear()}`;
      dates.push(formattedDate);
      startDate.setFullYear(startDate.getFullYear() + 1);
    }
  } else {
    throw new Error("Invalid period. Use 'day', 'month', or 'year'.");
  }

  return dates;
};

/**
 * Parses and groups the provided `data` into structured datasets based on the specified grouping criteria.
 *
 * @function dataParser
 * @param {Array} data - The input data to be parsed and grouped.
 * @param {(string|string[]|object|object[])} groupBy - The property or properties used to group the data.
 *        It can be:
 *        - A string: single-level grouping by a field.
 *        - An object: single-level grouping with optional custom properties.
 *          - `propName` {string} (required): The name of the property to group by.
 *          - `valueCalculator` {function} (optional): A function to calculate values for grouping (defaults to item[propName]).
 *          - `labels` {Array} (optional): A predefined set of labels for the group.
 *          - `isDate` {boolean} (optional): A flag to determine if the property is a date (default: false).
 *          - `datePeriod` {string} (optional): The period for date grouping (day, month, year).
 *        - An array of strings or objects (max length: 2): multi-level grouping.
 *
 * @param {Object} config - Configuration object containing additional options.
 *        - `propName` {string} (required): The name of the property to store the calculated value in the resulting dataset.
 *        - `valueCalculator` {function} (required): A function to calculate values based on the grouped data.
 *        - `accumulated` {boolean} (optional): A flag to determine if the values should be accumulated (default: false).
 *
 * @returns {Array} - An array of grouped and structured datasets.
 *  Each dataset corresponds to a group created from `groupBy`, with calculated values based on `config`.
 *
 * If `groupBy` contains a secondary key (multi-level grouping), data is nested into secondary groups.
 */
export const dataParser = (data, groupBy, config) => {
  const dataSets = [];

  let mainElement = getGroupByElement(groupBy, 0);
  let secondaryElement = getGroupByElement(groupBy, 1);

  if (!data || !groupBy || !groupBy.length) return dataSets;

  const result = Object.groupBy(
    data,
    functionCallBack(
      mainElement.valueCalculator,
      mainElement.isDate,
      mainElement.datePeriod
    )
  );
  const labelKeys = mainElement.labels
    ? mainElement.labels
    : Object.keys(result);
  const secondaryLabels = secondaryElement?.labels;

  labelKeys.forEach((key) => {
    let obj = {
      [mainElement.propName]: key,
    };
    let items = result[key] || [];

    if (secondaryElement) {
      const secondResult = Object.groupBy(
        items,
        functionCallBack(
          secondaryElement.valueCalculator,
          secondaryElement.isDate,
          secondaryElement.datePeriod
        )
      );
      const secondResultKeys = secondaryLabels
        ? secondaryLabels
        : Object.keys(secondResult);

      secondResultKeys.forEach((secondKey) => {
        obj[secondKey] = config.valueCalculator(
          secondResult[secondKey] || [],
          key
        );
      });
    } else {
      obj[config.propName] = config.valueCalculator(items, key);
    }

    if (config.accumulated && dataSets.length > 0) {
      const lastItem = dataSets[dataSets.length - 1];
      obj[config.propName] += lastItem[config.propName];
    }

    dataSets.push(obj);
  });

  if (secondaryElement && !secondaryElement.labels) return fixItems(dataSets);

  return dataSets;
};

/**
 * Generates chart data based on the provided `data`, grouping criteria, and configuration options.
 *
 * @function chartDataGenerator
 * @param {Array} data - The input data to be parsed and grouped for chart visualization.
 * @param {(string|string[]|object|object[])} groupBy - The property or properties used to group the data.
 *        It can be:
 *        - A string: single-level grouping by a field.
 *        - An object: single-level grouping with optional custom properties.
 *          - `propName` {string} (required): The name of the property to group by.
 *          - `valueCalculator` {function} (optional): A function to calculate values for grouping (defaults to item[propName]).
 *          - `labels` {Array} (optional): A predefined set of labels for the group.
 *          - `colorGetter` {function} (optional): A function to assign colors to datasets.
 *        - An array of strings or objects (max length: 2): multi-level grouping.
 *
 * @param {Object} config - Configuration object containing additional options for chart data generation.
 *        - `propName` {string} (required): The name of the property used to store the calculated values in the chart dataset.
 *        - `valueCalculator` {function} (required): A function to calculate values based on the grouped data.
 *        - `stacked` {boolean} (optional): A flag to determine if the chart should be stacked (default: false).
 *        - `sortedBy` {string} (optional): The dataset label to sort the data by (label, {{datasetLabel}}) (default: first dataset).
 *        - `order` {string} (optional): The order of the sorted data (asc, desc) (default: asc).
 *
 * @returns {Object} - An object containing:
 *   - `labels`: An array of main labels derived from the data, used for chart axes.
 *   - `datasets`: An array of dataset objects, each representing a series in the chart, with appropriate data, colors, and labels.
 *
 * The function supports multiple datasets in cases of secondary grouping and assigns colors either from a predefined array or via a custom `colorGetter` function.
 */
export const chartDataGenerator = (data, groupBy, config) => {
  const dataSets = [];
  const colorsArray = [
    colors.primary,
    colors.secondary,
    colors.red,
    colors.brown,
    colors.yellow,
    colors.orange,
    colors.purple,
    colors.blue,
    colors.green,
    colors.turquoise,
  ];

  let mainElement = getGroupByElement(groupBy, 0);
  let secondaryElement = getGroupByElement(groupBy, 1);

  if (!data || !groupBy || !groupBy.length) return dataSets;

  let mainLabels = mainElement.labels
    ? mainElement.labels
    : getLabels(data, {
        key: mainElement.propName,
        callBack: (label) => label,
      });
  const secondaryLabels = secondaryElement?.valueCalculator
    ? Object.keys(data && data.length ? data[0] : {}).filter(
        (key) => key !== mainElement.propName
      )
    : null;

  if (secondaryElement) {
    secondaryLabels.forEach((label, index) => {
      const color = secondaryElement.colorGetter
        ? secondaryElement.colorGetter(label)
        : colorsArray[index % colorsArray.length];
      const obj = {
        label,
        data: [],
        backgroundColor: color,
        borderColor: color,
        stack: config.stacked === true ? "Stack 0" : `Stack ${index}`,
      };
      dataSets.push(obj);
    });
  } else {
    const obj = {
      label: config.propName,
      data: [],
    };
    dataSets.push(obj);
  }

  mainLabels.forEach((label) => {
    const item = data.find((i) => i[mainElement.propName] === label);

    if (secondaryElement) {
      secondaryLabels.forEach((secondaryLabel) => {
        const value = item && item[secondaryLabel] ? item[secondaryLabel] : 0;
        dataSets.find((d) => d.label === secondaryLabel).data.push(value);
      });
    } else {
      const value = item ? item[config.propName] : 0;
      dataSets[0].data.push(value);
    }
  });

  let sortedDatasets = [];
  if (!secondaryElement) {
    const color = mainElement.colorGetter
      ? mainElement.colorGetter(dataSets[0].data, mainLabels)
      : colorsArray[0];
    dataSets[0].backgroundColor = color;
    dataSets[0].borderColor = color;
  }

  if (config.sortedBy !== undefined) {
    const sorterFn =
      config.sortedBy !== "label"
        ? dataSorterByDatasetValue
        : dataSorterByLabel;
    const { labels, datasets } = sorterFn(
      mainLabels,
      dataSets,
      config.order,
      config.sortedBy
    );
    mainLabels = labels;
    sortedDatasets = [...datasets];
  }

  return {
    labels: mainLabels,
    datasets: dataSets,
  };
};

/**
 * Transforms chart data values into percentages.
 * If the format is "percentage", it converts the data values into percentage values.
 * Otherwise, it returns the original chart data.
 *
 * @param {Object} chartData - The chart data to be transformed.
 * @param {string} format - The format type, either "percentage" or "value".
 * @returns {Object} - Transformed chart data with percentage values.
 */
export const parsePercentageData = (chartData, format) => {
  if (!chartData || !chartData.datasets || !format || format === "value")
    return chartData;

  const arrays = chartData.datasets.map((dataset) => dataset.data);
  const percentages = valueToPercentage(arrays);
  return {
    labels: chartData.labels,
    datasets: chartData.datasets.map((dataset, index) => ({
      ...dataset,
      data: percentages[index],
    })),
  };
};

/**
 * @param {Array} sets
 *    An array of arrays of numbers
 * @returns {Array} resultSets
 * @description This function takes an array of arrays and returns an
 *    array of arrays where each element is the percentage of the total
 *    of the corresponding element in the input arrays.
 * @example
 *    valueToPercentage([[1, 2, 3], [4, 5, 6], [7, 8, 9]]);
 *    returns [[10, 20, 30], [40, 50, 60], [70, 80, 90]]
 */
export const valueToPercentage = (sets) => {
  try {
    let resultSets = [];
    let maxLength = sets.reduce(
      (max, set) => (set.length > max ? set.length : max),
      0
    );
    sets.forEach((set) => resultSets.push([]));
    for (let i = 0; i < maxLength; i++) {
      let total = 0;
      sets.forEach((set) => {
        total += set[i] || 0;
      });
      sets.forEach((set, index) => {
        if (total === 0) resultSets[index].push(0);
        else resultSets[index].push((set[i] / total) * 100);
      });
    }
    return resultSets;
  } catch (e) {
    console.log(e);
  }
};

const functionCallBack = (fn, isDate = false, period = "month") => {
  return (item) => {
    const value = fn(item);
    return datePeriodParser(value, isDate, period);
  };
};

const datePeriodParser = (date, isDate = false, period = "month") => {
  if (!isDate) return date;

  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  if (period === "year") return year;
  if (period === "month") return `${year}-${String(month).padStart(2, "0")}`;
  if (period === "day")
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  return date;
};

const dataSorterByValue = (items) => {
  const labels = [];
  const data = [];

  [...items]
    .sort((a, b) => b.value - a.value)
    .forEach((si) => {
      labels.push(si.label);
      data.push(si.value);
    });

  return { labels: labels, data: data };
};

const dataSorterByLabel = (labels, datasets, order) => {
  let sortedLabels = [...labels].sort();
  const sortedDatasets = [...datasets];

  if (order === "desc") sortedLabels.reverse();

  sortedDatasets.forEach((dataset) => {
    const newData = labels.map((label) => {
      const index = sortedLabels.indexOf(label);
      return dataset.data[index];
    });
    dataset.data = newData;
  });

  return { labels: sortedLabels, datasets: sortedDatasets };
};

const dataSorterByDatasetValue = (
  labels,
  datasets,
  order = "asc",
  sortedBy
) => {
  if (!labels?.length || !datasets?.length) return { labels: [], datasets: [] };

  let sortedLabels = [...labels];
  let sortedDatasets = [...datasets];
  let guideDataset = datasets.find((d) => d.label === sortedBy);

  if (!guideDataset) guideDataset = datasets[0];

  const sortedValues = guideDataset.data
    .slice()
    .sort((a, b) => (order === "asc" ? a - b : b - a));

  sortedLabels = sortedValues.map((value) => {
    const index = guideDataset.data.indexOf(value);
    return labels[index];
  });

  sortedDatasets.forEach((dataset) => {
    const newData = sortedLabels.map((label) => {
      const index = labels.indexOf(label);
      return dataset.data[index];
    });
    dataset.data = newData;
  });

  return { labels: sortedLabels, datasets: sortedDatasets };
};

const getGroupByElement = (groupBy, index) => {
  if (
    (Array.isArray(groupBy) && index >= groupBy.length) ||
    (!Array.isArray(groupBy) && index === 1) ||
    (Array.isArray(groupBy) && index === 1 && groupBy.length === 1)
  )
    return null;

  const defaultFn = (itemKey) => (item) =>
    item[itemKey] ? item[itemKey] : "notAssigned";
  const key = Array.isArray(groupBy) ? groupBy[index] : groupBy;
  const result =
    typeof key === "object"
      ? {
          ...key,
          propName: key.propName || "",
          valueCalculator: key.valueCalculator
            ? key.valueCalculator
            : defaultFn(key.propName),
        }
      : {
          propName: key,
          valueCalculator: defaultFn(key),
        };
  return result;
};

const fixItems = (items) => {
  const keys = new Set();
  items.forEach((item) => Object.keys(item).forEach((key) => keys.add(key)));

  items.forEach((item) => {
    keys.forEach((key) => {
      if (!(key in item)) item[key] = 0;
    });
  });
  return items;
};

// ================================================================================================================
