import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import GeneralTab from "./Tabs/GeneralTab";
import NonPaymentsTab from "./Tabs/NonPaymentsTab";
import RecuperationTab from "./Tabs/RecuperationTab";

function NonPayments() {
  const [t] = useTranslation("dashboard");

  return (
    <Page browserTitle={t("nonPayments")} paper={true}>
      <Grid item>
        <Tabs
          tabs={[
            {
              label: t("general"),
              content: <GeneralTab />,
            },
            {
              label: t("nonPayments"),
              content: <NonPaymentsTab />,
            },
            {
              label: t("recuperations"),
              content: <RecuperationTab />,
            },
          ]}
        />
      </Grid>
    </Page>
  );
}

export default NonPayments;
