import { Line as ChartJsLine } from "react-chartjs-2";

function Line({ data = {}, options = {} }) {
  const labels = data.labels && data.labels.length > 0 ? data.labels : [];

  const dataSets =
    data.datasets && data.datasets.length > 0 ? data.datasets : [];

  const chartData = {
    labels: labels,
    datasets: dataSets,
  };

  const defaultOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const chartOptions = {
    ...defaultOptions,
    ...options,
    scales: {
      ...defaultOptions.scales,
      ...options.scales,
      y: {
        ...defaultOptions.scales.y,
        ...options.scales?.y,
      },
    },
  };

  return <ChartJsLine data={chartData} options={chartOptions} />;
}

export default Line;
