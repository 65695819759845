import { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// mui
import { ButtonGroup, Grid, Typography, Tooltip } from "@mui/material";

// components & utils
import { BILLING_ERROR_STATE_ID } from "../../../../data/constants";
import { CustomTable } from "../../../CustomTable";
import { formatDate } from "../../../../utils/date";
import { getParams, generateURL } from "../../../../utils/url";
import { localeFormat } from "../../../../utils/format";
import AppContext from "../../../../context/AppContext";
import Button from "../../../Inputs/CustomButton";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import CenterSelect from "../../../Inputs/CenterSelect";
import CustomSelect from "../../../Inputs/CustomSelect";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";

import Filters from "../../../global/structure/Filters";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  customers: [],
  filters: {
    autoSearch: "false",
    customerName: "",
    invoiceNumber: "",
    centerIds: [],
    hasNonPayments: true,

    orderBy: "",
    order: "asc",

    number: "",
    dateFrom: "",
    dateUntil: "",
  },
  options: {
    loaded: true,
    rowlink: "non-payment",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_LOADED_TRUE":
      return {
        ...state,
        options: { ...state.options, loaded: true },
      };
    case "SET_LOADED_FALSE":
      return {
        ...state,
        options: { ...state.options, loaded: false },
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

function ManagementTab(props) {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { currentTab, history, CSV_HEADERS, FILTERS } = props;

  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const query = useQuery();

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const NON_PAYMENT_COLUMNS = [
    { key: "fullName", label: t("customerName"), sortType: "string" },
    {
      key: "lastCommentDate",
      label: t("lastCommentDate"),
      sortType: "date",
    },
    {
      key: "totalDebt",
      label: t("totalDebt"),
      sortType: "number",
      renderFunction: (totalDebt) => localeFormat(totalDebt) + "€",
    },
    {
      key: "pendingInvoices",
      label: t("pendingInvoices"),
      sortType: "number",
    },
  ];

  useEffect(() => {
    if (state.filters.autoSearch === "true") {
      getCustomers();
    }
  }, [state.filters.autoSearch]);

  const getCustomers = () => {
    updateQuery();
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["Invoice", "CustomerComment"],
    };

    // TODO: Add filters

    state.filters.customerName !== "" &&
      (params.name = state.filters.customerName);
    state.filters.invoiceNumber !== "" &&
      (params.invoiceNumber = state.filters.invoiceNumber);
    state.filters.hasNonPayments !== "" &&
      (params.hasNonPayments = state.filters.hasNonPayments);
    state.filters.centerIds.length > 0 &&
      (params.centerIds = state.filters.centerIds);

    api
      .get("/customers-non-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          let customers = response.data.map((customer) => ({
            ...customer,
            totalDebt: getTotalDebt(customer.Invoices),
            lastCommentDate: getLastCommentDate(customer.CustomerComments),
            pendingInvoices:
              customer.Invoices?.length > 0
                ? customer.Invoices.filter(
                    (invoice) =>
                      invoice.uncollectible === false &&
                      invoice.state !== BILLING_ERROR_STATE_ID
                  ).length
                : 0,
          }));
          if (state.filters.hasNonPayments)
            customers = customers.filter((customer) => customer.totalDebt > 0);
          dispatch({ type: "SET_CUSTOMERS", payload: customers });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMaxDate = (dates) => {
    return new Date(
      Math.max.apply(
        null,
        dates.map((date) => Date.parse(date))
      )
    );
  };

  const getLastCommentDate = (comments) => {
    if (!comments) return "";
    const dates = comments
      .map((comment) => comment.createdAt)
      .sort((a, b) => b - a);
    if (dates.length > 0) {
      const maxDate = getMaxDate(dates);
      return formatDate(maxDate);
    } else {
      return "";
    }
  };

  const getTotalDebt = (invoices) => {
    const filtredInvoices = invoices.filter(
      (invoice) =>
        invoice.uncollectible === false &&
        invoice.state !== BILLING_ERROR_STATE_ID
    );
    return filtredInvoices.reduce((sum, invoice) => {
      return sum + invoice.totalAmount;
    }, 0);
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const updateQuery = () => {
    let params = {
      tab: currentTab,
      ...state.filters,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  const search = () => {
    if (state.filters.autoSearch === "true") {
      getCustomers();
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
    }
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              name="customerName"
              onChange={handleChangeFilter}
              value={state.filters.customerName}
              label={t("customer")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <TextInput
              name="invoiceNumber"
              onChange={handleChangeFilter}
              value={state.filters.invoiceNumber}
              label={t("invoiceNumber")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <CenterSelect
              multiple
              name="centerIds"
              onChange={handleChangeFilter}
              value={state.filters.centerIds}
            />,
            <CustomSelect
              label={t("hasNonPayments")}
              name="hasNonPayments"
              onChange={handleChangeFilter}
              value={state.filters.hasNonPayments}
              options={[
                { value: "", label: t("all") },
                { value: true, label: t("yes") },
                { value: false, label: t("no") },
              ]}
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ButtonGroup variant="contained">
          <Button onClick={resetFilters}>{t("reset")}</Button>
          <ButtonCSV
            data={state.customers}
            headers={CSV_HEADERS}
            filename={t("nonPayments")}
          />
          <SearchButton onClick={search} loading={!state.options.loaded} />
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={NON_PAYMENT_COLUMNS}
          data={state.customers}
          options={state.options}
          sortBy={state.filters.orderBy}
          sort={state.filters.order}
          onSortCallback={(orderBy, order) => {
            handleOrderChange(orderBy, order);
            const url = generateURL("/app/non-payments", {
              ...state.filters,
              orderBy,
              order,
            });
            history.push(url);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ManagementTab;
