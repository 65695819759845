import { Autocomplete, Grid } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import {
  ContractDuration,
  ContractInfoPanel,
  ContractMerchantablesByDate,
  ContractTotalsPanel,
} from "../Charts/ContractReport";
import AppContext from "../../../../context/AppContext";
import CenterSelect from "../../../Inputs/CenterSelect";
import TextInput from "../../../Inputs/TextInput";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CONTRACT":
      return { ...state, contract: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_FILTERED_CONTRACTS":
      return { ...state, filteredContracts: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  centers: [],
  contract: {},
  contracts: [],
  customers: [],
  filters: {
    centerId: "",
    customer: "",
  },
  filteredContracts: [],
};

const ContractReportPanel = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");
  const [state, dispatch] = useReducer(reducer, initialState);
  const query = useQuery();
  const contractId = query.get("contractId");

  useEffect(() => {
    getCenters();
    getContracts();
    getCustomers();
  }, []);

  useEffect(() => {
    filterContracts();
  }, [state.filters, state.contracts]);

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CENTERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContracts = () => {
    let params = { include: ["Box", "Center", "Customer", "Merchantable"] };
    api
      .get("/contracts", { params })
      .then((response) => {
        if (!response.data.error) {
          if (contractId) {
            let contract = response.data.filter(
              (contract) => contract.id === Number(contractId)
            )[0];

            handleChangeFilter({
              target: { name: "centerId", value: contract.Box.centerId },
            });
            handleChangeFilter({
              target: { name: "customer", value: contract.Customer },
            });
            dispatch({ type: "SET_CONTRACT", payload: contract });
          }
          dispatch({ type: "SET_CONTRACTS", payload: response.data });
        } else {
          enqueueSnackbar(response.data.error, { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (!response.data.error) {
          dispatch({ type: "SET_CUSTOMERS", payload: response.data });
        } else {
          enqueueSnackbar(response.data.error, { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const filterContracts = () => {
    let filteredContracts = state.contracts.filter(
      (contract) =>
        (state.filters.centerId === "" ||
          state.filters.centerId === null ||
          Number(contract.Box.centerId) === state.filters.centerId) &&
        (state.filters.customer === "" ||
          state.filters.customer === null ||
          Number(contract.customerId) === state.filters.customer?.id)
    );

    dispatch({ type: "SET_FILTERED_CONTRACTS", payload: filteredContracts });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleChangeContract = (contract) => {
    dispatch({ type: "SET_CONTRACT", payload: contract });
  };

  const filterOptions = createFilterOptions({ limit: 10 });

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <Grid item>
          <CenterSelect
            value={state.filters.centerId}
            onChange={handleChangeFilter}
            name="centerId"
          />
        </Grid>
        <Grid item>
          <Autocomplete
            options={state.customers}
            getOptionLabel={(customer) => customer.fullName || ""}
            isOptionEqualToValue={(option, value) => option.value === value.id}
            renderInput={(params) => (
              <TextInput
                {...params}
                size="small"
                label={t("customer")}
                fullWidth
              />
            )}
            value={state.filters.customer}
            onChange={(e, customer) => {
              handleChangeFilter({
                target: { value: customer, name: "customer" },
              });
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.fullName}
              </li>
            )}
            filterOptions={filterOptions}
            sx={{ width: 300 }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            options={state.filteredContracts}
            getOptionLabel={(contract) => contract.Box?.name || ""}
            isOptionEqualToValue={(option, value) => option.value === value.id}
            renderInput={(params) => (
              <TextInput
                {...params}
                size="small"
                label={t("contract")}
                fullWidth
              />
            )}
            value={state.contract}
            onChange={(e, contract) => {
              handleChangeContract(contract);
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.Box?.name}
              </li>
            )}
            filterOptions={filterOptions}
            sx={{ width: 200 }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <ContractInfoPanel contract={state.contract} />
        </Grid>
        <Grid item>
          <ContractDuration contract={state.contract} />
        </Grid>
        <Grid item>
          <ContractMerchantablesByDate contract={state.contract} />
        </Grid>
        <Grid item>
          <ContractTotalsPanel contract={state.contract} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContractReportPanel;
