import { Grid } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Page from "../../../../global/structure/Page";
import Filters from "../../../../global/structure/Filters";

import AppContext from "../../../../../context/AppContext";

import { thisYear } from "../../../../../utils/date";
import { localeFormat } from "../../../../../utils/format";
import DateInput from "../../../../Inputs/CustomDate";
import InformationBox from "../../Components/InformationBox";
import PaymentMethodInput from "../../../../Inputs/PaymentMethodInput";
import SearchButton from "../../../../Inputs/SearchButton";

import { HistoricalProviderInvoices } from "../../Charts/ProviderInvoicesCharts";
import { HistoricalInvoicesByMainExpenseTypes } from "../../Charts/ProviderInvoicesCharts";
import { HistoricalInvoicesByPaymentMethod } from "../../Charts/ProviderInvoicesCharts";
import { PaymentMethodPie } from "../../Charts/ProviderInvoicesCharts";

const paymentMethodNames = {
  4: "consolidation",
  2: "paycard",
  1: "receipt",
  3: "transfer",
  [null]: "notAssigned",
};

const defaultFilters = {
  loading: true,
  dateFrom: thisYear()[0],
  dateUntil: "",
  paymentMethodIds: [],
};

function General() {
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");
  const { api } = useContext(AppContext);
  const [filters, setFilters] = useState(defaultFilters);
  const [providerInvoices, setProviderInvoices] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);

  useEffect(() => {
    getExpenseTypes();
  }, []);

  const getExpenseTypes = () => {
    api
      .get("/expense-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          setExpenseTypes(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setFilters({ ...filters, loading: false });
      });
  };

  const getProviderInvoices = () => {
    setFilters({ ...filters, loading: true });

    const params = {
      ...filters,
      paymentMethodId: filters.paymentMethodIds,
    };

    api
      .get("/provider-invoices", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else
          setProviderInvoices(
            response.data.map((item) => {
              return {
                ...item,
                paymentMethodName: t(
                  paymentMethodNames[item.paymentMethodId] || ""
                ),
                mainParentId: getMainParent(item.expenseTypeId, expenseTypes),
              };
            })
          );
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => {
        setFilters({ ...filters, loading: false });
      });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const totalAmount = providerInvoices.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  return (
    <Page browserTitle={t("providerInvoices") + " | " + t("general")}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters
            filters={[
              <DateInput
                name="dateFrom"
                label={t("dateFrom")}
                value={filters.dateFrom}
                onChange={handleFilterChange}
                disabled={filters.loading}
              />,
              <DateInput
                name="dateUntil"
                label={t("dateUntil")}
                value={filters.dateUntil}
                onChange={handleFilterChange}
                disabled={filters.loading}
              />,
              <PaymentMethodInput
                name="paymentMethodIds"
                label={t("paymentMethod")}
                value={filters.paymentMethodIds}
                onChange={handleFilterChange}
                disabled={filters.loading}
              />,
              <SearchButton
                onClick={getProviderInvoices}
                loading={filters.loading}
              />,
            ]}
          />
        </Grid>
        <Grid
          item
          container
          spacing={2}
          justifyContent={"center"}
          marginTop={1}
          marginBottom={1}
        >
          <Grid item>
            <InformationBox
              title={t("providerInvoices")}
              mainValue={providerInvoices.length}
              height={150}
              width={275}
            />
          </Grid>
          <Grid item>
            <InformationBox
              title={t("totalAmount")}
              mainValue={localeFormat(totalAmount)}
              height={150}
              width={275}
              units={"€"}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <HistoricalProviderInvoices providerInvoices={providerInvoices} />
          </Grid>
          <Grid item xs={12}>
            <HistoricalInvoicesByMainExpenseTypes
              providerInvoices={providerInvoices}
            />
          </Grid>
          <Grid item xs={7}>
            <HistoricalInvoicesByPaymentMethod
              providerInvoices={providerInvoices}
            />
          </Grid>
          <Grid item xs={5}>
            <PaymentMethodPie providerInvoices={providerInvoices} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}

function getMainParent(expenseTypeId, expenseTypes) {
  const expenseType = expenseTypes.find((item) => item.id === expenseTypeId);
  if (expenseType?.parentId)
    return getMainParent(expenseType.parentId, expenseTypes);
  return expenseType?.id || "";
}

export default General;
