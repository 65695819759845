export const colors = {
  primary: "#b3d1ff",
  secondary: "#003d99",
  green: "#A1DD70",
  red: "#ff8566",
  yellow: "#e8ca4d",
  purple: "#cc66ff",
  pink: "#ff66cc",
  orange: "#e69e19",
  brown: "#996633",
  blue: "#344c64",
  turquoise: "#57a6a1",
  grey: "#808080",
  slateBlue: "#6A5ACD",
  lightBlue: "#Add8E6",
  limeGreen: "#32CD32",
  coral: "#ff7f50",
  gold: "#ffd700",
  lavender: "#e6e6fa",
  hotPink: "#ff69b4",
  sunsetOrange: "#ff4500",
  darkBrown: "#654321",
  midnightBlue: "#191970",
  seaGreen: "#2e8b57",
  lightGrey: "#d3d3d3",
  indigo: "#4b0082",
};

export const randomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

export const ColorGenerator = (color1, color2, numColors) => {
  let colorArr = [];

  //Check for hash
  if (color1[0] === "#") {
    color1 = color1.substring(1);
  }
  if (color2[0] === "#") {
    color2 = color2.substring(1);
  }

  //Convert hex color into decimal separated by red, green, blue
  let color1Arr = [
    parseInt(color1.substring(0, 2), 16),
    parseInt(color1.substring(2, 4), 16),
    parseInt(color1.substring(4), 16),
  ];

  let color2Arr = [
    parseInt(color2.substring(0, 2), 16),
    parseInt(color2.substring(2, 4), 16),
    parseInt(color2.substring(4), 16),
  ];

  //Get difference between colors with absolute value
  let diffArr = [];

  for (let i in color1Arr) {
    diffArr.push(color2Arr[i] - color1Arr[i]);
  }

  //Divide difference by number of colors
  let factors = [];

  for (let i in diffArr) {
    factors.push(diffArr[i] / numColors);
  }

  for (let i = 0; i < numColors; i++) {
    color1Arr = color1Arr.map((color, index) => {
      color += Math.round(factors[index]);
      if (color > 255) color = 255;
      if (color < 0) color = 0;
      return color;
    });

    let hexColors = color1Arr.map((color) => color.toString(16));

    hexColors = hexColors.map((color) =>
      color.length === 1 ? "0" + color : color
    );

    const color = "#" + hexColors[0] + hexColors[1] + hexColors[2];

    colorArr.push(color);
  }

  return colorArr;
};

export const addAlfa = (color, alfa) => {
  if (isNaN(alfa) || alfa < 0 || alfa > 1) throw Error("Alfa is invalid");
  if (typeof color !== "string" || ![4, 7].includes(color.length))
    throw Error("Color is invalid");

  let red = 0;
  let green = 0;
  let blue = 0;
  // TODO Change to regex
  if (color.length === 7) {
    red = hexToDecimal(color.substring(1, 3));
    green = hexToDecimal(color.substring(3, 5));
    blue = hexToDecimal(color.substring(5, 7));
  } else if (color.length === 4) {
    red = hexToDecimal(color[1].repeat(2));
    green = hexToDecimal(color[2].repeat(2));
    blue = hexToDecimal(color[3].repeat(2));
  }

  return `rgba(${red},${green},${blue},${alfa})`;
};

const hexToDecimal = (hex) => {
  return parseInt(Number("0x" + hex), 10);
};
