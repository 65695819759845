import { useTranslation } from "react-i18next";
import Dialog from "../../global/Dialog";
import PropTypes from "prop-types";
import { ButtonGroup, Divider, Grid, Typography } from "@mui/material";
import LabeledText from "../../global/LabeledText";
import { localeFormat } from "../../../utils/format";
import _ from "lodash";
import Select from "../../global/inputs/Select";
import {
  CASH_PAYMENT_METHOD_ID,
  OPERATIVE_BANK_ACCOUNT_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  SEND_PAYMENT_LINK,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  VIRTUAL_PAYCARD_ID,
} from "../../../data/constants";
import { useContext, useState } from "react";
import CustomButton from "../../Inputs/CustomButton";
import AppContext from "../../../context/AppContext";
import { enqueueSnackbar } from "notistack";
import TextInput from "../../Inputs/TextInput";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import CreditCardForm from "../../CreditCardForm";
import Filters from "../../global/structure/Filters";
import DateInput from "../../Inputs/CustomDate";
import SearchButton from "../../Inputs/SearchButton";
import { DataGrid } from "@mui/x-data-grid";

const NonPaymentRecuperationDialog = ({
  open,
  closeRecuperationDialog,
  invoiceInfo,
  onRecuperationSuccess,
  paymentMethods,
}) => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const [loading, setLoading] = useState(false);

  const emptyRecuperationData = {
    recuperationMethodId: "",
    ibanId: "",
    paycardId: "",
    recuperationComment: "",
    paymentManagement: 0,
    bankingTransactionIds: [],
  };
  const [recuperationData, setRecuperationData] = useState(
    emptyRecuperationData
  );

  const invoiceInfoItems = [
    {
      label: t("invoice"),
      value: invoiceInfo.InvoiceSerie?.name + invoiceInfo.number,
    },
    { label: t("total"), value: `${localeFormat(invoiceInfo.totalAmount)}€` },
    {
      label: t("reason"),
      value: invoiceInfo.reason
        ? _.upperFirst(invoiceInfo.reason.toLowerCase())
        : t("unknown"),
    },
    { label: t("nonPaymentDate"), value: invoiceInfo.nonPaymentDate },
  ];

  const paymentManagementOptions = [
    { label: t("noFee"), value: 0 },
    { label: "10€", value: 10 },
    { label: "20€", value: 20 },
    { label: "40€", value: 40 },
  ];

  const recuperationPaymentMethods = [
    { label: t("cash"), value: CASH_PAYMENT_METHOD_ID },
    { label: t("receipt"), value: RECEIPT_PAYMENT_METHOD_ID },
    { label: t("card"), value: PAYCARD_PAYMENT_METHOD_ID },
    { label: t("transfer"), value: TRANSFER_PAYMENT_METHOD_ID },
    {
      label: t("tokenizedCard"),
      value: TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
    },
  ];

  const recuperateNonPayment = () => {
    if (!validateForm()) return;
    setLoading(true);

    if (recuperationData.recuperationMethodId !== RECEIPT_PAYMENT_METHOD_ID)
      recuperationData.ibanId = null;
    if (
      ![
        PAYCARD_PAYMENT_METHOD_ID,
        TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
      ].includes(recuperationData.recuperationMethodId)
    )
      recuperationData.paycardId = null;

    api
      .post(
        `/non-payments/${invoiceInfo.nonPaymentId}/recuperate`,
        recuperationData
      )
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          enqueueSnackbar(t("nonPaymentRecuperatedSuccessfully"), {
            variant: "success",
          });
          handleCloseRecuperationDialog();
          onRecuperationSuccess();
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => setLoading(false));
  };

  const VALIDATION_RULES = [
    {
      condition: (data) => data.recuperationMethodId === "",
      message: "recuperationMethodRequired",
    },
    {
      condition: (data) =>
        data.recuperationMethodId === RECEIPT_PAYMENT_METHOD_ID &&
        data.ibanId === "",
      message: "ibanRequired",
    },
    {
      condition: (data) =>
        [
          PAYCARD_PAYMENT_METHOD_ID,
          TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
        ].includes(data.recuperationMethodId) && data.paycardId === "",
      message: "paycardRequired",
    },
    {
      condition: (data) =>
        TRANSFER_PAYMENT_METHOD_ID === data.recuperationMethodId &&
        data.bankingTransactionIds.length === 0,
      message: "bankingTransactionRequired",
    },
  ];

  const validateForm = () => {
    for (const rule of VALIDATION_RULES) {
      if (rule.condition(recuperationData)) {
        enqueueSnackbar(t(rule.message), { variant: "error" });
        return false;
      }
    }
    return true;
  };

  const onChangeRecuperationData = (key, value) => {
    setRecuperationData({
      ...recuperationData,
      [key]: value,
      ...(key === "recuperationMethodId" && {
        ibanId: "",
        paycardId: "",
        bankingTransactionIds: [],
      }),
    });
  };

  const resetRecuperationData = () => {
    setRecuperationData(emptyRecuperationData);
  };

  const handleCloseRecuperationDialog = () => {
    resetRecuperationData();
    closeRecuperationDialog();
  };

  const isPaymentByLink = () =>
    recuperationData.recuperationMethodId ===
      TOKENIZED_PAYCARD_PAYMENT_METHOD_ID &&
    recuperationData.paycardId === SEND_PAYMENT_LINK;

  const getActionButtonText = () => {
    if (isPaymentByLink()) return t("sendPaymentLink");

    return t("recuperate");
  };

  const getRecuperationInfoText = () => {
    if (recuperationData.recuperationMethodId === RECEIPT_PAYMENT_METHOD_ID)
      return t("receiptRecuperationInfo");
    if (recuperationData.recuperationMethodId === TRANSFER_PAYMENT_METHOD_ID)
      return t("transferRecuperationInfo");
    if (
      recuperationData.recuperationMethodId ===
      TOKENIZED_PAYCARD_PAYMENT_METHOD_ID
    )
      return t("tokenizedCardRecuperationInfo");
    return "";
  };

  const showRecuperationComment = () => {
    if (isPaymentByLink()) return false;

    return true;
  };

  const showRecuperationInfo = () => {
    if (
      [
        RECEIPT_PAYMENT_METHOD_ID,
        TRANSFER_PAYMENT_METHOD_ID,
        TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
      ].includes(recuperationData.recuperationMethodId)
    )
      return true;

    return false;
  };

  return (
    <Dialog
      open={open}
      title={t("nonPaymentInformation")}
      onClose={handleCloseRecuperationDialog}
      maxWidth="lg"
      actions={
        <CustomButton
          onClick={recuperateNonPayment}
          loading={loading}
          text={getActionButtonText()}
        />
      }
    >
      <Grid container spacing={3}>
        <Grid item container spacing={1} xs={12}>
          {invoiceInfoItems.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <LabeledText
                label={item.label}
                value={item.value?.toString() || ""}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container spacing={2} xs={12} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6">{t("recuperation")}</Typography>
          </Grid>
          <Grid item xs={12} md>
            <Select
              label={t("paymentMethod")}
              name="recuperationMethodId"
              onChange={(e) =>
                onChangeRecuperationData(e.target.name, e.target.value)
              }
              value={recuperationData.recuperationMethodId}
              options={recuperationPaymentMethods}
              autoWidth
            />
          </Grid>
          <Grid item xs={12} md>
            <Select
              label={t("addPaymentManagement")}
              name="paymentManagement"
              value={recuperationData.paymentManagement}
              onChange={(e) =>
                onChangeRecuperationData(e.target.name, e.target.value)
              }
              options={paymentManagementOptions}
              autoWidth
            />
          </Grid>
          <Grid item xs={12} md>
            <LabeledText
              label={t("recuperationAmount")}
              value={
                localeFormat(
                  invoiceInfo.totalAmount + recuperationData.paymentManagement
                ) + "€"
              }
            />
          </Grid>
          <Grid container item spacing={2} xs={12}>
            {renderPaymentMethodComponent({
              recuperationData,
              paymentMethods,
              invoiceInfo,
              onChangeRecuperationData,
            })}
          </Grid>
          {showRecuperationComment() && (
            <Grid item xs={12}>
              <TextInput
                multiline
                variant="outlined"
                label={t("recuperationComment")}
                name="recuperationComment"
                onChange={(e) =>
                  onChangeRecuperationData(e.target.name, e.target.value)
                }
                rows={4}
              />
            </Grid>
          )}
          {showRecuperationInfo() && (
            <Grid container spacing={2} item xs={12} alignItems="center">
              <Grid item>
                <InfoIcon color="disabled" />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {getRecuperationInfoText()}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

const renderPaymentMethodComponent = ({
  recuperationData,
  invoiceInfo,
  paymentMethods,
  onChangeRecuperationData,
}) => {
  switch (recuperationData.recuperationMethodId) {
    case RECEIPT_PAYMENT_METHOD_ID:
      return (
        <ReceiptComponent
          recuperationData={recuperationData}
          onChangeRecuperationData={onChangeRecuperationData}
          paymentMethods={paymentMethods}
          customerId={invoiceInfo.customerId}
        />
      );
    case PAYCARD_PAYMENT_METHOD_ID:
      return (
        <PayCardComponent
          recuperationData={recuperationData}
          onChangeRecuperationData={onChangeRecuperationData}
          paymentMethods={paymentMethods}
          customerId={invoiceInfo.customerId}
        />
      );
    case TRANSFER_PAYMENT_METHOD_ID:
      return (
        <TransferComponent
          invoiceInfo={invoiceInfo}
          onChangeRecuperationData={onChangeRecuperationData}
        />
      );
    case TOKENIZED_PAYCARD_PAYMENT_METHOD_ID:
      return (
        <TokenizedPaycardComponent
          paymentMethods={paymentMethods}
          onChangeRecuperationData={onChangeRecuperationData}
        />
      );
    default:
      return null;
  }
};

const ReceiptComponent = ({
  recuperationData,
  paymentMethods,
  customerId,
  onChangeRecuperationData,
}) => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const [newIban, setNewIban] = useState("");
  const [loading, setLoading] = useState(false);

  const createIban = () => {
    if (!newIban) return enqueueSnackbar(t("emptyIban"), { variant: "error" });
    setLoading(true);

    const data = { number: newIban };

    api
      .post(`/customers/${customerId}/iban`, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("ibanCreatedSuccessfully"), {
            variant: "success",
          });
          paymentMethods.onCreate(RECEIPT_PAYMENT_METHOD_ID, response.data);
          onChangeRecuperationData("ibanId", response.data.id);
          setNewIban("");
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => setLoading(false));
  };

  const ibanOptions = paymentMethods.ibans.map((iban) => ({
    value: iban.id,
    label: iban.number,
  }));

  return (
    <>
      <Grid item xs={12} md>
        <Select
          label={t("ibans")}
          name="ibanId"
          value={recuperationData.ibanId}
          onChange={(e) =>
            onChangeRecuperationData(e.target.name, e.target.value)
          }
          options={ibanOptions}
          autoWidth
        />
      </Grid>
      <Grid item xs={12} md>
        <TextInput
          label={t("newIban")}
          name="newIban"
          onChange={(e) => setNewIban(e.target.value)}
          value={newIban}
        />
      </Grid>
      <Grid item xs={12} md>
        <CustomButton
          startIcon={<AddIcon />}
          text={t("addIban")}
          variant="text"
          onClick={createIban}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const PayCardComponent = ({
  recuperationData,
  paymentMethods,
  customerId,
  onChangeRecuperationData,
}) => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const [dialogOpen, setDialogOpen] = useState(false);

  const paycardOptions = [
    { label: t("paymentByLink"), value: VIRTUAL_PAYCARD_ID },
    ...paymentMethods.paycards
      .filter((paycard) => !paycard.isToken)
      .map((paycard) => ({
        value: paycard.id,
        label:
          paycard.number +
          ` - (${paycard.expirationMonth}/${paycard.expirationYear})`,
        disabled: paycard.isExpired,
      })),
  ];

  const createPaycard = (card) => {
    const data = {
      name: card.name,
      number: card.number,
      expirationMonth: card.expiry.substring(0, 2),
      expirationYear: card.expiry.substring(3),
    };

    api
      .post(`/customers/${customerId}/paycard`, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("cardCreatedSuccessfully"), { variant: "success" });
          const createdPayCard = response.data;
          onChangeRecuperationData("paycardId", createdPayCard.id);
          paymentMethods.onCreate(PAYCARD_PAYMENT_METHOD_ID, createdPayCard);
          setDialogOpen(false);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  return (
    <>
      <Grid item xs={12} sm>
        <Select
          label={t("card")}
          name="paycardId"
          value={recuperationData.paycardId}
          onChange={(e) =>
            onChangeRecuperationData(e.target.name, e.target.value)
          }
          options={paycardOptions}
          autoWidth
        />
      </Grid>
      <Grid item xs={12} sm>
        <CustomButton
          startIcon={<AddCardIcon />}
          text={t("addCard")}
          variant="text"
          onClick={() => setDialogOpen(true)}
        />
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={t("addCard")}
      >
        <CreditCardForm onSubmit={createPaycard} />
      </Dialog>
    </>
  );
};

const UNASSIGNED_BANKING_TRANSACTION_TYPE = "UNASSIGNED";
const INVOICE_BANKING_TRANSACTION_TYPE = "INVOICE";

const TransferComponent = ({ invoiceInfo, onChangeRecuperationData }) => {
  const { api } = useContext(AppContext);

  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const initialFilters = {
    concept: "",
    dateFrom: invoiceInfo.nonPaymentDate,
    dateUntil: "",
    importMin: invoiceInfo.totalAmount,
    importMax: invoiceInfo.totalAmount,
    isAudited: false,
    bankingTransactionType: UNASSIGNED_BANKING_TRANSACTION_TYPE,
  };
  const [filters, setFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [bankingTransactions, setBankingTransactions] = useState([]);

  const isAuditedOptions = [
    { label: t("all"), value: "" },
    { label: t("yes"), value: true },
    { label: t("no"), value: false },
  ];

  const bankingTransactionTypesOptions = [
    { label: t("all"), value: "" },
    { label: t("unassigned"), value: UNASSIGNED_BANKING_TRANSACTION_TYPE },
    { label: t("invoice"), value: INVOICE_BANKING_TRANSACTION_TYPE },
  ];

  const BANKING_TRANSACTION_COLUMNS = [
    {
      field: "transactionDate",
      headerName: t("transactionDate"),
      flex: 1,
    },
    { field: "concept", headerName: t("concept"), flex: 2 },
    {
      field: "import",
      headerName: t("import"),
      flex: 1,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    { field: "comments", headerName: t("comments"), flex: 2 },
    {
      field: "isAudited",
      headerName: t("audited"),
      flex: 1,
      valueFormatter: ({ value }) => (value ? t("yes") : t("no")),
    },
    {
      field: "bankingTransactionType",
      headerName: t("type"),
      flex: 1,
      valueFormatter: ({ value }) => t(value?.toLowerCase()),
    },
  ];

  const getBankingTransactions = () => {
    setLoading(true);

    const params = {
      concept: filters.concept ? filters.concept : undefined,
      dateFrom: filters.dateFrom ? filters.dateFrom : undefined,
      dateUntil: filters.dateUntil ? filters.dateUntil : undefined,
      importMin: filters.importMin ? filters.importMin : undefined,
      importMax: filters.importMax ? filters.importMax : undefined,
      isAudited:
        typeof filters.isAudited === "boolean" ? filters.isAudited : undefined,
      transactionType: filters.bankingTransactionType
        ? filters.bankingTransactionType
        : undefined,
      bankAccountId: OPERATIVE_BANK_ACCOUNT_ID,
    };

    api
      .get("/banking-transactions", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const bankingTransactions = response.data.map((transaction) => ({
            ...transaction,
            isAudited: transaction.auditDate ? true : false,
            bankingTransactionType: transaction.bankingTransactionType
              ? transaction.bankingTransactionType
              : "",
          }));
          setBankingTransactions(bankingTransactions);
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => setLoading(false));
  };

  const handleChangeFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectionChange = (bankingTransactionIds) => {
    onChangeRecuperationData("bankingTransactionIds", bankingTransactionIds);
  };

  const resetFilters = () => {
    setFilters({
      concept: "",
      dateFrom: "",
      dateUntil: "",
      importMin: "0",
      importMax: "",
      isAudited: "",
      bankingTransactionType: "",
    });
  };

  const isRowSelectable = (row) => {
    if (!row.isAudited) return true;
    if (
      (row.isAudited &&
        row.bankingTransactionType === INVOICE_BANKING_TRANSACTION_TYPE) ||
      row.bankingTransactionType === ""
    )
      return true;
    return false;
  };

  return (
    <>
      <Grid item xs={12} sm>
        <Filters
          filters={[
            <TextInput
              label={t("concept")}
              name="concept"
              value={filters.concept}
              onChange={handleChangeFilter}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  getBankingTransactions();
                }
              }}
            />,
            <DateInput
              label={t("dateFrom")}
              name="dateFrom"
              value={filters.dateFrom}
              onChange={handleChangeFilter}
            />,
            <DateInput
              label={t("dateUntil")}
              name="dateUntil"
              value={filters.dateUntil}
              onChange={handleChangeFilter}
            />,
            <TextInput
              label={t("importMin")}
              name="importMin"
              value={filters.importMin}
              onChange={handleChangeFilter}
              type="number"
            />,
            <TextInput
              label={t("importMax")}
              name="importMax"
              value={filters.importMax}
              onChange={handleChangeFilter}
              type="number"
            />,
            <Select
              label={t("audited")}
              name="isAudited"
              value={filters.isAudited}
              onChange={handleChangeFilter}
              options={isAuditedOptions}
              autoWidth
            />,
            <Select
              label={t("bankingTransactionType")}
              name="bankingTransactionType"
              value={filters.bankingTransactionType}
              onChange={handleChangeFilter}
              options={bankingTransactionTypesOptions}
              autoWidth
            />,
            <ButtonGroup variant="contained" color="primary">
              <CustomButton onClick={resetFilters} text={t("reset")} />
              <SearchButton
                onClick={getBankingTransactions}
                loading={loading}
              />
            </ButtonGroup>,
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          columns={BANKING_TRANSACTION_COLUMNS}
          rows={bankingTransactions}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          isRowSelectable={(params) => isRowSelectable(params.row)}
        />
      </Grid>
    </>
  );
};

const TokenizedPaycardComponent = ({
  paymentMethods,
  onChangeRecuperationData,
}) => {
  const [t] = useTranslation("nonPayments");

  const tokenizedCardOptions = [
    { label: t("sendPaymentLink"), value: SEND_PAYMENT_LINK, disabled: true }, // When implemented remove disabled
    ...paymentMethods.paycards
      .filter((paycard) => paycard.isToken)
      .map((paycard) => ({
        value: paycard.id,
        label:
          paycard.mask +
          ` - (${paycard.expirationDate.slice(
            2
          )}/${paycard.expirationDate.slice(0, 2)})`,
        disabled: paycard.isExpired,
      })),
  ];

  return (
    <>
      <Grid item xs={12} sm>
        <Select
          label={t("tokenizedCards")}
          name="paycardId"
          onChange={(e) =>
            onChangeRecuperationData(e.target.name, e.target.value)
          }
          options={tokenizedCardOptions}
          autoWidth
        />
      </Grid>
    </>
  );
};

NonPaymentRecuperationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeRecuperationDialog: PropTypes.func.isRequired,
  invoiceInfo: PropTypes.object.isRequired,
  onRecuperationSuccess: PropTypes.func.isRequired,
  paymentMethods: PropTypes.shape({
    paycards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
      })
    ).isRequired,
    ibans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
      })
    ).isRequired,
    onCreate: PropTypes.func.isRequired,
  }).isRequired,
};

export default NonPaymentRecuperationDialog;
