import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TextInput from "../../../../../Inputs/TextInput";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

const GeneralCollapsible = (props) => {
  const { name, setName } = props;
  const [t] = useTranslation("floorPlans");

  return (
    <ItemCollapsibleTemplate title={t("basicInformation")}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextInput
            label={t("name")}
            value={name}
            onChange={(e) => setName && setName(e.target.value)}
          />
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

GeneralCollapsible.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
};

export default GeneralCollapsible;
