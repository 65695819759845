import { Line } from "react-konva";
import { useRef } from "react";

import DoorFrontIcon from "@mui/icons-material/DoorFront";
import PanoramaOutlinedIcon from "@mui/icons-material/PanoramaOutlined";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import VideocamIcon from "@mui/icons-material/Videocam";

import Camera from "./Camera";
import Door from "./Door";
import Image from "./Image";
import Rect from "./Rect";
import Text from "./Text";

const SHAPES = {
  Image: Image,
  Polygon: Line,
  Rect: Rect,
  Text: Text,
  Door: Door,
  Camera: Camera,
};

export const TileIcons = {
  Image: <PanoramaOutlinedIcon />,
  Rect: <RectangleOutlinedIcon />,
  Polygon: <TimelineIcon />,
  Text: <Text />,
  Door: <DoorFrontIcon />,
  Camera: <VideocamIcon />,
};

const Tile = (props) => {
  const {
    tile,
    onClick,
  } = props;


  const Shape = SHAPES[tile.shape];

  // Convert id to string to mute Konva warning
  tile.id = tile.id.toString();
  tile.width && (tile.width = Number(tile.width));
  tile.height && (tile.height = Number(tile.height));

  return (
    <Shape
      {...tile}
      {...props}      
      onClick={(e) => onClick && onClick(e, tile)}
      imageURL={tile.image}
    />
  );
};

export default Tile;
