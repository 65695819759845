import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ButtonGroup, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";

import ExportButton, {
  exportDataParse,
} from "../../../../global/inputs/ExportButton";
import Filters from "../../../../global/structure/Filters";
import Page from "../../../../global/structure/Page";
import Select from "../../../../global/inputs/Select";

import InformationBox from "../../Components/InformationBox";
import InvoiceStateChip from "../../../../InvoiceStateChip";

import CenterSelect from "../../../../Inputs/CenterSelect";
import CenterTypeSelect from "../../../../Inputs/CenterTypeSelect";
import DateInput from "../../../../Inputs/CustomDate";
import SearchButton from "../../../../Inputs/SearchButton";
import TextInput from "../../../../Inputs/TextInput";

import { firstDateCurrentMonth } from "../../../../../utils/date";
import { InvoicingContext } from "../InvoicingPanel";
import { localeFormat } from "../../../../../utils/format";
import { InvoicingByIssueDate } from "../../Charts/InvoicingCharts";

const initialFilters = {
  centers: [],
  centerTypes: [],
  customer: "",
  customerType: [],
  isKeyAccount: "",
  invoiceSeries: [],
  invoiceState: [],
  fromDate: firstDateCurrentMonth(),
  merchantableTypes: [],
  paymentMethods: [],
  untilDate: "",
};

function InvoicingTab() {
  const { state, getInvoicingTabData } = useContext(InvoicingContext);
  const [t] = useTranslation("dashboard");

  const [filters, setFilters] = useState(initialFilters);

  const COLUMNS = [
    {
      key: "concept",
      label: t("concept"),
    },
    {
      key: "pricePerUnit",
      label: t("pricePerUnit"),
    },
    {
      key: "units",
      label: t("units"),
    },
    {
      key: "price",
      label: t("price"),
    },
    {
      key: "merchantableType",
      label: t("merchantableType"),
    },
    {
      key: "center",
      label: t("center"),
    },
    {
      key: "centerType",
      label: t("centerType"),
    },
    {
      key: "issueDate",
      label: t("issueDate"),
    },
    {
      key: "invoice",
      label: t("invoice"),
    },
    {
      key: "serie",
      label: t("serie"),
    },
    {
      key: "paymentMethod",
      label: t("paymentMethod"),
    },
    {
      key: "Invoice.billingError",
      label: t("billingError"),
      renderFunction: (value) => (value ? t("yes") : t("no")),
    },
    {
      key: "customer",
      label: t("customer"),
    },
    {
      key: "customerType",
      label: t("customerType"),
    },
    {
      key: "keyAccount",
      label: t("keyAccount"),
      renderFunction: (value) => (value ? t("yes") : t("no")),
    },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const search = () => {
    let params = {};

    filters.centers.length > 0 && (params.centers = filters.centers);
    filters.centerTypes.length > 0 &&
      (params.centerTypes = filters.centerTypes);
    filters.customer !== "" && (params.customer = filters.customer.id);
    filters.customerType.length > 0 &&
      (params.customerType = filters.customerType);
    filters.isKeyAccount !== "" && (params.isKeyAccount = filters.isKeyAccount);
    filters.invoiceSeries.length > 0 &&
      (params.invoiceSeries = filters.invoiceSeries);
    filters.invoiceState.length > 0 &&
      (params.invoiceState = filters.invoiceState);
    filters.fromDate !== "" && (params.fromDate = filters.fromDate);
    filters.merchantableTypes.length > 0 &&
      (params.merchantableTypes = filters.merchantableTypes);
    filters.paymentMethods.length > 0 &&
      (params.paymentMethods = filters.paymentMethods);
    filters.untilDate !== "" && (params.untilDate = filters.untilDate);

    getInvoicingTabData(params);
  };

  return (
    <Page>
      <Grid container spacing={3}>
        <Grid item>
          <Filters
            filters={[
              <Select
                multiple
                searchable
                label={t("merchantableType")}
                name="merchantableTypes"
                value={filters.merchantableTypes}
                onChange={handleFilterChange}
                options={state?.merchantableTypes?.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />,
              <CenterSelect
                multiple
                name="centers"
                value={filters.centers}
                onChange={handleFilterChange}
              />,
              <CenterTypeSelect
                name="centerTypes"
                onChange={handleFilterChange}
                value={filters.centerTypes}
              />,
              <DateInput
                label={t("fromDate")}
                name="fromDate"
                value={filters.fromDate}
                onChange={handleFilterChange}
              />,
              <DateInput
                label={t("untilDate")}
                name="untilDate"
                value={filters.untilDate}
                onChange={handleFilterChange}
              />,
              <Select
                multiple
                searchable
                label={t("invoiceSerie")}
                name="invoiceSeries"
                value={filters.invoiceSeries}
                onChange={handleFilterChange}
                options={state?.invoiceSeries?.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />,
              <Select
                multiple
                searchable
                label={t("paymentMethod")}
                name="paymentMethods"
                value={filters.paymentMethods}
                onChange={handleFilterChange}
                options={state?.paymentMethods?.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />,
              <Select
                multiple
                searchable
                label={t("state")}
                name="invoiceState"
                value={filters.invoiceState}
                onChange={handleFilterChange}
                options={[
                  { value: 0, label: t("issued") },
                  { value: 1, label: t("paid") },
                  { value: 2, label: t("expired") },
                  { value: 3, label: t("unpaid") },
                  { value: 4, label: t("incorrectIssuance") },
                ]}
              />,
              <Autocomplete
                size="large"
                sx={{ minWidth: "200px" }}
                options={state.customers}
                getOptionLabel={(customer) => customer.fullName || ""}
                isOptionEqualToValue={(option, value) =>
                  option.nif === value.nif || null
                }
                filterOptions={createFilterOptions({ limit: 10 })}
                value={filters.customer || null}
                onChange={(e, customer) => {
                  handleFilterChange({
                    target: {
                      value: customer,
                      name: "customer",
                    },
                  });
                }}
                name="customer"
                renderInput={(params) => (
                  <TextInput {...params} size="small" label={t("customer")} />
                )}
              />,
              <Select
                multiple
                searchable
                label={t("customerType")}
                name="customerType"
                value={filters.customerType}
                onChange={handleFilterChange}
                options={[
                  { value: "", label: t("all") },
                  ...state.customerTypes.map((type) => ({
                    value: type.id,
                    label: type.name,
                  })),
                ]}
              />,
              <Select
                label={t("isKeyAccount")}
                name="isKeyAccount"
                value={filters.isKeyAccount}
                onChange={handleFilterChange}
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
              />,
              <ButtonGroup variant="contained">
                <ExportButton
                  data={exportDataParse(state.invoicingTabData, COLUMNS)}
                  display={true}
                />
                <SearchButton
                  onClick={() => search()}
                  loading={state.isLoading}
                />
              </ButtonGroup>,
            ]}
          />
        </Grid>

        <Grid item container spacing={2} justifyContent={"center"}>
          <Grid item>
            <InformationBox
              title={t("totalAmount")}
              mainValue={
                localeFormat(state.invoicingTabData.totalAmount || 0) + "€"
              }
              height={170}
              width={240}
            />
          </Grid>
          <Grid item>
            <InformationBox
              title={t("numberOfItems")}
              mainValue={localeFormat(
                state.invoicingTabData.numberOfItems || 0
              )}
              height={170}
              width={240}
            />
          </Grid>
          <Grid item>
            <InformationBox
              title={t("numberOfInvoices")}
              mainValue={localeFormat(
                state.invoicingTabData.numberOfInvoices || 0
              )}
              height={170}
              width={240}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <InvoicingByIssueDate
            invoiceItems={state.invoicingTabData || []}
            startDate={filters.fromDate}
            endDate={filters.untilDate}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default InvoicingTab;
