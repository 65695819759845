// MUI
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Menu,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// REACT
import { useContext, useState, useEffect, useReducer } from "react";

// OTHERS
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// CUSTOM
import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";
import TextInput from "../../Inputs/TextInput";
import CustomSelect from "../../Inputs/CustomSelect";
import CustomButton from "../../Inputs/CustomButton";
import BoxClustersTable from "./BoxClustersTable";
import {
  CLUSTER_NAMES_ARRAY,
  UNAVAILABLE_BOX_STATE_ID,
} from "../../../data/constants";
import BoxClusterAccordion from "./BoxClusterAccordion";
import { localeFormat, localeFormatInteger } from "../../../utils/format";
import ConfirmDialog from "../../ConfirmDialog";
import Page from "../../global/structure/Page";

const MAX_CLUSTER_TYPES = 6;

function reducer(state, action) {
  switch (action.type) {
    case "SET_ACQUISITION_ASSET":
      return { ...state, acquisitionAsset: action.payload };
    case "SET_ACQUISITION_ASSET_LOADING":
      return { ...state, acquisitionAssetLoading: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_BOXES_LOADING":
      return { ...state, boxesLoading: action.payload };
    case "SET_CONFIRM_DIALOG_OPEN":
      return { ...state, confirmDialogOpen: action.payload };
    case "SET_CLUSTERS":
      return { ...state, clusters: action.payload };
    case "SET_MODIFIED_BOXES":
      return { ...state, modifiedBoxes: action.payload };
    case "SET_RESET_KEY":
      return { ...state, resetKey: action.payload };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_CREATE_CLUSTER_LOADING":
      return { ...state, createClusterLoading: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
}

const initialState = {
  acquisitionAsset: null,
  acquisitionAssetLoading: false,
  boxes: [],
  boxesLoading: false,
  confirmDialogOpen: false,
  clusters: [],
  modifiedBoxes: [],
  resetKey: 0,
  submitLoading: false,
  createClusterLoading: false,
};

const initState = {
  name: "",
  minSize: "",
  maxSize: "",
  price: "",
  description: "",
};

function CreateClusterMenu({ clusters, createCluster, createLoading }) {
  const [t] = useTranslation("revenueManagement");
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValues, setInputValues] = useState({ ...initState });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInputValues({ ...initState });
  };

  const handleAccept = () => {
    createCluster(inputValues);
    handleClose();
  };

  const availableNewClusters =
    clusters?.length > 0
      ? CLUSTER_NAMES_ARRAY.filter(
          (clusterName) =>
            !clusters.some((cluster) => cluster.name === clusterName)
        ).map((cluster) => ({
          label: t(cluster),
          value: cluster,
        }))
      : CLUSTER_NAMES_ARRAY.map((cluster) => ({
          label: t(cluster),
          value: cluster,
        }));

  return (
    <>
      <Tooltip title={t("addCluster")}>
        <IconButton
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container maxWidth={250} spacing={1} padding={2}>
          <Grid item xs={12}>
            <CustomSelect
              label={t("name")}
              value={inputValues.name}
              onChange={(e) => {
                setInputValues({ ...inputValues, name: e.target.value });
              }}
              options={availableNewClusters}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t("minSize")}
              value={inputValues.minSize}
              type="number"
              onChange={(e) =>
                setInputValues({ ...inputValues, minSize: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t("maxSize")}
              value={inputValues.maxSize}
              type="number"
              onChange={(e) =>
                setInputValues({ ...inputValues, maxSize: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t("price")}
              value={inputValues.price}
              type="number"
              onChange={(e) =>
                setInputValues({ ...inputValues, price: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t("description")}
              value={inputValues.description}
              type="string"
              multiline
              rows={2}
              onChange={(e) =>
                setInputValues({ ...inputValues, description: e.target.value })
              }
            />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Grid item xs={12}>
              <CustomButton
                fullWidth
                color="success"
                disabled={
                  !inputValues.name ||
                  !inputValues.minSize ||
                  !inputValues.maxSize ||
                  !inputValues.price
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleAccept();
                }}
                loading={createLoading}
              >
                {t("createCluster")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
}

function RevenueSummary({ acquisitionAsset, boxes, hasClusters, loading }) {
  const [t] = useTranslation("revenueManagement");

  if (!boxes || !boxes.length) return <></>;

  // Filter out unavailable boxes
  const availableBoxes = boxes.filter(
    (box) => box.state !== UNAVAILABLE_BOX_STATE_ID
  );

  // Pre-calculate some values
  let activeContracts = [];
  availableBoxes.forEach((box) => {
    box.contract && activeContracts.push(box.contract);
  });
  const occupiedMeters = activeContracts.reduce(
    (acc, contract) => acc + contract.meters,
    0
  );

  // Acquisition calculations
  const GIA =
    Number(acquisitionAsset?.currentArea) +
    Number(acquisitionAsset?.aditionalConstructability);
  const lettableArea =
    GIA * (Number(acquisitionAsset?.lettableAreaRatio) / 100);
  const objectiveAveragePrice = Number(acquisitionAsset?.sqmPriceMonth);
  const objectiveOccupancy = Number(acquisitionAsset?.occupancy);
  const objectiveMonthlyInvoicing =
    (lettableArea * objectiveAveragePrice * objectiveOccupancy) / 100;

  // Actual calculations
  const totalMeters = availableBoxes.reduce((acc, box) => acc + box.meters, 0);

  const totalMonthlyInvoicingWithDiscount = availableBoxes.reduce(
    (acc, box) => acc + box.price,
    0
  );
  const totalMonthlyInvoicingMaximum = availableBoxes.reduce(
    (acc, box) => acc + (box.cluster?.price || 0),
    0
  );
  const totalMonthlyInvoicingActual = activeContracts.reduce(
    (acc, contract) => acc + contract.price,
    0
  );

  const averagePriceWithDiscount =
    totalMonthlyInvoicingWithDiscount / totalMeters;
  const averagePriceMaximum = totalMonthlyInvoicingMaximum / totalMeters;
  const averagePriceActual = totalMonthlyInvoicingActual / occupiedMeters;

  const occupancyConstructed = (occupiedMeters / totalMeters) * 100;
  const occupancyTotal = (occupiedMeters / lettableArea) * 100;

  /* HELPER FUNCTIONS */
  const getColor = (actual, objective) => {
    if (actual > objective) return "success.light";
    else if (actual < objective) return "error.light";
    else return undefined;
  };

  const getPercentageDifferenceText = (actual, objective) => {
    if (actual > objective)
      return " (↑" + localeFormatInteger((actual / objective - 1) * 100) + "%)";
    else if (actual < objective)
      return " (↓" + localeFormatInteger((1 - actual / objective) * 100) + "%)";
    else return "";
  };

  const data = {
    totalMeters: {
      title: t("totalMeters"),
      objective: isNaN(lettableArea) ? "-" : localeFormat(lettableArea) + "m²",
      actual: {
        label: localeFormat(totalMeters) + "m²",
        color: getColor(totalMeters, lettableArea),
        percentageLabel: getPercentageDifferenceText(totalMeters, lettableArea),
      },
    },
    monthlyInvoicing: {
      title: t("monthlyInvoicing"),
      objective: isNaN(objectiveMonthlyInvoicing)
        ? "-"
        : localeFormat(objectiveMonthlyInvoicing) + "€",
      withDiscount: hasClusters
        ? {
            label: localeFormat(totalMonthlyInvoicingWithDiscount) + "€",
            color: getColor(
              totalMonthlyInvoicingWithDiscount,
              objectiveMonthlyInvoicing
            ),
            percentageLabel: getPercentageDifferenceText(
              totalMonthlyInvoicingWithDiscount,
              objectiveMonthlyInvoicing
            ),
          }
        : undefined,
      maximum: hasClusters
        ? {
            label: localeFormat(totalMonthlyInvoicingMaximum) + "€",
            color: getColor(
              totalMonthlyInvoicingMaximum,
              objectiveMonthlyInvoicing
            ),
            percentageLabel: getPercentageDifferenceText(
              totalMonthlyInvoicingMaximum,
              objectiveMonthlyInvoicing
            ),
          }
        : undefined,
      actual: {
        label: localeFormat(totalMonthlyInvoicingActual) + "€",
        color: getColor(totalMonthlyInvoicingActual, objectiveMonthlyInvoicing),
        percentageLabel: getPercentageDifferenceText(
          totalMonthlyInvoicingActual,
          objectiveMonthlyInvoicing
        ),
      },
    },
    averagePrice: {
      title: t("averagePrice"),
      objective: isNaN(objectiveAveragePrice)
        ? "-"
        : localeFormat(objectiveAveragePrice) + "€/m²",
      withDiscount: hasClusters
        ? {
            label: localeFormat(averagePriceWithDiscount) + "€/m²",
            color: getColor(averagePriceWithDiscount, objectiveAveragePrice),
            percentageLabel: getPercentageDifferenceText(
              averagePriceWithDiscount,
              objectiveAveragePrice
            ),
          }
        : undefined,
      maximum: hasClusters
        ? {
            label: localeFormat(averagePriceMaximum) + "€/m²",
            color: getColor(averagePriceMaximum, objectiveAveragePrice),
            percentageLabel: getPercentageDifferenceText(
              averagePriceMaximum,
              objectiveAveragePrice
            ),
          }
        : undefined,
      actual: {
        label: localeFormat(averagePriceActual || 0) + "€/m²",
        color: getColor(averagePriceActual, objectiveAveragePrice),
        percentageLabel: getPercentageDifferenceText(
          averagePriceActual,
          objectiveAveragePrice
        ),
      },
    },
    occupancy: {
      title: t("occupancy"),
      objective: isNaN(objectiveOccupancy)
        ? "-"
        : localeFormat(objectiveOccupancy) + "%",
      actual: {
        label: localeFormat(occupancyConstructed) + "%",
        color: getColor(occupancyConstructed, objectiveOccupancy),
        percentageLabel: getPercentageDifferenceText(
          occupancyConstructed,
          objectiveOccupancy
        ),
      },
      vsObjective: objectiveOccupancy
        ? {
            label: localeFormat(occupancyTotal) + "%",
            color: getColor(occupancyTotal, objectiveOccupancy),
            percentageLabel: getPercentageDifferenceText(
              occupancyTotal,
              objectiveOccupancy
            ),
          }
        : undefined,
    },
  };

  /* Components */

  const DataLabel = ({ data, title }) => (
    <Typography variant="body2" fontWeight="bold">
      {title + ": "}
      <Typography
        variant="body2"
        fontWeight="normal"
        component="span"
        color={data.color}
      >
        {data.label}
        <Typography
          variant="caption"
          fontWeight="bold"
          component="span"
          color={data.color}
        >
          {data.percentageLabel}
        </Typography>
      </Typography>
    </Typography>
  );

  return (
    <Grid container spacing={2} justifyContent="space-evenly">
      {Object.keys(data).map((key) => (
        <Grid item xs={12} sm={6} lg={3} flexGrow={1} key={key}>
          {loading ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <Card style={{ height: "100%" }}>
              <CardContent>
                <Grid
                  container
                  item
                  xs="auto"
                  flexDirection="row"
                  rowGap={2}
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography variant="body1" fontWeight="bold">
                      {data[key].title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="body2" fontWeight="bold">
                        {t("objective") + ": "}
                        <Typography
                          variant="body2"
                          fontWeight="normal"
                          component="span"
                        >
                          {data[key].objective}
                        </Typography>
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      item
                      xs="auto"
                      rowGap={1}
                      flexDirection="column"
                    >
                      {data[key].withDiscount !== undefined && (
                        <Grid item>
                          <DataLabel
                            data={data[key].withDiscount}
                            title={t("withDiscount")}
                          />
                        </Grid>
                      )}
                      {data[key].maximum !== undefined && (
                        <Grid item>
                          <DataLabel
                            data={data[key].maximum}
                            title={t("maximum")}
                          />
                        </Grid>
                      )}
                      {data[key].actual !== undefined && (
                        <Grid item>
                          <DataLabel
                            data={data[key].actual}
                            title={
                              key === "totalMeters"
                                ? t("constructed")
                                : key === "occupancy"
                                ? t("vsConstructedMeters")
                                : t("actual")
                            }
                          />
                        </Grid>
                      )}
                      {data[key].vsObjective !== undefined && (
                        <Grid item>
                          <DataLabel
                            data={data[key].vsObjective}
                            title={t("vsObjectiveMeters")}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      ))}
    </Grid>
  );
}

function ClustersSummaryTable({ acquisitionAsset, boxes, clusters, loading }) {
  const [t] = useTranslation("revenueManagement");

  if (!boxes || !boxes.length) return <></>;

  const objectiveOccupancy = Number(acquisitionAsset?.occupancy);

  // Filter out unavailable boxes
  const availableBoxes = boxes.filter(
    (box) => box.state !== UNAVAILABLE_BOX_STATE_ID
  );

  // Pre-calculate some values
  let activeContracts = [];
  availableBoxes.forEach((box) => {
    box.contract && activeContracts.push(box.contract);
  });

  // Divide boxes by cluster
  const clustersBoxes = {};
  clusters.forEach((cluster) => {
    clustersBoxes[cluster.name] = availableBoxes.filter(
      (box) => box.cluster && box.cluster.name === cluster.name
    );
  });

  // Calculate cluster values
  const clustersValues = {};
  clusters.forEach((cluster) => {
    const clusterBoxes = clustersBoxes[cluster.name];
    const activeClusterContracts = clusterBoxes.filter((box) => box.contract);

    const totalMeters = clusterBoxes.reduce((acc, box) => acc + box.meters, 0);
    const occupiedMeters = clusterBoxes.reduce(
      (acc, box) => acc + (box.contract ? box.meters : 0),
      0
    );
    const averageClusterPrice =
      clusterBoxes.reduce((acc, box) => acc + box.cluster.price, 0) /
      totalMeters;
    const averageDiscountedPrice =
      clusterBoxes.reduce((acc, box) => acc + box.price, 0) / totalMeters;
    const averageContractPrice =
      activeClusterContracts.reduce(
        (acc, contract) => acc + contract.price,
        0
      ) / occupiedMeters;

    const maxDiscountPercentage = Math.max(
      ...clusterBoxes.map((box) => 100 - (box.price * 100) / box.cluster.price),
      0
    );
    const nextDiscountPercentage = Math.max(
      ...clusterBoxes
        .filter((box) => !box.contract)
        .map((box) => 100 - (box.price * 100) / box.cluster.price),
      0
    );

    clustersValues[cluster.name] = {
      name: cluster.name,
      numberOfBoxes: clusterBoxes.length,
      totalMeters,
      occupancy: (occupiedMeters / totalMeters) * 100,
      averageClusterPrice,
      averageDiscountedPrice,
      averageContractPrice,
      maxDiscountPercentage,
      nextDiscountPercentage,
    };
  });

  const CLUSTER_COLUMNS = [
    {
      key: "name",
      label: t("cluster"),
    },
    {
      key: "numberOfBoxes",
      label: t("numberOfBoxes"),
    },
    {
      key: "totalMeters",
      label: t("totalMeters"),
      render: (value) => localeFormat(value) + " m²",
    },
    {
      key: "occupancy",
      label: t("occupancy"),
      render: (value) => localeFormatInteger(value || 0) + " %",
      renderCell: (render, value) => {
        const color =
          value > objectiveOccupancy ? "success.light" : "error.light";
        return (
          <Typography variant="body2" color={color}>
            {render}
          </Typography>
        );
      },
    },
    {
      key: "averageClusterPrice",
      label: t("averagePrice"),
      render: (value) => localeFormat(value || 0) + " €/m²",
    },
    {
      key: "averageDiscountedPrice",
      label: t("averageDiscountedPrice"),
      render: (value) => localeFormat(value || 0) + " €/m²",
    },
    {
      key: "averageContractPrice",
      label: t("averageContractPrice"),
      render: (value) => localeFormat(value || 0) + " €/m²",
    },
    {
      key: "maxDiscountPercentage",
      label: t("maxDiscount"),
      render: (value) => localeFormatInteger(value) + " %",
    },
    {
      key: "nextDiscountPercentage",
      label: t("nextDiscount"),
      render: (value) => localeFormatInteger(value) + " %",
    },
  ];

  return loading ? (
    <Skeleton variant="rectangular" height={200} />
  ) : (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {CLUSTER_COLUMNS.map((column) => (
                <TableCell key={column.key}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(clustersValues).map((cluster, index) => (
              <TableRow hover key={index}>
                {CLUSTER_COLUMNS.map((column) => {
                  const value = clustersValues[cluster][column.key];
                  const render = column.render
                    ? column.render(value, cluster)
                    : value;
                  const renderCell = column.renderCell
                    ? column.renderCell(render, value)
                    : render;
                  return <TableCell key={column.key}>{renderCell}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function RevenuePage() {
  const [t] = useTranslation("revenueManagement");
  const [tErrors] = useTranslation("errors");
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [centerId, setCenterId] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (centerId) {
      getCenterBoxes();
      getCenterAcquisitionAsset();
      getCenterBoxClusters();
      resetModifiedBoxes();
    }
  }, [centerId]);

  /* GET REQUESTS */
  const getCenterAcquisitionAsset = () => {
    if (!centerId) return;

    const params = {
      include: ["AcquisitionAsset"],
    };

    dispatch({ type: "SET_ACQUISITION_ASSET_LOADING", payload: true });

    api
      .get(`/centers/${centerId}`, { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ACQUISITION_ASSET",
            payload: response.data.AcquisitionAsset || null,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_ACQUISITION_ASSET_LOADING", payload: false });
      });
  };

  const getCenterBoxClusters = () => {
    if (!centerId) return;

    api
      .get("/centers/" + centerId + "/box-clusters")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_CLUSTERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenterBoxes = () => {
    if (!centerId) return;
    const params = {
      centers: [centerId],
      include: ["BoxCluster", "Contract"],
    };

    dispatch({ type: "SET_BOXES_LOADING", payload: true });

    api
      .get("/boxes", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.forEach((box) => {
            box.contract = getCurrentBoxContract(box);
            if (box.cluster) {
              if (box.cluster.name === "Almacén") box.cluster.price = box.price;
            }
          });
          dispatch({
            type: "SET_BOXES",
            payload: response.data.sort((a, b) => a.meters - b.meters),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_BOXES_LOADING", payload: false });
      });
  };

  /* POST REQUESTS */
  const createCluster = (clusterData) => {
    if (!clusterData)
      return enqueueSnackbar(t("clusterInfoIsRequired"), {
        variant: "error",
      });

    handleSetCreateClusterLoading(true);
    api
      .post("/box-clusters/create", {
        ...clusterData,
        centerId: centerId,
        fromDate: new Date(),
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          getCenterBoxes();
          dispatch({
            type: "SET_CLUSTERS",
            payload: [...state.clusters, response.data],
          });
        }
      })
      .catch((error) => enqueueSnackbar(error, { variant: "error" }))
      .finally(() => handleSetCreateClusterLoading(false));
  };

  const editCluster = (cluster) => {
    if (!cluster)
      return enqueueSnackbar(t("clusterIsRequired"), { variant: "error" });

    api
      .post(`/box-clusters/edit/${cluster.id}`, cluster)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("clusterEditedSuccessfully"), {
            variant: "success",
          });
          getCenterBoxes();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const submitChangePrices = () => {
    const boxes = getModifiedBoxesArray();

    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

    api
      .post("/boxes/update-prices", { boxes })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("pricesUpdatedSuccessfully"), {
            variant: "success",
          });
          getCenterBoxes();
          dispatch({ type: "SET_CONFIRM_DIALOG_OPEN", payload: false });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_SUBMIT_LOADING", payload: false }));
  };

  /* DELETE REQUESTS */
  const deleteCluster = (clusterId) => {
    if (!clusterId)
      return enqueueSnackbar(t("clusterIsRequired"), { variant: "error" });

    api
      .delete(`/box-clusters/delete/${clusterId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("clusterDeletedSuccessfully"), {
            variant: "success",
          });
          getCenterBoxes();
          dispatch({
            type: "SET_CLUSTERS",
            payload: state.clusters.filter(
              (cluster) => cluster.id !== clusterId
            ),
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  /* UTILS */
  const getCurrentBoxContract = (box) => {
    if (!box || !box.Contracts || !box.Contracts.length) return null;
    for (let contract of box.Contracts) {
      if (!contract.endDate) return contract;
    }
    const today = new Date();
    for (let contract of box.Contracts) {
      if (
        new Date(contract.startDate) <= today &&
        new Date(contract.endDate) >= today
      )
        return contract;
    }
    return null;
  };

  const getModifiedBoxesArray = () => {
    return state.modifiedBoxes
      .filter((modifiedBox, index) => {
        const originalBox = state.boxes[index];
        return modifiedBox.pricePerMeter !== originalBox.pricePerMeter;
      })
      .map((box) => ({
        id: box.id,
        pricePerMeter: box.pricePerMeter,
      }));
  };

  const onChangeBoxes = (boxes) => {
    dispatch({ type: "SET_MODIFIED_BOXES", payload: boxes });
  };

  const resetModifiedBoxes = () => {
    dispatch({ type: "SET_MODIFIED_BOXES", payload: [] });
  };

  const handleChangeResetKey = () => {
    dispatch({ type: "SET_RESET_KEY", payload: Number(state.resetKey) + 1 });
  };

  const handleSave = () => {
    dispatch({ type: "SET_CONFIRM_DIALOG_OPEN", payload: true });
  };

  const handleSetCreateClusterLoading = (loading) => {
    dispatch({ type: "SET_CREATE_CLUSTER_LOADING", payload: loading });
  };

  return (
    <Page browserTitle={t("priceManagement")} title={t("priceManagement")}>
      <Grid container rowSpacing={3}>
        {/* HEAD */}
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} md="auto">
            <CenterSelect
              name="centerId"
              value={centerId}
              onChange={(e) => {
                setCenterId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            {centerId &&
              state.boxes?.length > 0 &&
              state.clusters.length < MAX_CLUSTER_TYPES && (
                <CreateClusterMenu
                  clusters={state.clusters}
                  createCluster={createCluster}
                  createLoading={state.createClusterLoading}
                />
              )}
          </Grid>
        </Grid>
        {/* BODY */}
        <Grid item xs={12}>
          <RevenueSummary
            acquisitionAsset={state.acquisitionAsset}
            boxes={
              state.modifiedBoxes?.length === 0
                ? state.boxes
                : state.modifiedBoxes
            }
            hasClusters={state.clusters?.length > 0}
            loading={state.acquisitionAssetLoading || state.boxesLoading}
          />
        </Grid>
        {state.clusters?.length > 0 && (
          <Grid item xs={12}>
            <ClustersSummaryTable
              acquisitionAsset={state.acquisitionAsset}
              boxes={
                state.modifiedBoxes?.length === 0
                  ? state.boxes
                  : state.modifiedBoxes
              }
              clusters={state.clusters}
              loading={state.boxesLoading}
            />
          </Grid>
        )}
        {state.boxes?.length > 0 && state.clusters?.length > 0 && (
          <Grid item xs={12}>
            {state.clusters?.map((cluster) => (
              <Grid item xs={12} key={cluster.id} marginBottom={2}>
                <BoxClusterAccordion
                  boxes={
                    state.modifiedBoxes?.length === 0
                      ? state.boxes
                      : state.modifiedBoxes
                  }
                  cluster={cluster}
                  clusters={state.clusters}
                  editCluster={editCluster}
                  deleteCluster={deleteCluster}
                  loading={state.boxesLoading}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <BoxClustersTable
            acquisitionAsset={state.acquisitionAsset}
            boxes={state.boxes}
            onChangeBoxes={onChangeBoxes}
            loading={state.boxesLoading}
            resetKey={state.resetKey}
          />
        </Grid>

        {state.boxes?.length > 0 && (
          <Grid item container xs={12} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm="auto">
              <CustomButton
                fullWidth
                onClick={() => {
                  resetModifiedBoxes();
                  handleChangeResetKey();
                }}
              >
                {t("reset")}
              </CustomButton>
            </Grid>
            <Grid item xs={6} sm="auto">
              <CustomButton
                fullWidth
                color="success"
                loading={state.submitLoading}
                disabled={getModifiedBoxesArray().length === 0}
                onClick={handleSave}
              >
                {t("save")}
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ConfirmDialog
        title={t("changePrices")}
        open={state.confirmDialogOpen}
        setOpen={(open) =>
          dispatch({ type: "SET_CONFIRM_DIALOG_OPEN", payload: open })
        }
        onConfirm={(confirm) => {
          if (confirm) submitChangePrices();
        }}
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              {t("attentionExclamation")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              {t("confirmChangePricesMessage", {
                numberOfBoxes: getModifiedBoxesArray().length,
              })}
            </Typography>
          </Grid>
        </Grid>
      </ConfirmDialog>
    </Page>
  );
}

export default RevenuePage;
