import { Link, Menu, MenuItem } from "@mui/material";
import CustomButton from "../../../Inputs/CustomButton";
import { useTranslation } from "react-i18next";
import { getCurrentContract } from "../../Dashboard/Centers/Centers";
import {
  OCCUPIED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
} from "../../../../data/constants";

function BoxManager(props) {
  const [t] = useTranslation("floorPlans");
  const { menuPosition, selectedTile, setMenuPosition, setSelectedTile } =
    props;

  const handleCloseMenu = () => {
    setSelectedTile(null);
    setMenuPosition(null);
  };
  if (!menuPosition || !selectedTile || selectedTile.shape !== "Rect")
    return <></>;

  let currentContract = null;
  if (selectedTile.Boxes[0].state === OCCUPIED_BOX_STATE_ID)
    currentContract = getCurrentContract(selectedTile.Boxes[0].Contracts);

  return (
    <Menu
      open={menuPosition}
      onClose={handleCloseMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition.mouseY !== null && menuPosition.mouseX !== null
          ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
          : undefined
      }
    >
      <LinkMenuItem href={`/app/box/${selectedTile.Boxes[0].id}`} text={t("seeBox")} />

      {selectedTile.Boxes[0].state === OCCUPIED_BOX_STATE_ID &&
        currentContract !== null && (
          <LinkMenuItem
            href={`/app/contract/${currentContract.id}`}
            text={t("seeContract")}
          />
        )}
      {selectedTile.Boxes[0].state === FREE_BOX_STATE_ID && (
        <LinkMenuItem
          href={`/app/contract/create?boxId=${selectedTile.Boxes[0].id}`}
          text={t("createContract")}
        />
      )}
      {selectedTile.Boxes[0].state === OCCUPIED_BOX_STATE_ID && (
        <LinkMenuItem
          href={`/app/customer/${currentContract.customerId}`}
          text={t("seeCustomer")}
        />
      )}
    </Menu>
  );
}

const LinkMenuItem = ({ href, text }) => {
  return (
    <MenuItem component={Link} href={href} target="_blank">
      {text}
    </MenuItem>
  );
};

export default BoxManager;
