import { Button, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

import DownloadIcon from "@mui/icons-material/Download";

// import Button from "../../Inputs/CustomButton"; With this component the ref does not work

/**
 * ExportButton Component
 *
 * This component renders a button that allows the user to export data in either CSV or XLSX format.
 * The user can choose the format by clicking on the button, which opens a dropdown menu.
 *
 * Props:
 * - data (Array<Object>): The dataset to be exported. It should be an array of objects where each
 *   object represents a row in the CSV/XLSX file. Keys of the objects will be used as column headers.
 * - options (Array<Object>) [optional]: Optional formats to export. It should be an array of objects where each
 *   object represents an option in the menu. The CSV and XLSX options are not needed, they're by default.
 * - type (string) [optional]: The display type of the exportButton. Can be "button" or "icon". Defaults to "button".
 * - csvFileName (string) [optional]: The name of the CSV file that will be downloaded. Defaults to "export.csv".
 * - xlsxFileName (string) [optional]: The name of the XLSX file that will be downloaded. Defaults to "export.xlsx".
 *
 * Usage:
 * <ExportButton
 *    data={myData}
 *    type="myType"
 *    options={myOptions}
 *    csvFileName="my-export.csv"
 *    xlsxFileName="my-export.xlsx"
 * />
 *
 * The data prop should be passed as an array of objects, for example:
 * const myData = [
 *   { id: 1, name: "John Doe", age: 25 },
 *   { id: 2, name: "Jane Smith", age: 30 },
 * ];
 *
 * The options prop should be passed as an array of objects and it must follow this structure:
 * const myOptions = [ { name: "XML", onClick: downloadXML }, ];
 *
 * When the user selects "CSV", the data will be exported as a CSV file.
 * When the user selects "XLSX", the data will be exported as an XLSX file.
 */

function ExportButton({
  disable = false,
  data,
  display,
  options,
  type = "button",

  csvFileName = "export.csv",
  xlsxFileName = "export.xlsx",
}) {
  const [t] = useTranslation("others");
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadCsv = () => {
    handleCloseMenu();
    document.getElementById("csv-download").click();
  };

  // XLSX download
  const downloadXlsx = () => {
    handleCloseMenu();
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, xlsxFileName);
  };

  return display === true ? (
    <>
      {/* Display type */}
      {type === "icon" && (
        <Tooltip title={t("export")} placement="top">
          <IconButton onClick={openMenu} disabled={disable}>
            <DownloadIcon color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {type === "button" && (
        <Button
          startIcon={<DownloadIcon />}
          onClick={openMenu}
          disabled={disable}
        >
          {t("export")}
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem dense onClick={downloadCsv}>
          CSV
        </MenuItem>
        <MenuItem dense onClick={downloadXlsx}>
          Excel
        </MenuItem>
        {options &&
          options.map((option) => (
            <MenuItem dense onClick={option.onClick}>
              {option.name}
            </MenuItem>
          ))}
      </Menu>

      {/* Hidden CSVLink component for CSV download */}
      <CSVLink
        data={data}
        filename={csvFileName}
        id="csv-download"
        style={{ display: "none" }}
      />
    </>
  ) : (
    <></>
  );
}

export const exportDataParse = (data, columns) => {
  return data.map((row) =>
    columns.reduce(
      (obj, column) => ({
        ...obj,
        [column.label]: row[column.key],
      }),
      {}
    )
  );
};

export default ExportButton;
