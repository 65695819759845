import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import AppContext from "../../../context/AppContext";
import ConfirmDialog from "../../ConfirmDialog";
import Link from "../../Inputs/Link";
import Page from "../../global/structure/Page";
import TextInput from "../../Inputs/TextInput";

import { CustomTable } from "../../CustomTable";

const initialState = {
  form: {
    Actions: [],
    color: "",
    description: "",
    name: "",
    Pages: [],
  },

  confirmDialogIsOpen: false,
  users: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CONFIRM_DIALOG":
      return { ...state, confirmDialogIsOpen: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ROLE":
      return { ...state, form: action.payload };
    case "SET_USERS":
      return { ...state, users: action.payload.users };
    default:
      throw new Error();
  }
}

export default function RolePage() {
  const { api } = useContext(AppContext);
  const { id } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [tErrors] = useTranslation("errors");
  const [t] = useTranslation("roles");

  const USER_COLUMNS = [
    { label: t("name"), key: "name" },
    {
      label: t("state"),
      key: "active",
      sortType: "number",
      renderFunction: (value) =>
        value ? (
          <Chip label={t("active")} color="success" size="small" />
        ) : (
          <Chip label={t("inactive")} color="error" size="small" />
        ),
    },
    {
      label: "Imagen",
      key: "image",
      renderFunction: (value, item) => (
        <Avatar src={"http://localhost:8080/profileImages/" + item.id} />
      ),
    },
  ];

  //Initial useEffect
  useEffect(() => {
    getRoles();
    getUsers();
  }, []);

  const getRoles = () => {
    let params = { include: ["Action", "Page"] };
    api
      .get("/roles/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({ type: "SET_ROLE", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getUsers = () => {
    api
      .get("/users")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        if (response.data.length === 0) {
          enqueueSnackbar(t("noUsers"), { variant: "error" });
          return;
        }
        let users = response.data.filter(
          (user) => Number(user.roleId) === Number(id)
        );
        dispatch({
          type: "SET_USERS",
          payload: { users: users },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    api
      .post("/roles/edit/" + id, state.form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("roleEditSuccess"), { variant: "success" });
        history.goBack();
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const deleteRole = () => {
    api
      .delete("/roles/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar("Rol deleted", { variant: "success" });
        history.goBack();
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  return (
    <Page browserTitle={t("rolePage")} paper={true}>
      <Grid container spacing={3}>
        <Grid item container xs={12} spacing={1}>
          <Grid item>
            <Typography variant="h4">
              {t("role") + " - " + state.form.name}
            </Typography>
          </Grid>
          <Grid item flex={1} justifyContent="flex-end" display="flex">
            <Tooltip title={t("createUser")} placement="top">
              <IconButton
                color="primary"
                component={Link}
                to={"/app/user/create?roleId=" + id}
              >
                <SupervisedUserCircleIcon />
              </IconButton>
            </Tooltip>
            {state.users.length === 0 && (
              <Tooltip title={t("delete")} placement="top">
                <IconButton
                  variant="contained"
                  onClick={(e) => {
                    setConfirmDialogState(true);
                  }}
                >
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextInput
              name="name"
              label={t("name")}
              value={state.form.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined">
              <input
                type="color"
                id="color"
                name="color"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                value={state.form.color || "#000000"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <TextInput
              multiline
              rows={4}
              name="description"
              label={t("description")}
              value={state.form.description}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <Typography variant="h6">{t("users")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={USER_COLUMNS}
              data={state.users}
              options={{ rowlink: "user" }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("pages")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List style={{ maxHeight: 500, overflow: "auto" }}>
                      {state.form.Pages.map((page) => (
                        <ListItem key={page.id}>{page.name}</ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("actions")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List style={{ maxHeight: 500, overflow: "auto" }}>
                      {state.form.Actions.map((action) => (
                        <ListItem key={action.id}>{action.id}</ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button onClick={() => history.goBack()}>{t("back")}</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={submitForm}>
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        title={t("deleteRoleQuestion")}
        open={state.confirmDialogIsOpen}
        setOpen={setConfirmDialogState}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={(confirmed) => confirmed && deleteRole()}
      >
        <Typography variant="body2" color="initial">
          {t("role") + ": " + state.form.name}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}
