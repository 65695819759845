import { Bar, Pie } from "react-chartjs-2";
import { ChartContainer } from "./ChartContainer";
import { Switch } from "@mui/material";
import { ColorGenerator, colors } from "../Components/ColorGenerator";
import CustomSelect from "../../../Inputs/CustomSelect";
import { CustomTable } from "../../../CustomTable";
import {
  count,
  distinctValues,
  formatDate,
  hours,
  percentage,
  weekDays,
} from "../../../../utils/chartUtils";
import { useState } from "react";

export const CallsByDepartment = ({ calls = [] }) => {
  const [isCount, setIsCount] = useState(true);
  const rawData = calls.map((call) =>
    call.CallDepartment ? call.CallDepartment.name : "None"
  );
  const labels = distinctValues(rawData);
  const data = count(rawData, labels);
  const dataPercentage = percentage(rawData, labels);

  const options = {
    count: { data: data },
    percentage: { data: dataPercentage },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: options[isCount ? "count" : "percentage"].data,
        backgroundColor: ColorGenerator(
          colors.primary,
          colors.secondary,
          labels.length
        ),
      },
    ],
  };

  const columns = [
    { key: "name", label: "name", sortType: "string" },
    { key: "count", label: "count", sortType: "number" },
  ];

  const colData = labels.map((label, i) => {
    return { name: label, count: data[i] };
  });

  return (
    <ChartContainer
      title="Llamadas por departamento"
      chart={<Pie data={chartData} />}
      table={<CustomTable columns={columns} data={colData} />}
      csv={colData}
    >
      Percentage
      <Switch
        checked={isCount}
        onChange={(e) => {
          setIsCount(e.target.checked);
        }}
      />
      Count
    </ChartContainer>
  );
};

export const CallsByDate = ({ calls = [] }) => {
  const options = {
    weekDay: {
      rawDataCallBack: (call) => weekDays[new Date(call.createdAt).getDay()],
      labels: weekDays,
    },
    hour: {
      rawDataCallBack: (call) => new Date(call.createdAt).getUTCHours(),
      labels: hours,
    },
  };
  const [groupBy, setGroupBy] = useState("weekDay");
  const rawData = calls.map(options[groupBy].rawDataCallBack);
  const labels = options[groupBy].labels;
  const data = count(rawData, labels);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Llamadas",
        data: data,
        backgroundColor: ColorGenerator(
          colors.primary,
          colors.secondary,
          options[groupBy].labels.length
        ),
      },
    ],
  };

  const columns = [
    { key: "name", label: "name", sortType: "string" },
    { key: "count", label: "count", sortType: "number" },
  ];

  const colData = labels.map((label, i) => {
    return { name: label, count: data[i] };
  });

  return (
    <ChartContainer
      title="Llamadas según fecha"
      chart={<Bar data={chartData} />}
      table={<CustomTable columns={columns} data={colData} />}
      csv={colData}
    >
      <CustomSelect
        value={groupBy}
        label="Group by"
        onChange={(e) => {
          setGroupBy(e.target.value);
        }}
        options={[
          { value: "weekDay", label: "Week day" },
          { value: "hour", label: "Hour" },
        ]}
      />
    </ChartContainer>
  );
};

export const CallsByCreatedBy = ({ calls = [] }) => {
  const rawData = calls.map((call) => call.User.name);
  const labels = distinctValues(rawData);
  const data = count(rawData, labels);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Llamadas",
        data: data,
        backgroundColor: ColorGenerator(
          colors.primary,
          colors.secondary,
          labels.length
        ),
      },
    ],
  };

  const columns = [
    { key: "name", label: "name", sortType: "string" },
    { key: "count", label: "count", sortType: "number" },
  ];

  const colData = labels.map((label, i) => {
    return { name: label, count: data[i] };
  });

  return (
    <ChartContainer
      title="Llamadas segun creador"
      chart={<Bar data={chartData} />}
      table={<CustomTable columns={columns} data={colData} />}
      csv={colData}
    />
  );
};

export const CallsByCenter = ({ calls = [] }) => {
  const rawData = calls.map((call) =>
    call.Center ? call.Center.name : "none"
  );
  const labels = distinctValues(rawData);
  const data = count(rawData, labels);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Llamadas",
        data: data,
        backgroundColor: ColorGenerator(
          colors.primary,
          colors.secondary,
          labels.length
        ),
      },
    ],
  };
  const columns = [
    { key: "name", label: "name", sortType: "string" },
    { key: "count", label: "count", sortType: "number" },
  ];

  const colData = labels.map((label, i) => {
    return { name: label, count: data[i] };
  });

  return (
    <ChartContainer
      title="Llamadas segun delegación"
      chart={<Bar data={chartData} />}
      table={<CustomTable columns={columns} data={colData} />}
      csv={colData}
    />
  );
};

export const CallsByCallback = ({ calls = [] }) => {
  const getMinDate = (calls) => {
    let min = calls[0]?.createdAt;
    calls.forEach((call) => {
      if (call.createdAt < min) min = call.createdAt;
    });
    return new Date(min);
  };
  const getMaxDate = (calls) => {
    let max = calls[0]?.createdAt;
    calls.forEach((call) => {
      if (call.createdAt > max) max = call.createdAt;
    });
    return new Date(max);
  };

  const getDates = (start, end, period) => {
    // get dates between two dates including those with no calls
    let startDate = new Date(start);
    let endDate = new Date(end);

    if (period === "month") startDate.setDate(endDate.getDate());
    let dates = [formatDate(startDate)];

    while (
      (period !== "year" && startDate < endDate) ||
      (period === "year" && startDate.getFullYear() < endDate.getFullYear())
    ) {
      switch (period) {
        case "day":
          startDate.setDate(startDate.getDate() + 1);
          break;
        case "month":
          startDate.setMonth(startDate.getMonth() + 1);
          break;
        case "year":
          startDate.setFullYear(startDate.getFullYear() + 1);
          break;
        default:
      }
      dates.push(formatDate(startDate));
    }
    return dates;
  };

  const [groupBy, setGroupBy] = useState("month");
  const rawData = [];
  const labels = getDates(getMinDate(calls), getMaxDate(calls), groupBy);

  labels.forEach((label) => {
    let arr = calls.filter((call) => {
      let filter = true;
      let createdAtDate = new Date(call.createdAt);
      let labelDate = new Date(label);

      if (createdAtDate.getFullYear() !== labelDate.getFullYear())
        filter = false;
      if (
        groupBy === "month" &&
        createdAtDate.getMonth() !== labelDate.getMonth()
      )
        filter = false;
      if (groupBy === "day" && createdAtDate.getDate() !== labelDate.getDate())
        filter = false;

      return filter;
    });

    rawData.push(arr);
  });
  const data = rawData.map((item) => item.length);
  const dataCallBacks = rawData.map(
    (item) => item.filter((call) => call.wantCallBack === true).length
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Llamadas",
        data: data,
        backgroundColor: colors.primary,
      },
      {
        label: "Llamadas con seguimiento",
        data: dataCallBacks,
        backgroundColor: colors.secondary,
      },
    ],
  };

  return (
    <ChartContainer
      title="Llamadas segun seguimiento"
      chart={<Bar data={chartData} />}
    >
      <CustomSelect
        label={"Group by"}
        value={groupBy}
        onChange={(e) => {
          setGroupBy(e.target.value);
        }}
        options={[
          { value: "day", label: "Day" },
          { value: "month", label: "Month" },
          { value: "quarter", label: "Quarter" },
          { value: "year", label: "Year" },
        ]}
      />
    </ChartContainer>
  );
};
