import { Box, Grid, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getParams } from "../../../../utils/url";
import { localeFormat } from "../../../../utils/format";
import AppContext from "../../../../context/AppContext";
import CreateNonPaymentForm from "../../NonPayments/CreateNonPaymentForm";
import InvoiceStackList from "../Components/InvoiceStackList";

import CreditCardIcon from "@mui/icons-material/CreditCard";

import Filters from "../../../global/structure/Filters";

import CustomDate from "../../../Inputs/CustomDate";
import CustomerInput from "../../../Inputs/CustomerInput";
import InvoiceStateSelect from "../../../Inputs/InvoiceStateSelect";
import PaymentDaySelect from "../../../Inputs/PaymentDaySelect";
import SearchButton from "../../../Inputs/SearchButton";
import { today } from "../../../../utils/date";

import ExportButton, {
  exportDataParse,
} from "../../../global/inputs/ExportButton";

import {
  PAID_INVOICE_STATE_ID,
  UNPAID_INVOICE_STATE_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
} from "../../../../data/constants";
import { useSnackbar } from "notistack";
import CustomButton from "../../../Inputs/CustomButton";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialFilters = {
  autoSearch: "false",
  loading: false,
  customer: null,
  dateFrom: "",
  dateUntil: "",
  state: [],
  paymentDay: "",
};

const initialPaymentDialog = {
  isOpen: false,
  createLoading: false,
};

const SUCCESS_RESPONSE_CODE = "0000";

const processingIds = [];
const correctPaymentIds = [];
const incorrectPaymentIds = [];

const emptyArrays = () => {
  processingIds.splice(0, processingIds.length);
  correctPaymentIds.splice(0, correctPaymentIds.length);
  incorrectPaymentIds.splice(0, incorrectPaymentIds.length);
};

function TokenizedPayCardsTab({ functions }) {
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  const [t] = useTranslation("invoices");
  const { api } = useContext(AppContext);

  const [processing, setProcessing] = useState([]);
  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [invoices, setInvoices] = useState([]);
  const [nonPaymentDialog, setNonPaymentDialog] =
    useState(initialPaymentDialog);

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "customerId", type: "number" },
    { name: "customerName", type: "string" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "paymentDay", type: "number" },
    { name: "state", type: "array" },
  ];

  const CSV_HEADERS = [
    { key: "number", label: t("number") },
    { key: "customerName", label: t("customerName") },
    { key: "payCard", label: t("payCard") },
    { key: "issueDate", label: t("issueDate") },
    { key: "totalAmount", label: t("import") },
    { key: "stateText", label: t("state") },
  ];

  useEffect(() => {
    const queryParams = getParams(query, FILTERS);
    const customer = {
      id: queryParams.customerId,
      name: queryParams.customerName,
    };

    setFilters({
      ...initialFilters,
      ...queryParams,
      ...(customer.id && { customer }),
    });
  }, []);

  useEffect(() => {
    if (filters.autoSearch === "true") getInvoices();
  }, [filters.autoSearch]);

  useEffect(emptyArrays, [filters.loading]);

  const search = () => {
    if (filters.autoSearch === "true") {
      getInvoices();
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        autoSearch: "true",
      }));
    }
  };

  const getInvoices = () => {
    setInvoices([]);
    setSelectedInvoicesIds([]);
    setProcessing([]);
    setNonPaymentDialog(initialPaymentDialog);
    functions.getInvoices(
      {
        ...filters,
        customerId: filters.customer?.id || "",
        customerName: filters.customer?.name || "",
        paymentMethodId: TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
      },
      (value) => {
        if (value) setFilters({ ...filters, loading: true });
        else setFilters({ ...filters, loading: false });
      },
      (data) => {
        data.forEach((item) => {
          item.payCard = getPayCard(item);
          item.stateText = functions.getStateText(item.state);
        });
        setInvoices(data);
      }
    );
  };

  const getPayCard = (item) => {
    //Search invoice paycard:
    const payCard = item?.Customer?.PayCards?.find(
      (card) => card.id === item.paycardId
    );
    return payCard ? payCard.mask : "-";
  };

  const createNonPayment = (form, setLoading, showSnackBar = true) => {
    let data = {
      nonPayment: {
        nonPaymentDate: form.date,
        reason: form.reason,
        comments: form.comment,
        invoiceId: form.invoiceId,
      },
    };

    api
      .post("/non-payments/create", data)
      .then((response) => {
        if (response.data.error) {
          if (showSnackBar)
            enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (showSnackBar)
            enqueueSnackbar(t("nonPaymentCreatedSuccessfully"), {
              variant: "success",
            });
          const invoice = invoices.find(
            (invoice) => invoice.id === form.invoiceId
          );
          invoice.state = UNPAID_INVOICE_STATE_ID;
          invoice.NonPayments.push(response.data);
          setInvoices([...invoices]);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  const payInvoice = (invoiceId) => {
    let invoiceIndex = invoices.findIndex(
      (invoice) => invoice.id === invoiceId
    );
    if (invoiceIndex === -1) return;

    invoices[invoiceIndex] = {
      ...invoices[invoiceIndex],
      state: PAID_INVOICE_STATE_ID,
    };

    setInvoices([...invoices]);
  };

  const updateInvoices = () => {
    setInvoices([
      ...invoices.map((i) => {
        if (correctPaymentIds.includes(i.id)) {
          i.state = PAID_INVOICE_STATE_ID;
          i.paidAt = new Date();
        } else if (incorrectPaymentIds.includes(i.id)) {
          i.state = UNPAID_INVOICE_STATE_ID;
        }
        return i;
      }),
    ]);

    const selectedInvoices = invoices.filter((invoice) =>
      selectedInvoicesIds.includes(invoice.id)
    );
    setSelectedInvoicesIds(
      selectedInvoices
        .filter(
          (invoice) =>
            !correctPaymentIds.includes(invoice.id) &&
            !incorrectPaymentIds.includes(invoice.id) &&
            !processingIds.includes(invoice.id)
        )
        .map((invoice) => invoice.id)
    );
  };

  const tryPayment = async (invoiceId) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post("/payment-orders/create", {
          invoiceIds: [invoiceId],
        })
        .then((response) => {
          if (response.data.error) reject(response.data.error);
          else {
            const paymentOrder = response.data;

            api
              .post(`/payment-orders/${paymentOrder.id}/resolve`)
              .then((response) => {
                if (response.data.error) reject(response.data.error);
                else resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const tryPaymentHandler = async (invoiceId, showSnackBar = true) => {
    try {
      let err = false;
      paymentProcessHandler(invoiceId, true);
      await tryPayment(invoiceId)
        .then((response) => {
          if (response.responseCode !== SUCCESS_RESPONSE_CODE) {
            err = true;
            createNonPayment(
              {
                reason: 6,
                comment:
                  "Error al cobrar tarjeta tokenizada:" +
                  response.responseCode?.toString(),
                date: today(),
                invoiceId: invoiceId,
              },
              () => {},
              false
            );
            if (showSnackBar)
              enqueueSnackbar(t("Payment error: " + response.responseCode), {
                variant: "error",
              });
          } else {
            if (showSnackBar)
              enqueueSnackbar(t("success"), { variant: "success" });
          }
        })
        .catch((error) => {
          err = true;
          console.log("error", error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          paymentProcessHandler(invoiceId, false, err);
          updateInvoices();
        });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(error.toString(), { variant: "error" });
    }
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const paymentProcessHandler = (invoiceId, add, error) => {
    if (add) {
      processingIds.push(invoiceId);
    } else {
      processingIds.splice(processingIds.indexOf(invoiceId), 1);
      if (error) {
        incorrectPaymentIds.push(invoiceId);
      } else {
        correctPaymentIds.push(invoiceId);
      }
    }
    setProcessing([...processingIds]);
  };

  const handleChargeAll = async () => {
    const invoiceIds = selectedInvoicesIds;
    let currentIndex = 0;
    let results = [];
    let errors = [];

    const processInvoice = async (invoiceId) => {
      processingIds.push(invoiceId);
      try {
        const result = await tryPaymentHandler(invoiceId, false);
        results.push({ invoiceId, result });
        correctPaymentIds.push(invoiceId);
      } catch (error) {
        errors.push({ invoiceId, error });
        incorrectPaymentIds.push(invoiceId);
      } finally {
        processingIds.splice(processingIds.indexOf(invoiceId), 1);
        setProcessing([...processingIds]);
      }
    };

    while (currentIndex < invoiceIds.length || processingIds.length > 0) {
      if (processingIds.length < 6 && currentIndex < invoiceIds.length) {
        processInvoice(invoiceIds[currentIndex]);
        currentIndex++;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 400));
      }
    }

    enqueueSnackbar(t("chargeAllCompleted"), { variant: "info" });
    return { results, errors };
  };

  return (
    <Grid container spacing={2} marginTop={1}>
      {/*Tab filters*/}
      <Grid item>
        <Filters
          filters={[
            <CustomerInput
              name={"customer"}
              label={t("customer")}
              value={filters.customer}
              onChange={handleFilterChange}
              disabled={filters.loading}
            />,
            <CustomDate
              name={"dateFrom"}
              label={t("dateFrom")}
              value={filters.dateFrom}
              onChange={handleFilterChange}
              disabled={filters.loading}
            />,
            <CustomDate
              name={"dateUntil"}
              label={t("dateUntil")}
              value={filters.dateUntil}
              onChange={handleFilterChange}
              disabled={filters.loading}
            />,
            <PaymentDaySelect
              name={"paymentDay"}
              label={t("paymentDay")}
              value={filters.paymentDay}
              onChange={handleFilterChange}
              disabled={filters.loading}
            />,
            <InvoiceStateSelect
              name={"state"}
              label={t("state")}
              value={filters.state}
              onChange={handleFilterChange}
              multiple
              disabled={filters.loading}
            />,
            <ExportButton
              disable={invoices.length < 1 || filters.loading}
              display={true}
              data={exportDataParse(invoices, CSV_HEADERS)}
            />,
            <SearchButton onClick={search} loading={filters.loading} />,
            <CustomButton
              startIcon={<CreditCardIcon />}
              disabled={selectedInvoicesIds.length === 0 || filters.loading}
              onClick={handleChargeAll}
            >
              {t("chargeAll")}
            </CustomButton>,
          ]}
        />
      </Grid>

      {/*Summary elements*/}
      <Grid
        item
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <SummaryElement
            title={t("totalInvoices")}
            value={filters.loading ? 0 : invoices.length}
          />
        </Grid>
        <Grid item>
          <SummaryElement
            title={t("totalAmount")}
            value={
              filters.loading
                ? 0
                : localeFormat(
                    invoices.length > 0
                      ? invoices.reduce(
                          (total, element) => total + element.totalAmount,
                          0
                        )
                      : 0
                  ) + " €"
            }
          />
        </Grid>
      </Grid>

      {/*Invoices List*/}
      <Grid item container xs={12}>
        <InvoiceStackList
          selectedInvoicesIds={selectedInvoicesIds}
          setSelectedInvoicesIds={setSelectedInvoicesIds}
          invoices={invoices}
          functions={{
            createNonPayment,
            payInvoice,
            tryPayment,
            tryPaymentHandler,
            search,
            setBeingProcessed: paymentProcessHandler,
            updateInvoices,
          }}
          loading={filters.loading}
          beingProcessedIds={processing}
        />
      </Grid>

      {/*Create Non Payment dialog */}
      <CreateNonPaymentForm
        open={nonPaymentDialog.isOpen}
        onClose={() =>
          setNonPaymentDialog({ ...nonPaymentDialog, isOpen: false })
        }
        // onSubmit={createNonPayment}
        invoicePaymentMethodId={PAYCARD_PAYMENT_METHOD_ID}
        loading={nonPaymentDialog.createLoading}
      />
    </Grid>
  );
}

const SummaryElement = ({ title, value }) => {
  return (
    <Box
      component={Paper}
      elevation={3}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "white",
        boxShadow: 3,
        position: "relative",
        padding: 2,
        height: "50px",
      }}
    >
      <Typography
        component="div"
        sx={{ display: "inline-flex", alignItems: "center" }}
      >
        <Typography component="span" sx={{ display: "inline", marginRight: 1 }}>
          {title}:
        </Typography>
        <Typography
          component="span"
          fontWeight="bold"
          sx={{ display: "inline" }}
        >
          {value}
        </Typography>
      </Typography>
    </Box>
  );
};

export default TokenizedPayCardsTab;
