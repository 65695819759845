import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import CenterReportContext from "../CenterReportContext";
import MonthInput from "../../../../Inputs/CustomMonth";
import Filters from "../../../../global/structure/Filters";

import { daysOfMonth } from "../../../../../utils/date";

import {
  HistoricalContractVariation,
  ExtendedHistoricalInvoicing,
  HistoricalOccupancy,
  HistoricalPricePerMeter,
} from "../../Charts/CenterReport";

const initialFilters = {
  dateFrom: "",
  dateUntil: "",
};

function PerformanceTab() {
  const { boxes, contracts, acquisitionAsset, invoiceItems } =
    useContext(CenterReportContext);

  const [t] = useTranslation("dashboard");
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} marginTop={1} marginBottom={1}>
        <Filters
          filters={[
            <MonthInput
              label={t("from")}
              value={filters.dateFrom}
              onChange={handleFilterChange}
              name="dateFrom"
            />,
            <MonthInput
              label={t("until")}
              value={filters.dateUntil}
              onChange={handleFilterChange}
              name="dateUntil"
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoricalContractVariation
          contracts={contracts}
          startDate={filters.dateFrom}
          endDate={filters.dateUntil}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoricalOccupancy
          contracts={contracts}
          startDate={filters.dateFrom}
          endDate={filters.dateUntil}
          boxes={boxes}
          objective={acquisitionAsset?.occupancy || null}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoricalPricePerMeter
          contracts={contracts}
          startDate={filters.dateFrom}
          endDate={filters.dateUntil}
          objective={acquisitionAsset?.sqmPriceMonth || null}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ExtendedHistoricalInvoicing
          contracts={contracts}
          startDate={filters.dateFrom}
          endDate={filters.dateUntil}
          objective={
            acquisitionAsset?.sqmPriceMonth *
              (acquisitionAsset?.currentArea +
                acquisitionAsset?.aditionalConstructability) *
              (acquisitionAsset?.lettableAreaRatio / 100) *
              (acquisitionAsset?.occupancy / 100) || null
          }
          invoiceItems={invoiceItems}
        />
      </Grid>
    </Grid>
  );
}

export default PerformanceTab;
