import { Grid, Card } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import CenterReportContext from "../CenterReportContext";

import { CustomTable } from "../../../../CustomTable";
import { localeFormat } from "../../../../../utils/format";
import ButtonCSV from "../../../../Inputs/ButtonCSV";
import ButtonLink from "../../../../Inputs/ButtonLink";
import Tabs from "../../../../global/structure/Tabs";
import Filters from "../../../../global/structure/Filters";
import DateInput from "../../../../Inputs/CustomDate";

import {
  HistoricalBillingError,
  HistoricalNonPayments,
  HistoricalCenterReceipts,
} from "../../Charts/CenterReport";
import { HistoricalInvoicingNonPayments } from "../../Charts/NonPaymentsChart";

import InformationBox from "../../Components/InformationBox";
import { removeTime } from "../../../../../utils/date";

function NonPaymentsTab() {
  const [t] = useTranslation("dashboard");
  const [dateFrom, setDateFrom] = useState("");
  const [dateUntil, setDateUntil] = useState("");
  const { invoiceItems, nonPayments, recuperations, receipts } =
    useContext(CenterReportContext);

  const dateFromDate = dateFrom === "" ? null : new Date(dateFrom);
  const dateUntilDate = dateUntil === "" ? null : new Date(dateUntil);

  const filteredInvoiceItems = invoiceItems.filter((item) => {
    const issueDate = new Date(item.Invoice.issueDate);
    const fromDate = dateFromDate ? issueDate >= dateFromDate : true;
    const untilDate = dateUntilDate ? issueDate <= dateUntilDate : true;
    return fromDate && untilDate;
  });

  const filteredNonPayments = nonPayments.filter((item) => {
    const nonPaymentDate = new Date(item.nonPaymentDate);
    const fromDate = dateFromDate ? nonPaymentDate >= dateFromDate : true;
    const untilDate = dateUntilDate ? nonPaymentDate <= dateUntilDate : true;
    return fromDate && untilDate;
  });

  const filteredRecuperations = recuperations.filter((item) => {
    const recuperationDate = new Date(item.recuperationDate);
    const fromDate = dateFromDate ? recuperationDate >= dateFromDate : true;
    const untilDate = dateUntilDate ? recuperationDate <= dateUntilDate : true;
    return fromDate && untilDate;
  });

  const filteredReceipts = receipts.filter((item) => {
    const receiptDate = new Date(item.dueDate);
    const fromDate = dateFromDate ? receiptDate >= dateFromDate : true;
    const untilDate = dateUntilDate ? receiptDate <= dateUntilDate : true;
    return fromDate && untilDate;
  });

  const parsedInvoiceItems = filteredInvoiceItems.map((item) => ({
    ...item,
    invoiceNumber: item.Invoice.number,
    concept: item.concept,
    amount: Number((item.units * item.pricePerUnit).toFixed(2)),
    date: item.Invoice.issueDate,
    serie: item.Invoice.InvoiceSerie.name,
    paymentMethod: item.Invoice.PaymentMethod?.name,
    billingError: item.Invoice.billingError ? t("yes") : t("no"),
    center: item.Merchantable.Center.name,
    merchantableType:
      item.Merchantable && item.Merchantable.MerchantableType
        ? item.Merchantable.MerchantableType.name
        : t("notAssigned"),
  }));

  /**
   * Items que han sido impagados y que ahora están recuperados
   */
  const recuperatedItems = parsedInvoiceItems.filter(
    (item) =>
      item.Invoice.NonPayments.length > 0 &&
      item.Invoice.NonPayments.every(
        (nonPayment) => nonPayment.recuperationDate !== null
      )
  );

  /**
   * Items que actualmente están impagados
   */
  const nonPaidItems = parsedInvoiceItems.filter(
    (item) =>
      item.Invoice.NonPayments.length > 0 &&
      item.Invoice.NonPayments.some(
        (nonPayment) => nonPayment.recuperationDate === null
      )
  );

  const neverUnpaidItems = parsedInvoiceItems.filter(
    (item) => item.Invoice.NonPayments.length === 0
  );

  const parserdNonPayments = [
    ...filteredNonPayments,
    ...filteredRecuperations,
  ].map((item) => ({
    ...item,
    invoiceNumber: item.invoiceNumber,
    concept: item.concept,
    amount: item.amount,
    serie: item.invoiceSerie,
    nonPaymentDate: item.nonPaymentDate,
    recuperationDate: item.recuperationDate,
  }));

  const totalInvoicing = parsedInvoiceItems.reduce(
    (acc, item) => acc + item.amount,
    0
  );
  const totalCurrentNonPayments = nonPaidItems.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const totalAmount = parsedInvoiceItems.reduce(
    (acc, item) => acc + Math.abs(item.amount),
    0
  );

  const totalRecuperationAmount = filteredRecuperations.reduce(
    (acc, item) => acc + Math.abs(item.amount),
    0
  );

  const totalNonPaymentsAmount = filteredNonPayments.reduce(
    (acc, item) => acc + Math.abs(item.amount),
    0
  );

  const totalBillingErrorAmount = parsedInvoiceItems
    .filter((item) => item.Invoice.billingError)
    .reduce((acc, item) => acc + Math.abs(item.amount), 0);

  const INVOICE_TABLE_COLUMNS = [
    {
      key: "invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "number",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/invoice/" + item.Invoice.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "concept",
      label: t("concept"),
      sortType: "string",
    },
    {
      key: "amount",
      label: t("amount"),
      renderFunction: (value) =>
        localeFormat(Math.abs(value)) +
        " €" +
        ` (${localeFormat((Math.abs(value) / totalAmount) * 100)}%)`,
      sortType: "number",
    },
    {
      key: "date",
      label: t("date"),
      sortType: "string",
    },
    {
      key: "serie",
      label: t("serie"),
      sortType: "string",
    },
    {
      key: "paymentMethod",
      label: t("paymentMethod"),
      sortType: "string",
    },
    {
      key: "billingError",
      label: t("billingError"),
      sortType: "string",
    },
    {
      key: "merchantableType",
      label: t("merchantableType"),
      sortType: "string",
    },
  ];

  const NON_PAYMENTS_TABLE_COLUMNS = [
    {
      key: "invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "number",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/invoice/" + item.invoiceId} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "concept",
      label: t("concept"),
      sortType: "string",
    },
    {
      key: "amount",
      label: t("amount"),
      renderFunction: (value) => localeFormat(value) + " €",
      sortType: "number",
    },
    {
      key: "serie",
      label: t("serie"),
      sortType: "string",
    },
    {
      key: "nonPaymentDate",
      label: t("nonPaymentDate"),
      sortType: "string",
    },
    {
      key: "recuperationDate",
      label: t("recuperationDate"),
      sortType: "string",
      renderFunction: (value) => (value ? removeTime(value) : ""),
    },
  ];

  const INVOICE_CSV_HEADERS = [
    {
      label: t("invoiceNumber"),
      key: "invoiceNumber",
    },
    {
      label: t("concept"),
      key: "concept",
    },
    {
      label: t("amount"),
      key: "amount",
    },
    {
      label: t("date"),
      key: "date",
    },
    {
      label: t("serie"),
      key: "serie",
    },
    {
      label: t("paymentMethod"),
      key: "paymentMethod",
    },
    {
      label: t("billingError"),
      key: "billingError",
    },
    {
      label: t("merchantableType"),
      key: "merchantableType",
    },
  ];

  const NON_PAYMENTS_CSV_HEADERS = [
    {
      label: t("invoiceNumber"),
      key: "invoiceNumber",
    },
    {
      label: t("concept"),
      key: "concept",
    },
    {
      label: t("amount"),
      key: "amount",
    },
    {
      label: t("serie"),
      key: "serie",
    },
    {
      label: t("nonPaymentDate"),
      key: "nonPaymentDate",
    },
    {
      label: t("recuperationDate"),
      key: "recuperationDate",
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <DateInput
              label={t("from")}
              value={dateFrom}
              onChange={(e) => {
                setDateFrom(e.target.value);
              }}
            />,
            <DateInput
              label={t("until")}
              value={dateUntil}
              onChange={(e) => {
                setDateUntil(e.target.value);
              }}
            />,
          ]}
        />
      </Grid>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={2}
      >
        <Grid item>
          <InformationBox
            mainValue={localeFormat(totalInvoicing) + "€"}
            title={t("invoicing")}
            size={225}
            borderRadius={1}
          />
        </Grid>

        <Grid item>
          <InformationBox
            mainValue={localeFormat(totalNonPaymentsAmount) + "€"}
            title={t("nonPayments")}
            size={225}
            borderRadius={1}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={localeFormat(totalRecuperationAmount) + "€"}
            title={t("recuperations")}
            size={225}
            borderRadius={1}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={localeFormat(totalCurrentNonPayments) + "€"}
            title={t("pending")}
            secondaryValue={
              totalInvoicing
                ? localeFormat(
                    (totalCurrentNonPayments / totalInvoicing) * 100
                  ) + "%"
                : "0%"
            }
            size={225}
            borderRadius={1}
          />
        </Grid>
        <Grid item>
          <InformationBox
            mainValue={localeFormat(totalBillingErrorAmount) + "€"}
            title={t("billingError")}
            secondaryValue={
              totalInvoicing
                ? localeFormat(
                    (totalBillingErrorAmount / totalInvoicing) * 100
                  ) + "%"
                : "0%"
            }
            size={225}
            borderRadius={1}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <HistoricalBillingError invoiceItems={filteredInvoiceItems} />
      </Grid>
      <Grid item xs={6}>
        <HistoricalInvoicingNonPayments
          recuperatedItems={recuperatedItems}
          neverUnpaidItems={neverUnpaidItems}
          nonPaidItems={nonPaidItems}
        />
      </Grid>
      <Grid item xs={12}>
        <HistoricalNonPayments
          nonPayments={filteredNonPayments}
          recuperations={filteredRecuperations}
        />
      </Grid>
      <Grid item xs={12}>
        <HistoricalCenterReceipts receipts={filteredReceipts} />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Card elevation={3}>
          <Grid item xs={12} margin={1}>
            <Tabs
              tabs={[
                {
                  label: t("invoicing"),
                  content: (
                    <Grid container spacing={1}>
                      <Grid item container justifyContent="flex-end">
                        <ButtonCSV
                          data={parsedInvoiceItems}
                          headers={INVOICE_CSV_HEADERS}
                          filename={t("invoiceItems")}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={1}>
                        <CustomTable
                          columns={INVOICE_TABLE_COLUMNS}
                          data={parsedInvoiceItems}
                        />
                      </Grid>
                    </Grid>
                  ),
                },
                {
                  label: t("nonPayments"),
                  content: (
                    <Grid container spacing={1}>
                      <Grid item container justifyContent="flex-end">
                        <ButtonCSV
                          data={parserdNonPayments}
                          headers={NON_PAYMENTS_CSV_HEADERS}
                          filename={t("nonPayments")}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={1}>
                        <CustomTable
                          columns={NON_PAYMENTS_TABLE_COLUMNS}
                          data={parserdNonPayments}
                        />
                      </Grid>
                    </Grid>
                  ),
                },
              ]}
            />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NonPaymentsTab;
