import {
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  Chip,
  IconButton,
} from "@mui/material";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import { useEffect } from "react";
import { useReducer } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SyncIcon from "@mui/icons-material/Sync";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LabeledText from "../../global/LabeledText";
import BlockIcon from "@mui/icons-material/Block";
import MailIcon from "@mui/icons-material/Mail";
import CustomButton from "../../Inputs/CustomButton";
import TextInput from "../../Inputs/TextInput";
import Select from "../../global/inputs/Select";
import { ACCESS_CONTROL_USER_TYPES } from "../../../data/constants";
import Dialog from "../../global/Dialog";

const initialState = {
  areas: [],
  accessUser: null,
  accessName: "",
  accessType: ACCESS_CONTROL_USER_TYPES.CUSTOMER,
  syncStatus: null,
  isAccessUserLoading: false,
  isNewCodeLoading: false,
  isChangePermissionLoading: false,
  isCreateUserLoading: false,
  isSendEmailLoading: false,
  isChangeDisableLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_AREAS":
      return { ...state, areas: action.payload };
    case "SET_ACCESS_USER":
      return { ...state, accessUser: action.payload };
    case "SET_SYNC_STATUS":
      return { ...state, syncStatus: action.payload };
    case "SET_ACCESS_NAME":
      return { ...state, accessName: action.payload };
    case "SET_ACCESS_TYPE":
      return { ...state, accessType: action.payload };
    case "SET_NEW_CODE_LOADING":
      return { ...state, isNewCodeLoading: action.payload };
    case "SET_CHANGE_PERMISSION_LOADING":
      return { ...state, isChangePermissionLoading: action.payload };
    case "SET_CREATE_USER_LOADING":
      return { ...state, isCreateUserLoading: action.payload };
    case "SET_SEND_EMAIL_LOADING":
      return { ...state, isSendEmailLoading: action.payload };
    case "SET_GET_ACCESS_USER_LOADING":
      return { ...state, isAccessUserLoading: action.payload };
    case "SET_CHANGE_DISABLE_LOADING":
      return { ...state, isChangeDisableLoading: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const AccessControlDialog = (props) => {
  const {
    open = false,
    onClose,
    accessControlUserId,
    customerId,
    accessControlUserName,
    accessType,
    onCreateAccessControlUser = () => {},
  } = props;
  const { api, user } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("accessControl");
  const [tErrors] = useTranslation("errors");

  // Initial setup
  useEffect(() => {
    getAccessAreas();
  }, []);

  useEffect(() => {
    if (accessControlUserId) {
      getAccessUser();
      getSyncStatus();
    }
  }, [accessControlUserId]);

  useEffect(() => {
    if (accessControlUserName)
      dispatch({ type: "SET_ACCESS_NAME", payload: accessControlUserName });
  }, [accessControlUserName]);

  const getAccessUser = () => {
    dispatch({ type: "SET_GET_ACCESS_USER_LOADING", payload: true });

    const params = {
      include: ["AccessControlPermission"],
    };

    api
      .get(`/access-control/users/${accessControlUserId}`, { params })
      .then((res) => {
        if (res.data?.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_ACCESS_USER", payload: res.data });
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() =>
        dispatch({ type: "SET_GET_ACCESS_USER_LOADING", payload: false })
      );
  };

  const getSyncStatus = () => {
    if (!customerId) return;

    api
      .get(`/access-control/users/${accessControlUserId}/sync-status`)
      .then((res) => {
        if (res.data?.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_SYNC_STATUS", payload: res.data });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getAccessAreas = () => {
    api
      .get("/access-control/areas")
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          const sortedData = res.data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          dispatch({ type: "SET_AREAS", payload: sortedData });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addPermissionToUser = (areaId) => {
    if (!state.accessUser) return;

    dispatch({ type: "SET_CHANGE_PERMISSION_LOADING", payload: true });

    const body = {
      areaIds: [areaId],
    };

    api
      .post(`/access-control/users/${state.accessUser.id}/permissions`, body)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          const accessUser = {
            ...state.accessUser,
            AccessControlPermissions: [
              ...state.accessUser.AccessControlPermissions,
              ...res.data,
            ],
          };

          dispatch({ type: "SET_ACCESS_USER", payload: accessUser });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CHANGE_PERMISSION_LOADING", payload: false });
      });
  };

  const removePermissionFromUser = (areaId) => {
    if (!state.accessUser) return;

    dispatch({ type: "SET_CHANGE_PERMISSION_LOADING", payload: true });

    api
      .delete(
        `/access-control/users/${state.accessUser.id}/permissions/${areaId}`
      )
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          const accessUser = {
            ...state.accessUser,
            AccessControlPermissions:
              state.accessUser.AccessControlPermissions.filter(
                (permission) => permission.areaId !== areaId
              ),
          };

          dispatch({ type: "SET_ACCESS_USER", payload: accessUser });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CHANGE_PERMISSION_LOADING", payload: false });
      });
  };

  const createAccessUser = () => {
    dispatch({ type: "SET_CREATE_USER_LOADING", payload: true });

    const body = {
      customerId: customerId,
      name: state.accessName,
      type: state.accessType,
    };

    api
      .post(`/access-control/users`, body)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          const accessUser = {
            ...res.data,
            AccessControlPermissions: [],
          };
          dispatch({ type: "SET_ACCESS_USER", payload: accessUser });
          onCreateAccessControlUser(accessUser.id);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CREATE_USER_LOADING", payload: false });
      });
  };

  const sendPermissionEmail = (areaId) => {
    if (!areaId || !customerId || !state.accessUser?.id) return;

    const body = {
      areaId,
      customerId,
    };

    dispatch({ type: "SET_SEND_EMAIL_LOADING", payload: true });

    api
      .post(
        `/access-control/users/${state.accessUser.id}/permissions/send-email`,
        body
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSentSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_SEND_EMAIL_LOADING", payload: false });
      });
  };

  const regenerateAccessCode = (areaId) => {
    dispatch({ type: "SET_NEW_CODE_LOADING", payload: true });
    const body = {
      areaId,
    };
    api
      .post(
        `/access-control/users/${state.accessUser.id}/permissions/regenerate-code`,
        body
      )
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          const areaIdToCodeMap = res.data.reduce((map, item) => {
            map[item.areaId] = item.code;
            return map;
          }, {});

          const accessUser = {
            ...state.accessUser,
            AccessControlPermissions:
              state.accessUser.AccessControlPermissions.map((permission) => {
                if (areaIdToCodeMap[permission.areaId]) {
                  return {
                    ...permission,
                    code: areaIdToCodeMap[permission.areaId],
                  };
                }
                return permission;
              }),
          };
          dispatch({ type: "SET_ACCESS_USER", payload: accessUser });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_NEW_CODE_LOADING", payload: false });
      });
  };

  const enableOrDisableUser = (newDisabledStatus) => {
    //TODO: Change to edit and add name and type to body
    if (!state.accessUser) return;

    dispatch({ type: "SET_CHANGE_DISABLE_LOADING", payload: true });

    const body = {
      isDisabled: newDisabledStatus,
    };

    api
      .post(`/access-control/users/${state.accessUser.id}`, body)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          const accessUser = {
            ...state.accessUser,
            isDisabled: res.data.isDisabled,
          };

          dispatch({ type: "SET_ACCESS_USER", payload: accessUser });
          getSyncStatus();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CHANGE_DISABLE_LOADING", payload: false });
      });
  };

  const userHasPermission = (areaId) => {
    return state.accessUser?.AccessControlPermissions?.map(
      (permission) => permission.areaId
    ).includes(areaId);
  };

  const handleChangeAccessName = (e) => {
    dispatch({ type: "SET_ACCESS_NAME", payload: e.target.value });
  };

  const handleChangeAccessType = (e) => {
    dispatch({ type: "SET_ACCESS_TYPE", payload: e.target.value });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose(state.accessUser);
      }}
      title={t("accessControl")}
      maxWidth="md"
    >
      {!state.isAccessUserLoading ? (
        <Container>
          {!state.accessUser ? (
            <Grid container spacing={1}>
              <Grid item>
                <TextInput
                  label={t("name")}
                  onChange={handleChangeAccessName}
                  value={state.accessName}
                  disabled={accessControlUserName ? true : false}
                />
              </Grid>
              <Grid item>
                <Select
                  label={t("type")}
                  name="type"
                  value={accessType || state.accessType}
                  onChange={handleChangeAccessType}
                  disabled={accessControlUserName ? true : false}
                  options={Object.values(ACCESS_CONTROL_USER_TYPES).map(
                    (type) => ({
                      value: type,
                      label: t(type.toLowerCase()),
                    })
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={createAccessUser}
                  startIcon={
                    state.isCreateUserLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <PersonAddIcon />
                    )
                  }
                  variant="contained"
                  disabled={state.isCreateUserLoading}
                >
                  {t("createUser")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {customerId && (
                <Grid item container xs={12} spacing={1}>
                  <Grid
                    item
                    container
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography variant="body1" fontWeight="bold">
                        {state.accessUser?.name
                          ? state.accessUser?.name + " | "
                          : "" + state.accessUser?.type}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {state.syncStatus?.current.isDisabled ? (
                        <Chip
                          size="small"
                          color="error"
                          label={t("disabled")}
                          icon={<BlockIcon />}
                        />
                      ) : (
                        <Chip
                          size="small"
                          color="success"
                          label={t("enabled")}
                          icon={<HowToRegIcon />}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <LabeledText
                        label={t("expectedState")}
                        value={
                          state.syncStatus?.expected.isDisabled
                            ? t("disabled")
                            : t("enabled")
                        }
                      />
                    </Grid>
                    <Grid item>
                      <CustomButton
                        variant="text"
                        text={
                          state.syncStatus?.current.isDisabled
                            ? t("enable")
                            : t("disable")
                        }
                        onClick={() =>
                          enableOrDisableUser(
                            !state.syncStatus?.current.isDisabled
                          )
                        }
                        loading={state.isChangeDisableLoading}
                      />
                    </Grid>
                  </Grid>
                  {user.hasAction("VIEW_ACCESS_CONTROL_PERMISSIONS") && (
                    <Grid item xs={12}>
                      <LabeledText
                        label={t("expectedPermissions")}
                        value={
                          state.syncStatus?.expected.areaIds
                            .map((id) => {
                              const area = state.areas.find(
                                (area) => area.id === id
                              );
                              return area?.name;
                            })
                            .join(", ") || t("none")
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )}
              {user.hasAction("VIEW_ACCESS_CONTROL_AREAS") &&
                user.hasAction("VIEW_ACCESS_CONTROL_PERMISSIONS") && (
                  <Grid item xs={12}>
                    <Typography variant="h5">{t("accessTo")}:</Typography>
                    <List>
                      {state.areas.map((area, index) => (
                        <ListItem key={index}>
                          <ListItemText>{area.name}</ListItemText>
                          {userHasPermission(area.id) &&
                            user.hasAction("MANAGE_ACCESS_CONTROL_USERS") && (
                              <ListItem
                                sx={{
                                  justifyContent: "flex-end",
                                  width: "auto",
                                  alignItems: "center",
                                }}
                              >
                                <CustomButton
                                  variant="text"
                                  icon={<SyncIcon />}
                                  text={
                                    state.accessUser?.AccessControlPermissions.find(
                                      (permission) =>
                                        permission.areaId === area.id
                                    ).code
                                  }
                                  onClick={() => regenerateAccessCode(area.id)}
                                  loading={state.isNewCodeLoading}
                                />
                                <IconButton
                                  color="primary"
                                  onClick={() => sendPermissionEmail(area.id)}
                                  disabled={state.isSendEmailLoading}
                                >
                                  <MailIcon />
                                </IconButton>
                              </ListItem>
                            )}
                          <Switch
                            checked={userHasPermission(area.id)}
                            onChange={(e) => {
                              e.target.checked === true
                                ? addPermissionToUser(area.id)
                                : removePermissionFromUser(area.id);
                            }}
                            disabled={
                              state.isChangePermissionLoading ||
                              !user.hasAction(
                                "MANAGE_ACCESS_CONTROL_PERMISSIONS"
                              )
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
            </Grid>
          )}
        </Container>
      ) : (
        <CircularProgress size={30} />
      )}
    </Dialog>
  );
};

export default AccessControlDialog;
