import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";

// Icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import GroupIcon from "@mui/icons-material/Group";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";

// Components & Utils
import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";
import App from "../../../context/AppContext";
import CreateButton from "../../Inputs/CreateButton";
import CustomerUserDialog from "../../Inputs/CustomerUserDialog";
import CustomSelect from "../../Inputs/CustomSelect";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import Filters from "../../global/structure/Filters";
import Page from "../../global/structure/Page";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import AccessControlDialog from "../AccessControl/AccessControlDialog";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  centers: [],
  customers: [],
  filters: {
    autoSearch: false,
    centerId: "",
    hasActiveContract: false,
    isKeyAccount: "",
    name: "",
    type: [],
  },
  name: "customer",
  options: {
    loaded: true,
    rowlink: "customer",
  },
  types: [],
  selectedCustomerId: null,
  isAccessDialogOpen: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_COLUMNS":
      return { ...state, columns: action.payload.columns };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload.customers };
    case "SET_CUSTOMER_TYPES":
      return { ...state, types: action.payload };
    case "SET_LOADED":
      return {
        ...state,
        options: { ...state.options, loaded: action.payload },
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SELECTED_CUSTOMER":
      return { ...state, selectedCustomerId: action.payload };
    case "SET_ACCESS_DIALOG_OPEN":
      return { ...state, isAccessDialogOpen: action.payload };
    default:
      throw new Error("Action type not found in reducer");
  }
}

const defaultCustomerUserDialog = {
  open: false,
  emails: [],
  customerUser: null,
};

export default function CustomersPage() {
  const { api, user } = useContext(App);
  const history = useHistory();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("customers");
  const [tErrors] = useTranslation("errors");
  const [customerUserDialog, setCustomerUserDialog] = useState(
    defaultCustomerUserDialog
  );

  const CUSTOMER_COLUMNS = [
    {
      key: "fullName",
      label: t("name"),
      sortType: "string",
    },
    {
      key: "nif",
      label: t("nif"),
      sortType: "string",
    },
    {
      key: "CustomerType.name",
      label: t("type"),
      sortType: "string",
      renderFunction: (value) => t(value?.toLowerCase()),
    },
    {
      key: "phoneNumbers",
      label: t("phoneNumbers"),
      sortType: "string",
      renderFunction: (value) => t(value?.toLowerCase()),
    },
    {
      key: "emails",
      label: t("emails"),
      sortType: "string",
      renderFunction: (value) => t(value?.toLowerCase()),
    },
    {
      key: "isKeyAccount",
      label: t("keyAccount"),
      sortType: "number",
      renderFunction: (value) => value && <DoneIcon />,
    },
    {
      key: "CustomerAccessUser",
      label: t("accessControl"),
      sortType: "other",
      renderFunction: (value, item) => {
        if (!user.hasAction("MANAGE_ACCESS_CONTROL_USERS")) {
          return (
            <Tooltip
              title={
                !value ? t("noAccessControlUser") : t("hasAccessControlUser")
              }
            >
              {!value ? <ClearIcon /> : <CheckIcon color="primary" />}
            </Tooltip>
          );
        } else
          return (
            <Tooltip title={t("accessControl")} placement="top">
              <Box>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: "SET_SELECTED_CUSTOMER",
                      payload: item.id,
                    });
                    dispatch({
                      type: "SET_ACCESS_DIALOG_OPEN",
                      payload: true,
                    });
                    e.stopPropagation();
                  }}
                >
                  <RoomPreferencesIcon color={value ? "success" : "primary"} />
                </IconButton>
              </Box>
            </Tooltip>
          );
      },
    },
    {
      key: "Users",
      label: t("user"),
      sortType: "number",
      renderFunction: (value, item) =>
        value?.length ? (
          <Tooltip title={t("viewUser")} placement="top">
            <Box>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCustomerUserDialog({
                    open: true,
                    emails: item.CustomerEmails,
                    customerUser: value[0],
                    onClose: (edited) => {
                      setCustomerUserDialog(defaultCustomerUserDialog);
                      edited && getCustomers();
                    },
                  });
                }}
              >
                <GroupIcon />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title={t("noUser")} placement="top">
            <Box>
              <IconButton color="default" component={Link} to={undefined}>
                <PersonOffIcon />
              </IconButton>
            </Box>
          </Tooltip>
        ),
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
    },
  ];

  const CSV_HEADERS = [
    {
      key: "CustomerType.name",
      label: t("type"),
    },
    {
      key: "fullName",
      label: t("name"),
    },
    {
      key: "nif",
      label: t("nif"),
    },
    {
      key: "address",
      label: t("address"),
    },
    {
      key: "City.name",
      label: t("city"),
    },
    {
      key: "PostalCode.number",
      label: t("postalCode"),
    },
    {
      key: "birthdate",
      label: t("birthdate"),
    },
    {
      key: "phoneNumbers",
      label: t("phoneNumbers"),
    },
    {
      key: "emails",
      label: t("emails"),
    },
    {
      key: "isKeyAccount",
      label: t("keyAccount"),
    },
    {
      key: "personaNonGrata",
      label: t("personaNonGrata"),
    },
    {
      key: "Users[0].name",
      label: t("user"),
    },
    ...(user.hasAction("VIEW_CONTRACTS")
      ? [
          {
            key: "numberOfContracts",
            label: t("contracts"),
          },
          {
            key: "activeContracts",
            label: t("activeContracts"),
          },
        ]
      : []),
  ];

  const FILTERS = [
    { name: "autoSearch", type: "boolean" },
    { name: "centerId", type: "number" },
    { name: "hasActiveContract", type: "boolean" },
    { name: "isKeyAccount", type: "boolean" },
    { name: "name", type: "string" },
    { name: "type", type: "number" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  //Initial useEffect
  useEffect(() => {
    getCenters();
    getCustomerTypes();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === true) getCustomers();
  }, [state.filters.autoSearch]);

  /* BACKEND CALLS */

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCenters"), { variant: "info" });
          }
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomers = () => {
    dispatch({ type: "SET_LOADED", payload: false });

    //Change url parameters
    const url = generateURL("/app/customers", state.filters);
    history.push(url);

    let params = {
      include: [
        "AccessControlUser",
        "Box",
        "City",
        "Contract",
        "CustomerAccessUser",
        "CustomerEmail",
        "CustomerPhoneNumber",
        "CustomerType",
        "PostalCode",
        "User",
      ],
    };

    state.filters.name && (params.name = state.filters.name);
    state.filters.hasActiveContract === true &&
      (params.hasActiveContract = state.filters.hasActiveContract);
    state.filters.type !== "" && (params.customerTypeId = state.filters.type);
    state.filters.centerId !== "" && (params.centerId = state.filters.centerId);
    state.filters.isKeyAccount !== "" &&
      (params.isKeyAccount = state.filters.isKeyAccount);

    api
      .get("/customers", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCustomers"), { variant: "info" });
          }

          const customers = response.data.map((customer) => {
            return {
              ...customer,
              emails: customer.CustomerEmails.map((email) => email.email).join(
                ", "
              ),
              phoneNumbers: customer.CustomerPhoneNumbers.map(
                (phone) => phone.number
              ).join(", "),
              numberOfContracts: customer.Contracts.length,
              activeContracts: customer.Contracts.filter(
                (contract) => contract.endDate != null
              ).length,
              actions: (
                <Tooltip title={t("createContract")} placement="top">
                  <IconButton
                    sx={{ padding: 0 }}
                    size="small"
                    variant="contained"
                    component={Link}
                    to={"/app/contract/create?customerId=" + customer.id}
                    color="success"
                  >
                    <NoteAddIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ),
            };
          });

          dispatch({
            type: "SET_CUSTOMERS",
            payload: { customers },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADED", payload: true }));
  };

  const getCustomerTypes = () => {
    api
      .get("/customer-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_CUSTOMER_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* HANDLERS */

  const handleFilterChange = (e) => {
    if (e.target.name === "name" && /^[0-9\s]+$/.test(e.target.value))
      e.target.value = e.target.value.replace(/ /g, "");
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleHasActiveContractChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.checked,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_CUSTOMERS", payload: { customers: [] } });
  };

  const search = () => {
    if (state.filters.autoSearch === true) getCustomers();
    else
      dispatch({
        type: "SET_FILTER",
        payload: {
          inputname: "autoSearch",
          value: true,
        },
      });
  };

  const selectedCustomer = state.customers.find(
    (customer) => customer.id === state.selectedCustomerId
  );

  return (
    <Page title={t("customers")} browserTitle={t("customers")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                id="nameFilter"
                label={t("search")}
                value={state.filters.name}
                name="name"
                onChange={handleFilterChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  ...state.types.map((type) => ({
                    value: type.id,
                    label: type.name,
                  })),
                ]}
                label={t("types")}
                name="type"
                onChange={handleFilterChange}
                value={state.filters.type}
              />,
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
                label={t("keyAccount")}
                name="isKeyAccount"
                onChange={handleFilterChange}
                value={state.filters.isKeyAccount}
              />,
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  ...state.centers.map((center) => ({
                    value: center.id,
                    label: center.name,
                  })),
                ]}
                label={t("centers")}
                name="centerId"
                onChange={handleFilterChange}
                value={state.filters.centerId}
              />,
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasActiveContract"
                    checked={state.filters.hasActiveContract}
                    onClick={handleHasActiveContractChange}
                  />
                }
                label={t("hasActiveContracts")}
              ></FormControlLabel>,
            ]}
          />
        </Grid>

        <Grid item xs={12} sm="auto">
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ExportButton
              data={exportDataParse(state.customers, CSV_HEADERS)}
              display={user.hasAction("EXPORT_DATA")}
            />
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} sm="auto">
          <CreateButton
            action={"CREATE_CUSTOMERS"}
            label={t("createCustomer")}
            link={"/customer/create"}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={CUSTOMER_COLUMNS}
            data={state.customers}
            options={state.options}
          />
        </Grid>
      </Grid>
      {console.log(
        state.customers.find(
          (customer) => customer.id == state.selectedCustomerId
        )
      )}
      {user.hasAction("MANAGE_ACCESS_CONTROL_USERS") &&
        state.selectedCustomerId && (
          <AccessControlDialog
            accessControlUserId={selectedCustomer?.AccessControlUser?.id}
            customerId={state.selectedCustomerId}
            open={state.isAccessDialogOpen}
            accessControlUserName={selectedCustomer?.fullName}
            onClose={(accessUser) => {
              if (!selectedCustomer?.AccessControlUser && accessUser) {
                const updatedCustomers = state.customers.map((customer) =>
                  customer.id === state.selectedCustomerId
                    ? { ...customer, AccessControlUser: accessUser }
                    : customer
                );

                dispatch({
                  type: "SET_CUSTOMERS",
                  payload: { customers: updatedCustomers },
                });
              }

              dispatch({
                type: "SET_SELECTED_CUSTOMER",
                payload: null,
              });
              dispatch({
                type: "SET_ACCESS_DIALOG_OPEN",
                payload: false,
              });
            }}
          />
        )}
      <CustomerUserDialog {...customerUserDialog} />
    </Page>
  );
}
