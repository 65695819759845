import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box, CircularProgress, Divider } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import AppContext from "../../../../../context/AppContext";

import Page from "../../../../global/structure/Page";
import Filters from "../../../../global/structure/Filters";
import Select from "../../../../global/inputs/Select";

import InformationBox from "../../Components/InformationBox";
import CustomDate from "../../../../Inputs/CustomDate";
import CenterSelect from "../../../../Inputs/CenterSelect";
import SearchButton from "../../../../Inputs/SearchButton";

import { CenterNonPayments } from "../../Charts/NonPaymentsChart";
import { HistoricalRecuperatedBy } from "../../Charts/NonPaymentsChart";
import { HistoricalRecuperationByRecuperationDelay } from "../../Charts/NonPaymentsChart";
import { HistoricalRecuperationMethod } from "../../Charts/NonPaymentsChart";
import { HistoricalRecuperations } from "../../Charts/NonPaymentsChart";
import { RecoveringEntityPie } from "../../Charts/NonPaymentsChart";
import { RecuperatedByResume } from "../../Charts/NonPaymentsChart";

import { removeDuplicatesObjectArray } from "../../../../../utils/dashboardUtils";
import { localeFormat } from "../../../../../utils/format";
import { thisYear, dateDiff } from "../../../../../utils/date";

const {
  // Invoice series
  F_INVOICE_SERIES_ID,
  A_INVOICE_SERIES_ID,
  S_INVOICE_SERIES_ID,
  C_INVOICE_SERIES_ID,
  H_INVOICE_SERIES_ID,
  R_INVOICE_SERIES_ID,

  // Merchantable types
  RENT_MERCHANTABLE_TYPE_ID,
  DEPOSIT_MERCHANTABLE_TYPE_ID,
  IKEA_MERCHANTABLE_TYPE_ID,
  OTHER_MERCHANTABLE_TYPE_ID,
  COMMISSION_MERCHANTABLE_TYPE_ID,
  SHELF_MERCHANTABLE_TYPE_ID,
  PADLOCK_MERCHANTABLE_TYPE_ID,
  IKEA_DELIVERY_MERCHANTABLE_TYPE_ID,
  PROMOTION_MERCHANTABLE_TYPE_ID,

  // Payment methods
  CASH_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  CONSOLIDATION_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
} = require("../../../../../data/constants");

const invoiceSerieNames = {
  [F_INVOICE_SERIES_ID]: "F",
  [A_INVOICE_SERIES_ID]: "A",
  [S_INVOICE_SERIES_ID]: "S",
  [C_INVOICE_SERIES_ID]: "C",
  [H_INVOICE_SERIES_ID]: "H",
  [R_INVOICE_SERIES_ID]: "R",
};

const merchantableTypeNames = {
  [RENT_MERCHANTABLE_TYPE_ID]: "rent",
  [DEPOSIT_MERCHANTABLE_TYPE_ID]: "deposit",
  [IKEA_MERCHANTABLE_TYPE_ID]: "ikea",
  [OTHER_MERCHANTABLE_TYPE_ID]: "other",
  [COMMISSION_MERCHANTABLE_TYPE_ID]: "commission",
  [SHELF_MERCHANTABLE_TYPE_ID]: "shelf",
  [PADLOCK_MERCHANTABLE_TYPE_ID]: "padlock",
  [IKEA_DELIVERY_MERCHANTABLE_TYPE_ID]: "ikeaDelivery",
  [PROMOTION_MERCHANTABLE_TYPE_ID]: "promotion",
};

const paymentMethodNames = {
  [CASH_PAYMENT_METHOD_ID]: "cash",
  [RECEIPT_PAYMENT_METHOD_ID]: "receipt",
  [PAYCARD_PAYMENT_METHOD_ID]: "paycard",
  [TRANSFER_PAYMENT_METHOD_ID]: "transfer",
  [CONSOLIDATION_PAYMENT_METHOD_ID]: "consolidation",
  [TOKENIZED_PAYCARD_PAYMENT_METHOD_ID]: "tokenizedPaycard",
};

const paymentMethods = Object.keys(paymentMethodNames).map((key) => ({
  value: key,
  label: paymentMethodNames[key],
}));

const defaultFilters = {
  billingError: [],
  centerId: [],
  invoiceIssueDateFrom: "",
  invoiceIssueDateTo: "",
  nonPaymentDateFrom: "",
  nonPaymentRecuperationDateFrom: thisYear()[0],
  nonPaymentRecuperationDateTo: "",
  nonPaymentDateTo: "",
  recuperatedBy: [],
  recuperationMethod: [],
};

function RecuperationTab() {
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");

  const { api } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [loaded, setLoaded] = useState(true);
  const [recuperator, setRecuperator] = useState([]);

  const getData = () => {
    setLoaded(false);
    const params = {
      include: ["Customer", "Invoice", "InvoiceItem", "Merchantable", "User"],
    };

    if (filters.centerId.length > 0) params.centerId = filters.centerId;

    if (filters.billingError.length > 0)
      params.invoiceBillingError = filters.billingError;

    if (
      filters.invoiceIssueDateFrom !== "" ||
      filters.invoiceIssueDateTo !== ""
    )
      params.invoiceIssueDate = {
        min:
          filters.invoiceIssueDateFrom !== ""
            ? filters.invoiceIssueDateFrom
            : undefined,
        max:
          filters.invoiceIssueDateTo !== ""
            ? filters.invoiceIssueDateTo
            : undefined,
      };

    if (filters.nonPaymentDateFrom !== "")
      params.nonPaymentDate = filters.nonPaymentDateFrom;

    if (filters.nonPaymentDateTo !== "")
      params.nonPaymentDateTo = filters.nonPaymentDateTo;

    if (
      filters.nonPaymentRecuperationDateFrom !== "" ||
      filters.nonPaymentRecuperationDateTo !== ""
    )
      params.recuperationDate = {
        min:
          filters.nonPaymentRecuperationDateFrom !== ""
            ? filters.nonPaymentRecuperationDateFrom
            : undefined,
        max:
          filters.nonPaymentRecuperationDateTo !== ""
            ? filters.nonPaymentRecuperationDateTo
            : undefined,
      };

    if (filters.recuperationMethod.length > 0)
      params.recuperationMethod = filters.recuperationMethod;

    api
      .get("/non-payments", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        const parsedData = parseData(response.data, t);
        const recuperator = removeDuplicatesObjectArray(
          parsedData,
          "recuperatedByName"
        )
          .filter((e) => e.recuperatedById !== "")
          .map((e) => ({
            id: e.recuperatedById,
            name: e.recuperatedByName,
            role: e.recuperatedByRole,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setData(parsedData);
        setRecuperator(recuperator);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = async () => {
    getData();
  };

  const filtredData = dataFilter(data, filters);

  const totalInvoices = removeDuplicatesObjectArray(filtredData, "id").length;
  const totalAmount = filtredData.reduce((acc, item) => acc + item.amount, 0);
  const averageRecuperationDelay = filtredData.length
    ? filtredData.reduce((acc, item) => acc + item.recuperationDelay, 0) /
      filtredData.length
    : 0;

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filters
            filters={[
              <CustomDate
                label={t("nonPaymentRecuperationDateFrom")}
                value={filters.nonPaymentRecuperationDateFrom}
                onChange={handleFilterChange}
                name="nonPaymentRecuperationDateFrom"
                sx={{ minWidth: 200 }}
              />,
              <CustomDate
                label={t("nonPaymentRecuperationDateTo")}
                value={filters.nonPaymentRecuperationDateTo}
                onChange={handleFilterChange}
                name="nonPaymentRecuperationDateTo"
                sx={{ minWidth: 200 }}
              />,
              <CustomDate
                label={t("nonPaymentDateFrom")}
                value={filters.nonPaymentDateFrom}
                onChange={handleFilterChange}
                name="nonPaymentDateFrom"
                sx={{ minWidth: 200 }}
              />,
              <CustomDate
                label={t("nonPaymentDateTo")}
                value={filters.nonPaymentDateTo}
                onChange={handleFilterChange}
                name="nonPaymentDateTo"
                sx={{ minWidth: 200 }}
              />,
              <CustomDate
                label={t("invoiceIssueDateFrom")}
                value={filters.invoiceIssueDateFrom}
                onChange={handleFilterChange}
                name="invoiceIssueDateFrom"
                sx={{ minWidth: 200 }}
              />,
              <CustomDate
                label={t("invoiceIssueDateTo")}
                value={filters.invoiceIssueDateTo}
                onChange={handleFilterChange}
                name="invoiceIssueDateTo"
                sx={{ minWidth: 200 }}
              />,
              <Select
                multiple
                label={t("recuperationMethod")}
                value={filters.recuperationMethod}
                onChange={handleFilterChange}
                name="recuperationMethod"
                options={[
                  ...paymentMethods.map((m) => ({
                    value: m.value,
                    label: t(m.label),
                  })),
                ]}
              />,
              <Select
                multiple
                label={t("billingError")}
                value={filters.billingError}
                onChange={handleFilterChange}
                name="billingError"
                options={[
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
              />,
              <CenterSelect
                multiple
                name={"centerId"}
                label={t("center")}
                value={filters.centerId}
                onChange={handleFilterChange}
              />,
              <SearchButton loading={!loaded} onClick={handleSearch} />,
            ]}
          />
        </Grid>
        {loaded && recuperator.length > 0 && (
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item>
              <Select
                multiple
                value={filters.recuperatedBy}
                onChange={handleFilterChange}
                name="recuperatedBy"
                label={t("recuperatedBy")}
                options={recuperator.map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
              />
            </Grid>
          </Grid>
        )}
        {loaded ? (
          <>
            <Grid item container spacing={2} justifyContent={"center"}>
              <Grid item>
                <InformationBox
                  title={t("totalInvoices")}
                  mainValue={totalInvoices}
                  height={150}
                  width={300}
                />
              </Grid>
              <Grid item>
                <InformationBox
                  title={t("sumOfAmounts")}
                  mainValue={localeFormat(totalAmount)}
                  units={"€"}
                  height={150}
                  width={300}
                />
              </Grid>
              <Grid item>
                <InformationBox
                  title={t("averageRecuperationDelay")}
                  mainValue={localeFormat(averageRecuperationDelay / 1440)}
                  units={t("days")}
                  height={150}
                  width={300}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <HistoricalRecuperations
                items={filtredData}
                startDate={filters.nonPaymentRecuperationDateFrom}
                endDate={filters.nonPaymentRecuperationDateTo}
              />
            </Grid>
            <Grid item xs={12}>
              <HistoricalRecuperationMethod
                items={filtredData}
                startDate={filters.nonPaymentRecuperationDateFrom}
                endDate={filters.nonPaymentRecuperationDateTo}
              />
            </Grid>
            <Grid item xs={12}>
              <HistoricalRecuperatedBy
                items={filtredData}
                startDate={filters.nonPaymentRecuperationDateFrom}
                endDate={filters.nonPaymentRecuperationDateTo}
              />
            </Grid>
            <Grid item xs={12}>
              <RecuperatedByResume items={filtredData} />
            </Grid>
            <Grid item xs={12}>
              <CenterNonPayments items={filtredData} />
            </Grid>
            <Grid item xs={12}>
              <HistoricalRecuperationByRecuperationDelay
                items={filtredData}
                startDate={filters.nonPaymentRecuperationDateFrom}
                endDate={filters.nonPaymentRecuperationDateTo}
              />
            </Grid>
            <Grid item xs={6}>
              <RecoveringEntityPie items={filtredData} />
            </Grid>

            {/* <Grid
              item
              container
              marginTop={2}
              marginLeft={2}
              xs={12}
              sx={{
                backgroundColor: "#ffffff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
              }}
            >
              <Grid item xs={12} marginBottom={1}>
                <Typography variant="h6">{t("paymentMethods")}</Typography>
              </Grid>
              {filtredData && filtredData.length > 0 && (
                <Grid item xs={12}>
                  <PaymentMethodsSankey items={filtredData} />
                </Grid>
              )}
            </Grid> */}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={"100%"}
            width={"100%"}
            minHeight="90vh"
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Page>
  );
}

const parseData = (data, t) => {
  const parsedData = [];

  try {
    data.forEach((item) => {
      const items = item.Invoice.items || [];

      items.forEach((invoiceItem) => {
        const units = invoiceItem.units || 0;
        const totalPrice = units * (invoiceItem.pricePerUnit || 0);
        const pricePerUnit = units > 0 ? invoiceItem.pricePerUnit : 0;
        const nonPaymentGap =
          dateDiff(item.Invoice.issueDate, item.nonPaymentDate) || null;
        const recuperatedByCustomer = item.recuperator?.Role?.id === 1;

        parsedData.push({
          // Non payment
          id: item.id,
          nonPaymentDate: item.nonPaymentDate,
          nonPaymentDates: {
            days: item.nonPaymentDate.slice(0, 10),
            months: item.nonPaymentDate.slice(0, 7),
            years: item.nonPaymentDate.slice(0, 4),
          },
          reason: item.reason,
          reasonName: t(paymentMethodNames[item.reason] || ""),
          recuperationDate: item.recuperationDate || null,
          recuperationDates: {
            days: item.recuperationDate?.slice(0, 10) || null,
            months: item.recuperationDate?.slice(0, 7) || null,
            years: item.recuperationDate?.slice(0, 4) || null,
          },
          recuperationDelay: getRecuperationDelay(
            item.nonPaymentDate,
            item.recuperationDate
          ),
          recuperationMethod: item.recuperationMethod ?? null,
          recuperationMethodName: t(
            paymentMethodNames[item.recuperationMethod] || ""
          ),
          recuperatedById: recuperatedByCustomer
            ? 1
            : item.recuperator?.id || "",
          recuperatedByName: recuperatedByCustomer
            ? "customer"
            : item.recuperator?.name || "",
          recuperatorRole: item.recuperator?.Role?.name || "",
          createdBy: item.creator?.name || "",
          createdByRole: item.creator?.Role?.name || "",
          nonPaymentGap: nonPaymentGap,

          // Invoice
          serie: item.Invoice?.serieId || "",
          serieName: invoiceSerieNames[item.Invoice.serieId] || "",
          number: item.Invoice?.number || "",
          issueDate: item.Invoice?.issueDate || "",
          customerId: item.Invoice?.Customer?.id || "",
          customerName: `${item.Invoice?.Customer?.name || ""} ${
            item.Invoice?.Customer?.name || ""
          }`,
          paidAt: item.Invoice?.paidAt || "",
          billingError: item.Invoice.billingError,
          isBillingError:
            item.Invoice.billingError === true ? t("yes") : t("no"),
          uncollectible: item.Invoice.uncollectible,
          isUncollectible:
            item.Invoice.uncollectible === true ? t("yes") : t("no"),
          issueToNonPaymentInterval: dateDiff(
            item.Invoice.issueDate,
            item.nonPaymentDate
          ),

          // Invoice Item
          units: units,
          pricePerUnit: pricePerUnit,
          amount: totalPrice,

          // Merchantable
          merchantableTypeId:
            invoiceItem?.Merchantable?.merchantableTypeId || "",
          merchantableTypeName:
            merchantableTypeNames[
              invoiceItem?.Merchantable?.merchantableTypeId
            ] || "",
          centerId: invoiceItem?.Merchantable?.Center?.id || "",
          centerName: invoiceItem?.Merchantable?.Center?.name || "",

          // Contract
          nonPaymentBeforeContractEnd:
            item.nonPaymentDate <
            (invoiceItem?.Merchantable?.Contract?.endDate || "9999-99-99"),
        });
      });
    });
  } catch (error) {
    console.log(error);
  }

  return parsedData;
};

const getRecuperationDelay = (nonPaymentDate, recuperationDate) => {
  if (!recuperationDate) return null;

  const nonPayment = new Date(nonPaymentDate);
  const recuperation = new Date(recuperationDate);

  const minutes = (recuperation - nonPayment) / 60000;

  return minutes;
};

const dataFilter = (data, filters) => {
  return data.filter((item) => {
    let isValid = true;

    if (filters.recuperatedBy.length > 0)
      isValid = filters.recuperatedBy.includes(item.recuperatedById);

    return isValid;
  });
};

export default RecuperationTab;
