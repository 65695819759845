import { ButtonBase, Box, Card, Grid, Icon, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import PropTypes from "prop-types";

import Dialog from "../../../../global/Dialog";
import TextInput from "../../../../Inputs/TextInput";
import CustomButton from "../../../../Inputs/CustomButton";

const EditLayerNameDialog = (props) => {
  const { isOpen, name, onClose, onConfirm } = props;
  const [t] = useTranslation("floorPlans");

  const [newName, setnewName] = useState(name);

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose && onClose()}
      maxWidth="md"
      title={t("changeName")}
      actions={
        <>
          <CustomButton onClick={() => onClose} color="error">
            {t("cancel")}
          </CustomButton>
          <CustomButton onClick={() => onConfirm(newName)}>
            {t("confirm")}
          </CustomButton>
        </>
      }
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <TextInput
            label={t("name")}
            value={newName}
            onChange={(e) => setnewName(e.target.value)}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

EditLayerNameDialog.propTypes = {
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default EditLayerNameDialog;
