import { Tab, Tabs as MUITabs, Box } from "@mui/material";
import { useState } from "react";

const TabBar = ({ onChange, currentTab, tabs, options }) => {
  const { invertColors } = options || {};
  const handleChange = (event, index) => {
    onChange && onChange(event, index);
  };

  const textColor = invertColors ? "inherit" : "primary";
  const indicatorColor = invertColors ? "secondary" : "primary";

  return (
    <MUITabs
      value={currentTab}
      onChange={handleChange}
      variant="scrollable"
      textColor={textColor}
      indicatorColor={indicatorColor}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={
            tab.selectedLabel && currentTab === index
              ? tab.selectedLabel
              : tab.label
          }
        />
      ))}
    </MUITabs>
  );
};

const Tabs = ({
  onTabChange,
  currentTab,
  tabs,
  tabBottomMargin,
  contentPadding = 2,
  invertColors,
}) => {
  const [currTab, setCurrTab] = useState(Number(currentTab) || 0);

  const handleChange = (event, index) => {
    setCurrTab(index);
    onTabChange && onTabChange(event, index);
  };
  return (
    <Box>
      <TabBar
        currentTab={currTab}
        onChange={handleChange}
        tabs={tabs}
        options={{ invertColors: invertColors }}
      />
      <Box marginTop={tabBottomMargin} padding={contentPadding}>
        {tabs.filter((_, index) => index === currTab).map((tab) => tab.content)}
      </Box>
    </Box>
  );
};

export default Tabs;
