import {
  Box,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import Bar from "../../../global/charts/Bar";
import Scatter from "../../../global/charts/Scatter";
import Treemap from "../../../global/charts/Treemap";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  addAlfa,
  colors as colorGenerator,
} from "../Components/ColorGenerator";

import PercentIcon from "@mui/icons-material/Percent";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import { ChartContainer } from "./ChartContainer";
import { colors } from "../Components/ColorGenerator";
import {
  distinctValues,
  formatDate,
  getMonths,
  sumArray,
} from "../../../../utils/chartUtils";

import { localeFormat } from "../../../../utils/format";
import {
  // Box states
  BLOCKED_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,

  // Expense types
  ELECTRICITY_EXPENSE_TYPE_ID,
  LIQUIDATION_EXPENSE_TYPE_ID,
  PHONE_EXPENSE_TYPE_ID,
  SUPPLY_EXPENSE_TYPE_ID,
  WATER_EXPENSE_TYPE_ID,

  // Invoice series
  F_INVOICE_SERIES_ID,
  R_INVOICE_SERIES_ID,
  FRANCHISE_CENTER_TYPE_ID,
  NUT_CENTER_TYPE_ID,
  OTHER_CENTER_TYPE_ID,
} from "../../../../data/constants";

const PERCENTAGE = 1;
const METERS = 2;

const OCCUPANCY = 1;
const OCCUPIED_METERS = 2;
const PRICE_PER_METER = 3;
const INVOICING = 4;

// General tab charts
export const CentersOccupancyBarChart = ({ centers }) => {
  const [t] = useTranslation("dashboard");
  const [selected, setSelected] = useState(PERCENTAGE);

  const nutCenters = centers.filter(
    (center) => center.type === NUT_CENTER_TYPE_ID
  );
  const franchiseCenters = centers.filter(
    (center) => center.type === FRANCHISE_CENTER_TYPE_ID
  );

  const totalNutMeters = nutCenters.reduce(
    (acc, center) => acc + center.totalMeters,
    0
  );
  const totalNutOccupiedMeters = nutCenters.reduce(
    (acc, center) => acc + center.occupiedMeters,
    0
  );
  const totalFranchiseMeters = franchiseCenters.reduce(
    (acc, center) => acc + center.totalMeters,
    0
  );
  const totalFranchiseOccupiedMeters = franchiseCenters.reduce(
    (acc, center) => acc + center.occupiedMeters,
    0
  );

  let labels = [];
  let nutValueArray = [];
  let franchiseValueArray = [];

  let nutAverageValue = 0;
  let franchiseAverageValue = 0;

  if (selected === PERCENTAGE) {
    labels = centers
      .sort((a, b) => b.occupancy - a.occupancy)
      .map((center) => center.name);

    nutValueArray = centers.map((center) =>
      center.type === NUT_CENTER_TYPE_ID ? center.occupancy : 0
    );

    franchiseValueArray = centers.map((center) =>
      center.type === FRANCHISE_CENTER_TYPE_ID ? center.occupancy : 0
    );

    nutAverageValue =
      totalNutMeters > 0 ? (totalNutOccupiedMeters / totalNutMeters) * 100 : 0;
    franchiseAverageValue =
      totalFranchiseMeters > 0
        ? (totalFranchiseOccupiedMeters / totalFranchiseMeters) * 100
        : 0;
  } else {
    labels = centers
      .sort((a, b) => b.occupiedMeters - a.occupiedMeters)
      .map((center) => center.name);

    nutValueArray = centers.map((center) =>
      center.type === NUT_CENTER_TYPE_ID ? center.occupiedMeters : 0
    );

    franchiseValueArray = centers.map((center) =>
      center.type === FRANCHISE_CENTER_TYPE_ID ? center.occupiedMeters : 0
    );

    nutAverageValue = totalNutOccupiedMeters / nutCenters.length;
    franchiseAverageValue =
      totalFranchiseOccupiedMeters / franchiseCenters.length;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("nutAverage"),
        data: new Array(nutValueArray.length).fill(nutAverageValue),
        type: "line",
        borderColor: colors.yellow,
        backgroundColor: colors.yellow,
      },
      {
        label: t("franchiseAverage"),
        data: new Array(franchiseValueArray.length).fill(franchiseAverageValue),
        type: "line",
        borderColor: colors.brown,
        backgroundColor: colors.brown,
      },
      {
        label: t("nutCenters"),
        data: nutValueArray,
        borderColor: colors.primary,
        backgroundColor: colors.primary,
        stack: "Stack 0",
      },
      {
        label: t("franchiseCenters"),
        data: franchiseValueArray,
        borderColor: colors.grey,
        backgroundColor: colors.grey,
        stack: "Stack 0",
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(value.raw)} ${
              selected === PERCENTAGE ? "%" : "m²"
            }`;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("centersOccupancy")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid item margin={2}>
        <ToggleButtonGroup exclusive value={selected}>
          <ToggleButton
            value={PERCENTAGE}
            onClick={() => setSelected(PERCENTAGE)}
          >
            <Tooltip title={t(PERCENTAGE)}>
              <PercentIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={METERS} onClick={() => setSelected(METERS)}>
            <Tooltip title={t("meters")}>
              <SquareFootIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </ChartContainer>
  );
};

export const CentersPricePerMeterOccupancyScatterChart = ({ centers }) => {
  const [t] = useTranslation("dashboard");

  let parsedCenters = centers.filter((c) => c.City !== null);

  const FRANCHISE = 0;
  const dataSetsColors = [
    colors.brown,
    colors.yellow,
    colors.orange,
    colors.purple,
    colors.blue,
    colors.green,
    colors.turquoise,
  ];
  const objectNutAc = Object.groupBy(
    parsedCenters.filter((center) => center.type === NUT_CENTER_TYPE_ID),
    (c) => c.AC.name
  );
  const nutAC = Object.keys(objectNutAc).map((key) => objectNutAc[key]);
  const dataSetsData = [
    {
      label: t("franchise"),
      data: [],
      pointRadius: [],
      hoverRadius: [],
      backgroundColor: addAlfa(colors.grey, 0.5),
    },
    ...nutAC.map((ac, index) => ({
      label: ac[0].AC.name,
      data: [],
      pointRadius: [],
      hoverRadius: [],
      backgroundColor: addAlfa(
        dataSetsColors[index % dataSetsColors.length],
        0.5
      ),
    })),
  ];
  const dataSetsCenters = [[]];
  const totalInvoicing = {};
  nutAC.forEach((ac) => {
    dataSetsCenters.push([]);
  });

  const calcRadius = (invoicing, maxInvoicing) => {
    const MIN_RADIUS = 5;
    const VARIABLE_RADIUS = 20;

    return MIN_RADIUS + VARIABLE_RADIUS * (invoicing / maxInvoicing);
  };

  const maxInvoicing = Math.max(
    ...parsedCenters.map((center) => center.theoreticalInvoicing)
  );
  let nutInvoicing = 0;
  let franchiseInvoicing = 0;
  let nutOccupiedMetres = 0;
  let franchiseOccupiedMetres = 0;
  let nutTotalMeters = 0;
  let franchiseTotalMeters = 0;

  parsedCenters.forEach((center) => {
    const element = {
      x: center.pricePerMeter,
      y: center.occupancy,
    };

    totalInvoicing[center.name] = center.theoreticalInvoicing;
    if (center.type === FRANCHISE_CENTER_TYPE_ID) {
      franchiseInvoicing += center.theoreticalInvoicing;
      franchiseOccupiedMetres += center.occupiedMeters;
      franchiseTotalMeters += center.totalMeters;
      dataSetsData[FRANCHISE].data.push(element);
      dataSetsData[FRANCHISE].pointRadius.push(
        calcRadius(center.theoreticalInvoicing, maxInvoicing)
      );
      dataSetsData[FRANCHISE].hoverRadius.push(
        calcRadius(center.theoreticalInvoicing, maxInvoicing)
      );
      dataSetsCenters[FRANCHISE].push(center.name);
    } else if (center.type === NUT_CENTER_TYPE_ID) {
      nutInvoicing += center.theoreticalInvoicing;
      nutOccupiedMetres += center.occupiedMeters;
      nutTotalMeters += center.totalMeters;
      const acIndex = dataSetsData.findIndex(
        (dataSet) => dataSet.label === center.AC.name
      );
      dataSetsData[acIndex].data.push(element);
      dataSetsData[acIndex].pointRadius.push(
        calcRadius(center.theoreticalInvoicing, maxInvoicing)
      );
      dataSetsData[acIndex].hoverRadius.push(
        calcRadius(center.theoreticalInvoicing, maxInvoicing)
      );
      dataSetsCenters[acIndex].push(center.name);
    }
  });

  const nutPricePerMeter =
    nutOccupiedMetres > 0 ? nutInvoicing / nutOccupiedMetres : nutInvoicing;
  const franchisePricePerMeter =
    franchiseOccupiedMetres > 0
      ? franchiseInvoicing / franchiseOccupiedMetres
      : franchiseInvoicing;
  const nutOccupiedPercentage =
    nutTotalMeters > 0 ? (nutOccupiedMetres / nutTotalMeters) * 100 : 0;
  const franchiseOccupiedPercentage =
    franchiseTotalMeters > 0
      ? (franchiseOccupiedMetres / franchiseTotalMeters) * 100
      : 0;

  const franchiseAverage = {
    x: franchisePricePerMeter,
    y: franchiseOccupiedPercentage,
  };

  const nutAverage = {
    x: nutPricePerMeter,
    y: nutOccupiedPercentage,
  };

  const data = {
    datasets: [
      ...dataSetsData,
      {
        label: t("franchiseAverage"),
        data: [franchiseAverage],
        pointStyle: "triangle",
        pointRadius: [20],
        hoverRadius: [20],
        backgroundColor: colors.primary,
      },
      {
        label: t("nutAverage"),
        data: [nutAverage],
        pointStyle: "triangle",
        pointRadius: [20],
        hoverRadius: [20],
        backgroundColor: colors.secondary,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex > dataSetsData.length - 1)
              return `${tooltipItem.dataset.label}: ${localeFormat(
                tooltipItem.parsed.x
              )} € | ${t("occupancy")}: ${localeFormat(
                tooltipItem.parsed.y
              )} %`;
            return ` ${
              dataSetsCenters[tooltipItem.datasetIndex][tooltipItem.dataIndex]
            } : ${t("occupancy")}: ${localeFormat(
              tooltipItem.parsed.y
            )} % | ${t("price")}: ${localeFormat(
              tooltipItem.parsed.x
            )} €/m² | ${t("theoreticalInvoice")}: ${localeFormat(
              totalInvoicing[
                dataSetsCenters[tooltipItem.datasetIndex][tooltipItem.dataIndex]
              ]
            )} €`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: t("pricePerMeter") + " €",
        },
      },
      y: {
        title: {
          display: true,
          text: t("occupiedMeters") + " %",
        },
      },
    },
  };
  return (
    <ChartContainer
      title={t("occupation/pricePerMeter")}
      chart={<Scatter data={data} options={options} />}
    />
  );
};

// Invoicing tab charts
export const CentersInvoicingTreeMap = ({ invoiceItems }) => {
  const [t] = useTranslation("dashboard");

  const data = {
    name: "invoicing",
    children: [],
  };
  const parsedItems = invoiceItems
    .filter((item) => item.center)
    .filter((i) => i.center.AC || i.center.type === OTHER_CENTER_TYPE_ID);

  const dataSetsColors = [
    colors.primary,
    colors.green,
    colors.brown,
    colors.orange,
    colors.yellow,
    colors.purple,
    colors.turquoise,
    colors.blue,
  ];
  let colorIndex = 0;

  const nutItems =
    parsedItems.filter((item) => item.center?.type === NUT_CENTER_TYPE_ID) ||
    [];
  const franchiseItems =
    parsedItems.filter(
      (item) => item.center?.type === FRANCHISE_CENTER_TYPE_ID
    ) || [];
  const otherItems =
    parsedItems.filter((item) => item.center?.type === OTHER_CENTER_TYPE_ID) ||
    [];

  // Other centers
  let otherCenters = { name: "other", children: [] };
  let itemsByOther = Object.groupBy(otherItems, (c) => c.center.name);
  for (let [centerName, items] of Object.entries(itemsByOther)) {
    let invoicing = sumArray(
      items.map((item) => item.units * item.pricePerUnit)
    );
    let center = {
      name: centerName + " " + localeFormat(invoicing) + "€",
      value: invoicing,
      color: colors.grey,
    };
    otherCenters.children.push(center);
  }
  data.children.push(otherCenters);

  // Franchise centers
  let franchises = { name: "franchises", children: [] };
  let itemsByFranchises = Object.groupBy(franchiseItems, (c) => c.center.name);
  for (let [centerName, items] of Object.entries(itemsByFranchises)) {
    let invoicing = sumArray(
      items.map((item) => item.units * item.pricePerUnit)
    );
    let center = {
      name: centerName + " " + localeFormat(invoicing) + "€",
      value: invoicing,
    };

    franchises.children.push(center);
  }
  data.children.push(franchises);

  // Nut centers
  let nutItemsByRegion = Object.groupBy(nutItems, (c) => c.center.AC.name);
  const nutCenters = { name: "nut", children: [] };
  for (let [regionName, items] of Object.entries(nutItemsByRegion)) {
    let itemsByCenter = Object.groupBy(items, (i) => i.center.name);
    let centers = Object.entries(itemsByCenter).map((center) => {
      let invoicing = sumArray(
        center[1].map((item) => item.units * item.pricePerUnit)
      );
      return {
        name: center[0] + " " + localeFormat(invoicing) + "€",
        value: invoicing,
        color: dataSetsColors[colorIndex],
      };
    });

    let region = {
      name: regionName,
      children: [...centers],
    };
    colorIndex += 1;

    nutCenters.children.push(region);
  }
  data.children.push(nutCenters);

  const regions = Object.keys(nutItemsByRegion);

  const franchiseValues = franchiseItems.reduce(
    (acc, item) => acc + item.units * item.pricePerUnit,
    0
  );

  return (
    <ChartContainer title="Invoicing" chart={<Treemap data={data} />}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {otherItems.length > 0 && (
          <Grid item>
            {LegendItem({
              color: colors.grey,
              label: "OTHER",
            })}
          </Grid>
        )}
        {franchiseValues > 0 && (
          <Grid item>
            {LegendItem({
              color: colors.slateBlue,
              label: "FRANCHISE",
            })}
          </Grid>
        )}
        {nutItems.length > 0 &&
          regions.map((ac, index) => (
            <Grid item>
              {LegendItem({
                color: dataSetsColors[index % dataSetsColors.length],
                label: ac,
              })}
            </Grid>
          ))}
      </Grid>
    </ChartContainer>
  );
};

export const CentersInvoicingBarChart = ({ invoiceItems }) => {
  const [t] = useTranslation("dashboard");

  const groupedItems = Object.groupBy(invoiceItems, (i) => i.centerId);
  const groupedItemsKeys = Object.keys(groupedItems);

  const data = groupedItemsKeys
    .map((key) => {
      const items = groupedItems[key];
      const center = items[0].center;
      const invoicing = sumArray(
        items.map((item) => item.units * item.pricePerUnit)
      );
      return {
        centerName: center.name,
        centerType: center.type,
        invoicing: invoicing,
      };
    })
    .sort((a, b) => b.invoicing - a.invoicing);

  const chartData = {
    labels: data.map((item) => item.centerName),
    datasets: [
      {
        label: t("nutAverage"),
        data: new Array(data.length).fill(
          getAverage(
            data,
            "invoicing",
            (e) => e.centerType === NUT_CENTER_TYPE_ID
          )
        ),
        type: "line",
        borderColor: colors.yellow,
        backgroundColor: colors.yellow,
      },
      {
        label: t("franchiseAverage"),
        data: new Array(data.length).fill(
          getAverage(
            data,
            "invoicing",
            (e) => e.centerType === FRANCHISE_CENTER_TYPE_ID
          )
        ),
        type: "line",
        borderColor: colors.brown,
        backgroundColor: colors.brown,
      },
      {
        label: t("otherAverage"),
        data: new Array(data.length).fill(
          getAverage(
            data,
            "invoicing",
            (e) => e.centerType === OTHER_CENTER_TYPE_ID
          )
        ),
        type: "line",
        borderColor: colors.grey,
        backgroundColor: colors.grey,
      },
      getDataSet(
        t("nutInvoicing"),
        data.map((e) => {
          if (e.centerType !== 0) return { ...e, invoicing: 0 };
          return e;
        }),
        {
          backgroundColor: colors.primary,
          dataKey: "invoicing",
          stack: "Stack 0",
        }
      ),
      getDataSet(
        t("franchiseInvoicing"),
        data.map((e) => {
          if (e.centerType !== 1) return { ...e, invoicing: 0 };
          return e;
        }),
        {
          backgroundColor: colors.secondary,
          dataKey: "invoicing",
          stack: "Stack 0",
        }
      ),
      getDataSet(
        t("otherInvoicing"),
        data.map((e) => {
          if (e.centerType !== 2) return { ...e, invoicing: 0 };
          return e;
        }),
        {
          backgroundColor: colors.grey,
          dataKey: "invoicing",
          stack: "Stack 0",
        }
      ),
    ],
  };

  const chartOptions = {
    elements: { point: { radius: 0 } },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(value.raw)} €`;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("invoicing")}
      chart={<Bar data={chartData} options={chartOptions} />}
    />
  );
};

// Objectives tab charts
export const NutCentersInvoicingScatterChart = ({ centers }) => {
  const [t] = useTranslation("dashboard");
  const [showAll, setShowAll] = useState(false);
  const parsedCenters = centers.filter((c) => c.type === NUT_CENTER_TYPE_ID);
  const dataSetColors = [
    colors.secondary,
    colors.green,
    colors.red,
    colors.purple,
    colors.yellow,
    colors.pink,
    colors.orange,
    colors.brown,
    colors.blue,
    colors.turquoise,
    colors.grey,
    colors.slateBlue,
    colors.coral,
    colors.gold,
    colors.hotPink,
    colors.sunsetOrange,
    colors.darkBrown,
    colors.midnightBlue,
    colors.seaGreen,
    colors.indigo,
  ];

  let chartData = {
    datasets: parsedCenters.map((center, index) => {
      return {
        label: center.name,
        data: [
          {
            x: center.pricePerMeter || 0,
            y: center.occupancy || 0,
          },
          {
            x: center.objectivePPM || 0,
            y: center.objectiveOccupancy || 0,
          },
        ],
        pointStyle: ["circle", "rectRot"],
        pointRadius: [10, 8],
        hoverRadius: 10,
        backgroundColor: addAlfa(
          dataSetColors[index % dataSetColors.length],
          0.5
        ),
        showLine: true,
        borderColor: addAlfa(dataSetColors[index % dataSetColors.length], 0.8),
        borderWidth: 1.5,
      };
    }),
  };

  chartData.datasets = chartData.datasets.filter((dataset) => {
    if (showAll) return true;
    return (
      dataset.data[0].x > 0 &&
      dataset.data[0].y > 0 &&
      dataset.data[1].x > 0 &&
      dataset.data[1].y > 0
    );
  });

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${localeFormat(
              tooltipItem.parsed.x
            )} €/m² | ${t("occupancy")}: ${localeFormat(
              tooltipItem.parsed.y
            )} %`;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title="Invoicing/Occupancy"
      chart={<Scatter data={chartData} options={chartOptions} />}
    >
      <Grid
        item
        container
        margin={2}
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item>
          <Switch
            checked={showAll}
            onChange={() => setShowAll(!showAll)}
            color="primary"
          />
        </Grid>
        <Grid item>
          <Typography variant="h7">{t("showAll")}</Typography>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const NutObjectivesCompareBarChart = ({ centers }) => {
  const [t] = useTranslation("dashboard");
  const [selected, setSelected] = useState(OCCUPANCY);
  const [showAll, setShowAll] = useState(false);
  const [type, setType] = useState(METERS);

  let data = centers
    .filter((c) => c.type === NUT_CENTER_TYPE_ID)
    .map((center) => {
      const percentageFunction = (value, objective) => {
        if (objective) return ((value - objective) / objective) * 100;
        return 0;
      };
      const objectiveOccupancy = center.objectiveOccupancy || 0;
      const objectiveOccupiedMeters = center.objectiveOccupiedMeters || 0;
      const objectivePPM = center.objectivePPM || 0;
      const objectiveInvoicing = center.objectiveInvoicing || 0;

      return {
        label: center.name,

        occupancy: center.occupancy,
        occupiedMeters: center.occupiedMeters,
        pricePerMeter: center.pricePerMeter,
        theoreticalInvoicing: center.theoreticalInvoicing,

        objectiveOccupancy: objectiveOccupancy,
        objectiveOccupiedMeters: objectiveOccupiedMeters,
        objectivePPM: objectivePPM,
        objectiveInvoicing: objectiveInvoicing,

        occupancyDiff: center.occupancy - objectiveOccupancy,
        occupiedMetersDiff: center.occupiedMeters - objectiveOccupiedMeters,
        pricePerMeterDiff: center.pricePerMeter - objectivePPM,
        theoreticalInvoicingDiff:
          center.theoreticalInvoicing - objectiveInvoicing,

        occupancyDiffPercentage: percentageFunction(
          center.occupancy,
          objectiveOccupancy
        ),
        occupiedMetersDiffPercentage: percentageFunction(
          center.occupiedMeters,
          objectiveOccupiedMeters
        ),
        pricePerMeterDiffPercentage: percentageFunction(
          center.pricePerMeter,
          objectivePPM
        ),
        theoreticalInvoicingDiffPercentage: percentageFunction(
          center.theoreticalInvoicing,
          objectiveInvoicing
        ),
      };
    });

  data = data.map((center) => {
    let value = 0;
    if (selected === OCCUPANCY) {
      value =
        type === METERS ? center.occupancyDiff : center.occupancyDiffPercentage;
    }
    if (selected === OCCUPIED_METERS) {
      value =
        type === METERS
          ? center.occupiedMetersDiff
          : center.occupiedMetersDiffPercentage;
    }
    if (selected === PRICE_PER_METER) {
      value =
        type === METERS
          ? center.pricePerMeterDiff
          : center.pricePerMeterDiffPercentage;
    }
    if (selected === INVOICING) {
      value =
        type === METERS
          ? center.theoreticalInvoicingDiff
          : center.theoreticalInvoicingDiffPercentage;
    }
    return { ...center, value: value };
  });

  data = data
    .filter((e) => {
      if (showAll) return true;
      if (e.pricePerMeter === 0) return false;
      if (e.occupancy === 0) return false;
      return true;
    })
    .sort((a, b) => b.value - a.value);

  const labels = data.map((center) => center.label);
  const dataSets = [
    {
      label: t("average"),
      data: [],
      type: "line",
      backgroundColor: [colors.brown],
      borderColor: [colors.brown],
    },
    {
      label: t("centers"),
      data: [],
      backgroundColor: [],
      borderColor: [],
    },
  ];

  data.forEach((center) => {
    dataSets[1].data.push(center.value);
    dataSets[1].backgroundColor.push(
      center.value > 0 ? colors.green : colors.red
    );
    dataSets[1].borderColor.push(center.value > 0 ? colors.green : colors.red);
  });

  dataSets[0].data = new Array(dataSets[1].data.length).fill(
    sumArray(dataSets[1].data) / dataSets[1].data.length
  );

  const chartData = {
    labels: labels,
    datasets: dataSets,
  };

  const chartOptions = {
    elements: { point: { radius: 0 } },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.label}: ${localeFormat(value.raw)} ${
              type === METERS
                ? selected === OCCUPANCY
                  ? "%"
                  : selected === PRICE_PER_METER
                  ? "€/m²"
                  : selected === INVOICING
                  ? "€"
                  : "m²"
                : "%"
            }`;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title="Objectives"
      chart={<Bar data={chartData} options={chartOptions} />}
    >
      <Grid
        item
        container
        margin={2}
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item>
          <Switch
            checked={showAll}
            onChange={() => setShowAll(!showAll)}
            color="primary"
          />
        </Grid>
        <Grid item>
          <Typography variant="h7">{t("showAll")}</Typography>
        </Grid>
      </Grid>
      <Grid item container margin={2} justifyContent="space-between">
        <Grid item>
          <ToggleButtonGroup exclusive value={selected}>
            <ToggleButton
              value={OCCUPANCY}
              onClick={() => setSelected(OCCUPANCY)}
            >
              <Tooltip title={t("occupancy")}>
                <PercentIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value={OCCUPIED_METERS}
              onClick={() => setSelected(OCCUPIED_METERS)}
            >
              <Tooltip title={t("occupiedMeters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value={PRICE_PER_METER}
              onClick={() => setSelected(PRICE_PER_METER)}
            >
              <Tooltip title={t("pricePerMeter")}>
                <AttachMoneyIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value={INVOICING}
              onClick={() => setSelected(INVOICING)}
            >
              <Tooltip title={t("invoicing")}>
                <ReceiptLongIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item marginRight={4}>
          <ToggleButtonGroup exclusive value={type}>
            <ToggleButton value={METERS} onClick={() => setType(METERS)}>
              <Tooltip title={t("value")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value={PERCENTAGE}
              onClick={() => setType(PERCENTAGE)}
            >
              <Tooltip title={t("percentage")}>
                <PercentIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

const getDataArray = (items, key, condition) => {
  const data = items.filter((item) => {
    if (condition) return condition(item);
    return true;
  });
  return data.map((item) => item[key]);
};

const getDataSet = (label, items, config) => {
  const { dataKey, ...rest } = config;

  return {
    label: label,
    data: getDataArray(items, config.dataKey, config.dataCondition),
    ...rest,
  };
};

const getAverage = (items, key, condition) => {
  const data = items.filter((item) => {
    if (condition) return condition(item);
    return true;
  });
  if (!data.length) return 0;
  return sumArray(data.map((item) => item[key])) / data.length;
};

const LegendItem = ({ color, label }) => {
  return (
    <Box display="flex" alignItems="center" marginBottom={1}>
      <Box
        width={16}
        height={16}
        bgcolor={color}
        marginRight={1}
        borderRadius={1}
      />
      <Typography variant="body2">{label}</Typography>
    </Box>
  );
};

// Contracts tab charts

export const CenterContractsScatter = ({ centers }) => {
  const [t] = useTranslation("dashboard");
  let dataArray = [];

  centers.forEach((center) => {
    const allContracts = center.allContracts;

    const averageContractDuration = allContracts.length
      ? allContracts.reduce((acc, contract) => {
          const startDate = new Date(contract.startDate).getTime();
          const endDate = (
            contract.endDate ? new Date(contract.endDate) : new Date()
          ).getTime();
          return acc + (endDate - startDate);
        }, 0) / allContracts.length
      : 0;

    dataArray.push({
      x: new Date(center.openingDate).getTime(),
      y: averageContractDuration,
      centerName: center.name,
    });
  });

  dataArray = dataArray.filter((c) => c.x > 0 && c.y > 0);

  const data = {
    datasets: [
      {
        label: "Sample Data",
        data: [...dataArray],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        pointRadius: 6,
        hoverRadius: 7,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: t("openingDate"),
        },
        ticks: {
          callback: function (value) {
            return new Date(value).getFullYear();
          },
        },
      },
      y: {
        title: {
          display: true,
          text: t("averageContractDuration") + " (" + t("days") + ")",
        },
        ticks: {
          callback: function (value) {
            return Math.floor(value / (1000 * 60 * 60 * 24));
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${
              dataArray[tooltipItem.dataIndex].centerName
            } (${formatDate(
              new Date(dataArray[tooltipItem.dataIndex].x)
            )}): ${Math.floor(
              dataArray[tooltipItem.dataIndex].y / (1000 * 60 * 60 * 24)
            )} ${t("days")}`;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("centerContractsDuration")}
      chart={<Scatter data={data} options={options} />}
    />
  );
};
