import { useEffect, useContext, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import {
  Button,
  Collapse,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import AppContext from "../../../context/AppContext";
import TransferList from "../../Inputs/TransferList";
import TextInput from "../../Inputs/TextInput";
import Select from "../../global/inputs/Select";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_FORM":
      return { ...state, form: action.payload };
    case "SET_USERS_RIGHT":
      return { ...state, usersRight: action.payload };
    case "SET_USERS_LEFT":
      return { ...state, usersLeft: action.payload };
    case "SET_ROLES_RIGHT":
      return { ...state, rolesRight: action.payload };
    case "SET_ROLES_LEFT":
      return { ...state, rolesLeft: action.payload };
    case "SET_SHOW_USERS_PERMISSIONS":
      return { ...state, showUsersPermisions: !state.showUsersPermisions };
    case "SET_SHOW_ROLES_PERMISSIONS":
      return { ...state, showRolesPermisions: !state.showRolesPermisions };
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    default:
      throw new Error("Action not found in reducer.");
  }
};

const initialState = {
  form: {
    id: "",
    name: "",
    parentId: "",
  },

  usersRight: [],
  usersLeft: [],

  rolesRight: [],
  rolesLeft: [],

  showUsersPermisions: false,
  showRolesPermisions: false,
};

const DirectoryForm = (props) => {
  const { api, user } = useContext(AppContext);
  const { data, isNew, open, onClose, onSubmit, users, roles, directories } =
    props;
  const [t] = useTranslation("documents");
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "SET_FORM", payload: data });
    getTransferListUsers(data?.Users);
    getTransferListRoles(data?.Roles);
  }, [data]);

  const getTransferListUsers = (data) => {
    let right = [];
    let left = [];

    //Get right users' names (if exist):
    if (data)
      data.forEach((user) => {
        right.push(user.name);
      });

    //Get left users' names:
    users.forEach((user) => {
      left.push(user.name);
    });

    //Filter left users (if is necessary):
    if (data)
      right.forEach((userRight) => {
        left = left.filter((userLeft) => userLeft != userRight);
      });

    //Set users:
    dispatch({ type: "SET_USERS_RIGHT", payload: right });
    dispatch({ type: "SET_USERS_LEFT", payload: left });
  };

  const getTransferListRoles = (data) => {
    let right = [];
    let left = [];

    //Get right roles' names (if exist):
    if (data)
      data.forEach((role) => {
        right.push(role.name);
      });

    //Get left roles' names:
    roles.forEach((role) => {
      left.push(role.name);
    });

    //Filter left roles (if is necessary):
    if (data)
      right.forEach((roleRight) => {
        left = left.filter((roleLeft) => roleLeft != roleRight);
      });

    //Set roles :
    dispatch({ type: "SET_ROLES_LEFT", payload: left });
    dispatch({ type: "SET_ROLES_RIGHT", payload: right });
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  const setUsersRight = (usersRight) => {
    const usersToAdd = not(usersRight, state.usersRight);

    if (usersToAdd.length > 0 && !isNew) {
      let idUsersToAdd = [];
      usersToAdd.forEach((toAdd) => {
        idUsersToAdd.push(users.filter((user) => user.name == toAdd));
      });

      api
        .post("/directories/" + state.form.id + "/user-add", idUsersToAdd)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_USERS_RIGHT", payload: usersRight });
            enqueueSnackbar("permissionAdded", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else dispatch({ type: "SET_USERS_RIGHT", payload: usersRight });
  };

  const setUsersLeft = (usersLeft) => {
    const usersToDelete = not(usersLeft, state.usersLeft);

    if (usersToDelete.length > 0 && !isNew) {
      let idUsersToDelete = [];
      usersToDelete.forEach((toDelete) => {
        idUsersToDelete.push(
          users.filter((user) => user.name == toDelete)[0].id
        );
      });

      api
        .post("/directories/" + state.form.id + "/user-delete", idUsersToDelete)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_USERS_LEFT", payload: usersLeft });
            enqueueSnackbar("permissionDeleted", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else dispatch({ type: "SET_USERS_LEFT", payload: usersLeft });
  };

  const setRolesRight = (rolesRight) => {
    const rolesToAdd = not(rolesRight, state.rolesRight);

    if (rolesToAdd.length > 0 && !isNew) {
      let idRolesToAdd = [];
      rolesToAdd.forEach((toAdd) => {
        idRolesToAdd.push(roles.filter((role) => role.name == toAdd));
      });

      api
        .post("/directories/" + state.form.id + "/role-add", idRolesToAdd)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_ROLES_RIGHT", payload: rolesRight });
            enqueueSnackbar("permissionAdded", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else dispatch({ type: "SET_ROLES_RIGHT", payload: rolesRight });
  };

  const setRolesLeft = (rolesLeft) => {
    const rolesToDelete = not(rolesLeft, state.rolesLeft);

    if (rolesToDelete.length > 0 && !isNew) {
      let idRolesToDelete = [];
      rolesToDelete.forEach((toDelete) => {
        idRolesToDelete.push(
          roles.filter((role) => role.name == toDelete)[0].id
        );
      });

      api
        .post("/directories/" + state.form.id + "/role-delete", idRolesToDelete)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_ROLES_LEFT", payload: rolesLeft });
            enqueueSnackbar("permissionDeleted", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else dispatch({ type: "SET_ROLES_LEFT", payload: rolesLeft });
  };

  const getPermissions = (e) => {
    const parentId = e.target.value;
    parentId &&
      api
        .get("/directories/" + parentId)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            //Get user and role permissions from parent
            const parentUserPermissions = response.data.Users || [];
            const parentRolePermissions = response.data.Roles || [];
            //Update users permissions
            const usersRight = parentUserPermissions.map((user) => user.name);
            dispatch({
              type: "SET_USERS_RIGHT",
              payload: usersRight,
            });

            const usersLeft = users
              .filter(
                (user) =>
                  !parentUserPermissions.find(
                    (userRight) => userRight.id === user.id
                  )
              )
              .map((userLeft) => userLeft.name);
            dispatch({
              type: "SET_USERS_LEFT",
              payload: usersLeft,
            });
            //Update roles permissions
            const rolesRight = parentRolePermissions.map((role) => role.name);
            dispatch({
              type: "SET_ROLES_RIGHT",
              payload: rolesRight,
            });

            const rolesLeft = roles
              .filter(
                (role) =>
                  !parentRolePermissions.find(
                    (roleRight) => roleRight.id === role.id
                  )
              )
              .map((roleLeft) => roleLeft.name);
            dispatch({
              type: "SET_ROLES_LEFT",
              payload: rolesLeft,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    if (!parentId) {
      dispatch({ type: "SET_USERS_RIGHT", payload: [] });
      dispatch({
        type: "SET_USERS_LEFT",
        payload: users.map((user) => user.name),
      });
      dispatch({ type: "SET_ROLES_RIGHT", payload: [] });
      dispatch({
        type: "SET_ROLES_LEFT",
        payload: roles.map((role) => role.name),
      });
    }
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const closeForm = () => {
    onClose();
    dispatch({ type: "RESET_FORM" });
  };

  return (
    <Dialog open={open} onClose={closeForm}>
      <DialogTitle id="form-dialog-title">
        <Grid container justifyContent="space-between">
          <Grid item>
            {isNew ? t("create") : t("edit")} {t("directory").toLowerCase()}
          </Grid>
          <Grid item>
            <IconButton onClick={closeForm}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <TextInput
                label={t("name")}
                name="name"
                onChange={handleInputChange}
                value={state.form.name}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label={t("directory")}
                name="parentId"
                value={state.form.parentId}
                options={[
                  { value: null, label: t("-") },
                  ...directories.map((dir) => ({
                    value: dir.id,
                    label: dir.fullPath,
                    disabled: dir.id === state.form.id,
                  })),
                ]}
                onChange={(e) => {
                  handleInputChange(e);
                  getPermissions(e);
                }}
                searchable
              />
            </Grid>

            {(user.hasAction("VIEW_USERS") || user.hasAction("VIEW_ROLES")) && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}

            {user.hasAction("VIEW_USERS") && (
              <Grid item xs={12}>
                <Button
                  fullWidth={true}
                  endIcon={
                    state.showUsersPermisions ? <ExpandLess /> : <ExpandMore />
                  }
                  onClick={() =>
                    dispatch({ type: "SET_SHOW_USERS_PERMISSIONS" })
                  }
                >
                  {t("manageUsersPermissions")}
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <Collapse orientation={"vertical"} in={state.showUsersPermisions}>
                <Grid item>
                  <TransferList
                    left={state.usersLeft.sort((a, b) => a.localeCompare(b))}
                    right={state.usersRight.sort((a, b) => a.localeCompare(b))}
                    setRight={setUsersRight}
                    setLeft={setUsersLeft}
                  />
                </Grid>
              </Collapse>
            </Grid>

            {user.hasAction("VIEW_ROLES") && (
              <Grid item xs={12}>
                <Button
                  fullWidth={true}
                  endIcon={
                    state.showRolesPermisions ? <ExpandLess /> : <ExpandMore />
                  }
                  onClick={() =>
                    dispatch({ type: "SET_SHOW_ROLES_PERMISSIONS" })
                  }
                >
                  {t("manageRolesPermissions")}
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Collapse orientation={"vertical"} in={state.showRolesPermisions}>
                <Grid item>
                  <TransferList
                    left={state.rolesLeft.sort((a, b) => a.localeCompare(b))}
                    right={state.rolesRight.sort((a, b) => a.localeCompare(b))}
                    setRight={setRolesRight}
                    setLeft={setRolesLeft}
                  />
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeForm}>{t("cancel")}</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (isNew) {
              let idUsersToAdd = [];
              state.usersRight.forEach((toAdd) => {
                idUsersToAdd.push(users.filter((user) => user.name == toAdd));
              });

              let idRolesToAdd = [];
              state.rolesRight.forEach((toAdd) => {
                idRolesToAdd.push(roles.filter((role) => role.name == toAdd));
              });

              onSubmit(state.form, idUsersToAdd, idRolesToAdd);
            } else onSubmit(state.form);

            dispatch({ type: "RESET_FORM" });
          }}
          disabled={state.form.name === ""}
        >
          {isNew ? t("create") : t("edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DirectoryForm;
