import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import TileListItem from "./ListItems/TileListItem";
import { FloorPlanContext } from "../FloorPlanPage";
import CreateItemDialog from "./Dialogs/CreateItemDialog";

const LayerEditor = (props) => {
  const { layer } = props;
  const [t] = useTranslation("floorPlans");
  const { selectTile, deleteTile, createTile } =
    useContext(FloorPlanContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Typography variant="h6">{t("tiles")}</Typography>
        <Button
          size="small"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsDialogOpen(true);
          }}
          sx={{ marginLeft: 2 }}
        >
          {t("addTile")}
        </Button>
      </Box>
      <Stack divider={<Divider />}>
        {layer.FloorPlanTiles.map((tile) => (
          <TileListItem
            key={tile.id}
            tile={tile}
            onSelect={() => selectTile(tile.id)}
            onDelete={(e) => {
              e.stopPropagation();
              deleteTile(tile.id);
            }}
          />
        ))}
      </Stack>
      <CreateItemDialog
        isOpen={isDialogOpen}
        selectedLayerId={layer.id}
        onCreateItem={(tile) => {
          createTile(tile);
          setIsDialogOpen(false);
        }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
    </Box>
  );
};

export default LayerEditor;
