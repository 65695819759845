import React, { useEffect } from "react";
import * as d3 from "d3";

function Treemap({ data = {}, options = {} }) {
  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    const container = d3.select("#my_dataviz");
    const containerNode = container.node();
    const containerWidth = containerNode.getBoundingClientRect().width;
    const containerHeight = containerNode.getBoundingClientRect().height;

    const margin = { top: 10, right: 10, bottom: 10, left: 10 },
      width = containerWidth - margin.left - margin.right,
      height = 600;

    container.selectAll("*").remove(); // Limpiar todos los elementos del contenedor

    // Crear el SVG y el grupo
    const svg = container
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //const root = d3.hierarchy(data).sum((d) => d.value);
    const root = d3
      .treemap()
      .tile(d3.treemapBinary)
      .size([width, height])
      .padding(2)(d3.hierarchy(data).sum((d) => d.value));

    // Añadir rectángulos al gráfico
    svg
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("rect")
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .style("stroke", "black")
      .style("fill", (d) => d.data.color || "slateblue");

    // Añadir etiquetas de texto
    svg
      .selectAll("text.first-line")
      .data(root.leaves())
      .enter()
      .append("text")
      .attr("x", (d) => d.x0 + 5)
      .attr("y", (d) => d.y0 + 12)
      .text((d) => {
        const rectWidth = d.x1 - d.x0;
        const text = d.data.name || "";
        const maxChars = Math.floor(rectWidth / 6.2);

        const splitIndex = text.search(/[\d]/);
        const firstLine = splitIndex > 0 ? text.slice(0, splitIndex) : text;

        return firstLine.length > maxChars
          ? firstLine.substring(0, maxChars - 3) + "..."
          : firstLine;
      })
      .attr("font-size", "12px")
      .attr("fill", "white")
      .attr("display", (d) => {
        const rectWidth = d.x1 - d.x0;
        const rectHeight = d.y1 - d.y0;
        return rectWidth < 20 || rectHeight < 20 ? "none" : "block";
      });

    svg
      .selectAll("text.second-line")
      .data(root.leaves())
      .enter()
      .append("text")
      .attr("x", (d) => d.x0 + 5)
      .attr("y", (d) => d.y0 + 25)
      .text((d) => {
        const rectWidth = d.x1 - d.x0;
        const text = d.data.name || "";
        const maxChars = Math.floor(rectWidth / 6.2);

        const splitIndex = text.search(/[\d]/);
        const secondLine = splitIndex > 0 ? text.slice(splitIndex) : "";

        return secondLine.length > maxChars
          ? secondLine.substring(0, maxChars - 3) + "..."
          : secondLine;
      })
      .attr("font-size", "12px")
      .attr("fill", "white")
      .attr("display", (d) => {
        const rectWidth = d.x1 - d.x0;
        const rectHeight = d.y1 - d.y0;
        return rectWidth < 20 || rectHeight < 20 ? "none" : "block";
      });
  }, [data]);

  return <div id="my_dataviz" style={{ width: "100%", height: "100%" }}></div>;
}

export default Treemap;
