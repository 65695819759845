import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Alert,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import AddIcon from "@mui/icons-material/Add";
import CreditCardForm from "../../CreditCardForm";
import CustomSelect from "../../Inputs/CustomSelect";
import InvoiceItems from "./InvoiceItems";
import TextInput from "../../Inputs/TextInput";

import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../../ConfirmDialog";
import CustomButton from "../../Inputs/CustomButton";
import ItemsSummary from "../../ItemsSummary";
import { localeFormat } from "../../../utils/format";
import AddMerchantableDialog from "../../Inputs/AddMerchantableDialog";
import LabeledText from "../../global/LabeledText";
import { today } from "../../../utils/date";
import _ from "lodash";

import {
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
} from "../../../data/constants";

const TODAY = today();

const initialState = {
  form: {
    number: "",
    serieId: "0",
    dueDate: TODAY,
    customer: null,
    customerName: "",
    customerNif: "",
    customerAddress: "",
    issuingName: "Necesito Un Trastero S.L",
    issuingNif: "B66604075",
    issuingAddress: "Av. Via Augusta, 15",
    items: [],
    billingAddress: "",
    issuerId: "",
    paymentMethodId: "",
    paycardId: "",
    ibanId: "",
  },
  inputError: {
    number: false,
    serieId: false,
    dueDate: false,
    customer: false,
    customerName: false,
    customerNif: false,
    customerAddress: false,
    issuingNif: false,
    issuingAddress: false,
    billingAddress: false,
  },
  boxes: [],
  billingAddresses: [],
  cards: [],
  cardModalIsOpen: false,
  centers: [],
  customers: [],
  ibans: [],
  id: null,
  issuers: [],
  filteredBoxes: [],

  merchantables: [],
  merchDialogAddButtonDisabled: false,
  merchDialogIsOpen: false,
  merchFilterBox: [],
  merchFilterCenter: [],
  merchfilterConcept: "",
  merchFilterContract: "",
  merchFilterCustomer: "",
  merchFilterFromDate: "",
  merchFilterInvoiced: "no",
  merchFilterType: "",
  merchFilterUntilDate: "",
  merchLoading: false,

  addIbanLoading: false,
  newIban: "",
  series: [],

  submitFormLoading: false,

  confirmDialog: {
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "RESET":
      return { ...state, form: initialState.form };
    case "SET_ITEMS":
      return {
        ...state,
        form: {
          ...state.form,
          items: action.payload.items,
        },
      };
    case "SET_INVOICE":
      return { ...state, form: action.payload.invoice };
    case "SET_SERIES":
      return { ...state, series: action.payload.series };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_FILTERED_BOXES":
      return { ...state, filteredBoxes: action.payload };
    case "SET_ISSUER":
      return {
        ...state,
        form: {
          ...state.form,
          issuingName: action.payload.issuingName,
          issuingNif: action.payload.issuingNif,
          issuingAddress: action.payload.issuingAddress,
        },
      };
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "SET_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          customerNif: action.payload.nif,
          customerAddress: action.payload.address,
        },
        billingAddresses: action.payload.billingAddresses,
      };
    case "OPEN_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: true };
    case "CLOSE_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: false };
    case "SET_FORMS_OF_PAY":
      return {
        ...state,
        ibans: action.payload.ibans,
        cards: action.payload.cards,
      };
    case "SET_SUBMIT_FORM_LOADING":
      return { ...state, submitFormLoading: action.payload };
    case "OPEN_MERCH_DIALOG":
      return { ...state, merchDialogIsOpen: true };
    case "CLOSE_MERCH_DIALOG":
      return {
        ...state,
        merchDialogIsOpen: false,
        merchantables: initialState.merchantables,
        merchFilterBox: initialState.merchFilterBox,
        merchFilterCenter: initialState.merchFilterCenter,
        merchFilterContract: initialState.merchFilterContract,
        merchFilterInvoiced: initialState.merchFilterInvoiced,
        merchFilterFromDate: initialState.merchFilterFromDate,
        merchFilterUntilDate: initialState.merchFilterUntilDate,
        merchFilterType: initialState.merchFilterType,
      };
    case "SET_MERCH_DIALOG_FILTER":
      return { ...state, [action.payload.inputname]: action.payload.value };
    case "SET_MERCH_LOADING_TRUE":
      return { ...state, merchLoading: true };
    case "SET_MERCH_LOADING_FALSE":
      return {
        ...state,
        merchLoading: false,
      };
    case "SET_MERCH_ADD_BUTTON_DISABLED_TRUE":
      return {
        ...state,
        merchDialogAddButtonDisabled: true,
      };
    case "SET_MERCH_ADD_BUTTON_DISABLED_FALSE":
      return {
        ...state,
        merchDialogAddButtonDisabled: false,
      };
    case "SET_MERCHANTABLES":
      return { ...state, merchantables: action.payload };
    case "SET_ADD_IBAN_LOADING_TRUE":
      return { ...state, addIbanLoading: true };
    case "SET_ADD_IBAN_LOADING_FALSE":
      return { ...state, addIbanLoading: false };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CreateInvoicePage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [counter, setCounter] = useState(1);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("invoices");
  const [tErrors] = useTranslation("errors");
  const query = useQuery();
  const queryCustomerId = Number(query.get("customerId"));

  useEffect(() => {
    document.title = t("createInvoicePage");

    getIssuers();
    getInvoiceSeries();

    dispatch({ type: "RESET" });
  }, []);

  useEffect(() => {
    if (queryCustomerId) {
      getCustomer(queryCustomerId);
    }
  }, [queryCustomerId]);

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handlerDates = () => {
    if (!state.form.dueDate || TODAY <= state.form.dueDate) return false;
    return true;
  };

  const handleInputChangeIssuer = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });

    if (e.target.value !== "") {
      const issuer = state.issuers.find((item) => item.id === e.target.value);

      dispatch({
        type: "SET_ISSUER",
        payload: {
          issuingName: issuer.issuerName,
          issuingNif: issuer.nif,
          issuingAddress: issuer.address,
        },
      });
    } else {
      dispatch({
        type: "SET_ISSUER",
        payload: {
          issuingName: "",
          issuingNif: "",
          issuingAddress: "",
        },
      });
    }
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_SERIES", payload: { series: response.data } });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ISSUERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getCustomer = (customerId) => {
    const params = { include: ["BillingAddress", "PayCard", "IBAN"] };

    api
      .get(`/customers/${customerId}`, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          setCustomer(response.data);
          handleInputChange({
            target: { value: response.data, name: "customer" },
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getCustomers = (customerName) => {
    let params = {
      include: ["BillingAddress", "PayCard", "IBAN"],
      name: customerName,
    };
    api
      .get("/customers", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const submitForm = () => {
    if (validateForm()) {
      dispatch({ type: "SET_SUBMIT_FORM_LOADING", payload: true });
      let data = {};
      state.form.serieId !== "" && (data.serieId = state.form.serieId);
      data.issueDate = TODAY;
      state.form.dueDate !== "" && (data.dueDate = state.form.dueDate);
      state.form.customer !== "" &&
        (data.customerName = state.form.customer.fullName); //grab name from customer obj
      state.form.customer !== "" && (data.customerId = state.form.customer.id); //grab name from customer obj
      state.form.customerNif !== "" &&
        (data.customerNif = state.form.customerNif);
      state.form.customerAddress !== "" &&
        (data.customerAddress = state.form.customerAddress);
      state.form.issuerId !== "" && (data.issuerId = state.form.issuerId);
      state.form.issuingName !== "" &&
        (data.issuerName = state.form.issuingName);
      state.form.issuingNif !== "" && (data.issuerNif = state.form.issuingNif);
      state.form.issuingAddress !== "" &&
        (data.issuerAddress = state.form.issuingAddress);
      state.form.billingAddress !== "" &&
        (data.billingAddress = state.form.billingAddress);
      state.form.paymentMethodId !== "" &&
        (data.paymentMethodId = state.form.paymentMethodId);
      state.form.paycardId !== "" && (data.paycardId = state.form.paycardId);
      state.form.ibanId !== "" && (data.ibanId = state.form.ibanId);
      if (state.form.items && state.form.items.length)
        data.items = state.form.items.map((item) => {
          const invoiceItem = {
            concept: item.concept,
            units: item.units,
            pricePerUnit: item.pricePerUnit,
            taxPercentage: item.taxPercentage,
            merchantableId: item.merchantableId,
            invoiceId: item.invoiceId,
            orderIndex: item.orderIndex,
          };
          return invoiceItem;
        });

      api
        .post("/invoices/create", data)
        .then((response) => {
          dispatch({ type: "SET_SUBMIT_FORM_LOADING", payload: false });
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("invoiceCreateSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error, { variant: "error" });
          dispatch({ type: "SET_SUBMIT_FORM_LOADING", payload: false });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = [
      "serieId",
      "issuingNif",
      "issuingAddress",
      "customerNif",
      "customerAddress",
      "customer",
      "dueDate",
      "paymentMethodId",
    ];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    if (handlerDates()) {
      enqueueSnackbar(t("issueDateLargerThanDueDateWarning"), {
        variant: "error",
      });
      isValid = false;
    }

    if (state.form.serieId === "0") {
      setInputErrorTrue("serieId");
    }

    return isValid;
  };

  const setItems = (items) => {
    dispatch({
      type: "SET_ITEMS",
      payload: {
        items: items,
      },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const setCustomer = (customer) => {
    if (customer) {
      dispatch({
        type: "SET_CUSTOMER",
        payload: {
          nif: customer.nif,
          address: customer.address,
          billingAddresses: customer.BillingAddresses,
        },
      });
      dispatch({
        type: "SET_FORMS_OF_PAY",
        payload: {
          cards: customer.PayCards,
          ibans: customer.IBANs,
        },
      });
    }
  };

  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  const handleOpenCardModal = () => {
    dispatch({ type: "OPEN_PAYCARD_MODAL" });
  };

  const handleCloseCardModal = () => {
    dispatch({ type: "CLOSE_PAYCARD_MODAL" });
  };

  const submitCardForm = (card) => {
    if (state.form.customer != null) {
      let form = {
        name: card.name,
        number: card.number,
        cvv: card.cvc,
        expirationMonth: card.expiry.substring(0, 2),
        expirationYear: card.expiry.substring(3),
        customerId: state.form.customer.id,
      };

      api
        .post("/pay-cards/create", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("cardCreated"), { variant: "success" });

            dispatch({
              type: "SET_FORMS_OF_PAY",
              payload: {
                cards: [...state.cards, response.data],
                ibans: state.ibans,
              },
            });

            handleCloseCardModal();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error, { variant: "error" });
        });
    } else enqueueSnackbar(t("selectCustomerWarning"), { variant: "warning" });
  };

  const handleInputChangeIban = (e) => {
    dispatch({ type: "SET_NEW_IBAN", payload: e.target.value });
  };

  const createIban = (number) => {
    if (state.form.customer !== null) {
      const ibanRepeated = state.ibans.some((iban) => iban.number === number);

      if (!ibanRepeated) {
        dispatch({ type: "SET_ADD_IBAN_LOADING_TRUE" });
        let data = {};
        data.number = number;
        data.customerId = state.form.customer.id;

        api
          .post("/ibans/create", data)
          .then((response) => {
            dispatch({ type: "SET_ADD_IBAN_LOADING_FALSE" });
            dispatch({ type: "SET_NEW_IBAN", payload: "" });
            if (response.data.error) {
              enqueueSnackbar(response.data.error, { variant: "error" });
            } else {
              enqueueSnackbar(t("ibanCreated"), { variant: "success" });
              dispatch({
                type: "SET_FORMS_OF_PAY",
                payload: {
                  cards: state.cards,
                  ibans: [...state.ibans, response.data.iban],
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(error, { variant: "error" });
          });
      } else {
        enqueueSnackbar(t("ibanRepeated"), { variant: "error" });
      }
    } else enqueueSnackbar(t("selectCustomer"), { variant: "warning" });
  };

  const MERCHANTABLE_DIALOG_COLUMNS = [
    { key: "startDate", label: t("startDate") },
    { key: "endDate", label: t("endDate") },
    { key: "concept", label: t("concept") },
    { key: "MerchantableType.name", label: t("type"), sortType: "string" },
    {
      key: "units",
      label: t("units"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value),
    },
    {
      key: "pricePerUnit",
      label: t("pricePerUnit"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "baseAmount",
      label: t("baseAmount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "vatPercentage", label: t("vatPercentage"), sortType: "number" },
    {
      key: "totalAmount",
      label: t("totalAmount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "InvoiceItems.length",
      label: t("invoiced"),
      sortType: "other",
      renderFunction: (value) => (value > 0 ? t("invoiced") : t("toInvoice")),
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => {
        return (
          <CustomButton
            loading={state.merchDialogAddButtonDisabled}
            loadingText=""
            onClick={() => {
              dispatch({ type: "SET_MERCH_ADD_BUTTON_DISABLED_TRUE" });
              const merchantable = {
                concept: item.concept,
                units: item.units,
                pricePerUnit: item.pricePerUnit,
                taxPercentage: item.vatPercentage,
                merchantableId: item.id,
                orderIndex: counter,
                base: item.units * item.pricePerUnit,
                total:
                  item.units *
                  item.pricePerUnit *
                  (1 + item.vatPercentage / 100),
              };
              setItems(state.form.items.concat(merchantable));
              dispatch({ type: "SET_MERCH_ADD_BUTTON_DISABLED_FALSE" });
              enqueueSnackbar(t("merchantableAdded"), { variant: "success" });
              setCounter(counter + 1);
            }}
            icon={<AddIcon />}
          >
            {t("add")}
          </CustomButton>
        );
      },
    },
  ];

  const openMerchDialog = () => {
    dispatch({ type: "OPEN_MERCH_DIALOG" });
  };

  const closeMerchDialog = () => {
    dispatch({ type: "CLOSE_MERCH_DIALOG" });
  };

  const setMerchFilter = (e) => {
    dispatch({
      type: "SET_MERCH_DIALOG_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const deleteMerchantable = (item) => {
    const invoiceItems = state.form.items.filter((arrItem) => arrItem !== item);
    setItems(invoiceItems);
    enqueueSnackbar(t("invoiceItemDeleted"), { variant: "success" });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirmDeleteMerchantable = (merchantable) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        childrenText: merchantable.concept,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteMerchantable(merchantable);
          resetConfirmDialog();
        },
      },
    });
  };

  /* Debounced customer search */
  const debouncedCustomerSearch = useMemo(() => {
    const debouncedFn = _.debounce((customerName) => {
      getCustomers(customerName);
    }, 300);

    return debouncedFn;
  }, [getCustomers]);

  const handleCustomerSearch = useCallback(
    (customerName) => {
      if (customerName.length > 3) debouncedCustomerSearch(customerName);
    },
    [debouncedCustomerSearch]
  );

  // Clean up
  useEffect(() => {
    return () => {
      debouncedCustomerSearch.cancel();
    };
  }, [debouncedCustomerSearch]);

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createInvoice")}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={handlerDates()}>
                <Alert severity="warning">
                  {t("issueDateLargerThanDueDateWarning")}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item container spacing={2} alignItems={"center"}>
              <Grid item>
                <CustomSelect
                  error={state.inputError.serieId}
                  helperText={
                    state.inputError.serieId
                      ? t("invoiceType") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.serieId}
                  name="serieId"
                  onChange={(e) => {
                    setInputErrorFalse("serieId");
                    handleInputChange(e);
                  }}
                  label={t("serie")}
                  options={[
                    { value: "0", label: t("none") },
                    ...state.series.map((serie) => ({
                      value: serie.id,
                      label: serie.name,
                    })),
                  ]}
                />
              </Grid>
              <Grid item>
                <LabeledText label={t("issueDate")} value={TODAY} />
              </Grid>

              <Grid item>
                <TextInput
                  error={state.inputError.dueDate}
                  helperText={
                    state.inputError.dueDate
                      ? t("dueDate") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("dueDate")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.form.dueDate}
                  onChange={(e) => {
                    setInputErrorFalse("dueDate");
                    handleInputChange(e);
                  }}
                  name="dueDate"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("customerInfo")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    sx={{ minWidth: "210px" }}
                    options={state.customers}
                    getOptionKey={(customer) => customer.id}
                    getOptionLabel={(customer) => customer.fullName || ""}
                    filterOptions={filterOptions}
                    value={state.form.customer}
                    onChange={(e, customer) => {
                      setInputErrorFalse("customer");
                      setCustomer(customer);
                      handleInputChange({
                        target: { value: customer, name: "customer" },
                      });
                    }}
                    name="customer"
                    renderInput={(params) => {
                      return (
                        <TextInput
                          {...params}
                          onChange={(e) => handleCustomerSearch(e.target.value)}
                          label={t("customerName")}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item>
                  <CustomSelect
                    error={state.inputError.billingAddress}
                    helperText={
                      state.inputError.billingAddress
                        ? t("billingAddress") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    value={state.form.billingAddress}
                    name="billingAddress"
                    onChange={(e) => {
                      setInputErrorFalse("billingAddress");
                      handleInputChange(e);
                    }}
                    label={t("billingAddress")}
                    options={[
                      { value: "0", label: t("none") },
                      ...state.billingAddresses?.map((address) => ({
                        value: address.id,
                        label: address.address,
                      })),
                    ]}
                  />
                </Grid>

                <Grid item>
                  <TextInput
                    error={state.inputError.customerNif}
                    helperText={
                      state.inputError.customerNif
                        ? t("customerNif") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    variant="outlined"
                    label={t("customerNif")}
                    value={state.form.customerNif}
                    onChange={(e) => {
                      setInputErrorFalse("customerNif");
                      handleInputChange(e);
                    }}
                    name="customerNif"
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    error={state.inputError.customerAddress}
                    helperText={
                      state.inputError.customerAddress
                        ? t("customerAddress") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    variant="outlined"
                    label={t("customerAddress")}
                    value={state.form.customerAddress}
                    onChange={(e) => {
                      setInputErrorFalse("customerAddress");
                      handleInputChange(e);
                    }}
                    name="customerAddress"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("issuerInfo")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomSelect
                    name="issuerId"
                    value={state.form.issuerId}
                    onChange={handleInputChangeIssuer}
                    label={t("issuer")}
                    options={state.issuers.map((issuer) => ({
                      value: issuer.id,
                      label: issuer.name,
                    }))}
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    disabled
                    label={t("issuingName")}
                    value={state.form.issuingName}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="issuingName"
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    error={state.inputError.issuingNif}
                    helperText={
                      state.inputError.issuingNif
                        ? t("issuingNif") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    disabled
                    label={t("issuingNif")}
                    value={state.form.issuingNif}
                    onChange={(e) => {
                      setInputErrorFalse("issuingNif");
                      handleInputChange(e);
                    }}
                    name="issuingNif"
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    error={state.inputError.issuingAddress}
                    helperText={
                      state.inputError.issuingAddress
                        ? t("issuingAddress") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    disabled
                    label={t("issuingAddress")}
                    value={state.form.issuingAddress}
                    onChange={(e) => {
                      setInputErrorFalse("issuingAddress");
                      handleInputChange(e);
                    }}
                    name="issuingAddress"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="paymentMethodId"
                value={Number(state.form.paymentMethodId)}
                onChange={(e) => {
                  e.target.value = Number(e.target.value);
                  handleInputChange(e);
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("counted")}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={t("receipt")}
                />
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label={t("card")}
                />
                <FormControlLabel
                  value={TOKENIZED_PAYCARD_PAYMENT_METHOD_ID}
                  control={<Radio />}
                  label={t("tokenizedCard")}
                />
                <FormControlLabel
                  value={4}
                  control={<Radio />}
                  label={t("transfer")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {Number(state.form.paymentMethodId) === RECEIPT_PAYMENT_METHOD_ID && (
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <CustomSelect
                  value={state.form.ibanId}
                  name="ibanId"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  label={t("IBANs")}
                  options={[
                    { value: "", label: t("none") },
                    ...state.ibans.map((iban) => ({
                      value: iban.id,
                      label: iban.number,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label={t("newIban")}
                  name="newIban"
                  value={state.newIban}
                  onChange={(e) => {
                    handleInputChangeIban(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomButton
                  loading={state.addIbanLoading}
                  loadingText=""
                  onClick={() => {
                    createIban(state.newIban);
                  }}
                >
                  {t("add")}
                </CustomButton>
              </Grid>
            </Grid>
          )}

          {Number(state.form.paymentMethodId) === PAYCARD_PAYMENT_METHOD_ID && (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  value={state.form.paycardId}
                  name="paycardId"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  label={t("card")}
                  options={[
                    { value: "", label: t("none") },
                    ...state.cards?.map((card) => ({
                      value: card.id,
                      label: card.number,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button variant="contained" onClick={handleOpenCardModal}>
                  {t("addCreditCard")}
                </Button>
              </Grid>

              {/* Credit card modal */}
              <Dialog
                open={state.cardModalIsOpen}
                onClose={handleCloseCardModal}
              >
                <DialogTitle>
                  <Grid container justifyContent="space-between">
                    <Grid item>{t("addCreditCard")}</Grid>
                    <Grid item>
                      <IconButton onClick={handleCloseCardModal}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <CreditCardForm onSubmit={submitCardForm} />
                </DialogContent>
              </Dialog>
            </Grid>
          )}

          {Number(state.form.paymentMethodId) ===
            TOKENIZED_PAYCARD_PAYMENT_METHOD_ID && (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  value={state.form.paycardId}
                  name="paycardId"
                  onChange={handleInputChange}
                  label={t("tokenizedCard")}
                  options={[
                    { value: "", label: t("none") },
                    ...state.cards
                      .filter((card) => card.isToken === true)
                      .map((card) => ({
                        value: card.id,
                        label: card.mask,
                      })),
                  ]}
                />
              </Grid>
            </Grid>
          )}

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <ItemsSummary
                gridItems={[
                  {
                    translatedText: t("items"),
                    value: state.form.items?.length,
                  },
                  {
                    translatedText: t("baseAmount"),
                    value:
                      localeFormat(
                        state.form.items?.reduce(
                          (sum, item) => sum + item.base,
                          0
                        )
                      ) + "€",
                  },
                  {
                    translatedText: t("taxes"),
                    value:
                      localeFormat(
                        state.form.items?.reduce(
                          (sum, item) =>
                            sum + item.base * (item.taxPercentage / 100),
                          0
                        )
                      ) + "€",
                  },
                  {
                    translatedText: t("total"),
                    value:
                      localeFormat(
                        state.form.items?.reduce(
                          (sum, item) =>
                            sum + item.base * (1 + item.taxPercentage / 100),
                          0
                        )
                      ) + "€",
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {state.form.customer && (
              <InvoiceItems
                addMerchantable={openMerchDialog}
                deleteMerchantable={openConfirmDeleteMerchantable}
                invoiceItems={state.form.items}
                name="items"
              />
            )}
          </Grid>

          <Grid item container xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <CustomButton
                color="success"
                loading={state.submitFormLoading}
                onClick={submitForm}
              >
                {t("create")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* Add merchantable dialog*/}
      {state.form.customer && (
        <AddMerchantableDialog
          closeDialog={closeMerchDialog}
          customerId={state.form.customer.id}
          invoiceItems={state.form.items}
          openDialog={state.merchDialogIsOpen}
          tableColumns={MERCHANTABLE_DIALOG_COLUMNS}
          defaultFilters={[{ filterName: "invoiced", value: "no" }]}
        />
      )}

      <ConfirmDialog
        title={t("deleteMechantableQuestion")}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
