import { Box, CircularProgress, Divider, Grid } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useState, useContext, useEffect, createContext } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AppContext from "../../../../../context/AppContext";

import Filters from "../../../../global/structure/Filters";
import Page from "../../../../global/structure/Page";
import Tabs from "../../../../global/structure/Tabs";
import Select from "../../../../global/inputs/Select";

import { thisYear, formatDate } from "../../../../../utils/date";
import { localeFormat } from "../../../../../utils/format";

import CenterSelect from "../../../../Inputs/CenterSelect";
import CustomDate from "../../../../Inputs/CustomDate";
import InformationBox from "../../Components/InformationBox";
import PaymentMethodInput from "../../../../Inputs/PaymentMethodInput";
import SearchButton from "../../../../Inputs/SearchButton";

import { ContractsByCenterName } from "../../Charts/ContractsCharts";
import { ContractsByDateOfPayment } from "../../Charts/ContractsCharts";
import { HistoricalContractsByCustomerType } from "../../Charts/ContractsCharts";
import { HistoricalContractsByKeyAccounts } from "../../Charts/ContractsCharts";
import { HistoricalContractsByPaymentMethod } from "../../Charts/ContractsCharts";
import { HistoricalContractsByPeriodicity } from "../../Charts/ContractsCharts";
import { HistoricalContractVariation } from "../../Charts/ContractsCharts";
import { SignatureTypeDonutChart } from "../../Charts/ContractsCharts";

import {
  getActiveContractMonths,
  getDates,
} from "../../../../../utils/chartUtils";

import {
  // Payment method
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  COMPANY_CUSTOMER_TYPE_ID,

  // Signature types
  NOT_SIGNED,
  DIGITAL_SIGNATURE,
  PHYSICAL_SIGNATURE,
  BOTH_SIGNATURES,

  // Contract states
  PENDING_CONTRACT_STATE_ID,
  ACTIVE_CONTRACT_STATE_ID,
  ENDED_CONTRACT_STATE_ID,
} from "../../../../../data/constants";

const customerTypeNames = {
  [INDIVIDUAL_CUSTOMER_TYPE_ID]: "individual",
  [COMPANY_CUSTOMER_TYPE_ID]: "company",
};

const defaultFilters = {
  centerIds: [],
  isKeyAccount: "",
  paymentMethodIds: [],
  startDate: thisYear()[0],
  states: [],
};

function GeneralTab() {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");

  const apiRef = useGridApiRef();

  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const { averageContractDuration, averagePricePerMeter, averagePrice } =
    getValues(contracts);

  const handleSearch = () => {
    setLoading(true);
    getContracts();
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const getContracts = () => {
    const params = {
      centerIds: filters.centerIds,
      isKeyAccount:
        filters.isKeyAccount !== "" ? filters.isKeyAccount : undefined,
      paymentMethodIds: filters.paymentMethodIds,
      startDate: filters.startDate !== "" ? filters.startDate : undefined,
      states: filters.states,
    };

    api
      .get("/dashboard/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          setContracts(parseData(t, response.data));
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const TABLE_COLUMNS = [
    {
      field: "centerName",
      headerName: t("center"),
      minWidth: 160,
    },
    {
      field: "boxName",
      headerName: t("box"),
      minWidth: 130,
    },
    {
      field: "meters",
      headerName: t("meters"),
      minWidth: 80,
    },
    { field: "pricePerMeter", headerName: t("pricePerMeter") },
    {
      field: "monthlyPrice",
      headerName: t("monthlyPrice"),
    },
    {
      field: "customerFullName",
      headerName: t("customerName"),
      minWidth: 200,
    },
    { field: "startDate", headerName: t("startDate"), minWidth: 100 },
    {
      field: "formattedCreatedAt",
      headerName: t("createdAt"),
      minWidth: 100,
    },
    {
      field: "createdByName",
      headerName: t("createdBy"),
      minWidth: 100,
    },
    { field: "endDate", headerName: t("endDate"), minWidth: 100 },
    {
      field: "cancelledByName",
      headerName: t("cancelledBy"),
      minWidth: 100,
    },
    {
      field: "translatedPeriodicityName",
      headerName: t("periodicity"),
      minWidth: 100,
    },

    { field: "isSigned", headerName: t("signed") },
    { field: "hasDigitalSignature", headerName: t("hasDigitalSignature") },
    { field: "hasSignedFile", headerName: t("hasSignedFile") },

    {
      field: "translatedPaymentMethodName",
      headerName: t("paymentMethod"),
      minWidth: 120,
    },
    {
      field: "paymentMethodDetail",
      headerName: t("iban/paycard"),
      minWidth: 220,
    },
    { field: "dateOfPayment", headerName: t("dayOfPayment") },
    { field: "isFrozen", headerName: t("frozen") },
    {
      field: "translatedPromotionName",
      headerName: t("promotion"),
      minWidth: 230,
    },
    { field: "comments", headerName: t("comments"), minWidth: 400 },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item marginTop={2} marginBottom={1}>
        <Filters
          filters={[
            <CustomDate
              name="startDate"
              label={t("startDate")}
              value={filters.startDate}
              onChange={handleFilterChange}
              disabled={loading}
            />,
            <CenterSelect
              name="centerIds"
              label={t("center")}
              value={filters.centerIds}
              onChange={handleFilterChange}
              disabled={loading}
              multiple
            />,
            <PaymentMethodInput
              name="paymentMethodIds"
              label={t("paymentMethod")}
              value={filters.paymentMethodIds}
              onChange={handleFilterChange}
              disabled={loading}
              multiple
            />,
            <Select
              name="isKeyAccount"
              label={t("keyAccount")}
              value={filters.isKeyAccount}
              onChange={handleFilterChange}
              options={[
                { value: "", label: t("all") },
                { value: 1, label: t("yes") },
                { value: 0, label: t("no") },
              ]}
              disabled={loading}
              width={120}
            />,
            <Select
              multiple
              name="states"
              label={t("contractState")}
              value={filters.states}
              onChange={handleFilterChange}
              options={[
                { value: PENDING_CONTRACT_STATE_ID, label: t("pending") },
                { value: ACTIVE_CONTRACT_STATE_ID, label: t("active") },
                { value: ENDED_CONTRACT_STATE_ID, label: t("ended") },
              ]}
              disabled={loading}
              width={120}
            />,
            <SearchButton loading={loading} onClick={handleSearch} />,
          ]}
        />
      </Grid>

      <Grid item container spacing={2} justifyContent={"center"}>
        <Grid item>
          <InformationBox
            title={t("contracts")}
            mainValue={contracts.length}
            height={170}
            width={240}
            loading={loading}
          />
        </Grid>
        <Grid item marginBottom={1}>
          <InformationBox
            title={t("averageContractDuration")}
            mainValue={localeFormat(averageContractDuration)}
            height={170}
            width={240}
            loading={loading}
            units={t("days")}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("averagePricePerMeter")}
            mainValue={localeFormat(averagePricePerMeter)}
            height={170}
            width={240}
            loading={loading}
            units={"€/m²"}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("averagePrice")}
            mainValue={localeFormat(averagePrice)}
            height={170}
            width={240}
            loading={loading}
            units={"€"}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <HistoricalContractsByPaymentMethod contracts={contracts} />
        </Grid>
        <Grid item xs={12}>
          <HistoricalContractsByPeriodicity contracts={contracts} />
        </Grid>
        <Grid item xs={12}>
          <HistoricalContractsByKeyAccounts contracts={contracts} />
        </Grid>
        <Grid item xs={12}>
          <HistoricalContractsByCustomerType contracts={contracts} />
        </Grid>
        <Grid item xs={12}>
          <ContractsByCenterName contracts={contracts} />
        </Grid>
        <Grid item xs={6}>
          <SignatureTypeDonutChart contracts={contracts} />
        </Grid>
        <Grid item xs={6}>
          <ContractsByDateOfPayment contracts={contracts} />
        </Grid>
        <Grid item xs={12}>
          <HistoricalContractVariation contracts={contracts} />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={contracts}
            columns={TABLE_COLUMNS}
            sx={{ height: "700px" }}
            slots={{ toolbar: GridToolbar }}
            loading={loading}
            apiRef={apiRef}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const parseData = (t, data) => {
  return data.map((item) => {
    const box = item.Box || {};
    const center = box.Center || {};
    const customer = item.Customer || {};
    const periodicity = item.Periodicity || {};
    const paymentMethod = item.PaymentMethod || {};
    const payCard = item.PayCard || {};
    const promotion = item.Promotion || {};
    const iban = item.IBAN || {};
    const monthlyPrice =
      Math.round(
        ((box.meters || 0) * (box.pricePerMeter || 0) + Number.EPSILON) * 100
      ) / 100;
    const paymentMethodDetail =
      paymentMethod.id === TOKENIZED_PAYCARD_PAYMENT_METHOD_ID
        ? payCard.mask
        : paymentMethod.id === PAYCARD_PAYMENT_METHOD_ID
        ? payCard.number
        : paymentMethod.id === RECEIPT_PAYMENT_METHOD_ID
        ? iban.number
        : "---";
    const hasSignedFile = item.hasSignedFile;
    const hasDigitalSignature = item.hasDigitalSignature;
    let signatureType = NOT_SIGNED;
    if (hasSignedFile && hasDigitalSignature) signatureType = BOTH_SIGNATURES;
    else if (hasSignedFile) signatureType = PHYSICAL_SIGNATURE;
    else if (hasDigitalSignature) signatureType = DIGITAL_SIGNATURE;

    return {
      id: item.id || "",

      centerId: center.id || "",
      centerName: center.name || "",

      boxId: box.id || "",
      boxName: box.name || "",
      boxMeters: box.meters || 0,
      boxPricePerMeter: box.pricePerMeter || 0,

      customerId: customer.id || "",
      customerName: customer.name || "",
      customerFullName: customer.fullName || "",
      customerTypeId: customer.customerTypeId || "",
      customerTypeName: customerTypeNames[customer.customerTypeId] || "",
      translatedCustomerTypeName:
        t(customerTypeNames[customer.customerTypeId]) || "",
      isKeyAccount: customer.isKeyAccount || false,

      meters: item.meters || 0,
      pricePerMeter: item.pricePerMeter || 0,
      price: (item.meters || 0) * (item.pricePerMeter || 0),
      monthlyPrice: monthlyPrice,

      startDate: item.startDate || "",
      formattedStartDate: formatDate(new Date(item.startDate)),
      rawStartDate: new Date(item.startDate),
      timeStampStartDate: new Date(item.startDate).getTime(),
      endDate: item.endDate || "",
      formattedEndDate: item.endDate ? formatDate(new Date(item.endDate)) : "",
      rawEndDate: item.endDate ? new Date(item.endDate) : "",
      utilEndDate: item.endDate ? new Date(item.endDate) : new Date(),
      timeStampEndDate: item.endDate
        ? new Date(item.endDate).getTime()
        : new Date().getTime(),
      activeMonths: getActiveContractMonths(item),

      createdAt: item.createdAt || "",
      formattedCreatedAt: formatDate(new Date(item.createdAt)),
      createdById: item.creator?.id || "",
      createdByName: item.creator?.name || "",
      cancelledById: item.cancellator?.id || "",
      cancelledByName: item.cancellator?.name || "",

      periodicityId: periodicity.id || "",
      periodicityName: periodicity.name || "",
      translatedPeriodicityName: t(periodicity.name) || "",

      paymentMethodId: paymentMethod.id || "",
      paymentMethodName: paymentMethod.name || "",
      translatedPaymentMethodName: t(paymentMethod.name) || "",
      paymentMethodDetail: paymentMethodDetail || "",

      promotionId: promotion.id || "",
      promotionName: promotion.name || "",
      translatedPromotionName: t(promotion.name) || "",

      dateOfPayment: item.dateOfPayment || "",
      isFrozen: item.isFrozen,
      isSigned: item.isSigned,
      hasDigitalSignature: item.hasDigitalSignature,
      hasSignedFile: item.hasSignedFile,
      signatureType: signatureType,
      comments: item.comments || "",
    };
  });
};

const getValues = (contracts) => {
  const length = contracts.length || 1;
  const averageContractDuration =
    contracts.reduce((acc, contract) => {
      return (
        acc +
        (contract.timeStampEndDate - contract.timeStampStartDate) / 86400000
      );
    }, 0) / length;

  const averagePricePerMeter =
    contracts.reduce((acc, contract) => {
      return acc + contract.pricePerMeter;
    }, 0) / length;

  const averagePrice =
    contracts.reduce((acc, contract) => {
      return acc + contract.price;
    }, 0) / length;

  return {
    averageContractDuration: averageContractDuration,
    averagePricePerMeter: averagePricePerMeter,
    averagePrice: averagePrice,
  };
};

export default GeneralTab;
