import { useSnackbar } from "notistack";
import { useEffect, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// Components & Utils
import AppContext from "../../../../context/AppContext";
import i18next from "i18next";
import { Fragment } from "react";
import { localeFormat, localeFormatInteger } from "../../../../utils/format";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import CustomSelect from "../../../Inputs/CustomSelect";
import CustomButton from "../../../Inputs/CustomButton";
import ButtonLink from "../../../Inputs/ButtonLink";
import { DataGrid } from "@mui/x-data-grid";

// Start date
const START_YEAR = 2022;
const START_MONTH = 8;

// Table borders
const BLACK_BORDER = "2px solid black";
const GREY_BORDER = "1px dotted grey";
const LIGHT_GREY_BORDER = "1px solid lightgrey";

// Colors
const LIGHT_GREY_BACKGROUND = "#f4f4f4";

// Type
const TYPE_OWN = 0;
const TYPE_FRANCHISE = 1;
const TYPE_OTHER = 2;

// Centers
const NUT_CENTRAL_ID = 91;
const NUT_OPERATIVA_ID = 100;

// Expense Types
const WAGES_AND_SALARIES_IDS = [78];
const SOCIAL_SECURITY_IDS = [79];

const RENT_IDS = [90, 91];
const PROFESSIONAL_SERVICES_IDS = [92, 98, 99, 102, 104, 186];
const INSURANCE_IDS = [83];
const BANK_COMISSION_IDS = [];
const SUPPLIES_IDS = [];
const TRIPS_IDS = [137, 138, 139, 141, 143, 145];
const OTHER_OPERATING_EXPENSES_IDS = [
  11, 12, 36, 44, 48, 53, 81, 85, 86, 101, 125, 128, 133, 134, 146, 148, 159,
  163, 168, 172, 176, 177, 182, 188, 192, 197,
];
const OTHER_TAXES_IDS = [];
const MARKETING_IDS = [2, 43, 120, 121, 123, 173, 196];

const FRANCHISE_OPEX_IDS = [50, 95, 96, 119, 120, 121, 125, 161, 168, 185];
const OWN_OPEX_IDS = [
  26, 94, 96, 119, 120, 121, 129, 130, 134, 150, 161, 176, 181, 183, 185,
];

const SETTLEMENT_IDS = [166];

const TALUS_MANAGEMENT_FEE_IDS = [189];

const ACQUISITIONS_IDS = [47, 103, 160, 193, 194, 195];

const LEGAL_DD_ID = 193;
const TECHNICAL_DD_ID = 160;

const CAPEX_IDS = [40, 52, 60, 162];

// Merchantable Types Ids
const BOX_RENTAL_ID = 1;

// Rented Centers
const RENTED_CENTERS_IDS = [19, 23, 87, 104];

const initialState = {
  acquisitionCosts: null,
  centers: null,
  csvData: [],
  data: {
    nutCenters: [],
    franchiseCenters: [],
    revenue: [],
    personnelExpenses: [],
    generalOpex: [],
    franchiseOpex: [],
    ownOpex: [],
    EBITDA: [],
    talus: [],
    CAPEX: [],
    acquisitionCosts: [],
    platformInvestment: [],
    yieldOnCost: [],
  },
  dataProcessed: {
    acquisitionCosts: false,
    centers: false,
    expenses: false,
    revenue: false,
  },
  expenseTypes: null,
  invoiceItems: null,
  personnelExpenses: null,
  providerInvoices: null,
  dialog: {
    open: false,
    title: "",
    children: null,
  },
  modifyData: {
    monthIndex: "",
    value: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CENTERS":
      return {
        ...state,
        centers: action.payload,
      };
    case "SET_CSV_DATA":
      return {
        ...state,
        csvData: action.payload,
      };
    case "SET_DATA":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_DATA_PROCESSED":
      return {
        ...state,
        dataProcessed: {
          ...state.dataProcessed,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_DATA_EXPENSES":
      return {
        ...state,
        data: {
          ...state.data,
          personnelExpenses: action.payload.personnelExpenses,
          generalOpex: action.payload.generalOpex,
          franchiseOpex: action.payload.franchiseOpex,
          ownOpex: action.payload.ownOpex,
          talus: action.payload.talus,
          CAPEX: action.payload.CAPEX,
        },
      };
    case "SET_EXPENSE_TYPES":
      return {
        ...state,
        expenseTypes: action.payload,
      };
    case "SET_GENERAL_TRACKING_DATA":
      return {
        ...state,
        centers: action.payload.centers,
        invoiceItems: action.payload.invoiceItems,
        providerInvoices: action.payload.providerInvoices,
        acquisitionCosts: action.payload.acquisitionCosts,
        personnelExpenses: action.payload.personnelExpenses,
      };
    case "SET_INVOICE_ITEMS":
      return {
        ...state,
        invoiceItems: action.payload,
      };
    case "SET_PROVIDER_INVOICES":
      return {
        ...state,
        providerInvoices: action.payload,
      };
    case "SET_DIALOG":
      return {
        ...state,
        dialog: action.payload,
      };
    case "SET_DIALOG_OPEN":
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: action.payload,
        },
      };
    case "SET_MODIFY_DATA":
      return {
        ...state,
        modifyData: {
          ...state.modifyData,
          [action.payload.name]: action.payload.value,
        },
      };
    case "RESET_MODIFY_DATA":
      return {
        ...state,
        modifyData: initialState.modifyData,
      };
    default:
      throw new Error("Action type not found");
  }
}

export const TrackingPanel = () => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("dashboard");
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  let TRACKING_HEADERS = [{ id: 1, label: "", minWidth: 250 }];
  let MONTHS_DATA = [];

  // Get the current Date
  const currentDate = new Date();

  // Define the starting month (August 2022)
  const startingMonth = new Date(START_YEAR, START_MONTH - 1);

  // Loop through each month from the starting month until the current month
  let currentMonth = startingMonth;
  // Start at 2 because the first column is the row titles
  let i = 2;
  while (currentMonth <= currentDate) {
    // Format the month as desired (e.g., "September 2022")
    const language = i18next.language;
    const formattedMonth = currentMonth
      .toLocaleString(language === "es" ? "es-ES" : "en-US", {
        month: "short",
        year: "2-digit",
      })
      .replace(" ", "-");

    // Add the formatted month to the array
    TRACKING_HEADERS.push({ id: i, label: formattedMonth, minWidth: 120 });

    // Add the formatted month to the array
    MONTHS_DATA.push(currentMonth);
    // Move to the next month
    currentMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1
    );
    i++;
  }

  // ROWS TITLES
  const ROWS = {
    ASSETS_OWN: {
      title: t("assets") + " - " + t("own"),
      dialogChildren: InfoDialogChildren(t("nutAssetsCategoryInfo")),
      clickable: false,
    },
    LETTABLE_AREA_OWN: {
      title: t("lettableArea") + " - " + t("own") + " (m²)",
      dialogChildren: InfoDialogChildren(t("nutLettableAreaCategoryInfo")),
      clickable: false,
    },
    OCCUPANCY_OWN: {
      title: t("occupancy") + " - " + t("own") + " (%)",
      dialogChildren: InfoDialogChildren(t("nutOccupancyCategoryInfo")),
      clickable: false,
    },
    ASSETS_FRANCHISE: {
      title: t("assets") + " - " + t("franchise"),
      dialogChildren: InfoDialogChildren(t("franchiseAssetsCategoryInfo")),
      clickable: false,
    },
    ROYALTY_FRANCHISE: {
      title: t("royalty") + " - " + t("franchise") + " (%)",
      dialogChildren: InfoDialogChildren(t("franchiseRoyaltyCategoryInfo")),
      clickable: false,
    },
    LETTABLE_AREA_FRANCHISE: {
      title: t("lettableArea") + " - " + t("franchise") + " (m²)",
      dialogChildren: InfoDialogChildren(
        t("franchiseLettableAreaCategoryInfo")
      ),
      clickable: false,
    },
    OCCUPANCY_FRANCHISE: {
      title: t("occupancy") + " - " + t("franchise") + " (%)",
      dialogChildren: InfoDialogChildren(t("franchiseOccupancyCategoryInfo")),
      clickable: false,
    },
    MAX_REVENUE_FRANCHISE: {
      title: t("maxRevenue") + " - " + t("franchise") + " (€)",
      dialogChildren: InfoDialogChildren(t("franchiseMaxRevenueCategoryInfo")),
      clickable: false,
    },
    REVENUE_FRANCHISE: {
      title: t("revenue") + " - " + t("franchise") + " (€)",
      dialogChildren: InfoDialogChildren(t("revenueFranchiseCategoryInfo")),
      clickable: true,
    },
    ROYALTY_REVENUE_FRANCHISE: {
      title: t("royalty") + " - " + t("franchise") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("royaltyFranchiseCategoryInfo"),
        state.expenseTypes,
        SETTLEMENT_IDS
      ),
      clickable: true,
    },
    REVENUE_OWN: {
      title: t("revenue") + " - " + t("own") + " (€)",
      dialogChildren: InfoDialogChildren(t("revenueOwnCategoryInfo")),
      clickable: true,
    },
    OTHER_INCOME: {
      title: t("otherIncome") + " (€)",
      dialogChildren: InfoDialogChildren(t("otherIncomeCategoryInfo")),
      clickable: true,
    },
    UNASSIGNED_REVENUE: {
      title: t("unassignedRevenue") + " (€)",
      dialogChildren: InfoDialogChildren(t("unassignedRevenueCategoryInfo")),
      clickable: true,
    },
    TOTAL_REVENUE: {
      title: t("totalRevenue") + " (€)",
      dialogChildren: InfoDialogChildren(t("totalRevenueCategoryInfo")),
      clickable: false,
    },
    WAGES_AND_SALARIES: {
      title: t("wagesAndSalaries") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("wagesAndSalariesCategoryInfo"),
        state.expenseTypes,
        WAGES_AND_SALARIES_IDS
      ),
      clickable: true,
    },
    SOCIAL_SECURITY: {
      title: t("socialSecurity") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("socialSecurityCategoryInfo"),
        state.expenseTypes,
        SOCIAL_SECURITY_IDS
      ),
      clickable: true,
    },
    TOTAL_PERSONNEL_EXPENSES: {
      title: t("totalPersonnelExpenses") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("totalPersonnelExpensesCategoryInfo")
      ),
      clickable: false,
    },
    RENT: {
      title: t("rent") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("rentCategoryInfo"),
        state.expenseTypes,
        RENT_IDS
      ),
      clickable: true,
    },
    PROFESSIONAL_SERVICES: {
      title: t("professionalServices") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("professionalServicesCategoryInfo"),
        state.expenseTypes,
        PROFESSIONAL_SERVICES_IDS
      ),
      clickable: true,
    },
    INSURANCE: {
      title: t("insurance") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("insuranceCategoryInfo"),
        state.expenseTypes,
        INSURANCE_IDS
      ),
      clickable: true,
    },
    BANK_COMISSION: {
      title: t("bankComission") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("bankComissionCategoryInfo"),
        state.expenseTypes,
        BANK_COMISSION_IDS
      ),
      clickable: true,
    },
    SUPPLIES: {
      title: t("supplies") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("suppliesCategoryInfo"),
        state.expenseTypes,
        SUPPLIES_IDS
      ),
      clickable: true,
    },
    TRIPS: {
      title: t("trips") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("tripsCategoryInfo"),
        state.expenseTypes,
        TRIPS_IDS
      ),
      clickable: true,
    },
    OTHER_OPERATING_EXPENSES: {
      title: t("otherOperatingExpenses") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("otherOperatingExpensesCategoryInfo"),
        state.expenseTypes,
        OTHER_OPERATING_EXPENSES_IDS
      ),
      clickable: true,
    },
    OTHER_TAXES: {
      title: t("otherTaxes") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("otherTaxesCategoryInfo"),
        state.expenseTypes,
        OTHER_TAXES_IDS
      ),
      clickable: true,
    },
    MARKETING: {
      title: t("marketing") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("marketingCategoryInfo"),
        state.expenseTypes,
        MARKETING_IDS
      ),
      clickable: true,
    },
    NOT_ASSIGNED: {
      title: t("notAssigned") + " (€)",
      dialogChildren: InfoDialogChildren(t("notAssignedCategoryInfo")),
      clickable: true,
    },
    TOTAL_GENERAL_OPEX: {
      title: t("totalGeneralOpex") + " (€)",
      dialogChildren: InfoDialogChildren(t("totalGeneralOpexCategoryInfo")),
      clickable: false,
    },
    FRANCHISE_OPEX: {
      title: t("franchiseOpex") + " (€)",
      dialogChildren: InfoDialogChildren(t("franchiseOpexCategoryInfo")),
      clickable: true,
    },
    OWN_OPEX: {
      title: t("ownOpex") + " (€)",
      dialogChildren: InfoDialogChildren(t("ownOpexCategoryInfo")),
      clickable: true,
    },
    EBITDA: {
      title: "EBITDA (€)",
      dialogChildren: InfoDialogChildren(t("EBITDACategoryInfo")),
      clickable: false,
    },
    TALUS: {
      title: t("talusManagementFee") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("talusManagementFeeCategoryInfo"),
        state.expenseTypes,
        TALUS_MANAGEMENT_FEE_IDS
      ),
      clickable: true,
    },
    CAPEX: {
      title: t("capex") + " (€)",
      dialogChildren: InfoDialogChildren(
        t("capexCategoryInfo"),
        state.expenseTypes,
        CAPEX_IDS
      ),
      clickable: true,
    },
    PURCHASE_PRICE: {
      title: t("purchasePrice") + " (€)",
      dialogChildren: InfoDialogChildren(t("purchasePriceCategoryInfo")),
      clickable: true,
    },
    LEGAL_DD: {
      title: t("legalDD") + " (€)",
      dialogChildren: InfoDialogChildren(t("legalDDCategoryInfo")),
      clickable: true,
    },
    TECHNICAL_DD: {
      title: t("technicalDD") + " (€)",
      dialogChildren: InfoDialogChildren(t("technicalDDCategoryInfo")),
      clickable: true,
    },
    NOTARY: {
      title: t("notary") + " (€)",
      dialogChildren: InfoDialogChildren(t("notaryCategoryInfo")),
      clickable: true,
    },
    BROKER: {
      title: t("broker") + " (€)",
      dialogChildren: InfoDialogChildren(t("brokerCategoryInfo")),
      clickable: true,
    },
    TOTAL_ACQUISITION: {
      title: t("totalAcquisition") + " (€)",
      dialogChildren: InfoDialogChildren(t("totalAcquisitionCategoryInfo")),
      clickable: false,
    },
    PLATFORM_INVESTMENT: {
      title: t("platformInvestment") + " (€)",
      dialogChildren: InfoDialogChildren(t("platformInvestmentCategoryInfo")),
      clickable: false,
    },
    YIELD_ON_COST: {
      title: t("yieldOnCost") + " (%)",
      dialogChildren: InfoDialogChildren(t("yieldOnCostCategoryInfo")),
      clickable: false,
    },
  };

  // Initial useEffect
  useEffect(() => {
    getGeneralTracking();
    getExpenseTypes();
  }, []);

  // Center useEffect
  useEffect(() => {
    if (state.centers && state.dataProcessed.centers === false) {
      processCentersData();
    }
  }, [state.centers]);

  // Invoices useEffect
  useEffect(() => {
    if (
      state.invoiceItems &&
      state.providerInvoices &&
      state.dataProcessed.revenue === false
    ) {
      processInvoicesData();
    }
  }, [state.invoiceItems, state.providerInvoices]);

  // ProviderInvoices useEffect
  useEffect(() => {
    if (
      state.providerInvoices &&
      state.centers &&
      state.dataProcessed.expenses === false
    ) {
      processExpensesData();
    }
  }, [state.providerInvoices, state.centers]);

  // EBITDA useEffect
  useEffect(() => {
    if (
      state.data.revenue.length > 0 &&
      state.data.personnelExpenses.length > 0 &&
      state.data.generalOpex.length > 0 &&
      state.data.franchiseOpex.length > 0 &&
      state.data.ownOpex.length > 0 &&
      state.data.EBITDA.length === 0
    ) {
      processEBITDAData();
    }
  }, [
    state.data.revenue,
    state.data.personnelExpenses,
    state.data.generalOpex,
    state.data.franchiseOpex,
    state.data.ownOpex,
  ]);

  // AcquisitionCosts useEffect
  useEffect(() => {
    if (
      state.acquisitionCosts &&
      state.dataProcessed.acquisitionCosts === false
    ) {
      processAcquisitionCostsData();
    }
  }, [state.acquisitionCosts]);

  // Platform Investment useEffect
  useEffect(() => {
    if (
      state.data.CAPEX.length > 0 &&
      state.data.acquisitionCosts.length > 0 &&
      state.data.platformInvestment.length === 0
    ) {
      processPlatformInvestmentData();
    }
  }, [state.data.CAPEX, state.data.acquisitionCosts]);

  // Yield on Cost useEffect
  useEffect(() => {
    if (
      state.data.EBITDA.length > 0 &&
      state.data.platformInvestment.length > 0 &&
      state.data.yieldOnCost.length === 0
    ) {
      processYieldOnCostData();
    }
  }, [state.data.EBITDA, state.data.platformInvestment]);

  // Get CSV data
  useEffect(() => {
    if (Object.values(state.dataProcessed).every((value) => value === true))
      getCSVData();
  }, [state.dataProcessed]);

  /* BACKEND CALLS */

  const getGeneralTracking = () => {
    const formattedStartMonth =
      START_MONTH < 10 ? `0${START_MONTH}` : START_MONTH;
    const params = {
      dateFrom: `${START_YEAR}-${formattedStartMonth}-01`,
    };

    api
      .get("/general-tracking", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_GENERAL_TRACKING_DATA",
            payload: {
              centers: response.data.centers,
              invoiceItems: response.data.invoiceItems,
              providerInvoices: response.data.providerInvoices,
              acquisitionCosts: response.data.acquisitionAssetCosts,
              personnelExpenses: response.data.personnelExpenses,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getExpenseTypes = () => {
    api
      .get("/expense-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_EXPENSE_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* DATA PROCESSING */

  const processCentersData = () => {
    // Declare the categories for each center type
    const nut = {
      assets: generateCategory(ROWS.ASSETS_OWN),
      lettableArea: generateCategory(
        ROWS.LETTABLE_AREA_OWN,
        localeFormatInteger
      ),
      occupancy: generateCategory(ROWS.OCCUPANCY_OWN, localeFormatInteger),
    };

    const franchise = {
      assets: generateCategory(ROWS.ASSETS_FRANCHISE),
      royalty: generateCategory(ROWS.ROYALTY_FRANCHISE, localeFormatInteger),
      lettableArea: generateCategory(
        ROWS.LETTABLE_AREA_FRANCHISE,
        localeFormatInteger
      ),
      occupancy: generateCategory(
        ROWS.OCCUPANCY_FRANCHISE,
        localeFormatInteger
      ),
      maxRevenue: generateCategory(
        ROWS.MAX_REVENUE_FRANCHISE,
        localeFormatInteger
      ),
    };

    // Compute necessary data for each center type
    const nutCenters = state.centers
      .filter((center) => center.type === TYPE_OWN)
      .map((center) => ({
        ...center,
        lettableArea: computeCenterLettableArea(center),
        lettedArea: computeCenterLettedArea(center),
        royalty: computeCenterRoyalty(center),
        maxRevenue: computeCenterMaxRevenue(center),
      }));

    const franchiseCenters = state.centers
      .filter((center) => center.type === TYPE_FRANCHISE)
      .map((center) => ({
        ...center,
        lettableArea: computeCenterLettableArea(center),
        lettedArea: computeCenterLettedArea(center),
        royalty: computeCenterRoyalty(center),
        maxRevenue: computeCenterMaxRevenue(center),
      }));

    // Loop through each month and compute the assets categories for each center type
    MONTHS_DATA.forEach((month, monthIndex) => {
      computeNutAssets(month, monthIndex, nut, franchise, nutCenters);
      computeFranchiseAssets(month, monthIndex, franchise, franchiseCenters);
    });

    //Transform occupancy and royalty into percentages
    nut.occupancy.data = computeOccupancy(nut);
    franchise.occupancy.data = computeOccupancy(franchise);

    franchise.royalty.data = computeRoyalty(franchise);

    // Transform data into usable table format
    const nutCentersData = Object.values(nut).map(
      ({ title, data, formatData, info, dialogChildren, clickable }) => ({
        title,
        data: data.map((value) => formatData(value)),
        info,
        dialogChildren,
        clickable,
      })
    );

    const franchiseCentersData = Object.values(franchise)
      .filter((obj) => !obj.title.includes("maxRevenue"))
      .map(({ title, data, formatData, info, dialogChildren, clickable }) => ({
        title,
        data: data.map((value) => formatData(value)),
        info,
        dialogChildren,
        clickable,
      }));

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "nutCenters",
        value: nutCentersData,
      },
    });

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "franchiseCenters",
        value: franchiseCentersData,
      },
    });

    dispatch({
      type: "SET_DATA_PROCESSED",
      payload: {
        name: "centers",
        value: true,
      },
    });
  };

  const processInvoicesData = () => {
    //TODO: Change function name
    const revenue = generateCategoryObject({
      revenueFranchise: ROWS.REVENUE_FRANCHISE,
      royaltyFranchise: ROWS.ROYALTY_REVENUE_FRANCHISE,
      revenueOwn: ROWS.REVENUE_OWN,
      otherIncome: ROWS.OTHER_INCOME,
      unassignedRevenue: ROWS.UNASSIGNED_REVENUE,
      totalRevenue: ROWS.TOTAL_REVENUE,
    });

    //royaltyFranchise is not an invoiceItem
    const invoiceItems = {
      revenueFranchise: state.invoiceItems.filter(
        (item) =>
          item?.Merchantable?.Contract?.Box?.Center?.type === TYPE_FRANCHISE
      ),
      royaltyFranchise: generateFilteredProviderInvoices(
        state.providerInvoices,
        [{ key: "settlements", expenseTypeIds: SETTLEMENT_IDS }]
      ).settlements,
      revenueOwn: state.invoiceItems.filter(
        (item) =>
          item?.Merchantable?.Contract?.Box?.Center?.type === TYPE_OWN &&
          item?.Merchantable?.merchantableTypeId === BOX_RENTAL_ID
      ),
      otherIncome: state.invoiceItems.filter(
        (item) =>
          (item?.Merchantable?.Contract?.Box?.Center?.type === TYPE_OWN &&
            item?.Merchantable?.merchantableTypeId !== BOX_RENTAL_ID) ||
          item?.Merchantable?.Contract?.Box?.Center?.type === TYPE_OTHER
      ),
      unassignedRevenue: state.invoiceItems.filter(
        (item) => !item.Merchantable || !item.Merchantable.Contract
      ),
    };

    // Find invoice items that are not in any of the specified categories
    const unassignedInvoiceItems = state.invoiceItems.filter((item) => {
      // Check if the item is not in any of the categories
      return (
        !invoiceItems.revenueFranchise.includes(item) &&
        !invoiceItems.revenueOwn.includes(item) &&
        !invoiceItems.otherIncome.includes(item) &&
        !invoiceItems.unassignedRevenue.includes(item)
      );
    });

    if (unassignedInvoiceItems.length > 0) {
      console.log("Unassigned invoice items: ", unassignedInvoiceItems);
    }

    MONTHS_DATA.forEach((month, monthIndex) => {
      computeRevenue(month, monthIndex, revenue, invoiceItems);
    });

    revenue.totalRevenue.data = computeTotalArray(revenue);

    const revenueData = generateProcessedData(revenue);

    revenueData[revenueData.length - 1].total = true;

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "revenue",
        value: revenueData,
      },
    });

    dispatch({
      type: "SET_DATA_PROCESSED",
      payload: {
        name: "revenue",
        value: true,
      },
    });
  };

  const processExpensesData = () => {
    // STEP 1: Generate the categories
    const personnelExpenses = generateCategoryObject({
      wagesAndSalaries: ROWS.WAGES_AND_SALARIES,
      socialSecurity: ROWS.SOCIAL_SECURITY,
      totalPersonnelExpenses: ROWS.TOTAL_PERSONNEL_EXPENSES,
    });

    const generalOpex = generateCategoryObject({
      rent: ROWS.RENT,
      professionalServices: ROWS.PROFESSIONAL_SERVICES,
      insurance: ROWS.INSURANCE,
      bankComission: ROWS.BANK_COMISSION,
      supplies: ROWS.SUPPLIES,
      trips: ROWS.TRIPS,
      otherOperatingExpenses: ROWS.OTHER_OPERATING_EXPENSES,
      otherTaxes: ROWS.OTHER_TAXES,
      marketing: ROWS.MARKETING,
      notAssigned: ROWS.NOT_ASSIGNED,
      totalGeneralOpex: ROWS.TOTAL_GENERAL_OPEX,
    });

    const franchiseOpex = generateCategoryObject({
      franchiseOpex: ROWS.FRANCHISE_OPEX,
    });

    const ownOpex = generateCategoryObject({
      ownOpex: ROWS.OWN_OPEX,
    });

    const talusManagementFee = generateCategoryObject({
      talusManagementFee: ROWS.TALUS,
    });

    const CAPEX = generateCategoryObject({
      CAPEX: ROWS.CAPEX,
    });

    let providerInvoices = state.providerInvoices.filter(
      (providerInvoice) =>
        !SETTLEMENT_IDS.includes(providerInvoice.expenseTypeId)
    );

    // STEP 2: Generate filtered provider invoices objects
    const personnelProviderInvoices = generateFilteredProviderInvoices(
      state.providerInvoices,
      [
        { key: "wagesAndSalaries", expenseTypeIds: WAGES_AND_SALARIES_IDS },
        { key: "socialSecurity", expenseTypeIds: SOCIAL_SECURITY_IDS },
      ]
    );

    // Remove personnelProviderInvoices from providerInvoices
    providerInvoices = removeUsedProviderInvoices(
      providerInvoices,
      personnelProviderInvoices
    );

    const talusManagementFeeProviderInvoices = generateFilteredProviderInvoices(
      providerInvoices,
      [
        {
          key: "talusManagementFee",
          expenseTypeIds: TALUS_MANAGEMENT_FEE_IDS,
        },
      ]
    );

    providerInvoices = removeUsedProviderInvoices(
      providerInvoices,
      talusManagementFeeProviderInvoices
    );

    const generalOpexProviderInvoicesArray = providerInvoices.filter(
      (providerInvoice) =>
        providerInvoice?.Center?.type === TYPE_OTHER ||
        !providerInvoice?.centerId
    );

    const generalOpexProviderInvoices = generateFilteredProviderInvoices(
      generalOpexProviderInvoicesArray,
      [
        { key: "rent", expenseTypeIds: RENT_IDS },
        {
          key: "professionalServices",
          expenseTypeIds: PROFESSIONAL_SERVICES_IDS,
        },
        { key: "insurance", expenseTypeIds: INSURANCE_IDS },
        {
          key: "bankComission",
          expenseTypeIds: BANK_COMISSION_IDS,
        },
        { key: "supplies", expenseTypeIds: SUPPLIES_IDS },
        {
          key: "trips",
          expenseTypeIds: TRIPS_IDS,
        },
        {
          key: "otherOperatingExpenses",
          expenseTypeIds: OTHER_OPERATING_EXPENSES_IDS,
        },
        {
          key: "otherTaxes",
          expenseTypeIds: OTHER_TAXES_IDS,
        },
        {
          key: "marketing",
          expenseTypeIds: MARKETING_IDS,
        },
      ]
    );

    const notAssignedGeneralOpexProviderInvoices =
      generalOpexProviderInvoicesArray.filter(
        (providerInvoice) =>
          !Object.values(generalOpexProviderInvoices).some((arr) =>
            arr.some((item) => item.id === providerInvoice.id)
          )
      );

    generalOpexProviderInvoices.notAssigned =
      notAssignedGeneralOpexProviderInvoices;

    providerInvoices = removeUsedProviderInvoices(
      providerInvoices,
      generalOpexProviderInvoices
    );

    const franchiseCenterIds = state.centers
      .filter((center) => center.type === TYPE_FRANCHISE)
      .map((center) => center.id);

    const franchiseOpexProviderInvoicesArray = providerInvoices.filter(
      (providerInvoice) => franchiseCenterIds.includes(providerInvoice.centerId)
    );

    const franchiseOpexProviderInvoices = {
      franchiseOpex: franchiseOpexProviderInvoicesArray,
    };

    providerInvoices = removeUsedProviderInvoices(
      providerInvoices,
      franchiseOpexProviderInvoices
    );

    const ownCenterIds = state.centers
      .filter((center) => center.type === TYPE_OWN)
      .map((center) => center.id);

    const ownOpexProviderInvoicesArray = providerInvoices.filter(
      (providerInvoice) => ownCenterIds.includes(providerInvoice.centerId)
    );

    const ownOpexProviderInvoices = {
      ownOpex: ownOpexProviderInvoicesArray.filter(
        (providerInvoice) => !CAPEX_IDS.includes(providerInvoice.expenseTypeId)
      ),
    };

    providerInvoices = removeUsedProviderInvoices(
      providerInvoices,
      ownOpexProviderInvoices
    );

    const capexProviderInvoices = generateFilteredProviderInvoices(
      providerInvoices,
      [
        {
          key: "CAPEX",
          expenseTypeIds: CAPEX_IDS,
        },
      ]
    );

    providerInvoices = removeUsedProviderInvoices(
      providerInvoices,
      capexProviderInvoices
    );

    if (providerInvoices.length > 0) {
      console.log("provider invoices left: ", providerInvoices);
    }
    // STEP 3: Compute expenses for each month
    MONTHS_DATA.forEach((month, monthIndex) => {
      computeExpenses(
        month,
        monthIndex,
        personnelExpenses,
        personnelProviderInvoices
      );
      computeExpenses(
        month,
        monthIndex,
        generalOpex,
        generalOpexProviderInvoices
      );
      computeExpenses(
        month,
        monthIndex,
        franchiseOpex,
        franchiseOpexProviderInvoices
      );
      computeExpenses(month, monthIndex, ownOpex, ownOpexProviderInvoices);
      computeExpenses(
        month,
        monthIndex,
        talusManagementFee,
        talusManagementFeeProviderInvoices
      );
      computeExpenses(month, monthIndex, CAPEX, capexProviderInvoices);
    });

    // STEP 4: Compute totals, if necessary
    personnelExpenses.totalPersonnelExpenses.data =
      computeTotalArray(personnelExpenses);
    generalOpex.totalGeneralOpex.data = computeTotalArray(generalOpex);

    // STEP 5: Generate processed data (for table)
    const personnelExpensesData = generateProcessedData(personnelExpenses);
    const generalOpexData = generateProcessedData(generalOpex);
    const franchiseOpexData = generateProcessedData(franchiseOpex);
    const ownOpexData = generateProcessedData(ownOpex);
    const talusManagementFeeData = generateProcessedData(talusManagementFee);
    const capexData = generateProcessedData(CAPEX);

    personnelExpensesData[personnelExpensesData.length - 1].total = true;
    generalOpexData[generalOpexData.length - 1].total = true;

    // STEP 6: Dispatch data
    dispatch({
      type: "SET_DATA_EXPENSES",
      payload: {
        personnelExpenses: personnelExpensesData,
        generalOpex: generalOpexData,
        franchiseOpex: franchiseOpexData,
        ownOpex: ownOpexData,
        talus: talusManagementFeeData,
        CAPEX: capexData,
      },
    });

    dispatch({
      type: "SET_DATA_PROCESSED",
      payload: {
        name: "expenses",
        value: true,
      },
    });
  };

  const processEBITDAData = () => {
    const ebitda = generateCategoryObject({
      EBITDA: ROWS.EBITDA,
    });

    ebitda.EBITDA.data = computeEBITDAArray();

    const ebitdaData = generateProcessedData(ebitda);

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "EBITDA",
        value: ebitdaData,
      },
    });
  };

  const processAcquisitionCostsData = () => {
    const acquisitionCosts = generateCategoryObject({
      purchasePrice: ROWS.PURCHASE_PRICE,
      legalDD: ROWS.LEGAL_DD,
      technicalDD: ROWS.TECHNICAL_DD,
      notary: ROWS.NOTARY,
      broker: ROWS.BROKER,
      totalAcquisition: ROWS.TOTAL_ACQUISITION,
    });

    const filteredCosts = {
      purchasePrice: state.acquisitionCosts.filter(
        (cost) => cost.type === "Asset"
      ),
      legalDD: state.acquisitionCosts.filter((cost) => cost.type === "LegalDD"),
      technicalDD: state.acquisitionCosts.filter(
        (cost) => cost.type === "TechnicalDD"
      ),
      notary: state.acquisitionCosts.filter((cost) =>
        ["Taxes", "Notaries"].includes(cost.type)
      ),
      broker: state.acquisitionCosts.filter((cost) => cost.type === "Broker"),
    };

    MONTHS_DATA.forEach((month, monthIndex) => {
      computeAcquisitionCosts(
        month,
        monthIndex,
        filteredCosts,
        acquisitionCosts
      );
    });

    acquisitionCosts.totalAcquisition.data =
      computeTotalArray(acquisitionCosts);

    const acquisitionCostsData = generateProcessedData(acquisitionCosts);

    acquisitionCostsData[acquisitionCostsData.length - 1].total = true;

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "acquisitionCosts",
        value: acquisitionCostsData,
      },
    });

    dispatch({
      type: "SET_DATA_PROCESSED",
      payload: {
        name: "acquisitionCosts",
        value: true,
      },
    });
  };

  const processPlatformInvestmentData = () => {
    const platformInvestment = generateCategoryObject({
      platformInvestment: ROWS.PLATFORM_INVESTMENT,
    });

    platformInvestment.platformInvestment.data =
      computePlatformInvestmentArray();

    const platformInvestmentData = generateProcessedData(platformInvestment);

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "platformInvestment",
        value: platformInvestmentData,
      },
    });
  };

  const processYieldOnCostData = () => {
    const yieldOnCost = generateCategoryObject({
      yieldOnCost: ROWS.YIELD_ON_COST,
    });

    yieldOnCost.yieldOnCost.data = computeYieldOnCostArray();

    const yieldOnCostData = generateProcessedData(yieldOnCost);

    dispatch({
      type: "SET_DATA",
      payload: {
        name: "yieldOnCost",
        value: yieldOnCostData,
      },
    });
  };

  /* COMPUTE DATA */
  const computeNutAssets = (month, monthIndex, nut, franchise, nutCenters) => {
    let nutLettedArea = 0;
    nutCenters.forEach((center) => {
      if (center.closeDate) {
        const closeDate = new Date(center.closeDate);
        if (
          isSameMonthAndYear(closeDate, month) ||
          (isPreviousToStartDate(closeDate) && monthIndex === 0)
        ) {
          decrementNutCounters(nut, center, monthIndex);
        }
      }
      if (center.acquisitionDate) {
        const acquisitionDate = new Date(center.acquisitionDate);
        const contractSignedAt = center.contractSignedAt
          ? new Date(center.contractSignedAt)
          : null;
        if (
          isSameMonthAndYear(acquisitionDate, month) ||
          (isPreviousToStartDate(acquisitionDate) && monthIndex === 0)
        ) {
          incrementNutCounters(nut, center, monthIndex);
          if (contractSignedAt) {
            decrementFranchiseCounters(franchise, center);
          }
        }
        if (
          isSameMonthAndYear(contractSignedAt, month) ||
          (isPreviousToStartDate(contractSignedAt) && monthIndex === 0)
        ) {
          incrementFranchiseCounters(franchise, center);
        }
      } else if (!center.acquisitionDate && monthIndex === 0) {
        incrementNutCounters(nut, center, monthIndex);
      }
      nutLettedArea += center.lettedArea[monthIndex];
    });

    if (monthIndex > 0) {
      nut.assets.info[monthIndex] = nut.assets.info[monthIndex].concat(
        nut.assets.info[monthIndex - 1]
      );
    }

    nut.assets.altData.push(nut.assets.altCounter);
    nut.assets.data.push(
      nut.assets.counter + " (" + nut.assets.altCounter + ")"
    );
    nut.lettableArea.data.push(nut.lettableArea.counter);
    nut.occupancy.data.push(nutLettedArea);
  };

  const computeFranchiseAssets = (
    month,
    monthIndex,
    franchise,
    franchiseCenters
  ) => {
    let franchiseLettedArea = 0;
    franchiseCenters.forEach((center) => {
      if (center.openingDate) {
        const openingDate = new Date(center.openingDate);
        if (
          isSameMonthAndYear(openingDate, month) ||
          (isPreviousToStartDate(openingDate) && monthIndex === 0) ||
          (!center.openingDate && monthIndex === 0)
        )
          incrementFranchiseCounters(franchise, center);
      }
      if (center.closeDate) {
        const closeDate = new Date(center.closeDate);
        if (
          isSameMonthAndYear(closeDate, month) ||
          (isPreviousToStartDate(closeDate) && monthIndex === 0)
        )
          decrementFranchiseCounters(franchise, center);
      }
      franchiseLettedArea += center.lettedArea[monthIndex];
    });

    franchise.assets.data.push(franchise.assets.counter);
    franchise.lettableArea.data.push(franchise.lettableArea.counter);
    franchise.occupancy.data.push(franchiseLettedArea);
    franchise.royalty.data.push(franchise.royalty.counter);
    franchise.maxRevenue.data.push(franchise.maxRevenue.counter);
  };

  const computeRevenue = (
    month,
    monthIndex,
    revenue,
    categorizedInvoiceItems
  ) => {
    const monthSettlements = categorizedInvoiceItems.royaltyFranchise.filter(
      (providerInvoice) => {
        const providerInvoiceDate = providerInvoice
          ? new Date(providerInvoice.date)
          : null;
        return isSameMonthAndYear(providerInvoiceDate, month);
      }
    );

    const totalMonthSettlements = monthSettlements.reduce(
      (acc, settlement) => acc + settlement.amount,
      0
    );

    Object.keys(categorizedInvoiceItems).forEach((key) => {
      if (key === "royaltyFranchise") {
        revenue[key].counter = -totalMonthSettlements;
        revenue[key].data.push(revenue[key].counter);
        revenue[key].info[monthIndex].push(...monthSettlements);
        return;
      }
      revenue[key].counter = 0;
      const invoiceItems = categorizedInvoiceItems[key];
      invoiceItems.forEach((item) => {
        const itemDate = item.Invoice.issueDate
          ? new Date(item.Invoice.issueDate)
          : null;
        if (isSameMonthAndYear(itemDate, month)) {
          revenue[key].counter += item.base;
          revenue[key].info[monthIndex].push(item);
        }
      });
      revenue[key].data.push(revenue[key].counter);
    });
  };

  const computeExpenses = (
    month,
    monthIndex,
    expenses,
    categorizedProviderInvoices
  ) => {
    Object.keys(categorizedProviderInvoices).forEach((key) => {
      expenses[key].counter = 0;
      const providerInvoices = categorizedProviderInvoices[key];
      providerInvoices.forEach((providerInvoice) => {
        const providerInvoiceDate = providerInvoice.date
          ? new Date(providerInvoice.date)
          : null;
        if (isSameMonthAndYear(providerInvoiceDate, month)) {
          expenses[key].counter += providerInvoice.amount;
          expenses[key].info[monthIndex].push(providerInvoice);
        }
      });

      // Add capex acquisition costs to capex expenses
      if (key === "CAPEX") {
        const capexAcquisitionCosts = state.acquisitionCosts.filter(
          (cost) => cost.type === "Capex"
        );
        capexAcquisitionCosts.forEach((cost) => {
          const costDate = cost.date ? new Date(cost.date) : null;
          if (isSameMonthAndYear(costDate, month)) {
            expenses[key].counter += cost.amount;
            expenses[key].info[monthIndex].push(cost);
          }
        });
      }

      // Find personnel expense of same month and type
      const personnelExpense = state.personnelExpenses.find((expense) => {
        const expenseDate = expense.date ? new Date(expense.date) : null;
        let type;
        if (key === "wagesAndSalaries") type = "WAGES_AND_SALARIES";
        else if (key === "socialSecurity") type = "SOCIAL_SECURITY";
        else return null;
        return isSameMonthAndYear(expenseDate, month) && type === expense.type;
      });

      // If there is a saved personnel expense in the db, overwrite counter
      if (personnelExpense) {
        expenses[key].counter = personnelExpense.amount;
      }

      expenses[key].data.push(expenses[key].counter);
    });
  };

  const computeAcquisitionCosts = (
    month,
    monthIndex,
    filteredCosts,
    acquisitionCosts
  ) => {
    Object.keys(filteredCosts).forEach((key) => {
      acquisitionCosts[key].counter = 0;
      const costs = filteredCosts[key];
      costs.forEach((cost) => {
        const costDate = cost.date ? new Date(cost.date) : null;
        if (isSameMonthAndYear(costDate, month)) {
          acquisitionCosts[key].counter += cost.amount;
          acquisitionCosts[key].info[monthIndex].push(cost);
        }
      });

      acquisitionCosts[key].data.push(acquisitionCosts[key].counter);
    });
  };

  const computeCenterLettableArea = (center) => {
    // let lettableMeters = new Array(MONTHS_DATA.length).fill(0);
    // const boxes = center.Boxes;
    // let startIndex = 0;
    // MONTHS_DATA.forEach((month, index) => {
    //   const centerStatus = getCenterStatus(center, month);
    //   if (centerStatus && centerStatus !== -1) {
    //     boxes.forEach((box) => {
    //       const openingDate = box.openedAt ? new Date(box.openedAt) : null;
    //       const closeDate = box.closedAt ? new Date(box.closedAt) : null;
    //       if (
    //         index === startIndex &&
    //         (isPreviousToStartDate(openingDate) || !openingDate)
    //       ) {
    //         lettableMeters[index] += box.meters;
    //       }
    //       if (isSameMonthAndYear(openingDate, month)) {
    //         lettableMeters[index] += box.meters;
    //       }
    //       if (isSameMonthAndYear(closeDate, month)) {
    //         lettableMeters[index] -= box.meters;
    //       }
    //     });
    //   } else startIndex = index + 1;
    // });
    // let tmpMonthLettableMeters = lettableMeters[0];
    // lettableMeters = lettableMeters.map((value, index) => {
    //   if (index === 0) return value;
    //   tmpMonthLettableMeters += value;
    //   return tmpMonthLettableMeters;
    // });
    // console.log(center.id, lettableMeters);
    return center.Boxes?.reduce((acc, box) => acc + box.meters, 0);
  };

  const computeCenterRoyalty = (center) => {
    const maxRevenue = center.Boxes?.reduce(
      (acc, box) => acc + box.meters * box.pricePerMeter,
      0
    );
    if (!maxRevenue) return 0;
    const averageRoyalty =
      center.Boxes?.reduce(
        (acc, box) => acc + box.meters * box.pricePerMeter * box.royalty,
        0
      ) / maxRevenue || 0;
    return averageRoyalty;
  };

  const computeCenterMaxRevenue = (center) => {
    const maxRevenue =
      center.Boxes?.reduce(
        (acc, box) => acc + box.meters * box.pricePerMeter,
        0
      ) || 0;
    return maxRevenue;
  };

  const computeCenterLettedArea = (center) => {
    // Get array of all contracts of center
    const contracts = center.Boxes?.reduce(
      (acc, box) => [...acc, ...box.Contracts],
      []
    );

    // Get minDate (first startDate) and maxDate (today)
    const minDate = new Date(
      Math.min(...contracts.map((contract) => new Date(contract.startDate)))
    );
    const maxDate = new Date();

    // Get array of all months between minDate and maxDate
    const months = getMonthsBetweenDates(minDate, maxDate);

    let accMeters = new Array(months.length).fill(0);

    months.forEach((month, index) => {
      // New contracts
      const newContracts = contracts.filter((contract) =>
        isSameMonthAndYear(new Date(contract.startDate), month)
      );
      const newMeters = newContracts.reduce(
        (acc, contract) => acc + contract.meters,
        0
      );

      // Cancellations
      const oldContracts = contracts.filter((contract) =>
        isSameMonthAndYear(new Date(contract.endDate), month)
      );
      const oldMeters = oldContracts.reduce(
        (acc, contract) => acc + contract.meters,
        0
      );
      if (index === 0) accMeters[index] = newMeters - oldMeters;
      else accMeters[index] = accMeters[index - 1] + newMeters - oldMeters;
    });

    // Get index of startDate, the index will be the cutout point for the occupancy array
    const startDate = new Date(START_YEAR, START_MONTH - 1);
    const indexOfStartDate = months.findIndex((month) =>
      isSameMonthAndYear(month, startDate)
    );
    // Cut array from startDate
    accMeters = accMeters.slice(indexOfStartDate);
    const accMetersSize = accMeters.length;
    // Add 0s to the beginning of the array if necessary
    if (accMetersSize < MONTHS_DATA.length) {
      accMeters = [
        ...new Array(MONTHS_DATA.length - accMetersSize).fill(0),
        ...accMeters,
      ];
    }
    if (center.type === TYPE_OWN) {
      // Remove months previous to acquisitionDate when they were franchises
      const acquisitionDate = new Date(center.acquisitionDate);
      accMeters = accMeters.map((value, index) => {
        if (!center.acquisitionDate) return value;
        if (acquisitionDate.getFullYear() < MONTHS_DATA[index].getFullYear())
          return value;
        if (
          acquisitionDate.getFullYear() === MONTHS_DATA[index].getFullYear() &&
          acquisitionDate.getMonth() <= MONTHS_DATA[index].getMonth()
        )
          return value;
        return 0;
      });
    }
    return accMeters;
  };

  const computeOccupancy = (centerType) => {
    return centerType.occupancy.data.map((lettedArea, index) => {
      const lettableArea = centerType.lettableArea.data[index];
      if (lettableArea === 0) return 0;
      return (lettedArea / lettableArea) * 100;
    });
  };

  const computeRoyalty = (centerType) => {
    return centerType.royalty.data.map((royalty, index) => {
      const maxRevenue = centerType.maxRevenue.data[index];
      return royalty / maxRevenue;
    });
  };

  const computeTotalArray = (category) => {
    const categoryKeys = Object.keys(category);
    const lastIndex = categoryKeys.length - 1;
    const totals = categoryKeys.reduce((acc, key, i) => {
      if (!key.includes("total") || i !== lastIndex) {
        category[key].data.forEach((value, index) => {
          acc[index] += value;
        });
      }
      return acc;
    }, Array(category[categoryKeys[0]].data.length).fill(0));
    return totals;
  };

  const computeEBITDAArray = () => {
    const personnelTotalExpenses =
      state.data.personnelExpenses[state.data.personnelExpenses.length - 1]
        .data;
    const generalOpexTotalExpenses =
      state.data.generalOpex[state.data.generalOpex.length - 1].data;
    const franchiseOpexTotalExpenses =
      state.data.franchiseOpex[state.data.franchiseOpex.length - 1].data;
    const ownOpexTotalExpenses =
      state.data.ownOpex[state.data.ownOpex.length - 1].data;

    const totalRevenue = state.data.revenue[state.data.revenue.length - 1].data;
    const totalExpenses = personnelTotalExpenses.map((value, index) => {
      return (
        Number(value.replaceAll(".", "")) +
        Number(generalOpexTotalExpenses[index].replaceAll(".", "")) +
        Number(franchiseOpexTotalExpenses[index].replaceAll(".", "")) +
        Number(ownOpexTotalExpenses[index].replaceAll(".", ""))
      );
    });

    const ebitda = totalRevenue.map((value, index) => {
      return Number(value.replaceAll(".", "")) - totalExpenses[index];
    });
    return ebitda;
  };

  const computePlatformInvestmentArray = () => {
    const capexTotal = state.data.CAPEX[state.data.CAPEX.length - 1].data;
    const acquisitionTotal =
      state.data.acquisitionCosts[state.data.acquisitionCosts.length - 1].data;

    const platformInvestment = capexTotal.map((value, index) => {
      return (
        Number(value.replaceAll(".", "")) +
        Number(acquisitionTotal[index].replaceAll(".", ""))
      );
    });

    return platformInvestment;
  };

  const computeYieldOnCostArray = () => {
    const ebitda = state.data.EBITDA[state.data.EBITDA.length - 1].data;
    const platformInvestment =
      state.data.platformInvestment[state.data.platformInvestment.length - 1]
        .data;
    const cumulativePlatformInvestment = platformInvestment.reduce(
      (acc, value, index) => {
        acc[index] = Number(value.replaceAll(".", "")) + (acc[index - 1] || 0);
        return acc;
      },
      []
    );

    const yieldOnCost = ebitda.map((value, index) => {
      if (cumulativePlatformInvestment[index] === 0) return 0;
      return (
        ((Number(value.replaceAll(".", "")) * 12) /
          cumulativePlatformInvestment[index]) *
        100
      );
    });

    return yieldOnCost;
  };

  /* HELPERS */
  const isSameMonthAndYear = (date1, date2) => {
    if (
      date1 &&
      date2 &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  const isPreviousToStartDate = (date) => {
    if (
      date &&
      ((date.getMonth() < START_MONTH - 1 &&
        date.getFullYear() <= START_YEAR) ||
        date.getFullYear() < START_YEAR)
    ) {
      return true;
    }
    return false;
  };

  const generateCategory = (row, formatter = (data) => data) => ({
    altCounter: 0,
    altData: [],
    dialogChildren: row.dialogChildren,
    counter: 0,
    data: [],
    formatData: formatter,
    info: MONTHS_DATA.map(() => []),
    title: row.title,
    clickable: row.clickable,
  });

  // CHECK RENTED_CENTERS_IDS
  const incrementNutCounters = (nut, center, monthIndex) => {
    if (RENTED_CENTERS_IDS.includes(center.id)) {
      nut.assets.altCounter++;
    }
    nut.assets.counter++;
    nut.lettableArea.counter += center.lettableArea;

    nut.assets.info[monthIndex].push(center);
  };

  const decrementNutCounters = (nut, center, monthIndex) => {
    //TODO: remove from info array
    if (RENTED_CENTERS_IDS.includes(center.id)) {
      nut.assets.altCounter--;
    }
    nut.assets.counter--;
    nut.lettableArea.counter -= center.lettableArea;
  };

  const incrementFranchiseCounters = (franchise, center) => {
    franchise.assets.counter++;
    franchise.lettableArea.counter += center.lettableArea;
    franchise.royalty.counter += center.royalty * center.maxRevenue;
    franchise.maxRevenue.counter += center.maxRevenue;
  };

  const decrementFranchiseCounters = (franchise, center) => {
    franchise.assets.counter--;
    franchise.lettableArea.counter -= center.lettableArea;
    franchise.royalty.counter -= center.royalty * center.maxRevenue;
    franchise.maxRevenue.counter -= center.maxRevenue;
  };

  const generateCategoryObject = (category) => {
    return Object.keys(category).reduce((obj, key) => {
      obj[key] = generateCategory(
        category[key],
        key === "yieldOnCost" ? localeFormat : localeFormatInteger
      );
      return obj;
    }, {});
  };

  const generateFilteredProviderInvoices = (providerInvoices, filters) => {
    return filters.reduce((obj, filter) => {
      obj[filter.key] = providerInvoices.filter((providerInvoice) =>
        filter.expenseTypeIds.includes(providerInvoice.expenseTypeId)
      );
      return obj;
    }, {});
  };

  const generateProcessedData = (category) => {
    return Object.values(category).map(
      ({ title, data, formatData, info, dialogChildren, clickable }) => ({
        title,
        data: data.map((value) => formatData(value)),
        info,
        dialogChildren,
        clickable,
      })
    );
  };

  const getCSVData = () => {
    const csvData = [];
    const flatData = Object.values(state.data).flatMap((value) => value);
    flatData.forEach((row, index) => {
      const csvObject = {};
      csvObject.title = row.title;
      row.data.forEach((value, index) => {
        const month = TRACKING_HEADERS[index + 1]?.label;
        csvObject[month] = value;
      });
      csvData.push(csvObject);
    });
    dispatch({ type: "SET_CSV_DATA", payload: csvData });
  };

  const getMonthsBetweenDates = (startDate, endDate) => {
    const months = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      months.push(new Date(currentDate));
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );
    }
    return months;
  };

  const removeUsedProviderInvoices = (
    providerInvoices,
    usedProviderInvoicesObject
  ) => {
    return providerInvoices.filter((providerInvoice) => {
      return !Object.values(usedProviderInvoicesObject).some((arr) =>
        arr.some((item) => item.id === providerInvoice.id)
      );
    });
  };

  const getCenterStatus = (center, month) => {
    const openingDate = center.openingDate
      ? new Date(center.openingDate)
      : null;
    const acquisitionDate = center.acquisitionDate
      ? new Date(center.acquisitionDate)
      : null;
    const closeDate = center.closeDate ? new Date(center.closeDate) : null;
    if (openingDate > month || !openingDate) return -1;
    if (closeDate) {
      if (isSameMonthAndYear(closeDate, month) || closeDate < month) return -1;
    }
    if (center.type === TYPE_FRANCHISE) {
      if (openingDate) {
        if (isSameMonthAndYear(openingDate, month) || openingDate < month)
          return 1;
      }
    }
    if (center.type === TYPE_OWN) {
      if (openingDate && !acquisitionDate) {
        if (isSameMonthAndYear(openingDate, month) || openingDate < month)
          return 0;
      }
      if (openingDate && acquisitionDate) {
        if (
          isSameMonthAndYear(acquisitionDate, month) ||
          acquisitionDate < month
        )
          return 0;
        if (isSameMonthAndYear(openingDate, month) || openingDate < month)
          return 1;
      }
    }
  };

  /* DIALOG */

  const setDialog = (row, editable = false) => {
    const payload = {
      open: true,
      title: row.title,
      children: editable ? null : row.dialogChildren,
    };
    dispatch({ type: "SET_DIALOG", payload });
  };

  const setDataDialog = (row, monthIndex) => {
    const data = row.info[monthIndex];
    const processedData = data.map((item) => {
      let link = "/app";
      if (item.type) link = null; //Acquisition asset cost
      else if (item.Invoice?.id)
        link += "/invoice/" + item.Invoice.id; //Invoice item
      else link += "/provider-invoice/" + item.id; //Provider invoice
      return {
        id: item.id,
        concept: item.concept || item.title || item.description,
        date: item.date || item.Invoice?.issueDate,
        amount: item.amount || item.base,
        link,
      };
    });
    const payload = {
      open: true,
      title: row.title + " | " + TRACKING_HEADERS[monthIndex + 1].label,
      children: DataDialogChildren(processedData, t),
    };
    dispatch({ type: "SET_DIALOG", payload });
  };

  const closeDialog = () => {
    dispatch({ type: "SET_DIALOG_OPEN", payload: false });
    dispatch({ type: "RESET_MODIFY_DATA" });
  };

  const handleSetModifyData = (e) => {
    dispatch({
      type: "SET_MODIFY_DATA",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const editPersonnelData = (rowTitle) => {
    const type = Object.entries(ROWS).find(
      (entry) => entry[1].title === rowTitle
    )[0];
    const date = new Date(MONTHS_DATA[state.modifyData.monthIndex].setDate(15));
    const params = {
      type,
      amount: state.modifyData.value,
      date,
    };
    api
      .post("/personnel-expenses/create", params)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("dataModified"), { variant: "success" });
          // Provisional data fetching only from personnelExpenses
          let data = state.data.personnelExpenses;
          data = data.map((row) => {
            if (row.title === rowTitle) {
              row.data[state.modifyData.monthIndex] = localeFormatInteger(
                state.modifyData.value
              );
            }
            return row;
          });
          dispatch({
            type: "SET_DATA",
            payload: { name: "personnelExpenses", value: data },
          });
          closeDialog();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <>
      <Box height="100%" overflow="auto">
        <Table component={Paper}>
          {Object.values(state.dataProcessed).every(
            (value) => value === true
          ) ? (
            <>
              <TableHead>
                <TableRow>
                  {TRACKING_HEADERS.map((column, index) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        minWidth: column.minWidth,
                        position: "sticky",
                        left: index === 0 ? 0 : "auto",
                        top: 0,
                        zIndex: index === 0 ? 2 : 1,
                        backgroundColor: index === 0 ? "#f4f4f4" : "white",
                      }}
                    >
                      {index === 0 ? (
                        <ButtonCSV
                          data={state.csvData}
                          headers={TRACKING_HEADERS.map((header, index) => ({
                            key: index === 0 ? "title" : header.label,
                            label: header.label,
                          }))}
                          filename={t("tracking")}
                          disabled={state.csvData.length === 0}
                        />
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(state.data)?.map((array, index) => (
                  <Fragment key={index}>
                    {Array.isArray(array) &&
                      array.map((row, rowIndex) => (
                        <TableRowComponent
                          key={rowIndex}
                          row={row}
                          rowIndex={rowIndex}
                          dataLength={array.length}
                          setDialog={setDialog}
                          setDataDialog={setDataDialog}
                          handleSetModifyData={handleSetModifyData}
                          ROWS={ROWS}
                        />
                      ))}
                    {index !== Object.values(state.data).length - 1 && (
                      <TableRow>
                        <TableCell sx={{ py: 1 }} />
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={500}
            >
              <CircularProgress />
            </Box>
          )}
        </Table>
      </Box>
      <Dialog
        open={state.dialog.open}
        onClose={closeDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">{state.dialog.title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {state.dialog.children ? (
            state.dialog.children
          ) : (
            <Grid container spacing={1}>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">{state.dialog.info}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label={t("date")}
                    name="monthIndex"
                    value={state.modifyData.monthIndex}
                    onChange={handleSetModifyData}
                    options={TRACKING_HEADERS.filter(
                      (month) => month.label !== ""
                    ).map((month, index) => ({
                      value: index,
                      label: month.label,
                    }))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("amount")}
                    name="value"
                    value={state.modifyData.value}
                    onChange={handleSetModifyData}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <CustomButton
                    color="primary"
                    onClick={() => {
                      editPersonnelData(state.dialog.title);
                    }}
                  >
                    {t("save")}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const TableRowComponent = ({
  row,
  rowIndex,
  dataLength,
  setDialog,
  setDataDialog,
  handleSetModifyData,
  ROWS,
}) => {
  const isLastRow = rowIndex === dataLength - 1;

  const isEditableRow = [
    ROWS.WAGES_AND_SALARIES.title,
    ROWS.SOCIAL_SECURITY.title,
  ].includes(row.title);

  return (
    <TableRow key={rowIndex}>
      <TableCell
        sx={{
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderTop:
            rowIndex === 0
              ? BLACK_BORDER
              : row.total
              ? LIGHT_GREY_BORDER
              : "none",
          borderRight: GREY_BORDER,
          borderBottom: isLastRow ? BLACK_BORDER : "none",
          backgroundColor: rowIndex % 2 ? "white" : LIGHT_GREY_BACKGROUND,
          py: 1,
        }}
      >
        <Typography
          variant="body2"
          fontWeight="medium"
          style={{ cursor: "pointer" }}
          onClick={() => setDialog(row)}
        >
          {row.title}
        </Typography>
      </TableCell>
      {row.data.map((cellValue, cellIndex) => (
        <TableCell
          key={cellIndex}
          sx={{
            borderTop:
              rowIndex === 0
                ? BLACK_BORDER
                : row.total
                ? LIGHT_GREY_BORDER
                : "none",
            borderBottom: isLastRow ? BLACK_BORDER : "none",
            backgroundColor: rowIndex % 2 ? "white" : LIGHT_GREY_BACKGROUND,
            py: 1,
            cursor: row.clickable && "pointer",
          }}
          onClick={() => {
            isEditableRow
              ? setDialog(row, true)
              : row.clickable && setDataDialog(row, cellIndex);
            handleSetModifyData({
              target: { name: "monthIndex", value: cellIndex },
            });
          }}
        >
          {cellValue}
        </TableCell>
      ))}
    </TableRow>
  );
};

const InfoDialogChildren = (
  info,
  expenseTypes = null,
  expenseTypesIds = null
) => {
  const { user } = useContext(AppContext);
  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body2">{info}</Typography>
        </Grid>
        {expenseTypes &&
          expenseTypesIds &&
          expenseTypes
            .filter((expenseType) => expenseTypesIds.includes(expenseType.id))
            .map((expenseType) => (
              <Grid item xs={6} key={expenseType.id}>
                {user.hasPage("EXPENSE TYPES") ? (
                  <ButtonLink
                    to={"/app/expense-type/" + expenseType.id}
                    target="_blank"
                  >
                    {expenseType.name}
                  </ButtonLink>
                ) : (
                  <Typography variant="body2">{expenseType.name}</Typography>
                )}
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
};

const DataDialogChildren = (data, t) => {
  const DATA_DIALOG_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      flex: 3,
    },
    {
      field: "date",
      headerName: t("date"),
      flex: 1,
    },
    {
      field: "amount",
      headerName: t("amount"),
      flex: 1,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "link",
      headerName: t("actions"),
      flex: 1,
      renderCell: ({ value }) =>
        value && (
          <ButtonLink to={value} target="_blank">
            {t("view")}
          </ButtonLink>
        ),
    },
  ];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box style={{ height: 400, width: "100%" }}>
          <DataGrid rows={data} columns={DATA_DIALOG_COLUMNS} />
        </Box>
      </Grid>
    </Grid>
  );
};
