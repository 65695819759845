import { useState, useContext, useEffect, createContext } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { Box, CircularProgress, Grid } from "@mui/material";

import AppContext from "../../../../context/AppContext";

import Page from "../../../global/structure/Page";
import Filters from "../../../global/structure/Filters";
import Tabs from "../../../global/structure/Tabs";
import Select from "../../../global/inputs/Select";

import GeneralTab from "./CentersTabs/GeneralTab";
import InvoicingTab from "./CentersTabs/InvoicingTab";
import ObjectivesTab from "./CentersTabs/ObjectivesTab";
import ContractsTab from "./CentersTabs/ContractsTab";

import SearchButton from "../../../Inputs/SearchButton";

import {
  BLOCKED_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
} from "../../../../data/constants";

export const CentersContext = createContext();

const initialState = {
  centers: [],
};

const initialFiltersData = {
  states: [],
  types: [],
  provinces: [],
  autonomousCommunities: [],
  centers: [],
};

const initialFilters = {
  states: [1],
  types: [],
  provinces: [],
  autonomousCommunities: [],
  centers: [],
};

const getCenters = async (api) => {
  return api
    .get("/centers", {
      params: {
        include: ["Box", "Contract", "City", "AcquisitionAsset"],
        showInactive: true,
      },
    })
    .then((res) => res.data)
    .catch((e) => ({ error: e }));
};

const getRegions = async (api) => {
  return api
    .get("/regions")
    .then((res) => res.data)
    .catch((e) => ({ error: e }));
};

function Centers() {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("dashboard");
  const [loaded, setLoaded] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersData, setFiltersData] = useState(initialFiltersData);
  const [data, setData] = useState(initialState);

  const getData = async () => {
    try {
      setLoaded(false);
      const [regions, centers] = await Promise.all([
        getRegions(api),
        getCenters(api),
      ]);

      if (regions?.error || centers?.error) {
        const error = regions?.error || centers?.error;
        enqueueSnackbar(error, { variant: "error" });
        console.log("error", error);
        return;
      }

      const parsedCenters = centers.map((center) => {
        const centerProvinceId = center.City?.regionId;
        const aCId = regions.find((r) => r.id === centerProvinceId)?.parentId;

        // Current values
        const totalMeters = center.Boxes.reduce(
          (acc, box) => acc + box.meters,
          0
        );
        const allContracts = center.Boxes.reduce(
          (acc, box) => [...acc, ...box.Contracts],
          []
        );
        const occupiedBoxes = center.Boxes.filter(
          (box) =>
            box.state === OCCUPIED_BOX_STATE_ID ||
            (box.state === BLOCKED_BOX_STATE_ID &&
              getCurrentContract(box.Contracts))
        );
        const occupiedMeters = occupiedBoxes.reduce(
          (acc, box) => acc + box.meters,
          0
        );
        const theoreticalInvoicing = occupiedBoxes.reduce((acc, box) => {
          const contract = getCurrentContract(box.Contracts);
          if (!contract) return acc;
          return acc + contract.meters * contract.pricePerMeter;
        }, 0);
        const occupancy = totalMeters
          ? (occupiedMeters / totalMeters) * 100
          : 0;
        const pricePerMeter =
          occupiedMeters > 0 ? theoreticalInvoicing / occupiedMeters : 0;

        // Objectives
        const objectivePPM = center.AcquisitionAsset?.sqmPriceMonth;
        const objectiveOccupancy = center.AcquisitionAsset?.occupancy;
        const objectiveOccupiedMeters =
          (objectiveOccupancy / 100) * totalMeters;
        const objectiveInvoicing = objectiveOccupiedMeters * objectivePPM;

        return {
          ...center,
          Province: regions.find((r) => r.id === centerProvinceId),
          AC: regions.find((r) => r.id === aCId),
          occupiedBoxes: occupiedBoxes,
          occupiedMeters: occupiedMeters || 0,
          occupancy: occupancy || 0,
          totalMeters: totalMeters || 0,
          pricePerMeter: pricePerMeter || 0,
          theoreticalInvoicing: theoreticalInvoicing || 0,
          objectivePPM: objectivePPM || null,
          objectiveOccupancy: objectiveOccupancy || null,
          objectiveOccupiedMeters: objectiveOccupiedMeters || null,
          objectiveInvoicing: objectiveInvoicing || null,
          allContracts: allContracts,
        };
      });

      setData({
        ...data,
        centers: parsedCenters,
      });

      setFiltersData({
        ...filtersData,
        states: [
          { value: 1, label: t("active") },
          { value: 0, label: t("noActive") },
        ],
        types: [
          { value: 0, label: t("nut") },
          { value: 1, label: t("franchise") },
          { value: 2, label: t("other") },
        ],
        provinces: regions
          .filter((r) => r.parentId)
          .map((region) => ({
            value: region.id,
            label: region.name,
            parentId: region.parentId,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        autonomousCommunities: regions
          .filter((r) => !r.parentId)
          .map((region) => ({
            value: region.id,
            label: region.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        centers: parsedCenters
          .map((center) => ({
            value: center.id,
            label: center.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
      setLoaded(true);
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const filteredCenters = applyFilters(data.centers, filters);

  const provinces = filtersData.provinces.filter((province) => {
    if (!filters.autonomousCommunities.length) return true;
    return filters.autonomousCommunities.includes(province.parentId);
  });

  const centers = filtersData.centers.filter((c) => {
    const center = data.centers.find((center) => center.id === c.value);
    if (filters.types.length && !filters.types.includes(center.type)) {
      return false;
    }
    if (
      filters.autonomousCommunities.length &&
      (!center.AC || !filters.autonomousCommunities.includes(center.AC.id))
    ) {
      return false;
    }
    if (
      filters.provinces.length &&
      (!center.Province || !filters.provinces.includes(center.Province.id))
    ) {
      return false;
    }
    return true;
  });

  return (
    <CentersContext.Provider
      value={{ centers: data.centers, filteredCenters: filteredCenters }}
    >
      <Page browserTitle={t("centers")} paper={true}>
        <Grid container spacing={3}>
          <Grid item>
            <Filters
              filters={[
                <SearchButton
                  onClick={getData}
                  loading={!loaded}
                  disabled={!loaded}
                />,
              ]}
            />
          </Grid>
          <Grid item>
            <Filters
              filters={[
                <Select
                  multiple
                  searchable
                  name="state"
                  label={t("state")}
                  value={filters.states}
                  options={filtersData.states}
                  disabled={!loaded}
                  onChange={(e) =>
                    setFilters({ ...filters, states: e.target.value })
                  }
                />,
                <Select
                  multiple
                  searchable
                  name="type"
                  label={t("type")}
                  value={filters.types}
                  options={filtersData.types}
                  disabled={!loaded}
                  onChange={(e) =>
                    setFilters({ ...filters, types: e.target.value })
                  }
                />,
                <Select
                  multiple
                  searchable
                  name="autonomousCommunities"
                  label={t("autonomousCommunities")}
                  value={filters.autonomousCommunities}
                  options={filtersData.autonomousCommunities}
                  disabled={!loaded}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      autonomousCommunities: e.target.value,
                    })
                  }
                />,
                <Select
                  multiple
                  searchable
                  name="province"
                  label={t("province")}
                  value={filters.provinces}
                  options={provinces}
                  disabled={!loaded}
                  onChange={(e) =>
                    setFilters({ ...filters, provinces: e.target.value })
                  }
                />,

                <Select
                  multiple
                  searchable
                  name="center"
                  label={t("center")}
                  value={filters.centers}
                  options={centers}
                  disabled={!loaded}
                  onChange={(e) =>
                    setFilters({ ...filters, centers: e.target.value })
                  }
                />,
              ]}
            />
          </Grid>
        </Grid>
        {loaded ? (
          <Grid item marginTop={2}>
            <Tabs
              tabs={[
                {
                  label: t("general"),
                  content: <GeneralTab />,
                },
                {
                  label: t("invoicing"),
                  content: <InvoicingTab />,
                },
                {
                  label: t("objectives"),
                  content: <ObjectivesTab />,
                },
                {
                  label: t("contracts"),
                  content: <ContractsTab />,
                },
              ]}
            />
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        )}
      </Page>
    </CentersContext.Provider>
  );
}

const applyFilters = (centers, filters) => {
  return centers.filter((center) => {
    const centerState = center.active === true ? 1 : 0;
    const centerType = center.type;
    const centerProvinceId = center.Province?.id;
    const centerACId = center.AC?.id;
    return (
      (!filters.states.length || filters.states.includes(centerState)) &&
      (!filters.types.length || filters.types.includes(centerType)) &&
      (!filters.provinces.length ||
        filters.provinces.includes(centerProvinceId)) &&
      (!filters.autonomousCommunities.length ||
        filters.autonomousCommunities.includes(centerACId)) &&
      (!filters.centers.length || filters.centers.includes(center.id))
    );
  });
};

export const getCurrentContract = (contracts) => {
  if (!contracts || !contracts.length) return null;
  for (let contract of contracts) {
    if (!contract.endDate) return contract;
  }
  const today = new Date();
  for (let contract of contracts) {
    if (
      new Date(contract.startDate) <= today &&
      new Date(contract.endDate) >= today
    )
      return contract;
  }
  return null;
};

export default Centers;
