import { ButtonBase, Box, Card, Grid, Icon, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import PropTypes from "prop-types";

import { DEFAULT_VALUES } from "../Utilities";
import { ITEM_ICONS, ITEM_SHAPES } from "../Utilities";
import Dialog from "../../../../global/Dialog";
import CreateImageDialog from "./CreateImageDialog";

const CreateItemDialog = (props) => {
  const {
    setCreateItemDialog,
    selectedLayerId,
    selectedLayerLength,
    isOpen,
    onCreateItem,
    onClose,
  } = props;
  const [t] = useTranslation("floorPlans");

  const [imageIsOpen, setImageIsOpen] = useState(false);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onClose && onClose()}
        maxWidth="md"
        title={t("createItem")}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {ITEM_SHAPES.map((shape, index) => (
            <Grid item key={index}>
              <Tooltip title={t(shape.label)} placement="top">
                <ButtonBase
                  onClick={() => {
                    shape.value === "Image"
                      ? setImageIsOpen(true)
                      : onCreateItem({
                          ...DEFAULT_VALUES[shape.value],
                          name: "New " + shape.label,
                          floorPlanLayerId: selectedLayerId,
                        });
                  }}
                >
                  <Card elevation={2}>
                    <Box
                      position="relative"
                      width={100}
                      height={100}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Icon color="primary">{ITEM_ICONS[shape.value]}</Icon>
                    </Box>
                  </Card>
                </ButtonBase>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Dialog>

      <CreateImageDialog
        isOpen={imageIsOpen}
        setDialogState={(state) => setImageIsOpen(state)}
        handleCreateItem={(shape, image) => {
          if (!image) return;
          onCreateItem({
            ...DEFAULT_VALUES[shape],
            ...image,
            name: "New " + shape,
            floorPlanLayerId: selectedLayerId,
          });
          setImageIsOpen(false);
        }}
      />
    </>
  );
};

CreateItemDialog.propTypes = {
  setCreateItemDialog: PropTypes.func,
  selectedLayerId: PropTypes.number,
  selectedLayerLength: PropTypes.number,
  isOpen: PropTypes.bool,
  onCreateItem: PropTypes.func,
};

export default CreateItemDialog;
