import { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// mui
import { ButtonGroup, Grid } from "@mui/material";

// components & utils
import { CustomTable } from "../../../CustomTable";
import { removeTime } from "../../../../utils/date";
import { getParams, generateURL } from "../../../../utils/url";
import { localeFormat } from "../../../../utils/format";
import AppContext from "../../../../context/AppContext";
import Button from "../../../Inputs/CustomButton";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import CenterSelect from "../../../Inputs/CenterSelect";
import ItemsSummary from "../../../ItemsSummary";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";

import Filters from "../../../global/structure/Filters";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  recuperatedInvoices: [],
  filters: {
    autoSearch: "false",
    customerName: "",
    invoiceNumber: "",
    centerIds: [],
    hasNonPayments: true,

    orderBy: "",
    order: "",

    number: "",
    dateFrom: "",
    dateUntil: "",
    recuperatorName: "",
    recuperateFrom: "",
    recuperateUntil: "",
  },
  options: {
    loaded: true,
    rowlink: "non-payment",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_RECUPERATED_INVOICES":
      return { ...state, recuperatedInvoices: action.payload };
    case "SET_LOADED_TRUE":
      return {
        ...state,
        options: { ...state.options, loaded: true },
      };
    case "SET_LOADED_FALSE":
      return {
        ...state,
        options: { ...state.options, loaded: false },
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_FILTERS":
      return {
        ...state,
        filters: initialState.filters,
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

function RecuperationsTab(props) {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { currentTab, history, CSV_INVOICE_HEADERS, INVOICE_COLUMNS, FILTERS } =
    props;

  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const query = useQuery();

  const INVOICE_RECUPERATION_COLUMNS = [...INVOICE_COLUMNS];

  user.hasAction("VIEW_NONPAYMENT_MANAGEMENT") &&
    INVOICE_RECUPERATION_COLUMNS.push({
      key: "NonPayments",
      label: t("recuperatedBy"),
      sortType: "other",
      renderFunction: (value, item) => value[0].recuperatorName,
    });

  INVOICE_RECUPERATION_COLUMNS.push({
    key: "recuperationDate",
    label: t("recuperationDate"),
    sortType: "string",
    renderFunction: (value) => (value ? removeTime(value) : ""),
  });

  const initNonPaymentsState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    initNonPaymentsState
  );

  useEffect(() => {
    if (state.filters.autoSearch === "true") {
      getInvoices();
    }
  }, [state.filters.autoSearch]);

  const RECUPERATIONS_SUMMARY = [
    {
      translatedText: t("invoiceNumber"),
      value: state.recuperatedInvoices?.length,
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.recuperatedInvoices?.reduce(
            (value, element) => value + element.totalAmount,
            0
          )
        ).toString() + " €",
    },
  ];

  const getInvoices = () => {
    updateQuery();
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = { include: ["NonPayment"] };

    state.filters.number !== "" && (params.number = state.filters.number);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.centerIds.length !== 0 &&
      (params.centerIds = state.filters.centerIds);
    state.filters.recuperatorName !== "" &&
      (params.recuperatorName = state.filters.recuperatorName);
    state.filters.recuperateUntil !== "" &&
      (params.recuperateUntil = state.filters.recuperateUntil);
    state.filters.recuperateFrom !== "" &&
      (params.recuperateFrom = state.filters.recuperateFrom);

    api
      .get("/invoices-non-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          let recuperatedInvoices = response.data.filter(
            (data) =>
              data.NonPayments?.length !== 0 &&
              data.NonPayments[data.NonPayments.length - 1].recuperationDate !==
                null
          );
          recuperatedInvoices.forEach(
            (invoice) =>
              (invoice.nonPaymentDate =
                invoice.NonPayments[
                  invoice.NonPayments.length - 1
                ].createdAt.split("T")[0])
          );
          recuperatedInvoices.forEach(
            (invoice) =>
              (invoice.recuperationDate =
                invoice.NonPayments[invoice.NonPayments.length - 1]
                  .recuperationDate || "--")
          );
          dispatch({
            type: "SET_RECUPERATED_INVOICES",
            payload: recuperatedInvoices,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const updateQuery = () => {
    let params = {
      tab: currentTab,
      ...state.filters,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  const search = () => {
    if (state.filters.autoSearch === "true") {
      getInvoices();
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
    }
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              label={t("number/name")}
              value={state.filters.number}
              name="number"
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <CenterSelect
              multiple
              name="centerIds"
              onChange={handleChangeFilter}
              value={state.filters.centerIds}
            />,
            <TextInput
              label={t("dateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateFrom}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="dateFrom"
            />,
            <TextInput
              label={t("dateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateUntil}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="dateUntil"
            />,
            user.hasAction("VIEW_NONPAYMENT_MANAGEMENT") && (
              <Grid item>
                <TextInput
                  label={t("recuperatedBy")}
                  value={state.filters.recuperatorName}
                  name="recuperatorName"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                />
              </Grid>
            ),
            <TextInput
              label={t("recuperateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.recuperateFrom}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="recuperateFrom"
            />,
            <TextInput
              label={t("recuperateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.recuperateUntil}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="recuperateUntil"
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ButtonGroup variant="contained">
          <Button onClick={resetFilters}>{t("reset")}</Button>
          <ButtonCSV
            data={state.recuperatedInvoices}
            headers={CSV_INVOICE_HEADERS}
            filename={t("recuperatedInvoices")}
          />
          <SearchButton onClick={search} loading={!state.options.loaded} />
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <ItemsSummary gridItems={RECUPERATIONS_SUMMARY} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={INVOICE_RECUPERATION_COLUMNS}
          data={state.recuperatedInvoices}
          sortBy={state.filters.orderBy}
          sort={state.filters.order}
          options={{
            rowlink: user.hasPage("INVOICES") ? "invoice" : null,
            loaded: state.options.loaded,
          }}
          onSortCallback={(orderBy, order) => {
            handleOrderChange(orderBy, order);
            const url = generateURL("/app/non-payments", {
              ...state.filters,
              orderBy,
              order,
            });
            history.push(url);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default RecuperationsTab;
