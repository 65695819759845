import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../context/AppContext";

import CustomButton from "../Inputs/CustomButton";
import CustomSelect from "../Inputs/CustomSelect";
import Dialog from "../global/Dialog";
import LabeledText from "../global/LabeledText";

import { CUSTOMER_ROLE_ID } from "../../data/constants";

function CustomerUserDialog(props) {
  const [t] = useTranslation("customers");
  const [tErrors] = useTranslation("errors");

  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { open, customerUser, emails, onClose } = props;

  const [roles, setRoles] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    loading: false,
  });

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (customerUser) {
      setSelectedEmail(customerUser.name);
    } else setSelectedEmail("");
  }, [customerUser]);

  const getRoles = () => {
    api
      .get("/roles")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          setRoles(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const updateName = () => {
    api
      .post(`/users/${customerUser.id}/name`, { name: selectedEmail })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nameUpdated"), { variant: "success" });
          onClose && onClose(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetPassword = () => {
    api
      .post(`/users/${customerUser.id}/set-password`, {
        newPassword: generateRandomPassword(),
        sendEmail: true,
      })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("passwordReset"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setConfirmDialog({ open: false, loading: false });
      });
  };

  const userData = getUserData(user, customerUser, roles);
  return (
    <>
      <Dialog
        open={open}
        title={t("customerUser")}
        onClose={() => {
          onClose && onClose(false);
        }}
        children={
          <>
            {userData.canEditName ? (
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  display={"flex"}
                  alignItems={"center"}
                  marginTop={1}
                  spacing={2}
                >
                  <Grid item xs={1.5}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("name") + ":"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <CustomSelect
                      label={t("emails")}
                      value={selectedEmail}
                      onChange={(e) => setSelectedEmail(e.target.value)}
                      options={emails?.map((email) => ({
                        value: email.email,
                        label: email.email,
                      }))}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  display={"flex"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1.5}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("role") + ":"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">{userData.roleName}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  display={"flex"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1.5}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("actions") + ":"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <CustomButton
                      loading={confirmDialog.loading}
                      onClick={() => {
                        setConfirmDialog({ open: true, loading: true });
                      }}
                    >
                      {t("resetPassword")}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container item spacing={3}>
                <Grid item>
                  <LabeledText label={t("name")} value={userData.userName} />
                </Grid>
                <Grid item>
                  <LabeledText label={t("role")} value={userData.roleName} />
                </Grid>
              </Grid>
            )}
          </>
        }
        actions={
          <CustomButton
            color="success"
            disabled={!customerUser || customerUser.name === selectedEmail}
            onClick={updateName}
          >
            {t("save")}
          </CustomButton>
        }
      />
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, loading: false })}
        title={t("confirm")}
        children={
          <Grid container>
            <Grid item>
              <Typography variant="body1">
                {t("deleteCustomerUserConfirm")}
              </Typography>
            </Grid>
          </Grid>
        }
        actions={
          <>
            <CustomButton
              variant={"text"}
              onClick={() => setConfirmDialog({ open: false, loading: false })}
            >
              {t("cancel")}
            </CustomButton>
            <CustomButton
              onClick={() => {
                setConfirmDialog({ open: false, loading: true });
                resetPassword();
              }}
            >
              {t("confirm")}
            </CustomButton>
          </>
        }
      />
    </>
  );
}

const getUserData = (user, customerUser, roles) => {
  const userName = customerUser ? customerUser.name : "";
  const sameUser = customerUser ? customerUser.id === user.id : false;
  const canEdit = user.hasAction("EDIT_USERS");
  const canEditCustomerUser =
    user.hasAction("EDIT_CUSTOMER_USERS") &&
    customerUser &&
    customerUser.roleId === CUSTOMER_ROLE_ID;
  const roleId = customerUser?.roleId;
  const roleName = roles.find((role) => role.id === roleId)?.name;
  const canEditName = sameUser || canEdit || canEditCustomerUser;
  return {
    canEdit,
    canEditCustomerUser,
    canEditName,
    roleName,
    sameUser,
    userName,
  };
};

const generateRandomPassword = () => {
  const allowedCharacters =
    "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789%$&@#*!?";
  const PASSWORD_LENGTH = 8;
  let password = "";
  for (let i = 0; i < PASSWORD_LENGTH; i++) {
    password += allowedCharacters.charAt(
      Math.floor(Math.random() * allowedCharacters.length)
    );
  }
  return password;
};

export default CustomerUserDialog;
