import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";
import PropTypes, { oneOfType } from "prop-types";
import React, { useEffect, useState } from "react";

import {
  ISSUED_INVOICE_STATE_ID,
  PAID_INVOICE_STATE_ID,
  EXPIRED_INVOICE_STATE_ID,
  UNPAID_INVOICE_STATE_ID,
  BILLING_ERROR_STATE_ID,
} from "../../data/constants";

/**
 * InvoiceStateSelect Component
 *
 * This component is used to render a select input for choosing invoice states.
 * It provides predefined invoice state options with associated labels and allows for single or multiple selections.
 *
 * @param {Object} props - The props for the InvoiceStateSelect component.
 * @param {boolean} [props.multiple] - If true, allows multiple selections.
 * @param {Function} props.onChange - A callback function to handle changes in the selected value(s).
 * @param {string} [props.value] - The initial value of the select input.
 * @param {string} [props.label] - The label to display above the select input.
 * @returns {JSX.Element} - A React component for selecting invoice states.
 */

const InvoiceStateSelect = (props) => {
  const { multiple, onChange, value = [], label, ...rest } = props;
  const [selectedInvoiceState, setSelectedInvoiceState] = useState(value);
  const [t] = useTranslation("invoices");

  const invoiceStateOptions = [
    { value: ISSUED_INVOICE_STATE_ID, label: t("issued") },
    { value: PAID_INVOICE_STATE_ID, label: t("paid") },
    { value: EXPIRED_INVOICE_STATE_ID, label: t("expired") },
    { value: UNPAID_INVOICE_STATE_ID, label: t("unpaid") },
    { value: BILLING_ERROR_STATE_ID, label: t("billingError") },
  ];

  useEffect(() => setSelectedInvoiceState(value), [value]);

  return (
    <CustomSelect
      label={label || t("invoiceStates")}
      multiple={multiple}
      sx={{ maxWidth: 230 }}
      value={selectedInvoiceState}
      options={invoiceStateOptions}
      onChange={(e) => {
        setSelectedInvoiceState(e.target.value);
        onChange && onChange(e);
      }}
      {...rest}
    />
  );
};

InvoiceStateSelect.propTypes = {
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
};

export default InvoiceStateSelect;
