import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import * as d3 from "d3";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";

import { NewChartContainer } from "./NewChartContainer";
import { colors } from "../Components/ColorGenerator";
import {
  getDataArray,
  getDates,
  getMonths,
  sumArray,
} from "../../../../utils/chartUtils";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import EuroIcon from "@mui/icons-material/Euro";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SquareFootIcon from "@mui/icons-material/SquareFoot";

import { ChartContainer } from "./ChartContainer";

import { localeFormat } from "../../../../utils/format";
import {
  // Payment methods
  CASH_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  CONSOLIDATION_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,

  // Nonpayment reasons
  UNPAID_REASON_ID,
  RETURNED_RECEIPT_REASON_ID,
  DENIED_CARD_REASON_ID,
  UNREALIZED_TRANSFER_REASON_ID,

  // Merchantable types
  COMMISSION_MERCHANTABLE_TYPE_ID,

  // Charts index
  BAR,
  DOUGHNUT,
  LINE,
  PIE,

  // Signature types
  NOT_SIGNED,
  DIGITAL_SIGNATURE,
  PHYSICAL_SIGNATURE,
  BOTH_SIGNATURES,
} from "../../../../data/constants";

const dataSetsColors = [
  colors.primary,
  colors.secondary,
  colors.red,
  colors.brown,
  colors.yellow,
  colors.orange,
  colors.purple,
  colors.blue,
  colors.green,
  colors.turquoise,
];

const dataGetters = {
  contracts: (items) => items.length,
  price: (items) => items.reduce((acc, item) => acc + item.price, 0),
  meters: (items) => items.reduce((acc, item) => acc + item.meters, 0),
};

const getChartValue = (selected, contracts, invoiced, meters) => {
  switch (selected) {
    case "contracts":
      return contracts;
    case "invoiced":
      return invoiced;
    case "meters":
      return meters;
    default:
      return [];
  }
};

const getContractsItemsByActiveMonths = (t, dataLabels, contracts) => {
  const data = [];
  dataLabels.forEach((label) => {
    const activeContracts = contracts.filter((contract) =>
      contract.activeMonths.includes(label)
    );
    activeContracts.forEach((contract) => {
      data.push({
        ["activeContracts"]: label,
        ["customerType"]: contract.translatedCustomerTypeName,
        ["endDate"]: contract.endDate,
        ["isKeyAccount"]: contract.isKeyAccount ? t("yes") : t("no"),
        ["meters"]: contract.meters,
        ["paymentMethodName"]: contract.translatedPaymentMethodName,
        ["periodicityName"]: contract.translatedPeriodicityName,
        ["price"]: contract.price,
        ["startDate"]: contract.startDate,
      });
    });
  });
  return data;
};

export const HistoricalContractsByPaymentMethod = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const startDate = new Date(
    Math.min(...contracts.map((c) => new Date(c.startDate)))
  );

  const dataLabels = getDates(startDate, null, "month");
  const data = getContractsItemsByActiveMonths(t, dataLabels, contracts);

  const groupBy = [
    {
      propName: "activeContracts",
      labels: dataLabels,
    },
    {
      propName: "paymentMethodName",
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 3,
    responsive: true,
  };

  return (
    <NewChartContainer
      title={t("historicalContractsByPaymentMethod")}
      data={data}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalContractsByPeriodicity = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const startDate = new Date(
    Math.min(...contracts.map((c) => new Date(c.startDate)))
  );

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const dataLabels = getDates(startDate, null, "month");
  const data = getContractsItemsByActiveMonths(t, dataLabels, contracts);

  const groupBy = [
    {
      propName: "activeContracts",
      labels: dataLabels,
    },
    {
      propName: "periodicityName",
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 3,
    responsive: true,
  };

  return (
    <NewChartContainer
      title={t("historicalContractsByPeriodicity")}
      data={data}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalContractsByKeyAccounts = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const startDate = new Date(
    Math.min(...contracts.map((c) => new Date(c.startDate)))
  );

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const dataLabels = getDates(startDate, null, "month");
  const data = getContractsItemsByActiveMonths(t, dataLabels, contracts);

  const groupBy = [
    {
      propName: "activeContracts",
      labels: dataLabels,
    },
    {
      propName: "isKeyAccount",
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 3,
    responsive: true,
  };

  return (
    <NewChartContainer
      title={t("historicalContractsByKeyAccounts")}
      data={data}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalContractsByCustomerType = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const startDate = new Date(
    Math.min(...contracts.map((c) => new Date(c.startDate)))
  );

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const dataLabels = getDates(startDate, null, "month");
  const data = getContractsItemsByActiveMonths(t, dataLabels, contracts);

  const groupBy = [
    {
      propName: "activeContracts",
      labels: dataLabels,
    },
    {
      propName: "customerType",
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 3,
    responsive: true,
  };

  return (
    <NewChartContainer
      title={t("historicalContractsByCustomerType")}
      data={data}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const SignatureTypeDonutChart = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    {
      propName: t("signatureType"),
      valueCalculator: (item) => {
        if (item.signatureType === DIGITAL_SIGNATURE)
          return t("digitalSignature");
        if (item.signatureType === PHYSICAL_SIGNATURE)
          return t("physicalSignature");
        if (item.signatureType === BOTH_SIGNATURES) return t("bothSignatures");
        return t("notSigned");
      },
      colorGetter: (elements) => [
        colors.orange,
        colors.turquoise,
        colors.primary,
        colors.red,
      ],
      labels: [
        t("digitalSignature"),
        t("physicalSignature"),
        t("bothSignatures"),
        t("notSigned"),
      ],
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    aspectRatio: 2,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  return (
    <NewChartContainer
      title={t("signatureType")}
      data={contracts}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={DOUGHNUT}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={2}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const ContractsByCenterName = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = ["centerName"];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
    sortedBy: "Contracts",
    order: "desc",
  };

  const options = {
    aspectRatio: 3,
    responsive: true,
  };

  return (
    <NewChartContainer
      title={t("contractsByCenterName")}
      data={contracts}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const ContractsByDateOfPayment = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const dataTypeOnChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const labels = ["1", "5", "10", "15", "20", "25", "30"];

  const filteredContracts = contracts.filter((contract) => {
    if (!contract.dateOfPayment || contract.dateOfPayment === "") return true;
    return labels.includes(String(contract.dateOfPayment));
  });
  const groupBy = [
    {
      propName: "dateOfPayment",
      valueCalculator: (item) => {
        if (!item.dateOfPayment || item.dateOfPayment === "") return "1";
        return item.dateOfPayment;
      },
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    stacked: true,
  };

  const options = {
    responsive: true,
  };

  return (
    <NewChartContainer
      title={t("ContractsByDateOfPayment")}
      data={filteredContracts}
      groupBy={groupBy}
      config={config}
      options={options}
      chart={BAR}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={dataType}
            onChange={dataTypeOnChange}
          >
            <ToggleButton size="small" value="contracts">
              <Tooltip title={t("contracts")}>
                <DescriptionIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="price">
              <Tooltip title={t("price")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="meters">
              <Tooltip title={t("meters")}>
                <SquareFootIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalContractVariation = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [selected, setSelected] = useState("contracts");

  const dates = getMonths(
    new Date(Math.min(...contracts.map((c) => c.rawStartDate))),
    Date.now()
  );

  const labels = dates.map((date) => date.year + "-" + date.month);

  let newMeters = new Array(dates.length).fill(0);
  let cancelledMeters = new Array(dates.length).fill(0);
  let differenceMeters = new Array(dates.length).fill(0);

  let newContracts = new Array(dates.length).fill(0);
  let cancelledContracts = new Array(dates.length).fill(0);
  let differenceContracts = new Array(dates.length).fill(0);

  let newInvoiced = new Array(dates.length).fill(0);
  let cancelledInvoiced = new Array(dates.length).fill(0);
  let differenceInvoiced = new Array(dates.length).fill(0);

  dates.forEach((date, index) => {
    // Move-ins
    const moveIns = contracts.filter(
      (c) =>
        c.rawStartDate.getFullYear() === date.year &&
        c.rawStartDate.getMonth() + 1 === date.month
    );
    newContracts[index] = moveIns.length;
    newMeters[index] = moveIns.reduce(
      (sum, contract) => sum + contract.meters,
      0
    );
    newInvoiced[index] = moveIns.reduce(
      (sum, contract) => sum + contract.meters * contract.pricePerMeter,
      0
    );

    // Move-outs
    const moveOuts = contracts.filter(
      (c) =>
        c.endDate !== "" &&
        c.rawEndDate.getFullYear() === date.year &&
        c.rawEndDate.getMonth() + 1 === date.month
    );
    cancelledContracts[index] = -moveOuts.length;
    cancelledMeters[index] = -moveOuts.reduce(
      (sum, contract) => sum + contract.meters,
      0
    );
    cancelledInvoiced[index] = -moveOuts.reduce(
      (sum, contract) => sum + contract.meters * contract.pricePerMeter,
      0
    );

    // Difference: move-ins + move-outs
    differenceMeters[index] = newMeters[index] + cancelledMeters[index];
    differenceContracts[index] =
      newContracts[index] + cancelledContracts[index];
    differenceInvoiced[index] = newInvoiced[index] + cancelledInvoiced[index];
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: t("difference"),
        type: "line",
        data: getChartValue(
          selected,
          differenceContracts,
          differenceInvoiced,
          differenceMeters
        ),
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        tension: 0.3,
      },
      {
        label: t("moveIns"),
        data: getChartValue(selected, newContracts, newInvoiced, newMeters),
        backgroundColor: colors.green,
      },
      {
        label: t("moveOuts"),
        data: getChartValue(
          selected,
          cancelledContracts,
          cancelledInvoiced,
          cancelledMeters
        ),
        backgroundColor: colors.red,
      },
    ],
  };

  const options = {
    aspectRatio: 3,
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (dataPoint) => {
            const index = chartData.labels.indexOf(dataPoint.label);
            if (index === -1)
              return "An error has ocurred, please contact the developer";
            if (dataPoint.dataset.label === t("difference"))
              return `${t("contracts")}: ${differenceContracts[index]}\n ${t(
                "invoiced"
              )}: ${localeFormat(differenceInvoiced[index])}€\n ${t(
                "meters"
              )}: ${localeFormat(differenceMeters[index])}m²`;
            else if (dataPoint.dataset.label === t("moveIns"))
              return `${t("contracts")}: ${newContracts[index]}\n ${t(
                "invoiced"
              )}: ${localeFormat(newInvoiced[index])}€\n ${t(
                "meters"
              )}: ${localeFormat(newMeters[index])}m²`;
            else if (dataPoint.dataset.label === t("moveOuts"))
              return `${t("contracts")}: ${cancelledContracts[index]}\n ${t(
                "invoiced"
              )}: ${localeFormat(cancelledInvoiced[index])}€\n ${t(
                "meters"
              )}: ${localeFormat(cancelledMeters[index])}m²`;
            else return "An error has ocurred, please contact the developer";
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("moveInsAndMoveOuts")}
      chart={<Bar data={chartData} options={options} />}
    >
      <Grid item margin={2}>
        <ToggleButtonGroup exclusive value={selected}>
          <ToggleButton
            value={"contracts"}
            onClick={() => setSelected("contracts")}
          >
            <Tooltip title={t("contracts")}>
              <DescriptionIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value={"invoiced"}
            onClick={() => setSelected("invoiced")}
          >
            <Tooltip title={t("invoiced")}>
              <AttachMoneyIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={"meters"} onClick={() => setSelected("meters")}>
            <Tooltip title={t("meters")}>
              <SquareFootIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </ChartContainer>
  );
};
