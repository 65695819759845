import {
  Container,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ItemCollapsibleTemplate = (props) => {
  const { title, children } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Grid item flex={1}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item sx={{ cursor: "pointer" }}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={isOpen} sx={{ marginTop: 1 }}>
          {children}
        </Collapse>
      </Grid>
    </Grid>
  );
};

ItemCollapsibleTemplate.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
};

export default ItemCollapsibleTemplate;
