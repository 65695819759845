import {
  Alert,
  Box,
  Card,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useEffect, useContext, useReducer, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

import AppContext from "../../../context/AppContext";

import { localeFormat } from "../../../utils/format";
import CenterSelect from "../../Inputs/CenterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import Filters from "../../global/structure/Filters";
import ItemsSummary from "../../ItemsSummary";
import Page from "../../global/structure/Page";
import SearchButton from "../../Inputs/SearchButton";
import CustomerInput from "../../Inputs/CustomerInput";

const NEGATIVE_IMPORT_COLOR = "rgba(232, 202, 77, 0.8)";
const CUSTOMER = "customer";
const PAYMENT_METHOD = "paymentMethod";
const ITEMS = "items";
const BASE_AMOUNT = "baseAmount";
const VAT = "vat";
const TOTAL = "total";

const ORDER_ASC = 1;
const ORDER_DESC = 2;

const sortFunctions = {
  [CUSTOMER]: (a, b) => a.customer.fullName.localeCompare(b.customer.fullName),
  [PAYMENT_METHOD]: (a, b) =>
    a.paymentMethod.name.localeCompare(b.paymentMethod.name),
  [ITEMS]: (a, b) => a.merchantables.length - b.merchantables.length,
  [BASE_AMOUNT]: (a, b) => a.baseAmount - b.baseAmount,
  [VAT]: (a, b) => a.vatAmount - b.vatAmount,
  [TOTAL]: (a, b) => a.totalAmount - b.totalAmount,
};

const InvoiceRow = (props) => {
  const { invoice, onSelect, i, selected } = props;
  const [t] = useTranslation("invoices");
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor:
            invoice.baseAmount < 0 ? NEGATIVE_IMPORT_COLOR : undefined,
        }}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Checkbox
            value={i}
            invoice={invoice}
            onClick={onSelect}
            checked={selected}
          />
        </TableCell>
        <TableCell>{invoice.customer.fullName}</TableCell>
        <TableCell>{invoice.paymentMethod.name}</TableCell>
        <TableCell>{invoice.merchantables.length}</TableCell>
        <TableCell>{localeFormat(invoice.baseAmount)}€</TableCell>
        <TableCell>{localeFormat(invoice.vatAmount)}€</TableCell>
        <TableCell>{localeFormat(invoice.totalAmount)}€</TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container marginBottom={2} marginTop={1}>
                <Grid item>
                  <Typography variant="h6">{t("details")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("concept")}</TableCell>
                        <TableCell>{t("baseAmount")}</TableCell>
                        <TableCell>{t("vat")}(%)</TableCell>
                        <TableCell>{t("vat")}</TableCell>
                        <TableCell>{t("total")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoice?.merchantables?.map((item) => (
                        <>
                          <TableRow>
                            <TableCell>{item.concept}</TableCell>
                            <TableCell>
                              {localeFormat(item.baseAmount)}€
                            </TableCell>
                            <TableCell>{item.vatPercentage}%</TableCell>
                            <TableCell>
                              {localeFormat(
                                (item.baseAmount * item.vatPercentage) / 100
                              )}
                              €
                            </TableCell>
                            <TableCell>
                              {localeFormat(item.totalAmount)}€
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const initialState = {
  selectedInvoices: [],
  customerTypes: [],
  invoicingTypes: [],
  issuers: [],
  invoices: [],
  paymentDays: [],
  paymentMethods: [],
  loading: false,
  submitLoading: false,
};

const initialDialogState = {
  isOpen: false,
  childrenText: "",
};

const initialFilters = {
  customer: null,
  customerType: [],
  centers: [],
  date: "",
  isKeyAccount: "",
  paymentMethods: [],
  paymentDay: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "SET_INVOICING_TYPES":
      return { ...state, invoicingTypes: action.payload };
    case "SET_SELECTED_INVOICES":
      return { ...state, selectedInvoices: action.payload };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_CUSTOMER_TYPES":
      return { ...state, customerTypes: action.payload };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "RESET":
      return {
        ...state,
        filters: initialState.filters,
        invoices: initialState.invoices,
        selectedInvoices: initialState.selectedInvoices,
      };
    default:
      throw new Error("Action type not found in reducer");
  }
};

const InvoicingPage = () => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("invoices");
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [confirmDialog, setConfirmDialog] = useState(initialDialogState);
  const [filters, setFilters] = useState(initialFilters);
  const [sortBy, setSortBy] = useState({ type: "", order: ORDER_ASC });

  const sortedInvoices = sortInvoices(state.invoices, sortBy);

  //Initial useEffect
  useEffect(() => {
    document.title = t("invoicingPage");

    getIssuers();
    getInvoicingTypes();
    getPaymentMethods();
    getCustomerTypes();
    getPaymentDays();
  }, []);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ISSUERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoicingTypes = () => {
    api
      .get("/invoicing-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_INVOICING_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_PAYMENT_METHODS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomerTypes = () => {
    api
      .get("/customer-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_CUSTOMER_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_PAYMENT_DAYS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPendingMerchantables = () => {
    dispatch({ type: "SET_INVOICES", payload: [] });
    dispatch({ type: "SET_LOADING", payload: true });
    const params = {};

    filters.date !== "" && (params.date = filters.date);

    filters.centers.length > 0 && (params.centers = filters.centers);

    filters.paymentMethods.length > 0 &&
      (params.paymentMethods = filters.paymentMethods);

    filters.customer !== null && (params.customer = filters.customer.id);

    filters.customerType !== null &&
      (params.customerTypeId = filters.customerType);

    filters.paymentDay !== null && (params.paymentDay = filters.paymentDay);

    filters.isKeyAccount !== "" && (params.isKeyAccount = filters.isKeyAccount);

    api
      .get("/invoicing/pending-merchantables", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_INVOICES",
            payload: response.data.map((i, index) => {
              return {
                ...i,
                id: index + 1,
                baseAmount: Number(i.baseAmount.toFixed(2)),
                vatAmount: Number(i.vatAmount.toFixed(2)),
                totalAmount: Number(i.totalAmount.toFixed(2)),
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  const invoiceMerchantables = () => {
    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

    let merchantableIds = state.selectedInvoices
      .map((i) => i.merchantables.map((m) => m.id))
      .flat();

    api
      .post("/invoicing/invoice-merchantables", { merchantableIds })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "RESET" });
          enqueueSnackbar(t("invoicedSuccess"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
      });
  };

  const setSelectedInvoices = (e, id) => {
    let invoice = sortedInvoices.find((i) => i.id === id);
    if (!invoice) {
      enqueueSnackbar(t("unexpectedError"), { variant: "error" });
      return;
    }

    if (e.target.checked === true)
      dispatch({
        type: "SET_SELECTED_INVOICES",
        payload: [...state.selectedInvoices, invoice],
      });
    else
      dispatch({
        type: "SET_SELECTED_INVOICES",
        payload: state.selectedInvoices.filter((i) => i.id !== id),
      });
  };

  const resetConfirmDialog = () => {
    setConfirmDialog(initialDialogState);
  };

  const openCreateConfirm = () => {
    const totalItems = state.selectedInvoices
      .map((i) => i.merchantables.length)
      .reduce((a, b) => a + b, 0);
    setConfirmDialog({
      isOpen: true,
      childrenText: t("creatingInvoices") + ": " + (totalItems || 0),
    });
  };

  const handleSort = (type) => {
    const currentType = sortBy.type;
    const currentOrder = sortBy.order;

    if (type === currentType) {
      setSortBy({
        type,
        order: currentOrder === ORDER_ASC ? ORDER_DESC : ORDER_ASC,
      });
    } else {
      setSortBy({ type, order: ORDER_ASC });
    }
  };

  const HeaderCell = (sortType) => {
    let title = "";
    switch (sortType) {
      case CUSTOMER:
        title = t("customer");
        break;
      case PAYMENT_METHOD:
        title = t("paymentMethod");
        break;
      case ITEMS:
        title = t("numItems");
        break;
      case BASE_AMOUNT:
        title = t("baseAmount");
        break;
      case VAT:
        title = t("vat");
        break;
      case TOTAL:
        title = t("total");
        break;
      default:
        title = "";
    }
    return HeaderCellGenerator(title, sortBy, sortType, handleSort);
  };

  const someNegativeBaseAmount = sortedInvoices.some(
    (invoice) => invoice.baseAmount < 0
  );

  const selectSomeNegativeBaseAmount = state.selectedInvoices.some(
    (i) => i.baseAmount < 0
  );

  return (
    <Page maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("invoicing")}</Typography>
        </Grid>
        <Grid item>
          <Filters
            filters={[
              <CustomerInput
                label={t("customer")}
                name="customer"
                value={filters.customer}
                onChange={handleFilterChange}
              />,
              <CenterSelect
                name="centers"
                value={filters.centers}
                onChange={handleFilterChange}
                multiple
              />,
              <CustomSelect
                name="paymentMethods"
                label={t("paymentMethod")}
                value={filters.paymentMethods}
                options={state.paymentMethods.map((p) => ({
                  value: p.id,
                  label: t(p.name),
                }))}
                onChange={handleFilterChange}
                multiple
              />,
              <CustomDate
                name="date"
                value={filters.date}
                onChange={handleFilterChange}
              />,
              <CustomSelect
                name="customerType"
                label={t("customerType")}
                value={filters.customerType}
                options={state.customerTypes.map((type) => ({
                  value: type.id,
                  label: t(type.name),
                }))}
                onChange={handleFilterChange}
                multiple
              />,
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
                label={t("keyAccount")}
                name="isKeyAccount"
                onChange={handleFilterChange}
                value={filters.isKeyAccount}
              />,
              <CustomSelect
                name="paymentDay"
                label={t("paymentDay")}
                value={filters.paymentDay}
                options={state.paymentDays.map((day) => ({
                  value: day.day,
                  label: t(day.day),
                }))}
                onChange={handleFilterChange}
                multiple
              />,
              <SearchButton
                loading={state.loading}
                onClick={getPendingMerchantables}
                disabled={filters.date === ""}
              >
                {t("search")}
              </SearchButton>,
              <CustomButton
                loading={state.submitLoading}
                color="success"
                onClick={openCreateConfirm}
                disabled={
                  !state.selectedInvoices.length || selectSomeNegativeBaseAmount
                }
              >
                {t("createInvoices")}!
              </CustomButton>,
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary
            gridItems={[
              {
                translatedText: t("items"),
                value: sortedInvoices.length,
              },
              {
                translatedText: t("baseAmount"),
                value:
                  localeFormat(
                    sortedInvoices?.reduce(
                      (sum, item) => sum + item.baseAmount,
                      0
                    )
                  ) + "€",
              },
              {
                translatedText: t("taxes"),
                value:
                  localeFormat(
                    sortedInvoices?.reduce(
                      (sum, item) => sum + item.vatAmount,
                      0
                    )
                  ) + "€",
              },
              {
                translatedText: t("total"),
                value:
                  localeFormat(
                    sortedInvoices?.reduce(
                      (sum, item) => sum + item.totalAmount,
                      0
                    )
                  ) + "€",
              },
            ]}
          />
        </Grid>

        {someNegativeBaseAmount && (
          <Grid item xs={12}>
            <Card>
              <Alert severity="warning">{t("negativeBaseAmount")}</Alert>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Checkbox
                      onClick={(e) => {
                        if (e.target.checked === true) {
                          dispatch({
                            type: "SET_SELECTED_INVOICES",
                            payload: sortedInvoices,
                          });
                        } else {
                          dispatch({
                            type: "SET_SELECTED_INVOICES",
                            payload: [],
                          });
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{HeaderCell(CUSTOMER)}</TableCell>
                  <TableCell>{HeaderCell(PAYMENT_METHOD)}</TableCell>
                  <TableCell>{HeaderCell(ITEMS)}</TableCell>
                  <TableCell>{HeaderCell(BASE_AMOUNT)}</TableCell>
                  <TableCell>{HeaderCell(VAT)}</TableCell>
                  <TableCell>{HeaderCell(TOTAL)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedInvoices.map((a, index) => (
                  <InvoiceRow
                    key={a.id}
                    invoice={a}
                    onSelect={(e) => {
                      setSelectedInvoices(e, a.id);
                    }}
                    selected={state.selectedInvoices.some((i) => i.id === a.id)}
                    i={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={t("createInvocesQuestion")}
        open={confirmDialog.isOpen}
        setOpen={resetConfirmDialog}
        onConfirm={(confirmed) => {
          confirmed && invoiceMerchantables();
          resetConfirmDialog();
        }}
      >
        <Typography variant="body2" color="initial">
          {confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
};

const HeaderCellGenerator = (title, sortBy, sortType, onClickCallback) => {
  return (
    <div
      style={{
        cursor: "pointer",
        width: "100%",
        height: "100%",
      }}
      onClick={(e) => {
        onClickCallback(sortType);
      }}
    >
      <Grid item container spacing={1} alignItems="center">
        <Grid item>{title}</Grid>
        {sortBy.type === sortType && (
          <Grid item>
            {sortBy.order === ORDER_ASC ? (
              <NorthIcon fontSize="small" color="action" />
            ) : (
              <SouthIcon fontSize="small" color="action" />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const sortInvoices = (invoices, sortBy) => {
  if (sortBy.type === "") return invoices;
  let res = invoices.sort((a, b) => {
    return sortFunctions[sortBy.type](a, b) * sortBy.order;
  });

  return sortBy.order === ORDER_ASC ? res : res.reverse();
};

export default InvoicingPage;
