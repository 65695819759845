import {
  Box,
  ButtonGroup,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import ButtonLink from "../../Inputs/ButtonLink";
import CreateButton from "../../Inputs/CreateButton";
import CustomButton from "../../Inputs/CustomButton";
import ConfirmDialog from "../../ConfirmDialog";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import ItemsSummary from "../../ItemsSummary";
import ReceiptStateChip from "../../ReceiptStateChip";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";

import { CustomTable } from "../../CustomTable";
import { DataGrid } from "@mui/x-data-grid";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import {
  CREATED_RECEIPT_STATE_ID,
  ISSUED_RECEIPT_STATE_ID,
  RETURNED_RECEIPT_STATE_ID,
  PAID_RECEIPT_STATE_ID,
} from "../../../data/constants";

import {
  firstDateCurrentMonth,
  lastDateCurrentMonth,
  today,
} from "../../../utils/date";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  receipts: [],
  remittances: [],
  selectedRemittance: [],
  selectedReceipt: "",
  filters: {
    amountMin: "",
    amountMax: "",
    autoSearch: false,
    dateFrom: firstDateCurrentMonth(),
    dateUntil: lastDateCurrentMonth(),
    orderBy: "",
    order: "asc",
    state: "",
    text: "",
  },
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  duplicateDialog: {
    isOpen: false,
    loading: false,
  },
  duplicateReceipt: {
    dueDate: "",
    signatureDate: today(),
    comments: "",
    concept: "",
    remittanceId: null,
  },
  addRemittanceDialog: {
    isOpen: false,
    loaded: true,
    columns: [],
  },
  filtersRemittance: {
    name: "",
    dateFrom: "",
    dateUntil: "",
  },
  options: {
    rowlink: false,
    loaded: true,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    case "SET_RECEIPTS":
      return { ...state, receipts: action.payload };
    case "SET_REMITTANCES":
      return { ...state, remittances: action.payload };
    case "SET_SELECTED_RECEIPT":
      return { ...state, selectedReceipt: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "OPEN_DUPLICATE_DIALOG":
      return {
        ...state,
        duplicateDialog: { ...state.duplicateDialog, isOpen: true },
      };
    case "CLOSE_DUPLICATE_DIALOG":
      return {
        ...state,
        duplicateDialog: { ...state.duplicateDialog, isOpen: false },
      };
    case "TOGGLE_DUPLICATE_LOADING":
      return {
        ...state,
        duplicateDialog: {
          ...state.duplicateDialog,
          loading: !state.duplicateDialog.loading,
        },
      };
    case "RESET_DUPLICATE_INPUT":
      return {
        ...state,
        duplicateReceipt: initialState.duplicateReceipt,
      };
    case "SET_DUPLICATE_INPUT":
      return {
        ...state,
        duplicateReceipt: {
          ...state.duplicateReceipt,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "OPEN_ADD_REMITTANCE_DIALOG":
      return {
        ...state,
        addRemittanceDialog: { ...state.addRemittanceDialog, isOpen: true },
      };
    case "CLOSE_ADD_REMITTANCE_DIALOG":
      return {
        ...state,
        addRemittanceDialog: { ...state.addRemittanceDialog, isOpen: false },
      };
    case "SET_REMITTANCE_COLUMNS":
      return {
        ...state,
        addRemittanceDialog: {
          ...state.addRemittanceDialog,
          columns: action.payload,
        },
      };
    case "SET_REMITTANCE_LOADED_TRUE":
      return {
        ...state,
        addRemittanceDialog: {
          ...state.addRemittanceDialog,
          loaded: true,
        },
      };
    case "SET_REMITTANCE_LOADED_FALSE":
      return {
        ...state,
        addRemittanceDialog: {
          ...state.addRemittanceDialog,
          loaded: false,
        },
      };
    case "RESET_REMITTANCE_FILTERS":
      return {
        ...state,
        filtersRemittance: initialState.filtersRemittance,
      };
    case "SET_SELECTED_REMITTANCE":
      return { ...state, selectedRemittance: action.payload };
    case "SET_FILTER_REMITTANCE":
      return {
        ...state,
        filtersRemittance: {
          ...state.filtersRemittance,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_MENU":
      const index = state.receipts.findIndex(
        (receipt) => receipt.id === action.payload.id
      );

      return {
        ...state,
        receipts: [
          ...state.receipts.slice(0, index),
          {
            ...state.receipts[index],
            anchorEl: action.payload.anchorEl,
            open: Boolean(action.payload.anchorEl),
          },
          ...state.receipts.slice(index + 1),
        ],
      };
    default:
      throw new Error();
  }
}

export default function ReceiptsPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const [t] = useTranslation("receipts");

  const FILTERS = [
    { name: "text", type: "string" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "amountMin", type: "number" },
    { name: "amountMax", type: "number" },
    { name: "state", type: "number" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "autoSearch", type: "boolean" },
  ];

  const handleClick = (event, id) => {
    dispatch({
      type: "SET_MENU",
      payload: { id: id, anchorEl: event.currentTarget },
    });
  };
  const handleClose = (id) => {
    dispatch({ type: "SET_MENU", payload: { id: id, anchorEl: null } });
  };

  const RECEIPT_COLUMNS = [
    {
      label: t("remittance"),
      key: "Remittance.name",
      sortType: "string",
      renderFunction: (value, item) =>
        user.hasAction("VIEW_REMITTANCES") && item.Remittance ? (
          <ButtonLink
            to={"/app/remittance/" + item.Remittance.id}
            size="small"
            sx={{ padding: 0 }}
          >
            {value}
          </ButtonLink>
        ) : (
          value
        ),
    },
    { label: t("customerName"), key: "customerName", sortType: "string" },
    { label: t("concept"), key: "concept", sortType: "string" },
    {
      label: t("amount"),
      key: "amount",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { label: t("dueDate"), key: "dueDate", sortType: "string" },
    {
      label: t("invoices"),
      key: "Invoices",
      sortType: "number",
      renderFunction: (value, item) => {
        switch (item.Invoices.length) {
          case 0:
            return <Typography>{t("0invoice")}</Typography>;
          case 1:
            return (
              <ButtonLink
                sx={{ px: 0 }}
                to={"/app/invoice/" + item.Invoices[0].id}
              >
                {item.Invoices[0].InvoiceSerie.name + item.Invoices[0].number}
              </ButtonLink>
            );
          default:
            return (
              <Grid>
                <Button
                  sx={{ px: 0 }}
                  variant="text"
                  onClick={(e) => handleClick(e, item.id)}
                >
                  {t("invoices")}
                </Button>
                <Menu
                  anchorEl={item.anchorEl}
                  open={item.open}
                  onClose={() => handleClose(item.id)}
                  TransitionComponent={Fade}
                >
                  {item.Invoices.map((invoice) => (
                    <MenuItem
                      sx={{ p: 0 }}
                      onClick={() => handleClose(item.id)}
                    >
                      <ButtonLink to={"/app/invoice/" + invoice.id}>
                        {invoice.InvoiceSerie.name + invoice.number}
                      </ButtonLink>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            );
        }
      },
    },
    {
      label: t("state"),
      key: "state",
      sortType: "number",
      renderFunction: (value) => <ReceiptStateChip state={value} />,
    },
    { label: t("actions"), key: "actions", sortType: "other" },
  ];

  const CSV_HEADERS = [
    { label: t("remittance"), key: "Remittance.name" },
    { label: t("customerName"), key: "customerName" },
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    { label: t("dueDate"), key: "dueDate" },
    { label: t("state"), key: "state" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const ITEMS_SUMMARY = [
    {
      translatedText: t("receipts"),
      value: state.receipts?.length,
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.receipts?.reduce(
            (value, element) => value + (element.amount ? element.amount : 0),
            0
          )
        ).toString() + " €",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("receipts");
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === true) getReceipts();
  }, [state.filters.autoSearch]);

  const search = () => {
    if (state.filters.autoSearch === true) getReceipts();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: true },
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const addReceiptToRemittance = () => {
    let data = {
      remittanceId: state.selectedRemittance,
    };

    api
      .post("/receipts/edit/" + state.selectedReceipt.id, data)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("receiptAdded"), { variant: "success" });
          closeAddRemittanceDialog();
          getReceipts();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteReceipt = (receiptId) => {
    api
      .delete("/receipts/" + receiptId)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("receiptDeleted"), { variant: "success" });
          getReceipts();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getReceipts = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    //Change url parameters:
    let url = generateURL("/app/receipts", state.filters);
    history.push(url);

    let params = {
      include: ["Remittance", "Invoice"],
    };

    state.filters.text !== "" && (params.text = state.filters.text);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.amountMin !== "" &&
      (params.amountMin = state.filters.amountMin);
    state.filters.amountMax !== "" &&
      (params.amountMax = state.filters.amountMax);
    state.filters.state !== "" && (params.state = state.filters.state);

    api
      .get("/receipts", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noReceipts"), { variant: "warning" });
          }
          dispatch({ type: "SET_RECEIPTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getRemittances = () => {
    dispatch({ type: "SET_REMITTANCE_LOADED_FALSE" });
    let params = {
      include: ["Receipt"],
    };

    state.filtersRemittance.name &&
      (params.name = state.filtersRemittance.name);
    state.filtersRemittance.dateFrom &&
      (params.dateFrom = state.filtersRemittance.dateFrom);
    state.filtersRemittance.dateUntil &&
      (params.dateUntil = state.filtersRemittance.dateUntil);

    //Only remittances which haven't been remitted yet
    params.remitted = true;

    api
      .get("/remittances/", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noRemittances"), { variant: "warning" });
          } else {
            dispatch({ type: "SET_REMITTANCES", payload: response.data });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_REMITTANCE_LOADED_TRUE" });
      });
  };

  const handleFilterRemittanceChange = (e) => {
    dispatch({
      type: "SET_FILTER_REMITTANCE",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const showAddRemittanceDialog = (selectedReceipt) => {
    getRemittances();
    dispatch({ type: "SET_SELECTED_RECEIPT", payload: selectedReceipt });
    dispatch({ type: "OPEN_ADD_REMITTANCE_DIALOG" });

    const REMITTANCE_COLUMNS = [
      { field: "id", headerName: "ID", flex: 1 },
      { field: "name", headerName: t("name"), flex: 6 },
      { field: "dueDate", headerName: t("dueDate"), flex: 6 },
      {
        field: "amount",
        headerName: t("amount"),
        flex: 6,
        valueGetter: ({ row }) =>
          row.Receipts.reduce((amount, receipt) => amount + receipt.amount, 0),
        valueFormatter: ({ value }) => localeFormat(value) + "€",
      },
      {
        field: "numberOfReceipts",
        headerName: t("numberOfReceipts"),
        flex: 6,
      },
    ];

    dispatch({ type: "SET_REMITTANCE_COLUMNS", payload: REMITTANCE_COLUMNS });
  };

  const closeAddRemittanceDialog = () => {
    dispatch({ type: "RESET_REMITTANCE_FILTERS" });
    dispatch({ type: "CLOSE_ADD_REMITTANCE_DIALOG" });
  };

  const openDuplicateDialog = (selectedReceipt) => {
    getRemittances();
    dispatch({
      type: "SET_DUPLICATE_INPUT",
      payload: { inputname: "concept", value: selectedReceipt.concept },
    });
    dispatch({ type: "SET_SELECTED_RECEIPT", payload: selectedReceipt });
    dispatch({ type: "OPEN_DUPLICATE_DIALOG" });
  };

  const closeDuplicateDialog = () => {
    dispatch({ type: "RESET_DUPLICATE_INPUT" });
    dispatch({ type: "CLOSE_DUPLICATE_DIALOG" });
  };

  const handleDuplicateInputChange = (e) => {
    dispatch({
      type: "SET_DUPLICATE_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const duplicateReceipt = () => {
    let data = {
      amount: state.selectedReceipt.amount,
      concept: state.duplicateReceipt.concept,
      createdBy: user.id,
      customerId: state.selectedReceipt.customerId,
      customerName: state.selectedReceipt.customerName,
      dueDate: state.duplicateReceipt.dueDate,
      iban: state.selectedReceipt.iban,
      selectedInvoices: state.selectedReceipt.Invoices,
      signatureDate: state.duplicateReceipt.signatureDate,
      remittanceId:
        state.duplicateReceipt.remittanceId === ""
          ? null
          : state.duplicateReceipt.remittanceId,
    };

    handleToggleDuplicateLoading();

    api
      .post("/receipts/create", data)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("receiptDuplicateSuccess"), { variant: "success" });
          closeDuplicateDialog();
          search();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => handleToggleDuplicateLoading());
  };

  const handleToggleDuplicateLoading = () => {
    dispatch({ type: "TOGGLE_DUPLICATE_LOADING" });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_RECEIPTS", payload: [] });
  };

  const resetRemittanceFilters = () => {
    dispatch({ type: "RESET_REMITTANCE_FILTERS" });
    dispatch({ type: "SET_REMITTANCES", payload: [] });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("receipts")}</Typography>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("search")}
              value={state.filters.text}
              name="text"
              onChange={handleFilterChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <CustomDate
              label={t("dateFrom")}
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateFrom}
              onChange={handleFilterChange}
              name="dateFrom"
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <CustomDate
              label={t("dateUntil")}
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateUntil}
              onChange={handleFilterChange}
              name="dateUntil"
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("amountMin")}
              name="amountMin"
              value={state.filters.amountMin}
              onChange={handleFilterChange}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("amountMax")}
              name="amountMax"
              value={state.filters.amountMax}
              onChange={handleFilterChange}
              type="number"
            />
          </Grid>

          <Grid item sm="auto">
            <CustomSelect
              label={t("state")}
              value={state.filters.state}
              onChange={handleFilterChange}
              options={[
                { value: "", label: t("all") },
                { value: CREATED_RECEIPT_STATE_ID, label: t("created") },
                { value: ISSUED_RECEIPT_STATE_ID, label: t("issued") },
                { value: PAID_RECEIPT_STATE_ID, label: t("paid") },
                { value: RETURNED_RECEIPT_STATE_ID, label: t("returned") },
              ]}
              name="state"
            />
          </Grid>

          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={resetFilters}>{t("reset")}</Button>
              <ExportButton
                data={exportDataParse(state.receipts, CSV_HEADERS)}
                display={user.hasAction("EXPORT_DATA")}
              />
              <SearchButton onClick={search} loading={!state.options.loaded} />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonLink
              to={"/app/returned-receipts"}
              variant="contained"
              color="primary"
            >
              {t("returnOfReceipts")}
            </ButtonLink>
          </Grid>
          <Grid item>
            <CreateButton
              action={"CREATE_RECEIPTS"}
              label={t("createReceipt")}
              link={"/receipt/create"}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary gridItems={ITEMS_SUMMARY} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            data={state.receipts?.map((receipt) => {
              receipt.actions = (
                <Grid>
                  <Tooltip title={t("addToRemittance")} placement="top">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          showAddRemittanceDialog(receipt);
                        }}
                        disabled={receipt.remittanceId ? true : false}
                      >
                        <AccountBalanceIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t("duplicate")} placement="top">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        openDuplicateDialog(receipt);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("delete")} placement="top">
                    <span>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch({
                            type: "SET_CONFIRM_DIALOG",
                            payload: {
                              title: t("deleteReceiptQuestion"),
                              isOpen: true,
                              childrenText: `${receipt.customerName} |
                                ${receipt.concept} |
                                ${receipt.amount}€`,
                              callback: (confirmed) => {
                                confirmed && deleteReceipt(receipt.id);
                                resetConfirmDialog();
                              },
                            },
                          });
                        }}
                        disabled={receipt.remittanceId ? true : false}
                        color="error"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              );

              return receipt;
            })}
            columns={RECEIPT_COLUMNS}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/receipts", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>

      {/* Duplicate receipt dialog */}
      <Dialog
        open={state.duplicateDialog.isOpen}
        onClose={closeDuplicateDialog}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("duplicateReceipt")}</Grid>
            <Grid item>
              <IconButton onClick={closeDuplicateDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  display="inline"
                  fontWeight="fontWeightMedium"
                >
                  {t("customerName")}:{" "}
                </Typography>
                <Typography variant="body1" display="inline">
                  {state.selectedReceipt.customerName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  display="inline"
                  fontWeight="fontWeightMedium"
                >
                  {"IBAN"}:{" "}
                </Typography>
                <Typography variant="body1" display="inline">
                  {state.selectedReceipt.iban}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  display="inline"
                  fontWeight="fontWeightMedium"
                >
                  {t("amount")}:{" "}
                </Typography>
                <Typography variant="body1" display="inline">
                  {localeFormat(state.selectedReceipt.amount) + "€"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={4}>
                <TextInput
                  label={t("concept")}
                  value={state.duplicateReceipt.concept}
                  onChange={handleDuplicateInputChange}
                  name="concept"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomDate
                  label={t("signatureDate")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.duplicateReceipt.signatureDate}
                  onChange={handleDuplicateInputChange}
                  name="signatureDate"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomDate
                  label={t("dueDate")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.duplicateReceipt.dueDate}
                  onChange={handleDuplicateInputChange}
                  name="dueDate"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("remittance")}
                  onChange={handleDuplicateInputChange}
                  name="remittanceId"
                  options={[
                    { label: t("none"), value: "" },
                    ...state.remittances.map((remittance) => ({
                      value: remittance.id,
                      label: remittance.name + " - " + remittance.dueDate,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t("comments")}
                  value={state.duplicateReceipt.comments}
                  name="comments"
                  multiline
                  rows={4}
                  onChange={handleDuplicateInputChange}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <CustomButton
                  onClick={duplicateReceipt}
                  disabled={
                    !state.duplicateReceipt.concept ||
                    !state.duplicateReceipt.signatureDate ||
                    !state.duplicateReceipt.dueDate
                  }
                  loading={state.duplicateDialog.loading}
                >
                  {t("duplicate")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Add to Remittance dialog */}
      <Dialog
        open={state.addRemittanceDialog.isOpen}
        onClose={closeAddRemittanceDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("addToRemittance")}</Grid>
            <Grid item>
              <IconButton onClick={closeAddRemittanceDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} marginTop={1}>
            <Grid item container spacing={1}>
              <Grid item>
                <TextInput
                  label={t("name")}
                  value={state.filtersRemittance.name}
                  onChange={handleFilterRemittanceChange}
                  name="name"
                />
              </Grid>
              <Grid item>
                <CustomDate
                  InputLabelProps={{ shrink: true }}
                  value={state.filtersRemittance.dateFrom}
                  onChange={handleFilterRemittanceChange}
                  name="dateFrom"
                  label={t("dateFrom")}
                />
              </Grid>
              <Grid item>
                <CustomDate
                  InputLabelProps={{ shrink: true }}
                  value={state.filtersRemittance.dateUntil}
                  onChange={handleFilterRemittanceChange}
                  name="dateUntil"
                  label={t("dateUntil")}
                />
              </Grid>
              <Grid item>
                <ButtonGroup variant="contained" color="primary">
                  <Button onClick={resetRemittanceFilters} color="primary">
                    {t("reset")}
                  </Button>
                  <SearchButton
                    onClick={getRemittances}
                    loading={!state.addRemittanceDialog.loaded}
                  />
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ height: 400, width: "100%" }}>
                <DataGrid
                  columns={state.addRemittanceDialog.columns}
                  rows={state.remittances}
                  onRowSelectionModelChange={(remittanceId) => {
                    dispatch({
                      type: "SET_SELECTED_REMITTANCE",
                      payload: remittanceId,
                    });
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {state.selectedRemittance?.length > 0 && (
                  <Typography
                    variant="body1"
                    fontWeight="fontWeightMedium"
                    display="inline"
                  >
                    {t("selectedRemittanceId")}:{" "}
                  </Typography>
                )}
                <Typography variant="body1" display="inline">
                  {state.selectedRemittance?.length > 0
                    ? state.selectedRemittance
                    : `${t("noSelectedRemittance")}. ${t("clickRowToSelect")}.`}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={addReceiptToRemittance}
                  disabled={state.selectedRemittance?.length === 0}
                >
                  {t("add")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
