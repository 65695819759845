import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AppContext from "../../../context/AppContext";

import Page from "../../global/structure/Page";
import Tabs from "../../global/structure/Tabs";

import CustomersTab from "./AccessControlTabs/CustomersTab";

export const AccessLevelsContext = React.createContext();

function useAccessLevels() {
  const { api } = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [accessLevels, setAccessLevels] = React.useState([]);

  React.useEffect(() => {
    getAccessLevels();
  }, []);

  const getAccessLevels = () => {
    api.get("/access-control/levels").then((res) => {
      if (res.data.error) enqueueSnackbar(res.data.error, { variant: "error" });
      else setAccessLevels(res.data);
    });
  };

  return [accessLevels];
}

function AccessControlPage() {
  const [t] = useTranslation("accessControl");
  const [accessLevels] = useAccessLevels();

  return (
    <AccessLevelsContext.Provider value={accessLevels}>
      <Page
        title={t("accessControl")}
        browserTitle={t("accessControl")}
        paper={true}
      >
        <Tabs
          tabs={[{ label: t("customer"), content: <CustomersTab /> }]}
        ></Tabs>
      </Page>
    </AccessLevelsContext.Provider>
  );
}

export default AccessControlPage;
