import Page from "./Components/Page.js";

const UrlKOPage = () => {
  return (
    <Page>
      {
        "Ha habido un fallo en la operación. \n Intentalo de nuevo en unos minutos."
      }
    </Page>
  );
};

export default UrlKOPage;
