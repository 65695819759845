import {
  Button,
  ButtonGroup,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";

import TextInput from "../../../../../Inputs/TextInput";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

const TextCollapsible = (props) => {
  const { text, setText, textAlign, setTextAlign, fontSize, setFontSize } =
    props;

  const [t] = useTranslation("floorPlans");

  return (
    <ItemCollapsibleTemplate title={t("text")}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextInput
            label={t("text")}
            value={text}
            multiline
            onChange={(e) => setText && setText(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label={t("fontSize")}
            value={fontSize}
            type="number"
            onChange={(e) => setFontSize && setFontSize(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup
            size="small"
            value={textAlign}
            exclusive
            onChange={(e, value) => {
              setTextAlign && value !== null && setTextAlign(value);
            }}
          >
            <ToggleButton value="left">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

TextCollapsible.propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func,
  textAlign: PropTypes.oneOf(["right", "center", "left"]),
  setTextAlign: PropTypes.func,
  fontSize: PropTypes.number,
  setFontSize: PropTypes.func,
};

export default TextCollapsible;
