import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Group, Layer, Stage } from "react-konva";

import Tile from "../TileShapes/Tile";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import LayersIcon from "@mui/icons-material/Layers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  TILE_DEFAULT_STROKE_WIDTH,
  TILE_DEFAULT_EDGE_COLOR,
  TILE_BLUE_COLOR,
} from "../../../../data/constants";

const SCALE_RATIO = 1.1;

const useContainerDimensions = (containerRef, data) => {
  const [containerWidth, setDimensions] = useState(0);
  const [containerDimensions, setContainerDimensions] = useState({
    scale: containerWidth / data.width,
    screenScale: containerWidth / data.width,
    x: 0,
    y: 0,
  });

  const handleResize = () => {
    setDimensions(containerRef.current?.offsetWidth || 1);
    setContainerDimensions({
      ...containerDimensions,
      scale: (containerRef.current?.offsetWidth || 1) / data.width,
      screenScale: (containerRef.current?.offsetWidth || 1) / data.width,
      x: containerDimensions.x,
      y: containerDimensions.y,
    });
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    let newScale =
      e.evt.deltaY < 0 ? oldScale * SCALE_RATIO : oldScale / SCALE_RATIO;

    // Set max values for zoom
    const MAX_ZOOM_IN = 0.5;
    const MAX_ZOOM_OUT = 6;
    if (newScale > MAX_ZOOM_OUT) newScale = MAX_ZOOM_OUT;
    if (newScale < MAX_ZOOM_IN) newScale = MAX_ZOOM_IN;

    setContainerDimensions({
      ...containerDimensions,
      scale: newScale,
      screenScale: containerWidth / data.width,
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
  };

  const zoomIn = () => {
    const newScale = containerDimensions.scale * SCALE_RATIO;

    // Set max values for zoom
    if (newScale > 4) newScale = 4;
    if (newScale < 0.5) newScale = 0.5;

    setContainerDimensions({ ...containerDimensions, scale: newScale });
  };

  const zoomOut = () => {
    const newScale = containerDimensions.scale / SCALE_RATIO;

    // Set max values for zoom
    if (newScale > 4) newScale = 4;
    if (newScale < 0.5) newScale = 0.5;

    setContainerDimensions({ ...containerDimensions, scale: newScale });
  };

  const setNewPosition = ({ xOffset, yOffset }) => {
    setContainerDimensions({
      ...containerDimensions,
      x: containerDimensions.x - xOffset,
      y: containerDimensions.y - yOffset,
    });
  };

  // Resize stage correctly when window size change
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef.current]);

  return {
    ...containerDimensions,
    handleWheel,
    zoomIn,
    zoomOut,
    setNewPosition,
  };
};

const useLayersData = (data) => {
  const [layers, setFloorPlanLayers] = useState(data?.layers ?? []);
  const [floorPlanSizes, setFloorPlanSizes] = useState({
    width: data.width,
    height: data.height,
  });

  useEffect(() => {
    setFloorPlanSizes({
      width: data.width ? data.width : floorPlanSizes.width,
      height: data.height ? data.height : floorPlanSizes.height,
    });
    data.layers && setFloorPlanLayers(data.layers.sort((a, b) => a.z - b.z));
  }, [data]);

  return {
    height: floorPlanSizes.height,
    width: floorPlanSizes.width,
    layers: layers,
  };
};

/**
 * FloorPlanRender component
 * @param {Object} data - Parsed data with build render data function
 * @param {Boolean} menu - Show menu
 * @param {Object} options - Additional options:
 *   - blockDragging: Boolean - Block dragging
 *   - cursorStyle: String - Cursor style
 */
const FloorPlanRender = ({ data, menu, options }) => {
  const containerRef = useRef(null);

  const {
    handleWheel,
    zoomIn,
    zoomOut,
    setNewPosition,
    ...containerDimensions
  } = useContainerDimensions(containerRef, data);
  const { height, width, layers } = useLayersData(data);
  const [selectedLayers, setSelectedLayers] = useState([]);

  const handleSelectedLayersChange = (layer) => {
    if (!selectedLayers.some((sl) => Number(sl) === Number(layer.id)))
      setSelectedLayers([...selectedLayers, layer.id]);
    else
      setSelectedLayers(
        selectedLayers.filter((sl) => Number(sl) !== Number(layer.id))
      );
  };
  let stageWidth = width * containerDimensions.screenScale;
  let stageHeight = height * containerDimensions.screenScale;

  if (stageWidth < 1) stageWidth = 1;
  if (stageHeight < 1) stageHeight = 1;

  return (
    <Box
      ref={containerRef}
      sx={{
        border: "1px solid",
        position: "relative",
        backgroundColor: "#FFF",
      }}
    >
      {menu && (
        <RenderActionButtons
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          layers={{ layers, selectedLayers, handleSelectedLayersChange }}
        />
      )}
      {/* STAGE */}
      <Stage
        width={stageWidth}
        height={stageHeight}
        scaleX={containerDimensions.scale}
        scaleY={containerDimensions.scale}
        x={containerDimensions.x}
        y={containerDimensions.y}
        onWheel={handleWheel}
        draggable
        {...data.props}
      >
        {/* LAYERS */}
        <Layer>
          {layers &&
            layers
              .filter(
                (l) => !selectedLayers.some((sl) => Number(sl) === Number(l.id))
              )
              .map((layer) => (
                <Group {...layer.props} key={layer.id}>
                  {/* TILES */}
                  {layer.tiles?.map((tile) => {
                    if (tile.render !== undefined) return tile.render(tile);
                    else return <DefaultTile tile={tile} />;
                  })}
                </Group>
              ))}
        </Layer>
      </Stage>
    </Box>
  );
};

function RenderActionButtons(props) {
  const { zoomIn, zoomOut, layers } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const disabled = layers.layers.length === 0;
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        zIndex: 1,
      }}
    >
      <Grid item>
        <IconButton disabled={disabled} onClick={zoomIn}>
          <ZoomInIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton disabled={disabled} onClick={zoomOut}>
          <ZoomOutIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          disabled={disabled}
        >
          <LayersIcon />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorPosition={{ top: 0, left: -100 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <List>
            {layers.layers.map((layer) => (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    layers.handleSelectedLayersChange(layer);
                  }}
                >
                  <ListItemIcon>
                    {layers.selectedLayers.some(
                      (sl) => Number(sl) === Number(layer.id)
                    ) ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={layer.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>
      </Grid>
    </div>
  );
}

function DefaultTile(props) {
  const { tile } = props;
  return (
    <Tile
      {...tile.props}
      closed={true}
      fill={tile.color ?? TILE_BLUE_COLOR}
      onClick={tile.onClick}
      points={tile.points ?? undefined}
      stroke={TILE_DEFAULT_EDGE_COLOR}
      strokeWidth={TILE_DEFAULT_STROKE_WIDTH}
      tile={tile}
    />
  );
}

export default FloorPlanRender;
