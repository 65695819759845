import { useEffect, useContext, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import BackupIcon from "@mui/icons-material/Backup";

import AppContext from "../../../context/AppContext";
import Select from "../../global/inputs/Select";
import TransferList from "../../Inputs/TransferList";
import TextInput from "../../Inputs/TextInput";
import Button from "../../Inputs/CustomButton";
import { CUSTOMER_ROLE_ID } from "../../../data/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_FORM":
      return { ...state, form: action.payload };
    case "SET_USERS_RIGHT":
      return { ...state, usersRight: action.payload };
    case "SET_USERS_LEFT":
      return { ...state, usersLeft: action.payload };
    case "SET_ROLES_RIGHT":
      return { ...state, rolesRight: action.payload };
    case "SET_ROLES_LEFT":
      return { ...state, rolesLeft: action.payload };
    case "SET_SHOW_USERS_PERMISIONS":
      return { ...state, showUsersPermisions: !state.showUsersPermisions };
    case "SET_SHOW_ROLES_PERMISIONS":
      return { ...state, showRolesPermisions: !state.showRolesPermisions };
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  form: {
    id: "",
    parentId: "",
    name: "",
    file: "",
  },

  usersRight: [],
  usersLeft: [],

  rolesRight: [],
  rolesLeft: [],

  showUsersPermisions: false,
  showRolesPermisions: false,
};

const FileForm = (props) => {
  const { api, user } = useContext(AppContext);
  const { data, isNew, open, onClose, onSubmit, users, roles, directories } =
    props;
  const [t] = useTranslation("documents");

  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "SET_FORM", payload: data });
    getTransferListUsers(data?.Users);
    getTransferListRoles(data?.Roles);
  }, [data]);

  const getTransferListUsers = (data) => {
    let right = [];
    let left = [];

    //Get right users' names (if exist):
    if (data)
      data.forEach((user) => {
        right.push(user.name);
      });

    //Get left users' names:
    users.forEach((user) => {
      left.push(user.name);
    });

    //Filter left users (if is necessary):
    if (data)
      right.forEach((userRight) => {
        left = left.filter((userLeft) => userLeft != userRight);
      });

    //Set users:
    dispatch({ type: "SET_USERS_RIGHT", payload: right });
    dispatch({ type: "SET_USERS_LEFT", payload: left });
  };

  const getTransferListRoles = (data) => {
    let right = [];
    let left = [];

    //Get right roles' names (if exist):
    if (data)
      data.forEach((role) => {
        right.push(role.name);
      });

    //Get left roles' names:
    roles.forEach((role) => {
      left.push(role.name);
    });

    //Filter left roles (if is necessary):
    if (data)
      right.forEach((roleRight) => {
        left = left.filter((roleLeft) => roleLeft != roleRight);
      });

    //Set roles :
    dispatch({ type: "SET_ROLES_LEFT", payload: left });
    dispatch({ type: "SET_ROLES_RIGHT", payload: right });
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  const setUsersRight = (usersRight) => {
    const usersToAdd = not(usersRight, state.usersRight);

    if (usersToAdd.length > 0 && !isNew) {
      let idUsersToAdd = [];
      usersToAdd.forEach((toAdd) => {
        idUsersToAdd.push(users.filter((user) => user.name == toAdd));
      });

      api
        .post("/files/" + state.form.id + "/user-add", idUsersToAdd)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_USERS_RIGHT", payload: usersRight });
            enqueueSnackbar("permissionAdded", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    } else dispatch({ type: "SET_USERS_RIGHT", payload: usersRight });
  };

  const setUsersLeft = (usersLeft) => {
    const usersToDelete = not(usersLeft, state.usersLeft);

    if (usersToDelete.length > 0 && !isNew) {
      let idUsersToDelete = [];
      usersToDelete.forEach((toDelete) => {
        idUsersToDelete.push(
          users.filter((user) => user.name == toDelete)[0].id
        );
      });

      api
        .post("/files/" + state.form.id + "/user-delete", idUsersToDelete)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_USERS_LEFT", payload: usersLeft });
            enqueueSnackbar("permissionDeleted", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    } else dispatch({ type: "SET_USERS_LEFT", payload: usersLeft });
  };

  const setRolesRight = (rolesRight) => {
    const rolesToAdd = not(rolesRight, state.rolesRight);

    if (rolesToAdd.length > 0 && !isNew) {
      let idRolesToAdd = [];
      rolesToAdd.forEach((toAdd) => {
        idRolesToAdd.push(roles.filter((role) => role.name == toAdd));
      });

      api
        .post("/files/" + state.form.id + "/role-add", idRolesToAdd)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_ROLES_RIGHT", payload: rolesRight });
            enqueueSnackbar("permissionAdded", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    } else dispatch({ type: "SET_ROLES_RIGHT", payload: rolesRight });
  };

  const setRolesLeft = (rolesLeft) => {
    const rolesToDelete = not(rolesLeft, state.rolesLeft);

    if (rolesToDelete.length > 0 && !isNew) {
      let idRolesToDelete = [];
      rolesToDelete.forEach((toDelete) => {
        idRolesToDelete.push(
          roles.filter((role) => role.name == toDelete)[0].id
        );
      });

      api
        .post("/files/" + state.form.id + "/role-delete", idRolesToDelete)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_ROLES_LEFT", payload: rolesLeft });
            enqueueSnackbar("permissionDeleted", { variant: "success" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    } else dispatch({ type: "SET_ROLES_LEFT", payload: rolesLeft });
  };

  const getPermissions = (e) => {
    const parentId = e.target.value;
    api
      .get("/directories/" + parentId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          //Inherit user and role permissions from parent
          const parentUserPermissions = response.data.Users || [];
          const parentRolePermissions = response.data.Roles || [];
          //Update user permissions
          const usersRight = parentUserPermissions.map((user) => user.name);
          dispatch({
            type: "SET_USERS_RIGHT",
            payload: usersRight,
          });

          const usersLeft = users
            .filter(
              (user) =>
                !parentUserPermissions.find(
                  (userRight) => userRight.id === user.id
                )
            )
            .map((userLeft) => userLeft.name);
          dispatch({
            type: "SET_USERS_LEFT",
            payload: usersLeft,
          });
          //Update role permissions
          const rolesRight = parentRolePermissions.map((role) => role.name);
          dispatch({
            type: "SET_ROLES_RIGHT",
            payload: rolesRight,
          });

          const rolesLeft = roles
            .filter(
              (role) =>
                !parentRolePermissions.find(
                  (roleRight) => roleRight.id === role.id
                )
            )
            .map((roleLeft) => roleLeft.name);
          dispatch({
            type: "SET_ROLES_LEFT",
            payload: rolesLeft,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "file",
          value: e.target.files,
        },
      });
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "name",
          value: e.target.files[0].name,
        },
      });
    }
  };

  const closeForm = () => {
    onClose();
    dispatch({ type: "RESET_FORM" });
  };

  return (
    <Dialog open={open} onClose={closeForm}>
      <DialogTitle id="form-dialog-title">
        <Grid container justifyContent="space-between">
          <Grid item>
            {isNew ? t("create") : t("edit")} {t("file").toLowerCase()}
          </Grid>
          <Grid item>
            <IconButton onClick={closeForm}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={6}>
              <TextInput
                label={t("name")}
                name="name"
                onChange={handleInputChange}
                value={state.form.name}
              />
            </Grid>

            <Grid item xs={6}>
              <Select
                label={t("directory")}
                name="parentId"
                value={state.form.parentId}
                options={directories.map((dir) => ({
                  value: dir.id,
                  label: dir.fullPath,
                }))}
                onChange={(e) => {
                  handleInputChange(e);
                  getPermissions(e);
                }}
                searchable
              />
            </Grid>
            {isNew && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  startIcon={<BackupIcon />}
                >
                  {t("uploadFile")}
                  <input
                    id="fileInput"
                    type="file"
                    hidden
                    name="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            )}

            {(user.hasAction("VIEW_USERS") || user.hasAction("VIEW_ROLES")) && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}

            {user.hasAction("VIEW_USERS") && (
              <Grid item xs={12}>
                <Button
                  variant="text"
                  fullWidth={true}
                  endIcon={
                    state.showUsersPermisions ? <ExpandLess /> : <ExpandMore />
                  }
                  onClick={() =>
                    dispatch({ type: "SET_SHOW_USERS_PERMISIONS" })
                  }
                >
                  {t("manageUsersPermissions")}
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <Collapse orientation={"vertical"} in={state.showUsersPermisions}>
                <Grid item>
                  <TransferList
                    left={state.usersLeft.sort((a, b) => a.localeCompare(b))}
                    right={state.usersRight.sort((a, b) => a.localeCompare(b))}
                    setRight={setUsersRight}
                    setLeft={setUsersLeft}
                  >
                    {" "}
                  </TransferList>
                </Grid>
              </Collapse>
            </Grid>

            {user.hasAction("VIEW_ROLES") && (
              <Grid item xs={12}>
                <Button
                  variant="text"
                  fullWidth={true}
                  endIcon={
                    state.showRolesPermisions ? <ExpandLess /> : <ExpandMore />
                  }
                  onClick={() =>
                    dispatch({ type: "SET_SHOW_ROLES_PERMISIONS" })
                  }
                >
                  {t("manageRolesPermissions")}
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Collapse orientation={"vertical"} in={state.showRolesPermisions}>
                <Grid item>
                  <TransferList
                    left={state.rolesLeft.sort((a, b) => a.localeCompare(b))}
                    right={state.rolesRight.sort((a, b) => a.localeCompare(b))}
                    setRight={setRolesRight}
                    setLeft={setRolesLeft}
                  >
                    {" "}
                  </TransferList>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={state.isFilePermissionsCollapsed}>
              <Typography variant="body1">Can see</Typography>
              <TransferList
                /*left={state.fileForm.cantSee}
              right={state.fileForm.canSee}
              setLeft={setArrFile}
              setRight={setArrFile}*/
                name="cantSee"
                name2="canSee"
              />
              <Typography variant="body1">Can edit</Typography>
              <TransferList
                /*left={state.fileForm.cantEdit}
              right={state.fileForm.canEdit}
              setLeft={setArrFile}
              setRight={setArrFile}*/
                name="cantEdit"
                name2="canEdit"
              />
              {/* !state.createFile && "Created by: " + state.fileForm.createdBy */}
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeForm} variant="text">
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            if (isNew) {
              let idUsersToAdd = [];
              state.usersRight.forEach((toAdd) => {
                idUsersToAdd.push(users.filter((user) => user.name == toAdd));
              });

              let idRolesToAdd = [];
              state.rolesRight.forEach((toAdd) => {
                idRolesToAdd.push(roles.filter((role) => role.name == toAdd));
              });

              onSubmit(state.form, idUsersToAdd, idRolesToAdd);
            } else {
              onSubmit(state.form);
              dispatch({ type: "RESET_FORM" });
            }
          }}
          disabled={state.form.name === "" || state.form.file === ""}
        >
          {isNew ? t("create") : t("edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileForm;
