import { Grid, Typography } from "@mui/material";

import Line from "../../../global/charts/Line";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "./ChartContainer";
import { NewChartContainer } from "./NewChartContainer";
import { colors } from "../Components/ColorGenerator";
import {
  chartDataGenerator,
  dataParser,
  getDataArray,
  getDates,
  parsePercentageData,
  valueToPercentage,
} from "../../../../utils/chartUtils";
import DataTypeToggle from "../Components/DataTypeToggle";
import DateLabelsToggle from "../Components/DateLabelsToggle";
import ValueFormatToggle from "../Components/ValueFormatToggle";

import { daysDifference } from "../../../../utils/date";

import { localeFormat } from "../../../../utils/format";
import Bar from "../../../global/charts/Bar";
import {
  // Platform source IDs
  APP_SOURCE_ID,
  ERP_SOURCE_ID,
  WEB_SOURCE_ID,

  // Source names
  DIRECT_TRAFFIC_SOURCE,
  NO_VALUE_SOURCE,
  OFFLINE_SOURCE,
  ORGANIC_SEARCH_SOURCE,
  OTHER_CAMPAIGNS_SOURCE,
  PAID_SEARCH_SOURCE,
  REFERRALS_SOURCE,
  SOCIAL_MEDIA_SOURCE,

  // Charts index
  BAR,
  DOUGHNUT,
  LINE,
  PIE,
} from "../../../../data/constants";

const dataSetsColors = [
  colors.primary,
  colors.secondary,
  colors.red,
  colors.brown,
  colors.yellow,
  colors.orange,
  colors.purple,
  colors.blue,
  colors.green,
  colors.turquoise,
];

const dataGetters = {
  contracts: (items) => items.length,
  meters: (items) => items.reduce((acc, item) => acc + item.meters, 0),
  invoicing: (items) => items.reduce((acc, item) => acc + item.price, 0),
  pricePerMeter: (items) => {
    const invoicing = items.reduce(
      (acc, item) => acc + item.pricePerMeter * item.meters,
      0
    );
    const meters = items.reduce((acc, item) => acc + item.meters, 0);
    return meters > 0 ? invoicing / meters : 0;
  },
};

export const SalesHistory = ({ contracts, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [period, setPeriod] = useState("months");
  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts
    .map((contract) => ({
      ...contract,
      date: contract.createdAt.slice(0, 10),
      price: contract.meters * contract.pricePerMeter,
      pricePerMeter: contract.pricePerMeter,
      meters: contract.meters,
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const labels = getDates(startDate, endDate, period);

  const moveInsGetter = (items) => items.length;
  const metersGetter = (items) =>
    items.reduce((acc, item) => acc + item.meters, 0);
  const invoicingGetter = (items) =>
    items.reduce((acc, item) => acc + item.price, 0);
  const pricePerMeterGetter = (items) => {
    const invoicing = items.reduce(
      (acc, item) => acc + item.pricePerMeter * item.meters,
      0
    );
    const meters = items.reduce((acc, item) => acc + item.meters, 0);
    return meters > 0 ? invoicing / meters : 0;
  };

  const moveInsDataArray = getDataArray(
    parsedContracts,
    labels,
    period,
    moveInsGetter
  );
  const metersDataArray = getDataArray(
    parsedContracts,
    labels,
    period,
    metersGetter
  );
  const invoicingDataArray = getDataArray(
    parsedContracts,
    labels,
    period,
    invoicingGetter
  );
  const pricePerMeterDataArray = getDataArray(
    parsedContracts,
    labels,
    period,
    pricePerMeterGetter
  );

  let label = "";
  let dataArray = [];
  switch (dataType) {
    case "contracts":
      label = t("contracts");
      dataArray = moveInsDataArray;
      break;
    case "meters":
      label = t("meters");
      dataArray = metersDataArray;
      break;
    case "invoicing":
      label = t("invoicing");
      dataArray = invoicingDataArray;
      break;
    case "pricePerMeter":
      label = t("pricePerMeter");
      dataArray = pricePerMeterDataArray;
      break;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: dataArray,
        backgroundColor: colors.primary,
        borderColor: colors.primary,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(
              Number(value.formattedValue.replace(".", "").replace(",", "."))
            )}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("salesHistory")}
      chart={<Line data={data} options={options} />}
    >
      <Grid item container justifyContent={"space-between"}>
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const HistoricalPricePerMeter = ({ contracts, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [period, setPeriod] = useState("months");

  const groupBy = [
    {
      propName: t("date"),
      valueCalculator: (item) => item?.createdAtDates[period],
      labels: getDates(startDate, endDate, period),
    },
  ];

  const config = {
    propName: t("pricePerMeter"),
    valueCalculator: (items) => {
      const invoicing = items.reduce(
        (acc, item) => acc + item.pricePerMeter * item.meters,
        0
      );
      const meters = items.reduce((acc, item) => acc + item.meters, 0);
      return meters > 0 ? invoicing / meters : 0;
    },
  };

  return (
    <NewChartContainer
      title={t("historicalPricePerMeter")}
      data={contracts}
      groupBy={groupBy}
      config={config}
      chart={LINE}
    >
      <Grid
        item
        container
        paddingRight={1}
        marginBottom={2}
        justifyContent={"flex-end"}
      >
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalSalesByPaymentMethod = ({
  contracts,
  startDate,
  endDate,
  paymentMethodNames,
}) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const groupBy = [
    {
      propName: t("date"),
      valueCalculator: (item) => item?.createdAtDates["months"],
      labels: getDates(startDate, endDate, "months"),
    },
    {
      propName: t("paymentMethod"),
      valueCalculator: (item) => t(paymentMethodNames[item.paymentMethod ?? 0]),
    },
  ];

  const config = {
    propName: dataType,
    valueCalculator: dataGetters[dataType],
  };

  return (
    <NewChartContainer
      title={t("historicalSalesByPaymentMethod")}
      data={contracts}
      groupBy={groupBy}
      config={config}
      chart={LINE}
    >
      <Grid
        item
        container
        paddingLeft={1}
        marginBottom={2}
        justifyContent={"space-between"}
      >
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HiringLeadTime = ({ contracts, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [period, setPeriod] = useState("months");

  const parsedContracts = contracts
    .map((contract) => ({
      ...contract,
      date: contract.createdAt.slice(0, 10),
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const labels = getDates(startDate, endDate, period);

  const valueGetter = (items) => {
    const diffArray = items.map((item) => {
      const startDate = new Date(item.startDate);
      const createdAt = new Date(item.createdAt);
      return daysDifference(startDate, createdAt);
    });
    return diffArray.reduce((acc, diff) => acc + diff, 0) / diffArray.length;
  };
  const dataArray = getDataArray(parsedContracts, labels, period, valueGetter);

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("contracts"),
        data: dataArray,
        backgroundColor: colors.primary,
        borderColor: colors.primary,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${t("hiringLeadTime")}: ${localeFormat(
              Number(value.formattedValue.replace(".", "").replace(",", "."))
            )}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <ChartContainer
      title={t("hiringLeadTime")}
      chart={<Line data={data} options={options} />}
    >
      <Grid item container justifyContent={"flex-end"}>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const SalesByCenter = ({ contracts }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts.map((contract) => {
    return {
      ...contract,
      centerId: contract.center?.id,
      price: contract.meters * contract.pricePerMeter,
    };
  });

  /**
   * Processes the given contracts and returns an object with the following properties:
   * - meterSum: an array of the new meters of each center
   * - moveInsCount: an array of the new contracts of each center
   * - priceSum: an array of the new invoicing of each center
   * - labels: an array of the names of the centers
   *
   * The arrays are sorted in descending order by the number of move-ins of each center.
   * @param {Array} contracts the contracts to be processed
   * @return {Object} an object with the above properties
   */
  const processContracts = (contracts) => {
    const centerMap = new Map();

    for (const contract of contracts) {
      // If the center is not in the map, add it
      if (!centerMap.has(contract.centerId)) {
        // Initialize the values
        centerMap.set(contract.centerId, {
          meters: 0,
          moveIns: 0,
          price: 0,
          name: contract.center?.name,
        });
      }

      // Get the center data and update the values
      const centerData = centerMap.get(contract.centerId);
      centerData.meters += contract.meters;
      centerData.moveIns++;
      centerData.price += contract.price;
    }

    // Sort the centers by the number of move-ins
    const sortedCenters = [...centerMap.values()].sort(
      (a, b) => b.moveIns - a.moveIns
    );

    return {
      meterSum: sortedCenters.map((center) => center.meters),
      moveInsCount: sortedCenters.map((center) => center.moveIns),
      priceSum: sortedCenters.map((center) => center.price),
      pricePerMeter: sortedCenters.map((center) =>
        center.meters > 0 ? center.price / center.meters : 0
      ),
      labels: sortedCenters.map((center) => center.name),
    };
  };

  const {
    meterSum: meterSumArray,
    moveInsCount: moveInsCountArray,
    priceSum: priceSumArray,
    pricePerMeter: pricePerMeterArray,
    labels,
  } = processContracts(parsedContracts);

  let label = "";
  let dataArray = [];
  switch (dataType) {
    case "contracts":
      label = t("contracts");
      dataArray = moveInsCountArray;
      break;
    case "meters":
      label = t("meters");
      dataArray = meterSumArray;
      break;
    case "invoicing":
      label = t("invoicing");
      dataArray = priceSumArray;
      break;
    case "pricePerMeter":
      label = t("pricePerMeter");
      dataArray = pricePerMeterArray;
      break;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: dataArray,
        backgroundColor: colors.primary,
        borderColor: colors.primary,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            const sanitizedValue = value.formattedValue.replace(/,/g, "");
            const numericValue = Number(sanitizedValue);
            return `${value.dataset.label}: ${localeFormat(numericValue)}`;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("salesByCenter")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid item container justifyContent={"space-between"}>
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const SalesByPlatform = ({ contracts, startDate, endDate }) => {
  const [t] = useTranslation("dashboard");
  const [period, setPeriod] = useState("months");
  const [dataType, setDataType] = useState("contracts");
  const [valueFormat, setValueFormat] = useState("value");

  const groupColor = {
    ERP: colors.blue,
    WEB: colors.orange,
    APP: colors.green,
  };

  const groupBy = [
    {
      propName: t("date"),
      valueCalculator: (item) => item?.createdAtDates[period],
      labels: getDates(startDate, endDate, period),
    },
    {
      propName: t("platform"),
      valueCalculator: (item) => item.sourceName,
      colorGetter: (label) => groupColor[label] || colors.primary,
      labels: ["ERP", "WEB", "APP"],
    },
  ];

  const config = {
    propName: dataType,
    valueCalculator: dataGetters[dataType],
  };

  return (
    <NewChartContainer
      title={t("salesByPlatform")}
      data={contracts}
      groupBy={groupBy}
      config={config}
      percentage={valueFormat === "percentage"}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={2}
        marginTop={1}
      >
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
        <Grid item>
          <ValueFormatToggle
            value={valueFormat}
            onChange={(e, v) => setValueFormat(v)}
          />
        </Grid>
        <Grid item>
          <DateLabelsToggle value={period} onChange={(e, v) => setPeriod(v)} />
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const SalesByAges = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts.filter((c) => c.customerAge);
  const calculationPercentage =
    contracts.length > 0
      ? (parsedContracts.length * 100) / contracts.length
      : 0;

  const labels = [
    " > 65",
    "61 - 65",
    "56 - 60",
    "51 - 55",
    "46 - 50",
    "41 - 45",
    "36 - 40",
    "31 - 35",
    "26 - 30",
    "21 - 25",
    " < 20",
  ];

  const labelsContracts = labels.map((label) => {
    return parsedContracts.filter((contract) => {
      const age = contract.customerAge;
      if (label === " > 65") return age > 65;
      if (label === " < 20") return age < 20;
      const [min, max] = label.split(" - ");
      return age >= min && age <= max;
    });
  });

  const getDataArrays = (getter, filter) => {
    return labelsContracts.map((labelContracts) => {
      let dataArray = labelContracts.filter(filter || (() => true));
      return dataGetters[getter](dataArray);
    });
  };

  const allCustomersData = getDataArrays(dataType);
  const mansData = getDataArrays(
    dataType,
    (item) => item.customerGender === "Male"
  );
  const womenData = getDataArrays(
    dataType,
    (item) => item.customerGender === "Female"
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("allCustomers"),
        data: allCustomersData,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 0",
      },
      {
        label: t("men"),
        data: mansData,
        borderColor: colors.primary,
        backgroundColor: colors.primary,
        hidden: true,
        stack: "Stack 1",
      },
      {
        label: t("women"),
        data: womenData,
        borderColor: colors.red,
        backgroundColor: colors.red,
        hidden: true,
        stack: "Stack 1",
      },
    ],
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <ChartContainer
      title={t("salesByAge")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={2}
        marginTop={1}
      >
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
        <Grid item alignContent={"end"}>
          <Typography fontStyle={"italic"} fontSize={13}>
            {t("calculationOnThe") +
              " " +
              localeFormat(calculationPercentage) +
              "% " +
              t("ofContracts")}
          </Typography>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const SalesByClusters = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts.filter((c) => c.clusterName !== "");
  const calculationPercentage =
    contracts.length > 0
      ? (parsedContracts.length * 100) / contracts.length
      : 0;

  const labels = [
    "Taquilla",
    "Trastero Pequeño",
    "Trastero Mediano",
    "Trastero Grande",
    "Trastero XL",
    "Almacén",
  ];

  const labelsContracts = labels.map((label) => {
    return parsedContracts.filter((contract) => {
      return contract.clusterName === label;
    });
  });

  const getDataArrays = (getter, filter) => {
    return labelsContracts.map((labelContracts) => {
      let dataArray = labelContracts.filter(filter || (() => true));
      return dataGetters[getter](dataArray);
    });
  };

  const valueData = getDataArrays(dataType);

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("contracts"),
        data: valueData,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 0",
      },
    ],
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <ChartContainer
      title={t("salesByCluster")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={2}
        marginTop={1}
      >
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
        <Grid item alignContent={"end"}>
          <Typography fontStyle={"italic"} fontSize={13}>
            {t("calculationOnThe") +
              " " +
              localeFormat(calculationPercentage) +
              "% " +
              t("ofContracts")}
          </Typography>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const SalesByWeekDay = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts.map((contract) => {
    const day = new Date(contract.createdAt).getDay();
    return {
      ...contract,
      day: day === 0 ? 6 : day - 1,
    };
  });

  const labels = [
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
    t("sunday"),
  ];

  const labelsContracts = labels.map((label) => {
    return parsedContracts.filter((contract) => {
      return contract.day === labels.indexOf(label);
    });
  });

  const getDataArrays = (getter, filter) => {
    return labelsContracts.map((labelContracts) => {
      let dataArray = labelContracts.filter(filter || (() => true));
      return dataGetters[getter](dataArray);
    });
  };

  const valueData = getDataArrays(dataType);

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("contracts"),
        data: valueData,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 0",
      },
    ],
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <ChartContainer
      title={t("salesByWeekDay")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid item container justifyContent={"space-between"} marginBottom={2}>
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const SalesByHours = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts.map((contract) => {
    return {
      ...contract,
      hour: new Date(contract.createdAt).getHours(),
    };
  });

  const labels = Array.from({ length: 24 }, (_, i) => i);

  const labelsContracts = labels.map((label) => {
    return parsedContracts.filter((contract) => {
      return contract.hour === label;
    });
  });

  const getDataArrays = (getter, filter) => {
    return labelsContracts.map((labelContracts) => {
      let dataArray = labelContracts.filter(filter || (() => true));
      return dataGetters[getter](dataArray);
    });
  };

  const valueData = getDataArrays(dataType);

  const data = {
    labels: labels.map((label) => `${String(label).padStart(2, "0")}:00`),
    datasets: [
      {
        label: t("contracts"),
        data: valueData,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 0",
      },
    ],
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <ChartContainer
      title={t("hourSales")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid item container justifyContent={"space-between"} marginBottom={2}>
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export const SalesBySource = ({ contracts }) => {
  const [t] = useTranslation("dashboard");

  const [dataType, setDataType] = useState("contracts");

  const parsedContracts = contracts;

  const labels = [
    DIRECT_TRAFFIC_SOURCE,
    "NOT_ASSIGNED",
    OFFLINE_SOURCE,
    ORGANIC_SEARCH_SOURCE,
    OTHER_CAMPAIGNS_SOURCE,
    PAID_SEARCH_SOURCE,
    REFERRALS_SOURCE,
    SOCIAL_MEDIA_SOURCE,
  ];

  const labelsContracts = labels.map((label) => {
    return parsedContracts.filter((contract) => {
      if (contract.hubSpotContact === null && label === "NOT_ASSIGNED")
        return true;
      return contract.hubSpotOriginSource === label;
    });
  });

  const getDataArrays = (getter, filter) => {
    return labelsContracts.map((labelContracts) => {
      let dataArray = labelContracts.filter(filter || (() => true));
      return dataGetters[getter](dataArray);
    });
  };

  const valueData = getDataArrays(dataType);

  const data = {
    labels: labels.map((label) => t(label)),
    datasets: [
      {
        label: t("contracts"),
        data: valueData,
        borderColor: colors.secondary,
        backgroundColor: colors.secondary,
        stack: "Stack 0",
      },
    ],
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <ChartContainer
      title={t("salesBySource")}
      chart={<Bar data={data} options={options} />}
    >
      <Grid item container justifyContent={"space-between"} marginBottom={2}>
        <Grid item>
          <DataTypeToggle
            value={dataType}
            onChange={(e, v) => setDataType(v)}
          />
        </Grid>
      </Grid>
    </ChartContainer>
  );
};
