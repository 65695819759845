import { Stack } from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import PositionCollapsible from "../ItemCollapsibles/PositionCollapsible";
import { FloorPlanContext } from "../../../FloorPlanPage";
import ActionButtons from "./ActionButtons";
import GeneralCollapsible from "../ItemCollapsibles/GeneralCollapsible";
import FormTitle from "./FormTitle";
import TextCollapsible from "../ItemCollapsibles/TextCollapsible";

const TextForm = (props) => {
  const { item } = props;
  const { selectTile, editTile, setEditItem } = useContext(FloorPlanContext);

  const setName = (name) => setEditItem({ ...item, name });
  const setX = (x) => setEditItem({ ...item, x });
  const setY = (y) => setEditItem({ ...item, y });
  const setText = (text) => setEditItem({ ...item, text });
  const setTextAlign = (textAlign) => setEditItem({ ...item, textAlign });
  const setFontSize = (fontSize) => setEditItem({ ...item, fontSize });

  const onEdit = async () => {
    await editTile({
      id: item.id,
      name: item.name,
      text: item.text,
      textAlign: item.textAlign,
      fontSize: item.fontSize,
      x: item.x,
      y: item.y,
    });
    selectTile(null);
  };

  return (
    <Stack spacing={2}>
      <FormTitle shape={item.shape} name={item.name} />
      <GeneralCollapsible name={item.name} setName={setName} />
      <TextCollapsible
        text={item.text}
        setText={setText}
        textAlign={item.textAlign || "left"}
        setTextAlign={setTextAlign}
        fontSize={item.fontSize}
        setFontSize={setFontSize}
      />
      <PositionCollapsible x={item.x} setX={setX} y={item.y} setY={setY} />
      <ActionButtons onCancel={() => selectTile(null)} onSave={onEdit} />
    </Stack>
  );
};

TextForm.propTypes = {
  item: PropTypes.object,
};

export default TextForm;
