import { enqueueSnackbar } from "notistack";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CentersContext } from "../Centers";
import AppContext from "../../../../../context/AppContext";

import {
  firstDateCurrentMonth,
  lastDateCurrentMonth,
} from "../../../../../utils/date";

import {
  CentersInvoicingTreeMap,
  CentersInvoicingBarChart,
} from "../../Charts/CentersCharts";

import { localeFormat } from "../../../../../utils/format";
import CustomDate from "../../../../Inputs/CustomDate";
import Filters from "../../../../global/structure/Filters";
import InformationBox from "../../Components/InformationBox";
import SearchButton from "../../../../Inputs/SearchButton";

const initialFilters = {
  dateFrom: firstDateCurrentMonth(),
  dateUntil: lastDateCurrentMonth(),
};

function InvoicingTab() {
  const [t] = useTranslation("dashboard");
  const { api } = useContext(AppContext);
  const { filteredCenters, centers } = useContext(CentersContext);

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    setLoaded(false);
    const params = {
      include: ["Merchantable"],
      attributes: ["id", "items", "serieId"],
      types: [1, 6],
    };

    filters.dateFrom !== "" && (params.dateFrom = filters.dateFrom);
    filters.dateUntil !== "" && (params.dateUntil = filters.dateUntil);

    api
      .get("/invoices", { params })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(t("errorGettingInvoices"), { variant: "error" });
        } else {
          const items = res.data
            .map((invoice) =>
              invoice.items.map((item) => {
                const centerId =
                  item.Merchantable?.centerId !== undefined
                    ? item.Merchantable?.centerId
                    : null;
                return {
                  ...item,
                  centerId: centerId,
                  center: centers.find((center) => center.id === centerId),
                };
              })
            )
            .flat();
          setItems(items);
        }
      })
      .catch((e) => {
        enqueueSnackbar(e, { variant: "error" });
        console.log(e);
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const filteredItems = filterInvoices(items, filteredCenters);
  const {
    nutInvoices,
    franchiseInvoices,
    otherInvoices,
    nutInvoiced,
    franchiseInvoiced,
    otherInvoiced,
  } = getPageValues(filteredItems);

  return (
    <Grid container spacing={2}>
      <Grid item marginTop={2}>
        <Filters
          filters={[
            <CustomDate
              name="dateFrom"
              label={t("dateFrom")}
              value={filters.dateFrom}
              onChange={(e) =>
                setFilters({ ...filters, dateFrom: e.target.value })
              }
            />,
            <CustomDate
              name="dateUntil"
              label={t("dateUntil")}
              value={filters.dateUntil}
              onChange={(e) =>
                setFilters({ ...filters, dateUntil: e.target.value })
              }
            />,
            <SearchButton
              onClick={getInvoices}
              text={t("search")}
              loading={!loaded}
              loadingText={t("searching")}
            />,
          ]}
        />
      </Grid>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={2}
        marginBottom={1}
      >
        <Grid item>
          <InformationBox title={t("nutInvoices")} mainValue={nutInvoices} />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("franchiseInvoices")}
            mainValue={franchiseInvoices}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("otherInvoices")}
            mainValue={otherInvoices}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("nutInvoiced")}
            mainValue={localeFormat(nutInvoiced) + " €"}
            width={265}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("franchiseInvoiced")}
            mainValue={localeFormat(franchiseInvoiced) + " €"}
            width={265}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("otherInvoiced")}
            mainValue={localeFormat(otherInvoiced) + " €"}
            width={265}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CentersInvoicingTreeMap invoiceItems={filteredItems} />
      </Grid>
      <Grid item xs={12}>
        <CentersInvoicingBarChart invoiceItems={filteredItems} />
      </Grid>
    </Grid>
  );
}

const filterInvoices = (invoices, centers) => {
  return invoices.filter((invoice) => {
    const center = centers.find((center) => center.id === invoice.centerId);
    return center !== undefined;
  });
};

const getPageValues = (invoiceItems) => {
  const nutInvoices = invoiceItems.filter((item) => item.center?.type === 0);
  const franchiseInvoices = invoiceItems.filter(
    (item) => item.center?.type === 1
  );
  const otherInvoices = invoiceItems.filter((item) => item.center?.type === 2);

  const nutInvoiced = nutInvoices.reduce(
    (acc, item) => acc + item.units * item.pricePerUnit,
    0
  );
  const franchiseInvoiced = franchiseInvoices.reduce(
    (acc, item) => acc + item.units * item.pricePerUnit,
    0
  );
  const otherInvoiced = otherInvoices.reduce(
    (acc, item) => acc + item.units * item.pricePerUnit,
    0
  );

  return {
    nutInvoices: nutInvoices.length,
    franchiseInvoices: franchiseInvoices.length,
    otherInvoices: otherInvoices.length,
    nutInvoiced: nutInvoiced,
    franchiseInvoiced: franchiseInvoiced,
    otherInvoiced: otherInvoiced,
  };
};

export default InvoicingTab;
