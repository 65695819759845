import { Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CentersContext } from "../Centers";
import {
  CentersOccupancyBarChart,
  CentersPricePerMeterOccupancyScatterChart,
} from "../../Charts/CentersCharts";
import InformationBox from "../../Components/InformationBox";
import { localeFormat } from "../../../../../utils/format";
import {
  NUT_CENTER_TYPE_ID,
  FRANCHISE_CENTER_TYPE_ID,
  OTHER_CENTER_TYPE_ID,
} from "../../../../../data/constants";

function GeneralTab() {
  const [t] = useTranslation("dashboard");
  const { filteredCenters } = useContext(CentersContext);

  const {
    centers,
    nutCenters,
    franchiseCenters,
    nutOccupiedMetres,
    franchiseOccupiedMetres,
    nutTotalMeters,
    franchiseTotalMeters,
  } = getPageValues(filteredCenters);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={2}
        marginBottom={1}
      >
        <Grid item>
          <InformationBox title={t("nutCenters")} mainValue={nutCenters} />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("franchiseCenters")}
            mainValue={franchiseCenters}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("nutOccupiedMetres")}
            mainValue={localeFormat(nutOccupiedMetres) + " m²"}
            secondaryValue={
              localeFormat(
                (nutTotalMeters > 0 ? nutOccupiedMetres / nutTotalMeters : 0) *
                  100
              ) + "%"
            }
            width={240}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("franchiseOccupiedMetres")}
            mainValue={localeFormat(franchiseOccupiedMetres) + " m²"}
            secondaryValue={
              localeFormat(
                (franchiseTotalMeters > 0
                  ? franchiseOccupiedMetres / franchiseTotalMeters
                  : 0) * 100
              ) + "%"
            }
            width={240}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("nutTotalMeters")}
            mainValue={localeFormat(nutTotalMeters) + " m²"}
            width={240}
          />
        </Grid>
        <Grid item>
          <InformationBox
            title={t("franchiseTotalMeters")}
            mainValue={localeFormat(franchiseTotalMeters) + " m²"}
            width={240}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CentersOccupancyBarChart centers={centers} />
      </Grid>
      <Grid item xs={12}>
        <CentersPricePerMeterOccupancyScatterChart centers={centers} />
      </Grid>
    </Grid>
  );
}

const getPageValues = (centers) => {
  const filteredCenters = centers.filter((center) => center.type !== 2);

  const nutCenters = filteredCenters.filter(
    (center) => center.type === NUT_CENTER_TYPE_ID
  );
  const franchiseCenters = filteredCenters.filter(
    (center) => center.type === FRANCHISE_CENTER_TYPE_ID
  );

  const nutTotalMeters = nutCenters.reduce(
    (acc, center) => acc + center.totalMeters,
    0
  );

  const franchiseTotalMeters = franchiseCenters.reduce(
    (acc, center) => acc + center.totalMeters,
    0
  );

  const nutOccupiedMetres = nutCenters.reduce(
    (acc, center) => acc + center.occupiedMeters,
    0
  );
  const franchiseOccupiedMetres = franchiseCenters.reduce(
    (acc, center) => acc + center.occupiedMeters,
    0
  );

  return {
    centers: filteredCenters || 0,
    nutCenters: nutCenters.length || 0,
    franchiseCenters: franchiseCenters.length || 0,
    nutOccupiedMetres: nutOccupiedMetres || 0,
    franchiseOccupiedMetres: franchiseOccupiedMetres || 0,
    nutTotalMeters: nutTotalMeters || 0,
    franchiseTotalMeters: franchiseTotalMeters || 0,
  };
};

export default GeneralTab;
