import { Box, Grid, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "./ChartContainer";
import { colors } from "../Components/ColorGenerator";
import { localeFormat } from "../../../../utils/format";

const PercentageAuditedChart = ({ transactions, loading }) => {
  const [t] = useTranslation("dashboard");

  let total = transactions.reduce((total, transaction) => {
    const value =
      transaction.import >= 0 ? transaction.import : transaction.import * -1;
    return total + value;
  }, 0);
  let audited = transactions.reduce((total, transaction) => {
    const value =
      transaction.import >= 0 ? transaction.import : transaction.import * -1;
    return total + (transaction.auditDate !== null ? value : 0);
  }, 0);
  let nonAudited = total - audited;
  let auditedPercentage = (audited / total) * 100;
  if (isNaN(auditedPercentage)) auditedPercentage = 0;

  const data = {
    labels: [t("pending"), t("audited")],
    datasets: [
      {
        data: [nonAudited, audited],
        backgroundColor: [colors.primary, colors.secondary],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (dataPoint) => {
            const value =
              dataPoint.label === t("audited") ? audited : nonAudited;
            return `${dataPoint.label}: ${
              localeFormat(value) + "€"
            } (${localeFormat((value / total) * 100)}%) `;
          },
        },
      },
    },
  };

  return (
    <ChartContainer
      title={t("auditedTransactions")}
      chart={
        <Box position="relative">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Doughnut data={data} options={options} />
            </Grid>
          </Grid>
          <Box
            position="absolute"
            left={0}
            top={0}
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            paddingTop={4}
            sx={{ pointerEvents: "none" }}
          >
            <Typography variant="h4" component="span">
              {localeFormat(auditedPercentage)}%
            </Typography>
          </Box>
        </Box>
      }
      loading={loading}
    ></ChartContainer>
  );
};

export default PercentageAuditedChart;
