import {
  Button,
  Chip,
  Container,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  ButtonGroup,
} from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AssistantIcon from "@mui/icons-material/Assistant";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";

import AppContext from "../../../context/AppContext";

import { CustomTable } from "../../CustomTable";
import { formatDate } from "../../../utils/date";
import { localeFormat } from "../../../utils/format";
import { SERVER } from "../../../utils/API";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import InvoiceStateChip from "../../InvoiceStateChip";
import SearchButton from "../../Inputs/SearchButton";
import Select from "../../global/inputs/Select";
import TextInput from "../../Inputs/TextInput";

import { TRANSFER_PAYMENT_METHOD_ID } from "../../../data/constants";

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    case "SET_PROVIDER_INVOICES":
      return { ...state, providerInvoices: action.payload };
    case "SET_REMITTANCES":
      return { ...state, remittances: action.payload };
    case "SET_TRANSFER_PAYMENT_GROUPS":
      return { ...state, transferPaymentGroups: action.payload };
    case "SET_RECEIPT_RETURNED_GROUPS":
      return { ...state, receiptReturnedGroups: action.payload };
    case "SET_TABLE":
      return { ...state, tableData: action.payload };
    case "SET_TABLE_COLUMNS":
      return { ...state, columns: action.payload };
    case "SET_TABLE_LOADING_TRUE":
      return { ...state, tableLoading: true };
    case "SET_TABLE_LOADING_FALSE":
      return { ...state, tableLoading: false };
    case "SET_LOADING_TRUE":
      return { ...state, loading: true };
    case "SET_DEFAULT_FILTERS":
      const filterProps = {
        fromDate: action.payload.fromDate,
        untilDate: action.payload.untilDate,
        amountMin: action.payload.import,
        amountMax: action.payload.import,
      };
      return {
        ...state,
        filters: {
          ...state.filters,
          invoices: { ...state.filters.invoices, ...filterProps },
          providerInvoices: {
            ...state.filters.providerInvoices,
            ...filterProps,
            amountMin: -action.payload.import,
            amountMax: -action.payload.import,
          },
          remittances: { ...state.filters.remittances, ...filterProps },
          transferPaymentGroups: {
            ...state.filters.transferPaymentGroups,
            ...filterProps,
            amountMin: -action.payload.import,
            amountMax: -action.payload.import,
          },
          receiptReturnedGroups: {
            ...state.filters.receiptReturnedGroups,
            ...filterProps,
            amountMin: -action.payload.import,
            amountMax: -action.payload.import,
          },
          receiptSendingFees: {
            ...state.filters.receiptSendingFees,
            ...filterProps,
          },
        },
      };
    case "ADD_ITEM":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.array]: [
            ...state.form[action.payload.array],
            action.payload.value,
          ],
        },
      };
    case "DELETE_ITEM":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.array]: state.form[action.payload.array].filter(
            (item) => item.id !== action.payload.id
          ),
        },
      };
    case "SET_FILE":
      return { ...state, file: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: {
            ...state.filters[action.payload.type],
            [action.payload.inputname]: action.payload.value,
          },
        },
      };
    case "RESET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: action.payload.value,
        },
      };
    case "RESET_SELECTED_ITEMS":
      return {
        ...state,
        form: {
          ...state.form,
          invoices: initialState.form.invoices,
          providerInvoices: initialState.form.providerInvoices,
          remittances: initialState.form.remittances,
          transferPaymentGroups: initialState.form.transferPaymentGroups,
          receiptReturnedGroups: initialState.form.receiptReturnedGroups,
          comments: initialState.form.comments,
        },
      };
    case "SET_HAS_FILE":
      return { ...state, hasFile: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    default:
      throw new Error(action.type + " not found in reducer");
  }
}

const initialState = {
  columns: [],
  filters: {
    invoices: {
      amountMin: "",
      amountMax: "",
      fromDate: "",
      name: "", // customer name and invoice number
      state: "",
      paymentMethodId: TRANSFER_PAYMENT_METHOD_ID,
      untilDate: "",
    },
    providerInvoices: {
      amountMin: "",
      amountMax: "",
      fromDate: "",
      providerId: "",
      title: "",
      untilDate: "",
    },
    remittances: {
      amountMin: "",
      amountMax: "",
      fromDate: "",
      untilDate: "",
    },
    transferPaymentGroups: {
      amountMin: "",
      amountMax: "",
      fromDate: "",
      untilDate: "",
    },
    receiptReturnedGroups: {
      amountMin: "",
      amountMax: "",
      fromDate: "",
      untilDate: "",
      numberReceipts: "",
    },
    receiptSendingFees: {
      amountMin: "",
      amountMax: "",
      fromDate: "",
      untilDate: "",
    },
  },
  form: {
    isAudited: "",
    comments: "",
    transactionType: "",
    invoices: [],
    invoiceSeries: [],
    providerInvoices: [],
    remittances: [],
    transferPaymentGroups: [],
    receiptReturnedGroups: [],
  },
  invoices: [],
  paymentMethods: [],
  providers: [],
  providerInvoices: [],
  remittances: [],
  transferPaymentGroups: [],
  receiptReturnedGroups: [],
  tableData: [],
  file: "",
  hasFile: false,
  loading: false,
  tableLoading: false,
};

export default function AuditForm({ onSubmit, transaction }) {
  const { api, user } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("bankingTransactions");
  const [tErrors] = useTranslation("errors");

  /**
   * Initial useEffect
   */
  useEffect(() => {
    setForm();
    //Default filters:
    loadDefaultFilters();

    getProviders();
    getPaymentMethods();
    getInvoiceSeries();
  }, []);

  /**
   * TODO: Data types object
   */
  const TYPES = {
    PROVIDER_INVOICE: {
      getDataFromAPI: () => {},
      data: [],
      columns: [],
      filters: {},
    },
  };

  /**
   * Columns
   */
  const INVOICE_COLUMNS = [
    { key: "issueDate", label: t("issueDate"), sortType: "string" },
    {
      key: "number",
      label: t("number"),
      sortType: "number",
      renderFunction: (value, item) => item.InvoiceSerie?.name + value,
    },
    {
      key: "totalAmount",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "customerName", label: t("customer"), sortType: "string" },
    {
      key: "PaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
    { key: "addRow", label: t("addRow"), sortType: "other" },
  ];

  const PROVIDER_INVOICE_COLUMNS = [
    { key: "date", label: t("date"), sortType: "string" },
    { key: "title", label: t("title"), sortType: "string" },
    { key: "invoiceNumber", label: t("number"), sortType: "string" },
    {
      key: "total",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "Provider.brand", label: t("provider"), sortType: "string" },
    {
      key: "isReconciliated",
      label: t("reconciled"),
      sortType: "number",
      renderFunction: (value) =>
        value === true ? (
          <Chip color="success" label={t("reconciled")} size="small" />
        ) : (
          <Chip color="error" label={t("nonReconciled")} size="small" />
        ),
    },
    { key: "addRow", label: t("addRow"), sortType: "other" },
  ];

  const REMITTANCE_COLUMNS = [
    { key: "dueDate", label: t("dueDate"), sortType: "string" },
    { key: "name", label: t("name"), sortType: "string" },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "addRow", label: t("addRow"), sortType: "other" },
  ];

  const TRANSFER_PAYMENT_GROUP_COLUMNS = [
    { key: "date", label: t("date"), sortType: "string" },
    { key: "debtorIBAN", label: t("debtorIBAN"), sortType: "string" },
    {
      key: "TransferPayments.length",
      label: t("numberOfTransfers"),
      sortType: "number",
    },
    {
      key: "total",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "addRow", label: t("addRow"), sortType: "other" },
  ];

  const RECEIPT_RETURNED_GROUP_COLUMNS = [
    { key: "id", label: "ID", sortType: "number" },
    { key: "date", label: t("date"), sortType: "string" },
    {
      key: "numberOfReceipts",
      label: t("numberOfReceipts"),
      sortType: "number",
    },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "isReconciled",
      label: t("reconciled"),
      sortType: "number",
      renderFunction: (value) =>
        value === true ? (
          <Chip color="success" label={t("reconciled")} size="small" />
        ) : (
          <Chip color="error" label={t("nonReconciled")} size="small" />
        ),
    },
    { key: "addRow", label: t("addRow"), sortType: "other" },
  ];

  const setForm = () => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "comments", value: transaction.comments },
    });

    // Cambiar, NO son inputs
    if (transaction.auditDate || transaction.bankingTransactionType) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "transactionType",
          value: transaction.bankingTransactionType,
        },
      });

      if (transaction.bankingTransactionType === "PROVIDER_INVOICE") {
        setDatesForProviderInvoices();
      }

      const transactionItems = [
        { name: "invoices", value: transaction.Invoices },
        { name: "providerInvoices", value: transaction.ProviderInvoices },
        { name: "remittances", value: transaction.Remittances },
        {
          name: "transferPaymentGroups",
          value: transaction.TransferPaymentGroups,
        },
        {
          name: "receiptReturnedGroups",
          value: transaction.ReceiptReturnedGroups,
        },
      ];
      transactionItems.forEach((item) => {
        if (item.value?.length !== 0) {
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: item.name,
              value: item.value,
            },
          });
        }
      });
    }
  };

  const loadDefaultFilters = () => {
    let fromDate = new Date(transaction.transactionDate);
    fromDate.setDate(fromDate.getDate() - 3);
    fromDate = formatDate(fromDate);

    let untilDate = new Date(transaction.transactionDate);
    untilDate.setMonth(untilDate.getMonth() + 1);
    untilDate = formatDate(untilDate);

    dispatch({
      type: "SET_DEFAULT_FILTERS",
      payload: {
        fromDate,
        untilDate,
        import: transaction.import,
      },
    });
  };

  const getProviderInvoices = () => {
    dispatch({ type: "SET_TABLE_LOADING_TRUE" });

    const { fromDate, untilDate, amountMin, amountMax, title, providerId } =
      state.filters.providerInvoices;

    let params = {
      include: ["Provider", "BankingTransaction", "TransferPayment"],
    };

    fromDate !== "" && (params.dateFrom = fromDate);
    untilDate !== "" && (params.dateUntil = untilDate);
    amountMin !== "" && (params.importMin = amountMin);
    amountMax !== "" && (params.importMax = amountMax);
    title !== "" && (params.text = title);
    providerId !== "" && (params.provider = providerId);

    api
      .get("/provider-invoices", { params })
      .then((response) => {
        if (response.data.error) {
          dispatch({ type: "SET_TABLE_LOADING_FALSE" });
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_PROVIDER_INVOICES", payload: response.data });

          //TODO no se necesita, definir en tabla
          const nonSelectedProviderInvoices = response.data.filter(
            (providerInvoice) =>
              !state.form.providerInvoices.find(
                (selectedProviderInvoice) =>
                  selectedProviderInvoice.id === providerInvoice.id
              )
          );
          //TODO eliminar función
          loadTable(
            "PROVIDER_INVOICE",
            nonSelectedProviderInvoices,
            PROVIDER_INVOICE_COLUMNS
          );
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getRemittances = () => {
    dispatch({ type: "SET_TABLE_LOADING_TRUE" });
    const { fromDate, untilDate, amountMin, amountMax } =
      state.filters.remittances;

    //TODO: Cambiar en el backend para que no sea un array
    let params = {
      include: ["Receipt"],
    };

    amountMin !== "" && (params.amountMin = amountMin);
    amountMax !== "" && (params.amountMax = amountMax);
    fromDate !== "" && (params.dateFrom = fromDate);
    untilDate !== "" && (params.dateUntil = untilDate);

    api
      .get("/remittances", { params })
      .then((response) => {
        if (response.data.error) {
          dispatch({ type: "SET_TABLE_LOADING_FALSE" });
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_REMITTANCES", payload: response.data });
          const nonSelectedRemittances = response.data.filter(
            (remittance) =>
              !state.form.remittances.find(
                (selectedRemittance) => selectedRemittance.id === remittance.id
              )
          );
          loadTable("REMITTANCE", nonSelectedRemittances, REMITTANCE_COLUMNS);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getTransferPaymentGroups = () => {
    dispatch({ type: "SET_TABLE_LOADING_TRUE" });

    const { fromDate, untilDate, amountMin, amountMax } =
      state.filters.transferPaymentGroups;

    let params = {
      include: ["TransferPayment"],
    };

    fromDate !== "" && (params.dateFrom = fromDate);
    untilDate !== "" && (params.dateUntil = untilDate);
    amountMin !== "" && (params.importMin = amountMin);
    amountMax !== "" && (params.importMax = amountMax);

    api
      .get("/transfer-payment-groups", { params })
      .then((response) => {
        if (response.data.error) {
          dispatch({ type: "SET_TABLE_LOADING_FALSE" });
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const transferPaymentGroups = response.data.map((group) => {
            let total = 0;
            group.TransferPayments.forEach(
              (transfer) => (total += transfer.amount)
            );
            return { ...group, total };
          });
          dispatch({
            type: "SET_TRANSFER_PAYMENT_GROUPS",
            payload: transferPaymentGroups,
          });
          const nonSelectedTransferPaymentGroups = transferPaymentGroups.filter(
            (transferPaymentGroup) =>
              !state.form.transferPaymentGroups.find(
                (selectedTransferPaymentGroup) =>
                  selectedTransferPaymentGroup.id === transferPaymentGroup.id
              )
          );
          loadTable(
            "TRANSFER_PAYMENT_GROUP",
            nonSelectedTransferPaymentGroups,
            TRANSFER_PAYMENT_GROUP_COLUMNS
          );
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoices = () => {
    const invoiceFilters = state.filters.invoices;

    const params = {
      include: ["Customer", "PaymentMethod"],
      dateFrom: invoiceFilters.fromDate,
      dateUntil: invoiceFilters.untilDate,
      min: invoiceFilters.amountMin,
      max: invoiceFilters.amountMax,
      number: invoiceFilters.name,
      paymentMethodId: invoiceFilters.paymentMethodId,
      state: invoiceFilters.state,
      attributes: [
        {
          model: "Customer",
          attributes: ["name", "address", "fullName"],
        },
        {
          model: "PaymentMethod",
          attributes: ["id", "name"],
        },
        {
          model: "Invoice",
          attributes: [
            "accountedAt",
            "accountedBy",
            "billingError",
            "customerName",
            "customerNif",
            "dueDate",
            "id",
            "issueDate",
            "issuerId",
            "issuerName",
            "issuerNif",
            "items",
            "number",
            "paidAt",
            "paymentMethodId",
            "serieId",
            "state",
            "baseAmount",
            "totalAmount",
          ],
        },
      ],
    };

    dispatch({ type: "SET_TABLE_LOADING_TRUE" });

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          dispatch({ type: "SET_TABLE_LOADING_FALSE" });
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          //TODO no se necesita aquí
          const nonSelectedInvoices = response.data.filter(
            (invoice) =>
              !state.form.invoices.find(
                (selectedInvoice) => selectedInvoice.id === invoice.id
              )
          );
          dispatch({ type: "SET_INVOICES", payload: response.data });

          //TODO eliminar función
          loadTable("INVOICE", nonSelectedInvoices, INVOICE_COLUMNS);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getReceiptReturnedGroups = () => {
    dispatch({ type: "SET_TABLE_LOADING_TRUE" });

    const { fromDate, untilDate, amountMin, amountMax, numberReceipts } =
      state.filters.receiptReturnedGroups;

    let params = {
      include: ["Receipt", "BankingTransaction"],
    };

    fromDate !== "" && (params.dateFrom = fromDate);
    untilDate !== "" && (params.dateUntil = untilDate);

    //Set params by transaction type
    if (state.form.transactionType === "RECEIPT_RETURNED_GROUP") {
      amountMin !== "" && (params.amountMin = amountMin);
      amountMax !== "" && (params.amountMax = amountMax);
    } else if (state.form.transactionType === "RETURN_RECEIPT_FEES")
      numberReceipts !== "" && (params.numReceipts = numberReceipts);

    api
      .get("/receipts/receipt-returned-groups", { params })
      .then((response) => {
        if (response.data.error) {
          dispatch({ type: "SET_TABLE_LOADING_FALSE" });
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_RECEIPT_RETURNED_GROUPS",
            payload: response.data,
          });
          const nonSelectedReceiptReturnedGroups = response.data.filter(
            (receiptReturnedGroup) =>
              !state.form.receiptReturnedGroups.find(
                (selectedReceiptReturnedGroup) =>
                  selectedReceiptReturnedGroup.id === receiptReturnedGroup.id
              )
          );

          //Load table by transaction type
          if (state.form.transactionType === "RECEIPT_RETURNED_GROUP")
            loadTable(
              "RECEIPT_RETURNED_GROUP",
              nonSelectedReceiptReturnedGroups,
              RECEIPT_RETURNED_GROUP_COLUMNS
            );
          else if (state.form.transactionType === "RETURN_RECEIPT_FEES")
            loadTable(
              "RETURN_RECEIPT_FEES",
              nonSelectedReceiptReturnedGroups,
              RECEIPT_RETURNED_GROUP_COLUMNS
            );
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_INPUT",
            payload: { inputname: "invoiceSeries", value: response.data },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const revertAudit = () => {
    let form = {
      revertAudit: true,
    };
    api
      .post("/banking-transactions/audit/" + transaction.id, form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("auditRevertedSuccessfully"), {
            variant: "success",
          });
          transaction.auditDate = null;
          transaction.auditUserId = null;
          setForm();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editAudit = () => {
    transaction.auditDate = null;
    transaction.auditUserId = null;
    transaction.editing = true;
    setForm();
  };

  //TODO eliminar esta función definirlo en el JSX??
  const loadTable = (transactionType, tableRows, tableColumns) => {
    if (
      !transaction.auditDate &&
      state.form.transactionType === transactionType
    ) {
      dispatch({ type: "SET_TABLE", payload: tableRows });
      dispatch({
        type: "SET_TABLE_COLUMNS",
        payload: tableColumns,
      });
      dispatch({ type: "SET_TABLE_LOADING_FALSE" });
    }
  };

  //Search depending on the transaction type
  const search = () =>
    ({
      INVOICE: getInvoices,
      PROVIDER_INVOICE: getProviderInvoices,
      REMITTANCE: getRemittances,
      TRANSFER_PAYMENT_GROUP: getTransferPaymentGroups,
      RECEIPT_RETURNED_GROUP: getReceiptReturnedGroups,
      RETURN_RECEIPT_FEES: getReceiptReturnedGroups,
    }[state.form.transactionType]?.());

  const getProviders = () => {
    api
      .get("/providers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PROVIDERS",
            payload: response.data.sort((a, b) =>
              a.brand.localeCompare(b.brand)
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  //TODO eliminar???
  const setTable = (transactionType) => {
    const tableMapping = {
      INVOICE: { table: state.invoices, columns: INVOICE_COLUMNS },
      PROVIDER_INVOICE: {
        table: state.providerInvoices,
        columns: PROVIDER_INVOICE_COLUMNS,
      },
      REMITTANCE: { table: state.remittances, columns: REMITTANCE_COLUMNS },
      TRANSFER_PAYMENT_GROUP: {
        table: state.transferPaymentGroups,
        columns: TRANSFER_PAYMENT_GROUP_COLUMNS,
      },
      RECEIPT_RETURNED_GROUP: {
        table: state.receiptReturnedGroups,
        columns: RECEIPT_RETURNED_GROUP_COLUMNS,
      },
      RETURN_RECEIPT_FEES: {
        table: state.receiptReturnedGroups,
        columns: RECEIPT_RETURNED_GROUP_COLUMNS,
      },
    };

    const { table, columns } = tableMapping[transactionType];

    dispatch({ type: "SET_TABLE", payload: table });
    dispatch({ type: "SET_TABLE_COLUMNS", payload: columns });
  };

  //TODO Ver para qué es, types object?
  const getTransactionTypeArrayName = (transactionType) => {
    const mapping = {
      INVOICE: "invoices",
      PROVIDER_INVOICE: "providerInvoices",
      REMITTANCE: "remittances",
      TRANSFER_PAYMENT_GROUP: "transferPaymentGroups",
      RECEIPT_RETURNED_GROUP: "receiptReturnedGroups",
      RETURN_RECEIPT_FEES: "receiptReturnedGroups",
      RECEIPT_SENDING_FEES: "receiptSendingFees",
    };
    return mapping[transactionType];
  };

  const handleChangeType = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });

    if (e.target.value === "PROVIDER_INVOICE") {
      setDatesForProviderInvoices();
    }
    const transactionType = e.target.value;
    setTable(transactionType);

    dispatch({ type: "RESET_SELECTED_ITEMS" });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const addRow = (row) => {
    const transactionType = getTransactionTypeArrayName(
      state.form.transactionType
    );
    const nonSelected = state[transactionType].filter(
      (transaction) =>
        !state.form[transactionType].find(
          (selected) => selected.id === transaction.id
        )
    );
    dispatch({
      type: "SET_TABLE",
      payload: nonSelected.filter((item) => item.id !== row.id),
    });
    if (
      state.form[transactionType].filter((item) => item.id === row.id)
        .length === 0
    ) {
      dispatch({
        type: "ADD_ITEM",
        payload: { array: transactionType, value: row },
      });
      formatAutoComment(transactionType, [...state.form[transactionType], row]);
    }
  };

  const deleteRow = (row) => {
    const transactionType = getTransactionTypeArrayName(
      state.form.transactionType
    );

    dispatch({
      type: "DELETE_ITEM",
      payload: { array: transactionType, id: row.id },
    });

    const selectedItems = state.form[transactionType].filter(
      (item) => item.id !== row.id
    );

    dispatch({
      type: "SET_TABLE",
      payload: state[transactionType].filter(
        (item) => !selectedItems.some((selected) => selected.id === item.id)
      ),
    });

    formatAutoComment(
      transactionType,
      state.form[transactionType].filter((item) => item.id !== row.id)
    );
  };

  const formatAutoComment = (transactionType, selectedItems) => {
    const { invoiceSeries } = state.form;
    let comment = "";
    switch (transactionType) {
      case "invoices":
        comment = "Invoices:\n";
        selectedItems.forEach((item) => {
          const { customerName, serieId, number } = item;
          const { name } = invoiceSeries.find(
            (invoiceSerie) => invoiceSerie.id === serieId
          );
          comment += `\t- ${customerName} (${name}${number})\n`;
        });
        break;
      case "providerInvoices":
        comment = "Provider invoices:\n";
        selectedItems.forEach((item) => {
          const { title, invoiceNumber } = item;
          comment += `\t- ${title} (${invoiceNumber})\n`;
        });
        break;
      case "remittances":
        comment = "Remittances:\n";
        selectedItems.forEach((item) => {
          const { name } = item;
          comment += `\t- ${name}\n`;
        });
        break;
      case "transferPaymentGroups":
        comment = "Transfer payment groups:\n";
        selectedItems.forEach((item) => {
          const { date, TransferPayments } = item;
          const amount = getTransferPaymentGroupAmount(item);
          const numPayments = TransferPayments?.length ?? 0;
          comment += `\t- ${date} | ${amount}€ (${numPayments})\n`;
        });
        break;
      case "receiptReturnedGroups":
        comment = "Returned receipt groups:\n";
        selectedItems.forEach((item) => {
          const { date, Receipts } = item;
          const amount = getReceiptReturnedGroupAmount(item);
          const numReceipts = Receipts?.length ?? 0;
          comment += `\t- ${date} | ${amount}€ (${numReceipts})\n`;
        });
      case "receiptReturnedFees":
        comment = "Receipt returned fees:\n";
        selectedItems.forEach((item) => {
          const { date, Receipts } = item;
          const amount = getReceiptReturnedGroupAmount(item);
          const numReceipts = Receipts?.length ?? 0;
          comment += `\t- ${date} | ${amount}€ (${numReceipts})\n`;
        });
      case "receiptSendingFees":
        comment = "Receipt sending fees:\n";
        selectedItems.forEach((item) => {
          const { date, Receipts } = item;
          const amount = getReceiptReturnedGroupAmount(item);
          const numReceipts = Receipts?.length ?? 0;
          comment += `\t- ${date} | ${amount}€ (${numReceipts})\n`;
        });
      default:
        break;
    }
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "comments",
        value: comment,
      },
    });
  };

  const setFile = (e) => {
    dispatch({ type: "SET_FILE", payload: e.target.files[0] });
  };

  const seeFile = () => {
    api
      .get("/banking-transactions/" + transaction.id + "/upload-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleFilterChange = (e, type) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
        type,
      },
    });
  };

  const isIncoherentFilter = (filter, type) => {
    if (filter === "date") {
      const { fromDate, untilDate } = state.filters[type];
      if (fromDate && untilDate) {
        return fromDate > untilDate;
      }
    }
    if (filter === "amount") {
      const { amountMin, amountMax } = state.filters[type];
      if (amountMin && amountMax) {
        return amountMin > amountMax;
      }
    }
    return false;
  };

  //TODO no es necesario, que se encargue el componente
  const selectedItemsNotEmpty = () => {
    if (
      state.form.invoices?.length === 0 &&
      state.form.providerInvoices?.length === 0 &&
      state.form.remittances?.length === 0 &&
      state.form.transferPaymentGroups?.length === 0 &&
      state.form.receiptReturnedGroups?.length === 0 &&
      state.form.receiptSendingFees?.length === 0
    )
      return false;
    return true;
  };

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });

    return isFound;
  };

  const getTransferPaymentGroupAmount = (transferPaymentGroup) => {
    if (transferPaymentGroup.amount)
      return localeFormat(transferPaymentGroup.amount);
    let amount = 0;
    transferPaymentGroup.TransferPayments?.forEach(
      (transferPayment) => (amount += transferPayment.amount)
    );
    return localeFormat(amount);
  };

  const getReceiptReturnedGroupAmount = (receiptReturnedGroup) => {
    if (receiptReturnedGroup.amount)
      return localeFormat(receiptReturnedGroup.amount);
    let amount = 0;
    receiptReturnedGroup.Receipts?.forEach(
      (receipt) => (amount += receipt.amount)
    );
    return localeFormat(amount);
  };

  const smartMatch = () => {
    api
      .get("/banking-transactions/" + transaction.id + "/smart-match")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          //Add transactionType
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "transactionType",
              value: response.data.transactionType,
            },
          });

          //Select items & comments
          const transactionType = getTransactionTypeArrayName(
            response.data.transactionType
          );
          if (response.data.items)
            for (const item of response.data.items) {
              dispatch({
                type: "ADD_ITEM",
                payload: { array: transactionType, value: item },
              });
              formatAutoComment(transactionType, [
                ...state.form[transactionType],
                item,
              ]);
            }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setDatesForProviderInvoices = () => {
    const transactionDate = new Date(transaction.transactionDate);
    let startDate = new Date(transactionDate);
    let endDate = new Date(transactionDate);
    startDate.setDate(startDate.getDate() - 21);
    endDate.setDate(endDate.getDate() + 7);
    handleFilterChange(
      { target: { name: "fromDate", value: formatDate(startDate) } },
      "providerInvoices"
    );
    handleFilterChange(
      { target: { name: "untilDate", value: formatDate(endDate) } },
      "providerInvoices"
    );
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <Typography variant="h6">{transaction.concept}</Typography>
            </Grid>
            <Grid item>
              {!transaction.editing ? (
                <Chip
                  label={
                    transaction.auditDate
                      ? [
                          t("auditedBy"),
                          transaction.User?.name,
                          t("at"),
                          transaction.auditDate,
                        ].join(" ")
                      : t("toBeAudited")
                  }
                  color={transaction.auditDate ? "success" : "warning"}
                />
              ) : (
                <Chip
                  label={t("editingAuditedTransaction")}
                  color={"success"}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              {t("transactionDate")}: {transaction.transactionDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              {t("valueDate")}: {transaction.valueDate}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              {t("import")}: {localeFormat(transaction.import) + "€"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              {t("ref1")}: {transaction.ref1}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              {t("ref2")}: {transaction.ref2}
            </Typography>
          </Grid>

          {transaction.auditDate && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">
                {t("auditDate")}: {transaction.auditDate}
              </Typography>
            </Grid>
          )}

          {transaction.User && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">
                {t("auditUser")}: {transaction.User.name}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="body1">
              {t("comments")}: {transaction.comments}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              {t("bankAccount")}: {transaction.BankAccount.name} (
              {
                /* Last 4 digits */ transaction.BankAccount.IBAN.number.substring(
                  transaction.BankAccount.IBAN.number.length - 4,
                  transaction.BankAccount.IBAN.number.length
                )
              }
              )
            </Typography>
          </Grid>

          {transaction.bankingTransactionType && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">
                {t("bankingTransactionType")}:{" "}
                {transaction.bankingTransactionType}
              </Typography>
            </Grid>
          )}
          {!transaction.auditDate && (
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Tooltip title={t("smartMatch")} placement="top">
                <IconButton
                  color="primary"
                  onClick={smartMatch}
                  //disabled={state.iconsLoadingState.emailPdf}
                >
                  <AssistantIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* TRANSACTION TYPE OPTIONS */}
        {!transaction.auditDate && (
          <Grid
            item
            container
            xs={12}
            md={4}
            lg={3}
            justifyContent="flex-start"
          >
            <Grid item>
              <Typography variant="h6">
                {t("bankingTransactionType")}
              </Typography>
              <RadioGroup
                onChange={handleChangeType}
                value={state.form.transactionType}
                name="transactionType"
              >
                {[
                  { value: "INVOICE", label: t("invoice") },
                  { value: "PROVIDER_INVOICE", label: t("providerInvoice") },
                  { value: "REMITTANCE", label: t("remittance") },
                  {
                    value: "TRANSFER_PAYMENT_GROUP",
                    label: t("transferPaymentGroup"),
                  },
                  {
                    value: "RECEIPT_RETURNED_GROUP",
                    label: t("receiptReturnedGroup"),
                  },
                  {
                    value: "RETURN_RECEIPT_FEES",
                    label: t("receiptReturnedFees"),
                  },
                  {
                    value: "RECEIPT_SENDING_FEES",
                    label: t("receiptSendingFees"),
                  },
                ].map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio disabled={transaction.auditDate} />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        )}

        {/* FILTERS */}
        <Grid item container xs={12} md={8} lg={9}>
          {/* Invoice */}
          {!transaction.auditDate &&
            state.form.transactionType === "INVOICE" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("invoices")}</Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={3} xl={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.invoices.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                    error={isIncoherentFilter("date", "invoices")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={3} xl={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.invoices.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                    error={isIncoherentFilter("date", "invoices")}
                  />
                </Grid>

                <Grid item xs={6} md={2} lg={3} xl={2}>
                  <TextInput
                    type="number"
                    label={t("amountMin")}
                    name="amountMin"
                    value={state.filters.invoices.amountMin}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                    error={isIncoherentFilter("amount", "invoices")}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={3} xl={2}>
                  <TextInput
                    type="number"
                    label={t("amountMax")}
                    name="amountMax"
                    value={state.filters.invoices.amountMax}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                    error={isIncoherentFilter("amount", "invoices")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextInput
                    label={t("customerName/invoiceNumber")}
                    name="name"
                    value={state.filters.invoices.name}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <CustomSelect
                    label={t("paymentMethod")}
                    name="paymentMethodId"
                    value={state.filters.invoices.paymentMethodId}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                    options={[
                      { value: "", label: t("all") },
                      ...state.paymentMethods.map((method) => ({
                        value: method.id,
                        label: method.name,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <CustomSelect
                    label={t("state")}
                    value={state.filters.invoices.state}
                    onChange={(e) => {
                      handleFilterChange(e, "invoices");
                    }}
                    options={[
                      { value: "", label: t("all") },
                      { value: 0, label: t("issued") },
                      { value: 1, label: t("paid") },
                      { value: 2, label: t("expired") },
                      { value: 3, label: t("unpaid") },
                    ]}
                    name="state"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.invoices,
                            type: "invoices",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {/* Provider invoice */}
          {!transaction.auditDate &&
            state.form.transactionType === "PROVIDER_INVOICE" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("providerInvoices")}</Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={3} xl={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.providerInvoices.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "providerInvoices");
                    }}
                    error={isIncoherentFilter("date", "providerInvoices")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={3} xl={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.providerInvoices.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "providerInvoices");
                    }}
                    error={isIncoherentFilter("date", "providerInvoices")}
                  />
                </Grid>

                <Grid item xs={6} md={2} lg={3} xl={2}>
                  <TextInput
                    type="number"
                    label={t("amountMin")}
                    name="amountMin"
                    value={state.filters.providerInvoices.amountMin}
                    onChange={(e) => {
                      handleFilterChange(e, "providerInvoices");
                    }}
                    error={isIncoherentFilter("amount", "providerInvoices")}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={3} xl={2}>
                  <TextInput
                    type="number"
                    label={t("amountMax")}
                    name="amountMax"
                    value={state.filters.providerInvoices.amountMax}
                    onChange={(e) => {
                      handleFilterChange(e, "providerInvoices");
                    }}
                    error={isIncoherentFilter("amount", "providerInvoices")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextInput
                    label={t("title")}
                    name="title"
                    value={state.filters.providerInvoices.title}
                    onChange={(e) => {
                      handleFilterChange(e, "providerInvoices");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <Select
                    label={t("provider")}
                    name="providerId"
                    value={state.filters.providerInvoices.providerId}
                    onChange={(e) => {
                      handleFilterChange(e, "providerInvoices");
                    }}
                    options={state.providers.map((provider) => ({
                      value: provider.id,
                      label: provider.brand,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.providerInvoices,
                            type: "providerInvoices",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {/* Remittance */}
          {!transaction.auditDate &&
            state.form.transactionType === "REMITTANCE" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("remittances")}</Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.remittances.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "remittances");
                    }}
                    error={isIncoherentFilter("date", "remittances")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.remittances.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "remittances");
                    }}
                    error={isIncoherentFilter("date", "remittances")}
                  />
                </Grid>

                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMin")}
                    name="amountMin"
                    value={state.filters.remittances.amountMin}
                    onChange={(e) => {
                      handleFilterChange(e, "remittances");
                    }}
                    error={isIncoherentFilter("amount", "remittances")}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMax")}
                    name="amountMax"
                    value={state.filters.remittances.amountMax}
                    onChange={(e) => {
                      handleFilterChange(e, "remittances");
                    }}
                    error={isIncoherentFilter("amount", "remittances")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.remittances,
                            type: "remittances",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {/* Transfer payment group */}
          {!transaction.auditDate &&
            state.form.transactionType === "TRANSFER_PAYMENT_GROUP" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("transferPaymentGroups")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.transferPaymentGroups.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "transferPaymentGroups");
                    }}
                    error={isIncoherentFilter("date", "transferPaymentGroups")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.transferPaymentGroups.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "transferPaymentGroups");
                    }}
                    error={isIncoherentFilter("date", "transferPaymentGroups")}
                  />
                </Grid>

                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMin")}
                    name="amountMin"
                    value={state.filters.transferPaymentGroups.amountMin}
                    onChange={(e) => {
                      handleFilterChange(e, "transferPaymentGroups");
                    }}
                    error={isIncoherentFilter(
                      "amount",
                      "transferPaymentGroups"
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMax")}
                    name="amountMax"
                    value={state.filters.transferPaymentGroups.amountMax}
                    onChange={(e) => {
                      handleFilterChange(e, "transferPaymentGroups");
                    }}
                    error={isIncoherentFilter(
                      "amount",
                      "transferPaymentGroups"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.transferPaymentGroups,
                            type: "transferPaymentGroups",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {/* Returned receipts */}
          {!transaction.auditDate &&
            state.form.transactionType === "RECEIPT_RETURNED_GROUP" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("receiptReturnedGroups")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.receiptReturnedGroups.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                    error={isIncoherentFilter("date", "receiptReturnedGroups")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.receiptReturnedGroups.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                    error={isIncoherentFilter("date", "receiptReturnedGroups")}
                  />
                </Grid>

                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMin")}
                    name="amountMin"
                    value={state.filters.receiptReturnedGroups.amountMin}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                    error={isIncoherentFilter(
                      "amount",
                      "receiptReturnedGroups"
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMax")}
                    name="amountMax"
                    value={state.filters.receiptReturnedGroups.amountMax}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                    error={isIncoherentFilter(
                      "amount",
                      "receiptReturnedGroups"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.receiptReturnedGroups,
                            type: "receiptReturnedGroups",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {/* Receipt fees*/}
          {!transaction.auditDate &&
            state.form.transactionType === "RETURN_RECEIPT_FEES" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("receiptReturnedGroups")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.receiptReturnedGroups.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                    error={isIncoherentFilter("date", "receiptReturnedGroups")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.receiptReturnedGroups.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                    error={isIncoherentFilter("date", "receiptReturnedGroups")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <TextInput
                    type="number"
                    label={t("numberOfReceipts")}
                    name="numberReceipts"
                    value={state.filters.receiptReturnedGroups.numberReceipts}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptReturnedGroups");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.receiptReturnedGroups,
                            type: "receiptReturnedGroups",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {/* Receipt sending fees*/}
          {!transaction.auditDate &&
            state.form.transactionType === "RECEIPT_SENDING_FEES" && (
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("receiptSendingFees")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("fromDate")}
                    name="fromDate"
                    value={state.filters.receiptSendingFees.fromDate}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptSendingFees");
                    }}
                    error={isIncoherentFilter("date", "receiptSendingFees")}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2.5}>
                  <CustomDate
                    label={t("untilDate")}
                    name="untilDate"
                    value={state.filters.receiptSendingFees.untilDate}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptSendingFees");
                    }}
                    error={isIncoherentFilter("date", "receiptSendingFees")}
                  />
                </Grid>

                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMin")}
                    name="amountMin"
                    value={state.filters.receiptSendingFees.amountMin}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptSendingFees");
                    }}
                    error={isIncoherentFilter("amount", "receiptSendingFees")}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <TextInput
                    type="number"
                    label={t("amountMax")}
                    name="amountMax"
                    value={state.filters.receiptSendingFees.amountMax}
                    onChange={(e) => {
                      handleFilterChange(e, "receiptSendingFees");
                    }}
                    error={isIncoherentFilter("amount", "receiptSendingFees")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ButtonGroup variant="contained">
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch({
                          type: "RESET_FILTER",
                          payload: {
                            value: initialState.filters.receiptSendingFees,
                            type: "receiptSendingFees",
                          },
                        });
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <SearchButton
                      onClick={search}
                      loading={state.tableLoading}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

          {!transaction.auditDate && state.form.transactionType !== "" && (
            <Grid item xs={12}>
              <CustomTable
                columns={state.columns}
                data={state.tableData.map((item) => {
                  item.addRow = (
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addRow(item);
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  );
                  return item;
                })}
                options={{ loaded: !state.tableLoading }}
              />
            </Grid>
          )}

          {selectedItemsNotEmpty() && (
            <Grid item>
              <Typography variant="h6">{t("selectedItems")}</Typography>
              <List>
                {state.form.transactionType === "INVOICE" &&
                  state.form.invoices?.map((invoice) => (
                    <ListItem key={invoice.id}>
                      {hasAction("VIEW_INVOICES") ? (
                        <ButtonLink
                          to={"/app/invoice/" + invoice.id}
                          size="small"
                          sx={{ padding: 0 }}
                        >
                          {invoice.customerName +
                            " (" +
                            state.form.invoiceSeries?.find(
                              (invoiceSerie) =>
                                invoiceSerie.id === invoice.serieId
                            )?.name +
                            invoice.number +
                            ")"}
                        </ButtonLink>
                      ) : (
                        invoice.customerName +
                        " (" +
                        state.form.invoiceSeries?.find(
                          (invoiceSerie) => (invoiceSerie.id = invoice.serieId)
                        )?.name +
                        invoice.number +
                        ")"
                      )}
                      {!transaction.auditDate && (
                        <IconButton onClick={() => deleteRow(invoice)}>
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                {state.form.transactionType === "PROVIDER_INVOICE" &&
                  state.form.providerInvoices?.map((providerInvoice) => (
                    <ListItem key={providerInvoice.id}>
                      {hasAction("VIEW_PROVIDER_INVOICES") ? (
                        <ButtonLink
                          to={"/app/provider-invoice/" + providerInvoice.id}
                          size="small"
                          sx={{ padding: 0 }}
                        >
                          {`${providerInvoice.title} (${providerInvoice.invoiceNumber})`}
                        </ButtonLink>
                      ) : (
                        `${providerInvoice.title} (${providerInvoice.invoiceNumber})`
                      )}
                      {!transaction.auditDate && (
                        <IconButton onClick={() => deleteRow(providerInvoice)}>
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                {state.form.transactionType === "REMITTANCE" &&
                  state.form.remittances?.map((remittance) => (
                    <ListItem key={remittance.id}>
                      {hasAction("VIEW_REMITTANCES") ? (
                        <ButtonLink
                          to={"/app/remittance/" + remittance.id}
                          size="small"
                          sx={{ padding: 0 }}
                        >
                          {remittance.name}
                        </ButtonLink>
                      ) : (
                        remittance.name
                      )}
                      {!transaction.auditDate && (
                        <IconButton onClick={() => deleteRow(remittance)}>
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                {state.form.transactionType === "TRANSFER_PAYMENT_GROUP" &&
                  state.form.transferPaymentGroups?.map(
                    (transferPaymentGroup) => (
                      <ListItem key={transferPaymentGroup.id}>
                        {hasAction("VIEW_TRANSFER_PAYMENT_GROUPS") ? (
                          <ButtonLink
                            to={
                              "/app/transfer-payment-group/" +
                              transferPaymentGroup.id
                            }
                            size="small"
                            sx={{ padding: 0 }}
                          >
                            {`${
                              transferPaymentGroup.date
                            } | ${getTransferPaymentGroupAmount(
                              transferPaymentGroup
                            )}€ (${
                              transferPaymentGroup.TransferPayments?.length
                            })`}
                          </ButtonLink>
                        ) : (
                          `${
                            transferPaymentGroup.date
                          } | ${getTransferPaymentGroupAmount(
                            transferPaymentGroup
                          )}€ (${
                            transferPaymentGroup.TransferPayments?.length
                          })`
                        )}
                        {!transaction.auditDate && (
                          <IconButton
                            onClick={() => deleteRow(transferPaymentGroup)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        )}
                      </ListItem>
                    )
                  )}
                {(state.form.transactionType === "RECEIPT_RETURNED_GROUP" ||
                  state.form.transactionType === "RETURN_RECEIPT_FEES") &&
                  state.form.receiptReturnedGroups?.map(
                    (receiptReturnedGroup) => (
                      <ListItem key={receiptReturnedGroup.id}>
                        {hasAction("VIEW_RECEIPTS") ? (
                          <ButtonLink
                            to={`/app/returned-receipts/?tab=1&autoSearch=true&groupId=${receiptReturnedGroup.id}`}
                            size="small"
                            sx={{ padding: 0 }}
                          >
                            {`${
                              receiptReturnedGroup.date
                            } | ${getReceiptReturnedGroupAmount(
                              receiptReturnedGroup
                            )}€ (${receiptReturnedGroup.Receipts?.length})`}
                          </ButtonLink>
                        ) : (
                          `${
                            receiptReturnedGroup.date
                          } | ${getReceiptReturnedGroupAmount(
                            receiptReturnedGroup
                          )}€ (${receiptReturnedGroup.Receipts?.length})`
                        )}
                        {!transaction.auditDate && (
                          <IconButton
                            onClick={() => deleteRow(receiptReturnedGroup)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        )}
                      </ListItem>
                    )
                  )}
              </List>
            </Grid>
          )}
        </Grid>

        <Grid item container xs={12} spacing={2} justifyContent="flex-end">
          {!transaction.auditDate && (
            <Grid item xs={12}>
              <TextInput
                multiline
                rows={4}
                label={t("comments")}
                name="comments"
                value={state.form.comments}
                onChange={handleInputChange}
                disabled={transaction.auditDate}
              />
            </Grid>
          )}

          {!transaction.auditDate && !transaction.editing && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.form.isAudited}
                    onChange={(e) => {
                      handleInputChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                    name="isAudited"
                    disabled={transaction.auditDate}
                  />
                }
                label={t("audited")}
              />
            </Grid>
          )}

          {transaction.hasFile && (
            <Grid item>
              <Button onClick={seeFile} variant="contained">
                {t("seeFile")}
              </Button>
            </Grid>
          )}

          {!transaction.auditDate && (
            <Grid item>
              <Grid item container direction="column" justifyContent="center">
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<PublishIcon />}
                  disabled={transaction.auditDate}
                >
                  {t("selectFile")}
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={setFile}
                  />
                </Button>
                <Typography
                  variant="caption"
                  style={{ maxWidth: 200 }}
                  noWrap
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {state.file !== "" && t("file") + ": " + state.file.name}
                </Typography>
              </Grid>
            </Grid>
          )}

          {transaction.auditDate &&
            hasAction("EDIT_AUDITED_BANKING_TRANSACTION") && (
              <Grid item>
                <CustomButton color="warning" onClick={editAudit}>
                  {t("editAudit")}
                </CustomButton>
              </Grid>
            )}
          {transaction.auditDate &&
            hasAction("EDIT_AUDITED_BANKING_TRANSACTION") && (
              <Grid item>
                <CustomButton color="error" onClick={revertAudit}>
                  {t("revertAudit")}
                </CustomButton>
              </Grid>
            )}

          <Grid item>
            <CustomButton
              color="primary"
              onClick={() => {
                dispatch({ type: "SET_LOADING_TRUE" });
                onSubmit(state.form, transaction.id, state.file);
              }}
              loading={state.loading}
              disabled={transaction.auditDate}
            >
              {t("save")}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
