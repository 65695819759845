import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TextInput from "../../../../../Inputs/TextInput";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

const PositionCollapsible = (props) => {
  const { x, setX, y, setY } = props;
  const [t] = useTranslation("floorPlans");

  return (
    <ItemCollapsibleTemplate title={t("position")}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <TextInput
            label={t("x")}
            value={x}
            type="number"
            onChange={(e) => setX && setX(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={t("y")}
            value={y}
            type="number"
            onChange={(e) => setY && setY(e.target.value)}
          />
        </Grid>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

PositionCollapsible.propTypes = {
  x: PropTypes.string,
  setX: PropTypes.func,
  y: PropTypes.string,
  setY: PropTypes.func,
};

export default PositionCollapsible;
