import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      flex={1}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      height="100%"
      {...other}
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DashboardTabs = ({ panels }) => {
  const DEFAULT_TAB = 0; // Center Report

  const [tabIndex, setTabIndex] = React.useState(DEFAULT_TAB);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const allowedPanels = panels.filter((panel) => panel.action);

  return (
    <Box display="flex" height="100%">
      {allowedPanels.length ? (
        <>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {allowedPanels.map((panel, index) => (
              <Tab key={index} label={panel.tabLabel} {...a11yProps(index)} />
            ))}
          </Tabs>
          <Box flex={1} overflow="auto">
            {allowedPanels.map((panel, index) => (
              <TabPanel key={index} value={tabIndex} index={index}>
                {panel.content}
              </TabPanel>
            ))}
          </Box>
        </>
      ) : (
        <Box marginTop={2} marginLeft={2}>
          <Typography variant="body1" fontWeight="bold">
            You don't have access to any dashboard panel. If you think this is a
            mistake, get in contact with the IT department.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DashboardTabs;
