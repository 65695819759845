import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TextInput from "../../../../../Inputs/TextInput";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

const SizeCollapsible = (props) => {
  const { width, setWidth, height, setHeight, radius, setRadius } = props;

  const [t] = useTranslation("floorPlans");

  return (
    <ItemCollapsibleTemplate title={t("size")}>
      <Grid container item xs={12} spacing={1}>
        {width && (
          <Grid item xs={6}>
            <TextInput
              label={t("width")}
              value={width}
              type="number"
              onChange={(e) => setWidth && setWidth(e.target.value)}
            />
          </Grid>
        )}
        {height && (
          <Grid item xs={6}>
            <TextInput
              label={t("height")}
              value={height}
              type="number"
              onChange={(e) => setHeight && setHeight(e.target.value)}
            />
          </Grid>
        )}
        {setRadius && (
          <Grid item xs={6}>
            <TextInput
              label={t("radius")}
              value={radius}
              type="number"
              onChange={(e) => setRadius && setRadius(e.target.value)}
            />
          </Grid>
        )}
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

SizeCollapsible.propTypes = {
  width: PropTypes.string,
  setWidth: PropTypes.func,
  height: PropTypes.string,
  setHeight: PropTypes.func,
  radius: PropTypes.string,
  setRadius: PropTypes.func,
};

export default SizeCollapsible;
