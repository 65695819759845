import { Grid, Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TextInput from "../../../../../Inputs/TextInput";
import ItemCollapsibleTemplate from "./ItemCollapsibleTemplate";

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const PointsCollapsible = (props) => {
  const { item, onClose, onOpen, isOpen, onInputChange } = props;
  const [t] = useTranslation("floorPlans");

  return (
    <ItemCollapsibleTemplate title={t("points")}>
      <Grid container spacing={1}>
        {item.points.map((point, index) => {
          return (
            <>
              <Grid item xs={5} key={index}>
                <TextInput
                  label={index % 2 === 0 ? t("x") : t("y")}
                  value={point || ""}
                  type="number"
                  name="points"
                  onChange={(e) =>
                    onInputChange(
                      e.target.name,
                      item.points.map((p, i) => {
                        if (i === index) return Number(e.target.value);
                        return p;
                      })
                    )
                  }
                />
              </Grid>
              {index % 2 !== 0 && (
                <Grid item xs={1}>
                  <Tooltip title={t("delete")} placement="top">
                    <IconButton
                      onClick={() => {
                        let newPoints = [...item.points];
                        newPoints.splice(index - 1, 2);
                        onInputChange("points", newPoints);
                      }}
                      color="error"
                      size="small"
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </>
          );
        })}
        <Tooltip title={t("addPoint")} sx={{ marginTop: 0.5 }}>
          <IconButton
            size="small"
            onClick={() =>
              onInputChange("points", [
                ...item.points,
                Number(100),
                Number(100),
              ])
            }
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </ItemCollapsibleTemplate>
  );
};

PointsCollapsible.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  onInputChange: PropTypes.func,
};

export default PointsCollapsible;
