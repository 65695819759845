import {
  Divider,
  IconButton,
  Tooltip,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ITEM_ICONS } from "../Utilities";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const TileListItem = (props) => {
  const { tile, onDelete, onSelect, identation = true } = props;
  const [t] = useTranslation("floorPlans");

  return (
    <ListItem
      disablePadding
      onClick={onSelect}
      secondaryAction={
        <Tooltip title={t("delete")} placement="top">
          <IconButton onClick={onDelete} color="error" size="small">
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemButton dense>
        <ListItemIcon>{ITEM_ICONS[tile.shape]}</ListItemIcon>
        <ListItemText primary={tile.name} />
      </ListItemButton>
    </ListItem>
  );
};

TileListItem.propTypes = {
  tile: PropTypes.object,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  identation: PropTypes.bool,
};

export default TileListItem;
