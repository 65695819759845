import { Image as KonvaImage } from "react-konva";
import { useImage } from "react-konva-utils";

const Image = (props) => {
  const {
    x,
    y,
    width,
    height,
    onClick,
    tileRef,
    onTransformEnd,
    onDragEnd,
    draggable,
    imageURL,
    opacity,
  } = props;

  let [image] = useImage(imageURL);

  return (
    <KonvaImage
      ref={tileRef}
      x={x}
      y={y}
      width={width}
      height={height}
      image={image}
      onClick={onClick}
      onTransformEnd={onTransformEnd}
      onDragEnd={onDragEnd}
      draggable={draggable}
      opacity={opacity}
    />
  );
};

export default Image;
