import { ButtonGroup } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import { getParams, generateURL } from "../../../utils/url";

import Page from "../../global/structure/Page";
import Tabs from "../../global/structure/Tabs";

import TransferTab from "./Tabs/TransferTab";
import TransferGroupTab from "./Tabs/TransferGroupTab";

import { initialFilters as initialFiltersTransfer } from "./Tabs/TransferTab";
import { initialFilters as initialFiltersTransferGroup } from "./Tabs/TransferGroupTab";

export const ActionsCell = ({ item }) => {
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");

  const CSV_HEADERS = [
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    { label: t("signatureDate"), key: "signatureDate" },
    { label: t("creditorIBAN"), key: "creditorIBAN" },
    { label: t("creditorName"), key: "creditorName" },
    { label: t("providerInvoice"), key: "providerInvoice" },
    { label: t("invoiceNumber"), key: "invoiceNumber" },
    { label: t("accountingAccount"), key: "accountingAccount" },
  ];

  const downloadXml = () => {
    api
      .get(`/transfer-payment-groups/${item.id}/xml`)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/xml",
          });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = fileURL;
          a.download = fileURL.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  item.TransferPayments.forEach((transfer) => {
    transfer.providerInvoice = transfer.ProviderInvoice?.title;
    transfer.invoiceNumber = transfer.ProviderInvoice?.invoiceNumber;
    transfer.accountingAccount = transfer.ProviderInvoice?.Provider?.accountingAccount;
  })

  return (
    <ButtonGroup onClick={(e) => e.preventDefault()}>
      <ExportButton
        data={exportDataParse(item.TransferPayments, CSV_HEADERS)}
        display={user.hasAction("EXPORT_DATA")}
        options={[{ name: "XML", onClick: downloadXml }]}
        type="icon"
      />
    </ButtonGroup>
  );
};

export const TransferPaymentsContext = createContext();

const tabParam = [{ name: "tab", type: "number" }];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function TransferPaymentsPage() {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const query = useQuery();

  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");

  const [transferAccounts, setTransferAccounts] = useState([]);

  useEffect(() => {
    handleChangeTab(null, getParams(query, tabParam)?.tab || 0);
    getTransferAccounts();
  }, []);

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else setTransferAccounts(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeTab = (event, newTab) => {
    const filters = [initialFiltersTransferGroup, initialFiltersTransfer];
    history.push(
      generateURL("/app/transfer-payments", { tab: newTab, ...filters[newTab] })
    );
  };

  return (
    <TransferPaymentsContext.Provider value={{ transferAccounts }}>
      <Page
        title={t("transferPayments")}
        browserTitle={t("transferPayments")}
        paper={true}
      >
        <Tabs
          currentTab={getParams(query, tabParam)?.tab}
          onTabChange={handleChangeTab}
          tabs={[
            {
              label: t("transferGroups"),
              content: <TransferGroupTab />,
            },
            {
              label: t("transferences"),
              content: <TransferTab />,
            },
          ]}
        />
      </Page>
    </TransferPaymentsContext.Provider>
  );
}
