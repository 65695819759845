import { Button, ButtonGroup, Chip, Grid, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ShieldIcon from "@mui/icons-material/Shield";

import AppContext from "../../../context/AppContext";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CreateButton from "../../Inputs/CreateButton";
import CustomSelect from "../../Inputs/CustomSelect";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";
import { CUSTOMER_ROLE_ID } from "../../../data/constants";
import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  filters: {
    active: 1,
    autoSearch: "false",
    name: "",
    MFA: "",
    roles: [],
    order: "asc",
    orderBy: "",
  },
  options: {
    rowlink: "user",
    loaded: true,
    orderBy: "",
    order: "",
  },
  roles: [],
  users: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_USERS":
      return { ...state, users: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    default:
      throw new Error();
  }
}

export default function UsersPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("users");
  const [tErrors] = useTranslation("errors");

  const filters = [
    { name: "name", type: "string" },
    { name: "roles", type: "array" },
    { name: "active", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "autoSearch", type: "string" },
    { name: "MFA", type: "number" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const columns = [
    { label: t("name"), key: "name" },
    {
      label: t("role"),
      key: "Role.name",
      renderFunction: (value, item) => (
        <ButtonLink
          to={user.hasPage("ROLES") ? "/app/role/" + item.Role.id : undefined}
          size="small"
          sx={{ padding: 0 }}
        >
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("state"),
      key: "active",
      sortType: "number",
      renderFunction: (value) =>
        value ? (
          <Chip label={t("active")} color="success" size="small" />
        ) : (
          <Chip label={t("inactive")} color="error" size="small" />
        ),
    },
    {
      label: t("MFA"),
      key: "UserMFA",
      sortType: "number",
      renderFunction: (value) => {
        if (value) {
          if (value.isActive)
            return (
              <Tooltip title={t("active")} placement="top">
                <GppGoodIcon color="success" />
              </Tooltip>
            );
          else if (value.isRequired)
            return (
              <Tooltip title={t("required")} placement="top">
                <GppMaybeIcon color="warning" />
              </Tooltip>
            );
          else
            return (
              <Tooltip title={t("noActive")} placement="top">
                <ShieldIcon color="disabled" />
              </Tooltip>
            );
        }
      },
    },
    {
      label: t("actions"),
      key: "id",
      sortType: "other",
      renderFunction: (value, item) =>
        item.Role.id === CUSTOMER_ROLE_ID &&
        item.Customers.length > 0 && (
          <Tooltip title={t("viewCustomer")} placement="top">
            <ButtonLink
              to={"/app/customer/" + item.Customers[0]?.id}
              onClick={(e) => e.stopPropagation()}
            >
              <EmojiPeopleIcon />
            </ButtonLink>
          </Tooltip>
        ),
    },
  ];

  const csvHeaders = [
    { label: t("id"), key: "id" },
    { label: t("name"), key: "name" },
    { label: t("role"), key: "Role.name" },
    { label: t("state"), key: "active" },
  ];

  //Initial useEffect
  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getUsers();
  }, [state.filters.autoSearch]);

  const getRoles = () => {
    api
      .get("/roles")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ROLES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getUsers();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getUsers = () => {
    //Change url parameters:
    let url = generateURL("/app/users", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["Role", "Customer", "UserMFA"],
      name: state.filters.name,
      roleIds: state.filters.roles,
      active: convertToBoolean(state.filters.active),
      MFA: convertToBoolean(state.filters.MFA),
    };

    api
      .get("/users", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noUsers"), { variant: "warning" });

          dispatch({ type: "SET_USERS", payload: response.data });
        }
        dispatch({ type: "SET_LOADED_TRUE" });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_USERS", payload: [] });
  };

  const convertToBoolean = (value) => {
    if (value === 1) return true;
    else if (value === 0) return false;
    else return undefined;
  };

  return (
    <Page browserTitle={t("usersPage")} title={t("users")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                label={t("search")}
                value={state.filters.name}
                name="name"
                onChange={handleChangeFilter}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <CustomSelect
                options={state.roles
                  .map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                label="Roles"
                onChange={handleChangeFilter}
                name="roles"
                multiple
                value={state.filters.roles}
              />,
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  { value: 0, label: t("inactive") },
                  { value: 1, label: t("active") },
                ]}
                label={t("state")}
                onChange={handleChangeFilter}
                name="active"
                value={state.filters.active}
              />,
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  { value: 0, label: t("noActive") },
                  { value: 1, label: t("active") },
                ]}
                label={t("MFA")}
                onChange={handleChangeFilter}
                name="MFA"
                value={state.filters.MFA}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ButtonCSV
              data={state.users}
              headers={csvHeaders}
              filename={t("users")}
            />
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item>
          <CreateButton
            action={"CREATE_USERS"}
            label={t("createUser")}
            link={"/user/create"}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={columns}
            data={state.users}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/users", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
