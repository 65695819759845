import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../context/AppContext";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "../../../utils/date";
import { enqueueSnackbar } from "notistack";
import { localeFormat } from "../../../utils/format";

const AVERAGE_STAY_MONTHS = 14;

const HubSpotDeals = ({ contract, hubSpotContactIds, handleInputChange }) => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("contracts");

  const [deals, setDeals] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const lastSelectedDealIdRef = useRef(null);

  useEffect(() => {
    getStages();
  }, []);

  useEffect(() => {
    if (contract.hubSpotDealId) {
      setSelectedDealId(contract.hubSpotDealId);
      lastSelectedDealIdRef.current = contract.hubSpotDealId;
    }
  }, [contract.hubSpotDealId]);

  useEffect(() => {
    if (hubSpotContactIds?.length && stages.length) getContacts();
  }, [hubSpotContactIds, selectedDealId, stages]);

  const getStages = async () => {
    try {
      const response = await api.get("/hubspot/deals/stages");
      if (!response.data.error) {
        setStages(response.data);
      }
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
    }
  };

  const getContacts = async () => {
    try {
      const include = ["Deals"];
      const responses = await Promise.all(
        hubSpotContactIds.map((contactId) =>
          api.get(`/hubspot/contacts/${contactId}`, { params: { include } })
        )
      );
      const deals = responses.flatMap((response) => response.data.deals || []);
      const dealsData = getDealsData(deals);
      setDeals(dealsData);
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
    }
  };

  const editContract = async (dealId) => {
    const body = { hubSpotDealId: dealId };
    await api.post(`/contracts/${contract.id}`, body);
  };

  const editHubSpotDeal = async (newDealId) => {
    const contractIdToSend = newDealId ? contract.id : null;
    const amountToSend = newDealId
      ? contract.price * AVERAGE_STAY_MONTHS
      : null;
    const dealIdToEdit = newDealId || lastSelectedDealIdRef.current;

    await api.post(`/hubspot/deals/${dealIdToEdit}`, {
      contractId: contractIdToSend,
      amount: amountToSend,
    });
  };

  const handleDealChange = async (e) => {
    lastSelectedDealIdRef.current = selectedDealId;
    const newSelectedDealId = e.target.value;
    setSelectedDealId(newSelectedDealId);
    setIsLoading(true);

    try {
      await Promise.all([
        editContract(newSelectedDealId),
        editHubSpotDeal(newSelectedDealId),
      ]);
    } catch (error) {
      enqueueSnackbar(t("errorUpdatingDeal"), { variant: "error" });
    } finally {
      handleInputChange({
        target: {
          name: "hubSpotDealId",
          value: newSelectedDealId,
        },
      });
      setIsLoading(false);
    }
  };

  const getDealsData = (deals) => {
    return deals.map((deal) => ({
      id: deal.id,
      createdAt: deal.properties.createdate,
      closedAt: deal.properties.closedate,
      stage: stages.find((s) => s.id === deal.properties.dealstage)?.label,
      startDate: deal.properties.fecha_de_inicio,
      endDate: deal.properties.fecha_de_salida,
      contractId: deal.properties.id_de_contrato,
      location: deal.properties.location,
      size: deal.properties.metros_cuadrados,
      offeredBox: deal.properties.no_de_trastero_ofrecido,
      firstPriceOffer: deal.properties.propuesta_de_precio_1,
      secondPriceOffer: deal.properties.propuesta_de_precio_2,
      dealName: deal.properties.dealname,
    }));
  };

  return (
    <FormControl fullWidth size="small" disabled={isLoading}>
      <InputLabel>{t("deals")}</InputLabel>
      <Select
        value={selectedDealId}
        name="deals"
        label={t("deals")}
        onChange={handleDealChange}
        renderValue={(selectedId) => {
          const deal = deals.find((d) => d.id === selectedId);
          if (!deal) return "";
          const labels = [deal.dealName, deal.location, deal.stage];
          return labels.join(" | ");
        }}
      >
        <MenuItem value={null}>{t("noDeal")}</MenuItem>
        {deals.map((deal) => (
          <MenuItem key={deal.id} value={deal.id}>
            <DealBox deal={deal} selectedDealId={selectedDealId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DealBox = ({ deal, selectedDealId }) => {
  const [t] = useTranslation("contracts");
  const theme = useTheme();

  const isSelected = selectedDealId === deal.id;

  const details = [
    { label: t("location"), value: deal.location },
    {
      label: t("createdAt"),
      value: deal.createdAt && formatDateTime(new Date(deal.createdAt)),
    },
    {
      label: t("closedAt"),
      value: deal.closedAt && formatDateTime(new Date(deal.closedAt)),
    },
    {
      label: t("startDate"),
      value: deal.startDate && formatDate(new Date(deal.startDate)),
    },
    {
      label: t("endDate"),
      value: deal.endDate && formatDate(new Date(deal.endDate)),
    },
    { label: t("size"), value: deal.size && `${deal.size}` },
    { label: t("offeredBox"), value: deal.offeredBox },
    {
      label: t("firstPriceOffer"),
      value: deal.firstPriceOffer && `${localeFormat(deal.firstPriceOffer)}€`,
    },
    {
      label: t("secondPriceOffer"),
      value: deal.secondPriceOffer && `${localeFormat(deal.secondPriceOffer)}€`,
    },
    { label: t("contractId"), value: deal.contractId },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 700,
        padding: 2,
        borderRadius: 2,
        boxShadow: 1,
        backgroundColor: "background.paper",
        border: isSelected ? `2px solid ${theme.palette.primary.main}` : "none",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {/* Deal Header */}
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight="bold">
          {deal.dealName}
        </Typography>
        {deal.stage && <Chip label={deal.stage} color="primary" />}
      </Grid>

      {/* Details Section */}
      <Divider sx={{ marginY: 1 }} />
      <Grid item container>
        {details
          .filter((field) => field.value)
          .map((field, index) => (
            <Grid container item xs={12} sm={6} md={4} key={index}>
              <DetailField label={field.label} value={field.value} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

const DetailField = ({ label, value }) => (
  <Grid container item flexDirection="column" paddingBottom={1}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="body2" fontWeight="medium">
      {value}
    </Typography>
  </Grid>
);

export default HubSpotDeals;
