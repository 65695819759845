import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider, closeSnackbar } from "notistack";
import Slide from "@mui/material/Slide";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
import "./utils/i18n";

import App from "./App";
import Head from "./components/Head.js";

import ContractSignaturePage from "./components/pages/Contracts/ContractSignaturePage";
import LandingPage from "./components/pages/LandingPage";
import LoginPage from "./components/pages/LoginPage";
import NewPasswordPage from "./components/pages/NewPasswordPage";
import NotFoundPage from "./components/pages/NotFoundPage.js";
import RegisterPage from "./components/pages/RegisterPage";
import ResetPasswordPage from "./components/pages/ResetPasswordPage";
import ResetPasswordLinkPage from "./components/pages/ResetPasswordLinkPage";

import theme from "./theme/theme";

import { ErrorBoundary } from "react-error-boundary";
import CustomErrorFallback from "./components/CustomErrorFallback";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

import AddPaycardPage from "./components/pages/Public/AddPaycardPage.js";
import UrlOKPage from "./components/pages/Public/UrlOKPage.js";
import UrlKOPage from "./components/pages/Public/UrlKOPage.js";

//import reportWebVitals from './reportWebVitals';

document.body.classList.add("default");

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={CustomErrorFallback}>
        <SnackbarProvider
          action={(key) => (
            <IconButton
              onClick={() => {
                closeSnackbar(key);
              }}
              color="inherit"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={5000}
          TransitionComponent={Slide}
          preventDuplicate
        >
          <Head />
          <Router>
            <Switch>
              <Route exact path="/">
                <LandingPage />
              </Route>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/register">
                <RegisterPage />
              </Route>
              <Route path="/reset-password">
                <ResetPasswordPage />
              </Route>
              <Route path="/reset-password-link">
                <ResetPasswordLinkPage />
              </Route>
              <Route path="/new-password/:token">
                <NewPasswordPage />
              </Route>
              <Route path="/sign-contract/:token">
                <ContractSignaturePage />
              </Route>
              <Route path="/add-paycard">
                <AddPaycardPage />
              </Route>
              <Route path="/urlOK">
                <UrlOKPage />
              </Route>
              <Route path="/urlKO">
                <UrlKOPage />
              </Route>
              <Route path="/app">
                <App />
              </Route>
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </Router>
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
