import { Grid } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AppContext from "../../../../context/AppContext";
import { AccessLevelsContext } from "../AccessControlPage";

import { CustomTable } from "../../../CustomTable";
import Filters from "../../../global/structure/Filters";
import SearchButton from "../../../Inputs/SearchButton";
import Select from "../../../global/inputs/Select";
import TextInput from "../../../Inputs/TextInput";
import ButtonLink from "../../../Inputs/ButtonLink";

const hACOptions = [
  { value: "", label: "All" },
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const initialFilters = {
  customerName: "",
  hasAccessControl: "",
};

const today = new Date();
const rowsPerPageOptions = [5, 10];

const filterCompare = (value) => (value !== "" ? value : undefined);

const getContractState = (contract) => {
  let state = -1;
  if (contract.startDate) {
    state = 1;
    let startDate = new Date(contract.startDate);
    let endDate = new Date(contract.endDate);

    if (today < startDate) {
      state = 0;
    }
    if (contract.endDate && endDate <= today) {
      state = 2;
    }
  }
  return state;
};

function useAccessData(filters, setLoading) {
  const { api } = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [accessUsers, setAccessUsers] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);

  const getCustomers = () => {
    setLoading(true);

    const params = {
      hasAccessControl: filterCompare(filters.hasAccessControl),
      name: filterCompare(filters.customerName),
      include: ["AccessControlUser", "Contract", "Box"],
    };

    api
      .get("/customers", { params })
      .then((res) => {
        if (res.data.error)
          enqueueSnackbar(res.data.error, { variant: "error" });
        else setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccessUsers = (page, rowsPerPage) => {
    if (!customers || !customers.length || !page || !rowsPerPage) return;
    const accessUsers = customers
      .filter((customer) => customer.AccessControlUser !== null)
      .map((customer) => customer.AccessControlUser)
      .slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const params = { pins: accessUsers.map((u) => u.id) };
    api
      .get("/access-control/person-list", { params })
      .then((res) => {
        setAccessUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const paginationCallBack = (page, rowsPerPage) => {
    if (!customers) return;
    getAccessUsers(page + 1, rowsPerPage);
  };

  React.useEffect(() => {
    if (customers.length > 0)
      getAccessUsers(1, rowsPerPageOptions[rowsPerPageOptions.length - 1]);
  }, [customers]);

  return { customers, accessUsers, getCustomers, paginationCallBack };
}

function CustomersTab() {
  const [t] = useTranslation("accessControl");

  const accessLevels = React.useContext(AccessLevelsContext);

  const [filters, setFilters] = React.useState(initialFilters);
  const [loading, setLoading] = React.useState(false);
  const { customers, accessUsers, getCustomers, paginationCallBack } =
    useAccessData(filters, setLoading);

  const accessCentersRender = (value, item) => {
    if (!value) return "";
    const accessUser = accessUsers.find((u) => Number(u.pin) === value.id);
    if (!accessUser) return "";
    const accessLevelIds = accessUser.accLevelIds
      ? accessUser.accLevelIds.split(",")
      : [];
    return accessLevels
      .filter((level) => accessLevelIds.includes(level.id))
      .map((level) => level.name)
      .join(", ");
  };

  const accessCentersWhoShouldRender = (value, item) => {
    if (!value) return "";
    const accessUser = accessUsers.find((u) => Number(u.pin) === value.id);
    if (!accessUser) return "";
    const centerIds = item.Contracts.filter(
      (c) => c.Box && getContractState(c) <= 1
    ).map((c) => c.Box.centerId);
    return accessLevels
      .filter((level) => centerIds.includes(level.centerId))
      .map((level) => level.name)
      .join(", ");
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilters({ ...filters, [name]: value });
  };

  const CUSTOMERS_COLUMNS = [
    {
      key: "fullName",
      label: t("name"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/customer/" + item.id}>{value}</ButtonLink>
      ),
    },
    {
      key: "AccessControlUser",
      label: t("hasAccessControl"),
      sortType: "other",
      renderFunction: (value, item) => {
        if (!value || !accessUsers.some((u) => Number(u.pin) === value.id))
          return "";
        return "YES";
      },
    },
    {
      key: "AccessControlUser",
      label: t("hasAccessToCenters"),
      sortType: "other",
      renderFunction: accessCentersRender,
    },
    {
      key: "AccessControlUser",
      label: t("shouldHaveAccessToCenters"),
      sortType: "other",
      renderFunction: accessCentersWhoShouldRender,
    },
  ];

  return (
    <Grid item marginTop={2} spacing={2}>
      <Filters
        filters={[
          <TextInput
            label={t("name")}
            name="customerName"
            value={filters.customerName}
            onChange={handleFilterChange}
          />,
          <Select
            label={t("hasAccessControl")}
            name="hasAccessControl"
            value={filters.hasAccessControl}
            options={hACOptions}
            onChange={handleFilterChange}
          />,
          <SearchButton onClick={getCustomers} loading={loading} />,
        ]}
      ></Filters>
      <Grid item marginTop={2}>
        <CustomTable
          currentPage={0}
          columns={CUSTOMERS_COLUMNS}
          data={customers}
          rowsPerPageOptions={rowsPerPageOptions}
          allRowsOption={false}
          onPaginationCallback={paginationCallBack}
        ></CustomTable>
      </Grid>
    </Grid>
  );
}

export default CustomersTab;
