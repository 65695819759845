import { Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../Inputs/TextInput";
import { localeFormat } from "../../../utils/format";
import AppContext from "../../../context/AppContext";

const PERIODICITY_VALUES = [1, 3, 6, 12];

const ContractPricingDetails = ({ contract, onChange }) => {
  const [t] = useTranslation("contracts");
  const { user } = useContext(AppContext);

  const meters = contract.meters;
  let pricePerMeter = contract.pricePerMeter;
  let monthlyPrice = meters * pricePerMeter;
  let monthlyPriceWithVAT = monthlyPrice * (1 + contract.taxes / 100);
  let pricePerInstallment =
    monthlyPrice * PERIODICITY_VALUES[contract.periodicity - 1];
  let pricePerInstallmentWithVAT =
    pricePerInstallment * (1 + contract.taxes / 100);

  // The edited values are used to allow changes in the component, without changing the parent state, which only changes onBlur event for monthlyPrice and monthlyPriceWithVAT
  const [editedMonthlyPrice, setEditedMonthlyPrice] = useState(0);
  const [editedMonthlyPriceWithVAT, setEditedMonthlyPriceWithVAT] = useState(0);

  useEffect(() => {
    setEditedMonthlyPrice(parseFloat(monthlyPrice).toFixed(2));
    setEditedMonthlyPriceWithVAT(parseFloat(monthlyPriceWithVAT).toFixed(2));
  }, [contract]);

  const handleMonthlyPriceChange = (e) => {
    onChange({
      target: {
        name: "pricePerMeter",
        value: parseFloat(e.target.value / meters).toFixed(2),
      },
    });
  };

  const handleMonthlyPriceWithVATChange = (e) => {
    onChange({
      target: {
        name: "pricePerMeter",
        value: parseFloat(
          e.target.value / meters / (1 + contract.taxes / 100)
        ).toFixed(2),
      },
    });
  };

  return (
    <>
      <Grid item container xs={12} md={3} rowSpacing={2}>
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={4} md={7}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("meters") + ": "}
            </Typography>
          </Grid>
          <Grid item md={5}>
            <Typography variant="body1" component="span" xs={6}>
              {localeFormat(meters) + "m²"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={4} md={7}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("pricePerMeter") + ": "}
            </Typography>
          </Grid>
          <Grid item md={5}>
            {user.hasAction("EDIT_CONTRACTS") ? (
              <TextInput
                label={t("pricePerMeter")}
                name="pricePerMeter"
                value={pricePerMeter}
                onChange={onChange}
                type="number"
                sx={{ maxWidth: 110 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography variant="body1" component="span">
                {localeFormat(pricePerMeter) + "€"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={0} md={0.5} justifyContent="center">
        <Grid item>
          <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} />
        </Grid>
      </Grid>

      <Grid item container xs={12} md={4} rowSpacing={2}>
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={4} md={7}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("monthlyPrice") + ": "}
            </Typography>
          </Grid>
          <Grid item md={5}>
            {user.hasAction("EDIT_CONTRACTS") ? (
              <TextInput
                label={t("monthlyPrice")}
                name="editedMonthlyPrice"
                value={editedMonthlyPrice}
                onChange={(e) => setEditedMonthlyPrice(e.target.value)}
                onBlur={handleMonthlyPriceChange}
                type="number"
                sx={{ maxWidth: 110 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography variant="body1" component="span">
                {localeFormat(monthlyPrice) + "€"}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={4} md={7}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("monthlyPriceWithVAT") + ": "}
            </Typography>
          </Grid>
          <Grid item md={5}>
            {user.hasAction("EDIT_CONTRACTS") ? (
              <TextInput
                label={t("monthlyPriceWithVAT")}
                name="editedMonthlyPriceWithVAT"
                value={editedMonthlyPriceWithVAT}
                onChange={(e) => setEditedMonthlyPriceWithVAT(e.target.value)}
                onBlur={handleMonthlyPriceWithVATChange}
                type="number"
                sx={{ maxWidth: 110 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography variant="body1" component="span">
                {localeFormat(monthlyPriceWithVAT) + "€"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={0} md={0.5} justifyContent="center">
        <Grid item>
          <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} />
        </Grid>
      </Grid>

      <Grid item container xs={12} md={3} rowSpacing={2}>
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={4} md={8}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("pricePerInstallment") + ": "}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body1" component="span">
              {localeFormat(pricePerInstallment) + "€"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={4} md={8}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("pricePerInstallmentWithVAT") + ": "}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body1" component="span">
              {localeFormat(pricePerInstallmentWithVAT) + "€"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContractPricingDetails;
