import { Button, Box, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import UploadIcon from "@mui/icons-material/Upload";

import Dialog from "../../../../global/Dialog";
import CustomButton from "../../../../Inputs/CustomButton";

const CreateImageDialog = (props) => {
  const { setDialogState, handleCreateItem, isOpen } = props;
  const [t] = useTranslation("floorPlans");

  const imageRef = useRef(null);
  const [image, setImage] = useState(null);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setDialogState(false);
        imageRef = null;
        setImage(null);
      }}
      maxWidth="md"
      title={t("createImageItem")}
      actions={
        <>
          <Grid item>
            <Button
              onClick={() => {
                setDialogState(false);
                setImage(null);
              }}
              variant="text"
            >
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <CustomButton
              color="primary"
              onClick={() => {
                const imageFile = new File([image], image.name);
                const item = {
                  width: imageRef.current?.width,
                  height: imageRef.current?.height,
                  image: imageFile,
                };
                handleCreateItem("Image", item);
                setImage(null);
              }}
            >
              {t("add")}
            </CustomButton>
          </Grid>
        </>
      }
    >
      <Box width="100%">
        <Grid item container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              startIcon={<UploadIcon />}
              variant="outlined"
              color="primary"
              component="label"
            >
              {t("selectImage")}
              <input
                type="file"
                hidden
                name="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Button>
          </Grid>
          <Grid item xs={12}></Grid>
          {image && (
            <Grid container item xs={12} justifyContent="center">
              <Grid item>
                <img
                  ref={imageRef}
                  src={image ? URL.createObjectURL(image) : null}
                  style={{
                    maxWidth: "200px",
                    maxHeight: "300px",
                    border: "1px solid black",
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
};

CreateImageDialog.propTypes = {
  setDialogState: PropTypes.func,
  handleCreateItem: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CreateImageDialog;
