import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import General from "./Tab/General";

function ProviderInvoices() {
  const [t] = useTranslation("dashboard");

  return (
    <Page browserTitle={t("providerInvoices")} paper>
      <Grid container>
        <Grid item>
          <Tabs
            tabs={[
              {
                label: [t("general")],
                content: <General />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default ProviderInvoices;
