import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dialog from "../../../../global/Dialog";
import TextInput from "../../../../Inputs/TextInput";
import CustomButton from "../../../../Inputs/CustomButton";

const EditFloorPlanDialog = (props) => {
  const { isOpen, data, onClose, onConfirm } = props;
  const [t] = useTranslation("floorPlans");

  const [newName, setNewName] = useState();
  const [newWidth, setNewWidth] = useState();
  const [newHeight, setNewHeight] = useState();

  useEffect(() => {
    setNewName(data.name);
    setNewWidth(data.width);
    setNewHeight(data.height);
  }, [data]);

  const handleOnConfirm = () => {
    let params = {};

    newName !== data.name && (params.name = newName);
    newWidth !== data.width && (params.width = newWidth);
    newHeight !== data.height && (params.height = newHeight);

    onConfirm(params);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose && onClose()}
      maxWidth="md"
      title={t("editFloorPlan")}
      actions={
        <>
          <CustomButton onClick={() => onClose()} color="error">
            {t("cancel")}
          </CustomButton>
          <CustomButton onClick={() => handleOnConfirm()}>
            {t("confirm")}
          </CustomButton>
        </>
      }
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <TextInput
            label={t("name")}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            label={t("width")}
            value={newWidth}
            onChange={(e) => setNewWidth(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            label={t("height")}
            value={newHeight}
            onChange={(e) => setNewHeight(e.target.value)}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

EditFloorPlanDialog.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default EditFloorPlanDialog;
