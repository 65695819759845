import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

//Material UI
import {
  Button,
  ButtonGroup,
  Chip,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

//Icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DownloadIcon from "@mui/icons-material/Download";

//Components
import AppContext from "../../../context/AppContext";
import CreateButton from "../../Inputs/CreateButton";
import CustomDate from "../../Inputs/CustomDate";
import { CustomTable } from "../../CustomTable";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import TextInput from "../../Inputs/TextInput";
import SearchButton from "../../Inputs/SearchButton";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import {
  firstDateCurrentMonth,
  lastDateCurrentMonth,
} from "../../../utils/date";
import Filters from "../../global/structure/Filters";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  tableOptions: {
    rowlink: "remittance",
    loaded: true,
  },
  filters: {
    amountMin: "",
    amountMax: "",
    autoSearch: false,
    name: "",
    dateFrom: firstDateCurrentMonth(),
    dateUntil: lastDateCurrentMonth(),
    orderBy: "",
    order: "asc",
  },
  remittances: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_REMITTANCES":
      return { ...state, remittances: action.payload };
    case "SET_LOADED_TRUE":
      return {
        ...state,
        tableOptions: { ...state.tableOptions, loaded: true },
      };
    case "SET_LOADED_FALSE":
      return {
        ...state,
        tableOptions: { ...state.tableOptions, loaded: false },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    default:
      throw new Error("Action not found in reducer.");
  }
}

const RemittancesPage = () => {
  const [t] = useTranslation("remittances");
  const { api, user } = useContext(AppContext);
  const query = useQuery();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const FILTERS = [
    { name: "amountMin", type: "number" },
    { name: "amountMax", type: "number" },
    { name: "name", type: "string" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "autoSearch", type: "boolean" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const CSV_RECEIPT_HEADERS = [
    { label: t("customerName"), key: "customerName" },
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    { label: t("iban"), key: "iban" },
    { label: t("dueDate"), key: "dueDate" },
  ];

  const REMITTANCE_COLUMNS = [
    { label: t("remittance"), key: "name", sortType: "string" },
    { label: t("dueDate"), key: "dueDate", sortType: "date" },
    {
      label: t("amount"),
      key: "amount",
      sortType: "number",
      renderFunction: (value) => value + "€",
    },
    {
      label: t("numberOfReceipts"),
      key: "numberOfReceipts",
      sortType: "number",
    },
    {
      label: t("sentAt"),
      key: "sentAt",
      sortType: "date",
      renderFunction: (value) =>
        value ? (
          <Chip color="success" label={value} size="small" />
        ) : (
          <Chip color="warning" label={t("pending")} size="small" />
        ),
    },
    {
      label: t("comments"),
      key: "comments",
      sortType: "string",
      renderFunction: (value) => (
        <Typography
          variant="body2"
          style={{
            maxWidth: 200,
            overflowWrap: "break-word",
          }}
        >
          {value}
        </Typography>
      ),
    },
    {
      label: t("actions"),
      key: "id",
      sortType: "number",
      renderFunction: (value, item) => (
        <ButtonGroup onClick={(e) => e.stopPropagation()}>
          <Tooltip title={t("downloadXml")} placement="top">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                downloadXML(value);
              }}
              disabled={value ? false : true}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <ButtonCSV
            data={item?.Receipts.map((receipt) => ({
              ...receipt,
              amount: localeFormat(receipt.amount),
            }))}
            headers={CSV_RECEIPT_HEADERS}
            filename={item?.name + ".csv"}
            icon
            tooltipTitle={t("downloadReceiptsCsv")}
            disabled={item?.Receipts?.length === 0}
          />
        </ButtonGroup>
      ),
    },
  ];

  const CSV_HEADERS = [
    { label: t("remittance"), key: "name" },
    { label: t("dueDate"), key: "dueDate" },
    { label: t("amount"), key: "amount" },
    { label: t("numberOfReceipts"), key: "numberOfReceipts" },
    { label: t("sentAt"), key: "sentAt" },
    { label: t("comments"), key: "comments" },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("remittancesPage");
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === true) getRemittances();
  }, [state.filters.autoSearch]);

  const search = () => {
    if (state.filters.autoSearch === true) getRemittances();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: true },
      });
  };

  const getRemittances = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    //Change url parameters:
    let url = generateURL("/app/remittances", state.filters);
    history.push(url);

    let params = {
      include: ["Receipt"],
    };

    state.filters.name !== "" && (params.name = state.filters.name);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.amountMin !== "" &&
      (params.amountMin = state.filters.amountMin);
    state.filters.amountMax !== "" &&
      (params.amountMax = state.filters.amountMax);

    api
      .get("/remittances", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noRemittances"), { variant: "warning" });
          }
          const data = response.data.map((remittance) => ({
            ...remittance,
            amount: localeFormat(remittance.amount),
          }));
          dispatch({ type: "SET_REMITTANCES", payload: data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const downloadXML = (id) => {
    const remittance = state.remittances.find((item) => item.id === id);

    api
      .get("/remittances/" + id + "/xml")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          let xml = response.data;

          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([xml], { type: "text/plain" })
          );
          a.download = remittance.name + ".xml";

          // Append anchor to body.
          document.body.appendChild(a);
          a.click();

          // Remove anchor from body
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_REMITTANCES", payload: [] });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("remittances")}</Typography>
        </Grid>

        <Grid item container xs={12} spacing={1}>
          <Grid item>
            <Filters
              filters={[
                <TextInput
                  label={t("name")}
                  value={state.filters.name}
                  name="name"
                  onChange={handleFilterChange}
                />,
                <CustomDate
                  label={t("dateFrom")}
                  value={state.filters.dateFrom}
                  onChange={handleFilterChange}
                  name="dateFrom"
                />,
                <CustomDate
                  label={t("dateUntil")}
                  value={state.filters.dateUntil}
                  onChange={handleFilterChange}
                  name="dateUntil"
                />,
                <TextInput
                  label={t("amountMin")}
                  value={state.filters.amountMin}
                  name="amountMin"
                  onChange={handleFilterChange}
                />,
                <TextInput
                  label={t("amountMax")}
                  value={state.filters.amountMax}
                  name="amountMax"
                  onChange={handleFilterChange}
                />,
              ]}
            />
          </Grid>

          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={resetFilters}>{t("reset")}</Button>
              <ExportButton
                data={exportDataParse(state.remittances, CSV_HEADERS)}
                display={user.hasAction("EXPORT_DATA")}
              />
              <SearchButton
                onClick={search}
                loading={!state.tableOptions.loaded}
              />
            </ButtonGroup>
          </Grid>

          <Grid item>
            <ButtonLink
              to={"/app/banks"}
              variant="contained"
              color="primary"
              startIcon={<AccountBalanceIcon />}
            >
              {t("banks")}
            </ButtonLink>
          </Grid>

          <Grid item>
            <CreateButton
              action={"CREATE_REMITTANCES"}
              label={t("createRemittance")}
              link={"/remittance/create"}
            />
          </Grid>
          <Grid item>
            <CreateButton
              action={"CREATE_REMITTANCES"}
              label={t("createDirectRemittance")}
              link={"/remittance/create-direct"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            data={state.remittances}
            columns={REMITTANCE_COLUMNS}
            options={state.tableOptions}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/remittances", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default RemittancesPage;
