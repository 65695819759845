import { Doughnut as ChartJsDoughnut } from "react-chartjs-2";

function Doughnut({ data = {}, options = {} }) {
  const labels = data.labels && data.labels.length > 0 ? data.labels : [];

  const dataSets =
    data.datasets && data.datasets.length > 0 ? data.datasets : [];

  const chartData = {
    labels: labels,
    datasets: dataSets,
  };

  const chartOptions = {
    ...options,
  };

  return <ChartJsDoughnut data={chartData} options={chartOptions} />;
}

export default Doughnut;
