import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React, {
  useEffect,
  useReducer,
  useContext,
  useState,
  useMemo,
} from "react";

// Material UI
import {
  Alert,
  Badge,
  Box,
  ButtonGroup,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

// Icons
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BrushIcon from "@mui/icons-material/Brush";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Fingerprint from "@mui/icons-material/Fingerprint";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ListIcon from "@mui/icons-material/List";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import UnsubscribeRoundedIcon from "@mui/icons-material/UnsubscribeRounded";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LinkIcon from "@mui/icons-material/Link";

// Components & Utils
import AppContext from "../../../context/AppContext";
import { SERVER } from "../../../utils/API";
import { formatDate } from "../../../utils/chartUtils";

import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import Button from "../../Inputs/CustomButton";
import ButtonLink from "../../Inputs/ButtonLink";
import ConfirmDialog from "../../ConfirmDialog";
import ContractSignature from "./ContractSignature";
import ContractStateChip from "./ContractStateChip";
import CreditCardForm from "../../CreditCardForm";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import DateInput from "../../Inputs/CustomDate";
import RequestCancellationForm from "./RequestCancellationForm";
import Select from "../../global/inputs/Select";
import TextInput from "../../Inputs/TextInput";
import {
  CASH_PAYMENT_METHOD_ID,
  ENDED_CONTRACT_STATE_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  UNPAID_INVOICE_STATE_ID,
} from "../../../data/constants";
import CustomButton from "../../Inputs/CustomButton";

import { NO_ENTER_CANCELLATION_TYPE_ID } from "../../../data/constants";
import BillingAddressCard from "../Customers/BillingAddressCard";
import LabeledText from "../../global/LabeledText";
import CancellationRequestCard from "./CancellationRequestCard";
import { downloadFile } from "../../../utils/file";
import Page from "../../global/structure/Page";
import ContractPricingDetails from "./ContractPricingDetails";
import HubSpotDeals from "./HubSpotDeals";

const INVOICE_SERIES_NAMES = ["?", "F", "A", "S", "C", "H", "R"];
const INVOICE_SERIES_COLORS = [
  "primary",
  "success",
  "warning",
  "error",
  "default",
];

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_REQUEST":
      return {
        ...state,
        request: {
          ...state.request,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CONTRACT":
      return {
        ...state,
        contract: action.payload,
        form: action.payload,
      };
    case "SET_CONTRACT_TYPES":
      return { ...state, contractTypes: action.payload };
    case "SET_FORMS_OF_PAY":
      return {
        ...state,
        ibans: action.payload.ibans,
        cards: action.payload.cards,
      };
    case "SET_ADD_IBAN_LOADING":
      return { ...state, addIbanLoading: action.payload };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "SET_CONTRACT_FREEZE_STATUS":
      return { ...state, form: { ...state.form, isFrozen: action.payload } };
    case "OPEN_CANCEL_DIALOG":
      return {
        ...state,
        cancelDialog: { ...state.cancelDialog, isOpen: true },
      };
    case "CLOSE_CANCEL_DIALOG":
      return {
        ...state,
        cancelDialog: { ...state.cancelDialog, isOpen: false },
      };
    case "SET_SEND_EMAIL":
      return {
        ...state,
        cancelDialog: { ...state.cancelDialog, sendEmail: action.payload },
      };
    case "SET_CANCEL_INPUT":
      return {
        ...state,
        cancelDialog: {
          ...state.cancelDialog,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_CANCEL_INPUTS":
      return { ...state, cancelDialog: initialState.cancelDialog };
    case "SET_TYPES":
      return { ...state, cancellationTypes: action.payload };
    case "OPEN_FILE_MODAL":
      return { ...state, fileModal: { ...state.fileModal, isOpen: true } };
    case "CLOSE_FILE_MODAL":
      return { ...state, fileModal: { ...state.fileModal, isOpen: false } };
    case "SET_FILE":
      return {
        ...state,
        fileModal: { ...state.fileModal, files: action.payload },
      };
    case "SET_SIGNED_FILE":
      return { ...state, signedFile: action.payload };
    case "OPEN_SIGNATURE_MODAL":
      return {
        ...state,
        signatureModal: { ...state.signatureModal, isOpen: true },
      };
    case "CLOSE_SIGNATURE_MODAL":
      return {
        ...state,
        signatureModal: { ...state.signatureModal, isOpen: false },
      };
    case "SET_SIGNATURE_REQUEST":
      return {
        ...state,
        signatureModal: { ...state.signatureModal, request: action.payload },
      };
    case "SET_SIGNATURE_LOADING":
      return {
        ...state,
        signatureModal: {
          ...state.signatureModal,
          loading: !state.signatureModal.loading,
        },
      };
    case "SET_DISABLE_DIG_SIG_REQ":
      return { ...state, disableDigSigReq: action.payload };
    case "CLOSE_CANCELLATION_REQ_MODAL":
      return {
        ...state,
        cancellationReqModal: { ...state.cancellationReqModal, isOpen: false },
      };
    case "OPEN_CANCELLATION_REQ_MODAL":
      return {
        ...state,
        cancellationReqModal: { ...state.cancellationReqModal, isOpen: true },
      };
    case "CHANGE_STATE_FREEZE_REQ_MODAL":
      return {
        ...state,
        freezeReqModal: { ...state.freezeReqModal, isOpen: action.payload },
      };
    case "CHANGE_STATE_RESUME_REQ_MODAL":
      return {
        ...state,
        resumeReqModal: { ...state.resumeReqModal, isOpen: action.payload },
      };
    case "SET_CANCELLATION_REQ_PENDING":
      return { ...state, pendingCancellationRequest: action.payload };
    case "CLOSE_CONFIRM_CANCELLATION_MODAL":
      return {
        ...state,
        confirmCancellationModal: {
          ...state.confirmCancellationModal,
          isOpen: false,
        },
      };
    case "OPEN_CONFIRM_CANCELLATION_MODAL":
      return {
        ...state,
        confirmCancellationModal: {
          ...state.confirmCancellationModal,
          isOpen: true,
        },
      };
    case "SET_INPUT_CONFIRM_CANCELLATION":
      return {
        ...state,
        confirmCancellationModal: {
          ...state.confirmCancellationModal,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_REQUEST":
      return { ...state, request: initialState.request };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_CANCELLATION_REQUEST_MODAL":
      return {
        ...state,
        confirmCancellationModal: initialState.confirmCancellationModal,
      };
    case "SET_LOADING":
      return { ...state, loading: !state.loading };
    case "SET_HAS_UNPAID_INVOICES":
      return { ...state, hasUnpaidInvoices: action.payload };
    case "SET_ICON_LOADING_STATE":
      return {
        ...state,
        iconsLoadingState: {
          ...state.iconsLoadingState,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error("Action not found in reducer.");
  }
}

const initialState = {
  form: {
    boxId: "",
    centerId: "",
    comments: "",
    contractTypeId: "",
    customer: "",
    customerId: "",
    dateOfPayment: "",
    endDate: "",
    ibanId: "",
    invoiceComments: "",
    invoiceIssuerId: "",
    isFrozen: false,
    meters: "",
    paycardId: "",
    paymentMethod: "",
    periodicity: "",
    pricePerMeter: "",
    publicId: "",
    startDate: "",
    state: "",
    taxes: "",
    hubSpotDealId: "",
  },
  cancel: false,
  cancelDialog: {
    cancelContract: "no",
    cancellationDate: "",
    cancellationType: "",
    comments: "",
    isOpen: false,
    loading: false,
    sendEmail: "no",
  },
  cancellationTypes: [],
  pendingCancellationRequest: [],
  cancellationReqModal: {
    isOpen: false,
  },
  cards: [],
  cardModal: {
    isOpen: false,
  },
  confirmCancellationModal: {
    isOpen: false,
    cancellationDate: "",
    resolutionComments: "",
    sendEmailCustomer: "false",
    sendEmailCenter: "false",
    loading: false,
  },
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  contractTypes: [],
  contract: {
    CancellationRequest: {
      CancellationType: {},
      requestedByUser: {},
    },
  },
  disableDigSigReq: false,
  fileModal: {
    files: [],
    isOpen: false,
  },
  freezeReqModal: {
    isOpen: false,
  },
  resumeReqModal: {
    isOpen: false,
  },
  addIbanLoading: false,
  ibans: [],
  id: null,
  issuers: [],
  inputError: {
    boxId: false,
    centerId: false,
    customer: false,
    contractTypeId: false,
    endDate: false,
    paymentMethod: false,
    meters: false,
    pricePerMeter: false,
    publicId: false,
    startDate: false,
    taxes: false,
    cancellationDate: false,
  },
  merchOptions: {
    rowlink: "merchantable",
  },
  open: false,
  paymentDays: [],
  hasUnpaidInvoices: false,
  request: {
    alert: false,
    alert2: false,
    alert3: false,
    contractId: "",
    cancellationDate: "",
    requestedBy: "",
    comments: "",
    cancellationTypeId: "",
    loading: false,
  },
  signatureModal: {
    isOpen: false,
    request: "",
    loading: false,
  },
  loading: false,
  iconsLoadingState: {
    uploadSignedContract: false,
    printContract: false,
    emailPdf: false,
    freezeContract: false,
    resumeContract: false,
  },
};

export default function ContractPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("contracts");
  const [tErrors] = useTranslation("errors");

  const paymentMethods = [
    { id: CASH_PAYMENT_METHOD_ID, label: t("counted") },
    { id: RECEIPT_PAYMENT_METHOD_ID, label: t("receipt") },
    { id: PAYCARD_PAYMENT_METHOD_ID, label: t("card") },
    { id: TOKENIZED_PAYCARD_PAYMENT_METHOD_ID, label: t("tokenizedPaycard") },
    { id: TRANSFER_PAYMENT_METHOD_ID, label: t("transfer") },
  ];

  const hubSpotContactIds = useMemo(
    () =>
      state.contract.Customer?.CustomerEmails?.map(
        (email) => email.hubspotContactId
      ).filter((id) => id),
    [state.contract.Customer?.CustomerEmails]
  );

  const MERCHANTABLE_COLUMNS = [
    { label: t("concept"), key: "concept", sortType: "string" },
    { label: t("startDate"), key: "startDate", sortType: "string" },
    { label: t("endDate"), key: "endDate", sortType: "string" },
    {
      label: t("invoice"),
      key: "InvoiceItems",
      renderFunction: (value) => {
        if (value.length === 0) return <></>;
        return value.length > 1 ? (
          <Box>
            <MerchantableRowMenu
              value={value}
              isColor={INVOICE_SERIES_COLORS}
              isNames={INVOICE_SERIES_NAMES}
            />
          </Box>
        ) : (
          <ButtonLink
            to={`/app/invoice/${value[0].Invoice.id}`}
            disabled={!user.hasPage("INVOICES")}
          >
            <Chip
              sx={{ cursor: "pointer" }}
              color={INVOICE_SERIES_COLORS[value[0].Invoice.state]}
              label={
                INVOICE_SERIES_NAMES[value[0].Invoice.serieId] +
                value[0].Invoice.number
              }
              size="small"
            />
          </ButtonLink>
        );
      },
      sortType: "other",
    },
    {
      label: t("base"),
      key: "baseAmount",
      renderFunction: (value) => localeFormat(value) + "€",
      sortType: "number",
    },
    {
      label: t("total"),
      key: "totalAmount",
      sortType: "number",
      renderFunction: (value) => value + "€",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    getContract();
    getCancellationTypes();
    getPaymentDays();
  }, []);

  /**
   * Load contract types available for contract's center.
   */
  useEffect(() => {
    getContractTypes();
  }, [state.form.centerId]);

  /*** BACKEND CALLS ***/
  /* GET */
  const getContract = () => {
    let params = {
      include: [
        "Box",
        "CancellationRequest",
        "Center",
        "Customer",
        "Issuer",
        "Merchantable",
        "Promotion",
        "User",
      ],
    };

    api
      .get("/contracts/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          // Extract properties using object destructuring
          const { Box, CancellationRequests, Customer, Merchantables } =
            response.data;

          // Assign properties with new names
          response.data.centerId = Box.centerId;
          response.data.center = Box.Center;

          if (user.hasAction("VIEW_MERCHANTABLES")) {
            // Calculate and update the "totalAmount" property for each merchantable
            Merchantables.forEach((merchantable) => {
              const units = merchantable.units || 1;
              const pricePerUnit = merchantable.pricePerUnit;
              const vatPercentage = merchantable.vatPercentage / 100;

              // Calculate total amount
              const totalAmount = localeFormat(
                units * pricePerUnit + units * pricePerUnit * vatPercentage
              );

              // Assign the calculated total amount to the merchantable object
              merchantable.totalAmount = totalAmount;
            });

            // Sort merchantables by ascending start date
            Merchantables.sort((a, b) => a.startDate < b.startDate);
          }

          // Check if contract has unpaid invoices
          const hasUnpaidInvoices = Merchantables?.flatMap(
            (merch) => merch.InvoiceItems ?? []
          ).some(
            (invoice) => invoice.Invoice.state === UNPAID_INVOICE_STATE_ID
          );
          if (hasUnpaidInvoices) {
            dispatch({
              type: "SET_HAS_UNPAID_INVOICES",
              payload: true,
            });
          }
          dispatch({ type: "SET_CONTRACT", payload: response.data });

          dispatch({
            type: "SET_FORMS_OF_PAY",
            payload: {
              cards: Customer?.PayCards,
              ibans: Customer?.IBANs,
            },
          });

          dispatch({
            type: "SET_ISSUERS",
            payload: response.data.center.Issuers,
          });

          dispatch({
            type: "SET_CANCELLATION_REQ_PENDING",
            payload: CancellationRequests?.find(
              (request) => request.approved === null
            ),
          });

          // TODO: mover al abrir dialog
          if (response.data.hasSignedFile) getSignedFile();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContractTypes = () => {
    if (state.form.centerId) {
      api
        .get("/centers/" + state.form.centerId, { include: ["ContractType"] })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({
              type: "SET_CONTRACT_TYPES",
              payload: response.data.ContractTypes,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const getCancellationTypes = () => {
    api
      .get("/contracts/cancellations/types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          let days = response.data.map((day) => {
            return day.day;
          });
          dispatch({ type: "SET_PAYMENT_DAYS", payload: days });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const downloadContract = () => {
    dispatch({
      type: "SET_ICON_LOADING_STATE",
      payload: { inputname: "printContract", value: true },
    });

    api
      .get("/contracts/" + id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          downloadFile(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_ICON_LOADING_STATE",
          payload: { inputname: "printContract", value: false },
        });
      });
  };

  const getSignedFile = () => {
    api
      .get("/contracts/" + id + "/signed-file", { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_SIGNED_FILE", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* POST */
  const submitForm = () => {
    if (validateForm()) {
      let form = state.form;
      form.customerId = state.form.Customer?.id;
      form.ibanId =
        state.form.paymentMethod == RECEIPT_PAYMENT_METHOD_ID
          ? state.form.ibanId
          : null;
      if (
        state.form.paymentMethod == PAYCARD_PAYMENT_METHOD_ID ||
        state.form.paymentMethod == TOKENIZED_PAYCARD_PAYMENT_METHOD_ID
      ) {
        form.paycardId = state.form.paycardId;
        form.paymentMethod = state.form.paymentMethod;
      }
      dispatch({ type: "SET_LOADING" });
      api
        .post("/contracts/edit/" + id, form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("contractEditSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_LOADING" });
        });
    }
  };

  const setContractFrozenState = () => {
    const form = {
      freeze: state.form.isFrozen ? false : true,
    };

    dispatch({
      type: "SET_ICON_LOADING_STATE",
      payload: { inputname: "freezeContract", value: true },
    });

    api
      .post("/contracts/" + state.contract.id + "/freeze", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            response.data.isFrozen
              ? t("frozenContract")
              : t("unfrozenContract"),
            { variant: "success" }
          );
          dispatch({
            type: "SET_CONTRACT_FREEZE_STATUS",
            payload: response.data.isFrozen,
          });
          handleOpenStatusFreezeConfirmDialog(false);
        }
      })
      .finally(() => {
        dispatch({
          type: "SET_ICON_LOADING_STATE",
          payload: { inputname: "freezeContract", value: false },
        });
      });
  };

  const resumeContract = () => {
    dispatch({
      type: "SET_ICON_LOADING_STATE",
      payload: { inputname: "resumeContract", value: true },
    });

    api
      .post(`/contracts/${state.form.id}/resume`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          getContract();
          enqueueSnackbar(t("contractResumedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_ICON_LOADING_STATE",
          payload: { inputname: "resumeContract", value: false },
        });
      });
  };

  const submitCardForm = (card) => {
    let form = {
      cvv: null,
      name: card.name,
      number: card.number,
      expirationMonth: card.expiry.substring(0, 2),
      expirationYear: card.expiry.substring(3),
      customerId: state.form.Customer?.id,
    };

    api
      .post("/pay-cards/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("cardCreatedSuccessfully"), { variant: "success" });
          dispatch({
            type: "SET_FORMS_OF_PAY",
            payload: {
              cards: [...state.cards, response.data],
              ibans: state.ibans,
            },
          });
          handleCloseCardModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createIban = (number) => {
    const ibanRepeated = state.ibans.some((iban) => iban.number === number);

    if (ibanRepeated) {
      enqueueSnackbar(t("ibanRepeated"), { variant: "error" });
    } else {
      dispatch({ type: "SET_ADD_IBAN_LOADING", payload: true });
      let form = {
        number: number,
        customerId: state.form.Customer?.id,
      };

      api
        .post("/ibans/create", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("ibanCreatedSuccessfully"), {
              variant: "success",
            });
            dispatch({
              type: "SET_FORMS_OF_PAY",
              payload: {
                cards: state.cards,
                ibans: [...state.ibans, response.data.iban],
              },
            });
            dispatch({ type: "SET_NEW_IBAN", payload: "" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_ADD_IBAN_LOADING", payload: false });
        });
    }
  };

  const createCancellationRequest = () => {
    if (state.pendingCancellationRequest) {
      return enqueueSnackbar(
        t("cancellationRequestPendingForContractWarning"),
        {
          variant: "warning",
        }
      );
    }
    let form = state.request;
    form.contractId = id;
    form.requestedBy = user.id;
    form.approved = null;

    handleSetInputRequestLoading(true);
    api
      .post("/contracts/cancellation-requests/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("cancellationRequestSuccess"), {
            variant: "success",
          });
          dispatch({
            type: "SET_CANCELLATION_REQ_PENDING",
            payload: response.data,
          });
          dispatch({ type: "RESET_REQUEST" });
          getContract();
          closeCancellationReqModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => handleSetInputRequestLoading(false));
  };

  const emailContract = () => {
    dispatch({
      type: "SET_ICON_LOADING_STATE",
      payload: { inputname: "emailPdf", value: true },
    });

    api
      .post("/contracts/" + id + "/send-email")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSent"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_ICON_LOADING_STATE",
          payload: { inputname: "emailPdf", value: false },
        });
      });
  };

  const cancelContract = () => {
    //TODO: Add field validation
    dispatch({
      type: "SET_CANCEL_INPUT",
      payload: {
        inputname: "loading",
        value: true,
      },
    });

    let form = {
      contractId: id,
      cancellationDate: state.cancelDialog.cancellationDate,
      comments:
        state.cancelDialog.comments.length > 0
          ? state.cancelDialog.comments
          : null,
      cancelledBy: user.id,
      cancellationType: state.cancelDialog.cancellationType,
      sendEmail: state.cancelDialog.sendEmail === "yes" ? true : false,
    };

    createMerchantables(state.cancelDialog.cancellationDate)
      .then(() => {
        api
          .post("/contracts/cancel-contract", form)
          .then((response) => {
            if (response.data.error) {
              enqueueSnackbar(response.data.error, { variant: "error" });
            } else {
              if (state.cancelDialog.cancellationType === 4) history.goBack();
              getContract();
              enqueueSnackbar(t("contractCancelled"), { variant: "success" });
              closeCancelDialog();
              if (response.data.warning) {
                enqueueSnackbar(
                  response.data.warning.msg +
                    " " +
                    response.data.warning.items
                      .map((merch) => "Merchantable id: " + merch.id)
                      .join("; "),
                  { variant: "warning" }
                );
              }
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: "error" });
          })
          .finally(() => {
            dispatch({
              type: "SET_CANCEL_INPUT",
              payload: {
                inputname: "loading",
                value: false,
              },
            });
          });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createMerchantables = (date) => {
    return new Promise((resolve, reject) => {
      api
        .post("/invoicing/create-merchantables", {
          contracts: [id],
          date: date,
        })
        .then((res) => {
          if (res.data.error) {
            reject(res.data.error);
          } else {
            resolve(res.data);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          reject(error);
        });
    });
  };

  const uploadPdf = () => {
    let formData = new FormData();
    formData.append(
      "contractPdf",
      new File(state.fileModal.files, "contractPdf")
    );

    dispatch({
      type: "SET_ICON_LOADING_STATE",
      payload: { inputname: "uploadSignedContract", value: true },
    });

    api
      .post("/contracts/" + id + "/signed-file", formData)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("fileUploadedSuccessfully"), {
            variant: "success",
          });
          dispatch({
            type: "SET_INPUT",
            payload: { inputname: "hasSignedFile", value: true },
          });
          getSignedFile();
        }

        dispatch({ type: "SET_FILE", payload: [] });

        closeFileModal();
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_ICON_LOADING_STATE",
          payload: { inputname: "uploadSignedContract", value: false },
        });
      });
  };

  const saveSignature = (uri) => {
    dispatch({ type: "SET_SIGNATURE_LOADING" });
    fetch(uri).then(async (response) => {
      //Convert uri to file
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();
      const file = new File([blob], "fileName", { contentType });

      //Send to backend
      const formData = new FormData();
      formData.append("signature", new File([file], "signature"));

      api
        .post("/contracts/" + id + "/signature", formData)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("signatureUploadedSuccessfully"), {
              variant: "success",
            });
            dispatch({
              type: "SET_INPUT",
              payload: { inputname: "hasDigitalSignature", value: true },
            });
          }
          closeSignatureModal();
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => dispatch({ type: "SET_SIGNATURE_LOADING" }));
    });
  };

  const requestDigitalSignatureEmail = () => {
    dispatch({ type: "SET_DISABLE_DIG_SIG_REQ", payload: true });

    api
      .post("/request-digital-signature/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("digitalSignatureEmailRequestSent"), {
            variant: "success",
          });
          closeSignatureModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_DISABLE_DIG_SIG_REQ", payload: false })
      );
  };

  const confirmCancellation = (cancellationDate) => {
    let form = {
      cancellationTypeId:
        state.pendingCancellationRequest?.CancellationType?.id,
      resolutionComments:
        state.confirmCancellationModal.resolutionComments !== ""
          ? state.confirmCancellationModal.resolutionComments
          : null,
      cancellationDate: cancellationDate,
      sendEmailCustomer:
        state.confirmCancellationModal.sendEmailCustomer === "true"
          ? true
          : false,
      sendEmailCenter:
        state.confirmCancellationModal.sendEmailCenter === "true"
          ? true
          : false,
    };
    dispatch({
      type: "SET_INPUT_CONFIRM_CANCELLATION",
      payload: { inputname: "loading", value: true },
    });
    createMerchantables(cancellationDate)
      .then(() => {
        api
          .post(
            "/contracts/cancellation-requests/" +
              state.pendingCancellationRequest.id +
              "/confirm",
            form
          )
          .then((response) => {
            if (response.data.error) {
              enqueueSnackbar(response.data.error, { variant: "error" });
            } else {
              if (state.pendingCancellationRequest?.CancellationType.id === 4)
                history.goBack();

              enqueueSnackbar(t("contractCancelled"), { variant: "success" });
              dispatch({ type: "SET_CANCELLATION_REQ_PENDING", payload: null });
              dispatch({
                type: "SET_INPUT",
                payload: {
                  inputname: "endDate",
                  value: cancellationDate,
                },
              });
              dispatch({
                type: "SET_INPUT",
                payload: { inputname: "cancelledBy", value: user.id },
              });
              dispatch({ type: "RESET_CANCELLATION_REQUEST_MODAL" });
              if (response.data.warning) {
                enqueueSnackbar(
                  response.data.warning.msg +
                    " " +
                    response.data.warning.items
                      .map((merch) => "Merchantable id: " + merch.id)
                      .join("; "),
                  { variant: "warning" }
                );
              }
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: "error" });
          });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_INPUT_CONFIRM_CANCELLATION",
          payload: { inputname: "loading", value: false },
        });
      });
  };

  const denyCancellation = () => {
    let form = {
      resolutionComments: state.confirmCancellationModal.resolutionComments,
      sendEmailCenter:
        state.confirmCancellationModal.sendEmailCenter === "true"
          ? true
          : false,
    };
    dispatch({
      type: "SET_INPUT_CONFIRM_CANCELLATION",
      payload: { inputname: "loading", value: true },
    });

    api
      .post(
        "/contracts/cancellation-requests/" +
          state.pendingCancellationRequest?.id +
          "/deny",
        form
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("cancellationRequestDeniedSuccessfully"), {
            variant: "success",
          });
          dispatch({ type: "SET_CANCELLATION_REQ_PENDING", payload: null });
          dispatch({ type: "RESET_CANCELLATION_REQUEST_MODAL" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_INPUT_CONFIRM_CANCELLATION",
          payload: { inputname: "loading", value: false },
        });
      });
  };

  /* DELETE */
  const deletePdf = () => {
    api
      .delete("/contracts/" + id + "/signed-file")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("fileDeletedSuccessfully"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: { inputname: "hasSignedFile", value: false },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /*** VALIDATIONS ***/
  const validateCancellation = (cancellationDate, isRequest) => {
    //TODO: Backend call should go in separate function
    api
      .get("/contracts/" + id + "/last-invoice-day")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const lastInvoiceDay = response.data;
          if (new Date(lastInvoiceDay) < new Date(cancellationDate)) {
            dispatch({
              type: "SET_CONFIRM_DIALOG",
              payload: {
                title: t("cancelConfirmation"),
                childrenText: t("cancellationWarning"),
                isOpen: true,
                callback: (confirmed) => {
                  confirmed &&
                    (isRequest
                      ? confirmCancellation(cancellationDate)
                      : cancelContract());
                  resetConfirmDialog();
                },
              },
            });
          } else
            isRequest
              ? confirmCancellation(cancellationDate)
              : cancelContract();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["startDate", "invoiceIssuerId"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputError(field, true);
        isValid = false;
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
      }
    });

    if (
      state.form.startDate &&
      state.form.endDate &&
      state.form.startDate > state.form.endDate
    ) {
      setInputError("endDate", true);
      isValid = false;
      enqueueSnackbar(t("endDateCannotBeEarlierThanStartDate"), {
        variant: "warning",
      });
    }

    const payMethod = Number(state.form.paymentMethod);
    if (
      payMethod === RECEIPT_PAYMENT_METHOD_ID &&
      (state.form.ibanId === "" || !state.form.ibanId)
    ) {
      enqueueSnackbar(t("youMustSelectIBAN"), { variant: "warning" });
      isValid = false;
    }

    if (
      (payMethod === PAYCARD_PAYMENT_METHOD_ID ||
        payMethod === TOKENIZED_PAYCARD_PAYMENT_METHOD_ID) &&
      (state.form.paycardId === "" || !state.form.paycardId)
    ) {
      enqueueSnackbar(t("youMustSelectPaycard"), { variant: "warning" });
      isValid = false;
    }
    return isValid;
  };

  /*** HANDLERS ***/
  const setInputError = (name, value) => {
    dispatch({
      type: "SET_INPUT_ERROR",
      payload: {
        inputname: name,
        value,
      },
    });
  };

  const handleInputChangeIban = (e) => {
    dispatch({
      type: "SET_NEW_IBAN",
      payload: e.target.value,
    });
  };

  const handleOpenCardModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "cardModal",
        value: true,
      },
    });
  };

  const handleOpenStatusFreezeConfirmDialog = (status) => {
    dispatch({ type: "CHANGE_STATE_FREEZE_REQ_MODAL", payload: status });
  };

  const handleOpenStatusResumeConfirmDialog = (status) => {
    dispatch({ type: "CHANGE_STATE_RESUME_REQ_MODAL", payload: status });
  };

  const handleCloseCardModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "cardModal",
        value: false,
      },
    });
  };

  const showCancelDialog = () => {
    dispatch({ type: "OPEN_CANCEL_DIALOG" });
  };

  const closeCancelDialog = () => {
    dispatch({ type: "CLOSE_CANCEL_DIALOG" });
    dispatch({ type: "RESET_CANCEL_INPUTS" });
  };

  const setSendEmail = (e) => {
    dispatch({ type: "SET_SEND_EMAIL", payload: e.target.value });
  };

  const setBillingAddressSelected = (billingAddressId) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "billingAddressId",
        value: billingAddressId,
      },
    });
  };

  const handleCancelChange = (e) => {
    dispatch({
      type: "SET_CANCEL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const openFileModal = () => {
    dispatch({ type: "OPEN_FILE_MODAL" });
  };

  const closeFileModal = () => {
    dispatch({ type: "CLOSE_FILE_MODAL" });
  };

  const handleFileChange = (e) => {
    dispatch({ type: "SET_FILE", payload: e.target.files });
  };

  /* UTILS */
  const seePdf = () => {
    const file = new Blob([state.signedFile], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const openSignatureModal = () => {
    dispatch({ type: "OPEN_SIGNATURE_MODAL" });
  };

  const closeSignatureModal = () => {
    dispatch({ type: "CLOSE_SIGNATURE_MODAL" });

    dispatch({ type: "SET_SIGNATURE_REQUEST", payload: "" });
  };

  const closeCancellationReqModal = () => {
    dispatch({ type: "CLOSE_CANCELLATION_REQ_MODAL" });
  };

  const openCancellationReqModal = () => {
    dispatch({ type: "OPEN_CANCELLATION_REQ_MODAL" });
  };

  const openFreezeReqModal = () => {
    dispatch({ type: "CHANGE_STATE_FREEZE_REQ_MODAL", payload: true });
  };

  const openResumeReqModal = () => {
    dispatch({ type: "CHANGE_STATE_RESUME_REQ_MODAL", payload: true });
  };

  const closeConfirmCancellationModal = () => {
    dispatch({ type: "CLOSE_CONFIRM_CANCELLATION_MODAL" });
  };

  const openConfirmCancellationModal = () => {
    const cancellationDate = state.contract.CancellationRequests?.find(
      (request) => request.resolutionDate === null
    )?.cancellationDate;

    dispatch({
      type: "SET_INPUT_CONFIRM_CANCELLATION",
      payload: {
        inputname: "cancellationDate",
        value: cancellationDate
          ? cancellationDate
          : state.request.cancellationDate,
      },
    });
    dispatch({ type: "OPEN_CONFIRM_CANCELLATION_MODAL" });
  };

  const handleInputConfirmCancellation = (e) => {
    dispatch({
      type: "SET_INPUT_CONFIRM_CANCELLATION",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChange = (e) => {
    setInputError(e.target.name, false);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
    if (e.target.name === "paymentMethod") {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "paycardId",
          value: "",
        },
      });
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "ibanId",
          value: "",
        },
      });
    }
  };

  const handleSetInputRequestLoading = (loading) => {
    dispatch({
      type: "SET_INPUT_REQUEST",
      payload: { inputname: "loading", value: loading },
    });
  };

  const handleInputRequest = (e) => {
    if (e.target.name == "cancellationDate") {
      const term = new Date();
      term.setDate(term.getDate() + 7);
      if (new Date(e.target.value) < term)
        dispatch({
          type: "SET_INPUT_REQUEST",
          payload: { inputname: "alert", value: true },
        });
      else
        dispatch({
          type: "SET_INPUT_REQUEST",
          payload: { inputname: "alert", value: false },
        });

      if (formatDate(new Date()) > formatDate(new Date(e.target.value)))
        dispatch({
          type: "SET_INPUT_REQUEST",
          payload: { inputname: "alert2", value: true },
        });
      else
        dispatch({
          type: "SET_INPUT_REQUEST",
          payload: { inputname: "alert2", value: false },
        });
    }

    // Checks for alert 3
    if (e.target.name == "cancellationTypeId") {
      // Check if cancellation type is "no llega a entrar"
      if (e.target.value == NO_ENTER_CANCELLATION_TYPE_ID) {
        // Check if merchantables are invoiced (invoice is paid)
        const allInvoiced = state.form.Merchantables.some(
          (merchantable) => merchantable.InvoiceItems
        );
        dispatch({
          type: "SET_INPUT_REQUEST",
          payload: { inputname: "alert3", value: allInvoiced },
        });
      }
    }
    dispatch({
      type: "SET_INPUT_REQUEST",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({ type: "SET_CONFIRM_DIALOG", payload: state });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  return (
    <Page paper={true} maxWidth="lg" browserTitle={t("contractPage")}>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item flexGrow={1}>
            <Typography variant="h4">{t("editContract")}</Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                `${t("contractCreatedBy")} ${state.form.creator?.name} ${t(
                  "the"
                )} ${state.form.createdAt?.split("T")[0]} ${t("at")} ${
                  state.form.createdAt?.split("T")[1].split(".")[0]
                }` +
                ". " +
                (state.form.cancelledBy && state.form?.endDate
                  ? `${t("cancelledBy")} ${state.form.cancellator?.name} ${t(
                      "the"
                    )} ${state.form?.endDate}.`
                  : "")
              }
              placement="bottom"
            >
              <IconButton>
                <HelpCenterIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t("uploadSignedContract")} placement="top">
              <IconButton
                color="primary"
                onClick={openFileModal}
                disabled={state.iconsLoadingState.uploadSignedContract}
              >
                {state.iconsLoadingState.uploadSignedContract ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={15} />
                  </Box>
                ) : (
                  <Badge
                    variant={state.form.hasSignedFile ? "dot" : ""}
                    color="success"
                  >
                    <UploadFileIcon />
                  </Badge>
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title={t("digitalSignature")} placement="top">
              <IconButton
                size="large"
                color="primary"
                onClick={openSignatureModal}
                disabled={state.signatureModal.loading}
              >
                {state.signatureModal.loading ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={15} />
                  </Box>
                ) : (
                  <Badge
                    variant={state.form.hasDigitalSignature ? "dot" : ""}
                    color="success"
                  >
                    <Fingerprint />
                  </Badge>
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title={t("printContract")} placement="top">
              <IconButton
                color="primary"
                onClick={downloadContract}
                disabled={state.iconsLoadingState.printContract}
              >
                {state.iconsLoadingState.printContract ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={15} />
                  </Box>
                ) : (
                  <PrintIcon />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title={t("emailPdf")} placement="top">
              <IconButton
                color="primary"
                onClick={emailContract}
                disabled={state.iconsLoadingState.emailPdf}
              >
                {state.iconsLoadingState.emailPdf ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={15} />
                  </Box>
                ) : (
                  <EmailIcon />
                )}
              </IconButton>
            </Tooltip>

            {user.hasAction("FREEZE_CONTRACT") &&
              state.form.cancelledBy === null && (
                <Tooltip
                  title={
                    !state.form.isFrozen
                      ? t("freezeContract")
                      : t("unfreezeContract")
                  }
                  placement="top"
                >
                  <IconButton
                    color="primary"
                    onClick={openFreezeReqModal}
                    disabled={state.iconsLoadingState.freezeContract}
                  >
                    {state.iconsLoadingState.freezeContract ? (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress size={15} />
                      </Box>
                    ) : (
                      <AcUnitRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            {user.hasAction("RESUME_CONTRACTS") &&
              state.form.cancelledBy !== null && (
                <Tooltip
                  title={t("resumeContract")}
                  placement="top"
                  disabled={!state.form.endDate}
                >
                  <IconButton
                    color="primary"
                    onClick={openResumeReqModal}
                    disabled={state.iconsLoadingState.resumeContract}
                  >
                    {state.iconsLoadingState.resumeContract ? (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress size={15} />
                      </Box>
                    ) : (
                      <AutorenewIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}

            {Number(state.form.state) !== ENDED_CONTRACT_STATE_ID &&
              user.hasAction("REQUEST_CANCELLATION_REQUESTS") && (
                <Tooltip
                  title={t("requestCancellation")}
                  placement="top"
                  disabled={
                    state.pendingCancellationRequest ||
                    state.form.endDate ||
                    state.request.loading
                      ? true
                      : false
                  }
                >
                  <IconButton color="error" onClick={openCancellationReqModal}>
                    {state.request.loading ? (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress size={15} />
                      </Box>
                    ) : (
                      <UnsubscribeRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}

            {user.hasAction("CANCEL_CONTRACTS") && (
              <Tooltip title={t("cancelContract")} placement="top">
                <IconButton
                  disabled={Boolean(
                    state.pendingCancellationRequest ||
                      state.form.endDate ||
                      state.cancelDialog.loading
                  )}
                  color="error"
                  onClick={showCancelDialog}
                >
                  {state.cancelDialog.loading ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress size={15} />
                    </Box>
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid container item spacing={3} xs={12}>
          <Grid item>
            <Typography variant="h5">ID: {state.form.publicId}</Typography>
          </Grid>
          <Grid item>
            <ContractStateChip
              state={state.form.state}
              endDate={state.form.endDate}
            />
          </Grid>

          {state.contract?.Promotion && (
            <Grid item>
              <Tooltip
                title={state.contract.Promotion.description}
                placement="top"
              >
                <Chip
                  label={state.contract.Promotion.name}
                  color="yellow"
                  onClick={() => {}}
                />
              </Tooltip>
            </Grid>
          )}

          {state.form?.isFrozen && (
            <Grid item>
              <Chip
                icon={<AcUnitRoundedIcon style={{ color: "#ffffff" }} />}
                style={{ backgroundColor: "#4DB9E8", color: "#ffffff" }}
                label={t("frozenContract")}
                onClick={
                  user.hasAction("FREEZE_CONTRACT") &&
                  state.form.cancelledBy === null
                    ? openFreezeReqModal
                    : null
                }
              />
            </Grid>
          )}

          {state.pendingCancellationRequest && (
            <Grid item>
              <Chip
                icon={<WarningAmberIcon />}
                color="warning"
                label={t("cancellationReqPending")}
                onClick={openConfirmCancellationModal}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} item xs={12}>
          <Grid
            item
            container
            xs={12}
            columnSpacing={4}
            rowSpacing={2}
            alignItems="center"
          >
            <Grid item>
              {user.hasAction("EDIT_CONTRACT_DATES") ? (
                <DateInput
                  label={t("startDate")}
                  name="startDate"
                  onChange={handleInputChange}
                  value={state.form.startDate}
                  error={state.inputError.startDate}
                  helperText={
                    state.inputError.startDate
                      ? t("startDate") + " " + t("isRequired")
                      : ""
                  }
                />
              ) : (
                <LabeledText
                  label={t("startDate")}
                  value={state.form.startDate}
                />
              )}
            </Grid>

            <Grid item>
              {user.hasAction("EDIT_CONTRACT_DATES") && state.form.endDate ? (
                <DateInput
                  disableClearable
                  label={t("endDate")}
                  name="endDate"
                  onChange={(e) => {
                    if (!e.target.value) openResumeReqModal();
                    handleInputChange(e);
                  }}
                  value={state.form.endDate}
                  error={state.inputError.endDate}
                  helperText={
                    state.inputError.endDate
                      ? t("endDate") + " " + t("isRequired")
                      : ""
                  }
                />
              ) : (
                <LabeledText
                  label={t("endDate")}
                  value={state.form.endDate || "---"}
                />
              )}
            </Grid>
            <Grid item>
              <LabeledText
                label={t("periodicity")}
                value={t(state.contract.Periodicity?.name)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} columnSpacing={4} rowSpacing={1}>
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {t("center") + ": "}
                <ButtonLink
                  to={"/app/center/" + state.contract?.Box?.Center?.id}
                >
                  {state.contract?.Box?.Center?.name}
                </ButtonLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {t("box") + ": "}
                <ButtonLink to={"/app/box/" + state.contract?.Box?.id}>
                  {state.contract?.Box?.name}
                </ButtonLink>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {t("customer") + ": "}
                <ButtonLink to={"/app/customer/" + state.contract.Customer?.id}>
                  {state.contract?.Customer?.fullName}
                </ButtonLink>
                {state.contract?.Customer?.fullName && (
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        state.contract?.Customer?.fullName
                      );
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent="center" rowSpacing={2}>
            <ContractPricingDetails
              contract={state.form}
              onChange={handleInputChange}
            />
          </Grid>

          {user.hasAction("EDIT_CONTRACTS") ? (
            <Grid item xs={12} md={4}>
              <Select
                autoWidth
                value={state.form.contractTypeId}
                name="contractTypeId"
                onChange={handleInputChange}
                label={t("contractType")}
                options={state.contractTypes?.map((contractType) => ({
                  value: contractType.id,
                  label: contractType.name,
                }))}
                disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
              />
            </Grid>
          ) : (
            <Grid item>
              <LabeledText
                label={t("contractType")}
                value={
                  state.form.contractTypeId
                    ? state.contractTypes.find(
                        (type) => type.id === state.form.contractTypeId
                      )?.name
                    : "---"
                }
              />
            </Grid>
          )}
        </Grid>

        {user.hasAction("EDIT_CONTRACT_COMMENTS") ? (
          <Grid item xs={12}>
            <TextInput
              error={state.inputError.comments}
              helperText={
                state.inputError.comments ? t("commentsMustNotBeBlank") : ""
              }
              label={t("comments")}
              multiline
              rows={4}
              value={state.form.comments}
              onChange={handleInputChange}
              name="comments"
              disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <LabeledText label={t("comments")} value={state.form.comments} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{t("paymentMethod")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="paymentMethod"
              value={Number(state.form.paymentMethod)}
              onChange={(e) => {
                e.target.value = Number(e.target.value);
                handleInputChange(e);
              }}
            >
              {paymentMethods.map((method) => (
                <FormControlLabel
                  key={method.id}
                  value={method.id}
                  control={<Radio />}
                  label={method.label}
                  disabled={
                    state.form.state == ENDED_CONTRACT_STATE_ID ||
                    !user.hasAction("EDIT_CONTRACTS")
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        {Number(state.form.paymentMethod) === RECEIPT_PAYMENT_METHOD_ID &&
          user.hasAction("EDIT_CONTRACTS") && (
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <CustomSelect
                  value={state.form.ibanId}
                  name="ibanId"
                  onChange={handleInputChange}
                  label={t("ibans")}
                  options={[
                    { value: "", label: t("none") },
                    ...state.ibans.map((iban) => ({
                      value: iban.id,
                      label: iban.number,
                    })),
                  ]}
                  disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label={t("newIban")}
                  name="newIban"
                  value={state.newIban}
                  onChange={handleInputChangeIban}
                  disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  loading={state.addIbanLoading}
                  loadingText=""
                  onClick={() => {
                    createIban(state.newIban);
                  }}
                  disabled={
                    state.form.state == ENDED_CONTRACT_STATE_ID ||
                    !state.newIban
                  }
                >
                  {t("add")}
                </Button>
              </Grid>
            </Grid>
          )}

        {Number(state.form.paymentMethod) === PAYCARD_PAYMENT_METHOD_ID &&
          user.hasAction("EDIT_CONTRACTS") && (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  value={state.form.paycardId}
                  name="paycardId"
                  onChange={handleInputChange}
                  label={t("card")}
                  options={[
                    { value: "", label: t("none") },
                    ...state.cards
                      .filter((c) => c.isToken !== true)
                      .map((card) => ({
                        value: card.id,
                        label:
                          card.number +
                          " - " +
                          card.expirationMonth +
                          "/" +
                          card.expirationYear,
                      })),
                  ]}
                  disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  onClick={handleOpenCardModal}
                  disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
                >
                  {t("addCreditCard")}
                </Button>
              </Grid>

              {/* Credit card modal */}
              <Dialog
                open={state.cardModal.isOpen}
                onClose={handleCloseCardModal}
                aria-labelledby="form-dialog-title"
              >
                <IconButton onClick={handleCloseCardModal}>
                  <CloseIcon />
                </IconButton>
                <DialogContent>
                  <CreditCardForm onSubmit={submitCardForm} />
                </DialogContent>
              </Dialog>
            </Grid>
          )}

        {Number(state.form.paymentMethod) ===
          TOKENIZED_PAYCARD_PAYMENT_METHOD_ID &&
          user.hasAction("EDIT_CONTRACTS") && (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  value={state.form.paycardId}
                  name="paycardId"
                  onChange={handleInputChange}
                  label={t("tokenizedPaycard")}
                  options={[
                    { value: "", label: t("none") },
                    ...state.cards
                      .filter((c) => c.isToken === true)
                      .map((card) => ({
                        value: card.id,
                        label: card.mask,
                      })),
                  ]}
                  disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
                />
              </Grid>
            </Grid>
          )}

        {user.hasAction("EDIT_CONTRACTS") ? (
          <Grid item container xs={12} spacing={2}>
            {user.hasAction("EDIT_CONTRACT_PAYMENT_DAY") && (
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  name="dateOfPayment"
                  onChange={handleInputChange}
                  label={t("dateOfPayment")}
                  value={state.form.dateOfPayment}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  options={[
                    { value: null, label: t("none") },
                    ...state.paymentDays?.map((day) => ({
                      value: day,
                      label: day,
                    })),
                  ]}
                  disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <CustomSelect
                error={state.inputError.invoiceIssuerId}
                helperText={
                  state.inputError.invoiceIssuerId
                    ? t("issuerMustNotBeBlank")
                    : ""
                }
                label={t("issuer")}
                name="invoiceIssuerId"
                value={state.form.invoiceIssuerId}
                onChange={handleInputChange}
                options={[
                  { value: "", label: t("none") },
                  ...state.issuers.map((issuer) => ({
                    value: issuer.id,
                    label: issuer.name,
                  })),
                ]}
                disabled={state.form.state == ENDED_CONTRACT_STATE_ID}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={12} spacing={4}>
            {Number(state.form.paymentMethod) === RECEIPT_PAYMENT_METHOD_ID &&
              !user.hasAction("EDIT_CONTRACTS") && (
                <Grid item>
                  <LabeledText
                    label={t("iban")}
                    value={
                      state.form.ibanId
                        ? state.ibans.find(
                            (iban) => iban.id === state.form.ibanId
                          )?.number
                        : "---"
                    }
                  />
                </Grid>
              )}
            {Number(state.form.paymentMethod) === PAYCARD_PAYMENT_METHOD_ID &&
              !user.hasAction("EDIT_CONTRACTS") && (
                <Grid item>
                  <LabeledText
                    label={t("card")}
                    value={
                      state.form.paycardId
                        ? state.cards
                            .find((card) => card.id === state.form.paycardId)
                            ?.number.replace(
                              state.cards
                                .find(
                                  (card) => card.id === state.form.paycardId
                                )
                                ?.number.substring(
                                  0,
                                  state.cards.find(
                                    (card) => card.id === state.form.paycardId
                                  )?.number.length - 4
                                ),
                              "**** **** **** "
                            )
                        : "---"
                    }
                  />
                </Grid>
              )}
            <Grid item>
              <LabeledText
                label={t("dateOfPayment")}
                value={state.form.dateOfPayment || "---"}
              />
            </Grid>
            <Grid item>
              <LabeledText
                label={t("issuer")}
                value={
                  state.form.invoiceIssuerId
                    ? state.issuers.find(
                        (issuer) => issuer.id === state.form.invoiceIssuerId
                      )?.name
                    : "---"
                }
              />
            </Grid>
          </Grid>
        )}

        {user.hasAction("EDIT_INVOICE_COMMENTS") && (
          <Grid item xs={12}>
            <TextInput
              label={t("invoiceComments")}
              multiline
              rows={4}
              name="invoiceComments"
              onChange={handleInputChange}
              value={state.form.invoiceComments || ""}
            />
          </Grid>
        )}

        {user.hasAction("VIEW_MERCHANTABLES") && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">{t("merchantables")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTable
                columns={MERCHANTABLE_COLUMNS}
                data={
                  state.form?.Merchantables?.sort(
                    (a, b) => a.startDate < b.startDate
                  ) || []
                }
                options={
                  user.hasPage("MERCHANTABLES") ? state.merchOptions : {}
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{t("billingAddress")}</Typography>
        </Grid>

        <Grid
          container
          item
          direction="column"
          xs={12}
          columnSpacing={2}
          marginLeft={3}
          sx={{
            overflowX: "auto",
            maxHeight: 300,
            paddingBottom: 2,
          }}
        >
          {state.contract.Customer?.BillingAddresses?.map((address) => (
            <Grid item key={address.id}>
              <BillingAddressCard
                billingAddress={address}
                setAsSelected={setBillingAddressSelected}
                isSelected={state.form.billingAddressId === address.id}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{t("hubSpot")}</Typography>
        </Grid>

        <Grid item container xs={12} spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <HubSpotDeals
              contract={state.form}
              hubSpotContactIds={hubSpotContactIds}
              handleInputChange={handleInputChange}
            />
          </Grid>
          {state.form.hubSpotDealId && (
            <Grid item xs={12} md={6}>
              <Tooltip title={t("openHubSpotDeal")} arrow>
                <Link href={state.form.hubSpotDealLink} target="_blank">
                  <LinkIcon />
                </Link>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        {user.hasAction("VIEW_CANCELLATION_REQUESTS") &&
          state.contract?.CancellationRequests?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("cancellationRequests")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  padding={1}
                  sx={{ overflowX: "auto" }}
                  marginBottom={1}
                >
                  {state.contract.CancellationRequests.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  ).map((request, index) => (
                    <CancellationRequestCard request={request} key={index} />
                  ))}
                </Stack>
              </Grid>
            </>
          )}

        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button variant="text" onClick={() => history.goBack()}>
              {t("back")}
            </Button>
          </Grid>
          {(user.hasAction("EDIT_CONTRACTS") ||
            user.hasAction("EDIT_CONTRACT_COMMENTS")) && (
            <Grid item>
              <CustomButton onClick={submitForm} loading={state.loading}>
                {t("save")}
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* File modal */}
      <Dialog open={state.fileModal.isOpen} onClose={closeFileModal}>
        <DialogActions>
          <Typography variant="h6">{t("uploadSignedContract")}</Typography>
          <IconButton onClick={closeFileModal}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          {!state.form.hasSignedFile && (
            <Grid container spacing={1}>
              <Grid item xs={12} justifyContent="center">
                <Typography variant="body2">
                  {state.fileModal.files[0] && state.fileModal.files[0].name}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<FileOpenIcon />}
                  component="label"
                  xs={6}
                >
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept="application/pdf"
                  />
                  {t("selectFile")}
                </Button>
              </Grid>
              {state.fileModal.files[0] && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={uploadPdf}
                    startIcon={<CloudUploadIcon />}
                    xs={6}
                  >
                    {t("upload")}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          {state.form.hasSignedFile && (
            <Grid item>
              <ButtonGroup>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={seePdf}
                  xs={4}
                >
                  {t("see")}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={() => downloadFile(state.signedFile)}
                  xs={4}
                >
                  {t("download")}
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  startIcon={<DeleteForeverIcon />}
                  onClick={deletePdf}
                  xs={4}
                >
                  {t("delete")}
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      {/* Confirm freeze request modal */}
      <ConfirmDialog
        title={
          state.form.isFrozen
            ? t("confirmUnfreezeTitle")
            : t("confirmFreezeTitle")
        }
        open={state.freezeReqModal.isOpen}
        setOpen={handleOpenStatusFreezeConfirmDialog}
        onConfirm={(confirm) => {
          confirm && setContractFrozenState();
        }}
      >
        <Alert severity="warning">
          {state.form.isFrozen ? t("confirmUnfreeze") : t("confirmFreeze")}
        </Alert>
      </ConfirmDialog>

      {/* Contract cancellation dialog */}
      <Dialog open={state.cancelDialog.isOpen} onClose={closeCancelDialog}>
        <DialogActions>
          <IconButton onClick={closeCancelDialog}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Collapse in={state.hasUnpaidInvoices}>
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t("theContractHasUnpaidInvoices")}
            </Alert>
          </Collapse>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={10}>
                <Typography variant="body1">
                  {t("confirmCancellation")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ToggleButtonGroup
                  value={state.cancelDialog.cancelContract}
                  onChange={handleCancelChange}
                  exclusive
                >
                  <ToggleButton
                    name="cancelContract"
                    value="no"
                    color={
                      state.cancelDialog.cancelContract === "no"
                        ? "error"
                        : "primary"
                    }
                  >
                    {t("no")}
                  </ToggleButton>
                  <ToggleButton
                    name="cancelContract"
                    value="yes"
                    color={
                      state.cancelDialog.cancelContract === "yes"
                        ? "success"
                        : "primary"
                    }
                  >
                    {t("yes")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={10}>
                <Typography variant="body1">{t("confirmSendEmail")}</Typography>
              </Grid>
              <Grid item xs={2}>
                <ToggleButtonGroup
                  value={state.cancelDialog.sendEmail}
                  exclusive
                  onChange={setSendEmail}
                  color={
                    state.cancelDialog.sendEmail === "yes" ? "success" : "error"
                  }
                >
                  <ToggleButton value="no">{t("no")}</ToggleButton>
                  <ToggleButton value="yes">{t("yes")}</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <CustomSelect
                value={state.cancelDialog.cancellationType}
                onChange={handleCancelChange}
                label={t("cancellationType")}
                name="cancellationType"
                options={[
                  { value: "", label: t("none") },
                  ...state.cancellationTypes.map((type) => ({
                    value: type.id,
                    label: t(type.name),
                  })),
                ]}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomDate
                value={state.cancelDialog.cancellationDate}
                onChange={handleCancelChange}
                label={t("cancellationDate")}
                name="cancellationDate"
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                value={state.cancelDialog.comments}
                onChange={handleCancelChange}
                label={t("comments")}
                name="comments"
                multiline
              />
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  disabled={
                    state.cancelDialog.cancelContract === "no" ||
                    state.cancelDialog.cancellationDate === "" ||
                    state.cancelDialog.cancellationType === "" ||
                    state.cancelDialog.loading
                  }
                  onClick={() =>
                    validateCancellation(
                      state.cancelDialog.cancellationDate,
                      false
                    )
                  }
                >
                  {t("cancelContract")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Signature modal */}
      <Dialog open={state.signatureModal.isOpen} close={closeSignatureModal}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              <Typography variant="h6">{t("digitalSignature")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={closeSignatureModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {state.form.hasDigitalSignature == true &&
            state.signatureModal.request === "" && (
              <img src={SERVER + "/contract-signatures/" + id + ".png"}></img>
            )}
          <Grid item container>
            {state.signatureModal.request === "" && (
              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    startIcon={<BrushIcon />}
                    fullWidth
                    onClick={() =>
                      dispatch({
                        type: "SET_SIGNATURE_REQUEST",
                        payload: false,
                      })
                    }
                  >
                    {state.form.hasDigitalSignature ? t("re-sign") : t("sign")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    startIcon={<ForwardToInboxIcon />}
                    onClick={requestDigitalSignatureEmail}
                    disabled={state.disableDigSigReq}
                    fullWidth
                  >
                    {state.form.hasDigitalSignature
                      ? t("reapply")
                      : t("request")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {state.signatureModal.request === false && (
              <ContractSignature
                loading={state.signatureModal.loading}
                onSave={saveSignature}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Create cancellation request modal  */}
      <Dialog
        open={state.cancellationReqModal.isOpen}
        close={closeCancellationReqModal}
      >
        <DialogActions>
          <IconButton onClick={closeCancellationReqModal}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Collapse in={state.request.alert}>
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t("requestCancellationWarning")}
            </Alert>
          </Collapse>
          <Collapse in={state.request.alert2}>
            <Alert severity="error" sx={{ mb: 2 }}>
              {t("requestCancellationError")}
            </Alert>
          </Collapse>
          <Collapse in={state.hasUnpaidInvoices}>
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t("theContractHasUnpaidInvoices")}
            </Alert>
          </Collapse>
          <Collapse in={state.request.alert3}>
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t("invoicedMerchantablesWarning")}
            </Alert>
          </Collapse>
          <RequestCancellationForm
            request={state.request}
            contract={{
              startDate: state.form.startDate,
              periodicity: state.contract.Periodicity?.days,
            }}
            onChange={handleInputRequest}
            onSubmit={createCancellationRequest}
            loading={state.request.loading}
          />
        </DialogContent>
      </Dialog>

      {/* Confirm cancellation request modal  */}
      <Dialog
        open={state.confirmCancellationModal.isOpen}
        close={closeConfirmCancellationModal}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("cancellationRequest")}</Grid>
            <Grid item>
              <IconButton onClick={closeConfirmCancellationModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {user.hasAction("VIEW_CANCELLATION_REQUESTS") && (
              <>
                <Grid item xs={12}>
                  <Collapse in={state.hasUnpaidInvoices}>
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      {t("theContractHasUnpaidInvoices")}
                    </Alert>
                  </Collapse>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    label={t("cancellationType")}
                    name="cancellationTypeId"
                    value={
                      state.pendingCancellationRequest?.CancellationType?.id
                    }
                    onChange={(e) => {
                      dispatch({
                        type: "SET_CANCELLATION_REQ_PENDING",
                        payload: {
                          ...state.pendingCancellationRequest,
                          CancellationType: {
                            id: e.target.value,
                          },
                        },
                      });
                    }}
                    options={[
                      ...state.cancellationTypes.map((type) => ({
                        value: type.id,
                        label: type.name,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabeledText
                    label={t("requestedBy")}
                    value={
                      state.pendingCancellationRequest?.requestedByUser?.name ||
                      ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <LabeledText
                    label={t("createdAt")}
                    value={
                      state.pendingCancellationRequest?.createdAt
                        ? state.pendingCancellationRequest.createdAt.slice(
                            0,
                            10
                          ) +
                          " " +
                          t("at") +
                          " " +
                          state.pendingCancellationRequest?.createdAt.slice(
                            11,
                            16
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {t("date")}:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    value={state.confirmCancellationModal?.cancellationDate}
                    error={state.inputError.cancellationDate}
                    onChange={handleInputConfirmCancellation}
                    helperText={
                      state.inputError.cancellationDate
                        ? t("cancellationDateMustNotBeBlank")
                        : ""
                    }
                    label={t("cancellationDate")}
                    name="cancellationDate"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {t("comments")}:{" "}
                    <Typography
                      variant="body1"
                      component="span"
                      style={{ wordWrap: "break-word" }}
                    >
                      {state.pendingCancellationRequest?.comments}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
            {user.hasAction("RESOLVE_CANCELLATION_REQUESTS") && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    multiline
                    rows={4}
                    label={t("resolutionComments")}
                    value={state.confirmCancellationModal.resolutionComments}
                    onChange={handleInputConfirmCancellation}
                    name="resolutionComments"
                  />
                </Grid>
                <Grid item container xs={12} spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      {t("confirmSendEmailToCustomer")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <ToggleButtonGroup
                      value={state.confirmCancellationModal.sendEmailCustomer}
                      exclusive
                      onChange={handleInputConfirmCancellation}
                      color={
                        state.confirmCancellationModal.sendEmailCustomer ===
                        "true"
                          ? "success"
                          : "error"
                      }
                    >
                      <ToggleButton name="sendEmailCustomer" value="false">
                        {t("no")}
                      </ToggleButton>
                      <ToggleButton name="sendEmailCustomer" value="true">
                        {t("yes")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      {t("confirmSendEmailToDelegation")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <ToggleButtonGroup
                      value={state.confirmCancellationModal.sendEmailCenter}
                      exclusive
                      onChange={handleInputConfirmCancellation}
                      color={
                        state.confirmCancellationModal.sendEmailCenter ===
                        "true"
                          ? "success"
                          : "error"
                      }
                    >
                      <ToggleButton name="sendEmailCenter" value="false">
                        {t("no")}
                      </ToggleButton>
                      <ToggleButton name="sendEmailCenter" value="true">
                        {t("yes")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        {user.hasAction("RESOLVE_CANCELLATION_REQUESTS") && (
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={denyCancellation}
              disabled={state.confirmCancellationModal.loading}
              fullWidth
            >
              {t("denyCancellation")}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                validateCancellation(
                  state.confirmCancellationModal.cancellationDate,
                  true
                )
              }
              disabled={
                state.confirmCancellationModal.loading ||
                !state.confirmCancellationModal.cancellationDate
              }
              fullWidth
            >
              {t("confirmCancellation")}
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {/* Confirm resume request modal */}
      <ConfirmDialog
        title={t("confirmResumeContract")}
        open={state.resumeReqModal.isOpen}
        setOpen={handleOpenStatusResumeConfirmDialog}
        onConfirm={(confirm) => {
          confirm && resumeContract();
        }}
      />

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}

const MerchantableRowMenu = (props) => {
  const { user } = useContext(AppContext);
  const { value, isColor, isNames } = props;

  const [menu, setMenu] = useState({ anchorEl: null, open: false });

  return (
    <>
      <IconButton
        sx={{ px: 1 }}
        variant="text"
        onClick={(e) => {
          e.preventDefault();
          setMenu({ anchorEl: e.currentTarget, open: true });
        }}
      >
        <ListIcon
          onClick={(e) => {
            e.preventDefault();
            setMenu({ anchorEl: e.currentTarget, open: true });
          }}
        />
      </IconButton>
      <Menu
        anchorEl={menu.anchorEl}
        open={menu.open}
        onClose={(e) => {
          e.preventDefault();
          setMenu({ anchorEl: null, open: false });
        }}
        TransitionComponent={Fade}
      >
        {value.map((invoiceItem) => {
          return (
            <MenuItem sx={{ p: 0 }}>
              <ButtonLink
                to={`/app/invoice/${invoiceItem.Invoice.id}`}
                disabled={!user.hasPage("INVOICES")}
              >
                <Chip
                  key={invoiceItem.Invoice.number}
                  color={isColor[invoiceItem.Invoice.state]}
                  label={
                    isNames[invoiceItem.Invoice.serieId] +
                    invoiceItem.Invoice.number
                  }
                  size="small"
                />
              </ButtonLink>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
