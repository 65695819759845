import {
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

//Icons
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CheckIcon from "@mui/icons-material/Check";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import HelpIcon from "@mui/icons-material/Help";

import AppContext from "../../../context/AppContext";

import { CustomTable } from "../../CustomTable";
import { generateURL, getParams } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import { readString } from "react-papaparse";
import { useHistory, useLocation } from "react-router-dom";
import AuditForm from "./AuditForm";
import Button from "../../Inputs/CustomButton";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ConfirmDialog from "../../ConfirmDialog";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import Filters from "../../global/structure/Filters";
import ItemsSummary from "../../ItemsSummary";
import Page from "../../global/structure/Page";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";

import { UploadFile } from "@mui/icons-material";
import { Box } from "@mui/system";

import {
  firstDateCurrentMonth,
  formatDate,
  lastDateCurrentMonth,
} from "../../../utils/date";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_BANKING_TRANSACTIONS":
      return { ...state, bankingTransactions: action.payload };
    case "SET_BANK_ACCOUNTS":
      return { ...state, bankAccounts: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "OPEN_AUDIT_MODAL":
      return { ...state, auditModal: { ...state.auditModal, isOpen: true } };
    case "CLOSE_AUDIT_MODAL":
      return { ...state, auditModal: { ...state.auditModal, isOpen: false } };
    case "SET_MODAL_TRANSACTION":
      return {
        ...state,
        auditModal: { ...state.auditModal, transaction: action.payload },
      };

    //Upload file modal
    case "OPEN_FILE_MODAL":
      return { ...state, fileModal: { ...state.fileModal, isOpen: true } };
    case "CLOSE_FILE_MODAL":
      return { ...state, fileModal: { ...state.fileModal, isOpen: false } };
    case "SET_FILE_MODAL_INPUT":
      return {
        ...state,
        fileModal: {
          ...state.fileModal,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_FILE_MODAL_FILE":
      return {
        ...state,
        fileModal: { ...state.fileModal, file: action.payload },
      };
    case "SET_FILE_MODAL_TRANSACTIONS":
      return {
        ...state,
        fileModal: { ...state.fileModal, transactions: action.payload },
      };
    case "SET_FILE_MODAL_ROWS":
      return {
        ...state,
        fileModal: { ...state.fileModal, rows: action.payload },
      };
    case "SET_FILE_MODAL_COLUMNS":
      return {
        ...state,
        fileModal: { ...state.fileModal, columns: action.payload },
      };
    case "SET_FILE_MODAL_SUBMIT_LOADING":
      return {
        ...state,
        fileModal: { ...state.fileModal, submitLoading: action.payload },
      };
    case "OPEN_FILE_CONFIRM":
      return {
        ...state,
        fileModal: { ...state.fileModal, confirmIsOpen: true },
      };
    case "CLOSE_FILE_CONFIRM":
      return {
        ...state,
        fileModal: { ...state.fileModal, confirmIsOpen: false },
      };
    case "RESET_FILE_MODAL":
      return { ...state, fileModal: initialState.fileModal };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          isOpen: action.payload.isOpen,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

const DEFAULT_BANK_ACCOUNT_ID = 1; //Id of Banc Sabadell

const initialState = {
  auditModal: {
    transaction: {},
    isOpen: false,
  },
  bankAccounts: [],
  bankingTransactions: [],
  fileModal: {
    bankAccountId: "",
    columns: [],
    confirmIsOpen: false,
    rows: [],
    isOpen: false,
    file: "",
    transactions: [],
    submitLoading: false,
  },
  filters: {
    autoSearch: "false",
    bankAccountId: DEFAULT_BANK_ACCOUNT_ID,
    dateFrom: firstDateCurrentMonth(),
    dateUntil: lastDateCurrentMonth(),
    concept: "",
    importMin: "",
    importMax: "",
    isAudited: "",
    orderBy: "",
    order: "asc",
    transactionType: "",
  },
  options: {
    loaded: true,
  },
  confirmDialog: {
    isOpen: false,
  },
};

export default function BankingTransactionsPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("bankingTransactions");

  const filters = [
    { name: "dateFrom", type: "string" },
    { name: "dateUntil", type: "string" },
    { name: "concept", type: "string" },
    { name: "importMin", type: "number" },
    { name: "importMax", type: "number" },
    { name: "orderBy", type: "number" },
    { name: "order", type: "number" },
    { name: "autoSearch", type: "string" },
    { name: "isAudited", type: "boolean" },
    { name: "transactionType", type: "string" },
  ];

  const types = {
    INVOICE: "invoices",
    PROVIDER_INVOICE: "providerInvoices",
    REMITTANCE: "remittances",
    TRANSFER_PAYMENT_GROUP: "transferPaymentGroups",
    RECEIPT_RETURNED_GROUP: "receiptReturnedGroups",
    RETURN_RECEIPT_FEES: "receiptReturnedGroups",
    BANK_FEES: "bankFees",
    REMITTANCE_FEES: "remittanceFees",
    UNASSIGNED: "unassigned",
  };

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const BANKING_TRANSACTION_COLUMNS = [
    { key: "transactionDate", label: t("transactionDate"), sortType: "string" },
    { key: "concept", label: t("concept"), sortType: "string" },
    { key: "valueDate", label: t("valueDate"), sortType: "string" },
    {
      key: "import",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "balance",
      label: t("balance"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "comments", label: t("comments"), sortType: "string" },
    {
      key: "isAudited",
      label: t("audited"),
      sortType: "number",
      renderFunction: (value) => value && <CheckIcon color="success" />,
    },
    {
      key: "bankingTransactionType",
      label: t("bankingTransactionType"),
      sortType: "string",
      renderFunction: (value) => t(types[value]),
    },
    {
      key: "auditUserId",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Tooltip title={value ? t("seeAudit") : t("audit")} placement="top">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              openAuditModal(item);
            }}
            disabled={!value && !user.hasAction("AUDIT_BANKING_TRANSACTIONS")}
          >
            <BeenhereIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      key: "hasFile",
      label: t("file"),
      sortType: "number",
      renderFunction: (value, item) =>
        value ? (
          <InsertDriveFileIcon
            color="success"
            onClick={(e) => {
              e.preventDefault();
              showFile(item);
            }}
          />
        ) : (
          <InsertDriveFileIcon color="disabled" />
        ),
    },
  ];

  const BANKING_TRANSACTIONS_ITEMS_SUMMARY = [
    {
      translatedText: t("numberOfLines"),
      value: state.bankingTransactions?.length,
    },
    {
      translatedText: t("income"),
      value:
        localeFormat(
          state.bankingTransactions?.reduce(
            (sum, item) => sum + (item.import > 0 ? item.import : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("expenses"),
      value:
        localeFormat(
          state.bankingTransactions?.reduce(
            (sum, item) => sum + (item.import < 0 ? item.import : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value:
        localeFormat(
          state.bankingTransactions?.reduce((sum, item) => sum + item.import, 0)
        ) + "€",
    },
  ];

  const CSV_HEADERS = [
    { key: "transactionDate", label: t("transactionDate") },
    { key: "concept", label: t("concept") },
    { key: "valueDate", label: t("valueDate") },
    { key: "import", label: t("import") },
    { key: "balance", label: t("balance") },
    { key: "comments", label: t("comments") },
    { key: "auditDate", label: t("audited") },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("bankingTransactions");

    getBankAccounts();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getBankingTransactions();
  }, [state.filters.autoSearch]);

  const getBankAccounts = () => {
    api
      .get("/bank-accounts")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_BANK_ACCOUNTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBankingTransactions = () => {
    if (state.filters.bankAccountId) {
      //Change url parameters:
      const url = generateURL("/app/banking-transactions", state.filters);
      history.push(url);

      dispatch({ type: "SET_BANKING_TRANSACTIONS", payload: [] });
      dispatch({ type: "SET_LOADED_FALSE" });
      let params = {
        include: [
          "BankAccount",
          "Invoice",
          "ProviderInvoice",
          "Remittance",
          "TransferPaymentGroup",
          "ReceiptReturnedGroup",
          "User",
        ],
      };

      state.filters.concept !== "" && (params.concept = state.filters.concept);
      state.filters.dateFrom !== "" &&
        (params.dateFrom = state.filters.dateFrom);
      state.filters.dateUntil !== "" &&
        (params.dateUntil = state.filters.dateUntil);
      state.filters.importMin !== "" &&
        (params.importMin = state.filters.importMin);
      state.filters.importMax !== "" &&
        (params.importMax = state.filters.importMax);
      state.filters.bankAccountId !== "" &&
        (params.bankAccountId = state.filters.bankAccountId);
      state.filters.isAudited !== "" &&
        (params.isAudited = state.filters.isAudited);
      state.filters.transactionType != "" &&
        (params.transactionType = state.filters.transactionType);

      api
        .get("/banking-transactions", { params })
        .then((response) => {
          dispatch({ type: "SET_LOADED_TRUE" });

          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            if (response.data.length === 0)
              enqueueSnackbar(t("noBankingTransactions"), { variant: "info" });

            const bankingTransactions = response.data.map((transaction) => ({
              ...transaction,
              isAudited: transaction.auditDate ? true : false,
              bankingTransactionType: transaction.bankingTransactionType
                ? transaction.bankingTransactionType
                : "",
            }));

            dispatch({
              type: "SET_BANKING_TRANSACTIONS",
              payload: bankingTransactions,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getBankingTransactions();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const openAuditModal = (transaction) => {
    dispatch({ type: "OPEN_AUDIT_MODAL" });
    dispatch({ type: "SET_MODAL_TRANSACTION", payload: transaction });
  };

  const closeAuditModal = () => {
    dispatch({ type: "CLOSE_AUDIT_MODAL" });
  };

  const submitAuditForm = (transaction, id, file) => {
    if (user.hasAction("AUDIT_BANKING_TRANSACTIONS")) {
      const { comments, isAudited, transactionType } = transaction;
      let form = {
        comments,
        isAudited,
        transactionType,
      };

      const type = types[transactionType];

      form[type] = transaction[type]?.map((item) => item.id);

      api
        .post("/banking-transactions/audit/" + id, form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            transaction.isAudited
              ? enqueueSnackbar(t("auditSuccess"), { variant: "success" })
              : enqueueSnackbar(t("saveSuccess"), { variant: "success" });
            getBankingTransactions();
            closeAuditModal();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });

      if (file !== "") {
        let formData = new FormData();
        formData.append("fileUpload", new File([file], "fileUpload"));
        api
          .post("/banking-transactions/" + id + "/upload-file", formData)
          .then((response) => {
            if (response.data.error) {
              enqueueSnackbar(response.data.error, { variant: "error" });
            } else {
              enqueueSnackbar(t("fileUploadSuccess"), { variant: "success" });
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(error.toString(), { variant: "error" });
          });
      }
    } else {
      enqueueSnackbar(t("permissionDenied"), { variant: "error" });
      closeAuditModal();
    }
  };

  //Banking transactions file modal

  const openFileModal = () => {
    dispatch({ type: "OPEN_FILE_MODAL" });
  };

  const closeFileModal = () => {
    dispatch({ type: "CLOSE_FILE_MODAL" });
    dispatch({ type: "RESET_FILE_MODAL" });
  };

  const handleFileChange = (e) => {
    dispatch({ type: "SET_FILE_MODAL_FILE", payload: e.target.files[0] });
  };

  const handleFileModalInputChange = (e) => {
    dispatch({
      type: "SET_FILE_MODAL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const processFile = () => {
    const file = state.fileModal.file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      processCsv(text);
    };

    reader.readAsText(file);
  };

  // Convert csv (with headers) into array of objects
  const processCsv = (str) => {
    //Replace "" with "
    const processedStr = str.replace(/""/g, '"');

    //Use react-papaparse to parse processed string, using input delimiter (change to auto-detect?)
    const parsedStr = readString(processedStr);

    //Snackbar with the first error in the error array, return if there is a formatting error
    if (parsedStr.errors.length != 0) {
      const { code, row } = parsedStr.errors[0];
      let translatedCode;
      switch (code) {
        case "InvalidQuotes":
          translatedCode = `${t("invalidQuotes")}`;
          break;
        case "UndetectableDelimiter":
          translatedCode = `${t("undetectableDelimiter")}`;
          break;
        default:
          translatedCode = `${t("undefinedError")}`;
          break;
      }
      enqueueSnackbar(
        `${t("fileError")}: ${translatedCode} ${t(
          "onRow"
        ).toLowerCase()} ${row}`,
        {
          variant: "error",
        }
      );
      return;
    }

    //Get headers and rows
    const headers = parsedStr.data[0];
    const rows = parsedStr.data.slice(1);

    //Transform rows into object assigning each header to it's corresponding value
    const data = rows.map((row, i) => {
      const object = headers.reduce((obj, header, i) => {
        obj[header] = row[i];
        //console.log(obj)
        return obj;
      }, {});

      object.id = i + 1; // Id's are required for datagrid

      return object;
    });

    const columns = headers.map((header) => ({
      field: header,
      headerName: header,
      width: 200,
      editable: true,
    }));

    dispatch({ type: "SET_FILE_MODAL_ROWS", payload: data });
    dispatch({ type: "SET_FILE_MODAL_TRANSACTIONS", payload: data });

    dispatch({ type: "SET_FILE_MODAL_COLUMNS", payload: columns });
  };

  const editFileModalRow = (id, field, value) => {
    let transactions = state.fileModal.transactions;
    transactions.forEach((transaction) => {
      if (transaction.id === id) transaction[field] = value;
    });

    dispatch({ type: "SET_FILE_MODAL_TRANSACTIONS", payload: transactions });
  };

  const openFileConfirm = () => {
    dispatch({ type: "OPEN_FILE_CONFIRM" });
  };

  const closeFileConfirm = () => {
    dispatch({ type: "CLOSE_FILE_CONFIRM" });
  };

  const validateRow = (row) => {
    return [
      "bankAccountId",
      "transactionDate",
      "concept",
      "valueDate",
      "import",
      "balance",
    ].every((prop) => Object.keys(row).includes(prop));
  };

  const createTransactions = () => {
    //Format transactions to db model
    let transactions = state.fileModal.transactions.map((transaction) => {
      let data = {};

      data.transactionDate = new Date(
        transaction["F. Operativa"]?.split("/").reverse().join("-")
      );
      data.valueDate = new Date(
        transaction["F. Valor"]?.split("/").reverse().join("-")
      );
      data.concept = transaction["Concepto"];
      data.import = parseFloat(
        transaction["Importe"]?.replace(/\./g, "").replace(",", ".")
      );
      data.balance = parseFloat(
        transaction["Saldo"]?.replace(/\./g, "").replace(",", ".")
      );
      data.ref1 = transaction["Referencia 1"];
      data.ref2 = transaction["Referencia 2"];
      data.bankAccountId = state.fileModal.bankAccountId;

      return data;
    });

    //Validate rows (only checks for empty rows), then call api
    if (transactions.every(validateRow)) {
      dispatch({ type: "SET_FILE_MODAL_SUBMIT_LOADING", payload: true });
      api
        .post("/bank-transactions/create-multiple", { transactions })
        .then((response) => {
          dispatch({ type: "SET_FILE_MODAL_SUBMIT_LOADING", payload: false });
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("bankingTransactionsCreatedSuccessfully"), {
              variant: "success",
            });
            closeFileModal();
          }
          closeFileConfirm();
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
          dispatch({ type: "SET_FILE_MODAL_SUBMIT_LOADING", payload: false });
        });
    } else
      enqueueSnackbar(t("errorBankingTransactionContainer"), {
        variant: "error",
      });
  };

  const showFile = (transaction) => {
    api
      .get("/banking-transactions/" + transaction.id + "/upload-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_BANKING_TRANSACTIONS", payload: [] });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirmCreate = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        isOpen: true,
      },
    });
  };

  return (
    <Page
      maxWidth={"xl"}
      title={t("bankingTransactions")}
      browserTitle={t("bankingTransactions")}
    >
      <Grid container spacing={2} marginTop={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                label={t("concept")}
                name="concept"
                value={state.filters.concept}
                onChange={handleChangeFilter}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <CustomDate
                label={t("dateFrom")}
                name="dateFrom"
                value={state.filters.dateFrom}
                onChange={handleChangeFilter}
              />,
              <CustomDate
                label={t("dateUntil")}
                name="dateUntil"
                value={state.filters.dateUntil}
                onChange={handleChangeFilter}
              />,
              <TextInput
                label={t("importMin")}
                name="importMin"
                value={state.filters.importMin}
                onChange={handleChangeFilter}
                type="number"
              />,
              <TextInput
                label={t("importMax")}
                name="importMax"
                value={state.filters.importMax}
                onChange={handleChangeFilter}
                type="number"
              />,
              <CustomSelect
                label={t("bankAccount")}
                name="bankAccountId"
                value={
                  state.bankAccounts.length != 0
                    ? state.filters.bankAccountId
                    : ""
                }
                onChange={handleChangeFilter}
                options={[
                  ...state.bankAccounts?.map((account) => ({
                    value: account.id,
                    label: account.name,
                  })),
                ]}
              />,
              <CustomSelect
                label={t("isAudited")}
                name="isAudited"
                value={state.filters.isAudited}
                onChange={handleChangeFilter}
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
              />,
              <CustomSelect
                label={t("transactionType")}
                name="transactionType"
                value={state.filters.transactionType}
                onChange={handleChangeFilter}
                options={[
                  { value: "", label: t("all") },
                  { value: "INVOICE", label: t("invoice") },
                  { value: "PROVIDER_INVOICE", label: t("providerInvoice") },
                  { value: "REMITTANCE", label: t("remittance") },
                  {
                    value: "TRANSFER_PAYMENT_GROUP",
                    label: t("transferPaymentGroup"),
                  },
                  {
                    value: "RECEIPT_RETURNED_GROUP",
                    label: t("receiptReturnedGroup"),
                  },
                  {
                    value: "RETURN_RECEIPT_FEES",
                    label: t("returnReceiptFees"),
                  },
                  { value: "BANK_FEES", label: t("bankFees") },
                  { value: "REMITTANCE_FEES", label: t("remittanceFees") },
                  { value: "UNASSIGNED", label: t("unassigned") },
                ]}
              />,
              <ButtonGroup variant="contained" color="primary">
                <Button onClick={resetFilters}>{t("reset")}</Button>
                <ButtonCSV
                  filename={t("bankingTransactions")}
                  data={state.bankingTransactions}
                  headers={CSV_HEADERS}
                />
                <SearchButton
                  onClick={search}
                  loading={!state.options.loaded}
                  disabled={!state.filters.bankAccountId}
                />
              </ButtonGroup>,
              user.hasAction("EDIT_BANKING_TRANSACTIONS") && (
                <Grid item>
                  <CustomButton
                    onClick={openFileModal}
                    color="success"
                    startIcon={<UploadFile />}
                  >
                    {t("importTransactions")}
                  </CustomButton>
                </Grid>
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary gridItems={BANKING_TRANSACTIONS_ITEMS_SUMMARY} />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={BANKING_TRANSACTION_COLUMNS}
            data={state.bankingTransactions}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/banking-transactions", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>

      {/* Audit modal */}
      <Dialog
        open={state.auditModal.isOpen}
        onClose={closeAuditModal}
        maxWidth={"xl"}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("auditBankingTransaction")}</Grid>
            <Grid item>
              <IconButton onClick={closeAuditModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <AuditForm
            transaction={state.auditModal.transaction}
            onSubmit={submitAuditForm}
          />
        </DialogContent>
      </Dialog>

      {/* Upload banking transactions file modal */}

      <Dialog
        onClose={closeFileModal}
        open={state.fileModal.isOpen}
        maxWidth={"xl"}
        fullWidth
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("importTransactions")}</Grid>
            <Grid item>
              <IconButton onClick={closeFileModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ height: 650 }}>
          <Grid container spacing={1}>
            <Grid item container columnSpacing={1}>
              <Grid item>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                </Button>
              </Grid>
              <Grid item>
                <CustomSelect
                  value={state.fileModal.bankAccountId}
                  onChange={handleFileModalInputChange}
                  name="bankAccountId"
                  label={t("bankAccount")}
                  options={[
                    { value: "", label: "" },
                    ...state.bankAccounts.map((account) => ({
                      value: account.id,
                      label: account.name,
                    })),
                  ]}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={processFile}
                  disabled={state.fileModal.bankAccountId == "" ? true : false}
                >
                  {t("search")}
                </Button>
              </Grid>
            </Grid>

            <Grid item container alignItems="center">
              <Grid item>
                <Typography variant="body2">
                  {state.fileModal.file !== ""
                    ? state.fileModal.file?.name
                    : t("noFileSelected")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("importFileInfo")}>
                  <IconButton size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box style={{ height: 400, width: "100%" }}>
                <DataGrid
                  columns={state.fileModal.columns}
                  rows={state.fileModal.rows}
                  onCellEditCommit={(params) => {
                    editFileModalRow(params.id, params.field, params.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            loading={state.fileModal.submitLoading}
            color="success"
            onClick={() => openConfirmCreate()}
            disabled={state.fileModal.transactions == "" ? true : false}
          >
            {t("create")}
          </CustomButton>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title={t("createBankingTransactions")}
        open={state.confirmDialog.isOpen}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        setOpen={setConfirmDialogState}
        onConfirm={(confirm) => {
          confirm && createTransactions();
          resetConfirmDialog();
        }}
      >
        <Typography variant="body2">
          {t("confirmCreateBankingTransactionsQuestion")}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}
