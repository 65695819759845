import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Box,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

// Icons
import EmailIcon from "@mui/icons-material/Email";
import GetAppIcon from "@mui/icons-material/GetApp";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

// Components & utils
import AssignInvoiceDialog from "./AssignInvoiceDialog";
import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import CenterSelect from "../../Inputs/CenterSelect";
import CreateButton from "../../Inputs/CreateButton";
import CustomDate from "../../Inputs/CustomDate";
import SearchButton from "../../Inputs/SearchButton";
import { CustomTable } from "../../CustomTable";
import { useHistory, useLocation } from "react-router-dom";
import { generateURL, getParams } from "../../../utils/url";
import ButtonCSV from "../../Inputs/ButtonCSV";
import TextInput from "../../Inputs/TextInput";
import { localeFormat } from "../../../utils/format";
import ProviderInvoiceStateChip from "./ProviderInvoiceStateChip";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  filters: {
    amountMin: "",
    amountMax: "",
    autoSearch: false,
    centerIds: [],
    fromDate: "",
    name: "",
    order: "",
    orderBy: "",
    toDate: "",
  },
  options: {
    loaded: true,
    rowlink: "settlement",
  },
  settlements: [],
  downloadPdfLoading: false,
  emailSettlementLoading: false,
  invoiceDialog: {
    selectedSettlement: "",
    isOpen: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return initialState;
    case "SET_DOWNLOAD_PDF_LOADING":
      return { ...state, downloadPdfLoading: action.payload };
    case "SET_EMAIL_SETTLEMENT_LOADING":
      return { ...state, emailSettlementLoading: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputName]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_SETTLEMENTS":
      return { ...state, settlements: action.payload };
    case "SET_INVOICE_DIALOG":
      return {
        ...state,
        invoiceDialog: {
          ...state.invoiceDialog,
          isOpen: action.payload.isOpen,
          selectedSettlement: action.payload.selectedSettlement,
        },
      };

    default:
      throw new Error("Action not found in reducer.");
  }
};

const SettlementsPage = () => {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("settlements");

  const CSV_HEADERS = [
    { label: t("name"), key: "name" },
    { label: t("center"), key: "Center.name" },
    { label: t("startDate"), key: "startDate" },
    { label: t("endDate"), key: "endDate" },
    { label: t("amount"), key: "total" },
  ];

  const FILTERS = [
    { name: "amountMin", type: "number" },
    { name: "amountMax", type: "number" },
    { name: "autoSearch", type: "boolean" },
    { name: "centerIds", type: "array" },
    { name: "fromDate", type: "string" },
    { name: "name", type: "name" },
    { name: "order", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "toDate", type: "string" },
  ];

  const SETTLEMENT_COLUMNS = [
    { label: t("name"), key: "name", sortType: "string" },
    { label: t("center"), key: "Center.name", sortType: "string" },
    {
      label: t("startDate"),
      key: "startDate",
      sortType: "string",
      renderFunction: (value) => value.substring(0, 10),
    },
    {
      label: t("endDate"),
      key: "endDate",
      sortType: "string",
      renderFunction: (value) => value.substring(0, 10),
    },
    {
      label: t("amount"),
      key: "total",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      label: t("invoice"),
      key: "providerInvoiceId",
      sortType: "number",
      renderFunction: (value, item) => (
        <ProviderInvoiceStateChip
          isNull={value}
          paidAt={item?.ProviderInvoice?.paidAt || null}
        />
      ),
    },
    {
      label: t("actions"),
      key: "actions",
      sortType: "other",
      renderFunction: (value, item) => (
        <>
          <Tooltip title={t("downloadPdf")} placement="top">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                downloadPdf(item);
              }}
              disabled={state.downloadPdfLoading}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("emailSettlement")} placement="top">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                emailSettlement(item.id);
              }}
              disabled={state.emailSettlementLoading}
            >
              {item.sentAt ? (
                <MarkEmailReadIcon color="success" />
              ) : (
                <EmailIcon />
              )}
            </IconButton>
          </Tooltip>
          {item.providerInvoiceId && user.hasPage("PROVIDER INVOICES") && (
            <Tooltip title={t("viewInvoice")} placement="top">
              <IconButton
                color="primary"
                to={`/app/provider-invoice/${item.ProviderInvoice?.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  history.push(
                    `/app/provider-invoice/${item.ProviderInvoice?.id}`
                  );
                }}
              >
                <Box sx={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </Box>
              </IconButton>
            </Tooltip>
          )}
          {user.hasAction("EDIT_SETTLEMENTS") && (
            <Tooltip title={t("assignProviderInvoice")} placement="top">
              <IconButton
                color={
                  state.settlement?.providerInvoiceId ? "success" : "primary"
                }
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "SET_INVOICE_DIALOG",
                    payload: {
                      isOpen: true,
                      selectedSettlement: item,
                    },
                  });
                }}
              >
                <NoteAddIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  //Initial useEffect
  useEffect(() => {
    document.title = t("settlementsPage");
  }, []);

  //Autosearch useEffect
  useEffect(() => {
    if (state.filters.autoSearch === true) getSettlements();
  }, [state.filters.autoSearch]);

  /* API CALLS */

  const getSettlements = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    // Change url parameters
    const url = generateURL("/app/settlements", state.filters);
    history.push(url);

    const { name, centerIds, fromDate, toDate, amountMin, amountMax } =
      state.filters;
    let params = { include: ["ProviderInvoice"] };

    name !== "" && (params.name = name);
    centerIds.length !== 0 && (params.centerIds = centerIds);
    toDate !== "" && (params.toDate = toDate);
    fromDate !== "" && (params.fromDate = fromDate);
    amountMin !== "" && (params.amountMin = amountMin);
    amountMax !== "" && (params.amountMax = amountMax);

    api
      .get("/settlements", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noSettlements"), { variant: "info" });
          }
          dispatch({ type: "SET_SETTLEMENTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const downloadPdf = (settlement) => {
    dispatch({ type: "SET_DOWNLOAD_PDF_LOADING", payload: true });
    api
      .get("/settlements/" + settlement.id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        downloadFile(response.data, settlement.name);
        dispatch({ type: "SET_DOWNLOAD_PDF_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_DOWNLOAD_PDF_LOADING", payload: false });
      });
  };

  const emailSettlement = (settlementId) => {
    dispatch({ type: "SET_EMAIL_SETTLEMENT_LOADING", payload: true });
    api
      .get("/settlements/" + settlementId + "/email")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            t("emailSentTo") + ": " + response.data.acceptedEmails,
            {
              variant: "success",
            }
          );
          if (response.data.rejectedEmails) {
            enqueueSnackbar(
              t("rejectedEmails") + ": " + response.data.rejectedEmails,
              {
                variant: "warning",
              }
            );
          } else {
            const toEdit = state.settlements.find((s) => s.id == settlementId);
            toEdit.sentAt = new Date();
            dispatch({
              type: "SET_SETTLEMENTS",
              payload: [...state.settlements],
            });
          }
        }
        dispatch({ type: "SET_EMAIL_SETTLEMENT_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_EMAIL_SETTLEMENT_LOADING", payload: false });
      });
  };

  /* HANDLERS */

  const handleFilterChange = (event) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputName: event.target.name,
        value: event.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET" });
  };

  const search = () => {
    if (state.filters.autoSearch === true) getSettlements();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputName: "autoSearch", value: true },
      });
  };

  const downloadFile = (file, filename, fileType = "pdf") => {
    // Create a Blob from the file
    const fileBlob = new Blob([file], {
      type: "application/" + fileType,
    });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(fileBlob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = filename + "." + fileType;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const editSettlement = (selectedInvoiceId, settlementId) => {
    let data = {
      providerInvoiceId: selectedInvoiceId || null,
    };

    api
      .post("/settlements/" + settlementId, data)
      .then((response) => {
        if (response.data?.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          getSettlements();
          enqueueSnackbar(t("settlementEditedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Container maxWidth={false} sx={{ marginY: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("settlements")}</Typography>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12} sm={6} md="auto">
            <TextInput
              label={t("name")}
              value={state.filters.name}
              name="name"
              onChange={handleFilterChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") search();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <CenterSelect
              multiple
              name="centerIds"
              value={state.filters.centerIds}
              onChange={handleFilterChange}
              autoWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <CustomDate
              name="fromDate"
              label={t("from")}
              value={state.filters.fromDate}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <CustomDate
              name="toDate"
              label={t("to")}
              value={state.filters.toDate}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <TextInput
              label={t("amountMin")}
              name="amountMin"
              value={state.filters.amountMin}
              onChange={handleFilterChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <TextInput
              label={t("amountMax")}
              name="amountMax"
              value={state.filters.amountMax}
              onChange={handleFilterChange}
              type="number"
            />
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={resetFilters}>{t("reset")}</Button>
              <ButtonCSV
                filename={t("settlements")}
                data={state.settlements.map((settlement) => ({
                  ...settlement,
                  total: localeFormat(settlement.total),
                }))}
                headers={CSV_HEADERS}
              />
              <SearchButton onClick={search} loading={!state.options.loaded} />
            </ButtonGroup>
          </Grid>

          <Grid item>
            <CreateButton
              action={"CREATE_SETTLEMENTS"}
              label={t("createSettlement")}
              link={"/settlement/create"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={SETTLEMENT_COLUMNS}
            data={state.settlements}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/settlements", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>

      <AssignInvoiceDialog
        open={state.invoiceDialog.isOpen}
        onClose={(selectedId) => {
          editSettlement(selectedId, state.invoiceDialog.selectedSettlement.id);
          dispatch({
            type: "SET_INVOICE_DIALOG",
            payload: {
              isOpen: false,
              selectedSettlement: "",
            },
          });
        }}
        settlement={state.invoiceDialog.selectedSettlement}
      />
    </Container>
  );
};

export default SettlementsPage;
