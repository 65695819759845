import { Stack } from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import GeneralCollapsible from "../ItemCollapsibles/GeneralCollapsible";
import PointsCollapsible from "../ItemCollapsibles/PointsCollapsible";
import BoxCollapsible from "../ItemCollapsibles/BoxCollapsible";
import { FloorPlanContext } from "../../../FloorPlanPage";
import ActionButtons from "./ActionButtons";
import FormTitle from "./FormTitle";
import PositionCollapsible from "../ItemCollapsibles/PositionCollapsible";

const PolygonForm = (props) => {
  const { item } = props;

  const { selectTile, setEditItem } = useContext(FloorPlanContext);

  const setName = (name) => setEditItem({ ...item, name });
  const setX = (x) => setEditItem({ ...item, x });
  const setY = (y) => setEditItem({ ...item, y });

  return (
    <Stack spacing={2}>
      <FormTitle shape={item.shape} name={item.name} />
      <GeneralCollapsible name={item.name} setName={setName} />
      <PositionCollapsible x={item.x} setX={setX} y={item.y} setY={setY} />
      <PointsCollapsible item={item} />
      <BoxCollapsible />
      <ActionButtons onCancel={() => selectTile(null)} />
    </Stack>
  );
};

PolygonForm.propTypes = {
  item: PropTypes.object,
};

export default PolygonForm;
