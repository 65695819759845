import { Stack } from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import PositionCollapsible from "../ItemCollapsibles/PositionCollapsible";
import BoxCollapsible from "../ItemCollapsibles/BoxCollapsible";
import GeneralCollapsible from "../ItemCollapsibles/GeneralCollapsible";
import SizeCollapsible from "../ItemCollapsibles/SizeCollapsible";
import ActionButtons from "./ActionButtons";
import { FloorPlanContext } from "../../../FloorPlanPage";
import FormTitle from "./FormTitle";

const RectangleForm = (props) => {
  const { item } = props;
  const { selectTile, editTile, setEditItem } = useContext(FloorPlanContext);

  const setName = (name) => setEditItem({ ...item, name });
  const setX = (x) => setEditItem({ ...item, x });
  const setY = (y) => setEditItem({ ...item, y });
  const setWidth = (width) => setEditItem({ ...item, width });
  const setHeight = (height) => setEditItem({ ...item, height });

  const onEdit = async () => {
    await editTile({
      id: item.id,
      name: item.name,
      height: item.height,
      width: item.width,
      x: item.x,
      y: item.y,
    });
    selectTile(null);
  };

  const box = item.Boxes ? item.Boxes[0] : null;

  return (
    <Stack spacing={2}>
      <FormTitle shape={item.shape} name={item.name} />
      <GeneralCollapsible name={item?.name} setName={setName} />
      <SizeCollapsible
        height={item?.height}
        setHeight={setHeight}
        width={item?.width}
        setWidth={setWidth}
      />
      <PositionCollapsible x={item?.x} setX={setX} y={item?.y} setY={setY} />
      <BoxCollapsible box={item.Boxes ? item.Boxes[0] : null /* TODO */} />
      <ActionButtons onCancel={() => selectTile(null)} onSave={onEdit} />
    </Stack>
  );
};

RectangleForm.propTypes = {
  item: PropTypes.object,
};

export default RectangleForm;
