import PropTypes from "prop-types";
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";

const Dialog = (props) => {
  const {
    open = false,
    onClose,
    actions,
    children,
    title,
    hideCloseIcon,
    maxWidth = "sm",
    fullWidth = true,
    draggable = false,
    hideBackdrop = false,
  } = props;

  return (
    <MUIDialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperComponent={draggable ? DraggablePaper : undefined}
      hideBackdrop={hideBackdrop}
    >
      <Title title={title} onClose={onClose} hideCloseIcon={hideCloseIcon} />
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MUIDialog>
  );
};

const DraggablePaper = (props) => {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
};

const Title = ({ title, onClose, hideCloseIcon }) => {
  const displayIconCloseIcon = onClose && hideCloseIcon !== true;

  return (
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {title}
      {displayIconCloseIcon && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

Dialog.propTypes = {
  actions: PropTypes.element,
  hideCloseIcon: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  fullWidth: PropTypes.bool,
};

export default Dialog;
