import {
  Alert,
  AlertTitle,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import ConfirmDialog from "../../ConfirmDialog";
import TextInput from "../../Inputs/TextInput";
import BoxStateChip from "../../BoxStateChip";
import { localeFormat } from "../../../utils/format";

import DescriptionIcon from "@mui/icons-material/Description";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AdjustIcon from "@mui/icons-material/Adjust";
import LayersIcon from "@mui/icons-material/Layers";
import PercentIcon from "@mui/icons-material/Percent";
import StoreIcon from "@mui/icons-material/Store";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";

import {
  BLOCKED_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BOX_ZONES,
  GENERAL_VAT_ES,
} from "../../../data/constants";

import ActionIconButtons from "../../global/ActionIconButtons";
import CustomButton from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import Dialog from "../../global/Dialog";
import LabeledText from "../../global/LabeledText";
import Page from "../../global/structure/Page";
import Select from "../../global/inputs/Select";
import { formatTime } from "../../../utils/time";
import { CustomTable } from "../../CustomTable";
import ContractStateChip from "../Contracts/ContractStateChip";
import BoxClusterChip from "../../BoxClusterChip";

const editableStates = [
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BLOCKED_BOX_STATE_ID,
];

const SPANISH_VAT_MULTIPLIER = (GENERAL_VAT_ES + 100) / 100;

const initialState = {
  contracts: [],
  centerBoxClusters: [],
  form: {
    name: "",
    centerId: "",
    meters: "",
    royalty: "",
    pricePerMeter: "",
    state: "",
    isLocker: false,
    floor: "",
    zone: "",
    boxClusterId: "",
  },
  inputError: {
    name: false,
    centerId: false,
    meters: false,
    royalty: false,
    pricePerMeter: false,
  },
  loading: {
    submitForm: false,
    endBooking: false,
    createBooking: false,
  },
  bookingDialog: {
    comments: "",
    duration: 15,
    open: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_CENTER_BOX_CLUSTERS":
      return { ...state, centerBoxClusters: action.payload };
    case "SET_BOX":
      return { ...state, form: action.payload };
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.loadingName]: action.payload.value,
        },
      };
    case "SET_BOOKING_DIALOG":
      return {
        ...state,
        bookingDialog: {
          ...state.bookingDialog,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
}

export default function BoxPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("boxes");
  const [tErrors] = useTranslation("errors");

  //Initial useEffect
  useEffect(() => {
    getBox();
    user.hasAction("VIEW_CONTRACTS") && getContracts();
  }, []);

  useEffect(() => {
    getCenterBoxClusters();
  }, [state.form.centerId]);

  const getBox = () => {
    api
      .get("/boxes/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_BOX",
            payload: response.data,
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getContracts = () => {
    let params = {
      include: ["Box", "Center", "Customer"],
      boxId: id,
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CONTRACTS", payload: response.data });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getCenterBoxClusters = () => {
    const id = state.form.centerId;

    if (!id) return;

    api
      .get("/centers/" + id + "/box-clusters")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTER_BOX_CLUSTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getCurrentBoxContract = (box) => {
    if (!box || !box.Contracts || !box.Contracts.length) return null;
    for (let contract of box.Contracts) {
      if (!contract.endDate) return contract;
    }
    const today = new Date();
    for (let contract of box.Contracts) {
      if (
        new Date(contract.startDate) <= today &&
        new Date(contract.endDate) >= today
      )
        return contract;
    }
    return null;
  };

  const submitForm = (form) => {
    if (!validateForm(form)) return;

    let data = {};
    form.name !== "" && (data.name = form.name);
    form.centerId !== "" && (data.centerId = form.centerId);
    form.meters !== "" && (data.meters = form.meters);
    form.pricePerMeter !== "" && (data.pricePerMeter = form.pricePerMeter);
    form.royalty !== "" && (data.royalty = form.royalty);
    form.comments !== "" && (data.comments = form.comments);
    form.floor !== "" && (data.floor = form.floor);
    form.zone !== "" && (data.zone = form.zone);
    data.isLocker = form.isLocker;
    data.boxClusterId = form.boxClusterId === "" ? null : form.boxClusterId;

    handleSetLoading("submitForm", true);
    api
      .post("/boxes/edit/" + id, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("boxEditSuccess"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => handleSetLoading("submitForm", false));
  };

  const modifyBoxState = (newState) => {
    api
      .post("/boxes/" + id + "/modify-state", { state: newState })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("boxStateEditSuccess"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "state",
              value: newState,
            },
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const activeBooking = state.form.BoxBookings?.find(
    (booking) => booking.remainingMinutes > 0
  );

  const createOrExtendBooking = () => {
    handleSetLoading("createBooking", true);

    const body = {
      boxId: id,
      durationInMinutes: state.bookingDialog.duration,
    };

    state.bookingDialog.comments != "" &&
      (body.comments = state.bookingDialog.comments);

    const doCreateBooking = state.form.state === FREE_BOX_STATE_ID;

    const apiRoute = doCreateBooking
      ? "/box-bookings/create"
      : `/box-bookings/${activeBooking.id}/extend`;

    api
      .post(apiRoute, body)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(
            doCreateBooking
              ? t("bookingCreatedSuccessfully")
              : t("bookingExtendedSuccessfully"),
            {
              variant: "success",
            }
          );
          getBox();
          handleSetBookingDialog("open", false);
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => handleSetLoading("createBooking", false));
  };

  const endBooking = (bookingId) => {
    handleSetLoading("endBooking", true);

    api
      .post(`/box-bookings/end/${bookingId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("bookingEndedSuccessfully"), {
            variant: "success",
          });
          getBox();
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => handleSetLoading("endBooking", false));
  };

  const handleInputChange = (e) => {
    setInputError(e.target.name, false);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleSetLoading = (loadingName, value) => {
    dispatch({
      type: "SET_LOADING",
      payload: {
        loadingName,
        value,
      },
    });
  };

  const handleSetBookingDialog = (key, value) => {
    dispatch({
      type: "SET_BOOKING_DIALOG",
      payload: {
        key,
        value,
      },
    });
  };

  const handleBookingDialogInputChange = (e) => {
    handleSetBookingDialog(e.target.name, e.target.value);
  };

  const handleBoxStateChange = (e) => {
    const checked = e.target.checked;
    const currentState = Number(state.form.state);

    const getNewState = (checked, currentState) => {
      if (
        [FREE_BOX_STATE_ID, UNAVAILABLE_BOX_STATE_ID].includes(currentState)
      ) {
        return checked ? UNAVAILABLE_BOX_STATE_ID : FREE_BOX_STATE_ID;
      } else if (
        [OCCUPIED_BOX_STATE_ID, BLOCKED_BOX_STATE_ID].includes(currentState)
      ) {
        return checked ? BLOCKED_BOX_STATE_ID : OCCUPIED_BOX_STATE_ID;
      }
      return currentState;
    };

    const newState = getNewState(checked, currentState);
    modifyBoxState(newState);
  };

  const handleIsLockerChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "isLocker", value: e.target.checked },
    });
  };

  const setInputError = (name, value) => {
    dispatch({
      type: "SET_INPUT_ERROR",
      payload: {
        inputname: name,
        value,
      },
    });
  };

  const validateForm = (form) => {
    let isValid = true;
    let fields = ["name", "meters", "pricePerMeter", "royalty", "centerId"];

    fields.forEach((field) => {
      if (form[field] === "") {
        setInputError(field, true);
        enqueueSnackbar(t(field) + " " + t("isRequired"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <Page browserTitle={t("box")} maxWidth="lg" paper={true}>
      <Grid container spacing={2}>
        <Grid item container alignItems="center" xs={12} spacing={2}>
          <Grid item>
            <Typography variant="h4">
              {t("box") + " - " + state.form.name}
            </Typography>
          </Grid>

          <Grid item>
            <BoxStateChip
              state={state.form.state}
              endDate={
                state.form.state === OCCUPIED_BOX_STATE_ID
                  ? getCurrentBoxContract({ Contracts: state.contracts })
                      ?.endDate
                  : null
              }
            />
          </Grid>

          <Grid item flex={1} justifyContent="flex-end" display="flex">
            <ActionIconButtons
              actions={[
                {
                  display: user.hasPage("CENTERS"),
                  icon: <StoreIcon />,
                  title: t("viewCenter"),
                  link: `/app/center/${state.form?.centerId}`,
                },
                {
                  display:
                    user.hasAction("CREATE_BOX_BOOKINGS") &&
                    state.form?.state === FREE_BOX_STATE_ID,
                  icon: <ScheduleIcon />,
                  title: t("createBooking"),
                  color: "success",
                  onClick: () => handleSetBookingDialog("open", true),
                },
                {
                  display:
                    (user.hasAction("END_ANY_BOX_BOOKINGS") ||
                      user?.id === activeBooking?.userId) &&
                    state.form?.state === BOOKED_BOX_STATE_ID,
                  icon: <MoreTimeIcon />,
                  title: t("extendBooking"),
                  color: "success",
                  onClick: () => handleSetBookingDialog("open", true),
                },
                {
                  display: user.hasAction("CREATE_CONTRACTS"),
                  icon: <DescriptionIcon />,
                  title: t("createContract"),
                  link: "/app/contract/create?boxId=" + id,
                  color: "success",
                },
              ]}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          {state.form?.state === BOOKED_BOX_STATE_ID && (
            <BoxBookingInfo
              box={state.form}
              user={user}
              endBooking={endBooking}
              loading={state.loading}
            />
          )}
          {user.hasAction("EDIT_BOXES") ? (
            <EditableBoxForm
              centerBoxClusters={state.centerBoxClusters}
              form={state.form}
              inputError={state.inputError}
              handleInputChange={handleInputChange}
              handleIsLockerChange={handleIsLockerChange}
            />
          ) : (
            <NonEditableBoxForm form={state.form} />
          )}
        </Grid>

        <Grid item xs={12}>
          {user.hasAction("EDIT_BOX_COMMENTS") ? (
            <TextInput
              label={t("comments")}
              multiline
              rows={3}
              onChange={handleInputChange}
              value={state.form.comments}
              name="comments"
              InputLabelProps={{ shrink: state.form?.comments !== "" }}
            />
          ) : (
            <LabeledText
              label={t("comments")}
              value={state.form.comments || t("noComments")}
            />
          )}
        </Grid>

        {user.hasAction("EDIT_BOX_STATE") &&
          editableStates.includes(Number(state.form.state)) && (
            <Grid item xs={12}>
              <BoxStateSwitch
                state={state.form.state}
                handleBoxStateChange={handleBoxStateChange}
              />
            </Grid>
          )}

        {/* Contracts of the box */}
        {user.hasAction("VIEW_CONTRACTS") && (
          <Grid container item spacing={1} xs={12}>
            <Grid item>
              <Typography variant="h6">{t("contracts")}</Typography>
            </Grid>
            <Grid item xs={12}>
              {state.contracts.length > 0 ? (
                <ContractsTable contracts={state.contracts} />
              ) : (
                <Typography variant="body1">{t("noContracts")}</Typography>
              )}
            </Grid>
          </Grid>
        )}

        <Grid container item spacing={1} xs={12} justifyContent="flex-end">
          <Grid item>
            <CustomButton onClick={() => history.goBack()} variant="text">
              {t("back")}
            </CustomButton>
          </Grid>
          {(user.hasAction("EDIT_BOXES") ||
            user.hasAction("EDIT_BOX_COMMENTS")) && (
            <Grid item>
              <CustomButton
                onClick={() => submitForm(state.form)}
                loading={state.loading.submitForm}
              >
                {t("save")}
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={state.bookingDialog.open}
        onClose={() => handleSetBookingDialog("open", false)}
        title={
          state.form.state === FREE_BOX_STATE_ID
            ? t("createBooking")
            : t("extendBooking")
        }
        actions={
          <CustomButton
            onClick={createOrExtendBooking}
            loading={state.loading.createBooking}
            color="success"
          >
            {state.form.state === FREE_BOX_STATE_ID
              ? t("createBooking")
              : t("extendBooking")}
          </CustomButton>
        }
      >
        <Grid item marginTop={1}>
          <CustomSelect
            options={[
              { label: "15 " + t("minutes"), value: 15 },
              { label: "30 " + t("minutes"), value: 30 },
              { label: "1 " + t("hour"), value: 60 },
              { label: "6 " + t("hours"), value: 360 },
              { label: "12 " + t("hours"), value: 720 },
              { label: "1 " + t("day"), value: 1440 },
              { label: "2 " + t("days"), value: 2880 },
            ]}
            label={t("time")}
            name="duration"
            value={state.bookingDialog.duration}
            onChange={handleBookingDialogInputChange}
          />
          <TextInput
            label={t("comments")}
            multiline
            rows={4}
            name="comments"
            onChange={handleBookingDialogInputChange}
            value={state.bookingDialog.comments}
          />
        </Grid>
      </Dialog>
    </Page>
  );
}

const EditableBoxForm = ({
  centerBoxClusters,
  form,
  inputError,
  handleInputChange,
  handleIsLockerChange,
}) => {
  const [t] = useTranslation("boxes");

  const boxPriceWithVAT =
    form.pricePerMeter * form.meters * SPANISH_VAT_MULTIPLIER;
  const clusterPriceWithVAT = form.cluster?.price * SPANISH_VAT_MULTIPLIER;
  const discount = clusterPriceWithVAT
    ? ((clusterPriceWithVAT - boxPriceWithVAT) / clusterPriceWithVAT) * 100
    : 0;

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          error={inputError.name}
          helperText={
            inputError.name ? t("name") + " " + t("mustNotBeBlank") : ""
          }
          label={t("name")}
          name="name"
          value={form.name}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          error={inputError.meters}
          helperText={
            inputError.meters ? t("meters") + " " + t("mustNotBeBlank") : ""
          }
          label={t("meters") + " (㎡)"}
          type="number"
          name="meters"
          value={form.meters}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          error={inputError.pricePerMeter}
          helperText={
            inputError.pricePerMeter
              ? t("pricePerMeter") + " " + t("mustNotBeBlank")
              : ""
          }
          label={t("pricePerMeter") + " (€)"}
          type="number"
          name="pricePerMeter"
          value={form.pricePerMeter}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          error={inputError.royalty}
          helperText={
            inputError.royalty ? t("royalty") + " " + t("mustNotBeBlank") : ""
          }
          label={t("royalty") + " (%)"}
          type="number"
          name="royalty"
          value={form.royalty}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          label={t("floor")}
          type="number"
          name="floor"
          value={form.floor}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Select
          label={t("zone")}
          name="zone"
          value={form.zone}
          onChange={handleInputChange}
          options={[
            { value: null, label: t("notAssigned") },
            ...BOX_ZONES.map((zone) => ({
              value: zone,
              label: zone,
            })),
          ]}
          autoWidth
        />
      </Grid>

      {centerBoxClusters && centerBoxClusters.length > 0 && (
        <Grid item xs={12} sm={6} md={4}>
          <Select
            label={t("cluster")}
            name="boxClusterId"
            value={form.boxClusterId}
            onChange={handleInputChange}
            options={[
              { value: "", label: t("notAssigned") },
              ...centerBoxClusters.map((bc) => ({
                value: bc.id,
                label: bc.name,
              })),
            ]}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={3}>
        <FormControlLabel
          label={t("isLocker")}
          control={
            <Checkbox
              name="isLocker"
              checked={form.isLocker}
              onChange={handleIsLockerChange}
            />
          }
        />
      </Grid>

      <Grid item container xs={12} spacing={2}>
        {form.cluster && (
          <Grid item>
            <BoxClusterChip name={form.cluster.name} />
          </Grid>
        )}
        <Grid item>
          <LabeledText
            label={t("priceWithVAT")}
            value={localeFormat(boxPriceWithVAT) + "€"}
          />
        </Grid>
        {form.cluster && (
          <>
            <Grid item>
              <LabeledText
                label={t("clusterPriceWithVAT")}
                value={localeFormat(clusterPriceWithVAT) + "€"}
              />
            </Grid>
            <Grid item>
              <LabeledText
                label={t("discount")}
                value={localeFormat(discount) + "%"}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const NonEditableBoxForm = ({ form }) => {
  const [t] = useTranslation("boxes");

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <LabeledText label={t("center")} value={form.Center?.name} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LabeledText
          label={t("meters")}
          value={localeFormat(form.meters) + "㎡"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LabeledText
          label={t("pricePerMeter")}
          value={localeFormat(form.pricePerMeter) + "€"}
        />
      </Grid>
      {form.cluster ? (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <LabeledText
              label={t("clusterPriceWithVAT")}
              value={
                localeFormat(form.cluster.price * SPANISH_VAT_MULTIPLIER) + "€"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LabeledText
              label={t("minPriceWithVAT")}
              value={localeFormat(form.price * SPANISH_VAT_MULTIPLIER) + "€"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LabeledText
              label={t("allowedDiscount")}
              value={localeFormat(form.discount) + "%"}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={6} md={4}>
          <LabeledText
            label={t("priceWithVAT")}
            value={localeFormat(form.price) + "€"}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          {form.cluster && <BoxClusterChip name={form.cluster.name} />}
          {form.zone && (
            <Chip
              size="small"
              variant="outlined"
              label={
                <LabeledText
                  label={t("zone")}
                  variant="body2"
                  value={form.zone}
                />
              }
              icon={<AdjustIcon />}
            />
          )}
          {form.floor && (
            <Chip
              variant="outlined"
              size="small"
              label={
                <LabeledText
                  label={t("floor")}
                  variant="body2"
                  value={form.floor}
                />
              }
              icon={<LayersIcon />}
            />
          )}
          {form.isLocker && (
            <Chip
              variant="outlined"
              size="small"
              label={t("locker")}
              icon={<SensorDoorIcon />}
            />
          )}
          {form.royalty ? (
            <Chip
              size="small"
              variant="outlined"
              label={
                <LabeledText
                  label={t("royalty")}
                  variant="body2"
                  value={form.royalty + "%"}
                />
              }
              icon={<PercentIcon />}
            />
          ) : undefined}
        </Stack>
      </Grid>
    </>
  );
};

const BoxStateSwitch = ({ state, handleBoxStateChange }) => {
  const [t] = useTranslation("boxes");

  const getFormControlLabel = () => {
    if ([FREE_BOX_STATE_ID, UNAVAILABLE_BOX_STATE_ID].includes(Number(state)))
      return t("unavailable");
    else if (
      [OCCUPIED_BOX_STATE_ID, BLOCKED_BOX_STATE_ID].includes(Number(state))
    )
      return t("blocked");
    else return "?";
  };

  return (
    <FormControlLabel
      label={getFormControlLabel()}
      control={
        <Checkbox
          checked={
            [UNAVAILABLE_BOX_STATE_ID, BLOCKED_BOX_STATE_ID].includes(
              Number(state)
            ) || false
          }
          onChange={handleBoxStateChange}
        />
      }
    />
  );
};

const BoxBookingInfo = ({ box, user, endBooking, loading }) => {
  const [t] = useTranslation("boxes");
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const activeBooking = box.BoxBookings?.find(
    (booking) => booking.remainingMinutes > 0
  );

  const userCanEndBoxBooking =
    user?.id === activeBooking?.userId ||
    user.hasAction("END_ANY_BOX_BOOKINGS");

  return (
    <Grid item xs={12}>
      <Collapse in={isVisible}>
        <Alert
          severity="info"
          action={
            userCanEndBoxBooking && (
              <CustomButton
                variant="text"
                onClick={() => setIsConfirmOpen(true)}
                loading={loading.endBooking}
              >
                {t("endBooking")}
              </CustomButton>
            )
          }
        >
          <AlertTitle>{`${t("bookingId")}: ${activeBooking?.id}`}</AlertTitle>
          {`${t("bookedBy")} ${activeBooking.User?.name} ${t(
            "with"
          )} ${formatTime(activeBooking?.remainingMinutes)} ${t("left")}. ${
            activeBooking?.comments
              ? t("comments") + ": " + activeBooking?.comments
              : ""
          }`}
        </Alert>
      </Collapse>

      <ConfirmDialog
        title={t("confirmEnding")}
        open={isConfirmOpen}
        setOpen={() => setIsConfirmOpen()}
        confirmText={t("yes")}
        cancelText={t("no")}
        onConfirm={(confirmed) => {
          confirmed && endBooking(activeBooking?.id);
        }}
      >
        {`${t("bookedBy")} ${activeBooking.User?.name} ${t(
          "with"
        )} ${formatTime(activeBooking?.remainingMinutes)} ${t("left")}`}
      </ConfirmDialog>
    </Grid>
  );
};

const ContractsTable = ({ contracts }) => {
  const [t] = useTranslation("contracts");

  const CONTRACT_COLUMNS = [
    { label: "ID", key: "publicId" },
    { label: t("customerName"), key: "Customer.fullName" },
    { label: t("startDate"), key: "startDate" },
    {
      label: t("pricePerMeter"),
      key: "pricePerMeter",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      label: t("priceWithVAT"),
      key: "price",
      renderFunction: (value) =>
        localeFormat((value * (100 + GENERAL_VAT_ES)) / 100) + "€",
    },
    {
      label: t("state"),
      key: "state",
      renderFunction: (value) => <ContractStateChip state={value} />,
    },
  ];

  return (
    <CustomTable
      columns={CONTRACT_COLUMNS}
      data={contracts}
      options={{
        rowlink: "contract",
      }}
    />
  );
};
