import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { toPng } from "html-to-image";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { CustomTable } from "../../../CustomTable";

import {
  chartDataGenerator,
  dataParser,
  parsePercentageData,
} from "../../../../utils/chartUtils";

import Bar from "../../../global/charts/Bar";
import Doughnut from "../../../global/charts/Doughnut";
import Line from "../../../global/charts/Line";
import Pie from "../../../global/charts/Pie";

const CHARTS = [Bar, Doughnut, Line, Pie];

export const NewChartContainer = (props) => {
  const [t] = useTranslation("dashboard");

  const {
    title,
    subtitle,
    data = [],
    groupBy,
    chart = 0,
    config = {},
    options = {},
    percentage = false,
    children,
  } = props;
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isRawView, setIsRawView] = useState(false);

  const anchorMenuEl = useRef(null);
  const csvRef = useRef(null);
  const containerRef = useRef(null);

  const parsedData = dataParser(data, groupBy, config);
  const chartData = chartDataGenerator(parsedData, groupBy, config);
  const convertedData = parsePercentageData(chartData, percentage);

  const ChartComponent = CHARTS[chart];
  const dataColumns = Object.keys(parsedData[0] || []).map((key) => ({
    key: key,
    label: t(key),
  }));
  const openMenu = () => {
    setIsMenuActive(true);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  const printImage = () => {
    if (containerRef.current === null) {
      return;
    }

    toPng(containerRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${title}_chart.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadXlsx = () => {
    const worksheet = XLSX.utils.json_to_sheet(parsedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${title}_data.xlsx`);
  };

  return (
    <Card ref={containerRef} sx={{ p: 1 }}>
      {props.loading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : (
        <>
          <CardHeader
            title={title}
            subheader={subtitle}
            action={
              <Box>
                <IconButton
                  ref={anchorMenuEl}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={openMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorMenuEl.current}
                  keepMounted
                  open={isMenuActive}
                  onClose={closeMenu}
                >
                  {isRawView ? (
                    <MenuItem
                      onClick={() => {
                        setIsRawView(false);
                      }}
                      key="chart"
                    >
                      {t("showChart")}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        setIsRawView(true);
                      }}
                      key="table"
                    >
                      {t("showTable")}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      printImage();
                      closeMenu();
                    }}
                    key="img"
                  >
                    {t("downloadImage")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      csvRef.current.link.click();
                      closeMenu();
                    }}
                    key="csv"
                  >
                    {t("downloadCsv")}
                    <CSVLink
                      data={parsedData}
                      headers={dataColumns}
                      filename={`${title}_data.csv`}
                      ref={csvRef}
                      separator=";"
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      downloadXlsx();
                    }}
                    key="xlsx"
                  >
                    {t("downloadXlsx")}
                  </MenuItem>
                </Menu>
              </Box>
            }
          />
          {children}
          {isRawView ? (
            <CustomTable
              allRowsOption={false}
              columns={dataColumns}
              data={parsedData}
              linesPerPage={5}
            />
          ) : (
            <ChartComponent data={convertedData} options={options} />
          )}
        </>
      )}
    </Card>
  );
};
