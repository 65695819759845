import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";

import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

import CenterTypeSelect from "../../Inputs/CenterTypeSelect";
import CreateButton from "../../Inputs/CreateButton";
import CustomSelect from "../../Inputs/CustomSelect";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import Link from "../../Inputs/Link";
import TextInput from "../../Inputs/TextInput";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";
import Select from "../../global/inputs/Select";

import { CustomTable } from "../../CustomTable";
import CentersMap from "./CentersMap";

import { getParams, generateURL } from "../../../utils/url";

const DEFAULT__CENTER_IMG = "/img/default_center_img.jpg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CenterCard({ center }) {
  return (
    <Card
      variant="outlined"
      component={Link}
      to={"/app/center/" + center.id}
      sx={{ width: 600 }}
    >
      <CardMedia
        component="img"
        height="200"
        title={center.name}
        image={center.picture || DEFAULT__CENTER_IMG}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography variant="h6">{center.name}</Typography>
          </Grid>

          <Grid item xs={1}>
            <PhoneIcon />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">
              {" "}
              {center.CenterPhoneNumbers?.length > 0 &&
                center.CenterPhoneNumbers[0].number}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <BusinessIcon />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1"> {center.address}</Typography>
          </Grid>

          <Grid item xs={1}>
            <EmailIcon />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">
              {" "}
              {center.CenterEmails?.length > 0 && center.CenterEmails[0].email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const initialState = {
  centers: [],
  centerCompanies: [],
  filters: {
    name: "",
    state: 1,
    type: "",
    currentTab: 0,
    centerCompanyIds: [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CENTER_COMPANIES":
      return { ...state, centerCompanies: action.payload };
    case "SET_CURRENT_TAB":
      return {
        ...state,
        filters: { ...state.filters, currentTab: action.payload },
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, loaded: true };
    case "SET_LOADED_FALSE":
      return { ...state, loaded: false };
    default:
      throw new Error();
  }
}

export default function CentersPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("centers");
  const [tErrors] = useTranslation("errors");

  const filters = [
    { name: "name", type: "string" },
    { name: "type", type: "number" },
    { name: "state", type: "number" },
    { name: "currentTab", type: "number" },
  ];

  const initState = (state) => {
    const queryParams = { ...getParams(query, filters) };
    filters.forEach((filter) => {
      if (queryParams[filter.name] !== undefined && filter.type === "number") {
        queryParams[filter.name] =
          queryParams[filter.name] === "" ||
          queryParams[filter.name] === null ||
          queryParams[filter.name] === "null"
            ? ""
            : Number(queryParams[filter.name]);
      }
    });
    return { ...state, filters: { ...state.filters, ...queryParams } };
  };
  const [state, dispatch] = useReducer(reducer, initialState, initState);

  //Initial useEffect
  useEffect(() => {
    getCenters();
    getCenterCompanies();
  }, []);

  // Filter centers
  const filteredCenters = state.centers
    .filter(
      (center) =>
        center.name
          .toUpperCase()
          .match(new RegExp(`${state.filters.name.toUpperCase()}`)) ||
        center.address
          .toUpperCase()
          .match(new RegExp(`${state.filters.name.toUpperCase()}`))
    )
    .filter(
      (center) =>
        center.type === state.filters.type || state.filters.type === ""
    )
    .filter(
      (center) =>
        state.filters.state === "" ||
        (state.filters.state === 0 &&
          (!center.active || center.active === false)) ||
        (state.filters.state === 1 && center.active === true)
    )
    .filter(
      (center) =>
        state.filters.centerCompanyIds.length === 0 ||
        state.filters.centerCompanyIds.includes(center.centerCompanyId)
    );

  const CENTERS_COLUMNS = [
    {
      key: "name",
      label: t("name"),
      sortType: "string",
    },
    {
      key: "City.name",
      label: t("city"),
      sortType: "string",
    },
    {
      key: "address",
      label: t("address"),
      sortType: "string",
    },
    {
      key: "type",
      label: t("type"),
      sortType: "other",
      renderFunction: (value) => (
        <Chip
          size="small"
          color={!value ? "primary" : "default"}
          label={!value ? t("NUT") : t("franchise")}
        />
      ),
    },
    {
      key: "stateName",
      label: t("state"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Chip
          size="small"
          color={item.active ? "success" : "error"}
          label={value}
        />
      ),
    },
    {
      key: "phoneNumbers",
      label: t("phoneNumber"),
      sortType: "string",
    },
    {
      key: "emails",
      label: t("email"),
      sortType: "string",
    },
  ];

  const getCenters = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    const params = { showInactive: true, include: ["City"] };
    api
      .get("/centers", { params })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        }
        if (response.data.length > 0) {
          response.data.forEach((center) => {
            center.phoneNumbers = center?.CenterPhoneNumbers.map(
              (num) => num.number
            ).join(center?.CenterPhoneNumbers.length > 0 ? ", " : "");
            center.emails = center?.CenterEmails.map((mail) => mail.email).join(
              center?.CenterEmails.length > 0 ? ", " : ""
            );
            center.stateName = center.active ? t("active") : t("noActive");
          });
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        } else {
          enqueueSnackbar(t("noCenters"), { variant: "warning" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getCenterCompanies = () => {
    api
      .get("/center-companies")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTER_COMPANIES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const handleTabChange = (event, value) => {
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: value,
    });
  };

  const updateQuery = (e, v) => {
    const url = generateURL("/app/centers", {
      ...state.filters,
      [e.target.name]: e.target.value,
    });
    history.push(url);
  };

  return (
    <Page title={t("centers")} browserTitle={t("centersPage")}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Tabs
            name="currentTab"
            value={state.filters.currentTab}
            onChange={(e, v) => {
              handleTabChange(e, v);
              updateQuery({ target: { name: "currentTab", value: v } });
            }}
          >
            <Tab label={t("map")} id={0} />
            <Tab label={t("table")} id={1} />
          </Tabs>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item>
            <Filters
              filters={[
                <TextInput
                  label={t("name")}
                  value={state.filters.name}
                  name="name"
                  onChange={(e) => {
                    handleFilterChange(e);
                    updateQuery(e);
                  }}
                />,
                <CenterTypeSelect
                  name="type"
                  onChange={(e) => {
                    handleFilterChange(e);
                    updateQuery(e);
                  }}
                  value={state.filters.type}
                />,
                <CustomSelect
                  name="state"
                  onChange={(e) => {
                    handleFilterChange(e);
                    updateQuery(e);
                  }}
                  label={t("state")}
                  value={state.filters.state}
                  options={[
                    { value: "", label: t("all") },
                    { value: 0, label: t("noActive") },
                    { value: 1, label: t("active") },
                  ]}
                />,
                <Select
                  name="centerCompanyIds"
                  label={t("company")}
                  multiple
                  value={state.filters.centerCompanyIds}
                  options={state.centerCompanies.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  onChange={(e) => {
                    handleFilterChange(e);
                    updateQuery(e);
                  }}
                />,
              ]}
            />
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <ExportButton
                data={exportDataParse(filteredCenters, CENTERS_COLUMNS)}
                display={user.hasAction("EXPORT_DATA")}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} sm="auto">
            <CreateButton
              action={"CREATE_CENTERS"}
              label={t("createCenter")}
              link={"/center/create"}
            />
          </Grid>
        </Grid>
        {state.filters.currentTab === 0 && (
          <Grid item container spacing={2} xs={12}>
            <Grid item container xs={12} md={7} sx={{ mb: 3 }}>
              <Grid item xs={12}>
                <CentersMap
                  reload={true}
                  data={filteredCenters?.map((center) => {
                    const description = `<b>${center.name}</b><p>${center.comments}</p>`;
                    return {
                      active: center.active,
                      lng: center.longitude,
                      lat: center.latitude,
                      name: center.name,
                      description,
                      id: center.id,
                    };
                  })}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} sx={{ mb: 3 }}>
              <Grid item xs={12} style={{ height: "80vh", overflow: "auto" }}>
                {state.loaded ? (
                  <List spacing={3}>
                    {filteredCenters?.map((center) => {
                      return (
                        <ListItem key={center.id} id={center.id}>
                          <CenterCard center={center} />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {state.filters.currentTab === 1 && (
          <Grid item container spacing={2} xs={12} marginTop={3}>
            <CustomTable
              data={filteredCenters}
              columns={CENTERS_COLUMNS}
              linesPerPage={25}
              options={{ rowlink: "center" }}
            />
          </Grid>
        )}
      </Grid>
    </Page>
  );
}
