import {
  Card,
  CardContent,
  CardHeader,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableFooter,
  TableHead,
  TablePagination,
  Typography,
  Button,
} from "@mui/material";
import { ChartContainer } from "./ChartContainer";
import { ColorGenerator } from "../Components/ColorGenerator";
import CustomSelect from "../../../Inputs/CustomSelect";
import { CustomTable } from "../../../CustomTable";
import { distinctValues, getDates, months } from "../../../../utils/chartUtils";
import { Line } from "react-chartjs-2";
import React, { useReducer, useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ25vbWJvIiwiYSI6ImNrNGszYXlxZjBjdGozanFpOGFpZno0bWEifQ.Gchc2G72ohn5xofBIq5agg";

const reducer = (state, action) => {
  switch (action.type) {
    default:
      throw new Error();
  }
};

const initialState = {
  actualPrice: {
    precioReal: 10,
    precioSinDesc: 10,
    precioBase: 10,
  },
  byCenter: {
    data: [
      { id: 1, center: "center1", precioReal: 100, precioBase: 80 },
      { id: 2, center: "center2", precioReal: 100, precioBase: 80 },
      { id: 3, center: "center3", precioReal: 100, precioBase: 80 },
      { id: 4, center: "center4", precioReal: 100, precioBase: 80 },
    ],
  },
  historical: {
    data: [10, 20, 30, 10, 20, 40],
  },
};

export const MeterPriceByCenter = () => {
  const [state] = useReducer(reducer, initialState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, state.byCenter.data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ChartContainer title="Precio metro delegaciones">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Delegacion</TableCell>
              <TableCell align="left">Precio Real (ocupados)</TableCell>
              <TableCell align="left">
                Precio Base (todos los trasteros)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? state.byCenter.data.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : state.byCenter.data
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.center}
                </TableCell>
                <TableCell align="left">{row.precioReal}</TableCell>
                <TableCell align="left">{row.precioBase}</TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={state.byCenter.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </ChartContainer>
  );
};

export const MeterPriceHistorical = () => {
  const [state] = useReducer(reducer, initialState);

  const data = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"],
    datasets: [
      {
        label: "Precio Metro",
        data: state.historical.data,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        backgroundColor: ColorGenerator("#b3d1ff", "#003d99", 4),
      },
    ],
  };

  return (
    <ChartContainer title="Historico precio metro">
      <Line
        data={data}
        options={{
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </ChartContainer>
  );
};

export const MeterPriceActual = () => {
  const [state] = useReducer(reducer, initialState);

  return (
    <Card>
      <CardHeader title="Precio metro actual" />
      <CardContent>
        <Typography variant="body1">
          {"Precio metro real (ocupados): " + state.actualPrice.precioReal}
        </Typography>
        <Typography variant="body1">
          {"Precio metro sin descuento (ocupados sin contar precio especial): " +
            state.actualPrice.precioSinDesc}
        </Typography>
        <Typography variant="body1">
          {"Precio metro base (todos los trasteros): " +
            state.actualPrice.precioBase}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const MeterPriceAverage = ({ contracts }) => {
  const calculateAverage = (contracts) => {
    let totalPrice = 0;
    let totalMeters = 0;

    contracts?.forEach((contract) => {
      totalPrice += contract.pricePerMeter * contract.meters;
      totalMeters += contract.meters;
    });

    return (totalPrice / totalMeters).toFixed(2);
  };
  const average = calculateAverage(contracts);

  return (
    <Card>
      <CardHeader title="Precio metro medio" />
      <CardContent>
        <Typography variant="h6">{average} €</Typography>
      </CardContent>
    </Card>
  );
};

export const MeterPriceByCenterByYear = ({ contracts, periods }) => {
  const centers = distinctValues(
    contracts.map((contract) => contract.Box.centerId)
  );

  const rawData = [];
  centers.forEach((centerId) => {
    let group = contracts.filter(
      (contract) => contract.Box.centerId === centerId
    );

    rawData.push(group);
  });

  const getMinDate = (contracts) => {
    return contracts.sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    )[0].startDate;
  };

  const [groupBy, setGroupBy] = useState(1);
  const [period, setPeriod] = useState("month");
  let dates = [];
  if (contracts.length > 0)
    dates = getDates(getMinDate(contracts), new Date(), period);

  const data = rawData.map((centerContracts) => {
    let row = { center: centerContracts[0].Box.Center.name };
    dates.forEach((date) => {
      let totalPrice = 0;
      let totalMeters = 0;
      centerContracts.forEach((contract) => {
        if (
          new Date(contract.startDate) < new Date(date) &&
          (contract.endDate === null ||
            new Date(contract.startDate) > new Date(date))
        ) {
          totalPrice += contract.pricePerMeter * contract.meters;
          totalMeters += contract.meters;
        }
      });
      if (totalMeters === 0) row[date] = 0;
      else row[date] = (totalPrice / totalMeters).toFixed(2);
    });
    return row;
  });

  const getColumns = (dates) => {
    let cols = dates.map((date) => ({ label: date, key: date }));
    cols.unshift({ label: "Center", key: "center", sortType: "string" });
    return cols;
  };

  let columns = getColumns(dates);

  return (
    <Card>
      <CardHeader title="Precio por metro por fecha" />
      <CardContent>
        <CustomSelect
          label="Group by"
          value={groupBy}
          onChange={(e) => {
            setGroupBy(e.target.value);
            switch (e.target.value) {
              case 1:
                setPeriod("month");
                break;
              // case 2: setPeriod("quarter"); break;
              // case 3: setPeriod("half"); break;
              case 4:
                setPeriod("year");
                break;
              default:
            }
          }}
          style={{ width: 200 }}
          options={periods.map((period) => ({
            value: period.id,
            label: period.name,
          }))}
        />
        <CustomTable
          columns={columns}
          data={data}
          options={{ rowsPerPage: 10 }}
        />
      </CardContent>
    </Card>
  );
};

export const PriceMap = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-3);
  const [lat, setLat] = useState(40);
  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      //style: "mapbox://styles/gnombo/ckwc9yuhv0srg15suxzvmiqqn",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    /*map.current.on('mousemove', (event) => {
      const states = map.current.queryRenderedFeatures(event.point, {
        layers: ['esp-bofljv']
      });
    });*/
  });

  const drawProvinces = () => {
    map.current.addSource("mapId", {
      type: "geojson",
      data: provincesGJ,
    });

    map.current.addLayer({
      id: "provinces",
      type: "fill",
      source: "mapId",
      paint: {
        "fill-color": [
          "step",
          ["get", "provnum_ne"],
          "#110055",
          0,
          "#002280",
          1,
          "#008800",
          2,
          "#112307",
          3,
          "#fd8d3c",
          4,
          "#475577",
          5,
          "#770078",
          6,
          "hsl(348, 100%, 37%)",
          7,
          "#453878",
          8,
          "#776301",
          9,
          "#110044",
          10,
          "#bd0026",
          11,
          "#209900",
          12,
          "#779933",
          13,
          "#101044",
          14,
          "#112288",
          15,
          "#bd0026",
          16,
          "#456823",
          17,
          "#15099d",
        ],
      },
    });
  };

  return (
    <>
      <div ref={mapContainer} style={{ width: 400, height: 400 }}>
        hola
      </div>
      <Button onClick={drawProvinces}>Pintar Capa</Button>
      <Button
        onClick={() => {
          console.log(provincesGJ.features);
        }}
      >
        Pintar Capa
      </Button>
    </>
  );
};

const provincesGJ = {
  type: "FeatureCollection",

  features: [
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP+99?",
        OBJECTID_1: 6273,
        diss_me: 5819,
        adm1_cod_1: "ESP+99?",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 8,
        name: null,
        name_alt: null,
        name_local: null,
        type: null,
        type_en: null,
        code_local: null,
        code_hasc: "-99",
        note: "ESP-99 (Spain minor island)",
        hasc_maybe: null,
        region: null,
        region_cod: "-99",
        provnum_ne: 0,
        gadm_level: 0,
        check_me: 0,
        scalerank: 11,
        datarank: 11,
        abbrev: null,
        postal: null,
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 20,
        featurecla: "Admin-1 minor island",
        name_len: 0,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: null,
        fips_alt: null,
        woe_id: -99,
        woe_label: null,
        woe_name: null,
        latitude: 35.1721,
        longitude: -4.2993899999999998,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 8,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 0,
        gn_name: null,
        gns_id: -99,
        gns_name: null,
        gn_level: 0,
        gn_region: null,
        gn_a1_code: "ES.",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.296899558999883, 35.172249026000159],
              [-4.297730463999869, 35.171536821000032],
              [-4.299392273999985, 35.17165552199999],
              [-4.300935382999853, 35.172070974000079],
              [-4.301884987999898, 35.172664478000073],
              [-4.301054082999883, 35.172901879],
              [-4.300045127999908, 35.172723828000116],
              [-4.298700709999963, 35.172612020000187],
              [-4.296899558999883, 35.172249026000159],
            ],
          ],
          [
            [
              [-2.438839973999904, 35.179074635000134],
              [-2.44078047799988, 35.176089243000106],
              [-2.446303452999899, 35.17698486100015],
              [-2.445109295999941, 35.179820983000141],
              [-2.440929747999888, 35.18101513900011],
              [-2.438839973999904, 35.179074635000134],
            ],
          ],
          [
            [
              [-2.418142894999932, 35.179725365000152],
              [-2.42142682599993, 35.175993626000107],
              [-2.425009295999871, 35.179426826000125],
              [-2.421874634999881, 35.183009296000179],
              [-2.418142894999932, 35.179725365000152],
            ],
          ],
          [
            [
              [-2.426501991999942, 35.182113679000125],
              [-2.431414170999858, 35.178765584000061],
              [-2.435858709999877, 35.181189878000097],
              [-2.434242513999919, 35.185230367000187],
              [-2.42939392699995, 35.186038465000152],
              [-2.426501991999942, 35.182113679000125],
            ],
          ],
          [
            [
              [-3.901956849999948, 35.215464452000035],
              [-3.902532578999882, 35.214787124000011],
              [-3.90313178999989, 35.215038701000068],
              [-3.902962458999923, 35.215309632000086],
              [-3.902329379999856, 35.215904714000132],
              [-3.901956849999948, 35.215464452000035],
            ],
          ],
          [
            [
              [-3.899953471999908, 35.217860931000146],
              [-3.89981800699988, 35.217285203000031],
              [-3.900495333999885, 35.217352935000136],
              [-3.901003328999906, 35.217217470000108],
              [-3.901308126999908, 35.217319069],
              [-3.901274259999866, 35.217623866000068],
              [-3.900732397999889, 35.217827065],
              [-3.899953471999908, 35.217860931000146],
            ],
          ],
          [
            [
              [-5.415969540999896, 35.91353101900016],
              [-5.417888241999918, 35.910874356000178],
              [-5.4202497199999, 35.91161231800011],
              [-5.420840089999928, 35.91426898100012],
              [-5.418773795999925, 35.91559731200006],
              [-5.415969540999896, 35.91353101900016],
            ],
          ],
          [
            [
              [-3.036411089999973, 35.94436840200008],
              [-3.037927320999927, 35.942515231000144],
              [-3.039780491999863, 35.943862991000017],
              [-3.038769670999898, 35.945547692000147],
              [-3.036411089999973, 35.94436840200008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5801",
        OBJECTID_1: 1367,
        diss_me: 5801,
        adm1_cod_1: "ESP-5801",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Álava",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.PV",
        note: null,
        hasc_maybe: null,
        region: "País Vasco",
        region_cod: "ES.PV",
        provnum_ne: 18,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "PV",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP94",
        fips_alt: "SP59",
        woe_id: 12602134,
        woe_label: "Basque Country, ES, Spain",
        woe_name: "País Vasco",
        latitude: 42.8902,
        longitude: -2.6966000000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3130717,
        gn_name: "Provincia de Alava",
        gns_id: -369579,
        gns_name: "Alava, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.VI",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP59",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.895385089750107, 43.047481881583593],
            [-2.862678789294705, 43.048822214073908],
            [-2.851198076430052, 43.046877634295384],
            [-2.82925837857124, 43.037165719722196],
            [-2.78186335670847, 43.026706734728037],
            [-2.738368482319657, 43.033155709268556],
            [-2.697905834086953, 43.020620308380501],
            [-2.686897533294513, 43.021477242680561],
            [-2.667946116220293, 43.027234078290093],
            [-2.640524240201501, 43.022718697409886],
            [-2.636646065863232, 43.022839547407187],
            [-2.633130440617265, 43.024872017929283],
            [-2.629801583876002, 43.035473825156885],
            [-2.635239815764891, 43.047141305626724],
            [-2.657256418249148, 43.069751170180297],
            [-2.658662668347461, 43.07289326021575],
            [-2.65684992468428, 43.075760691902332],
            [-2.637437081656003, 43.085472606475605],
            [-2.622451729663652, 43.087658885349271],
            [-2.600237373456054, 43.08146259602205],
            [-2.546547185404222, 43.088043406677826],
            [-2.532232000106717, 43.04726215562394],
            [-2.533660222441256, 43.034452096387099],
            [-2.541306707238675, 43.025761910583697],
            [-2.588921455061097, 43.010117378913918],
            [-2.595623115714204, 43.003767280335666],
            [-2.602819160675693, 42.982354926038994],
            [-2.559016668684308, 42.96283222093021],
            [-2.554819891524801, 42.962821233912791],
            [-2.549392644854606, 42.964590033103548],
            [-2.537780095874695, 42.972796820716667],
            [-2.535187321637608, 42.973379095768678],
            [-2.529188786932991, 42.969138373237314],
            [-2.50312921564074, 42.960623968921055],
            [-2.354703917152051, 42.950099065419522],
            [-2.308407528684484, 42.928598820379278],
            [-2.290126277405733, 42.905977970606841],
            [-2.281194392507189, 42.900990176755187],
            [-2.252957728915987, 42.900069078525149],
            [-2.239134283920009, 42.858314520898404],
            [-2.239780239066732, 42.849426173978685],
            [-2.242312383709475, 42.839194240821598],
            [-2.26342220696921, 42.813717760266371],
            [-2.274429287381679, 42.790980129929068],
            [-2.276522183352085, 42.776045640893585],
            [-2.276987271345433, 42.759638373512132],
            [-2.279416063200728, 42.750930895544315],
            [-2.284919602957274, 42.744678046054929],
            [-2.312075568332773, 42.737753403896505],
            [-2.32036963515057, 42.728994249085332],
            [-2.320705531934721, 42.721010239730731],
            [-2.312023892388709, 42.682924710107272],
            [-2.306856247617759, 42.680082506202695],
            [-2.295926683369714, 42.677912095866503],
            [-2.293161993830921, 42.67202098248228],
            [-2.292696905837545, 42.663080960517874],
            [-2.298458828012485, 42.657758287015298],
            [-2.32843115887087, 42.642978826711442],
            [-2.338301356822171, 42.639490668559432],
            [-2.348094041307007, 42.638121243000967],
            [-2.365870734246982, 42.637914537426013],
            [-2.372588669931162, 42.641841946048942],
            [-2.380960252014006, 42.652849026461325],
            [-2.38530107268636, 42.656130479038353],
            [-2.391037157338957, 42.658585110214631],
            [-2.399512091309987, 42.659928697351461],
            [-2.408710495692787, 42.660212918191576],
            [-2.418012253762555, 42.657525743018624],
            [-2.437856004251216, 42.645562649097002],
            [-2.450671758894515, 42.634813951102927],
            [-2.470179612598997, 42.622850857181334],
            [-2.478680385891039, 42.619104315711553],
            [-2.492736374883776, 42.609337470547814],
            [-2.494545050013983, 42.601999417239341],
            [-2.493278978142285, 42.595307319077506],
            [-2.488085495848907, 42.589907132108436],
            [-2.481367560164728, 42.585876369798569],
            [-2.473357713287839, 42.584713649815058],
            [-2.458009813102422, 42.578124905340076],
            [-2.450051642169512, 42.576006170947977],
            [-2.44173173693008, 42.578977566061752],
            [-2.421319545660481, 42.598795478128807],
            [-2.413309698783536, 42.598278714191281],
            [-2.405868292687501, 42.596108302955741],
            [-2.400674811293499, 42.590733954408506],
            [-2.39806515138568, 42.583964341880886],
            [-2.394008550654064, 42.564973253013164],
            [-2.402871060051353, 42.512237454295047],
            [-2.40607499826254, 42.497096258785319],
            [-2.427133144678862, 42.499370021908959],
            [-2.462040575412203, 42.507250678475998],
            [-2.491651170165341, 42.520092271541102],
            [-2.501521369015933, 42.537662258006748],
            [-2.510616420611257, 42.535233466151567],
            [-2.518057826707292, 42.531047675110045],
            [-2.524026455356648, 42.52510488488258],
            [-2.528832363123058, 42.51717255327074],
            [-2.526506924055269, 42.515131334143774],
            [-2.52531836564998, 42.514304510944356],
            [-2.524181484088189, 42.513219306226006],
            [-2.522036912173746, 42.510351263899707],
            [-2.541648117766442, 42.512366645504244],
            [-2.557745326785437, 42.510842190314776],
            [-2.571336228684004, 42.505622870499053],
            [-2.583454352236515, 42.496682848534562],
            [-2.58513383615761, 42.511979071876652],
            [-2.595055710952266, 42.517508450054919],
            [-2.606166144152212, 42.516862494008933],
            [-2.61138546396802, 42.513761909484515],
            [-2.61787085565544, 42.507715766469374],
            [-2.632081875178358, 42.51252167423587],
            [-2.667454393005869, 42.533166409502257],
            [-2.676756151075438, 42.536887112550232],
            [-2.684662645164934, 42.537765610794338],
            [-2.687737393066186, 42.553423570241563],
            [-2.687246465751741, 42.567918809705333],
            [-2.685179409102432, 42.574714259755268],
            [-2.685644497095893, 42.581793932443986],
            [-2.689132656147194, 42.589493720059153],
            [-2.699442103670151, 42.597710273410343],
            [-2.733987799197536, 42.615719509447842],
            [-2.747397833942841, 42.618690904561618],
            [-2.761841395663879, 42.614505113520096],
            [-2.769670376286797, 42.608820705711025],
            [-2.773391079334857, 42.601560166768252],
            [-2.779979823809754, 42.585617988279552],
            [-2.793493211342621, 42.586909898572941],
            [-2.805973070101118, 42.582362372325548],
            [-2.813001064147755, 42.580812078714388],
            [-2.821036750345712, 42.58427440024343],
            [-2.82682451004311, 42.59019135204926],
            [-2.826772834098961, 42.596004951067641],
            [-2.814783900856327, 42.605875149018942],
            [-2.812665168262868, 42.611094468834665],
            [-2.816230840780662, 42.620215358851709],
            [-2.829925096366082, 42.613807481530046],
            [-2.836022915325259, 42.621455593201063],
            [-2.837469855249594, 42.634452215896971],
            [-2.837314825618677, 42.644115709172695],
            [-2.841707323134443, 42.655820421575186],
            [-2.852456021128518, 42.6654063986864],
            [-2.917930060124149, 42.705791530754595],
            [-2.943354864735227, 42.714343979990673],
            [-2.968676316558799, 42.737753403896505],
            [-2.984127570431014, 42.743127753343117],
            [-3.003196173664492, 42.753230496190497],
            [-3.031437343758341, 42.772738348995631],
            [-3.063502569687842, 42.785295722119884],
            [-3.093991665382987, 42.774443671338332],
            [-3.119571498725691, 42.768707586685736],
            [-3.123860642554604, 42.770051173822594],
            [-3.13631466289138, 42.769896145091053],
            [-3.132232224637448, 42.777389228030415],
            [-3.129002448004542, 42.786251736528413],
            [-3.134454311817052, 42.793228053731724],
            [-3.141585658651252, 42.797956448031641],
            [-3.147735155353075, 42.803692531784947],
            [-3.15186926865178, 42.810152085950023],
            [-3.151197475982741, 42.817593492045972],
            [-3.147683477610457, 42.825396633347879],
            [-3.141301438710428, 42.834698391417618],
            [-3.130216843932203, 42.845214545414933],
            [-3.119313117206588, 42.858314520898404],
            [-3.112543503779705, 42.868262234114781],
            [-3.110424771186246, 42.879605211311485],
            [-3.114222987700686, 42.886917426198238],
            [-3.121225145124356, 42.890328070883754],
            [-3.130216843932203, 42.888622748540968],
            [-3.144221156980791, 42.873533229874653],
            [-3.151481695923565, 42.868107205383154],
            [-3.159078131650404, 42.864619046331853],
            [-3.178120897361453, 42.865239163056941],
            [-3.187551845741041, 42.863714707867473],
            [-3.195690883827211, 42.860329902503054],
            [-3.202718878773197, 42.855058905843805],
            [-3.207369756908719, 42.848857734097123],
            [-3.210909592803432, 42.842863267925424],
            [-3.209979416816623, 42.83728221290383],
            [-3.211917284055374, 42.832062893088107],
            [-3.217394985390314, 42.829737454020318],
            [-3.224293789127103, 42.831830349091319],
            [-3.231450975282314, 42.835886948923559],
            [-3.261810878868914, 42.864929103795106],
            [-3.275918544705064, 42.874695949858136],
            [-3.280078498224071, 42.880354519245685],
            [-3.279096646293198, 42.887201646139061],
            [-3.275453456711631, 42.893919582722532],
            [-3.270259976216892, 42.900947576769255],
            [-3.256023118272253, 42.912264716443445],
            [-3.244421758657239, 42.924615383093482],
            [-3.2399775860967, 42.931565864573003],
            [-3.233182135147388, 42.938154609047984],
            [-3.224112921973784, 42.943787340013728],
            [-3.188326992097018, 42.94673289580652],
            [-3.175950487025375, 42.945079251206408],
            [-3.166054449753091, 42.942857164026918],
            [-3.157450323673459, 42.939343167453188],
            [-3.151197475982741, 42.934278876369007],
            [-3.146494920104431, 42.921256415251293],
            [-3.141043057191325, 42.916088772279011],
            [-3.133059047836781, 42.913039862799451],
            [-3.123912319398045, 42.910766100575074],
            [-3.104146084174403, 42.908518174973779],
            [-3.094405077432384, 42.906502794268505],
            [-3.084741584156774, 42.905546779859975],
            [-3.075284797355579, 42.905572618281695],
            [-3.058980881862254, 42.908673203705405],
            [-3.037896897923503, 42.908854071757929],
            [-3.029189419056507, 42.910249334838909],
            [-3.021696336117031, 42.914460965201414],
            [-3.008958095839432, 42.923271795956623],
            [-3.000870733697411, 42.923271795956623],
            [-2.987460699851482, 42.926320706335474],
            [-2.982267217558018, 42.929395453337463],
            [-2.97906328024618, 42.933607082800592],
            [-2.977125413007343, 42.939033108191353],
            [-2.978520676987699, 42.944484972003863],
            [-2.983378261597522, 42.950996202112975],
            [-3.012291226159164, 42.958463447530107],
            [-3.025442877586727, 42.96507803042681],
            [-3.034279547663004, 42.972622789310321],
            [-3.039628057788661, 42.978048813801735],
            [-3.03936967626953, 42.987040514408221],
            [-3.027019008720231, 42.986497911149712],
            [-3.008596360633561, 42.988978379848334],
            [-3.001645880952594, 42.989055895113466],
            [-2.999320440985514, 42.989469306263487],
            [-2.99699500191781, 42.990916246187822],
            [-2.992912562764502, 42.994275214029898],
            [-2.989708624553316, 43.000476385776579],
            [-2.987409023007956, 43.008925483124557],
            [-2.989501918978362, 43.014687405299497],
            [-2.997124193127007, 43.020836900202767],
            [-3.016089442673632, 43.025410264871851],
            [-3.022884893622972, 43.031327215778418],
            [-3.023582525612994, 43.037114977274371],
            [-3.023608364034715, 43.04310944344607],
            [-3.028620978275484, 43.047346910431543],
            [-3.036734177940019, 43.049724026342773],
            [-3.055260178814194, 43.043135280968414],
            [-3.073527798169323, 43.01396393488767],
            [-3.084948288832464, 43.010708319833043],
            [-3.092079636565956, 43.012206935701528],
            [-3.121612717852656, 43.013576362159341],
            [-3.152231003857736, 43.019855048271893],
            [-3.160473394731383, 43.022955633695489],
            [-3.165124273766281, 43.026676336743549],
            [-3.16093848272476, 43.033032538121148],
            [-3.155124883706378, 43.039595445073701],
            [-3.148510300809676, 43.050447495855252],
            [-3.148148565603805, 43.074218654966955],
            [-3.142748378634735, 43.092460435900392],
            [-3.141844041069646, 43.1041393089819],
            [-3.147580124822866, 43.111270656715391],
            [-3.165098436243824, 43.116541653374526],
            [-3.170834519997072, 43.122432765859486],
            [-3.172565679862203, 43.131372788723183],
            [-3.164814215403737, 43.146979072226372],
            [-3.159543218744489, 43.154937242259905],
            [-3.141844041069646, 43.165117499473041],
            [-3.116737636932385, 43.149753614737477],
            [-3.104081386946405, 43.145216260721867],
            [-3.043261071541252, 43.154708448436111],
            [-3.031472741074083, 43.160882765527219],
            [-3.025265465628706, 43.170407912495193],
            [-3.021837731126425, 43.191677444558422],
            [-3.015377770467751, 43.199840285900507],
            [-2.999326744333757, 43.204981888103703],
            [-2.984308433087676, 43.201817825832052],
            [-2.970476645081078, 43.193259475244616],
            [-2.950371664021105, 43.173484084023272],
            [-2.935913655590724, 43.146864209915321],
            [-2.93673763063714, 43.135394483168909],
            [-2.954019125382302, 43.109279980386816],
            [-2.95678768020727, 43.096019481313959],
            [-2.948273274991635, 43.083868601754546],
            [-2.895385089750107, 43.047481881583593],
          ],
          [
            [-2.538237473980217, 42.64336640123841],
            [-2.526041836061893, 42.644735825897584],
            [-2.515267299646126, 42.649309190566697],
            [-2.512941859679046, 42.653365790398908],
            [-2.51444047734617, 42.665768133892271],
            [-2.518290370704051, 42.674527288703331],
            [-2.519530605053319, 42.680961005346063],
            [-2.522553677010535, 42.686387030736853],
            [-2.525783453643498, 42.689487617059825],
            [-2.53234636059608, 42.68881582349141],
            [-2.537720710042692, 42.685973618687541],
            [-2.547099981578924, 42.678067125497421],
            [-2.553301154224812, 42.675379951223761],
            [-2.559502325971579, 42.673519599250113],
            [-2.566065232924132, 42.674527288703331],
            [-2.572137214360964, 42.677395331029714],
            [-2.581981573890488, 42.688247381811181],
            [-2.586839159399688, 42.69253652653947],
            [-2.592161831103624, 42.696257228688154],
            [-2.597045254135168, 42.700287990997936],
            [-2.600791794705572, 42.704370429251895],
            [-2.600300869189795, 42.709072984230829],
            [-2.598207974118765, 42.713155423384137],
            [-2.593117844612777, 42.715894272702485],
            [-2.580431281178676, 42.718478095088045],
            [-2.575160285418804, 42.721320298992538],
            [-2.571284551840563, 42.725247708514928],
            [-2.561931117826759, 42.739148667876663],
            [-2.560354986693341, 42.743851222855682],
            [-2.557771165207043, 42.748243720371363],
            [-2.556065842864342, 42.756382758457534],
            [-2.559192266709744, 42.759354153571309],
            [-2.569501715131992, 42.759095771152914],
            [-2.578105841211595, 42.760491035133185],
            [-2.586942511287816, 42.764883530850199],
            [-2.592704434362219, 42.769250189944245],
            [-2.596347622145032, 42.773642687460011],
            [-2.601541104438411, 42.776097316837706],
            [-2.608129848913393, 42.776614080775232],
            [-2.616682298149499, 42.774185288919938],
            [-2.626294114581697, 42.773203436988979],
            [-2.638903163650014, 42.774236964864002],
            [-2.700423957399664, 42.787957057971852],
            [-2.71502254875162, 42.792995509734936],
            [-2.726132981951565, 42.795062568182999],
            [-2.752798020911911, 42.796974596100682],
            [-2.816592575986533, 42.793977363464563],
            [-2.827082891562213, 42.791832791550092],
            [-2.832870653058137, 42.787259425981716],
            [-2.853541225846868, 42.763694973344371],
            [-2.868113978777103, 42.751395981739222],
            [-2.87015519880336, 42.744678046054929],
            [-2.865323451715938, 42.736280626449741],
            [-2.840751308725913, 42.717754625575481],
            [-2.814137945709632, 42.70292348932756],
            [-2.752436285706011, 42.681839505388837],
            [-2.684352585903099, 42.670289821717859],
            [-2.634071418361259, 42.654761054379179],
            [-2.556918503586019, 42.653365790398908],
            [-2.55040727437617, 42.651815496787748],
            [-2.545136277717006, 42.650006822556833],
            [-2.54275916180589, 42.646208604243725],
            [-2.538237473980217, 42.64336640123841],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5802",
        OBJECTID_1: 7268,
        diss_me: 5802,
        adm1_cod_1: "ESP-5802",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Albacete",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CM",
        note: null,
        hasc_maybe: null,
        region: "Castilla-La Mancha",
        region_cod: "ES.CM",
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP84",
        fips_alt: "SP54",
        woe_id: 12602110,
        woe_label: "Castille la Mancha, ES, Spain",
        woe_name: "Castilla-La Mancha",
        latitude: 38.834000000000003,
        longitude: -1.94309,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2522257,
        gn_name: "Provincia de Albacete",
        gns_id: -369590,
        gns_name: "Albacete, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.AB",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP54",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.44078508106665, 39.366227932373135],
            [-1.435333218153517, 39.367261461147507],
            [-1.42649654807721, 39.366718857888912],
            [-1.420760464323905, 39.367649033875836],
            [-1.415411953298985, 39.370284532205346],
            [-1.406239386438529, 39.370594591467182],
            [-1.385000372868888, 39.364987698023867],
            [-1.339680141825085, 39.341733302849576],
            [-1.329086472562636, 39.337935086335222],
            [-1.320973272898186, 39.338245143798304],
            [-1.31048295732262, 39.33728913028915],
            [-1.177726203301575, 39.304112861219579],
            [-1.171473353812189, 39.296077175021622],
            [-1.170336473149689, 39.288299872141323],
            [-1.17379879377927, 39.280755113257811],
            [-1.175969204115432, 39.273365383105926],
            [-1.178294644082513, 39.257707424557964],
            [-1.17728695283057, 39.249955960099498],
            [-1.177597012092463, 39.242411201215958],
            [-1.176279263377268, 39.235228175739678],
            [-1.178578864023336, 39.220552070022052],
            [-1.181162686408811, 39.213214015814231],
            [-1.184547491773344, 39.206961168123399],
            [-1.186252814116045, 39.201147569105018],
            [-1.190955369094979, 39.191768297568871],
            [-1.19328080906206, 39.184998684141988],
            [-1.201807419876531, 39.170425930312462],
            [-1.207931077257371, 39.164431464140762],
            [-1.21485571941588, 39.15916046838089],
            [-1.226922166125064, 39.146809800831676],
            [-1.231314662741454, 39.139936835516579],
            [-1.237179937703985, 39.124769802484423],
            [-1.241184861592046, 39.117302557966667],
            [-1.251390957226903, 39.102962348133815],
            [-1.254982469065681, 39.096063544397111],
            [-1.256739468251823, 39.089190579082015],
            [-1.259685024044614, 39.082369289710954],
            [-1.262113816799172, 39.073687649265594],
            [-1.261312832021559, 39.063352363320917],
            [-1.255809292265013, 39.047280991824266],
            [-1.248367886168978, 39.038134264284878],
            [-1.151474574591902, 38.94852733007221],
            [-1.134395513642033, 38.937985338552494],
            [-1.121037156639431, 38.932585151583424],
            [-1.093648647267258, 38.933231105830856],
            [-1.066105109163459, 38.938088691339971],
            [-1.037863939069496, 38.937313544084788],
            [-1.019596319714339, 38.940569159139386],
            [-1.004248420428212, 38.948682358803836],
            [-0.998538174197421, 38.950491033934043],
            [-0.991070928780289, 38.951214505245275],
            [-0.98130408361655, 38.949535020424804],
            [-0.971072149560086, 38.94625356694857],
            [-0.959186570903512, 38.940026556780168],
            [-0.926527064872204, 38.895533148935698],
            [-0.920946010749788, 38.886153876500202],
            [-0.921540289952446, 38.87855744077325],
            [-0.926527064872204, 38.863416246162785],
            [-0.926217007408923, 38.855251370554271],
            [-0.913272060657079, 38.824581406806402],
            [-0.912574428666943, 38.819801337461797],
            [-0.915132411731406, 38.813290107352572],
            [-0.918827277257037, 38.808406684321028],
            [-0.920480922756326, 38.791766872942816],
            [-0.930643004670856, 38.785508015982202],
            [-0.939652878777309, 38.779958807752735],
            [-0.949083828056246, 38.777271633479074],
            [-0.95298539915683, 38.77026947695478],
            [-0.953683031146966, 38.762647202806107],
            [-0.948877121581916, 38.743346056475332],
            [-0.942288377106934, 38.731176256079351],
            [-0.933089971824757, 38.720505073350438],
            [-0.927302212127387, 38.715053209538041],
            [-0.916398485401771, 38.702418321148542],
            [-0.909861415971562, 38.696217149401861],
            [-0.903479377071619, 38.688698228940154],
            [-0.903479377071619, 38.68508087778028],
            [-0.908156093628747, 38.681360174732333],
            [-0.939575365310816, 38.663996893841556],
            [-0.957145351776546, 38.658829250869161],
            [-0.977169969418554, 38.656452134958045],
            [-1.001432054046063, 38.657433986889004],
            [-1.006470505809148, 38.658312486032372],
            [-1.022128465256401, 38.654540107040361],
            [-1.077861496610808, 38.687613023322342],
            [-1.105301682826337, 38.707508449755153],
            [-1.109254929871071, 38.713761298345247],
            [-1.111916265723039, 38.720505073350438],
            [-1.112743088922372, 38.72786896418134],
            [-1.114293381634184, 38.734690253552372],
            [-1.119926112600012, 38.740064602099721],
            [-1.128426885892054, 38.743371893997676],
            [-1.164497035709616, 38.752621975223974],
            [-1.174186367406946, 38.754094754469378],
            [-1.23338172029014, 38.742131658749031],
            [-1.243174403875599, 38.738075059816168],
            [-1.252812058729518, 38.731873888069487],
            [-1.278236864239858, 38.711590888008999],
            [-1.334202439590882, 38.686734524178945],
            [-1.345338711212548, 38.686579495447319],
            [-1.35285763167434, 38.69045522722692],
            [-1.357766893127604, 38.696398017454499],
            [-1.363502976880909, 38.701307278008471],
            [-1.371486986235368, 38.702702541988742],
            [-1.385775519224865, 38.696914781391996],
            [-1.402828741753012, 38.684977524992718],
            [-1.433627895810702, 38.655832018233042],
            [-1.450319383132978, 38.627720038449013],
            [-1.453264939825146, 38.612346299841789],
            [-1.452929043040882, 38.60493073216756],
            [-1.454272630177712, 38.589686183870242],
            [-1.458665126794187, 38.582864895398586],
            [-1.464530401756633, 38.57619863475918],
            [-1.476235114159124, 38.569790758336808],
            [-1.485149297701923, 38.56291779212242],
            [-1.497448289307073, 38.545864570493478],
            [-1.499980434849192, 38.540593573834343],
            [-1.501995814655089, 38.534909166025159],
            [-1.501324021986022, 38.529173082271939],
            [-1.500135463580733, 38.523333644831865],
            [-1.498352626872247, 38.519354560264759],
            [-1.494786952555813, 38.513411770037123],
            [-1.489645148005167, 38.500001736191166],
            [-1.485717739382125, 38.483697822496509],
            [-1.483030565108464, 38.452226873971],
            [-1.483288946627567, 38.43569041627967],
            [-1.478483038861157, 38.411454169174505],
            [-1.477940435602648, 38.403676866294319],
            [-1.479051478742718, 38.395692856939775],
            [-1.481015184403191, 38.38827728926546],
            [-1.485278489810469, 38.381378486428019],
            [-1.494709438190057, 38.373110256233304],
            [-1.567314825818556, 38.326213894055968],
            [-1.626742722698509, 38.311408596229683],
            [-1.636302863186557, 38.31078847950468],
            [-1.646147222716081, 38.312235419429015],
            [-1.654596320064059, 38.314664212183658],
            [-1.667799648335119, 38.319676826424427],
            [-1.673458217722583, 38.322777410948731],
            [-1.678057419914012, 38.326911526046018],
            [-1.681933152592904, 38.331562405080888],
            [-1.684000210141591, 38.336600856844086],
            [-1.685162930125159, 38.34189769102565],
            [-1.684465298135024, 38.347323717315703],
            [-1.681674771073887, 38.352543036232163],
            [-1.681881475749464, 38.357607327316231],
            [-1.683121710998194, 38.362439073504362],
            [-1.686558193206054, 38.366624864545884],
            [-1.690873174557396, 38.370216376384661],
            [-1.695989141585642, 38.372877713135978],
            [-1.702655402225048, 38.373627021070178],
            [-1.719036831184866, 38.371353257946424],
            [-1.72500545893476, 38.373627021070178],
            [-1.736374273653837, 38.379388943245118],
            [-1.743092210237279, 38.380499986385274],
            [-1.750533617232605, 38.380344956754357],
            [-1.759912888768838, 38.378122870474044],
            [-1.767147590189097, 38.375073960994484],
            [-1.773865525873276, 38.369492905972805],
            [-1.786474574941707, 38.367089952539231],
            [-1.791073778032427, 38.366934922908342],
            [-1.792779100375128, 38.365100409356415],
            [-1.792624070744324, 38.359829412697195],
            [-1.795724657067296, 38.353912461790713],
            [-1.848021206213673, 38.329081936382266],
            [-1.885848354318114, 38.306240953257401],
            [-1.910342982942268, 38.295518092785755],
            [-1.926181810442159, 38.291048081803496],
            [-1.958117845162349, 38.286655585187106],
            [-1.984808721645209, 38.279265855035135],
            [-1.992792730999753, 38.279524238352906],
            [-1.998890549958844, 38.282159735783154],
            [-2.015995449330518, 38.295078844113391],
            [-2.023359341060768, 38.298980414314713],
            [-2.031730923143527, 38.299910590301522],
            [-2.042893032287623, 38.298360296690333],
            [-2.059067755672373, 38.29037628823508],
            [-2.085061001064275, 38.265829982767485],
            [-2.127409836994389, 38.235185859239976],
            [-2.139088710975187, 38.230328273730862],
            [-2.158751593411239, 38.227098497097955],
            [-2.170327113705355, 38.223817044521013],
            [-2.182806973363114, 38.217900091815778],
            [-2.211926641701183, 38.195937607834281],
            [-2.222210251701711, 38.182837633250159],
            [-2.250528937060778, 38.136277166957655],
            [-2.280966355912625, 38.103230089097366],
            [-2.318819342438672, 38.069356187138311],
            [-2.324219530307005, 38.062379869035652],
            [-2.338533900818845, 38.026490587270786],
            [-2.3509362452115, 38.023622544944487],
            [-2.361168179268049, 38.024346015356343],
            [-2.465838791926672, 38.049383246339744],
            [-2.547384202419039, 38.080647488390895],
            [-2.536196254853223, 38.093075670305865],
            [-2.521933560285532, 38.105064601750001],
            [-2.515835741326327, 38.111911729542669],
            [-2.506482306413261, 38.125295924966991],
            [-2.444806483932069, 38.188186144275079],
            [-2.442946132857742, 38.196247667096202],
            [-2.444108852841225, 38.205136013116459],
            [-2.448449672614288, 38.217150783881607],
            [-2.448863083764309, 38.222266750909824],
            [-2.445607468709682, 38.225367337232825],
            [-2.441163296149256, 38.228467923555797],
            [-2.437287562570987, 38.233144640113011],
            [-2.434807094771571, 38.238854885444624],
            [-2.432869229331459, 38.244901028459651],
            [-2.431913214922929, 38.252497464186604],
            [-2.430259569423555, 38.258801987821471],
            [-2.430621303730248, 38.265623277192446],
            [-2.432481654804491, 38.272961331400353],
            [-2.4400522530091, 38.282754014985812],
            [-2.471109787686544, 38.298179430436363],
            [-2.47756934185162, 38.308127142753364],
            [-2.479352178560191, 38.317351386457347],
            [-2.480075648972019, 38.326291409321129],
            [-2.479197149828565, 38.341923530346662],
            [-2.481936000945666, 38.357013048113686],
            [-2.485553351206136, 38.363756822219585],
            [-2.487517055967345, 38.371094876427406],
            [-2.488188848636469, 38.378846339986609],
            [-2.487155320761474, 38.393083197931247],
            [-2.488731451894978, 38.398741767318711],
            [-2.557176886004413, 38.414451401810737],
            [-2.567408820060962, 38.422797146371238],
            [-2.571517095837237, 38.427628893458632],
            [-2.574023402957664, 38.434553533818502],
            [-2.573248256601772, 38.441271471301349],
            [-2.57066443511556, 38.448402818135548],
            [-2.570044318390586, 38.462717190445971],
            [-2.572266404670813, 38.469667670126825],
            [-2.574617682160323, 38.483594468809656],
            [-2.571129523108937, 38.49558340115297],
            [-2.571207038374155, 38.498735663420121],
            [-2.571387904628125, 38.499872544981969],
            [-2.571904670364205, 38.500544339449675],
            [-2.573842535804317, 38.502042955318132],
            [-2.582214117887247, 38.505246894428609],
            [-2.598750575578606, 38.509949449407543],
            [-2.626319953003417, 38.510827949450288],
            [-2.642804734750712, 38.509277655839128],
            [-2.656163091753228, 38.506332099147045],
            [-2.675205858363654, 38.500234280187868],
            [-2.718536546224612, 38.514290270079869],
            [-2.756027798444165, 38.533126329316673],
            [-2.746937818125843, 38.546921407896662],
            [-2.765482740735933, 38.573673117220608],
            [-2.750969800815625, 38.605533470393283],
            [-2.742202710386778, 38.615893578525828],
            [-2.728491772377396, 38.624594750447386],
            [-2.679327952223218, 38.635504174378184],
            [-2.668682200523136, 38.649237085523055],
            [-2.645292306294834, 38.707409695201278],
            [-2.643842111724098, 38.726097440044782],
            [-2.650686593711328, 38.742170438415002],
            [-2.681755931091317, 38.777063017744069],
            [-2.731765698528193, 38.790103789957982],
            [-2.749211988642287, 38.811252472024023],
            [-2.76238459607228, 38.880806918604378],
            [-2.799452469056234, 38.90793216313881],
            [-2.847759355809615, 38.90551517128813],
            [-2.870610918559066, 38.912128941197636],
            [-2.882124591576627, 38.931267124977779],
            [-2.879795489570171, 38.945505407448621],
            [-2.807439529810438, 39.053808633661561],
            [-2.806033279712125, 39.06824466985563],
            [-2.809801590171247, 39.084504435831064],
            [-2.842683672113708, 39.134426312524255],
            [-2.722229565442063, 39.268613329441592],
            [-2.742125806660653, 39.318018848690883],
            [-2.662178293593399, 39.321787160049411],
            [-2.619287667391973, 39.339969533567142],
            [-2.567794745231282, 39.326939747471386],
            [-2.529364567520759, 39.327620900284273],
            [-2.511182194003027, 39.342034963342883],
            [-2.373929991675766, 39.232907762083528],
            [-2.349210752873944, 39.278885546830537],
            [-2.321074764788534, 39.283697558295671],
            [-2.288818904169062, 39.226733444992504],
            [-2.168364798396709, 39.280950976606306],
            [-2.048954393630339, 39.266767626524683],
            [-2.09065849659234, 39.359481253457062],
            [-2.020807419426859, 39.351988577011184],
            [-2.029047166293708, 39.337926076027514],
            [-1.9899138635339, 39.293651171963973],
            [-1.886971965483809, 39.304637500520215],
            [-1.845103067152792, 39.286103564927544],
            [-1.769956580331893, 39.280610400649437],
            [-1.739414586513959, 39.321106007236494],
            [-1.50101396272413, 39.42203847899259],
            [-1.487009649675542, 39.419635525559045],
            [-1.484451666611051, 39.415372219252561],
            [-1.481738653915755, 39.409429429924188],
            [-1.476855230884212, 39.40607046208217],
            [-1.469336310422392, 39.404158434164401],
            [-1.467708503344738, 39.400799465422949],
            [-1.464995489749981, 39.396742866490086],
            [-1.455512865426357, 39.394236559369659],
            [-1.452386439782373, 39.390205797059878],
            [-1.450991176701393, 39.385529080502664],
            [-1.450009324770519, 39.379482937487609],
            [-1.448536546424407, 39.373772691256647],
            [-1.445746019363241, 39.366899725941551],
            [-1.44078508106665, 39.366227932373135],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5803",
        OBJECTID_1: 7264,
        diss_me: 5803,
        adm1_cod_1: "ESP-5803",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Alicante",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.VC",
        note: null,
        hasc_maybe: "ES.AR|ESP-VLN",
        region: "Valenciana",
        region_cod: "ES.VC",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "VC",
        area_sqkm: 0,
        sameascity: 6,
        labelrank: 6,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP89",
        fips_alt: "SP60|SP52",
        woe_id: 12602137,
        woe_label: "Valencia, ES, Spain",
        woe_name: "Comunidad Valenciana",
        latitude: 38.541800000000002,
        longitude: -0.53387899999999999,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2521976,
        gn_name: "Provincia de Alicante",
        gns_id: -370212,
        gns_name: "Alicante, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.A",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP60",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.760574989999242, 37.845898277536094],
            [-0.761033291353357, 37.845907294186276],
            [-0.779714320959158, 37.842910061550043],
            [-0.787104051111044, 37.844279487108594],
            [-0.791548223671583, 37.846372382179624],
            [-0.796922573118195, 37.851074937158643],
            [-0.804260627326045, 37.856190904186889],
            [-0.812554694143813, 37.86029918086254],
            [-0.830563931080576, 37.864200751063777],
            [-0.838702969166661, 37.867973130955164],
            [-0.881000129152625, 37.902958076054375],
            [-0.888699916767678, 37.90802236623918],
            [-0.91668270534251, 37.933550522738471],
            [-0.923064744242538, 37.941301988096228],
            [-0.934381883017522, 37.96047394501656],
            [-0.948024460860154, 37.977527167544707],
            [-0.955000779862104, 37.984322618494019],
            [-0.965723639434373, 38.000006415463702],
            [-0.965878669065262, 38.000109768251207],
            [-0.966266241793591, 38.000652371509688],
            [-0.982234258704011, 38.026413072005653],
            [-1.003111537967158, 38.052716375760127],
            [-1.006987270646022, 38.056824653335042],
            [-1.013265956758488, 38.061708076366614],
            [-1.020035570185371, 38.068503526416549],
            [-1.025849168304461, 38.078864650782947],
            [-1.030810105701789, 38.097054754873056],
            [-1.032903000772905, 38.116898505361718],
            [-1.028097093006494, 38.135346991870193],
            [-1.022826097246536, 38.14630239543925],
            [-0.993654751165764, 38.18097728127654],
            [-0.985954962651419, 38.19518829990011],
            [-0.983164434690906, 38.202991441202016],
            [-0.976446499006727, 38.234410711984793],
            [-0.973449266370494, 38.241903794924156],
            [-0.969418504060712, 38.258698635033994],
            [-0.969160121642233, 38.270842597008254],
            [-0.983629522684367, 38.310917669814614],
            [-0.994662440619152, 38.324947822184271],
            [-0.999778407647398, 38.328539334023048],
            [-1.011870692778217, 38.334818020135515],
            [-1.048690151429327, 38.344714057407799],
            [-1.068172165812769, 38.347065334897223],
            [-1.07667293910481, 38.349700833226848],
            [-1.080987922254792, 38.359312648759754],
            [-1.083959315569928, 38.374608873001137],
            [-1.084450242884373, 38.415691637059354],
            [-1.082098965394948, 38.431142890032362],
            [-1.075613572808152, 38.442124132023025],
            [-1.068947313068037, 38.449643053384193],
            [-1.030706752914284, 38.477186591487992],
            [-1.024014654752534, 38.483387763234674],
            [-1.019441290982797, 38.490622463755756],
            [-1.016547410234807, 38.498425605057662],
            [-1.018795334936726, 38.521628323388427],
            [-1.015255499941304, 38.537027900417257],
            [-1.005669521031535, 38.564623115364412],
            [-1.00543697793421, 38.590306301494621],
            [-1.022128465256401, 38.654540107040361],
            [-1.006470505809148, 38.658312486032372],
            [-1.001432054046063, 38.657433986889004],
            [-0.977169969418554, 38.656452134958045],
            [-0.957145351776546, 38.658829250869161],
            [-0.939575365310816, 38.663996893841556],
            [-0.908156093628747, 38.681360174732333],
            [-0.903479377071619, 38.68508087778028],
            [-0.903479377071619, 38.688698228940154],
            [-0.909861415971562, 38.696217149401861],
            [-0.916398485401771, 38.702418321148542],
            [-0.927302212127387, 38.715053209538041],
            [-0.933089971824757, 38.720505073350438],
            [-0.942288377106934, 38.731176256079351],
            [-0.948877121581916, 38.743346056475332],
            [-0.953683031146966, 38.762647202806107],
            [-0.95298539915683, 38.77026947695478],
            [-0.949083828056246, 38.777271633479074],
            [-0.939652878777309, 38.779958807752735],
            [-0.930643004670856, 38.785508015982202],
            [-0.916143070746131, 38.773437529518333],
            [-0.908298832225341, 38.770394316344607],
            [-0.897905765738528, 38.770042754269696],
            [-0.876724124418843, 38.774338408290902],
            [-0.866880373730226, 38.77425051754733],
            [-0.857245363782482, 38.769240752358854],
            [-0.830647461910843, 38.746191434986713],
            [-0.81635424884945, 38.740775174434731],
            [-0.747261228223067, 38.754343290210699],
            [-0.730056638103321, 38.752673368780961],
            [-0.651108880670279, 38.717648953336521],
            [-0.633948235922304, 38.702125270764583],
            [-0.615469230920212, 38.69383059330724],
            [-0.50711107321743, 38.742873563464201],
            [-0.520503407506254, 38.757232695032855],
            [-0.532884999143391, 38.762583037077462],
            [-0.547046376988703, 38.761264677722721],
            [-0.565767081085937, 38.755562772703826],
            [-0.595298332252241, 38.795036651420304],
            [-0.416583725158745, 38.819228546660071],
            [-0.310807354675603, 38.881510043653577],
            [-0.23050827863409, 38.853374055568139],
            [-0.030995245866961, 38.882757879986144],
            [-0.009755011999943, 38.869696356000105],
            [0.017425977000073, 38.864325262000051],
            [0.0630802740001, 38.860785223000079],
            [0.111013217000021, 38.849920966000099],
            [0.153981967000078, 38.831000067000076],
            [0.185313347000118, 38.80353424700013],
            [0.181000196000127, 38.801906643000109],
            [0.170909050000148, 38.796087958000044],
            [0.182871941000087, 38.791815497000044],
            [0.19076582100007, 38.785223700000074],
            [0.217865431000092, 38.768133856000105],
            [0.227712436000104, 38.770209052000041],
            [0.23406009200005, 38.757879950000103],
            [0.234548373000024, 38.742254950000103],
            [0.227712436000104, 38.734564520000077],
            [0.208994988000143, 38.733710028000075],
            [0.192067905000073, 38.730414130000042],
            [0.176768425000091, 38.72402578300003],
            [0.162608269000089, 38.71352773600006],
            [0.150889519000089, 38.692450262000136],
            [0.148936394000145, 38.689927476000037],
            [0.145030144000145, 38.686346747000044],
            [0.125743035000113, 38.685492255000042],
            [0.118581576000082, 38.682806708000072],
            [0.10531660200013, 38.672552802000041],
            [0.093679233000046, 38.667629299000083],
            [0.083018425000091, 38.66095612200013],
            [0.073130730000088, 38.64520905200007],
            [0.080577019000145, 38.631008205000057],
            [0.060435418000111, 38.634833075000074],
            [0.046641472000118, 38.632228908000059],
            [0.033376498000081, 38.627386786000102],
            [0.015025261000119, 38.62474192900001],
            [-0.014759894999884, 38.624416408000087],
            [-0.028797980999911, 38.620672919000043],
            [-0.042958136999857, 38.611721096000025],
            [-0.054758266999926, 38.595282294000128],
            [-0.055083787999934, 38.58075592700007],
            [-0.053618943999879, 38.566961981000077],
            [-0.060047980999883, 38.552720445000062],
            [-0.075428839999944, 38.539943752000028],
            [-0.090199347999913, 38.535345770000077],
            [-0.131743943999908, 38.535345770000077],
            [-0.176991339999915, 38.527248440000079],
            [-0.202137824999909, 38.519598700000131],
            [-0.233957485999952, 38.501654364000075],
            [-0.329416469999956, 38.470038153000104],
            [-0.344553188999953, 38.46161530200007],
            [-0.350819464999915, 38.450018622000044],
            [-0.358713344999956, 38.445949611000088],
            [-0.39517167899993, 38.413763739000046],
            [-0.406076626999919, 38.398871161000073],
            [-0.410145636999886, 38.379339911000102],
            [-0.409982876999919, 38.368963934000107],
            [-0.413685675999943, 38.364813544000071],
            [-0.445871548999918, 38.363430080000086],
            [-0.460194464999887, 38.36005280200007],
            [-0.470570441999882, 38.351874091000042],
            [-0.474354620999918, 38.336737372000044],
            [-0.509836391999897, 38.338364976000065],
            [-0.518788214999915, 38.298651434000078],
            [-0.504017706999946, 38.207220770000021],
            [-0.515451626999891, 38.195502020000049],
            [-0.532866990999878, 38.19216543200011],
            [-0.56883704299986, 38.194199937000036],
            [-0.591379360999923, 38.189642645000106],
            [-0.609486456999946, 38.17804596600007],
            [-0.623687303999958, 38.162420966000099],
            [-0.634388800999886, 38.145819403000132],
            [-0.640736456999917, 38.131659247000101],
            [-0.645008917999917, 38.11603424700013],
            [-0.648019985999923, 38.0737979190001],
            [-0.652495897999898, 38.007757880000099],
            [-0.65453040299991, 37.989325262000051],
            [-0.663400844999956, 37.98029205900005],
            [-0.689930792999917, 37.972154039000117],
            [-0.708851691999939, 37.956284898],
            [-0.718413865999935, 37.943019924000083],
            [-0.758615688999924, 37.863348700000103],
            [-0.760731574999852, 37.847072658000087],
            [-0.760574989999242, 37.845898277536094],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5804",
        OBJECTID_1: 7276,
        diss_me: 5804,
        adm1_cod_1: "ESP-5804",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Almería",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602095,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 37.156300000000002,
        longitude: -2.2247300000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2521883,
        gn_name: "Provincia de Almeria",
        gns_id: -370377,
        gns_name: "Almeria, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.AL",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.171799892950759, 37.896446845045887],
            [-2.1254719706549, 37.89874644569187],
            [-2.112682054433236, 37.895309964383387],
            [-2.076973638922283, 37.88109894486044],
            [-1.982793340939963, 37.870815334859827],
            [-1.975687831628079, 37.865854397462414],
            [-1.976127082099197, 37.860144151231538],
            [-1.987366705608963, 37.848129381365851],
            [-1.991681687859597, 37.841153062363901],
            [-1.993257818993186, 37.833840847477177],
            [-1.993335334258234, 37.815986640171332],
            [-1.994110479714863, 37.809682114737797],
            [-2.001422694601615, 37.794282537708938],
            [-2.002817958581886, 37.788029690018192],
            [-1.99997575467728, 37.752476305037419],
            [-1.996125861319399, 37.742296047824283],
            [-1.99519568533259, 37.735474758453336],
            [-1.998709682805583, 37.722142238973078],
            [-2.002972988212775, 37.71185862897255],
            [-2.004445766558831, 37.704546414085712],
            [-2.00460079618972, 37.688500881010896],
            [-2.005530972176643, 37.68085277023917],
            [-2.004239060983849, 37.663954576442478],
            [-2.00072506261148, 37.653825995173491],
            [-1.996590949312861, 37.647831529001877],
            [-1.987831793602425, 37.638994858925571],
            [-1.838409389781475, 37.454820054901546],
            [-1.826446295859853, 37.445363268100351],
            [-1.818927375398061, 37.441565049787158],
            [-1.811227585984341, 37.438800361147713],
            [-1.805698207806103, 37.437456773111592],
            [-1.80345028310407, 37.441332505790569],
            [-1.802235887176437, 37.446345120031225],
            [-1.7976625234067, 37.449988307814152],
            [-1.79270158511008, 37.450737617546991],
            [-1.741516079103775, 37.441332505790569],
            [-1.732421026609074, 37.438438625941842],
            [-1.641617712793646, 37.381036447237108],
            [-1.64591344299987, 37.369247095000105],
            [-1.652838941999931, 37.361259187000059],
            [-1.670887824999909, 37.363308010000111],
            [-1.68016516799986, 37.354437567000076],
            [-1.685780402999853, 37.336981512000108],
            [-1.698963995999918, 37.316433010000083],
            [-1.728505011999886, 37.282416083000044],
            [-1.787220831999889, 37.235215562000064],
            [-1.812733527999882, 37.207058010000083],
            [-1.830352342999902, 37.16636790600009],
            [-1.837757941999882, 37.131618557000053],
            [-1.849842902999882, 37.104437567000048],
            [-1.855213995999861, 37.076605536000073],
            [-1.884999152999853, 37.015488999000127],
            [-1.906117316999882, 36.987616278000075],
            [-1.906971808999884, 36.975287177000041],
            [-1.904164191999911, 36.9663760440001],
            [-1.900502081999974, 36.958766994000143],
            [-1.898671027999882, 36.950059312000064],
            [-1.900339321999894, 36.941188869000115],
            [-1.905222133999871, 36.935980536000017],
            [-1.912871873999904, 36.933539130000099],
            [-1.935292120999918, 36.932196356000105],
            [-1.941395636999914, 36.929877020000049],
            [-1.953846808999913, 36.919948635000139],
            [-1.988921678999901, 36.900498765000037],
            [-2.002797003999888, 36.887030341000042],
            [-2.013050910999908, 36.845770575000103],
            [-2.024769660999908, 36.833766994000086],
            [-2.040476040999891, 36.824326890000066],
            [-2.056874152999882, 36.810085354000051],
            [-2.06073971299989, 36.802435614000046],
            [-2.065907355999883, 36.78355540600009],
            [-2.071156378999888, 36.775336005000099],
            [-2.080311652999853, 36.770615953000089],
            [-2.091420050999886, 36.768255927000041],
            [-2.100819464999915, 36.765163479000051],
            [-2.111073370999947, 36.746120510000054],
            [-2.126698370999918, 36.736395575000103],
            [-2.146717902999882, 36.729925848000107],
            [-2.166127081999889, 36.727565822000059],
            [-2.203643357999908, 36.737127997000044],
            [-2.231678839999915, 36.760077216000127],
            [-2.256133592999959, 36.787380276000107],
            [-2.282785610999895, 36.810085354000051],
            [-2.318348761999886, 36.827907619000115],
            [-2.33914140499985, 36.834255276000079],
            [-2.361683722999942, 36.836778062000093],
            [-2.380238410999937, 36.833766994000086],
            [-2.412709113999938, 36.820013739000075],
            [-2.433094855999911, 36.816270249000127],
            [-2.442331508999871, 36.818304755000071],
            [-2.469390428999901, 36.827744859000063],
            [-2.481434699999937, 36.82994212400007],
            [-2.560780402999882, 36.818793036000045],
            [-2.576893683999941, 36.811306057000081],
            [-2.596709764999957, 36.773098049000026],
            [-2.617176886999914, 36.728420315000051],
            [-2.642689581999917, 36.70701732],
            [-2.647572394999884, 36.704169012000079],
            [-2.658884243999864, 36.694810289000117],
            [-2.684356248999961, 36.685980536000073],
            [-2.718251105999911, 36.682033596000082],
            [-2.736195441999882, 36.687404690000051],
            [-2.756662563999924, 36.68036530200007],
            [-2.782948370999861, 36.694606838000084],
            [-2.802601691999911, 36.70730215100005],
            [-2.82099361899995, 36.713893947000116],
            [-2.829986131999874, 36.711737372000101],
            [-2.839060024999867, 36.702419338000084],
            [-2.848255988999938, 36.700222072000088],
            [-2.858754035999908, 36.704942124000098],
            [-2.88609778599988, 36.734401760000083],
            [-2.913970506999931, 36.750311591000099],
            [-2.933257615999878, 36.751654364000075],
            [-2.987718933999929, 36.73710894900006],
            [-3.07645340499991, 36.749756234000046],
            [-3.128224358155819, 36.746382255157613],
            [-3.140226408022301, 36.787845412835566],
            [-3.029022789969787, 36.844457964063793],
            [-3.043436853028396, 36.879130816533944],
            [-3.052269861065213, 36.887502398616789],
            [-3.064761316581496, 36.894226032405442],
            [-3.072660487491476, 36.902268024649572],
            [-3.06771663901165, 36.914693562557858],
            [-2.996909751583729, 36.970646933209395],
            [-2.99080135300008, 36.986181601899403],
            [-2.992647055916876, 36.999266319485088],
            [-3.008807945929931, 37.036136437846352],
            [-3.015355797332177, 37.069655726330637],
            [-3.011126060918969, 37.085080531141585],
            [-2.997722740512074, 37.095473598527747],
            [-2.96250057044503, 37.096660121767229],
            [-2.952338216935118, 37.100823940572354],
            [-2.943900716345013, 37.113095669229693],
            [-2.936067463942265, 37.144022184376269],
            [-2.927860677228438, 37.157370573293321],
            [-2.917423664470562, 37.163215300545716],
            [-2.893978839651794, 37.170136687158362],
            [-2.884717364914451, 37.17939816189562],
            [-2.859932207605254, 37.260905733412926],
            [-2.854823564655788, 37.267102022740147],
            [-2.846495927944716, 37.271606417502142],
            [-2.83486140582923, 37.272990695364257],
            [-2.804385330518556, 37.268530245974063],
            [-2.794640456691752, 37.263476534514439],
            [-2.769943190126128, 37.238043183645971],
            [-2.756946363284015, 37.232374237880805],
            [-2.710364330349591, 37.22955075156591],
            [-2.660112863817574, 37.212005585489379],
            [-2.665562082723909, 37.284405490620912],
            [-2.663892161294143, 37.290744602181661],
            [-2.654960275496308, 37.304598362424457],
            [-2.6525762428993, 37.312288791694385],
            [-2.648709054679159, 37.368725561435497],
            [-2.642139230141339, 37.383216529119522],
            [-2.490154361034911, 37.489366435712412],
            [-2.358483213729357, 37.522226544519384],
            [-2.375995421451535, 37.61213865737318],
            [-2.315933163484715, 37.622092271041481],
            [-2.32149224537082, 37.697744129188237],
            [-2.313076717916175, 37.715014637815273],
            [-2.288862849540834, 37.746435537270173],
            [-2.282358943510388, 37.764068594090276],
            [-2.290488826498176, 37.82062621382866],
            [-2.285918514128099, 37.864780267894986],
            [-2.280315485970846, 37.874481196350104],
            [-2.271647273303017, 37.881699214447195],
            [-2.209648157136769, 37.915668080317658],
            [-2.171799892950759, 37.896446845045887],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5805",
        OBJECTID_1: 1363,
        diss_me: 5805,
        adm1_cod_1: "ESP-5805",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Asturias",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AS",
        note: null,
        hasc_maybe: null,
        region: "Asturias",
        region_cod: "ES.AS",
        provnum_ne: 15,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AS",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP95",
        fips_alt: "SP34",
        woe_id: 12578027,
        woe_label: "Asturias, ES, Spain",
        woe_name: "Principado de Asturias",
        latitude: 43.326099999999997,
        longitude: -5.8419600000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355236,
        gn_name: "Province of Asturias",
        gns_id: -394971,
        gns_name: "Principado de Asturias, Comunidad Autonoma del",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.O",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP34",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.513830313922597, 43.402834267027572],
            [-4.514370083412587, 43.39649872493618],
            [-4.51325904027243, 43.384354762961863],
            [-4.521966519139539, 43.370634670753475],
            [-4.534446376998659, 43.354614976999613],
            [-4.537314419324957, 43.34831045246537],
            [-4.535144008988794, 43.342264309450314],
            [-4.530880702682225, 43.338233548039824],
            [-4.521863166352063, 43.319965929584043],
            [-4.518710903185649, 43.305987454057799],
            [-4.520364548684938, 43.296220607994684],
            [-4.523542650273043, 43.287900701855904],
            [-4.52982133638551, 43.28169953100857],
            [-4.53715938969404, 43.277203680705242],
            [-4.545169236571013, 43.275808416725084],
            [-4.551163702742741, 43.279167385466451],
            [-4.556796433708485, 43.290562039506511],
            [-4.563540207814384, 43.29420522728941],
            [-4.58188534243456, 43.297357490455823],
            [-4.592608202006915, 43.298158474334173],
            [-4.602220018439027, 43.296840724719658],
            [-4.617412888993556, 43.289864407516376],
            [-4.624285855208115, 43.284128322863779],
            [-4.628704189346848, 43.2785472678421],
            [-4.633690965165869, 43.275705063937579],
            [-4.643173591288161, 43.272191067363877],
            [-4.663947515965077, 43.270744127439542],
            [-4.67363684676306, 43.27110586174615],
            [-4.682783576101173, 43.272501125726421],
            [-4.69311886204585, 43.271881008102042],
            [-4.703609177621416, 43.269503893090189],
            [-4.715675625229892, 43.264697984424402],
            [-4.721540900192394, 43.258703518252702],
            [-4.723633796162716, 43.251985582568608],
            [-4.721127489042374, 43.238239651039038],
            [-4.723246221635748, 43.231392524145662],
            [-4.724641485616019, 43.224493720408844],
            [-4.72531328008381, 43.216432196688544],
            [-4.727948778413435, 43.207750556243155],
            [-4.734537522888331, 43.196898505461689],
            [-4.742676560974473, 43.192144272739966],
            [-4.751849127834959, 43.191937568064276],
            [-4.770917731068437, 43.194934800700509],
            [-4.780400356291352, 43.193617051985285],
            [-4.79001217272355, 43.191110744864943],
            [-4.819726121163512, 43.18759674739195],
            [-4.831870083137858, 43.188681952110386],
            [-4.859413621241572, 43.223408514791117],
            [-4.878998989311953, 43.235164903137786],
            [-4.888584967322345, 43.237516181526559],
            [-4.898377650907889, 43.238549710300873],
            [-4.908480393755156, 43.237309475052228],
            [-4.961474574891668, 43.216225491113477],
            [-4.97715837186135, 43.205580145906993],
            [-5.008293422703304, 43.192919419995235],
            [-5.045888026810957, 43.185271308324246],
            [-5.062889574294417, 43.178760077315729],
            [-5.072113817098938, 43.172455552781486],
            [-5.0824491030437, 43.158037828582877],
            [-5.086195645412658, 43.151449083208604],
            [-5.08818518679692, 43.144188544265916],
            [-5.095471564161329, 43.128323880143],
            [-5.102835455891579, 43.119538886010758],
            [-5.118131680132961, 43.108583482441816],
            [-5.129836391636161, 43.107188219360808],
            [-5.140016648849212, 43.109591172794381],
            [-5.150403611637415, 43.114164537463409],
            [-5.159550340076066, 43.113182685532536],
            [-5.212001918853446, 43.093959051768849],
            [-5.307474128028616, 43.085484116898442],
            [-5.361036749945981, 43.091530259913583],
            [-5.374007534220283, 43.08966990793985],
            [-5.382844204296561, 43.085329088166901],
            [-5.389898036764919, 43.063780016234716],
            [-5.398502162844551, 43.056493638870307],
            [-5.408062303332599, 43.053573919700654],
            [-5.418759325382524, 43.05186859825713],
            [-5.473381312697398, 43.051119290322958],
            [-5.478626470934842, 43.0462358672915],
            [-5.481391159574287, 43.039905504335536],
            [-5.485835333933551, 43.033549302957937],
            [-5.490770432909159, 43.028640042404049],
            [-5.498754442263703, 43.027864895148753],
            [-5.517306280660307, 43.02905345355407],
            [-5.540999925406226, 43.021792913712005],
            [-5.551567756246953, 43.021017768255376],
            [-5.561696335717301, 43.022955633695489],
            [-5.570765549790281, 43.026521308011922],
            [-5.578982103141499, 43.031172187046792],
            [-5.600815395913799, 43.034686184519813],
            [-5.615439825687361, 43.03458283083296],
            [-5.637092251306314, 43.037993476417853],
            [-5.665152554247015, 43.048277086418381],
            [-5.679932012752147, 43.049646511077555],
            [-5.705175951109226, 43.048793850355878],
            [-5.719541999363798, 43.045124824151344],
            [-5.729980638095952, 43.040577297903951],
            [-5.735174118590663, 43.034479478944746],
            [-5.738662279440604, 43.028200791933017],
            [-5.743855759935343, 43.021947944242186],
            [-5.750599534940619, 43.016134345223833],
            [-5.75532792834116, 43.010088202208777],
            [-5.758170132245851, 43.004248766567258],
            [-5.759151984176697, 43.001458237707453],
            [-5.75979793932342, 42.998667711545664],
            [-5.762278408022041, 42.993164170889742],
            [-5.766309170331908, 42.987660631133195],
            [-5.769926519693115, 42.98161448811814],
            [-5.778685675403551, 42.974664008437202],
            [-5.789279343766708, 42.972364406891842],
            [-5.804833950426485, 42.9706074077057],
            [-5.84483150886706, 42.971692613323427],
            [-5.854856737348484, 42.97427643480961],
            [-5.862944097691951, 42.977945461913521],
            [-5.889014858349015, 42.98564525042795],
            [-5.893717414227297, 42.989986070200899],
            [-5.904853684949586, 42.992699082896308],
            [-5.91865129242322, 42.998796901855485],
            [-5.930924444707358, 43.006522528791635],
            [-5.954669766296632, 43.01758128514814],
            [-5.960405850049938, 43.023394884166521],
            [-5.964591641091374, 43.030035305484944],
            [-5.967614712149214, 43.03706330043093],
            [-5.971257899932141, 43.051403510263782],
            [-5.976373866960358, 43.057682197275511],
            [-5.987794359422139, 43.062333076310381],
            [-6.016758999028553, 43.05732046206964],
            [-6.02998816572125, 43.057888901951202],
            [-6.07910661507816, 43.065097764949911],
            [-6.088330857882767, 43.062100532313707],
            [-6.091276413675558, 43.056493638870307],
            [-6.092723355398505, 43.049517319868443],
            [-6.095746425557081, 43.041895047518352],
            [-6.102386846875504, 43.03478953730729],
            [-6.122023891789183, 43.027916571992193],
            [-6.135511440000982, 43.027115587214581],
            [-6.148068813125178, 43.029570217491568],
            [-6.175457323396728, 43.03783844678685],
            [-6.199745246445929, 43.041042384998036],
            [-6.212793545086072, 43.038871975561221],
            [-6.218968879310324, 43.033600978902086],
            [-6.221681891106442, 43.020087592268567],
            [-6.226281094197077, 43.015436713233697],
            [-6.234445969805677, 43.014351508515347],
            [-6.253592088304202, 43.017994697197452],
            [-6.297284512270437, 43.033962714107957],
            [-6.310384486854645, 43.033600978902086],
            [-6.329814826193285, 43.037864285208542],
            [-6.337928025857735, 43.043445340230249],
            [-6.347281459871539, 43.048096218365743],
            [-6.364851447236447, 43.051429347786126],
            [-6.392756719646883, 43.042205104981605],
            [-6.398725348296153, 43.03517711003559],
            [-6.401877610563275, 43.028640042404049],
            [-6.408724738355943, 43.022154648917876],
            [-6.413659837331636, 43.016496080429789],
            [-6.414745042050072, 43.010294907783759],
            [-6.414745042050072, 43.004868883292346],
            [-6.418698289094721, 43.000528063519283],
            [-6.426139696090047, 42.997582505927852],
            [-6.446939460087947, 42.992776598161441],
            [-6.47556820291021, 42.991846422174632],
            [-6.480270757889144, 42.987867336708177],
            [-6.481226772297674, 42.982389635373323],
            [-6.47631751084441, 42.976860257195085],
            [-6.468204312079337, 42.973139553247734],
            [-6.4503501038742, 42.968126939006993],
            [-6.444071417761762, 42.963992824808997],
            [-6.444846564117654, 42.957714137797268],
            [-6.449032355159062, 42.951073717378137],
            [-6.501561449201517, 42.929705511700007],
            [-6.515798306246779, 42.928697822246704],
            [-6.551222500018355, 42.917845771465238],
            [-6.562100389221541, 42.916657213059921],
            [-6.608454149039716, 42.919602769752004],
            [-6.614732835152154, 42.923271795956623],
            [-6.622975226025801, 42.925442206292814],
            [-6.635739304725149, 42.927302558266433],
            [-6.682506475693259, 42.926889146217121],
            [-6.701109991832652, 42.924512031205268],
            [-6.719429287131874, 42.920222887376354],
            [-6.72979041239762, 42.915261949079678],
            [-6.73761939122187, 42.909525865326344],
            [-6.744363166227146, 42.903092149582989],
            [-6.752373013104034, 42.897433580195525],
            [-6.763483446303979, 42.892834378004096],
            [-6.770873174657282, 42.89407461235345],
            [-6.777229376934173, 42.904099839935668],
            [-6.795316128236692, 42.910869452463288],
            [-6.828285691731281, 42.914796861086216],
            [-6.833660041177922, 42.952365628570902],
            [-6.8368898178108, 42.96270091451558],
            [-6.845648972621973, 42.971408393382575],
            [-6.868851690952823, 42.98866832148579],
            [-6.877378301767209, 42.991277981393694],
            [-6.896395229955914, 42.990709539713464],
            [-6.905852016757194, 42.992078966171306],
            [-6.913706834003165, 42.994740302023274],
            [-6.922000901720224, 43.000192165835756],
            [-6.924481370418846, 43.002388413694263],
            [-6.945203620050989, 43.024609280094154],
            [-6.951327277431915, 43.025126044031765],
            [-6.953575202133834, 43.023782456894821],
            [-6.95373023086546, 43.00492055923641],
            [-6.954892950848972, 43.001587428916736],
            [-6.955978155567408, 42.999882107473212],
            [-6.957683477910109, 42.997944241133808],
            [-6.961972621739108, 42.995670478010169],
            [-6.966390956777218, 42.994275214029898],
            [-6.975847743578498, 42.998305976339793],
            [-6.983314988995545, 43.007969468716027],
            [-6.988456794445483, 43.019338284334282],
            [-6.986131353579111, 43.030216173537553],
            [-6.979568446626473, 43.036624049959926],
            [-6.971222702965349, 43.038613593142742],
            [-6.963445400085163, 43.042592677709905],
            [-6.958251918691047, 43.049801540708557],
            [-6.955203009211516, 43.059465033984083],
            [-6.952179938153563, 43.066208808089982],
            [-6.948846807833917, 43.072074083052485],
            [-6.945203620050989, 43.076724962087383],
            [-6.939390021032636, 43.079593004413567],
            [-6.932723761292493, 43.080988268393838],
            [-6.922595180922883, 43.080109768351093],
            [-6.913241746009703, 43.080574856344526],
            [-6.877223273035554, 43.091788642331977],
            [-6.868980882161907, 43.095302638905679],
            [-6.83154130678588, 43.120159002735733],
            [-6.829603441345682, 43.128866482502133],
            [-6.832161424410259, 43.139899400436917],
            [-6.847586838961433, 43.159226386088903],
            [-6.857379523446184, 43.1692516145703],
            [-6.866293707888246, 43.175297756686177],
            [-6.873812629249414, 43.174625963117734],
            [-6.879962124152655, 43.170595200807867],
            [-6.884251267981654, 43.1646782499013],
            [-6.886266648686927, 43.158399562889485],
            [-6.891175910140106, 43.153180243073763],
            [-6.898462286605223, 43.149123644140815],
            [-6.907919074305795, 43.146410631445519],
            [-6.926651780755094, 43.13803904936259],
            [-6.943420783342503, 43.132613023072537],
            [-6.951533983006868, 43.134576727833718],
            [-6.95928544836471, 43.138840034140202],
            [-6.965693324787082, 43.144601956315228],
            [-6.97091264460289, 43.151733303149427],
            [-6.973780686929103, 43.159226386088903],
            [-6.972669643789033, 43.168864040942708],
            [-6.965848355317377, 43.183255926719625],
            [-6.965176560849585, 43.189612128097195],
            [-6.965564133577885, 43.195012315066265],
            [-6.9718428205897, 43.198422959751781],
            [-6.979956021153441, 43.200102444572167],
            [-6.995898199642227, 43.206045233900454],
            [-7.006956955998675, 43.217000637469482],
            [-7.013597378216446, 43.217362371775977],
            [-7.021348842674939, 43.218757635756248],
            [-7.043414679443913, 43.2316767440864],
            [-7.050959438327425, 43.237309475052228],
            [-7.056798874868235, 43.24464752926005],
            [-7.06279334103985, 43.25487946241725],
            [-7.071268275910171, 43.297719224762432],
            [-7.078347947699598, 43.303093574209043],
            [-7.086486985785768, 43.307020981932709],
            [-7.094884406290248, 43.308674628331374],
            [-7.108371955401424, 43.319035752697857],
            [-7.115348272604706, 43.32655467405894],
            [-7.121601121194715, 43.336786607216112],
            [-7.124805060305277, 43.348207098778602],
            [-7.125476853873693, 43.357198798485712],
            [-7.125270148298739, 43.365208645362685],
            [-7.126045294654631, 43.372701728302161],
            [-7.130050217643429, 43.378618679208728],
            [-7.136613124595982, 43.382106839159292],
            [-7.153588832758459, 43.381383367848173],
            [-7.166947190660323, 43.386215114935567],
            [-7.17012529134908, 43.390840156448093],
            [-7.171623908116828, 43.39611115220788],
            [-7.168807541734679, 43.416781724996582],
            [-7.166869676294567, 43.423396307893285],
            [-7.160901047645268, 43.429132392545881],
            [-7.14855038099526, 43.431457830714322],
            [-7.137620814948662, 43.431457830714322],
            [-7.127440557735525, 43.428822333283989],
            [-7.119947475695426, 43.428693142074877],
            [-7.112738612696802, 43.429339098120863],
            [-7.105116340346797, 43.433059801168838],
            [-7.095013596600154, 43.440061956793841],
            [-7.081138474760763, 43.456055813025358],
            [-7.050520188755684, 43.476338813085761],
            [-7.048892380778739, 43.477889105797544],
            [-7.046922738073732, 43.483677935203787],
            [-7.044585740999906, 43.47919342700007],
            [-7.043853318999879, 43.47919342700007],
            [-7.038319464999859, 43.47919342700007],
            [-7.032704230999855, 43.495469468000096],
            [-7.029530402999882, 43.512152411000045],
            [-7.023752407999922, 43.525824286000073],
            [-7.01036536399991, 43.533229885000054],
            [-7.0013728509999, 43.556667385000139],
            [-6.943959113999881, 43.565985419000071],
            [-6.845936652999882, 43.568630276000079],
            [-6.830718553999901, 43.56513092700007],
            [-6.770822719999956, 43.568630276000079],
            [-6.729644334999904, 43.559759833000015],
            [-6.703724738999881, 43.557562567000048],
            [-6.648671027999939, 43.579046942000048],
            [-6.597727016999926, 43.575222072000145],
            [-6.546620245999861, 43.561835028000047],
            [-6.510121222999885, 43.547471421000068],
            [-6.499663865999906, 43.553086656000062],
            [-6.488270636999857, 43.555121161000102],
            [-6.462961391999897, 43.554958401000135],
            [-6.464751756999902, 43.559678453000032],
            [-6.467274542999917, 43.570868231000048],
            [-6.469105597999885, 43.575384833000015],
            [-6.455311652999882, 43.573350328000089],
            [-6.431792772999927, 43.563421942000076],
            [-6.417632615999906, 43.561143296000097],
            [-6.366118943999908, 43.568630276000079],
            [-6.314605272999927, 43.568630276000079],
            [-6.300038214999915, 43.570624091000099],
            [-6.27391516799986, 43.579616604000108],
            [-6.249989386999886, 43.584051825000103],
            [-6.243885870999918, 43.589300848000079],
            [-6.236398891999926, 43.594305731000134],
            [-6.222767706999889, 43.595892645000049],
            [-6.190988735999952, 43.578558661000073],
            [-6.17804928299995, 43.575384833000015],
            [-6.100412563999896, 43.569199937000036],
            [-6.08495032499988, 43.575384833000015],
            [-6.069162563999924, 43.569688218000124],
            [-6.054188605999911, 43.57457103100009],
            [-6.03921464799987, 43.583115953000117],
            [-6.023508266999897, 43.588446356000077],
            [-5.907378709999875, 43.588446356000077],
            [-5.902170376999891, 43.59752024900007],
            [-5.90884355399993, 43.604925848000079],
            [-5.91307532499988, 43.612860419000043],
            [-5.900624152999853, 43.623236395000049],
            [-5.902577277999882, 43.62649160400008],
            [-5.905344204999949, 43.633815822000088],
            [-5.907378709999875, 43.636826890000094],
            [-5.875152147999927, 43.636786200000103],
            [-5.863514777999939, 43.64142487200013],
            [-5.859038865999878, 43.653631903000104],
            [-5.852202928999958, 43.662949937000036],
            [-5.836333787999934, 43.662217515000094],
            [-5.803822394999941, 43.650498765000123],
            [-5.782338019999912, 43.637030341000042],
            [-5.727406378999916, 43.589544989000046],
            [-5.708851691999911, 43.568630276000079],
            [-5.701975063999924, 43.568630276000079],
            [-5.696766730999911, 43.582831122000073],
            [-5.68919837099989, 43.578558661000073],
            [-5.67747962099989, 43.561143296000097],
            [-5.625599738999881, 43.554958401000135],
            [-5.424224412999905, 43.561143296000097],
            [-5.393869594999927, 43.555243231000077],
            [-5.390736456999946, 43.541327216000099],
            [-5.403553839999887, 43.525417385000083],
            [-5.420806443999879, 43.513373114000046],
            [-5.413929816999882, 43.506537177000041],
            [-5.403675910999937, 43.511094468000096],
            [-5.382639126999919, 43.516384182000053],
            [-5.37242591099988, 43.52020905200007],
            [-5.365101691999882, 43.526719468000067],
            [-5.362212693999936, 43.533392645000021],
            [-5.356800910999908, 43.538600979000108],
            [-5.341949022999927, 43.540635484000063],
            [-5.299875454999977, 43.537665106000134],
            [-5.284006313999953, 43.533474026000107],
            [-5.211903449999937, 43.498358466000042],
            [-5.177886522999898, 43.489365953000117],
            [-5.091664191999939, 43.486273505000042],
            [-5.067697719999899, 43.481390692000048],
            [-5.057036912999877, 43.468613999000098],
            [-5.045033331999946, 43.474310614000103],
            [-4.975168423999918, 43.458766994000086],
            [-4.967111782999893, 43.460882880000014],
            [-4.952544725999957, 43.470078843000067],
            [-4.941029425999915, 43.471747137000079],
            [-4.930246548999918, 43.46950918200011],
            [-4.907622850999957, 43.460638739000075],
            [-4.83653723899991, 43.449774481000077],
            [-4.754994269999884, 43.421657619000086],
            [-4.734934048999918, 43.417100328000032],
            [-4.513830313922597, 43.402834267027572],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5806",
        OBJECTID_1: 7250,
        diss_me: 5806,
        adm1_cod_1: "ESP-5806",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Ávila",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602115,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 40.561100000000003,
        longitude: -4.87934,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3129138,
        gn_name: "Provincia de Avila",
        gns_id: -371970,
        gns_name: "Avila, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.AV",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.856620515824147, 41.097507442414468],
            [-4.836680330133163, 41.104033321580516],
            [-4.794064361381146, 41.136025509969272],
            [-4.70151552981784, 41.154998899279775],
            [-4.713853176982468, 41.090564082666248],
            [-4.6994501000421, 41.068020136619936],
            [-4.642650781208573, 41.029018669975699],
            [-4.631543603554405, 41.016098747758974],
            [-4.611163964145675, 40.97676769127591],
            [-4.601836570900957, 40.967649038772095],
            [-4.590575583995957, 40.961408804073073],
            [-4.574898093072534, 40.95716808154171],
            [-4.568328268534799, 40.953454702572515],
            [-4.563648093184952, 40.946302602982684],
            [-4.560044577195441, 40.914299427576509],
            [-4.545542623393374, 40.875726427632486],
            [-4.548069479123171, 40.863652452698489],
            [-4.561033346711582, 40.841086534416064],
            [-4.559780904964725, 40.830638535539975],
            [-4.546674215142929, 40.824046738765958],
            [-4.510814838533918, 40.832890732921015],
            [-4.495247211489669, 40.830990098514263],
            [-4.487446918340567, 40.824046738765958],
            [-4.483151263420098, 40.816059678011754],
            [-4.480338763223358, 40.797481796147935],
            [-4.475098285057811, 40.783078719207481],
            [-4.45294984645821, 40.763676863196707],
            [-4.444578264375366, 40.751427106775481],
            [-4.419540420953695, 40.633368020617695],
            [-4.343888562806853, 40.646892191021777],
            [-4.321926891812637, 40.65787851957802],
            [-4.258436899343451, 40.661646830037142],
            [-4.276278696904313, 40.692661235927289],
            [-4.261018686563091, 40.698857525254539],
            [-4.24584656696544, 40.700802105033148],
            [-4.185224006182921, 40.695715435219171],
            [-4.161290859385645, 40.687310086291461],
            [-4.168189663122462, 40.667595527011997],
            [-4.168241339965903, 40.660929267271854],
            [-4.17103186702704, 40.644909573518021],
            [-4.171290249445434, 40.629897569217377],
            [-4.173951585297402, 40.622869575170739],
            [-4.18436438560795, 40.616358344162251],
            [-4.195009730814434, 40.613180243473408],
            [-4.205551724132732, 40.611164862768248],
            [-4.223173387441875, 40.605454617436635],
            [-4.242086961044379, 40.602793280685319],
            [-4.251182012639788, 40.604446926184693],
            [-4.259760301196877, 40.607134101357644],
            [-4.266349045671859, 40.611423245186643],
            [-4.273480394304727, 40.623334662264824],
            [-4.278880581273796, 40.627572130149673],
            [-4.285960252163932, 40.626771145372061],
            [-4.290301072836286, 40.621629339922123],
            [-4.290197720048695, 40.612456773061552],
            [-4.286683721676411, 40.605377102171587],
            [-4.284384121030342, 40.597987372019617],
            [-4.283996548302014, 40.59111440670452],
            [-4.284461636295475, 40.584138089501209],
            [-4.288621588915191, 40.572950141036131],
            [-4.290507778411239, 40.569358629197353],
            [-4.293298306371781, 40.565947984511837],
            [-4.297174038151383, 40.562950750976341],
            [-4.304667121090745, 40.559100856719084],
            [-4.312082688765059, 40.553545641018474],
            [-4.318981493401225, 40.544037177373866],
            [-4.319214035599259, 40.533727728951533],
            [-4.318283861411118, 40.525278632502904],
            [-4.318748949404466, 40.510705877774029],
            [-4.317250331737426, 40.505021470864222],
            [-4.316940273374911, 40.500163886254285],
            [-4.318258022090021, 40.494686184020168],
            [-4.318748949404466, 40.482490546101786],
            [-4.316397671015665, 40.475979315992646],
            [-4.315234951032096, 40.469261380308382],
            [-4.316707729378209, 40.46254344282562],
            [-4.320635138001251, 40.454378567217134],
            [-4.328722500143272, 40.432571112866526],
            [-4.323709885902502, 40.418515122974526],
            [-4.324820929042573, 40.413269964737083],
            [-4.334975347833989, 40.409420071379117],
            [-4.363319870715429, 40.407766424980451],
            [-4.370502896191709, 40.405053412285071],
            [-4.374947068752249, 40.404717516400268],
            [-4.38073482934891, 40.405673529909421],
            [-4.386419237158066, 40.407378852252236],
            [-4.394480759979103, 40.408929144964048],
            [-4.401224534984266, 40.409549261689023],
            [-4.407270677100058, 40.408464056970587],
            [-4.413368496059235, 40.405906073906209],
            [-4.418794520550648, 40.402495429220693],
            [-4.427398646630252, 40.395105699068694],
            [-4.431481085783474, 40.389317939371409],
            [-4.434736700838101, 40.372548936784028],
            [-4.434891729569728, 40.366916204918908],
            [-4.436519538445935, 40.35960399003207],
            [-4.44029191743806, 40.350198880074203],
            [-4.45003292507937, 40.335367742927019],
            [-4.457629359907031, 40.326221015387546],
            [-4.469463262619456, 40.320097358006649],
            [-4.479281784626608, 40.317616889308027],
            [-4.496360845576561, 40.31645416932443],
            [-4.501089239876478, 40.317901109248851],
            [-4.506928677316665, 40.32180268034935],
            [-4.518064948038955, 40.33456675904867],
            [-4.524989590197379, 40.33898509408678],
            [-4.53214677635259, 40.338416653305927],
            [-4.537624477687501, 40.334075833532893],
            [-4.538037888837437, 40.325032456982342],
            [-4.536616787334822, 40.317461860576401],
            [-4.53142330594082, 40.304568589768593],
            [-4.52982133638551, 40.296662096578473],
            [-4.531578334672361, 40.289298203948931],
            [-4.53697852164143, 40.283536281773905],
            [-4.549716762818321, 40.263124091403682],
            [-4.558140020845315, 40.229172675078843],
            [-4.571885952374771, 40.212016099763133],
            [-4.602426724014094, 40.202249253700103],
            [-4.62547441181465, 40.19759837556461],
            [-4.639375372975053, 40.197081610727736],
            [-4.652604538768458, 40.199949653054034],
            [-4.672009039685463, 40.206745104003346],
            [-4.678985357788065, 40.211344306194803],
            [-4.684075487293853, 40.217080389948023],
            [-4.686891851877448, 40.224263414524955],
            [-4.69133602533725, 40.247776191218236],
            [-4.693894009301118, 40.254700833376774],
            [-4.695831874741231, 40.26229726820435],
            [-4.694927537176113, 40.269531968725317],
            [-4.700043504204359, 40.275603950162179],
            [-4.710792202198348, 40.275836493259561],
            [-4.732909714911386, 40.2704104678688],
            [-4.779935269197267, 40.265320340161566],
            [-4.800760870717596, 40.265656236046425],
            [-4.806316087317583, 40.260953681067519],
            [-4.811819627074129, 40.247052720806408],
            [-4.812827318326072, 40.239042873929435],
            [-4.815023566184692, 40.229844469546634],
            [-4.826960821684594, 40.217648830728962],
            [-4.843161382591688, 40.206590074372457],
            [-4.865459764256713, 40.196823229208604],
            [-4.875898402988867, 40.189640203732409],
            [-4.89315833199143, 40.181216945705415],
            [-4.903493617936107, 40.173956406762727],
            [-4.91220109680313, 40.161631578534525],
            [-4.91408728719847, 40.154138494695786],
            [-4.918040534243289, 40.146722927021472],
            [-4.922407192437959, 40.140289212177379],
            [-4.929977789743191, 40.134501451580746],
            [-4.951656052884488, 40.122770900756421],
            [-4.96617713076995, 40.117861640202534],
            [-4.988630540267252, 40.114037584366997],
            [-5.003099941309216, 40.117086492947351],
            [-5.007880010653992, 40.122486680815683],
            [-5.014546271293398, 40.150882880540536],
            [-5.021290046298589, 40.155172024369364],
            [-5.032322964233401, 40.154810289163493],
            [-5.057851120732664, 40.148557441472747],
            [-5.069633348400316, 40.143544827232006],
            [-5.077384812858782, 40.138583888935301],
            [-5.085859747729131, 40.130729071689331],
            [-5.089812994773865, 40.126284898229528],
            [-5.131231654717055, 40.102539578438808],
            [-5.135029873030277, 40.098663844860567],
            [-5.141179368832809, 40.094788113080966],
            [-5.15066199405581, 40.091610012392124],
            [-5.166785040597119, 40.088819485330959],
            [-5.190582038130543, 40.089439602055961],
            [-5.201950852849507, 40.096183377061152],
            [-5.230321214152639, 40.106466987061765],
            [-5.327963832764624, 40.115897936340701],
            [-5.345378791398105, 40.137602037004427],
            [-5.349177007912573, 40.145198472731295],
            [-5.354060430944116, 40.151425482899668],
            [-5.361501837939443, 40.164835516745711],
            [-5.364705776150629, 40.209690659796053],
            [-5.363775601063082, 40.217442125153894],
            [-5.361036749945981, 40.22488353124993],
            [-5.350029670432889, 40.245450751251184],
            [-5.349797126436215, 40.251031806272863],
            [-5.354603034202626, 40.255165921370235],
            [-5.373077358233473, 40.254416612536573],
            [-5.423591070671364, 40.243125312183366],
            [-5.457800869414569, 40.221111152257834],
            [-5.46454464441976, 40.214806626824384],
            [-5.478135545419036, 40.204212958461312],
            [-5.486300421926927, 40.201344916134929],
            [-5.494516974378854, 40.199252021063899],
            [-5.500718146125536, 40.198373521021153],
            [-5.539811367000937, 40.197443345933692],
            [-5.577741868792145, 40.207778631878369],
            [-5.603812629449294, 40.219405829015813],
            [-5.627041185302488, 40.244003811326849],
            [-5.658589647294377, 40.26594045688654],
            [-5.686908331754097, 40.281495063546402],
            [-5.727732713394005, 40.293974921405521],
            [-5.680430362171478, 40.347393888317214],
            [-5.675134951616712, 40.358830656709443],
            [-5.674739444170001, 40.371366057597498],
            [-5.679628360260551, 40.385417571563664],
            [-5.692724063964306, 40.404621673851182],
            [-5.694602726134917, 40.414102875447355],
            [-5.687714297876539, 40.42458383267774],
            [-5.617017273428417, 40.467694185738083],
            [-5.590507263199612, 40.473582858362192],
            [-5.580070250441764, 40.472385348105348],
            [-5.575225279722815, 40.466848238455469],
            [-5.573269712926873, 40.448050630632082],
            [-5.549385435289594, 40.426495454101911],
            [-5.53457586388501, 40.418376557232364],
            [-5.520359555449147, 40.419233490633133],
            [-5.509098568544118, 40.428615815367579],
            [-5.51538274861494, 40.455708101547572],
            [-5.478446711746329, 40.460146577802277],
            [-5.467185724841329, 40.468496187648924],
            [-5.459605158551255, 40.479746188435769],
            [-5.436544854161667, 40.547828466410607],
            [-5.511834164115356, 40.548278906246537],
            [-5.517316342275308, 40.582929786480463],
            [-5.511526546512954, 40.582105811434047],
            [-5.447399347501914, 40.586522315452555],
            [-5.413484550671456, 40.599497169159008],
            [-5.385546317208764, 40.619723000216283],
            [-5.363046316534223, 40.674390970766609],
            [-5.243899583998569, 40.739979352265294],
            [-5.217499435850272, 40.772400008253754],
            [-5.179640547972895, 40.792944442132324],
            [-5.16151310594509, 40.81854258836978],
            [-5.159151045584281, 40.830188096603422],
            [-5.162490889343076, 40.868101915970641],
            [-5.111371502392956, 40.914771839648722],
            [-5.127499432253018, 40.9542457183652],
            [-5.103955730572522, 40.973768424373276],
            [-5.097385906034702, 40.985424918725073],
            [-5.095759929077445, 41.001662712464167],
            [-5.099901775646458, 41.019987908215342],
            [-5.104505047270152, 41.02811779120313],
            [-5.111074871807887, 41.034885369464206],
            [-5.117754559325476, 41.038313103966573],
            [-5.138419843201234, 41.041400262512099],
            [-5.144517255666756, 41.045168572971221],
            [-5.147153974376351, 41.050244256667156],
            [-5.151526533023059, 41.083313106214888],
            [-5.145527997419094, 41.100210079631438],
            [-5.125104412638564, 41.131993527279491],
            [-5.107504315072106, 41.140903439941752],
            [-5.093661540947352, 41.143935666997407],
            [-5.059900553367811, 41.138519406445454],
            [-5.01867984859615, 41.158426633782057],
            [-4.998607825890446, 41.160788695042243],
            [-4.974734534371237, 41.155328489118489],
            [-4.88531680582588, 41.105988888376459],
            [-4.856620515824147, 41.097507442414468],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5807",
        OBJECTID_1: 1365,
        diss_me: 5807,
        adm1_cod_1: "ESP-5807",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Badajoz",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.EX",
        note: null,
        hasc_maybe: null,
        region: "Extremadura",
        region_cod: "ES.EX",
        provnum_ne: 6,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "EX",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP90",
        fips_alt: "SP57",
        woe_id: 12602128,
        woe_label: "Extremadura, ES, Spain",
        woe_name: "Extremadura",
        latitude: 38.573099999999997,
        longitude: -6.2672400000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2521419,
        gn_name: "Provincia de Badajoz",
        gns_id: -372125,
        gns_name: "Badajoz, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.BA",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP57",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.658392300264381, 39.173939927785369],
            [-4.671414761382096, 39.17445669172298],
            [-4.676117316361029, 39.175619411706464],
            [-4.711179774926649, 39.189029446451769],
            [-4.719034593071996, 39.193163561549142],
            [-4.727328659889679, 39.196496690070148],
            [-4.744769456045503, 39.198822130037229],
            [-4.762623664250725, 39.195437323773433],
            [-4.771925422320379, 39.194817206149139],
            [-4.781097988281573, 39.195127265410974],
            [-4.798642137224874, 39.199106349978052],
            [-4.806781175311045, 39.198796292514885],
            [-4.813395758207633, 39.194455470943183],
            [-4.819648606797756, 39.188719387189963],
            [-4.825772264178653, 39.182027289028213],
            [-4.839724901283091, 39.170270901580835],
            [-4.847037116169929, 39.165490831336854],
            [-4.85111955442386, 39.158979601227628],
            [-4.8555637269844, 39.147843328706699],
            [-4.867397629696825, 39.106140448822657],
            [-4.867475144961958, 39.091981106143152],
            [-4.863986985910685, 39.081619980877406],
            [-4.857475754902168, 39.076219793908336],
            [-4.840241665220617, 39.06862335908076],
            [-4.833627082323915, 39.062887275327455],
            [-4.83104325993844, 39.054257310826216],
            [-4.83471228704235, 39.047591051086101],
            [-4.859000210091551, 39.036506456307933],
            [-4.867604336171155, 39.033638413981549],
            [-4.877293666969138, 39.032604885207263],
            [-4.895328742327592, 39.037669176291445],
            [-4.919926723739252, 39.04914134379797],
            [-4.92858252576292, 39.051518459709115],
            [-4.937806768567441, 39.053068753320304],
            [-4.946385057124729, 39.050975857349897],
            [-4.954136521583195, 39.045575670380828],
            [-4.953593919223977, 39.038961087484125],
            [-4.951165127368711, 39.031700548541437],
            [-4.947599453052277, 39.024388332755336],
            [-4.946385057124729, 39.016791897028384],
            [-4.944317999575958, 39.009660549294892],
            [-4.939512090910256, 39.004622097531808],
            [-4.934447800725366, 39.001521511208722],
            [-4.931269700935985, 39.000126248127827],
            [-4.930933804151721, 38.999351100872559],
            [-4.929796921690581, 38.991728826724],
            [-4.929590216115514, 38.984907538252315],
            [-4.928039924302993, 38.977466132156195],
            [-4.925016852345863, 38.9701797547919],
            [-4.920624354830125, 38.964133613575399],
            [-4.913828904780075, 38.95968943921622],
            [-4.879774135667844, 38.952248033120185],
            [-4.873211228715206, 38.948940742121522],
            [-4.846649543441629, 38.945969347007747],
            [-4.840060798067356, 38.939638984051868],
            [-4.836314255698397, 38.928528550851922],
            [-4.841404385204186, 38.914240016963134],
            [-4.845719366555528, 38.892044989884425],
            [-4.850757819217989, 38.886670641336991],
            [-4.858586798941616, 38.886153876500202],
            [-4.886337042620397, 38.889099433192257],
            [-4.895793830320969, 38.888117581261298],
            [-4.904682176341396, 38.885998846869285],
            [-4.912898728793351, 38.88227814382131],
            [-4.919745855686642, 38.877162176792979],
            [-4.927342292312943, 38.870315049899602],
            [-4.942871059651623, 38.848869329855688],
            [-4.947521937787229, 38.839360867110344],
            [-4.971654832104804, 38.769158433814709],
            [-4.981370002223855, 38.749598904166049],
            [-4.990775112181609, 38.739315294165436],
            [-4.997854783971064, 38.735827135114135],
            [-5.002815721368478, 38.735103664702393],
            [-5.00974036262761, 38.735362047120788],
            [-5.021445075030215, 38.734225165558911],
            [-5.036586269640594, 38.728411567439906],
            [-5.063819749381935, 38.725388495482719],
            [-5.079865281557488, 38.719419867732711],
            [-5.15944698728859, 38.717947089386684],
            [-5.170454066801767, 38.713812975188688],
            [-5.173037889187242, 38.708206081745288],
            [-5.169187994929985, 38.701152249276845],
            [-5.167250128590581, 38.693426622340695],
            [-5.170996670060276, 38.682987982709278],
            [-5.174381476324072, 38.676502590122482],
            [-5.176293504241755, 38.670869859156653],
            [-5.180815192067428, 38.666038112968522],
            [-5.210064053413333, 38.659035956444228],
            [-5.255771857185437, 38.634386298189156],
            [-5.288147142376658, 38.612527166995221],
            [-5.293185594139857, 38.607075304082002],
            [-5.297423062024706, 38.603173732981418],
            [-5.300523647448301, 38.599246324358376],
            [-5.303159145777926, 38.594621283745226],
            [-5.30346920414047, 38.590306301494621],
            [-5.306828171982545, 38.585500392828919],
            [-5.311324022285788, 38.584260159378829],
            [-5.317060106039122, 38.583795071385396],
            [-5.329152391169941, 38.585732936825508],
            [-5.34948706717438, 38.586042996087428],
            [-5.362406174605269, 38.583872585751237],
            [-5.379898646705215, 38.571444403836182],
            [-5.385453864204464, 38.566070055288833],
            [-5.394187180593917, 38.555166326764578],
            [-5.400310837974814, 38.549120185548077],
            [-5.404134893810294, 38.543539130526398],
            [-5.406925421770723, 38.538113105135722],
            [-5.407829759335897, 38.531860256545599],
            [-5.411472948018115, 38.524832262498876],
            [-5.41963782362663, 38.515840561892389],
            [-5.449816860059968, 38.500389308919495],
            [-5.450204433687645, 38.499975897769474],
            [-5.464906378726369, 38.489072171043858],
            [-5.470590785636261, 38.483026028028718],
            [-5.480848558114445, 38.469202582133477],
            [-5.488212449844696, 38.46462921836374],
            [-5.504826422801102, 38.46015920738148],
            [-5.549293993123229, 38.440806383307887],
            [-5.562083910244269, 38.432486477169192],
            [-5.56937028581001, 38.425329291013981],
            [-5.575648972821824, 38.404477851071931],
            [-5.576475796021157, 38.399310208099649],
            [-5.575804001553365, 38.394323432280515],
            [-5.572703416129769, 38.389672553245731],
            [-5.563918422896904, 38.381766059156348],
            [-5.561024543048262, 38.377347724118152],
            [-5.560042690217927, 38.372412625142545],
            [-5.560378587002162, 38.367115790061661],
            [-5.563014085331702, 38.35631541612355],
            [-5.56495195167119, 38.351147773151155],
            [-5.567122362007353, 38.341019191882168],
            [-5.567122362007353, 38.336290798481627],
            [-5.564280158102775, 38.332079169018414],
            [-5.559887660587094, 38.328306790026375],
            [-5.554771693558763, 38.324637762922464],
            [-5.550275845054188, 38.320813707086927],
            [-5.547020229999674, 38.316111152107993],
            [-5.544772305297641, 38.311408596229683],
            [-5.540664027722698, 38.299781399092325],
            [-5.538416103920042, 38.295078844113391],
            [-5.5352380032312, 38.290092068294342],
            [-5.532447476170063, 38.284743557269337],
            [-5.52293901162605, 38.270739244220749],
            [-5.521337042970202, 38.266760158754295],
            [-5.521957159695091, 38.259447943867457],
            [-5.521259527705041, 38.252187404924769],
            [-5.51908911826817, 38.244849352515615],
            [-5.517823044597833, 38.237614651095271],
            [-5.51720292787283, 38.230121568155795],
            [-5.517306280660307, 38.222886868534204],
            [-5.519709234993257, 38.20888255548553],
            [-5.526840582726749, 38.20190623648358],
            [-5.529863653784588, 38.19482656469421],
            [-5.533248460048384, 38.180667222913996],
            [-5.533610195254255, 38.17353587518042],
            [-5.533403489679301, 38.167644761796282],
            [-5.538209398345089, 38.162373766036524],
            [-5.581049159790894, 38.135088609451799],
            [-5.623449672564334, 38.136897284582005],
            [-5.642828335059562, 38.12682038015646],
            [-5.649520433221397, 38.120490017200495],
            [-5.671431240359453, 38.104005235453286],
            [-5.693574590594835, 38.091266995175658],
            [-5.710886196440754, 38.088683172790212],
            [-5.722048305584849, 38.090181790457223],
            [-5.728249478230794, 38.095401109373654],
            [-5.733675502722292, 38.116459255789977],
            [-5.734657354653166, 38.123409736370206],
            [-5.734088913872313, 38.129688422482673],
            [-5.72840450696242, 38.134313463095822],
            [-5.720885585601252, 38.138240871718835],
            [-5.711816372427649, 38.141160589989198],
            [-5.696029221771198, 38.148498644197019],
            [-5.691714240419884, 38.153847154322762],
            [-5.693652105859996, 38.159634914919422],
            [-5.697992925632946, 38.16591360103186],
            [-5.713780077188773, 38.179995429345581],
            [-5.727526007818966, 38.188315335484361],
            [-5.740393439305763, 38.190201524081061],
            [-5.807211066337601, 38.183044338825141],
            [-5.837080044408509, 38.174259344692985],
            [-5.846278448791395, 38.168574936883829],
            [-5.853151414106492, 38.161960353987126],
            [-5.861703864241946, 38.149454657706286],
            [-5.867104051211015, 38.144752101828061],
            [-5.874519618885245, 38.140075385270762],
            [-5.900590378643017, 38.127647203355679],
            [-5.909478725562764, 38.121135973246567],
            [-5.915395677368593, 38.114082139878832],
            [-5.919891526772574, 38.106769924092731],
            [-5.922811245042908, 38.099793605990158],
            [-5.922759569098872, 38.092791450365127],
            [-5.918728806788977, 38.086538600875741],
            [-5.913742030969956, 38.08033742912906],
            [-5.910667283967967, 38.073490302235683],
            [-5.910925666386362, 38.06651398413311],
            [-5.913690355025892, 38.059589341974487],
            [-5.930769415975732, 38.042045193031186],
            [-5.933947515765198, 38.036386624543098],
            [-5.935058559804645, 38.031529039033899],
            [-5.937203131719201, 38.025741279336614],
            [-5.941078864398065, 38.018274033919482],
            [-5.952111782332878, 38.007990423918869],
            [-5.962963833114429, 38.002641912893949],
            [-5.975986294232058, 37.998636989905151],
            [-6.018929409364716, 37.991660671802578],
            [-6.038308070960738, 37.990497951819009],
            [-6.114091559277995, 37.978586533841423],
            [-6.138922084686413, 37.96830292384081],
            [-6.16995378274089, 37.949647731757437],
            [-6.183260463799343, 37.938976549028524],
            [-6.200287847905855, 37.951973171724518],
            [-6.330874193389292, 37.994606228494661],
            [-6.356273158679357, 38.000006415463702],
            [-6.356789924415438, 38.000729884976181],
            [-6.359425421845685, 38.006414292785365],
            [-6.361285772920127, 38.013261420578118],
            [-6.365006475968102, 38.021193753089221],
            [-6.372422043642416, 38.031167303827999],
            [-6.413065558128977, 38.053698229489726],
            [-6.423969284854593, 38.056798814014059],
            [-6.434304571698647, 38.057212226063371],
            [-6.442676153781406, 38.055351874089723],
            [-6.449574958417571, 38.051398627044989],
            [-6.453450690197172, 38.045662543291769],
            [-6.458747524378765, 38.039693915541761],
            [-6.473682013414162, 38.029591172694495],
            [-6.478978847595727, 38.025017808924673],
            [-6.481226772297674, 38.019359239537295],
            [-6.484792445714731, 38.016387844423434],
            [-6.499236009234437, 38.014940904499099],
            [-6.523911505911229, 38.021297104977407],
            [-6.571712204754448, 38.02385508894119],
            [-6.577603319037962, 38.028505967976059],
            [-6.580083786837292, 38.035068874928697],
            [-6.579411994168169, 38.04258779628978],
            [-6.581479050817563, 38.050003363964009],
            [-6.584398769987274, 38.056824653335042],
            [-6.60336402043319, 38.077521064545465],
            [-6.617342495060058, 38.086771144872415],
            [-6.6303649561778, 38.090853583126375],
            [-6.644627650745491, 38.09374746387428],
            [-6.677442186407831, 38.096021226998033],
            [-6.701523402982673, 38.092946479096753],
            [-6.74883317720932, 38.093644111086888],
            [-6.776376716212496, 38.100465400457836],
            [-6.788675706019006, 38.106149807367643],
            [-6.801904872711674, 38.1086819520105],
            [-6.810353970059651, 38.113022773582117],
            [-6.813144497120817, 38.118810533279486],
            [-6.811904262771463, 38.125761012960339],
            [-6.804927943769599, 38.13927440049315],
            [-6.800793829571603, 38.145578925027309],
            [-6.795548672233451, 38.151444199989811],
            [-6.792370570645346, 38.158317166204284],
            [-6.792680629907068, 38.165319321829202],
            [-6.797899949722876, 38.172941595977875],
            [-6.933808966010929, 38.209295965736175],
            [-6.950526291754926, 38.198418077432279],
            [-6.950575759931667, 38.198385887999194],
            [-6.964195516999951, 38.206144511000076],
            [-6.992100789999938, 38.204284160000071],
            [-7.018145710999931, 38.198031311000051],
            [-7.021039591999909, 38.198238017000023],
            [-7.026413939999969, 38.199633281000104],
            [-7.038712930999907, 38.186223246000054],
            [-7.063052530999926, 38.177541606000091],
            [-7.088322306999885, 38.173381653000064],
            [-7.103515176999906, 38.173588359000121],
            [-7.117068156884955, 38.183633156222555],
            [-7.126872924999873, 38.190899964000039],
            [-7.167180541999926, 38.272083639000073],
            [-7.172399861999935, 38.276527812000097],
            [-7.189453084999883, 38.286527202000116],
            [-7.196326049999925, 38.29215993300005],
            [-7.200666870999925, 38.298386943000111],
            [-7.207126424999956, 38.311693624000043],
            [-7.211363891999923, 38.317533061000034],
            [-7.317145547999871, 38.424580791000011],
            [-7.345515909999961, 38.440962220000074],
            [-7.359210163999904, 38.446362407000038],
            [-7.344844115999877, 38.458325501000076],
            [-7.335180623999861, 38.469435934000103],
            [-7.334095417999947, 38.481011455000043],
            [-7.34561926299989, 38.494137268000117],
            [-7.335800740999929, 38.505867819000088],
            [-7.317713988999913, 38.5562523400001],
            [-7.275856079999926, 38.603820496000111],
            [-7.27043005399986, 38.617695618000084],
            [-7.273530639999905, 38.625395406000081],
            [-7.279421752999923, 38.628909404000126],
            [-7.28603633599991, 38.631906637000043],
            [-7.290945596999933, 38.638159485000045],
            [-7.292495889999969, 38.646117656000115],
            [-7.292960977999883, 38.656272074000071],
            [-7.290945596999933, 38.679087219000081],
            [-7.284072631999976, 38.713245341000061],
            [-7.280713663999961, 38.720428366000107],
            [-7.272187052999953, 38.73373504700001],
            [-7.27043005399986, 38.737507427000139],
            [-7.225878384889825, 38.766133828256784],
            [-7.211518920999964, 38.775360413000044],
            [-7.196429402999854, 38.779985454000069],
            [-7.179737914999919, 38.790889180000136],
            [-7.166198689999931, 38.803420715000044],
            [-7.160617634999937, 38.812903341000037],
            [-7.15100581899992, 38.819285380000053],
            [-7.10485876499996, 38.827191874000107],
            [-7.08868404099988, 38.833367209000073],
            [-7.076540079999887, 38.843237406000071],
            [-7.064292764999948, 38.851118063000115],
            [-7.056076212999926, 38.855148824000111],
            [-7.057264769999932, 38.869437357000095],
            [-7.052200479999954, 38.884991964000108],
            [-7.066049764999946, 38.904603170000144],
            [-7.044500691999872, 38.918633321000044],
            [-7.02212479699989, 38.942869568000049],
            [-6.988638467999891, 38.993770854000076],
            [-6.97881994599993, 39.003821920000092],
            [-6.973290567999925, 39.013950501000068],
            [-6.974892537999949, 39.023019715000117],
            [-6.986313028999888, 39.030073547000114],
            [-6.979388386999887, 39.047178447000078],
            [-6.985692911999905, 39.066763815000087],
            [-6.99969722599991, 39.084695536000083],
            [-7.015768594999884, 39.096813660000066],
            [-7.038816283999921, 39.109138489000117],
            [-7.06491288299992, 39.114771220000137],
            [-7.091267862999899, 39.112445781000105],
            [-7.115194050999889, 39.10110280400005],
            [-7.127286336999873, 39.097485453000047],
            [-7.14253088399991, 39.099526673000099],
            [-7.157206990999896, 39.105443624000117],
            [-7.167490600999912, 39.113479310000088],
            [-7.168885864999879, 39.121850892000083],
            [-7.157930460999893, 39.141384583000075],
            [-7.155811726999929, 39.151513163000033],
            [-7.168059040999935, 39.170633443000042],
            [-7.191571817999943, 39.17916005500004],
            [-7.219322062999879, 39.184844462000086],
            [-7.244126749999907, 39.195644837000117],
            [-7.258079386999924, 39.21130279500008],
            [-7.265779174999892, 39.231353252000105],
            [-7.264332233999909, 39.252333883000119],
            [-7.250844686116551, 39.270627339790138],
            [-7.203992432204984, 39.265844774616625],
            [-7.157476316878473, 39.314162647488189],
            [-7.155004392638602, 39.318908741345297],
            [-7.157025877042571, 39.325522511254889],
            [-7.167957274108858, 39.335761769390132],
            [-7.170462156703167, 39.343386281951354],
            [-7.169363524207256, 39.347264455390274],
            [-7.163650633070233, 39.358415579315505],
            [-7.161101805104209, 39.361261037866598],
            [-7.11826611039271, 39.380014701217476],
            [-7.096710933862539, 39.38402471077174],
            [-7.072969477559326, 39.380585990151303],
            [-7.078308833485863, 39.335410207315221],
            [-7.072859614579556, 39.315997364286943],
            [-7.053194086337982, 39.311339161173379],
            [-7.045042230214676, 39.31492070402723],
            [-7.040142328005999, 39.320952198884925],
            [-7.022047844332548, 39.373708548910372],
            [-7.02157543226042, 39.382069144875146],
            [-7.023794671287021, 39.389034476859592],
            [-7.028386956792673, 39.395033012463557],
            [-7.035154535053749, 39.400493217487934],
            [-7.067069818817089, 39.414434868474416],
            [-7.049228021256113, 39.436737115425473],
            [-6.888783679323382, 39.383475394973459],
            [-6.872183337391107, 39.382530569929855],
            [-6.833643296700814, 39.389177299093092],
            [-6.815109360208794, 39.387814994366465],
            [-6.79946482853893, 39.377048392669167],
            [-6.780238753115924, 39.341705373504169],
            [-6.760001935940579, 39.329169972616114],
            [-6.751685285347691, 39.302736866113463],
            [-6.762089338851922, 39.278193407899494],
            [-6.800772201775715, 39.23942265513142],
            [-6.778118391850256, 39.19922367823014],
            [-6.769549056044127, 39.190610397052154],
            [-6.681043193288758, 39.177327925743214],
            [-6.659729715853643, 39.198938033763227],
            [-6.585308326318255, 39.181821334387053],
            [-6.580254614858546, 39.179371383282643],
            [-6.578090308221078, 39.175866744154945],
            [-6.576574195142967, 39.166901900002671],
            [-6.574574682975225, 39.162452436730632],
            [-6.570696509536305, 39.158772017014968],
            [-6.565708715684679, 39.15616825665964],
            [-6.554975073240996, 39.153773237045272],
            [-6.545735570739964, 39.155695844587513],
            [-6.535386449624951, 39.168505903824354],
            [-6.528563938974656, 39.174130904217918],
            [-6.371470427817968, 39.162902876566449],
            [-6.319307339861865, 39.184523970704703],
            [-6.316582730408697, 39.130416302969991],
            [-6.312199184744543, 39.115914349167809],
            [-6.293819057503555, 39.100269817498031],
            [-6.248928919134272, 39.09876469053799],
            [-6.230790490089049, 39.089832805639446],
            [-6.212871788802033, 39.066585733644672],
            [-6.205620811451297, 39.061059611012269],
            [-6.196677940434597, 39.0594556071905],
            [-6.173936239765681, 39.060004922988782],
            [-6.167970663415417, 39.058917276611027],
            [-6.142229695843753, 39.031473428356037],
            [-6.08972603193078, 39.084658244182606],
            [-6.021435013215267, 39.127559857401479],
            [-6.011986770872795, 39.068837931025683],
            [-6.009097366949931, 39.065794718751249],
            [-6.00308784522781, 39.063333780629534],
            [-5.986102981067688, 39.065542032638689],
            [-5.941839063122302, 39.108685344952676],
            [-5.924656445238753, 39.083295939456093],
            [-5.858101267359558, 39.103126263066571],
            [-5.828570016193225, 39.119166302183146],
            [-5.810398628793649, 39.144720503048717],
            [-5.767848578549092, 39.136821332138823],
            [-5.689944522122005, 39.082944377381068],
            [-5.646021181032665, 39.10218143892223],
            [-5.614424500090536, 39.10157719073473],
            [-5.601680359361154, 39.105971721617664],
            [-5.589935973366494, 39.117100872407292],
            [-5.577894957686141, 39.137579388677864],
            [-5.577949889176068, 39.147873579202326],
            [-5.607151550503602, 39.185062301284205],
            [-5.612831482387008, 39.188533981158287],
            [-5.566282408706229, 39.209089401154984],
            [-5.541651059748602, 39.21419804410445],
            [-5.517470150626878, 39.209056442800545],
            [-5.469031427758125, 39.17031864838691],
            [-5.444487970443447, 39.158640180899624],
            [-5.410386406007802, 39.163847700710789],
            [-5.396411796667053, 39.171637007741737],
            [-5.371286063401101, 39.194851119583433],
            [-5.347215017259259, 39.228754929396473],
            [-5.346127370881391, 39.237027635516966],
            [-5.347720388585003, 39.243718309152626],
            [-5.353674978817196, 39.256275682276879],
            [-5.355147146523592, 39.263054247555488],
            [-5.348698171983074, 39.301868946594624],
            [-5.339623463951654, 39.32358891759452],
            [-5.32138615894408, 39.328291066079856],
            [-5.247151537013792, 39.310196583305697],
            [-5.178761641436552, 39.309976856446752],
            [-5.140858808187403, 39.32676396688359],
            [-5.122786297649441, 39.339431203886932],
            [-5.117106365766119, 39.347473196131062],
            [-5.115293621203648, 39.357053274588878],
            [-5.115864911036795, 39.377883352934361],
            [-5.113206219191625, 39.387254691550766],
            [-5.098704265389529, 39.398383842340394],
            [-5.083334392068537, 39.39130864647683],
            [-5.056813394822314, 39.363315481524268],
            [-4.991939124491012, 39.370863089459988],
            [-4.944369676419456, 39.391911119402607],
            [-4.880135870873715, 39.366873888419207],
            [-4.855382859831167, 39.37824270313817],
            [-4.850060187227854, 39.378733629553324],
            [-4.842618781131819, 39.380542303784352],
            [-4.836365933441016, 39.38384959658157],
            [-4.813679979047748, 39.39237620739604],
            [-4.777919887592731, 39.400592759847967],
            [-4.767016159967739, 39.404649360579583],
            [-4.752960170975115, 39.418162747212989],
            [-4.703557501677352, 39.438419907952408],
            [-4.692731289317521, 39.449814561992468],
            [-4.677874314647909, 39.417594306432051],
            [-4.681362473699181, 39.400127671854534],
            [-4.696064418737905, 39.378371894347453],
            [-4.710947230929889, 39.344136257182527],
            [-4.720352341787105, 39.334834500012136],
            [-4.730274216581847, 39.32953766493128],
            [-4.740661180269228, 39.327935696275347],
            [-4.749394496658681, 39.324576728433243],
            [-4.756758389288223, 39.316515203813566],
            [-4.756189947607993, 39.306154080346545],
            [-4.75355445017766, 39.297394923736732],
            [-4.734615037254173, 39.260963040511939],
            [-4.71720008041936, 39.236054998939011],
            [-4.715675625229892, 39.232075914371933],
            [-4.710611335045087, 39.225306300945022],
            [-4.69911332821755, 39.218123277267409],
            [-4.670432908551874, 39.190993150313602],
            [-4.658392300264381, 39.173939927785369],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5808",
        OBJECTID_1: 380,
        diss_me: 5808,
        adm1_cod_1: "ESP-5808",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 6,
        name: "Baleares",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.PM",
        note: null,
        hasc_maybe: null,
        region: "Islas Baleares",
        region_cod: "ES.PM",
        provnum_ne: 1,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "PM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP07",
        fips_alt: null,
        woe_id: 12602088,
        woe_label: "Balearic Islands, ES, Spain",
        woe_name: "Islas Baleares",
        latitude: 39.616199999999999,
        longitude: 2.9915600000000002,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 3,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6424360,
        gn_name: "Illes Balears",
        gns_id: -372237,
        gns_name: "Islas Baleares, Comunidad Autonoma de las",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.PM",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP07",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.437510613000143, 38.74925364800005],
              [1.48609459700009, 38.694566148000021],
              [1.526383264000089, 38.677767624000083],
              [1.565683815000114, 38.691485505000017],
              [1.582367384000122, 38.674912828000132],
              [1.581390821000127, 38.653062242000104],
              [1.557485750000097, 38.648838859000151],
              [1.523448113000143, 38.649400132000054],
              [1.474222865000058, 38.674596642],
              [1.458085631000046, 38.681340739000134],
              [1.438731316000144, 38.670274156000019],
              [1.429047071000099, 38.664943752000156],
              [1.412791680000083, 38.64644583200014],
              [1.396976700000096, 38.638646307000059],
              [1.382950333000053, 38.642060925000138],
              [1.387550540000092, 38.66237786100011],
              [1.383459549000094, 38.670000028000075],
              [1.383474155000044, 38.680405992000161],
              [1.388682488000143, 38.68711985900002],
              [1.393321160000056, 38.696275132000025],
              [1.393728061000076, 38.70420970300016],
              [1.386485222000147, 38.70766836100016],
              [1.382823113000086, 38.71157461100016],
              [1.388612436000102, 38.719114917000084],
              [1.401002263000123, 38.730326346000126],
              [1.408029279000033, 38.724485848000043],
              [1.418142123000109, 38.727891343000081],
              [1.427907748000052, 38.739365953000132],
              [1.431325717000078, 38.754868882000139],
              [1.43539472700013, 38.755357164000131],
              [1.436534050000148, 38.754014390000137],
              [1.436534050000148, 38.751613674000097],
              [1.437510613000143, 38.74925364800005],
            ],
          ],
          [
            [
              [1.578379754000139, 39.10488515800013],
              [1.58969160200013, 39.102769273000135],
              [1.59791100400011, 39.098130601000108],
              [1.605967644000145, 39.091294664000188],
              [1.600840691000144, 39.082383531000133],
              [1.606944207000112, 39.062363999000084],
              [1.623057488000086, 39.02920156500015],
              [1.606455925000148, 39.028387762000037],
              [1.593272332000083, 39.020249742000132],
              [1.584646030000101, 39.007432359000106],
              [1.581390821000127, 38.991929429],
              [1.576182488000143, 38.985825914000188],
              [1.541188998000052, 38.967759507000082],
              [1.509776238000114, 38.930161851000079],
              [1.497243686000104, 38.92186107000002],
              [1.469493035000141, 38.91026439000008],
              [1.45866946700005, 38.900132554000109],
              [1.454925977000102, 38.904038804000109],
              [1.448415561000104, 38.908799546000026],
              [1.444997592000107, 38.913153387000122],
              [1.428558790000096, 38.896592515000137],
              [1.414728475000118, 38.890621737000018],
              [1.404144727000073, 38.856756903000147],
              [1.403104951000103, 38.832277563000119],
              [1.370650433000122, 38.831809407000108],
              [1.360850457000083, 38.851914781000076],
              [1.34351647200009, 38.86237213700015],
              [1.328868035000085, 38.857896226000022],
              [1.322032097000147, 38.857896226000022],
              [1.318614129000139, 38.859116929000109],
              [1.31771894600007, 38.859849351000051],
              [1.307790561000076, 38.857896226000022],
              [1.300303582000083, 38.870469468000081],
              [1.283457879000082, 38.874619859000049],
              [1.266612175000091, 38.870347398000106],
              [1.259938998000052, 38.857896226000022],
              [1.228200717000078, 38.874619859000049],
              [1.216644727000045, 38.885077216000113],
              [1.212168816000087, 38.903225002000013],
              [1.224294467000078, 38.92735423400002],
              [1.22754967500012, 38.942002671000054],
              [1.21843509200005, 38.954087632000139],
              [1.226735873000109, 38.960191148000135],
              [1.236827019000089, 38.96515534100017],
              [1.248220248000052, 38.966376044000086],
              [1.259938998000052, 38.960923570000048],
              [1.26726321700005, 38.966050523000078],
              [1.276866082000112, 38.968003648000106],
              [1.300954623000052, 38.967759507000082],
              [1.292246941000144, 39.009873765000023],
              [1.29281660200013, 39.033270575000117],
              [1.304453972000118, 39.043443101000051],
              [1.317556186000104, 39.047593492000189],
              [1.332530144000117, 39.057237046000168],
              [1.356781446000127, 39.077622789000046],
              [1.363047722000061, 39.077622789000046],
              [1.372243686000047, 39.071397203000103],
              [1.391937696000099, 39.083319403000033],
              [1.403330925000148, 39.077622789000046],
              [1.42318769600007, 39.090562242000047],
              [1.450694207000083, 39.101792710000055],
              [1.478688998000109, 39.107733466000084],
              [1.499522332000083, 39.10488515800013],
              [1.508636915000068, 39.114365953000132],
              [1.522715691000116, 39.118882554000024],
              [1.537119988000086, 39.118028062000107],
              [1.553558790000096, 39.107652085],
              [1.560394727000102, 39.105821031000019],
              [1.578379754000139, 39.10488515800013],
            ],
          ],
          [
            [
              [2.982307966000093, 39.139183508000187],
              [2.962154470000144, 39.116062235000172],
              [2.930866760000072, 39.118175889000085],
              [2.913614193000086, 39.151962675000064],
              [2.940424231000122, 39.171930378000084],
              [2.980582428000076, 39.185560431000127],
              [2.985366533000047, 39.166176140000132],
              [2.982307966000093, 39.139183508000187],
            ],
          ],
          [
            [
              [3.179453972000118, 39.968776760000125],
              [3.193125847000147, 39.955104885000182],
              [3.198415561000019, 39.959295966000113],
              [3.201345248000052, 39.960516669000029],
              [3.21420332100007, 39.955104885000182],
              [3.203623894000117, 39.949164130000057],
              [3.168467644000145, 39.936387437000022],
              [3.155609571000127, 39.934027411000059],
              [3.140879754000139, 39.928086656000133],
              [3.127452019000145, 39.916693427000084],
              [3.11548912900011, 39.908758856000091],
              [3.104340040000096, 39.913519598],
              [3.096934441000116, 39.913519598],
              [3.09473717500012, 39.881211656000076],
              [3.130218946000099, 39.876410223000093],
              [3.20679772200009, 39.893011786000145],
              [3.20679772200009, 39.88682689000008],
              [3.196787957000112, 39.881211656000076],
              [3.195567254000082, 39.874538479000122],
              [3.196462436000076, 39.867132880000142],
              [3.193125847000147, 39.859564520000092],
              [3.187185092000021, 39.854356187000107],
              [3.18148847700013, 39.851385809000178],
              [3.165212436000104, 39.845892645000148],
              [3.133555535000141, 39.839544989000117],
              [3.126312696000099, 39.831854559000092],
              [3.124847852000045, 39.811102606000091],
              [3.136485222000147, 39.788763739000089],
              [3.162608269000117, 39.769110419000029],
              [3.19483483200014, 39.754990953000103],
              [3.223887566000116, 39.749701239000117],
              [3.29249108200014, 39.749701239000117],
              [3.309906446000127, 39.755072333000086],
              [3.340342644000089, 39.778998114000117],
              [3.354502800000091, 39.784369208],
              [3.383148634000065, 39.777940171000083],
              [3.439789259000065, 39.751979885000182],
              [3.474375847000147, 39.749701239000117],
              [3.468516472000118, 39.739325262000122],
              [3.469411655000101, 39.731065171000026],
              [3.472666863000143, 39.723578192000062],
              [3.474375847000147, 39.715521552000112],
              [3.47055097700013, 39.710313218000024],
              [3.464366082000083, 39.706406968000053],
              [3.461436394000145, 39.700059312000107],
              [3.467539910000141, 39.687567450000088],
              [3.453868035000113, 39.659694729000037],
              [3.443207227000073, 39.645778713000155],
              [3.429942254000139, 39.639837958000143],
              [3.413096550000148, 39.635077216000141],
              [3.40186608200014, 39.623236395000092],
              [3.398692254000082, 39.60773346600017],
              [3.40601647200009, 39.591986395000035],
              [3.365407748000081, 39.563055731000119],
              [3.32325280000012, 39.523871161000088],
              [3.291758660000113, 39.47577545800003],
              [3.282481316000059, 39.420111395000092],
              [3.272715691000116, 39.424750067000119],
              [3.268809441000116, 39.427557684000149],
              [3.252614780000073, 39.38617584800015],
              [3.221039259000094, 39.359890041000128],
              [3.129405144000089, 39.315741278000061],
              [3.090993686000076, 39.290065822000187],
              [3.083262566000087, 39.280096747000087],
              [3.07911217500012, 39.276882229000037],
              [3.06999759200005, 39.271755276000121],
              [3.060801629000082, 39.269069729000037],
              [3.056651238000114, 39.272975979000037],
              [3.029307488000143, 39.304632880000113],
              [3.022959832000112, 39.308172919000086],
              [3.00733483200014, 39.313747463000098],
              [3.001312696000127, 39.31769440300009],
              [2.996755405000101, 39.326971747000144],
              [2.994639519000089, 39.337958075000088],
              [2.991384311000047, 39.347276109000134],
              [2.984222852000102, 39.351141669000143],
              [2.974457227000045, 39.353461005000113],
              [2.958832227000073, 39.363226630000057],
              [2.950043165000096, 39.365505276000036],
              [2.938161655000044, 39.364365953000103],
              [2.922373894000117, 39.359727281000161],
              [2.91260826900006, 39.358669338000098],
              [2.825450066000144, 39.36359284100017],
              [2.786631707000112, 39.375921942000119],
              [2.761729363000086, 39.400213934000092],
              [2.751231316000116, 39.454779364000061],
              [2.73658287900011, 39.462103583000086],
              [2.739512566000116, 39.478298244000129],
              [2.754893425000148, 39.502630927],
              [2.743500196000099, 39.52338288],
              [2.715098504000082, 39.543524481000034],
              [2.681651238000114, 39.558661200000031],
              [2.65528405000012, 39.564724026000121],
              [2.633474155000073, 39.559271552],
              [2.569102410000056, 39.531520901000093],
              [2.555674675000063, 39.520046291000156],
              [2.544932488000143, 39.49298737200003],
              [2.520192905000073, 39.472845770000092],
              [2.492686394000145, 39.470241604000179],
              [2.473806187000122, 39.495794988000071],
              [2.471690300000091, 39.497910874000084],
              [2.46900475400011, 39.50588613500004],
              [2.472178582000083, 39.512152411],
              [2.480642123000052, 39.516221421000139],
              [2.453949415000096, 39.537420966000141],
              [2.445811394000089, 39.528876044000029],
              [2.438161655000073, 39.525213934000149],
              [2.431162957000083, 39.527696031000104],
              [2.425954623000081, 39.537420966000141],
              [2.398285352000102, 39.524969794000029],
              [2.391856316000087, 39.523749091000113],
              [2.382090691000144, 39.529242255000142],
              [2.373708530000073, 39.547186591000084],
              [2.355642123000052, 39.558661200000031],
              [2.350759311000104, 39.576483466000113],
              [2.351735873000081, 39.596747137000122],
              [2.357676629000082, 39.611883856000119],
              [2.429372592000107, 39.646673895000063],
              [2.450450066000116, 39.650336005000113],
              [2.468272332000083, 39.659369208000115],
              [2.517588738000086, 39.70014069200009],
              [2.532399936000047, 39.706366278000061],
              [2.552419467000021, 39.708685614],
              [2.569672071000127, 39.713080145000092],
              [2.586599155000101, 39.723822333],
              [2.617686394000117, 39.749701239000117],
              [2.621918165000125, 39.754461981000119],
              [2.629079623000081, 39.766872463000041],
              [2.631358269000145, 39.770127671000083],
              [2.635996941000087, 39.771022854000179],
              [2.64747155000012, 39.769232489],
              [2.651866082000112, 39.770127671000083],
              [2.67603600400011, 39.789089260000097],
              [2.679209832000083, 39.79401276200015],
              [2.683604363000057, 39.796210028000147],
              [2.703949415000068, 39.814154364],
              [2.710622592000107, 39.818548895000092],
              [2.717946811000104, 39.820135809000121],
              [2.747406446000099, 39.831610419000143],
              [2.761729363000086, 39.840521552],
              [2.773203972000118, 39.849636135000182],
              [2.78484134200005, 39.856716213000041],
              [2.799652540000096, 39.859564520000092],
              [2.803233269000089, 39.861599026000121],
              [2.80958092500012, 39.87055084800015],
              [2.813243035000056, 39.872585354000179],
              [2.834157748000109, 39.871161200000117],
              [2.84359785200013, 39.872585354000179],
              [2.873057488000114, 39.883490302000055],
              [2.933278842000078, 39.914496161000088],
              [2.964121941000144, 39.920965887000179],
              [3.020274285000141, 39.92515696800011],
              [3.075694207000112, 39.936102606000176],
              [3.179453972000118, 39.968776760000125],
            ],
          ],
          [
            [
              [4.116791212000095, 40.059637762000179],
              [4.139089389000048, 40.045925197000159],
              [4.13697350400011, 40.043036200000031],
              [4.134857618000098, 40.035711981000034],
              [4.135427280000073, 40.028225002000156],
              [4.142263217000107, 40.02480703300013],
              [4.148529493000041, 40.027818101000051],
              [4.15593509200005, 40.034654039000159],
              [4.165782097000147, 40.045925197000159],
              [4.16382897200009, 40.060573635000154],
              [4.170502149000072, 40.064601955000128],
              [4.176524285000056, 40.059230861000074],
              [4.172618035000085, 40.045925197000159],
              [4.178640170000079, 40.038845119000101],
              [4.206716342000078, 40.02480703300013],
              [4.212494337000038, 40.017238674000012],
              [4.216888868000126, 40.01260000200007],
              [4.227224155000044, 40.004909572000045],
              [4.232676629000082, 40.002712307000152],
              [4.247487826000054, 40.000392971000011],
              [4.254567905000101, 39.997463283000073],
              [4.264903191000116, 39.986395575000031],
              [4.273366733000046, 39.96995677300005],
              [4.272390170000079, 39.955349026000121],
              [4.254567905000101, 39.949693101000136],
              [4.2701115240001, 39.943752346000011],
              [4.285899285000141, 39.944606838000098],
              [4.298024936000047, 39.941961981000119],
              [4.302907748000109, 39.925523179000109],
              [4.307302280000101, 39.910549221000096],
              [4.328786655000044, 39.88344961100016],
              [4.337087436000104, 39.867173570000134],
              [4.330332879000082, 39.867173570000134],
              [4.326263868000098, 39.873439846000068],
              [4.319753451000111, 39.879217841000141],
              [4.311778191000059, 39.884182033000101],
              [4.302907748000109, 39.887600002000013],
              [4.300059441000087, 39.821763414000046],
              [4.248138868000098, 39.816473700000145],
              [4.177175326000111, 39.843329169000143],
              [4.015065951000025, 39.925767320000077],
              [3.981374545000023, 39.936061916000185],
              [3.941335483000103, 39.936753648000135],
              [3.869313998000052, 39.925523179000109],
              [3.8306583990001, 39.936061916000185],
              [3.834890170000023, 39.947984117000132],
              [3.835703972000147, 39.962429104000179],
              [3.839121941000144, 39.975572007000139],
              [3.850515170000108, 39.983872789000131],
              [3.8423771490001, 39.989406643000123],
              [3.82203209700009, 39.995143947000102],
              [3.812917514000105, 40.001206773000106],
              [3.803396030000016, 40.013169664000046],
              [3.803151889000077, 40.018133856000091],
              [3.807058139000077, 40.023260809000092],
              [3.809499545000108, 40.03538646],
              [3.821869337000038, 40.047634182000152],
              [3.891530795000108, 40.05955638200011],
              [3.915456576000082, 40.05198802300005],
              [4.011892123000081, 40.05955638200011],
              [4.052012566000087, 40.058172919000029],
              [4.063405795000051, 40.05955638200011],
              [4.077565951000082, 40.064276434000121],
              [4.0943302740001, 40.074733791000185],
              [4.105072462000095, 40.079413153000118],
              [4.107839389000077, 40.06761302300005],
              [4.116791212000095, 40.059637762000179],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5809",
        OBJECTID_1: 850,
        diss_me: 5809,
        adm1_cod_1: "ESP-5809",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Barcelona",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CT",
        note: null,
        hasc_maybe: null,
        region: "Cataluña",
        region_cod: "ES.CT",
        provnum_ne: 16,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP86",
        fips_alt: "SP56",
        woe_id: 12602124,
        woe_label: "Catalonia, ES, Spain",
        woe_name: "Cataluña",
        latitude: 41.657499999999999,
        longitude: 2.0077699999999998,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3128759,
        gn_name: "Provincia de Barcelona",
        gns_id: -372491,
        gns_name: "Barcelona",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.B",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP56",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.987323836925498, 42.294160319842888],
            [2.015954209319318, 42.299400798907698],
            [2.029972764031868, 42.29871964609481],
            [2.036125107987402, 42.296599284829114],
            [2.040651475884857, 42.293523113301035],
            [2.043881455764591, 42.289106609282527],
            [2.046166612399276, 42.282932292191504],
            [2.04671592909682, 42.272725993309905],
            [2.043112413107394, 42.264826822399925],
            [2.029181748239068, 42.251324625131303],
            [2.023886338583679, 42.227341469733034],
            [2.025248643310306, 42.223441323158539],
            [2.04574913181699, 42.204138344009323],
            [2.050868760884612, 42.196810462932547],
            [2.054186632407209, 42.185296790814249],
            [2.055614854741719, 42.152964025569332],
            [2.058844835520745, 42.143438878601387],
            [2.065546496173823, 42.13715469853048],
            [2.073544543046268, 42.135957189172927],
            [2.090902942416875, 42.138374181023607],
            [2.098044055888465, 42.137286534645852],
            [2.124081654944405, 42.120949864045002],
            [2.129333119228107, 42.119587559318376],
            [2.1588863435299, 42.127629551562507],
            [2.204171989345866, 42.12800308677302],
            [2.208610466499863, 42.129914708197191],
            [2.219904411759217, 42.138286290280035],
            [2.231286248661434, 42.142153478500262],
            [2.280153438230599, 42.143449864719429],
            [2.286393672030357, 42.138330235651807],
            [2.294084102199548, 42.123443760521184],
            [2.307795040208958, 42.112962803290714],
            [2.327174923983506, 42.112644199570155],
            [2.365890746161028, 42.121345372390977],
            [2.40544152860366, 42.081080477881727],
            [2.419547974059753, 42.073236239360938],
            [2.459142702773534, 42.073357089358154],
            [2.478434695804509, 42.063820955372762],
            [2.49429895343394, 42.046770173604671],
            [2.502648563280587, 42.025940095259188],
            [2.499308719521792, 42.005055084524486],
            [2.489377078988952, 41.990871734442976],
            [2.439850709742529, 41.949420316694216],
            [2.439279420808589, 41.943432767208407],
            [2.444157349881806, 41.938016507555716],
            [2.452353151376855, 41.932193753438895],
            [2.462965944722612, 41.920899808179627],
            [2.462262819673384, 41.910605617655079],
            [2.442751100682756, 41.887644191026567],
            [2.424425904032319, 41.88901748277064],
            [2.393510375903162, 41.87381240391926],
            [2.382853637185548, 41.871472315794733],
            [2.337546018234207, 41.88909438649668],
            [2.331745236353584, 41.8867213400178],
            [2.324604122881993, 41.87938247192352],
            [2.315309688891034, 41.864847559767071],
            [2.31794640760063, 41.846192773277835],
            [2.329679806577843, 41.825483544030277],
            [2.346049436432395, 41.807916404818286],
            [2.362704710753803, 41.79866591619907],
            [2.370900511349589, 41.798303367106655],
            [2.394543090790989, 41.804312888828747],
            [2.436598756727221, 41.802873680375995],
            [2.547428838669958, 41.71915785684962],
            [2.636989388549466, 41.733220356933913],
            [2.66783899907054, 41.747194967174039],
            [2.683988902066176, 41.749677877532065],
            [2.701501109788325, 41.748853902485564],
            [2.747929334371264, 41.735725240427541],
            [2.767770644099897, 41.721344135723285],
            [2.774032851035315, 41.700382221262544],
            [2.771527968440978, 41.695493305171823],
            [2.762255506686301, 41.68736342218412],
            [2.759596815740508, 41.682815082050354],
            [2.760783338979905, 41.673542620295649],
            [2.778496577777588, 41.645582260906366],
            [2.651866082000112, 41.605292059000135],
            [2.449473504000139, 41.535223700000046],
            [2.428965691000087, 41.518947658000116],
            [2.37134850400011, 41.489203192000105],
            [2.296722852000102, 41.465318101000037],
            [2.26734459700009, 41.448635158000087],
            [2.240407748000024, 41.420965887000079],
            [2.191905144000145, 41.356756903000047],
            [2.163259311000076, 41.326646226000037],
            [2.13054446700005, 41.303656317000048],
            [2.06031334700009, 41.274603583000072],
            [2.019704623000109, 41.265855210000097],
            [1.951345248000024, 41.260687567000076],
            [1.88412519600007, 41.24750397300005],
            [1.863780144000089, 41.238104559000107],
            [1.854014519000145, 41.235988674000083],
            [1.819834832000055, 41.23712799700013],
            [1.624359570868819, 41.192165432194457],
            [1.627829193862169, 41.217983521322395],
            [1.62422567877212, 41.2231690679981],
            [1.617238373652015, 41.225915650586757],
            [1.584235442611629, 41.228838013763351],
            [1.57393026596904, 41.234353150277599],
            [1.574677336389954, 41.245251588989618],
            [1.578962005192381, 41.249206667054068],
            [1.605504974674886, 41.25897351311707],
            [1.612162689956193, 41.264785281115849],
            [1.615502533714988, 41.272398806659538],
            [1.61394247526502, 41.281792117512168],
            [1.608603119338454, 41.288493778165332],
            [1.586081145528453, 41.301029179053302],
            [1.566064055212053, 41.334109013819926],
            [1.563449308738569, 41.358245977569737],
            [1.559626066789491, 41.3662550114596],
            [1.536818448512577, 41.385239386888202],
            [1.485270594862101, 41.407519661603061],
            [1.471867274455121, 41.426635873147006],
            [1.480963953823391, 41.454123666773768],
            [1.482765712267792, 41.476601695211997],
            [1.458969324474708, 41.49063123694205],
            [1.404718834506582, 41.506682263075959],
            [1.397423911784074, 41.52125013448611],
            [1.427658287999577, 41.552396375592252],
            [1.436623132151851, 41.566656630299292],
            [1.431107995637518, 41.573962538240579],
            [1.418407799380475, 41.576115858759891],
            [1.392677817926938, 41.575028212382051],
            [1.407091880985547, 41.590464004210418],
            [1.388722739862601, 41.600373672507033],
            [1.369980063529169, 41.602933487490475],
            [1.374198813824222, 41.619336075699294],
            [1.389447837147912, 41.628520645811136],
            [1.436842859010682, 41.636760392677985],
            [1.438512780440448, 41.643505998702864],
            [1.406806236518605, 41.704150531721666],
            [1.409486900600001, 41.782537986339037],
            [1.412189537816943, 41.789250633110271],
            [1.420758873623157, 41.79716078923903],
            [1.432272545741426, 41.80144545714208],
            [1.444643152159784, 41.802016746975312],
            [1.455827234439369, 41.798775780078216],
            [1.474064539446942, 41.780044089862855],
            [1.482238368705708, 41.776836081320141],
            [1.510473232753498, 41.78131850384591],
            [1.519416103770169, 41.779967185237439],
            [1.543432218422168, 41.763740377616358],
            [1.553078214487982, 41.763718405380075],
            [1.560241301095232, 41.767838278813514],
            [1.630224213476623, 41.829054100766086],
            [1.633915620209706, 41.838491356990573],
            [1.633234467396733, 41.848719629007661],
            [1.614623627178645, 41.884172511152428],
            [1.615612396694758, 41.895686184169989],
            [1.643188081065119, 41.929161527282673],
            [1.643561616275605, 41.954430082782068],
            [1.646242281256264, 41.961593168489941],
            [1.653449312335937, 41.968097075419678],
            [1.662458101859897, 41.970338285783185],
            [1.694076755038253, 41.970107573705576],
            [1.704689548383982, 41.97375503416751],
            [1.712358006317004, 41.980039214238303],
            [1.715807713055483, 41.98789443887722],
            [1.71435751758537, 41.996046295000525],
            [1.707282322621069, 42.003220367725731],
            [1.700492771224418, 42.005780181809996],
            [1.69379111057134, 42.005626373458426],
            [1.669137789377487, 41.997617339568563],
            [1.664084078817154, 41.998924712805234],
            [1.65977743777853, 42.003593902936217],
            [1.657843844118133, 42.011679840552148],
            [1.660854098038129, 42.018425446577112],
            [1.682607028291756, 42.033564606921033],
            [1.688781345382779, 42.041881257514035],
            [1.692384860472913, 42.051648103577151],
            [1.693131930893912, 42.061865389476083],
            [1.689440525060206, 42.079256747201129],
            [1.690143650109405, 42.084694979989308],
            [1.694120700410053, 42.088781894169102],
            [1.707062595762238, 42.092253574043156],
            [1.712577732276458, 42.096021884502392],
            [1.715697850075713, 42.104810947167437],
            [1.706754978159722, 42.130793614733534],
            [1.707018650390438, 42.134836583541443],
            [1.708183201393638, 42.138868566231309],
            [1.71259970541206, 42.145877843587613],
            [1.716071385286199, 42.148525549314627],
            [1.72771689351984, 42.153755041362047],
            [1.733517674501087, 42.16005020755108],
            [1.737165135862313, 42.168289953518553],
            [1.738087987770456, 42.177177893944702],
            [1.735802831135771, 42.185428626030244],
            [1.732067479031002, 42.189372717976511],
            [1.701064060158274, 42.201051185463797],
            [1.695021579182509, 42.205995033044275],
            [1.690627048299689, 42.212356117740597],
            [1.683134371853811, 42.249347086099135],
            [1.683200290361185, 42.256114664360297],
            [1.686320407260951, 42.262124186082332],
            [1.715587986196567, 42.2881178397665],
            [1.842545998898743, 42.306311199402302],
            [1.913913189142335, 42.318374188218144],
            [1.938236920497474, 42.314287274038435],
            [1.973085555354118, 42.294962321753729],
            [1.987323836925498, 42.294160319842888],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5810",
        OBJECTID_1: 7247,
        diss_me: 5810,
        adm1_cod_1: "ESP-5810",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Burgos",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602116,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 42.347999999999999,
        longitude: -3.60703,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3127460,
        gn_name: "Provincia de Burgos",
        gns_id: -374416,
        gns_name: "Burgos",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.BU",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.638903163650014, 42.774236964864045],
              [-2.626294114581697, 42.773203436988936],
              [-2.616682298149499, 42.774185288920009],
              [-2.608129848913393, 42.77661408077519],
              [-2.601541104438411, 42.776097316837692],
              [-2.596347622145032, 42.773642687460054],
              [-2.592704434362219, 42.769250189944287],
              [-2.586942511287816, 42.764883530850241],
              [-2.578105841211595, 42.760491035133228],
              [-2.569501715131992, 42.759095771152957],
              [-2.559192266709744, 42.759354153571351],
              [-2.556065842864342, 42.756382758457576],
              [-2.557771165207043, 42.74824372037132],
              [-2.560354986693341, 42.743851222855753],
              [-2.561931117826759, 42.739148667876734],
              [-2.571284551840563, 42.725247708514914],
              [-2.575160285418804, 42.72132029899258],
              [-2.580431281178676, 42.718478095088088],
              [-2.593117844612777, 42.715894272702442],
              [-2.598207974118765, 42.71315542338418],
              [-2.600300869189766, 42.709072984230872],
              [-2.600791794705572, 42.704370429251938],
              [-2.597045254135168, 42.700287990997921],
              [-2.592161831103624, 42.696257228688197],
              [-2.586839159399688, 42.692536526539428],
              [-2.581981573890488, 42.688247381811252],
              [-2.572137214360964, 42.677395331029786],
              [-2.566065232924132, 42.674527288703402],
              [-2.559502325971579, 42.673519599250184],
              [-2.553301154224812, 42.675379951223718],
              [-2.547099981578924, 42.678067125497378],
              [-2.537720710042692, 42.685973618687584],
              [-2.532346360596051, 42.688815823491453],
              [-2.525783453643498, 42.689487617059868],
              [-2.522553677010535, 42.68638703073681],
              [-2.519530605053319, 42.680961005346106],
              [-2.518290370704051, 42.674527288703402],
              [-2.51444047734617, 42.665768133892314],
              [-2.512941859679046, 42.653365790398979],
              [-2.515267299646126, 42.64930919056674],
              [-2.526041836061893, 42.644735825897627],
              [-2.538237473980189, 42.643366401238453],
              [-2.54275916180589, 42.646208604243768],
              [-2.545136277717006, 42.650006822556875],
              [-2.55040727437617, 42.65181549678779],
              [-2.556918503586019, 42.653365790398979],
              [-2.634071418361259, 42.654761054379222],
              [-2.684352585903099, 42.670289821717816],
              [-2.752436285706011, 42.681839505388879],
              [-2.814137945709632, 42.702923489327603],
              [-2.840751308725913, 42.717754625575438],
              [-2.865323451715938, 42.736280626449698],
              [-2.87015519880336, 42.744678046054972],
              [-2.868113978777103, 42.751395981739265],
              [-2.853541225846868, 42.763694973344414],
              [-2.832870653058137, 42.787259425981759],
              [-2.827082891562213, 42.791832791550164],
              [-2.816592575986533, 42.793977363464606],
              [-2.752798020911911, 42.796974596100725],
              [-2.726132981951565, 42.795062568183042],
              [-2.71502254875162, 42.792995509735007],
              [-2.700423957399664, 42.787957057971894],
              [-2.638903163650014, 42.774236964864045],
            ],
          ],
          [
            [
              [-3.164814215403737, 43.146979072226415],
              [-3.172565679862203, 43.131372788723226],
              [-3.170834519997072, 43.122432765859529],
              [-3.165098436243824, 43.116541653374597],
              [-3.147580124822866, 43.111270656715433],
              [-3.141844041069646, 43.104139308981942],
              [-3.142748378634735, 43.092460435900435],
              [-3.148148565603805, 43.074218654966913],
              [-3.148510300809676, 43.050447495855295],
              [-3.155124883706378, 43.039595445073658],
              [-3.16093848272476, 43.033032538121191],
              [-3.165124273766253, 43.026676336743506],
              [-3.160473394731383, 43.02295563369556],
              [-3.152231003857736, 43.01985504827185],
              [-3.121612717852656, 43.013576362159412],
              [-3.092079636565927, 43.01220693570157],
              [-3.084948288832436, 43.010708319833],
              [-3.073527798169323, 43.013963934887627],
              [-3.055260178814194, 43.043135280968457],
              [-3.036734177940019, 43.049724026342815],
              [-3.028620978275455, 43.047346910431528],
              [-3.023608364034715, 43.043109443446113],
              [-3.023582525612994, 43.037114977274328],
              [-3.022884893622972, 43.031327215778489],
              [-3.016089442673632, 43.025410264871923],
              [-2.997124193127007, 43.020836900202724],
              [-2.989501918978334, 43.014687405299568],
              [-2.987409023007956, 43.008925483124628],
              [-2.989708624553316, 43.000476385776622],
              [-2.992912562764502, 42.99427521402994],
              [-2.99699500191781, 42.990916246187865],
              [-2.999320440985514, 42.98946930626353],
              [-3.001645880952594, 42.989055895113509],
              [-3.008596360633533, 42.988978379848291],
              [-3.027019008720231, 42.986497911149755],
              [-3.03936967626953, 42.987040514408264],
              [-3.039628057788661, 42.978048813801777],
              [-3.034279547663004, 42.972622789310279],
              [-3.025442877586727, 42.965078030426767],
              [-3.012291226159164, 42.958463447530065],
              [-2.983378261597522, 42.950996202113046],
              [-2.978520676987699, 42.944484972003821],
              [-2.977125413007343, 42.939033108191424],
              [-2.97906328024618, 42.933607082800634],
              [-2.98226721755799, 42.92939545333742],
              [-2.987460699851454, 42.926320706335517],
              [-3.000870733697411, 42.923271795956609],
              [-3.008958095839432, 42.923271795956609],
              [-3.021696336117031, 42.914460965201485],
              [-3.029189419056479, 42.910249334838866],
              [-3.037896897923503, 42.908854071757972],
              [-3.058980881862254, 42.908673203705362],
              [-3.07528479735555, 42.905572618281738],
              [-3.084741584156774, 42.905546779860046],
              [-3.094405077432384, 42.906502794268576],
              [-3.104146084174403, 42.908518174973736],
              [-3.123912319398016, 42.910766100575032],
              [-3.133059047836781, 42.913039862799408],
              [-3.141043057191297, 42.916088772279053],
              [-3.146494920104431, 42.921256415251335],
              [-3.151197475982741, 42.934278876369049],
              [-3.157450323673459, 42.939343167453231],
              [-3.166054449753091, 42.942857164026876],
              [-3.175950487025375, 42.945079251206451],
              [-3.188326992097018, 42.946732895806477],
              [-3.224112921973756, 42.943787340013685],
              [-3.233182135147388, 42.938154609048027],
              [-3.2399775860967, 42.931565864573045],
              [-3.244421758657239, 42.924615383093524],
              [-3.256023118272253, 42.912264716443516],
              [-3.270259976216892, 42.900947576769298],
              [-3.275453456711631, 42.893919582722575],
              [-3.279096646293198, 42.887201646139104],
              [-3.280078498224071, 42.880354519245728],
              [-3.275918544705064, 42.874695949858179],
              [-3.261810878868914, 42.864929103795177],
              [-3.231450975282314, 42.835886948923601],
              [-3.224293789127103, 42.831830349091305],
              [-3.217394985390314, 42.829737454020361],
              [-3.211917284055374, 42.832062893088064],
              [-3.209979416816623, 42.837282212903872],
              [-3.210909592803432, 42.842863267925466],
              [-3.207369756908719, 42.848857734097081],
              [-3.202718878773197, 42.855058905843762],
              [-3.195690883827211, 42.860329902503096],
              [-3.187551845741041, 42.86371470786743],
              [-3.178120897361453, 42.865239163056984],
              [-3.159078131650404, 42.864619046331896],
              [-3.151481695923565, 42.868107205383197],
              [-3.144221156980791, 42.873533229874695],
              [-3.130216843932203, 42.88862274854101],
              [-3.121225145124356, 42.890328070883712],
              [-3.114222987700686, 42.886917426198195],
              [-3.110424771186217, 42.879605211311556],
              [-3.112543503779676, 42.868262234114823],
              [-3.119313117206588, 42.858314520898475],
              [-3.130216843932203, 42.84521454541489],
              [-3.141301438710428, 42.834698391417689],
              [-3.147683477610457, 42.825396633347921],
              [-3.151197475982741, 42.817593492046015],
              [-3.15186926865178, 42.810152085950094],
              [-3.147735155353075, 42.803692531785018],
              [-3.141585658651252, 42.797956448031599],
              [-3.134454311817052, 42.793228053731767],
              [-3.129002448004542, 42.786251736528371],
              [-3.132232224637448, 42.777389228030373],
              [-3.13631466289138, 42.769896145091096],
              [-3.123860642554604, 42.770051173822637],
              [-3.119571498725691, 42.768707586685807],
              [-3.093991665382987, 42.774443671338403],
              [-3.063502569687842, 42.785295722119841],
              [-3.031437343758341, 42.772738348995588],
              [-3.003196173664492, 42.753230496190454],
              [-2.984127570430985, 42.743127753343103],
              [-2.968676316558799, 42.737753403896548],
              [-2.943354864735227, 42.714343979990744],
              [-2.917930060124149, 42.705791530754638],
              [-2.852456021128518, 42.665406398686358],
              [-2.841707323134443, 42.655820421575143],
              [-2.837314825618677, 42.644115709172652],
              [-2.906716274136727, 42.626261501866807],
              [-2.933381313097129, 42.630188910489736],
              [-2.948651698916706, 42.635149847887149],
              [-3.01415157723352, 42.640885932539746],
              [-3.056887986791139, 42.637294419801705],
              [-3.07210669576736, 42.632075099985897],
              [-3.075801561292991, 42.62566722356344],
              [-3.064484421618801, 42.613523260690002],
              [-3.061151292198332, 42.606521104165623],
              [-3.060531174574038, 42.599415594853738],
              [-3.06675818474244, 42.591457423921028],
              [-3.073501959747631, 42.585669664223545],
              [-3.079625617128528, 42.579416816532913],
              [-3.08365637943831, 42.573215643886854],
              [-3.113887090916535, 42.548410956000865],
              [-3.11515316368758, 42.540762844329876],
              [-3.110708991127069, 42.536809597285028],
              [-3.102595790563242, 42.536163642138334],
              [-3.094611782108075, 42.538954169199499],
              [-3.08580095135278, 42.540065213238918],
              [-3.077842780419957, 42.539755153977197],
              [-3.072390915708183, 42.535233466151524],
              [-3.070013799797039, 42.529135647192405],
              [-3.070711431787174, 42.520970771583833],
              [-3.073734503744305, 42.512082423764824],
              [-3.080555793115337, 42.499731757114986],
              [-3.083191291444962, 42.491463527819533],
              [-3.080478277850204, 42.484668076870278],
              [-3.065621304079855, 42.476890773990092],
              [-3.060066086580605, 42.471464749498594],
              [-3.05730139794116, 42.464953518490105],
              [-3.052082078125352, 42.459062405105968],
              [-3.049214035799139, 42.452344469421789],
              [-3.049601610326107, 42.446298326406733],
              [-3.058231573927969, 42.425860296715328],
              [-3.059290941124061, 42.403613592793093],
              [-3.058283249872034, 42.395345364397102],
              [-3.056087002013498, 42.388058987032608],
              [-3.056035326069377, 42.380281684152308],
              [-3.071951667035819, 42.359378567367017],
              [-3.078385381879798, 42.355502834688039],
              [-3.097815721218524, 42.352608953940106],
              [-3.107866787222463, 42.348965766157207],
              [-3.109417079934275, 42.343462226400661],
              [-3.105463832889541, 42.337648627382194],
              [-3.09957272040478, 42.332506821932341],
              [-3.097867397162588, 42.325117091780371],
              [-3.098099941159376, 42.307753810889679],
              [-3.096162074819887, 42.299795640856146],
              [-3.093474901445575, 42.291940822710799],
              [-3.090167608648244, 42.284757799033244],
              [-3.088565639992225, 42.277393907302908],
              [-3.08789384642381, 42.269952501206959],
              [-3.088772344667916, 42.260444037562351],
              [-3.09083940311595, 42.249075221943897],
              [-3.102208217834914, 42.217836819213844],
              [-3.10869361042171, 42.209465237130999],
              [-3.120966762705962, 42.201558743041588],
              [-3.120449997869088, 42.196287747281744],
              [-3.087144537590319, 42.158563951065432],
              [-3.084509040160071, 42.1509158393945],
              [-3.077765265154824, 42.137686672701747],
              [-3.070711431787174, 42.133190823297767],
              [-3.05523434129185, 42.125258490786749],
              [-3.044485643297776, 42.113269558443434],
              [-3.039860601785335, 42.106551621859964],
              [-3.036475796420802, 42.099549465335585],
              [-3.026321376730095, 42.087973945041469],
              [-3.01084428533548, 42.082134508500829],
              [-2.997718472329638, 42.081514390876478],
              [-2.99001868291586, 42.082392890019847],
              [-2.975032517936313, 42.082082830757955],
              [-2.960149704845065, 42.083323066006685],
              [-2.951313034768759, 42.08508006429345],
              [-2.94221798317335, 42.084873358718554],
              [-2.934156460352312, 42.082702949281682],
              [-2.928730434961551, 42.077018541472611],
              [-2.924234584658308, 42.070197252101551],
              [-2.921805792803127, 42.063298448364762],
              [-2.917620001761691, 42.057174790983836],
              [-2.910178594766393, 42.023430081133355],
              [-2.932266194229499, 42.018051911366669],
              [-2.964302328889403, 41.962548979651771],
              [-2.969202231098109, 41.940059965095386],
              [-2.973750571231875, 41.930820462594241],
              [-2.981660727360662, 41.929304349516073],
              [-2.990779380763769, 41.929765774570754],
              [-2.999052085984971, 41.926447903947533],
              [-3.002556724213406, 41.921657864718625],
              [-3.016849938174062, 41.886831202997556],
              [-3.045150720729112, 41.889369044845594],
              [-3.075352137690999, 41.883194728653862],
              [-3.171438566736441, 41.792942038943934],
              [-3.194839447082813, 41.826922753382306],
              [-3.201563079972175, 41.831130517559401],
              [-3.228677338388479, 41.840446923786587],
              [-3.233412446127431, 41.84395156291437],
              [-3.237334565837529, 41.84936782256689],
              [-3.252990083625434, 41.863693994881899],
              [-3.272095309051224, 41.86539687556531],
              [-3.286421481366261, 41.855234522055483],
              [-3.287695895349316, 41.833997949245983],
              [-3.277863131678203, 41.771408835549337],
              [-3.27953305400726, 41.767299948234054],
              [-3.282971774627669, 41.764333639685745],
              [-3.320995457874147, 41.745382222611525],
              [-3.354349950989445, 41.737340230367309],
              [-3.358469824422855, 41.693405902260636],
              [-3.393483252849876, 41.690670306689313],
              [-3.459708841789563, 41.589431289322633],
              [-3.527681256784604, 41.607470840606865],
              [-3.535844098126574, 41.605822891413411],
              [-3.540886823468156, 41.600922989204619],
              [-3.542963239361967, 41.593770888715611],
              [-3.54173277075077, 41.577774794970836],
              [-3.572944930364201, 41.565415174670548],
              [-3.623317245994144, 41.573610976165625],
              [-3.647542100487527, 41.570161269427118],
              [-3.672766711514498, 41.559240858478901],
              [-3.678611437867545, 41.554275037762849],
              [-3.690861194288658, 41.535850965149891],
              [-3.697409045690904, 41.531049939802912],
              [-3.719952992636479, 41.521590710442936],
              [-3.72722594132415, 41.514076061760775],
              [-3.729566029448677, 41.504957409257102],
              [-3.729093617376549, 41.45765027813782],
              [-3.769918814701498, 41.460067270887762],
              [-3.786046744561645, 41.502100963688591],
              [-3.834089959084309, 41.499519176468837],
              [-3.835803826785138, 41.499870738543777],
              [-3.88901061574731, 41.547100965037444],
              [-3.980614623166332, 41.583245986113369],
              [-3.992996215702817, 41.608657363846348],
              [-4.030382691507981, 41.630619034840564],
              [-4.027581177429397, 41.684001605289794],
              [-4.031382447142164, 41.701074360193658],
              [-4.055794069240875, 41.740778950987831],
              [-4.04102844410744, 41.768222800142112],
              [-4.037941285561857, 41.789843894280366],
              [-4.090104373518045, 41.831031640697674],
              [-4.088731082673348, 41.870483546278663],
              [-4.053267213511049, 41.86967055735029],
              [-3.998049926263093, 41.88566665199447],
              [-3.991095580396717, 41.890654444946634],
              [-3.985899047602942, 41.897301174109955],
              [-3.982647093688456, 41.905848537679859],
              [-3.980471800932833, 41.925283352944447],
              [-3.976088256168055, 41.930128323663283],
              [-3.914465938851976, 41.942707669922939],
              [-3.901128536053079, 41.950595853815614],
              [-3.893218379924292, 41.962757719493183],
              [-3.892460323385251, 41.974084624006181],
              [-3.896195674590672, 41.984301909905199],
              [-3.903919064013621, 41.991278228007857],
              [-3.915125118529403, 41.992915191083185],
              [-4.006026000899226, 41.957253569097006],
              [-4.024570923509316, 41.957517240428345],
              [-4.029009400663313, 41.976534575110563],
              [-3.972671507783843, 42.030763092842406],
              [-3.970946653964859, 42.039815827738252],
              [-3.978296508177294, 42.048451082051812],
              [-3.988524780194382, 42.051538240597395],
              [-4.079931032990714, 42.040112459222698],
              [-4.085380251897135, 42.042683259424919],
              [-4.1041778606197, 42.061623690381069],
              [-4.120305790479819, 42.128211827513738],
              [-4.205405891868423, 42.13472672056163],
              [-4.190991828809814, 42.181056067383665],
              [-4.238079233070096, 42.214377602144694],
              [-4.2350250337783, 42.229066323552061],
              [-4.276981821953513, 42.383281416903927],
              [-4.272675180914803, 42.385577559656596],
              [-4.317466442422415, 42.382457442756802],
              [-4.326980603272148, 42.390741134096075],
              [-4.330759900748831, 42.405561691618814],
              [-4.330595105379814, 42.438696458774544],
              [-4.325255749453277, 42.449276292866656],
              [-4.315895397854291, 42.456340501712802],
              [-4.302470104311823, 42.459361742650302],
              [-4.24393494554127, 42.455274828470621],
              [-4.246220102175926, 42.492013110716485],
              [-4.263172007082403, 42.536035329566843],
              [-4.276004038555527, 42.550526296351492],
              [-4.297064830777288, 42.566621267857172],
              [-4.299284068904683, 42.56431413898629],
              [-4.304249889620735, 42.568829519866441],
              [-4.282628794583189, 42.576047537963447],
              [-4.29393372685999, 42.605205253919436],
              [-4.274048472658848, 42.616883721406552],
              [-4.275773325578541, 42.6529847962113],
              [-4.285045787333246, 42.675968195975472],
              [-4.282903452931976, 42.686009700387345],
              [-4.26683045456187, 42.705411556398204],
              [-4.265369272973601, 42.701665218175279],
              [-4.258546763222597, 42.697270687292431],
              [-4.245176401169971, 42.692370784184291],
              [-4.218446663182959, 42.704730404484579],
              [-4.185498664531707, 42.736305112291163],
              [-4.159131475637025, 42.748752622435561],
              [-4.145881963581701, 42.751631040240355],
              [-4.132687383016332, 42.747796812173149],
              [-4.117141728208168, 42.737392758668918],
              [-4.111516727814717, 42.736008481706065],
              [-4.103375858708944, 42.738755063395544],
              [-4.096047977632082, 42.745742368515451],
              [-4.085765754385989, 42.763126533462852],
              [-4.047163459026365, 42.770102851565255],
              [-4.018896449611447, 42.816301580853249],
              [-4.011041632365391, 42.820771592734857],
              [-4.002773403070023, 42.823587958217658],
              [-4.000344611214814, 42.821107490418299],
              [-3.999104376865461, 42.815423081709767],
              [-3.998251716143812, 42.792685452271726],
              [-3.997399053623383, 42.785941677266649],
              [-4.000189581583925, 42.769792792303619],
              [-3.965204637384062, 42.762506414939125],
              [-3.949029913999311, 42.764418442856893],
              [-3.929418708406615, 42.768965969104201],
              [-3.917403936742289, 42.77346181940753],
              [-3.883039110166806, 42.797129624832351],
              [-3.865469122801784, 42.799816799105955],
              [-3.83089758975197, 42.799868475949452],
              [-3.821389126107249, 42.803950914203412],
              [-3.815756395141506, 42.810048733162503],
              [-3.814722866367219, 42.816973375320956],
              [-3.815601366409965, 42.82371714942694],
              [-3.818081834209295, 42.828600572458484],
              [-3.825549078726965, 42.839349270452473],
              [-3.827797004328261, 42.847152410855088],
              [-3.826815150598748, 42.857539374542469],
              [-3.827564460331587, 42.865497545475378],
              [-3.831388516167124, 42.872318833947062],
              [-3.839682582984835, 42.875781155475991],
              [-3.849914517041299, 42.876633816197582],
              [-3.862084315638612, 42.872008775584519],
              [-3.86937069300302, 42.864799913485243],
              [-3.877199672726732, 42.85526561141883],
              [-3.885312873290445, 42.851234850008396],
              [-3.89389116004898, 42.851389878739852],
              [-3.900996670260128, 42.855368964206392],
              [-3.90463985804297, 42.861544298430559],
              [-3.905208298823908, 42.868649806843152],
              [-3.90923906113369, 42.880277004879972],
              [-3.906422694751541, 42.885392971008841],
              [-3.90047990632263, 42.889630438893576],
              [-3.899833950276559, 42.894539700346954],
              [-3.89815446635555, 42.898570461757359],
              [-3.869680752264912, 42.899991563259974],
              [-3.839475877409768, 42.9141767443612],
              [-3.834101527963156, 42.921566474513256],
              [-3.836246099877599, 42.927870999047414],
              [-3.841749639634145, 42.934433905999882],
              [-3.848519253061113, 42.94099681295252],
              [-3.855883144791306, 42.945751043875603],
              [-3.864642299602451, 42.947921454211681],
              [-3.873323940047754, 42.946216131868951],
              [-3.881333786924728, 42.943864854379555],
              [-3.886320562743748, 42.938361313723718],
              [-3.89482133603579, 42.925597235923661],
              [-3.901952683769366, 42.920377916107853],
              [-3.92779090042967, 42.908828233336365],
              [-3.9461101948296, 42.90435822235402],
              [-3.956161260833539, 42.904564927929172],
              [-3.965773078165, 42.907252102202747],
              [-3.974248013035321, 42.912161362756748],
              [-3.98065589035707, 42.918491725712514],
              [-3.984790004555066, 42.925829779920448],
              [-3.985100062917525, 42.933141994807201],
              [-3.983627284571469, 42.940325019384105],
              [-3.960708787080819, 42.987428087136564],
              [-3.952621425838089, 42.99923615232656],
              [-3.95114864659277, 43.000631415407454],
              [-3.943293830246091, 43.00497223607978],
              [-3.934973924107311, 43.007633571931834],
              [-3.91662879128566, 43.016496080429832],
              [-3.896139084750928, 43.033187567752023],
              [-3.880248582206264, 43.039905504335579],
              [-3.86495235796491, 43.043471177752636],
              [-3.854901291961056, 43.047656968794158],
              [-3.842679815620954, 43.057320462069598],
              [-3.838804083841353, 43.067087307233422],
              [-3.83802893658617, 43.076027330097205],
              [-3.838287319004564, 43.084657293699152],
              [-3.831724412051926, 43.088377996747127],
              [-3.823068610028258, 43.090341702407684],
              [-3.813224249599472, 43.09085846634521],
              [-3.784647182721272, 43.089101467159068],
              [-3.775293747808178, 43.090393378351749],
              [-3.766224534634574, 43.093442287831309],
              [-3.751005824758977, 43.103338325103707],
              [-3.732815720668953, 43.110857245565413],
              [-3.721111009165725, 43.11804027014243],
              [-3.706202357652671, 43.129719143223937],
              [-3.682327846652782, 43.153386949548135],
              [-3.662148200279148, 43.169303290514407],
              [-3.647807990446381, 43.176176255829503],
              [-3.636697557246436, 43.177054754972986],
              [-3.627783371905082, 43.174315903855884],
              [-3.612022060569643, 43.165324205048037],
              [-3.603779669695996, 43.161758530731518],
              [-3.574349941196857, 43.158657945307837],
              [-3.546082932681173, 43.152999375920459],
              [-3.519030320992528, 43.143775133115938],
              [-3.500065069647178, 43.140700385214686],
              [-3.4816682599822, 43.141863105198084],
              [-3.452005988385622, 43.138865872561965],
              [-3.423790655814116, 43.140622869949439],
              [-3.362424892594674, 43.157934474896152],
              [-3.278476527769556, 43.197311917510959],
              [-3.26832210897814, 43.199947414941292],
              [-3.25945960137949, 43.20118764929056],
              [-3.252483283276831, 43.20043834135636],
              [-3.246592169892693, 43.198087062967588],
              [-3.229745652939499, 43.186976629767642],
              [-3.207498949017264, 43.184392809180807],
              [-3.173573371114173, 43.183979397131409],
              [-3.151507534345171, 43.181447252488752],
              [-3.145926479323577, 43.178372503688124],
              [-3.142154100331453, 43.174780991849261],
              [-3.141844041069646, 43.165117499473084],
              [-3.159543218744489, 43.154937242259862],
              [-3.164814215403737, 43.146979072226415],
            ],
            [
              [-4.092554324622341, 42.13859390788248],
              [-4.073778689035265, 42.133781896417375],
              [-4.057947389760187, 42.13716568554797],
              [-4.053981325577695, 42.139483800536894],
              [-4.052091676389722, 42.143515783226761],
              [-4.05439880615998, 42.15729263884414],
              [-4.059935915809888, 42.165949866293275],
              [-4.070702517507186, 42.172014319505152],
              [-4.08300720541817, 42.174486242845845],
              [-4.093147586691771, 42.172398840833878],
              [-4.097311405496896, 42.169179846173009],
              [-4.100783084471772, 42.164961096777247],
              [-4.102991337380303, 42.160061193669193],
              [-4.103342899455214, 42.154820715503647],
              [-4.100047001068276, 42.145207677792101],
              [-4.096838992525477, 42.141120763612392],
              [-4.092554324622341, 42.13859390788248],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5811",
        OBJECTID_1: 7269,
        diss_me: 5811,
        adm1_cod_1: "ESP-5811",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Cáceres",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.EX",
        note: null,
        hasc_maybe: null,
        region: "Extremadura",
        region_cod: "ES.EX",
        provnum_ne: 6,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "EX",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP90",
        fips_alt: "SP57",
        woe_id: 12602129,
        woe_label: "Extremadura, ES, Spain",
        woe_name: "Extremadura",
        latitude: 39.727899999999998,
        longitude: -6.0820400000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2520610,
        gn_name: "Provincia de Caceres",
        gns_id: -374848,
        gns_name: "Caceres, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CC",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP57",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.912450119777162, 40.28074575471274],
            [-5.903690964966017, 40.284259752185847],
            [-5.896456265344398, 40.289995835939067],
            [-5.891805386309528, 40.29689464057526],
            [-5.888213873571374, 40.304361884193526],
            [-5.885810920137828, 40.311286526352148],
            [-5.881805996249739, 40.31748769809883],
            [-5.875062222143839, 40.321673489140238],
            [-5.867620815148427, 40.32492910419478],
            [-5.834082810873014, 40.332215481559246],
            [-5.81333472281915, 40.340845445161222],
            [-5.804162156858069, 40.342964179553235],
            [-5.794989589997584, 40.341827297991358],
            [-5.785377772666038, 40.331647039879044],
            [-5.78157955615157, 40.323352973061247],
            [-5.780752732952237, 40.315058906243536],
            [-5.783000657654185, 40.308082587241586],
            [-5.79181148930877, 40.296041978954122],
            [-5.789796107704234, 40.290460923932528],
            [-5.783646612800965, 40.286145942581186],
            [-5.762433437653044, 40.284414780917388],
            [-5.727732713394005, 40.293974921405521],
            [-5.686908331754097, 40.281495063546402],
            [-5.658589647294377, 40.26594045688654],
            [-5.627041185302488, 40.244003811326849],
            [-5.603812629449294, 40.219405829015813],
            [-5.577741868792145, 40.207778631878369],
            [-5.539811367000937, 40.197443345933692],
            [-5.500718146125536, 40.198373521021153],
            [-5.494516974378854, 40.199252021063899],
            [-5.486300421926927, 40.201344916134929],
            [-5.478135545419036, 40.204212958461312],
            [-5.46454464441976, 40.214806626824384],
            [-5.457800869414569, 40.221111152257834],
            [-5.423591070671364, 40.243125312183366],
            [-5.373077358233473, 40.254416612536573],
            [-5.354603034202626, 40.255165921370235],
            [-5.349797126436215, 40.251031806272863],
            [-5.350029670432889, 40.245450751251184],
            [-5.361036749945981, 40.22488353124993],
            [-5.363775601063082, 40.217442125153894],
            [-5.364705776150629, 40.209690659796053],
            [-5.361501837939443, 40.164835516745711],
            [-5.354060430944116, 40.151425482899668],
            [-5.349177007912573, 40.145198472731295],
            [-5.345378791398105, 40.137602037004427],
            [-5.327963832764624, 40.115897936340701],
            [-5.35762610526055, 40.105330105499974],
            [-5.360700853161831, 40.101531887186866],
            [-5.365041672934865, 40.093263657891498],
            [-5.370390183959785, 40.068329779695603],
            [-5.371139491893985, 40.058020331273269],
            [-5.368891567192037, 40.048666897259466],
            [-5.37411088700776, 39.994820055401192],
            [-5.391009079905075, 39.92040599174311],
            [-5.390595668755054, 39.893585924051138],
            [-5.3861514961946, 39.881028550926885],
            [-5.376746385337384, 39.883948269197248],
            [-5.357781133992091, 39.887178045830126],
            [-5.330237595888292, 39.888935045016268],
            [-5.321504278599548, 39.887488105091961],
            [-5.313210211781865, 39.884284165981484],
            [-5.306027188104224, 39.879555772580858],
            [-5.299490118674015, 39.873897203193394],
            [-5.293754034920767, 39.86785106107763],
            [-5.289309862360255, 39.861701565275013],
            [-5.289051479941861, 39.854854438381608],
            [-5.292100389421392, 39.84836904579484],
            [-5.302849087415382, 39.835243231889621],
            [-5.307758347969354, 39.82692332575084],
            [-5.310393846298894, 39.814882717463462],
            [-5.311866623745658, 39.803333034691775],
            [-5.314347093343713, 39.793436998318839],
            [-5.31693091482984, 39.771887926386739],
            [-5.313416917356818, 39.763206285041974],
            [-5.308120083175254, 39.758297024488087],
            [-5.274065314063023, 39.752870999097297],
            [-5.260267706589389, 39.752250882372323],
            [-5.253989019577574, 39.753956203815761],
            [-5.24892473029206, 39.757366848501277],
            [-5.237840134614544, 39.771422838393278],
            [-5.229106818225091, 39.779329332482689],
            [-5.208746303798847, 39.792739366328618],
            [-5.20223507279033, 39.79457387988063],
            [-5.182339647256896, 39.79692515737014],
            [-5.175725062561526, 39.796485907798399],
            [-5.168128627733978, 39.792894395959621],
            [-5.163813646382636, 39.784910387504368],
            [-5.159240281713608, 39.771784572699886],
            [-5.15908525208269, 39.762250271532736],
            [-5.165363939094533, 39.747186591288141],
            [-5.16241838240245, 39.741243801060563],
            [-5.148930833291274, 39.72868642793631],
            [-5.141721971191942, 39.720314845853466],
            [-5.14058508963015, 39.712356675819933],
            [-5.142419603182162, 39.704837755358113],
            [-5.146140306230137, 39.69796478914364],
            [-5.150481126003172, 39.68411550572597],
            [-5.151824714039293, 39.663005683365611],
            [-5.155028652250479, 39.656571966722879],
            [-5.157379929739989, 39.649673162986062],
            [-5.160997280000487, 39.643213608820986],
            [-5.171487595576053, 39.63096629495854],
            [-5.175828416248379, 39.624222519953378],
            [-5.181745368054237, 39.618047187527679],
            [-5.186783819817435, 39.611432603731714],
            [-5.197610033076558, 39.599650376963353],
            [-5.199522060994326, 39.593578396425869],
            [-5.19644731309296, 39.587661445519302],
            [-5.190271978868708, 39.582338772016726],
            [-5.182908088037834, 39.577119452200918],
            [-5.16683671744056, 39.568799546961486],
            [-5.159395311344525, 39.563941962351663],
            [-5.122627528637537, 39.527200018965658],
            [-5.101285163179682, 39.509371650081533],
            [-5.08689327560424, 39.500069892011879],
            [-5.079245163933138, 39.492835191490911],
            [-5.064284837375311, 39.485548814126503],
            [-5.046430630069551, 39.482008979131052],
            [-5.03924760639191, 39.478107408030468],
            [-5.024623174819681, 39.459297187215384],
            [-4.994831712013934, 39.432657985777382],
            [-4.984444749225844, 39.420953274274154],
            [-4.971422288108101, 39.409739488286732],
            [-4.966487189132408, 39.406225490813711],
            [-4.955971035135121, 39.399610907917122],
            [-4.944369676419456, 39.391911119402607],
            [-4.991939124491012, 39.370863089459988],
            [-5.056813394822314, 39.363315481524268],
            [-5.083334392068537, 39.39130864647683],
            [-5.098704265389529, 39.398383842340394],
            [-5.113206219191625, 39.387254691550766],
            [-5.115864911036795, 39.377883352934361],
            [-5.115293621203648, 39.357053274588878],
            [-5.117106365766119, 39.347473196131062],
            [-5.122786297649441, 39.339431203886932],
            [-5.140858808187403, 39.32676396688359],
            [-5.178761641436552, 39.309976856446752],
            [-5.247151537013792, 39.310196583305697],
            [-5.32138615894408, 39.328291066079856],
            [-5.339623463951654, 39.32358891759452],
            [-5.348698171983074, 39.301868946594624],
            [-5.355147146523592, 39.263054247555488],
            [-5.353674978817196, 39.256275682276879],
            [-5.347720388585003, 39.243718309152626],
            [-5.346127370881391, 39.237027635516966],
            [-5.347215017259259, 39.228754929396473],
            [-5.371286063401101, 39.194851119583433],
            [-5.396411796667053, 39.171637007741737],
            [-5.410386406007802, 39.163847700710789],
            [-5.444487970443447, 39.158640180899624],
            [-5.469031427758125, 39.17031864838691],
            [-5.517470150626878, 39.209056442800545],
            [-5.541651059748602, 39.21419804410445],
            [-5.566282408706229, 39.209089401154984],
            [-5.612831482387008, 39.188533981158287],
            [-5.607151550503602, 39.185062301284205],
            [-5.577949889176068, 39.147873579202326],
            [-5.577894957686141, 39.137579388677864],
            [-5.589935973366494, 39.117100872407292],
            [-5.601680359361154, 39.105971721617664],
            [-5.614424500090536, 39.10157719073473],
            [-5.646021181032665, 39.10218143892223],
            [-5.689944522122005, 39.082944377381068],
            [-5.767848578549092, 39.136821332138823],
            [-5.810398628793649, 39.144720503048717],
            [-5.828570016193225, 39.119166302183146],
            [-5.858101267359558, 39.103126263066571],
            [-5.924656445238753, 39.083295939456093],
            [-5.941839063122302, 39.108685344952676],
            [-5.986102981067688, 39.065542032638689],
            [-6.00308784522781, 39.063333780629534],
            [-6.009097366949931, 39.065794718751249],
            [-6.011986770872795, 39.068837931025683],
            [-6.021435013215267, 39.127559857401479],
            [-6.08972603193078, 39.084658244182606],
            [-6.142229695843753, 39.031473428356037],
            [-6.167970663415417, 39.058917276611027],
            [-6.173936239765681, 39.060004922988782],
            [-6.196677940434597, 39.0594556071905],
            [-6.205620811451297, 39.061059611012269],
            [-6.212871788802033, 39.066585733644672],
            [-6.230790490089049, 39.089832805639446],
            [-6.248928919134272, 39.09876469053799],
            [-6.293819057503555, 39.100269817498031],
            [-6.312199184744543, 39.115914349167809],
            [-6.316582730408697, 39.130416302969991],
            [-6.319307339861865, 39.184523970704703],
            [-6.371470427817968, 39.162902876566449],
            [-6.528563938974656, 39.174130904217918],
            [-6.535386449624951, 39.168505903824354],
            [-6.545735570739964, 39.155695844587513],
            [-6.554975073240996, 39.153773237045272],
            [-6.565708715684679, 39.15616825665964],
            [-6.570696509536305, 39.158772017014968],
            [-6.574574682975225, 39.162452436730632],
            [-6.576574195142967, 39.166901900002671],
            [-6.578090308221078, 39.175866744154945],
            [-6.580254614858546, 39.179371383282643],
            [-6.585308326318255, 39.181821334387053],
            [-6.659729715853643, 39.198938033763227],
            [-6.681043193288758, 39.177327925743214],
            [-6.769549056044127, 39.190610397052154],
            [-6.778118391850256, 39.19922367823014],
            [-6.800772201775715, 39.23942265513142],
            [-6.762089338851922, 39.278193407899494],
            [-6.751685285347691, 39.302736866113463],
            [-6.760001935940579, 39.329169972616114],
            [-6.780238753115924, 39.341705373504169],
            [-6.79946482853893, 39.377048392669167],
            [-6.815109360208794, 39.387814994366465],
            [-6.833643296700814, 39.389177299093092],
            [-6.872183337391107, 39.382530569929855],
            [-6.888783679323382, 39.383475394973459],
            [-7.049228021256113, 39.436737115425473],
            [-7.067069818817089, 39.414434868474416],
            [-7.035154535053749, 39.400493217487934],
            [-7.028386956792673, 39.395033012463557],
            [-7.023794671287021, 39.389034476859592],
            [-7.02157543226042, 39.382069144875146],
            [-7.022047844332548, 39.373708548910372],
            [-7.040142328005999, 39.320952198884925],
            [-7.045042230214676, 39.31492070402723],
            [-7.053194086337982, 39.311339161173379],
            [-7.072859614579556, 39.315997364286943],
            [-7.078308833485863, 39.335410207315221],
            [-7.072969477559326, 39.380585990151303],
            [-7.096710933862539, 39.38402471077174],
            [-7.11826611039271, 39.380014701217476],
            [-7.161101805104209, 39.361261037866598],
            [-7.163650633070233, 39.358415579315505],
            [-7.169363524207256, 39.347264455390274],
            [-7.170462156703167, 39.343386281951354],
            [-7.167957274108858, 39.335761769390132],
            [-7.157025877042571, 39.325522511254889],
            [-7.155004392638602, 39.318908741345297],
            [-7.157476316878473, 39.314162647488189],
            [-7.203992432204984, 39.265844774616625],
            [-7.250844686116551, 39.270627339790138],
            [-7.300454060999925, 39.317911275000142],
            [-7.319264282999939, 39.329538473000099],
            [-7.326654011999949, 39.341320699000107],
            [-7.331408243999931, 39.362611390000097],
            [-7.331925008999889, 39.384728902000091],
            [-7.326705688999965, 39.398836568000036],
            [-7.31885087099991, 39.413254293000136],
            [-7.313631550999901, 39.43609527600006],
            [-7.313528197999887, 39.457437643000048],
            [-7.320917927999886, 39.46741119400005],
            [-7.362930866999903, 39.476092835000117],
            [-7.384169880999877, 39.483999329000056],
            [-7.399414428999904, 39.4936628210001],
            [-7.408922891999907, 39.515573629000045],
            [-7.417552856999947, 39.524203593000095],
            [-7.451349242999925, 39.537846172000087],
            [-7.468092406999887, 39.550248515000078],
            [-7.497858032999943, 39.581047668000053],
            [-7.515841430999956, 39.593760071000077],
            [-7.548242553999955, 39.663213197000061],
            [-7.555871749140834, 39.677207318231581],
            [-7.557285929999921, 39.679801331000078],
            [-7.341433470999959, 39.67184316],
            [-7.327842569999859, 39.674788717000098],
            [-7.311409464999912, 39.680318095000104],
            [-7.292754272999929, 39.683315329000038],
            [-7.252860066999887, 39.682333476000053],
            [-7.235755167999855, 39.686519267000079],
            [-7.214051065999854, 39.676183981000094],
            [-7.182890177999894, 39.675098775000095],
            [-7.035767373999903, 39.689258118000055],
            [-7.021142944999923, 39.694012350000037],
            [-7.009619099999895, 39.71545806900005],
            [-7.010704304999905, 39.722796122000062],
            [-7.013649861999909, 39.72961741200001],
            [-7.013339802999923, 39.738919170000116],
            [-7.008740600999971, 39.748324280000077],
            [-6.996803344999933, 39.764705709000054],
            [-6.993030964999889, 39.774214173000104],
            [-6.994581258999915, 39.782585755000099],
            [-6.998870401999881, 39.791784160000105],
            [-7.000265664999858, 39.80253285800012],
            [-6.993030964999889, 39.81607208300008],
            [-6.985227824999868, 39.821136373000058],
            [-6.962335164999956, 39.828526103000087],
            [-6.952826700999879, 39.832866924000058],
            [-6.927660278999923, 39.861909078000053],
            [-6.924249633999921, 39.871572571000087],
            [-6.923474487999897, 39.88831573500002],
            [-6.921769164999944, 39.897152405000043],
            [-6.921562459999961, 39.90216501900008],
            [-6.92512813299993, 39.912241924000028],
            [-6.925231485999888, 39.917926331000075],
            [-6.922285928999884, 39.922422180000098],
            [-6.912570759999937, 39.928106588000048],
            [-6.909521850999909, 39.931982321000078],
            [-6.903682413999917, 39.956683655000091],
            [-6.899755004999861, 39.965623678000043],
            [-6.901615355999866, 39.976475728000082],
            [-6.896912800999871, 39.987121074000072],
            [-6.879704548999911, 40.009186910000068],
            [-6.919908812999921, 40.064894104000103],
            [-6.942129679999965, 40.086339823000031],
            [-6.955720581999856, 40.102927959000027],
            [-6.966417602999883, 40.110317688000038],
            [-6.977166300999983, 40.113418274000082],
            [-7.003676309999889, 40.11631215400007],
            [-7.015768594999884, 40.119051005000102],
            [-7.027654174999896, 40.132641907000107],
            [-7.034837198999867, 40.16674835200007],
            [-7.043105428999894, 40.181372783000143],
            [-7.034578816999982, 40.193051656000108],
            [-7.024760294999908, 40.216202698000103],
            [-7.015768594999884, 40.225504456000039],
            [-6.997785196999899, 40.231705627000025],
            [-6.954687052999873, 40.253358053000113],
            [-6.942100807952897, 40.25497773101884],
            [-6.935411742999946, 40.25583852200009],
            [-6.915826374999909, 40.254443258],
            [-6.896447712999958, 40.255476786000088],
            [-6.878050903999849, 40.265243632000136],
            [-6.875105346999959, 40.271238099000072],
            [-6.874509092060407, 40.279188166850332],
            [-6.86218543121268, 40.27175405500563],
            [-6.844253708641702, 40.255786038095124],
            [-6.834745245896357, 40.250308335861007],
            [-6.824513311839894, 40.246355088816273],
            [-6.814694789832629, 40.244417223376161],
            [-6.804695399772839, 40.24436554653272],
            [-6.794385952249883, 40.247905382427433],
            [-6.785135871023556, 40.248706367205074],
            [-6.777771979293391, 40.24648428002547],
            [-6.758780890425754, 40.244830634526181],
            [-6.726328090868691, 40.258111477162913],
            [-6.717646450423302, 40.258938300362246],
            [-6.698707038399107, 40.250850938220225],
            [-6.68857845713012, 40.249739895080069],
            [-6.677416347986025, 40.25351227497157],
            [-6.668398809857194, 40.258163154006354],
            [-6.655040451955301, 40.262038885785955],
            [-6.585148077921474, 40.271986599002304],
            [-6.571014573663604, 40.276534125249697],
            [-6.562229580430824, 40.281495063546402],
            [-6.556105923049898, 40.287101956090424],
            [-6.553186203880188, 40.293871567718668],
            [-6.552772792730167, 40.300847886720618],
            [-6.558121303755172, 40.314697171037579],
            [-6.557966274124254, 40.321570136352676],
            [-6.553082851991974, 40.327822984043507],
            [-6.546881680245292, 40.33363658306186],
            [-6.515539923828385, 40.352420966354515],
            [-6.507840135313955, 40.355857449461752],
            [-6.487402105622635, 40.362110297152498],
            [-6.47983150921678, 40.365546780259734],
            [-6.44226274263147, 40.375933743047739],
            [-6.421307949002625, 40.393400376725992],
            [-6.412807175710583, 40.397431139035774],
            [-6.402213508246803, 40.40063507724696],
            [-6.372396206119987, 40.405440985912747],
            [-6.362732712844434, 40.410401923310076],
            [-6.357720098603693, 40.417352402990929],
            [-6.354464484448357, 40.424302883571272],
            [-6.349503547051029, 40.430607408105431],
            [-6.342423875261602, 40.436085110339548],
            [-6.333483853297196, 40.441588650096094],
            [-6.323148565553879, 40.446317044396011],
            [-6.305501063822987, 40.448745836251305],
            [-6.292581956392127, 40.451691392943445],
            [-6.280205451320484, 40.455980535872982],
            [-6.246228196574037, 40.475255846480081],
            [-6.236693895406972, 40.479441637521518],
            [-6.224110683860999, 40.480320135765623],
            [-6.206669887705175, 40.477555447126178],
            [-6.172899339432973, 40.460915635747966],
            [-6.16258989101064, 40.454481920004611],
            [-6.146518521312743, 40.441304430155355],
            [-6.137139247977927, 40.437532050263854],
            [-6.117967291956973, 40.43381134721588],
            [-6.116416999245075, 40.427403468994868],
            [-6.115822720042502, 40.421253974091627],
            [-6.107735357900509, 40.415517890338322],
            [-6.095591395926164, 40.410608628885143],
            [-6.074610764775002, 40.404743353922612],
            [-6.069133063440063, 40.400169990152875],
            [-6.069107225018456, 40.396294257474011],
            [-6.07507585276835, 40.388697821747058],
            [-6.075618455127568, 40.384718736280604],
            [-6.078408983088025, 40.380739650814235],
            [-6.082336391711124, 40.37665721345968],
            [-6.084248419628807, 40.37293651041162],
            [-6.086625534640774, 40.365081692266273],
            [-6.08957109223212, 40.361154282743968],
            [-6.09626319039387, 40.357562770905105],
            [-6.093162604070898, 40.353687039125504],
            [-6.080036790165764, 40.348390204044733],
            [-6.04507768438765, 40.3376931828941],
            [-6.023011846719385, 40.327771308099443],
            [-6.009524299406849, 40.319244697284972],
            [-6.001204393268154, 40.311519070348822],
            [-5.987613491369501, 40.303225003531026],
            [-5.951543340652648, 40.28914317521739],
            [-5.925550096160009, 40.281495063546402],
            [-5.912450119777162, 40.28074575471274],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5812",
        OBJECTID_1: 7272,
        diss_me: 5812,
        adm1_cod_1: "ESP-5812",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Cádiz",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602096,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 36.482799999999997,
        longitude: -5.8248800000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2520597,
        gn_name: "Provincia de Cadiz",
        gns_id: -374887,
        gns_name: "Cadiz, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CA",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.436698663412614, 36.94876216684051],
            [-5.424152276406403, 36.950959432731622],
            [-5.314970142757829, 37.016372032743135],
            [-5.306345875461801, 37.017591515236262],
            [-5.296150561798981, 37.01287838063277],
            [-5.250392503910888, 36.956694296104871],
            [-5.236450852924492, 36.949311483538168],
            [-5.21899357669227, 36.949641073376853],
            [-5.196460616764028, 36.958276327690328],
            [-5.176619307934743, 36.970811728578383],
            [-5.142243085150227, 37.003704795739708],
            [-5.097034343959791, 36.9559032803121],
            [-5.084663737541433, 36.92538325962974],
            [-5.088080485925644, 36.893566852728014],
            [-5.105493816786208, 36.866276811925331],
            [-5.131146893614215, 36.843776811250791],
            [-5.161765791158302, 36.830043901005212],
            [-5.194076583267702, 36.829055131489071],
            [-5.262708177939999, 36.873879351350951],
            [-5.29072331602822, 36.872363238272783],
            [-5.30490666610973, 36.865068316449623],
            [-5.335020192328045, 36.839667924834799],
            [-5.341392263142438, 36.82557246459757],
            [-5.33269109122088, 36.805654251142812],
            [-5.29740300354581, 36.770695754205747],
            [-5.300709888050875, 36.750272169425017],
            [-5.320968678361794, 36.728727979013001],
            [-5.339282887994898, 36.666347605157853],
            [-5.35561955859572, 36.648846384453122],
            [-5.383744559663626, 36.634597115864239],
            [-5.444938409380086, 36.618172554519731],
            [-5.454540460973476, 36.610339302117097],
            [-5.472788752099206, 36.582807563118536],
            [-5.483467463952309, 36.573139593917148],
            [-5.509219417642072, 36.559955999469736],
            [-5.592704528191348, 36.538642522034792],
            [-5.601944030692493, 36.533215276263945],
            [-5.607843689434731, 36.51966913272426],
            [-5.600098327775584, 36.507957706882621],
            [-5.585651305463358, 36.498959903476816],
            [-5.571413023891893, 36.493565616060323],
            [-5.550154477946791, 36.493203067867256],
            [-5.513185482723827, 36.511780948831699],
            [-5.477095393137859, 36.521833439361728],
            [-5.441301935036222, 36.5236461839242],
            [-5.42620671916481, 36.51805414278445],
            [-5.415352226723854, 36.50700189662021],
            [-5.391467948187199, 36.46583612333842],
            [-5.355355886365118, 36.433690125698718],
            [-5.335108083071532, 36.395259948887485],
            [-5.320001881081964, 36.324914486514359],
            [-5.277792406794163, 36.336911557722203],
            [-5.253651495959815, 36.29710521021866],
            [-5.258615688999924, 36.292547919000128],
            [-5.265939907999922, 36.287095445000091],
            [-5.274118618999921, 36.282538153000047],
            [-5.290598110999923, 36.277411200000131],
            [-5.289499477999868, 36.26984284100007],
            [-5.285308397999927, 36.260809637000079],
            [-5.296620245999918, 36.247381903000075],
            [-5.329335089999859, 36.198146877000084],
            [-5.334055141999954, 36.18781159100007],
            [-5.338773483119979, 36.141119672012252],
            [-5.358386758763487, 36.141109447088297],
            [-5.366607225999928, 36.163153387000079],
            [-5.382517055999955, 36.172308661000045],
            [-5.406076626999891, 36.179225979000051],
            [-5.425445115999906, 36.179754950000131],
            [-5.428700324999852, 36.169989325000103],
            [-5.437896287999934, 36.161485093000067],
            [-5.442331508999899, 36.14878978100009],
            [-5.441477016999897, 36.134548244000086],
            [-5.434885219999927, 36.121527411000017],
            [-5.439890102999868, 36.114203192000105],
            [-5.437163865999906, 36.098700262000079],
            [-5.431630011999886, 36.088934637000051],
            [-5.428700324999852, 36.080796617000118],
            [-5.431833462999919, 36.069566148000035],
            [-5.438628709999875, 36.062811591000099],
            [-5.445505337999862, 36.057440497000044],
            [-5.466501430999898, 36.050726630000099],
            [-5.566599087999862, 36.021633205000029],
            [-5.581003383999899, 36.013983466000099],
            [-5.611765102999897, 36.00645579600004],
            [-5.629709438999924, 36.036769924000026],
            [-5.671701626999919, 36.055975653000075],
            [-5.689361131999931, 36.06712474200009],
            [-5.713124152999853, 36.059963283000059],
            [-5.73387610599994, 36.065659898000035],
            [-5.753651495999861, 36.07558828300003],
            [-5.796050584999932, 36.085516669000043],
            [-5.811268683999913, 36.096828518000024],
            [-5.832753058999856, 36.122381903000104],
            [-5.897897915999891, 36.173895575000074],
            [-5.917185024999924, 36.184068101000037],
            [-5.938954230999883, 36.183823960000097],
            [-5.983550584999961, 36.176988023000064],
            [-6.036773240999935, 36.189520575000074],
            [-6.062489386999886, 36.217230536000073],
            [-6.082183397999927, 36.250677802000013],
            [-6.104725714999915, 36.280707098000079],
            [-6.15945390499985, 36.304307359000092],
            [-6.165964321999923, 36.318711656000062],
            [-6.168527798999946, 36.335313218000039],
            [-6.1752009759999, 36.353583075000103],
            [-6.18419348899991, 36.370713609000035],
            [-6.218373175999915, 36.402451890000094],
            [-6.242054816999939, 36.446478583000015],
            [-6.25711015499985, 36.47642649900007],
            [-6.279611782999922, 36.5112979190001],
            [-6.301421678999901, 36.525987046000097],
            [-6.310007290999891, 36.534125067000019],
            [-6.297678188999953, 36.538763739000046],
            [-6.289377407999979, 36.537298895000077],
            [-6.257517055999955, 36.513780015000037],
            [-6.248972133999928, 36.504746812000121],
            [-6.254099087999947, 36.496283270000077],
            [-6.246652798999946, 36.486639716000099],
            [-6.231475389999957, 36.472683010000139],
            [-6.21906490799995, 36.474961656000119],
            [-6.211496548999889, 36.480536200000131],
            [-6.200754360999895, 36.493841864000046],
            [-6.179351365999935, 36.513535874000098],
            [-6.186472133999899, 36.514227606000134],
            [-6.206817186999984, 36.50889720300006],
            [-6.224354620999918, 36.510850328000117],
            [-6.232451951999849, 36.526190497000073],
            [-6.226022915999948, 36.556667385000111],
            [-6.23489335799988, 36.5757510440001],
            [-6.283192511999914, 36.612372137000108],
            [-6.293853318999936, 36.616848049000083],
            [-6.361724412999934, 36.620550848000022],
            [-6.379790818999936, 36.624253648000064],
            [-6.394683397999898, 36.636908270000049],
            [-6.406971808999856, 36.676703192000019],
            [-6.417632615999906, 36.69594961100006],
            [-6.431548631999874, 36.714585679000066],
            [-6.437896287999905, 36.733303127000028],
            [-6.433338995999861, 36.751206773000092],
            [-6.414540167999917, 36.767645575000074],
            [-6.396311001999919, 36.775091864000046],
            [-6.358876105999883, 36.78583405200007],
            [-6.345611131999874, 36.794989325000046],
            [-6.335194464999887, 36.818264065000079],
            [-6.343739386999886, 36.859930731000105],
            [-6.338205532999979, 36.883734442000019],
            [-6.304351365999906, 36.907212632000068],
            [-6.231609807583254, 36.902832809828993],
            [-6.157687459908317, 36.885997271656748],
            [-6.105183795995345, 36.857498735378329],
            [-5.973051222735734, 36.844128374225079],
            [-5.941487501047305, 36.852016558117668],
            [-5.891038279892655, 36.842073930567494],
            [-5.880271678195356, 36.875087847726036],
            [-5.866242136465388, 36.896994587230409],
            [-5.843445505205892, 36.911079060450277],
            [-5.806388618340094, 36.920615193536321],
            [-5.717047795319502, 36.919626424020265],
            [-5.692614200085245, 36.952695272668649],
            [-5.66907049840475, 36.965186728185017],
            [-5.642219911319813, 36.958474081413783],
            [-5.586870787956485, 36.922351033473348],
            [-5.565744078126727, 36.920823933377818],
            [-5.544639341432486, 36.927020222705124],
            [-5.523710385325984, 36.938621785566909],
            [-5.506736507284046, 36.9547826746807],
            [-5.495343685162965, 36.975327109458533],
            [-5.493179378525497, 36.988115195559885],
            [-5.497562923290275, 37.024238244399498],
            [-5.494519710116549, 37.03375240434994],
            [-5.488971614348486, 37.039652063092205],
            [-5.471492365879953, 37.045496789445309],
            [-5.458978937228096, 37.045881311673156],
            [-5.445169122357072, 37.042684289248569],
            [-5.433194024284717, 37.035773888753994],
            [-5.426184746928499, 37.025051232428467],
            [-5.42827214894055, 37.008022422896488],
            [-5.447761895694896, 36.977019004023873],
            [-5.446674249317113, 36.958539999021696],
            [-5.436698663412614, 36.94876216684051],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5813",
        OBJECTID_1: 394,
        diss_me: 5813,
        adm1_cod_1: "ESP-5813",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Cantabria",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CB",
        note: null,
        hasc_maybe: null,
        region: "Cantabria",
        region_cod: "ES.CB",
        provnum_ne: 14,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CB",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 10,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP39",
        woe_id: 12578028,
        woe_label: "Cantabria, ES, Spain",
        woe_name: "Cantabria",
        latitude: 43.218200000000003,
        longitude: -4.0208500000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3109716,
        gn_name: "Provincia de Cantabria",
        gns_id: 6198366,
        gns_name: "Cantabria, Comunidad Autonoma de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.S",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP39",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.267360201312243, 43.2273939980346],
              [-3.299198581349458, 43.216869095432358],
              [-3.304241306690926, 43.222153519869053],
              [-3.304197361319154, 43.260682573541899],
              [-3.286146823017475, 43.256364946385204],
              [-3.268909273643999, 43.237589310798128],
              [-3.267360201312243, 43.2273939980346],
            ],
          ],
          [
            [
              [-3.431507941999939, 43.466864325000174],
              [-3.428293423999889, 43.461737372000144],
              [-3.427154100999871, 43.455633856000176],
              [-3.428618943999908, 43.446844794000114],
              [-3.432606574999909, 43.445420640000137],
              [-3.438872850999957, 43.446559963000155],
              [-3.447010870999861, 43.445095119000101],
              [-3.451161261999914, 43.447699286000031],
              [-3.460601365999906, 43.45221588700015],
              [-3.470041469999927, 43.454169012000122],
              [-3.474354620999947, 43.448797919000143],
              [-3.477772589999859, 43.443060614000089],
              [-3.485829230999883, 43.437567450000145],
              [-3.501698370999918, 43.430772203000046],
              [-3.481800910999908, 43.428045966000141],
              [-3.470448370999861, 43.416774807000152],
              [-3.461089647999898, 43.412461656000161],
              [-3.447010870999861, 43.430772203000046],
              [-3.436187303999873, 43.419012762000065],
              [-3.422230597999913, 43.418076890000165],
              [-3.392486131999874, 43.424587307000152],
              [-3.378041144999912, 43.422552802000112],
              [-3.330311652999882, 43.410345770000063],
              [-3.268950975999871, 43.404120184000092],
              [-3.223540818999879, 43.391628322000187],
              [-3.210519985999895, 43.389837958],
              [-3.200021938999924, 43.385524807000095],
              [-3.176909959999875, 43.366237697000159],
              [-3.150868292999888, 43.360215562000135],
              [-3.150601676048183, 43.360154509078242],
              [-3.150499843992606, 43.353684801012676],
              [-3.149543829584076, 43.324435940566232],
              [-3.153910487778745, 43.315030828809725],
              [-3.160240850734624, 43.307796129187935],
              [-3.20354570197253, 43.292293199371713],
              [-3.212899135986333, 43.293197536936717],
              [-3.235197515852633, 43.29908865032094],
              [-3.261035731613731, 43.296763211253236],
              [-3.322944098091682, 43.300096341572967],
              [-3.335811529578393, 43.295290432007832],
              [-3.347154506775098, 43.287590644392694],
              [-3.430896165125887, 43.243458970854888],
              [-3.441644863119961, 43.233304552063387],
              [-3.445029670283049, 43.224235337990493],
              [-3.441722378385009, 43.217310695831983],
              [-3.440327114404766, 43.209869289735948],
              [-3.440042893564652, 43.202143662799713],
              [-3.441463995966615, 43.195141507174881],
              [-3.440947231129826, 43.188061835385454],
              [-3.43776913134036, 43.181137193226917],
              [-3.433118252305491, 43.173980007071734],
              [-3.425651006888472, 43.153128567129741],
              [-3.423790655814116, 43.140622869949439],
              [-3.452005988385622, 43.138865872561965],
              [-3.4816682599822, 43.141863105198084],
              [-3.500065069647178, 43.140700385214686],
              [-3.519030320992528, 43.143775133115938],
              [-3.546082932681173, 43.152999375920459],
              [-3.574349941196857, 43.158657945307837],
              [-3.603779669695996, 43.161758530731518],
              [-3.612022060569643, 43.165324205048037],
              [-3.627783371905082, 43.174315903855884],
              [-3.636697557246436, 43.177054754972986],
              [-3.647807990446381, 43.176176255829503],
              [-3.662148200279148, 43.169303290514407],
              [-3.682327846652782, 43.153386949548135],
              [-3.706202357652671, 43.129719143223937],
              [-3.721111009165725, 43.11804027014243],
              [-3.732815720668953, 43.110857245565413],
              [-3.751005824758977, 43.103338325103707],
              [-3.766224534634574, 43.093442287831309],
              [-3.775293747808178, 43.090393378351749],
              [-3.784647182721272, 43.089101467159068],
              [-3.813224249599472, 43.09085846634521],
              [-3.823068610028258, 43.090341702407684],
              [-3.831724412051926, 43.088377996747127],
              [-3.838287319004564, 43.084657293699152],
              [-3.83802893658617, 43.076027330097205],
              [-3.838804083841353, 43.067087307233422],
              [-3.842679815620954, 43.057320462069598],
              [-3.854901291961056, 43.047656968794158],
              [-3.86495235796491, 43.043471177752636],
              [-3.880248582206264, 43.039905504335579],
              [-3.896139084750928, 43.033187567752023],
              [-3.91662879128566, 43.016496080429832],
              [-3.934973924107311, 43.007633571931834],
              [-3.943293830246091, 43.00497223607978],
              [-3.95114864659277, 43.000631415407454],
              [-3.952621425838089, 42.99923615232656],
              [-3.960708787080819, 42.987428087136564],
              [-3.983627284571469, 42.940325019384105],
              [-3.985100062917525, 42.933141994807201],
              [-3.984790004555066, 42.925829779920448],
              [-3.98065589035707, 42.918491725712514],
              [-3.974248013035321, 42.912161362756748],
              [-3.965773078165, 42.907252102202747],
              [-3.956161260833539, 42.904564927929172],
              [-3.9461101948296, 42.90435822235402],
              [-3.92779090042967, 42.908828233336365],
              [-3.901952683769366, 42.920377916107853],
              [-3.89482133603579, 42.925597235923661],
              [-3.886320562743748, 42.938361313723718],
              [-3.881333786924728, 42.943864854379555],
              [-3.873323940047754, 42.946216131868951],
              [-3.864642299602451, 42.947921454211681],
              [-3.855883144791306, 42.945751043875603],
              [-3.848519253061113, 42.94099681295252],
              [-3.841749639634145, 42.934433905999882],
              [-3.836246099877599, 42.927870999047414],
              [-3.834101527963156, 42.921566474513256],
              [-3.839475877409768, 42.9141767443612],
              [-3.869680752264912, 42.899991563259974],
              [-3.89815446635555, 42.898570461757359],
              [-3.899833950276559, 42.894539700346954],
              [-3.90047990632263, 42.889630438893576],
              [-3.906422694751541, 42.885392971008841],
              [-3.90923906113369, 42.880277004879972],
              [-3.905208298823908, 42.868649806843152],
              [-3.90463985804297, 42.861544298430559],
              [-3.900996670260128, 42.855368964206392],
              [-3.89389116004898, 42.851389878739852],
              [-3.885312873290445, 42.851234850008396],
              [-3.877199672726732, 42.85526561141883],
              [-3.86937069300302, 42.864799913485243],
              [-3.862084315638612, 42.872008775584519],
              [-3.849914517041299, 42.876633816197582],
              [-3.839682582984835, 42.875781155475991],
              [-3.831388516167124, 42.872318833947062],
              [-3.827564460331587, 42.865497545475378],
              [-3.826815150598748, 42.857539374542469],
              [-3.827797004328261, 42.847152410855088],
              [-3.825549078726965, 42.839349270452473],
              [-3.818081834209295, 42.828600572458484],
              [-3.815601366409965, 42.82371714942694],
              [-3.814722866367219, 42.816973375320956],
              [-3.815756395141506, 42.810048733162503],
              [-3.821389126107249, 42.803950914203412],
              [-3.83089758975197, 42.799868475949452],
              [-3.865469122801784, 42.799816799105955],
              [-3.883039110166806, 42.797129624832351],
              [-3.917403936742289, 42.77346181940753],
              [-3.929418708406615, 42.768965969104201],
              [-3.949029913999311, 42.764418442856893],
              [-3.965204637384062, 42.762506414939125],
              [-4.000189581583925, 42.769792792303619],
              [-3.997399053623383, 42.785941677266649],
              [-3.998251716143812, 42.792685452271726],
              [-3.999104376865461, 42.815423081709767],
              [-4.000344611214814, 42.821107490418299],
              [-4.002773403070023, 42.823587958217658],
              [-4.011041632365391, 42.820771592734857],
              [-4.018896449611447, 42.816301580853249],
              [-4.047163459026365, 42.770102851565255],
              [-4.085765754385989, 42.763126533462852],
              [-4.096126877853095, 42.776200670524432],
              [-4.1014753888781, 42.781006578291041],
              [-4.110389574219454, 42.785967515688284],
              [-4.120440640223393, 42.78896474922378],
              [-4.147054002340298, 42.790515041935649],
              [-4.158577846690264, 42.793512275471144],
              [-4.178214890704595, 42.806224677326966],
              [-4.185242885650609, 42.811960761080186],
              [-4.187671678405252, 42.817671007311063],
              [-4.184674444869756, 42.823484605430238],
              [-4.177362229982919, 42.826843574171548],
              [-4.159404669889597, 42.83105520273547],
              [-4.15023210302914, 42.831597805094603],
              [-4.141627976949508, 42.834543361786771],
              [-4.135530157990331, 42.8395042991841],
              [-4.132713791608182, 42.84611888297998],
              [-4.134444953271981, 42.853508613132064],
              [-4.14116288895616, 42.858831284835972],
              [-4.150154587763978, 42.862190253577253],
              [-4.17449418765662, 42.85702260970568],
              [-4.183821784147995, 42.852345893148382],
              [-4.196637538791379, 42.84405182633067],
              [-4.201314256247883, 42.842346503088677],
              [-4.208652309556413, 42.842734076716184],
              [-4.215964525342542, 42.84529205978076],
              [-4.221390549834041, 42.850201321233939],
              [-4.223483445804334, 42.855420641049747],
              [-4.224516973679357, 42.859373888094481],
              [-4.225111252881987, 42.865445867732674],
              [-4.226739060858932, 42.872060452427846],
              [-4.227307501639871, 42.87973440252064],
              [-4.229452073554341, 42.886633206257457],
              [-4.230072191178692, 42.893867905879162],
              [-4.231829188566195, 42.901076768877786],
              [-4.236738450918722, 42.915158596292244],
              [-4.238883021933901, 42.929214586184244],
              [-4.237901170002942, 42.936294257074323],
              [-4.237591111640484, 42.943425604807814],
              [-4.239916550708216, 42.950246894178761],
              [-4.254127570231134, 42.957636624330817],
              [-4.356550259087186, 42.985412706431319],
              [-4.370425380926577, 42.992776598161484],
              [-4.37644568462062, 42.999701240319908],
              [-4.37740169902915, 43.001251533031805],
              [-4.38109656365549, 43.008615423862707],
              [-4.400811122934982, 43.033290921438876],
              [-4.409647793011175, 43.038329373202046],
              [-4.433961553582805, 43.048018704000029],
              [-4.44602800119128, 43.050860907904521],
              [-4.462874518144559, 43.050938422270391],
              [-4.481245490287193, 43.042541001765827],
              [-4.490857305820015, 43.039595445073658],
              [-4.505662603646215, 43.03941457792051],
              [-4.518710903185649, 43.037709256476987],
              [-4.552378098670289, 43.023937486525782],
              [-4.565607266262333, 43.023239854535646],
              [-4.601238165608891, 43.025539456081006],
              [-4.639116990556658, 43.019777533906066],
              [-4.689604864572829, 43.019596665853456],
              [-4.70583126480102, 43.015720934073855],
              [-4.726863572795594, 43.015927638749545],
              [-4.734744229362661, 43.04140412020395],
              [-4.738723313929825, 43.0480962183657],
              [-4.758515386675697, 43.062281399467011],
              [-4.764458176903361, 43.071608995059094],
              [-4.770452643074975, 43.078636990005108],
              [-4.81138037840168, 43.094811713389859],
              [-4.820759649937827, 43.100676988352362],
              [-4.827890997671403, 43.108066718504247],
              [-4.833549567058839, 43.123931383526482],
              [-4.836030035757545, 43.135093491771286],
              [-4.831870083137858, 43.188681952110429],
              [-4.819726121163512, 43.187596747391993],
              [-4.79001217272355, 43.191110744864901],
              [-4.780400356291352, 43.193617051985328],
              [-4.770917731068437, 43.194934800700551],
              [-4.75184912783493, 43.191937568064318],
              [-4.742676560974473, 43.192144272740009],
              [-4.734537522888331, 43.196898505461732],
              [-4.727948778413406, 43.207750556243198],
              [-4.72531328008381, 43.216432196688586],
              [-4.724641485616019, 43.224493720408887],
              [-4.723246221635748, 43.231392524145704],
              [-4.721127489042374, 43.238239651039081],
              [-4.723633796162716, 43.251985582568651],
              [-4.721540900192394, 43.258703518252744],
              [-4.715675625229892, 43.264697984424359],
              [-4.703609177621416, 43.269503893090146],
              [-4.693118862045822, 43.271881008102085],
              [-4.682783576101173, 43.272501125726464],
              [-4.673636846763031, 43.271105861746108],
              [-4.663947515965077, 43.270744127439585],
              [-4.643173591288161, 43.27219106736392],
              [-4.633690965165869, 43.27570506393765],
              [-4.628704189346848, 43.278547267842143],
              [-4.624285855208115, 43.284128322863822],
              [-4.617412888993556, 43.289864407516419],
              [-4.602220018439027, 43.296840724719729],
              [-4.592608202006915, 43.29815847433413],
              [-4.58188534243456, 43.297357490455866],
              [-4.563540207814356, 43.294205227289396],
              [-4.556796433708456, 43.290562039506554],
              [-4.551163702742713, 43.279167385466408],
              [-4.545169236571013, 43.275808416725127],
              [-4.53715938969404, 43.277203680705313],
              [-4.52982133638551, 43.281699531008641],
              [-4.523542650273043, 43.287900701855946],
              [-4.520364548684938, 43.296220607994726],
              [-4.518710903185649, 43.305987454057842],
              [-4.521863166352063, 43.319965929584086],
              [-4.530880702682225, 43.338233548039867],
              [-4.535144008988794, 43.342264309450385],
              [-4.537314419324957, 43.348310452465412],
              [-4.534446376998659, 43.354614976999684],
              [-4.521966519139539, 43.370634670753432],
              [-4.51325904027243, 43.384354762961905],
              [-4.514370083412587, 43.396498724936137],
              [-4.513830313922597, 43.402834267027558],
              [-4.512318488999938, 43.402736721000011],
              [-4.508168097999885, 43.40245189000008],
              [-4.481678839999915, 43.383612372000059],
              [-4.460804816999939, 43.398708401000064],
              [-4.432525193999908, 43.402614651000036],
              [-4.404896613999881, 43.396999416000043],
              [-4.385568813999953, 43.383612372000059],
              [-4.379261847999913, 43.383612372000059],
              [-4.368763800999943, 43.396185614000117],
              [-4.359364386999914, 43.399684963000126],
              [-4.312814907999893, 43.395778713000126],
              [-4.188588019999941, 43.405381578000018],
              [-4.160959438999924, 43.421169338000041],
              [-4.097727016999954, 43.435614325000117],
              [-4.07396399599989, 43.438259182000095],
              [-4.029204881999931, 43.438259182000095],
              [-4.023426886999886, 43.440944729000151],
              [-4.022572394999884, 43.445746161000059],
              [-4.020904100999871, 43.450384833],
              [-4.012766079999921, 43.45250071800011],
              [-4.005686001999891, 43.450791734000106],
              [-3.98143469999988, 43.438259182000095],
              [-3.976470506999931, 43.442938544000114],
              [-3.971994594999956, 43.44415924700003],
              [-3.967152472999885, 43.444199937000022],
              [-3.961008266999926, 43.445095119000101],
              [-3.965443488999881, 43.446844794000114],
              [-3.974598761999886, 43.45250071800011],
              [-3.974598761999886, 43.458766994000044],
              [-3.940052863999881, 43.477118231000091],
              [-3.922718878999888, 43.48261139500012],
              [-3.906320766999954, 43.479193427000112],
              [-3.893788214999887, 43.484890041000099],
              [-3.890858527999853, 43.483710028000175],
              [-3.885853644999912, 43.479193427000112],
              [-3.856800910999937, 43.492377020000063],
              [-3.826730923999889, 43.497015692],
              [-3.79702714799987, 43.492743231000091],
              [-3.769154425999915, 43.479193427000112],
              [-3.812652147999955, 43.46487051000004],
              [-3.830962693999908, 43.456000067],
              [-3.844309048999918, 43.445095119000101],
              [-3.833851691999939, 43.435899156000133],
              [-3.829253709999904, 43.433132229000179],
              [-3.823841925999943, 43.430772203000046],
              [-3.827259894999884, 43.430039781000104],
              [-3.829986131999931, 43.430121161000088],
              [-3.831532355999883, 43.429063218000024],
              [-3.831288214999944, 43.424587307000152],
              [-3.820220506999902, 43.424261786000116],
              [-3.811187303999901, 43.425482489000061],
              [-3.796498175999886, 43.430772203000046],
              [-3.789540167999888, 43.435858466000141],
              [-3.776193813999896, 43.449652411000059],
              [-3.772287563999896, 43.45250071800011],
              [-3.763498501999919, 43.450262762000122],
              [-3.754139777999882, 43.440497137000179],
              [-3.744984503999916, 43.438259182000095],
              [-3.740712042999888, 43.442328192000062],
              [-3.743031378999945, 43.451361395000063],
              [-3.748890753999916, 43.460720119000101],
              [-3.755523240999878, 43.465521552],
              [-3.755523240999878, 43.471747137000037],
              [-3.741525844999899, 43.470241604000094],
              [-3.735747850999928, 43.468817450000031],
              [-3.728871222999942, 43.465521552],
              [-3.727162238999938, 43.470526434000035],
              [-3.725819464999859, 43.470526434000035],
              [-3.721424933999856, 43.471747137000037],
              [-3.728871222999942, 43.479193427000112],
              [-3.717925584999875, 43.482733466000113],
              [-3.688384568999908, 43.484238999000141],
              [-3.676991339999859, 43.489447333000058],
              [-3.667062954999949, 43.496649481000176],
              [-3.656076626999891, 43.500677802000055],
              [-3.579416469999927, 43.518255927],
              [-3.554351365999935, 43.51691315300009],
              [-3.556263800999886, 43.499701239000089],
              [-3.539051886999857, 43.498968817000147],
              [-3.525624152999882, 43.491644598000121],
              [-3.514719204999892, 43.483221747000087],
              [-3.505360480999855, 43.479193427000112],
              [-3.436634894999884, 43.470485744000044],
              [-3.431507941999939, 43.466864325000174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5814",
        OBJECTID_1: 7263,
        diss_me: 5814,
        adm1_cod_1: "ESP-5814",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Castellón",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.VC",
        note: null,
        hasc_maybe: "ES.AR|ESP-VLN",
        region: "Valenciana",
        region_cod: "ES.VC",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "VC",
        area_sqkm: 0,
        sameascity: 6,
        labelrank: 6,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP89",
        fips_alt: "SP60|SP52",
        woe_id: 12602138,
        woe_label: "Valencia, ES, Spain",
        woe_name: "Comunidad Valenciana",
        latitude: 40.222000000000001,
        longitude: -0.097511399999999998,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3125881,
        gn_name: "Provincia de Castello",
        gns_id: -376966,
        gns_name: "Castellon de la Plana, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CS",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP60",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.048260260402685, 40.727178453522868],
            [0.001581659366082, 40.729633083799769],
            [0.01816979300159, 40.726144923849091],
            [0.024732699954143, 40.72020213542018],
            [0.028143344639659, 40.712683214059012],
            [0.027729932590347, 40.705836087165721],
            [0.029538607720554, 40.699609076097971],
            [0.03620486835996, 40.696689357827609],
            [0.045041538436266, 40.696172593890083],
            [0.056203646680984, 40.701701972068349],
            [0.064265171300661, 40.707696438240077],
            [0.073101841376854, 40.712709052480818],
            [0.081525099403848, 40.716429755528793],
            [0.093152297440582, 40.719504503430045],
            [0.107104932746466, 40.721545722557011],
            [0.14498375769432, 40.72257925133141],
            [0.173044060634936, 40.730899156570814],
            [0.198985630082774, 40.727178453522868],
            [0.219294467665549, 40.730537421364858],
            [0.223790317069501, 40.726067410382711],
            [0.226735873761555, 40.719426988164997],
            [0.232161899152345, 40.711675522807155],
            [0.243789097189079, 40.703045559205179],
            [0.265699904327136, 40.697877916232812],
            [0.27825727655204, 40.691573390799277],
            [0.287714064252697, 40.683821926340784],
            [0.28600874190991, 40.677310696231672],
            [0.281047803613291, 40.671419582847449],
            [0.277017043102063, 40.663642279967235],
            [0.276241895846795, 40.654314684375152],
            [0.283838331573719, 40.639716091224642],
            [0.293140089643487, 40.63214549391941],
            [0.316601190392646, 40.622585354330624],
            [0.387501254779039, 40.60374929509382],
            [0.418403760724942, 40.590830186763696],
            [0.429875929130787, 40.584396471020341],
            [0.436852248132737, 40.577445991339374],
            [0.436852248132737, 40.570753893177624],
            [0.440417922449171, 40.563054103763903],
            [0.447394239652454, 40.554579168893497],
            [0.456075880997133, 40.549204820346148],
            [0.518914421663226, 40.528947658707466],
            [0.523759592032405, 40.526163029399868],
            [0.517425977000073, 40.514349677000013],
            [0.501312696000099, 40.484198309000078],
            [0.48340905000012, 40.465033270000021],
            [0.466156446000127, 40.450669664000145],
            [0.452647332000055, 40.434068101000094],
            [0.446136915000068, 40.408433335000012],
            [0.420258009000065, 40.388251044000071],
            [0.350596550000063, 40.28489817900001],
            [0.338959181000064, 40.274359442000048],
            [0.312103712000095, 40.257513739000046],
            [0.298838738000143, 40.246771552000041],
            [0.264740431000035, 40.209784247000073],
            [0.205251498000109, 40.171535549000055],
            [0.144541863000057, 40.07884349200009],
            [0.11703535200013, 40.056341864000103],
            [0.06617272200009, 40.031927802000041],
            [0.04049726700012, 40.002875067000076],
            [-0.002919074999909, 39.93195221600007],
            [-0.028675910999937, 39.90180084800005],
            [-0.091297980999855, 39.847927151000135],
            [-0.194488085128398, 39.71544017121343],
            [-0.272036601008324, 39.751088934007157],
            [-0.309170391600304, 39.787387763434538],
            [-0.345392316402268, 39.797473213218211],
            [-0.379329085468925, 39.782938300162414],
            [-0.409585433920654, 39.745321111380179],
            [-0.432667710546383, 39.729533757476958],
            [-0.45594774089551, 39.722919987567366],
            [-0.478195056356753, 39.728083562006759],
            [-0.533104726002307, 39.796187812217738],
            [-0.56998583138099, 39.78019171847302],
            [-0.586102775122981, 39.760690984701171],
            [-0.601395744717848, 39.75080328954013],
            [-0.618885979304537, 39.74591437344958],
            [-0.634475579484359, 39.748463201415603],
            [-0.646637445161929, 39.757900457640005],
            [-0.6474284609547, 39.766359930466422],
            [-0.644297357037402, 39.776643133973437],
            [-0.644703851501532, 39.791595527611548],
            [-0.656030756014644, 39.835859444657643],
            [-0.691033199222773, 39.852327951373837],
            [-0.712654293360941, 39.81698493220884],
            [-0.737010983969697, 39.820753243567367],
            [-0.773749266215589, 39.869818186859789],
            [-0.791289842152452, 39.877540391875584],
            [-0.797465176376789, 39.887488105091961],
            [-0.809195726301652, 39.897642523883377],
            [-0.81712805791355, 39.901337389409008],
            [-0.8238201560753, 39.90632416432868],
            [-0.83250179741998, 39.919424139812151],
            [-0.836196662046319, 39.933376776916703],
            [-0.83635169077786, 39.939939683869341],
            [-0.839090541894961, 39.945830797253478],
            [-0.838702969166661, 39.952574571359378],
            [-0.835654058787725, 39.967638250704681],
            [-0.831209886227299, 39.974821275281613],
            [-0.824440272800302, 39.981487535021728],
            [-0.801418423421524, 39.990892645878887],
            [-0.790773078214954, 39.992959703427573],
            [-0.77780229304139, 39.999574286324275],
            [-0.775089281245357, 40.00138296235383],
            [-0.766175095903918, 40.009392809230803],
            [-0.762040980806574, 40.018487860826127],
            [-0.757700161033597, 40.034610908266728],
            [-0.753901942720489, 40.039778551239095],
            [-0.746693080621128, 40.041432196738498],
            [-0.738786587431122, 40.040295315176706],
            [-0.722043423265433, 40.039881904026686],
            [-0.683854539055744, 40.044429430273993],
            [-0.614349737750217, 40.071740424381119],
            [-0.612747769094284, 40.077709052131013],
            [-0.617734544014041, 40.091454982761206],
            [-0.619517380722527, 40.099128932853915],
            [-0.617657029648171, 40.107087103786824],
            [-0.613419561763322, 40.1139083931578],
            [-0.596728075340422, 40.127008367742008],
            [-0.58318884938592, 40.134811509943262],
            [-0.574868944146516, 40.145146795887939],
            [-0.570037197058923, 40.153518377970698],
            [-0.567815110778696, 40.169072983731184],
            [-0.563345098897173, 40.176333523573248],
            [-0.5563687807946, 40.185170192750178],
            [-0.552286343440016, 40.200285548938922],
            [-0.553035651374216, 40.207365220728349],
            [-0.551640387393945, 40.214186510099381],
            [-0.551821255446555, 40.228449205566363],
            [-0.549960902573559, 40.235063788463066],
            [-0.544224818820339, 40.240102241125527],
            [-0.534380459290759, 40.240412299487986],
            [-0.525362922061191, 40.237699285893314],
            [-0.516267868667228, 40.233358466120364],
            [-0.501126674956055, 40.230309557539982],
            [-0.495493943990311, 40.230464586271637],
            [-0.487794154576591, 40.231498115045923],
            [-0.47947425023645, 40.233771878169563],
            [-0.470250007431844, 40.235063788463066],
            [-0.451620652870815, 40.235916450084119],
            [-0.442318894801161, 40.237750962736754],
            [-0.4058611722553, 40.25113515906034],
            [-0.397928839744196, 40.25687124281356],
            [-0.391417608735594, 40.263925076181295],
            [-0.39009986002057, 40.274208686181908],
            [-0.390745815167179, 40.28214101779372],
            [-0.392812872715865, 40.28914317521739],
            [-0.390926683219789, 40.295576890960746],
            [-0.369377611287689, 40.308289292816653],
            [-0.357156134048324, 40.317746080517225],
            [-0.341213954660248, 40.337383124531556],
            [-0.331653815071462, 40.345651353826923],
            [-0.2920955062026, 40.365520941838014],
            [-0.286488612759314, 40.371747952006302],
            [-0.285584276093402, 40.381540636491138],
            [-0.289098272667104, 40.389214586583847],
            [-0.295092738838832, 40.39632009499644],
            [-0.307236700813064, 40.407921453712078],
            [-0.314057990184097, 40.412959907273915],
            [-0.329354214425393, 40.421693224562631],
            [-0.335477870907027, 40.427765204200853],
            [-0.338655971595756, 40.435309963983627],
            [-0.339896205945109, 40.44269969323625],
            [-0.333850062930082, 40.450011909022351],
            [-0.323695645038015, 40.456962388703232],
            [-0.277367722742184, 40.471819363372958],
            [-0.272174242247445, 40.475720934473543],
            [-0.27263932934153, 40.48143117890578],
            [-0.275817430030372, 40.487528997864956],
            [-0.280623337796783, 40.499853826992478],
            [-0.281863573045428, 40.500939033509553],
            [-0.296694709293433, 40.507915350712864],
            [-0.29793494274341, 40.513703111309525],
            [-0.297469854750062, 40.52036937104964],
            [-0.294524298057894, 40.52706146921139],
            [-0.292974006245373, 40.534063625735683],
            [-0.292663946983538, 40.548507188356012],
            [-0.294162562852023, 40.555561020824456],
            [-0.296488002819103, 40.562433987038844],
            [-0.298555060367676, 40.576645006561762],
            [-0.297340664440128, 40.584138089501209],
            [-0.294782681375665, 40.592199612322247],
            [-0.294369269326353, 40.605764674899802],
            [-0.300415412341408, 40.609046129275413],
            [-0.308089362434117, 40.608710232491234],
            [-0.325116745641253, 40.604240221509002],
            [-0.333953415717559, 40.605273749384025],
            [-0.34268673300636, 40.607340806932683],
            [-0.349998948792489, 40.611061509980658],
            [-0.358758103603634, 40.61194000912414],
            [-0.367207200951526, 40.614472153766911],
            [-0.374596930204149, 40.618192856814886],
            [-0.377464971631071, 40.624264838251634],
            [-0.376302253446227, 40.638346666565354],
            [-0.376586473386965, 40.645684718974536],
            [-0.3787052068798, 40.652609361133159],
            [-0.377309942899529, 40.660154120016585],
            [-0.368679979297553, 40.667440497381079],
            [-0.357750413250955, 40.671910509262517],
            [-0.345813157750968, 40.673900051546156],
            [-0.329405890369543, 40.671574612478338],
            [-0.313050298932154, 40.662944647977099],
            [-0.304497849695963, 40.663254706339643],
            [-0.293077358133559, 40.667879746952735],
            [-0.284809128838219, 40.673331610765217],
            [-0.234088710825347, 40.694803168331532],
            [-0.229231126215495, 40.701030178499934],
            [-0.228869391009539, 40.708006497501884],
            [-0.229334479003001, 40.715499579541984],
            [-0.228016730287862, 40.730718289417496],
            [-0.223417528096434, 40.745316880769451],
            [-0.219025030580667, 40.751931464565416],
            [-0.213676519555747, 40.758287665043639],
            [-0.194814621897308, 40.775986842718567],
            [-0.18388505675, 40.779423325825803],
            [-0.165798306346744, 40.779914252240985],
            [-0.152155727604679, 40.774901638000216],
            [-0.14236304401922, 40.76939809824367],
            [-0.135309210651485, 40.763377794549626],
            [-0.119961309566804, 40.753275050802984],
            [-0.074486049791375, 40.730847479727402],
            [-0.048260260402685, 40.727178453522868],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5815",
        OBJECTID_1: 1306,
        diss_me: 5815,
        adm1_cod_1: "ESP-5815",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 5,
        name: "Ceuta",
        name_alt: null,
        name_local: null,
        type: "Ciudades Autónomas",
        type_en: "Autonomous City",
        code_local: null,
        code_hasc: "ES.CE",
        note: null,
        hasc_maybe: null,
        region: "Ceuta",
        region_cod: "ES.CE",
        provnum_ne: 23,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CE",
        area_sqkm: 0,
        sameascity: 9,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP00",
        fips_alt: "SP|",
        woe_id: 55862983,
        woe_label: "Ceuta Province, ES, Spain",
        woe_name: "Ceuta",
        latitude: 35.880299999999998,
        longitude: -5.3410000000000002,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 5,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 8335119,
        gn_name: "Ceuta",
        gns_id: -99,
        gns_name: null,
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CE",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.340725500098017, 35.847365679033942],
            [-5.362897908999884, 35.863806865000129],
            [-5.378400837999891, 35.881686910000099],
            [-5.389717976999862, 35.902047425000035],
            [-5.398858678935312, 35.924503852782863],
            [-5.398833787999877, 35.924505927000013],
            [-5.385161912999934, 35.926174221000025],
            [-5.366200324999909, 35.924750067000048],
            [-5.344105597999942, 35.91474030200007],
            [-5.327381964999915, 35.904242255000099],
            [-5.309315558999884, 35.900824286000073],
            [-5.283070441999968, 35.911688544000071],
            [-5.291859503999916, 35.890529690000079],
            [-5.335560675999915, 35.862372137000079],
            [-5.340725500098017, 35.847365679033942],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5816",
        OBJECTID_1: 393,
        diss_me: 5816,
        adm1_cod_1: "ESP-5816",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Ciudad Real",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CM",
        note: null,
        hasc_maybe: null,
        region: "Castilla-La Mancha",
        region_cod: "ES.CM",
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 11,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP84",
        fips_alt: "SP54",
        woe_id: 12602111,
        woe_label: "Castille la Mancha, ES, Spain",
        woe_name: "Castilla-La Mancha",
        latitude: 38.893599999999999,
        longitude: -3.7472699999999999,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2519401,
        gn_name: "Provincia de Ciudad Real",
        gns_id: -378245,
        gns_name: "Ciudad Real, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CR",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP54",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.855382859831138, 39.378242703138213],
              [-4.880135870873715, 39.366873888419164],
              [-4.944369676419456, 39.39191111940265],
              [-4.898544345645007, 39.517014218331568],
              [-4.873737216099585, 39.551181699475862],
              [-4.862695956053528, 39.55603765721203],
              [-4.774915190583556, 39.519420224064092],
              [-4.754030179848883, 39.502182674690786],
              [-4.745867337607535, 39.491196346134373],
              [-4.742099027148384, 39.477705134084616],
              [-4.766148100154652, 39.434353081929217],
              [-4.752960170975086, 39.418162747212946],
              [-4.767016159967739, 39.404649360579626],
              [-4.777919887592731, 39.400592759848038],
              [-4.813679979047748, 39.392376207396083],
              [-4.836365933441016, 39.383849596581527],
              [-4.842618781131819, 39.380542303784395],
              [-4.850060187227854, 39.378733629553309],
              [-4.855382859831138, 39.378242703138213],
            ],
          ],
          [
            [
              [-4.158724982072272, 39.5767688586959],
              [-4.124414677795102, 39.563728087381335],
              [-4.041720583038511, 39.575395567851203],
              [-4.039512331029243, 39.567002012632869],
              [-4.038545533749414, 39.546787168592928],
              [-4.041522828415793, 39.538887998582211],
              [-4.052179567133294, 39.531604061977944],
              [-4.065132448603549, 39.527692929285209],
              [-4.07545959748245, 39.522089902027304],
              [-4.078294070814678, 39.509675350237174],
              [-4.075075076153809, 39.503435115538153],
              [-4.063154909571381, 39.491174372998969],
              [-4.059826051930742, 39.483824519685882],
              [-4.060573122351713, 39.473552302296937],
              [-4.065110476367352, 39.465664118404376],
              [-4.079810183892874, 39.451854303533352],
              [-4.118251347721525, 39.456995904837171],
              [-4.127842412297468, 39.408952690314393],
              [-4.14404724678306, 39.377224173257062],
              [-4.145794072838243, 39.367940726283521],
              [-4.140322881695596, 39.358964896013205],
              [-4.115933231833196, 39.344309132960078],
              [-4.106177371888236, 39.342309621691712],
              [-4.097673953689963, 39.345407766355279],
              [-4.073284304726826, 39.365369925181838],
              [-3.972056273478188, 39.352318166849855],
              [-3.966914671274992, 39.298452198210455],
              [-3.902380977799851, 39.280269823793347],
              [-3.875958857415327, 39.2620874502757],
              [-3.841648553138157, 39.259670458424935],
              [-3.814259636373123, 39.284543506477704],
              [-3.798626090821386, 39.291992236652405],
              [-3.780377798796366, 39.294376270148817],
              [-3.735828237283272, 39.269613085975109],
              [-3.722205190017519, 39.269854785070336],
              [-3.67898497307803, 39.295013476690556],
              [-3.586677840609866, 39.307713672947713],
              [-3.556135847691223, 39.299144336242065],
              [-3.476858500419468, 39.343067678230753],
              [-3.470343607371575, 39.372247366422798],
              [-3.39875669116833, 39.405008598368212],
              [-3.342616552012203, 39.404668022411343],
              [-3.326719334229864, 39.410227104297363],
              [-3.318106053051878, 39.423981987678545],
              [-3.305471775302209, 39.462489069115193],
              [-3.294155856907253, 39.470728815982127],
              [-3.185171477881426, 39.474156550484409],
              [-3.184490325068424, 39.487889460730017],
              [-3.14330257955038, 39.495426082547581],
              [-3.069518397456022, 39.450129449714368],
              [-3.022496910803767, 39.456995904837171],
              [-2.977903403019582, 39.444647271554302],
              [-2.931222493223515, 39.471409967895752],
              [-2.907217365588963, 39.430222222377736],
              [-2.86980891664814, 39.390429740839906],
              [-2.896560626871434, 39.36674321602662],
              [-2.882498125887764, 39.35096684824147],
              [-2.830675614787793, 39.355075735556753],
              [-2.789147292413645, 39.397636771919466],
              [-2.742125806660624, 39.318018848690841],
              [-2.722229565442063, 39.268613329441635],
              [-2.842683672113708, 39.134426312524326],
              [-2.809801590171247, 39.084504435831107],
              [-2.806033279712096, 39.068244669855588],
              [-2.807439529810438, 39.053808633661603],
              [-2.879795489570171, 38.945505407448664],
              [-2.882124591576627, 38.93126712497785],
              [-2.870610918559066, 38.912128941197622],
              [-2.847759355809615, 38.905515171288087],
              [-2.799452469056234, 38.907932163138852],
              [-2.76238459607228, 38.880806918604421],
              [-2.749211988642287, 38.81125247202398],
              [-2.731765698528193, 38.790103789957939],
              [-2.681755931091317, 38.777063017744027],
              [-2.650686593711328, 38.742170438415044],
              [-2.643842111724098, 38.72609744004474],
              [-2.645292306294834, 38.707409695201321],
              [-2.668682200523136, 38.649237085523012],
              [-2.679327952223218, 38.635504174378141],
              [-2.728491772377396, 38.624594750447429],
              [-2.742202710386778, 38.615893578525871],
              [-2.750969800815596, 38.605533470393326],
              [-2.765482740735933, 38.573673117220565],
              [-2.746937818125843, 38.546921407896619],
              [-2.756027798444165, 38.533126329316715],
              [-2.767680833103952, 38.524212144874653],
              [-2.770083787436818, 38.520517280248313],
              [-2.790857713012997, 38.507055569558943],
              [-2.806903245188579, 38.500492661706929],
              [-2.807368333182012, 38.499924220926076],
              [-2.811269904282625, 38.497547105014959],
              [-2.816592575986533, 38.495841783571521],
              [-2.844885422923909, 38.483439439178781],
              [-2.85090572841662, 38.479951280127509],
              [-2.859639044806045, 38.473465888440089],
              [-2.861602748667877, 38.470907905375512],
              [-2.86335974785402, 38.469047553401893],
              [-2.865168422984311, 38.46783315747426],
              [-2.870646125218514, 38.465714423082218],
              [-2.873333298592826, 38.464034939161039],
              [-2.875503708928989, 38.461942044090037],
              [-2.878991867980289, 38.460598456054001],
              [-2.883849452590198, 38.461554470462531],
              [-2.895037401055191, 38.466902981487451],
              [-2.902737188670358, 38.4719155948289],
              [-2.913124152357739, 38.474654445945831],
              [-2.923459438302416, 38.47633392986701],
              [-2.938135544919419, 38.475946357138625],
              [-2.950796270831262, 38.474473577893306],
              [-2.966557583065992, 38.468608302930775],
              [-2.986814743805382, 38.457213649790177],
              [-2.992731695611241, 38.451968492452025],
              [-2.995134649944077, 38.43853262018429],
              [-2.997072516283566, 38.433054917950088],
              [-3.000870733697411, 38.430057685313969],
              [-3.009371506989453, 38.434450181930359],
              [-3.023194952884694, 38.446335761486196],
              [-3.046345994372103, 38.472923285181494],
              [-3.052960578168097, 38.478969428196635],
              [-3.060608689839199, 38.48261261687874],
              [-3.069703742333786, 38.482715968766954],
              [-3.098487514786967, 38.468582465408431],
              [-3.11352535571055, 38.457549547473647],
              [-3.123266364251322, 38.453803006003952],
              [-3.137348191665694, 38.452795314751896],
              [-3.170834519997072, 38.460960191259872],
              [-3.240287645358535, 38.463724880798608],
              [-3.259562954166967, 38.466747951856533],
              [-3.277933926309686, 38.471657213309797],
              [-3.295710618350313, 38.478685208255726],
              [-3.307415329853541, 38.481889146466884],
              [-3.3228149068824, 38.482405911303672],
              [-3.345785081216547, 38.479796251395882],
              [-3.361882290235542, 38.473259181965759],
              [-3.369556240328166, 38.466231187019659],
              [-3.373612840160405, 38.458893133711186],
              [-3.375628220865735, 38.451761785977695],
              [-3.376661749640022, 38.444759630352692],
              [-3.381390143040676, 38.438635972971881],
              [-3.395937059347773, 38.426440335053385],
              [-3.41906226241349, 38.415045681013439],
              [-3.44521053743631, 38.406493231777162],
              [-3.457328660089559, 38.404839586277873],
              [-3.469575974851296, 38.404477851071888],
              [-3.48606075659859, 38.406441554933807],
              [-3.519081996936592, 38.413340359569915],
              [-3.526368374301001, 38.417138576983817],
              [-3.530399135711519, 38.423391425573826],
              [-3.5331121484069, 38.430264390888922],
              [-3.534559089230584, 38.437395737723151],
              [-3.538073085804228, 38.443648587212621],
              [-3.543835007979169, 38.448816230184903],
              [-3.553601854042256, 38.450159817321762],
              [-3.566391771163296, 38.449126288547362],
              [-3.576752896428985, 38.443493557581533],
              [-3.580602789786951, 38.435897121854779],
              [-3.584866095194144, 38.413650417932544],
              [-3.588741827873037, 38.407681790182536],
              [-3.596028205237417, 38.403986925556111],
              [-3.60706112317223, 38.401687324010723],
              [-3.61985104029327, 38.401428940693151],
              [-3.671966722286385, 38.411557521962138],
              [-3.692172207081654, 38.413469549879821],
              [-3.717467821382826, 38.42209951438106],
              [-3.725581021047276, 38.422797146371281],
              [-3.732660691937326, 38.420239163306704],
              [-3.740980597176758, 38.418146267336411],
              [-3.757801275708289, 38.422797146371281],
              [-3.76705135693453, 38.426543686941599],
              [-3.778368495709543, 38.427344671719212],
              [-3.794155646365965, 38.426078599847514],
              [-3.807539841790287, 38.423107204733739],
              [-3.823094447550687, 38.415200711543619],
              [-3.829683192924961, 38.407785142970013],
              [-3.839682582984835, 38.392618109937857],
              [-3.859939743724254, 38.385951850197799],
              [-3.893736131317439, 38.381611030424764],
              [-4.177594773979592, 38.390576890810891],
              [-4.21552527487151, 38.401222236017375],
              [-4.235110642941777, 38.403392646353623],
              [-4.256065435671246, 38.402772528729272],
              [-4.264979621012685, 38.401428940693151],
              [-4.271800910383718, 38.39478852027402],
              [-4.274849818963901, 38.387993069324764],
              [-4.275495775009972, 38.350915229154523],
              [-4.290042690417863, 38.348874010027558],
              [-4.301799078764446, 38.350114244376826],
              [-4.366317105150301, 38.371172389893857],
              [-4.379752977417951, 38.372593492295934],
              [-4.405694545966526, 38.379569811297799],
              [-4.449748704239255, 38.395951240257503],
              [-4.460058152661588, 38.40091217675554],
              [-4.46951494036216, 38.409542141256892],
              [-4.488893601958182, 38.431969713231652],
              [-4.517057256786984, 38.455508328346653],
              [-4.543050503078263, 38.472923285181494],
              [-4.558269212054427, 38.478814399465008],
              [-4.570439011551173, 38.486746731076735],
              [-4.584184943080658, 38.490002346131448],
              [-4.593331672418685, 38.490002346131448],
              [-4.602530076801571, 38.492586168516894],
              [-4.610488246835104, 38.496978665133284],
              [-4.618549770555518, 38.505970363941131],
              [-4.623820767214568, 38.514341946024061],
              [-4.632734950757339, 38.523540351306238],
              [-4.668004115797288, 38.545089423238338],
              [-4.685238206378074, 38.550437934263257],
              [-4.703970912827373, 38.559352117805943],
              [-4.706993983885212, 38.565398260820999],
              [-4.714926317295692, 38.572581285398016],
              [-4.72797461593575, 38.579764309075571],
              [-4.768979864728948, 38.594388739748595],
              [-4.78158891379735, 38.597334296440678],
              [-4.801251797132693, 38.597747708489877],
              [-4.813938360566851, 38.59976308919515],
              [-4.828485276874034, 38.604052232124857],
              [-4.849285040871962, 38.614852606962259],
              [-4.861093105162666, 38.625808010531287],
              [-4.865899013828368, 38.635006414914088],
              [-4.867371792174481, 38.650793565570623],
              [-4.867371792174481, 38.658467516562624],
              [-4.869723069663905, 38.6738670917929],
              [-4.87349544865603, 38.680688381163961],
              [-4.883029750722358, 38.685235908310531],
              [-4.912821214427481, 38.684925849048724],
              [-4.924241705989971, 38.683323880392763],
              [-4.94597164597468, 38.683737291542698],
              [-4.979199590988316, 38.694382635850005],
              [-5.036586269640566, 38.728411567439977],
              [-5.021445075030215, 38.734225165558954],
              [-5.00974036262761, 38.73536204712083],
              [-5.002815721368449, 38.735103664702436],
              [-4.997854783971036, 38.735827135114206],
              [-4.990775112181609, 38.739315294165479],
              [-4.981370002223855, 38.749598904166092],
              [-4.971654832104804, 38.769158433814667],
              [-4.947521937787229, 38.839360867110415],
              [-4.942871059651623, 38.848869329855674],
              [-4.927342292312943, 38.87031504989956],
              [-4.919745855686642, 38.877162176792936],
              [-4.912898728793351, 38.882278143821353],
              [-4.904682176341396, 38.885998846869327],
              [-4.895793830320969, 38.888117581261341],
              [-4.886337042620397, 38.889099433192214],
              [-4.858586798941616, 38.886153876500245],
              [-4.850757819217989, 38.886670641337034],
              [-4.845719366555528, 38.892044989884468],
              [-4.841404385204186, 38.914240016963177],
              [-4.836314255698397, 38.92852855085188],
              [-4.840060798067356, 38.939638984051911],
              [-4.846649543441629, 38.945969347007704],
              [-4.873211228715206, 38.948940742121479],
              [-4.879774135667844, 38.952248033120227],
              [-4.913828904780075, 38.959689439216177],
              [-4.920624354830096, 38.96413361357547],
              [-4.925016852345863, 38.970179754791857],
              [-4.928039924302993, 38.977466132156266],
              [-4.929590216115514, 38.984907538252386],
              [-4.929796921690581, 38.991728826723957],
              [-4.930933804151721, 38.999351100872516],
              [-4.931269700935985, 39.000126248127899],
              [-4.934447800725366, 39.001521511208793],
              [-4.939512090910256, 39.00462209753185],
              [-4.944317999575958, 39.00966054929485],
              [-4.946385057124729, 39.016791897028341],
              [-4.947599453052277, 39.024388332755379],
              [-4.951165127368711, 39.031700548541394],
              [-4.953593919223977, 39.038961087484168],
              [-4.954136521583195, 39.04557567038087],
              [-4.946385057124729, 39.050975857349854],
              [-4.937806768567441, 39.053068753320346],
              [-4.92858252576292, 39.051518459709158],
              [-4.919926723739252, 39.049141343798041],
              [-4.895328742327592, 39.037669176291402],
              [-4.877293666969138, 39.03260488520722],
              [-4.867604336171155, 39.03363841398162],
              [-4.859000210091551, 39.036506456308004],
              [-4.83471228704235, 39.047591051086144],
              [-4.83104325993844, 39.054257310826259],
              [-4.833627082323915, 39.062887275327412],
              [-4.840241665220617, 39.068623359080831],
              [-4.857475754902168, 39.076219793908407],
              [-4.863986985910685, 39.081619980877363],
              [-4.867475144961958, 39.091981106143223],
              [-4.867397629696825, 39.106140448822615],
              [-4.8555637269844, 39.147843328706657],
              [-4.85111955442386, 39.158979601227671],
              [-4.847037116169929, 39.165490831336811],
              [-4.839724901283091, 39.170270901580878],
              [-4.825772264178653, 39.182027289028255],
              [-4.819648606797756, 39.18871938718992],
              [-4.813395758207633, 39.19445547094314],
              [-4.806781175311016, 39.198796292514842],
              [-4.798642137224874, 39.199106349978095],
              [-4.781097988281573, 39.195127265410932],
              [-4.771925422320379, 39.194817206149125],
              [-4.762623664250725, 39.195437323773476],
              [-4.744769456045503, 39.198822130037186],
              [-4.727328659889679, 39.196496690070106],
              [-4.719034593071996, 39.193163561549184],
              [-4.711179774926649, 39.18902944645184],
              [-4.676117316361029, 39.175619411706421],
              [-4.671414761382096, 39.174456691722938],
              [-4.658392300264381, 39.17393992778544],
              [-4.670432908551874, 39.19099315031356],
              [-4.69911332821755, 39.218123277267452],
              [-4.710611335045087, 39.225306300945007],
              [-4.715675625229892, 39.23207591437189],
              [-4.71720008041936, 39.236054998939053],
              [-4.734615037254173, 39.26096304051201],
              [-4.75355445017766, 39.297394923736803],
              [-4.756189947607993, 39.306154080346587],
              [-4.756758389288223, 39.316515203813609],
              [-4.749394496658681, 39.324576728433286],
              [-4.740661180269228, 39.327935696275389],
              [-4.730274216581847, 39.329537664931323],
              [-4.720352341787105, 39.334834500012207],
              [-4.710947230929889, 39.344136257182484],
              [-4.696064418737905, 39.378371894347495],
              [-4.681362473699181, 39.400127671854491],
              [-4.67787431464788, 39.417594306432122],
              [-4.692731289317521, 39.449814561992426],
              [-4.618239159109862, 39.454260309265933],
              [-4.613328270783029, 39.469992731679284],
              [-4.60024355319743, 39.482890681659697],
              [-4.583555320521583, 39.492273006394086],
              [-4.567822897208941, 39.497469539187833],
              [-4.562351706066408, 39.522540340963943],
              [-4.553837301750065, 39.539426328262508],
              [-4.538423482158009, 39.548105527947698],
              [-4.512243061767833, 39.54853399464811],
              [-4.499026508066862, 39.502742977506486],
              [-4.487798480415563, 39.492514705489313],
              [-4.47606508143835, 39.486164607810409],
              [-4.463793352781011, 39.484736384576522],
              [-4.450928362054242, 39.489317683964018],
              [-4.447138078459517, 39.493107967558629],
              [-4.441018693757712, 39.502929745111672],
              [-4.435723283202947, 39.506829891686166],
              [-4.426780412186275, 39.508807430718363],
              [-4.24213318799616, 39.47793584706163],
              [-4.192705695611352, 39.498172664237032],
              [-4.158724982072272, 39.5767688586959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5817",
        OBJECTID_1: 392,
        diss_me: 5817,
        adm1_cod_1: "ESP-5817",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Córdoba",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602098,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 37.969900000000003,
        longitude: -4.7478800000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2519239,
        gn_name: "Province of Cordoba",
        gns_id: -378767,
        gns_name: "Cordoba, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CO",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.924241705989971, 38.68332388039272],
            [-4.912821214427481, 38.684925849048653],
            [-4.883029750722358, 38.685235908310574],
            [-4.87349544865603, 38.68068838116389],
            [-4.869723069663905, 38.673867091792857],
            [-4.867371792174481, 38.658467516562666],
            [-4.867371792174481, 38.650793565570581],
            [-4.865899013828368, 38.635006414914045],
            [-4.861093105162666, 38.625808010531244],
            [-4.849285040871962, 38.614852606962302],
            [-4.828485276874034, 38.604052232124815],
            [-4.81393836056688, 38.599763089195164],
            [-4.801251797132693, 38.597747708489919],
            [-4.78158891379735, 38.597334296440607],
            [-4.768979864728948, 38.594388739748553],
            [-4.727974615935779, 38.579764309075586],
            [-4.714926317295692, 38.572581285397973],
            [-4.706993983885241, 38.565398260821041],
            [-4.703970912827401, 38.559352117805986],
            [-4.685238206378074, 38.5504379342633],
            [-4.668004115797316, 38.545089423238295],
            [-4.632734950757339, 38.523540351306195],
            [-4.623820767214568, 38.514341946024018],
            [-4.618549770555518, 38.505970363941174],
            [-4.610488246835104, 38.496978665133241],
            [-4.602530076801571, 38.492586168516851],
            [-4.593331672418685, 38.490002346131376],
            [-4.584184943080658, 38.490002346131376],
            [-4.570439011551173, 38.486746731076778],
            [-4.558269212054427, 38.478814399464937],
            [-4.543050503078291, 38.472923285181452],
            [-4.517057256786984, 38.45550832834661],
            [-4.488893601958182, 38.431969713231695],
            [-4.46951494036216, 38.409542141256821],
            [-4.460058152661588, 38.400912176755497],
            [-4.449748704239255, 38.39595124025746],
            [-4.405694545966526, 38.379569811297728],
            [-4.379752977417951, 38.372593492295863],
            [-4.366317105150301, 38.3711723898939],
            [-4.301799078764446, 38.350114244376869],
            [-4.290042690417891, 38.348874010027515],
            [-4.275495775009972, 38.350915229154481],
            [-4.243528451976516, 38.339301770959906],
            [-4.241199349970032, 38.337576917141007],
            [-4.214557503625883, 38.289270030387542],
            [-4.215205696285892, 38.256563730831516],
            [-4.209998177373961, 38.234997567283898],
            [-4.198396613612829, 38.214760750108496],
            [-4.168008429045727, 38.180758063433899],
            [-4.168349005002597, 38.136834722344588],
            [-4.193046271568136, 38.127562260589883],
            [-4.25030701635572, 38.061105959572302],
            [-4.254591684258884, 38.052185060791913],
            [-4.259337778115992, 38.025609132055763],
            [-4.263754282134414, 38.019138184379756],
            [-4.284320689148444, 38.003691406433234],
            [-4.260996714326893, 37.96353637580313],
            [-4.283837290958189, 37.820483391595246],
            [-4.279168101726469, 37.808530265759202],
            [-4.268840952847683, 37.798994132673073],
            [-4.24393494554127, 37.787216788324059],
            [-4.23071839273959, 37.774560538338164],
            [-4.228224496263493, 37.733251942822932],
            [-4.219808967909501, 37.716069324939383],
            [-4.186223761817217, 37.70572020382437],
            [-4.171985480245667, 37.697150867118808],
            [-4.171249395942823, 37.679364001047873],
            [-4.178379523296343, 37.668641344722261],
            [-4.209009406958558, 37.640791002003141],
            [-4.213096322037643, 37.626267075964847],
            [-4.205241096499407, 37.619642319037808],
            [-4.182246710617193, 37.612545150937933],
            [-4.156000371719841, 37.577553694747138],
            [-4.11013245085195, 37.53781614469932],
            [-4.094597782161884, 37.498386211354529],
            [-4.086610721407681, 37.486542949397631],
            [-4.037501832743345, 37.456341532435829],
            [-4.000873413477251, 37.4021130147039],
            [-4.076382449390508, 37.403519264802213],
            [-4.093883670994614, 37.396147437454289],
            [-4.137169804642724, 37.357574438409642],
            [-4.154725957736787, 37.348895238724282],
            [-4.172117316361096, 37.346686986715042],
            [-4.209174202327546, 37.352355932480293],
            [-4.217216194571677, 37.350400365684351],
            [-4.225060433092466, 37.343544896679589],
            [-4.235508431968583, 37.327208226078767],
            [-4.237123421908393, 37.296424534064982],
            [-4.263402720059503, 37.260389375968913],
            [-4.255162973192625, 37.229495820075982],
            [-4.320300915754643, 37.186572234620911],
            [-4.326266492104907, 37.20271115149842],
            [-4.372200331480144, 37.215510223717814],
            [-4.383505263756916, 37.277550021616264],
            [-4.444940812568433, 37.277550021616264],
            [-4.480624407690271, 37.238757294813325],
            [-4.539313374812423, 37.211313446558336],
            [-4.596266501997519, 37.221607636183421],
            [-4.613778709719668, 37.255236787647675],
            [-4.653230615300657, 37.255928926578719],
            [-4.658075586918784, 37.277407199382765],
            [-4.670654933178639, 37.29906125277455],
            [-4.677246729952572, 37.340765355736579],
            [-4.691946437478094, 37.354575170607603],
            [-4.710579250831756, 37.361606421099509],
            [-4.763445463837058, 37.328823216917868],
            [-4.783836089364058, 37.330449192975834],
            [-4.822068512451835, 37.358189672715184],
            [-4.849863923681113, 37.410693336628242],
            [-4.884877353007397, 37.438148171900565],
            [-4.887525057835148, 37.462592753252892],
            [-4.908267246336322, 37.494771709246947],
            [-4.943884923850192, 37.520183086979841],
            [-4.936183507562816, 37.537827130817362],
            [-4.928020665321469, 37.583684066466475],
            [-4.932283360988322, 37.602492660407904],
            [-4.937337071548569, 37.607941879314239],
            [-4.950213049292756, 37.613995346408132],
            [-4.955222814481317, 37.618367905054754],
            [-4.957958410951932, 37.626365952826461],
            [-4.954937170014318, 37.640549302907999],
            [-4.955541418201875, 37.648020006218275],
            [-4.966538732876273, 37.670882555985145],
            [-4.982073401566254, 37.690163561998787],
            [-5.002112465018257, 37.703995350005385],
            [-5.02664493621478, 37.710510243053278],
            [-5.052429850057536, 37.708280017908464],
            [-5.10502140471408, 37.690932605555275],
            [-5.123137860623729, 37.669432360515032],
            [-5.129422039795344, 37.665554186176735],
            [-5.152548261792816, 37.661917711832956],
            [-5.179453780367709, 37.664016100862341],
            [-5.191055343229465, 37.669586168866601],
            [-5.276770678923697, 37.624487290655935],
            [-5.344369557808875, 37.609985336853839],
            [-5.380108085319847, 37.611765122162666],
            [-5.401004082172705, 37.631035142058181],
            [-5.414022881250929, 37.673903796023382],
            [-5.390028739734532, 37.697579333819249],
            [-5.31108098230149, 37.697919910675296],
            [-5.309707691456794, 37.773428946588609],
            [-5.334690602489331, 37.786129141946304],
            [-5.341205495537224, 37.792292472919257],
            [-5.346522878328187, 37.805278312743866],
            [-5.349632009109911, 37.830590813615146],
            [-5.360112967239644, 37.841796869030304],
            [-5.393236747377955, 37.857606196069071],
            [-5.404508720401111, 37.866823725434614],
            [-5.413374687691658, 37.88508300357779],
            [-5.415088555392487, 37.927424313980936],
            [-5.421834160518074, 37.940684812154416],
            [-5.478589533979743, 37.981290282620421],
            [-5.496068782448276, 38.042835695311084],
            [-5.58182806351428, 38.115554203263883],
            [-5.581049159790894, 38.135088609451799],
            [-5.538209398345089, 38.162373766036524],
            [-5.533403489679301, 38.167644761796282],
            [-5.533610195254255, 38.17353587518042],
            [-5.533248460048384, 38.180667222913996],
            [-5.529863653784588, 38.19482656469421],
            [-5.526840582726749, 38.20190623648358],
            [-5.519709234993257, 38.20888255548553],
            [-5.517306280660307, 38.222886868534204],
            [-5.51720292787283, 38.230121568155795],
            [-5.517823044597833, 38.237614651095271],
            [-5.51908911826817, 38.244849352515615],
            [-5.521259527705041, 38.252187404924769],
            [-5.521957159695091, 38.259447943867457],
            [-5.521337042970202, 38.266760158754295],
            [-5.52293901162605, 38.270739244220749],
            [-5.532447476170063, 38.284743557269337],
            [-5.5352380032312, 38.290092068294342],
            [-5.538416103920042, 38.295078844113391],
            [-5.540664027722698, 38.299781399092325],
            [-5.544772305297641, 38.311408596229683],
            [-5.547020229999674, 38.316111152107993],
            [-5.550275845054188, 38.320813707086927],
            [-5.554771693558763, 38.324637762922464],
            [-5.559887660587094, 38.328306790026375],
            [-5.564280158102775, 38.332079169018414],
            [-5.567122362007353, 38.336290798481627],
            [-5.567122362007353, 38.341019191882168],
            [-5.56495195167119, 38.351147773151155],
            [-5.563014085331702, 38.35631541612355],
            [-5.560378587002162, 38.367115790061661],
            [-5.560042690217927, 38.372412625142545],
            [-5.561024543048262, 38.377347724118152],
            [-5.563918422896904, 38.381766059156348],
            [-5.572703416129769, 38.389672553245731],
            [-5.575804001553365, 38.394323432280515],
            [-5.576475796021157, 38.399310208099649],
            [-5.575648972821824, 38.404477851071931],
            [-5.56937028581001, 38.425329291013981],
            [-5.562083910244269, 38.432486477169192],
            [-5.549293993123229, 38.440806383307887],
            [-5.504826422801102, 38.46015920738148],
            [-5.488212449844696, 38.46462921836374],
            [-5.480848558114445, 38.469202582133477],
            [-5.470590785636261, 38.483026028028718],
            [-5.464906378726369, 38.489072171043858],
            [-5.450204433687645, 38.499975897769474],
            [-5.449816860059968, 38.500389308919495],
            [-5.41963782362663, 38.515840561892389],
            [-5.411472948018115, 38.524832262498876],
            [-5.407829759335897, 38.531860256545599],
            [-5.406925421770723, 38.538113105135722],
            [-5.404134893810294, 38.543539130526398],
            [-5.400310837974814, 38.549120185548077],
            [-5.394187180593917, 38.555166326764578],
            [-5.385453864204464, 38.566070055288833],
            [-5.379898646705215, 38.571444403836182],
            [-5.362406174605269, 38.583872585751237],
            [-5.34948706717438, 38.586042996087428],
            [-5.329152391169941, 38.585732936825508],
            [-5.317060106039122, 38.583795071385396],
            [-5.311324022285788, 38.584260159378829],
            [-5.306828171982545, 38.585500392828919],
            [-5.30346920414047, 38.590306301494621],
            [-5.303159145777926, 38.594621283745226],
            [-5.300523647448301, 38.599246324358376],
            [-5.297423062024706, 38.603173732981418],
            [-5.293185594139857, 38.607075304082002],
            [-5.288147142376658, 38.612527166995221],
            [-5.255771857185437, 38.634386298189156],
            [-5.210064053413333, 38.659035956444228],
            [-5.180815192067428, 38.666038112968522],
            [-5.176293504241755, 38.670869859156653],
            [-5.174381476324072, 38.676502590122482],
            [-5.170996670060276, 38.682987982709278],
            [-5.167250128590581, 38.693426622340695],
            [-5.169187994929985, 38.701152249276845],
            [-5.173037889187242, 38.708206081745288],
            [-5.170454066801767, 38.713812975188688],
            [-5.15944698728859, 38.717947089386684],
            [-5.079865281557488, 38.719419867732711],
            [-5.063819749381935, 38.725388495482719],
            [-5.036586269640594, 38.728411567439906],
            [-4.979199590988316, 38.694382635849962],
            [-4.945971645974709, 38.683737291542741],
            [-4.924241705989971, 38.68332388039272],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5818",
        OBJECTID_1: 7267,
        diss_me: 5818,
        adm1_cod_1: "ESP-5818",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Cuenca",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CM",
        note: null,
        hasc_maybe: null,
        region: "Castilla-La Mancha",
        region_cod: "ES.CM",
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP84",
        fips_alt: "SP54",
        woe_id: 12602112,
        woe_label: "Castille la Mancha, ES, Spain",
        woe_name: "Castilla-La Mancha",
        latitude: 39.911499999999997,
        longitude: -2.1512600000000002,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2519034,
        gn_name: "Provincia de Cuenca",
        gns_id: -379458,
        gns_name: "Cuenca, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.CU",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP54",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.982827681552266, 40.587214454383513],
            [-1.955460737023401, 40.59906870245868],
            [-1.940969770238667, 40.598914894107111],
            [-1.92989555093888, 40.592652687171778],
            [-1.891597209343729, 40.53298593665167],
            [-1.856429971665875, 40.428846528344593],
            [-1.80825619087048, 40.402030341227231],
            [-1.808254613459667, 40.402029803432612],
            [-1.779291551264066, 40.392160143276016],
            [-1.762134975948442, 40.378285021436625],
            [-1.720638799840714, 40.335367742927019],
            [-1.71534196565915, 40.321415106721759],
            [-1.71603959764937, 40.310201320734336],
            [-1.720638799840714, 40.30234650348828],
            [-1.719036831184866, 40.289763291942393],
            [-1.713559128950664, 40.287670395971986],
            [-1.708985765180927, 40.291003526291661],
            [-1.699994066372994, 40.294750067761413],
            [-1.685240443591567, 40.293328966258741],
            [-1.660099859820605, 40.283019517836493],
            [-1.555093350377831, 40.205401515967139],
            [-1.541709154054217, 40.197908433927068],
            [-1.53346676318057, 40.195221258754117],
            [-1.519901698804347, 40.201164048981667],
            [-1.509437221650387, 40.201164048981667],
            [-1.495277878970995, 40.198425197864566],
            [-1.481118537190781, 40.19405853877052],
            [-1.469801398415768, 40.192973334052169],
            [-1.4443765929054, 40.196719876421128],
            [-1.439389817985756, 40.192379054849511],
            [-1.437813686852138, 40.186022854371203],
            [-1.43732276043707, 40.178813992271955],
            [-1.438123745214682, 40.171630967694938],
            [-1.442981329824505, 40.159176948257539],
            [-1.448536546424407, 40.149797674922723],
            [-1.452334763838223, 40.130677394845918],
            [-1.447787237590944, 40.125742295870197],
            [-1.440940110697539, 40.120445461688718],
            [-1.416884730745664, 40.109541734063754],
            [-1.412931483701016, 40.104787503140756],
            [-1.410166795061571, 40.097914536926368],
            [-1.40662696006612, 40.085460517488883],
            [-1.378308274707138, 40.022131049508317],
            [-1.369549118996588, 40.016343288911656],
            [-1.359782273832849, 40.014095364209652],
            [-1.349343634201347, 40.014405423471459],
            [-1.285910814332567, 40.006188870120241],
            [-1.258909877688694, 39.998127346399855],
            [-1.242967699199994, 39.997378038465655],
            [-1.234932013001952, 39.999936021530232],
            [-1.233226690659222, 40.000711167886038],
            [-1.223537359861268, 40.002313137441377],
            [-1.205786506242276, 40.003346666215663],
            [-1.187105474837722, 40.006188870120241],
            [-1.180542567885169, 40.008901881916358],
            [-1.162714199001044, 40.011459865880113],
            [-1.14548010931955, 39.972289129739551],
            [-1.157934128757063, 39.963891710134277],
            [-1.196226365754143, 39.950636705019889],
            [-1.203796963059375, 39.944332180485731],
            [-1.206613327642941, 39.93857025831079],
            [-1.205140550196205, 39.933480129704265],
            [-1.19901689281528, 39.92407501884702],
            [-1.196846483378494, 39.918183906362174],
            [-1.196691452848228, 39.910587469735958],
            [-1.199404466442985, 39.894826158400491],
            [-1.200334642429794, 39.879555772580858],
            [-1.202866787072537, 39.863303533930946],
            [-1.217801276107963, 39.81191132324895],
            [-1.225578578988234, 39.796305039745761],
            [-1.232684089199381, 39.787003282575483],
            [-1.243872035865735, 39.775479438225517],
            [-1.267048915774865, 39.743465888240166],
            [-1.270097826153687, 39.73424164543556],
            [-1.270562913247858, 39.725947576819209],
            [-1.269736090048553, 39.718816229984981],
            [-1.265550299007032, 39.703442491377842],
            [-1.265498623062967, 39.693804837423301],
            [-1.269942795623507, 39.686776842477315],
            [-1.275911424272778, 39.680627345775406],
            [-1.283766242418125, 39.67620901073721],
            [-1.292189500445033, 39.672669175741873],
            [-1.30174964003379, 39.671015530242585],
            [-1.320353156173212, 39.671377265448456],
            [-1.329086472562636, 39.673651028572095],
            [-1.337044644394837, 39.67744924598594],
            [-1.353994513236216, 39.683624579310901],
            [-1.363063727309196, 39.684348048823381],
            [-1.372029587695295, 39.683572903366752],
            [-1.380969610559077, 39.680575669831256],
            [-1.398953009074035, 39.668948472693899],
            [-1.42148393383647, 39.651378486228168],
            [-1.427530075952234, 39.645228990425522],
            [-1.463186814619775, 39.590245266106109],
            [-1.474788174234817, 39.578488877759469],
            [-1.485304328232189, 39.570918281353613],
            [-1.494425218249233, 39.567275091772018],
            [-1.498120082875488, 39.563476874358201],
            [-1.503158534638658, 39.552934881939279],
            [-1.50432125462217, 39.54924001731294],
            [-1.504476284253172, 39.545545151787309],
            [-1.50357194668797, 39.542677110360387],
            [-1.501453213195219, 39.539111436943216],
            [-1.499902919584059, 39.535674953835979],
            [-1.499825405218274, 39.534899807480087],
            [-1.49987708206163, 39.533142809193322],
            [-1.502150845185355, 39.527355047697284],
            [-1.50339107863536, 39.522962551080894],
            [-1.503313564269575, 39.517949936840068],
            [-1.502615933178731, 39.512989000342117],
            [-1.503236049903705, 39.503971463112549],
            [-1.501168993254396, 39.500173244799356],
            [-1.49987708206163, 39.499294744756696],
            [-1.499205287593924, 39.498312892825737],
            [-1.498352626872247, 39.496090807444801],
            [-1.501324021986022, 39.493713691533657],
            [-1.505432297762326, 39.49102651636062],
            [-1.507473516889291, 39.486789049375119],
            [-1.506439989014268, 39.476040351381073],
            [-1.512692836705014, 39.458935452009484],
            [-1.518196377360908, 39.451829941798337],
            [-1.518273891726693, 39.446016344578624],
            [-1.517447069426737, 39.441081243804376],
            [-1.515018275772803, 39.436740424031314],
            [-1.510909999996528, 39.432683824199103],
            [-1.506801724220225, 39.430410061075435],
            [-1.501016139982852, 39.422041627519093],
            [-1.50101396272413, 39.42203847899259],
            [-1.739414586513959, 39.321106007236494],
            [-1.769956580331893, 39.280610400649437],
            [-1.845103067152792, 39.286103564927544],
            [-1.886971965483809, 39.304637500520215],
            [-1.9899138635339, 39.293651171963973],
            [-2.029047166293708, 39.337926076027514],
            [-2.020807419426859, 39.351988577011184],
            [-2.09065849659234, 39.359481253457062],
            [-2.048954393630339, 39.266767626524683],
            [-2.168364798396709, 39.280950976606306],
            [-2.288818904169062, 39.226733444992504],
            [-2.321074764788534, 39.283697558295671],
            [-2.349210752873944, 39.278885546830537],
            [-2.373929991675766, 39.232907762083528],
            [-2.511182194003027, 39.342034963342883],
            [-2.529364567520759, 39.327620900284273],
            [-2.567794745231282, 39.326939747471386],
            [-2.619287667391973, 39.339969533567142],
            [-2.662178293593399, 39.321787160049411],
            [-2.742125806660653, 39.318018848690883],
            [-2.789147292413645, 39.397636771919508],
            [-2.830675614787793, 39.355075735556795],
            [-2.882498125887764, 39.350966848241427],
            [-2.896560626871434, 39.366743216026549],
            [-2.86980891664814, 39.390429740839835],
            [-2.907217365588963, 39.430222222377694],
            [-2.931222493223515, 39.47140996789571],
            [-2.925388752988539, 39.601817688235741],
            [-2.90823909435872, 39.642313294822713],
            [-2.999502524921525, 39.721920231933183],
            [-3.015300865842249, 39.764481268295896],
            [-3.040701257457073, 39.787464668059954],
            [-3.059553796770302, 39.837562326240317],
            [-3.096281093797501, 39.869148020164943],
            [-3.125098232897074, 39.878068918945445],
            [-3.096973231829168, 39.933330151565201],
            [-3.096643641990454, 39.986844558129775],
            [-3.142621427636755, 39.985130691328322],
            [-3.170065275891773, 40.048269120823221],
            [-3.163548142632663, 40.066185207781047],
            [-3.113447842244085, 40.068019721333059],
            [-3.093759121386313, 40.072644761946208],
            [-3.078669602719998, 40.082308255221847],
            [-3.068696051081929, 40.090550646095494],
            [-3.06303748169438, 40.098121243400612],
            [-3.062236496916768, 40.105330105499974],
            [-3.065672980024004, 40.111918849974955],
            [-3.071822475826536, 40.117551580940699],
            [-3.076085781233814, 40.124114487893252],
            [-3.079548101863395, 40.130935777264312],
            [-3.081847703408755, 40.138790595409631],
            [-3.085671759244292, 40.145663560724728],
            [-3.086963670437086, 40.154706936375987],
            [-3.084121465633132, 40.15858266905488],
            [-3.080400762585157, 40.159176948257539],
            [-3.07275265091414, 40.15760081622463],
            [-3.063684656321868, 40.168382650638819],
            [-3.014279137072577, 40.155341879324254],
            [-3.019772301350599, 40.193079917204415],
            [-2.998129234076856, 40.209207847963853],
            [-2.947009847126708, 40.169755942382864],
            [-2.912765461356912, 40.15757210356972],
            [-2.901482502215629, 40.159318929624902],
            [-2.892319904340098, 40.16470223092324],
            [-2.885178790868508, 40.172744223167371],
            [-2.873851885456133, 40.192607505132202],
            [-2.866183428422516, 40.197924888822541],
            [-2.823743241157644, 40.204077232778076],
            [-2.816294510083566, 40.209878013759322],
            [-2.801012527506117, 40.266534510359349],
            [-2.815382645192926, 40.325388271951198],
            [-2.807692215023735, 40.406873871232222],
            [-2.769943190126128, 40.421309906526943],
            [-2.72291071825498, 40.397271819638718],
            [-2.679514719828433, 40.433230073109343],
            [-2.626879219800117, 40.453060396719934],
            [-2.601907294885706, 40.453357027305003],
            [-2.575298407795287, 40.447929780634865],
            [-2.512797184842213, 40.419716888823416],
            [-2.50738092429026, 40.420793549083015],
            [-2.503063297133394, 40.424924408634496],
            [-2.500975895121428, 40.430857026630491],
            [-2.500723209008868, 40.437327973407207],
            [-2.501909733147642, 40.443062837679832],
            [-2.508138980829301, 40.451137789177608],
            [-2.527507878485807, 40.461904390874906],
            [-2.534978581796082, 40.469792574767467],
            [-2.535055486421527, 40.476560153028629],
            [-2.529254704540989, 40.494149265376308],
            [-2.528694401725204, 40.498862399979799],
            [-2.54447076951044, 40.516396579938174],
            [-2.548777410549064, 40.526668797327147],
            [-2.545360662164825, 40.537248632318523],
            [-2.541372624846758, 40.540456639961945],
            [-2.527771550716523, 40.544093114305838],
            [-2.500481509913726, 40.516198826214918],
            [-2.494384097448119, 40.513595066758967],
            [-2.488001040515599, 40.513023777825111],
            [-2.432080628218415, 40.535622655361266],
            [-2.397858215584108, 40.528690281731087],
            [-2.391508117006026, 40.531063329109344],
            [-2.387575012077093, 40.539237157468847],
            [-2.390090880789472, 40.550234472143131],
            [-2.416106506709923, 40.580051367776377],
            [-2.416644837289425, 40.589071143418494],
            [-2.413579650980125, 40.598486427406669],
            [-2.407844787606876, 40.606374611299231],
            [-2.400374084296573, 40.610791115317653],
            [-2.388992248293562, 40.610813087553936],
            [-2.366997618045616, 40.600310158087353],
            [-2.330918515477066, 40.573294775633343],
            [-2.320184872134121, 40.572360937607243],
            [-2.309033749108266, 40.576865332369124],
            [-2.299519588258363, 40.584654639400071],
            [-2.292883846112545, 40.594795020673672],
            [-2.290356990382833, 40.606319679809388],
            [-2.291433650642432, 40.633313089127739],
            [-2.287039118860235, 40.644200539922963],
            [-2.274207087387197, 40.646189065972578],
            [-2.24607110020105, 40.632368264084135],
            [-2.211914604275535, 40.623447365303633],
            [-2.194666068783988, 40.623809914396077],
            [-2.177274711059027, 40.628577981388787],
            [-2.169386527166466, 40.633752541946251],
            [-2.164157034219784, 40.640915627654124],
            [-2.157048880001753, 40.658757425214986],
            [-2.145710988471336, 40.659790140102899],
            [-2.085714649011777, 40.641135354512954],
            [-2.03110160905203, 40.599398292297366],
            [-1.99355033877714, 40.587511084968583],
            [-1.982827681552266, 40.587214454383513],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5820",
        OBJECTID_1: 7261,
        diss_me: 5820,
        adm1_cod_1: "ESP-5820",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Gerona",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CT",
        note: null,
        hasc_maybe: null,
        region: "Cataluña",
        region_cod: "ES.CT",
        provnum_ne: 16,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP86",
        fips_alt: "SP56",
        woe_id: 12602125,
        woe_label: "Catalonia, ES, Spain",
        woe_name: "Cataluña",
        latitude: 42.084400000000002,
        longitude: 2.7912300000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355230,
        gn_name: "Provincia de Girona",
        gns_id: -383957,
        gns_name: "Gerona, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.GI",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP56",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.986162557000114, 42.441260681000031],
              [1.983372030000055, 42.441260681000031],
              [1.960634399000071, 42.443715312],
              [1.947508585000122, 42.451234233000051],
              [1.945648233000099, 42.463817445000146],
              [1.956603638000075, 42.481775005000046],
              [1.967972453000073, 42.485702413000112],
              [1.978307740000076, 42.474979554000086],
              [1.988436320000119, 42.458184713000136],
              [1.99949507600013, 42.443896180000038],
              [1.996084432000117, 42.442836812000067],
              [1.986162557000114, 42.441260681000031],
            ],
          ],
          [
            [
              [1.995671020000088, 42.348682353000171],
              [2.009210245000077, 42.347080383000147],
              [2.035255168000049, 42.349250794000127],
              [2.05168827300011, 42.352351380000172],
              [2.063263794000079, 42.357906597000081],
              [2.080110311000055, 42.371264954000097],
              [2.103984823000133, 42.384830017000084],
              [2.108739054000125, 42.39064361599999],
              [2.116283813000081, 42.405397237],
              [2.121038045000148, 42.410616557000097],
              [2.136954386000099, 42.416223450000032],
              [2.184186645000125, 42.411805115000121],
              [2.245991659000111, 42.426119487000037],
              [2.277410930000144, 42.428625794000098],
              [2.305316203000132, 42.416972758000114],
              [2.317305135000083, 42.409608867000131],
              [2.375492798000096, 42.389325867000125],
              [2.406602010000142, 42.384261576000043],
              [2.422518351000065, 42.38369313599999],
              [2.429856404000077, 42.367544251000098],
              [2.445566040000131, 42.351963806000171],
              [2.464893025000094, 42.340620829],
              [2.483393188000122, 42.336977641000018],
              [2.503650350000015, 42.328451030000068],
              [2.514812459000069, 42.325660503000123],
              [2.525251098000098, 42.325893047000179],
              [2.531969035000117, 42.330776470000032],
              [2.540340617000084, 42.346977031],
              [2.548092081000107, 42.352248027000044],
              [2.56741906700006, 42.352635600000056],
              [2.613411092000149, 42.340956726000073],
              [2.635425253000022, 42.337881979000045],
              [2.662193644000098, 42.339018861000156],
              [2.661780232000098, 42.34335968000012],
              [2.649377889000107, 42.35372080600014],
              [2.640282837000143, 42.372841085000132],
              [2.648241007000109, 42.381471049000183],
              [2.690408976000072, 42.406172384000101],
              [2.708082316000059, 42.41289032000013],
              [2.72854618300002, 42.414027202000128],
              [2.767716919000065, 42.409557191000104],
              [2.788594198000055, 42.412011821000178],
              [2.804613892000106, 42.419556580000133],
              [2.834792928000098, 42.441312358000133],
              [2.850812622000149, 42.448366191000062],
              [2.866935669000043, 42.449632263000112],
              [2.88409224500009, 42.449193014000073],
              [2.900835409000138, 42.45126007100005],
              [2.933701619000061, 42.470225322000104],
              [2.966051066000148, 42.461001079000098],
              [2.983104289000096, 42.469320984000049],
              [2.993749634000096, 42.467615662000085],
              [3.02299849400012, 42.467047221000044],
              [3.029613077000135, 42.465651957000048],
              [3.036020956000073, 42.45461903900015],
              [3.04387577300011, 42.445265605000102],
              [3.053590942000142, 42.437643331000132],
              [3.065579874000093, 42.432010600000027],
              [3.086147094000097, 42.427178854000104],
              [3.111675252000111, 42.425008444000028],
              [3.137823527000137, 42.425938620000167],
              [3.180969656711085, 42.431484147686447],
              [3.178721550000091, 42.417995510000097],
              [3.170176629000053, 42.396592515000165],
              [3.174489780000073, 42.380886135000097],
              [3.174489780000073, 42.363796291000043],
              [3.187347852000073, 42.349514065000065],
              [3.204193556000092, 42.339585679000052],
              [3.226491733000046, 42.34251536699999],
              [3.24984785200013, 42.343207098000036],
              [3.26303144600007, 42.32920970300016],
              [3.269867384000094, 42.32920970300016],
              [3.280609571000099, 42.33685944200009],
              [3.293711785000085, 42.334987697000102],
              [3.317718946000099, 42.322984117000019],
              [3.294037306000092, 42.28803131700009],
              [3.290375196000127, 42.27806224199999],
              [3.287038608000103, 42.26292552299999],
              [3.279388868000098, 42.255641994000158],
              [3.270355665000068, 42.250108140000165],
              [3.26303144600007, 42.239854234000134],
              [3.247162306000035, 42.245713609000077],
              [3.210134311000104, 42.239732164000159],
              [3.178721550000091, 42.249701239000061],
              [3.170176629000053, 42.25967031500015],
              [3.151621941000144, 42.25682200700011],
              [3.130300326000082, 42.239732164000159],
              [3.11882571700005, 42.218329169000029],
              [3.117442254000053, 42.182847398000135],
              [3.116465691000087, 42.158433335000083],
              [3.122487826000082, 42.140488999000027],
              [3.138601108000074, 42.127630927000027],
              [3.173106316000087, 42.112005927000112],
              [3.192298528000066, 42.092921471000167],
              [3.214495693000117, 42.068333846000044],
              [3.213009237000051, 42.055986077000156],
              [3.199859564000036, 42.046190092000089],
              [3.195896147000042, 42.033117872000119],
              [3.201589389000077, 42.017442124000112],
              [3.206960483000046, 41.996690171000111],
              [3.207855665000125, 41.990016994000044],
              [3.211843295000108, 41.984605210000026],
              [3.230560743000098, 41.973008531000076],
              [3.235687696000099, 41.965521552000112],
              [3.232188347000118, 41.946030992000132],
              [3.207448764000048, 41.913926499000141],
              [3.194916212000123, 41.888291734000106],
              [3.178558790000125, 41.875230210000112],
              [3.150165377000064, 41.845350970000041],
              [3.116829332000066, 41.847569212000096],
              [3.078630960000112, 41.826618274000154],
              [3.069057555000086, 41.799076112000094],
              [3.040341037000104, 41.786890013000075],
              [2.95093834700009, 41.734279690000122],
              [2.932990851000056, 41.712464376000085],
              [2.900870238000095, 41.706706377000089],
              [2.877253539000122, 41.701957700000108],
              [2.851084832000112, 41.697170315000122],
              [2.840179884000122, 41.694647528000118],
              [2.835459832000112, 41.692572333],
              [2.820160352000073, 41.681952216000141],
              [2.817067905000073, 41.67763906500015],
              [2.813161655000073, 41.670314846000124],
              [2.803721550000148, 41.668646552000112],
              [2.792002800000091, 41.669012762000037],
              [2.778496577777588, 41.645582260906409],
              [2.760783338979905, 41.67354262029572],
              [2.759596815740508, 41.682815082050311],
              [2.762255506686301, 41.687363422184163],
              [2.771527968440978, 41.695493305171865],
              [2.774032851035315, 41.700382221262501],
              [2.767770644099897, 41.721344135723328],
              [2.747929334371264, 41.735725240427584],
              [2.701501109788325, 41.748853902485521],
              [2.683988902066176, 41.749677877532022],
              [2.66783899907054, 41.747194967173996],
              [2.636989388549466, 41.733220356933984],
              [2.547428838669958, 41.719157856849577],
              [2.436598756727221, 41.802873680376038],
              [2.394543090790989, 41.804312888828733],
              [2.370900511349589, 41.798303367106698],
              [2.362704710753803, 41.798665916199027],
              [2.346049436432395, 41.807916404818329],
              [2.329679806577843, 41.825483544030348],
              [2.31794640760063, 41.846192773277792],
              [2.315309688891034, 41.864847559767114],
              [2.324604122882022, 41.879382471923478],
              [2.331745236353584, 41.886721340017786],
              [2.337546018234207, 41.889094386496751],
              [2.382853637185576, 41.871472315794691],
              [2.393510375903162, 41.873812403919217],
              [2.424425904032319, 41.889017482770683],
              [2.442751100682756, 41.887644191026638],
              [2.462262819673384, 41.910605617655122],
              [2.462965944722612, 41.920899808179584],
              [2.452353151376883, 41.932193753438938],
              [2.444157349881806, 41.938016507555758],
              [2.439279420808589, 41.943432767208449],
              [2.439850709742529, 41.949420316694173],
              [2.489377078988952, 41.990871734443019],
              [2.499308719521792, 42.005055084524443],
              [2.502648563280587, 42.02594009525923],
              [2.49429895343394, 42.046770173604713],
              [2.478434695804509, 42.063820955372719],
              [2.459142702773534, 42.073357089358197],
              [2.419547974059753, 42.073236239360924],
              [2.40544152860366, 42.08108047788177],
              [2.365890746161028, 42.121345372390962],
              [2.327174923983506, 42.112644199570198],
              [2.307795040208958, 42.112962803290671],
              [2.294084102199577, 42.123443760521141],
              [2.286393672030357, 42.138330235651878],
              [2.280153438230599, 42.143449864719472],
              [2.231286248661434, 42.142153478500305],
              [2.219904411759217, 42.138286290280078],
              [2.208610466499863, 42.129914708197148],
              [2.204171989345866, 42.128003086773063],
              [2.1588863435299, 42.127629551562464],
              [2.129333119228107, 42.119587559318418],
              [2.124081654944405, 42.120949864045045],
              [2.098044055888465, 42.137286534645895],
              [2.090902942416875, 42.138374181023565],
              [2.073544543046268, 42.135957189172913],
              [2.065546496173852, 42.137154698530438],
              [2.058844835520745, 42.14343887860143],
              [2.055614854741748, 42.152964025569403],
              [2.054186632407209, 42.185296790814292],
              [2.050868760884612, 42.196810462932504],
              [2.04574913181699, 42.20413834400928],
              [2.025248643310306, 42.223441323158582],
              [2.023886338583679, 42.227341469733105],
              [2.029181748239068, 42.251324625131261],
              [2.043112413107394, 42.264826822399883],
              [2.04671592909682, 42.272725993309891],
              [2.046166612399276, 42.282932292191489],
              [2.043881455764591, 42.289106609282598],
              [2.040651475884857, 42.293523113300992],
              [2.036125107987402, 42.296599284829185],
              [2.029972764031868, 42.298719646094767],
              [2.015954209319347, 42.299400798907683],
              [1.987323836925498, 42.294160319842845],
              [1.973085555354118, 42.294962321753772],
              [1.938236920497474, 42.314287274038477],
              [1.913913189142335, 42.318374188218186],
              [1.842545998898743, 42.306311199402344],
              [1.833647073253815, 42.327240155508733],
              [1.83648154568678, 42.335337079242819],
              [1.837558205946465, 42.343027509412124],
              [1.836525491058637, 42.350684980327614],
              [1.833031838948273, 42.358650068845591],
              [1.815607521969497, 42.375964522844512],
              [1.811125099443814, 42.383632979878044],
              [1.810861428112389, 42.412812668070259],
              [1.807170021379392, 42.418547531443423],
              [1.742834081627564, 42.436158616926676],
              [1.728749608407696, 42.446826341762247],
              [1.719367283673222, 42.46094377333641],
              [1.714071873118456, 42.477038744842204],
              [1.712174112972548, 42.493712260056398],
              [1.730570923000073, 42.488053691000133],
              [1.792685995000056, 42.481723328000115],
              [1.813666626000042, 42.475237936000056],
              [1.869787232000107, 42.446790060000026],
              [1.89324833200007, 42.442733460000127],
              [1.911955200000136, 42.441958313000114],
              [1.927458129000058, 42.43689402300005],
              [1.941100708000079, 42.420099182000072],
              [1.954226522000056, 42.382685445000035],
              [1.963734985000116, 42.367234192000026],
              [1.983372030000055, 42.354547628000077],
              [1.995671020000088, 42.348682353000171],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5821",
        OBJECTID_1: 7275,
        diss_me: 5821,
        adm1_cod_1: "ESP-5821",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Granada",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602099,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 37.266500000000001,
        longitude: -3.2410999999999999,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2517115,
        gn_name: "Provincia de Granada",
        gns_id: -384330,
        gns_name: "Granada",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.GR",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.3509362452115, 38.023622544944487],
            [-2.338533900818845, 38.026490587270786],
            [-2.304298264553211, 38.006362616841301],
            [-2.296236741732173, 38.00057485624464],
            [-2.29569413937304, 37.999799709888748],
            [-2.294996507382905, 37.998352769964328],
            [-2.281819016634273, 37.978276476378255],
            [-2.261432663786394, 37.953523465335707],
            [-2.232235480183192, 37.926419175904243],
            [-2.209652878577515, 37.915670477910282],
            [-2.209648157136769, 37.915668080317658],
            [-2.271647273303017, 37.881699214447195],
            [-2.280315485970846, 37.874481196350104],
            [-2.285918514128099, 37.864780267894986],
            [-2.290488826498176, 37.82062621382866],
            [-2.282358943510388, 37.764068594090276],
            [-2.288862849540834, 37.746435537270173],
            [-2.313076717916175, 37.715014637815273],
            [-2.32149224537082, 37.697744129188237],
            [-2.315933163484715, 37.622092271041481],
            [-2.375995421451535, 37.61213865737318],
            [-2.358483213729357, 37.522226544519384],
            [-2.490154361034911, 37.489366435712412],
            [-2.642139230141339, 37.383216529119522],
            [-2.648709054679159, 37.368725561435497],
            [-2.6525762428993, 37.312288791694385],
            [-2.654960275496308, 37.304598362424457],
            [-2.663892161294143, 37.290744602181661],
            [-2.665562082723909, 37.284405490620912],
            [-2.660112863817574, 37.212005585489379],
            [-2.710364330349591, 37.22955075156591],
            [-2.756946363284015, 37.232374237880805],
            [-2.769943190126128, 37.238043183645971],
            [-2.794640456691752, 37.263476534514439],
            [-2.804385330518556, 37.268530245974063],
            [-2.83486140582923, 37.272990695364257],
            [-2.846495927944716, 37.271606417502142],
            [-2.854823564655788, 37.267102022740147],
            [-2.859932207605254, 37.260905733412926],
            [-2.884717364914451, 37.17939816189562],
            [-2.893978839651794, 37.170136687158362],
            [-2.917423664470562, 37.163215300545716],
            [-2.927860677228438, 37.157370573293321],
            [-2.936067463942265, 37.144022184376269],
            [-2.943900716345013, 37.113095669229693],
            [-2.952338216935118, 37.100823940572354],
            [-2.96250057044503, 37.096660121767229],
            [-2.997722740512074, 37.095473598527747],
            [-3.011126060918969, 37.085080531141585],
            [-3.015355797332177, 37.069655726330637],
            [-3.008807945929931, 37.036136437846352],
            [-2.992647055916876, 36.999266319485088],
            [-2.99080135300008, 36.986181601899403],
            [-2.996909751583729, 36.970646933209395],
            [-3.06771663901165, 36.914693562557858],
            [-3.072660487491476, 36.902268024649572],
            [-3.064761316581496, 36.894226032405442],
            [-3.052269861065213, 36.887502398616789],
            [-3.043436853028396, 36.879130816533944],
            [-3.029022789969787, 36.844457964063793],
            [-3.140226408022301, 36.787845412835566],
            [-3.128224358155819, 36.746382255157613],
            [-3.156711171999916, 36.744525733000103],
            [-3.22789466099988, 36.754828192000048],
            [-3.329579230999855, 36.744899807000138],
            [-3.34439042899993, 36.736924546000097],
            [-3.358306443999908, 36.725938218000039],
            [-3.378773566999968, 36.713893947000116],
            [-3.415154668999918, 36.695516287000089],
            [-3.477602127999972, 36.696095994000103],
            [-3.51596254699993, 36.721046505000032],
            [-3.536060363999894, 36.718406387000073],
            [-3.549831564999891, 36.712372686],
            [-3.58640154899993, 36.726579257000054],
            [-3.601100173999924, 36.742550294000068],
            [-3.632419884999877, 36.741279670000011],
            [-3.665666698999871, 36.739370416000042],
            [-3.679822363999961, 36.728807878000055],
            [-3.731281329999945, 36.724097324000041],
            [-3.77724176569518, 36.73580342668572],
            [-3.782278434102409, 36.764510450996568],
            [-3.768204947000669, 36.801918899937306],
            [-3.827234490079661, 36.855455278738077],
            [-3.875618281458486, 36.853049273005581],
            [-4.148474736919525, 36.948465536255441],
            [-4.260963755073249, 37.023974572168783],
            [-4.27332337447416, 37.038048059270523],
            [-4.280629283314738, 37.056691858742312],
            [-4.287078257855342, 37.119676479885669],
            [-4.320300915754643, 37.186572234620911],
            [-4.255162973192625, 37.229495820075982],
            [-4.263402720059503, 37.260389375968913],
            [-4.237123421908393, 37.296424534064982],
            [-4.235508431968583, 37.327208226078767],
            [-4.225060433092466, 37.343544896679589],
            [-4.217216194571677, 37.350400365684351],
            [-4.209174202327546, 37.352355932480293],
            [-4.172117316361096, 37.346686986715042],
            [-4.154725957736787, 37.348895238724282],
            [-4.137169804642724, 37.357574438409642],
            [-4.093883670994614, 37.396147437454289],
            [-4.076382449390508, 37.403519264802213],
            [-4.000873413477251, 37.4021130147039],
            [-3.950786741414959, 37.378437476008656],
            [-3.841307977181401, 37.38633664691875],
            [-3.791880484796536, 37.466295146104045],
            [-3.683071887257825, 37.518117657204101],
            [-3.627162461078711, 37.529422589480902],
            [-3.507136822006686, 37.61334715284886],
            [-3.491492290336907, 37.616434311394357],
            [-3.468256205359552, 37.606337875492557],
            [-3.42402524666781, 37.568522932986951],
            [-3.399262061594811, 37.564106428968557],
            [-3.315106785249952, 37.577817366977854],
            [-3.297385837686193, 37.587781966764311],
            [-3.275501071317393, 37.61622557155286],
            [-3.251825532622263, 37.633034654225924],
            [-3.223403900969288, 37.639252915789456],
            [-3.187280853028938, 37.635869126658861],
            [-3.090798915637436, 37.596021712731897],
            [-3.070507166972163, 37.607414535752156],
            [-3.051336023039028, 37.612885727794065],
            [-3.031231041979026, 37.612929673165866],
            [-2.983385581179618, 37.601492904773721],
            [-2.976189536218129, 37.632727036623407],
            [-2.902064777267611, 37.665664050055909],
            [-2.900230060468857, 37.74824828183273],
            [-2.889650226376773, 37.774318839243023],
            [-2.786279860726978, 37.912449948106627],
            [-2.699641674024065, 37.972644042188819],
            [-2.691643627151649, 37.973182371869029],
            [-2.686480052712227, 37.971391600441962],
            [-2.670769602535103, 37.961481932145432],
            [-2.598347725167343, 38.005745850090904],
            [-2.602478584718938, 38.062028810581154],
            [-2.547384202419039, 38.080647488390895],
            [-2.465838791926672, 38.049383246339744],
            [-2.361168179268049, 38.024346015356343],
            [-2.3509362452115, 38.023622544944487],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5822",
        OBJECTID_1: 7266,
        diss_me: 5822,
        adm1_cod_1: "ESP-5822",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Guadalajara",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CM",
        note: null,
        hasc_maybe: null,
        region: "Castilla-La Mancha",
        region_cod: "ES.CM",
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 11,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP84",
        fips_alt: "SP54",
        woe_id: 12602113,
        woe_label: "Castille la Mancha, ES, Spain",
        woe_name: "Castilla-La Mancha",
        latitude: 40.842199999999998,
        longitude: -2.5308700000000002,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3121069,
        gn_name: "Provincia de Guadalajara",
        gns_id: -384581,
        gns_name: "Guadalajara, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.GU",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP54",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.805895554835985, 41.259936632169854],
            [-2.78129757342424, 41.26104767531001],
            [-2.770057949015097, 41.265543524713877],
            [-2.760988734942117, 41.270556138954646],
            [-2.751816168980952, 41.273940945218442],
            [-2.741351690927786, 41.274225165159265],
            [-2.728096685813398, 41.272778225234958],
            [-2.720138515779865, 41.265956935863898],
            [-2.715125902438388, 41.259032293705388],
            [-2.709234788154959, 41.252521064495539],
            [-2.698124354955013, 41.247973538248146],
            [-2.680218471705103, 41.243736070363383],
            [-2.655801357446705, 41.240945543302132],
            [-2.639471605330328, 41.234873561865385],
            [-2.631410080710651, 41.228362331756159],
            [-2.625906540954105, 41.222652086424631],
            [-2.618258429283117, 41.220094103360168],
            [-2.609525111994287, 41.221876939169363],
            [-2.599913295562089, 41.222574571159498],
            [-2.59192928710695, 41.221980291956868],
            [-2.585986496879286, 41.215520737791792],
            [-2.58859615678719, 41.209965522091181],
            [-2.593350388609537, 41.20358348229189],
            [-2.608362392910095, 41.195573635414917],
            [-2.610041876831076, 41.191697902736024],
            [-2.602161221163414, 41.186659450972854],
            [-2.584823777795066, 41.179398912030166],
            [-2.579501105191866, 41.172370917084152],
            [-2.580224574704346, 41.165756334187449],
            [-2.588854539205585, 41.154852607461834],
            [-2.586322393663551, 41.149581610802699],
            [-2.578235033320084, 41.146377671692136],
            [-2.567176276064288, 41.14555084939218],
            [-2.550872362369603, 41.150382595580311],
            [-2.54079545704468, 41.154852607461834],
            [-2.532062139755965, 41.157229723373064],
            [-2.522786221007294, 41.152630520282344],
            [-2.517902797975751, 41.146170966117182],
            [-2.513717006934314, 41.139091295227018],
            [-2.511494919754739, 41.132580064218615],
            [-2.508135951912635, 41.127515774033725],
            [-2.505603807269864, 41.124725246972559],
            [-2.500384486554793, 41.121934719012103],
            [-2.488137172692348, 41.117232164033084],
            [-2.48144507542986, 41.113821520246944],
            [-2.477491828385126, 41.107904568441086],
            [-2.471884934941727, 41.093461004921494],
            [-2.467621628635243, 41.086071274769523],
            [-2.460386929013566, 41.079740911813644],
            [-2.449715745385333, 41.074599107262969],
            [-2.422482265644078, 41.065426541301804],
            [-2.409201423007232, 41.064987290830771],
            [-2.376516078554118, 41.077570502376744],
            [-2.364475470266711, 41.080671087800454],
            [-2.352409022658236, 41.077958076004421],
            [-2.344580043833901, 41.073178005760354],
            [-2.326880866159058, 41.065245673249166],
            [-2.313315801782835, 41.063798733324859],
            [-2.28835608606451, 41.067519436372919],
            [-2.273266567398196, 41.071291816264306],
            [-2.260554164642997, 41.076046048086653],
            [-2.249056158714751, 41.083539130126752],
            [-2.237041387949716, 41.088965156416805],
            [-2.219109666278086, 41.091109728331247],
            [-2.196475388728174, 41.088396714736604],
            [-2.187070277871044, 41.088810125886624],
            [-2.177613491069735, 41.090670477860243],
            [-2.145186529934392, 41.114389960128591],
            [-2.137099167792371, 41.118317368751548],
            [-2.128960129706201, 41.116457018576568],
            [-2.123146531587111, 41.111470241858143],
            [-2.115860155121993, 41.106328437307582],
            [-2.09121049596763, 41.094442856852368],
            [-2.084389206596597, 41.089688625929369],
            [-2.079299078889335, 41.083797512545146],
            [-2.072942878411141, 41.078888251091882],
            [-2.065268927419055, 41.076872870386609],
            [-2.059377814034917, 41.079456691872821],
            [-2.057284918963802, 41.084986070051059],
            [-2.060075446024939, 41.091135565853705],
            [-2.061780768367754, 41.109997464411492],
            [-2.05265987835071, 41.13552562091067],
            [-2.053228319131563, 41.15035675715859],
            [-1.971863775792627, 41.169942125228857],
            [-1.944862840047961, 41.164412747050619],
            [-1.938661668301279, 41.157229723373064],
            [-1.928868983816528, 41.150150051583637],
            [-1.916234097225782, 41.143018703850146],
            [-1.891171026921313, 41.135163885704713],
            [-1.850424159647247, 41.111134345073992],
            [-1.81691199379344, 41.096975003293778],
            [-1.767328457342444, 41.064987290830771],
            [-1.761101447174042, 41.058553575087416],
            [-1.756398892195108, 41.052274888974978],
            [-1.753065761875376, 41.045505276447358],
            [-1.747303839700436, 41.038813178285523],
            [-1.732808600236666, 41.026023261164568],
            [-1.67927181584156, 40.990754096124618],
            [-1.673070644994255, 40.984501248433872],
            [-1.662606166941032, 40.976413886291851],
            [-1.656353319250286, 40.970109360858316],
            [-1.621885138088658, 40.946751613795925],
            [-1.610154588163653, 40.927734687405888],
            [-1.611937424872224, 40.919208075692126],
            [-1.614107835208387, 40.88670360019097],
            [-1.6088368394486, 40.871872463943077],
            [-1.601033698146608, 40.858746650037858],
            [-1.593618129573002, 40.85084015594839],
            [-1.579820522099396, 40.838541165242503],
            [-1.55369808639756, 40.825105292075563],
            [-1.544551357059447, 40.817379666038704],
            [-1.541399094792411, 40.809757391890145],
            [-1.539616258083839, 40.8007140162388],
            [-1.538763597362163, 40.776839505238996],
            [-1.541786668420002, 40.767227687907422],
            [-1.551191779277133, 40.752293198872025],
            [-1.555403407840998, 40.7439474552109],
            [-1.556695319033679, 40.735395005974794],
            [-1.557005378295599, 40.725163071918217],
            [-1.553077968773295, 40.716326401842025],
            [-1.54698014981409, 40.707489731765719],
            [-1.539667934927252, 40.694363918759905],
            [-1.537058275019348, 40.684131984703328],
            [-1.537445847747648, 40.662996323921163],
            [-1.540546434070649, 40.655115668253501],
            [-1.548607956891658, 40.641705634407458],
            [-1.554240687857487, 40.625789293441102],
            [-1.555222540687737, 40.614162096303744],
            [-1.551191779277133, 40.595093492170975],
            [-1.555635951837672, 40.589150701943311],
            [-1.561733770796877, 40.58294953109592],
            [-1.56989864730474, 40.578143622430218],
            [-1.586745164257906, 40.570598863546707],
            [-1.595711024644032, 40.569772040347374],
            [-1.604315150723664, 40.57062470106905],
            [-1.612480028130818, 40.574164536963764],
            [-1.620231492589284, 40.578918768786139],
            [-1.636871303967496, 40.5843447941769],
            [-1.64599219398454, 40.584448146964377],
            [-1.671055264289009, 40.589279894051884],
            [-1.677799038394909, 40.585274970163709],
            [-1.682759975792237, 40.579022122472992],
            [-1.698598802392809, 40.542383530975201],
            [-1.699994066372994, 40.528947658707466],
            [-1.69976152237632, 40.493342596883338],
            [-1.705626797338823, 40.485358588428085],
            [-1.713714158581553, 40.480216782978147],
            [-1.732472702553139, 40.472904568091394],
            [-1.741412726316298, 40.467349352390698],
            [-1.75004268991816, 40.459210313405265],
            [-1.782547166318665, 40.42104726851656],
            [-1.789626838108092, 40.415957139910034],
            [-1.79595720106397, 40.414691067139074],
            [-1.80825619087048, 40.402030341227231],
            [-1.856429971665875, 40.428846528344593],
            [-1.891597209343729, 40.53298593665167],
            [-1.92989555093888, 40.592652687171778],
            [-1.940969770238667, 40.598914894107111],
            [-1.955460737023401, 40.59906870245868],
            [-1.982827681552266, 40.587214454383513],
            [-1.99355033877714, 40.587511084968583],
            [-2.03110160905203, 40.599398292297366],
            [-2.085714649011777, 40.641135354512954],
            [-2.145710988471336, 40.659790140102899],
            [-2.157048880001753, 40.658757425214986],
            [-2.164157034219784, 40.640915627654124],
            [-2.169386527166466, 40.633752541946251],
            [-2.177274711059027, 40.628577981388787],
            [-2.194666068783988, 40.623809914396077],
            [-2.211914604275535, 40.623447365303633],
            [-2.24607110020105, 40.632368264084135],
            [-2.274207087387197, 40.646189065972578],
            [-2.287039118860235, 40.644200539922963],
            [-2.291433650642432, 40.633313089127739],
            [-2.290356990382833, 40.606319679809388],
            [-2.292883846112545, 40.594795020673672],
            [-2.299519588258363, 40.584654639400071],
            [-2.309033749108266, 40.576865332369124],
            [-2.320184872134121, 40.572360937607243],
            [-2.330918515477066, 40.573294775633343],
            [-2.366997618045616, 40.600310158087353],
            [-2.388992248293562, 40.610813087553936],
            [-2.400374084296573, 40.610791115317653],
            [-2.407844787606876, 40.606374611299231],
            [-2.413579650980125, 40.598486427406669],
            [-2.416644837289425, 40.589071143418494],
            [-2.416106506709923, 40.580051367776377],
            [-2.390090880789472, 40.550234472143131],
            [-2.387575012077093, 40.539237157468847],
            [-2.391508117006026, 40.531063329109344],
            [-2.397858215584108, 40.528690281731087],
            [-2.432080628218415, 40.535622655361266],
            [-2.488001040515599, 40.513023777825111],
            [-2.494384097448119, 40.513595066758967],
            [-2.500481509913726, 40.516198826214918],
            [-2.527771550716523, 40.544093114305838],
            [-2.541372624846758, 40.540456639961945],
            [-2.545360662164825, 40.537248632318523],
            [-2.548777410549064, 40.526668797327147],
            [-2.54447076951044, 40.516396579938174],
            [-2.528694401725204, 40.498862399979799],
            [-2.529254704540989, 40.494149265376308],
            [-2.535055486421527, 40.476560153028629],
            [-2.534978581796082, 40.469792574767467],
            [-2.527507878485807, 40.461904390874906],
            [-2.508138980829301, 40.451137789177608],
            [-2.501909733147642, 40.443062837679832],
            [-2.500723209008868, 40.437327973407207],
            [-2.500975895121428, 40.430857026630491],
            [-2.503063297133394, 40.424924408634496],
            [-2.50738092429026, 40.420793549083015],
            [-2.512797184842213, 40.419716888823416],
            [-2.575298407795287, 40.447929780634865],
            [-2.601907294885706, 40.453357027305003],
            [-2.626879219800117, 40.453060396719934],
            [-2.679514719828433, 40.433230073109343],
            [-2.72291071825498, 40.397271819638718],
            [-2.769943190126128, 40.421309906526943],
            [-2.807692215023735, 40.406873871232222],
            [-2.815382645192926, 40.325388271951198],
            [-2.801012527506117, 40.266534510359349],
            [-2.816294510083566, 40.209878013759322],
            [-2.823743241157644, 40.204077232778076],
            [-2.866183428422516, 40.197924888822541],
            [-2.873851885456133, 40.192607505132202],
            [-2.885178790868508, 40.172744223167371],
            [-2.892319904340098, 40.16470223092324],
            [-2.901482502215629, 40.159318929624902],
            [-2.912765461356912, 40.15757210356972],
            [-2.947009847126708, 40.169755942382864],
            [-2.998129234076856, 40.209207847963853],
            [-3.019772301350599, 40.193079917204415],
            [-3.014279137072577, 40.155341879324254],
            [-3.063684656321868, 40.168382650638819],
            [-3.07275265091414, 40.15760081622463],
            [-3.082726202552237, 40.197624213086954],
            [-3.082183600193019, 40.2182172715099],
            [-3.083113776179829, 40.227674059210472],
            [-3.08652442086526, 40.239327093870259],
            [-3.095335251620554, 40.257207140497115],
            [-3.103758511446102, 40.269893703931274],
            [-3.115385707684197, 40.276094876577247],
            [-3.124842495384854, 40.274854641328602],
            [-3.150732387989279, 40.26023021155504],
            [-3.157863734823479, 40.254029038908982],
            [-3.163961553782684, 40.247672838430759],
            [-3.171092902415467, 40.242505195458392],
            [-3.178637661298978, 40.24227265146169],
            [-3.185924037764096, 40.249455675139245],
            [-3.188507860149571, 40.263692532184621],
            [-3.187939419368718, 40.274337877391105],
            [-3.185407273826598, 40.283820501714814],
            [-3.181970790719447, 40.292527981481214],
            [-3.180730557269356, 40.301571357132389],
            [-3.177965867730563, 40.312578437544829],
            [-3.171945563137228, 40.319709784379057],
            [-3.157682867670161, 40.330613512003936],
            [-3.153057827057069, 40.336246242969679],
            [-3.151197475982741, 40.341698105882898],
            [-3.150499843992606, 40.346555691392012],
            [-3.146339891372889, 40.355857449461752],
            [-3.143368496259029, 40.360172430813009],
            [-3.14207658506632, 40.364978339478796],
            [-3.142231614697238, 40.369887600032683],
            [-3.143471849046591, 40.379292710889928],
            [-3.142696701791323, 40.382600002787854],
            [-3.140216233991993, 40.38678579382929],
            [-3.138665941280181, 40.394743963862823],
            [-3.141947393857123, 40.405906073906209],
            [-3.157140265311028, 40.425388089188914],
            [-3.169232550441819, 40.433062039281708],
            [-3.180575526739176, 40.437867947048119],
            [-3.189541388024594, 40.439392402237587],
            [-3.196466030183103, 40.443526516435583],
            [-3.200238410074604, 40.450502835437533],
            [-3.198791470150184, 40.478821519897252],
            [-3.200858526799578, 40.498716946329978],
            [-3.200806850855429, 40.502644354952992],
            [-3.201530321267285, 40.507140204356944],
            [-3.204527553903489, 40.513031317741081],
            [-3.225404832267174, 40.528637600345007],
            [-3.233259650412521, 40.533391832167268],
            [-3.240235967615831, 40.538559475139664],
            [-3.248530036232182, 40.541530870253439],
            [-3.257521735040001, 40.542771103703416],
            [-3.263748745208403, 40.540910753528436],
            [-3.270595872101779, 40.538042711202138],
            [-3.276693691060984, 40.534632066516622],
            [-3.282558966023487, 40.533391832167268],
            [-3.289509446603631, 40.533133449748874],
            [-3.292997605654932, 40.535510566559367],
            [-3.294961310416141, 40.538817857558058],
            [-3.29315263528585, 40.543236191696877],
            [-3.290672165687852, 40.545897529347485],
            [-3.288811814613496, 40.549101467558671],
            [-3.28798499231354, 40.5537006697501],
            [-3.290052048962849, 40.561710517526365],
            [-3.293075120020802, 40.56853180599802],
            [-3.306950242759456, 40.578453680792762],
            [-3.31578691193647, 40.582639471834085],
            [-3.321471319745655, 40.589357408417641],
            [-3.325967170048813, 40.597780667343926],
            [-3.324132655597509, 40.624936631820134],
            [-3.318164027847615, 40.646253159755474],
            [-3.324649421333675, 40.64832021730416],
            [-3.329842901828414, 40.645736395818062],
            [-3.337025926405317, 40.643255927119355],
            [-3.344932419595523, 40.644392809580495],
            [-3.35547441201453, 40.649715481284431],
            [-3.366765713267, 40.658913886566609],
            [-3.380744187893981, 40.67818919537504],
            [-3.389193285241873, 40.686405747826996],
            [-3.396350469598502, 40.686405747826996],
            [-3.399606085552392, 40.68128978079875],
            [-3.404644538214882, 40.677310696231672],
            [-3.418829718416703, 40.678395900950022],
            [-3.434306809811432, 40.675476183579036],
            [-3.442109951113338, 40.67818919537504],
            [-3.448750373331023, 40.683046779984892],
            [-3.455054896965919, 40.68898957021247],
            [-3.458801439334962, 40.69495819796245],
            [-3.455209926596808, 40.703769028717659],
            [-3.44311764236528, 40.716869005100534],
            [-3.439655320836351, 40.723199368056413],
            [-3.439422776839649, 40.730873318149122],
            [-3.442962611835014, 40.737901313095108],
            [-3.444021979031106, 40.751414699728542],
            [-3.446373257419907, 40.761439928210137],
            [-3.452884486629756, 40.772343654935753],
            [-3.459912481575742, 40.778803209100829],
            [-3.48874793087225, 40.785521144785008],
            [-3.495879278605827, 40.789035143157378],
            [-3.498437261670233, 40.792549139731022],
            [-3.498437261670233, 40.796218166834933],
            [-3.494716558622258, 40.7995771355763],
            [-3.491383429201875, 40.803711248875004],
            [-3.485208095876914, 40.812909654157181],
            [-3.480040452005255, 40.823167425736102],
            [-3.478154263408555, 40.829084378441308],
            [-3.472366501912546, 40.858152370835228],
            [-3.469782681325626, 40.863009955445051],
            [-3.466113654221715, 40.86665314412727],
            [-3.461462775186931, 40.869521186453653],
            [-3.457173631357932, 40.871097316687781],
            [-3.451489224448125, 40.875128078997676],
            [-3.44738094687321, 40.880450750701584],
            [-3.447742682079081, 40.890734360702112],
            [-3.452651942633054, 40.89910594278507],
            [-3.451644253179666, 40.907038276195436],
            [-3.448156094128478, 40.914014594298123],
            [-3.444874639752783, 40.923497219521039],
            [-3.440042893564652, 40.930835272829569],
            [-3.436373867360118, 40.938431708556521],
            [-3.427924770911403, 40.961324368524743],
            [-3.423532274295013, 40.968559068146533],
            [-3.420147467131926, 40.976207179817521],
            [-3.412266812363441, 40.989177964991086],
            [-3.409450445981292, 40.99847972306074],
            [-3.409786342765528, 41.012199815269241],
            [-3.424669154957513, 41.043231513323605],
            [-3.436451381725874, 41.077415472745855],
            [-3.445753139795528, 41.080386867859715],
            [-3.453272061156696, 41.081988837414855],
            [-3.476655645741431, 41.090825507491161],
            [-3.484045375893402, 41.095011298532569],
            [-3.493166265910446, 41.102168483788489],
            [-3.503863287960371, 41.1156818713213],
            [-3.541612921698942, 41.14420726225535],
            [-3.545772874318658, 41.161751410299274],
            [-3.527918667012898, 41.160046087956573],
            [-3.519624600195101, 41.162216498292821],
            [-3.442109951113338, 41.206529038983945],
            [-3.41921729204438, 41.210714830025381],
            [-3.411310797954911, 41.213608709874109],
            [-3.408752813991157, 41.221799424803606],
            [-3.406194830926665, 41.237173163410745],
            [-3.406530727710845, 41.244046128725842],
            [-3.411104092379929, 41.250609036577771],
            [-3.416400925662145, 41.256396796275141],
            [-3.419759894403626, 41.261202704041551],
            [-3.416891852077327, 41.26409658478957],
            [-3.389710049179371, 41.255569973075808],
            [-3.339764776623127, 41.253554592370563],
            [-3.291085577737249, 41.260685940104054],
            [-3.279871791749827, 41.266008612707338],
            [-3.249175991378877, 41.266628730331604],
            [-3.219823778144871, 41.293035386873555],
            [-3.198791470150184, 41.301200263381418],
            [-3.083811408169964, 41.280762233690098],
            [-3.048903978335886, 41.280917263321015],
            [-2.982267217558018, 41.287247626276894],
            [-2.959994676113439, 41.291433417318331],
            [-2.944000819881921, 41.299081528989319],
            [-2.940926072879932, 41.305205187269593],
            [-2.93570675306421, 41.310915431701829],
            [-2.929815639679987, 41.31628978114847],
            [-2.921133999234712, 41.319648748990545],
            [-2.909713506772846, 41.322129217689252],
            [-2.897156133648679, 41.321405748176687],
            [-2.879637824026361, 41.31458445880574],
            [-2.87426347457972, 41.307530626337297],
            [-2.871472948417761, 41.299934189711081],
            [-2.869767625175768, 41.292725328511096],
            [-2.863850674269202, 41.278746852984852],
            [-2.855763312127181, 41.272700710869088],
            [-2.844084438146297, 41.267920641524398],
            [-2.805895554835985, 41.259936632169854],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5823",
        OBJECTID_1: 7257,
        diss_me: 5823,
        adm1_cod_1: "ESP-5823",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Gipuzkoa",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.PV",
        note: null,
        hasc_maybe: null,
        region: "País Vasco",
        region_cod: "ES.PV",
        provnum_ne: 18,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "PV",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP94",
        fips_alt: "SP59",
        woe_id: 12602135,
        woe_label: "Basque Country, ES, Spain",
        woe_name: "País Vasco",
        latitude: 43.149500000000003,
        longitude: -2.2242999999999999,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3120935,
        gn_name: "Provincia de Guipuzcoa",
        gns_id: -384888,
        gns_name: "Gipuzkoa",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.SS",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP59",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.794074744889741, 43.386015332151658],
            [-1.796448933999955, 43.374382020000013],
            [-1.797172403999951, 43.353323873000079],
            [-1.796914021999982, 43.34112823500007],
            [-1.768233601999981, 43.330922140000098],
            [-1.755779581999889, 43.318545634000145],
            [-1.752420612999884, 43.299089458000054],
            [-1.749729985875064, 43.294792691214838],
            [-1.749939338030032, 43.294747830547919],
            [-1.773452113823964, 43.289709377885458],
            [-1.781927048694314, 43.284464220547306],
            [-1.791306322029101, 43.2774103871796],
            [-1.805543179074448, 43.256998195909972],
            [-1.821071947312504, 43.238601386244994],
            [-1.833680996380934, 43.229506333750322],
            [-1.847401088589322, 43.223615221265447],
            [-1.857271287439914, 43.222865912431899],
            [-1.864118415232667, 43.225656440392441],
            [-1.872696701991202, 43.227697659519322],
            [-1.878096889859535, 43.224648749140471],
            [-1.880008917777303, 43.219481106168104],
            [-1.87980221220235, 43.214132595143184],
            [-1.883703783302934, 43.211962184807021],
            [-1.88925899900363, 43.211626288022757],
            [-1.902410651330456, 43.215760403120129],
            [-1.90853430781209, 43.218344225505604],
            [-1.910988938988339, 43.213512478418096],
            [-1.918662889081077, 43.19224762642682],
            [-1.919283005806051, 43.18454783791239],
            [-1.917577684362612, 43.169174099305252],
            [-1.916079066695602, 43.162120265937546],
            [-1.912410041390331, 43.155712389515173],
            [-1.911092291775844, 43.146281440236237],
            [-1.912487554856739, 43.135145169513947],
            [-1.926724411902086, 43.114371243038448],
            [-1.937912360367079, 43.10558624890632],
            [-1.950753953432184, 43.098093166866136],
            [-1.964008957647309, 43.093287258200434],
            [-2.014600186249623, 43.066958116024267],
            [-2.0285786599772, 43.049026394352637],
            [-2.036562669331659, 43.036443182806664],
            [-2.040567593219805, 43.027554836786237],
            [-2.04180782666981, 43.019544989909349],
            [-2.039482387602106, 43.012258613444232],
            [-2.03958574128896, 43.004300442511408],
            [-2.042660488290835, 42.994456082082536],
            [-2.049404263296111, 42.988229071914134],
            [-2.057284918963802, 42.983836575297659],
            [-2.08521602979593, 42.979650784256222],
            [-2.094750331862258, 42.975464993214814],
            [-2.113586391998439, 42.956163845085371],
            [-2.122758957959519, 42.949626777453801],
            [-2.132913376750935, 42.946939602280764],
            [-2.143791265954121, 42.945880235084758],
            [-2.154488288004046, 42.945957750349891],
            [-2.174099493596742, 42.944045722432122],
            [-2.182884486829607, 42.945906074405826],
            [-2.2008162094005, 42.951642158159046],
            [-2.214148728880673, 42.949626777453801],
            [-2.225440029233965, 42.941410224102498],
            [-2.231692877824059, 42.935157376411752],
            [-2.235801153600363, 42.928697822246704],
            [-2.237506475943064, 42.922961738493456],
            [-2.252957728915987, 42.900069078525149],
            [-2.281194392507189, 42.900990176755187],
            [-2.290126277405733, 42.905977970606841],
            [-2.308407528684484, 42.928598820379278],
            [-2.354703917152051, 42.950099065419522],
            [-2.50312921564074, 42.960623968921055],
            [-2.529188786932991, 42.969138373237314],
            [-2.535187321637608, 42.973379095768678],
            [-2.537780095874695, 42.972796820716667],
            [-2.549392644854606, 42.964590033103548],
            [-2.554819891524801, 42.962821233912791],
            [-2.559016668684308, 42.96283222093021],
            [-2.602819160675693, 42.982354926038994],
            [-2.595623115714204, 43.003767280335666],
            [-2.588921455061097, 43.010117378913918],
            [-2.541306707238675, 43.025761910583697],
            [-2.533660222441256, 43.034452096387099],
            [-2.532232000106717, 43.04726215562394],
            [-2.546547185404222, 43.088043406677826],
            [-2.514005680317837, 43.093228954252822],
            [-2.501722965542427, 43.103226513293009],
            [-2.498262271786416, 43.118684277357488],
            [-2.507051334451546, 43.161530958187114],
            [-2.506732730730903, 43.16457417136084],
            [-2.504326724998293, 43.167507521554882],
            [-2.501777897032355, 43.16782612527544],
            [-2.498954410717488, 43.167474562301237],
            [-2.495713443820307, 43.168397414209295],
            [-2.488945865559231, 43.183657424550518],
            [-2.490527896245425, 43.222351273592352],
            [-2.476959780469429, 43.227591751757899],
            [-2.468093814078259, 43.224768265443117],
            [-2.464688051812175, 43.224768265443117],
            [-2.459359682003708, 43.227306107290985],
            [-2.418193909621266, 43.26753804254659],
            [-2.440166566733723, 43.288126422696223],
            [-2.406783742288411, 43.329305821587923],
            [-2.36742102799991, 43.315700588000112],
            [-2.327056443999936, 43.306830145000049],
            [-2.284657355999883, 43.30349355700011],
            [-2.241118943999936, 43.307237046000068],
            [-2.207875128999945, 43.313991604000108],
            [-2.196278449999909, 43.314764716000127],
            [-2.19041907499988, 43.311590887000051],
            [-2.183461066999882, 43.304510809000078],
            [-2.174468553999873, 43.297430731000134],
            [-2.162220831999889, 43.294256903000075],
            [-2.1595759759999, 43.295355536000017],
            [-2.156320766999954, 43.297674872000073],
            [-2.151519334999875, 43.299994208000044],
            [-2.144846157999922, 43.30109284100007],
            [-2.140044725999928, 43.298895575000103],
            [-2.137806769999941, 43.294216213000084],
            [-2.134836391999926, 43.289577541000057],
            [-2.127797003999973, 43.287420966000042],
            [-2.098866339999859, 43.304144598000079],
            [-2.08641516799986, 43.307237046000068],
            [-2.065541144999884, 43.309597072000116],
            [-1.994618292999888, 43.32843659100007],
            [-1.971791144999884, 43.339219468000067],
            [-1.960357225999928, 43.343085028000075],
            [-1.945627407999922, 43.342637437000064],
            [-1.933705206999889, 43.335638739000075],
            [-1.926584438999924, 43.325873114000046],
            [-1.918690558999856, 43.32099030200007],
            [-1.904652472999913, 43.32843659100007],
            [-1.910227016999926, 43.331366278000075],
            [-1.918853318999908, 43.338568427000013],
            [-1.925689256999931, 43.342637437000064],
            [-1.88491777299987, 43.356024481000077],
            [-1.847401495999861, 43.380926825000103],
            [-1.811268683999913, 43.395086981000134],
            [-1.794074744889741, 43.386015332151658],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5824",
        OBJECTID_1: 7271,
        diss_me: 5824,
        adm1_cod_1: "ESP-5824",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Huelva",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602100,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 37.621699999999997,
        longitude: -6.9024799999999997,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2516547,
        gn_name: "Provincia de Huelva",
        gns_id: -385564,
        gns_name: "Huelva, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.H",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.701523402982673, 38.092946479096753],
            [-6.677442186407831, 38.096021226998033],
            [-6.644627650745491, 38.09374746387428],
            [-6.6303649561778, 38.090853583126375],
            [-6.617342495060058, 38.086771144872415],
            [-6.60336402043319, 38.077521064545465],
            [-6.584398769987274, 38.056824653335042],
            [-6.581479050817563, 38.050003363964009],
            [-6.579411994168169, 38.04258779628978],
            [-6.580083786837292, 38.035068874928697],
            [-6.577603319037962, 38.028505967976059],
            [-6.571712204754448, 38.02385508894119],
            [-6.523911505911229, 38.021297104977407],
            [-6.499236009234437, 38.014940904499099],
            [-6.484792445714731, 38.016387844423434],
            [-6.481226772297674, 38.019359239537295],
            [-6.478978847595727, 38.025017808924673],
            [-6.473682013414162, 38.029591172694495],
            [-6.458747524378765, 38.039693915541761],
            [-6.453450690197172, 38.045662543291769],
            [-6.449574958417571, 38.051398627044989],
            [-6.442676153781406, 38.055351874089723],
            [-6.434304571698647, 38.057212226063371],
            [-6.423969284854593, 38.056798814014059],
            [-6.413065558128977, 38.053698229489726],
            [-6.372422043642416, 38.031167303827999],
            [-6.365006475968102, 38.021193753089221],
            [-6.361285772920127, 38.013261420578118],
            [-6.359425421845685, 38.006414292785365],
            [-6.356789924415438, 38.000729884976181],
            [-6.356273158679357, 38.000006415463702],
            [-6.330874193389292, 37.994606228494661],
            [-6.200287847905855, 37.951973171724518],
            [-6.183260463799343, 37.938976549028524],
            [-6.167036825389147, 37.90898925435053],
            [-6.158181845116019, 37.911790768429228],
            [-6.135571980562446, 37.910494381310684],
            [-6.127068562364229, 37.907220455159859],
            [-6.146712118369521, 37.848476556547894],
            [-6.124409871418436, 37.804663078438324],
            [-6.147052694326391, 37.793665763763954],
            [-6.185032432200956, 37.803410636691581],
            [-6.202874229761846, 37.799103996552134],
            [-6.21525582139904, 37.783316641749565],
            [-6.21415718890313, 37.743776845425003],
            [-6.216431358520396, 37.73417479473099],
            [-6.221990441305763, 37.729384755502082],
            [-6.228494347336238, 37.728879384176224],
            [-6.241831750135134, 37.73080199171855],
            [-6.247544641272157, 37.733988027125775],
            [-6.251950159172509, 37.739624013637325],
            [-6.253587122247836, 37.751313467242738],
            [-6.255553675161934, 37.756905508382488],
            [-6.266221399997619, 37.764255361695575],
            [-6.311067592994988, 37.764848623765005],
            [-6.448935029627876, 37.734405507707976],
            [-6.468963106961809, 37.724693593134788],
            [-6.503328342728821, 37.677529284249005],
            [-6.522488499644538, 37.663961168473122],
            [-6.527728978709462, 37.658039537494687],
            [-6.531376439171282, 37.631716294871069],
            [-6.505811253086847, 37.621663804341125],
            [-6.443189180136585, 37.623443588750575],
            [-6.394135222962177, 37.604228500345016],
            [-6.401342254941142, 37.575411360345981],
            [-6.375260710513402, 37.546242658272007],
            [-6.356034635989687, 37.496145000091644],
            [-6.315187466428426, 37.438225076526095],
            [-6.313550503353014, 37.428886697163307],
            [-6.317659389769034, 37.420284402103476],
            [-6.327634976572909, 37.414648415591955],
            [-6.350266813362708, 37.411594215400697],
            [-6.359594206607312, 37.40676023169928],
            [-6.364153532859234, 37.398168922757606],
            [-6.357287076837054, 37.371406226416156],
            [-6.359737028840811, 37.359782690418797],
            [-6.374458708602475, 37.347499974744039],
            [-6.380369353462868, 37.339446996381753],
            [-6.378831267249183, 37.328087132615025],
            [-6.34614693992927, 37.303851292003571],
            [-6.339236539434694, 37.289602023414574],
            [-6.340895474746389, 37.275671359445624],
            [-6.353244108029145, 37.247392549126801],
            [-6.355287565568688, 37.235571260305363],
            [-6.344114469407259, 37.176728483932379],
            [-6.354035123821916, 37.146010709526593],
            [-6.352881559836078, 37.114139370235762],
            [-6.355770963758971, 37.107086147507658],
            [-6.37542550588239, 37.084256556994518],
            [-6.380149626604009, 37.073446009925448],
            [-6.381621795209696, 37.061503870207446],
            [-6.379951872880582, 37.048067590546822],
            [-6.354320768288858, 36.985170860147065],
            [-6.378336882940744, 36.934040487078789],
            [-6.339066520795427, 36.904695773284146],
            [-6.34471594999988, 36.901271877000056],
            [-6.352447068999879, 36.880275783000116],
            [-6.346669074999909, 36.8198916690001],
            [-6.352447068999879, 36.80857982],
            [-6.388010219999956, 36.8081729190001],
            [-6.41624915299991, 36.837795315000051],
            [-6.448638475999928, 36.904201565000079],
            [-6.471099412999934, 36.935614325000103],
            [-6.499582485999923, 36.959662177000041],
            [-6.744862433999884, 37.10138580900005],
            [-6.771473761999914, 37.112779039000117],
            [-6.794667120999947, 37.117743231000048],
            [-6.811756964999887, 37.12372467700007],
            [-6.853016730999855, 37.151109117000118],
            [-6.873280402999853, 37.158677476000094],
            [-6.892893032999922, 37.166571356000048],
            [-6.910878058999856, 37.188950914000088],
            [-6.917632615999878, 37.215073960000041],
            [-6.910755988999881, 37.227606512000136],
            [-6.89875240799995, 37.23578522300005],
            [-6.864125128999973, 37.277533270000077],
            [-6.852691209999904, 37.29523346600007],
            [-6.903472459999875, 37.249579169000043],
            [-6.931141730999855, 37.233872789000088],
            [-6.962554490999878, 37.233832098000079],
            [-6.962554490999878, 37.227606512000136],
            [-6.940744594999927, 37.20066966400006],
            [-6.930083787999905, 37.1831729190001],
            [-6.928456183999884, 37.172349351000037],
            [-6.941639777999939, 37.171087958000015],
            [-7.029367641999926, 37.205511786000045],
            [-7.061675584999932, 37.213934637000108],
            [-7.101040416999865, 37.224250751000028],
            [-7.127349412999934, 37.214748440000108],
            [-7.098988410999908, 37.211900132000068],
            [-7.085560675999915, 37.206488348000022],
            [-7.327870245999861, 37.201564846000053],
            [-7.343088344999927, 37.196234442000105],
            [-7.362904425999943, 37.181708075000046],
            [-7.381418423999946, 37.179917710000069],
            [-7.398508266999897, 37.185451565000079],
            [-7.414418097999913, 37.192816473000079],
            [-7.429386758999954, 37.236746318000087],
            [-7.434244343999893, 37.259328919000012],
            [-7.427578083999947, 37.274521790000051],
            [-7.437448282999952, 37.307336324000062],
            [-7.444993041999908, 37.386763001000133],
            [-7.462252970999884, 37.417872213000095],
            [-7.460030883999877, 37.42913767500005],
            [-7.462924763999865, 37.451125997000105],
            [-7.4688675539999, 37.475568950000053],
            [-7.475947224999913, 37.494249980000035],
            [-7.481476603999909, 37.500373638000099],
            [-7.498633178999881, 37.511664938000081],
            [-7.506332967999867, 37.518124492000112],
            [-7.514613427833666, 37.528333923136074],
            [-7.515428018999927, 37.529338277000079],
            [-7.51754675299992, 37.53517771500006],
            [-7.518425252999862, 37.541947327000088],
            [-7.52367040999988, 37.555667419000116],
            [-7.527597819999926, 37.555512391000093],
            [-7.52912227399986, 37.556700948000099],
            [-7.529690714999902, 37.567165426000145],
            [-7.526486775999928, 37.571196188000044],
            [-7.515841430999956, 37.580937195000104],
            [-7.514652872999932, 37.585872294000126],
            [-7.512534138999854, 37.590368144000053],
            [-7.511758992999944, 37.595277405000076],
            [-7.514342813999946, 37.601401062000065],
            [-7.504886026999969, 37.606387838000103],
            [-7.478479369999889, 37.628867086000099],
            [-7.471606404999932, 37.636902771000109],
            [-7.466335407999907, 37.651966452000096],
            [-7.457550414999901, 37.700077210000075],
            [-7.444011189999941, 37.730152893000138],
            [-7.426337849999925, 37.750616761000089],
            [-7.334405476999848, 37.81193084700007],
            [-7.306861937999969, 37.850765686000102],
            [-7.295803181999872, 37.884872131000066],
            [-7.273892374999917, 37.931535950000068],
            [-7.268621378999882, 37.949235128000055],
            [-7.269086465999891, 37.959441224000045],
            [-7.272135375999937, 37.968949687000105],
            [-7.272703816999893, 37.977011210000114],
            [-7.265830850999919, 37.982954001000024],
            [-7.234049844999987, 37.988069967000115],
            [-7.223301146999859, 37.993831889000091],
            [-7.21033036299994, 37.993030904000051],
            [-7.197617960999878, 37.989930319000024],
            [-7.185267293999914, 37.988690084000069],
            [-7.172864949999877, 37.993831889000091],
            [-7.159739135999899, 37.996803284000094],
            [-7.152246052999942, 38.00065317800005],
            [-7.141910765999853, 38.011582743000105],
            [-7.131988891999953, 38.032382507000051],
            [-7.12325557399987, 38.040004781000022],
            [-7.105943969999885, 38.038661194000071],
            [-7.049358275999907, 38.020212708000145],
            [-7.023985148999913, 38.02256398500009],
            [-7.015768594999884, 38.046826071000083],
            [-7.006363484999923, 38.059331767000089],
            [-6.997630167999944, 38.08961415600001],
            [-6.978974975999961, 38.119948222000062],
            [-6.965487426999914, 38.153331197000114],
            [-6.957632609999877, 38.168162333000055],
            [-6.947504027999912, 38.196636047000084],
            [-6.950575759931667, 38.198385887999194],
            [-6.950526291754926, 38.198418077432279],
            [-6.933808966010929, 38.209295965736175],
            [-6.797899949722876, 38.172941595977875],
            [-6.792680629907068, 38.165319321829202],
            [-6.792370570645346, 38.158317166204284],
            [-6.795548672233451, 38.151444199989811],
            [-6.800793829571603, 38.145578925027309],
            [-6.804927943769599, 38.13927440049315],
            [-6.811904262771463, 38.125761012960339],
            [-6.813144497120817, 38.118810533279486],
            [-6.810353970059651, 38.113022773582117],
            [-6.801904872711674, 38.1086819520105],
            [-6.788675706019006, 38.106149807367643],
            [-6.776376716212496, 38.100465400457836],
            [-6.74883317720932, 38.093644111086888],
            [-6.701523402982673, 38.092946479096753],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5825",
        OBJECTID_1: 7258,
        diss_me: 5825,
        adm1_cod_1: "ESP-5825",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Huesca",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AR",
        note: null,
        hasc_maybe: null,
        region: "Aragón",
        region_cod: "ES.AR",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AR",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP81",
        fips_alt: "SP52",
        woe_id: 12602105,
        woe_label: "Aragon, ES, Spain",
        woe_name: "Aragón",
        latitude: 42.186300000000003,
        longitude: -0.012808099999999999,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3120513,
        gn_name: "Provincia de Huesca",
        gns_id: -385646,
        gns_name: "Huesca",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.HU",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP52",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.720287231999862, 42.876608785000059],
            [-0.710520385999899, 42.877668153000059],
            [-0.700391804999953, 42.876427918000019],
            [-0.666078653999932, 42.860744120000049],
            [-0.648301961999891, 42.849788717000081],
            [-0.609492960999944, 42.811444804000047],
            [-0.60804602099995, 42.807181498],
            [-0.609958048999971, 42.802840679000013],
            [-0.610164753999925, 42.799094137000111],
            [-0.603550170999938, 42.796846212000077],
            [-0.587323770999916, 42.796846212000077],
            [-0.582931274999936, 42.795838521000121],
            [-0.577143513999943, 42.789792379000062],
            [-0.578538777999938, 42.783306987000117],
            [-0.578693806999894, 42.777338359000083],
            [-0.56903031399986, 42.772635804000117],
            [-0.561175495999919, 42.773540141000069],
            [-0.544432332999861, 42.78201507600005],
            [-0.535647338999894, 42.784211324000069],
            [-0.533890339999914, 42.808008322000035],
            [-0.508413858999887, 42.807491557000077],
            [-0.454463662999927, 42.788913880000052],
            [-0.423922892999911, 42.7906708780001],
            [-0.399738321999962, 42.8013162230001],
            [-0.351679239999896, 42.829247335000105],
            [-0.342997599999933, 42.822736105000018],
            [-0.335168619999905, 42.824958191000121],
            [-0.328243977999932, 42.832580465],
            [-0.322792113999952, 42.842502340000095],
            [-0.307418375999873, 42.831831157000096],
            [-0.305138881039341, 42.830811870253967],
            [-0.305134937999952, 42.830810107000076],
            [-0.247034463999881, 42.80483022100006],
            [-0.235148885999934, 42.786226705000104],
            [-0.208354654999937, 42.783875427000055],
            [-0.179390014999882, 42.789740702000074],
            [-0.161406615999908, 42.795347596000084],
            [-0.163628702999915, 42.777984314000065],
            [-0.157789265999924, 42.760698547000061],
            [-0.145722818999928, 42.745893250000051],
            [-0.129238036999965, 42.735893860000033],
            [-0.12311437999989, 42.730364482000113],
            [-0.121822468999937, 42.723310649000013],
            [-0.119031941999879, 42.716851095000067],
            [-0.108489949999921, 42.713362936000095],
            [-0.095751708999956, 42.71067576100009],
            [-0.088852905999886, 42.706128235000051],
            [-0.084202026999918, 42.701089783000072],
            [-0.078414265999953, 42.696671448000075],
            [-0.038933471999911, 42.685147604000036],
            [-0.004310262999923, 42.687524720000084],
            [0.072842651000144, 42.708479513000071],
            [0.134182576000114, 42.715223288000118],
            [0.152941121000111, 42.724602560000051],
            [0.169167521000105, 42.726462911000056],
            [0.222394246000079, 42.716205140000085],
            [0.238362264000017, 42.710365703000093],
            [0.259807983000144, 42.682382914000101],
            [0.27538842800007, 42.668688660000072],
            [0.290245402000039, 42.670290629000107],
            [0.318305704000039, 42.699539490000063],
            [0.336030721000071, 42.71269114200004],
            [0.354169148000096, 42.717497050000134],
            [0.381816040000103, 42.700107931000105],
            [0.384012288000093, 42.696852315000115],
            [0.383676391000108, 42.692692363000077],
            [0.384399862000095, 42.688584086000077],
            [0.389283285000118, 42.685147604000036],
            [0.409282064000053, 42.682253723000144],
            [0.428789917000074, 42.683028870000072],
            [0.466927121364535, 42.69044443685442],
            [0.483515259000029, 42.685302633000077],
            [0.608158813000102, 42.687989808000111],
            [0.64360884600012, 42.684475810000066],
            [0.656734660000097, 42.687989808000111],
            [0.663425813975067, 42.696214350905848],
            [0.675649041270191, 42.688686632282213],
            [0.694562615772156, 42.662357490106046],
            [0.708411899189826, 42.634193834377953],
            [0.716318394178586, 42.625718899507632],
            [0.725465121718059, 42.620292874116842],
            [0.733991733431822, 42.617269802159626],
            [0.754662306220439, 42.614453437576032],
            [0.76551435700199, 42.609905911328752],
            [0.768925001687506, 42.605590929078119],
            [0.769545119311772, 42.601275946827514],
            [0.765669385733531, 42.594919745449914],
            [0.764429152283554, 42.589571235324286],
            [0.762723829940825, 42.575386054222975],
            [0.761483594692208, 42.569314072786227],
            [0.759778273248685, 42.564663193751358],
            [0.757297803650772, 42.560554917975054],
            [0.752336867152735, 42.554870510165898],
            [0.746859164918533, 42.54660228087053],
            [0.742208285883748, 42.537713934850274],
            [0.737557406848879, 42.533993231802214],
            [0.734146763062625, 42.528231310526536],
            [0.730581088746277, 42.519730536335146],
            [0.733216587075901, 42.50877513276609],
            [0.730581088746277, 42.50314240180046],
            [0.730271030383761, 42.500713609045789],
            [0.726395297704869, 42.498594876452415],
            [0.722519565025891, 42.497251288416209],
            [0.718333773984483, 42.496682848534562],
            [0.710892367888448, 42.49637278927284],
            [0.704381137779308, 42.492755439012257],
            [0.701435581087253, 42.484332180086085],
            [0.703605991423331, 42.477045802721591],
            [0.708566928820744, 42.468984279900553],
            [0.710892367888448, 42.461387844173714],
            [0.711512484613536, 42.450794175810557],
            [0.715078158929856, 42.44314606413954],
            [0.718953891608834, 42.439063625885609],
            [0.722519565025891, 42.436738185918529],
            [0.726860385698217, 42.433327542132389],
            [0.732906527814009, 42.426867987967228],
            [0.738332554104147, 42.414620673205491],
            [0.746394076925071, 42.390539455731357],
            [0.75109663190409, 42.34795807580457],
            [0.751251662434299, 42.328682766096733],
            [0.749856397554737, 42.319251816817882],
            [0.744378696219826, 42.306100165390347],
            [0.745618930569265, 42.301681830352152],
            [0.745618930569265, 42.288633530812803],
            [0.743448521132365, 42.270650133197051],
            [0.741743197890287, 42.264009710979337],
            [0.733216587075901, 42.245225327686683],
            [0.725775180979781, 42.235535996888729],
            [0.721744418669999, 42.231479397056489],
            [0.71941897960221, 42.226776842077555],
            [0.711667515143716, 42.195357571294863],
            [0.708256869558909, 42.188071193930483],
            [0.705466343397035, 42.17835602471078],
            [0.703450961792498, 42.16789154755682],
            [0.705931431390411, 42.162232978169385],
            [0.708876987183174, 42.152052720956334],
            [0.706241488853664, 42.141665758168216],
            [0.703140903429954, 42.120323390911722],
            [0.699110142019435, 42.10936798824207],
            [0.693942498147777, 42.100376288534846],
            [0.684485711346497, 42.095027778409218],
            [0.684330681715579, 42.089989325746757],
            [0.668517694436076, 42.04916494410682],
            [0.654875115694097, 42.026995755449718],
            [0.654875115694097, 42.02048452444123],
            [0.655960321311824, 42.0127847359268],
            [0.651619499740121, 42.001881008301922],
            [0.646813591973711, 41.996248277336178],
            [0.642472772200733, 41.992269191869724],
            [0.631258986213311, 41.989116930501865],
            [0.624437696842278, 41.984776108930248],
            [0.619321729814033, 41.979505113170489],
            [0.612190382080541, 41.968678900810545],
            [0.607539503944935, 41.964829007452664],
            [0.601183301668073, 41.962038479492236],
            [0.592811719585228, 41.960772406721162],
            [0.583975050408213, 41.956767482832987],
            [0.576171909106392, 41.951263943076441],
            [0.570125766990543, 41.944907741698955],
            [0.566095004680761, 41.938887438004798],
            [0.56718021029846, 41.932737942202266],
            [0.58066775940955, 41.923100287348376],
            [0.589556105429892, 41.918578600422052],
            [0.593276807578604, 41.913721014912852],
            [0.595292189183226, 41.907416490378694],
            [0.597617629150307, 41.874395250040749],
            [0.593586866840411, 41.867548123147373],
            [0.587075635831923, 41.861967068125693],
            [0.58066775940955, 41.855533352382338],
            [0.572606234789873, 41.852484442902693],
            [0.558033480960347, 41.845172228015855],
            [0.549661898877503, 41.834035956394274],
            [0.542220492781468, 41.82674957902978],
            [0.533538853235456, 41.821840318475893],
            [0.514728630621818, 41.8183263210029],
            [0.50666710780078, 41.816000881935082],
            [0.50093102404756, 41.812435208518025],
            [0.496590203375149, 41.808817857358264],
            [0.483412712626603, 41.791971340405013],
            [0.473180780368693, 41.775899969807739],
            [0.464602491811604, 41.767425034937418],
            [0.454215529023401, 41.762464098439352],
            [0.420264112698675, 41.757761542561155],
            [0.41282270660264, 41.756004544274276],
            [0.405949741287543, 41.752955633895368],
            [0.402074008608651, 41.749389960478311],
            [0.39602786559351, 41.742310288688884],
            [0.393857456156638, 41.736858424876374],
            [0.38657107879223, 41.732750149100099],
            [0.379749790320574, 41.726704006984335],
            [0.366417270840401, 41.709960841919269],
            [0.342646111728698, 41.693476061071351],
            [0.328538445892548, 41.677404690474077],
            [0.328228386630713, 41.669472357962974],
            [0.329468620980066, 41.661746731026739],
            [0.343266229352963, 41.63720042645852],
            [0.348072137119374, 41.615160427211976],
            [0.351482781804918, 41.607228094700758],
            [0.356598747933873, 41.601621202156736],
            [0.365177035591756, 41.598339749579793],
            [0.395252720136909, 41.595445867932398],
            [0.405949741287543, 41.596376043919321],
            [0.415768263294694, 41.595420030410054],
            [0.42398481574665, 41.592913723289655],
            [0.429720900399246, 41.5846971708377],
            [0.429410841137411, 41.576997382323384],
            [0.430341017124221, 41.56934926975299],
            [0.432976514554468, 41.561804510869479],
            [0.437627393589338, 41.55487986871097],
            [0.441193067905772, 41.546146552321517],
            [0.438247511213717, 41.539712836578161],
            [0.433131545084763, 41.533589179197264],
            [0.413546177014496, 41.51617422146316],
            [0.40843020998625, 41.510024726559806],
            [0.404554478206649, 41.504211127541453],
            [0.402539096602027, 41.500412910127693],
            [0.402074008608651, 41.498940130882289],
            [0.400368687165127, 41.496537177448829],
            [0.397423129573781, 41.494289251847505],
            [0.390446812370499, 41.491679591939601],
            [0.361301303812155, 41.484599921049551],
            [0.35117272344246, 41.47759776542452],
            [0.348537225112921, 41.472094224768625],
            [0.349777458562897, 41.45250885669833],
            [0.348537225112921, 41.433233547889984],
            [0.338305291056372, 41.405896715361138],
            [0.240651406637085, 41.428481576063888],
            [0.219030312498916, 41.403443733541508],
            [0.182687537699735, 41.394522834761034],
            [0.163791052115357, 41.406530892087005],
            [0.129118199645205, 41.38765637963823],
            [0.106134799881147, 41.350929082611117],
            [0.019112091849593, 41.360311407345591],
            [0.012234649709285, 41.363563360360814],
            [-0.000037078947969, 41.37545056858886],
            [-0.014725800355336, 41.383404670089448],
            [-0.033094941478367, 41.38277844966575],
            [-0.06991012834979, 41.372890753605333],
            [-0.103264621465087, 41.4754151719726],
            [-0.117107395589841, 41.497365855949482],
            [-0.152351537893196, 41.528226452588683],
            [-0.167095190790405, 41.569018690660016],
            [-0.17490647005755, 41.581982558248512],
            [-0.185508277285123, 41.591035293144245],
            [-0.197362526259553, 41.595440811044597],
            [-0.209766091032265, 41.594572890626409],
            [-0.239231423691223, 41.576764052319163],
            [-0.245900125090657, 41.575478652218038],
            [-0.258303689863368, 41.578181288535632],
            [-0.313773663223913, 41.622752824083534],
            [-0.344194807044715, 41.631179337656306],
            [-0.35095139918775, 41.637210832513915],
            [-0.37333054986496, 41.678343646542004],
            [-0.380669417959268, 41.684682758102838],
            [-0.488774890448838, 41.735549458940397],
            [-0.497684803111184, 41.744481343838942],
            [-0.503079090527564, 41.754907369579456],
            [-0.524897938389103, 41.839831689480889],
            [-0.537433339277158, 41.855706934127653],
            [-0.553715078388137, 41.867605127574592],
            [-0.607119621972942, 41.888149562352424],
            [-0.652064691832038, 41.94581680070479],
            [-0.667994867968758, 41.955298002300992],
            [-0.778209715605868, 41.957308500586805],
            [-0.853685793164857, 41.996705474677839],
            [-0.864056887415359, 42.011438141457091],
            [-0.866067385701257, 42.027159577752286],
            [-0.856399416499869, 42.059679109703126],
            [-0.84811572426122, 42.072719881917038],
            [-0.842227052536401, 42.077553866517718],
            [-0.835140870554767, 42.081036532509955],
            [-0.827439454267392, 42.082552645588152],
            [-0.819199707400543, 42.082102206651513],
            [-0.811289551271756, 42.079828036134984],
            [-0.804620849872322, 42.075905917324178],
            [-0.789207031179529, 42.058569491089145],
            [-0.781714354733651, 42.056580965039473],
            [-0.767520018533986, 42.061250155170541],
            [-0.755786619556773, 42.072269442081023],
            [-0.747865476410567, 42.086540682906218],
            [-0.745107907703755, 42.100943759846672],
            [-0.757390623378541, 42.258454752484823],
            [-0.749568357093949, 42.273308268361831],
            [-0.734429196750028, 42.290710613104295],
            [-0.731550778945319, 42.29780778120417],
            [-0.731440915066145, 42.308574382901469],
            [-0.740570554587435, 42.340874188892656],
            [-0.740054197143422, 42.375294356149595],
            [-0.745470456796113, 42.385313888325271],
            [-0.750535154373893, 42.389730392343694],
            [-0.755391111210798, 42.391740890629592],
            [-0.780198241655455, 42.391850753609361],
            [-0.782549315898223, 42.389038253412622],
            [-0.782285643667507, 42.382523360364758],
            [-0.772705565209606, 42.354101728711782],
            [-0.772683592973493, 42.344950116954294],
            [-0.80408251929282, 42.283734294102345],
            [-0.808323242723446, 42.279196940986026],
            [-0.814167969076493, 42.275670328722725],
            [-0.822495606686914, 42.273846598941418],
            [-0.829043458089075, 42.275505534253028],
            [-0.842721436844812, 42.282141276398733],
            [-0.851620363389088, 42.283492595007203],
            [-0.861541017803688, 42.282569743099174],
            [-0.870791506422876, 42.279273844712151],
            [-0.877690920799324, 42.273506022085172],
            [-0.884315676826986, 42.255796060639653],
            [-0.892939945022476, 42.249841470407461],
            [-0.904750247725758, 42.249621744448007],
            [-0.915978276276405, 42.254148111446199],
            [-0.922855717517365, 42.262310953687546],
            [-0.922482182306851, 42.27163834693215],
            [-0.838909181013889, 42.368713545493733],
            [-0.834690430718751, 42.380677657447933],
            [-0.83497607518575, 42.387928633899293],
            [-0.842270997908287, 42.407484299161098],
            [-0.898191410205357, 42.43261003242705],
            [-0.901289554869038, 42.435982835439489],
            [-0.901003910402096, 42.439992844993753],
            [-0.889852786476894, 42.458416918506003],
            [-0.888380618770555, 42.46504167453358],
            [-0.888831057707108, 42.471831225031053],
            [-0.896763186971469, 42.494265308097482],
            [-0.904827152351174, 42.613104423929997],
            [-0.924668462079808, 42.646436944809096],
            [-0.924690434316119, 42.651446710896948],
            [-0.922163578586208, 42.655797296408082],
            [-0.916956058775099, 42.659543634631007],
            [-0.894521976607962, 42.664652277580473],
            [-0.884546389803972, 42.669101740852625],
            [-0.879822269082439, 42.679121272129009],
            [-0.88298633135409, 42.688833186702198],
            [-0.909199710997882, 42.706850765750204],
            [-0.911309086145422, 42.715266293204849],
            [-0.904357876215016, 42.742559313461555],
            [-0.896425543703913, 42.755013332898983],
            [-0.890224371957231, 42.758889065577847],
            [-0.877537807623753, 42.763746650187699],
            [-0.867021653626381, 42.76994782193438],
            [-0.856944749200807, 42.78689769077576],
            [-0.851001959872519, 42.816559964171034],
            [-0.852526414162696, 42.832269599562352],
            [-0.850381842248169, 42.840486152014279],
            [-0.846221889628453, 42.849477850822126],
            [-0.819195116361527, 42.876091213838407],
            [-0.815655279567522, 42.882137355954171],
            [-0.816352911557658, 42.888855292537727],
            [-0.813097297402322, 42.896296699533025],
            [-0.806224331187963, 42.904358222354062],
            [-0.77144609166379, 42.91836253540265],
            [-0.745246140696821, 42.918724269709259],
            [-0.745031962827426, 42.918727227289736],
            [-0.740906128999939, 42.91089609800008],
            [-0.741422891999889, 42.903893942000082],
            [-0.744781859999904, 42.899294739000112],
            [-0.745867065999903, 42.893920390000062],
            [-0.740286010999881, 42.884799499000081],
            [-0.730105753999908, 42.877513123000114],
            [-0.720287231999862, 42.876608785000059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5826",
        OBJECTID_1: 7274,
        diss_me: 5826,
        adm1_cod_1: "ESP-5826",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Jaén",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 4,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602101,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 37.982900000000001,
        longitude: -3.43703,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2516394,
        gn_name: "Provincia de Jaen",
        gns_id: -386069,
        gns_name: "Jaen, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.J",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.675205858363654, 38.500234280187868],
            [-2.656163091753228, 38.506332099147045],
            [-2.642804734750712, 38.509277655839128],
            [-2.626319953003417, 38.510827949450288],
            [-2.598750575578606, 38.509949449407543],
            [-2.582214117887247, 38.505246894428609],
            [-2.573842535804317, 38.502042955318132],
            [-2.571904670364205, 38.500544339449675],
            [-2.571387904628125, 38.499872544981969],
            [-2.571207038374155, 38.498735663420121],
            [-2.571129523108937, 38.49558340115297],
            [-2.574617682160323, 38.483594468809656],
            [-2.572266404670813, 38.469667670126825],
            [-2.570044318390586, 38.462717190445971],
            [-2.57066443511556, 38.448402818135548],
            [-2.573248256601772, 38.441271471301349],
            [-2.574023402957664, 38.434553533818502],
            [-2.571517095837237, 38.427628893458632],
            [-2.567408820060962, 38.422797146371238],
            [-2.557176886004413, 38.414451401810737],
            [-2.488731451894978, 38.398741767318711],
            [-2.487155320761474, 38.393083197931247],
            [-2.488188848636469, 38.378846339986609],
            [-2.487517055967345, 38.371094876427406],
            [-2.485553351206136, 38.363756822219585],
            [-2.481936000945666, 38.357013048113686],
            [-2.479197149828565, 38.341923530346662],
            [-2.480075648972019, 38.326291409321129],
            [-2.479352178560191, 38.317351386457347],
            [-2.47756934185162, 38.308127142753364],
            [-2.471109787686544, 38.298179430436363],
            [-2.4400522530091, 38.282754014985812],
            [-2.432481654804491, 38.272961331400353],
            [-2.430621303730248, 38.265623277192446],
            [-2.430259569423555, 38.258801987821471],
            [-2.431913214922929, 38.252497464186604],
            [-2.432869229331459, 38.244901028459651],
            [-2.434807094771571, 38.238854885444624],
            [-2.437287562570987, 38.233144640113011],
            [-2.441163296149256, 38.228467923555797],
            [-2.445607468709682, 38.225367337232825],
            [-2.448863083764309, 38.222266750909824],
            [-2.448449672614288, 38.217150783881607],
            [-2.444108852841225, 38.205136013116459],
            [-2.442946132857742, 38.196247667096202],
            [-2.444806483932069, 38.188186144275079],
            [-2.506482306413261, 38.125295924966991],
            [-2.515835741326327, 38.111911729542669],
            [-2.521933560285532, 38.105064601750001],
            [-2.536196254853223, 38.093075670305865],
            [-2.547384202419039, 38.080647488390895],
            [-2.602478584718938, 38.062028810581154],
            [-2.598347725167343, 38.005745850090904],
            [-2.670769602535103, 37.961481932145432],
            [-2.686480052712227, 37.971391600441962],
            [-2.691643627151649, 37.973182371869029],
            [-2.699641674024065, 37.972644042188819],
            [-2.786279860726978, 37.912449948106627],
            [-2.889650226376773, 37.774318839243023],
            [-2.900230060468857, 37.74824828183273],
            [-2.902064777267611, 37.665664050055909],
            [-2.976189536218129, 37.632727036623407],
            [-2.983385581179618, 37.601492904773721],
            [-3.031231041979026, 37.612929673165866],
            [-3.051336023039028, 37.612885727794065],
            [-3.070507166972163, 37.607414535752156],
            [-3.090798915637436, 37.596021712731897],
            [-3.187280853028938, 37.635869126658861],
            [-3.223403900969288, 37.639252915789456],
            [-3.251825532622263, 37.633034654225924],
            [-3.275501071317393, 37.61622557155286],
            [-3.297385837686193, 37.587781966764311],
            [-3.315106785249952, 37.577817366977854],
            [-3.399262061594811, 37.564106428968557],
            [-3.42402524666781, 37.568522932986951],
            [-3.468256205359552, 37.606337875492557],
            [-3.491492290336907, 37.616434311394357],
            [-3.507136822006686, 37.61334715284886],
            [-3.627162461078711, 37.529422589480902],
            [-3.683071887257825, 37.518117657204101],
            [-3.791880484796536, 37.466295146104045],
            [-3.841307977181401, 37.38633664691875],
            [-3.950786741414959, 37.378437476008656],
            [-4.000873413477251, 37.4021130147039],
            [-4.037501832743345, 37.456341532435829],
            [-4.086610721407681, 37.486542949397631],
            [-4.094597782161884, 37.498386211354529],
            [-4.11013245085195, 37.53781614469932],
            [-4.156000371719841, 37.577553694747138],
            [-4.182246710617193, 37.612545150937933],
            [-4.205241096499407, 37.619642319037808],
            [-4.213096322037643, 37.626267075964847],
            [-4.209009406958558, 37.640791002003141],
            [-4.178379523296343, 37.668641344722261],
            [-4.171249395942823, 37.679364001047873],
            [-4.171985480245667, 37.697150867118808],
            [-4.186223761817217, 37.70572020382437],
            [-4.219808967909501, 37.716069324939383],
            [-4.228224496263493, 37.733251942822932],
            [-4.23071839273959, 37.774560538338164],
            [-4.24393494554127, 37.787216788324059],
            [-4.268840952847683, 37.798994132673073],
            [-4.279168101726469, 37.808530265759202],
            [-4.283837290958189, 37.820483391595246],
            [-4.260996714326893, 37.96353637580313],
            [-4.284320689148444, 38.003691406433234],
            [-4.263754282134414, 38.019138184379756],
            [-4.259337778115992, 38.025609132055763],
            [-4.254591684258884, 38.052185060791913],
            [-4.25030701635572, 38.061105959572302],
            [-4.193046271568136, 38.127562260589883],
            [-4.168349005002597, 38.136834722344588],
            [-4.168008429045727, 38.180758063433899],
            [-4.198396613612829, 38.214760750108496],
            [-4.209998177373961, 38.234997567283898],
            [-4.215205696285892, 38.256563730831516],
            [-4.214557503625883, 38.289270030387542],
            [-4.241199349970032, 38.337576917141007],
            [-4.243528451976516, 38.339301770959906],
            [-4.275495775009972, 38.350915229154481],
            [-4.274849818963901, 38.387993069324722],
            [-4.271800910383718, 38.394788520273977],
            [-4.264979621012685, 38.401428940693108],
            [-4.256065435671246, 38.40277252872923],
            [-4.235110642941805, 38.403392646353581],
            [-4.21552527487151, 38.401222236017418],
            [-4.177594773979592, 38.39057689081082],
            [-3.893736131317439, 38.381611030424693],
            [-3.859939743724254, 38.385951850197756],
            [-3.839682582984835, 38.392618109937786],
            [-3.829683192924961, 38.407785142969971],
            [-3.823094447550687, 38.415200711543662],
            [-3.807539841790287, 38.423107204733668],
            [-3.794155646365965, 38.426078599847472],
            [-3.778368495709543, 38.427344671719254],
            [-3.76705135693453, 38.426543686941557],
            [-3.757801275708289, 38.422797146371238],
            [-3.740980597176758, 38.418146267336368],
            [-3.732660691937326, 38.420239163306746],
            [-3.725581021047276, 38.422797146371238],
            [-3.717467821382826, 38.422099514381102],
            [-3.692172207081654, 38.413469549879778],
            [-3.671966722286385, 38.411557521962095],
            [-3.61985104029327, 38.401428940693108],
            [-3.60706112317223, 38.401687324010766],
            [-3.596028205237417, 38.403986925556126],
            [-3.588741827873037, 38.407681790182494],
            [-3.584866095194144, 38.413650417932473],
            [-3.580602789786951, 38.435897121854708],
            [-3.576752896428985, 38.443493557581576],
            [-3.566391771163296, 38.449126288547404],
            [-3.553601854042284, 38.450159817321691],
            [-3.543835007979169, 38.44881623018486],
            [-3.538073085804228, 38.443648587212579],
            [-3.534559089230584, 38.437395737723108],
            [-3.5331121484069, 38.43026439088888],
            [-3.530399135711519, 38.423391425573783],
            [-3.526368374301001, 38.417138576983774],
            [-3.519081996936592, 38.413340359569929],
            [-3.48606075659859, 38.406441554933764],
            [-3.469575974851296, 38.404477851071931],
            [-3.457328660089559, 38.404839586277888],
            [-3.44521053743631, 38.406493231777176],
            [-3.41906226241349, 38.415045681013368],
            [-3.395937059347773, 38.426440335053343],
            [-3.381390143040676, 38.438635972971809],
            [-3.37666174964005, 38.44475963035265],
            [-3.375628220865735, 38.451761785977652],
            [-3.373612840160405, 38.458893133711143],
            [-3.369556240328166, 38.466231187019588],
            [-3.361882290235542, 38.473259181965688],
            [-3.345785081216576, 38.479796251395811],
            [-3.3228149068824, 38.482405911303715],
            [-3.307415329853541, 38.481889146466926],
            [-3.295710618350313, 38.47868520825574],
            [-3.277933926309714, 38.471657213309754],
            [-3.259562954166967, 38.46674795185649],
            [-3.240287645358535, 38.463724880798537],
            [-3.170834519997072, 38.460960191259829],
            [-3.137348191665694, 38.452795314751938],
            [-3.123266364251322, 38.453803006003909],
            [-3.11352535571055, 38.457549547473576],
            [-3.098487514786967, 38.468582465408389],
            [-3.069703742333786, 38.482715968766882],
            [-3.060608689839199, 38.482612616878782],
            [-3.052960578168097, 38.478969428196592],
            [-3.046345994372132, 38.472923285181452],
            [-3.023194952884694, 38.446335761486154],
            [-3.009371506989453, 38.434450181930401],
            [-3.000870733697411, 38.430057685313926],
            [-2.997072516283566, 38.433054917950045],
            [-2.995134649944106, 38.438532620184247],
            [-2.992731695611241, 38.451968492451982],
            [-2.986814743805382, 38.457213649790134],
            [-2.966557583065992, 38.468608302930818],
            [-2.950796270831262, 38.474473577893235],
            [-2.938135544919419, 38.475946357138639],
            [-2.923459438302416, 38.476333929866968],
            [-2.913124152357739, 38.474654445945873],
            [-2.902737188670358, 38.471915594828857],
            [-2.89503740105522, 38.46690298148738],
            [-2.883849452590198, 38.46155447046246],
            [-2.878991867980289, 38.460598456053958],
            [-2.875503708928989, 38.461942044090051],
            [-2.873333298592826, 38.464034939161081],
            [-2.870646125218514, 38.465714423082176],
            [-2.865168422984311, 38.467833157474189],
            [-2.86335974785402, 38.46904755340185],
            [-2.861602748667877, 38.470907905375469],
            [-2.859639044806045, 38.473465888440046],
            [-2.85090572841662, 38.479951280127437],
            [-2.844885422923909, 38.483439439178738],
            [-2.816592575986533, 38.495841783571478],
            [-2.811269904282625, 38.497547105014888],
            [-2.807368333182012, 38.499924220926033],
            [-2.806903245188579, 38.500492661706971],
            [-2.790857713013025, 38.507055569558901],
            [-2.770083787436818, 38.520517280248271],
            [-2.767680833103952, 38.52421214487461],
            [-2.756027798444165, 38.533126329316673],
            [-2.718536546224612, 38.514290270079869],
            [-2.675205858363654, 38.500234280187868],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5827",
        OBJECTID_1: 1364,
        diss_me: 5827,
        adm1_cod_1: "ESP-5827",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "La Coruña",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.GA",
        note: null,
        hasc_maybe: null,
        region: "Galicia",
        region_cod: "ES.GA",
        provnum_ne: 12,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "GA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP91",
        fips_alt: "SP58",
        woe_id: 12602130,
        woe_label: "Galicia, ES, Spain",
        woe_name: "Galicia",
        latitude: 43.102600000000002,
        longitude: -8.3973600000000008,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3119840,
        gn_name: "Provincia da Coruna",
        gns_id: -386793,
        gns_name: "Corunna",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.C",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP58",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.696156379106668, 43.731512761802378],
            [-7.709473407871457, 43.674175021360099],
            [-7.690950458396969, 43.653927218066627],
            [-7.723634785716769, 43.625802216998721],
            [-7.728919209254116, 43.615705780197544],
            [-7.725337666400179, 43.59757833816974],
            [-7.726590108146922, 43.586482146633728],
            [-7.729973897277517, 43.58150533979952],
            [-7.753792257306799, 43.563410857025445],
            [-7.758428488184137, 43.554753629576311],
            [-7.758340597440565, 43.545525114092698],
            [-7.746662129953279, 43.530583707472118],
            [-7.744805440918299, 43.526321011805265],
            [-7.746013937293355, 43.521300259599286],
            [-7.780170432319608, 43.449054162819323],
            [-7.807284690735884, 43.437397668467611],
            [-7.817205346049832, 43.415029503009094],
            [-7.824610130852847, 43.406592003318252],
            [-7.836134789988563, 43.401911827069114],
            [-7.882090602499289, 43.407525841344437],
            [-7.889308619697005, 43.34145406075632],
            [-7.896504665557899, 43.33010518400701],
            [-7.931660916218306, 43.299925739281434],
            [-7.936758573049701, 43.293202106392044],
            [-7.937769315701331, 43.285050250268768],
            [-7.934605252530389, 43.277129107122562],
            [-7.930408475370825, 43.271042681674402],
            [-7.927552029802314, 43.264494829372865],
            [-7.928397977084927, 43.255167437027637],
            [-7.961356962753626, 43.189952590739466],
            [-7.924684598115732, 43.092174266229392],
            [-7.92702468624023, 43.084143260103332],
            [-7.931726834725566, 43.076331980836272],
            [-7.935385282204948, 43.065158884674759],
            [-7.934846951625445, 43.05184345411206],
            [-7.9064143338544, 42.987639350475604],
            [-7.906601101459614, 42.961909369022152],
            [-7.911775662017163, 42.936728704266272],
            [-7.935626981300175, 42.903429141741441],
            [-7.944844510665632, 42.897309757039636],
            [-7.965982206613518, 42.889048037936561],
            [-7.97526565448635, 42.883049502332682],
            [-7.999809112700405, 42.848860048052728],
            [-8.032834015977102, 42.836456483280131],
            [-8.049214631949724, 42.834533875737804],
            [-8.174447790832915, 42.853012880739811],
            [-8.192190711532078, 42.847618593323404],
            [-8.204627235558405, 42.834292176642663],
            [-8.209944618349482, 42.830699646771279],
            [-8.220711220946043, 42.829040711459669],
            [-8.252066201893683, 42.836302674928561],
            [-8.262294473910856, 42.833764833080608],
            [-8.289661418439721, 42.820031921935623],
            [-8.298835002433407, 42.821635925757306],
            [-8.304361125965158, 42.828293641038698],
            [-8.308953411470782, 42.836786073118731],
            [-8.315314495267728, 42.843938172708476],
            [-8.321752484589467, 42.846113466363363],
            [-8.329322064761499, 42.845717958017275],
            [-8.335957806907288, 42.842674744843549],
            [-8.339616254386556, 42.836939881470386],
            [-8.339319623801572, 42.831666444051137],
            [-8.333848431759776, 42.812956726971322],
            [-8.33343095117749, 42.801421081717535],
            [-8.335913861535516, 42.791423522677434],
            [-8.342220013842535, 42.783985778620803],
            [-8.353327192395966, 42.780151549654306],
            [-8.392032027555956, 42.789489929017066],
            [-8.42164018334779, 42.756761656325352],
            [-8.445370652633471, 42.744292173045295],
            [-8.458290575749459, 42.742018003428058],
            [-8.472342089715625, 42.743248472039312],
            [-8.502334766836015, 42.756069517394309],
            [-8.578052542590768, 42.745874204630866],
            [-8.594125541860251, 42.73843645967483],
            [-8.599662650610867, 42.732086361995954],
            [-8.601651176660482, 42.726296567132863],
            [-8.602024711870968, 42.722165707581354],
            [-8.603474907341081, 42.719419124992612],
            [-8.608748344760357, 42.717804135052717],
            [-8.645431695516294, 42.725516537458219],
            [-8.716337459805942, 42.70645525740423],
            [-8.730946417821514, 42.688421942009569],
            [-8.737172003999888, 42.688421942000076],
            [-8.744048631999902, 42.672430731000077],
            [-8.753895636999914, 42.65688711100006],
            [-8.767323370999918, 42.645168361000088],
            [-8.784982876999919, 42.640570380000042],
            [-8.803822394999884, 42.645941473000107],
            [-8.815988735999952, 42.658514716000099],
            [-8.826242641999897, 42.67413971600007],
            [-8.839507615999906, 42.682440497000044],
            [-8.854603644999912, 42.661566473000079],
            [-8.857004360999952, 42.64911530200007],
            [-8.846994594999984, 42.640570380000042],
            [-8.868763800999943, 42.61749909100007],
            [-8.880523240999935, 42.611802476],
            [-8.894846157999922, 42.620103257000068],
            [-8.889475063999953, 42.630601304000066],
            [-8.903431769999912, 42.625474351000037],
            [-8.936390753999888, 42.606431382000039],
            [-8.922230597999885, 42.593695380000099],
            [-8.933990037999934, 42.586818752000084],
            [-8.969878709999932, 42.579169012000051],
            [-8.972767706999889, 42.573919989000075],
            [-8.980783657999922, 42.551214911000017],
            [-8.989165818999879, 42.548895575000074],
            [-8.997059699999852, 42.543402411000045],
            [-9.011545376999891, 42.530707098000079],
            [-9.02635657499988, 42.543117580000086],
            [-9.028553839999859, 42.561468817000048],
            [-9.03335527299987, 42.57811107],
            [-9.055816209999961, 42.585394598000022],
            [-9.070871548999975, 42.596136786000045],
            [-9.065744594999956, 42.620306708000044],
            [-9.051421678999873, 42.645941473000107],
            [-9.03880774599989, 42.661078192000105],
            [-9.039458787999934, 42.673041083000044],
            [-9.036284959999875, 42.687933661000073],
            [-9.030140753999888, 42.707709052000013],
            [-9.018788214999915, 42.719753322000116],
            [-9.014027472999913, 42.726833401000079],
            [-9.006255662999934, 42.73383209800005],
            [-8.994496222999942, 42.737005927000041],
            [-8.992014126999919, 42.739081122000044],
            [-8.96312415299991, 42.751288153000104],
            [-8.937367316999882, 42.777777411000102],
            [-8.922230597999885, 42.788112697000088],
            [-8.898264126999919, 42.792303778000132],
            [-8.891346808999913, 42.799343166000085],
            [-8.884917772999898, 42.815008856000077],
            [-8.88768469999988, 42.831122137000051],
            [-8.908436652999882, 42.83946360900012],
            [-8.912587042999917, 42.816595770000021],
            [-8.928089972999913, 42.802516994000058],
            [-8.951039191999911, 42.795111395000077],
            [-8.977365688999924, 42.792303778000132],
            [-9.028187628999945, 42.797878322000145],
            [-9.048939581999946, 42.794745184000078],
            [-9.045643683999913, 42.778021552000041],
            [-9.068674282999893, 42.759711005000071],
            [-9.081288214999859, 42.754828192000105],
            [-9.100249803999873, 42.757513739000075],
            [-9.113433397999927, 42.766058661000017],
            [-9.12010657499988, 42.778998114000018],
            [-9.125477667999945, 42.794989325000103],
            [-9.134999152999939, 42.812770901000079],
            [-9.122547980999911, 42.812608140000037],
            [-9.113189256999874, 42.816351630000071],
            [-9.106190558999913, 42.823309637000051],
            [-9.100249803999873, 42.833278713000055],
            [-9.10838782499988, 42.84483470300006],
            [-9.122222459999904, 42.851752020000077],
            [-9.141224738999881, 42.857448635000054],
            [-9.142323370999947, 42.865301825000046],
            [-9.147531704999921, 42.878607489000046],
            [-9.148671027999882, 42.884466864000103],
            [-9.146636522999927, 42.888373114000103],
            [-9.137521938999953, 42.8960635440001],
            [-9.134999152999939, 42.902167059000107],
            [-9.133697068999936, 42.911200262000108],
            [-9.134510870999861, 42.917547919000043],
            [-9.141428188999953, 42.921413479000051],
            [-9.158599412999877, 42.922634182000081],
            [-9.167876756999931, 42.926825262000108],
            [-9.183461066999911, 42.945257880000042],
            [-9.195871548999946, 42.949326890000094],
            [-9.195871548999946, 42.943141994000115],
            [-9.190012173999889, 42.93239980700011],
            [-9.19579016799986, 42.926581122000044],
            [-9.205189581999889, 42.924750067000076],
            [-9.210072394999941, 42.925726630000042],
            [-9.214344855999883, 42.939032294000071],
            [-9.224232550999886, 42.94021230700011],
            [-9.235585089999859, 42.934881903000132],
            [-9.244252081999946, 42.928778387000051],
            [-9.251698370999918, 42.920233466000127],
            [-9.261708136999914, 42.900173244000058],
            [-9.268422003999945, 42.891302802000013],
            [-9.273060675999886, 42.893337307000053],
            [-9.28294837099989, 42.916612046000068],
            [-9.291981574999909, 42.922634182000081],
            [-9.291981574999909, 42.928778387000051],
            [-9.285389777999939, 42.938340562000036],
            [-9.264759894999912, 42.980332749000098],
            [-9.263986782999893, 42.986517645000077],
            [-9.261830206999889, 42.994492906000033],
            [-9.258656378999916, 43.002386786000017],
            [-9.254790818999908, 43.008002020000021],
            [-9.250965949999909, 43.016791083000072],
            [-9.257313605999911, 43.023260809000078],
            [-9.272124803999901, 43.031276760000111],
            [-9.279530402999882, 43.054999091000042],
            [-9.271107550999943, 43.060492255000071],
            [-9.257923956999889, 43.060899156000062],
            [-9.251088019999969, 43.069769598000022],
            [-9.245716925999915, 43.087998765000094],
            [-9.233957485999923, 43.09650299700013],
            [-9.222320115999935, 43.102728583000101],
            [-9.217600063999924, 43.114447333000072],
            [-9.202951626999919, 43.105047919000043],
            [-9.189808722999942, 43.102321682000081],
            [-9.176828579999949, 43.105576890000037],
            [-9.162261522999927, 43.114447333000072],
            [-9.156890428999873, 43.119737046000068],
            [-9.154204881999902, 43.124701239000018],
            [-9.150298631999902, 43.12958405200007],
            [-9.141224738999881, 43.134873765000066],
            [-9.13312740799995, 43.137966213000141],
            [-9.125803188999953, 43.139593817000048],
            [-9.10773678299995, 43.141140041000085],
            [-9.10773678299995, 43.148504950000103],
            [-9.124663865999935, 43.142523505000071],
            [-9.147084113999938, 43.138698635000054],
            [-9.163319464999887, 43.139756578000032],
            [-9.162261522999927, 43.148504950000103],
            [-9.194203253999916, 43.136542059000078],
            [-9.208363410999937, 43.137884833000044],
            [-9.217600063999924, 43.155340887000108],
            [-9.170074022999927, 43.188747463000084],
            [-9.137928839999859, 43.201524156000033],
            [-9.100412563999924, 43.189520575000103],
            [-9.083159959999932, 43.189764716000042],
            [-9.065174933999884, 43.192328192000048],
            [-9.052398240999935, 43.196356512000108],
            [-9.039784308999884, 43.204535223000022],
            [-9.026966925999943, 43.210150458000044],
            [-9.016753709999904, 43.223863023000064],
            [-9.011545376999891, 43.238755601],
            [-9.004058397999927, 43.238755601],
            [-8.985747850999871, 43.22687409100007],
            [-8.958973761999886, 43.227484442000019],
            [-8.932525193999879, 43.235296942000019],
            [-8.915272589999887, 43.245021877000056],
            [-8.936390753999888, 43.244086005000071],
            [-8.948150193999879, 43.249945380000014],
            [-8.96312415299991, 43.272284247000044],
            [-8.967518683999884, 43.271958726000037],
            [-8.979807094999956, 43.276922919000071],
            [-8.988026495999861, 43.283026434000078],
            [-8.980783657999922, 43.285956122000073],
            [-8.970855272999927, 43.28656647300005],
            [-8.930490688999953, 43.299750067000105],
            [-8.888010219999899, 43.320746161000045],
            [-8.856597459999875, 43.326890367000033],
            [-8.849110480999883, 43.334051825000046],
            [-8.841175910999937, 43.338934637000108],
            [-8.829986131999931, 43.341213283000087],
            [-8.81700598899991, 43.335842190000051],
            [-8.785552537999905, 43.312445380000071],
            [-8.768544074999852, 43.307074286000102],
            [-8.750477667999917, 43.304917710000012],
            [-8.711740688999896, 43.29555898600006],
            [-8.69310462099989, 43.293402411000073],
            [-8.665394660999908, 43.301174221000053],
            [-8.630034959999904, 43.315863348000079],
            [-8.592518683999884, 43.324204820000034],
            [-8.558420376999948, 43.31321849200009],
            [-8.505970831999917, 43.331529039000145],
            [-8.497547980999855, 43.337795315000079],
            [-8.494252081999917, 43.346625067000048],
            [-8.486073370999918, 43.354315497000073],
            [-8.475982225999928, 43.359686591000127],
            [-8.45571855399993, 43.363348700000074],
            [-8.451405402999939, 43.367377020000049],
            [-8.44896399599989, 43.371975002000084],
            [-8.443023240999878, 43.375311591000099],
            [-8.388295050999943, 43.385891018000052],
            [-8.384673631999874, 43.372707424000112],
            [-8.375721808999856, 43.362982489000075],
            [-8.353586391999954, 43.348618882000096],
            [-8.340809699999937, 43.359930731000077],
            [-8.337269660999937, 43.369045315000051],
            [-8.339914516999926, 43.392726955000086],
            [-8.330718553999873, 43.405666408000087],
            [-8.310617641999926, 43.403143622000073],
            [-8.281564907999922, 43.389593817000105],
            [-8.267201300999943, 43.385931708000044],
            [-8.260812954999949, 43.377020575000103],
            [-8.257069464999915, 43.365668036000045],
            [-8.250559048999918, 43.35488515800003],
            [-8.227528449999852, 43.338120835000012],
            [-8.217274542999917, 43.328314520000077],
            [-8.210194464999859, 43.31321849200009],
            [-8.202463344999927, 43.329250393000081],
            [-8.202951626999919, 43.344671942000105],
            [-8.210194464999859, 43.375311591000099],
            [-8.209624803999901, 43.390692450000046],
            [-8.20482337099989, 43.399725653000047],
            [-8.19395911399991, 43.406805731000105],
            [-8.175404425999915, 43.416327216000099],
            [-8.195057745999861, 43.423244533000116],
            [-8.213286912999934, 43.424953518000024],
            [-8.247141079999892, 43.423081773000064],
            [-8.264759894999912, 43.427313544000071],
            [-8.288726365999878, 43.446234442000105],
            [-8.305165167999888, 43.451076565000079],
            [-8.286488410999908, 43.461981512000051],
            [-8.185617641999926, 43.471502997000044],
            [-8.178781704999921, 43.473293361000032],
            [-8.171457485999923, 43.47797272300005],
            [-8.166981574999852, 43.484564520000021],
            [-8.168568488999881, 43.492010809000107],
            [-8.173451300999943, 43.492743231000134],
            [-8.196522589999915, 43.485174872000073],
            [-8.257313605999855, 43.477728583000015],
            [-8.25365149599989, 43.481268622000073],
            [-8.250884568999936, 43.482855536000017],
            [-8.243723110999895, 43.485174872000073],
            [-8.247547980999911, 43.487738348000079],
            [-8.257313605999855, 43.492010809000107],
            [-8.269195115999906, 43.478949286000017],
            [-8.288970506999931, 43.472967841000099],
            [-8.333119269999912, 43.471502997000044],
            [-8.325347459999904, 43.486761786000017],
            [-8.321685350999957, 43.507879950000131],
            [-8.31663977799991, 43.52289459800005],
            [-8.305165167999888, 43.519354559000078],
            [-8.292103644999912, 43.532945054000038],
            [-8.300160285999937, 43.538234768000109],
            [-8.308216925999972, 43.546332098000022],
            [-8.313221808999913, 43.556341864000018],
            [-8.312652147999927, 43.567124742000033],
            [-8.308705206999946, 43.567124742000033],
            [-8.291330532999979, 43.564846096000053],
            [-8.284657355999911, 43.567124742000033],
            [-8.260975714999915, 43.564520575000103],
            [-8.232248501999948, 43.575506903000075],
            [-8.086659308999856, 43.656480210000041],
            [-8.08299719999988, 43.645453192000076],
            [-8.074615037999934, 43.639634507000039],
            [-8.063547329999892, 43.638861395000021],
            [-8.051869269999884, 43.642808335000012],
            [-8.062408006999959, 43.64232005400001],
            [-8.065541144999941, 43.642808335000012],
            [-8.065541144999941, 43.64907461100006],
            [-8.061268683999913, 43.650946356000105],
            [-8.056467251999919, 43.654242255000071],
            [-8.051869269999884, 43.656480210000041],
            [-8.071156378999916, 43.667873440000108],
            [-8.07396399599989, 43.678534247000044],
            [-8.069162563999896, 43.68927643400005],
            [-8.065541144999941, 43.700873114000075],
            [-8.059315558999884, 43.709418036000073],
            [-8.044504360999895, 43.712103583000072],
            [-8.010975714999859, 43.711737372000044],
            [-7.981190558999856, 43.717840887000136],
            [-7.952951626999891, 43.730129299000083],
            [-7.928130662999934, 43.746771552000041],
            [-7.908558722999942, 43.766343492000033],
            [-7.880848761999886, 43.763251044000128],
            [-7.869048631999902, 43.748846747000073],
            [-7.86148027299987, 43.731187242000061],
            [-7.846506313999924, 43.717962958000015],
            [-7.846506313999924, 43.711737372000044],
            [-7.852284308999884, 43.714178778000075],
            [-7.86229407499988, 43.716253973000107],
            [-7.867583787999934, 43.717962958000015],
            [-7.874623175999915, 43.709418036000073],
            [-7.879261847999942, 43.700506903000047],
            [-7.879790818999908, 43.691961981000134],
            [-7.873768683999913, 43.684475002000056],
            [-7.884103969999927, 43.678615627000028],
            [-7.887318488999881, 43.679673570000062],
            [-7.894886847999913, 43.684475002000056],
            [-7.901234503999945, 43.679754950000046],
            [-7.903879360999952, 43.678290106000077],
            [-7.908558722999942, 43.676988023],
            [-7.908558722999942, 43.670152085000069],
            [-7.85838782499988, 43.668198960000041],
            [-7.849232550999886, 43.675604559000107],
            [-7.867583787999934, 43.697455145000049],
            [-7.856027798999918, 43.70343659100007],
            [-7.843861456999917, 43.703802802000098],
            [-7.819162563999953, 43.697455145000049],
            [-7.821400519999941, 43.7007510440001],
            [-7.823963995999947, 43.708075262000108],
            [-7.826039191999939, 43.711737372000044],
            [-7.812001105999911, 43.723089911000017],
            [-7.793853318999908, 43.734116929000066],
            [-7.773793097999942, 43.742580471000025],
            [-7.734038865999935, 43.750067450000074],
            [-7.712757941999882, 43.760687567000019],
            [-7.695708787999905, 43.774888414000117],
            [-7.688832160999908, 43.789984442000048],
            [-7.685536261999886, 43.793443101000037],
            [-7.678334113999938, 43.790472723000022],
            [-7.671294725999871, 43.782863674000083],
            [-7.668324347999942, 43.772528387000079],
            [-7.672596808999884, 43.766669012000136],
            [-7.695627407999922, 43.74591705900005],
            [-7.696156379106668, 43.731512761802378],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5828",
        OBJECTID_1: 1366,
        diss_me: 5828,
        adm1_cod_1: "ESP-5828",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "La Rioja",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.LO",
        note: null,
        hasc_maybe: null,
        region: "La Rioja",
        region_cod: "ES.LO",
        provnum_ne: 9,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "LO",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP27",
        woe_id: 12578023,
        woe_label: "La Rioja, ES, Spain",
        woe_name: "La Rioja",
        latitude: 42.317900000000002,
        longitude: -2.4033699999999998,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355232,
        gn_name: "Provincia de La Rioja",
        gns_id: 6198365,
        gns_name: "La Rioja, Comunidad Autonoma de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.LO",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP27",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.829925096366082, 42.613807481530046],
            [-2.816230840780662, 42.620215358851709],
            [-2.812665168262868, 42.611094468834665],
            [-2.814783900856327, 42.605875149018942],
            [-2.826772834098961, 42.596004951067641],
            [-2.82682451004311, 42.59019135204926],
            [-2.821036750345712, 42.58427440024343],
            [-2.813001064147755, 42.580812078714388],
            [-2.805973070101118, 42.582362372325548],
            [-2.793493211342621, 42.586909898572941],
            [-2.779979823809754, 42.585617988279552],
            [-2.773391079334857, 42.601560166768252],
            [-2.769670376286797, 42.608820705711025],
            [-2.761841395663879, 42.614505113520096],
            [-2.747397833942841, 42.618690904561618],
            [-2.733987799197536, 42.615719509447842],
            [-2.699442103670151, 42.597710273410343],
            [-2.689132656147194, 42.589493720059153],
            [-2.685644497095893, 42.581793932443986],
            [-2.685179409102432, 42.574714259755268],
            [-2.687246465751741, 42.567918809705333],
            [-2.687737393066186, 42.553423570241563],
            [-2.684662645164934, 42.537765610794338],
            [-2.676756151075438, 42.536887112550232],
            [-2.667454393005869, 42.533166409502257],
            [-2.632081875178358, 42.51252167423587],
            [-2.61787085565544, 42.507715766469374],
            [-2.61138546396802, 42.513761909484515],
            [-2.606166144152212, 42.516862494008933],
            [-2.595055710952266, 42.517508450054919],
            [-2.58513383615761, 42.511979071876652],
            [-2.583454352236515, 42.496682848534562],
            [-2.571336228684004, 42.505622870499053],
            [-2.557745326785437, 42.510842190314776],
            [-2.541648117766442, 42.512366645504244],
            [-2.522036912173746, 42.510351263899707],
            [-2.524181484088189, 42.513219306226006],
            [-2.52531836564998, 42.514304510944356],
            [-2.526506924055269, 42.515131334143774],
            [-2.528832363123058, 42.51717255327074],
            [-2.524026455356648, 42.52510488488258],
            [-2.518057826707292, 42.531047675110045],
            [-2.510616420611257, 42.535233466151567],
            [-2.501521369015933, 42.537662258006748],
            [-2.491651170165341, 42.520092271541102],
            [-2.462040575412203, 42.507250678475998],
            [-2.427133144678862, 42.499370021908959],
            [-2.40607499826254, 42.497096258785319],
            [-2.38447425038629, 42.494667466930025],
            [-2.343262295118791, 42.483040269792582],
            [-2.278382534426413, 42.489861559163614],
            [-2.26789221795147, 42.48691600247156],
            [-2.238720871870697, 42.471981513436049],
            [-2.230297613843817, 42.465676988002627],
            [-2.218231167134604, 42.453429674140182],
            [-2.206009690794616, 42.446401679194167],
            [-2.191385260121649, 42.442939358564502],
            [-2.1719549216823, 42.441440741796754],
            [-2.104956426597823, 42.445161444844814],
            [-2.076508550928907, 42.44257762335863],
            [-2.055269538258528, 42.428418281578388],
            [-2.0601529612901, 42.423560696069188],
            [-2.06480384032497, 42.417307848378442],
            [-2.069558071248053, 42.414775701937032],
            [-2.055605435042793, 42.399066067445006],
            [-2.032402715812651, 42.392968248485829],
            [-1.976747198824199, 42.393045762851671],
            [-1.901325445712729, 42.32537547509807],
            [-1.863472459186681, 42.304911606985058],
            [-1.86140540163791, 42.299433906549581],
            [-1.862154709572167, 42.291992499554169],
            [-1.861922165575493, 42.284189358252263],
            [-1.857271287439914, 42.277574775355561],
            [-1.85125098284658, 42.276179511375403],
            [-1.835799729873656, 42.278143215237122],
            [-1.829314338186151, 42.277574775355561],
            [-1.820012580116497, 42.272872219477364],
            [-1.795776333011361, 42.256490790517546],
            [-1.747407191588621, 42.236001084882162],
            [-1.726943325274249, 42.231479397056489],
            [-1.697074347203369, 42.215821438508527],
            [-1.697746140771784, 42.194530748095531],
            [-1.691648321812579, 42.187037665156083],
            [-1.688599412333019, 42.180758979043645],
            [-1.689116177169808, 42.176805731998883],
            [-1.690098029100767, 42.174402778565423],
            [-1.691777513021776, 42.171534736239039],
            [-1.692268439436958, 42.166780504416749],
            [-1.68999467631329, 42.156393540729283],
            [-1.685783046850077, 42.149029648999118],
            [-1.688186001182999, 42.14306102124911],
            [-1.694128791410577, 42.138461819057682],
            [-1.703172166162545, 42.136343085564931],
            [-1.713094040957202, 42.136136379989978],
            [-1.734668952210399, 42.144223741232622],
            [-1.744590827005027, 42.146626695565544],
            [-1.754073452228027, 42.146394151568785],
            [-1.774227261079261, 42.139960435825429],
            [-1.782728034371303, 42.139288642257014],
            [-1.79231401238178, 42.14112315490965],
            [-1.804302943825746, 42.145903225153717],
            [-1.814586553826359, 42.146936753928003],
            [-1.823423223902637, 42.145619005212893],
            [-1.829107631711821, 42.140787258125471],
            [-1.831975674038119, 42.134431056747871],
            [-1.835773892351227, 42.127868149795233],
            [-1.841820033567728, 42.122028714153799],
            [-1.856392788296517, 42.111538398578233],
            [-1.896054449952857, 42.076140042329087],
            [-1.899930181732458, 42.069370428902175],
            [-1.901480475343647, 42.06192902280614],
            [-1.902100592068621, 42.054384263922628],
            [-1.901325445712729, 42.045599269790472],
            [-1.897320521824554, 42.035599881529265],
            [-1.89060258614046, 42.025316271528737],
            [-1.852413702830063, 42.001209214733507],
            [-1.844533047162372, 41.982037257813147],
            [-1.848176234945299, 41.978187364455266],
            [-1.857736375433348, 41.954803778971268],
            [-1.896519537946318, 41.938784085217321],
            [-1.904710251976525, 41.93400401497334],
            [-1.922461107394184, 41.925735784778624],
            [-1.932512173398123, 41.92255768498913],
            [-1.943855149695366, 41.920464789018752],
            [-1.967884691225549, 41.918449409212855],
            [-1.977935757229403, 41.920542304283885],
            [-1.988219367230016, 41.92772532796144],
            [-1.998270433233955, 41.933513089457534],
            [-2.023359341060768, 41.941316229860092],
            [-2.040128342748801, 41.94203970027192],
            [-2.095215419855805, 41.952736721422582],
            [-2.105369838647135, 41.957077542094822],
            [-2.11064083530627, 41.963407905050786],
            [-2.116118536641181, 41.97810985008951],
            [-2.117203742258823, 41.984931139460542],
            [-2.115395067128617, 41.991390692726242],
            [-2.110485805675353, 41.995886542130194],
            [-2.106558397052339, 41.998832098822277],
            [-2.104594692291215, 42.00007233317163],
            [-2.104052089931997, 42.001312568420275],
            [-2.104181281141194, 42.003844713063017],
            [-2.106661749839816, 42.009219062509743],
            [-2.116635300578622, 42.022034817153042],
            [-2.14050981337715, 42.036865953401048],
            [-2.146194220287043, 42.043222153879356],
            [-2.149449836240933, 42.050766912762782],
            [-2.150535040959397, 42.058156642914668],
            [-2.1489847482475, 42.064771226710747],
            [-2.142886929288295, 42.069732164108046],
            [-2.135057948665406, 42.072936103218609],
            [-2.127797409722717, 42.077897039716561],
            [-2.123224045952981, 42.083684801212598],
            [-2.122681443593763, 42.090712796158584],
            [-2.127797409722717, 42.096655585486872],
            [-2.137874315047554, 42.101564846040787],
            [-2.153377243964599, 42.104562079576283],
            [-2.171799892950759, 42.105492255563092],
            [-2.189679937778948, 42.104071153161186],
            [-2.245516322820038, 42.089524237753295],
            [-2.256290859235719, 42.089420884965818],
            [-2.266135219664619, 42.095156968719124],
            [-2.272233038623796, 42.101926581246659],
            [-2.275669520831741, 42.109342148921058],
            [-2.276315476877727, 42.116654364707188],
            [-2.278537563157954, 42.123682358753911],
            [-2.281224738331019, 42.129935208243296],
            [-2.286650762822404, 42.134120999284704],
            [-2.296133388944781, 42.137790025489267],
            [-2.347448086160284, 42.142208360527349],
            [-2.410467494879015, 42.138565171845158],
            [-2.437442593101196, 42.131898912105129],
            [-2.449948289382036, 42.123113918872264],
            [-2.472634242876012, 42.111409207369036],
            [-2.48152258979573, 42.108231105780902],
            [-2.502451545002828, 42.111099148107201],
            [-2.50839433433103, 42.110349840172915],
            [-2.511572435019787, 42.105130520357221],
            [-2.512735155003355, 42.097947495780289],
            [-2.513613654146752, 42.082496242807395],
            [-2.515654873273718, 42.068801988121237],
            [-2.519737310628386, 42.063427639573888],
            [-2.524982468865801, 42.059965318045045],
            [-2.530408494256591, 42.056968085408812],
            [-2.536919725265079, 42.052575588792337],
            [-2.542113205759819, 42.046426092989805],
            [-2.546169805592029, 42.038571274844486],
            [-2.556815150798514, 42.022758287564869],
            [-2.567951423319471, 42.010097560753849],
            [-2.570354376753045, 42.004774889049941],
            [-2.572266404670813, 42.001700141148575],
            [-2.572938198239228, 42.000149848436678],
            [-2.59086991991083, 41.999529730812412],
            [-2.619550339576534, 42.002320257873578],
            [-2.655620490293359, 42.000692450795981],
            [-2.660426398059798, 41.999555569234133],
            [-2.664147101107858, 41.999555569234133],
            [-2.671846889622259, 42.000459906799222],
            [-2.690605435392484, 42.011596178420888],
            [-2.70132829406549, 42.014929307841271],
            [-2.713704800036481, 42.016789658915599],
            [-2.729026861800207, 42.016944688546516],
            [-2.743599615629734, 42.022525743568195],
            [-2.748483038661277, 42.028778591258941],
            [-2.747449509886906, 42.036039130201715],
            [-2.744348923563905, 42.0434288603536],
            [-2.740473191784304, 42.050715236818718],
            [-2.737786016611352, 42.057872422973929],
            [-2.734168667250231, 42.064073594720611],
            [-2.721094530188481, 42.073711249574501],
            [-2.715746019163475, 42.079912421321183],
            [-2.712335373578583, 42.093968411213211],
            [-2.712567917575342, 42.10125478857762],
            [-2.714195725552287, 42.10812775299334],
            [-2.717192959087782, 42.114199734430173],
            [-2.72520280596467, 42.117765407847344],
            [-2.735357224756086, 42.120116686236031],
            [-2.762228970190847, 42.118101305530786],
            [-2.773933681694075, 42.112468574565042],
            [-2.785896775615697, 42.101099758047411],
            [-2.789694993029514, 42.092702338442137],
            [-2.795043504054433, 42.084950873084381],
            [-2.79752397275314, 42.077793687828461],
            [-2.798557502426803, 42.070765692882361],
            [-2.796438768034704, 42.063686021093019],
            [-2.796283739303078, 42.056012071000197],
            [-2.801632249428792, 42.047304592133202],
            [-2.812846035416214, 42.03805451090696],
            [-2.840105352679842, 42.025497137782708],
            [-2.870930345159252, 42.014748439788633],
            [-2.910178594766393, 42.023430081133313],
            [-2.917620001761691, 42.057174790983794],
            [-2.921805792803127, 42.063298448364691],
            [-2.924234584658308, 42.070197252101508],
            [-2.928730434961551, 42.07701854147254],
            [-2.934156460352341, 42.082702949281725],
            [-2.94221798317335, 42.084873358718511],
            [-2.951313034768759, 42.085080064293493],
            [-2.960149704845065, 42.083323066006614],
            [-2.975032517936313, 42.082082830757997],
            [-2.99001868291586, 42.082392890019804],
            [-2.997718472329666, 42.081514390876436],
            [-3.01084428533548, 42.082134508500786],
            [-3.026321376730095, 42.087973945041483],
            [-3.036475796420802, 42.099549465335514],
            [-3.039860601785335, 42.106551621859893],
            [-3.044485643297776, 42.113269558443363],
            [-3.05523434129185, 42.125258490786706],
            [-3.070711431787174, 42.133190823297809],
            [-3.077765265154824, 42.137686672701761],
            [-3.084509040160071, 42.150915839394457],
            [-3.087144537590319, 42.158563951065446],
            [-3.120449997869088, 42.196287747281673],
            [-3.120966762705962, 42.201558743041545],
            [-3.10869361042171, 42.209465237131042],
            [-3.102208217834942, 42.217836819213801],
            [-3.09083940311595, 42.24907522194394],
            [-3.088772344667916, 42.26044403756228],
            [-3.08789384642381, 42.269952501206916],
            [-3.088565639992225, 42.277393907302951],
            [-3.090167608648272, 42.284757799033201],
            [-3.093474901445575, 42.291940822710757],
            [-3.096162074819887, 42.299795640856189],
            [-3.098099941159376, 42.307753810889636],
            [-3.097867397162588, 42.325117091780299],
            [-3.09957272040478, 42.332506821932299],
            [-3.105463832889541, 42.337648627382237],
            [-3.109417079934275, 42.34346222640059],
            [-3.107866787222463, 42.348965766157136],
            [-3.097815721218552, 42.352608953940063],
            [-3.078385381879798, 42.355502834687996],
            [-3.071951667035819, 42.359378567366974],
            [-3.056035326069377, 42.380281684152351],
            [-3.056087002013527, 42.38805898703265],
            [-3.058283249872034, 42.395345364397059],
            [-3.059290941124061, 42.40361359279305],
            [-3.058231573927969, 42.425860296715371],
            [-3.049601610326107, 42.446298326406691],
            [-3.049214035799139, 42.452344469421718],
            [-3.052082078125352, 42.459062405105925],
            [-3.05730139794116, 42.464953518490063],
            [-3.060066086580605, 42.471464749498551],
            [-3.065621304079855, 42.47689077399005],
            [-3.080478277850204, 42.484668076870236],
            [-3.083191291444962, 42.491463527819576],
            [-3.080555793115337, 42.499731757114915],
            [-3.073734503744305, 42.512082423764866],
            [-3.070711431787174, 42.520970771583762],
            [-3.070013799797039, 42.529135647192362],
            [-3.072390915708183, 42.535233466151567],
            [-3.077842780419957, 42.539755153977154],
            [-3.08580095135278, 42.540065213238961],
            [-3.094611782108075, 42.538954169199542],
            [-3.102595790563242, 42.536163642138376],
            [-3.110708991127069, 42.536809597285071],
            [-3.115153163687609, 42.540762844329834],
            [-3.113887090916535, 42.548410956000822],
            [-3.08365637943831, 42.573215643886812],
            [-3.079625617128528, 42.57941681653287],
            [-3.073501959747631, 42.585669664223587],
            [-3.06675818474244, 42.591457423920957],
            [-3.060531174574038, 42.599415594853781],
            [-3.061151292198332, 42.606521104165665],
            [-3.064484421618801, 42.613523260689959],
            [-3.075801561292991, 42.625667223563482],
            [-3.07210669576736, 42.632075099985826],
            [-3.056887986791139, 42.637294419801634],
            [-3.01415157723352, 42.640885932539703],
            [-2.948651698916706, 42.635149847887106],
            [-2.933381313097158, 42.630188910489778],
            [-2.906716274136727, 42.62626150186685],
            [-2.837314825618677, 42.644115709172695],
            [-2.837469855249594, 42.634452215896971],
            [-2.836022915325259, 42.621455593201063],
            [-2.829925096366082, 42.613807481530046],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5829",
        OBJECTID_1: 7277,
        diss_me: 5829,
        adm1_cod_1: "ESP-5829",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 3,
        name: "Las Palmas",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CN",
        note: null,
        hasc_maybe: null,
        region: "Canary Is.",
        region_cod: "ES.CN",
        provnum_ne: 0,
        gadm_level: 0,
        check_me: 0,
        scalerank: 10,
        datarank: 10,
        abbrev: null,
        postal: null,
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 20,
        featurecla: "Admin-1 scale rank",
        name_len: 10,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: null,
        fips_alt: null,
        woe_id: 12602108,
        woe_label: null,
        woe_name: "Palmas",
        latitude: 27.946300000000001,
        longitude: -15.6013,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 5,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2515271,
        gn_name: "Provincia de Las Palmas",
        gns_id: -388527,
        gns_name: "Las Palmas, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.GC",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP53",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-15.415638800999943, 28.141506252000127],
              [-15.414947068999908, 28.11448802299999],
              [-15.408558722999885, 28.074896552000141],
              [-15.406239386999914, 28.068060614000117],
              [-15.403920050999943, 28.056301174000154],
              [-15.399647589999859, 28.042669989],
              [-15.391590949999909, 28.03021881700009],
              [-15.383452928999901, 28.024318752000156],
              [-15.367583787999877, 28.01581452000012],
              [-15.363636847999885, 28.009711005000113],
              [-15.366281704999949, 27.99347565300009],
              [-15.374134894999941, 27.975490627000156],
              [-15.376698370999861, 27.957505601000108],
              [-15.363636847999885, 27.941473700000031],
              [-15.363636847999885, 27.934637762000094],
              [-15.374623175999915, 27.92987702],
              [-15.381906704999949, 27.921210028000118],
              [-15.391590949999909, 27.89984772300015],
              [-15.382720506999874, 27.885484117000161],
              [-15.375965949999909, 27.867580471000124],
              [-15.377837693999879, 27.852280992000161],
              [-15.394683397999898, 27.845892645000148],
              [-15.406971808999856, 27.838324286],
              [-15.43919837099989, 27.802679755000142],
              [-15.453033006999874, 27.791245835],
              [-15.523426886999886, 27.77167389500012],
              [-15.534901495999947, 27.76642487200003],
              [-15.539662238999938, 27.765611070000105],
              [-15.566029425999943, 27.739406643000095],
              [-15.577951626999891, 27.734361070000048],
              [-15.588246222999885, 27.738918361000103],
              [-15.597727016999897, 27.74628327],
              [-15.607248501999891, 27.750311591000141],
              [-15.650502081999889, 27.753119208000115],
              [-15.669545050999886, 27.757147528000175],
              [-15.685699022999927, 27.763332424000154],
              [-15.739654100999871, 27.804348049000154],
              [-15.747873501999948, 27.814520575000088],
              [-15.751861131999931, 27.821356512000122],
              [-15.77281653599988, 27.831976630000057],
              [-15.781971808999856, 27.838446356000148],
              [-15.786000128999916, 27.844224351000136],
              [-15.81505286399991, 27.901271877000127],
              [-15.821197068999879, 27.921779690000093],
              [-15.816070115999878, 27.941473700000031],
              [-15.820057745999861, 27.952866929],
              [-15.821766730999855, 27.968329169000114],
              [-15.820627407999922, 27.984076239000061],
              [-15.816070115999878, 27.99603913],
              [-15.806996222999885, 28.005031643],
              [-15.767689581999946, 28.023993231000034],
              [-15.749826626999891, 28.035956122000144],
              [-15.72520911399991, 28.056870835000112],
              [-15.708363410999908, 28.081935940000037],
              [-15.713693813999953, 28.105902411000059],
              [-15.702137824999852, 28.115057684000149],
              [-15.698109503999973, 28.13304271],
              [-15.700062628999916, 28.152899481000091],
              [-15.706206834999904, 28.16738515800013],
              [-15.69505774599989, 28.168198960000055],
              [-15.684396938999924, 28.167629299000097],
              [-15.674427863999938, 28.165269273000135],
              [-15.665272589999859, 28.160549221000124],
              [-15.650786912999905, 28.163885809000149],
              [-15.597035285999965, 28.147528387000037],
              [-15.521311001999891, 28.147528387000037],
              [-15.458729620999861, 28.134222723000036],
              [-15.435047980999855, 28.140936591000141],
              [-15.438710089999887, 28.174872137000094],
              [-15.409291144999912, 28.179754950000174],
              [-15.405262824999852, 28.177923895],
              [-15.399891730999883, 28.165025132],
              [-15.400135870999918, 28.157212632],
              [-15.408558722999885, 28.153753973],
              [-15.415638800999943, 28.141506252000127],
            ],
          ],
          [
            [
              [-14.244536912999877, 28.12921784100017],
              [-14.271392381999931, 28.096136786000116],
              [-14.301340298999918, 28.067857164000159],
              [-14.343169725999928, 28.05068594000015],
              [-14.369007941999939, 28.050767320000134],
              [-14.408314581999946, 28.066961981000176],
              [-14.428618943999965, 28.071193752000013],
              [-14.494862433999913, 28.067816473000093],
              [-14.507069464999915, 28.071193752000013],
              [-14.503407355999855, 28.074774481000176],
              [-14.499256964999915, 28.086574611000131],
              [-14.496449347999942, 28.09853750200007],
              [-14.496896938999953, 28.102525132000054],
              [-14.489735480999911, 28.10431549700003],
              [-14.455555792999917, 28.099107164000046],
              [-14.43602454299986, 28.100531317000033],
              [-14.398915167999917, 28.111232815000122],
              [-14.341175910999908, 28.134507554000052],
              [-14.270130988999938, 28.175970770000148],
              [-14.240142381999874, 28.201564846000124],
              [-14.224436001999919, 28.218573309000092],
              [-14.21353105399993, 28.234930731000119],
              [-14.207386847999942, 28.254380601000108],
              [-14.201893683999913, 28.303900458000143],
              [-14.193226691999939, 28.321844794000086],
              [-14.1712947259999, 28.35236237200003],
              [-14.146392381999874, 28.423325914000102],
              [-14.140858527999853, 28.434881903000033],
              [-14.127919074999852, 28.443589585000112],
              [-14.098296678999901, 28.486639716000141],
              [-14.07555091099988, 28.53611888200011],
              [-14.03612219999988, 28.587388414000046],
              [-14.024566209999875, 28.637111721000124],
              [-14.010121222999913, 28.667466539000102],
              [-14.005279100999928, 28.703599351000051],
              [-13.999908006999874, 28.716742255000028],
              [-13.989491339999887, 28.726874091000141],
              [-13.972645636999886, 28.735907294000171],
              [-13.935414191999911, 28.749497789000131],
              [-13.89867102799991, 28.753892320000105],
              [-13.864572719999899, 28.744208075000145],
              [-13.835519985999923, 28.715480861000103],
              [-13.824370897999898, 28.6817894550001],
              [-13.821156378999945, 28.636664130000142],
              [-13.828684048999889, 28.55158112200003],
              [-13.835194464999887, 28.530747789000046],
              [-13.8529353509999, 28.492743231000063],
              [-13.856556769999941, 28.478989976000136],
              [-13.857736782999979, 28.445502020000092],
              [-13.856556769999941, 28.434881903000033],
              [-13.853627081999917, 28.428412177000141],
              [-13.844349738999881, 28.41429271],
              [-13.842925584999904, 28.406927802000027],
              [-13.847767706999889, 28.397406317],
              [-13.865712042999917, 28.382879950000174],
              [-13.869618292999917, 28.376898505000057],
              [-13.870106574999909, 28.367743231000176],
              [-13.871815558999913, 28.357977606000034],
              [-13.875152147999927, 28.349066473],
              [-13.892079230999911, 28.328192450000031],
              [-13.900217251999948, 28.311102606000176],
              [-13.911284959999875, 28.277248440000037],
              [-13.933705206999889, 28.244208075000145],
              [-13.966786261999857, 28.227972723000121],
              [-14.07258053299995, 28.212836005000113],
              [-14.1185603509999, 28.200506903000175],
              [-14.152007615999878, 28.179999091000028],
              [-14.19163977799991, 28.170843817000147],
              [-14.212228969999927, 28.160549221000124],
              [-14.244536912999877, 28.12921784100017],
            ],
          ],
          [
            [
              [-13.458729620999918, 29.147406317000147],
              [-13.462391730999883, 29.132147528000175],
              [-13.459136522999927, 29.118597723],
              [-13.454009568999908, 29.104071356000148],
              [-13.451893683999884, 29.086004950000145],
              [-13.453846808999856, 29.078924872000087],
              [-13.462269660999908, 29.065741278000033],
              [-13.465565558999941, 29.058050848000121],
              [-13.466216600999871, 29.049750067000147],
              [-13.464588995999861, 29.032171942],
              [-13.465565558999941, 29.023911851000108],
              [-13.482045050999915, 28.999457098000093],
              [-13.510487433999856, 28.977484442000062],
              [-13.543039516999897, 28.961737372000115],
              [-13.589955206999946, 28.951361395],
              [-13.599720831999889, 28.94204336100016],
              [-13.60757402299987, 28.932603257000054],
              [-13.61978105399993, 28.928371486000131],
              [-13.635487433999913, 28.927232164000188],
              [-13.65843665299991, 28.922593492000161],
              [-13.674387173999918, 28.921535549000012],
              [-13.709299282999922, 28.914699611000103],
              [-13.736073370999918, 28.896470445000105],
              [-13.757191535999937, 28.870306708000058],
              [-13.774647589999915, 28.839585679000081],
              [-13.780873175999943, 28.839585679000081],
              [-13.791493292999917, 28.853461005000057],
              [-13.812123175999915, 28.859076239000061],
              [-13.859689907999922, 28.859442450000174],
              [-13.867298956999946, 28.864894924000041],
              [-13.873646613999881, 28.87685781500015],
              [-13.87633216099988, 28.888820705000072],
              [-13.873036261999943, 28.894232489000117],
              [-13.86192786399991, 28.897406317],
              [-13.853423631999874, 28.904974677000141],
              [-13.842925584999904, 28.921535549000012],
              [-13.839100714999887, 28.937892971000124],
              [-13.835072394999912, 28.987209377000013],
              [-13.832102016999897, 29.000392971000068],
              [-13.798491990999878, 29.048081773000135],
              [-13.780873175999943, 29.064886786000145],
              [-13.766835089999915, 29.074042059000121],
              [-13.750559048999889, 29.080633856000091],
              [-13.731434699999909, 29.084662177000055],
              [-13.708892381999931, 29.086004950000145],
              [-13.690174933999856, 29.090887762000122],
              [-13.656483527999853, 29.116034247000087],
              [-13.636830206999889, 29.126939195000162],
              [-13.618275519999884, 29.132391669000114],
              [-13.602365688999953, 29.134263414000159],
              [-13.551177537999905, 29.134222723000093],
              [-13.537220831999946, 29.138251044000143],
              [-13.526112433999913, 29.150295315000065],
              [-13.513986782999922, 29.174750067000033],
              [-13.503163214999915, 29.208156643000095],
              [-13.495472785999908, 29.225083726000079],
              [-13.482980923999889, 29.239935614000117],
              [-13.465402798999975, 29.243312893000152],
              [-13.446603969999927, 29.232977606000034],
              [-13.417795376999891, 29.208929755],
              [-13.427601691999911, 29.18870677299999],
              [-13.431507941999911, 29.172430731000148],
              [-13.438791469999927, 29.159002997000144],
              [-13.458729620999918, 29.147406317000147],
            ],
          ],
          [
            [
              [-13.492014126999919, 29.24266185099999],
              [-13.517079230999911, 29.226263739],
              [-13.54124915299991, 29.22939687700007],
              [-13.54124915299991, 29.236802476000051],
              [-13.525746222999913, 29.251206773000106],
              [-13.517933722999913, 29.273504950000145],
              [-13.508168097999885, 29.289455471000068],
              [-13.486683722999942, 29.284613348000093],
              [-13.47789466099988, 29.266262111000131],
              [-13.492014126999919, 29.24266185099999],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5830",
        OBJECTID_1: 7255,
        diss_me: 5830,
        adm1_cod_1: "ESP-5830",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "León",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 4,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602117,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 42.6006,
        longitude: -5.7587200000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3118528,
        gn_name: "Provincia de Leon",
        gns_id: -389074,
        gns_name: "Leon, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.LE",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.831870083137858, 43.188681952110386],
            [-4.836030035757574, 43.135093491771244],
            [-4.833549567058867, 43.123931383526497],
            [-4.827890997671403, 43.108066718504205],
            [-4.820759649937827, 43.100676988352319],
            [-4.81138037840168, 43.094811713389902],
            [-4.770452643074975, 43.078636990005066],
            [-4.764458176903361, 43.071608995059051],
            [-4.758515386675697, 43.062281399467054],
            [-4.738723313929825, 43.048096218365743],
            [-4.734744229362661, 43.041404120203907],
            [-4.726863572795622, 43.015927638749588],
            [-4.760446196035133, 43.005393257292923],
            [-4.789197417526822, 42.989792670995001],
            [-4.800271636826608, 42.975202826449276],
            [-4.81343325813836, 42.925720402574456],
            [-4.822859528244607, 42.916953312145637],
            [-4.84762271331752, 42.90236346849926],
            [-4.85535708795922, 42.891772647389729],
            [-4.865651278483682, 42.86051654240444],
            [-4.890150790426674, 42.862570986062138],
            [-4.896786533471754, 42.859769472882817],
            [-4.900335117071961, 42.854594911425977],
            [-4.901917148657446, 42.848058046141887],
            [-4.903674960830756, 42.779569273703004],
            [-4.909267002869882, 42.769209166469864],
            [-4.929767491376651, 42.747225523239337],
            [-4.932074620247533, 42.739985532006671],
            [-4.928064610693269, 42.732624692575541],
            [-4.919956699941679, 42.72581316894258],
            [-4.887503085598922, 42.711607845725467],
            [-4.881021151804674, 42.704763362838889],
            [-4.881933016695399, 42.697973812341587],
            [-4.892402987807628, 42.684153010453059],
            [-4.898093906708368, 42.669090753835178],
            [-4.89525943337614, 42.621629815263788],
            [-4.914397618055659, 42.54481340611386],
            [-4.887964511553008, 42.536914235203767],
            [-4.888052402296466, 42.517995777383192],
            [-4.912705723490319, 42.400639816274492],
            [-4.911310459510048, 42.381468673240619],
            [-4.972735022203494, 42.374261641261768],
            [-4.979590491208228, 42.337885908108149],
            [-4.942511633005495, 42.346455243914335],
            [-4.954893224642603, 42.296017009777131],
            [-5.002233315015445, 42.289150554654327],
            [-5.038268472212224, 42.297049724665044],
            [-5.041707192832661, 42.267540445734937],
            [-5.064009439783831, 42.273714762826046],
            [-5.093848308552538, 42.309398357947799],
            [-5.112415203398939, 42.306201336422504],
            [-5.123972820888923, 42.300246746190339],
            [-5.148109784638848, 42.27998795587942],
            [-5.170368086218218, 42.267902994827381],
            [-5.194801681452418, 42.26208024071056],
            [-5.219487961899887, 42.270770426513963],
            [-5.229848069133055, 42.269331217161891],
            [-5.238164719726029, 42.264343424209642],
            [-5.250436449282745, 42.248303385093152],
            [-5.259368334181204, 42.224770668631436],
            [-5.261741381559546, 42.221068276679574],
            [-5.265333910531552, 42.218178871857418],
            [-5.267520190304481, 42.217981118134077],
            [-5.270025072898818, 42.218947914514615],
            [-5.274518481542657, 42.219607094192042],
            [-5.286812182436279, 42.216344155058664],
            [-5.30922429326651, 42.204094398637523],
            [-5.321660817292866, 42.204226234752895],
            [-5.352466482442139, 42.22269425273754],
            [-5.361189627499272, 42.224979409372224],
            [-5.381459403029055, 42.214773109591334],
            [-5.387205253419722, 42.195162513738978],
            [-5.376878104540822, 42.134265294607701],
            [-5.387381034007518, 42.083036044677698],
            [-5.376295828589548, 42.041386873205596],
            [-5.421581475304805, 42.030752106724293],
            [-5.52075506289583, 42.096977695664179],
            [-5.541695005120374, 42.098010410552007],
            [-5.553362485590242, 42.06300796734385],
            [-5.624740662851281, 42.083585360475979],
            [-5.621148132979897, 42.102185215475259],
            [-5.622510437706524, 42.107315830661037],
            [-5.625125184179922, 42.111194004100042],
            [-5.643274598443924, 42.128750157193991],
            [-5.655996766937278, 42.134770665933445],
            [-5.679650332496834, 42.140208897822362],
            [-5.676914736925625, 42.120312657503149],
            [-5.743129338847893, 42.113468174616486],
            [-5.755137396173865, 42.136792150337413],
            [-5.838194040022927, 42.118598789802235],
            [-5.869417185754486, 42.122037510422757],
            [-5.883633495089725, 42.142142492382106],
            [-5.893037792060397, 42.150459142975023],
            [-5.903584666898865, 42.151074377280651],
            [-5.90737495049359, 42.148152014104141],
            [-5.911582714670487, 42.140714269148219],
            [-5.915318065875994, 42.137528233740966],
            [-5.920745312546103, 42.136132970660071],
            [-5.932478711523345, 42.136352696619639],
            [-5.937949902665878, 42.134825597423401],
            [-5.950364454456007, 42.126399082951338],
            [-5.955879590970312, 42.125827794017482],
            [-6.045022661166911, 42.157688147190271],
            [-6.203698203908971, 42.148338781709441],
            [-6.214717491718829, 42.150470129093179],
            [-6.243677453951307, 42.167883459953714],
            [-6.279536830560289, 42.177837073622015],
            [-6.290786830447871, 42.185933997356102],
            [-6.302124721978402, 42.202336586464298],
            [-6.311529018949074, 42.208115395209319],
            [-6.32039498623962, 42.207214515537402],
            [-6.339544157037182, 42.20012833445503],
            [-6.427764374426346, 42.201996009608138],
            [-6.490584201099978, 42.184165199064608],
            [-6.523773899745663, 42.200699623388886],
            [-6.557809545673962, 42.207137611811333],
            [-6.616289772954588, 42.205599525597677],
            [-6.644173074927437, 42.21559708463775],
            [-6.654060770088392, 42.216816567130792],
            [-6.690557353239228, 42.213608559487454],
            [-6.705630595975151, 42.217179116223235],
            [-6.78324585761024, 42.252876028505881],
            [-6.769813809259944, 42.261968491852372],
            [-6.756403773615261, 42.280882066354224],
            [-6.74441484217121, 42.29183746992328],
            [-6.740616623858102, 42.298193671300851],
            [-6.738317023212005, 42.304963283828471],
            [-6.737748582431152, 42.311991278774485],
            [-6.738756272783746, 42.326357327028944],
            [-6.738058640793611, 42.333850409968392],
            [-6.735707363304186, 42.341136786433538],
            [-6.740461595126561, 42.35025767734993],
            [-6.755421921684302, 42.362660020843293],
            [-6.789166633333451, 42.378886420172194],
            [-6.839111904091112, 42.410124823801539],
            [-6.831851366047715, 42.412605292500245],
            [-6.828544074149676, 42.415447496404823],
            [-6.820740932847769, 42.42366404885675],
            [-6.816400112175444, 42.430201118286988],
            [-6.81304114433334, 42.438908596254691],
            [-6.810819058053028, 42.456116848413757],
            [-6.808777838926062, 42.464023342503225],
            [-6.821050991210228, 42.485055650497827],
            [-6.892571174120349, 42.499783433958271],
            [-6.897170376311806, 42.501617947510255],
            [-6.909443528595972, 42.509291896703616],
            [-6.919985521014979, 42.512831733497705],
            [-6.93293046686756, 42.513064277494379],
            [-6.952645026147025, 42.508258367929301],
            [-6.962024298582548, 42.503814195368875],
            [-6.966623500773892, 42.500558580314163],
            [-6.968354660639051, 42.498388169978085],
            [-6.972307908583076, 42.495700994805048],
            [-6.983134120942907, 42.494822496560943],
            [-7.026283941650547, 42.502005520238583],
            [-7.071035731913497, 42.50345246016289],
            [-7.061966518739808, 42.522004299458871],
            [-7.062509121099026, 42.54065949244162],
            [-7.060106166766161, 42.548617662475152],
            [-7.042794561819562, 42.577608141402578],
            [-7.042019416362933, 42.589106147330824],
            [-7.044344855430722, 42.617088935006393],
            [-7.03990068197092, 42.625563869876714],
            [-7.032252570299818, 42.631558336048329],
            [-7.023183356226923, 42.63543406782793],
            [-7.014295010206581, 42.641325182111444],
            [-7.013907436578904, 42.647629705746311],
            [-7.016749639584191, 42.654270127964026],
            [-7.023364224279476, 42.659980374194902],
            [-7.030237188695281, 42.667189236294149],
            [-7.036179978922945, 42.675922553582978],
            [-7.039693976395853, 42.689125881853954],
            [-7.037652757268887, 42.698169257505214],
            [-7.032795172659121, 42.706256618747943],
            [-7.021245489887434, 42.718064683938024],
            [-7.016956346058549, 42.72085521099919],
            [-6.98837927918035, 42.730105292225488],
            [-6.965693324787082, 42.74702932444373],
            [-6.955745611570734, 42.749122219514845],
            [-6.947606574383855, 42.747339381906983],
            [-6.938718228363484, 42.746590073972698],
            [-6.928589647094498, 42.750052395501655],
            [-6.91804765467549, 42.757312934444343],
            [-6.895620082700646, 42.780644843085014],
            [-6.863348151196277, 42.808446764506499],
            [-6.85753455217781, 42.818136095304482],
            [-6.853891364394883, 42.829427394758483],
            [-6.854072230648853, 42.83759227126626],
            [-6.862262945578465, 42.862035223946378],
            [-6.860480108869865, 42.874463405861462],
            [-6.843556076651566, 42.889087836534401],
            [-6.839086065669392, 42.896658433839633],
            [-6.837096523385867, 42.905572618281695],
            [-6.828285691731281, 42.914796861086216],
            [-6.795316128236692, 42.910869452463288],
            [-6.777229376934173, 42.904099839935668],
            [-6.770873174657282, 42.89407461235345],
            [-6.763483446303979, 42.892834378004096],
            [-6.752373013104034, 42.897433580195525],
            [-6.744363166227146, 42.903092149582989],
            [-6.73761939122187, 42.909525865326344],
            [-6.72979041239762, 42.915261949079678],
            [-6.719429287131874, 42.920222887376354],
            [-6.701109991832652, 42.924512031205268],
            [-6.682506475693259, 42.926889146217121],
            [-6.635739304725149, 42.927302558266433],
            [-6.622975226025801, 42.925442206292814],
            [-6.614732835152154, 42.923271795956623],
            [-6.608454149039716, 42.919602769752004],
            [-6.562100389221541, 42.916657213059921],
            [-6.551222500018355, 42.917845771465238],
            [-6.515798306246779, 42.928697822246704],
            [-6.501561449201517, 42.929705511700007],
            [-6.449032355159062, 42.951073717378137],
            [-6.444846564117654, 42.957714137797268],
            [-6.444071417761762, 42.963992824808997],
            [-6.4503501038742, 42.968126939006993],
            [-6.468204312079337, 42.973139553247734],
            [-6.47631751084441, 42.976860257195085],
            [-6.481226772297674, 42.982389635373323],
            [-6.480270757889144, 42.987867336708177],
            [-6.47556820291021, 42.991846422174632],
            [-6.446939460087947, 42.992776598161441],
            [-6.426139696090047, 42.997582505927852],
            [-6.418698289094721, 43.000528063519283],
            [-6.414745042050072, 43.004868883292346],
            [-6.414745042050072, 43.010294907783759],
            [-6.413659837331636, 43.016496080429789],
            [-6.408724738355943, 43.022154648917876],
            [-6.401877610563275, 43.028640042404049],
            [-6.398725348296153, 43.03517711003559],
            [-6.392756719646883, 43.042205104981605],
            [-6.364851447236447, 43.051429347786126],
            [-6.347281459871539, 43.048096218365743],
            [-6.337928025857735, 43.043445340230249],
            [-6.329814826193285, 43.037864285208542],
            [-6.310384486854645, 43.033600978902086],
            [-6.297284512270437, 43.033962714107957],
            [-6.253592088304202, 43.017994697197452],
            [-6.234445969805677, 43.014351508515347],
            [-6.226281094197077, 43.015436713233697],
            [-6.221681891106442, 43.020087592268567],
            [-6.218968879310324, 43.033600978902086],
            [-6.212793545086072, 43.038871975561221],
            [-6.199745246445929, 43.041042384998036],
            [-6.175457323396728, 43.03783844678685],
            [-6.148068813125178, 43.029570217491568],
            [-6.135511440000982, 43.027115587214581],
            [-6.122023891789183, 43.027916571992193],
            [-6.102386846875504, 43.03478953730729],
            [-6.095746425557081, 43.041895047518352],
            [-6.092723355398505, 43.049517319868443],
            [-6.091276413675558, 43.056493638870307],
            [-6.088330857882767, 43.062100532313707],
            [-6.07910661507816, 43.065097764949911],
            [-6.02998816572125, 43.057888901951202],
            [-6.016758999028553, 43.05732046206964],
            [-5.987794359422139, 43.062333076310381],
            [-5.976373866960358, 43.057682197275511],
            [-5.971257899932141, 43.051403510263782],
            [-5.967614712149214, 43.03706330043093],
            [-5.964591641091374, 43.030035305484944],
            [-5.960405850049938, 43.023394884166521],
            [-5.954669766296632, 43.01758128514814],
            [-5.930924444707358, 43.006522528791635],
            [-5.91865129242322, 42.998796901855485],
            [-5.904853684949586, 42.992699082896308],
            [-5.893717414227297, 42.989986070200899],
            [-5.889014858349015, 42.98564525042795],
            [-5.862944097691951, 42.977945461913521],
            [-5.854856737348484, 42.97427643480961],
            [-5.84483150886706, 42.971692613323427],
            [-5.804833950426485, 42.9706074077057],
            [-5.789279343766708, 42.972364406891842],
            [-5.778685675403551, 42.974664008437202],
            [-5.769926519693115, 42.98161448811814],
            [-5.766309170331908, 42.987660631133195],
            [-5.762278408022041, 42.993164170889742],
            [-5.75979793932342, 42.998667711545664],
            [-5.759151984176697, 43.001458237707453],
            [-5.758170132245851, 43.004248766567258],
            [-5.75532792834116, 43.010088202208777],
            [-5.750599534940619, 43.016134345223833],
            [-5.743855759935343, 43.021947944242186],
            [-5.738662279440604, 43.028200791933017],
            [-5.735174118590663, 43.034479478944746],
            [-5.729980638095952, 43.040577297903951],
            [-5.719541999363798, 43.045124824151344],
            [-5.705175951109226, 43.048793850355878],
            [-5.679932012752147, 43.049646511077555],
            [-5.665152554247015, 43.048277086418381],
            [-5.637092251306314, 43.037993476417853],
            [-5.615439825687361, 43.03458283083296],
            [-5.600815395913799, 43.034686184519813],
            [-5.578982103141499, 43.031172187046792],
            [-5.570765549790281, 43.026521308011922],
            [-5.561696335717301, 43.022955633695489],
            [-5.551567756246953, 43.021017768255376],
            [-5.540999925406226, 43.021792913712005],
            [-5.517306280660307, 43.02905345355407],
            [-5.498754442263703, 43.027864895148753],
            [-5.490770432909159, 43.028640042404049],
            [-5.485835333933551, 43.033549302957937],
            [-5.481391159574287, 43.039905504335536],
            [-5.478626470934842, 43.0462358672915],
            [-5.473381312697398, 43.051119290322958],
            [-5.418759325382524, 43.05186859825713],
            [-5.408062303332599, 43.053573919700654],
            [-5.398502162844551, 43.056493638870307],
            [-5.389898036764919, 43.063780016234716],
            [-5.382844204296561, 43.085329088166901],
            [-5.374007534220283, 43.08966990793985],
            [-5.361036749945981, 43.091530259913583],
            [-5.307474128028616, 43.085484116898442],
            [-5.212001918853446, 43.093959051768849],
            [-5.159550340076066, 43.113182685532536],
            [-5.150403611637415, 43.114164537463409],
            [-5.140016648849212, 43.109591172794381],
            [-5.129836391636161, 43.107188219360808],
            [-5.118131680132961, 43.108583482441816],
            [-5.102835455891579, 43.119538886010758],
            [-5.095471564161329, 43.128323880143],
            [-5.08818518679692, 43.144188544265916],
            [-5.086195645412658, 43.151449083208604],
            [-5.0824491030437, 43.158037828582877],
            [-5.072113817098938, 43.172455552781486],
            [-5.062889574294417, 43.178760077315729],
            [-5.045888026810957, 43.185271308324246],
            [-5.008293422703304, 43.192919419995235],
            [-4.97715837186135, 43.205580145906993],
            [-4.961474574891668, 43.216225491113477],
            [-4.908480393755156, 43.237309475052228],
            [-4.898377650907889, 43.238549710300873],
            [-4.888584967322345, 43.237516181526559],
            [-4.878998989311953, 43.235164903137786],
            [-4.859413621241572, 43.223408514791117],
            [-4.831870083137858, 43.188681952110386],
          ],
          [
            [-5.319287769914609, 42.244985513570583],
            [-5.303192798408901, 42.252082681670458],
            [-5.301973315915802, 42.273176432246544],
            [-5.30922429326651, 42.293171550326747],
            [-5.33014226235548, 42.289611979709122],
            [-5.348335622890659, 42.278153239080751],
            [-5.348357595126856, 42.260970621197202],
            [-5.33716252672923, 42.246556558138707],
            [-5.319287769914609, 42.244985513570583],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5831",
        OBJECTID_1: 7260,
        diss_me: 5831,
        adm1_cod_1: "ESP-5831",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Lérida",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CT",
        note: null,
        hasc_maybe: null,
        region: "Cataluña",
        region_cod: "ES.CT",
        provnum_ne: 16,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP86",
        fips_alt: "SP56",
        woe_id: 12602126,
        woe_label: "Catalonia, ES, Spain",
        woe_name: "Cataluña",
        latitude: 42.014299999999999,
        longitude: 1.1151800000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355231,
        gn_name: "Provincia de Lleida",
        gns_id: -389097,
        gns_name: "Lleida, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.L",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP56",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.776313924000078, 42.832451274000078],
            [0.795744263000074, 42.833846537000056],
            [0.812900838000132, 42.831624451000039],
            [0.856102336000077, 42.812762553000113],
            [0.856104557726525, 42.812761914747199],
            [0.885764608000045, 42.804235942000105],
            [0.894446248000094, 42.799326681000053],
            [0.910362589000101, 42.787673646000087],
            [0.921111287000116, 42.784366354000099],
            [0.930619751000108, 42.788035380000082],
            [0.940024862000058, 42.793668112000034],
            [0.950256795000143, 42.79612274200008],
            [0.958731730000068, 42.793719788000033],
            [0.975268189000133, 42.783746236000127],
            [0.982916300000113, 42.780361430000113],
            [1.068182414000091, 42.77612396300006],
            [1.089059692000149, 42.771318054000062],
            [1.106733032000079, 42.759613343000069],
            [1.116551554000125, 42.74524729400008],
            [1.124096313000081, 42.729744365000073],
            [1.13432824700007, 42.715972596000114],
            [1.151484823000118, 42.706722514],
            [1.162543579000044, 42.705688986000041],
            [1.195926554000096, 42.713647156000093],
            [1.319123169000079, 42.713362936000095],
            [1.342584269000042, 42.708660381000129],
            [1.338243449000061, 42.694449361000068],
            [1.343307739000039, 42.690444438000071],
            [1.352712850000103, 42.690056865000088],
            [1.361084432000069, 42.686723735000072],
            [1.378757771000068, 42.663236796000092],
            [1.397981404000092, 42.643625590000056],
            [1.414827921000068, 42.609338277000035],
            [1.42929732200011, 42.595385641000064],
            [1.424853150000104, 42.5893653360001],
            [1.419272095000082, 42.579262594000085],
            [1.418031860000042, 42.569831645000107],
            [1.426403442000122, 42.565645854000081],
            [1.426300090000097, 42.56179596000004],
            [1.409763631000089, 42.540608622000065],
            [1.406456339000101, 42.529239808000085],
            [1.428987264000114, 42.531461894000074],
            [1.446557251000087, 42.519886374000038],
            [1.44996789500118, 42.504073384239575],
            [1.43022749800005, 42.493557231000096],
            [1.424543090000014, 42.492472026000087],
            [1.407593221000099, 42.486761780000052],
            [1.436428670000055, 42.453482158000099],
            [1.436428670000055, 42.440950623000077],
            [1.447900838000066, 42.434646098000087],
            [1.508465617000127, 42.428677470000054],
            [1.516630493000122, 42.429504293000093],
            [1.528206014000091, 42.434232686000058],
            [1.534510539000081, 42.439917094000094],
            [1.538851359000063, 42.445653178000072],
            [1.544432414000084, 42.450355734000141],
            [1.572664978317505, 42.453074853704038],
            [1.607477661000104, 42.456427714000085],
            [1.639517049000119, 42.466427104000019],
            [1.650369100000063, 42.493402202000141],
            [1.653986450000048, 42.496528626000099],
            [1.656983683016847, 42.497639669211736],
            [1.659774210000137, 42.496812846000068],
            [1.662358032000043, 42.493712260000024],
            [1.674243611000065, 42.49050832100005],
            [1.68633589600006, 42.490611675000082],
            [1.697498006000075, 42.494461569000123],
            [1.707006470000067, 42.502781474000102],
            [1.710003703000069, 42.501334534000108],
            [1.711967407000117, 42.499293315000045],
            [1.712690877000114, 42.496787008000069],
            [1.712174112972548, 42.493712260056441],
            [1.714071873118456, 42.477038744842162],
            [1.719367283673222, 42.460943773336453],
            [1.728749608407668, 42.446826341762204],
            [1.742834081627564, 42.436158616926633],
            [1.807170021379392, 42.41854753144338],
            [1.810861428112389, 42.412812668070217],
            [1.811125099443814, 42.383632979878087],
            [1.815607521969497, 42.375964522844441],
            [1.833031838948273, 42.358650068845549],
            [1.836525491058637, 42.350684980327543],
            [1.837558205946465, 42.343027509412053],
            [1.83648154568678, 42.335337079242748],
            [1.833647073253815, 42.32724015550869],
            [1.842545998898743, 42.306311199402302],
            [1.715587986196567, 42.2881178397665],
            [1.686320407260951, 42.262124186082332],
            [1.683200290361185, 42.256114664360297],
            [1.683134371853811, 42.249347086099135],
            [1.690627048299689, 42.212356117740597],
            [1.695021579182509, 42.205995033044275],
            [1.701064060158274, 42.201051185463797],
            [1.732067479031002, 42.189372717976511],
            [1.735802831135771, 42.185428626030244],
            [1.738087987770456, 42.177177893944702],
            [1.737165135862313, 42.168289953518553],
            [1.733517674501087, 42.16005020755108],
            [1.72771689351984, 42.153755041362047],
            [1.716071385286199, 42.148525549314627],
            [1.71259970541206, 42.145877843587613],
            [1.708183201393638, 42.138868566231309],
            [1.707018650390438, 42.134836583541443],
            [1.706754978159722, 42.130793614733534],
            [1.715697850075713, 42.104810947167437],
            [1.712577732276458, 42.096021884502392],
            [1.707062595762238, 42.092253574043156],
            [1.694120700410053, 42.088781894169102],
            [1.690143650109405, 42.084694979989308],
            [1.689440525060206, 42.079256747201129],
            [1.693131930893912, 42.061865389476083],
            [1.692384860472913, 42.051648103577151],
            [1.688781345382779, 42.041881257514035],
            [1.682607028291756, 42.033564606921033],
            [1.660854098038129, 42.018425446577112],
            [1.657843844118133, 42.011679840552148],
            [1.65977743777853, 42.003593902936217],
            [1.664084078817154, 41.998924712805234],
            [1.669137789377487, 41.997617339568563],
            [1.69379111057134, 42.005626373458426],
            [1.700492771224418, 42.005780181809996],
            [1.707282322621069, 42.003220367725731],
            [1.71435751758537, 41.996046295000525],
            [1.715807713055483, 41.98789443887722],
            [1.712358006317004, 41.980039214238303],
            [1.704689548383982, 41.97375503416751],
            [1.694076755038253, 41.970107573705576],
            [1.662458101859897, 41.970338285783185],
            [1.653449312335937, 41.968097075419678],
            [1.646242281256264, 41.961593168489941],
            [1.643561616275605, 41.954430082782068],
            [1.643188081065119, 41.929161527282673],
            [1.615612396694758, 41.895686184169989],
            [1.614623627178645, 41.884172511152428],
            [1.633234467396733, 41.848719629007661],
            [1.633915620209706, 41.838491356990573],
            [1.630224213476623, 41.829054100766086],
            [1.560241301095232, 41.767838278813514],
            [1.553078214487982, 41.763718405380075],
            [1.543432218422168, 41.763740377616358],
            [1.519416103770169, 41.779967185237439],
            [1.510473232753498, 41.78131850384591],
            [1.482238368705708, 41.776836081320141],
            [1.474064539446942, 41.780044089862855],
            [1.455827234439369, 41.798775780078216],
            [1.444643152159784, 41.802016746975312],
            [1.432272545741426, 41.80144545714208],
            [1.420758873623157, 41.79716078923903],
            [1.412189537816943, 41.789250633110271],
            [1.409486900600001, 41.782537986339037],
            [1.406806236518605, 41.704150531721666],
            [1.438512780440448, 41.643505998702864],
            [1.436842859010682, 41.636760392677985],
            [1.389447837147912, 41.628520645811136],
            [1.374198813824222, 41.619336075699294],
            [1.369980063529169, 41.602933487490475],
            [1.388722739862601, 41.600373672507033],
            [1.407091880985547, 41.590464004210418],
            [1.392677817926938, 41.575028212382051],
            [1.371759848837968, 41.565415174670591],
            [1.207799881659213, 41.572325576064458],
            [1.201010330262534, 41.570402968522131],
            [1.194616287211886, 41.566469862693992],
            [1.178004958262164, 41.544519177817847],
            [1.169128004853576, 41.518426648171328],
            [1.170226637349373, 41.495421276170958],
            [1.167502027896319, 41.486566294998539],
            [1.157394605876334, 41.480249156573308],
            [1.130368237304339, 41.473217906081487],
            [1.117184643756303, 41.472547740285933],
            [1.073019602672559, 41.482732066032042],
            [1.065109446543772, 41.480831431625205],
            [1.062560617678372, 41.475239390485456],
            [1.06337360660666, 41.458957651374476],
            [1.062033274116317, 41.4516297702977],
            [1.045509835910195, 41.426679818518778],
            [1.023669014913082, 41.406267219856289],
            [0.98945758749764, 41.39831311835573],
            [0.981920966579366, 41.391787239189711],
            [0.968869208247298, 41.364420294660846],
            [0.957685125967828, 41.354510626364345],
            [0.879044986137274, 41.342008183830643],
            [0.868717837258373, 41.343513310790684],
            [0.842921937297575, 41.357575811774268],
            [0.833517640326789, 41.358487676664964],
            [0.827453187114941, 41.356059698696157],
            [0.82365191740206, 41.351950811380874],
            [0.817785217913439, 41.341173223565448],
            [0.804975158676626, 41.331340458994958],
            [0.758481016485689, 41.331263554369542],
            [0.73644244176532, 41.318398563642774],
            [0.6207564020863, 41.298667117793201],
            [0.595949271641615, 41.303369266278622],
            [0.570285208695452, 41.320826542510957],
            [0.561869681240807, 41.323913701056455],
            [0.5534541528869, 41.321925175906131],
            [0.547411672810426, 41.315673954189606],
            [0.538908254612238, 41.30000745028363],
            [0.531371632794674, 41.29283337755831],
            [0.520934620936004, 41.28892224486566],
            [0.509772510892702, 41.288493778165332],
            [0.500016650947657, 41.291767703416753],
            [0.491623095729324, 41.299611941937542],
            [0.472814501787809, 41.32826428746688],
            [0.464135302102449, 41.333383916534501],
            [0.454445360664863, 41.334603399027628],
            [0.445238817417447, 41.331779911813442],
            [0.438053758574085, 41.324759648339096],
            [0.433175828601605, 41.276112185628847],
            [0.38580277987441, 41.278858767318212],
            [0.36037112782526, 41.232832342738419],
            [0.354273308866055, 41.256293443487664],
            [0.352361280948372, 41.279211940978314],
            [0.377269320722576, 41.321405748176687],
            [0.374013705667977, 41.338924058698382],
            [0.36507368280428, 41.352566637440361],
            [0.351741164223313, 41.362540188179167],
            [0.335204704733286, 41.368896389556738],
            [0.327298210643903, 41.370110785484286],
            [0.324197625220194, 41.390161241548014],
            [0.338305291056372, 41.405896715361138],
            [0.348537225112921, 41.433233547889984],
            [0.349777458562897, 41.45250885669833],
            [0.348537225112921, 41.472094224768625],
            [0.35117272344246, 41.47759776542452],
            [0.361301303812155, 41.484599921049551],
            [0.390446812370499, 41.491679591939601],
            [0.397423129573781, 41.494289251847505],
            [0.400368687165127, 41.496537177448829],
            [0.402074008608651, 41.498940130882289],
            [0.402539096602027, 41.500412910127693],
            [0.404554478206649, 41.504211127541453],
            [0.40843020998625, 41.510024726559806],
            [0.413546177014496, 41.51617422146316],
            [0.433131545084763, 41.533589179197264],
            [0.438247511213717, 41.539712836578161],
            [0.441193067905772, 41.546146552321517],
            [0.437627393589338, 41.55487986871097],
            [0.432976514554468, 41.561804510869479],
            [0.430341017124221, 41.56934926975299],
            [0.429410841137411, 41.576997382323384],
            [0.429720900399246, 41.5846971708377],
            [0.42398481574665, 41.592913723289655],
            [0.415768263294694, 41.595420030410054],
            [0.405949741287543, 41.596376043919321],
            [0.395252720136909, 41.595445867932398],
            [0.365177035591756, 41.598339749579793],
            [0.356598747933873, 41.601621202156736],
            [0.351482781804918, 41.607228094700758],
            [0.348072137119374, 41.615160427211976],
            [0.343266229352963, 41.63720042645852],
            [0.329468620980066, 41.661746731026739],
            [0.328228386630713, 41.669472357962974],
            [0.328538445892548, 41.677404690474077],
            [0.342646111728698, 41.693476061071351],
            [0.366417270840401, 41.709960841919269],
            [0.379749790320574, 41.726704006984335],
            [0.38657107879223, 41.732750149100099],
            [0.393857456156638, 41.736858424876374],
            [0.39602786559351, 41.742310288688884],
            [0.402074008608651, 41.749389960478311],
            [0.405949741287543, 41.752955633895368],
            [0.41282270660264, 41.756004544274276],
            [0.420264112698675, 41.757761542561155],
            [0.454215529023401, 41.762464098439352],
            [0.464602491811604, 41.767425034937418],
            [0.473180780368693, 41.775899969807739],
            [0.483412712626603, 41.791971340405013],
            [0.496590203375149, 41.808817857358264],
            [0.50093102404756, 41.812435208518025],
            [0.50666710780078, 41.816000881935082],
            [0.514728630621818, 41.8183263210029],
            [0.533538853235456, 41.821840318475893],
            [0.542220492781468, 41.82674957902978],
            [0.549661898877503, 41.834035956394274],
            [0.558033480960347, 41.845172228015855],
            [0.572606234789873, 41.852484442902693],
            [0.58066775940955, 41.855533352382338],
            [0.587075635831923, 41.861967068125693],
            [0.593586866840411, 41.867548123147373],
            [0.597617629150307, 41.874395250040749],
            [0.595292189183226, 41.907416490378694],
            [0.593276807578604, 41.913721014912852],
            [0.589556105429892, 41.918578600422052],
            [0.58066775940955, 41.923100287348376],
            [0.56718021029846, 41.932737942202266],
            [0.566095004680761, 41.938887438004798],
            [0.570125766990543, 41.944907741698955],
            [0.576171909106392, 41.951263943076441],
            [0.583975050408213, 41.956767482832987],
            [0.592811719585228, 41.960772406721162],
            [0.601183301668073, 41.962038479492236],
            [0.607539503944935, 41.964829007452664],
            [0.612190382080541, 41.968678900810545],
            [0.619321729814033, 41.979505113170489],
            [0.624437696842278, 41.984776108930248],
            [0.631258986213311, 41.989116930501865],
            [0.642472772200733, 41.992269191869724],
            [0.646813591973711, 41.996248277336178],
            [0.651619499740121, 42.001881008301922],
            [0.655960321311824, 42.0127847359268],
            [0.654875115694097, 42.02048452444123],
            [0.654875115694097, 42.026995755449718],
            [0.668517694436076, 42.04916494410682],
            [0.684330681715579, 42.089989325746757],
            [0.684485711346497, 42.095027778409218],
            [0.693942498147777, 42.100376288534846],
            [0.699110142019435, 42.10936798824207],
            [0.703140903429954, 42.120323390911722],
            [0.706241488853664, 42.141665758168216],
            [0.708876987183174, 42.152052720956334],
            [0.705931431390411, 42.162232978169385],
            [0.703450961792498, 42.16789154755682],
            [0.705466343397035, 42.17835602471078],
            [0.708256869558909, 42.188071193930483],
            [0.711667515143716, 42.195357571294863],
            [0.71941897960221, 42.226776842077555],
            [0.721744418669999, 42.231479397056489],
            [0.725775180979781, 42.235535996888729],
            [0.733216587075901, 42.245225327686683],
            [0.741743197890287, 42.264009710979337],
            [0.743448521132365, 42.270650133197051],
            [0.745618930569265, 42.288633530812803],
            [0.745618930569265, 42.301681830352152],
            [0.744378696219826, 42.306100165390347],
            [0.749856397554737, 42.319251816817882],
            [0.751251662434299, 42.328682766096733],
            [0.75109663190409, 42.34795807580457],
            [0.746394076925071, 42.390539455731357],
            [0.738332554104147, 42.414620673205491],
            [0.732906527814009, 42.426867987967228],
            [0.726860385698217, 42.433327542132389],
            [0.722519565025891, 42.436738185918529],
            [0.718953891608834, 42.439063625885609],
            [0.715078158929856, 42.44314606413954],
            [0.711512484613536, 42.450794175810557],
            [0.710892367888448, 42.461387844173714],
            [0.708566928820744, 42.468984279900553],
            [0.703605991423331, 42.477045802721591],
            [0.701435581087253, 42.484332180086085],
            [0.704381137779308, 42.492755439012257],
            [0.710892367888448, 42.49637278927284],
            [0.718333773984483, 42.496682848534562],
            [0.722519565025891, 42.497251288416209],
            [0.726395297704869, 42.498594876452415],
            [0.730271030383761, 42.500713609045789],
            [0.730581088746277, 42.50314240180046],
            [0.733216587075901, 42.50877513276609],
            [0.730581088746277, 42.519730536335146],
            [0.734146763062625, 42.528231310526536],
            [0.737557406848879, 42.533993231802214],
            [0.742208285883748, 42.537713934850274],
            [0.746859164918533, 42.54660228087053],
            [0.752336867152735, 42.554870510165898],
            [0.757297803650772, 42.560554917975054],
            [0.759778273248685, 42.564663193751358],
            [0.761483594692208, 42.569314072786227],
            [0.762723829940825, 42.575386054222975],
            [0.764429152283554, 42.589571235324286],
            [0.765669385733531, 42.594919745449914],
            [0.769545119311772, 42.601275946827514],
            [0.768925001687506, 42.605590929078119],
            [0.76551435700199, 42.609905911328752],
            [0.754662306220439, 42.614453437576032],
            [0.733991733431822, 42.617269802159626],
            [0.725465121718059, 42.620292874116842],
            [0.716318394178586, 42.625718899507632],
            [0.708411899189826, 42.634193834377953],
            [0.694562615772156, 42.662357490106046],
            [0.675649041270191, 42.688686632282213],
            [0.663425813975067, 42.696214350905848],
            [0.66541630100005, 42.698660991000111],
            [0.666449829000044, 42.714758200000091],
            [0.659628540000085, 42.729899394000086],
            [0.647639608000134, 42.741035665000112],
            [0.633480265000088, 42.744782206000139],
            [0.63957808400005, 42.75483327200007],
            [0.643298787000049, 42.759070740000112],
            [0.650740194000065, 42.763385723000084],
            [0.639681437000149, 42.774677023000066],
            [0.653737427000095, 42.802685649000068],
            [0.651567016000087, 42.824053854000084],
            [0.656321248000097, 42.838419902000084],
            [0.679368937000049, 42.845112000000114],
            [0.706757446000097, 42.846584779000096],
            [0.72432743300007, 42.845292867000069],
            [0.758847290000034, 42.835267640000041],
            [0.776313924000078, 42.832451274000078],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5832",
        OBJECTID_1: 7244,
        diss_me: 5832,
        adm1_cod_1: "ESP-5832",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Lugo",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.GA",
        note: null,
        hasc_maybe: null,
        region: "Galicia",
        region_cod: "ES.GA",
        provnum_ne: 12,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "GA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 4,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP91",
        fips_alt: "SP58",
        woe_id: 12602131,
        woe_label: "Galicia, ES, Spain",
        woe_name: "Galicia",
        latitude: 42.9467,
        longitude: -7.4426600000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3117813,
        gn_name: "Provincia de Lugo",
        gns_id: -390506,
        gns_name: "Lugo",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.LU",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP58",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.046922738073732, 43.483677935203787],
            [-7.048892380778739, 43.477889105797544],
            [-7.050520188755684, 43.476338813085761],
            [-7.081138474760763, 43.456055813025358],
            [-7.095013596600154, 43.440061956793841],
            [-7.105116340346797, 43.433059801168838],
            [-7.112738612696802, 43.429339098120863],
            [-7.119947475695426, 43.428693142074877],
            [-7.127440557735525, 43.428822333283989],
            [-7.137620814948662, 43.431457830714322],
            [-7.14855038099526, 43.431457830714322],
            [-7.160901047645268, 43.429132392545881],
            [-7.166869676294567, 43.423396307893285],
            [-7.168807541734679, 43.416781724996582],
            [-7.171623908116828, 43.39611115220788],
            [-7.17012529134908, 43.390840156448093],
            [-7.166947190660323, 43.386215114935567],
            [-7.153588832758459, 43.381383367848173],
            [-7.136613124595982, 43.382106839159292],
            [-7.130050217643429, 43.378618679208728],
            [-7.126045294654631, 43.372701728302161],
            [-7.125270148298739, 43.365208645362685],
            [-7.125476853873693, 43.357198798485712],
            [-7.124805060305277, 43.348207098778602],
            [-7.121601121194715, 43.336786607216112],
            [-7.115348272604706, 43.32655467405894],
            [-7.108371955401424, 43.319035752697857],
            [-7.094884406290248, 43.308674628331374],
            [-7.086486985785768, 43.307020981932709],
            [-7.078347947699598, 43.303093574209043],
            [-7.071268275910171, 43.297719224762432],
            [-7.06279334103985, 43.25487946241725],
            [-7.056798874868235, 43.24464752926005],
            [-7.050959438327425, 43.237309475052228],
            [-7.043414679443913, 43.2316767440864],
            [-7.021348842674939, 43.218757635756248],
            [-7.013597378216446, 43.217362371775977],
            [-7.006956955998675, 43.217000637469482],
            [-6.995898199642227, 43.206045233900454],
            [-6.979956021153441, 43.200102444572167],
            [-6.9718428205897, 43.198422959751781],
            [-6.965564133577885, 43.195012315066265],
            [-6.965176560849585, 43.189612128097195],
            [-6.965848355317377, 43.183255926719625],
            [-6.972669643789033, 43.168864040942708],
            [-6.973780686929103, 43.159226386088903],
            [-6.97091264460289, 43.151733303149427],
            [-6.965693324787082, 43.144601956315228],
            [-6.95928544836471, 43.138840034140202],
            [-6.951533983006868, 43.134576727833718],
            [-6.943420783342503, 43.132613023072537],
            [-6.926651780755094, 43.13803904936259],
            [-6.907919074305795, 43.146410631445519],
            [-6.898462286605223, 43.149123644140815],
            [-6.891175910140106, 43.153180243073763],
            [-6.886266648686927, 43.158399562889485],
            [-6.884251267981654, 43.1646782499013],
            [-6.879962124152655, 43.170595200807867],
            [-6.873812629249414, 43.174625963117734],
            [-6.866293707888246, 43.175297756686177],
            [-6.857379523446184, 43.1692516145703],
            [-6.847586838961433, 43.159226386088903],
            [-6.832161424410259, 43.139899400436917],
            [-6.829603441345682, 43.128866482502133],
            [-6.83154130678588, 43.120159002735733],
            [-6.868980882161907, 43.095302638905679],
            [-6.877223273035554, 43.091788642331977],
            [-6.913241746009703, 43.080574856344526],
            [-6.922595180922883, 43.080109768351093],
            [-6.932723761292493, 43.080988268393838],
            [-6.939390021032636, 43.079593004413567],
            [-6.945203620050989, 43.076724962087383],
            [-6.948846807833917, 43.072074083052485],
            [-6.952179938153563, 43.066208808089982],
            [-6.955203009211516, 43.059465033984083],
            [-6.958251918691047, 43.049801540708557],
            [-6.963445400085163, 43.042592677709905],
            [-6.971222702965349, 43.038613593142742],
            [-6.979568446626473, 43.036624049959926],
            [-6.986131353579111, 43.030216173537553],
            [-6.988456794445483, 43.019338284334282],
            [-6.983314988995545, 43.007969468716027],
            [-6.975847743578498, 42.998305976339793],
            [-6.966390956777218, 42.994275214029898],
            [-6.961972621739108, 42.995670478010169],
            [-6.957683477910109, 42.997944241133808],
            [-6.955978155567408, 42.999882107473212],
            [-6.954892950848972, 43.001587428916736],
            [-6.95373023086546, 43.00492055923641],
            [-6.953575202133834, 43.023782456894821],
            [-6.951327277431915, 43.025126044031765],
            [-6.945203620050989, 43.024609280094154],
            [-6.924481370418846, 43.002388413694263],
            [-6.922000901720224, 43.000192165835756],
            [-6.913706834003165, 42.994740302023274],
            [-6.905852016757194, 42.992078966171306],
            [-6.896395229955914, 42.990709539713464],
            [-6.877378301767209, 42.991277981393694],
            [-6.868851690952823, 42.98866832148579],
            [-6.845648972621973, 42.971408393382575],
            [-6.8368898178108, 42.96270091451558],
            [-6.833660041177922, 42.952365628570902],
            [-6.828285691731281, 42.914796861086216],
            [-6.837096523385867, 42.905572618281695],
            [-6.839086065669392, 42.896658433839633],
            [-6.843556076651566, 42.889087836534401],
            [-6.860480108869865, 42.874463405861462],
            [-6.862262945578465, 42.862035223946378],
            [-6.854072230648853, 42.83759227126626],
            [-6.853891364394883, 42.829427394758483],
            [-6.85753455217781, 42.818136095304482],
            [-6.863348151196277, 42.808446764506499],
            [-6.895620082700646, 42.780644843085014],
            [-6.91804765467549, 42.757312934444343],
            [-6.928589647094498, 42.750052395501655],
            [-6.938718228363484, 42.746590073972698],
            [-6.947606574383855, 42.747339381906983],
            [-6.955745611570734, 42.749122219514845],
            [-6.965693324787082, 42.74702932444373],
            [-6.98837927918035, 42.730105292225488],
            [-7.016956346058549, 42.72085521099919],
            [-7.021245489887434, 42.718064683938024],
            [-7.032795172659121, 42.706256618747943],
            [-7.037652757268887, 42.698169257505214],
            [-7.039693976395853, 42.689125881853954],
            [-7.036179978922945, 42.675922553582978],
            [-7.030237188695281, 42.667189236294149],
            [-7.023364224279476, 42.659980374194902],
            [-7.016749639584191, 42.654270127964026],
            [-7.013907436578904, 42.647629705746311],
            [-7.014295010206581, 42.641325182111444],
            [-7.023183356226923, 42.63543406782793],
            [-7.032252570299818, 42.631558336048329],
            [-7.03990068197092, 42.625563869876714],
            [-7.044344855430722, 42.617088935006393],
            [-7.042019416362933, 42.589106147330824],
            [-7.042794561819562, 42.577608141402578],
            [-7.060106166766161, 42.548617662475152],
            [-7.062509121099026, 42.54065949244162],
            [-7.061966518739808, 42.522004299458871],
            [-7.071035731913497, 42.50345246016289],
            [-7.1032148398929, 42.457823656436574],
            [-7.138887448896611, 42.440388353339841],
            [-7.150829587715236, 42.427226731128712],
            [-7.202630127478358, 42.325526287807946],
            [-7.255727052561383, 42.406220871296171],
            [-7.269317141472925, 42.414691330240657],
            [-7.3202277876822, 42.425007493001402],
            [-7.361986823033448, 42.419536300959493],
            [-7.369743170810636, 42.421272140896633],
            [-7.406371590076844, 42.444321458268689],
            [-7.41828076964174, 42.43317033524275],
            [-7.520871105617005, 42.39500382976297],
            [-7.577066176262974, 42.407660080648128],
            [-7.617342056890379, 42.402090011744605],
            [-7.715406025867452, 42.451143968918984],
            [-7.751089620989205, 42.452132738435125],
            [-7.752726583165241, 42.451132981901651],
            [-7.781543723164276, 42.47207292412611],
            [-7.861502222349657, 42.50397722267067],
            [-7.896845241514654, 42.505691089472208],
            [-7.892648464355091, 42.536518727757056],
            [-7.894417262646556, 42.546362478445587],
            [-7.900679470481151, 42.556491872701685],
            [-7.930803982817537, 42.578453543696071],
            [-7.893000026430002, 42.635307794019468],
            [-7.890923610536191, 42.667629572246938],
            [-7.887331080664836, 42.678275324846396],
            [-7.868247828374535, 42.698160579946801],
            [-7.868148951512893, 42.71321184954715],
            [-7.880409694052105, 42.722011899229813],
            [-7.912281033343021, 42.729856137750488],
            [-7.93225417828765, 42.739952573652317],
            [-7.978517607501573, 42.781151305288489],
            [-7.981571806793454, 42.795081970156815],
            [-7.96445510741728, 42.842356142022282],
            [-7.999809112700405, 42.848860048052728],
            [-7.97526565448635, 42.883049502332682],
            [-7.965982206613518, 42.889048037936561],
            [-7.944844510665632, 42.897309757039636],
            [-7.935626981300175, 42.903429141741441],
            [-7.911775662017163, 42.936728704266272],
            [-7.906601101459614, 42.961909369022152],
            [-7.9064143338544, 42.987639350475604],
            [-7.934846951625445, 43.05184345411206],
            [-7.935385282204948, 43.065158884674759],
            [-7.931726834725566, 43.076331980836272],
            [-7.92702468624023, 43.084143260103332],
            [-7.924684598115732, 43.092174266229392],
            [-7.961356962753626, 43.189952590739466],
            [-7.928397977084927, 43.255167437027637],
            [-7.927552029802314, 43.264494829372865],
            [-7.930408475370825, 43.271042681674402],
            [-7.934605252530389, 43.277129107122562],
            [-7.937769315701331, 43.285050250268768],
            [-7.936758573049701, 43.293202106392044],
            [-7.931660916218306, 43.299925739281434],
            [-7.896504665557899, 43.33010518400701],
            [-7.889308619697005, 43.34145406075632],
            [-7.882090602499289, 43.407525841344437],
            [-7.836134789988563, 43.401911827069114],
            [-7.824610130852847, 43.406592003318252],
            [-7.817205346049832, 43.415029503009094],
            [-7.807284690735884, 43.437397668467611],
            [-7.780170432319608, 43.449054162819323],
            [-7.746013937293355, 43.521300259599286],
            [-7.744805440918299, 43.526321011805265],
            [-7.746662129953279, 43.530583707472118],
            [-7.758340597440565, 43.545525114092698],
            [-7.758428488184137, 43.554753629576311],
            [-7.753792257306799, 43.563410857025445],
            [-7.729973897277517, 43.58150533979952],
            [-7.726590108146922, 43.586482146633728],
            [-7.725337666400179, 43.59757833816974],
            [-7.728919209254116, 43.615705780197544],
            [-7.723634785716769, 43.625802216998721],
            [-7.690950458396969, 43.653927218066627],
            [-7.709473407871457, 43.674175021360099],
            [-7.696156379106668, 43.731512761802378],
            [-7.69155839799987, 43.72724030200007],
            [-7.683990037999934, 43.728705145000021],
            [-7.67516028599988, 43.731634833000044],
            [-7.673573370999947, 43.734361070000034],
            [-7.674143032999922, 43.739203192000105],
            [-7.67247473899991, 43.743841864000018],
            [-7.664621548999918, 43.74591705900005],
            [-7.658029751999948, 43.746527411000102],
            [-7.641021287999877, 43.75267161700009],
            [-7.637603318999879, 43.73908112200013],
            [-7.613026495999861, 43.704291083000072],
            [-7.615956183999884, 43.688544012000108],
            [-7.607167120999918, 43.691107489000018],
            [-7.594593878999916, 43.703680731000105],
            [-7.586415167999917, 43.717962958000015],
            [-7.574289516999926, 43.714544989000103],
            [-7.56541907499988, 43.719794012000079],
            [-7.555775519999912, 43.727606512000079],
            [-7.541411912999934, 43.731634833000044],
            [-7.502023891999926, 43.728338934000107],
            [-7.490142381999902, 43.725327867],
            [-7.4076228509999, 43.691229559000107],
            [-7.367054816999882, 43.680080471000082],
            [-7.304107225999928, 43.598578192000048],
            [-7.256743943999908, 43.581610419000071],
            [-7.260161912999905, 43.577500718000124],
            [-7.262277798999918, 43.574286200000074],
            [-7.265126105999883, 43.571478583000015],
            [-7.271066860999895, 43.568630276000079],
            [-7.271066860999895, 43.561143296000097],
            [-7.257720506999874, 43.556667385000139],
            [-7.249623175999943, 43.559393622000101],
            [-7.242014126999919, 43.564805406000062],
            [-7.230091925999886, 43.568630276000079],
            [-7.215443488999966, 43.56867096600007],
            [-7.194894985999923, 43.563503322000059],
            [-7.156971808999884, 43.559271552000041],
            [-7.095855272999927, 43.560980536000045],
            [-7.079335089999859, 43.568630276000079],
            [-7.061594204999949, 43.561590887000108],
            [-7.04430091099988, 43.559393622000101],
            [-7.032338019999941, 43.553005276000079],
            [-7.030873175999886, 43.533229885000054],
            [-7.037749803999901, 43.52224355700011],
            [-7.048410610999923, 43.510443427000041],
            [-7.053618943999908, 43.496527411000045],
            [-7.046922738073732, 43.483677935203787],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5833",
        OBJECTID_1: 396,
        diss_me: 5833,
        adm1_cod_1: "ESP-5833",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Madrid",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.MD",
        note: null,
        hasc_maybe: null,
        region: "Madrid",
        region_cod: "ES.MD",
        provnum_ne: 10,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "MD",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP87",
        fips_alt: "SP29",
        woe_id: 12578024,
        woe_label: "Madrid, ES, Spain",
        woe_name: "Comunidad de Madrid",
        latitude: 40.459299999999999,
        longitude: -3.6701199999999998,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355233,
        gn_name: "Provincia de Madrid",
        gns_id: -390628,
        gns_name: "Madrid, Comunidad de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.M",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP29",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.258436899343423, 40.661646830037213],
              [-4.321926891812637, 40.657878519578063],
              [-4.318488171192115, 40.682356059284658],
              [-4.299349987411972, 40.696231792663141],
              [-4.276278696904313, 40.692661235927275],
              [-4.258436899343423, 40.661646830037213],
            ],
          ],
          [
            [
              [-3.48874793087225, 40.785521144785051],
              [-3.459912481575742, 40.778803209100872],
              [-3.452884486629728, 40.772343654935796],
              [-3.446373257419879, 40.76143992821018],
              [-3.444021979031106, 40.751414699728585],
              [-3.442962611835014, 40.737901313095094],
              [-3.439422776839649, 40.730873318149165],
              [-3.439655320836323, 40.723199368056456],
              [-3.44311764236528, 40.716869005100577],
              [-3.455209926596808, 40.703769028717616],
              [-3.458801439334962, 40.694958197962492],
              [-3.455054896965919, 40.688989570212513],
              [-3.448750373331023, 40.683046779984934],
              [-3.442109951113338, 40.678189195375111],
              [-3.434306809811432, 40.675476183579079],
              [-3.418829718416703, 40.67839590094998],
              [-3.404644538214853, 40.677310696231714],
              [-3.399606085552392, 40.681289780798707],
              [-3.396350469598502, 40.686405747827038],
              [-3.389193285241873, 40.686405747827038],
              [-3.380744187893981, 40.678189195375111],
              [-3.366765713267, 40.658913886566651],
              [-3.35547441201453, 40.649715481284389],
              [-3.344932419595523, 40.644392809580452],
              [-3.337025926405317, 40.643255927119313],
              [-3.329842901828385, 40.64573639581802],
              [-3.324649421333675, 40.648320217304118],
              [-3.318164027847615, 40.646253159755432],
              [-3.324132655597509, 40.624936631820205],
              [-3.325967170048784, 40.597780667343969],
              [-3.321471319745655, 40.589357408417627],
              [-3.31578691193647, 40.582639471834156],
              [-3.306950242759456, 40.578453680792805],
              [-3.293075120020774, 40.568531805997978],
              [-3.290052048962849, 40.561710517526407],
              [-3.28798499231354, 40.553700669750171],
              [-3.288811814613496, 40.549101467558714],
              [-3.290672165687852, 40.545897529347442],
              [-3.29315263528585, 40.54323619169692],
              [-3.294961310416113, 40.538817857558101],
              [-3.292997605654932, 40.535510566559353],
              [-3.289509446603631, 40.533133449748917],
              [-3.282558966023487, 40.533391832167311],
              [-3.276693691060984, 40.534632066516579],
              [-3.270595872101779, 40.538042711202095],
              [-3.263748745208403, 40.540910753528479],
              [-3.257521735040001, 40.542771103703458],
              [-3.248530036232182, 40.541530870253396],
              [-3.240235967615803, 40.538559475139706],
              [-3.233259650412521, 40.533391832167311],
              [-3.225404832267174, 40.528637600344965],
              [-3.204527553903489, 40.513031317741067],
              [-3.201530321267285, 40.507140204357015],
              [-3.200806850855429, 40.502644354953034],
              [-3.200858526799578, 40.498716946330021],
              [-3.198791470150184, 40.478821519897295],
              [-3.200238410074576, 40.450502835437575],
              [-3.196466030183103, 40.443526516435625],
              [-3.189541388024566, 40.43939240223763],
              [-3.180575526739176, 40.437867947048076],
              [-3.169232550441819, 40.433062039281666],
              [-3.157140265311, 40.425388089188957],
              [-3.141947393857123, 40.405906073906252],
              [-3.138665941280181, 40.39474396386278],
              [-3.140216233991993, 40.386785793829247],
              [-3.142696701791323, 40.382600002787839],
              [-3.143471849046591, 40.37929271088997],
              [-3.142231614697238, 40.369887600032726],
              [-3.14207658506632, 40.364978339478753],
              [-3.143368496259029, 40.360172430812966],
              [-3.146339891372889, 40.355857449461794],
              [-3.150499843992606, 40.346555691392055],
              [-3.151197475982741, 40.341698105882941],
              [-3.153057827057069, 40.336246242969722],
              [-3.157682867670133, 40.330613512003893],
              [-3.171945563137228, 40.3197097843791],
              [-3.177965867730563, 40.3125784375449],
              [-3.180730557269356, 40.301571357132431],
              [-3.181970790719447, 40.292527981481257],
              [-3.18540727382657, 40.283820501714885],
              [-3.187939419368718, 40.274337877391147],
              [-3.188507860149571, 40.263692532184663],
              [-3.185924037764096, 40.249455675139203],
              [-3.178637661298978, 40.242272651461676],
              [-3.171092902415467, 40.242505195458435],
              [-3.163961553782684, 40.247672838430802],
              [-3.157863734823479, 40.254029038908939],
              [-3.150732387989279, 40.260230211555083],
              [-3.124842495384854, 40.27485464132856],
              [-3.115385707684197, 40.276094876577204],
              [-3.103758511446102, 40.269893703931345],
              [-3.095335251620554, 40.257207140497158],
              [-3.08652442086526, 40.239327093870216],
              [-3.083113776179829, 40.227674059210429],
              [-3.082183600192991, 40.218217271509857],
              [-3.082726202552237, 40.197624213086911],
              [-3.07275265091414, 40.157600816224701],
              [-3.080400762585157, 40.159176948257581],
              [-3.084121465633132, 40.158582669054923],
              [-3.086963670437086, 40.154706936375973],
              [-3.085671759244292, 40.145663560724799],
              [-3.081847703408755, 40.138790595409702],
              [-3.079548101863395, 40.130935777264355],
              [-3.076085781233814, 40.124114487893323],
              [-3.071822475826536, 40.117551580940656],
              [-3.065672980024004, 40.111918849975027],
              [-3.062236496916768, 40.105330105500016],
              [-3.06303748169438, 40.098121243400598],
              [-3.068696051081929, 40.090550646095537],
              [-3.078669602719998, 40.082308255221889],
              [-3.093759121386313, 40.072644761946165],
              [-3.113447842244057, 40.068019721333101],
              [-3.163548142632663, 40.066185207781089],
              [-3.165589361759629, 40.076055405732419],
              [-3.169387580072737, 40.080396227304121],
              [-3.187267624900926, 40.088354397337568],
              [-3.227730272234254, 40.077864080862625],
              [-3.288243373832671, 40.053162747562752],
              [-3.32942949067845, 40.047685045328549],
              [-3.341909349436946, 40.044636134949641],
              [-3.352916428950039, 40.046057237351604],
              [-3.371752489086134, 40.057400214548309],
              [-3.406220669348386, 40.045617987779863],
              [-3.502054612830079, 40.048666897259423],
              [-3.515361293888532, 40.039675198451576],
              [-3.52233761199119, 40.028771470826783],
              [-3.52673010860758, 40.023242091749168],
              [-3.535566778683886, 40.020219020691329],
              [-3.575331794027079, 40.012493395553818],
              [-3.583625860844791, 40.009392809230761],
              [-3.589827032591472, 40.005878810858476],
              [-3.596751674749981, 40.000866197516999],
              [-3.59830196836117, 40.000039374317581],
              [-3.617008836388692, 39.996370348113132],
              [-3.632666794936654, 39.991719469078262],
              [-3.637395189236571, 39.987559516458546],
              [-3.636154953987926, 39.973426012200676],
              [-3.638712937951709, 39.96831004517243],
              [-3.646903651981916, 39.964796047699437],
              [-3.670132208734401, 39.959680081570482],
              [-3.687676356778411, 39.950068264239079],
              [-3.699226040449389, 39.946192532459477],
              [-3.717390306117778, 39.944642238848317],
              [-3.728242356899216, 39.941825873365431],
              [-3.735916306991953, 39.938957831039048],
              [-3.742634242676132, 39.934410304791655],
              [-3.75183264795831, 39.925832018033233],
              [-3.766121181847097, 39.91544505524503],
              [-3.774647792661483, 39.912447821709534],
              [-3.790383267373869, 39.902836005277337],
              [-3.797695482260679, 39.896014715906475],
              [-3.809710252126365, 39.893534247207768],
              [-3.825704108357854, 39.895446275125451],
              [-3.857278407872201, 39.910949204941872],
              [-3.86937069300302, 39.919553331021504],
              [-3.873866543306349, 39.926736354699031],
              [-3.864693976445892, 39.932911688923369],
              [-3.852963425621567, 39.937872626320612],
              [-3.790589972049531, 39.953065496875226],
              [-3.778601039706217, 39.953737291343018],
              [-3.773485072677886, 39.957044583240943],
              [-3.757310350192512, 39.971539821805337],
              [-3.751600103961607, 39.97482127528157],
              [-3.733099940609776, 39.979007066323092],
              [-3.717106086176926, 39.989445705954509],
              [-3.670648972671984, 40.027531236477344],
              [-3.65765234997599, 40.033448188283288],
              [-3.65023678230159, 40.034507555479294],
              [-3.638402878689874, 40.040346992019991],
              [-3.633674486188511, 40.043447577443786],
              [-3.6314265605873, 40.047064927704284],
              [-3.623339199344571, 40.056805935345679],
              [-3.620626186649162, 40.060965887965281],
              [-3.611634486941966, 40.081558946388228],
              [-3.610394252592698, 40.086804103726379],
              [-3.611014370217049, 40.096803493786283],
              [-3.610394252592698, 40.101583564030349],
              [-3.614114955640673, 40.106492825483528],
              [-3.624501919328054, 40.113210761167707],
              [-3.673336146945587, 40.131943468516297],
              [-3.691862147819847, 40.133106186701141],
              [-3.707856004051337, 40.136723537861016],
              [-3.721446905949904, 40.138273831472091],
              [-3.746639166564307, 40.135121568305763],
              [-3.758653937329342, 40.137757065735926],
              [-3.764390021082562, 40.139772447340548],
              [-3.771469691972641, 40.143544827232049],
              [-3.786895107423277, 40.160598048860905],
              [-3.793819748682438, 40.164990546376671],
              [-3.801674566827785, 40.16682505992857],
              [-3.818856980565215, 40.163336899978006],
              [-3.827977870582231, 40.164189562498237],
              [-3.83764136385787, 40.170106513404804],
              [-3.843119066091987, 40.175842597158223],
              [-3.848519253061113, 40.179976712255396],
              [-3.853557704824226, 40.18266388652917],
              [-3.901229214256801, 40.192017320542973],
              [-3.933061896189542, 40.203902900098811],
              [-3.98639197411029, 40.217054552425637],
              [-3.999026861600328, 40.221576240251309],
              [-4.007786017310849, 40.227519029579597],
              [-4.012746954708177, 40.234211126842084],
              [-4.019077317664056, 40.241109931478164],
              [-4.026647914969288, 40.246794339287348],
              [-4.035717129042268, 40.251445217422841],
              [-4.057292039396089, 40.258266506793873],
              [-4.066051195106525, 40.259222521202403],
              [-4.074939541126867, 40.253615627759089],
              [-4.084422166349867, 40.248964748724134],
              [-4.09796139230437, 40.245528266516274],
              [-4.116797450641798, 40.247879544005784],
              [-4.132558762876641, 40.251806951729449],
              [-4.160748257026427, 40.273356025460274],
              [-4.166923591250679, 40.279402167575952],
              [-4.174675054809882, 40.28366547298323],
              [-4.183899299413127, 40.284130560976607],
              [-4.208652309556413, 40.272270819842461],
              [-4.234102951689835, 40.268886013578665],
              [-4.244980840893106, 40.262865708985331],
              [-4.250510219970636, 40.257491360437982],
              [-4.257770758014061, 40.246690986499758],
              [-4.266064825731121, 40.237079169168382],
              [-4.272860276680461, 40.231446438202553],
              [-4.286864589729049, 40.222351385707853],
              [-4.291980556757267, 40.220232652215103],
              [-4.296037156589506, 40.220801092995956],
              [-4.309989792794738, 40.232841702182796],
              [-4.325286017036035, 40.237621772426891],
              [-4.334200202377474, 40.243332017758476],
              [-4.340995653326701, 40.249274807086678],
              [-4.348617925676706, 40.262659003410263],
              [-4.35122758558461, 40.269428615937798],
              [-4.351563483268137, 40.276714993302377],
              [-4.348979660882662, 40.291494451807509],
              [-4.349935675291192, 40.298315742077918],
              [-4.353863083914149, 40.304155177719238],
              [-4.36011593340362, 40.308082587241543],
              [-4.36585201715684, 40.309477851221814],
              [-4.371071336073271, 40.307979234454152],
              [-4.383551194831767, 40.29348399499041],
              [-4.388227912288357, 40.286145942581143],
              [-4.394842495184974, 40.279453844419479],
              [-4.425434942768334, 40.255217597314342],
              [-4.43055090979658, 40.247414456012407],
              [-4.439749315078757, 40.23917206513876],
              [-4.453727789705709, 40.231653143777592],
              [-4.484061854870674, 40.221627916195374],
              [-4.496696743260088, 40.215814317177021],
              [-4.50636023563635, 40.20891551433958],
              [-4.511243658667894, 40.202817695380375],
              [-4.520571255159268, 40.198838609014629],
              [-4.532999437074352, 40.197960109871175],
              [-4.549303350769009, 40.200595608200686],
              [-4.571885952374771, 40.212016099763176],
              [-4.558140020845315, 40.229172675078885],
              [-4.549716762818321, 40.263124091403725],
              [-4.53697852164143, 40.283536281773948],
              [-4.531578334672361, 40.289298203948888],
              [-4.52982133638551, 40.29666209657843],
              [-4.53142330594082, 40.304568589768635],
              [-4.536616787334822, 40.317461860576444],
              [-4.538037888837437, 40.325032456982299],
              [-4.537624477687501, 40.334075833532935],
              [-4.53214677635259, 40.338416653305998],
              [-4.524989590197379, 40.338985094086823],
              [-4.518064948038926, 40.334566759048741],
              [-4.506928677316637, 40.321802680349393],
              [-4.501089239876478, 40.317901109248808],
              [-4.496360845576561, 40.316454169324501],
              [-4.479281784626608, 40.31761688930807],
              [-4.469463262619456, 40.320097358006691],
              [-4.457629359907031, 40.326221015387503],
              [-4.45003292507937, 40.335367742926977],
              [-4.44029191743806, 40.350198880074274],
              [-4.436519538445935, 40.359603990032113],
              [-4.434891729569728, 40.366916204918951],
              [-4.434736700838073, 40.372548936784071],
              [-4.431481085783474, 40.38931793937148],
              [-4.427398646630252, 40.395105699068765],
              [-4.418794520550648, 40.402495429220735],
              [-4.413368496059235, 40.405906073906252],
              [-4.40727067710003, 40.408464056970629],
              [-4.401224534984266, 40.409549261689094],
              [-4.394480759979103, 40.408929144964091],
              [-4.386419237158066, 40.407378852252194],
              [-4.38073482934891, 40.405673529909492],
              [-4.374947068752249, 40.404717516400311],
              [-4.370502896191709, 40.405053412285113],
              [-4.363319870715429, 40.407766424980494],
              [-4.334975347833989, 40.409420071379159],
              [-4.324820929042573, 40.41326996473704],
              [-4.323709885902502, 40.418515122974568],
              [-4.328722500143272, 40.432571112866512],
              [-4.320635138001251, 40.454378567217177],
              [-4.316707729378209, 40.462543442825606],
              [-4.315234951032096, 40.469261380308424],
              [-4.316397671015665, 40.475979315992717],
              [-4.318748949404466, 40.482490546101829],
              [-4.318258022090021, 40.494686184020125],
              [-4.316940273374883, 40.500163886254327],
              [-4.317250331737426, 40.505021470864179],
              [-4.318748949404466, 40.510705877774072],
              [-4.318283861411089, 40.525278632502946],
              [-4.319214035599259, 40.533727728951575],
              [-4.318981493401225, 40.544037177373909],
              [-4.312082688765059, 40.553545641018516],
              [-4.304667121090745, 40.559100856719127],
              [-4.297174038151354, 40.562950750976384],
              [-4.293298306371753, 40.56594798451188],
              [-4.290507778411239, 40.569358629197396],
              [-4.288621588915191, 40.572950141036088],
              [-4.284461636295475, 40.584138089501167],
              [-4.283996548302014, 40.591114406704563],
              [-4.284384121030342, 40.597987372019659],
              [-4.286683721676411, 40.605377102171545],
              [-4.290197720048695, 40.612456773061623],
              [-4.290301072836257, 40.621629339922166],
              [-4.285960252163932, 40.626771145372018],
              [-4.278880581273796, 40.627572130149716],
              [-4.273480394304727, 40.623334662264782],
              [-4.266349045671859, 40.611423245186685],
              [-4.259760301196877, 40.607134101357602],
              [-4.251182012639788, 40.604446926184735],
              [-4.242086961044379, 40.602793280685361],
              [-4.223173387441875, 40.605454617436592],
              [-4.205551724132732, 40.611164862768291],
              [-4.195009730814434, 40.613180243473451],
              [-4.184364385607921, 40.616358344162293],
              [-4.173951585297402, 40.622869575170697],
              [-4.171290249445434, 40.62989756921742],
              [-4.17103186702704, 40.644909573518063],
              [-4.168241339965874, 40.660929267271811],
              [-4.168189663122462, 40.66759552701204],
              [-4.161290859385645, 40.687310086291419],
              [-4.132739630929166, 40.719220283489378],
              [-4.128863898250273, 40.725473131180095],
              [-4.090106574159051, 40.769139715825148],
              [-4.079642096105886, 40.776451930711985],
              [-4.059850023359843, 40.784125881704071],
              [-4.047473517388909, 40.783970852073153],
              [-4.036311408244899, 40.782317206573865],
              [-4.027836473374578, 40.779604193878484],
              [-4.018741420879905, 40.779371649881725],
              [-3.995848760911571, 40.783014838564],
              [-3.990112677158265, 40.784565131275812],
              [-3.98409237256493, 40.788725083895528],
              [-3.98024247920705, 40.796631577984911],
              [-3.975746628903806, 40.803659572931011],
              [-3.968615282069578, 40.818284002704573],
              [-3.966444870834152, 40.825647895334114],
              [-3.965437181380764, 40.840427353839246],
              [-3.963654343772902, 40.84802379046566],
              [-3.959365199943989, 40.855981961398371],
              [-3.952931484200633, 40.865206204202892],
              [-3.949908413142708, 40.873086859870753],
              [-3.949598353880958, 40.880579941910852],
              [-3.95306067540983, 40.894300035018702],
              [-3.951071133126277, 40.901379705908667],
              [-3.947893032437435, 40.908278510544747],
              [-3.934793056954049, 40.926158556272313],
              [-3.919884406340259, 40.940240382787479],
              [-3.911512824257414, 40.950265611268989],
              [-3.902081874978478, 40.958533840564357],
              [-3.891875780243083, 40.964838365098515],
              [-3.805731166659996, 40.98726593707336],
              [-3.796222703015388, 40.991451728114797],
              [-3.787127651419979, 40.996774399818619],
              [-3.773485072677886, 41.013000800046896],
              [-3.768472459336493, 41.020623074195456],
              [-3.762529670008234, 41.028064480291576],
              [-3.752762823945204, 41.043024806849317],
              [-3.743021816303724, 41.051758124138061],
              [-3.72790646011498, 41.061990058194695],
              [-3.684911668138966, 41.084701850110307],
              [-3.665429652856176, 41.099972235929854],
              [-3.647627122393772, 41.117413032085679],
              [-3.641477626591154, 41.124621894185125],
              [-3.633829514920137, 41.13195994749357],
              [-3.623881801703789, 41.139401354488982],
              [-3.592204149401965, 41.154077460206693],
              [-3.545772874318658, 41.161751410299317],
              [-3.541612921698942, 41.144207262255307],
              [-3.503863287960371, 41.115681871321343],
              [-3.493166265910446, 41.102168483788446],
              [-3.484045375893402, 41.095011298532611],
              [-3.476655645741431, 41.090825507491203],
              [-3.453272061156696, 41.081988837414897],
              [-3.445753139795528, 41.080386867859673],
              [-3.436451381725874, 41.077415472745898],
              [-3.424669154957513, 41.043231513323676],
              [-3.409786342765528, 41.012199815269284],
              [-3.409450445981292, 40.998479723060811],
              [-3.412266812363441, 40.989177964991043],
              [-3.420147467131926, 40.976207179817564],
              [-3.423532274295013, 40.968559068146575],
              [-3.427924770911403, 40.9613243685247],
              [-3.436373867360089, 40.938431708556564],
              [-3.440042893564652, 40.930835272829611],
              [-3.444874639752783, 40.923497219521082],
              [-3.448156094128478, 40.914014594298166],
              [-3.451644253179666, 40.907038276195507],
              [-3.452651942633054, 40.899105942785113],
              [-3.447742682079081, 40.890734360702183],
              [-3.447380946873182, 40.88045075070157],
              [-3.451489224448125, 40.875128078997719],
              [-3.457173631357932, 40.871097316687766],
              [-3.461462775186931, 40.869521186453696],
              [-3.466113654221715, 40.866653144127312],
              [-3.469782681325626, 40.863009955445094],
              [-3.472366501912518, 40.858152370835271],
              [-3.478154263408555, 40.829084378441351],
              [-3.480040452005255, 40.823167425736145],
              [-3.485208095876914, 40.812909654157139],
              [-3.491383429201875, 40.803711248874961],
              [-3.494716558622258, 40.799577135576342],
              [-3.498437261670233, 40.796218166834976],
              [-3.498437261670233, 40.792549139731065],
              [-3.495879278605827, 40.789035143157335],
              [-3.48874793087225, 40.785521144785051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5834",
        OBJECTID_1: 7273,
        diss_me: 5834,
        adm1_cod_1: "ESP-5834",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Málaga",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602102,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 36.838000000000001,
        longitude: -4.6860600000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2514254,
        gn_name: "Provincia de Malaga",
        gns_id: -390789,
        gns_name: "Malaga, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.MA",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.320300915754643, 37.186572234620911],
            [-4.287078257855342, 37.119676479885669],
            [-4.280629283314738, 37.056691858742312],
            [-4.27332337447416, 37.038048059270523],
            [-4.260963755073249, 37.023974572168783],
            [-4.148474736919525, 36.948465536255441],
            [-3.875618281458486, 36.853049273005581],
            [-3.827234490079661, 36.855455278738077],
            [-3.768204947000669, 36.801918899937306],
            [-3.782278434102409, 36.764510450996568],
            [-3.77724176569518, 36.73580342668572],
            [-3.803433052999935, 36.7424743360001],
            [-3.83854125499991, 36.751969933000083],
            [-3.889444745999953, 36.743388384000042],
            [-3.960961123999937, 36.72679277900005],
            [-4.056711391999954, 36.747992255000042],
            [-4.079957949999965, 36.743564192000122],
            [-4.110172195999922, 36.725732647000086],
            [-4.19701087099989, 36.715399481000077],
            [-4.224720831999889, 36.71238841400006],
            [-4.403187628999888, 36.723863023],
            [-4.420318162999934, 36.719875393000052],
            [-4.433257615999935, 36.710679429000066],
            [-4.461171027999882, 36.671454169000043],
            [-4.466867641999954, 36.663031317000076],
            [-4.480458136999914, 36.65119049700013],
            [-4.491118943999936, 36.636664130000099],
            [-4.495350714999859, 36.620550848000022],
            [-4.524826052999913, 36.580715264000048],
            [-4.587799793999864, 36.573817661000078],
            [-4.615021325999862, 36.530555761000102],
            [-4.6498103509999, 36.512111721000025],
            [-4.659250454999921, 36.50763580900005],
            [-4.680287238999966, 36.50079987200013],
            [-4.722767706999917, 36.492987372000044],
            [-4.764230923999918, 36.493963934000107],
            [-4.880319790999863, 36.505682684000107],
            [-4.902658657999893, 36.506537177000013],
            [-4.912180141999897, 36.504014390000094],
            [-4.92353268099987, 36.496852932000053],
            [-4.994166374999907, 36.457093958000115],
            [-5.062489386999914, 36.452215887000079],
            [-5.108713344999899, 36.429022528000047],
            [-5.121042446999866, 36.425563869000143],
            [-5.1576228509999, 36.421942450000074],
            [-5.173654751999891, 36.417425848000022],
            [-5.1865535149999, 36.411200262000079],
            [-5.199655727999868, 36.397162177000013],
            [-5.227121548999918, 36.357936916000085],
            [-5.227935350999928, 36.348537502000084],
            [-5.244699673999946, 36.3120791690001],
            [-5.253651495959815, 36.29710521021866],
            [-5.277792406794163, 36.336911557722203],
            [-5.320001881081964, 36.324914486514359],
            [-5.335108083071532, 36.395259948887485],
            [-5.355355886365118, 36.433690125698718],
            [-5.391467948187199, 36.46583612333842],
            [-5.415352226723854, 36.50700189662021],
            [-5.42620671916481, 36.51805414278445],
            [-5.441301935036222, 36.5236461839242],
            [-5.477095393137859, 36.521833439361728],
            [-5.513185482723827, 36.511780948831699],
            [-5.550154477946791, 36.493203067867256],
            [-5.571413023891893, 36.493565616060323],
            [-5.585651305463358, 36.498959903476816],
            [-5.600098327775584, 36.507957706882621],
            [-5.607843689434731, 36.51966913272426],
            [-5.601944030692493, 36.533215276263945],
            [-5.592704528191348, 36.538642522034792],
            [-5.509219417642072, 36.559955999469736],
            [-5.483467463952309, 36.573139593917148],
            [-5.472788752099206, 36.582807563118536],
            [-5.454540460973476, 36.610339302117097],
            [-5.444938409380086, 36.618172554519731],
            [-5.383744559663626, 36.634597115864239],
            [-5.35561955859572, 36.648846384453122],
            [-5.339282887994898, 36.666347605157853],
            [-5.320968678361794, 36.728727979013001],
            [-5.300709888050875, 36.750272169425017],
            [-5.29740300354581, 36.770695754205747],
            [-5.33269109122088, 36.805654251142812],
            [-5.341392263142438, 36.82557246459757],
            [-5.335020192328045, 36.839667924834799],
            [-5.30490666610973, 36.865068316449623],
            [-5.29072331602822, 36.872363238272783],
            [-5.262708177939999, 36.873879351350951],
            [-5.194076583267702, 36.829055131489071],
            [-5.161765791158302, 36.830043901005212],
            [-5.131146893614215, 36.843776811250791],
            [-5.105493816786208, 36.866276811925331],
            [-5.088080485925644, 36.893566852728014],
            [-5.084663737541433, 36.92538325962974],
            [-5.097034343959791, 36.9559032803121],
            [-5.142243085150227, 37.003704795739708],
            [-4.916594883946743, 37.113040737739851],
            [-4.902103916262718, 37.134211392941381],
            [-4.946411779579989, 37.155327116653154],
            [-4.95692569606399, 37.166774870263993],
            [-4.95482730703452, 37.170147673276517],
            [-4.952168615189464, 37.172806365121687],
            [-4.945357091556502, 37.17666256632441],
            [-4.933535801835802, 37.179584929500919],
            [-4.920835606478022, 37.179562957264608],
            [-4.878340487723307, 37.166027799843079],
            [-4.870166659363804, 37.166840788771367],
            [-4.861586336540199, 37.174157683730073],
            [-4.853533358177913, 37.188110320834596],
            [-4.848128083744086, 37.192658660968362],
            [-4.839888337776586, 37.191779754432105],
            [-4.796349518015802, 37.15852413817845],
            [-4.777266264826238, 37.162248503265801],
            [-4.73808901759412, 37.181243864812529],
            [-4.721708401621498, 37.195646941752983],
            [-4.718434475470673, 37.214620331063401],
            [-4.735595121117939, 37.244931611904349],
            [-4.719818753332788, 37.259708224055288],
            [-4.653230615300657, 37.255928926578719],
            [-4.613778709719668, 37.255236787647675],
            [-4.596266501997519, 37.221607636183421],
            [-4.539313374812423, 37.211313446558336],
            [-4.480624407690271, 37.238757294813325],
            [-4.444940812568433, 37.277550021616264],
            [-4.383505263756916, 37.277550021616264],
            [-4.372200331480144, 37.215510223717814],
            [-4.326266492104907, 37.20271115149842],
            [-4.320300915754643, 37.186572234620911],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5835",
        OBJECTID_1: 1305,
        diss_me: 5835,
        adm1_cod_1: "ESP-5835",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 5,
        name: "Melilla",
        name_alt: null,
        name_local: null,
        type: "Ciudades Autónomas",
        type_en: "Autonomous City",
        code_local: null,
        code_hasc: "ES.CE",
        note: null,
        hasc_maybe: null,
        region: "Melilla",
        region_cod: "ES.CE",
        provnum_ne: 21,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CE",
        area_sqkm: 0,
        sameascity: 9,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP00",
        fips_alt: "SP|",
        woe_id: 55862984,
        woe_label: "Melilla Province, ES, Spain",
        woe_name: "Melilla",
        latitude: 35.293399999999998,
        longitude: -2.94015,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 5,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 8335120,
        gn_name: "Melilla",
        gns_id: -99,
        gns_name: null,
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.ME",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.912906597796621, 35.27691564016672],
            [-2.943045613999885, 35.267874248000112],
            [-2.96376786299993, 35.286219381000109],
            [-2.966920125999877, 35.313866272000013],
            [-2.947817735929419, 35.329768125043614],
            [-2.92202714799987, 35.287990627000113],
            [-2.913197394999912, 35.277248440000079],
            [-2.912912563999953, 35.276922919000071],
            [-2.912906597796621, 35.27691564016672],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5836",
        OBJECTID_1: 1324,
        diss_me: 5836,
        adm1_cod_1: "ESP-5836",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 5,
        name: "Murcia",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.MU",
        note: null,
        hasc_maybe: null,
        region: "Murcia",
        region_cod: "ES.MU",
        provnum_ne: 17,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "MU",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP96",
        fips_alt: "SP31",
        woe_id: 12578025,
        woe_label: "Murcia, ES, Spain",
        woe_name: "Región de Murcia",
        latitude: 37.913600000000002,
        longitude: -1.4575800000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355234,
        gn_name: "Murcia",
        gns_id: -393421,
        gns_name: "Region de Murcia, Comunidad Autonoma de la",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.MU",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP31",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.740142381999902, 37.70246002800009],
              [-0.726470506999874, 37.665187893000123],
              [-0.746896938999924, 37.68378327000012],
              [-0.751210089999859, 37.713283596000096],
              [-0.75031490799995, 37.746039130000113],
              [-0.754505988999881, 37.774400132000054],
              [-0.749419725999871, 37.774888414000131],
              [-0.748199022999927, 37.773179429000137],
              [-0.748199022999927, 37.770493882000054],
              [-0.746978318999908, 37.768215236000074],
              [-0.743641730999883, 37.763983466000141],
              [-0.737538214999915, 37.753648179000052],
              [-0.732736782999922, 37.747748114000117],
              [-0.741851365999906, 37.725531317000062],
              [-0.740142381999902, 37.70246002800009],
            ],
          ],
          [
            [
              [-1.077861496610808, 38.687613023322299],
              [-1.022128465256401, 38.654540107040404],
              [-1.00543697793421, 38.590306301494664],
              [-1.005669521031535, 38.564623115364483],
              [-1.015255499941304, 38.537027900417328],
              [-1.018795334936726, 38.52162832338847],
              [-1.016547410234807, 38.498425605057619],
              [-1.019441290982797, 38.490622463755798],
              [-1.024014654752534, 38.483387763234745],
              [-1.030706752914284, 38.477186591488064],
              [-1.068947313068037, 38.44964305338415],
              [-1.075613572808152, 38.442124132022983],
              [-1.082098965394948, 38.431142890032405],
              [-1.084450242884373, 38.415691637059311],
              [-1.083959315569928, 38.374608873001094],
              [-1.080987922254792, 38.359312648759825],
              [-1.07667293910481, 38.349700833226805],
              [-1.068172165812769, 38.347065334897266],
              [-1.048690151429327, 38.34471405740787],
              [-1.011870692778217, 38.334818020135558],
              [-0.999778407647398, 38.328539334023006],
              [-0.994662440619152, 38.324947822184342],
              [-0.983629522684339, 38.310917669814572],
              [-0.969160121642233, 38.270842597008297],
              [-0.969418504060712, 38.258698635034037],
              [-0.973449266370494, 38.241903794924113],
              [-0.976446499006727, 38.234410711984836],
              [-0.983164434690906, 38.202991441201974],
              [-0.985954962651419, 38.195188299900153],
              [-0.993654751165764, 38.180977281276583],
              [-1.022826097246536, 38.146302395439292],
              [-1.028097093006465, 38.135346991870264],
              [-1.032903000772905, 38.11689850536176],
              [-1.030810105701789, 38.097054754873099],
              [-1.025849168304461, 38.07886465078299],
              [-1.020035570185371, 38.06850352641662],
              [-1.01326595675846, 38.061708076366656],
              [-1.006987270646022, 38.056824653335084],
              [-1.003111537967129, 38.05271637576017],
              [-0.982234258704011, 38.02641307200561],
              [-0.966266241793591, 38.000652371509759],
              [-0.965878669065262, 38.000109768251249],
              [-0.965723639434373, 38.000006415463659],
              [-0.955000779862104, 37.98432261849409],
              [-0.948024460860154, 37.97752716754475],
              [-0.934381883017522, 37.960473945016602],
              [-0.92306474424251, 37.941301988096271],
              [-0.91668270534251, 37.933550522738514],
              [-0.888699916767678, 37.908022366239223],
              [-0.881000129152625, 37.902958076054418],
              [-0.838702969166661, 37.867973130955207],
              [-0.830563931080576, 37.864200751063819],
              [-0.812554694143813, 37.860299180862583],
              [-0.804260627326016, 37.856190904186931],
              [-0.796922573118195, 37.851074937158714],
              [-0.791548223671583, 37.846372382179695],
              [-0.787104051111044, 37.844279487108665],
              [-0.779714320959158, 37.842910061550114],
              [-0.761033291353357, 37.845907294186318],
              [-0.760574989999242, 37.845898277536051],
              [-0.760568813999953, 37.845851955000015],
              [-0.75609290299991, 37.806057033000016],
              [-0.760731574999852, 37.796128648000106],
              [-0.787993943999936, 37.822821356000034],
              [-0.812408006999874, 37.770086981000063],
              [-0.825550910999937, 37.761379299000154],
              [-0.847808397999898, 37.756089585],
              [-0.856109178999873, 37.742499091000028],
              [-0.852609829999977, 37.723781643000066],
              [-0.819813605999855, 37.676703192000062],
              [-0.809925910999937, 37.666896877000127],
              [-0.777699347999913, 37.650091864000117],
              [-0.754790818999908, 37.641302802000055],
              [-0.734852667999917, 37.639349677],
              [-0.726470506999874, 37.651516018000095],
              [-0.707997199999852, 37.643011786000059],
              [-0.701242641999897, 37.631333726000051],
              [-0.705311652999853, 37.619533596000096],
              [-0.719715949999937, 37.610541083000058],
              [-0.727894660999937, 37.603420315000037],
              [-0.736643032999922, 37.599432684000035],
              [-0.745676235999923, 37.599432684000035],
              [-0.754505988999881, 37.604315497000115],
              [-0.764027472999885, 37.599595445000105],
              [-0.791493292999917, 37.591457424000097],
              [-0.807972785999908, 37.588446356000176],
              [-0.836415167999917, 37.576402085000083],
              [-0.873890753999945, 37.576402085000083],
              [-0.886708136999886, 37.574286200000145],
              [-0.900624152999853, 37.569037177000084],
              [-0.92516028599988, 37.555894273000106],
              [-0.933705206999917, 37.561021226000136],
              [-0.973500128999888, 37.576402085000083],
              [-1.006418423999889, 37.581447658000101],
              [-1.069935675999886, 37.582586981000034],
              [-1.087757941999939, 37.58087799700003],
              [-1.103423631999931, 37.576402085000083],
              [-1.114654100999928, 37.568670966000141],
              [-1.119984503999888, 37.559027411],
              [-1.116851365999906, 37.549383856000119],
              [-1.105766687999903, 37.532134935000059],
              [-1.137171939999917, 37.538000623000087],
              [-1.167057480999887, 37.543755316000087],
              [-1.173408476999924, 37.555075788000167],
              [-1.178585325999904, 37.563883357000137],
              [-1.199732067999975, 37.566564654000146],
              [-1.22899329299986, 37.57607656500015],
              [-1.242698867999934, 37.579202797000065],
              [-1.257666802999921, 37.567013400000135],
              [-1.261670586999912, 37.557661268000018],
              [-1.289784308999913, 37.558417059000035],
              [-1.323735140999872, 37.561990821000151],
              [-1.353260870999918, 37.549912828000103],
              [-1.369170701999849, 37.541367906000076],
              [-1.413685675999915, 37.506537177000141],
              [-1.430368618999864, 37.498114325000088],
              [-1.450225389999957, 37.494696356000176],
              [-1.455555792999945, 37.492702541000043],
              [-1.46349036399991, 37.487982489000117],
              [-1.47089596299989, 37.482245184000149],
              [-1.474110480999855, 37.477606512000037],
              [-1.476673956999946, 37.472723700000145],
              [-1.48729407499988, 37.439764716000084],
              [-1.488352016999954, 37.433213609000106],
              [-1.496937628999945, 37.427801825000145],
              [-1.502797003999916, 37.429266669000029],
              [-1.508168097999942, 37.432684637000037],
              [-1.51500403599988, 37.433213609000106],
              [-1.523915167999917, 37.428656317000062],
              [-1.543731248999933, 37.414984442000119],
              [-1.552967902999882, 37.412176825000174],
              [-1.56773841099988, 37.409938869000186],
              [-1.61872311099998, 37.392279364000089],
              [-1.641617712793646, 37.381036447237179],
              [-1.732421026609074, 37.438438625941885],
              [-1.741516079103775, 37.441332505790527],
              [-1.79270158511008, 37.450737617547034],
              [-1.7976625234067, 37.449988307814195],
              [-1.802235887176408, 37.446345120031268],
              [-1.80345028310407, 37.441332505790527],
              [-1.805698207806074, 37.437456773111549],
              [-1.811227585984341, 37.438800361147756],
              [-1.818927375398061, 37.441565049787201],
              [-1.826446295859853, 37.445363268100394],
              [-1.838409389781475, 37.454820054901617],
              [-1.987831793602425, 37.638994858925528],
              [-1.996590949312861, 37.64783152900192],
              [-2.00072506261148, 37.653825995173534],
              [-2.004239060983849, 37.663954576442521],
              [-2.005530972176643, 37.680852770239213],
              [-2.00460079618972, 37.688500881010938],
              [-2.004445766558831, 37.704546414085669],
              [-2.002972988212775, 37.711858628972507],
              [-1.998709682805583, 37.72214223897312],
              [-1.99519568533259, 37.735474758453293],
              [-1.996125861319399, 37.742296047824354],
              [-1.99997575467728, 37.752476305037376],
              [-2.002817958581886, 37.788029690018263],
              [-2.001422694601615, 37.79428253770898],
              [-1.994110479714863, 37.809682114737839],
              [-1.993335334258234, 37.815986640171374],
              [-1.993257818993186, 37.833840847477219],
              [-1.991681687859597, 37.841153062363887],
              [-1.987366705608963, 37.848129381365922],
              [-1.976127082099197, 37.86014415123158],
              [-1.975687831628079, 37.865854397462485],
              [-1.982793340939963, 37.870815334859898],
              [-2.076973638922254, 37.881098944860511],
              [-2.112682054433236, 37.895309964383429],
              [-2.1254719706549, 37.898746445691827],
              [-2.171799892950759, 37.896446845045929],
              [-2.209648157136769, 37.915668080317701],
              [-2.209652878577515, 37.915670477910325],
              [-2.232235480183192, 37.926419175904201],
              [-2.261432663786394, 37.953523465335749],
              [-2.281819016634273, 37.978276476378213],
              [-2.294996507382905, 37.99835276996437],
              [-2.29569413937304, 37.999799709888705],
              [-2.296236741732173, 38.000574856244683],
              [-2.304298264553211, 38.006362616841344],
              [-2.338533900818845, 38.026490587270857],
              [-2.324219530307005, 38.062379869035695],
              [-2.318819342438672, 38.069356187138382],
              [-2.280966355912597, 38.103230089097437],
              [-2.250528937060778, 38.136277166957612],
              [-2.222210251701711, 38.18283763325023],
              [-2.211926641701183, 38.195937607834352],
              [-2.182806973363114, 38.217900091815849],
              [-2.170327113705355, 38.22381704452097],
              [-2.158751593411239, 38.227098497097998],
              [-2.139088710975187, 38.230328273730905],
              [-2.127409836994389, 38.235185859240019],
              [-2.085061001064275, 38.265829982767528],
              [-2.059067755672373, 38.290376288235151],
              [-2.042893032287623, 38.298360296690404],
              [-2.031730923143527, 38.299910590301479],
              [-2.023359341060768, 38.298980414314755],
              [-2.015995449330489, 38.295078844113348],
              [-1.998890549958844, 38.282159735783196],
              [-1.992792730999753, 38.279524238352863],
              [-1.984808721645209, 38.279265855035092],
              [-1.958117845162349, 38.286655585187177],
              [-1.926181810442131, 38.291048081803567],
              [-1.910342982942268, 38.295518092785713],
              [-1.885848354318114, 38.306240953257443],
              [-1.848021206213673, 38.329081936382337],
              [-1.795724657067296, 38.353912461790756],
              [-1.792624070744324, 38.359829412697238],
              [-1.792779100375128, 38.365100409356458],
              [-1.791073778032427, 38.366934922908385],
              [-1.786474574941678, 38.367089952539189],
              [-1.773865525873276, 38.369492905972848],
              [-1.767147590189097, 38.375073960994442],
              [-1.759912888768838, 38.378122870474002],
              [-1.750533617232605, 38.380344956754314],
              [-1.743092210237279, 38.380499986385317],
              [-1.736374273653809, 38.379388943245161],
              [-1.72500545893476, 38.37362702107022],
              [-1.719036831184866, 38.371353257946495],
              [-1.702655402225048, 38.37362702107022],
              [-1.695989141585642, 38.37287771313602],
              [-1.690873174557396, 38.370216376384704],
              [-1.686558193206054, 38.366624864545841],
              [-1.683121710998194, 38.362439073504405],
              [-1.681881475749464, 38.357607327316302],
              [-1.681674771073887, 38.352543036232206],
              [-1.684465298135024, 38.34732371731566],
              [-1.685162930125159, 38.341897691025693],
              [-1.684000210141591, 38.336600856844129],
              [-1.681933152592904, 38.331562405080845],
              [-1.678057419914012, 38.326911526046061],
              [-1.673458217722583, 38.322777410948717],
              [-1.667799648335119, 38.31967682642447],
              [-1.654596320064059, 38.3146642121837],
              [-1.646147222716081, 38.312235419429058],
              [-1.636302863186557, 38.310788479504751],
              [-1.626742722698509, 38.31140859622964],
              [-1.567314825818556, 38.326213894055925],
              [-1.494709438190057, 38.373110256233346],
              [-1.485278489810469, 38.381378486428062],
              [-1.481015184403191, 38.388277289265503],
              [-1.479051478742718, 38.395692856939732],
              [-1.477940435602648, 38.403676866294362],
              [-1.478483038861157, 38.411454169174576],
              [-1.483288946627567, 38.435690416279712],
              [-1.483030565108436, 38.452226873971071],
              [-1.485717739382125, 38.483697822496552],
              [-1.489645148005138, 38.500001736191123],
              [-1.494786952555813, 38.513411770037166],
              [-1.498352626872247, 38.51935456026483],
              [-1.500135463580733, 38.523333644831908],
              [-1.501324021986022, 38.529173082271896],
              [-1.501995814655089, 38.534909166025116],
              [-1.499980434849192, 38.540593573834386],
              [-1.497448289307073, 38.545864570493521],
              [-1.485149297701895, 38.562917792122462],
              [-1.476235114159124, 38.569790758336765],
              [-1.464530401756605, 38.576198634759223],
              [-1.458665126794187, 38.582864895398629],
              [-1.454272630177712, 38.589686183870199],
              [-1.452929043040882, 38.604930732167603],
              [-1.453264939825118, 38.612346299841832],
              [-1.450319383132978, 38.62772003844897],
              [-1.433627895810702, 38.655832018233085],
              [-1.402828741753012, 38.68497752499276],
              [-1.385775519224865, 38.696914781392039],
              [-1.371486986235368, 38.7027025419887],
              [-1.363502976880909, 38.701307278008429],
              [-1.357766893127604, 38.696398017454541],
              [-1.35285763167434, 38.690455227226963],
              [-1.34533871121252, 38.686579495447361],
              [-1.334202439590882, 38.686734524178988],
              [-1.278236864239858, 38.711590888009042],
              [-1.252812058729518, 38.731873888069558],
              [-1.243174403875599, 38.738075059816211],
              [-1.23338172029014, 38.742131658748988],
              [-1.174186367406946, 38.754094754469449],
              [-1.164497035709616, 38.752621975223931],
              [-1.128426885892054, 38.743371893997718],
              [-1.119926112600012, 38.740064602099679],
              [-1.114293381634184, 38.734690253552415],
              [-1.112743088922372, 38.727868964181383],
              [-1.111916265723039, 38.720505073350481],
              [-1.109254929871071, 38.713761298345318],
              [-1.105301682826337, 38.70750844975511],
              [-1.077861496610808, 38.687613023322299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5837",
        OBJECTID_1: 3492,
        diss_me: 5837,
        adm1_cod_1: "ESP-5837",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Navarra",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.NA",
        note: null,
        hasc_maybe: null,
        region: "Foral de Navarra",
        region_cod: "ES.NA",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "NA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP88",
        fips_alt: "SP32",
        woe_id: 12578026,
        woe_label: "Navarre, ES, Spain",
        woe_name: "Comunidad Foral de Navarra",
        latitude: 42.749299999999998,
        longitude: -1.63845,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 6355235,
        gn_name: "Provincia de Navarra",
        gns_id: -393793,
        gns_name: "Navarra, Comunidad Foral de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.NA",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP32",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.176330939321417, 42.403665270535797],
              [-1.190386928314126, 42.402838446437173],
              [-1.197001512110035, 42.411933498931788],
              [-1.194521043411413, 42.424335842425236],
              [-1.186252814116045, 42.430950426221116],
              [-1.173023647423378, 42.435911364517906],
              [-1.163928594928677, 42.440872301015943],
              [-1.158140835231393, 42.4367381859186],
              [-1.158140835231393, 42.425989487924525],
              [-1.165582241327343, 42.411933498931788],
              [-1.176330939321417, 42.403665270535797],
            ],
          ],
          [
            [
              [-1.067190313881895, 42.4367381859186],
              [-1.075458543177234, 42.432604071720604],
              [-1.087034064370556, 42.433430894919823],
              [-1.108531460358591, 42.4367381859186],
              [-1.123414272550576, 42.440045477816525],
              [-1.134162971443914, 42.446660062511896],
              [-1.133336147345318, 42.456581936407261],
              [-1.12837520994799, 42.466503811202003],
              [-1.124241095749994, 42.473118394098705],
              [-1.112665574556587, 42.482213447492668],
              [-1.106050990760622, 42.483040269792539],
              [-1.098609584664587, 42.482213447492668],
              [-1.095302293665924, 42.474772040497342],
              [-1.094475469567215, 42.463196519303963],
              [-1.085380417971891, 42.459062405105968],
              [-1.07463171997793, 42.4507941758106],
              [-1.067190313881895, 42.445833238413186],
              [-1.067190313881895, 42.4367381859186],
            ],
          ],
          [
            [
              [-1.645036986999912, 43.292371521000078],
              [-1.645192016999943, 43.287643128000084],
              [-1.647982543999916, 43.281545309000037],
              [-1.646328897999865, 43.272786153000155],
              [-1.63904252099988, 43.255681255000027],
              [-1.634081583999915, 43.251107890000171],
              [-1.621885945999907, 43.246999613000142],
              [-1.611085571999894, 43.245811056000136],
              [-1.598476521999942, 43.246999613000142],
              [-1.587572794999886, 43.251598817000016],
              [-1.581888386999935, 43.26035797100009],
              [-1.57398189299991, 43.277437032000037],
              [-1.559047403999926, 43.284077454000126],
              [-1.539203654999937, 43.283560690000101],
              [-1.508094441999901, 43.276765239000142],
              [-1.466391560999881, 43.26077138300009],
              [-1.455281127999854, 43.262218323000084],
              [-1.444222371999928, 43.265112203000072],
              [-1.432233438999987, 43.265008850000143],
              [-1.403501342999959, 43.243330587000159],
              [-1.399677286999946, 43.20674367300002],
              [-1.41052933799989, 43.166901144000079],
              [-1.425773884999927, 43.135275167000103],
              [-1.438434610999877, 43.122795309000097],
              [-1.479620727999873, 43.090264995000084],
              [-1.487837279999894, 43.081815898000073],
              [-1.475279906999987, 43.060137634000071],
              [-1.457141478999858, 43.045073955000092],
              [-1.435023965999932, 43.035720520000055],
              [-1.41052933799989, 43.030914612000132],
              [-1.383140827999853, 43.02920929],
              [-1.366139282999939, 43.033317567],
              [-1.357612670999913, 43.046830953000082],
              [-1.355183877999934, 43.073030904000078],
              [-1.348259236999866, 43.093081360000141],
              [-1.331050984999905, 43.107344056000031],
              [-1.308623412999907, 43.113571066000091],
              [-1.286040811999953, 43.109178569],
              [-1.306969766999941, 43.095975240000129],
              [-1.315082966999853, 43.082875265000055],
              [-1.310587117999944, 43.069491069000108],
              [-1.293688923999952, 43.055202535000049],
              [-1.274878702999928, 43.046830953000082],
              [-1.216897745999887, 43.039260356000014],
              [-1.182532918999925, 43.025281881000026],
              [-1.149925088999879, 43.005722352000092],
              [-1.112408, 43.008254496000077],
              [-1.103726358999921, 43.005748190000091],
              [-1.087189900999931, 42.997996725000107],
              [-1.079696817999888, 42.993397522000137],
              [-1.0450736089999, 42.990219422000095],
              [-1.028485473999893, 42.986705424000135],
              [-1.016289834999952, 42.976240947000079],
              [-0.981459920999896, 42.957043152000082],
              [-0.943219360999905, 42.947896423000188],
              [-0.863534301999948, 42.943064677000066],
              [-0.786433064999926, 42.953270773000057],
              [-0.759664672999861, 42.946501160000096],
              [-0.745031962827426, 42.918727227289779],
              [-0.745246140696821, 42.918724269709301],
              [-0.77144609166379, 42.918362535402608],
              [-0.806224331187934, 42.90435822235402],
              [-0.813097297402322, 42.896296699533011],
              [-0.816352911557658, 42.88885529253777],
              [-0.815655279567522, 42.882137355954129],
              [-0.819195116361527, 42.87609121383845],
              [-0.846221889628453, 42.849477850822169],
              [-0.850381842248169, 42.840486152014321],
              [-0.852526414162696, 42.832269599562395],
              [-0.851001959872519, 42.816559964171105],
              [-0.856944749200807, 42.786897690775803],
              [-0.867021653626381, 42.769947821934423],
              [-0.877537807623753, 42.763746650187741],
              [-0.890224371957231, 42.758889065577918],
              [-0.896425543703884, 42.755013332899026],
              [-0.904357876215016, 42.742559313461626],
              [-0.924976772160306, 42.735582994459563],
              [-0.951745163908129, 42.716695258379517],
              [-0.964509242607448, 42.710287381057796],
              [-0.977118292575142, 42.705714016388768],
              [-1.0246347723768, 42.696128038378291],
              [-1.031662767322814, 42.690107733784956],
              [-1.034375780018195, 42.683079738838856],
              [-1.033703985550517, 42.676258450367285],
              [-1.034995896743197, 42.669178779477036],
              [-1.040266893402446, 42.655846259097487],
              [-1.044452684443854, 42.649955145713434],
              [-1.055485602378667, 42.645355943522006],
              [-1.075665248752216, 42.645355943522006],
              [-1.08522538924035, 42.64700958902138],
              [-1.145015021326117, 42.641376858055551],
              [-1.151655442644596, 42.635924994243155],
              [-1.154290940974136, 42.629336248868796],
              [-1.154523484970809, 42.623600165115548],
              [-1.155531175323489, 42.618639227718134],
              [-1.165530565383278, 42.61504771587947],
              [-1.172326016332619, 42.613471584745767],
              [-1.168915370747726, 42.611094468834622],
              [-1.153283249722193, 42.605435899447272],
              [-1.154781867389204, 42.601792710765054],
              [-1.160543788664882, 42.596082465433469],
              [-1.184960902923365, 42.584558621083588],
              [-1.195761277760766, 42.575334378278896],
              [-1.203047655125175, 42.566601060990152],
              [-1.205347255771244, 42.558100286798762],
              [-1.21201351641065, 42.553888658234897],
              [-1.219661628081667, 42.550633043180468],
              [-1.228033210164512, 42.549392808831115],
              [-1.246042446201898, 42.55337189429757],
              [-1.257411260920946, 42.553216865565943],
              [-1.265007696647899, 42.54846263284422],
              [-1.271493089234667, 42.541537990685768],
              [-1.278159348974725, 42.532029527040962],
              [-1.282190111284592, 42.524174709794991],
              [-1.284515550352296, 42.516578274068152],
              [-1.283042772006354, 42.510118719903076],
              [-1.280329759310973, 42.504770208877986],
              [-1.27575639464186, 42.498491522765548],
              [-1.27368933799255, 42.494770819717573],
              [-1.272681646740608, 42.487639471984082],
              [-1.279477097689835, 42.476994126777484],
              [-1.294411586725346, 42.459837551461774],
              [-1.320663215435019, 42.440923976960008],
              [-1.341953904948724, 42.414827378780515],
              [-1.348051723907929, 42.397386583524067],
              [-1.350377162975633, 42.386792914261619],
              [-1.349111090204644, 42.379170641012351],
              [-1.345493739944175, 42.372943630843864],
              [-1.338827480204031, 42.369610501423566],
              [-1.33340145481327, 42.364132799189363],
              [-1.331024338902125, 42.357983303386746],
              [-1.334486660430969, 42.349870103722381],
              [-1.364123093605826, 42.323153387918538],
              [-1.384018520938014, 42.300415758480497],
              [-1.390891486253111, 42.287289944575306],
              [-1.394999762029386, 42.27716136330632],
              [-1.395852422751062, 42.269771633154434],
              [-1.400865036991888, 42.253906969031405],
              [-1.404895799301698, 42.244269314177487],
              [-1.412543910972687, 42.230187485863851],
              [-1.416419643651579, 42.220575670330945],
              [-1.41703976127593, 42.211945705829706],
              [-1.415437790821329, 42.204891873361262],
              [-1.411148646992501, 42.197579658474424],
              [-1.405412563239196, 42.191533515459369],
              [-1.400425788319438, 42.185151476559369],
              [-1.396627570006331, 42.177968451083146],
              [-1.39533565881365, 42.169416001847068],
              [-1.394767218032712, 42.149623928201734],
              [-1.393707851735968, 42.141614081324761],
              [-1.394224615673494, 42.127583929854453],
              [-1.392467617386615, 42.120788478905141],
              [-1.38717078320505, 42.114742336789462],
              [-1.373424851675566, 42.104691270785523],
              [-1.368515591121593, 42.098774318979579],
              [-1.364355637602586, 42.092237250448747],
              [-1.361745977694682, 42.085338446712015],
              [-1.356707525931597, 42.0786721869719],
              [-1.350661382916456, 42.072729396744322],
              [-1.3424189929421, 42.069215400170506],
              [-1.333349778869206, 42.067458400984449],
              [-1.323918829590269, 42.067251695409411],
              [-1.316451585072599, 42.06275584600543],
              [-1.312446662083801, 42.054280911135109],
              [-1.315960658657445, 42.037150174241205],
              [-1.322368536878457, 42.02777090090639],
              [-1.342987432823747, 42.00697113690849],
              [-1.361177536913829, 41.983768419477016],
              [-1.374510057293293, 41.960462348358661],
              [-1.380659552196533, 41.953124295050216],
              [-1.38314002089524, 41.943693345771365],
              [-1.393992071676792, 41.932737942202223],
              [-1.403423020955643, 41.925451564837928],
              [-1.409029914398957, 41.915736396517516],
              [-1.414662645364785, 41.910827135064167],
              [-1.419287685977963, 41.908863430303043],
              [-1.426393195289734, 41.910982163795794],
              [-1.444738328111384, 41.914392809380686],
              [-1.454401821386909, 41.915193793259007],
              [-1.483288946627567, 41.922170112260872],
              [-1.490704515201173, 41.922661037776678],
              [-1.497887538878786, 41.921627509002391],
              [-1.503003505907031, 41.919999701025446],
              [-1.518325567670843, 41.913410956550436],
              [-1.537135789385104, 41.910878811008317],
              [-1.546644253029825, 41.910672105433335],
              [-1.555946011099479, 41.911602281420144],
              [-1.573748541561912, 41.916744085970734],
              [-1.589432339430857, 41.925193183318711],
              [-1.596356980690103, 41.930154119816777],
              [-1.607286545837439, 41.941884671540365],
              [-1.614211187995863, 41.947000636769943],
              [-1.621936814932099, 41.951238105554168],
              [-1.639403448610267, 41.957594306032476],
              [-1.657154303128607, 41.962167669802184],
              [-1.666430222776569, 41.960333157149577],
              [-1.6849303861284, 41.953976955771893],
              [-1.694645555348075, 41.953305162203478],
              [-1.704179857414516, 41.955062161389705],
              [-1.723610194954489, 41.960591539567972],
              [-1.732033453880774, 41.964673976922441],
              [-1.739604051186006, 41.969299018434882],
              [-1.752135585888539, 41.98141714108813],
              [-1.759499477618817, 41.986998196109894],
              [-1.767716030070744, 41.990615546370307],
              [-1.776578537669309, 41.992992662281537],
              [-1.814844937144102, 41.995266425405262],
              [-1.823888311896098, 41.994646307780826],
              [-1.834611172367744, 41.998909614087452],
              [-1.83639400907623, 42.000279038746655],
              [-1.838667772199869, 42.000847480426856],
              [-1.841277432107773, 42.001881008301964],
              [-1.852413702830063, 42.001209214733549],
              [-1.89060258614046, 42.02531627152878],
              [-1.897320521824554, 42.035599881529222],
              [-1.901325445712729, 42.045599269790543],
              [-1.902100592068621, 42.054384263922671],
              [-1.901480475343647, 42.061929022806211],
              [-1.899930181732458, 42.069370428902133],
              [-1.896054449952857, 42.076140042329129],
              [-1.856392788296517, 42.111538398578276],
              [-1.8418200335677, 42.122028714153871],
              [-1.835773892351227, 42.127868149795191],
              [-1.831975674038119, 42.134431056747857],
              [-1.829107631711821, 42.140787258125513],
              [-1.823423223902637, 42.145619005212936],
              [-1.814586553826359, 42.14693675392796],
              [-1.804302943825746, 42.145903225153759],
              [-1.79231401238178, 42.141123154909693],
              [-1.782728034371274, 42.139288642257057],
              [-1.774227261079233, 42.139960435825472],
              [-1.754073452228027, 42.146394151568828],
              [-1.744590827005027, 42.146626695565502],
              [-1.734668952210399, 42.14422374123258],
              [-1.713094040957202, 42.136136379989935],
              [-1.703172166162545, 42.136343085564889],
              [-1.694128791410577, 42.138461819057639],
              [-1.688186001182999, 42.143061021249181],
              [-1.685783046850077, 42.149029648999161],
              [-1.68999467631329, 42.156393540729354],
              [-1.69226843943693, 42.166780504416735],
              [-1.691777513021776, 42.171534736238996],
              [-1.690098029100767, 42.17440277856538],
              [-1.689116177169808, 42.176805731998954],
              [-1.688599412333019, 42.180758979043688],
              [-1.691648321812579, 42.187037665156041],
              [-1.697746140771784, 42.194530748095517],
              [-1.697074347203369, 42.21582143850857],
              [-1.726943325274249, 42.231479397056447],
              [-1.747407191588621, 42.236001084882147],
              [-1.795776333011361, 42.256490790517503],
              [-1.820012580116497, 42.272872219477406],
              [-1.829314338186151, 42.277574775355518],
              [-1.835799729873656, 42.278143215237193],
              [-1.85125098284658, 42.276179511375361],
              [-1.857271287439914, 42.277574775355518],
              [-1.861922165575493, 42.28418935825222],
              [-1.862154709572167, 42.291992499554155],
              [-1.86140540163791, 42.299433906549623],
              [-1.863472459186681, 42.304911606985101],
              [-1.901325445712729, 42.325375475098113],
              [-1.976747198824171, 42.393045762851656],
              [-2.032402715812651, 42.392968248485786],
              [-2.055605435042793, 42.399066067444991],
              [-2.069558071248025, 42.414775701937103],
              [-2.06480384032497, 42.417307848378513],
              [-2.0601529612901, 42.423560696069231],
              [-2.055269538258528, 42.428418281578374],
              [-2.076508550928907, 42.442577623358673],
              [-2.104956426597823, 42.445161444844771],
              [-2.1719549216823, 42.441440741796796],
              [-2.191385260121649, 42.442939358564459],
              [-2.206009690794588, 42.44640167919421],
              [-2.218231167134604, 42.453429674140224],
              [-2.230297613843817, 42.46567698800267],
              [-2.238720871870697, 42.471981513436006],
              [-2.26789221795147, 42.486916002471602],
              [-2.278382534426413, 42.489861559163572],
              [-2.343262295118791, 42.483040269792539],
              [-2.38447425038629, 42.494667466929982],
              [-2.40607499826254, 42.497096258785362],
              [-2.402871060051353, 42.512237454295089],
              [-2.394008550654064, 42.564973253013207],
              [-2.39806515138568, 42.583964341880929],
              [-2.40067481129347, 42.590733954408549],
              [-2.405868292687501, 42.596108302955813],
              [-2.413309698783507, 42.598278714191238],
              [-2.421319545660481, 42.598795478128764],
              [-2.44173173693008, 42.578977566061795],
              [-2.450051642169512, 42.57600617094802],
              [-2.458009813102422, 42.578124905340033],
              [-2.473357713287839, 42.584713649815043],
              [-2.481367560164728, 42.585876369798612],
              [-2.488085495848907, 42.589907132108507],
              [-2.493278978142285, 42.595307319077463],
              [-2.494545050013983, 42.601999417239412],
              [-2.492736374883776, 42.609337470547771],
              [-2.478680385891039, 42.619104315711596],
              [-2.470179612598997, 42.622850857181376],
              [-2.450671758894487, 42.634813951102998],
              [-2.437856004251216, 42.645562649097045],
              [-2.418012253762527, 42.657525743018667],
              [-2.408710495692787, 42.660212918191533],
              [-2.399512091309987, 42.659928697351447],
              [-2.391037157338928, 42.658585110214588],
              [-2.385301072686332, 42.656130479038424],
              [-2.380960252014006, 42.652849026461368],
              [-2.372588669931162, 42.641841946048899],
              [-2.365870734246982, 42.637914537425971],
              [-2.348094041307007, 42.638121243000924],
              [-2.338301356822171, 42.639490668559475],
              [-2.32843115887087, 42.642978826711399],
              [-2.298458828012485, 42.657758287015369],
              [-2.292696905837545, 42.663080960517917],
              [-2.293161993830921, 42.672020982482351],
              [-2.295926683369714, 42.677912095866574],
              [-2.306856247617759, 42.680082506202737],
              [-2.312023892388709, 42.68292471010723],
              [-2.320705531934721, 42.721010239730688],
              [-2.32036963515057, 42.728994249085403],
              [-2.312075568332773, 42.737753403896548],
              [-2.284919602957274, 42.744678046054972],
              [-2.279416063200728, 42.750930895544357],
              [-2.276987271345433, 42.75963837351209],
              [-2.276522183352085, 42.776045640893628],
              [-2.274429287381679, 42.79098012992911],
              [-2.26342220696921, 42.813717760266414],
              [-2.242312383709475, 42.839194240821556],
              [-2.239780239066704, 42.849426173978756],
              [-2.239134283920009, 42.858314520898475],
              [-2.252957728915987, 42.900069078525192],
              [-2.237506475943064, 42.922961738493527],
              [-2.235801153600363, 42.928697822246747],
              [-2.231692877824059, 42.935157376411823],
              [-2.225440029233965, 42.94141022410254],
              [-2.214148728880673, 42.949626777453844],
              [-2.2008162094005, 42.951642158159117],
              [-2.182884486829607, 42.945906074405869],
              [-2.174099493596742, 42.94404572243208],
              [-2.154488288004046, 42.945957750349933],
              [-2.143791265954121, 42.945880235084715],
              [-2.132913376750935, 42.946939602280807],
              [-2.122758957959519, 42.949626777453844],
              [-2.113586391998439, 42.956163845085328],
              [-2.094750331862258, 42.975464993214771],
              [-2.085216029795902, 42.979650784256293],
              [-2.057284918963802, 42.983836575297701],
              [-2.049404263296111, 42.988229071914091],
              [-2.042660488290835, 42.994456082082493],
              [-2.039585741288931, 43.004300442511365],
              [-2.039482387602078, 43.012258613444274],
              [-2.04180782666981, 43.019544989909392],
              [-2.040567593219805, 43.02755483678628],
              [-2.036562669331659, 43.036443182806707],
              [-2.0285786599772, 43.049026394352708],
              [-2.014600186249595, 43.06695811602431],
              [-1.96400895764728, 43.093287258200476],
              [-1.950753953432184, 43.098093166866093],
              [-1.937912360367079, 43.105586248906363],
              [-1.926724411902086, 43.114371243038519],
              [-1.912487554856739, 43.13514516951399],
              [-1.911092291775844, 43.146281440236194],
              [-1.912410041390331, 43.155712389515216],
              [-1.916079066695602, 43.162120265937588],
              [-1.917577684362612, 43.169174099305295],
              [-1.919283005806051, 43.184547837912433],
              [-1.918662889081048, 43.192247626426777],
              [-1.910988938988339, 43.213512478418139],
              [-1.908534307812062, 43.218344225505646],
              [-1.902410651330456, 43.215760403120171],
              [-1.88925899900363, 43.211626288022799],
              [-1.883703783302934, 43.211962184806978],
              [-1.879802212202321, 43.214132595143226],
              [-1.880008917777303, 43.219481106168146],
              [-1.878096889859535, 43.224648749140513],
              [-1.872696701991174, 43.227697659519364],
              [-1.864118415232667, 43.225656440392484],
              [-1.857271287439914, 43.222865912431942],
              [-1.847401088589322, 43.223615221265433],
              [-1.833680996380906, 43.229506333750365],
              [-1.821071947312504, 43.238601386245065],
              [-1.805543179074448, 43.256998195910043],
              [-1.791306322029101, 43.277410387179643],
              [-1.781927048694314, 43.284464220547264],
              [-1.773452113823964, 43.289709377885416],
              [-1.749939338030032, 43.294747830547877],
              [-1.749729985875064, 43.29479269121488],
              [-1.747356322999906, 43.291002096000014],
              [-1.736555948999865, 43.292319844000147],
              [-1.724825397999894, 43.29653147400002],
              [-1.703328002999854, 43.298624370000184],
              [-1.683019164999934, 43.304928894000099],
              [-1.673872435999925, 43.30415374799999],
              [-1.654907185999889, 43.297539164000099],
              [-1.645036986999912, 43.292371521000078],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5838",
        OBJECTID_1: 7246,
        diss_me: 5838,
        adm1_cod_1: "ESP-5838",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Orense",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.GA",
        note: null,
        hasc_maybe: null,
        region: "Galicia",
        region_cod: "ES.GA",
        provnum_ne: 12,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "GA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP91",
        fips_alt: "SP58",
        woe_id: 12602132,
        woe_label: "Galicia, ES, Spain",
        woe_name: "Galicia",
        latitude: 42.160899999999998,
        longitude: -7.5923400000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3114964,
        gn_name: "Provincia de Ourense",
        gns_id: -394671,
        gns_name: "Orense",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.OR",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP58",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.752726583165241, 42.451132981901651],
            [-7.751089620989205, 42.452132738435125],
            [-7.715406025867452, 42.451143968918984],
            [-7.617342056890379, 42.402090011744605],
            [-7.577066176262974, 42.407660080648128],
            [-7.520871105617005, 42.39500382976297],
            [-7.41828076964174, 42.43317033524275],
            [-7.406371590076844, 42.444321458268689],
            [-7.369743170810636, 42.421272140896633],
            [-7.361986823033448, 42.419536300959493],
            [-7.3202277876822, 42.425007493001402],
            [-7.269317141472925, 42.414691330240657],
            [-7.255727052561383, 42.406220871296171],
            [-7.202630127478358, 42.325526287807946],
            [-7.150829587715236, 42.427226731128712],
            [-7.138887448896611, 42.440388353339841],
            [-7.1032148398929, 42.457823656436574],
            [-7.071035731913497, 42.50345246016289],
            [-7.026283941650547, 42.502005520238583],
            [-6.983134120942907, 42.494822496560943],
            [-6.972307908583076, 42.495700994805048],
            [-6.968354660639051, 42.498388169978085],
            [-6.966623500773892, 42.500558580314163],
            [-6.962024298582548, 42.503814195368875],
            [-6.952645026147025, 42.508258367929301],
            [-6.93293046686756, 42.513064277494379],
            [-6.919985521014979, 42.512831733497705],
            [-6.909443528595972, 42.509291896703616],
            [-6.897170376311806, 42.501617947510255],
            [-6.892571174120349, 42.499783433958271],
            [-6.821050991210228, 42.485055650497827],
            [-6.808777838926062, 42.464023342503225],
            [-6.810819058053028, 42.456116848413757],
            [-6.81304114433334, 42.438908596254691],
            [-6.816400112175444, 42.430201118286988],
            [-6.820740932847769, 42.42366404885675],
            [-6.828544074149676, 42.415447496404823],
            [-6.831851366047715, 42.412605292500245],
            [-6.839111904091112, 42.410124823801539],
            [-6.789166633333451, 42.378886420172194],
            [-6.755421921684302, 42.362660020843293],
            [-6.740461595126561, 42.35025767734993],
            [-6.735707363304186, 42.341136786433538],
            [-6.738058640793611, 42.333850409968392],
            [-6.738756272783746, 42.326357327028944],
            [-6.737748582431152, 42.311991278774485],
            [-6.738317023212005, 42.304963283828471],
            [-6.740616623858102, 42.298193671300851],
            [-6.74441484217121, 42.29183746992328],
            [-6.756403773615261, 42.280882066354224],
            [-6.769813809259944, 42.261968491852372],
            [-6.78324585761024, 42.252876028505881],
            [-6.783249680628217, 42.252873440257048],
            [-6.799424404013052, 42.238378200793306],
            [-6.802395799126856, 42.230807604387365],
            [-6.805470547028108, 42.217940172001278],
            [-6.81133582199061, 42.216596584864533],
            [-6.830456102067444, 42.220937405536773],
            [-6.84464128316867, 42.220885728693418],
            [-6.866758795881708, 42.217009996014554],
            [-6.882545945638867, 42.209594428340154],
            [-6.898410609761783, 42.200163479061274],
            [-6.932904629345131, 42.174118556825931],
            [-6.94057857943784, 42.165333564492357],
            [-6.944738532057642, 42.158357245490407],
            [-6.955590582839108, 42.132208971366936],
            [-6.962592739363487, 42.126162828351795],
            [-6.970809291815328, 42.122028714153799],
            [-6.979180873898173, 42.119496567712389],
            [-6.986932339256015, 42.11412221916504],
            [-7.011220262305216, 42.085751857861908],
            [-7.024656133673602, 42.075726630279775],
            [-7.028144293624251, 42.067975164921904],
            [-7.026283941650547, 42.061360582025202],
            [-7.019772712440698, 42.056244614996984],
            [-7.01269303975198, 42.052213854485842],
            [-7.005070767401975, 42.051025296080553],
            [-6.984761928919852, 42.051180324812094],
            [-6.977863125183035, 42.047924709757552],
            [-6.967501999917374, 42.035935777414238],
            [-6.966003384048918, 42.032964382300435],
            [-6.965305752058782, 42.028313504164856],
            [-6.983134120942907, 41.996713365329526],
            [-6.986467251262667, 41.989168606446015],
            [-6.987707484712644, 41.983380845849354],
            [-6.982720709792886, 41.973975734992138],
            [-6.983444180204742, 41.963175361053999],
            [-6.983456182778326, 41.962996165870265],
            [-6.992359170999919, 41.966380107000049],
            [-7.015768594999884, 41.9536677050001],
            [-7.051270303999928, 41.942014669000031],
            [-7.082741251999977, 41.952789205000045],
            [-7.113178670999929, 41.972426250000098],
            [-7.1453730879999, 41.987489929000077],
            [-7.159739135999899, 41.985732931000115],
            [-7.177309122999873, 41.978369039000114],
            [-7.192605346999926, 41.969609884000107],
            [-7.1999434, 41.963718771000117],
            [-7.202113810999919, 41.949740295000112],
            [-7.195654255999955, 41.917365011000129],
            [-7.19792801899996, 41.901500346000034],
            [-7.209316118172069, 41.889160873187876],
            [-7.218650268999909, 41.879046937000112],
            [-7.251464802999919, 41.863699036000042],
            [-7.322468220999895, 41.845405579000072],
            [-7.340503295999895, 41.843209331000082],
            [-7.376831827999894, 41.844036153000104],
            [-7.391921345999975, 41.842046611000058],
            [-7.405408894999937, 41.8351994840001],
            [-7.429541788999899, 41.814503073000083],
            [-7.442719278999959, 41.805950623000058],
            [-7.446956746999916, 41.821841126000066],
            [-7.456568562999848, 41.839230245000095],
            [-7.469384318999914, 41.852536926000113],
            [-7.483285278999915, 41.856205954000103],
            [-7.493000447999947, 41.856903586000101],
            [-7.508141642999959, 41.864939270000093],
            [-7.515841430999956, 41.864990947000109],
            [-7.523282836999954, 41.860159201000073],
            [-7.525246540999916, 41.854164734000051],
            [-7.526331746999887, 41.847369283],
            [-7.531551066999924, 41.840160421000064],
            [-7.546692260999919, 41.832047221000039],
            [-7.567104451999882, 41.826595358000077],
            [-7.588395141999854, 41.824218242000114],
            [-7.60606848099988, 41.825535991000066],
            [-7.622191528999849, 41.832331442000026],
            [-7.622811644999899, 41.841736552000071],
            [-7.607360391999919, 41.873181662000036],
            [-7.661155557999933, 41.874912822000084],
            [-7.67867386899988, 41.881346537000042],
            [-7.706372436999914, 41.896255188000083],
            [-7.722030395999866, 41.899252421000114],
            [-7.750142374999911, 41.888374532000057],
            [-7.812154092999947, 41.877134909000091],
            [-7.839232543999913, 41.878375143000142],
            [-7.849722859999872, 41.875765483000123],
            [-7.854425414999952, 41.870261943000031],
            [-7.857887735999981, 41.863776551000058],
            [-7.864812377999954, 41.857885437000078],
            [-7.896593383999914, 41.857937113000077],
            [-7.903362995999942, 41.886824240000124],
            [-7.906101846999888, 41.913851014000073],
            [-7.92579056799994, 41.908166606000123],
            [-7.930493122999906, 41.896668600000112],
            [-7.93535070799993, 41.879951274000064],
            [-7.94475581899988, 41.866954652000032],
            [-7.96335933499995, 41.866618755000047],
            [-7.979430704999913, 41.868608297000094],
            [-7.993641723999872, 41.863673198000043],
            [-8.005889037999907, 41.853958029000097],
            [-8.015707560999971, 41.841891581000112],
            [-8.048573770999894, 41.816389262000087],
            [-8.05044571679818, 41.815986437961953],
            [-8.093480590999889, 41.806725769000082],
            [-8.179573526999889, 41.810704854000051],
            [-8.179265101051726, 41.812612525073973],
            [-8.175387735999948, 41.836594747000078],
            [-8.177041381999913, 41.849849752000097],
            [-8.184586140999954, 41.857730408000123],
            [-8.220397908999871, 41.868194886000083],
            [-8.231715047999955, 41.886049093000025],
            [-8.23021643099986, 41.904678447000094],
            [-8.220707966999953, 41.922713521000077],
            [-8.182131509999891, 41.965424093000081],
            [-8.152960163999865, 41.986818136000096],
            [-8.12391800899988, 42.000899964000041],
            [-8.108027506999861, 42.010899353000042],
            [-8.097356322999957, 42.023482565000052],
            [-8.095082559999923, 42.040923361000097],
            [-8.103195760999938, 42.055806173000036],
            [-8.118440307999975, 42.066864929000047],
            [-8.137612263999898, 42.072911072000096],
            [-8.158386189999931, 42.071386617000087],
            [-8.176162882999961, 42.065262960000013],
            [-8.19130407699987, 42.062084859000066],
            [-8.204016479999922, 42.069552104000081],
            [-8.207375447999937, 42.085778504000075],
            [-8.204326537999918, 42.128799133000086],
            [-8.213395751999883, 42.144017843000114],
            [-8.222361612899363, 42.15362965989371],
            [-8.183356651288392, 42.173116382015422],
            [-8.179611365272308, 42.209873207382572],
            [-8.164867712375013, 42.245908365478698],
            [-8.235630654431134, 42.231812905241384],
            [-8.245946817191793, 42.236295327767152],
            [-8.268435831748263, 42.256685953294067],
            [-8.274522258095715, 42.26639786786734],
            [-8.275478068358098, 42.27851578817311],
            [-8.261975870190213, 42.315232098182776],
            [-8.276005411920181, 42.318506024333573],
            [-8.286431437660696, 42.323845379360762],
            [-8.31810502322827, 42.3608363477193],
            [-8.318972942747109, 42.366582198109995],
            [-8.317006389832983, 42.383984541952998],
            [-8.318643352908396, 42.389159103409924],
            [-8.334145063244023, 42.412702805090333],
            [-8.342285932349881, 42.432544114818967],
            [-8.339012006199084, 42.48523454633721],
            [-8.340572064649052, 42.492309741301511],
            [-8.302823039751473, 42.490255298543218],
            [-8.248869180368388, 42.53142107092566],
            [-8.214130409390805, 42.539979421513067],
            [-8.173777625037332, 42.571927664530051],
            [-8.146674352739126, 42.548592702691053],
            [-8.067144320254158, 42.552042410328994],
            [-8.018672639031053, 42.542066823525118],
            [-8.012685089545243, 42.545384695047602],
            [-7.992613067738915, 42.570246756082952],
            [-7.984241484756694, 42.571795828414764],
            [-7.958214872718173, 42.56846697077404],
            [-7.930803982817537, 42.578453543696071],
            [-7.900679470481151, 42.556491872701685],
            [-7.894417262646556, 42.546362478445587],
            [-7.892648464355091, 42.536518727757056],
            [-7.896845241514654, 42.505691089472208],
            [-7.861502222349657, 42.50397722267067],
            [-7.781543723164276, 42.47207292412611],
            [-7.752726583165241, 42.451132981901651],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5839",
        OBJECTID_1: 7254,
        diss_me: 5839,
        adm1_cod_1: "ESP-5839",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Palencia",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602118,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 42.3386,
        longitude: -4.5986799999999999,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3114530,
        gn_name: "Provincia de Palencia",
        gns_id: -395196,
        gns_name: "Palencia",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.P",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.057947389760187, 42.13716568554797],
              [-4.073778689035265, 42.133781896417375],
              [-4.092554324622341, 42.13859390788248],
              [-4.096838992525477, 42.141120763612392],
              [-4.100047001068276, 42.145207677792101],
              [-4.103342899455214, 42.154820715503647],
              [-4.102991337380303, 42.160061193669193],
              [-4.100783084471772, 42.164961096777247],
              [-4.097311405496896, 42.169179846173009],
              [-4.093147586691771, 42.172398840833878],
              [-4.08300720541817, 42.174486242845845],
              [-4.070702517507186, 42.172014319505152],
              [-4.059935915809888, 42.165949866293275],
              [-4.05439880615998, 42.15729263884414],
              [-4.052091676389722, 42.143515783226761],
              [-4.053981325577695, 42.139483800536894],
              [-4.057947389760187, 42.13716568554797],
            ],
          ],
          [
            [
              [-4.400811122934982, 43.033290921438876],
              [-4.38109656365549, 43.008615423862707],
              [-4.37740169902915, 43.001251533031805],
              [-4.37644568462062, 42.999701240319908],
              [-4.370425380926577, 42.992776598161484],
              [-4.356550259087186, 42.985412706431319],
              [-4.254127570231134, 42.957636624330817],
              [-4.239916550708216, 42.950246894178761],
              [-4.237591111640484, 42.943425604807814],
              [-4.237901170002942, 42.936294257074323],
              [-4.238883021933901, 42.929214586184244],
              [-4.236738450918722, 42.915158596292244],
              [-4.231829188566195, 42.901076768877786],
              [-4.230072191178692, 42.893867905879162],
              [-4.229452073554341, 42.886633206257457],
              [-4.227307501639871, 42.87973440252064],
              [-4.226739060858932, 42.872060452427846],
              [-4.225111252881987, 42.865445867732674],
              [-4.224516973679357, 42.859373888094481],
              [-4.223483445804334, 42.855420641049747],
              [-4.221390549834041, 42.850201321233939],
              [-4.215964525342542, 42.84529205978076],
              [-4.208652309556413, 42.842734076716184],
              [-4.201314256247883, 42.842346503088677],
              [-4.196637538791379, 42.84405182633067],
              [-4.183821784147995, 42.852345893148382],
              [-4.17449418765662, 42.85702260970568],
              [-4.150154587763978, 42.862190253577253],
              [-4.14116288895616, 42.858831284835972],
              [-4.134444953271981, 42.853508613132064],
              [-4.132713791608182, 42.84611888297998],
              [-4.135530157990331, 42.8395042991841],
              [-4.141627976949508, 42.834543361786771],
              [-4.15023210302914, 42.831597805094603],
              [-4.159404669889597, 42.83105520273547],
              [-4.177362229982919, 42.826843574171548],
              [-4.184674444869756, 42.823484605430238],
              [-4.187671678405252, 42.817671007311063],
              [-4.185242885650609, 42.811960761080186],
              [-4.178214890704595, 42.806224677326966],
              [-4.158577846690264, 42.793512275471144],
              [-4.147054002340298, 42.790515041935649],
              [-4.120440640223393, 42.78896474922378],
              [-4.110389574219454, 42.785967515688284],
              [-4.1014753888781, 42.781006578291041],
              [-4.096126877853095, 42.776200670524432],
              [-4.085765754385989, 42.763126533462852],
              [-4.096047977632082, 42.745742368515451],
              [-4.103375858708944, 42.738755063395544],
              [-4.111516727814717, 42.736008481706065],
              [-4.117141728208168, 42.737392758668918],
              [-4.132687383016332, 42.747796812173149],
              [-4.145881963581701, 42.751631040240355],
              [-4.159131475637025, 42.748752622435561],
              [-4.185498664531707, 42.736305112291163],
              [-4.218446663182959, 42.704730404484579],
              [-4.245176401169971, 42.692370784184291],
              [-4.258546763222597, 42.697270687292431],
              [-4.265369272973601, 42.701665218175279],
              [-4.26683045456187, 42.705411556398204],
              [-4.282903452931976, 42.686009700387345],
              [-4.285045787333246, 42.675968195975472],
              [-4.275773325578541, 42.6529847962113],
              [-4.274048472658848, 42.616883721406552],
              [-4.29393372685999, 42.605205253919436],
              [-4.282628794583189, 42.576047537963447],
              [-4.304249889620735, 42.568829519866441],
              [-4.299284068904683, 42.56431413898629],
              [-4.297064830777288, 42.566621267857172],
              [-4.276004038555527, 42.550526296351492],
              [-4.263172007082403, 42.536035329566843],
              [-4.246220102175926, 42.492013110716485],
              [-4.24393494554127, 42.455274828470621],
              [-4.302470104311823, 42.459361742650302],
              [-4.315895397854291, 42.456340501712802],
              [-4.325255749453277, 42.449276292866656],
              [-4.330595105379814, 42.438696458774544],
              [-4.330759900748831, 42.405561691618814],
              [-4.326980603272148, 42.390741134096075],
              [-4.317466442422415, 42.382457442756802],
              [-4.272675180914803, 42.385577559656596],
              [-4.276981821953513, 42.383281416903927],
              [-4.2350250337783, 42.229066323552061],
              [-4.238079233070096, 42.214377602144694],
              [-4.190991828809814, 42.181056067383665],
              [-4.205405891868423, 42.13472672056163],
              [-4.120305790479819, 42.128211827513738],
              [-4.1041778606197, 42.061623690381069],
              [-4.085380251897135, 42.042683259424919],
              [-4.079931032990714, 42.040112459222698],
              [-3.988524780194382, 42.051538240597395],
              [-3.978296508177294, 42.048451082051812],
              [-3.970946653964859, 42.039815827738252],
              [-3.972671507783843, 42.030763092842406],
              [-4.029009400663313, 41.976534575110563],
              [-4.024570923509316, 41.957517240428345],
              [-4.006026000899226, 41.957253569097006],
              [-3.915125118529403, 41.992915191083185],
              [-3.903919064013621, 41.991278228007857],
              [-3.896195674590672, 41.984301909905199],
              [-3.892460323385251, 41.974084624006181],
              [-3.893218379924292, 41.962757719493183],
              [-3.901128536053079, 41.950595853815614],
              [-3.914465938851976, 41.942707669922939],
              [-3.976088256168055, 41.930128323663283],
              [-3.980471800932833, 41.925283352944447],
              [-3.982647093688456, 41.905848537679859],
              [-3.985899047602942, 41.897301174109955],
              [-3.991095580396717, 41.890654444946634],
              [-3.998049926263093, 41.88566665199447],
              [-4.053267213511049, 41.86967055735029],
              [-4.088731082673348, 41.870483546278663],
              [-4.090104373518045, 41.831031640697674],
              [-4.037941285561857, 41.789843894280366],
              [-4.04102844410744, 41.768222800142112],
              [-4.099376835272722, 41.774397116333844],
              [-4.123041386950433, 41.807356102002458],
              [-4.151539922329533, 41.798424217103999],
              [-4.240133675828474, 41.804345848082349],
              [-4.257305307593811, 41.801357567297842],
              [-4.291099254427053, 41.778670798118853],
              [-4.312138073513211, 41.771628561508905],
              [-4.399743057496039, 41.781032859378954],
              [-4.413366104761849, 41.777407370253925],
              [-4.435097062779164, 41.764762106386016],
              [-4.443457658743966, 41.76325697942606],
              [-4.461958635982256, 41.766805563925729],
              [-4.493665179904099, 41.780230857468197],
              [-4.491951313102561, 41.838568261615947],
              [-4.52110902905838, 41.823132469787666],
              [-4.572250388244782, 41.815925438708106],
              [-4.632642236050316, 41.863287501317146],
              [-4.640618310686477, 41.886501613158842],
              [-4.645946679595596, 41.891258693134105],
              [-4.660130029677106, 41.897378078735372],
              [-4.666974512563684, 41.898278957507856],
              [-4.675587794641046, 41.896905666663159],
              [-4.69341860518449, 41.889687648566067],
              [-4.709282863713213, 41.878800196871666],
              [-4.728904446582902, 41.852092432020143],
              [-4.75858950610089, 41.825868066258252],
              [-4.78913149991871, 41.817990868483847],
              [-4.838196443211245, 41.898630519582767],
              [-4.869089999104119, 41.931578519133396],
              [-4.927767980108172, 41.915099026298961],
              [-4.94972965020321, 41.933973538747765],
              [-4.986808509305234, 41.913385159497423],
              [-5.005045814312894, 41.938016507555758],
              [-4.971306799868955, 41.986696929519738],
              [-4.966692542127106, 42.001989899114605],
              [-4.96391300028472, 42.006604156856369],
              [-4.957628821113218, 42.010976715502991],
              [-4.950256994664556, 42.014107819420374],
              [-4.944104650709022, 42.018348541951738],
              [-4.94150089035378, 42.026038972120872],
              [-4.947444494467817, 42.047033845835344],
              [-4.945466955435649, 42.054295809304122],
              [-4.927976719949669, 42.071621249421085],
              [-4.924043614121445, 42.081443026974128],
              [-4.922066075089276, 42.091869052714671],
              [-4.92742740325204, 42.150173498508153],
              [-5.026952553817267, 42.153952795085345],
              [-5.014548989044584, 42.18462662411936],
              [-5.019086342160932, 42.230274819926947],
              [-5.002233315015445, 42.289150554654285],
              [-4.954893224642603, 42.296017009777088],
              [-4.942511633005495, 42.346455243914406],
              [-4.979590491208228, 42.337885908108106],
              [-4.972735022203494, 42.37426164126181],
              [-4.911310459510048, 42.381468673240661],
              [-4.912705723490319, 42.400639816274534],
              [-4.888052402296466, 42.517995777383149],
              [-4.88796451155298, 42.536914235203724],
              [-4.914397618055659, 42.544813406113818],
              [-4.89525943337614, 42.62162981526383],
              [-4.898093906708368, 42.669090753835135],
              [-4.8924029878076, 42.684153010453102],
              [-4.881933016695399, 42.697973812341658],
              [-4.881021151804674, 42.704763362838847],
              [-4.887503085598922, 42.711607845725538],
              [-4.919956699941679, 42.725813168942537],
              [-4.928064610693241, 42.732624692575499],
              [-4.932074620247533, 42.739985532006713],
              [-4.929767491376651, 42.747225523239379],
              [-4.909267002869882, 42.769209166469906],
              [-4.903674960830756, 42.779569273703075],
              [-4.901917148657446, 42.848058046141844],
              [-4.900335117071961, 42.854594911425934],
              [-4.896786533471754, 42.859769472882775],
              [-4.890150790426674, 42.862570986062181],
              [-4.865651278483682, 42.860516542404483],
              [-4.85535708795922, 42.891772647389772],
              [-4.847622713317492, 42.902363468499303],
              [-4.822859528244607, 42.916953312145679],
              [-4.81343325813836, 42.925720402574498],
              [-4.800271636826608, 42.975202826449234],
              [-4.789197417526793, 42.989792670995072],
              [-4.760446196035133, 43.005393257292965],
              [-4.726863572795594, 43.015927638749545],
              [-4.70583126480102, 43.015720934073855],
              [-4.689604864572829, 43.019596665853456],
              [-4.639116990556658, 43.019777533906066],
              [-4.601238165608891, 43.025539456081006],
              [-4.565607266262333, 43.023239854535646],
              [-4.552378098670289, 43.023937486525782],
              [-4.518710903185649, 43.037709256476987],
              [-4.505662603646215, 43.03941457792051],
              [-4.490857305820015, 43.039595445073658],
              [-4.481245490287193, 43.042541001765827],
              [-4.462874518144559, 43.050938422270391],
              [-4.44602800119128, 43.050860907904521],
              [-4.433961553582805, 43.048018704000029],
              [-4.409647793011175, 43.038329373202046],
              [-4.400811122934982, 43.033290921438876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5840",
        OBJECTID_1: 7245,
        diss_me: 5840,
        adm1_cod_1: "ESP-5840",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Pontevedra",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.GA",
        note: null,
        hasc_maybe: null,
        region: "Galicia",
        region_cod: "ES.GA",
        provnum_ne: 12,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "GA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 10,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP91",
        fips_alt: "SP58",
        woe_id: 12602133,
        woe_label: "Galicia, ES, Spain",
        woe_name: "Galicia",
        latitude: 42.525700000000001,
        longitude: -8.4998000000000005,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3113208,
        gn_name: "Provincia de Pontevedra",
        gns_id: -397118,
        gns_name: "Pontevedra",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.PO",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP58",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.049214631949724, 42.834533875737804],
            [-8.032834015977102, 42.836456483280131],
            [-7.999809112700405, 42.848860048052728],
            [-7.96445510741728, 42.842356142022282],
            [-7.981571806793454, 42.795081970156815],
            [-7.978517607501573, 42.781151305288489],
            [-7.93225417828765, 42.739952573652317],
            [-7.912281033343021, 42.729856137750488],
            [-7.880409694052105, 42.722011899229813],
            [-7.868148951512893, 42.71321184954715],
            [-7.868247828374535, 42.698160579946801],
            [-7.887331080664836, 42.678275324846396],
            [-7.890923610536191, 42.667629572246938],
            [-7.893000026430002, 42.635307794019468],
            [-7.930803982817537, 42.578453543696071],
            [-7.958214872718173, 42.56846697077404],
            [-7.984241484756694, 42.571795828414764],
            [-7.992613067738915, 42.570246756082952],
            [-8.012685089545243, 42.545384695047602],
            [-8.018672639031053, 42.542066823525118],
            [-8.067144320254158, 42.552042410328994],
            [-8.146674352739126, 42.548592702691053],
            [-8.173777625037332, 42.571927664530051],
            [-8.214130409390805, 42.539979421513067],
            [-8.248869180368388, 42.53142107092566],
            [-8.302823039751473, 42.490255298543218],
            [-8.340572064649052, 42.492309741301511],
            [-8.339012006199084, 42.48523454633721],
            [-8.342285932349881, 42.432544114818967],
            [-8.334145063244023, 42.412702805090333],
            [-8.318643352908396, 42.389159103409924],
            [-8.317006389832983, 42.383984541952998],
            [-8.318972942747109, 42.366582198109995],
            [-8.31810502322827, 42.3608363477193],
            [-8.286431437660696, 42.323845379360762],
            [-8.276005411920181, 42.318506024333573],
            [-8.261975870190213, 42.315232098182776],
            [-8.275478068358098, 42.27851578817311],
            [-8.274522258095715, 42.26639786786734],
            [-8.268435831748263, 42.256685953294067],
            [-8.245946817191793, 42.236295327767152],
            [-8.235630654431134, 42.231812905241384],
            [-8.164867712375013, 42.245908365478698],
            [-8.179611365272308, 42.209873207382572],
            [-8.183356651288392, 42.173116382015422],
            [-8.222361612899363, 42.15362965989371],
            [-8.252333943999957, 42.137584128000071],
            [-8.270679076999926, 42.132003072000145],
            [-8.287835652999917, 42.131434632000094],
            [-8.295380411999957, 42.130091045000114],
            [-8.304268757999864, 42.125827739000073],
            [-8.329951944999948, 42.10843861900014],
            [-8.346746785999898, 42.101694845000111],
            [-8.501207641999883, 42.083091330000059],
            [-8.520017862999879, 42.077716980000105],
            [-8.554434366999942, 42.060069478000088],
            [-8.572805337999938, 42.054410909000069],
            [-8.609418090999981, 42.053661601000073],
            [-8.626884724999911, 42.05102610300014],
            [-8.643886271999946, 42.041491802000053],
            [-8.65256791199991, 42.031440736000036],
            [-8.66321325699991, 42.009891663000076],
            [-8.670809691999864, 41.999814758000127],
            [-8.69127355899991, 41.989608664000059],
            [-8.717008422999925, 41.983769226000078],
            [-8.739591023999935, 41.975113424000114],
            [-8.750803188999953, 41.968980210000069],
            [-8.761952277999882, 41.95258209800005],
            [-8.777088995999861, 41.937160549000026],
            [-8.795399542999917, 41.925685940000079],
            [-8.815988735999952, 41.921210028000104],
            [-8.832834438999953, 41.913967190000108],
            [-8.848784959999875, 41.899481512000051],
            [-8.8646541009999, 41.888902085000012],
            [-8.881255662999962, 41.89329661700009],
            [-8.878732876999948, 41.907131252000084],
            [-8.898589647999927, 42.101752020000049],
            [-8.895497199999852, 42.119086005000042],
            [-8.884592251999891, 42.126044012000108],
            [-8.844960089999859, 42.126613674000083],
            [-8.826527472999913, 42.130519924000083],
            [-8.819081183999856, 42.139715887000051],
            [-8.823719855999883, 42.147609768000024],
            [-8.84276282499988, 42.154445705000057],
            [-8.846994594999984, 42.16083405200007],
            [-8.842030402999939, 42.164292710000069],
            [-8.815988735999952, 42.174505927000013],
            [-8.813465949999852, 42.178697007000039],
            [-8.811024542999917, 42.18813711100006],
            [-8.805775519999912, 42.197495835000069],
            [-8.79523678299995, 42.201727606000105],
            [-8.790638800999915, 42.20453522300005],
            [-8.77196204299986, 42.222235419000071],
            [-8.719553188999981, 42.252346096000082],
            [-8.7064509759999, 42.256415106000048],
            [-8.701893683999856, 42.258530992000061],
            [-8.669911261999857, 42.280910549000083],
            [-8.652007615999906, 42.286322333000044],
            [-8.631743943999908, 42.296820380000099],
            [-8.625152147999927, 42.301947333000015],
            [-8.621652798999918, 42.310207424000083],
            [-8.621652798999918, 42.340806382000011],
            [-8.622710740999878, 42.347479559000078],
            [-8.626454230999911, 42.34833405200007],
            [-8.634755011999914, 42.346380927000041],
            [-8.649891730999911, 42.335598049000026],
            [-8.660755988999881, 42.316799221000082],
            [-8.674794074999852, 42.299017645000077],
            [-8.699574347999913, 42.291164455000114],
            [-8.719838019999912, 42.287583726000037],
            [-8.773915167999888, 42.26898834800005],
            [-8.784982876999919, 42.260077216000099],
            [-8.795765753999945, 42.258246161000017],
            [-8.848296678999873, 42.260972398],
            [-8.867502407999922, 42.256415106000048],
            [-8.863636847999942, 42.26898834800005],
            [-8.853423631999902, 42.291083075000131],
            [-8.853260870999918, 42.304836330000057],
            [-8.846994594999984, 42.304836330000057],
            [-8.832997199999909, 42.289129950000074],
            [-8.827219204999949, 42.296698309000107],
            [-8.826527472999913, 42.329046942000048],
            [-8.828521287999962, 42.337225653000047],
            [-8.830718553999958, 42.341620184000135],
            [-8.827259894999941, 42.344142971000053],
            [-8.812855597999885, 42.346380927000041],
            [-8.776926235999895, 42.34015534100007],
            [-8.77196204299986, 42.342718817000076],
            [-8.764637824999852, 42.355698960000069],
            [-8.726877407999893, 42.388739325000131],
            [-8.709828253999916, 42.400376695000034],
            [-8.676869269999941, 42.416449286000017],
            [-8.662505662999877, 42.426906643000081],
            [-8.655262824999852, 42.441351630000042],
            [-8.66437740799995, 42.437974351000037],
            [-8.672515428999873, 42.438421942000019],
            [-8.682525193999936, 42.441351630000042],
            [-8.691273566999911, 42.440619208000015],
            [-8.694488084999875, 42.439642645000049],
            [-8.696766730999855, 42.437974351000037],
            [-8.718820766999954, 42.427964585000041],
            [-8.743641730999911, 42.413234768000052],
            [-8.757639126999948, 42.407212632000039],
            [-8.779367641999926, 42.402899481000048],
            [-8.811024542999917, 42.40070221600007],
            [-8.843576626999976, 42.403713283000059],
            [-8.867502407999922, 42.414699611000117],
            [-8.875721808999941, 42.425970770000021],
            [-8.88023841099988, 42.445013739000103],
            [-8.888010219999899, 42.455633856000048],
            [-8.900949673999889, 42.461004950000103],
            [-8.935454881999902, 42.465399481000077],
            [-8.942616339999859, 42.469305731000077],
            [-8.935047980999911, 42.479071356000105],
            [-8.916737433999856, 42.487616278000047],
            [-8.895985480999855, 42.493882554000095],
            [-8.881255662999962, 42.496568101000065],
            [-8.869007941999882, 42.493394273],
            [-8.860829230999883, 42.48501211100006],
            [-8.861154751999919, 42.475572007000039],
            [-8.874338344999956, 42.469305731000077],
            [-8.865549282999893, 42.465033270000077],
            [-8.851551886999914, 42.46076080900005],
            [-8.836984829999892, 42.458929755000071],
            [-8.826527472999913, 42.461859442000105],
            [-8.822499152999853, 42.472357489000075],
            [-8.825184699999937, 42.484116929000066],
            [-8.825062628999945, 42.494940497000044],
            [-8.812855597999885, 42.502752997000044],
            [-8.825184699999937, 42.537420966000099],
            [-8.826527472999913, 42.554632880000042],
            [-8.822987433999856, 42.569525458000072],
            [-8.813832160999965, 42.58144765800003],
            [-8.801584438999896, 42.589300848000022],
            [-8.788400844999927, 42.592189846000053],
            [-8.775502081999917, 42.604722398000035],
            [-8.738758917999917, 42.666083075000046],
            [-8.730946417821514, 42.688421942009569],
            [-8.716337459805942, 42.70645525740423],
            [-8.645431695516294, 42.725516537458219],
            [-8.608748344760357, 42.717804135052717],
            [-8.603474907341081, 42.719419124992612],
            [-8.602024711870968, 42.722165707581354],
            [-8.601651176660482, 42.726296567132863],
            [-8.599662650610867, 42.732086361995954],
            [-8.594125541860251, 42.73843645967483],
            [-8.578052542590768, 42.745874204630866],
            [-8.502334766836015, 42.756069517394309],
            [-8.472342089715625, 42.743248472039312],
            [-8.458290575749459, 42.742018003428058],
            [-8.445370652633471, 42.744292173045295],
            [-8.42164018334779, 42.756761656325352],
            [-8.392032027555956, 42.789489929017066],
            [-8.353327192395966, 42.780151549654306],
            [-8.342220013842535, 42.783985778620803],
            [-8.335913861535516, 42.791423522677434],
            [-8.33343095117749, 42.801421081717535],
            [-8.333848431759776, 42.812956726971322],
            [-8.339319623801572, 42.831666444051137],
            [-8.339616254386556, 42.836939881470386],
            [-8.335957806907288, 42.842674744843549],
            [-8.329322064761499, 42.845717958017275],
            [-8.321752484589467, 42.846113466363363],
            [-8.315314495267728, 42.843938172708476],
            [-8.308953411470782, 42.836786073118731],
            [-8.304361125965158, 42.828293641038698],
            [-8.298835002433407, 42.821635925757306],
            [-8.289661418439721, 42.820031921935623],
            [-8.262294473910856, 42.833764833080608],
            [-8.252066201893683, 42.836302674928561],
            [-8.220711220946043, 42.829040711459669],
            [-8.209944618349482, 42.830699646771279],
            [-8.204627235558405, 42.834292176642663],
            [-8.192190711532078, 42.847618593323404],
            [-8.174447790832915, 42.853012880739811],
            [-8.049214631949724, 42.834533875737804],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5841",
        OBJECTID_1: 7251,
        diss_me: 5841,
        adm1_cod_1: "ESP-5841",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Salamanca",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602119,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 40.8324,
        longitude: -5.9926000000000004,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3111107,
        gn_name: "Provincia de Salamanca",
        gns_id: -400106,
        gns_name: "Salamanca, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.SA",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.128650593949715, 41.197175415213223],
            [-6.109303669428783, 41.212644165395858],
            [-6.056360551798065, 41.208359497492808],
            [-6.031421586137299, 41.200734984931671],
            [-6.025620805156052, 41.196186645697168],
            [-6.022588578100368, 41.189979369352443],
            [-6.023104935544296, 41.181585815033287],
            [-6.028301469237419, 41.168775755796446],
            [-6.027642289559992, 41.165018431455451],
            [-6.007691116851504, 41.151637083284783],
            [-5.999495315356512, 41.149209104416599],
            [-5.991980666674351, 41.1504066146735],
            [-5.980488966792365, 41.161689573814812],
            [-5.976072462773971, 41.164150511037263],
            [-5.969722365095066, 41.163084837794997],
            [-5.965789259266842, 41.157470823519674],
            [-5.962735059975074, 41.150417600791656],
            [-5.958966749515838, 41.144990354121546],
            [-5.946760938466497, 41.141145139036894],
            [-5.932610547638603, 41.143803830882064],
            [-5.920547558822761, 41.151483274933213],
            [-5.914658886198652, 41.162667357212797],
            [-5.914966503801082, 41.168457152075888],
            [-5.918526074418793, 41.18052014089173],
            [-5.920767284782329, 41.20519543432178],
            [-5.917735058626022, 41.221861694761401],
            [-5.908089061660831, 41.232990845551058],
            [-5.889181590857703, 41.235276002185742],
            [-5.811266548312574, 41.216577271224082],
            [-5.711851261626379, 41.228530396160863],
            [-5.673267275564314, 41.245405397341187],
            [-5.660457216327472, 41.244076051868291],
            [-5.630486512342685, 41.216247681385369],
            [-5.611205505429666, 41.208689087331521],
            [-5.549143736194452, 41.206678589045623],
            [-5.496749935261278, 41.177290160112818],
            [-5.407518974321221, 41.185529906979582],
            [-5.401685234086244, 41.14778088208206],
            [-5.397213798577894, 41.14613293288852],
            [-5.362639822070093, 41.143924680879365],
            [-5.35597112067066, 41.140474973241396],
            [-5.337195485982846, 41.122050900628523],
            [-5.329142506721297, 41.117821164215314],
            [-5.320078785707381, 41.116151241886286],
            [-5.309553883105224, 41.117019162304473],
            [-5.301094410278807, 41.119864620855566],
            [-5.296337329404253, 41.124511837850974],
            [-5.294151050530502, 41.131147579996792],
            [-5.28877873535032, 41.182091185459768],
            [-5.262114915870683, 41.195857055858482],
            [-5.253084154110411, 41.196757934630938],
            [-5.244086351603869, 41.192517211200311],
            [-5.230781907159241, 41.176345335968449],
            [-5.222816819540526, 41.170335814246442],
            [-5.21118229742504, 41.167863890006544],
            [-5.18674870219084, 41.168061644629176],
            [-5.174487959651657, 41.165380980547809],
            [-5.125104412638564, 41.131993527279491],
            [-5.145527997419094, 41.100210079631438],
            [-5.151526533023059, 41.083313106214888],
            [-5.147153974376351, 41.050244256667156],
            [-5.144517255666756, 41.045168572971221],
            [-5.138419843201234, 41.041400262512099],
            [-5.117754559325476, 41.038313103966573],
            [-5.111074871807887, 41.034885369464206],
            [-5.104505047270152, 41.02811779120313],
            [-5.099901775646458, 41.019987908215342],
            [-5.095759929077445, 41.001662712464167],
            [-5.097385906034702, 40.985424918725073],
            [-5.103955730572522, 40.973768424373276],
            [-5.127499432253018, 40.9542457183652],
            [-5.111371502392956, 40.914771839648722],
            [-5.162490889343076, 40.868101915970641],
            [-5.159151045584281, 40.830188096603422],
            [-5.16151310594509, 40.81854258836978],
            [-5.179640547972895, 40.792944442132324],
            [-5.217499435850272, 40.772400008253754],
            [-5.243899583998569, 40.739979352265294],
            [-5.363046316534223, 40.674390970766609],
            [-5.385546317208764, 40.619723000216283],
            [-5.413484550671456, 40.599497169159008],
            [-5.447399347501914, 40.586522315452555],
            [-5.511526546512954, 40.582105811434047],
            [-5.517316342275308, 40.582929786480463],
            [-5.511834164115356, 40.548278906246537],
            [-5.436544854161667, 40.547828466410607],
            [-5.459605158551255, 40.479746188435769],
            [-5.467185724841329, 40.468496187648924],
            [-5.478446711746329, 40.460146577802277],
            [-5.51538274861494, 40.455708101547572],
            [-5.509098568544118, 40.428615815367579],
            [-5.520359555449147, 40.419233490633133],
            [-5.53457586388501, 40.418376557232364],
            [-5.549385435289594, 40.426495454101911],
            [-5.573269712926873, 40.448050630632082],
            [-5.575225279722815, 40.466848238455469],
            [-5.580070250441764, 40.472385348105348],
            [-5.590507263199612, 40.473582858362192],
            [-5.617017273428417, 40.467694185738083],
            [-5.687714297876539, 40.42458383267774],
            [-5.694602726134917, 40.414102875447355],
            [-5.692724063964306, 40.404621673851182],
            [-5.679628360260551, 40.385417571563664],
            [-5.674739444170001, 40.371366057597498],
            [-5.675134951616712, 40.358830656709443],
            [-5.680430362171478, 40.347393888317214],
            [-5.727732713394005, 40.293974921405521],
            [-5.762433437653044, 40.284414780917388],
            [-5.783646612800965, 40.286145942581186],
            [-5.789796107704234, 40.290460923932528],
            [-5.79181148930877, 40.296041978954122],
            [-5.783000657654185, 40.308082587241586],
            [-5.780752732952237, 40.315058906243536],
            [-5.78157955615157, 40.323352973061247],
            [-5.785377772666038, 40.331647039879044],
            [-5.794989589997584, 40.341827297991358],
            [-5.804162156858069, 40.342964179553235],
            [-5.81333472281915, 40.340845445161222],
            [-5.834082810873014, 40.332215481559246],
            [-5.867620815148427, 40.32492910419478],
            [-5.875062222143839, 40.321673489140238],
            [-5.881805996249739, 40.31748769809883],
            [-5.885810920137828, 40.311286526352148],
            [-5.888213873571374, 40.304361884193526],
            [-5.891805386309528, 40.29689464057526],
            [-5.896456265344398, 40.289995835939067],
            [-5.903690964966017, 40.284259752185847],
            [-5.912450119777162, 40.28074575471274],
            [-5.925550096160009, 40.281495063546402],
            [-5.951543340652648, 40.28914317521739],
            [-5.987613491369501, 40.303225003531026],
            [-6.001204393268154, 40.311519070348822],
            [-6.009524299406849, 40.319244697284972],
            [-6.023011846719385, 40.327771308099443],
            [-6.04507768438765, 40.3376931828941],
            [-6.080036790165764, 40.348390204044733],
            [-6.093162604070898, 40.353687039125504],
            [-6.09626319039387, 40.357562770905105],
            [-6.08957109223212, 40.361154282743968],
            [-6.086625534640774, 40.365081692266273],
            [-6.084248419628807, 40.37293651041162],
            [-6.082336391711124, 40.37665721345968],
            [-6.078408983088025, 40.380739650814235],
            [-6.075618455127568, 40.384718736280604],
            [-6.07507585276835, 40.388697821747058],
            [-6.069107225018456, 40.396294257474011],
            [-6.069133063440063, 40.400169990152875],
            [-6.074610764775002, 40.404743353922612],
            [-6.095591395926164, 40.410608628885143],
            [-6.107735357900509, 40.415517890338322],
            [-6.115822720042502, 40.421253974091627],
            [-6.116416999245075, 40.427403468994868],
            [-6.117967291956973, 40.43381134721588],
            [-6.137139247977927, 40.437532050263854],
            [-6.146518521312743, 40.441304430155355],
            [-6.16258989101064, 40.454481920004611],
            [-6.172899339432973, 40.460915635747966],
            [-6.206669887705175, 40.477555447126178],
            [-6.224110683860999, 40.480320135765623],
            [-6.236693895406972, 40.479441637521518],
            [-6.246228196574037, 40.475255846480081],
            [-6.280205451320484, 40.455980535872982],
            [-6.292581956392127, 40.451691392943445],
            [-6.305501063822987, 40.448745836251305],
            [-6.323148565553879, 40.446317044396011],
            [-6.333483853297196, 40.441588650096094],
            [-6.342423875261602, 40.436085110339548],
            [-6.349503547051029, 40.430607408105431],
            [-6.354464484448357, 40.424302883571272],
            [-6.357720098603693, 40.417352402990929],
            [-6.362732712844434, 40.410401923310076],
            [-6.372396206119987, 40.405440985912747],
            [-6.402213508246803, 40.40063507724696],
            [-6.412807175710583, 40.397431139035774],
            [-6.421307949002625, 40.393400376725992],
            [-6.44226274263147, 40.375933743047739],
            [-6.47983150921678, 40.365546780259734],
            [-6.487402105622635, 40.362110297152498],
            [-6.507840135313955, 40.355857449461752],
            [-6.515539923828385, 40.352420966354515],
            [-6.546881680245292, 40.33363658306186],
            [-6.553082851991974, 40.327822984043507],
            [-6.557966274124254, 40.321570136352676],
            [-6.558121303755172, 40.314697171037579],
            [-6.552772792730167, 40.300847886720618],
            [-6.553186203880188, 40.293871567718668],
            [-6.556105923049898, 40.287101956090424],
            [-6.562229580430824, 40.281495063546402],
            [-6.571014573663604, 40.276534125249697],
            [-6.585148077921474, 40.271986599002304],
            [-6.655040451955301, 40.262038885785955],
            [-6.668398809857194, 40.258163154006354],
            [-6.677416347986025, 40.25351227497157],
            [-6.68857845713012, 40.249739895080069],
            [-6.698707038399107, 40.250850938220225],
            [-6.717646450423302, 40.258938300362246],
            [-6.726328090868691, 40.258111477162913],
            [-6.758780890425754, 40.244830634526181],
            [-6.777771979293391, 40.24648428002547],
            [-6.785135871023556, 40.248706367205074],
            [-6.794385952249883, 40.247905382427433],
            [-6.804695399772839, 40.24436554653272],
            [-6.814694789832629, 40.244417223376161],
            [-6.824513311839894, 40.246355088816273],
            [-6.834745245896357, 40.250308335861007],
            [-6.844253708641702, 40.255786038095124],
            [-6.86218543121268, 40.27175405500563],
            [-6.874509092060407, 40.279188166850332],
            [-6.873865112999908, 40.287774556000073],
            [-6.870196085999908, 40.295526022000075],
            [-6.863943236999916, 40.300952047000038],
            [-6.820793415999901, 40.325343323000055],
            [-6.811956745999908, 40.332319641000055],
            [-6.794438435999922, 40.356400859000104],
            [-6.828079793999962, 40.380585429000064],
            [-6.85128251099988, 40.409524231000034],
            [-6.857121948999861, 40.442132060000091],
            [-6.838673461999861, 40.477220358000125],
            [-6.815315714999912, 40.50254181000011],
            [-6.812525186999949, 40.514634095000105],
            [-6.818054564999954, 40.531894023000092],
            [-6.828544881999903, 40.545536601000094],
            [-6.839758666999955, 40.554579976000042],
            [-6.848388630999892, 40.564760234000119],
            [-6.850455688999944, 40.582226868000078],
            [-6.844461222999939, 40.597781474000101],
            [-6.821310180999944, 40.6301825970001],
            [-6.814282185999929, 40.647184143000118],
            [-6.814230508999913, 40.659328105000014],
            [-6.816245889999948, 40.677156474000071],
            [-6.824307413999861, 40.710694478000079],
            [-6.836968139999897, 40.743302308000125],
            [-6.839138549999888, 40.757513326000094],
            [-6.839138549999888, 40.776891989000063],
            [-6.837278197999893, 40.786762187000136],
            [-6.834539347999936, 40.792239889000115],
            [-6.833505818999953, 40.797820943000033],
            [-6.836813109999952, 40.808311260000096],
            [-6.838053343999889, 40.814925842000093],
            [-6.836658080999911, 40.840247294000079],
            [-6.82032832799996, 40.844226380000066],
            [-6.81619421399995, 40.856835429000085],
            [-6.821155151999903, 40.870064596000077],
            [-6.832058877999941, 40.875904033000069],
            [-6.847045043999941, 40.878901266000071],
            [-6.855261596999867, 40.887221172000125],
            [-6.863633178999947, 40.908305156000068],
            [-6.885182251999908, 40.948922832000108],
            [-6.899238240999921, 40.966751201000136],
            [-6.930605834999938, 40.992072652000019],
            [-6.940424356999898, 41.003389791000103],
            [-6.942491414999949, 41.015998840000123],
            [-6.931794392999961, 41.0291246540001],
            [-6.927866983999905, 41.047934876000085],
            [-6.92744785701646, 41.048097583968257],
            [-6.90683467599996, 41.056099752000108],
            [-6.845288044999961, 41.051913961000082],
            [-6.818002888999928, 41.054136048000089],
            [-6.804360310999925, 41.064419658000077],
            [-6.764776163999983, 41.108861390000044],
            [-6.756507934999917, 41.127051494000085],
            [-6.758988403999894, 41.151752828000014],
            [-6.755216023999878, 41.15883250000013],
            [-6.742245238999942, 41.171441549000065],
            [-6.706950235999955, 41.19433420900009],
            [-6.701265828999908, 41.202447408000069],
            [-6.693772745999894, 41.217381897000053],
            [-6.658219359999919, 41.257844544000136],
            [-6.646643839999882, 41.267611390000098],
            [-6.628918823999868, 41.269936829000102],
            [-6.563496459999897, 41.27071197500004],
            [-6.554453083999931, 41.275621237000053],
            [-6.495852010999897, 41.294948222000102],
            [-6.482128385747274, 41.302991610350063],
            [-6.321889127081505, 41.280473758157399],
            [-6.128650593949715, 41.197175415213223],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5842",
        OBJECTID_1: 3626,
        diss_me: 5842,
        adm1_cod_1: "ESP-5842",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 3,
        name: "Santa Cruz de Tenerife",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CN",
        note: null,
        hasc_maybe: null,
        region: "Canary Is.",
        region_cod: "ES.CN",
        provnum_ne: 0,
        gadm_level: 0,
        check_me: 0,
        scalerank: 10,
        datarank: 10,
        abbrev: null,
        postal: null,
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 20,
        featurecla: "Admin-1 scale rank",
        name_len: 22,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: null,
        fips_alt: null,
        woe_id: 12602109,
        woe_label: null,
        woe_name: "Santa Cruz de Tenerife",
        latitude: 28.256599999999999,
        longitude: -16.6144,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 5,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2511173,
        gn_name: "Provincia de Santa Cruz de Tenerife",
        gns_id: -401687,
        gns_name: "Santa Cruz de Tenerife, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.TF",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP53",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-17.885121222999942, 27.797919012000037],
              [-17.926421678999901, 27.742824611000103],
              [-17.927601691999911, 27.736517645000148],
              [-17.926909959999875, 27.730373440000065],
              [-17.926909959999875, 27.724351304000081],
              [-17.930165167999917, 27.718654690000093],
              [-17.935129360999952, 27.715643622000087],
              [-17.940663214999887, 27.71369049700003],
              [-17.945139126999948, 27.710760809000121],
              [-17.946929490999935, 27.704657294000029],
              [-17.950306769999941, 27.699530341000113],
              [-17.957753058999941, 27.693996486000103],
              [-17.965240037999905, 27.686997789000102],
              [-17.968617316999911, 27.677679755000085],
              [-17.97130286399991, 27.654364325000145],
              [-17.979481574999909, 27.642523505000028],
              [-17.994130011999914, 27.642238674000069],
              [-18.015777147999898, 27.653469143000066],
              [-18.030669725999871, 27.667873440000122],
              [-18.039784308999856, 27.681341864000117],
              [-18.053130662999877, 27.691229559000035],
              [-18.126942511999886, 27.700262762000037],
              [-18.146636522999927, 27.708563544000029],
              [-18.160389777999853, 27.722357489000117],
              [-18.167225714999859, 27.757554429],
              [-18.140492316999939, 27.76878489799999],
              [-18.097767706999917, 27.765366929000081],
              [-18.056792772999927, 27.756496486000131],
              [-18.041981574999852, 27.765204169000114],
              [-18.003244594999899, 27.798814195000134],
              [-17.995350714999944, 27.808050848],
              [-17.994130011999914, 27.818752346000124],
              [-17.989857550999915, 27.823716539000159],
              [-17.968617316999911, 27.83222077],
              [-17.933949347999942, 27.852036851000108],
              [-17.914051886999857, 27.849554755],
              [-17.896473761999914, 27.840318101000136],
              [-17.884022589999887, 27.827582098000093],
              [-17.879261847999913, 27.814520575000088],
              [-17.885121222999942, 27.797919012000037],
            ],
          ],
          [
            [
              [-17.207110520999947, 28.026693299000058],
              [-17.232705541999906, 28.019106132000061],
              [-17.257887594999914, 28.02456954100002],
              [-17.310073186999887, 28.053473574000137],
              [-17.343220901999928, 28.097660414000146],
              [-17.343376510999917, 28.133356288000087],
              [-17.338816928999876, 28.171047137000173],
              [-17.309673192999924, 28.20711735499999],
              [-17.259173290999883, 28.217716638000084],
              [-17.212721082999906, 28.205449330000093],
              [-17.17039954299986, 28.181097723000065],
              [-17.116118943999908, 28.146226304000137],
              [-17.104279473999952, 28.123035265000041],
              [-17.098419696999883, 28.100763585000053],
              [-17.113937354999877, 28.080967118000061],
              [-17.133525170999917, 28.069778589000165],
              [-17.16318652599989, 28.045013603000129],
              [-17.184975310999988, 28.031619039000034],
              [-17.207110520999947, 28.026693299000058],
            ],
          ],
          [
            [
              [-16.117746548999946, 28.572088934000178],
              [-16.127070471999929, 28.543818502000178],
              [-16.157397776999858, 28.524482834000125],
              [-16.231391362999915, 28.48841554799999],
              [-16.248014738999899, 28.46112282000017],
              [-16.268671852999944, 28.443315981000026],
              [-16.296135933999949, 28.417762461000123],
              [-16.323895607999873, 28.397469471000093],
              [-16.341633609999889, 28.391334892000103],
              [-16.355513648999903, 28.381149772000086],
              [-16.365771928999948, 28.362455298000057],
              [-16.366106515999917, 28.341425299000079],
              [-16.360577139999918, 28.324581066000107],
              [-16.35810622799994, 28.314630928000085],
              [-16.36133221699987, 28.304423457000084],
              [-16.3710002279999, 28.297118609000066],
              [-16.380015986999894, 28.288342115000077],
              [-16.386773159999876, 28.264989735000128],
              [-16.419232876999928, 28.211382738000125],
              [-16.427966321999918, 28.167685903000162],
              [-16.427695488999888, 28.14966954],
              [-16.441029425999943, 28.132757880000057],
              [-16.45677649599989, 28.115464585000055],
              [-16.521546654999923, 28.057573086000119],
              [-16.536327270999919, 28.036474285000125],
              [-16.545765753999888, 28.023993231000034],
              [-16.579710212999885, 28.030332462000061],
              [-16.634421504999921, 28.011656512000073],
              [-16.657439618999945, 28.003653684000042],
              [-16.676339731999917, 27.997096487000093],
              [-16.692683274999865, 27.999846942000104],
              [-16.703551699999934, 28.013683183000168],
              [-16.705751978999899, 28.023000334000031],
              [-16.704090949999852, 28.034979559000178],
              [-16.713246222999942, 28.038275458000115],
              [-16.721058722999942, 28.044501044000171],
              [-16.724110480999911, 28.052679755],
              [-16.725941535999908, 28.071478583000143],
              [-16.727894660999937, 28.07859935099999],
              [-16.741607225999871, 28.093817450000145],
              [-16.780629035999937, 28.12787506700009],
              [-16.788685675999886, 28.143784898000106],
              [-16.795399542999917, 28.165269273000135],
              [-16.810536261999914, 28.181870835],
              [-16.826812303999873, 28.195746161000088],
              [-16.837147589999859, 28.208970445000105],
              [-16.843983527999882, 28.253078518],
              [-16.847238735999923, 28.26251862200003],
              [-16.861195441999911, 28.281887111000131],
              [-16.873402472999885, 28.305243231000034],
              [-16.906605597999885, 28.335150458000115],
              [-16.916058089999865, 28.354969806000142],
              [-16.891935291999857, 28.368656446000145],
              [-16.837439856999907, 28.391082809000025],
              [-16.817766410999866, 28.383563112000033],
              [-16.794178839999915, 28.369126695000048],
              [-16.782541469999899, 28.366034247000172],
              [-16.770130988999881, 28.366278387000122],
              [-16.74156653599988, 28.373480536000145],
              [-16.719619392999931, 28.383261875000144],
              [-16.687189944999915, 28.392780067000146],
              [-16.6641795029999, 28.400726786000135],
              [-16.644398566999882, 28.396429755000142],
              [-16.604406380999905, 28.399666977000109],
              [-16.584633967999878, 28.401099510000151],
              [-16.564478885999876, 28.41533534100013],
              [-16.538929816999882, 28.417873440000065],
              [-16.528187628999973, 28.42210521],
              [-16.507135176999981, 28.424134052000099],
              [-16.48690353399985, 28.438273596],
              [-16.439930792999888, 28.475246486000103],
              [-16.420399542999917, 28.490627346000124],
              [-16.41429602799991, 28.499212958000143],
              [-16.411976691999968, 28.513739325000088],
              [-16.408273891999926, 28.51984284100017],
              [-16.389393683999884, 28.528753973],
              [-16.381214972999885, 28.534247137000037],
              [-16.363352016999926, 28.543036200000117],
              [-16.341053839999887, 28.546576239000089],
              [-16.322743292999917, 28.553452867000189],
              [-16.316395636999886, 28.572088934000178],
              [-16.300160285999937, 28.56663646000014],
              [-16.202707485999895, 28.564032294000143],
              [-16.141346808999884, 28.58344147300015],
              [-16.117746548999946, 28.572088934000178],
            ],
          ],
          [
            [
              [-17.86583411399991, 28.84064362200003],
              [-17.841664191999911, 28.835435289000046],
              [-17.80138098899991, 28.849310614000117],
              [-17.781483527999939, 28.842271226000051],
              [-17.765004035999937, 28.829413153000033],
              [-17.755767381999874, 28.818508205000157],
              [-17.757801886999914, 28.798651434000149],
              [-17.750602426999933, 28.774762657000124],
              [-17.741438084999913, 28.757911764000042],
              [-17.721882904999887, 28.74902884900014],
              [-17.721044159999849, 28.730643804000138],
              [-17.751099500999942, 28.699983927000133],
              [-17.765253519999874, 28.677548591000047],
              [-17.757532133999888, 28.657676527000021],
              [-17.745604195999903, 28.621886961000044],
              [-17.753660395999901, 28.603026217000107],
              [-17.759856402999901, 28.57682100300012],
              [-17.783340290999888, 28.548595946000134],
              [-17.803297796999885, 28.509456075000074],
              [-17.821685350999928, 28.475816148000078],
              [-17.830881313999896, 28.455389716000084],
              [-17.837066209999875, 28.455389716000084],
              [-17.842884894999912, 28.465033270000063],
              [-17.865122056999979, 28.475381034000023],
              [-17.872153170999866, 28.49442620900011],
              [-17.879687701999956, 28.543712542000108],
              [-17.891166430999903, 28.555651122000157],
              [-17.898589853999852, 28.571648749000175],
              [-17.919289367999852, 28.596303282000022],
              [-17.924838353999888, 28.618339006000113],
              [-17.963098057999957, 28.671488781],
              [-17.967145670999912, 28.69673583100014],
              [-17.97141390399986, 28.712563172],
              [-17.97614498599998, 28.728176174000154],
              [-17.992298956999946, 28.738592841000141],
              [-18.002186652999853, 28.749579169000114],
              [-17.996815558999884, 28.780096747000144],
              [-17.970692511999857, 28.813218492000161],
              [-17.936350063999896, 28.840318101000108],
              [-17.911145077999947, 28.855619032],
              [-17.890534539999919, 28.843945767000051],
              [-17.86583411399991, 28.84064362200003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5843",
        OBJECTID_1: 7249,
        diss_me: 5843,
        adm1_cod_1: "ESP-5843",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Segovia",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602120,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 41.206600000000002,
        longitude: -4.0467199999999997,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3109254,
        gn_name: "Provincia de Segovia",
        gns_id: -402550,
        gns_name: "Segovia",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.SG",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.834089959084309, 41.499519176468795],
            [-3.786046744561645, 41.502100963688548],
            [-3.769918814701498, 41.460067270887805],
            [-3.729093617376549, 41.457650278137777],
            [-3.729566029448677, 41.504957409257059],
            [-3.72722594132415, 41.514076061760818],
            [-3.719952992636507, 41.521590710442979],
            [-3.697409045690904, 41.53104993980287],
            [-3.690861194288658, 41.535850965149905],
            [-3.678611437867545, 41.554275037762807],
            [-3.672766711514498, 41.559240858478859],
            [-3.647542100487527, 41.570161269427075],
            [-3.623317245994144, 41.573610976165583],
            [-3.572944930364201, 41.565415174670591],
            [-3.54173277075077, 41.577774794970793],
            [-3.536536237057646, 41.556768934238875],
            [-3.53187803394411, 41.548078748435472],
            [-3.523550397233038, 41.540662976615124],
            [-3.482780131397931, 41.522777233682376],
            [-3.475056742874358, 41.523623180964989],
            [-3.456544779517913, 41.530357800871798],
            [-3.449282816049134, 41.529621716568954],
            [-3.437373635584834, 41.524633923616705],
            [-3.427145363567661, 41.516888561957558],
            [-3.424365822624566, 41.512230358843993],
            [-3.421278664079068, 41.488730601635922],
            [-3.418872658346459, 41.483589000332103],
            [-3.414719826558752, 41.479600963014036],
            [-3.382354102060134, 41.46521985830978],
            [-3.348472265382668, 41.461110971893788],
            [-3.340979588936818, 41.457089975322077],
            [-3.337452977572809, 41.452673471303655],
            [-3.336914647892684, 41.448015268190034],
            [-3.345604833696086, 41.405036751245035],
            [-3.343660253018271, 41.388007941713141],
            [-3.332629979090257, 41.377054572410543],
            [-3.198791470150184, 41.301200263381418],
            [-3.219823778144871, 41.293035386873555],
            [-3.249175991378877, 41.266628730331604],
            [-3.279871791749827, 41.266008612707338],
            [-3.291085577737249, 41.260685940104054],
            [-3.339764776623127, 41.253554592370563],
            [-3.389710049179371, 41.255569973075808],
            [-3.416891852077327, 41.26409658478957],
            [-3.419759894403626, 41.261202704041551],
            [-3.416400925662145, 41.256396796275141],
            [-3.411104092379929, 41.250609036577771],
            [-3.406530727710845, 41.244046128725842],
            [-3.406194830926665, 41.237173163410745],
            [-3.408752813991157, 41.221799424803606],
            [-3.411310797954911, 41.213608709874109],
            [-3.41921729204438, 41.210714830025381],
            [-3.442109951113338, 41.206529038983945],
            [-3.519624600195101, 41.162216498292821],
            [-3.527918667012898, 41.160046087956573],
            [-3.545772874318658, 41.161751410299274],
            [-3.592204149401965, 41.154077460206651],
            [-3.623881801703789, 41.139401354488939],
            [-3.633829514920137, 41.131959947493527],
            [-3.641477626591154, 41.124621894185083],
            [-3.647627122393772, 41.117413032085722],
            [-3.665429652856176, 41.099972235929897],
            [-3.684911668138966, 41.084701850110235],
            [-3.72790646011498, 41.061990058194652],
            [-3.743021816303724, 41.051758124138075],
            [-3.752762823945204, 41.04302480684936],
            [-3.762529670008234, 41.028064480291533],
            [-3.768472459336522, 41.020623074195498],
            [-3.773485072677886, 41.013000800046939],
            [-3.787127651419979, 40.996774399818662],
            [-3.796222703015388, 40.991451728114754],
            [-3.805731166659996, 40.987265937073317],
            [-3.891875780243112, 40.964838365098473],
            [-3.902081874978478, 40.958533840564286],
            [-3.911512824257443, 40.950265611268946],
            [-3.919884406340287, 40.940240382787437],
            [-3.934793056954049, 40.926158556272355],
            [-3.947893032437463, 40.90827851054479],
            [-3.951071133126277, 40.90137970590871],
            [-3.95306067540983, 40.894300035018631],
            [-3.949598353880987, 40.880579941910781],
            [-3.949908413142708, 40.87308685987071],
            [-3.952931484200633, 40.865206204202934],
            [-3.959365199943989, 40.855981961398328],
            [-3.963654343772902, 40.848023790465589],
            [-3.965437181380764, 40.840427353839289],
            [-3.966444870834152, 40.825647895334072],
            [-3.968615282069607, 40.81828400270453],
            [-3.975746628903806, 40.803659572930968],
            [-3.98024247920705, 40.796631577984954],
            [-3.98409237256493, 40.788725083895457],
            [-3.990112677158265, 40.78456513127577],
            [-3.995848760911599, 40.783014838563957],
            [-4.018741420879905, 40.779371649881739],
            [-4.027836473374578, 40.779604193878441],
            [-4.036311408244927, 40.782317206573822],
            [-4.047473517388909, 40.783970852073111],
            [-4.059850023359843, 40.784125881704028],
            [-4.079642096105886, 40.776451930712028],
            [-4.090106574159051, 40.76913971582519],
            [-4.128863898250273, 40.725473131180053],
            [-4.132739630929166, 40.719220283489307],
            [-4.161290859385645, 40.687310086291461],
            [-4.185224006182921, 40.695715435219171],
            [-4.24584656696544, 40.700802105033148],
            [-4.261018686563091, 40.698857525254539],
            [-4.276278696904313, 40.692661235927289],
            [-4.299349987411972, 40.696231792663099],
            [-4.318488171192115, 40.6823560592847],
            [-4.321926891812637, 40.65787851957802],
            [-4.343888562806853, 40.646892191021777],
            [-4.419540420953695, 40.633368020617695],
            [-4.444578264375366, 40.751427106775481],
            [-4.45294984645821, 40.763676863196707],
            [-4.475098285057811, 40.783078719207481],
            [-4.480338763223358, 40.797481796147935],
            [-4.483151263420098, 40.816059678011754],
            [-4.487446918340567, 40.824046738765958],
            [-4.495247211489669, 40.830990098514263],
            [-4.510814838533918, 40.832890732921015],
            [-4.546674215142929, 40.824046738765958],
            [-4.559780904964725, 40.830638535539975],
            [-4.561033346711582, 40.841086534416064],
            [-4.548069479123171, 40.863652452698489],
            [-4.545542623393374, 40.875726427632486],
            [-4.560044577195441, 40.914299427576509],
            [-4.563648093184952, 40.946302602982684],
            [-4.568328268534799, 40.953454702572515],
            [-4.574898093072534, 40.95716808154171],
            [-4.590575583995957, 40.961408804073073],
            [-4.601836570900957, 40.967649038772095],
            [-4.611163964145675, 40.97676769127591],
            [-4.631543603554405, 41.016098747758974],
            [-4.642650781208573, 41.029018669975699],
            [-4.6994501000421, 41.068020136619936],
            [-4.713853176982468, 41.090564082666248],
            [-4.70151552981784, 41.154998899279775],
            [-4.650495019729362, 41.199943970038163],
            [-4.650835595686203, 41.221554078058205],
            [-4.579138815603812, 41.278847781200142],
            [-4.606989159222309, 41.298018924233929],
            [-4.596958640928563, 41.320738651767385],
            [-4.557506735347488, 41.302545292131583],
            [-4.534160787390448, 41.319376347040759],
            [-4.471362932953014, 41.291569949693411],
            [-4.470330218065101, 41.30263318287507],
            [-4.472285784861072, 41.309181034277316],
            [-4.49223695756956, 41.334306767543268],
            [-4.49555482819278, 41.341063359686274],
            [-4.496071185636708, 41.3639808409431],
            [-4.514934711967356, 41.409618049733155],
            [-4.486458148824539, 41.408947883937685],
            [-4.407532364526986, 41.44187391035274],
            [-4.364960341146826, 41.438786752706505],
            [-4.324212048447293, 41.450959604502117],
            [-4.252372446131488, 41.452047250879872],
            [-4.166250616872503, 41.477535533238267],
            [-4.13375305715789, 41.479963511207103],
            [-4.014946899679614, 41.513911267291093],
            [-3.999148559658266, 41.527699109026628],
            [-3.991458129489075, 41.540234509914683],
            [-3.980614623166332, 41.583245986113411],
            [-3.88901061574731, 41.547100965037487],
            [-3.835803826785138, 41.499870738543706],
            [-3.834089959084309, 41.499519176468795],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5844",
        OBJECTID_1: 7270,
        diss_me: 5844,
        adm1_cod_1: "ESP-5844",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Sevilla",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AN",
        note: null,
        hasc_maybe: null,
        region: "Andalucía",
        region_cod: "ES.AN",
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP80",
        fips_alt: "SP51",
        woe_id: 12602104,
        woe_label: "Andalusia, ES, Spain",
        woe_name: "Andalucía",
        latitude: 37.438000000000002,
        longitude: -5.7391699999999997,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2510910,
        gn_name: "Provincia de Sevilla",
        gns_id: -402850,
        gns_name: "Seville",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.SE",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP51",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.710886196440754, 38.088683172790212],
            [-5.693574590594835, 38.091266995175658],
            [-5.671431240359453, 38.104005235453286],
            [-5.649520433221397, 38.120490017200495],
            [-5.642828335059562, 38.12682038015646],
            [-5.623449672564334, 38.136897284582005],
            [-5.581049159790894, 38.135088609451799],
            [-5.58182806351428, 38.115554203263883],
            [-5.496068782448276, 38.042835695311084],
            [-5.478589533979743, 37.981290282620421],
            [-5.421834160518074, 37.940684812154416],
            [-5.415088555392487, 37.927424313980936],
            [-5.413374687691658, 37.88508300357779],
            [-5.404508720401111, 37.866823725434614],
            [-5.393236747377955, 37.857606196069071],
            [-5.360112967239644, 37.841796869030304],
            [-5.349632009109911, 37.830590813615146],
            [-5.346522878328187, 37.805278312743866],
            [-5.341205495537224, 37.792292472919257],
            [-5.334690602489331, 37.786129141946304],
            [-5.309707691456794, 37.773428946588609],
            [-5.31108098230149, 37.697919910675296],
            [-5.390028739734532, 37.697579333819249],
            [-5.414022881250929, 37.673903796023382],
            [-5.401004082172705, 37.631035142058181],
            [-5.380108085319847, 37.611765122162666],
            [-5.344369557808875, 37.609985336853839],
            [-5.276770678923697, 37.624487290655935],
            [-5.191055343229465, 37.669586168866601],
            [-5.179453780367709, 37.664016100862341],
            [-5.152548261792816, 37.661917711832956],
            [-5.129422039795344, 37.665554186176735],
            [-5.123137860623729, 37.669432360515032],
            [-5.10502140471408, 37.690932605555275],
            [-5.052429850057536, 37.708280017908464],
            [-5.02664493621478, 37.710510243053278],
            [-5.002112465018257, 37.703995350005385],
            [-4.982073401566254, 37.690163561998787],
            [-4.966538732876273, 37.670882555985145],
            [-4.955541418201875, 37.648020006218275],
            [-4.954937170014318, 37.640549302907999],
            [-4.957958410951932, 37.626365952826461],
            [-4.955222814481317, 37.618367905054754],
            [-4.950213049292756, 37.613995346408132],
            [-4.937337071548569, 37.607941879314239],
            [-4.932283360988322, 37.602492660407904],
            [-4.928020665321469, 37.583684066466475],
            [-4.936183507562816, 37.537827130817362],
            [-4.943884923850192, 37.520183086979841],
            [-4.908267246336322, 37.494771709246947],
            [-4.887525057835148, 37.462592753252892],
            [-4.884877353007397, 37.438148171900565],
            [-4.849863923681113, 37.410693336628242],
            [-4.822068512451835, 37.358189672715184],
            [-4.783836089364058, 37.330449192975834],
            [-4.763445463837058, 37.328823216917868],
            [-4.710579250831756, 37.361606421099509],
            [-4.691946437478094, 37.354575170607603],
            [-4.677246729952572, 37.340765355736579],
            [-4.670654933178639, 37.29906125277455],
            [-4.658075586918784, 37.277407199382765],
            [-4.653230615300657, 37.255928926578719],
            [-4.719818753332788, 37.259708224055288],
            [-4.735595121117939, 37.244931611904349],
            [-4.718434475470673, 37.214620331063401],
            [-4.721708401621498, 37.195646941752983],
            [-4.73808901759412, 37.181243864812529],
            [-4.777266264826238, 37.162248503265801],
            [-4.796349518015802, 37.15852413817845],
            [-4.839888337776586, 37.191779754432105],
            [-4.848128083744086, 37.192658660968362],
            [-4.853533358177913, 37.188110320834596],
            [-4.861586336540199, 37.174157683730073],
            [-4.870166659363804, 37.166840788771367],
            [-4.878340487723307, 37.166027799843079],
            [-4.920835606478022, 37.179562957264608],
            [-4.933535801835802, 37.179584929500919],
            [-4.945357091556502, 37.17666256632441],
            [-4.952168615189464, 37.172806365121687],
            [-4.95482730703452, 37.170147673276517],
            [-4.95692569606399, 37.166774870263993],
            [-4.946411779579989, 37.155327116653154],
            [-4.902103916262718, 37.134211392941381],
            [-4.916594883946743, 37.113040737739851],
            [-5.142243085150227, 37.003704795739708],
            [-5.176619307934743, 36.970811728578383],
            [-5.196460616764028, 36.958276327690328],
            [-5.21899357669227, 36.949641073376853],
            [-5.236450852924492, 36.949311483538168],
            [-5.250392503910888, 36.956694296104871],
            [-5.296150561798981, 37.01287838063277],
            [-5.306345875461801, 37.017591515236262],
            [-5.314970142757829, 37.016372032743135],
            [-5.424152276406403, 36.950959432731622],
            [-5.436698663412614, 36.94876216684051],
            [-5.446674249317113, 36.958539999021696],
            [-5.447761895694896, 36.977019004023873],
            [-5.42827214894055, 37.008022422896488],
            [-5.426184746928499, 37.025051232428467],
            [-5.433194024284717, 37.035773888753994],
            [-5.445169122357072, 37.042684289248569],
            [-5.458978937228096, 37.045881311673156],
            [-5.471492365879953, 37.045496789445309],
            [-5.488971614348486, 37.039652063092205],
            [-5.494519710116549, 37.03375240434994],
            [-5.497562923290275, 37.024238244399498],
            [-5.493179378525497, 36.988115195559885],
            [-5.495343685162965, 36.975327109458533],
            [-5.506736507284046, 36.9547826746807],
            [-5.523710385325984, 36.938621785566909],
            [-5.544639341432486, 36.927020222705124],
            [-5.565744078126727, 36.920823933377818],
            [-5.586870787956485, 36.922351033473348],
            [-5.642219911319813, 36.958474081413783],
            [-5.66907049840475, 36.965186728185017],
            [-5.692614200085245, 36.952695272668649],
            [-5.717047795319502, 36.919626424020265],
            [-5.806388618340094, 36.920615193536321],
            [-5.843445505205892, 36.911079060450277],
            [-5.866242136465388, 36.896994587230409],
            [-5.880271678195356, 36.875087847726036],
            [-5.891038279892655, 36.842073930567494],
            [-5.941487501047305, 36.852016558117668],
            [-5.973051222735734, 36.844128374225079],
            [-6.105183795995345, 36.857498735378329],
            [-6.157687459908317, 36.885997271656748],
            [-6.231609807583254, 36.902832809828993],
            [-6.223255988999881, 36.90232982],
            [-6.194813605999855, 36.932114976000037],
            [-6.214019334999904, 36.921698309000135],
            [-6.2396541009999, 36.913397528000047],
            [-6.2650447259999, 36.912909247000073],
            [-6.283558722999913, 36.925930080000057],
            [-6.325917120999918, 36.912665106000105],
            [-6.339066520795427, 36.904695773284146],
            [-6.378336882940744, 36.934040487078789],
            [-6.354320768288858, 36.985170860147065],
            [-6.379951872880582, 37.048067590546822],
            [-6.381621795209696, 37.061503870207446],
            [-6.380149626604009, 37.073446009925448],
            [-6.37542550588239, 37.084256556994518],
            [-6.355770963758971, 37.107086147507658],
            [-6.352881559836078, 37.114139370235762],
            [-6.354035123821916, 37.146010709526593],
            [-6.344114469407259, 37.176728483932379],
            [-6.355287565568688, 37.235571260305363],
            [-6.353244108029145, 37.247392549126801],
            [-6.340895474746389, 37.275671359445624],
            [-6.339236539434694, 37.289602023414574],
            [-6.34614693992927, 37.303851292003571],
            [-6.378831267249183, 37.328087132615025],
            [-6.380369353462868, 37.339446996381753],
            [-6.374458708602475, 37.347499974744039],
            [-6.359737028840811, 37.359782690418797],
            [-6.357287076837054, 37.371406226416156],
            [-6.364153532859234, 37.398168922757606],
            [-6.359594206607312, 37.40676023169928],
            [-6.350266813362708, 37.411594215400697],
            [-6.327634976572909, 37.414648415591955],
            [-6.317659389769034, 37.420284402103476],
            [-6.313550503353014, 37.428886697163307],
            [-6.315187466428426, 37.438225076526095],
            [-6.356034635989687, 37.496145000091644],
            [-6.375260710513402, 37.546242658272007],
            [-6.401342254941142, 37.575411360345981],
            [-6.394135222962177, 37.604228500345016],
            [-6.443189180136585, 37.623443588750575],
            [-6.505811253086847, 37.621663804341125],
            [-6.531376439171282, 37.631716294871069],
            [-6.527728978709462, 37.658039537494687],
            [-6.522488499644538, 37.663961168473122],
            [-6.503328342728821, 37.677529284249005],
            [-6.468963106961809, 37.724693593134788],
            [-6.448935029627876, 37.734405507707976],
            [-6.311067592994988, 37.764848623765005],
            [-6.266221399997619, 37.764255361695575],
            [-6.255553675161934, 37.756905508382488],
            [-6.253587122247836, 37.751313467242738],
            [-6.251950159172509, 37.739624013637325],
            [-6.247544641272157, 37.733988027125775],
            [-6.241831750135134, 37.73080199171855],
            [-6.228494347336238, 37.728879384176224],
            [-6.221990441305763, 37.729384755502082],
            [-6.216431358520396, 37.73417479473099],
            [-6.21415718890313, 37.743776845425003],
            [-6.21525582139904, 37.783316641749565],
            [-6.202874229761846, 37.799103996552134],
            [-6.185032432200956, 37.803410636691581],
            [-6.147052694326391, 37.793665763763954],
            [-6.124409871418436, 37.804663078438324],
            [-6.146712118369521, 37.848476556547894],
            [-6.127068562364229, 37.907220455159859],
            [-6.135571980562446, 37.910494381310684],
            [-6.158181845116019, 37.911790768429228],
            [-6.167036825389147, 37.90898925435053],
            [-6.183260463799343, 37.938976549028524],
            [-6.16995378274089, 37.949647731757437],
            [-6.138922084686413, 37.96830292384081],
            [-6.114091559277995, 37.978586533841423],
            [-6.038308070960738, 37.990497951819009],
            [-6.018929409364716, 37.991660671802578],
            [-5.975986294232058, 37.998636989905151],
            [-5.962963833114429, 38.002641912893949],
            [-5.952111782332878, 38.007990423918869],
            [-5.941078864398065, 38.018274033919482],
            [-5.937203131719201, 38.025741279336614],
            [-5.935058559804645, 38.031529039033899],
            [-5.933947515765198, 38.036386624543098],
            [-5.930769415975732, 38.042045193031186],
            [-5.913690355025892, 38.059589341974487],
            [-5.910925666386362, 38.06651398413311],
            [-5.910667283967967, 38.073490302235683],
            [-5.913742030969956, 38.08033742912906],
            [-5.918728806788977, 38.086538600875741],
            [-5.922759569098872, 38.092791450365127],
            [-5.922811245042908, 38.099793605990158],
            [-5.919891526772574, 38.106769924092731],
            [-5.915395677368593, 38.114082139878832],
            [-5.909478725562764, 38.121135973246567],
            [-5.900590378643017, 38.127647203355679],
            [-5.874519618885245, 38.140075385270762],
            [-5.867104051211015, 38.144752101828061],
            [-5.861703864241946, 38.149454657706286],
            [-5.853151414106492, 38.161960353987126],
            [-5.846278448791395, 38.168574936883829],
            [-5.837080044408509, 38.174259344692985],
            [-5.807211066337601, 38.183044338825141],
            [-5.740393439305763, 38.190201524081061],
            [-5.727526007818966, 38.188315335484361],
            [-5.713780077188773, 38.179995429345581],
            [-5.697992925632946, 38.16591360103186],
            [-5.693652105859996, 38.159634914919422],
            [-5.691714240419884, 38.153847154322762],
            [-5.696029221771198, 38.148498644197019],
            [-5.711816372427649, 38.141160589989198],
            [-5.720885585601252, 38.138240871718835],
            [-5.72840450696242, 38.134313463095822],
            [-5.734088913872313, 38.129688422482673],
            [-5.734657354653166, 38.123409736370206],
            [-5.733675502722292, 38.116459255789977],
            [-5.728249478230794, 38.095401109373654],
            [-5.722048305584849, 38.090181790457223],
            [-5.710886196440754, 38.088683172790212],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5845",
        OBJECTID_1: 7248,
        diss_me: 5845,
        adm1_cod_1: "ESP-5845",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Soria",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602121,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 41.646299999999997,
        longitude: -2.55925,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3108680,
        gn_name: "Provincia de Soria",
        gns_id: -403311,
        gns_name: "Soria",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.SO",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.256290859235719, 42.089420884965818],
            [-2.245516322820038, 42.089524237753295],
            [-2.189679937778948, 42.104071153161186],
            [-2.171799892950759, 42.105492255563092],
            [-2.153377243964599, 42.104562079576283],
            [-2.137874315047554, 42.101564846040787],
            [-2.127797409722717, 42.096655585486872],
            [-2.122681443593763, 42.090712796158584],
            [-2.123224045952981, 42.083684801212598],
            [-2.127797409722717, 42.077897039716561],
            [-2.135057948665406, 42.072936103218609],
            [-2.142886929288295, 42.069732164108046],
            [-2.1489847482475, 42.064771226710747],
            [-2.150535040959397, 42.058156642914668],
            [-2.149449836240933, 42.050766912762782],
            [-2.146194220287043, 42.043222153879356],
            [-2.14050981337715, 42.036865953401048],
            [-2.116635300578622, 42.022034817153042],
            [-2.106661749839816, 42.009219062509743],
            [-2.104181281141194, 42.003844713063017],
            [-2.104052089931997, 42.001312568420275],
            [-2.104594692291215, 42.00007233317163],
            [-2.106558397052339, 41.998832098822277],
            [-2.110485805675353, 41.995886542130194],
            [-2.115395067128617, 41.991390692726242],
            [-2.117203742258823, 41.984931139460542],
            [-2.116118536641181, 41.97810985008951],
            [-2.11064083530627, 41.963407905050786],
            [-2.105369838647135, 41.957077542094822],
            [-2.095215419855805, 41.952736721422582],
            [-2.040128342748801, 41.94203970027192],
            [-2.023359341060768, 41.941316229860092],
            [-1.998270433233955, 41.933513089457534],
            [-1.988219367230016, 41.92772532796144],
            [-1.977935757229403, 41.920542304283885],
            [-1.967884691225549, 41.918449409212855],
            [-1.943855149695366, 41.920464789018752],
            [-1.932512173398123, 41.92255768498913],
            [-1.922461107394184, 41.925735784778624],
            [-1.904710251976525, 41.93400401497334],
            [-1.896519537946318, 41.938784085217321],
            [-1.857736375433348, 41.954803778971268],
            [-1.851586879630815, 41.906150418506996],
            [-1.836884934592092, 41.874085190778942],
            [-1.816033493750751, 41.851683458125081],
            [-1.815826789075089, 41.844216214506702],
            [-1.817764655414464, 41.836051337099548],
            [-1.821562872828309, 41.827834785546884],
            [-1.825903692601344, 41.820574245704819],
            [-1.831820645306493, 41.81429555869309],
            [-1.842672695188753, 41.799567776131937],
            [-1.84585079587751, 41.790731106055659],
            [-1.842465989613714, 41.783134671228083],
            [-1.83546383308942, 41.777036852268878],
            [-1.818901536076964, 41.757813219404483],
            [-1.810788337311891, 41.744919949496051],
            [-1.808540411710595, 41.737504380922445],
            [-1.80264929922572, 41.731819973113289],
            [-1.79595720106397, 41.7287452261113],
            [-1.785544398954869, 41.719288438410643],
            [-1.788179897284408, 41.705749213355517],
            [-1.79270158511008, 41.696886704857576],
            [-1.799419520794174, 41.687171536537193],
            [-1.807326015783048, 41.670428372371504],
            [-1.815878465019125, 41.661669215761691],
            [-1.824973516614534, 41.658026027978764],
            [-1.834301113105823, 41.658232734453122],
            [-1.844352179109762, 41.65686330799528],
            [-1.854299893225487, 41.652600003487379],
            [-1.865642868623468, 41.643039862999302],
            [-1.874789597961581, 41.636942044040126],
            [-1.893961553982564, 41.62944896110065],
            [-1.910937262144927, 41.614152736859296],
            [-1.917035081104132, 41.607408962753397],
            [-1.9235204736909, 41.602990627715286],
            [-1.932124599770418, 41.601130275741582],
            [-1.950521410334773, 41.605806993198172],
            [-1.95858293315581, 41.608726712367883],
            [-1.96455156090579, 41.60867503552447],
            [-1.969460822359054, 41.60771902111594],
            [-1.975145229268946, 41.605212713995513],
            [-1.980700445868848, 41.60190542299685],
            [-1.98827104317408, 41.595497544775924],
            [-1.990777350294479, 41.588547065094957],
            [-1.990234747935261, 41.58175161414573],
            [-1.987909307968181, 41.575188707193092],
            [-1.981940680218202, 41.570796210576702],
            [-1.975920375624867, 41.542658393270244],
            [-1.978710902686004, 41.507621772226969],
            [-1.975274421377435, 41.49113699047976],
            [-1.969124924675526, 41.47979401328314],
            [-1.961916062576194, 41.473747870267999],
            [-1.956179978822945, 41.466823229008838],
            [-1.952846849402562, 41.457831529301643],
            [-1.945948044766396, 41.415534369315679],
            [-1.951787482206555, 41.411658637536078],
            [-1.960133225867708, 41.40879059520978],
            [-1.970132615927497, 41.407291979341295],
            [-1.979847785147172, 41.404940700952608],
            [-1.996048346054351, 41.397990221271726],
            [-2.014057582991029, 41.388068346476985],
            [-2.022196621077171, 41.385381171304033],
            [-2.030619880003457, 41.385432848147389],
            [-2.03741533005342, 41.38876597846712],
            [-2.040825974738937, 41.394734605317751],
            [-2.040567593219805, 41.401865953051242],
            [-2.039120653295498, 41.41010834392489],
            [-2.039353197292257, 41.417678941230122],
            [-2.040981004369854, 41.424887804228746],
            [-2.045218472254703, 41.430753079191277],
            [-2.052117275092229, 41.434990546176749],
            [-2.069506395303904, 41.439357205270795],
            [-2.099711270158963, 41.442612820325394],
            [-2.110950893668814, 41.437884426025391],
            [-2.116273566272014, 41.431373195916251],
            [-2.119580858170053, 41.423673408301113],
            [-2.120769415675966, 41.415999457309113],
            [-2.120976121250948, 41.408273831272254],
            [-2.122371385231219, 41.399333808408585],
            [-2.127952440252898, 41.376751206802709],
            [-2.133946906424598, 41.36910309513172],
            [-2.140148078171279, 41.362695216910708],
            [-2.147072720329817, 41.35889700039624],
            [-2.153919847223108, 41.357036648422536],
            [-2.159836799029051, 41.353186754165364],
            [-2.166244676350686, 41.34595205274502],
            [-2.171877407316515, 41.327761949554372],
            [-2.173350185662542, 41.294585680484715],
            [-2.165417853151439, 41.280943101742736],
            [-2.16146460610662, 41.275646267561143],
            [-2.159216681404757, 41.269212550918439],
            [-2.151336024837633, 41.231282050026607],
            [-2.149139776979126, 41.193945828337348],
            [-2.145057338725195, 41.186581935707807],
            [-2.138236050253511, 41.181465969578852],
            [-2.129657761696336, 41.179166368033378],
            [-2.111286789553702, 41.176866767387395],
            [-2.101752489285929, 41.176970120174872],
            [-2.092347378428769, 41.174825548260429],
            [-2.083743252349166, 41.171879991568346],
            [-2.061806605890183, 41.158624986453958],
            [-2.053228319131563, 41.15035675715859],
            [-2.05265987835071, 41.13552562091067],
            [-2.061780768367754, 41.109997464411492],
            [-2.060075446024939, 41.091135565853705],
            [-2.057284918963802, 41.084986070051059],
            [-2.059377814034917, 41.079456691872821],
            [-2.065268927419055, 41.076872870386609],
            [-2.072942878411141, 41.078888251091882],
            [-2.079299078889335, 41.083797512545146],
            [-2.084389206596597, 41.089688625929369],
            [-2.09121049596763, 41.094442856852368],
            [-2.115860155121993, 41.106328437307582],
            [-2.123146531587111, 41.111470241858143],
            [-2.128960129706201, 41.116457018576568],
            [-2.137099167792371, 41.118317368751548],
            [-2.145186529934392, 41.114389960128591],
            [-2.177613491069735, 41.090670477860243],
            [-2.187070277871044, 41.088810125886624],
            [-2.196475388728174, 41.088396714736604],
            [-2.219109666278086, 41.091109728331247],
            [-2.237041387949716, 41.088965156416805],
            [-2.249056158714751, 41.083539130126752],
            [-2.260554164642997, 41.076046048086653],
            [-2.273266567398196, 41.071291816264306],
            [-2.28835608606451, 41.067519436372919],
            [-2.313315801782835, 41.063798733324859],
            [-2.326880866159058, 41.065245673249166],
            [-2.344580043833901, 41.073178005760354],
            [-2.352409022658236, 41.077958076004421],
            [-2.364475470266711, 41.080671087800454],
            [-2.376516078554118, 41.077570502376744],
            [-2.409201423007232, 41.064987290830771],
            [-2.422482265644078, 41.065426541301804],
            [-2.449715745385333, 41.074599107262969],
            [-2.460386929013566, 41.079740911813644],
            [-2.467621628635243, 41.086071274769523],
            [-2.471884934941727, 41.093461004921494],
            [-2.477491828385126, 41.107904568441086],
            [-2.48144507542986, 41.113821520246944],
            [-2.488137172692348, 41.117232164033084],
            [-2.500384486554793, 41.121934719012103],
            [-2.505603807269864, 41.124725246972559],
            [-2.508135951912635, 41.127515774033725],
            [-2.511494919754739, 41.132580064218615],
            [-2.513717006934314, 41.139091295227018],
            [-2.517902797975751, 41.146170966117182],
            [-2.522786221007294, 41.152630520282344],
            [-2.532062139755965, 41.157229723373064],
            [-2.54079545704468, 41.154852607461834],
            [-2.550872362369603, 41.150382595580311],
            [-2.567176276064288, 41.14555084939218],
            [-2.578235033320084, 41.146377671692136],
            [-2.586322393663551, 41.149581610802699],
            [-2.588854539205585, 41.154852607461834],
            [-2.580224574704346, 41.165756334187449],
            [-2.579501105191866, 41.172370917084152],
            [-2.584823777795066, 41.179398912030166],
            [-2.602161221163414, 41.186659450972854],
            [-2.610041876831076, 41.191697902736024],
            [-2.608362392910095, 41.195573635414917],
            [-2.593350388609537, 41.20358348229189],
            [-2.58859615678719, 41.209965522091181],
            [-2.585986496879286, 41.215520737791792],
            [-2.59192928710695, 41.221980291956868],
            [-2.599913295562089, 41.222574571159498],
            [-2.609525111994287, 41.221876939169363],
            [-2.618258429283117, 41.220094103360168],
            [-2.625906540954105, 41.222652086424631],
            [-2.631410080710651, 41.228362331756159],
            [-2.639471605330328, 41.234873561865385],
            [-2.655801357446705, 41.240945543302132],
            [-2.680218471705103, 41.243736070363383],
            [-2.698124354955013, 41.247973538248146],
            [-2.709234788154959, 41.252521064495539],
            [-2.715125902438388, 41.259032293705388],
            [-2.720138515779865, 41.265956935863898],
            [-2.728096685813398, 41.272778225234958],
            [-2.741351690927786, 41.274225165159265],
            [-2.751816168980952, 41.273940945218442],
            [-2.760988734942117, 41.270556138954646],
            [-2.770057949015097, 41.265543524713877],
            [-2.78129757342424, 41.26104767531001],
            [-2.805895554835985, 41.259936632169854],
            [-2.844084438146297, 41.267920641524398],
            [-2.855763312127181, 41.272700710869088],
            [-2.863850674269202, 41.278746852984852],
            [-2.869767625175768, 41.292725328511096],
            [-2.871472948417761, 41.299934189711081],
            [-2.87426347457972, 41.307530626337297],
            [-2.879637824026361, 41.31458445880574],
            [-2.897156133648679, 41.321405748176687],
            [-2.909713506772846, 41.322129217689252],
            [-2.921133999234712, 41.319648748990545],
            [-2.929815639679987, 41.31628978114847],
            [-2.93570675306421, 41.310915431701829],
            [-2.940926072879932, 41.305205187269593],
            [-2.944000819881921, 41.299081528989319],
            [-2.959994676113439, 41.291433417318331],
            [-2.982267217558018, 41.287247626276894],
            [-3.048903978335886, 41.280917263321015],
            [-3.083811408169964, 41.280762233690098],
            [-3.198791470150184, 41.301200263381418],
            [-3.332629979090257, 41.377054572410543],
            [-3.343660253018271, 41.388007941713141],
            [-3.345604833696086, 41.405036751245035],
            [-3.336914647892684, 41.448015268190034],
            [-3.337452977572809, 41.452673471303655],
            [-3.340979588936818, 41.457089975322077],
            [-3.348472265382668, 41.461110971893788],
            [-3.382354102060134, 41.46521985830978],
            [-3.414719826558752, 41.479600963014036],
            [-3.418872658346459, 41.483589000332103],
            [-3.421278664079068, 41.488730601635922],
            [-3.424365822624566, 41.512230358843993],
            [-3.427145363567661, 41.516888561957558],
            [-3.437373635584834, 41.524633923616705],
            [-3.449282816049134, 41.529621716568954],
            [-3.456544779517913, 41.530357800871798],
            [-3.475056742874358, 41.523623180964989],
            [-3.482780131397931, 41.522777233682376],
            [-3.523550397233038, 41.540662976615124],
            [-3.53187803394411, 41.548078748435472],
            [-3.536536237057646, 41.556768934238875],
            [-3.54173277075077, 41.577774794970793],
            [-3.542963239361967, 41.593770888715568],
            [-3.540886823468156, 41.600922989204577],
            [-3.535844098126603, 41.605822891413339],
            [-3.527681256784604, 41.607470840606823],
            [-3.459708841789563, 41.58943128932259],
            [-3.393483252849876, 41.69067030668927],
            [-3.358469824422855, 41.693405902260594],
            [-3.354349950989445, 41.737340230367266],
            [-3.320995457874147, 41.745382222611482],
            [-3.282971774627669, 41.764333639685702],
            [-3.27953305400726, 41.767299948234012],
            [-3.277863131678203, 41.771408835549295],
            [-3.287695895349316, 41.833997949245912],
            [-3.286421481366261, 41.855234522055525],
            [-3.272095309051224, 41.865396875565352],
            [-3.252990083625434, 41.863693994881942],
            [-3.237334565837529, 41.849367822566933],
            [-3.233412446127431, 41.843951562914327],
            [-3.228677338388479, 41.840446923786516],
            [-3.201563079972175, 41.83113051755933],
            [-3.194839447082813, 41.826922753382348],
            [-3.171438566736441, 41.792942038943892],
            [-3.075352137690999, 41.88319472865382],
            [-3.045150720729112, 41.889369044845552],
            [-3.01684993817409, 41.886831202997598],
            [-3.002556724213406, 41.921657864718668],
            [-2.999052085984971, 41.926447903947576],
            [-2.990779380763769, 41.929765774570797],
            [-2.981660727360662, 41.929304349516087],
            [-2.973750571231875, 41.930820462594198],
            [-2.969202231098109, 41.940059965095344],
            [-2.964302328889431, 41.9625489796517],
            [-2.932266194229499, 42.018051911366598],
            [-2.910178594766393, 42.023430081133313],
            [-2.870930345159252, 42.014748439788633],
            [-2.840105352679842, 42.025497137782708],
            [-2.812846035416214, 42.03805451090696],
            [-2.801632249428792, 42.047304592133202],
            [-2.796283739303078, 42.056012071000197],
            [-2.796438768034704, 42.063686021093019],
            [-2.798557502426803, 42.070765692882361],
            [-2.79752397275314, 42.077793687828461],
            [-2.795043504054433, 42.084950873084381],
            [-2.789694993029514, 42.092702338442137],
            [-2.785896775615697, 42.101099758047411],
            [-2.773933681694075, 42.112468574565042],
            [-2.762228970190847, 42.118101305530786],
            [-2.735357224756086, 42.120116686236031],
            [-2.72520280596467, 42.117765407847344],
            [-2.717192959087782, 42.114199734430173],
            [-2.714195725552287, 42.10812775299334],
            [-2.712567917575342, 42.10125478857762],
            [-2.712335373578583, 42.093968411213211],
            [-2.715746019163475, 42.079912421321183],
            [-2.721094530188481, 42.073711249574501],
            [-2.734168667250231, 42.064073594720611],
            [-2.737786016611352, 42.057872422973929],
            [-2.740473191784304, 42.050715236818718],
            [-2.744348923563905, 42.0434288603536],
            [-2.747449509886906, 42.036039130201715],
            [-2.748483038661277, 42.028778591258941],
            [-2.743599615629734, 42.022525743568195],
            [-2.729026861800207, 42.016944688546516],
            [-2.713704800036481, 42.016789658915599],
            [-2.70132829406549, 42.014929307841271],
            [-2.690605435392484, 42.011596178420888],
            [-2.671846889622259, 42.000459906799222],
            [-2.664147101107858, 41.999555569234133],
            [-2.660426398059798, 41.999555569234133],
            [-2.655620490293359, 42.000692450795981],
            [-2.619550339576534, 42.002320257873578],
            [-2.59086991991083, 41.999529730812412],
            [-2.572938198239228, 42.000149848436678],
            [-2.572266404670813, 42.001700141148575],
            [-2.570354376753045, 42.004774889049941],
            [-2.567951423319471, 42.010097560753849],
            [-2.556815150798514, 42.022758287564869],
            [-2.546169805592029, 42.038571274844486],
            [-2.542113205759819, 42.046426092989805],
            [-2.536919725265079, 42.052575588792337],
            [-2.530408494256591, 42.056968085408812],
            [-2.524982468865801, 42.059965318045045],
            [-2.519737310628386, 42.063427639573888],
            [-2.515654873273718, 42.068801988121237],
            [-2.513613654146752, 42.082496242807395],
            [-2.512735155003355, 42.097947495780289],
            [-2.511572435019787, 42.105130520357221],
            [-2.50839433433103, 42.110349840172915],
            [-2.502451545002828, 42.111099148107201],
            [-2.48152258979573, 42.108231105780902],
            [-2.472634242876012, 42.111409207369036],
            [-2.449948289382036, 42.123113918872264],
            [-2.437442593101196, 42.131898912105129],
            [-2.410467494879015, 42.138565171845158],
            [-2.347448086160284, 42.142208360527349],
            [-2.296133388944781, 42.137790025489267],
            [-2.286650762822404, 42.134120999284704],
            [-2.281224738331019, 42.129935208243296],
            [-2.278537563157954, 42.123682358753911],
            [-2.276315476877727, 42.116654364707188],
            [-2.275669520831741, 42.109342148921058],
            [-2.272233038623796, 42.101926581246659],
            [-2.266135219664619, 42.095156968719124],
            [-2.256290859235719, 42.089420884965818],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5846",
        OBJECTID_1: 7262,
        diss_me: 5846,
        adm1_cod_1: "ESP-5846",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Tarragona",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CT",
        note: null,
        hasc_maybe: null,
        region: "Cataluña",
        region_cod: "ES.CT",
        provnum_ne: 16,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP86",
        fips_alt: "SP56",
        woe_id: 12602127,
        woe_label: "Catalonia, ES, Spain",
        woe_name: "Cataluña",
        latitude: 41.147599999999997,
        longitude: 0.87301200000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3108287,
        gn_name: "Provincia de Tarragona",
        gns_id: -403903,
        gns_name: "Tarragona",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.T",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP56",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.624359570868819, 41.192165432194457],
            [1.539235873000081, 41.187445380000042],
            [1.28809655000012, 41.116278387000108],
            [1.217784050000091, 41.104681708000072],
            [1.210052931000064, 41.099880276000107],
            [1.192474806000035, 41.075995184000107],
            [1.188649936000047, 41.068304755000014],
            [1.183604363000086, 41.062567450000131],
            [1.172943556000064, 41.058661200000131],
            [1.149099155000073, 41.069647528000104],
            [1.141693556000092, 41.071722723000022],
            [1.097666863000086, 41.070868231000105],
            [1.055674675000091, 41.064886786000102],
            [1.017425977000045, 41.051784572000116],
            [0.990407748000109, 41.039699611000032],
            [0.877452019000145, 40.954779364000075],
            [0.822764519000089, 40.899603583000044],
            [0.714203321000127, 40.815212307000081],
            [0.694834832000112, 40.785996812000036],
            [0.721527540000125, 40.774278062000064],
            [0.750173373000109, 40.765570380000071],
            [0.762380405000073, 40.766058661000045],
            [0.76726321700005, 40.771022854000108],
            [0.763519727000102, 40.778387762000108],
            [0.750173373000109, 40.785996812000036],
            [0.766937696000127, 40.78502024900007],
            [0.780528191000087, 40.779486395000049],
            [0.79200280000012, 40.771185614000075],
            [0.815928582000083, 40.748399156000033],
            [0.841807488000086, 40.732123114000103],
            [0.854177280000044, 40.733140367000061],
            [0.873708530000016, 40.727932033000087],
            [0.872406446000099, 40.707098700000103],
            [0.858083530000044, 40.686265367000118],
            [0.848317905000101, 40.675360419000043],
            [0.784027540000068, 40.660956122000073],
            [0.75635826900006, 40.642238674000112],
            [0.707041863000086, 40.58340078300003],
            [0.677093946000099, 40.565171617000146],
            [0.647959832000055, 40.55691152600005],
            [0.615896030000073, 40.553452867000146],
            [0.592458530000101, 40.582098700000131],
            [0.640635613000143, 40.586859442000019],
            [0.663177931000035, 40.574733791000114],
            [0.696218295000079, 40.590358791000114],
            [0.732676629000139, 40.633734442000076],
            [0.712494337000123, 40.636175848000107],
            [0.682220899000129, 40.63422272300005],
            [0.599782748000109, 40.613714911000017],
            [0.580170118000041, 40.603664455000029],
            [0.563487175000091, 40.590033270000077],
            [0.548513217000078, 40.572333075000074],
            [0.523759592032405, 40.526163029399868],
            [0.518914421663226, 40.528947658707466],
            [0.456075880997133, 40.549204820346148],
            [0.447394239652454, 40.554579168893497],
            [0.440417922449171, 40.563054103763903],
            [0.436852248132737, 40.570753893177624],
            [0.436852248132737, 40.577445991339374],
            [0.429875929130787, 40.584396471020341],
            [0.418403760724942, 40.590830186763696],
            [0.387501254779039, 40.60374929509382],
            [0.316601190392646, 40.622585354330624],
            [0.293140089643487, 40.63214549391941],
            [0.283838331573719, 40.639716091224642],
            [0.276241895846795, 40.654314684375152],
            [0.277017043102063, 40.663642279967235],
            [0.281047803613291, 40.671419582847449],
            [0.28600874190991, 40.677310696231672],
            [0.287714064252697, 40.683821926340784],
            [0.27825727655204, 40.691573390799277],
            [0.265699904327136, 40.697877916232812],
            [0.243789097189079, 40.703045559205179],
            [0.232161899152345, 40.711675522807155],
            [0.226735873761555, 40.719426988164997],
            [0.223790317069501, 40.726067410382711],
            [0.219294467665549, 40.730537421364858],
            [0.198985630082774, 40.727178453522868],
            [0.173044060634936, 40.730899156570814],
            [0.167463005613229, 40.743198147276701],
            [0.172734002272392, 40.747125555899714],
            [0.227355991385934, 40.77172353821075],
            [0.23417727985759, 40.77745962196397],
            [0.242703891571352, 40.790740465500079],
            [0.261979201279189, 40.807793687128964],
            [0.267250197038948, 40.814279079715732],
            [0.271280959348758, 40.821384589027616],
            [0.271901076073817, 40.829213568751214],
            [0.261514113285727, 40.847119452001124],
            [0.257948438969294, 40.85551687340498],
            [0.257483350975832, 40.865671292196396],
            [0.255778028633131, 40.873577786285779],
            [0.249576856886449, 40.888563951265326],
            [0.251747267222612, 40.898589178847459],
            [0.254537795183069, 40.906805732198762],
            [0.258258498231129, 40.914712226288259],
            [0.260428907668, 40.922101956440144],
            [0.264304640346893, 40.928716539336847],
            [0.275931838383627, 40.942255764391973],
            [0.280117629425035, 40.951867580824171],
            [0.285543653916534, 40.959308986920206],
            [0.28972944495797, 40.982460029306907],
            [0.274226515141521, 41.018891913431077],
            [0.257948438969294, 41.028452053019834],
            [0.243324009195618, 41.033309638528976],
            [0.227821079379282, 41.043179837379569],
            [0.216503939705092, 41.0706200217966],
            [0.212473179193864, 41.084159246851755],
            [0.208132357622247, 41.08943024351089],
            [0.205341830560997, 41.096871650506188],
            [0.205186801829456, 41.104261379758896],
            [0.20658206491035, 41.111780300220602],
            [0.20890750487743, 41.119609279944314],
            [0.213713412643841, 41.125758774847583],
            [0.224410434693851, 41.130177109885665],
            [0.233712191864157, 41.130332140415959],
            [0.242393833208808, 41.132037461859397],
            [0.248491652168013, 41.136197415378376],
            [0.252212355215988, 41.141520087082313],
            [0.25841352696267, 41.146429348535662],
            [0.276241895846795, 41.149995021952719],
            [0.285078565923072, 41.153173123540824],
            [0.293140089643487, 41.158263251248002],
            [0.299651319752599, 41.164877835044052],
            [0.306524285067695, 41.183533027127424],
            [0.306989373061157, 41.192007961997859],
            [0.308849725034776, 41.19929433936224],
            [0.313345575338019, 41.206890774189816],
            [0.321097038897221, 41.213918769135915],
            [0.336444939982016, 41.221566880806932],
            [0.347917108387833, 41.225752671848255],
            [0.357838983182489, 41.230532742092322],
            [0.36037112782526, 41.232832342738419],
            [0.38580277987441, 41.278858767318212],
            [0.433175828601605, 41.276112185628847],
            [0.438053758574085, 41.324759648339096],
            [0.445238817417447, 41.331779911813442],
            [0.454445360664863, 41.334603399027628],
            [0.464135302102449, 41.333383916534501],
            [0.472814501787809, 41.32826428746688],
            [0.491623095729324, 41.299611941937542],
            [0.500016650947657, 41.291767703416753],
            [0.509772510892702, 41.288493778165332],
            [0.520934620936004, 41.28892224486566],
            [0.531371632794674, 41.29283337755831],
            [0.538908254612238, 41.30000745028363],
            [0.547411672810426, 41.315673954189606],
            [0.5534541528869, 41.321925175906131],
            [0.561869681240807, 41.323913701056455],
            [0.570285208695452, 41.320826542510957],
            [0.595949271641615, 41.303369266278622],
            [0.6207564020863, 41.298667117793201],
            [0.73644244176532, 41.318398563642774],
            [0.758481016485689, 41.331263554369542],
            [0.804975158676626, 41.331340458994958],
            [0.817785217913439, 41.341173223565448],
            [0.82365191740206, 41.351950811380874],
            [0.827453187114941, 41.356059698696157],
            [0.833517640326789, 41.358487676664964],
            [0.842921937297575, 41.357575811774268],
            [0.868717837258373, 41.343513310790684],
            [0.879044986137274, 41.342008183830643],
            [0.957685125967828, 41.354510626364345],
            [0.968869208247298, 41.364420294660846],
            [0.981920966579366, 41.391787239189711],
            [0.98945758749764, 41.39831311835573],
            [1.023669014913082, 41.406267219856289],
            [1.045509835910195, 41.426679818518778],
            [1.062033274116317, 41.4516297702977],
            [1.06337360660666, 41.458957651374476],
            [1.062560617678372, 41.475239390485456],
            [1.065109446543772, 41.480831431625205],
            [1.073019602672559, 41.482732066032042],
            [1.117184643756303, 41.472547740285933],
            [1.130368237304339, 41.473217906081487],
            [1.157394605876334, 41.480249156573308],
            [1.167502027896319, 41.486566294998539],
            [1.170226637349373, 41.495421276170958],
            [1.169128004853576, 41.518426648171328],
            [1.178004958262164, 41.544519177817847],
            [1.194616287211886, 41.566469862693992],
            [1.201010330262534, 41.570402968522131],
            [1.207799881659213, 41.572325576064458],
            [1.371759848837968, 41.565415174670591],
            [1.392677817926938, 41.575028212382051],
            [1.418407799380475, 41.576115858759891],
            [1.431107995637518, 41.573962538240579],
            [1.436623132151851, 41.566656630299292],
            [1.427658287999577, 41.552396375592252],
            [1.397423911784074, 41.52125013448611],
            [1.404718834506582, 41.506682263075959],
            [1.458969324474708, 41.49063123694205],
            [1.482765712267792, 41.476601695211997],
            [1.480963953823391, 41.454123666773768],
            [1.471867274455121, 41.426635873147006],
            [1.485270594862101, 41.407519661603061],
            [1.536818448512577, 41.385239386888202],
            [1.559626066789491, 41.3662550114596],
            [1.563449308738569, 41.358245977569737],
            [1.566064055212053, 41.334109013819926],
            [1.586081145528453, 41.301029179053302],
            [1.608603119338454, 41.288493778165332],
            [1.61394247526502, 41.281792117512168],
            [1.615502533714988, 41.272398806659538],
            [1.612162689956193, 41.264785281115849],
            [1.605504974674886, 41.25897351311707],
            [1.578962005192381, 41.249206667054068],
            [1.574677336389954, 41.245251588989618],
            [1.57393026596904, 41.234353150277599],
            [1.584235442611629, 41.228838013763351],
            [1.617238373652015, 41.225915650586757],
            [1.62422567877212, 41.2231690679981],
            [1.627829193862169, 41.217983521322395],
            [1.624359570868819, 41.192165432194457],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5847",
        OBJECTID_1: 7259,
        diss_me: 5847,
        adm1_cod_1: "ESP-5847",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Teruel",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AR",
        note: null,
        hasc_maybe: null,
        region: "Aragón",
        region_cod: "ES.AR",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AR",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP81",
        fips_alt: "SP52",
        woe_id: 12602106,
        woe_label: "Aragon, ES, Spain",
        woe_name: "Aragón",
        latitude: 40.625,
        longitude: -0.80940900000000005,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3108125,
        gn_name: "Provincia de Teruel",
        gns_id: -404165,
        gns_name: "Teruel, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.TE",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP52",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.337229449879203, 41.241461305394893],
            [-0.313202349109133, 41.255534792496633],
            [-0.268597855206792, 41.215028198892242],
            [-0.036204047978458, 41.134069944072678],
            [-0.016934027183595, 41.121721310789809],
            [0.02103472367358, 41.054232293985137],
            [0.039645564791016, 41.048640252845388],
            [0.044743220723063, 41.0519141789961],
            [0.054543026039795, 41.067503779176036],
            [0.058322323516364, 41.071250117398961],
            [0.062541072912154, 41.073952753716554],
            [0.07245074210806, 41.077050898380207],
            [0.119186583394111, 41.076402704820936],
            [0.14346636937745, 41.067152216201833],
            [0.166955139568017, 41.065064814189782],
            [0.216503939705092, 41.0706200217966],
            [0.227821079379282, 41.043179837379569],
            [0.243324009195618, 41.033309638528976],
            [0.257948438969294, 41.028452053019834],
            [0.274226515141521, 41.018891913431077],
            [0.28972944495797, 40.982460029306907],
            [0.285543653916534, 40.959308986920206],
            [0.280117629425035, 40.951867580824171],
            [0.275931838383627, 40.942255764391973],
            [0.264304640346893, 40.928716539336847],
            [0.260428907668, 40.922101956440144],
            [0.258258498231129, 40.914712226288259],
            [0.254537795183069, 40.906805732198762],
            [0.251747267222612, 40.898589178847459],
            [0.249576856886449, 40.888563951265326],
            [0.255778028633131, 40.873577786285779],
            [0.257483350975832, 40.865671292196396],
            [0.257948438969294, 40.85551687340498],
            [0.261514113285727, 40.847119452001124],
            [0.271901076073817, 40.829213568751214],
            [0.271280959348758, 40.821384589027616],
            [0.267250197038948, 40.814279079715732],
            [0.261979201279189, 40.807793687128964],
            [0.242703891571352, 40.790740465500079],
            [0.23417727985759, 40.77745962196397],
            [0.227355991385934, 40.77172353821075],
            [0.172734002272392, 40.747125555899714],
            [0.167463005613229, 40.743198147276701],
            [0.173044060634936, 40.730899156570814],
            [0.14498375769432, 40.72257925133141],
            [0.107104932746466, 40.721545722557011],
            [0.093152297440582, 40.719504503430045],
            [0.081525099403848, 40.716429755528793],
            [0.073101841376854, 40.712709052480818],
            [0.064265171300661, 40.707696438240077],
            [0.056203646680984, 40.701701972068349],
            [0.045041538436266, 40.696172593890083],
            [0.03620486835996, 40.696689357827609],
            [0.029538607720554, 40.699609076097971],
            [0.027729932590347, 40.705836087165721],
            [0.028143344639659, 40.712683214059012],
            [0.024732699954143, 40.72020213542018],
            [0.01816979300159, 40.726144923849091],
            [0.001581659366082, 40.729633083799769],
            [-0.048260260402685, 40.727178453522868],
            [-0.074486049791375, 40.730847479727402],
            [-0.119961309566804, 40.753275050802984],
            [-0.135309210651485, 40.763377794549626],
            [-0.14236304401922, 40.76939809824367],
            [-0.152155727604679, 40.774901638000216],
            [-0.165798306346744, 40.779914252240985],
            [-0.18388505675, 40.779423325825803],
            [-0.194814621897308, 40.775986842718567],
            [-0.213676519555747, 40.758287665043639],
            [-0.219025030580667, 40.751931464565416],
            [-0.223417528096434, 40.745316880769451],
            [-0.228016730287862, 40.730718289417496],
            [-0.229334479003001, 40.715499579541984],
            [-0.228869391009539, 40.708006497501884],
            [-0.229231126215495, 40.701030178499934],
            [-0.234088710825347, 40.694803168331532],
            [-0.284809128838219, 40.673331610765217],
            [-0.293077358133559, 40.667879746952735],
            [-0.304497849695963, 40.663254706339643],
            [-0.313050298932154, 40.662944647977099],
            [-0.329405890369543, 40.671574612478338],
            [-0.345813157750968, 40.673900051546156],
            [-0.357750413250955, 40.671910509262517],
            [-0.368679979297553, 40.667440497381079],
            [-0.377309942899529, 40.660154120016585],
            [-0.3787052068798, 40.652609361133159],
            [-0.376586473386965, 40.645684718974536],
            [-0.376302253446227, 40.638346666565354],
            [-0.377464971631071, 40.624264838251634],
            [-0.374596930204149, 40.618192856814886],
            [-0.367207200951526, 40.614472153766911],
            [-0.358758103603634, 40.61194000912414],
            [-0.349998948792489, 40.611061509980658],
            [-0.34268673300636, 40.607340806932683],
            [-0.333953415717559, 40.605273749384025],
            [-0.325116745641253, 40.604240221509002],
            [-0.308089362434117, 40.608710232491234],
            [-0.300415412341408, 40.609046129275413],
            [-0.294369269326353, 40.605764674899802],
            [-0.294782681375665, 40.592199612322247],
            [-0.297340664440128, 40.584138089501209],
            [-0.298555060367676, 40.576645006561762],
            [-0.296488002819103, 40.562433987038844],
            [-0.294162562852023, 40.555561020824456],
            [-0.292663946983538, 40.548507188356012],
            [-0.292974006245373, 40.534063625735683],
            [-0.294524298057894, 40.52706146921139],
            [-0.297469854750062, 40.52036937104964],
            [-0.29793494274341, 40.513703111309525],
            [-0.296694709293433, 40.507915350712864],
            [-0.281863573045428, 40.500939033509553],
            [-0.280623337796783, 40.499853826992478],
            [-0.275817430030372, 40.487528997864956],
            [-0.27263932934153, 40.48143117890578],
            [-0.272174242247445, 40.475720934473543],
            [-0.277367722742184, 40.471819363372958],
            [-0.323695645038015, 40.456962388703232],
            [-0.333850062930082, 40.450011909022351],
            [-0.339896205945109, 40.44269969323625],
            [-0.338655971595756, 40.435309963983627],
            [-0.335477870907027, 40.427765204200853],
            [-0.329354214425393, 40.421693224562631],
            [-0.314057990184097, 40.412959907273915],
            [-0.307236700813064, 40.407921453712078],
            [-0.295092738838832, 40.39632009499644],
            [-0.289098272667104, 40.389214586583847],
            [-0.285584276093402, 40.381540636491138],
            [-0.286488612759314, 40.371747952006302],
            [-0.2920955062026, 40.365520941838014],
            [-0.331653815071462, 40.345651353826923],
            [-0.341213954660248, 40.337383124531556],
            [-0.357156134048324, 40.317746080517225],
            [-0.369377611287689, 40.308289292816653],
            [-0.390926683219789, 40.295576890960746],
            [-0.392812872715865, 40.28914317521739],
            [-0.390745815167179, 40.28214101779372],
            [-0.39009986002057, 40.274208686181908],
            [-0.391417608735594, 40.263925076181295],
            [-0.397928839744196, 40.25687124281356],
            [-0.4058611722553, 40.25113515906034],
            [-0.442318894801161, 40.237750962736754],
            [-0.451620652870815, 40.235916450084119],
            [-0.470250007431844, 40.235063788463066],
            [-0.47947425023645, 40.233771878169563],
            [-0.487794154576591, 40.231498115045923],
            [-0.495493943990311, 40.230464586271637],
            [-0.501126674956055, 40.230309557539982],
            [-0.516267868667228, 40.233358466120364],
            [-0.525362922061191, 40.237699285893314],
            [-0.534380459290759, 40.240412299487986],
            [-0.544224818820339, 40.240102241125527],
            [-0.549960902573559, 40.235063788463066],
            [-0.551821255446555, 40.228449205566363],
            [-0.551640387393945, 40.214186510099381],
            [-0.553035651374216, 40.207365220728349],
            [-0.552286343440016, 40.200285548938922],
            [-0.5563687807946, 40.185170192750178],
            [-0.563345098897173, 40.176333523573248],
            [-0.567815110778696, 40.169072983731184],
            [-0.570037197058923, 40.153518377970698],
            [-0.574868944146516, 40.145146795887939],
            [-0.58318884938592, 40.134811509943262],
            [-0.596728075340422, 40.127008367742008],
            [-0.613419561763322, 40.1139083931578],
            [-0.617657029648171, 40.107087103786824],
            [-0.619517380722527, 40.099128932853915],
            [-0.617734544014041, 40.091454982761206],
            [-0.612747769094284, 40.077709052131013],
            [-0.614349737750217, 40.071740424381119],
            [-0.683854539055744, 40.044429430273993],
            [-0.722043423265433, 40.039881904026686],
            [-0.738786587431122, 40.040295315176706],
            [-0.746693080621128, 40.041432196738498],
            [-0.753901942720489, 40.039778551239095],
            [-0.757700161033597, 40.034610908266728],
            [-0.762040980806574, 40.018487860826127],
            [-0.766175095903918, 40.009392809230803],
            [-0.775089281245357, 40.00138296235383],
            [-0.77780229304139, 39.999574286324275],
            [-0.790773078214954, 39.992959703427573],
            [-0.801418423421524, 39.990892645878887],
            [-0.824440272800302, 39.981487535021728],
            [-0.831209886227299, 39.974821275281613],
            [-0.835654058787725, 39.967638250704681],
            [-0.838702969166661, 39.952574571359378],
            [-0.839090541894961, 39.945830797253478],
            [-0.83635169077786, 39.939939683869341],
            [-0.836196662046319, 39.933376776916703],
            [-0.83250179741998, 39.919424139812151],
            [-0.8238201560753, 39.90632416432868],
            [-0.81712805791355, 39.901337389409008],
            [-0.809195726301652, 39.897642523883377],
            [-0.797465176376789, 39.887488105091961],
            [-0.791289842152452, 39.877540391875584],
            [-0.846997035984458, 39.855345363897413],
            [-0.859812790627842, 39.853097439195466],
            [-0.878054571561165, 39.852244778473704],
            [-0.888208991251872, 39.855242011109937],
            [-0.896890631697261, 39.859686184569739],
            [-0.902394172353183, 39.865396429901352],
            [-0.903453537750551, 39.872889512840715],
            [-0.902936773813025, 39.880873521295968],
            [-0.903324347440616, 39.888159897761085],
            [-0.904357876215016, 39.894800319978799],
            [-0.908543667256424, 39.900407213422113],
            [-0.912109340673567, 39.906401678694522],
            [-0.912057663830069, 39.913067939333928],
            [-0.909861415971562, 39.920095934279942],
            [-0.906476609707738, 39.926813869964121],
            [-0.904771287364952, 39.934617011266056],
            [-0.908776211253098, 39.942911078083739],
            [-0.923064744242538, 39.955985216044894],
            [-0.97246741443962, 39.974046128925693],
            [-0.989210577705961, 39.977766831973668],
            [-1.000269334062466, 39.979213771898088],
            [-1.018097702946591, 39.977275906457976],
            [-1.053496060095028, 39.978826199169788],
            [-1.078455775813381, 39.975183010487484],
            [-1.097059291952689, 39.969860337884285],
            [-1.105999314816472, 39.96918854431587],
            [-1.129382900300584, 39.964046738865903],
            [-1.14548010931955, 39.972289129739551],
            [-1.162714199001044, 40.011459865880113],
            [-1.139072231098623, 40.016214097702374],
            [-1.09171078092777, 40.034559231423401],
            [-1.084114346100193, 40.039261786402307],
            [-1.078765835075188, 40.0451270622641],
            [-1.075613572808152, 40.052129217889046],
            [-1.076698778425879, 40.059002184103491],
            [-1.079954392581129, 40.065771795731848],
            [-1.093002692120564, 40.077011420140877],
            [-1.125843065305219, 40.097914536926368],
            [-1.151061164341257, 40.110058498900628],
            [-1.215088263412554, 40.115897936340701],
            [-1.234673630583558, 40.12295176880906],
            [-1.242166714422297, 40.128920396559039],
            [-1.245370652633483, 40.134966539574094],
            [-1.245215623002565, 40.140211696912246],
            [-1.246119960567768, 40.144862575947116],
            [-1.250641649292618, 40.147885647004955],
            [-1.25828976096372, 40.151425482899668],
            [-1.26503353506962, 40.156541449927914],
            [-1.271518926757125, 40.162716783252876],
            [-1.276634893785342, 40.169331367048954],
            [-1.285058152711542, 40.183154812044904],
            [-1.291414354089113, 40.196771552365277],
            [-1.294592453878579, 40.201629136975043],
            [-1.300819464946358, 40.203205268108633],
            [-1.314229498792372, 40.195272936496821],
            [-1.320017259389033, 40.187418118351474],
            [-1.322291021613324, 40.179046536268629],
            [-1.318466965777873, 40.163801987971311],
            [-1.319422980186374, 40.15646393556213],
            [-1.32453894721462, 40.149280910985226],
            [-1.343555873604686, 40.143131415182694],
            [-1.364123093605826, 40.139617417709701],
            [-1.395464850022847, 40.140082505703049],
            [-1.448536546424407, 40.149797674922723],
            [-1.442981329824505, 40.159176948257539],
            [-1.438123745214682, 40.171630967694938],
            [-1.43732276043707, 40.178813992271955],
            [-1.437813686852138, 40.186022854371203],
            [-1.439389817985756, 40.192379054849511],
            [-1.4443765929054, 40.196719876421128],
            [-1.469801398415768, 40.192973334052169],
            [-1.481118537190781, 40.19405853877052],
            [-1.495277878970995, 40.198425197864566],
            [-1.509437221650387, 40.201164048981667],
            [-1.519901698804347, 40.201164048981667],
            [-1.53346676318057, 40.195221258754117],
            [-1.541709154054217, 40.197908433927068],
            [-1.555093350377831, 40.205401515967139],
            [-1.660099859820605, 40.283019517836493],
            [-1.685240443591567, 40.293328966258741],
            [-1.699994066372994, 40.294750067761413],
            [-1.708985765180927, 40.291003526291661],
            [-1.713559128950664, 40.287670395971986],
            [-1.719036831184866, 40.289763291942393],
            [-1.720638799840714, 40.30234650348828],
            [-1.71603959764937, 40.310201320734336],
            [-1.71534196565915, 40.321415106721759],
            [-1.720638799840714, 40.335367742927019],
            [-1.762134975948442, 40.378285021436625],
            [-1.779291551264066, 40.392160143276016],
            [-1.808254613459667, 40.402029803432612],
            [-1.80825619087048, 40.402030341227231],
            [-1.79595720106397, 40.414691067139074],
            [-1.789626838108092, 40.415957139910034],
            [-1.782547166318665, 40.42104726851656],
            [-1.75004268991816, 40.459210313405265],
            [-1.741412726316298, 40.467349352390698],
            [-1.732472702553139, 40.472904568091394],
            [-1.713714158581553, 40.480216782978147],
            [-1.705626797338823, 40.485358588428085],
            [-1.69976152237632, 40.493342596883338],
            [-1.699994066372994, 40.528947658707466],
            [-1.698598802392809, 40.542383530975201],
            [-1.682759975792237, 40.579022122472992],
            [-1.677799038394909, 40.585274970163709],
            [-1.671055264289009, 40.589279894051884],
            [-1.64599219398454, 40.584448146964377],
            [-1.636871303967496, 40.5843447941769],
            [-1.620231492589284, 40.578918768786139],
            [-1.612480028130818, 40.574164536963764],
            [-1.604315150723664, 40.57062470106905],
            [-1.595711024644032, 40.569772040347374],
            [-1.586745164257906, 40.570598863546707],
            [-1.56989864730474, 40.578143622430218],
            [-1.561733770796877, 40.58294953109592],
            [-1.555635951837672, 40.589150701943311],
            [-1.551191779277133, 40.595093492170975],
            [-1.555222540687737, 40.614162096303744],
            [-1.554240687857487, 40.625789293441102],
            [-1.548607956891658, 40.641705634407458],
            [-1.540546434070649, 40.655115668253501],
            [-1.537445847747648, 40.662996323921163],
            [-1.537058275019348, 40.684131984703328],
            [-1.539667934927252, 40.694363918759905],
            [-1.54698014981409, 40.707489731765719],
            [-1.553077968773295, 40.716326401842025],
            [-1.557005378295599, 40.725163071918217],
            [-1.556695319033679, 40.735395005974794],
            [-1.555403407840998, 40.7439474552109],
            [-1.551191779277133, 40.752293198872025],
            [-1.541786668420002, 40.767227687907422],
            [-1.538763597362163, 40.776839505238996],
            [-1.539616258083839, 40.8007140162388],
            [-1.541399094792411, 40.809757391890145],
            [-1.544551357059447, 40.817379666038704],
            [-1.55369808639756, 40.825105292075563],
            [-1.579820522099396, 40.838541165242503],
            [-1.593618129573002, 40.85084015594839],
            [-1.601033698146608, 40.858746650037858],
            [-1.6088368394486, 40.871872463943077],
            [-1.614107835208387, 40.88670360019097],
            [-1.611937424872224, 40.919208075692126],
            [-1.610154588163653, 40.927734687405888],
            [-1.621885138088658, 40.946751613795925],
            [-1.555942875163538, 40.943786733371041],
            [-1.526774173089564, 40.953180044223643],
            [-1.494353517101104, 40.955278433253113],
            [-1.475116456459318, 40.949016225418518],
            [-1.464382813116288, 40.951279409816891],
            [-1.45516528375083, 40.959255483553761],
            [-1.449221679636793, 40.970186880620048],
            [-1.448276855492452, 40.981338004545279],
            [-1.456648437575296, 41.002937126447222],
            [-1.458208496025264, 41.01390148186789],
            [-1.452418701162088, 41.022075310227365],
            [-1.42944628841542, 41.028579217157102],
            [-1.42266772313684, 41.032479363731596],
            [-1.416965819017321, 41.039719354064914],
            [-1.409121580496532, 41.056594355245323],
            [-1.403331785633327, 41.06405407153818],
            [-1.393345212711381, 41.070261347882933],
            [-1.381732663731384, 41.073260615684859],
            [-1.369746578641667, 41.07324962866744],
            [-1.358639400987528, 41.070382196980859],
            [-1.329207026682923, 41.054649774567423],
            [-1.270188469722086, 41.054177362495295],
            [-1.25834520776516, 41.060922968520146],
            [-1.254016594490253, 41.07139293963246],
            [-1.257444328992619, 41.081258662557218],
            [-1.263585685930622, 41.090959591012336],
            [-1.267430901015274, 41.10094616303499],
            [-1.266508050006507, 41.107900508901366],
            [-1.254719719539366, 41.128170285330441],
            [-1.246875480119286, 41.13387218944996],
            [-1.235977042306644, 41.136728635018471],
            [-1.214388907422119, 41.137146115600757],
            [-1.168136463427032, 41.121886105259534],
            [-1.158688221084589, 41.122292599723664],
            [-1.122455310164469, 41.136421018315332],
            [-1.110348375976741, 41.155581175231077],
            [-1.106085680309889, 41.159074827341442],
            [-1.100823229008768, 41.161118284880871],
            [-1.086497056693844, 41.159250608828586],
            [-1.000561994140696, 41.114843868649587],
            [-0.998694318987674, 41.103857540093372],
            [-0.995222639113507, 41.096892208108926],
            [-0.989015363668045, 41.092168086487931],
            [-0.954287578808675, 41.078995479058108],
            [-0.945685283748844, 41.079369014268622],
            [-0.938269511928411, 41.082434199678545],
            [-0.883535622870625, 41.130268674359797],
            [-0.823649146390949, 41.09291515690893],
            [-0.813926245699719, 41.090640987291664],
            [-0.80121506332452, 41.093607295839973],
            [-0.792371068270171, 41.10214367329192],
            [-0.772518773322759, 41.15185680924435],
            [-0.762191624443858, 41.16126110711437],
            [-0.751183322752155, 41.162601438705423],
            [-0.740273898821329, 41.156306272516389],
            [-0.694010469607491, 41.112569699032349],
            [-0.610052946086597, 41.191704223171314],
            [-0.642627410426627, 41.238374146849395],
            [-0.625499724033034, 41.248327760517697],
            [-0.631069792936557, 41.309928104698287],
            [-0.628927458535316, 41.320398075810516],
            [-0.619808806031557, 41.325990116950351],
            [-0.610206755337543, 41.324803593710868],
            [-0.601461637144837, 41.319618046135901],
            [-0.532566371141087, 41.232485475124577],
            [-0.524678187248526, 41.227190064569811],
            [-0.516724085747967, 41.227970094244455],
            [-0.510209192700074, 41.237308472707838],
            [-0.508737024993678, 41.244021119479072],
            [-0.509297327809463, 41.249964723593109],
            [-0.521766810190144, 41.276529666211133],
            [-0.559383998972379, 41.324660771477369],
            [-0.564382778942075, 41.336284306575436],
            [-0.562548062143321, 41.346457647102682],
            [-0.550507045563705, 41.352983526268702],
            [-0.493004602580328, 41.354345830995243],
            [-0.485588830759895, 41.351829962282949],
            [-0.479897911859126, 41.345402959978543],
            [-0.474130090131524, 41.327407353166819],
            [-0.470845177862657, 41.32223279260927],
            [-0.465132286725606, 41.318574345129917],
            [-0.446038047417971, 41.317618534867421],
            [-0.440237266436725, 41.314256717973137],
            [-0.43032759814011, 41.297260867694888],
            [-0.405861044551585, 41.272354861287852],
            [-0.337229449879203, 41.241461305394893],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5848",
        OBJECTID_1: 7265,
        diss_me: 5848,
        adm1_cod_1: "ESP-5848",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Toledo",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CM",
        note: null,
        hasc_maybe: null,
        region: "Castilla-La Mancha",
        region_cod: "ES.CM",
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP84",
        fips_alt: "SP54",
        woe_id: 12602114,
        woe_label: "Castille la Mancha, ES, Spain",
        woe_name: "Castilla-La Mancha",
        latitude: 39.740099999999998,
        longitude: -3.9513400000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2510407,
        gn_name: "Province of Toledo",
        gns_id: -404359,
        gns_name: "Toledo, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.TO",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP54",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.291980556757267, 40.22023265221506],
            [-4.286864589729049, 40.222351385707896],
            [-4.272860276680461, 40.231446438202482],
            [-4.266064825731121, 40.237079169168339],
            [-4.257770758014061, 40.2466909864998],
            [-4.250510219970636, 40.257491360437939],
            [-4.244980840893106, 40.262865708985288],
            [-4.234102951689835, 40.268886013578708],
            [-4.208652309556413, 40.272270819842419],
            [-4.183899299413127, 40.284130560976564],
            [-4.174675054809882, 40.283665472983188],
            [-4.166923591250679, 40.27940216757591],
            [-4.160748257026427, 40.273356025460231],
            [-4.132558762876641, 40.251806951729378],
            [-4.116797450641826, 40.247879544005741],
            [-4.09796139230437, 40.245528266516317],
            [-4.084422166349867, 40.248964748724177],
            [-4.074939541126867, 40.253615627759046],
            [-4.066051195106525, 40.259222521202361],
            [-4.057292039396089, 40.258266506793831],
            [-4.035717129042268, 40.251445217422884],
            [-4.026647914969288, 40.246794339287277],
            [-4.019077317664056, 40.241109931478121],
            [-4.012746954708177, 40.234211126842041],
            [-4.007786017310849, 40.227519029579554],
            [-3.999026861600328, 40.221576240251267],
            [-3.98639197411029, 40.217054552425594],
            [-3.933061896189542, 40.203902900098768],
            [-3.901229214256801, 40.192017320542931],
            [-3.853557704824226, 40.182663886529127],
            [-3.848519253061141, 40.179976712255439],
            [-3.843119066091987, 40.17584259715818],
            [-3.83764136385787, 40.170106513404846],
            [-3.827977870582231, 40.164189562498279],
            [-3.818856980565215, 40.163336899977963],
            [-3.801674566827785, 40.166825059928527],
            [-3.793819748682438, 40.164990546376629],
            [-3.786895107423277, 40.160598048860862],
            [-3.771469691972641, 40.143544827232006],
            [-3.764390021082562, 40.139772447340505],
            [-3.758653937329342, 40.137757065735968],
            [-3.746639166564307, 40.135121568305721],
            [-3.721446905949904, 40.138273831472134],
            [-3.707856004051337, 40.136723537860945],
            [-3.691862147819847, 40.133106186701099],
            [-3.673336146945587, 40.131943468516255],
            [-3.624501919328054, 40.11321076116775],
            [-3.614114955640673, 40.106492825483457],
            [-3.610394252592698, 40.101583564030278],
            [-3.611014370217077, 40.096803493786211],
            [-3.610394252592698, 40.086804103726337],
            [-3.611634486941966, 40.08155894638827],
            [-3.620626186649162, 40.060965887965324],
            [-3.623339199344571, 40.056805935345636],
            [-3.6314265605873, 40.047064927704241],
            [-3.633674486188511, 40.043447577443743],
            [-3.638402878689874, 40.040346992020034],
            [-3.65023678230159, 40.034507555479252],
            [-3.65765234997599, 40.033448188283245],
            [-3.670648972671984, 40.027531236477387],
            [-3.717106086176955, 39.989445705954552],
            [-3.733099940609804, 39.979007066323021],
            [-3.751600103961636, 39.974821275281613],
            [-3.757310350192512, 39.971539821805379],
            [-3.773485072677886, 39.957044583240901],
            [-3.778601039706217, 39.953737291342946],
            [-3.79058997204956, 39.953065496875155],
            [-3.852963425621567, 39.937872626320654],
            [-3.864693976445892, 39.932911688923326],
            [-3.873866543306349, 39.926736354698988],
            [-3.86937069300302, 39.919553331021433],
            [-3.857278407872201, 39.910949204941829],
            [-3.825704108357883, 39.895446275125494],
            [-3.809710252126365, 39.893534247207725],
            [-3.797695482260707, 39.896014715906432],
            [-3.790383267373869, 39.902836005277379],
            [-3.774647792661483, 39.912447821709577],
            [-3.766121181847097, 39.915445055245073],
            [-3.75183264795831, 39.925832018033162],
            [-3.742634242676132, 39.934410304791697],
            [-3.735916306991953, 39.938957831039005],
            [-3.728242356899244, 39.941825873365389],
            [-3.717390306117778, 39.944642238848274],
            [-3.699226040449389, 39.946192532459435],
            [-3.687676356778411, 39.950068264239036],
            [-3.670132208734401, 39.959680081570525],
            [-3.646903651981916, 39.964796047699394],
            [-3.638712937951709, 39.968310045172387],
            [-3.636154953987955, 39.973426012200719],
            [-3.637395189236571, 39.987559516458504],
            [-3.632666794936654, 39.99171946907822],
            [-3.617008836388692, 39.996370348113089],
            [-3.59830196836117, 40.000039374317623],
            [-3.596751674749981, 40.000866197516956],
            [-3.589827032591472, 40.005878810858434],
            [-3.583625860844791, 40.009392809230803],
            [-3.575331794027079, 40.012493395553776],
            [-3.535566778683886, 40.020219020691258],
            [-3.526730108607609, 40.023242091749097],
            [-3.522337611991219, 40.02877147082674],
            [-3.515361293888532, 40.039675198451619],
            [-3.502054612830079, 40.048666897259466],
            [-3.406220669348386, 40.045617987779906],
            [-3.371752489086134, 40.057400214548267],
            [-3.352916428950067, 40.046057237351647],
            [-3.341909349436975, 40.044636134949656],
            [-3.329429490678478, 40.047685045328592],
            [-3.288243373832671, 40.053162747562794],
            [-3.227730272234254, 40.077864080862639],
            [-3.187267624900926, 40.088354397337525],
            [-3.169387580072737, 40.080396227304078],
            [-3.165589361759629, 40.076055405732376],
            [-3.163548142632663, 40.066185207781047],
            [-3.170065275891773, 40.048269120823221],
            [-3.142621427636755, 39.985130691328322],
            [-3.096643641990454, 39.986844558129775],
            [-3.096973231829168, 39.933330151565201],
            [-3.125098232897074, 39.878068918945445],
            [-3.096281093797501, 39.869148020164943],
            [-3.059553796770302, 39.837562326240317],
            [-3.040701257457073, 39.787464668059954],
            [-3.015300865842249, 39.764481268295896],
            [-2.999502524921525, 39.721920231933183],
            [-2.90823909435872, 39.642313294822713],
            [-2.925388752988539, 39.601817688235741],
            [-2.931222493223515, 39.47140996789571],
            [-2.977903403019582, 39.44464727155426],
            [-3.022496910803767, 39.456995904837129],
            [-3.069518397456022, 39.450129449714325],
            [-3.143302579550408, 39.495426082547624],
            [-3.184490325068424, 39.487889460730059],
            [-3.185171477881426, 39.474156550484366],
            [-3.294155856907253, 39.470728815982085],
            [-3.305471775302209, 39.462489069115236],
            [-3.318106053051878, 39.423981987678587],
            [-3.326719334229864, 39.410227104297405],
            [-3.342616552012231, 39.4046680224113],
            [-3.39875669116833, 39.40500859836817],
            [-3.470343607371603, 39.372247366422812],
            [-3.476858500419468, 39.34306767823071],
            [-3.556135847691223, 39.29914433624208],
            [-3.586677840609894, 39.307713672947671],
            [-3.67898497307803, 39.295013476690599],
            [-3.722205190017519, 39.269854785070294],
            [-3.735828237283272, 39.269613085975152],
            [-3.780377798796394, 39.294376270148774],
            [-3.798626090821386, 39.291992236652362],
            [-3.814259636373123, 39.284543506477661],
            [-3.841648553138185, 39.259670458424893],
            [-3.875958857415327, 39.262087450275658],
            [-3.902380977799851, 39.28026982379339],
            [-3.966914671275021, 39.298452198210413],
            [-3.972056273478216, 39.352318166849898],
            [-4.073284304726826, 39.365369925181881],
            [-4.097673953689963, 39.345407766355322],
            [-4.106177371888236, 39.342309621691669],
            [-4.115933231833196, 39.34430913296012],
            [-4.140322881695596, 39.358964896013134],
            [-4.145794072838243, 39.367940726283479],
            [-4.14404724678306, 39.377224173257019],
            [-4.127842412297497, 39.408952690314351],
            [-4.118251347721525, 39.456995904837129],
            [-4.079810183892874, 39.451854303533281],
            [-4.065110476367352, 39.465664118404305],
            [-4.060573122351713, 39.473552302296895],
            [-4.059826051930742, 39.48382451968584],
            [-4.063154909571381, 39.491174372998927],
            [-4.075075076153809, 39.50343511553811],
            [-4.078294070814678, 39.509675350237217],
            [-4.07545959748245, 39.522089902027346],
            [-4.065132448603549, 39.527692929285251],
            [-4.052179567133294, 39.531604061977902],
            [-4.041522828415793, 39.538887998582254],
            [-4.038545533749414, 39.546787168592886],
            [-4.039512331029243, 39.567002012632827],
            [-4.041720583038511, 39.57539556785116],
            [-4.124414677795102, 39.563728087381293],
            [-4.158724982072272, 39.576768858695829],
            [-4.192705695611352, 39.498172664236989],
            [-4.24213318799616, 39.477935847061644],
            [-4.426780412186275, 39.508807430718292],
            [-4.435723283202947, 39.506829891686124],
            [-4.441018693757712, 39.502929745111629],
            [-4.447138078459517, 39.493107967558586],
            [-4.450928362054242, 39.489317683963947],
            [-4.463793352781011, 39.484736384576451],
            [-4.47606508143835, 39.486164607810366],
            [-4.487798480415591, 39.492514705489242],
            [-4.499026508066862, 39.502742977506415],
            [-4.512243061767833, 39.548533994648068],
            [-4.538423482158009, 39.548105527947712],
            [-4.553837301750065, 39.539426328262465],
            [-4.562351706066408, 39.5225403409639],
            [-4.56782289720897, 39.497469539187875],
            [-4.583555320521583, 39.4922730063941],
            [-4.60024355319743, 39.482890681659654],
            [-4.613328270783029, 39.469992731679241],
            [-4.618239159109891, 39.454260309265891],
            [-4.692731289317521, 39.449814561992468],
            [-4.703557501677352, 39.438419907952408],
            [-4.752960170975115, 39.418162747212989],
            [-4.766148100154652, 39.434353081929174],
            [-4.742099027148384, 39.477705134084658],
            [-4.745867337607535, 39.491196346134416],
            [-4.754030179848883, 39.502182674690715],
            [-4.774915190583556, 39.519420224064021],
            [-4.862695956053528, 39.556037657212073],
            [-4.873737216099613, 39.551181699475819],
            [-4.898544345645007, 39.517014218331525],
            [-4.944369676419456, 39.391911119402607],
            [-4.955971035135121, 39.399610907917122],
            [-4.966487189132408, 39.406225490813711],
            [-4.971422288108101, 39.409739488286732],
            [-4.984444749225844, 39.420953274274154],
            [-4.994831712013934, 39.432657985777382],
            [-5.024623174819681, 39.459297187215384],
            [-5.03924760639191, 39.478107408030468],
            [-5.046430630069551, 39.482008979131052],
            [-5.064284837375311, 39.485548814126503],
            [-5.079245163933138, 39.492835191490911],
            [-5.08689327560424, 39.500069892011879],
            [-5.101285163179682, 39.509371650081533],
            [-5.122627528637537, 39.527200018965658],
            [-5.159395311344525, 39.563941962351663],
            [-5.16683671744056, 39.568799546961486],
            [-5.182908088037834, 39.577119452200918],
            [-5.190271978868708, 39.582338772016726],
            [-5.19644731309296, 39.587661445519302],
            [-5.199522060994326, 39.593578396425869],
            [-5.197610033076558, 39.599650376963353],
            [-5.186783819817435, 39.611432603731714],
            [-5.181745368054237, 39.618047187527679],
            [-5.175828416248379, 39.624222519953378],
            [-5.171487595576053, 39.63096629495854],
            [-5.160997280000487, 39.643213608820986],
            [-5.157379929739989, 39.649673162986062],
            [-5.155028652250479, 39.656571966722879],
            [-5.151824714039293, 39.663005683365611],
            [-5.150481126003172, 39.68411550572597],
            [-5.146140306230137, 39.69796478914364],
            [-5.142419603182162, 39.704837755358113],
            [-5.14058508963015, 39.712356675819933],
            [-5.141721971191942, 39.720314845853466],
            [-5.148930833291274, 39.72868642793631],
            [-5.16241838240245, 39.741243801060563],
            [-5.165363939094533, 39.747186591288141],
            [-5.15908525208269, 39.762250271532736],
            [-5.159240281713608, 39.771784572699886],
            [-5.163813646382636, 39.784910387504368],
            [-5.168128627733978, 39.792894395959621],
            [-5.175725062561526, 39.796485907798399],
            [-5.182339647256896, 39.79692515737014],
            [-5.20223507279033, 39.79457387988063],
            [-5.208746303798847, 39.792739366328618],
            [-5.229106818225091, 39.779329332482689],
            [-5.237840134614544, 39.771422838393278],
            [-5.24892473029206, 39.757366848501277],
            [-5.253989019577574, 39.753956203815761],
            [-5.260267706589389, 39.752250882372323],
            [-5.274065314063023, 39.752870999097297],
            [-5.308120083175254, 39.758297024488087],
            [-5.313416917356818, 39.763206285041974],
            [-5.31693091482984, 39.771887926386739],
            [-5.314347093343713, 39.793436998318839],
            [-5.311866623745658, 39.803333034691775],
            [-5.310393846298894, 39.814882717463462],
            [-5.307758347969354, 39.82692332575084],
            [-5.302849087415382, 39.835243231889621],
            [-5.292100389421392, 39.84836904579484],
            [-5.289051479941861, 39.854854438381608],
            [-5.289309862360255, 39.861701565275013],
            [-5.293754034920767, 39.86785106107763],
            [-5.299490118674015, 39.873897203193394],
            [-5.306027188104224, 39.879555772580858],
            [-5.313210211781865, 39.884284165981484],
            [-5.321504278599548, 39.887488105091961],
            [-5.330237595888292, 39.888935045016268],
            [-5.357781133992091, 39.887178045830126],
            [-5.376746385337384, 39.883948269197248],
            [-5.3861514961946, 39.881028550926885],
            [-5.390595668755054, 39.893585924051138],
            [-5.391009079905075, 39.92040599174311],
            [-5.37411088700776, 39.994820055401192],
            [-5.368891567192037, 40.048666897259466],
            [-5.371139491893985, 40.058020331273269],
            [-5.370390183959785, 40.068329779695603],
            [-5.365041672934865, 40.093263657891498],
            [-5.360700853161831, 40.101531887186866],
            [-5.35762610526055, 40.105330105499974],
            [-5.327963832764624, 40.115897936340701],
            [-5.230321214152639, 40.106466987061765],
            [-5.201950852849507, 40.096183377061152],
            [-5.190582038130543, 40.089439602055961],
            [-5.166785040597119, 40.088819485330959],
            [-5.15066199405581, 40.091610012392124],
            [-5.141179368832809, 40.094788113080966],
            [-5.135029873030277, 40.098663844860567],
            [-5.131231654717055, 40.102539578438808],
            [-5.089812994773865, 40.126284898229528],
            [-5.085859747729131, 40.130729071689331],
            [-5.077384812858782, 40.138583888935301],
            [-5.069633348400316, 40.143544827232006],
            [-5.057851120732664, 40.148557441472747],
            [-5.032322964233401, 40.154810289163493],
            [-5.021290046298589, 40.155172024369364],
            [-5.014546271293398, 40.150882880540536],
            [-5.007880010653992, 40.122486680815683],
            [-5.003099941309216, 40.117086492947351],
            [-4.988630540267252, 40.114037584366997],
            [-4.96617713076995, 40.117861640202534],
            [-4.951656052884488, 40.122770900756421],
            [-4.929977789743191, 40.134501451580746],
            [-4.922407192437959, 40.140289212177379],
            [-4.918040534243289, 40.146722927021472],
            [-4.91408728719847, 40.154138494695786],
            [-4.91220109680313, 40.161631578534525],
            [-4.903493617936107, 40.173956406762727],
            [-4.89315833199143, 40.181216945705415],
            [-4.875898402988867, 40.189640203732409],
            [-4.865459764256713, 40.196823229208604],
            [-4.843161382591688, 40.206590074372457],
            [-4.826960821684594, 40.217648830728962],
            [-4.815023566184692, 40.229844469546634],
            [-4.812827318326072, 40.239042873929435],
            [-4.811819627074129, 40.247052720806408],
            [-4.806316087317583, 40.260953681067519],
            [-4.800760870717596, 40.265656236046425],
            [-4.779935269197267, 40.265320340161566],
            [-4.732909714911386, 40.2704104678688],
            [-4.710792202198348, 40.275836493259561],
            [-4.700043504204359, 40.275603950162179],
            [-4.694927537176113, 40.269531968725317],
            [-4.695831874741231, 40.26229726820435],
            [-4.693894009301118, 40.254700833376774],
            [-4.69133602533725, 40.247776191218236],
            [-4.686891851877448, 40.224263414524955],
            [-4.684075487293853, 40.217080389948023],
            [-4.678985357788065, 40.211344306194803],
            [-4.672009039685463, 40.206745104003346],
            [-4.652604538768458, 40.199949653054034],
            [-4.639375372975053, 40.197081610727736],
            [-4.62547441181465, 40.19759837556461],
            [-4.602426724014094, 40.202249253700103],
            [-4.571885952374771, 40.212016099763133],
            [-4.549303350769009, 40.200595608200729],
            [-4.532999437074352, 40.197960109871104],
            [-4.520571255159268, 40.198838609014587],
            [-4.511243658667894, 40.202817695380332],
            [-4.50636023563635, 40.208915514339509],
            [-4.496696743260088, 40.21581431717695],
            [-4.484061854870674, 40.221627916195331],
            [-4.453727789705709, 40.23165314377755],
            [-4.439749315078757, 40.239172065138717],
            [-4.43055090979658, 40.247414456012365],
            [-4.425434942768334, 40.255217597314271],
            [-4.394842495184974, 40.279453844419436],
            [-4.388227912288357, 40.286145942581186],
            [-4.383551194831767, 40.293483994990368],
            [-4.371071336073271, 40.307979234454109],
            [-4.36585201715684, 40.309477851221857],
            [-4.36011593340362, 40.308082587241586],
            [-4.353863083914149, 40.304155177719281],
            [-4.349935675291192, 40.298315742077847],
            [-4.348979660882662, 40.291494451807438],
            [-4.351563483268137, 40.276714993302335],
            [-4.35122758558461, 40.269428615937841],
            [-4.348617925676706, 40.262659003410221],
            [-4.340995653326701, 40.249274807086636],
            [-4.334200202377474, 40.243332017758433],
            [-4.325286017036035, 40.23762177242682],
            [-4.309989792794738, 40.232841702182753],
            [-4.296037156589506, 40.220801092995998],
            [-4.291980556757267, 40.22023265221506],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5849",
        OBJECTID_1: 3493,
        diss_me: 5849,
        adm1_cod_1: "ESP-5849",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Valencia",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.VC",
        note: null,
        hasc_maybe: "ES.AR|ESP-VLN",
        region: "Valenciana",
        region_cod: "ES.VC",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "VC",
        area_sqkm: 0,
        sameascity: 6,
        labelrank: 6,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP89",
        fips_alt: "SP60|SP52",
        woe_id: 12602139,
        woe_label: "Valencia, ES, Spain",
        woe_name: "Comunidad Valenciana",
        latitude: 39.3003,
        longitude: -0.71224500000000002,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 2509951,
        gn_name: "Provincia de Valencia",
        gns_id: -406136,
        gns_name: "Valence",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.V",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP60",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.989210577705961, 39.977766831973625],
              [-0.97246741443962, 39.974046128925679],
              [-0.92306474424251, 39.955985216044851],
              [-0.908776211253098, 39.942911078083696],
              [-0.904771287364952, 39.934617011266099],
              [-0.906476609707738, 39.926813869964107],
              [-0.909861415971562, 39.920095934279985],
              [-0.912057663830069, 39.913067939333914],
              [-0.912109340673567, 39.906401678694479],
              [-0.908543667256424, 39.900407213422156],
              [-0.904357876215016, 39.894800319978842],
              [-0.903324347440616, 39.888159897761156],
              [-0.902936773813025, 39.88087352129601],
              [-0.903453537750522, 39.872889512840757],
              [-0.902394172353183, 39.86539642990131],
              [-0.896890631697261, 39.859686184569696],
              [-0.888208991251872, 39.855242011109979],
              [-0.878054571561165, 39.852244778473775],
              [-0.859812790627814, 39.853097439195537],
              [-0.846997035984458, 39.855345363897371],
              [-0.791289842152452, 39.877540391875542],
              [-0.773749266215589, 39.869818186859774],
              [-0.737010983969697, 39.82075324356741],
              [-0.712654293360941, 39.816984932208911],
              [-0.691033199222773, 39.85232795137388],
              [-0.656030756014616, 39.835859444657686],
              [-0.644703851501532, 39.791595527611591],
              [-0.644297357037402, 39.776643133973423],
              [-0.6474284609547, 39.766359930466379],
              [-0.646637445161929, 39.757900457639963],
              [-0.634475579484359, 39.748463201415674],
              [-0.618885979304508, 39.745914373449565],
              [-0.601395744717848, 39.750803289540173],
              [-0.586102775122981, 39.760690984701128],
              [-0.56998583138099, 39.780191718472977],
              [-0.533104726002307, 39.796187812217781],
              [-0.478195056356753, 39.728083562006745],
              [-0.45594774089551, 39.722919987567323],
              [-0.432667710546355, 39.729533757477029],
              [-0.409585433920654, 39.745321111380221],
              [-0.379329085468925, 39.782938300162456],
              [-0.345392316402268, 39.797473213218169],
              [-0.309170391600304, 39.787387763434495],
              [-0.272036601008324, 39.7510889340072],
              [-0.194488085128398, 39.715440171213473],
              [-0.199818488999938, 39.704942124000169],
              [-0.202137824999909, 39.680853583000143],
              [-0.208933071999923, 39.664089260000125],
              [-0.219838019999912, 39.651393947000159],
              [-0.243723110999895, 39.631048895000063],
              [-0.257435675999915, 39.616237697000102],
              [-0.269561326999906, 39.59870026200015],
              [-0.274891730999883, 39.581813869000158],
              [-0.281483527999853, 39.569362697000045],
              [-0.31273352799991, 39.53400299700003],
              [-0.323353644999941, 39.516221421000139],
              [-0.330593476999894, 39.480406634000033],
              [-0.327471569999886, 39.430674937000063],
              [-0.320668097999885, 39.388128973000093],
              [-0.301340298999946, 39.322007554],
              [-0.238701407999883, 39.215445865000092],
              [-0.227817945999902, 39.1993356360001],
              [-0.215492823999909, 39.181838544000115],
              [-0.227857368999878, 39.175244997000092],
              [-0.24218216599985, 39.16627518000017],
              [-0.236505322999903, 39.1480534680001],
              [-0.226703362999956, 39.111781755000109],
              [-0.210026595999892, 39.078690194000089],
              [-0.184040187999869, 39.042258516000018],
              [-0.158029751999919, 39.00214264500012],
              [-0.120676235999952, 38.951117255000113],
              [-0.10594641799986, 38.93650950700011],
              [-0.06859290299991, 38.913397528000147],
              [-0.030995245866961, 38.882757879986187],
              [-0.23050827863409, 38.85337405556821],
              [-0.310807354675603, 38.881510043653535],
              [-0.416583725158716, 38.819228546660113],
              [-0.595298332252241, 38.795036651420347],
              [-0.565767081085937, 38.755562772703868],
              [-0.547046376988703, 38.761264677722764],
              [-0.532884999143391, 38.762583037077533],
              [-0.520503407506254, 38.757232695032926],
              [-0.50711107321743, 38.742873563464244],
              [-0.615469230920212, 38.693830593307311],
              [-0.633948235922304, 38.702125270764625],
              [-0.651108880670279, 38.717648953336564],
              [-0.730056638103321, 38.752673368781004],
              [-0.747261228223067, 38.75434329021077],
              [-0.81635424884945, 38.740775174434773],
              [-0.830647461910843, 38.746191434986756],
              [-0.857245363782482, 38.769240752358925],
              [-0.866880373730226, 38.774250517547287],
              [-0.876724124418843, 38.774338408290944],
              [-0.897905765738528, 38.770042754269653],
              [-0.908298832225341, 38.770394316344593],
              [-0.916143070746102, 38.773437529518318],
              [-0.930643004670856, 38.78550801598216],
              [-0.920480922756326, 38.791766872942858],
              [-0.918827277257037, 38.808406684320985],
              [-0.915132411731406, 38.813290107352614],
              [-0.912574428666943, 38.819801337461755],
              [-0.913272060657079, 38.824581406806445],
              [-0.926217007408923, 38.855251370554342],
              [-0.926527064872175, 38.863416246162743],
              [-0.921540289952418, 38.878557440773207],
              [-0.920946010749788, 38.886153876500245],
              [-0.926527064872175, 38.895533148935769],
              [-0.959186570903512, 38.940026556780239],
              [-0.971072149560086, 38.946253566948613],
              [-0.98130408361655, 38.949535020424847],
              [-0.991070928780289, 38.951214505245318],
              [-0.998538174197421, 38.950491033934],
              [-1.004248420428212, 38.948682358803907],
              [-1.019596319714339, 38.940569159139372],
              [-1.037863939069496, 38.93731354408483],
              [-1.066105109163431, 38.938088691340013],
              [-1.093648647267258, 38.933231105830899],
              [-1.121037156639431, 38.932585151583467],
              [-1.134395513642033, 38.937985338552451],
              [-1.151474574591902, 38.948527330072281],
              [-1.248367886168978, 39.038134264284949],
              [-1.255809292265013, 39.047280991824223],
              [-1.261312832021559, 39.063352363320959],
              [-1.262113816799172, 39.073687649265636],
              [-1.259685024044614, 39.082369289711025],
              [-1.256739468251823, 39.089190579082057],
              [-1.254982469065681, 39.096063544397154],
              [-1.251390957226903, 39.102962348133772],
              [-1.241184861592046, 39.11730255796671],
              [-1.237179937703985, 39.124769802484465],
              [-1.231314662741454, 39.139936835516622],
              [-1.226922166125064, 39.146809800831718],
              [-1.21485571941588, 39.159160468380847],
              [-1.207931077257342, 39.164431464140804],
              [-1.201807419876531, 39.170425930312504],
              [-1.19328080906206, 39.184998684141945],
              [-1.190955369094979, 39.191768297568942],
              [-1.186252814116045, 39.201147569105089],
              [-1.184547491773344, 39.206961168123442],
              [-1.181162686408811, 39.213214015814273],
              [-1.178578864023336, 39.220552070022009],
              [-1.176279263377268, 39.235228175739636],
              [-1.177597012092463, 39.242411201216029],
              [-1.17728695283057, 39.249955960099541],
              [-1.178294644082513, 39.257707424557921],
              [-1.175969204115432, 39.273365383105997],
              [-1.17379879377927, 39.280755113257882],
              [-1.170336473149689, 39.288299872141394],
              [-1.171473353812189, 39.296077175021665],
              [-1.177726203301575, 39.304112861219622],
              [-1.310482957322591, 39.337289130289193],
              [-1.320973272898186, 39.338245143798346],
              [-1.329086472562636, 39.337935086335264],
              [-1.339680141825085, 39.341733302849647],
              [-1.385000372868888, 39.364987698023825],
              [-1.406239386438529, 39.370594591467224],
              [-1.415411953298985, 39.370284532205304],
              [-1.420760464323905, 39.367649033875878],
              [-1.42649654807721, 39.366718857888984],
              [-1.435333218153517, 39.367261461147464],
              [-1.440785081066622, 39.366227932373178],
              [-1.445746019363241, 39.366899725941508],
              [-1.448536546424407, 39.373772691256605],
              [-1.450009324770519, 39.379482937487651],
              [-1.450991176701393, 39.385529080502707],
              [-1.452386439782373, 39.390205797059835],
              [-1.455512865426357, 39.39423655936973],
              [-1.464995489749981, 39.396742866490129],
              [-1.467708503344738, 39.400799465422907],
              [-1.469336310422392, 39.404158434164358],
              [-1.476855230884212, 39.406070462082241],
              [-1.481738653915755, 39.409429429924231],
              [-1.484451666611051, 39.415372219252518],
              [-1.487009649675542, 39.419635525559087],
              [-1.50101396272413, 39.422038478992548],
              [-1.501016139982852, 39.422041627519079],
              [-1.506801724220225, 39.430410061075506],
              [-1.510909999996528, 39.432683824199145],
              [-1.515018275772803, 39.436740424031271],
              [-1.517447069426737, 39.441081243804334],
              [-1.518273891726693, 39.446016344578695],
              [-1.518196377360908, 39.451829941798323],
              [-1.512692836705014, 39.45893545200947],
              [-1.506439989014268, 39.476040351381116],
              [-1.507473516889291, 39.48678904937519],
              [-1.505432297762326, 39.491026516360662],
              [-1.501324021986022, 39.493713691533699],
              [-1.498352626872247, 39.496090807444844],
              [-1.499205287593924, 39.49831289282578],
              [-1.49987708206163, 39.499294744756654],
              [-1.501168993254396, 39.500173244799399],
              [-1.503236049903705, 39.50397146311262],
              [-1.502615933178731, 39.512989000342159],
              [-1.503313564269575, 39.517949936840026],
              [-1.503391078635332, 39.522962551080965],
              [-1.502150845185355, 39.527355047697355],
              [-1.49987708206163, 39.533142809193365],
              [-1.499825405218274, 39.534899807480045],
              [-1.499902919584059, 39.53567495383605],
              [-1.501453213195219, 39.539111436943287],
              [-1.50357194668797, 39.54267711036043],
              [-1.504476284253144, 39.545545151787351],
              [-1.50432125462217, 39.549240017312982],
              [-1.503158534638658, 39.552934881939237],
              [-1.498120082875488, 39.563476874358244],
              [-1.494425218249233, 39.567275091772089],
              [-1.485304328232189, 39.570918281353656],
              [-1.474788174234789, 39.578488877759511],
              [-1.463186814619775, 39.590245266106152],
              [-1.427530075952234, 39.645228990425593],
              [-1.42148393383647, 39.651378486228211],
              [-1.398953009074035, 39.668948472693856],
              [-1.380969610559077, 39.680575669831299],
              [-1.372029587695295, 39.683572903366795],
              [-1.363063727309168, 39.684348048823338],
              [-1.353994513236216, 39.683624579310859],
              [-1.337044644394837, 39.677449245985898],
              [-1.329086472562636, 39.673651028572166],
              [-1.320353156173184, 39.671377265448498],
              [-1.30174964003379, 39.671015530242627],
              [-1.292189500445033, 39.672669175741916],
              [-1.283766242418125, 39.676209010737253],
              [-1.275911424272778, 39.680627345775363],
              [-1.269942795623507, 39.686776842477357],
              [-1.265498623062967, 39.693804837423372],
              [-1.265550299007032, 39.703442491377913],
              [-1.269736090048525, 39.718816229985052],
              [-1.270562913247858, 39.725947576819252],
              [-1.270097826153687, 39.734241645435603],
              [-1.267048915774865, 39.743465888240124],
              [-1.243872035865735, 39.77547943822556],
              [-1.232684089199381, 39.787003282575441],
              [-1.225578578988234, 39.796305039745803],
              [-1.217801276107934, 39.811911323248992],
              [-1.202866787072537, 39.863303533930903],
              [-1.200334642429794, 39.8795557725809],
              [-1.199404466442957, 39.894826158400562],
              [-1.196691452848199, 39.910587469735916],
              [-1.196846483378494, 39.918183906362131],
              [-1.19901689281528, 39.924075018847006],
              [-1.205140550196205, 39.933480129704222],
              [-1.206613327642941, 39.938570258310747],
              [-1.203796963059347, 39.944332180485688],
              [-1.196226365754143, 39.950636705019932],
              [-1.157934128757063, 39.963891710134348],
              [-1.14548010931955, 39.972289129739622],
              [-1.129382900300584, 39.964046738865974],
              [-1.105999314816472, 39.969188544315912],
              [-1.097059291952689, 39.969860337884327],
              [-1.078455775813381, 39.975183010487527],
              [-1.053496060095028, 39.978826199169831],
              [-1.018097702946591, 39.977275906457933],
              [-1.000269334062466, 39.979213771898131],
              [-0.989210577705961, 39.977766831973625],
            ],
          ],
          [
            [
              [-1.245215623002565, 40.140211696912289],
              [-1.245370652633483, 40.134966539574137],
              [-1.242166714422297, 40.12892039655911],
              [-1.234673630583558, 40.122951768809102],
              [-1.215088263412554, 40.115897936340744],
              [-1.151061164341229, 40.110058498900671],
              [-1.125843065305219, 40.097914536926439],
              [-1.093002692120564, 40.077011420140863],
              [-1.079954392581129, 40.065771795731891],
              [-1.076698778425879, 40.059002184103562],
              [-1.075613572808152, 40.052129217889089],
              [-1.078765835075188, 40.045127062264086],
              [-1.084114346100193, 40.039261786402378],
              [-1.09171078092777, 40.034559231423444],
              [-1.139072231098623, 40.016214097702417],
              [-1.162714199001044, 40.01145986588007],
              [-1.180542567885169, 40.008901881916316],
              [-1.187105474837722, 40.006188870120198],
              [-1.205786506242276, 40.003346666215705],
              [-1.223537359861268, 40.002313137441419],
              [-1.233226690659222, 40.000711167885996],
              [-1.234932013001952, 39.999936021530189],
              [-1.242967699199994, 39.997378038465726],
              [-1.258909877688694, 39.998127346399897],
              [-1.285910814332567, 40.006188870120198],
              [-1.349343634201318, 40.014405423471501],
              [-1.359782273832849, 40.014095364209695],
              [-1.369549118996588, 40.016343288911727],
              [-1.378308274707109, 40.02213104950836],
              [-1.40662696006612, 40.085460517488926],
              [-1.410166795061542, 40.097914536926439],
              [-1.412931483700987, 40.104787503140713],
              [-1.416884730745664, 40.109541734063797],
              [-1.440940110697539, 40.12044546168876],
              [-1.447787237590916, 40.125742295870268],
              [-1.452334763838223, 40.130677394845961],
              [-1.448536546424407, 40.149797674922794],
              [-1.395464850022847, 40.140082505703006],
              [-1.364123093605826, 40.139617417709744],
              [-1.343555873604686, 40.143131415182737],
              [-1.32453894721462, 40.149280910985183],
              [-1.319422980186374, 40.156463935562201],
              [-1.318466965777844, 40.163801987971382],
              [-1.322291021613324, 40.179046536268586],
              [-1.320017259389033, 40.187418118351516],
              [-1.314229498792372, 40.195272936496863],
              [-1.300819464946358, 40.203205268108675],
              [-1.294592453878579, 40.201629136975086],
              [-1.291414354089113, 40.19677155236532],
              [-1.285058152711542, 40.183154812044862],
              [-1.276634893785342, 40.169331367048997],
              [-1.271518926757125, 40.162716783252918],
              [-1.26503353506962, 40.156541449927957],
              [-1.25828976096372, 40.151425482899739],
              [-1.250641649292618, 40.147885647004912],
              [-1.246119960567739, 40.144862575947073],
              [-1.245215623002565, 40.140211696912289],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5850",
        OBJECTID_1: 7253,
        diss_me: 5850,
        adm1_cod_1: "ESP-5850",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Valladolid",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 10,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602122,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 41.5715,
        longitude: -4.83256,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3106671,
        gn_name: "Provincia de Valladolid",
        gns_id: -406202,
        gns_name: "Valladolid, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.VA",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.421581475304805, 42.030752106724336],
              [-5.43839055797784, 41.967251128136994],
              [-5.484741877935278, 41.984411772885053],
              [-5.471690119603295, 42.006384429997425],
              [-5.507714290681918, 42.057514803065615],
              [-5.52075506289583, 42.096977695664222],
              [-5.421581475304805, 42.030752106724336],
            ],
          ],
          [
            [
              [-5.303192798408901, 42.252082681670416],
              [-5.319287769914609, 42.244985513570626],
              [-5.337162526729202, 42.246556558138664],
              [-5.348357595126856, 42.26097062119716],
              [-5.348335622890659, 42.278153239080794],
              [-5.33014226235548, 42.289611979709164],
              [-5.30922429326651, 42.293171550326704],
              [-5.301973315915802, 42.27317643224653],
              [-5.303192798408901, 42.252082681670416],
            ],
          ],
          [
            [
              [-5.064009439783803, 42.273714762826003],
              [-5.041707192832661, 42.267540445734895],
              [-5.038268472212224, 42.297049724665001],
              [-5.002233315015445, 42.289150554654285],
              [-5.019086342160932, 42.230274819926947],
              [-5.014548989044584, 42.18462662411936],
              [-5.026952553817267, 42.153952795085345],
              [-4.92742740325204, 42.150173498508153],
              [-4.922066075089276, 42.091869052714671],
              [-4.924043614121445, 42.081443026974128],
              [-4.927976719949669, 42.071621249421085],
              [-4.945466955435649, 42.054295809304122],
              [-4.947444494467817, 42.047033845835344],
              [-4.94150089035378, 42.026038972120872],
              [-4.944104650709022, 42.018348541951738],
              [-4.950256994664556, 42.014107819420374],
              [-4.957628821113218, 42.010976715502991],
              [-4.96391300028472, 42.006604156856369],
              [-4.966692542127106, 42.001989899114605],
              [-4.971306799868955, 41.986696929519738],
              [-5.005045814312894, 41.938016507555758],
              [-4.986808509305234, 41.913385159497423],
              [-4.94972965020321, 41.933973538747765],
              [-4.927767980108172, 41.915099026298961],
              [-4.869089999104119, 41.931578519133396],
              [-4.838196443211245, 41.898630519582767],
              [-4.78913149991871, 41.817990868483847],
              [-4.75858950610089, 41.825868066258252],
              [-4.728904446582902, 41.852092432020143],
              [-4.709282863713213, 41.878800196871666],
              [-4.69341860518449, 41.889687648566067],
              [-4.675587794641046, 41.896905666663159],
              [-4.666974512563684, 41.898278957507856],
              [-4.660130029677106, 41.897378078735372],
              [-4.645946679595596, 41.891258693134105],
              [-4.640618310686477, 41.886501613158842],
              [-4.632642236050316, 41.863287501317146],
              [-4.572250388244782, 41.815925438708106],
              [-4.52110902905838, 41.823132469787666],
              [-4.491951313102561, 41.838568261615947],
              [-4.493665179904099, 41.780230857468197],
              [-4.461958635982256, 41.766805563925729],
              [-4.443457658743966, 41.76325697942606],
              [-4.435097062779164, 41.764762106386016],
              [-4.413366104761849, 41.777407370253925],
              [-4.399743057496039, 41.781032859378954],
              [-4.312138073513211, 41.771628561508905],
              [-4.291099254427053, 41.778670798118853],
              [-4.257305307593811, 41.801357567297842],
              [-4.240133675828474, 41.804345848082349],
              [-4.151539922329533, 41.798424217103999],
              [-4.123041386950433, 41.807356102002458],
              [-4.099376835272722, 41.774397116333844],
              [-4.04102844410744, 41.768222800142112],
              [-4.055794069240875, 41.740778950987831],
              [-4.031382447142164, 41.701074360193658],
              [-4.027581177429397, 41.684001605289794],
              [-4.030382691507981, 41.630619034840564],
              [-3.992996215702817, 41.608657363846348],
              [-3.980614623166332, 41.583245986113369],
              [-3.991458129489047, 41.54023450991464],
              [-3.999148559658266, 41.527699109026585],
              [-4.014946899679614, 41.513911267291135],
              [-4.13375305715789, 41.479963511207146],
              [-4.166250616872503, 41.477535533238253],
              [-4.25237244613146, 41.452047250879943],
              [-4.324212048447293, 41.45095960450216],
              [-4.364960341146826, 41.438786752706548],
              [-4.407532364526958, 41.441873910352783],
              [-4.486458148824539, 41.408947883937643],
              [-4.514934711967356, 41.409618049733197],
              [-4.496071185636708, 41.363980840943142],
              [-4.49555482819278, 41.341063359686345],
              [-4.49223695756956, 41.33430676754331],
              [-4.472285784861072, 41.309181034277358],
              [-4.470330218065101, 41.302633182875027],
              [-4.471362932953014, 41.291569949693454],
              [-4.53416078739042, 41.319376347040716],
              [-4.557506735347488, 41.302545292131541],
              [-4.596958640928563, 41.320738651767343],
              [-4.606989159222309, 41.298018924233887],
              [-4.579138815603812, 41.278847781200213],
              [-4.650835595686203, 41.221554078058247],
              [-4.650495019729362, 41.199943970038234],
              [-4.70151552981784, 41.154998899279846],
              [-4.794064361381146, 41.13602550996923],
              [-4.836680330133163, 41.104033321580559],
              [-4.856620515824119, 41.097507442414539],
              [-4.88531680582588, 41.10598888837653],
              [-4.974734534371237, 41.15532848911856],
              [-4.998607825890446, 41.160788695042228],
              [-5.01867984859615, 41.158426633782128],
              [-5.059900553367811, 41.138519406445496],
              [-5.093661540947352, 41.143935666997479],
              [-5.107504315072077, 41.140903439941795],
              [-5.125104412638564, 41.131993527279562],
              [-5.174487959651657, 41.165380980547766],
              [-5.18674870219084, 41.168061644629162],
              [-5.211182297425012, 41.167863890006615],
              [-5.222816819540526, 41.170335814246485],
              [-5.230781907159241, 41.176345335968435],
              [-5.244086351603869, 41.192517211200354],
              [-5.253084154110411, 41.19675793463098],
              [-5.262114915870654, 41.195857055858525],
              [-5.28877873535032, 41.182091185459811],
              [-5.325484059241916, 41.177982299043904],
              [-5.340260671392798, 41.194110228903881],
              [-5.308268482104751, 41.218181275045808],
              [-5.296974536845369, 41.233496216876873],
              [-5.294414721861926, 41.252821169161578],
              [-5.297842456364208, 41.29277844606834],
              [-5.302368824261862, 41.305533572916048],
              [-5.323473560956018, 41.332757695211498],
              [-5.327890064974525, 41.343546270044371],
              [-5.328999684487883, 41.357268194171908],
              [-5.32123235059251, 41.411375862805912],
              [-5.326286061152757, 41.417187630804577],
              [-5.333372243134505, 41.422131478385054],
              [-5.33933781948474, 41.433194710667422],
              [-5.341216481655266, 41.449498422913834],
              [-5.338623708317471, 41.466010874102508],
              [-5.331460621710306, 41.480908336250664],
              [-5.319683278260669, 41.492301158371745],
              [-5.306499683813342, 41.498090954134327],
              [-5.264389086387183, 41.503397350807134],
              [-5.254578294952296, 41.507297497381629],
              [-5.228727463501627, 41.528358288704013],
              [-5.289800464120077, 41.563009168937938],
              [-5.294436694997501, 41.572534315905912],
              [-5.290097094705146, 41.58054334979569],
              [-5.282868090490012, 41.588343642944679],
              [-5.27885808093572, 41.597242568589692],
              [-5.282923022879231, 41.604702285781812],
              [-5.313992359359929, 41.614238418867856],
              [-5.322979176647692, 41.630827775581352],
              [-5.326923268593987, 41.670268695044186],
              [-5.337107595239274, 41.686517474901549],
              [-5.369198660489758, 41.719432515198363],
              [-5.391215262974015, 41.76099379592695],
              [-5.279594165238564, 41.813332665370197],
              [-5.301819507564232, 41.850971826388744],
              [-5.307905933911684, 41.871153712074218],
              [-5.305368092063816, 41.89301650620682],
              [-5.296864673865599, 41.909078518458884],
              [-5.296699878496611, 41.921372220251797],
              [-5.304829761484314, 41.944355619116507],
              [-5.303928882711745, 41.955319975436524],
              [-5.280549975500975, 41.978929594725017],
              [-5.376295828589548, 42.041386873205667],
              [-5.38738103400749, 42.083036044677741],
              [-5.376878104540822, 42.134265294607658],
              [-5.387205253419722, 42.195162513738936],
              [-5.381459403029055, 42.214773109591405],
              [-5.361189627499272, 42.224979409372182],
              [-5.352466482442139, 42.222694252737526],
              [-5.321660817292866, 42.204226234752937],
              [-5.30922429326651, 42.204094398637594],
              [-5.286812182436279, 42.216344155058621],
              [-5.274518481542657, 42.219607094192],
              [-5.270025072898818, 42.218947914514573],
              [-5.267520190304481, 42.217981118134119],
              [-5.265333910531524, 42.218178871857461],
              [-5.261741381559546, 42.221068276679617],
              [-5.259368334181204, 42.224770668631393],
              [-5.250436449282745, 42.248303385093109],
              [-5.238164719726029, 42.264343424209599],
              [-5.229848069133027, 42.269331217161877],
              [-5.219487961899887, 42.270770426514005],
              [-5.19480168145239, 42.262080240710603],
              [-5.170368086218218, 42.267902994827423],
              [-5.148109784638848, 42.279987955879463],
              [-5.123972820888923, 42.300246746190297],
              [-5.112415203398939, 42.306201336422575],
              [-5.093848308552538, 42.30939835794787],
              [-5.064009439783803, 42.273714762826003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5851",
        OBJECTID_1: 7256,
        diss_me: 5851,
        adm1_cod_1: "ESP-5851",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Bizkaia",
        name_alt: "Biscay|Vizcaya",
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.PV",
        note: null,
        hasc_maybe: null,
        region: "País Vasco",
        region_cod: "ES.PV",
        provnum_ne: 18,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "PV",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP94",
        fips_alt: "SP59",
        woe_id: 12602136,
        woe_label: "Basque Country, ES, Spain",
        woe_name: "País Vasco",
        latitude: 43.239199999999997,
        longitude: -2.7953000000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3104469,
        gn_name: "Bizkaia",
        gns_id: -408929,
        gns_name: "Bizkaia",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.BI",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP59",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.406783742288411, 43.329305821587923],
            [-2.440166566733723, 43.288126422696223],
            [-2.418193909621266, 43.26753804254659],
            [-2.459359682003708, 43.227306107290985],
            [-2.464688051812175, 43.224768265443117],
            [-2.468093814078259, 43.224768265443117],
            [-2.476959780469429, 43.227591751757899],
            [-2.490527896245425, 43.222351273592352],
            [-2.488945865559231, 43.183657424550518],
            [-2.495713443820307, 43.168397414209295],
            [-2.498954410717488, 43.167474562301237],
            [-2.501777897032355, 43.16782612527544],
            [-2.504326724998293, 43.167507521554882],
            [-2.506732730730903, 43.16457417136084],
            [-2.507051334451546, 43.161530958187114],
            [-2.498262271786416, 43.118684277357488],
            [-2.501722965542427, 43.103226513293009],
            [-2.514005680317837, 43.093228954252822],
            [-2.546547185404222, 43.088043406677826],
            [-2.600237373456054, 43.08146259602205],
            [-2.622451729663652, 43.087658885349271],
            [-2.637437081656003, 43.085472606475605],
            [-2.65684992468428, 43.075760691902332],
            [-2.658662668347461, 43.07289326021575],
            [-2.657256418249148, 43.069751170180297],
            [-2.635239815764891, 43.047141305626724],
            [-2.629801583876002, 43.035473825156885],
            [-2.633130440617265, 43.024872017929283],
            [-2.636646065863232, 43.022839547407187],
            [-2.640524240201501, 43.022718697409886],
            [-2.667946116220293, 43.027234078290093],
            [-2.686897533294513, 43.021477242680561],
            [-2.697905834086953, 43.020620308380501],
            [-2.738368482319657, 43.033155709268556],
            [-2.78186335670847, 43.026706734728037],
            [-2.82925837857124, 43.037165719722196],
            [-2.851198076430052, 43.046877634295384],
            [-2.862678789294705, 43.048822214073908],
            [-2.895385089750107, 43.047481881583593],
            [-2.948273274991635, 43.083868601754546],
            [-2.95678768020727, 43.096019481313959],
            [-2.954019125382302, 43.109279980386816],
            [-2.93673763063714, 43.135394483168909],
            [-2.935913655590724, 43.146864209915321],
            [-2.950371664021105, 43.173484084023272],
            [-2.970476645081078, 43.193259475244616],
            [-2.984308433087676, 43.201817825832052],
            [-2.999326744333757, 43.204981888103703],
            [-3.015377770467751, 43.199840285900507],
            [-3.021837731126425, 43.191677444558422],
            [-3.025265465628706, 43.170407912495193],
            [-3.031472741074083, 43.160882765527219],
            [-3.043261071541252, 43.154708448436111],
            [-3.104081386946405, 43.145216260721867],
            [-3.116737636932385, 43.149753614737477],
            [-3.141844041069646, 43.165117499473041],
            [-3.142154100331481, 43.174780991849275],
            [-3.145926479323577, 43.178372503688053],
            [-3.151507534345171, 43.181447252488709],
            [-3.173573371114173, 43.183979397131452],
            [-3.207498949017292, 43.184392809180764],
            [-3.229745652939528, 43.186976629767685],
            [-3.246592169892693, 43.198087062967545],
            [-3.252483283276831, 43.200438341356318],
            [-3.25945960137949, 43.201187649290517],
            [-3.26832210897814, 43.199947414941249],
            [-3.278476527769556, 43.197311917510916],
            [-3.362424892594674, 43.157934474896109],
            [-3.423790655814116, 43.140622869949482],
            [-3.425651006888472, 43.153128567129698],
            [-3.433118252305491, 43.173980007071663],
            [-3.43776913134036, 43.181137193226874],
            [-3.440947231129826, 43.188061835385412],
            [-3.441463995966615, 43.195141507174839],
            [-3.440042893564652, 43.202143662799756],
            [-3.440327114404766, 43.209869289735991],
            [-3.441722378385009, 43.217310695831941],
            [-3.445029670283049, 43.22423533799045],
            [-3.441644863119961, 43.23330455206343],
            [-3.430896165125915, 43.243458970854846],
            [-3.347154506775126, 43.287590644392708],
            [-3.335811529578393, 43.295290432007789],
            [-3.322944098091682, 43.300096341572925],
            [-3.261035731613731, 43.296763211253193],
            [-3.235197515852633, 43.299088650320982],
            [-3.212899135986333, 43.293197536936759],
            [-3.20354570197253, 43.292293199371642],
            [-3.160240850734624, 43.307796129187977],
            [-3.153910487778745, 43.315030828809682],
            [-3.149543829584076, 43.32443594056619],
            [-3.150499843992606, 43.353684801012719],
            [-3.150601676048183, 43.360154509078257],
            [-3.131499803999901, 43.355780341000127],
            [-3.112375454999921, 43.354071356000105],
            [-3.089507615999906, 43.366888739000046],
            [-3.057850714999915, 43.352362372000101],
            [-3.008168097999913, 43.320908921000097],
            [-3.008168097999913, 43.32843659100007],
            [-3.020008917999888, 43.352565822000059],
            [-3.023426886999886, 43.364935614000018],
            [-3.029286261999857, 43.372137762000051],
            [-3.029286261999857, 43.376166083000015],
            [-3.024322068999879, 43.380926825000103],
            [-3.010975714999887, 43.384141343000067],
            [-2.999419725999957, 43.394354559000107],
            [-2.955922003999916, 43.413845119000086],
            [-2.939930792999917, 43.417100328000032],
            [-2.939930792999917, 43.42458730700011],
            [-2.946685350999871, 43.42458730700011],
            [-2.946685350999871, 43.43077220300006],
            [-2.930083787999905, 43.437933661000017],
            [-2.905262824999852, 43.439886786000073],
            [-2.854237433999856, 43.438259182000138],
            [-2.806996222999885, 43.44167715100005],
            [-2.785959438999953, 43.447862046000125],
            [-2.768625454999949, 43.452500718000039],
            [-2.755848761999914, 43.450262762000051],
            [-2.734934048999946, 43.438788153000104],
            [-2.715199347999913, 43.424505927000041],
            [-2.70653235599994, 43.413682359000035],
            [-2.694935675999915, 43.388617255000042],
            [-2.691029425999915, 43.36753978100009],
            [-2.687001105999855, 43.37144603100009],
            [-2.679269985999952, 43.389837958000044],
            [-2.678456183999941, 43.394639390000037],
            [-2.678781704999949, 43.400580145000049],
            [-2.677805141999983, 43.406195380000071],
            [-2.672963019999912, 43.410345770000106],
            [-2.661203579999921, 43.411932684000135],
            [-2.649322068999879, 43.409531968000096],
            [-2.638091600999871, 43.405951239000018],
            [-2.606922980999883, 43.401882229000051],
            [-2.548898891999926, 43.383612372000073],
            [-2.506011522999927, 43.377142645000077],
            [-2.483509894999941, 43.37063222900008],
            [-2.464426235999952, 43.349229234000035],
            [-2.406783742288411, 43.329305821587923],
          ],
          [
            [-3.299198581349458, 43.216869095432401],
            [-3.267360201312272, 43.227393998034557],
            [-3.268909273643999, 43.237589310798086],
            [-3.286146823017475, 43.256364946385162],
            [-3.304197361319154, 43.260682573541857],
            [-3.304241306690926, 43.222153519869011],
            [-3.299198581349458, 43.216869095432401],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5852",
        OBJECTID_1: 7252,
        diss_me: 5852,
        adm1_cod_1: "ESP-5852",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Zamora",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.CL",
        note: null,
        hasc_maybe: "ES.CB|ESP-CYL",
        region: "Castilla y León",
        region_cod: "ES.CL",
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP85",
        fips_alt: "SP55|SP39",
        woe_id: 12602123,
        woe_label: "Castille and Leon, ES, Spain",
        woe_name: "Castilla y León",
        latitude: 41.683700000000002,
        longitude: -5.8429200000000003,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3104341,
        gn_name: "Provincia de Zamora",
        gns_id: -409114,
        gns_name: "Zamora, Provincia de",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.ZA",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP55",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.690557353239228, 42.213608559487454],
            [-6.654060770088392, 42.216816567130792],
            [-6.644173074927437, 42.21559708463775],
            [-6.616289772954588, 42.205599525597677],
            [-6.557809545673962, 42.207137611811333],
            [-6.523773899745663, 42.200699623388886],
            [-6.490584201099978, 42.184165199064608],
            [-6.427764374426346, 42.201996009608138],
            [-6.339544157037182, 42.20012833445503],
            [-6.32039498623962, 42.207214515537402],
            [-6.311529018949074, 42.208115395209319],
            [-6.302124721978402, 42.202336586464298],
            [-6.290786830447871, 42.185933997356102],
            [-6.279536830560289, 42.177837073622015],
            [-6.243677453951307, 42.167883459953714],
            [-6.214717491718829, 42.150470129093179],
            [-6.203698203908971, 42.148338781709441],
            [-6.045022661166911, 42.157688147190271],
            [-5.955879590970312, 42.125827794017482],
            [-5.950364454456007, 42.126399082951338],
            [-5.937949902665878, 42.134825597423401],
            [-5.932478711523345, 42.136352696619639],
            [-5.920745312546103, 42.136132970660071],
            [-5.915318065875994, 42.137528233740966],
            [-5.911582714670487, 42.140714269148219],
            [-5.90737495049359, 42.148152014104141],
            [-5.903584666898865, 42.151074377280651],
            [-5.893037792060397, 42.150459142975023],
            [-5.883633495089725, 42.142142492382106],
            [-5.869417185754486, 42.122037510422757],
            [-5.838194040022927, 42.118598789802235],
            [-5.755137396173865, 42.136792150337413],
            [-5.743129338847893, 42.113468174616486],
            [-5.676914736925625, 42.120312657503149],
            [-5.679650332496834, 42.140208897822362],
            [-5.655996766937278, 42.134770665933445],
            [-5.643274598443924, 42.128750157193991],
            [-5.625125184179922, 42.111194004100042],
            [-5.622510437706524, 42.107315830661037],
            [-5.621148132979897, 42.102185215475259],
            [-5.624740662851281, 42.083585360475979],
            [-5.553362485590242, 42.06300796734385],
            [-5.541695005120374, 42.098010410552007],
            [-5.52075506289583, 42.096977695664179],
            [-5.507714290681918, 42.057514803065658],
            [-5.471690119603295, 42.006384429997382],
            [-5.484741877935278, 41.984411772885011],
            [-5.43839055797784, 41.967251128137036],
            [-5.421581475304805, 42.030752106724293],
            [-5.376295828589548, 42.041386873205596],
            [-5.280549975500975, 41.978929594725059],
            [-5.303928882711745, 41.955319975436566],
            [-5.304829761484314, 41.94435561911655],
            [-5.296699878496611, 41.921372220251754],
            [-5.296864673865599, 41.909078518458813],
            [-5.305368092063816, 41.893016506206777],
            [-5.307905933911684, 41.871153712074175],
            [-5.301819507564232, 41.850971826388701],
            [-5.279594165238564, 41.813332665370154],
            [-5.391215262974015, 41.760993795926908],
            [-5.369198660489758, 41.719432515198378],
            [-5.337107595239274, 41.686517474901478],
            [-5.326923268593987, 41.670268695044228],
            [-5.322979176647692, 41.630827775581395],
            [-5.313992359359929, 41.614238418867899],
            [-5.282923022879231, 41.604702285781855],
            [-5.27885808093572, 41.59724256858965],
            [-5.282868090490012, 41.588343642944722],
            [-5.290097094705146, 41.580543349795732],
            [-5.294436694997501, 41.572534315905955],
            [-5.289800464120077, 41.563009168937981],
            [-5.228727463501656, 41.528358288704055],
            [-5.254578294952296, 41.507297497381586],
            [-5.264389086387183, 41.503397350807091],
            [-5.306499683813342, 41.498090954134256],
            [-5.319683278260698, 41.492301158371703],
            [-5.331460621710335, 41.480908336250707],
            [-5.338623708317471, 41.466010874102551],
            [-5.341216481655295, 41.449498422913848],
            [-5.33933781948474, 41.433194710667379],
            [-5.333372243134505, 41.422131478385012],
            [-5.326286061152757, 41.417187630804534],
            [-5.32123235059251, 41.411375862805869],
            [-5.328999684487883, 41.357268194171866],
            [-5.327890064974525, 41.3435462700443],
            [-5.323473560956046, 41.332757695211427],
            [-5.302368824261862, 41.305533572916005],
            [-5.297842456364208, 41.292778446068382],
            [-5.294414721861926, 41.252821169161621],
            [-5.296974536845397, 41.233496216876915],
            [-5.308268482104751, 41.218181275045765],
            [-5.340260671392798, 41.194110228903924],
            [-5.325484059241944, 41.177982299043862],
            [-5.28877873535032, 41.182091185459768],
            [-5.294151050530502, 41.131147579996792],
            [-5.296337329404253, 41.124511837850974],
            [-5.301094410278807, 41.119864620855566],
            [-5.309553883105224, 41.117019162304473],
            [-5.320078785707381, 41.116151241886286],
            [-5.329142506721297, 41.117821164215314],
            [-5.337195485982846, 41.122050900628523],
            [-5.35597112067066, 41.140474973241396],
            [-5.362639822070093, 41.143924680879365],
            [-5.397213798577894, 41.14613293288852],
            [-5.401685234086244, 41.14778088208206],
            [-5.407518974321221, 41.185529906979582],
            [-5.496749935261278, 41.177290160112818],
            [-5.549143736194452, 41.206678589045623],
            [-5.611205505429666, 41.208689087331521],
            [-5.630486512342685, 41.216247681385369],
            [-5.660457216327472, 41.244076051868291],
            [-5.673267275564314, 41.245405397341187],
            [-5.711851261626379, 41.228530396160863],
            [-5.811266548312574, 41.216577271224082],
            [-5.889181590857703, 41.235276002185742],
            [-5.908089061660831, 41.232990845551058],
            [-5.917735058626022, 41.221861694761401],
            [-5.920767284782329, 41.20519543432178],
            [-5.918526074418793, 41.18052014089173],
            [-5.914966503801082, 41.168457152075888],
            [-5.914658886198652, 41.162667357212797],
            [-5.920547558822761, 41.151483274933213],
            [-5.932610547638603, 41.143803830882064],
            [-5.946760938466497, 41.141145139036894],
            [-5.958966749515838, 41.144990354121546],
            [-5.962735059975074, 41.150417600791656],
            [-5.965789259266842, 41.157470823519674],
            [-5.969722365095066, 41.163084837794997],
            [-5.976072462773971, 41.164150511037263],
            [-5.980488966792365, 41.161689573814812],
            [-5.991980666674351, 41.1504066146735],
            [-5.999495315356512, 41.149209104416599],
            [-6.007691116851504, 41.151637083284783],
            [-6.027642289559992, 41.165018431455451],
            [-6.028301469237419, 41.168775755796446],
            [-6.023104935544296, 41.181585815033287],
            [-6.022588578100368, 41.189979369352443],
            [-6.025620805156052, 41.196186645697168],
            [-6.031421586137299, 41.200734984931671],
            [-6.056360551798065, 41.208359497492808],
            [-6.109303669428783, 41.212644165395858],
            [-6.128650593949715, 41.197175415213223],
            [-6.321889127081505, 41.280473758157399],
            [-6.482128385747274, 41.302991610350063],
            [-6.463140828999911, 41.314120178000024],
            [-6.430998086999892, 41.339028219000085],
            [-6.399578816999849, 41.377475485000048],
            [-6.395496377999933, 41.378405660000084],
            [-6.3831973879999, 41.376545309000079],
            [-6.379166625999915, 41.377475485000048],
            [-6.376737832999936, 41.383004863000053],
            [-6.37554927599993, 41.390808004000064],
            [-6.376169392999884, 41.396854146000095],
            [-6.379166625999915, 41.397319234000122],
            [-6.366247517999909, 41.405174053000053],
            [-6.352346557999908, 41.409256491000065],
            [-6.321133991999943, 41.41101348900014],
            [-6.321030639999918, 41.419591777000079],
            [-6.282945108999854, 41.465635478000081],
            [-6.282480020999941, 41.472301738000112],
            [-6.285167194999872, 41.478967998000115],
            [-6.285890665999943, 41.486874492000055],
            [-6.279534463999937, 41.497003072000012],
            [-6.253644571999899, 41.517518616000075],
            [-6.205947224999932, 41.570280253000107],
            [-6.216127481999905, 41.580098775000096],
            [-6.230080118999894, 41.594904073000123],
            [-6.272248087999856, 41.628235372000063],
            [-6.317103230999948, 41.650146180000036],
            [-6.365937459999913, 41.663607890000094],
            [-6.447069457999959, 41.676242778000102],
            [-6.46081538999988, 41.676552836000099],
            [-6.467740030999977, 41.672056987000076],
            [-6.474354614999868, 41.665933330000115],
            [-6.488048868999897, 41.658801982000085],
            [-6.503035033999964, 41.653091737000125],
            [-6.513421996999881, 41.650921326000031],
            [-6.541533976999915, 41.6589053350001],
            [-6.555279906999942, 41.674899191000065],
            [-6.560499226999866, 41.694458721000075],
            [-6.563083048999886, 41.71303639700011],
            [-6.567682250999923, 41.72244150800006],
            [-6.574348510999926, 41.731045634000111],
            [-6.577397419999954, 41.739727275000064],
            [-6.571506306999964, 41.749209900000039],
            [-6.564374958999935, 41.757503968000094],
            [-6.560137491999882, 41.766883240000141],
            [-6.53574621599995, 41.848583679000058],
            [-6.524222371999912, 41.867419740000059],
            [-6.550060587999923, 41.873879293000101],
            [-6.567888956999894, 41.875868836000052],
            [-6.575795450999919, 41.882896831000068],
            [-6.567630574999924, 41.926072490000081],
            [-6.57166133599992, 41.9426089480001],
            [-6.585148885999956, 41.954649557000067],
            [-6.6094884849999, 41.962323507000036],
            [-6.622821003999917, 41.940955302000134],
            [-6.656772419999925, 41.933074647000097],
            [-6.727982543999872, 41.930852559000101],
            [-6.757903197999923, 41.937570496000035],
            [-6.794386759999924, 41.979712626000065],
            [-6.824927530999929, 41.983769226000078],
            [-6.831232055999919, 41.953951925000069],
            [-6.854951537999881, 41.942944845000085],
            [-6.917118285999976, 41.93909495000014],
            [-6.945126911999893, 41.943358257000085],
            [-6.96951818799991, 41.957698466000096],
            [-6.983456182778326, 41.962996165870265],
            [-6.983444180204742, 41.963175361053999],
            [-6.982720709792886, 41.973975734992138],
            [-6.987707484712644, 41.983380845849354],
            [-6.986467251262667, 41.989168606446015],
            [-6.983134120942907, 41.996713365329526],
            [-6.965305752058782, 42.028313504164856],
            [-6.966003384048918, 42.032964382300435],
            [-6.967501999917374, 42.035935777414238],
            [-6.977863125183035, 42.047924709757552],
            [-6.984761928919852, 42.051180324812094],
            [-7.005070767401975, 42.051025296080553],
            [-7.01269303975198, 42.052213854485842],
            [-7.019772712440698, 42.056244614996984],
            [-7.026283941650547, 42.061360582025202],
            [-7.028144293624251, 42.067975164921904],
            [-7.024656133673602, 42.075726630279775],
            [-7.011220262305216, 42.085751857861908],
            [-6.986932339256015, 42.11412221916504],
            [-6.979180873898173, 42.119496567712389],
            [-6.970809291815328, 42.122028714153799],
            [-6.962592739363487, 42.126162828351795],
            [-6.955590582839108, 42.132208971366936],
            [-6.944738532057642, 42.158357245490407],
            [-6.94057857943784, 42.165333564492357],
            [-6.932904629345131, 42.174118556825931],
            [-6.898410609761783, 42.200163479061274],
            [-6.882545945638867, 42.209594428340154],
            [-6.866758795881708, 42.217009996014554],
            [-6.84464128316867, 42.220885728693418],
            [-6.830456102067444, 42.220937405536773],
            [-6.81133582199061, 42.216596584864533],
            [-6.805470547028108, 42.217940172001278],
            [-6.802395799126856, 42.230807604387365],
            [-6.799424404013052, 42.238378200793306],
            [-6.783249680628217, 42.252873440257048],
            [-6.78324585761024, 42.252876028505881],
            [-6.705630595975151, 42.217179116223235],
            [-6.690557353239228, 42.213608559487454],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "ESP-5853",
        OBJECTID_1: 3491,
        diss_me: 5853,
        adm1_cod_1: "ESP-5853",
        iso_3166_2: "ES-",
        wikipedia: null,
        iso_a2: "ES",
        adm0_sr: 1,
        name: "Zaragoza",
        name_alt: null,
        name_local: null,
        type: "Comunidad Autónoma",
        type_en: "Autonomous Community",
        code_local: null,
        code_hasc: "ES.AR",
        note: null,
        hasc_maybe: null,
        region: "Aragón",
        region_cod: "ES.AR",
        provnum_ne: 0,
        gadm_level: 1,
        check_me: 20,
        scalerank: 3,
        datarank: 3,
        abbrev: null,
        postal: "AR",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 3,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 5,
        fips: "SP81",
        fips_alt: "SP52",
        woe_id: 12602107,
        woe_label: "Aragon, ES, Spain",
        woe_name: "Aragón",
        latitude: 41.581400000000002,
        longitude: -1.0538000000000001,
        sov_a3: "ESP",
        adm0_a3: "ESP",
        adm0_label: 2,
        admin: "Spain",
        geonunit: null,
        gu_a3: null,
        gn_id: 3104323,
        gn_name: "Provincia de Zaragoza",
        gns_id: -409150,
        gns_name: "Saragossa",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "ES.Z",
        region_sub: null,
        sub_code: null,
        gns_level: 2,
        gns_lang: null,
        gns_adm1: null,
        gns_region: "SP52",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.904750247725758, 42.249621744448007],
            [-0.892939945022476, 42.249841470407461],
            [-0.884315676826986, 42.255796060639653],
            [-0.877690920799324, 42.273506022085172],
            [-0.870791506422876, 42.279273844712151],
            [-0.861541017803688, 42.282569743099174],
            [-0.851620363389088, 42.283492595007203],
            [-0.842721436844812, 42.282141276398733],
            [-0.829043458089075, 42.275505534253028],
            [-0.822495606686914, 42.273846598941418],
            [-0.814167969076493, 42.275670328722725],
            [-0.808323242723446, 42.279196940986026],
            [-0.80408251929282, 42.283734294102345],
            [-0.772683592973493, 42.344950116954294],
            [-0.772705565209606, 42.354101728711782],
            [-0.782285643667507, 42.382523360364758],
            [-0.782549315898223, 42.389038253412622],
            [-0.780198241655455, 42.391850753609361],
            [-0.755391111210798, 42.391740890629592],
            [-0.750535154373893, 42.389730392343694],
            [-0.745470456796113, 42.385313888325271],
            [-0.740054197143422, 42.375294356149595],
            [-0.740570554587435, 42.340874188892656],
            [-0.731440915066145, 42.308574382901469],
            [-0.731550778945319, 42.29780778120417],
            [-0.734429196750028, 42.290710613104295],
            [-0.749568357093949, 42.273308268361831],
            [-0.757390623378541, 42.258454752484823],
            [-0.745107907703755, 42.100943759846672],
            [-0.747865476410567, 42.086540682906218],
            [-0.755786619556773, 42.072269442081023],
            [-0.767520018533986, 42.061250155170541],
            [-0.781714354733651, 42.056580965039473],
            [-0.789207031179529, 42.058569491089145],
            [-0.804620849872322, 42.075905917324178],
            [-0.811289551271756, 42.079828036134984],
            [-0.819199707400543, 42.082102206651513],
            [-0.827439454267392, 42.082552645588152],
            [-0.835140870554767, 42.081036532509955],
            [-0.842227052536401, 42.077553866517718],
            [-0.84811572426122, 42.072719881917038],
            [-0.856399416499869, 42.059679109703126],
            [-0.866067385701257, 42.027159577752286],
            [-0.864056887415359, 42.011438141457091],
            [-0.853685793164857, 41.996705474677839],
            [-0.778209715605868, 41.957308500586805],
            [-0.667994867968758, 41.955298002300992],
            [-0.652064691832038, 41.94581680070479],
            [-0.607119621972942, 41.888149562352424],
            [-0.553715078388137, 41.867605127574592],
            [-0.537433339277158, 41.855706934127653],
            [-0.524897938389103, 41.839831689480889],
            [-0.503079090527564, 41.754907369579456],
            [-0.497684803111184, 41.744481343838942],
            [-0.488774890448838, 41.735549458940397],
            [-0.380669417959268, 41.684682758102838],
            [-0.37333054986496, 41.678343646542004],
            [-0.35095139918775, 41.637210832513915],
            [-0.344194807044715, 41.631179337656306],
            [-0.313773663223913, 41.622752824083534],
            [-0.258303689863368, 41.578181288535632],
            [-0.245900125090657, 41.575478652218038],
            [-0.239231423691223, 41.576764052319163],
            [-0.209766091032265, 41.594572890626409],
            [-0.197362526259553, 41.595440811044597],
            [-0.185508277285123, 41.591035293144245],
            [-0.17490647005755, 41.581982558248512],
            [-0.167095190790405, 41.569018690660016],
            [-0.152351537893196, 41.528226452588683],
            [-0.117107395589841, 41.497365855949482],
            [-0.103264621465087, 41.4754151719726],
            [-0.06991012834979, 41.372890753605333],
            [-0.033094941478367, 41.38277844966575],
            [-0.014725800355336, 41.383404670089448],
            [-0.000037078947969, 41.37545056858886],
            [0.012234649709285, 41.363563360360814],
            [0.019112091849593, 41.360311407345591],
            [0.106134799881147, 41.350929082611117],
            [0.129118199645205, 41.38765637963823],
            [0.163791052115357, 41.406530892087005],
            [0.182687537699735, 41.394522834761034],
            [0.219030312498916, 41.403443733541508],
            [0.240651406637085, 41.428481576063888],
            [0.338305291056372, 41.405896715361138],
            [0.324197625220194, 41.390161241548014],
            [0.327298210643903, 41.370110785484286],
            [0.335204704733286, 41.368896389556738],
            [0.351741164223313, 41.362540188179167],
            [0.36507368280428, 41.352566637440361],
            [0.374013705667977, 41.338924058698382],
            [0.377269320722576, 41.321405748176687],
            [0.352361280948372, 41.279211940978314],
            [0.354273308866055, 41.256293443487664],
            [0.36037112782526, 41.232832342738419],
            [0.357838983182489, 41.230532742092322],
            [0.347917108387833, 41.225752671848255],
            [0.336444939982016, 41.221566880806932],
            [0.321097038897221, 41.213918769135915],
            [0.313345575338019, 41.206890774189816],
            [0.308849725034776, 41.19929433936224],
            [0.306989373061157, 41.192007961997859],
            [0.306524285067695, 41.183533027127424],
            [0.299651319752599, 41.164877835044052],
            [0.293140089643487, 41.158263251248002],
            [0.285078565923072, 41.153173123540824],
            [0.276241895846795, 41.149995021952719],
            [0.25841352696267, 41.146429348535662],
            [0.252212355215988, 41.141520087082313],
            [0.248491652168013, 41.136197415378376],
            [0.242393833208808, 41.132037461859397],
            [0.233712191864157, 41.130332140415959],
            [0.224410434693851, 41.130177109885665],
            [0.213713412643841, 41.125758774847583],
            [0.20890750487743, 41.119609279944314],
            [0.20658206491035, 41.111780300220602],
            [0.205186801829456, 41.104261379758896],
            [0.205341830560997, 41.096871650506188],
            [0.208132357622247, 41.08943024351089],
            [0.212473179193864, 41.084159246851755],
            [0.216503939705092, 41.0706200217966],
            [0.166955139568017, 41.065064814189782],
            [0.14346636937745, 41.067152216201833],
            [0.119186583394111, 41.076402704820936],
            [0.07245074210806, 41.077050898380207],
            [0.062541072912154, 41.073952753716554],
            [0.058322323516364, 41.071250117398961],
            [0.054543026039795, 41.067503779176036],
            [0.044743220723063, 41.0519141789961],
            [0.039645564791016, 41.048640252845388],
            [0.02103472367358, 41.054232293985137],
            [-0.016934027183595, 41.121721310789809],
            [-0.036204047978458, 41.134069944072678],
            [-0.268597855206792, 41.215028198892242],
            [-0.313202349109133, 41.255534792496633],
            [-0.337229449879203, 41.241461305394893],
            [-0.405861044551585, 41.272354861287852],
            [-0.43032759814011, 41.297260867694888],
            [-0.440237266436725, 41.314256717973137],
            [-0.446038047417971, 41.317618534867421],
            [-0.465132286725606, 41.318574345129917],
            [-0.470845177862657, 41.32223279260927],
            [-0.474130090131524, 41.327407353166819],
            [-0.479897911859126, 41.345402959978543],
            [-0.485588830759895, 41.351829962282949],
            [-0.493004602580328, 41.354345830995243],
            [-0.550507045563705, 41.352983526268702],
            [-0.562548062143321, 41.346457647102682],
            [-0.564382778942075, 41.336284306575436],
            [-0.559383998972379, 41.324660771477369],
            [-0.521766810190144, 41.276529666211133],
            [-0.509297327809463, 41.249964723593109],
            [-0.508737024993678, 41.244021119479072],
            [-0.510209192700074, 41.237308472707838],
            [-0.516724085747967, 41.227970094244455],
            [-0.524678187248526, 41.227190064569811],
            [-0.532566371141087, 41.232485475124577],
            [-0.601461637144837, 41.319618046135901],
            [-0.610206755337543, 41.324803593710868],
            [-0.619808806031557, 41.325990116950351],
            [-0.628927458535316, 41.320398075810516],
            [-0.631069792936557, 41.309928104698287],
            [-0.625499724033034, 41.248327760517697],
            [-0.642627410426627, 41.238374146849395],
            [-0.610052946086597, 41.191704223171314],
            [-0.694010469607491, 41.112569699032349],
            [-0.740273898821329, 41.156306272516389],
            [-0.751183322752155, 41.162601438705423],
            [-0.762191624443858, 41.16126110711437],
            [-0.772518773322759, 41.15185680924435],
            [-0.792371068270171, 41.10214367329192],
            [-0.80121506332452, 41.093607295839973],
            [-0.813926245699719, 41.090640987291664],
            [-0.823649146390949, 41.09291515690893],
            [-0.883535622870625, 41.130268674359797],
            [-0.938269511928411, 41.082434199678545],
            [-0.945685283748844, 41.079369014268622],
            [-0.954287578808675, 41.078995479058108],
            [-0.989015363668045, 41.092168086487931],
            [-0.995222639113507, 41.096892208108926],
            [-0.998694318987674, 41.103857540093372],
            [-1.000561994140696, 41.114843868649587],
            [-1.086497056693844, 41.159250608828586],
            [-1.100823229008768, 41.161118284880871],
            [-1.106085680309889, 41.159074827341442],
            [-1.110348375976741, 41.155581175231077],
            [-1.122455310164469, 41.136421018315332],
            [-1.158688221084589, 41.122292599723664],
            [-1.168136463427032, 41.121886105259534],
            [-1.214388907422119, 41.137146115600757],
            [-1.235977042306644, 41.136728635018471],
            [-1.246875480119286, 41.13387218944996],
            [-1.254719719539366, 41.128170285330441],
            [-1.266508050006507, 41.107900508901366],
            [-1.267430901015274, 41.10094616303499],
            [-1.263585685930622, 41.090959591012336],
            [-1.257444328992619, 41.081258662557218],
            [-1.254016594490253, 41.07139293963246],
            [-1.25834520776516, 41.060922968520146],
            [-1.270188469722086, 41.054177362495295],
            [-1.329207026682923, 41.054649774567423],
            [-1.358639400987528, 41.070382196980859],
            [-1.369746578641667, 41.07324962866744],
            [-1.381732663731384, 41.073260615684859],
            [-1.393345212711381, 41.070261347882933],
            [-1.403331785633327, 41.06405407153818],
            [-1.409121580496532, 41.056594355245323],
            [-1.416965819017321, 41.039719354064914],
            [-1.42266772313684, 41.032479363731596],
            [-1.42944628841542, 41.028579217157102],
            [-1.452418701162088, 41.022075310227365],
            [-1.458208496025264, 41.01390148186789],
            [-1.456648437575296, 41.002937126447222],
            [-1.448276855492452, 40.981338004545279],
            [-1.449221679636793, 40.970186880620048],
            [-1.45516528375083, 40.959255483553761],
            [-1.464382813116288, 40.951279409816891],
            [-1.475116456459318, 40.949016225418518],
            [-1.494353517101104, 40.955278433253113],
            [-1.526774173089564, 40.953180044223643],
            [-1.555942875163538, 40.943786733371041],
            [-1.621885138088658, 40.946751613795925],
            [-1.656353319250286, 40.970109360858316],
            [-1.662606166941032, 40.976413886291851],
            [-1.673070644994255, 40.984501248433872],
            [-1.67927181584156, 40.990754096124618],
            [-1.732808600236666, 41.026023261164568],
            [-1.747303839700436, 41.038813178285523],
            [-1.753065761875376, 41.045505276447358],
            [-1.756398892195108, 41.052274888974978],
            [-1.761101447174042, 41.058553575087416],
            [-1.767328457342444, 41.064987290830771],
            [-1.81691199379344, 41.096975003293778],
            [-1.850424159647247, 41.111134345073992],
            [-1.891171026921313, 41.135163885704713],
            [-1.916234097225782, 41.143018703850146],
            [-1.928868983816528, 41.150150051583637],
            [-1.938661668301279, 41.157229723373064],
            [-1.944862840047961, 41.164412747050619],
            [-1.971863775792627, 41.169942125228857],
            [-2.053228319131563, 41.15035675715859],
            [-2.061806605890183, 41.158624986453958],
            [-2.083743252349166, 41.171879991568346],
            [-2.092347378428769, 41.174825548260429],
            [-2.101752489285929, 41.176970120174872],
            [-2.111286789553702, 41.176866767387395],
            [-2.129657761696336, 41.179166368033378],
            [-2.138236050253511, 41.181465969578852],
            [-2.145057338725195, 41.186581935707807],
            [-2.149139776979126, 41.193945828337348],
            [-2.151336024837633, 41.231282050026607],
            [-2.159216681404757, 41.269212550918439],
            [-2.16146460610662, 41.275646267561143],
            [-2.165417853151439, 41.280943101742736],
            [-2.173350185662542, 41.294585680484715],
            [-2.171877407316515, 41.327761949554372],
            [-2.166244676350686, 41.34595205274502],
            [-2.159836799029051, 41.353186754165364],
            [-2.153919847223108, 41.357036648422536],
            [-2.147072720329817, 41.35889700039624],
            [-2.140148078171279, 41.362695216910708],
            [-2.133946906424598, 41.36910309513172],
            [-2.127952440252898, 41.376751206802709],
            [-2.122371385231219, 41.399333808408585],
            [-2.120976121250948, 41.408273831272254],
            [-2.120769415675966, 41.415999457309113],
            [-2.119580858170053, 41.423673408301113],
            [-2.116273566272014, 41.431373195916251],
            [-2.110950893668814, 41.437884426025391],
            [-2.099711270158963, 41.442612820325394],
            [-2.069506395303904, 41.439357205270795],
            [-2.052117275092229, 41.434990546176749],
            [-2.045218472254703, 41.430753079191277],
            [-2.040981004369854, 41.424887804228746],
            [-2.039353197292257, 41.417678941230122],
            [-2.039120653295498, 41.41010834392489],
            [-2.040567593219805, 41.401865953051242],
            [-2.040825974738937, 41.394734605317751],
            [-2.03741533005342, 41.38876597846712],
            [-2.030619880003457, 41.385432848147389],
            [-2.022196621077171, 41.385381171304033],
            [-2.014057582991029, 41.388068346476985],
            [-1.996048346054351, 41.397990221271726],
            [-1.979847785147172, 41.404940700952608],
            [-1.970132615927497, 41.407291979341295],
            [-1.960133225867708, 41.40879059520978],
            [-1.951787482206555, 41.411658637536078],
            [-1.945948044766396, 41.415534369315679],
            [-1.952846849402562, 41.457831529301643],
            [-1.956179978822945, 41.466823229008838],
            [-1.961916062576194, 41.473747870267999],
            [-1.969124924675526, 41.47979401328314],
            [-1.975274421377435, 41.49113699047976],
            [-1.978710902686004, 41.507621772226969],
            [-1.975920375624867, 41.542658393270244],
            [-1.981940680218202, 41.570796210576702],
            [-1.987909307968181, 41.575188707193092],
            [-1.990234747935261, 41.58175161414573],
            [-1.990777350294479, 41.588547065094957],
            [-1.98827104317408, 41.595497544775924],
            [-1.980700445868848, 41.60190542299685],
            [-1.975145229268946, 41.605212713995513],
            [-1.969460822359054, 41.60771902111594],
            [-1.96455156090579, 41.60867503552447],
            [-1.95858293315581, 41.608726712367883],
            [-1.950521410334773, 41.605806993198172],
            [-1.932124599770418, 41.601130275741582],
            [-1.9235204736909, 41.602990627715286],
            [-1.917035081104132, 41.607408962753397],
            [-1.910937262144927, 41.614152736859296],
            [-1.893961553982564, 41.62944896110065],
            [-1.874789597961581, 41.636942044040126],
            [-1.865642868623468, 41.643039862999302],
            [-1.854299893225487, 41.652600003487379],
            [-1.844352179109762, 41.65686330799528],
            [-1.834301113105823, 41.658232734453122],
            [-1.824973516614534, 41.658026027978764],
            [-1.815878465019125, 41.661669215761691],
            [-1.807326015783048, 41.670428372371504],
            [-1.799419520794174, 41.687171536537193],
            [-1.79270158511008, 41.696886704857576],
            [-1.788179897284408, 41.705749213355517],
            [-1.785544398954869, 41.719288438410643],
            [-1.79595720106397, 41.7287452261113],
            [-1.80264929922572, 41.731819973113289],
            [-1.808540411710595, 41.737504380922445],
            [-1.810788337311891, 41.744919949496051],
            [-1.818901536076964, 41.757813219404483],
            [-1.83546383308942, 41.777036852268878],
            [-1.842465989613714, 41.783134671228083],
            [-1.84585079587751, 41.790731106055659],
            [-1.842672695188753, 41.799567776131937],
            [-1.831820645306493, 41.81429555869309],
            [-1.825903692601344, 41.820574245704819],
            [-1.821562872828309, 41.827834785546884],
            [-1.817764655414464, 41.836051337099548],
            [-1.815826789075089, 41.844216214506702],
            [-1.816033493750751, 41.851683458125081],
            [-1.836884934592092, 41.874085190778942],
            [-1.851586879630815, 41.906150418506996],
            [-1.857736375433348, 41.954803778971268],
            [-1.848176234945299, 41.978187364455266],
            [-1.844533047162372, 41.982037257813147],
            [-1.852413702830063, 42.001209214733507],
            [-1.841277432107773, 42.001881008301922],
            [-1.838667772199869, 42.000847480426813],
            [-1.83639400907623, 42.000279038746584],
            [-1.834611172367744, 41.99890961408741],
            [-1.823888311896098, 41.99464630778084],
            [-1.81484493714413, 41.995266425405219],
            [-1.776578537669309, 41.992992662281466],
            [-1.767716030070744, 41.99061554637035],
            [-1.759499477618817, 41.986998196109852],
            [-1.752135585888567, 41.981417141088173],
            [-1.739604051186006, 41.969299018434924],
            [-1.732033453880774, 41.964673976922484],
            [-1.723610194954489, 41.960591539567901],
            [-1.704179857414516, 41.955062161389662],
            [-1.694645555348075, 41.953305162203407],
            [-1.6849303861284, 41.95397695577185],
            [-1.666430222776569, 41.960333157149506],
            [-1.657154303128635, 41.962167669802142],
            [-1.639403448610267, 41.957594306032405],
            [-1.621936814932099, 41.951238105554097],
            [-1.614211187995863, 41.947000636769985],
            [-1.607286545837439, 41.941884671540294],
            [-1.596356980690103, 41.930154119816706],
            [-1.589432339430857, 41.925193183318754],
            [-1.573748541561912, 41.916744085970777],
            [-1.555946011099479, 41.911602281420102],
            [-1.546644253029825, 41.910672105433292],
            [-1.537135789385133, 41.910878811008274],
            [-1.518325567670843, 41.913410956550393],
            [-1.503003505907031, 41.919999701025375],
            [-1.497887538878814, 41.92162750900232],
            [-1.490704515201173, 41.92266103777672],
            [-1.483288946627567, 41.922170112260829],
            [-1.454401821386909, 41.915193793258965],
            [-1.444738328111384, 41.914392809380644],
            [-1.426393195289734, 41.910982163795751],
            [-1.419287685977963, 41.908863430303001],
            [-1.414662645364814, 41.91082713506421],
            [-1.409029914398957, 41.915736396517474],
            [-1.403423020955671, 41.925451564837886],
            [-1.393992071676792, 41.932737942202266],
            [-1.38314002089524, 41.943693345771322],
            [-1.380659552196533, 41.953124295050173],
            [-1.374510057293293, 41.960462348358618],
            [-1.361177536913857, 41.983768419476945],
            [-1.342987432823747, 42.006971136908447],
            [-1.322368536878457, 42.027770900906347],
            [-1.315960658657445, 42.037150174241162],
            [-1.312446662083801, 42.054280911135066],
            [-1.316451585072599, 42.062755846005473],
            [-1.323918829590269, 42.06725169540934],
            [-1.333349778869206, 42.067458400984407],
            [-1.3424189929421, 42.069215400170549],
            [-1.350661382916456, 42.072729396744279],
            [-1.356707525931597, 42.078672186971829],
            [-1.361745977694682, 42.085338446711972],
            [-1.364355637602586, 42.092237250448676],
            [-1.368515591121593, 42.098774318979622],
            [-1.373424851675566, 42.104691270785565],
            [-1.38717078320505, 42.114742336789419],
            [-1.392467617386615, 42.120788478905183],
            [-1.394224615673494, 42.12758392985441],
            [-1.393707851735968, 42.141614081324718],
            [-1.394767218032712, 42.149623928201777],
            [-1.39533565881365, 42.169416001846997],
            [-1.396627570006331, 42.177968451083103],
            [-1.400425788319438, 42.185151476559383],
            [-1.405412563239196, 42.191533515459327],
            [-1.41114864699253, 42.197579658474382],
            [-1.415437790821329, 42.204891873361305],
            [-1.41703976127593, 42.211945705829663],
            [-1.416419643651579, 42.220575670330902],
            [-1.412543910972687, 42.230187485863809],
            [-1.404895799301698, 42.244269314177444],
            [-1.400865036991888, 42.253906969031362],
            [-1.395852422751062, 42.269771633154392],
            [-1.394999762029386, 42.277161363306277],
            [-1.390891486253111, 42.287289944575264],
            [-1.384018520938014, 42.300415758480455],
            [-1.364123093605826, 42.323153387918495],
            [-1.334486660430997, 42.349870103722338],
            [-1.331024338902125, 42.357983303386703],
            [-1.33340145481327, 42.364132799189321],
            [-1.338827480204031, 42.369610501423523],
            [-1.345493739944175, 42.372943630843906],
            [-1.349111090204644, 42.37917064101228],
            [-1.350377162975633, 42.386792914261576],
            [-1.348051723907929, 42.397386583524025],
            [-1.341953904948724, 42.414827378780473],
            [-1.320663215435019, 42.440923976959965],
            [-1.294411586725346, 42.459837551461817],
            [-1.279477097689835, 42.476994126777527],
            [-1.272681646740608, 42.487639471984011],
            [-1.27368933799255, 42.494770819717502],
            [-1.275756394641888, 42.498491522765562],
            [-1.280329759310973, 42.504770208878028],
            [-1.283042772006354, 42.510118719903033],
            [-1.284515550352324, 42.51657827406811],
            [-1.282190111284592, 42.524174709794949],
            [-1.278159348974725, 42.532029527041004],
            [-1.271493089234696, 42.541537990685725],
            [-1.265007696647899, 42.548462632844235],
            [-1.257411260920946, 42.553216865565872],
            [-1.246042446201898, 42.553371894297527],
            [-1.228033210164512, 42.549392808831072],
            [-1.219661628081667, 42.550633043180426],
            [-1.212013516410678, 42.553888658234939],
            [-1.205347255771244, 42.558100286798776],
            [-1.203047655125175, 42.566601060990109],
            [-1.195761277760766, 42.57533437827891],
            [-1.184960902923365, 42.584558621083517],
            [-1.160543788664882, 42.596082465433398],
            [-1.154781867389204, 42.601792710765011],
            [-1.153283249722193, 42.605435899447201],
            [-1.168915370747726, 42.611094468834665],
            [-1.172326016332619, 42.61347158474581],
            [-1.165530565383278, 42.615047715879399],
            [-1.155531175323489, 42.618639227718177],
            [-1.154523484970809, 42.623600165115505],
            [-1.154290940974136, 42.629336248868725],
            [-1.151655442644625, 42.635924994243084],
            [-1.145015021326117, 42.641376858055594],
            [-1.08522538924035, 42.647009589021337],
            [-1.075665248752216, 42.645355943521963],
            [-1.055485602378667, 42.645355943521963],
            [-1.044452684443854, 42.649955145713392],
            [-1.040266893402446, 42.65584625909753],
            [-1.034995896743197, 42.669178779477079],
            [-1.033703985550517, 42.676258450367214],
            [-1.034375780018223, 42.683079738838813],
            [-1.031662767322814, 42.690107733784913],
            [-1.024634772376828, 42.696128038378248],
            [-0.977118292575142, 42.705714016388725],
            [-0.964509242607448, 42.710287381057725],
            [-0.951745163908129, 42.716695258379474],
            [-0.924976772160306, 42.735582994459605],
            [-0.904357876215016, 42.742559313461555],
            [-0.911309086145422, 42.715266293204849],
            [-0.909199710997882, 42.706850765750204],
            [-0.88298633135409, 42.688833186702198],
            [-0.879822269082439, 42.679121272129009],
            [-0.884546389803972, 42.669101740852625],
            [-0.894521976607962, 42.664652277580473],
            [-0.916956058775099, 42.659543634631007],
            [-0.922163578586208, 42.655797296408082],
            [-0.924690434316119, 42.651446710896948],
            [-0.924668462079808, 42.646436944809096],
            [-0.904827152351174, 42.613104423929997],
            [-0.896763186971469, 42.494265308097482],
            [-0.888831057707108, 42.471831225031053],
            [-0.888380618770555, 42.46504167453358],
            [-0.889852786476894, 42.458416918506003],
            [-0.901003910402096, 42.439992844993753],
            [-0.901289554869038, 42.435982835439489],
            [-0.898191410205357, 42.43261003242705],
            [-0.842270997908287, 42.407484299161098],
            [-0.83497607518575, 42.387928633899293],
            [-0.834690430718751, 42.380677657447933],
            [-0.838909181013889, 42.368713545493733],
            [-0.922482182306851, 42.27163834693215],
            [-0.922855717517365, 42.262310953687546],
            [-0.915978276276405, 42.254148111446199],
            [-0.904750247725758, 42.249621744448007],
          ],
          [
            [-1.163928594928677, 42.440872301015901],
            [-1.173023647423378, 42.435911364517864],
            [-1.186252814116045, 42.430950426221159],
            [-1.194521043411413, 42.424335842425194],
            [-1.197001512110035, 42.41193349893183],
            [-1.190386928314155, 42.40283844643713],
            [-1.176330939321417, 42.403665270535839],
            [-1.165582241327343, 42.41193349893183],
            [-1.158140835231393, 42.425989487924483],
            [-1.158140835231393, 42.436738185918529],
            [-1.163928594928677, 42.440872301015901],
          ],
          [
            [-1.075458543177263, 42.432604071720533],
            [-1.067190313881895, 42.436738185918529],
            [-1.067190313881895, 42.445833238413144],
            [-1.07463171997793, 42.450794175810557],
            [-1.085380417971891, 42.459062405105925],
            [-1.094475469567215, 42.463196519303921],
            [-1.095302293665924, 42.474772040497299],
            [-1.098609584664587, 42.482213447492626],
            [-1.106050990760622, 42.483040269792582],
            [-1.112665574556587, 42.482213447492626],
            [-1.124241095749994, 42.473118394098634],
            [-1.12837520994799, 42.466503811201932],
            [-1.133336147345318, 42.456581936407218],
            [-1.134162971443914, 42.446660062511825],
            [-1.123414272550576, 42.440045477816483],
            [-1.108531460358591, 42.436738185918529],
            [-1.087034064370556, 42.433430894919866],
            [-1.075458543177263, 42.432604071720533],
          ],
        ],
      },
    },
  ],
};
