import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useState } from "react";

import { generateURL, getParams } from "../../../utils/url";

import AppContext from "../../../context/AppContext";
import Page from "../../global/structure/Page";
import Tabs from "../../global/structure/Tabs";

import PayCardsTab from "./PayCardTabs/PayCardsTab";
import TokenizedPayCardsTab from "./PayCardTabs/TokenizedPayCardsTab";

import {
  F_INVOICE_SERIES_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  ISSUED_INVOICE_STATE_ID,
  PAID_INVOICE_STATE_ID,
  EXPIRED_INVOICE_STATE_ID,
  UNPAID_INVOICE_STATE_ID,
  BILLING_ERROR_STATE_ID,
} from "../../../data/constants";
import BankReconciliation from "./PayCardTabs/BankReconciliation";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PaycardsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("invoices");
  const history = useHistory();
  const query = useQuery();

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "customerId", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "paymentDay", type: "number" },
    { name: "state", type: "array" },
    { name: "tab", type: "number" },
  ];

  const initialTabValue = getParams(query, FILTERS).tab;

  const [tab, setTab] = useState(initialTabValue ?? 0);

  const getInvoices = (filters, setLoading, onSuccess) => {
    const filtersForUrl = { ...filters };
    delete filtersForUrl.paymentMethodId;
    //Change url parameters:
    const url = generateURL("/app/pay-cards", {
      ...filtersForUrl,
      tab: tab,
    });
    history.push(url);

    setLoading && setLoading(true);

    let params = {
      include: [
        "Contract",
        "Customer",
        "Merchantable",
        "InvoiceItem",
        "PayCard",
        "IBAN",
        "NonPayment",
        "State",
      ],
      serieId: F_INVOICE_SERIES_ID,
      paymentMethodId: PAYCARD_PAYMENT_METHOD_ID,
    };

    filters.customerId !== "" && (params.customerId = filters.customerId);
    filters.dateFrom !== "" && (params.dateFrom = filters.dateFrom);
    filters.dateUntil !== "" && (params.dateUntil = filters.dateUntil);
    filters.state !== "" && (params.state = filters.state);
    filters.paymentDay !== "" && (params.dayOfPayment = filters.paymentDay);
    filters.paymentMethodId !== undefined &&
      (params.paymentMethodId = filters.paymentMethodId);
    params.types = [F_INVOICE_SERIES_ID];

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noInvoices"), { variant: "warning" });
          onSuccess && onSuccess(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  const getCustomers = (filters, onSuccess) => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          onSuccess && onSuccess(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = (filters, onSuccess) => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          onSuccess && onSuccess(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = (filters, onSuccess) => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          onSuccess && onSuccess(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getNonPaymentReasons = (filters, onSuccess) => {
    api
      .get("/non-payments/reasons")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          onSuccess && onSuccess(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editInvoice = (id, paidAt, onSuccess) => {
    let data = {};

    paidAt ? (data.paidAt = null) : (data.paidAt = new Date());

    api
      .post("/invoices/edit/" + id, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("invoiceEditSuccess"), { variant: "success" });
          onSuccess && onSuccess(data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createNonPayment = (form, selectedInvoiceId, setLoading, onSuccess) => {
    setLoading && setLoading(true);
    let data = {
      nonPayment: {
        nonPaymentDate: form.date,
        reason: form.reason,
        comments: form.comment,
        invoiceId: selectedInvoiceId,
      },
    };

    api
      .post("/non-payments/create", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentCreatedSuccessfully"), {
            variant: "success",
          });
          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  const getPaymentMethodTranslation = (paymentMethodName) => {
    const paymentMethodMap = {
      CONTADO: t("cash"),
      RECIBO: t("receipt"),
      TARJETA: t("card"),
      TRANSFERENCIA: t("transfer"),
      CONSOLIDACION: t("consolidation"),
      TARJETA_TOKENIZADA: t("tokenizedCard"),
    };
    return paymentMethodMap[paymentMethodName]
      ? paymentMethodMap[paymentMethodName]
      : "PaymentMethod not found";
  };

  const addComment = (comment = null, selectedInvoice, onSuccess) => {
    if (!comment)
      return enqueueSnackbar(t("commentCannotBeEmpty"), { variant: "warning" });

    const customerId = selectedInvoice.Customer.id;
    const form = { text: comment };
    api
      .post("/customers/" + customerId + "/comment", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("commentAddedSuccessfully"), {
            variant: "success",
          });
          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    const url = generateURL("/app/pay-cards", { tab: newValue });
    history.push(url);
  };

  const getStateText = (state) => {
    switch (state) {
      case ISSUED_INVOICE_STATE_ID:
        return t("issued");
      case PAID_INVOICE_STATE_ID:
        return t("paid");
      case EXPIRED_INVOICE_STATE_ID:
        return t("expired");
      case UNPAID_INVOICE_STATE_ID:
        return t("unpaid");
      case BILLING_ERROR_STATE_ID:
        return t("billingError");
      default:
        return "-";
    }
  };

  const functions = {
    addComment,
    createNonPayment,
    editInvoice,
    getCustomers,
    getInvoices,
    getNonPaymentReasons,
    getPaymentDays,
    getPaymentMethods,
    getPaymentMethodTranslation,
    getStateText,
  };

  return (
    <Page
      maxWidth={"lg"}
      title={t("paycardCharges")}
      browserTitle={t("paycardCharges")}
    >
      <Tabs
        currentTab={tab}
        onTabChange={handleTabChange}
        tabs={[
          {
            label: t("payCards"),
            content: <PayCardsTab functions={functions} key="paycards" />,
          },
          {
            label: t("tokenizedPayCards"),
            content: (
              <TokenizedPayCardsTab
                functions={functions}
                key="tokenizedPaycards"
              />
            ),
          },
          ...(user.hasAction("RECONCILE_PAYCARD_CHARGES")
            ? [
                {
                  label: t("bankReconciliation"),
                  content: <BankReconciliation key="bankReconciliation" />,
                },
              ]
            : []),
          ,
        ]}
      />
    </Page>
  );
}
