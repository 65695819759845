import { Box } from "@mui/material";
import { useEffect, useReducer, useContext } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../../context/AppContext";
import CenterReportContext from "./CenterReportContext";

import CenterSelect from "../../../Inputs/CenterSelect";
import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import { getMonthsFromPeriodicityId } from "../../../../utils/date";
import { getActiveContractMonths } from "../../../../utils/chartUtils";

import ContractsTab from "./CenterReportTabs/ContractsTab";
import CustomersTab from "./CenterReportTabs/CustomersTab";
import ExpensesTab from "./CenterReportTabs/ExpensesTab";
import InvoicingTab from "./CenterReportTabs/InvoicingTab";
import LocationsTab from "./CenterReportTabs/LocationsTab";
import MarketingTab from "./CenterReportTabs/MarketingTab";
import NonPaymentsTab from "./CenterReportTabs/NonPaymentsTab";
import OccupancyTab from "./CenterReportTabs/OccupancyTab";
import PerformanceTab from "./CenterReportTabs/PerformanceTab";
import PnLTab from "./CenterReportTabs/PnLTab";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ACQUISITION_ASSET":
      return { ...state, acquisitionAsset: action.payload };
    case "SET_BOX_CLUSTERS":
      return { ...state, boxClusters: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_CENTER":
      return { ...state, center: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_INVOICE_SERIES":
      return { ...state, invoiceSeries: action.payload };
    case "SET_MERCHANTABLE_TYPES":
      return { ...state, merchantableTypes: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PROVIDER_INVOICES":
      return { ...state, providerInvoices: action.payload };
    case "SET_INVOICE_ITEMS_NON_PAYMENTS_RECUPERATIONS_RECEIPTS":
      return {
        ...state,
        invoiceItems: action.payload.invoiceItems,
        nonPayments: action.payload.nonPayments,
        recuperations: action.payload.recuperations,
        receipts: action.payload.receipts,
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error(
        "Error using reducer, action type not found:",
        action.type
      );
  }
};

const initialState = {
  acquisitionAsset: null,
  boxClusters: [],
  boxes: [],
  center: { Boxes: [] },
  contracts: [],
  customers: [],
  filters: { center: "" },
  invoiceItems: [],
  invoiceSeries: [],
  merchantableTypes: [],
  paymentMethods: [],
  providerInvoices: [],
  nonPayments: [],
  recuperations: [],
  receipts: [],
};

export const CenterReportPanel = () => {
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getMerchantableTypes();
    getInvoiceSeries();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (state.filters.center) {
      getCenter();
      getContracts();
      getBoxes();
      getBoxClusters();
      getInvoiceItems();
      getCustomers();
      getProviderInvoices();
    }
  }, [state.filters.center]);

  useEffect(() => {
    getAcquisitionAsset();
  }, [state.center]);

  const getCenter = () => {
    if (state.filters.center === "") return;
    let params = {
      /*include: [
          "Box",
          "Contract",
          "AcquisitionAsset",
          "AcquisitionAssetCosts",
          "BoxCluster",
        ],*/
    };

    api
      .get("/centers/" + state.filters.center, { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else dispatch({ type: "SET_CENTER", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Centers: " + error.toString(), { variant: "error" })
      );
  };

  const getBoxes = () => {
    if (state.filters.center === "") return;
    let params = {
      include: ["Contract"],
      centers: [state.filters.center],
    };

    api
      .get("/boxes", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else dispatch({ type: "SET_BOXES", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Boxes: " + error.toString(), { variant: "error" })
      );
  };

  const getCustomers = () => {
    if (state.filters.center === "") return;
    api
      .get("/customers", {
        params: {
          centerId: state.filters.center,
          hasActiveContract: true,
          include: ["Contract", "Box"],
        },
      })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else dispatch({ type: "SET_CUSTOMERS", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Customers: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getBoxClusters = () => {
    if (state.filters.center === "") return;

    api
      .get("/centers/" + state.filters.center + "/box-clusters")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else dispatch({ type: "SET_BOX_CLUSTERS", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Box clusters: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getContracts = () => {
    if (state.filters.center === "") return;

    let params = {
      centerId: [state.filters.center],
      include: ["Box", "Customer"],
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          const today = new Date();
          dispatch({
            type: "SET_CONTRACTS",
            payload: response.data.map((c) => ({
              ...c,
              contractDuration: calculateContractDuration(c),
              finalPrice:
                c.pricePerMeter *
                c.meters *
                (1 + c.taxes / 100) *
                getMonthsFromPeriodicityId(c.periodicity),
              activeMonths: getActiveContractMonths(c),
              notFormattedStartDate: new Date(c.startDate),
              notFormattedEndDate: c.endDate ? new Date(c.endDate) : today,
            })),
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar("Error getting contracts: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getAcquisitionAsset = () => {
    if (!state.center.acquisitionAssetId) return;

    api
      .get("/acquisitions/" + state.center.acquisitionAssetId)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else
          dispatch({ type: "SET_ACQUISITION_ASSET", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Acquisition asset: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getInvoiceItems = () => {
    api
      .get("/invoice-items/" + state.filters.center, {
        params: { includeReceiptsCommissionCosts: true },
      })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          const nonPayments = [];
          const recuperations = [];
          const receipts = [];
          response.data.forEach((item) => {
            item.Invoice.Receipts.forEach((receipt) => {
              if (receipt.ReceiptReturnedGroup?.BankingTransactions) {
                receipt.ReceiptReturnedGroup.BankingTransactions.forEach(
                  (bankingTransaction) => {
                    if (
                      bankingTransaction.concept.startsWith(
                        "GASTOS IMPAG.DÉBITOS DIR.SEPA"
                      )
                    )
                      receipt.commissionCost =
                        bankingTransaction.import /
                        1.21 /
                        receipt.ReceiptReturnedGroup.Receipts.length;
                  }
                );
              } else receipt.commissionCost = null;
              receipts.push({
                ...receipt,
              });
            });
            if (item.Invoice?.NonPayments) {
              item.Invoice.NonPayments.forEach((nonPayment) => {
                const element = {
                  ...nonPayment,
                  amount: item.units * item.pricePerUnit,
                  invoiceItemId: item.id,
                  invoiceId: item.Invoice.id,
                  invoiceNumber: item.Invoice.number,
                  concept: item.concept,
                  invoiceSerie: item.Invoice.InvoiceSerie.name,
                  nonPaymentDate: nonPayment.nonPaymentDate,
                  recuperationDate: nonPayment.recuperationDate,
                };
                nonPayments.push({
                  ...element,
                  date: nonPayment.nonPaymentDate,
                });
                if (nonPayment.recuperationDate)
                  recuperations.push({
                    ...element,
                    date: nonPayment.recuperationDate,
                  });
              });
            }
          });

          dispatch({
            type: "SET_INVOICE_ITEMS_NON_PAYMENTS_RECUPERATIONS_RECEIPTS",
            payload: {
              invoiceItems: response.data,
              nonPayments: nonPayments,
              recuperations: recuperations,
              receipts: receipts,
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar("Invoice items: " + error.toString(), {
          variant: "error",
        });
      });
  };

  const getProviderInvoices = () => {
    const params = {
      include: ["Center", "Provider", "ExpenseType"],
      centerId: [state.filters.center],
    };
    user.hasAction("VIEW_PROVIDER_INVOICES") &&
      api
        .get("/provider-invoices", { params })
        .then((response) => {
          if (response.data.error)
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          else {
            response.data = response.data.map((invoice) => {
              invoice.CenterProviderInvoices =
                invoice.CenterProviderInvoices.filter(
                  (center) => center.centerId == state.filters.center
                );
              invoice.centerAmount = invoice.CenterProviderInvoices.reduce(
                (sum, center) => sum + center.amount,
                0
              );
              return invoice;
            });
            dispatch({
              type: "SET_PROVIDER_INVOICES",
              payload: response.data,
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.toString(), { variant: "error" })
        );
  };

  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_MERCHANTABLE_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else dispatch({ type: "SET_INVOICE_SERIES", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <CenterReportContext.Provider value={state}>
      <Page paper={true}>
        <Box marginTop={1}>
          <Box marginBottom={2}>
            <CenterSelect
              value={state.filters.center}
              onChange={handleChangeFilter}
              name="center"
            />
          </Box>
          <Tabs
            tabBottomMargin={1}
            tabs={[
              {
                label: t("performance"),
                content: <PerformanceTab />,
              },
              {
                label: t("occupancy"),
                content: <OccupancyTab />,
              },
              {
                label: t("customers"),
                content: <CustomersTab />,
              },
              {
                label: t("locations"),
                content: <LocationsTab />,
              },
              {
                label: t("marketing"),
                content: <MarketingTab />,
              },
              {
                label: t("expenses"),
                content: <ExpensesTab />,
              },
              {
                label: t("invoicing"),
                content: <InvoicingTab />,
              },
              {
                label: t("nonPayments"),
                content: <NonPaymentsTab />,
              },
              {
                label: t("contracts"),
                content: <ContractsTab />,
              },
              { label: t("pnl"), content: <PnLTab /> },
            ]}
          />
        </Box>
      </Page>
    </CenterReportContext.Provider>
  );
};

const calculateContractDuration = (contract) => {
  const startDate = new Date(contract.startDate);
  const endDate = contract.endDate ? new Date(contract.endDate) : new Date();

  const durationInMilliseconds = endDate - startDate;
  const durationInDays = Math.ceil(
    durationInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return durationInDays;
};
