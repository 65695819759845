import { Box, Button, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import RectangleForm from "./ItemForms/RectangleForm";
import ImageForm from "./ItemForms/ImageForm";
import CameraForm from "./ItemForms/CameraForm";
import TextForm from "./ItemForms/TextForm";
import PolygonForm from "./ItemForms/PolygonForm";
import DoorForm from "./ItemForms/DoorForm";

const TileEditor = (props) => {
  const { item } = props;
  const [t] = useTranslation("floorPlans");

  const ITEM_FORMS = {
    Image: <ImageForm item={item} />,
    Rect: <RectangleForm item={item} />,
    Polygon: <PolygonForm item={item} />,
    Text: <TextForm item={item} />,
    Door: <DoorForm item={item} />,
    Camera: <CameraForm item={item} />,
  };

  return <Container sx={{ paddingY: 2 }}>{ITEM_FORMS[item.shape]}</Container>;
};

TileEditor.propTypes = {
  item: PropTypes.object,
};

export default TileEditor;
