import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  Radio,
  RadioGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MailIcon from "@mui/icons-material/Mail";
import Phone from "@mui/icons-material/Phone";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import StoreIcon from "@mui/icons-material/Store";
import DescriptionIcon from "@mui/icons-material/Description";

import AppContext from "../../../context/AppContext";
import CenterCompanySelect from "../../Inputs/CenterCompanySelect";
import CenterMap from "../../CenterMap";
import CenterTypeSelect from "../../Inputs/CenterTypeSelect";
import CityInput from "../../Inputs/CityInput";
import ConfirmDialog from "../../ConfirmDialog";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";

import TextEditor from "./TextEditor";
import TextInput from "../../Inputs/TextInput";

import mapToken from "../../../data/mapboxToken";
import axios from "axios";

const initialState = {
  form: {
    acquisitionDate: "",
    active: 0,
    address: "",
    canon: "",
    canonPaid: "",
    city: "",
    comments: "",
    contractSignedAt: "",
    defaultRoyalty: "",
    emails: [],
    latitude: "",
    longitude: "",
    name: "",
    type: "",
    phones: [],
    postalCode: "",
    openingDate: "",
    centerCompanyId: "",

    businessName: "",
    cif: "",
    contractTypes: [],
    defaultContractType: "",
    defaultIssuer: "",
    issuers: [],
  },
  inputError: {
    name: false,
    address: false,
    postalCode: false,
    contractSignedAt: false,
    openingDate: false,
    city: false,
    longitude: false,
    latitude: false,
    defaultRoyalty: false,
    canon: false,
    canonPaid: false,
  },
  emailModal: { isOpen: false },
  newEmail: { email: "", contact: "" },
  phoneModal: { isOpen: false },
  newPhone: { number: "", contact: "" },
  id: "",
  cities: [],
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  contractTypes: [],
  issuers: [],
  saved: true,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_COORDINATES":
      return {
        ...state,
        form: {
          ...state.form,
          longitude: action.payload[0],
          latitude: action.payload[1],
        },
      };
    case "SET_EMAILS":
      return {
        ...state,
        form: {
          ...state.form,
          emails: action.payload,
        },
      };
    case "SET_EMAIL_INPUT":
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PHONES":
      return {
        ...state,
        form: {
          ...state.form,
          phones: action.payload,
        },
      };
    case "SET_PHONE_INPUT":
      return {
        ...state,
        newPhone: {
          ...state.newPhone,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CENTER":
      return {
        ...state,
        center: action.payload.center,
        form: action.payload.center,
      };
    case "SET_CITIES":
      return {
        ...state,
        cities: action.payload.cities,
      };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_SAVED_VALUE":
      return { ...state, saved: action.payload.value };
    case "SET_CONTRACT_TYPES":
      return { ...state, contractTypes: action.payload };
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    default:
      throw new Error();
  }
}

export default function CreateCenterPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("centers");

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: { inputname: name },
    });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};
      state.form.name !== "" && (data.name = state.form.name);
      state.form.address !== "" && (data.address = state.form.address);
      state.form.postalCode !== "" &&
        state.form.postalCode !== null &&
        (data.postalCode = state.form.postalCode);
      state.form.defaultRoyalty !== "" &&
        (data.defaultRoyalty = state.form.defaultRoyalty);
      state.form.canon !== "" && (data.canon = state.form.canon);
      state.form.canonPaid !== "" && (data.canonPaid = state.form.canonPaid);
      state.form.contractSignedAt !== "" &&
        (data.contractSignedAt = state.form.contractSignedAt);
      state.form.openingDate !== "" &&
        (data.openingDate = state.form.openingDate);
      state.form.city !== "" && (data.cityId = state.form.city.id);
      state.form.longitude !== "" && (data.longitude = state.form.longitude);
      state.form.latitude !== "" && (data.latitude = state.form.latitude);
      state.form.emails !== "" && (data.CenterEmails = state.form.emails);
      state.form.phones !== "" && (data.CenterPhoneNumbers = state.form.phones);
      state.form.comments !== "" && (data.comments = state.form.comments);
      state.form.active !== "" && (data.active = state.form.active + "");
      state.form.type !== "" && (data.type = state.form.type);
      state.form.contractTypes !== "" &&
        (data.contractTypes = state.form.contractTypes);
      state.form.defaultContractType !== "" &&
        (data.defaultContractType = state.form.defaultContractType);
      state.form.issuers !== "" && (data.issuers = state.form.issuers);
      state.form.defaultIssuer !== "" &&
        (data.defaultIssuer = state.form.defaultIssuer);
      state.form.centerCompanyId !== "" &&
        (data.centerCompanyId = state.form.centerCompanyId);
      state.form.cif !== "" && (data.cif = state.form.cif);
      state.form.businessName !== "" &&
        (data.businessName = state.form.businessName);
      if (state.form.acquisitionDate && state.form.type === 0) {
        data.acquisitionDate = state.form.acquisitionDate;
      } else {
        data.acquisitionDate = null;
      }
      data.createdBy = user.id;

      dispatch({ type: "SET_SAVED_VALUE", payload: { value: false } });
      api
        .post("/centers", data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("centerCreateSuccess"), { variant: "success" });
            history.goBack();
          }
          dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } });
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error, { variant: "error" });
          dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (state.form.name === "") {
      setInputErrorTrue("name");
      enqueueSnackbar(t("name") + " " + t("isRequired"), { variant: "error" });
      isValid = false;
    }

    return isValid;
  };

  const validateEmail = (emailVal) => {
    let isValid = true;
    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (emailVal !== null && emailVal !== "") {
      if (!emailVal || emailVal === "" || !re.test(emailVal)) {
        isValid = false;
      }
      state.form.emails.forEach((email) => {
        if (email.email.localeCompare(emailVal) === 0) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  };

  const validatePhone = (phone) => {
    let isValid = true;

    if (!phone || phone === "") {
      isValid = false;
    }
    return isValid;
  };

  const handleChangeEmails = (e) => {
    dispatch({
      type: "SET_EMAIL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value.trim(),
      },
    });
  };

  const addEmail = (e) => {
    let exist = state.form.emails.some(
      (email) => email.email == state.newEmail.email
    );

    if (validateEmail(state.newEmail.email) && !exist) {
      state.form.emails.push(state.newEmail);
      dispatch({
        type: "SET_EMAILS",
        payload: state.form.emails,
      });
      handleCloseEmailModal();
      dispatch({
        type: "SET_EMAIL_INPUT",
        payload: { inputname: "email", value: "" },
      });
      dispatch({
        type: "SET_EMAIL_INPUT",
        payload: { inputname: "contact", value: "" },
      });
    } else enqueueSnackbar(t("invalidEmail"), { variant: "error" });
  };

  const deleteEmail = (e) => {
    dispatch({
      type: "SET_EMAILS",
      payload: state.form.emails.filter((email) => email.email != e.email),
    });
  };

  const handleChangePhones = (e) => {
    dispatch({
      type: "SET_PHONE_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const addPhone = (e) => {
    let exist = state.form.phones.some(
      (phone) => phone.number == state.newPhone.number
    );

    if (validatePhone(state.newPhone.number) && !exist) {
      dispatch({
        type: "SET_PHONES",
        payload: [...state.form.phones, state.newPhone],
      });
      dispatch({
        type: "SET_PHONE_INPUT",
        payload: { inputname: "number", value: "" },
      });
      dispatch({
        type: "SET_PHONE_INPUT",
        payload: { inputname: "contact", value: "" },
      });
      handleClosePhoneModal();
    } else enqueueSnackbar(t("invalidPhone"), { variant: "error" });
  };

  const deletePhone = (e) => {
    dispatch({
      type: "SET_PHONES",
      payload: state.form.phones.filter((phone) => phone.number != e.number),
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("createCenter");

    getCities();
    getContractTypes();
    getIssuers();
  }, []);

  const getCities = () => {
    api
      .get("/cities")
      .then((response) => {
        response.data.error &&
          enqueueSnackbar(response.data.error, { variant: "error" });
        dispatch({
          type: "SET_CITIES",
          payload: {
            cities: response.data,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCoordinatesFromAddress = () => {
    if (
      !state.form.address &&
      !state.form.city?.name &&
      !state.form.postalCode
    ) {
      return;
    }

    let a = [];
    let l, ll;
    state.form.address && a.push(state.form.address);
    state.form.postalCode && a.push(state.form.postalCode);
    state.form.city?.name && a.push(state.form.city.name);

    const route =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      encodeURIComponent(a.join(", ")) +
      ".json" +
      "?access_token=" +
      mapToken +
      "&autocomplete=true&country=es&language=es";

    axios
      .get(route)
      .then((response) => {
        [l, ll] = response.data?.features[0]?.center || [0, 0];
        handleChangeCoordinates(l, ll);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const handleCommentChange = (e) => {
    handleInputChange({ target: { name: "comments", value: e.target.value } });
  };

  const handleChangeCoordinates = (l, ll) => {
    dispatch({
      type: "SET_COORDINATES",
      payload: [l, ll],
    });
  };

  const handleOpenEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: true,
      },
    });
  };

  const handleCloseEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: false,
      },
    });
  };

  const openConfirmEmail = (email) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteEmailQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          email.email + (email.contact !== null ? " - " + email.contact : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmail(email);
          resetConfirmDialog();
        },
      },
    });
  };

  const handleOpenPhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: true,
      },
    });
  };

  const handleClosePhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: false,
      },
    });
  };

  const openConfirmPhone = (phone) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deletePhoneQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: phone.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deletePhone(phone);
          resetConfirmDialog();
        },
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const getContractTypes = () => {
    api
      .get("/contracts/types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CONTRACT_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ISSUERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createCenter")}</Typography>
          </Grid>

          <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    error={state.inputError.name}
                    helperText={
                      state.inputError.name
                        ? t("name") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    variant="outlined"
                    label={t("name")}
                    value={state.form.name}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="name"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CityInput
                    label={t("city")}
                    value={state.form.city}
                    onChange={handleInputChange}
                    name="city"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PostalCodeInput
                    label={t("postalCode")}
                    value={state.form.postalCode}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    variant="outlined"
                    label={t("address")}
                    value={state.form.address}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="address"
                  />
                </Grid>

                {state.form.type === 0 && (
                  <Grid item xs={12} md={6}>
                    <CustomDate
                      name="acquisitionDate"
                      label={t("acquisitionDate")}
                      value={state.form.acquisitionDate}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <CustomDate
                    name="contractSignedAt"
                    label={t("contractSigningDate")}
                    value={state.form.contractSignedAt}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDate
                    name="openingDate"
                    label={t("openingDate")}
                    value={state.form.openingDate}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    error={state.inputError.defaultRoyalty}
                    helperText={
                      state.inputError.defaultRoyalty
                        ? "Default royalty must not be blank"
                        : ""
                    }
                    label={t("defaultRoyalty")}
                    value={state.form.defaultRoyalty}
                    name="defaultRoyalty"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    type="number"
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                {false && ( // se a añadido el false para ocultar temporalmente
                  <Grid item xs={6}>
                    <TextInput
                      error={state.inputError.canon}
                      helperText={
                        state.inputError.canon ? "Canon must not be blank" : ""
                      }
                      variant="outlined"
                      label={t("canon")}
                      value={state.form.canon}
                      name="canon"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(state.form.active)}
                        onChange={(e) => {
                          handleInputChange({
                            target: {
                              value: e.target.checked,
                              name: e.target.name,
                            },
                          });
                        }}
                        name="active"
                      />
                    }
                    label={t("active")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CenterTypeSelect
                    name="type"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    value={state.form.type}
                  />
                </Grid>
                {state.form.type === 2 && (
                  <Grid item xs={6} lg={4}>
                    <CenterCompanySelect
                      value={state.form.centerCompanyId}
                      name="centerCompanyId"
                      label={t("company")}
                      onChange={handleInputChange}
                      disabled={state.form.type !== 2}
                      width="100%"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12} md={6}>
              <Grid item xs={12}>
                <CenterMap
                  lat={state.form.latitude || 40.4165}
                  lng={state.form.longitude || -3.70256}
                />
              </Grid>
              <Grid item xs={12} sm>
                <Button
                  sx={{ minWidth: 100 }}
                  fullWidth
                  variant="contained"
                  onClick={getCoordinatesFromAddress}
                >
                  {t("searchLocation")}
                </Button>
              </Grid>
              <Grid item xs={12} sm>
                <TextInput
                  label={t("latitude")}
                  value={state.form.latitude}
                  name="latitude"
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm>
                <TextInput
                  label={t("longitude")}
                  value={state.form.longitude}
                  name="longitude"
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Divider />
            </Grid>

            {/* Emails */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <MailIcon />
                  {t("emails")}
                </Typography>
              </Grid>
              <Grid item flex={1} justifyContent="flex-end" display="flex">
                <Button startIcon={<AddIcon />} onClick={handleOpenEmailModal}>
                  {t("addEmail")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {state.form.emails.map((email) => (
                <List>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("address") + ": "}
                          </Typography>
                          <Typography variant="body">{email?.email}</Typography>
                          <Grid xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {email.contact ? email.contact : "---"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          flex={1}
                          alignItems="flex-start"
                          justifyContent={"flex-end"}
                          display="flex"
                        >
                          <IconButton
                            onClick={() => {
                              openConfirmEmail(email);
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.emails.length === 0 && t("noEmails")}
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Divider />
            </Grid>

            {/* Phones */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Phone />
                  {t("phoneNumbers")}
                </Typography>
              </Grid>
              <Grid item flex={1} justifyContent="flex-end" display="flex">
                <Button startIcon={<AddIcon />} onClick={handleOpenPhoneModal}>
                  {t("addPhone")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {state.form.phones?.map((phone) => (
                <List>
                  <Card variant="outlined" xs={6}>
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("number") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone?.number}
                          </Typography>
                          <Grid xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone.contact ? phone.contact : "---"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          flex={1}
                          alignItems="flex-start"
                          justifyContent="flex-end"
                          display="flex"
                        >
                          <IconButton onClick={() => openConfirmPhone(phone)}>
                            <HighlightOffIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.phones.length === 0 && t("noPhones")}
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Divider />
            </Grid>

            {/* Contract types */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <DescriptionIcon />
                  {t("contractTypes")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                multiple
                options={state.contractTypes.map((contractType) => ({
                  value: contractType.id,
                  label: contractType.name,
                }))}
                label={t("contractTypes")}
                onChange={(e) => {
                  if (state.form.defaultContractType !== "")
                    if (
                      !e.target.value.includes(
                        Number(state.form.defaultContractType)
                      )
                    ) {
                      enqueueSnackbar(t("thisContractTypeIsTheDefault"), {
                        variant: "error",
                      });
                      return;
                    }
                  handleInputChange(e);
                }}
                name="contractTypes"
                value={state.form.contractTypes}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {t("defaultContractType")}
                </FormLabel>
                <RadioGroup
                  name="defaultContractType"
                  value={Number(state.form.defaultContractType)}
                  onChange={(e) => {
                    e.target.value = Number(e.target.value);
                    handleInputChange(e);
                  }}
                >
                  {state.contractTypes?.map((type) => (
                    <FormControlLabel
                      control={
                        <Radio
                          disabled={!state.form.contractTypes.includes(type.id)}
                        />
                      }
                      value={type.id}
                      label={type.name}
                      key={type.id}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Divider />
            </Grid>

            {/* Invoicing */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <ReceiptLongIcon />
                  {t("invoicing")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                multiple
                options={state.issuers.map((issuer) => ({
                  label: issuer.name,
                  value: issuer.id,
                }))}
                label={t("issuers")}
                onChange={(e) => {
                  if (state.form.defaultIssuer !== "")
                    if (
                      !e.target.value.includes(Number(state.form.defaultIssuer))
                    ) {
                      enqueueSnackbar(t("thisContractTypeIsTheDefault"), {
                        variant: "error",
                      });
                      return;
                    }
                  handleInputChange(e);
                }}
                name="issuers"
                value={state.form.issuers}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                label={t("defaultIssuer")}
                name="defaultIssuer"
                value={state.form.defaultIssuer}
                onChange={handleInputChange}
                options={state.form.issuers?.map((issuerId) => {
                  let issuer = state.issuers.filter(
                    (item) => item.id === issuerId
                  )[0];

                  return {
                    value: issuer?.id,
                    label: issuer?.name,
                  };
                })}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Divider />
            </Grid>

            {/* Tax data */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <StoreIcon />
                  {t("taxData")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={t("businessName")}
                value={state.form.businessName}
                name="businessName"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={t("cif")}
                value={state.form.cif}
                name="cif"
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">{t("comments")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label="Comments"
                multiline
                minRows={4}
                onChange={handleCommentChange}
              />
            </Grid>

            <Grid container item xs={12} spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button onClick={() => history.goBack()}>{t("back")}</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  disabled={!state.saved}
                  onClick={submitForm}
                >
                  {!state.saved ? t("creating") : t("create")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Add email Modal*/}

        <Dialog
          open={state.emailModal.isOpen}
          onClose={handleCloseEmailModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle textAlign="right">
            {t("addEmail")}
            <IconButton onClick={handleCloseEmailModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  label={t("email")}
                  name="email"
                  variant="outlined"
                  onChange={(e) => {
                    handleChangeEmails(e);
                  }}
                  value={state.newEmail.email}
                />
              </Grid>

              <Grid item xs={10}>
                <TextInput
                  label={t("contact")}
                  name="contact"
                  variant="outlined"
                  onChange={(e) => {
                    handleChangeEmails(e);
                  }}
                  value={state.newEmail.contact}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    addEmail();
                  }}
                >
                  <AddCircleOutlineOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Add phone Modal*/}

        <Dialog
          open={state.phoneModal.isOpen}
          onClose={handleClosePhoneModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle textAlign="right">
            {t("addPhoneNumber")}
            <IconButton onClick={handleClosePhoneModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <PhoneInput
                  inputProps={{
                    label: t("phoneNumber"),
                    name: "number",
                    onChange: (e) => {
                      handleChangePhones(e);
                    },
                  }}
                  value={state.newPhone.number}
                />
              </Grid>

              <Grid item xs={5}>
                <TextInput
                  label={t("contact")}
                  name="contact"
                  onChange={(e) => {
                    handleChangePhones(e);
                  }}
                  value={state.newPhone.contact}
                />
              </Grid>

              <Grid item xs={2}>
                <Button variant="contained" color="primary" onClick={addPhone}>
                  <AddCircleOutlineOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <ConfirmDialog
          title={state.confirmDialog.title}
          open={state.confirmDialog.isOpen}
          setOpen={setConfirmDialogState}
          confirmText={state.confirmDialog.confirmText}
          cancelText={state.confirmDialog.cancelText}
          onConfirm={state.confirmDialog.callback}
        >
          <Typography variant="body2" color="initial">
            {state.confirmDialog.childrenText}
          </Typography>
        </ConfirmDialog>
      </Paper>
    </Container>
  );
}
