import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../Inputs/CustomButton";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useContext, useRef, useState } from "react";
import LabeledText from "../../../global/LabeledText";
import AppContext from "../../../../context/AppContext";
import { enqueueSnackbar } from "notistack";

const BankReconciliation = () => {
  const [t] = useTranslation("invoices");
  const { api } = useContext(AppContext);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (file) => {
    setFile(file);
  };

  const processFile = () => {
    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    api
      .post("/pay-card-charges/process", formData, {
        responseType: "blob", // Set the response type to 'blob'
      })
      .then((response) => {
        // Create a download link for the XLSX file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "reconciliation.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <FileDropZone onFileSelect={handleFileChange} />
      </Grid>
      {file && (
        <Grid
          container
          item
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <LabeledText
              label={t("uploadedFile")}
              value={file ? file.name : ""}
            />
          </Grid>
          <Grid item>
            <CustomButton onClick={processFile} loading={loading}>
              {t("processFile")}
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const FileDropZone = ({ onFileSelect }) => {
  const [t] = useTranslation("others");

  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      onFileSelect(e.target.files[0]);
    }
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
      sx={{
        border: "2px dashed",
        borderColor: isDragging ? "primary.main" : "grey.400",
        borderRadius: 2,
        p: 2,
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: isDragging ? "action.hover" : "background.paper",
      }}
    >
      <AttachmentIcon
        color={isDragging ? "primary" : "action"}
        fontSize="large"
      />
      <Typography
        variant="body1"
        color={isDragging ? "primary" : "text.secondary"}
      >
        {t("fileDragAndDropText")}
      </Typography>
      <input
        type="file"
        hidden
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx"
      />
    </Box>
  );
};

export default BankReconciliation;
