import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useContext, useEffect, useReducer } from "react";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

import { CustomTable } from "../../../CustomTable";
import { getParams } from "../../../../utils/url";
import { localeFormat } from "../../../../utils/format";
import AppContext from "../../../../context/AppContext";
import ButtonLink from "../../../Inputs/ButtonLink";
import CreateNonPaymentForm from "../../NonPayments/CreateNonPaymentForm";
import CustomSelect from "../../../Inputs/CustomSelect";
import InvoiceStateChip from "../../../InvoiceStateChip";
import ItemsSummary from "../../../ItemsSummary";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import {
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
} from "../../../../data/constants";
import NonPaymentRecuperationDialog from "../../NonPayments/NonPaymentRecuperationDialog";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  customers: [],
  invoices: [],
  filters: {
    autoSearch: "false",
    customer: "",
    dateFrom: "",
    dateUntil: "",
    state: [],
    paymentDay: "",
  },
  loaded: true,
  selectedCustomer: "",
  paymentDays: [],
  paymentMethods: [],
  paycards: [],
  form: {
    newComment: "",
  },
  nonPaymentDialog: {
    isOpen: false,
    createLoading: false,
  },
  recuperateDialog: {
    isOpen: false,
  },
  selectedInvoice: {},
  nonPaymentReasons: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER_FILTER":
      return { ...state, selectedCustomer: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_PAID_AT":
      const index = state.invoices.findIndex(
        (post) => post.id === action.payload.id
      );

      return {
        ...state,
        invoices: [
          ...state.invoices.slice(0, index),
          {
            ...state.invoices[index],
            paidAt: action.payload.paidAt,
          },
          ...state.invoices.slice(index + 1),
        ],
      };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "SET_SELECTED_INVOICE":
      return { ...state, selectedInvoice: action.payload };
    case "OPEN_RECUPERATE_DIALOG":
      return {
        ...state,
        recuperateDialog: { ...state.recuperateDialog, isOpen: true },
      };
    case "CLOSE_RECUPERATE_DIALOG":
      return {
        ...state,
        recuperateDialog: { ...state.recuperateDialog, isOpen: false },
      };
    case "SET_NONPAYMENT_DIALOG_CREATE_LOADING":
      return {
        ...state,
        nonPaymentDialog: {
          ...state.nonPaymentDialog,
          createLoading: action.payload,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PAYMENT_METHOD_INFO":
      return {
        ...state,
        paycards: action.payload.paycards,
        ibans: action.payload.ibans,
      };
    case "OPEN_NONPAYMENT_DIALOG":
      return {
        ...state,
        nonPaymentDialog: { ...state.nonPaymentDialog, isOpen: true },
      };
    case "CLOSE_NONPAYMENT_DIALOG":
      return {
        ...state,
        nonPaymentDialog: { ...state.nonPaymentDialog, isOpen: false },
      };
    case "SET_NONPAYMENT_REASONS":
      return { ...state, nonPaymentReasons: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function PayCardsTab({ functions }) {
  const { user } = useContext(AppContext);
  const [t] = useTranslation("invoices");
  const history = useHistory();
  const query = useQuery();

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "customerId", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "paymentDay", type: "number" },
    { name: "state", type: "array" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const INVOICES_ITEM_SUMMARY = [
    {
      translatedText: t("totalInvoices"),
      value: localeFormat(state.invoices.length),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.invoices.length > 0
            ? state.invoices.reduce(
                (total, element) => total + element.totalAmount,
                0
              )
            : 0
        ) + " €",
    },
  ];

  const INVOICES_COLUMNS = [
    {
      key: "number",
      label: t("number"),
      sortType: "number",
      renderFunction: (value, item) => (
        <ButtonLink variant="text" to={"/app/invoice/" + item.id}>
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "customerName",
      label: t("customerName"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink variant="text" to={"/app/customer/" + item.Customer?.id}>
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "paycardId",
      label: t("payCard"),
      sortType: "number",
      renderFunction: (value, item) => item.payCard,
    },
    { key: "issueDate", label: t("issueDate"), sortType: "string" },
    {
      key: "totalAmount",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
    {
      key: "paidAt",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <>
          <Tooltip
            title={value ? t("uncheckPaid") : t("checkPaid")}
            placement="top"
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                editInvoice(item.id, value);
              }}
            >
              {value ? (
                <CreditScoreIcon color="success" />
              ) : (
                <CreditCardIcon color="error" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("goNonPayments")} placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/app/non-payment/${item.Customer?.id}`);
              }}
            >
              <MoneyOffIcon />
            </IconButton>
          </Tooltip>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => {
              item.NonPayments.length !== 0 &&
              item.NonPayments.some(
                (nonPayment) => !nonPayment.recuperationDate
              )
                ? showRecuperationDialog(item)
                : showNonPaymentDialog(item);
            }}
          >
            {item.NonPayments.length !== 0 &&
            item.NonPayments.some((nonPayment) => !nonPayment.recuperationDate)
              ? t("showNonPayment")
              : t("nonPayment")}
          </Button>
        </>
      ),
    },
  ];

  const CSV_HEADERS = [
    { key: "number", label: t("number") },
    { key: "customerName", label: t("customerName") },
    { key: "payCard", label: t("payCard") },
    { key: "issueDate", label: t("issueDate") },
    { key: "totalAmount", label: t("import") },
    { key: "stateText", label: t("state") },
  ];

  useEffect(() => {
    getCustomers();
    getPaymentDays();
    getPaymentMethods();
    getNonPaymentReasons();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getInvoices();
  }, [state.filters.autoSearch]);

  const search = () => {
    if (state.filters.autoSearch === "true") getInvoices();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getInvoices = () => {
    functions.getInvoices(
      state.filters,
      (value) => {
        if (value) dispatch({ type: "SET_LOADED_FALSE" });
        else dispatch({ type: "SET_LOADED_TRUE" });
      },
      (data) => {
        data.forEach((item) => {
          item.payCard = getPayCard(item);
          item.stateText = functions.getStateText(item.state);
        });
        dispatch({
          type: "SET_INVOICES",
          payload: data
            .filter((data) => data.totalAmount > 0)
            .map((invoice) => ({
              ...invoice,
              invoiceNum: invoice.InvoiceSerie.name + invoice.number,
            })),
        });
        dispatch({
          type: "SET_PAYMENT_METHOD_INFO",
          payload: {
            paycards: data[0]?.Customer?.PayCards || [],
            ibans: data[0]?.Customer?.IBANs || [],
          },
        });
      }
    );
  };

  const getPayCard = (item) => {
    const reg = /[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}/;

    //Search invoice paycard:
    const payCard = item?.Customer?.PayCards?.find(
      (card) => card.id === item.paycardId
    );

    //Check regex
    if (item.paycardId === null || !payCard) return "---";
    else if (reg.test(payCard.number))
      return (
        payCard.number +
        " (" +
        payCard.expirationMonth +
        " / " +
        payCard.expirationYear +
        ")"
      );
    else
      return (
        payCard.number.substring(0, 4) +
        " " +
        payCard.number.substring(4, 8) +
        " " +
        payCard.number.substring(8, 12) +
        " " +
        payCard.number.substring(12, 16) +
        " (" +
        payCard.expirationMonth +
        " / " +
        payCard.expirationYear +
        ")"
      );
  };

  const getCustomers = () => {
    functions.getCustomers(state.filters, (data) => {
      if (state.filters.customerId) {
        const customer = data.find(
          (customer) => customer.id === Number(state.filters.customerId)
        );
        dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
      }

      dispatch({
        type: "SET_CUSTOMERS",
        payload: data,
      });
    });
  };

  const getPaymentDays = () => {
    functions.getPaymentDays(state.filters, (data) => {
      const days = data.map((day) => day.day);
      dispatch({ type: "SET_PAYMENT_DAYS", payload: days });
    });
  };

  const getPaymentMethods = () => {
    functions.getPaymentMethods(state.filters, (data) => {
      dispatch({ type: "SET_PAYMENT_METHODS", payload: data });
    });
  };

  const getNonPaymentReasons = () => {
    functions.getNonPaymentReasons(state.filters, (data) => {
      dispatch({ type: "SET_NONPAYMENT_REASONS", payload: data });
    });
  };

  const editInvoice = (id, paidAt) => {
    functions.editInvoice(id, paidAt, (data) => {
      dispatch({
        type: "SET_PAID_AT",
        payload: { id: id, paidAt: data.paidAt },
      });
    });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const showNonPaymentDialog = (invoice) => {
    dispatch({ type: "SET_SELECTED_INVOICE", payload: invoice });
    dispatch({ type: "OPEN_NONPAYMENT_DIALOG" });
  };

  const handleSetSelectedInvoice = (invoice) => {
    const lastNonPayment = invoice.NonPayments.find(
      (nonPayment) => !nonPayment.recuperationDate
    );

    invoice.reason = state.nonPaymentReasons.find(
      (reason) => reason.id === lastNonPayment.reason
    ).name;
    invoice.nonPaymentId = lastNonPayment.id;
    invoice.nonPaymentDate = lastNonPayment.nonPaymentDate;
    invoice.comments = lastNonPayment.comments;

    dispatch({ type: "SET_SELECTED_INVOICE", payload: invoice });
  };

  const showRecuperationDialog = (invoice) => {
    handleSetSelectedInvoice(invoice);
    dispatch({ type: "OPEN_RECUPERATE_DIALOG" });
  };

  const closeNonPaymentDialog = () => {
    dispatch({ type: "CLOSE_NONPAYMENT_DIALOG" });
  };

  const closeRecuperationDialog = () => {
    dispatch({ type: "CLOSE_RECUPERATE_DIALOG" });
  };

  const createNonPayment = (form) => {
    functions.createNonPayment(
      form,
      state.selectedInvoice.id,
      (value) => {
        dispatch({
          type: "SET_NONPAYMENT_DIALOG_CREATE_LOADING",
          payload: value,
        });
      },
      (data) => {
        closeNonPaymentDialog();
        addComment(
          `${t("theUser")} ${user.name} ${t(
            "createdANonPayment"
          ).toLowerCase()} ${t("forTheInvoice").toLowerCase()} ${
            state.selectedInvoice.invoiceNum
          }.
        ${form.comment !== "" ? t("comment") + ": " + form.comment : ""}`
        );
        getInvoices();
      }
    );
  };

  const addComment = (comment = null) => {
    functions.addComment(comment, state.selectedInvoice, (data) => {
      dispatch({
        type: "SET_INPUT",
        payload: { inputname: "newComment", value: "" },
      });
    });
  };

  const handleOnCreatePaymentMethod = (paymentMethodId, data) => {
    const paymentMethodMap = {
      [PAYCARD_PAYMENT_METHOD_ID]: "PayCards",
      [RECEIPT_PAYMENT_METHOD_ID]: "IBANs",
    };
    const methodKey = paymentMethodMap[paymentMethodId];
    if (!methodKey) return;

    const invoices = state.invoices.map((invoice) => {
      return {
        ...invoice,
        Customer: {
          ...invoice.Customer,
          [methodKey]: [...(invoice.Customer[methodKey] || []), data],
        },
      };
    });

    const selectedInvoice = invoices.find(
      (invoice) => invoice.id === state.selectedInvoice.id
    );

    handleSetSelectedInvoice(selectedInvoice);
    dispatch({ type: "SET_INVOICES", payload: invoices });
  };

  return (
    <Box sx={{ marginTop: "15px" }}>
      <Grid item container rowSpacing={3}>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={12} sm="auto">
            <Autocomplete
              size="large"
              sx={{ minWidth: "200px" }}
              options={state.customers}
              getOptionLabel={(customer) => customer.fullName || ""}
              isOptionEqualToValue={(option, value) =>
                option.nif === value.nif || null
              }
              filterOptions={createFilterOptions({ limit: 10 })}
              value={state.selectedCustomer || null}
              onChange={(e, customer) => {
                handleFilterChange({
                  target: {
                    value: customer ? customer.id : "",
                    name: "customerId",
                  },
                });
                dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
              }}
              renderInput={(params) => (
                <TextInput {...params} size="small" label={t("customer")} />
              )}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("dateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateFrom}
              onChange={handleFilterChange}
              name="dateFrom"
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("dateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateUntil}
              onChange={handleFilterChange}
              name="dateUntil"
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              label={t("paymentDay")}
              name="paymentDay"
              value={state.filters.paymentDay}
              onChange={handleFilterChange}
              options={[
                { value: "", label: t("none") },
                ...state.paymentDays?.map((day) => ({
                  value: day,
                  label: day,
                })),
              ]}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              label={t("state")}
              value={state.filters.state}
              onChange={handleFilterChange}
              options={[
                { value: 0, label: t("issued") },
                { value: 1, label: t("paid") },
                { value: 2, label: t("expired") },
                { value: 3, label: t("unpaid") },
                { value: 4, label: t("incorrectIssuance") },
              ]}
              name="state"
              multiple
            />
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <ButtonCSV
                filename={t("payCards")}
                data={state.invoices}
                headers={CSV_HEADERS}
              />
              <SearchButton onClick={search} loading={!state.loaded} />
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <ItemsSummary gridItems={INVOICES_ITEM_SUMMARY} />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={INVOICES_COLUMNS}
              data={state.invoices.map((invoice) => {
                invoice.actions = "";
                return invoice;
              })}
              options={state.options}
              sortBy={state.filters.orderBy}
              sort={state.filters.order}
              /*onSortCallback={(orderBy, order) => {
                handleOrderChange(orderBy, order);
                const url = generateURL("/app/invoices", {
                  ...state.filters,
                  orderBy: orderBy,
                  order: order,
                });
                history.push(url);
              }}*/
            />
          </Grid>
        </Grid>
      </Grid>

      {/*Create Non Payment dialog */}
      <CreateNonPaymentForm
        open={state.nonPaymentDialog.isOpen}
        onClose={closeNonPaymentDialog}
        onSubmit={createNonPayment}
        invoicePaymentMethodId={PAYCARD_PAYMENT_METHOD_ID}
        loading={state.nonPaymentDialog.createLoading}
      />

      {/* Non Payment recuperation dialog */}
      <NonPaymentRecuperationDialog
        open={state.recuperateDialog.isOpen}
        closeRecuperationDialog={closeRecuperationDialog}
        invoiceInfo={state.selectedInvoice}
        onRecuperationSuccess={getInvoices}
        paymentMethods={{
          paycards: state.selectedInvoice.Customer?.PayCards || [],
          ibans: state.selectedInvoice.Customer?.IBANs || [],
          onCreate: handleOnCreatePaymentMethod,
        }}
      />
    </Box>
  );
}
