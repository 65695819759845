import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PropTypes, { oneOfType } from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import Select from "../global/inputs/Select";

import AppContext from "../../context/AppContext";

/**
 * PaymentDaySelect Component
 *
 * This component renders a select input for choosing payment days.
 * It provides predefined options and supports single or multiple selections.
 *
 * @param {Object} props - The props for the PaymentDaySelect component.
 * @param {boolean} [props.multiple] - If true, allows multiple selections.
 * @param {Function} props.onChange - A callback function to handle changes in the selected value(s).
 * @param {string} [props.value] - The initial value of the select input.
 * @param {string} [props.label] - The label to display above the select input.
 * @returns {JSX.Element} - A React component for selecting payment days.
 */

const PaymentDaySelect = (props) => {
  const {
    multiple,
    onChange,
    value = multiple ? [] : "",
    label,
    ...rest
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDay, setSelectedDay] = useState(value);
  const [paymentDays, setPaymentDays] = useState([]);
  const [t] = useTranslation("invoices");
  const { api } = useContext(AppContext);

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          setPaymentDays(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  useEffect(getPaymentDays, []);
  useEffect(() => setSelectedDay(value), [value]);

  let options = [...paymentDays.map((d) => ({ value: d.day, label: d.day }))];

  if (!multiple) {
    options = [{ value: "", label: t("all") }, ...options];
  }

  return (
    <Select
      label={label || t("paymentDays")}
      multiple={multiple}
      sx={{ maxWidth: 230 }}
      value={selectedDay}
      options={options}
      onChange={(e) => {
        setSelectedDay(e.target.value);
        onChange && onChange(e);
      }}
      {...rest}
    />
  );
};

PaymentDaySelect.propTypes = {
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
};

export default PaymentDaySelect;
