import { Grid, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { NewChartContainer } from "./NewChartContainer";
import { colors } from "../Components/ColorGenerator";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import EuroIcon from "@mui/icons-material/Euro";

import { localeFormat } from "../../../../utils/format";
import {
  // Charts index
  LINE,
  PIE,
} from "../../../../data/constants";

const mainExpenseTypes = {
  77: "GASTOS DE PERSONAL",
  89: "ALQUILERES",
  97: "SERVICIOS PROFESIONALES",
  110: "SERVICIOS BANCARIOS",
  117: "PUBLICIDAD",
  132: "OTROS SERVICIOS",
  149: "OTROS TRIBUTOS",
  154: "INVERSIÓN",
  164: "COSTE DE BIENES Y SERVICIOS",
  170: "OTROS",
};

const dataGetters = {
  invoices: (items) => items.length,
  amount: (items) => items.reduce((acc, item) => acc + item.amount, 0),
};

export const HistoricalProviderInvoices = ({ providerInvoices }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("invoices");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [{ propName: "date", isDate: true, datePeriod: "month" }];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    sortedBy: "label",
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(value.raw)} ${
              dataType === "amount" ? "€" : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalProviderInvoices")}
      data={providerInvoices}
      groupBy={groupBy}
      options={options}
      config={config}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalInvoicesByMainExpenseTypes = ({ providerInvoices }) => {
  const [t] = useTranslation("dashboard");

  const [dataType, setDataType] = useState("amount");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const groupBy = [
    { propName: "date", isDate: true, datePeriod: "month" },
    {
      propName: "mainParentId",
      valueCalculator: (item) => mainExpenseTypes[item.mainParentId],
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    sortedBy: "label",
  };

  const options = {
    aspectRatio: 3,
    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: "right",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(value.raw)} ${
              dataType === "amount" ? "€" : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalInvoicesByMainExpenseTypes")}
      data={providerInvoices}
      groupBy={groupBy}
      options={options}
      config={config}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const HistoricalInvoicesByPaymentMethod = ({ providerInvoices }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("amount");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const paymentMethodColors = {
    [t("cash")]: colors.primary,
    [t("consolidation")]: colors.secondary,
    [t("paycard")]: colors.red,
    [t("receipt")]: colors.brown,
    [t("tokenizedPaycard")]: colors.yellow,
    [t("transfer")]: colors.orange,
    [t("notAssigned")]: colors.purple,
  };

  const groupBy = [
    { propName: "date", isDate: true, datePeriod: "month" },
    {
      propName: "paymentMethodName",
      colorGetter: (label) => paymentMethodColors[label] || colors.primary,
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
    sortedBy: "label",
  };

  const options = {
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(value.raw)} ${
              dataType === "amount" ? "€" : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("historicalInvoicesByPaymentMethod")}
      data={providerInvoices}
      groupBy={groupBy}
      options={options}
      config={config}
      chart={LINE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};

export const PaymentMethodPie = ({ providerInvoices }) => {
  const [t] = useTranslation("dashboard");
  const [dataType, setDataType] = useState("amount");

  const onChange = (event, newValue) => {
    if (newValue === null) return;
    setDataType(newValue);
  };

  const paymentMethodColors = {
    [t("cash")]: colors.primary,
    [t("consolidation")]: colors.secondary,
    [t("paycard")]: colors.red,
    [t("receipt")]: colors.brown,
    [t("tokenizedPaycard")]: colors.yellow,
    [t("transfer")]: colors.orange,
    [t("notAssigned")]: colors.purple,
  };

  const groupBy = [
    {
      propName: "paymentMethodName",
      colorGetter: (elements, labels) =>
        labels.map((label) => paymentMethodColors[label]),
    },
  ];

  const config = {
    propName: t(dataType),
    valueCalculator: dataGetters[dataType],
  };

  const options = {
    aspectRatio: 1.4,
    responsive: true,

    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (value) => {
            return `${value.dataset.label}: ${localeFormat(value.raw)} ${
              dataType === "amount" ? "€" : ""
            }`;
          },
        },
      },
    },
  };

  return (
    <NewChartContainer
      title={t("paymentMethodPie")}
      data={providerInvoices}
      groupBy={groupBy}
      options={options}
      config={config}
      chart={PIE}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        marginBottom={1}
        paddingLeft={2}
        paddingRight={2}
      >
        <Grid item>
          <ToggleButtonGroup exclusive value={dataType} onChange={onChange}>
            <ToggleButton size="small" value="invoices">
              <Tooltip title={t("invoices")}>
                <RequestQuoteIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton size="small" value="amount">
              <Tooltip title={t("amount")}>
                <EuroIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </NewChartContainer>
  );
};
