import { Bar as ChartJsBar } from "react-chartjs-2";

function Bar({ data = {}, options = {} }) {
  const labels = data.labels && data.labels.length > 0 ? data.labels : [];

  const dataSets =
    data.datasets && data.datasets.length > 0 ? data.datasets : [];

  const chartData = {
    labels: labels,
    datasets: dataSets,
  };

  const chartOptions = {
    ...options,
  };

  return <ChartJsBar data={chartData} options={chartOptions} />;
}

export default Bar;
