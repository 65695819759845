import {
  Container,
  Button,
  Tab,
  Tabs,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomDate from "../../Inputs/CustomDate";
import CenterSelect from "../../Inputs/CenterSelect";
import SearchButton from "../../Inputs/SearchButton";
import ExportButton, {
  exportDataParse,
} from "../../global/inputs/ExportButton";
import { CustomTable } from "../../CustomTable";
import { generateURL, getParams } from "../../../utils/url";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  HistoricalOccupancy,
  HistoricalContractVariation,
} from "../Dashboard/Charts/CenterReport";

function reducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: action.payload };
    case "SET_CENTER":
      return { ...state, center: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_CONTRACTS":
      return {
        ...state,
        contracts: action.payload.contracts,
        altas: action.payload.altas,
        bajas: action.payload.bajas,
      };
    default:
      throw new Error("Action not found in reducer.");
  }
}

const initialState = {
  currentTab: 0,
  contracts: [],
  center: [],
  altas: [],
  bajas: [],
  filters: {
    autoSearch: "false",
    center: [],
    dateFrom: "",
    dateUntil: "",
  },
  options: {
    rowlink: "contract",
    loaded: true,
  },
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AltasBajasPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("contracts");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();
  const query = useQuery();

  const filters = [
    { name: "center", type: "array" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "autoSearch", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
    currentTab: parseInt(query.get("tab") ?? state.currentTab),
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const ALTAS_COLUMNS = [
    { label: t("id"), key: "publicId" },
    {
      label: t("center"),
      key: "centerName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/center/" + item.Box.centerId} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("box"),
      key: "boxName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/box/" + item.Box?.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("customer"),
      key: "customerName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/customer/" + item.Customer?.id} size="small">
          {Number(item.Customer?.customerTypeId) === 1
            ? value + " " + item.Customer?.surnames
            : value}
        </ButtonLink>
      ),
    },
    {
      label: t("periodicity"),
      key: "periodicityName",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      label: t("paymentMethod"),
      key: "paymentMethodName",
      sortType: "string",
    },
    {
      label: t("createDate"),
      key: "createDate",
      sortType: "string",
    },
    { label: t("startDate"), key: "startDate", sortType: "string" },
  ];

  const BAJAS_COLUMNS = [
    { label: "ID", key: "publicId" },
    {
      label: t("center"),
      key: "centerName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/center/" + item.Box.centerId} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("box"),
      key: "boxName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/box/" + item.Box?.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("customer"),
      key: "customerName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/customer/" + item.Customer?.id} size="small">
          {Number(item.Customer?.customerTypeId) === 1
            ? value + " " + item.Customer?.surnames
            : value}
        </ButtonLink>
      ),
    },
    {
      label: t("periodicity"),
      key: "periodicityName",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      label: t("paymentMethod"),
      key: "paymentMethodName",
      sortType: "string",
    },
    { label: t("endDate"), key: "endDate", sortType: "string" },
  ];

  const updateQuery = () => {
    let params = {
      tab: state.currentTab,
      ...state.filters,
    };
    const url = generateURL("/app/altas-bajas/", params);
    history.replace(url);
  };

  useEffect(() => {
    updateQuery();
  }, [state.currentTab]);

  useEffect(() => {
    if (state.filters.autoSearch === "true") search();
  }, [state.filters.autoSearch]);

  const handleTabChange = (event, value) => {
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: value,
    });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getContracts();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getContracts = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    updateQuery();

    let params = {
      include: ["Box", "Center", "Customer", "Users"],
      centerId: state.filters.center,
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }

        if (response.data.length === 0) {
          enqueueSnackbar(t("noContracts"), {
            variant: "info",
            preventDuplicate: true,
          });
          return;
        }

        //Apply filters
        let contracts = response.data.map((contract) => ({
          ...contract,
          centerName: contract.Box.Center.name,
          boxName: contract.Box.name,
          customerName: contract.Customer.name,
          periodicityName: contract.Periodicity.name,
          paymentMethodName: contract.PaymentMethod.name,
          createDate: contract.createdAt.split("T")[0],
        }));
        let altas = contracts;
        let bajas = contracts.filter((contract) => contract.endDate !== null);

        if (state.filters.dateFrom != "") {
          altas = altas.filter(
            (contract) => contract.startDate >= state.filters.dateFrom
          );
          bajas = bajas.filter(
            (contract) => contract.endDate >= state.filters.dateFrom
          );
        }
        if (state.filters.dateUntil != "") {
          altas = altas.filter(
            (contract) => contract.startDate <= state.filters.dateUntil
          );
          bajas = bajas.filter(
            (contract) => contract.endDate <= state.filters.dateUntil
          );
        }

        dispatch({
          type: "SET_CONTRACTS",
          payload: {
            contracts: contracts,
            altas: altas,
            bajas: bajas,
          },
        });

        //Get center for the ocupancy graph:
        getCenter();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getCenter = () => {
    let params = { include: ["Box", "Contract"] };
    api
      .get("/centers/" + state.filters.center, { params })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTER",
            payload: response.data,
          });

          if (response.data.length === 0) {
            enqueueSnackbar(t("noContracts"), {
              variant: "info",
              preventDuplicate: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("Altas y Bajas")}</Typography>
          </Grid>

          {state.center.length !== 0 && (
            <Grid item container spacing={1} justifyContent="center">
              <Grid item xs={12} lg={6}>
                <HistoricalContractVariation contracts={state.contracts} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <HistoricalOccupancy
                  contracts={state?.contracts}
                  boxes={state?.center?.Boxes}
                  objective={null}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mb: 2 }}>
            <Tabs value={state.currentTab} onChange={handleTabChange}>
              <Tab label={t("altas")} id={0} />
              <Tab label={t("bajas")} id={1} />
            </Tabs>
          </Grid>

          <Grid item container spacing={1} sx={{ mb: 2 }}>
            <Grid item>
              <CenterSelect
                multiple
                name="center"
                onChange={handleChangeFilter}
                required
                value={state.filters.center}
              />
            </Grid>
            <Grid item>
              <CustomDate
                label={t("dateFrom")}
                InputLabelProps={{ shrink: true }}
                value={state.filters.dateFrom}
                onChange={(e) => {
                  handleChangeFilter(e);
                }}
                name="dateFrom"
                inputProps={{
                  // only needs the first 16 characters in the date string
                  min: new Date().toISOString().slice(0, 16),
                }}
              />
            </Grid>
            <Grid item>
              <CustomDate
                label={t("dateUntil")}
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateUntil}
                onChange={(e) => {
                  handleChangeFilter(e);
                }}
                name="dateUntil"
              />
            </Grid>
            <Grid item>
              <SearchButton
                onClick={search}
                loading={!state.options.loaded}
                disabled={state.filters.center.length === 0 ? true : false}
              ></SearchButton>
            </Grid>
            <Grid item>
              <ExportButton
                display={true}
                data={exportDataParse(
                  state.currentTab === 0 ? state.altas : state.bajas,
                  state.currentTab === 0 ? ALTAS_COLUMNS : BAJAS_COLUMNS
                )}
              />
            </Grid>
          </Grid>

          {state.currentTab === 0 && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <CustomTable
                  columns={ALTAS_COLUMNS}
                  data={state.altas}
                  options={state.options}
                  sortBy={"startDate"}
                  sort={"desc"}
                />
              </Grid>
            </Grid>
          )}

          {state.currentTab === 1 && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <CustomTable
                  columns={BAJAS_COLUMNS}
                  data={state.bajas}
                  options={state.options}
                  sortBy={"endDate"}
                  sort={"desc"}
                />
              </Grid>
            </Grid>
          )}

          <Grid item container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="text" onClick={() => history.goBack()}>
                {t("back")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
