import { Card } from "@mui/material";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";


const TextEditor = ({ html, onChange }) => {
  const content = ContentState.createFromBlockArray(htmlToDraft(html));
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(content)
  );


  return (
    <>
      <Card >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
          onChange={() => {
            onChange(
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
            );
          }}
        />
      </Card>
    </>
  );
};

export default TextEditor;
