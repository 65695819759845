import { Box, Grid, Link } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <Box sx={styles.footer} xs={12} spacing={2} justifyContent="center">
      <Grid
        container
        flex={1}
        justifyContent="flex-end"
        display="flex"
        spacing={2}
      >
        <Grid item>
          <Link href="https://www.tiktok.com/@necesitountrastero?lang=es">
            <MusicNoteIcon color="black"></MusicNoteIcon>
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://es-es.facebook.com/Necesitountrastero">
            <FacebookIcon color="black"></FacebookIcon>
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.instagram.com/necesitountrastero/?hl=es">
            <InstagramIcon color="black"></InstagramIcon>
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.linkedin.com/company/necesito-un-trastero/">
            <LinkedInIcon color="black"></LinkedInIcon>
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://twitter.com/nut_trasteros">
            <TwitterIcon color="black"></TwitterIcon>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={styles.content}>
        <Grid item xs={12} md={3} style={styles.logo}>
          <img src="/img/NUT_LOGO_3_black.png" width="230px" height="auto" />
        </Grid>
        <Grid item xs={12} md={5}>
          {
            "\n NECESITO UN TRASTERO \n LLAMA GRATIS 900 811 646 \n info@necesitountrastero.es \n\n"
          }
          <Link
            href="https://necesitountrastero.es/aviso-legal/"
            color="#000"
            fontSize={"1rem"}
          >
            {"Aviso legal"}
          </Link>
          {" | "}
          <Link
            href="https://necesitountrastero.es/politica-de-privacidad/"
            color="#000"
            fontSize={"1rem"}
          >
            {"Política de privacidad"}
          </Link>
          {" | "}
          <Link
            href="https://necesitountrastero.es/politica-de-cookies/"
            color="#000"
            fontSize={"1rem"}
          >
            {"Política de cookies"}
          </Link>
          {" | "}
          <Link
            href="https://necesitountrastero.es/politica-de-cancelacion/"
            color="#000"
            fontSize={"1rem"}
          >
            {"Política de cancelación y reembolso"}
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          {
            "\n CRECE TU HISTORIA, CRECE TU ESPACIO \n CONTRATA ONLINE TU TRASTERO · NUT \n NECESITOUNTRASTERO.ES"
          }
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  content: {
    whiteSpace: "pre-line",
    alignItems: "end",
  },
  footer: {
    backgroundColor: "#e8ca4d",
    borderRadius: "8px",
    margin: 4,
    padding: "2.8125rem",
  },
  logo: {
    marginBottom: "-20px",
    with: "",
  },
};

export default Footer;
