import { Container, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CENTER_EMAILS":
      return { ...state, centerEmails: action.payload };

    default:
      throw new Error("Action not found in reducer.");
  }
};

const initialState = {
  customerEmails: [],
  filters: {
    centers: [],
    state: "",
  },
  form: {},
  selectedCustomerEmailIds: [],
};

const CreateMassEmailCustomerPage = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("massEmails");

  const cols = [
    { field: "Customer.name", headerName: t("customerName"), width: 150 },
    { field: "email", headerName: t("email"), width: 150 },
    { field: "contact", headerName: t("contact"), width: 150 },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createMassEmailCustomerPage");

    // getCustomerEmails();
  }, []);

  const getCustomerEmails = () => {
    api
      .get("/center-emails")
      .then((response) => {
        if (!response.data.error) {
          dispatch({ type: "SET_CENTER_EMAILS", payload: response.data });
        } else {
          enqueueSnackbar(response.data.error.toString(), { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleFilterChange = (e) => {
    dispatch({ type: "SET_FILTER_CUSTOMER_STATE", payload: e.target.value });
  };

  const selectCustomerEmails = (ids) => {};

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createMassEmailCustomer")}</Typography>
          </Grid>

          <Grid item>
            <CenterSelect
              multiple
              value={state.filters.centers}
              onChange={handleFilterChange}
            />
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              columns={cols}
              rows={state.customerEmails.length > 0 ? state.customerEmails : []}
              checkboxSelection
              selectionModel={state.selectedCustomerEmailIds}
              onRowSelectionModelChange={(ids) => {
                selectCustomerEmails(ids);
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CreateMassEmailCustomerPage;
