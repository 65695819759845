import { Bar } from "react-chartjs-2";
import { Box, Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { PromotionContext } from "../PromotionPage";

import { colors } from "../../Dashboard/Components/ColorGenerator";
import { CustomTable } from "../../../CustomTable";
import ButtonLink from "../../../Inputs/ButtonLink";
import ContractStateBox from "../../Contracts/ContractStateBox";
import ItemsSummary from "../../../ItemsSummary";

import { getMonths } from "../../../../utils/chartUtils";

import { ACTIVE_CONTRACT_STATE_ID } from "../../../../data/constants";

function StatisticsTab() {
  const [t] = useTranslation("promotion");
  const { contracts } = useContext(PromotionContext);

  const TABLE_COLUMNS = [
    { label: t("publicId"), key: "publicId", sortType: "number" },
    {
      label: t("customerName"),
      key: "Customer.fullName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/customer/" + item.Customer.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("centerName"),
      key: "Box.Center.name",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/center/" + item.Box.Center.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    { label: t("startDate"), key: "startDate", sortType: "string" },
    {
      label: t("periodicity"),
      key: "Periodicity.name",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      label: t("state"),
      key: "state",
      sortType: "number",
      renderFunction: (value, item) => (
        <ContractStateBox
          state={value}
          isFrozen={item?.isFrozen}
          hasFreezePermission={false}
        />
      ),
    },
  ];

  const ITEMS_SUMMARY = [
    {
      translatedText: t("numberOfContracts"),
      value: contracts.length,
    },
    {
      translatedText: t("differentCustomers"),
      value: new Set(contracts.map((c) => c.Customer.id)).size,
    },
    {
      translatedText: t("differentCenters"),
      value: new Set(contracts.map((c) => c.Box.Center.id)).size,
    },
    {
      translatedText: t("totalActiveContracts"),
      value: contracts.filter((c) => c.state === ACTIVE_CONTRACT_STATE_ID)
        .length,
    },
  ];

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid
        item
        container
        xs={12}
        md={3}
        justifyContent="center"
        alignItems="center"
      >
        <CountDisplay count={contracts.length} />
      </Grid>
      <Grid item xs={12} md={9}>
        <PromotionChart contracts={contracts} />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <ItemsSummary gridItems={ITEMS_SUMMARY} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable columns={TABLE_COLUMNS} data={contracts} />
      </Grid>
    </Grid>
  );
}

function CountDisplay({ count }) {
  return (
    <Box
      sx={{
        width: "150px",
        height: "150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: 3,
        backgroundColor: "background.paper",
        borderRadius: 1,
      }}
    >
      <Box
        component="span"
        sx={{
          fontSize: "2rem",
          fontWeight: "bold",
        }}
      >
        {count}
      </Box>
    </Box>
  );
}

function PromotionChart({ contracts }) {
  const [t] = useTranslation("promotion");

  const dates = getMonths(
    new Date(Math.min(...contracts.map((c) => new Date(c.startDate)))),
    Date.now()
  );

  const labels = dates.map((date) => date.year + "-" + date.month);
  const values = new Array(dates.length).fill(0);

  dates.forEach((date, index) => {
    // Move-ins
    const moveIns = contracts.filter(
      (c) =>
        new Date(c.startDate).getFullYear() === date.year &&
        new Date(c.startDate).getMonth() + 1 === date.month
    );
    values[index] = moveIns.length;
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: t("contracts"),
        data: values,
        backgroundColor: colors.primary,
      },
    ],
  };

  const chartOptions = {};

  return <Bar data={chartData} options={chartOptions} />;
}

export default StatisticsTab;
