import AppContext from "../../../../context/AppContext";
import React, { useState, useContext, useEffect } from "react";

import { Button, ButtonGroup, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { getParams, generateURL } from "../../../../utils/url";

import ExportButton, {
  exportDataParse,
} from "../../../global/inputs/ExportButton";
import Page from "../../../global/structure/Page";
import Filters from "../../../global/structure/Filters";
import Select from "../../../global/inputs/Select";

import ButtonLink from "../../../Inputs/ButtonLink";
import CustomDate from "../../../Inputs/CustomDate";
import SearchButton from "../../../Inputs/SearchButton";
import TextInput from "../../../Inputs/TextInput";
import ItemsSummary from "../../../ItemsSummary";

import { CustomTable } from "../../../CustomTable";
import { localeFormat } from "../../../../utils/format";

import { TransferPaymentsContext, useQuery } from "../TransferPaymentsPage";

const filtersQuery = [
  { name: "search", type: "string" },
  { name: "fromDate", type: "date" },
  { name: "untilDate", type: "date" },
  { name: "importMin", type: "number" },
  { name: "importMax", type: "number" },
  { name: "debtorIBAN", type: "string" },
];

export const initialFilters = {
  search: "",
  fromDate: "",
  untilDate: "",
  importMin: "",
  importMax: "",
  debtorIBAN: "",
};
const initialLoaded = true;
const initialTransfers = [];

export default function TransferTab() {
  const { api, user } = useContext(AppContext);
  const { transferAccounts } = useContext(TransferPaymentsContext);

  const history = useHistory();
  const query = useQuery();

  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");

  const [filters, setFilters] = useState(initialFilters);
  const [loaded, setLoaded] = useState(initialLoaded);
  const [transfers, setTransfers] = useState(initialTransfers);

  useEffect(() => {
    const params = getParams(query, filtersQuery);
    setFilters({ ...filters, ...params });
  }, []);

  const TRANSFERENCE_SUMMARY = [
    {
      translatedText: t("numberOfTransfers"),
      value: localeFormat(transfers.length),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          transfers?.length > 0
            ? transfers.reduce((total, element) => total + element.amount, 0)
            : 0
        ) + "€",
    },
  ];

  const TRANSFER_COLUMNS = [
    { key: "concept", label: t("concept"), sortType: "string" },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "signatureDate", label: t("signatureDate"), sortType: "string" },
    { key: "creditorIBAN", label: t("creditorIBAN"), sortType: "string" },
    { key: "creditorName", label: t("businessName"), sortType: "string" },
    {
      key: "ProviderInvoice.title",
      label: t("providerInvoice"),
      sortType: "string",
      renderFunction: (value, item) =>
        value ? (
          <ButtonLink
            to={"/app/provider-invoice/" + item.providerInvoiceId}
            size="small"
          >
            {value || t("invoice")}
          </ButtonLink>
        ) : (
          t("deleted")
        ),
    },
    {
      key: "ProviderInvoice.invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "string",
      renderFunction: (value) => (value !== null ? value : t("deleted")),
    },
  ];

  const handleFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const getTransferPayments = () => {
    setLoaded(false);
    updateURL();
    const params = { include: ["ProviderInvoice"] };

    filters.debtorIBAN != "" && (params.debtorIBAN = filters.debtorIBAN);
    filters.search != "" && (params.search = filters.search);
    filters.fromDate != "" && (params.fromDate = filters.fromDate);
    filters.untilDate != "" && (params.untilDate = filters.untilDate);
    filters.importMax != "" && (params.importMax = filters.importMax);
    filters.importMin != "" && (params.importMin = filters.importMin);

    api
      .get("/transfer-payments", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noResults"), { variant: "warning" });
          setTransfers(response.data);
          // const url = generateURL("/app/transfer-payments", filters);
          // history.push(url);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const updateURL = () => {
    const url = generateURL("/app/transfer-payments", { tab: 1, ...filters });
    history.push(url);
  };

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                name="search"
                label={t("search")}
                value={filters.search}
                onChange={handleFilters}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    getTransferPayments();
                  }
                }}
              />,
              <CustomDate
                name="fromDate"
                label={t("fromDate")}
                value={filters.fromDate}
                onChange={handleFilters}
              />,
              <CustomDate
                name="untilDate"
                label={t("untilDate")}
                value={filters.untilDate}
                onChange={handleFilters}
              />,
              <TextInput
                name="importMin"
                label={t("importMin")}
                type="number"
                value={filters.importMin}
                onChange={handleFilters}
              />,
              <TextInput
                name="importMax"
                label={t("importMax")}
                type="number"
                value={filters.importMax}
                onChange={handleFilters}
              />,
              <Select
                label={t("transferAccount")}
                name="debtorIBAN"
                options={[
                  { value: "", label: t("all") },
                  ...transferAccounts
                    .map((account) => ({
                      value: account.IBAN,
                      label: account.IBAN,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
                ]}
                value={filters.debtorIBAN}
                onChange={handleFilters}
              />,
              <ButtonGroup variant="contained">
                <Button>{t("reset")}</Button>
                <ExportButton
                  data={exportDataParse(transfers, TRANSFER_COLUMNS)}
                  display={user.hasAction("EXPORT_DATA")}
                />
                <SearchButton onClick={getTransferPayments} />
              </ButtonGroup>,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <ItemsSummary gridItems={TRANSFERENCE_SUMMARY} />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={TRANSFER_COLUMNS}
            data={transfers}
            options={{ loaded: loaded }}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
