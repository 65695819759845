import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

function DateLabelsToggle({
  days = true,
  months = true,
  years = true,
  value,
  onChange,
}) {
  const [t] = useTranslation("dashboard");

  const handleOnChange = (event, value) => {
    if (!value) return;
    onChange(event, value);
  };

  return (
    <ToggleButtonGroup exclusive value={value} onChange={handleOnChange}>
      {days && (
        <ToggleButton size="small" value="days">
          {t("days")}
        </ToggleButton>
      )}
      {months && (
        <ToggleButton size="small" value="months">
          {t("months")}
        </ToggleButton>
      )}
      {years && (
        <ToggleButton size="small" value="years">
          {t("years")}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}

export default DateLabelsToggle;
