import { Box, CircularProgress, Grid } from "@mui/material";
import { useState, useContext, useEffect, createContext } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AppContext from "../../../../context/AppContext";

import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import Sales from "./Tabs/Sales";
import {
  CLUSTER_NAMES_ARRAY,
  COMPANY_CUSTOMER_TYPE_ID,
  FEMALE_GENDER_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  MALE_GENDER_ID,
} from "../../../../data/constants";

const initialFiltersData = {
  centers: [],
  provinces: [],
  regions: [],
  centerTypes: [],
  customerTypes: [],
  genders: [],
  keyAccounts: [],
  ageRanges: [],
  boxClusters: [],
};

export const MarketingContext = createContext();

function Marketing() {
  const [t] = useTranslation("dashboard");
  const [tErrors] = useTranslation("errors");
  const { api } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [filtersData, setFiltersData] = useState(initialFiltersData);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoaded(false);
      const [regions, centers] = await Promise.all([
        getRegions(api),
        getCenters(api),
      ]);

      if (regions?.error || centers?.error) {
        const error = regions?.error || centers?.error;
        return enqueueSnackbar(tErrors(error), { variant: "error" });
      }

      const autonomousCommunities = regions.filter((r) => r.parentId === null);
      const provinces = regions.filter((r) => r.parentId !== null);

      const customerTypes = [
        { label: t("all"), value: "" },
        { label: t("individual"), value: INDIVIDUAL_CUSTOMER_TYPE_ID },
        { label: t("company"), value: COMPANY_CUSTOMER_TYPE_ID },
      ];

      const genders = [
        { label: t("all"), value: "" },
        { label: t("male"), value: MALE_GENDER_ID },
        { label: t("female"), value: FEMALE_GENDER_ID },
      ];

      const keyAccounts = [
        { label: t("all"), value: "" },
        { label: t("yes"), value: true },
        { label: t("no"), value: false },
      ];

      const ageRanges = [
        { label: " < 20", value: 1, range: { min: 0, max: 19 } },
        { label: "21 - 25", value: 2, range: { min: 21, max: 25 } },
        { label: "26 - 30", value: 3, range: { min: 26, max: 30 } },
        { label: "31 - 35", value: 4, range: { min: 31, max: 35 } },
        { label: "36 - 40", value: 5, range: { min: 36, max: 40 } },
        { label: "41 - 45", value: 6, range: { min: 41, max: 45 } },
        { label: "46 - 50", value: 7, range: { min: 46, max: 50 } },
        { label: "51 - 55", value: 8, range: { min: 51, max: 55 } },
        { label: "56 - 60", value: 9, range: { min: 56, max: 60 } },
        { label: "61 - 65", value: 10, range: { min: 61, max: 65 } },
        { label: " > 65", value: 11, range: { min: 66, max: 130 } },
      ];

      const boxClusters = CLUSTER_NAMES_ARRAY.map((name) => ({
        label: name,
        value: name,
      }));

      setFiltersData({
        centers: centers.sort((a, b) => a.name.localeCompare(b.name)),
        autonomousCommunities: autonomousCommunities.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        provinces: provinces.sort((a, b) => a.name.localeCompare(b.name)),
        customerTypes,
        genders,
        keyAccounts,
        ageRanges,
        boxClusters,
      });

      setLoaded(true);
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <MarketingContext.Provider value={{ ...filtersData, loaded }}>
      <Page browserTitle={t("marketing")} paper={true}>
        <Grid item>
          <Tabs
            tabs={[
              {
                label: t("sales"),
                content: <Sales />,
              },
            ]}
          />
        </Grid>
      </Page>
    </MarketingContext.Provider>
  );
}

const getCenters = async (api) => {
  return api
    .get("/centers", {
      params: {
        include: ["City", "BoxCluster"],
      },
    })
    .then((res) => res.data)
    .catch((e) => ({ error: e }));
};

const getRegions = async (api) => {
  return api
    .get("/regions")
    .then((res) => res.data)
    .catch((e) => ({ error: e }));
};

export default Marketing;
