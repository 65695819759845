import Select from "../global/inputs/Select";
import { useTranslation } from "react-i18next";
import {
  BLOCKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
} from "../../data/constants";

function BoxStateFilter({ state = [], setState }) {
  const [t] = useTranslation("boxes");
  return (
    <Select
      name="state"
      label={t("state")}
      value={state}
      onChange={setState}
      multiple
      options={[
        { value: FREE_BOX_STATE_ID, label: t("free") },
        { value: OCCUPIED_BOX_STATE_ID, label: t("occupied") },
        { value: BLOCKED_BOX_STATE_ID, label: t("blocked") },
        { value: UNAVAILABLE_BOX_STATE_ID, label: t("unavailable") },
        { value: BOOKED_BOX_STATE_ID, label: t("booked") },
      ]}
    />
  );
}

export default BoxStateFilter;
