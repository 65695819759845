import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import EditLayerNameDialog from "./Dialogs/EditLayerNameDialog";
import EditFloorPlanDialog from "./Dialogs/EditFloorPlanDialog";
import Tabs from "../../../global/structure/Tabs";
import LayerEditor from "./LayerEditor";
import TileEditor from "./PropertiesEditor/TileEditor";
import { FloorPlanContext } from "../FloorPlanPage";

const FloorPlanEditor = (props) => {
  const [t] = useTranslation("floorPlans");
  const { floorPlan } = props;

  const {
    editItem,
    selectTile,
    createLayer,
    deleteLayer,
    editLayer,
    editFloorPlan,
  } = useContext(FloorPlanContext);

  const [currentTab, setTab] = useState(0);
  const [selectedLayerId, setSelectedLayer] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    floorPlan?.id !== undefined &&
      (selectedLayerId === null || selectedLayerId === undefined) &&
      setSelectedLayer(floorPlan.FloorPlanLayers[0]?.id);
  }, [floorPlan]);

  let tabs =
    floorPlan?.FloorPlanLayers?.map((layer) => {
      return {
        label: layer.name,
        selectedLabel: (
          <SelectedTabLabel
            layer={layer}
            onDelete={() => {
              deleteLayer(layer.id);
            }}
            onEdit={(newName) => editLayer({ name: newName }, layer.id)}
          />
        ),
        content: <LayerEditor layer={layer} />,
      };
    }) || [];

  return (
    <Paper>
      {editItem ? (
        <TileEditor item={editItem} onCancelEditItem={() => selectTile(null)} />
      ) : (
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center" padding={2}>
            <Typography variant="h5">
              {floorPlan?.Center?.name} - {floorPlan?.name}
            </Typography>
            <IconButton onClick={() => setIsDialogOpen(true)}>
              <EditIcon color="primary" />
            </IconButton>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row">
            <Typography variant="h6" padding={2}>
              {t("layers")}
            </Typography>
            <Button size="small" startIcon={<AddIcon />} onClick={createLayer}>
              {t("addLayer")}
            </Button>
          </Box>
          <Tabs
            onTabChange={(e, value) => setTab(value)}
            currentTab={currentTab}
            tabs={tabs}
          />
          <EditFloorPlanDialog
            isOpen={isDialogOpen}
            onConfirm={(data) => {
              editFloorPlan(floorPlan.id, data);
              setIsDialogOpen(false);
            }}
            onClose={() => setIsDialogOpen(false)}
            data={{
              name: floorPlan.name,
              width: floorPlan.width,
              height: floorPlan.height,
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

const SelectedTabLabel = (props) => {
  const { layer, onDelete, onEdit } = props;
  const [t] = useTranslation("floorPlans");

  const [anchor, setAnchor] = useState(null);
  const isOpen = Boolean(anchor);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {layer.name}
      <IconButton
        size="small"
        color="primary"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <EditIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchor(null);
            setIsDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          {t("changeName")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete && onDelete();
            setAnchor(null);
          }}
          disabled={layer.FloorPlanTiles.length > 0}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("delete")}</ListItemText>
        </MenuItem>
      </Menu>
      <EditLayerNameDialog
        isOpen={isDialogOpen}
        name={layer.name}
        onConfirm={(newName) => {
          onEdit(newName);
          setIsDialogOpen(false);
        }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
    </Box>
  );
};

FloorPlanEditor.propTypes = {
  floorPlan: PropTypes.object,
};

export default FloorPlanEditor;
