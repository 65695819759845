import { Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import CenterReportContext from "../CenterReportContext";

import { CustomTable } from "../../../../CustomTable";
import { localeFormat } from "../../../../../utils/format";
import ButtonCSV from "../../../../Inputs/ButtonCSV";
import ButtonLink from "../../../../Inputs/ButtonLink";
import CustomDate from "../../../../Inputs/CustomDate";
import Filters from "../../../../global/structure/Filters";
import ItemsSummary from "../../../../ItemsSummary";
import Select from "../../../../global/inputs/Select";
import TextInput from "../../../../Inputs/TextInput";

import {
  HistoricalInvoiceItems,
  MerchantableTypeDoughnut,
  PaymentMethodDoughnut,
  SerieDoughnut,
} from "../../Charts/CenterReport";

const initialFilters = {
  concept: "",
  dateFrom: "",
  dateUntil: "",
  merchantableType: [],
  serie: [],
  paymentMethod: [],
  billingError: "",
};

function InvoicingTab() {
  const [t] = useTranslation("dashboard");
  const { invoiceItems, merchantableTypes, invoiceSeries, paymentMethods } =
    useContext(CenterReportContext);
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filteredInvoiceItems = filterInvoiceItems(invoiceItems, filters);
  const parsedInvoiceItems = filteredInvoiceItems.map((item) => ({
    ...item,
    invoiceNumber: item.Invoice.number,
    concept: item.concept,
    amount: Number((item.units * item.pricePerUnit).toFixed(2)),
    date: item.Invoice.issueDate,
    serie: item.Invoice.InvoiceSerie.name,
    paymentMethod: item.Invoice.PaymentMethod?.name,
    billingError: item.Invoice.billingError ? t("yes") : t("no"),
    center: item.Merchantable.Center.name,
    merchantableType:
      item.Merchantable && item.Merchantable.MerchantableType
        ? item.Merchantable.MerchantableType.name
        : t("notAssigned"),
  }));

  const completeMerchantableTypes = [
    ...merchantableTypes,
    {
      id: merchantableTypes[merchantableTypes.length - 1].id + 1,
      name: t("notAssigned"),
    },
  ];

  const totalAmount = parsedInvoiceItems.reduce(
    (acc, item) => acc + Math.abs(item.amount),
    0
  );
  const TABLE_COLUMNS = [
    {
      key: "invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "number",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/invoice/" + item.Invoice.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "concept",
      label: t("concept"),
      sortType: "string",
    },
    {
      key: "amount",
      label: t("amount"),
      renderFunction: (value) =>
        localeFormat(Math.abs(value)) +
        " €" +
        ` (${localeFormat((Math.abs(value) / totalAmount) * 100)}%)`,
      sortType: "number",
    },
    {
      key: "date",
      label: t("date"),
      sortType: "string",
    },
    {
      key: "serie",
      label: t("serie"),
      sortType: "string",
    },
    {
      key: "paymentMethod",
      label: t("paymentMethod"),
      sortType: "string",
    },
    {
      key: "billingError",
      label: t("billingError"),
      sortType: "string",
    },
    {
      key: "merchantableType",
      label: t("merchantableType"),
      sortType: "string",
    },
  ];

  const CSV_HEADERS = [
    {
      label: t("invoiceNumber"),
      key: "invoiceNumber",
    },
    {
      label: t("concept"),
      key: "concept",
    },
    {
      label: t("amount"),
      key: "amount",
    },
    {
      label: t("date"),
      key: "date",
    },
    {
      label: t("serie"),
      key: "serie",
    },
    {
      label: t("paymentMethod"),
      key: "paymentMethod",
    },
    {
      label: t("billingError"),
      key: "billingError",
    },
    {
      label: t("merchantableType"),
      key: "merchantableType",
    },
  ];

  const billingErrorAmount = parsedInvoiceItems.reduce(
    (acc, item) =>
      acc + (item.Invoice.billingError ? Math.abs(item.amount) : 0),
    0
  );
  const allAmount = parsedInvoiceItems.reduce(
    (acc, item) => acc + Math.abs(item.amount),
    0
  );
  const billingErrorAmountPercentage = allAmount
    ? (billingErrorAmount / allAmount) * 100
    : 0;
  const SUMMARY = [
    {
      translatedText: t("totalInvoices"),
      value: localeFormat(parsedInvoiceItems.length),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          parsedInvoiceItems.reduce((acc, item) => acc + item.amount, 0)
        ) + "€",
    },
    {
      translatedText: t("totalPositiveAmount"),
      value:
        localeFormat(
          parsedInvoiceItems.reduce(
            (acc, item) => acc + (item.amount > 0 ? item.amount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalNegativeAmount"),
      value:
        localeFormat(
          parsedInvoiceItems.reduce(
            (acc, item) => acc + (item.amount < 0 ? item.amount * -1 : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalBillingError"),
      value:
        localeFormat(billingErrorAmount) +
        `€ (${localeFormat(billingErrorAmountPercentage)}%)`,
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              name="concept"
              label={t("concept")}
              value={filters.concept}
              onChange={handleFilterChange}
            />,
            <CustomDate
              name="dateFrom"
              label={t("dateFrom")}
              value={filters.dateFrom}
              onChange={handleFilterChange}
            />,
            <CustomDate
              name="dateUntil"
              label={t("dateUntil")}
              value={filters.dateUntil}
              onChange={handleFilterChange}
            />,
            <Select
              name="merchantableType"
              label={t("merchantableType")}
              value={filters.merchantableType}
              onChange={handleFilterChange}
              multiple
              options={completeMerchantableTypes.map((type) => ({
                value: type.id,
                label: type.name,
              }))}
            />,
            <Select
              name="paymentMethod"
              label={t("paymentMethod")}
              value={filters.paymentMethod}
              onChange={handleFilterChange}
              multiple
              options={paymentMethods.map((method) => ({
                value: method.id,
                label: method.name,
              }))}
            />,
            <Select
              name="serie"
              label={t("serie")}
              value={filters.serie}
              onChange={handleFilterChange}
              multiple
              options={invoiceSeries.map((serie) => ({
                value: serie.id,
                label: serie.name,
              }))}
            />,
            <Select
              name="billingError"
              label={t("billingError")}
              value={filters.billingError}
              onChange={handleFilterChange}
              options={[
                { value: "", label: t("all") },
                { value: "yes", label: t("yes") },
                { value: "no", label: t("no") },
              ]}
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <ItemsSummary gridItems={SUMMARY} />
      </Grid>
      <Grid item xs={12}>
        <HistoricalInvoiceItems
          invoiceItems={filteredInvoiceItems}
          series={invoiceSeries}
          merchantableTypes={completeMerchantableTypes}
          paymentMethods={paymentMethods}
        />
      </Grid>
      <Grid item xs={4}>
        <SerieDoughnut
          invoiceItems={filteredInvoiceItems}
          series={invoiceSeries}
        />
      </Grid>
      <Grid item xs={4}>
        <PaymentMethodDoughnut
          invoiceItems={filteredInvoiceItems}
          paymentMethods={paymentMethods}
        />
      </Grid>
      <Grid item xs={4}>
        <MerchantableTypeDoughnut
          invoiceItems={filteredInvoiceItems}
          merchantableTypes={completeMerchantableTypes}
        />
      </Grid>
      <Grid item container justifyContent="flex-end" marginTop={1}>
        <ButtonCSV
          data={parsedInvoiceItems}
          headers={CSV_HEADERS}
          filename={t("invoiceItemsReport")}
        />
      </Grid>
      <Grid item xs={12} marginTop={1}>
        <CustomTable columns={TABLE_COLUMNS} data={parsedInvoiceItems} />
      </Grid>
    </Grid>
  );
}

const filterInvoiceItems = (invoiceItems, filters) => {
  return invoiceItems.filter((item) => {
    if (filters.concept !== "" && !item.concept.includes(filters.concept))
      return false;
    if (
      filters.dateFrom !== "" &&
      new Date(item.Invoice.issueDate) < new Date(filters.dateFrom)
    )
      return false;
    if (
      filters.dateUntil !== "" &&
      new Date(item.Invoice.issueDate) > new Date(filters.dateUntil)
    )
      return false;
    if (
      filters.merchantableType.length &&
      !filters.merchantableType.includes(
        item.Merchantable && item.Merchantable.MerchantableType
          ? item.Merchantable.merchantableTypeId
          : 10
      )
    )
      return false;
    if (
      filters.paymentMethod.length &&
      !filters.paymentMethod.includes(item.Invoice.paymentMethodId)
    )
      return false;
    if (
      filters.serie.length &&
      !filters.serie.includes(item.Invoice.InvoiceSerie.id)
    )
      return false;
    if (
      filters.billingError !== "" &&
      (filters.billingError === "yes") !== item.Invoice.billingError
    )
      return false;
    return true;
  });
};

export default InvoicingTab;
