import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../context/AppContext";

import TextInput from "./TextInput";

const CustomerInput = (props) => {
  const { label, name, onChange, value, helperText, disabled } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [customers, setCustomers] = React.useState([]);
  const { api } = React.useContext(AppContext);
  const [t] = useTranslation("customers");

  React.useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    const params = {
      attributes: ["id", "name", "surnames", "fullName"],
    };
    api
      .get("/customers", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error.toString(), {
            variant: "error",
          });
        else
          setCustomers(
            response.data.map((customer) => ({
              name: customer.fullName,
              id: customer.id,
            }))
          );
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const filterOptions = createFilterOptions({
    limit: 10,
  });

  return (
    <Autocomplete
      name={name}
      options={customers}
      size="small"
      sx={{ minWidth: "200px" }}
      disabled={disabled}
      getOptionLabel={(customer) => customer.name || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={filterOptions}
      value={value}
      onChange={(e, customer) => {
        onChange({ target: { name: name, value: customer } });
      }}
      fullWidth
      renderInput={(params) => (
        <TextInput
          error={helperText}
          helperText={
            helperText ? t("customer") + " " + t("mustNotBeBlank") : ""
          }
          size="small"
          label={label}
          {...params}
        />
      )}
    />
  );
};

export default CustomerInput;
