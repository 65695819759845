import { Box, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import AppContext from "../../../context/AppContext";

import { today as getToday, formatDate } from "../../../utils/date";
import FloorPlan from "./FloorPlan";
import FloorPlanEditor from "./Editor/FloorPlanEditor";
import Page from "../../global/structure/Page";

import {
  TILE_BLUE_COLOR,
  TILE_RED_COLOR,
  TILE_WHITE_COLOR,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BLOCKED_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
} from "../../../data/constants";

const BOX_COLORS_STATES = [
  TILE_BLUE_COLOR,
  TILE_RED_COLOR,
  TILE_WHITE_COLOR,
  TILE_WHITE_COLOR,
  TILE_WHITE_COLOR,
];

const initialState = {
  floorPlan: {},
  boxes: [],
  cameras: [],
  doors: [],
  createImageDialogIsOpen: false,
};

export const FloorPlanContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "SET_FLOOR_PLAN":
      return { ...state, floorPlan: action.payload };
    case "SET_CREATE_IMAGE_DIALOG":
      return { ...state, createImageDialogIsOpen: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_CAMERAS":
      return { ...state, cameras: action.payload };
    case "SET_DOORS":
      return { ...state, doors: action.payload };
    default:
      throw new Error("Action not found in reducer.");
  }
}

const FloorPlanPage = () => {
  const [t] = useTranslation("floorPlans");
  const [tErrors] = useTranslation("errors");
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [boxes, setBoxes] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [doors, setDoors] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [floorPlanData, setFloorPlanData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const selectTile = (tileId) => {
    // If there's a selected tile, put it back into its layer
    if (selectedItem) {
      let layer = floorPlanData.FloorPlanLayers.find(
        (layer) => layer.id === selectedItem.floorPlanLayerId
      );
      if (!layer) return;
      // TODO with use state
      layer.FloorPlanTiles.push(selectedItem);
      setFloorPlanData(floorPlanData);
    }

    let newSelectedTile = null;
    // Find and remove new selected tile from its layer
    let layer = floorPlanData.FloorPlanLayers.find((layer) =>
      layer.FloorPlanTiles.some((tile) => tile.id === tileId)
    );
    if (layer) {
      newSelectedTile = layer.FloorPlanTiles.find((tile) => tile.id === tileId);
      let index = layer.FloorPlanTiles.indexOf(newSelectedTile);
      layer.FloorPlanTiles.splice(index, 1);
    }
    // Set selectedTile and editItem
    setEditItem(newSelectedTile);
    setSelectedItem(newSelectedTile);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getCameras();
    getBoxes();
    getDoors();
  }, [state.floorPlan]);

  const getData = () => {
    const params = {
      include: ["FloorPlanTile", "FloorPlanLayer", "Box", "Center"],
    };
    api
      .get("/floor-plans/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        const floorPlan = response.data;
        floorPlan?.FloorPlanLayers.sort((a, b) => (a.z > b.z ? 1 : -1));
        floorPlan?.FloorPlanLayers.forEach((layer) => {
          layer.FloorPlanTiles.forEach((tile) => {
            if (tile.color === "") tile.color = null;
            if (tile.color === null && tile.Boxes.length) {
              tile.color = BOX_COLORS_STATES[getBoxState(tile.Boxes[0])];
            }
          });
        });
        dispatch({ type: "SET_FLOOR_PLAN", payload: response.data });
        setFloorPlanData(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBoxState = (box) => {
    let state = box.state > -1 ? box.state : UNAVAILABLE_BOX_STATE_ID;
    const today = getToday();
    const lastContract = box.Contracts.length
      ? box.Contracts[box.Contracts.length - 1]
      : null;
    if (!lastContract || state > 2) return state;
    const lastContractEnd = lastContract.endDate
      ? formatDate(new Date(lastContract.endDate))
      : null;
    if (lastContractEnd && lastContractEnd < today) return FREE_BOX_STATE_ID;
    return OCCUPIED_BOX_STATE_ID;
  };

  const getBoxes = () => {
    if (!state.floorPlan.centerId) return;
    const params = { centers: [state.floorPlan.centerId] };
    api
      .get(`/boxes`, { params })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          return;
        }
        setBoxes(res.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const getCameras = () => {
    if (!state.floorPlan.centerId) return;
    const params = { centerId: state.floorPlan.centerId };
    api
      .get(`/surveillance/cameras`, { params })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          return;
        }
        setCameras(res.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const getDoors = () => {
    api
      .get("/access-control/doors")
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          return;
        } else {
          setDoors(res.data);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const deleteLayer = (layerId) => {
    api
      .post(`/floor-plans/layer/delete/${layerId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        const newArray = state.floorPlan.FloorPlanLayers.filter(
          (layer) => layer.id !== layerId
        );
        //TODO update tab

        dispatch({
          type: "SET_FLOOR_PLAN",
          payload: { ...state.floorPlan, FloorPlanLayers: newArray },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createLayer = () => {
    try {
      const layer = {
        name: "Layer " + (state.floorPlan.FloorPlanLayers.length + 1),
        floorPlanId: state.floorPlan.id,
      };
      if (!layer || !layer.name || !layer.floorPlanId) {
        //TODO
        enqueueSnackbar(t("layerNameAndFloorPlanIdRequired"), {
          variant: "error",
        });
        return;
      }
      api.post("/floor-plans/layer/create", layer).then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        getData();

        //TODO update tab

        //TODO: only update layer
      });
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
    }
  };

  const deleteTile = (tileId) => {
    api
      .post(`/floor-plans/tile/delete/${tileId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        const newArray = state.floorPlan.FloorPlanLayers.map((layer) => {
          layer.FloorPlanTiles = layer.FloorPlanTiles.filter(
            (tile) => tile.id !== tileId
          );
          return layer;
        });
        dispatch({
          type: "SET_FLOOR_PLAN",
          payload: { ...state.floorPlan, FloorPlanLayers: newArray },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createTile = (tile) => {
    let data = tile;
    if (tile.shape === "Image") {
      data = new FormData();
      Object.entries(tile).forEach((entry) => data.append(entry[0], entry[1]));
    }
    try {
      api.post("/floor-plans/tile/create", data).then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          let newTile = response.data;
          let newFloorPlan = state.floorPlan;

          for (let layer of newFloorPlan.FloorPlanLayers) {
            if (layer.id === newTile.floorPlanLayerId) {
              layer.FloorPlanTiles.push(newTile);
              break;
            }
          }
          dispatch({
            type: "SET_FLOOR_PLAN",
            payload: newFloorPlan,
          });
        }
      });
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
    }
  };

  const getLayer = (layerId) => {
    return new Promise((resolve, reject) => {
      const params = {
        include: ["FloorPlanTile", "Box"],
      };
      api
        .get(`/floor-plans/layer/${layerId}`, { params })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
            resolve(response.data.error);
            return;
          } else {
            const newArray = state.floorPlan.FloorPlanLayers.map((layer) => {
              if (layer.id === layerId) {
                return {
                  ...response.data,
                  FloorPlanTiles: response.data.FloorPlanTiles.map((tile) => {
                    if (tile.color === null && tile.Boxes.length) {
                      tile.color =
                        tile.Boxes[0].state > -1
                          ? BOX_COLORS_STATES[tile.Boxes[0].state]
                          : BOX_COLORS_STATES[2];
                    }
                    return tile;
                  }),
                };
              }
              return layer;
            });
            dispatch({
              type: "SET_FLOOR_PLAN",
              payload: { ...state.floorPlan, FloorPlanLayers: newArray },
            });
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          reject(error);
          return;
        });
    });
  };

  const editTile = (tile) => {
    return new Promise((resolve, reject) => {
      if (!tile) {
        reject(t("errorEditingTile"));
        return;
      }

      let data = tile;
      if (tile.shape === "Image") {
        data = new FormData();
        Object.entries(tile).forEach(([key, value]) => {
          data.append(key, value);
        });
      }

      api
        .post(`/floor-plans/tile/${tile.id}`, data)
        .then(async (response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
            return;
          } else {
            getData();
          }
          resolve(response.data);
          return;
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          return;
        });
    });
  };

  const createBoxFloorPlanTile = (boxId, floorPlanTileId) => {
    if (!boxId || !floorPlanTileId) {
      enqueueSnackbar(t("somethingWentWrong"), { variant: "error" });
      return;
    }

    const params = {
      BoxId: boxId,
      FloorPlanTileId: floorPlanTileId,
    };

    api
      .post("/floor-plans/box-tile/create", params)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        getData();
        enqueueSnackbar(t("boxAdded"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const deleteBoxFloorPlanTile = (boxId, floorPlanTileId) => {
    if (!boxId || !floorPlanTileId) {
      enqueueSnackbar(t("somethingWentWrong"), { variant: "error" });
      return;
    }

    const params = {
      BoxId: boxId,
      FloorPlanTileId: floorPlanTileId,
    };

    api
      .post("/floor-plans/box-tile/delete", params)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        getData();
        enqueueSnackbar(t("boxRemoved"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const editLayer = (data, layerId) => {
    api
      .post(`/floor-plans/layer/${layerId}`, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        const newLayers = state.floorPlan.FloorPlanLayers.map((layer) =>
          layer.id === layerId ? { ...layer, ...data } : layer
        );
        dispatch({
          type: "SET_FLOOR_PLAN",
          payload: { ...state.floorPlan, FloorPlanLayers: newLayers },
        });
        enqueueSnackbar(t("layerEditedSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editFloorPlan = (floorPlanId, data) => {
    api
      .post(`/floor-plans/${floorPlanId}`, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({
          type: "SET_FLOOR_PLAN",
          payload: { ...state.floorPlan, ...data },
        });
        enqueueSnackbar(t("floorPlanEditedSuccessfully"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setTileAssociation = async (tileId, externalId) => {
    const data = {
      externalId: externalId === "" ? null : externalId,
    };
    return new Promise((resolve, reject) => {
      api
        .post(`/floor-plans/tile/${tileId}/associate`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };

  return (
    <Page browserTitle={t("floorPlan")} title={t("floorPlan")}>
      <FloorPlanContext.Provider
        value={{
          boxes,
          cameras,
          doors,
          editItem,
          selectedItem,
          createLayer,
          createTile,
          deleteLayer,
          deleteTile,
          doors,
          editTile,
          selectTile,
          setEditItem,
          setSelectedItem,
          editLayer,
          editFloorPlan,
          setTileAssociation,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Box height={700}>
              <FloorPlan
                floorPlan={state.floorPlan}
                editTile={editItem}
                onEditTile={setEditItem}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <FloorPlanEditor
              floorPlan={state.floorPlan}
              cameras={state.cameras}
              boxes={state.boxes}
              doors={state.doors}
              onDeleteLayer={deleteLayer}
              onCreateLayer={createLayer}
              onDeleteItem={deleteTile}
              onCreateItem={createTile}
              onEditItem={editTile}
              deleteBoxFloorPlanTile={deleteBoxFloorPlanTile}
              createBoxFloorPlanTile={createBoxFloorPlanTile}
              onEditLayer={editLayer}
            />
          </Grid>
        </Grid>
      </FloorPlanContext.Provider>
    </Page>
  );
};

export default FloorPlanPage;
