import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PropTypes, { oneOfType } from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import Select from "../global/inputs/Select";

import AppContext from "../../context/AppContext";

/**
 * PaymentMethodInput Component
 *
 * This component renders a select input for choosing payment methods.
 * It provides predefined options and supports single or multiple selections.
 *
 * @param {Object} props - The props for the PaymentMethodInput component.
 * @param {boolean} [props.multiple] - If true, allows multiple selections.
 * @param {Function} props.onChange - A callback function to handle changes in the selected value(s).
 * @param {string} [props.value] - The initial value of the select input.
 * @param {string} [props.label] - The label to display above the select input.
 * @returns {JSX.Element} - A React component for selecting payment methods.
 */

const PaymentMethodInput = (props) => {
  const {
    multiple,
    onChange,
    value = multiple ? [] : "",
    label,
    ...rest
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMethod, setSelectedMethod] = useState(value);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [t] = useTranslation("invoices");
  const { api } = useContext(AppContext);

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          setPaymentMethods(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  useEffect(getPaymentMethods, []);
  useEffect(() => setSelectedMethod(value), [value]);

  let options = [
    ...paymentMethods.map((m) => ({ value: m.id, label: t(m.name) })),
  ];

  if (!multiple) {
    options = [{ value: "", label: t("all") }, ...options];
  }

  return (
    <Select
      label={label || t("paymentMethods")}
      multiple={multiple}
      sx={{ maxWidth: 230 }}
      value={selectedMethod}
      options={options}
      onChange={(e) => {
        setSelectedMethod(e.target.value);
        onChange && onChange(e);
      }}
      {...rest}
    />
  );
};

PaymentMethodInput.propTypes = {
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
};

export default PaymentMethodInput;
