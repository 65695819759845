import React, { useContext, useEffect, useReducer } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Icons
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CustomSelect from "../../Inputs/CustomSelect";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SearchIcon from "@mui/icons-material/Search";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import FileCopyIcon from "@mui/icons-material/FileCopy";

// Components & utils
import { CustomTable } from "../../CustomTable";
import { formatDate } from "../../../utils/chartUtils";
import { localeFormat } from "../../../utils/format";
import { round } from "../../../utils/math";
import AppContext from "../../../context/AppContext";
import BankingTransactionsDialog from "./BankingTransactionsDialog";
import ButtonLink from "../../Inputs/ButtonLink";
import CenterSelect from "../../Inputs/CenterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import CustomDate from "../../Inputs/CustomDate";
import ExpenseTypeSelect from "../../Inputs/ExpenseTypeSelect";
import ItemsSummary from "../../ItemsSummary";
import TextInput from "../../Inputs/TextInput";
import Select from "../../global/inputs/Select";

const initialState = {
  bankingTransactionDialog: false,
  centers: [],
  confirmDialog: {
    childrenText: "",
    isOpen: false,
    title: "",
    callback: () => {},
  },
  form: {
    amount: 0,
    centerId: "",
    createdBy: "",
    date: "",
    description: "",
    dueDate: "",
    defaultTransferAccountId: "",
    expenseTypeId: "",
    fileRequired: true,
    fileType: "",
    invoiceNumber: "",
    isAuthorized: false,
    isReconciliated: false,
    isTicket: false,
    items: [],
    paidAt: "",
    paymentMethodId: "",
    personalIncomeTax: 0,
    provider: null,
    providerInvoiceAuthorizations: [],
    providerInvoiceCenters: [],
    title: "",
    vat: 0,
  },
  inputError: {
    amount: false,
    centerId: false,
    createdBy: false,
    date: false,
    description: false,
    expenseTypeId: false,
    invoiceNumber: false,
    paidAt: false,
    provider: false,
    title: false,
  },
  options: {
    loaded: false,
  },
  paymentMethods: [],
  providerInvoiceItem: {
    amount: "",
    vatPercentage: "",
  },
  providerInvoiceCenters: {
    centerIds: [],
    amountCenters: "",
  },
  editable: true,
  providers: [],
  transferAccounts: [],
  transferPayments: [],
  saved: true,
  sendAuthorizationRequestEmailLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET_CENTER_INPUT":
      return {
        ...state,
        providerInvoiceCenters: initialState.providerInvoiceCenters,
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_ITEM_INPUT":
      return {
        ...state,
        providerInvoiceItem: initialState.providerInvoiceItem,
      };
    case "SET_BANKING_TRANSACTIONS":
      return {
        ...state,
        form: {
          ...state.form,
          bankingTransactions: action.payload,
        },
      };
    case "SET_BANKING_TRANSACTION_MODAL":
      return {
        ...state,
        bankingTransactionDialog: !state.bankingTransactionDialog,
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_ITEM_INPUT":
      return {
        ...state,
        providerInvoiceItem: {
          ...state.providerInvoiceItem,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    case "SET_PROVIDER_INVOICE":
      return { ...state, form: action.payload };
    case "SET_PROVIDER_INVOICE_CENTERS_INPUT":
      return {
        ...state,
        providerInvoiceCenters: {
          ...state.providerInvoiceCenters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_TRANSFER_PAYMENTS":
      return { ...state, transferPayments: action.payload };
    case "SET_SAVED_VALUE":
      return { ...state, saved: action.payload.value };
    case "SET_SEND_AUTHORIZATION_REQUEST_EMAIL_LOADING":
      return {
        ...state,
        sendAuthorizationRequestEmailLoading: action.payload,
      };
    case "SET_EDITED_VALUE":
      return { ...state, editable: action.payload.value };
    case "SET_TRANSFER_ACCOUNTS":
      return { ...state, transferAccounts: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function ProviderInvoicePage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [t] = useTranslation("providerInvoices");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  const BANKING_TRANSACTION_COLUMNS = [
    { key: "transactionDate", label: t("transactionDate"), sortType: "string" },
    { key: "concept", label: t("concept"), sortType: "string" },
    { key: "valueDate", label: t("valueDate"), sortType: "string" },
    {
      key: "import",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "comments", label: t("comments"), sortType: "string" },
    {
      key: "auditDate",
      label: t("audited"),
      sortType: "string",
      renderFunction: (value) => value && <CheckIcon color="success" />,
    },
    {
      key: "hasFile",
      label: t("file"),
      sortType: "number",
      renderFunction: (value, item) =>
        value ? (
          <InsertDriveFileIcon
            color="success"
            onClick={(e) => {
              e.preventDefault();
              showBankingTransactionFile(item);
            }}
          />
        ) : (
          <InsertDriveFileIcon color="disabled" />
        ),
    },
  ];

  const TRANSFER_PAYMENT_COLUMNS = [
    { key: "creditorName", label: t("creditorName"), sortType: "string" },
    { key: "creditorIBAN", label: t("creditorIBAN"), sortType: "string" },
    { key: "signatureDate", label: t("date"), sortType: "date" },
    {
      key: "amount",
      label: t("amount"),
      renderFunction: (value) => localeFormat(value) + "€",
      sortType: "number",
    },
    { key: "concept", label: t("provider"), sortType: "string" },
    {
      key: "transferPaymentGroupId",
      label: t("actions"),
      sortType: "",
      renderFunction: (value) => (
        <Tooltip title={t("transferGroup")}>
          <ButtonLink
            to={"/app/transfer-payment-group/" + value}
            size="small"
            sx={{ padding: 0 }}
          >
            <SyncAltIcon />
          </ButtonLink>
        </Tooltip>
      ),
    },
  ];

  const PROVIDER_INVOICE_COLUMNS = [
    {
      field: "amount",
      headerName: t("amount"),
      editable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "vatPercentage",
      headerName: t("vatPercentage"),
      editable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "%",
    },
    {
      field: "vat",
      headerName: t("vat"),
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) => localeFormat(row.vat) + "€",
    },
    {
      field: "total",
      headerName: t("total"),
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) => localeFormat(row.amountWithVat) + "€",
    },
    {
      field: "actions",
      headerName: t("actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const onEdit = (e) => {
          e.stopPropagation();
          editProviderInvoiceItem(params.row);
        };
        const onDelete = (e) => {
          e.stopPropagation();
          deleteProviderInvoiceItem(params.row.id);
        };
        return (
          <Grid item container spacing={1}>
            <Grid item>
              <Tooltip title={t("confirmEdit")} placement="top">
                <IconButton size="small" color="primary" onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("delete")} placement="top">
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const PROVIDER_INVOICE_CENTER_COLUMNS = [
    {
      field: "center",
      headerName: t("center"),
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) =>
        state.centers.find((center) => center.id === Number(row.centerId))
          ?.name,
    },
    {
      field: "amount",
      headerName: t("amount"),
      editable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "actions",
      headerName: t("actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const onEdit = (e) => {
          e.stopPropagation();
          editProviderInvoiceCenter(params.row);
        };
        const onDelete = (e) => {
          e.stopPropagation();
          deleteProviderInvoiceCenter(params.row.id);
        };
        return (
          <Grid item container spacing={1}>
            <Grid item>
              <Tooltip title={t("confirmEdit")} placement="top">
                <IconButton size="small" color="primary" onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("delete")} placement="top">
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const ITEMS_SUMMARY = [
    {
      translatedText: t("numberOfRows"),
      value: state.form.providerInvoiceCenters.length,
    },
    {
      translatedText: t("invoiceBaseAmount"),
      value: localeFormat(state.form.amount) + "€",
    },
    {
      translatedText: t("itemsAmount"),
      value:
        localeFormat(
          state.form.providerInvoiceCenters.reduce(
            (acc, item) => acc + Number(item.amount),
            0
          )
        ) + "€",
    },
  ];

  const ITEMS_VAT_SUMMARY = [
    {
      translatedText: t("numberOfRows"),
      value: state.form.items.length,
    },
    {
      translatedText: t("itemsAmount"),
      value:
        localeFormat(
          state.form.items.reduce((acc, item) => acc + Number(item.amount), 0)
        ) + "€",
    },
    {
      translatedText: t("itemsVAT"),
      value:
        localeFormat(
          state.form.items.reduce((acc, item) => acc + item.vat, 0)
        ) + "€",
    },
    {
      translatedText: t("itemsTotal"),
      value:
        localeFormat(
          state.form.items.reduce((acc, item) => acc + item.amountWithVat, 0)
        ) + "€",
    },
  ];

  const SELECT_VAT_OPTIONS = [
    { value: null, label: t("notAssigned") },
    { value: 0, label: 0 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 21, label: 21 },
  ];

  // Initial useEffect
  useEffect(() => {
    document.title = t("providerInvoice");

    getCenters();
    getPaymentMethods();
    getProviderInvoice();
    getProviders();
    getTransferPayments();
    getTransferAccounts();
  }, []);

  /* API CALLS */

  const authorizeProviderInvoice = (authorizationId) => {
    api
      .post("/provider-invoices/authorize/" + authorizationId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("providerInvoiceAuthorized"), { variant: "success" });

        const updatedAuthorizations =
          state.form.providerInvoiceAuthorizations.map((authorization) =>
            authorization.id === response.data.id
              ? {
                  ...response.data,
                  specifiedAuthorizer: authorization.specifiedAuthorizer,
                  authorizer: user,
                }
              : authorization
          );
        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "providerInvoiceAuthorizations",
            value: updatedAuthorizations,
          },
        });

        const isAuthorized = updatedAuthorizations.every(
          (authorization) => authorization.authorizedBy
        );
        if (isAuthorized) {
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "isAuthorized",
              value: true,
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const unauthorizeProviderInvoice = (authorizationId) => {
    api
      .post("/provider-invoices/" + id + "/unauthorize/" + authorizationId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("providerInvoiceUnauthorizedSuccessfully"), {
          variant: "success",
        });

        const updatedAuthorizations =
          state.form.providerInvoiceAuthorizations.map((authorization) =>
            authorization.id === response.data.id
              ? { ...response.data }
              : authorization
          );

        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "providerInvoiceAuthorizations",
            value: updatedAuthorizations,
          },
        });

        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "isAuthorized",
            value: false,
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createProviderInvoiceCenters = () => {
    if (validateProviderInvoiceCenters(state.providerInvoiceCenters)) {
      const { amountCenters, centerIds } = state.providerInvoiceCenters;
      // Calculate the amount per center, rounding downward to 2 decimals
      const amountPerCenter = round(amountCenters / centerIds.length);

      const providerInvoiceCenters = centerIds.map((centerId) => {
        return {
          amount: amountPerCenter,
          centerId: Number(centerId),
        };
      });

      Promise.all(
        providerInvoiceCenters.map((data) =>
          api.post(`/provider-invoices/${id}/add-center`, data)
        )
      )
        .then((responses) => {
          const errors = responses.filter((response) => response.data.error);
          if (errors.length) {
            enqueueSnackbar(tErrors(errors[0].data.error), {
              variant: "error",
            });
            return;
          }
          const snackbarMessage =
            responses.length === 1
              ? t("providerInvoiceCenterAdded")
              : t("providerInvoiceCentersAdded");

          enqueueSnackbar(snackbarMessage, { variant: "success" });
          const newProviderInvoiceCenters = responses.map((response) => ({
            id: response.data.id,
            amount: response.data.amount,
            centerId: response.data.centerId,
          }));
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "providerInvoiceCenters",
              value: state.form.providerInvoiceCenters.concat(
                newProviderInvoiceCenters
              ),
            },
          });
          dispatch({ type: "RESET_CENTER_INPUT" });
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const createProviderInvoiceItem = () => {
    if (validateProviderInvoiceItem()) {
      let data = {
        amount: state.providerInvoiceItem.amount,
        providerInvoiceId: id,
        vatPercentage: state.providerInvoiceItem.vatPercentage,
      };

      api
        .post("/provider-invoices/" + id + "/item", data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
            return;
          }
          enqueueSnackbar(t("providerInvoiceItemCreated"), {
            variant: "success",
          });
          dispatch({ type: "RESET_ITEM_INPUT" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "items",
              value: [...state.form.items, response.data],
            },
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const deleteFile = () => {
    api
      .delete("/provider-invoices/" + id + "/delete-file")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("fileDeletedSuccessfully"), { variant: "success" });
        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "fileType",
            value: "",
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteProviderInvoice = () => {
    api
      .delete("/provider-invoices/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("providerInvoiceDeletedSuccessfully"), {
          variant: "success",
        });
        history.goBack();
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteProviderInvoiceCenter = (providerInvoiceCenterId) => {
    api
      .delete("/provider-invoices/center/" + providerInvoiceCenterId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("providerInvoiceCenterRemoved"), {
          variant: "success",
        });
        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "providerInvoiceCenters",
            value: state.form.providerInvoiceCenters.filter(
              (center) => center.id != providerInvoiceCenterId
            ),
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteProviderInvoiceItem = (providerInvoiceId) => {
    api
      .delete("/provider-invoices/item/" + providerInvoiceId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("providerInvoiceItemDeleted"), {
          variant: "success",
        });
        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "items",
            value: state.form.items.filter(
              (item) => item.id != providerInvoiceId
            ),
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editProviderInvoiceCenter = (providerInvoiceCenter) => {
    const { id, amount } = providerInvoiceCenter;
    if (Number(amount) || amount === "0") {
      api
        .post("/provider-invoices/center/" + id, {
          amount,
        })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
            return;
          }
          enqueueSnackbar(t("providerInvoiceCenterEdited"), {
            variant: "success",
          });

          // Find the index of the edited center in the array
          const editedCenterIndex = state.form.providerInvoiceCenters.findIndex(
            (center) => center.id === id
          );

          // Replace the edited center with the new center at the same position
          const newCenters = state.form.providerInvoiceCenters.map(
            (center, index) => {
              if (index === editedCenterIndex) {
                return response.data;
              }
              return center;
            }
          );

          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "providerInvoiceCenters",
              value: newCenters,
            },
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("amountIsNotValid"), { variant: "error" });
    }
  };

  const editProviderInvoiceItem = (item) => {
    if (validateEditProviderInvoiceItem(item)) {
      api
        .post("/provider-invoices/item/" + item.id, item)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
            return;
          }
          enqueueSnackbar(t("providerInvoiceItemEdited"), {
            variant: "success",
          });

          // Find the index of the edited center in the array
          const editedItemIndex = state.form.items.findIndex(
            (editedItem) => editedItem.id === item.id
          );

          // Replace the edited center with the new center at the same position
          const newItems = state.form.items.map((item, index) => {
            if (index === editedItemIndex) {
              return response.data;
            }
            return item;
          });

          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "items",
              value: newItems,
            },
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCenters"), { variant: "warning" });
          }
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getFile = (download) => {
    api
      .get("/provider-invoice/" + id + "/get-file", { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (download) downloadFile(response.data, state.form.fileType);
          else showFile(response.data, state.form.fileType);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/providers/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noPaymentMethods"), { variant: "warning" });
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getProviderInvoice = () => {
    let params = {
      include: [
        "BankingTransaction",
        "CenterProviderInvoice",
        "Provider",
        "ProviderInvoiceItem",
        "User",
      ],
    };
    api
      .get("/provider-invoices/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        const {
          Provider: provider,
          CenterProviderInvoices: providerInvoiceCenters,
          ProviderInvoiceAuthorizations: providerInvoiceAuthorizations,
          ...rest
        } = response.data;
        const payload = {
          provider: provider || [],
          providerInvoiceCenters: providerInvoiceCenters || [],
          providerInvoiceAuthorizations: providerInvoiceAuthorizations || [],
          ...rest,
        };
        getMinProviderDate(response.data.date);
        dispatch({ type: "SET_PROVIDER_INVOICE", payload });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getProviders = () => {
    api
      .get("/providers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noProviders"), { variant: "warning" });
          }
          dispatch({ type: "SET_PROVIDERS", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getTransferPayments = () => {
    if (user.hasAction("VIEW_TRANSFER_PAYMENTS")) {
      dispatch({ type: "SET_LOADED_FALSE" });
      const params = { providerInvoiceId: id };
      api
        .get("/transfer-payments", { params })
        .then((response) => {
          dispatch({ type: "SET_LOADED_TRUE" });
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_TRANSFER_PAYMENTS", payload: response.data });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        dispatch({
          type: "SET_TRANSFER_ACCOUNTS",
          payload: response.data,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMinProviderDate = (invoiceDate) => {
    const params = { key: "MIN_PROVIDER_DATE" };
    api
      .get("/miscellaneous", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          new Date(invoiceDate) < new Date(response.data.value) &&
            dispatch({ type: "SET_EDITED_VALUE", payload: { value: false } });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const sendAuthorizationRequestEmail = (authorization) => {
    if (!authorization.authorizerId) {
      enqueueSnackbar(t("noAuthorizerEmailRequestError"), { variant: "error" });
      return;
    }
    const userId = authorization.authorizerId;

    const params = {
      userId,
      providerInvoiceId: id,
    };

    dispatch({
      type: "SET_SEND_AUTHORIZATION_REQUEST_EMAIL_LOADING",
      payload: true,
    });

    api
      .post("/provider-invoices/authorize/send-request", params)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("authorizationRequestEmailSent"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_SEND_AUTHORIZATION_REQUEST_EMAIL_LOADING",
          payload: false,
        });
      });
  };

  const showBankingTransactionFile = (transaction) => {
    api
      .get("/banking-transactions/" + transaction.id + "/upload-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};
      state.form.invoiceNumber !== "" &&
        (data.invoiceNumber = state.form.invoiceNumber);
      state.form.title !== "" && (data.title = state.form.title);
      data.description = state.form.description;
      state.form.amount !== "" && (data.amount = state.form.amount);
      state.form.vat !== "" && (data.vat = state.form.vat);
      state.form.personalIncomeTax !== "" &&
        (data.personalIncomeTax = state.form.personalIncomeTax);
      state.form.paidAt !== "" && (data.paidAt = state.form.paidAt);
      state.form.provider !== null &&
        (data.providerId = state.form.provider.id);
      state.form.centerId !== "" && (data.centerId = state.form.centerId);
      state.form.createdBy !== "" && (data.createdBy = state.form.createdBy);
      state.form.expenseTypeId !== "" &&
        (data.expenseTypeId = state.form.expenseTypeId);
      state.form.date !== "" && (data.date = state.form.date);
      data.fileRequired = state.form.fileRequired;
      state.form.paymentMethodId !== "" &&
        (data.paymentMethodId = state.form.paymentMethodId);
      data.isTicket = state.form.isTicket;
      state.form.dueDate !== ""
        ? (data.dueDate = state.form.dueDate)
        : (data.dueDate = null);
      state.form.providerInvoiceCenters.length !== 0 &&
        (data.CenterProviderInvoices = state.form.providerInvoiceCenters);
      state.form.defaultTransferAccountId !== "" &&
        (data.defaultTransferAccountId = state.form.defaultTransferAccountId);

      dispatch({ type: "SET_SAVED_VALUE", payload: { value: false } });
      api
        .post("/provider-invoices/edit/" + id, data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
            return;
          }
          enqueueSnackbar(t("providerInvoiceEditSuccess"), {
            variant: "success",
          });
          history.goBack();
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } });
        });
    }
  };

  const uploadFile = (e) => {
    let formData = new FormData();
    formData.append("fileUpload", new File(e.target.files, "fileUpload"));
    formData.append("type", e.target.files[0].type);

    api
      .post("/provider-invoices/" + id + "/upload-file", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("fileUploadedSuccessfully"), {
          variant: "success",
        });
        dispatch({
          type: "SET_INPUT",
          payload: {
            inputname: "fileType",
            value: response.data.fileType,
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* HANDLERS */

  const changeDueDate = (provider, date) => {
    const invoiceDate = new Date(date);
    let dueDate;

    if (provider && date)
      switch (provider.paymentDelay) {
        case 15:
          dueDate = new Date(invoiceDate.setDate(invoiceDate.getDate() + 15));
          break;
        case 30:
          dueDate = new Date(invoiceDate.setMonth(invoiceDate.getMonth() + 1));
          break;
        case 60:
          dueDate = new Date(invoiceDate.setMonth(invoiceDate.getMonth() + 2));
          break;
        default:
          dueDate = invoiceDate;
      }
    else if (date && !provider) dueDate = invoiceDate;
    else if (provider && !date) dueDate = null;

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "dueDate",
        value: formatDate(dueDate),
      },
    });
  };

  const downloadFile = (invoiceFile) => {
    const file = new Blob([invoiceFile], { type: invoiceFile.type });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileURL.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleItemInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_ITEM_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleProviderInvoiceCentersChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_PROVIDER_INVOICE_CENTERS_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleAmountInputChange = (e) => {
    handleInputChange(e);
    handleProviderInvoiceCentersChange({
      target: {
        name: "amountCenters",
        value: e.target.value,
      },
    });

    const percentage = (state.form.vat || 0 / e.target.value) * 100;
    checkItemDefaultVATPercentage(percentage);
  };

  const handleVATInputChange = (e) => {
    handleInputChange(e);
    const percentage = (e.target.value / (state.form.amount || 0)) * 100;
    checkItemDefaultVATPercentage(percentage);
  };

  const checkItemDefaultVATPercentage = (percentage) => {
    const min = percentage - 0.5;
    const max = percentage + 0.5;

    SELECT_VAT_OPTIONS.forEach((option) => {
      if (option.value >= min && option.value <= max)
        dispatch({
          type: "SET_ITEM_INPUT",
          payload: {
            inputname: "vatPercentage",
            value: option.value,
          },
        });
    });
  };

  const openConfirmDelete = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteProviderInvoiceQuestion"),
        childrenText: state.form.title + " - " + state.form.invoiceNumber,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteProviderInvoice();
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmDeleteFile = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteFileQuestion"),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteFile();
          resetConfirmDialog();
        },
      },
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: { inputname: name },
    });
  };

  const setBankingTransactionModal = () => {
    dispatch({ type: "SET_BANKING_TRANSACTION_MODAL" });
  };

  const setBankingTransactions = (transactions) => {
    dispatch({
      type: "SET_BANKING_TRANSACTIONS",
      payload: transactions,
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: { inputname: name },
    });
  };

  const showFile = (invoiceFile) => {
    const file = new Blob([invoiceFile], { type: invoiceFile.type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleDuplicateInvoice = () => {
    localStorage.setItem(
      `duplicateProviderInvoice_${state.form.invoiceNumber}`,
      JSON.stringify({ ...state.form, paidAt: "" })
    );
    history.push(
      `/app/provider-invoice/create?invoiceNumber=${state.form.invoiceNumber}`
    );
  };

  /* VALIDATIONS */

  const validateProviderInvoiceCenters = (providerInvoiceCenters) => {
    if (providerInvoiceCenters.centerIds.length === 0) {
      enqueueSnackbar(t("centerIsRequired"), { variant: "warning" });
      return false;
    }
    if (providerInvoiceCenters.amountCenters === "") {
      setInputErrorTrue("amountCenters");
      return false;
    }

    // Validate that the center is not already selected
    const isDuplicateCenterId = state.form.providerInvoiceCenters?.some(
      (item) => providerInvoiceCenters.centerIds.includes(item.centerId)
    );
    if (isDuplicateCenterId) {
      enqueueSnackbar(t("centerAlreadySelected"), { variant: "warning" });
      return false;
    }

    return true;
  };

  const validateEditProviderInvoiceItem = (item) => {
    let isValid = true;
    Object.keys(item).forEach((field) => {
      if (
        (field === "amount" || field === "vatPercentage") &&
        !Number(item[field]) &&
        Number(item[field]) !== 0
      ) {
        enqueueSnackbar(t(field) + " " + t("mustBeANumber"), {
          variant: "error",
        });
        isValid = false;
      }
    });
    return isValid;
  };

  const validateProviderInvoiceItem = () => {
    if (state.providerInvoiceItem.amount === "") {
      enqueueSnackbar(t("amountIsRequired"), { variant: "warning" });
      return false;
    }
    if (state.providerInvoiceItem.vatPercentage === "") {
      enqueueSnackbar(t("vatPercentageIsRequired"), { variant: "warning" });
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["invoiceNumber", "title", "amount", "date", "provider"];

    fields.forEach((field) => {
      if (!state.form[field]) {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const showAuthorizeButton = (authorization) => {
    const authorizersIds = state.form.providerInvoiceAuthorizations.map(
      (authorization) => authorization.authorizerId
    );
    const authorizedByIds = state.form.providerInvoiceAuthorizations.map(
      (authorization) => authorization.authorizedBy
    );
    if (
      !user.hasAction("AUTHORIZE_PROVIDER_INVOICES") ||
      authorization.authorizedBy ||
      authorizedByIds.includes(user.id)
    )
      return false;

    if (user.id === authorization.authorizerId) return true;

    if (!authorization.authorizerId && !authorizersIds.includes(user.id))
      return true;

    return false;
  };

  const showSendAuthorizationEmailIconButton = (authorization) => {
    // If it is authorized or the authorizer is anyone, don't show
    if (authorization.authorizedBy || !authorization.authorizerId) return false;

    // If it is not authorized, but the authorizer is the current user, don't show
    if (!authorization.authorizedBy && authorization.authorizerId === user.id)
      return false;

    return true;
  };

  const showUnauthorizeButton = (authorization) => {
    return (
      authorization.authorizedBy &&
      user.hasAction("AUTHORIZE_PROVIDER_INVOICES") &&
      authorization.authorizedBy === user.id &&
      !state.form.paidAt
    );
  };

  const totalAmountIsRight = (elements, total) => {
    let sum = 0;
    elements.forEach((element) => {
      sum += Number(element.amount);
    });
    let min = sum - 0.01 * elements.length;
    let max = sum + 0.01 * elements.length;
    return total >= min && total <= max;
  };

  const totalVatIsRight = (elements, total) => {
    let sum = 0;
    elements.forEach((element) => {
      sum += Number(element.vat);
    });
    let min = sum - 0.01 * elements.length;
    let max = sum + 0.01 * elements.length;
    return total >= min && total <= max;
  };

  const generateDuplicateUrl = () => {
    let url = `/app/provider-invoice/create?`;
    url += `title=${state.form.title}&`;
    url += `invoiceNumber=${state.form.invoiceNumber}&`;
    url += `date=${state.form.date}&`;
    url += `dueDate=${state.form.dueDate}&`;
    url += `description=${state.form.description}&`;
    url += `providerId=${state.form.provider.id}&`;
    url += `paymentMethodId=${state.form.paymentMethodId}&`;
    url += `expenseTypeId=${state.form.expenseTypeId}&`;
    url += `amount=${state.form.amount}&`;
    url += `vat=${state.form.vat}&`;
    url += `personalIncomeTax=${state.form.personalIncomeTax}&`;
    url += `isTicket=${state.form.isTicket}&`;
    url += `paidAt=${state.form.paidAt}&`;
    url += `providerInvoiceCenters=${JSON.stringify(
      state.form.providerInvoiceCenters
    )}&`;
    url += `items=${JSON.stringify(state.form.items)}&`;
    return url;
  };

  return (
    <Container maxWidth="md" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3} justify="center">
          <Grid item container xs={12} spacing={1}>
            <Grid item>
              <Typography variant="h4">{t("editProviderInvoice")}</Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Tooltip title={t("duplicateInvoice")} placement="top">
                <IconButton color="primary" onClick={handleDuplicateInvoice}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
              {user.hasAction("EDIT_BANKING_TRANSACTIONS") && (
                <Tooltip title={t("bankingTransactions")} placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => setBankingTransactionModal("")}
                  >
                    <CurrencyExchangeIcon />
                  </IconButton>
                </Tooltip>
              )}
              {user.hasAction("DELETE_PROVIDER_INVOICES") && (
                <Tooltip title={t("deleteProviderInvoice")} placement="top">
                  <IconButton color="error" onClick={openConfirmDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid container item spacing={3} xs={12}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={state.inputError.title}
                  helperText={
                    state.inputError.title
                      ? t("title") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("title")}
                  name="title"
                  onChange={handleInputChange}
                  value={state.form.title}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={state.inputError.invoiceNumber}
                  helperText={
                    state.inputError.invoiceNumber
                      ? t("invoiceNumber") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("number")}
                  name="invoiceNumber"
                  onChange={handleInputChange}
                  value={state.form.invoiceNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDate
                  error={state.inputError.date}
                  helperText={
                    state.inputError.date
                      ? t("dateInvoice") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("dateInvoice")}
                  name="date"
                  value={state.form.date}
                  onChange={(e) => {
                    handleInputChange(e);
                    changeDueDate(state.form.provider, e.target.value);
                  }}
                  disabled={!state.editable}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDate
                  label={t("dueDate")}
                  name="dueDate"
                  value={state.form.dueDate}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  error={state.inputError.description}
                  helperText={
                    state.inputError.description
                      ? t("description") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("description")}
                  multiline
                  rows={4}
                  name="description"
                  onChange={handleInputChange}
                  value={state.form.description}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  xs={{ minWidth: "210px" }}
                  error={state.inputError.provider}
                  helperText={
                    state.inputError.provider
                      ? t("provider") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.provider}
                  name="provider"
                  onChange={(e, provider) => {
                    dispatch({
                      type: "SET_INPUT",
                      payload: {
                        inputname: "provider",
                        value: provider,
                      },
                    });
                    changeDueDate(provider, state.form.date);
                  }}
                  options={state.providers.sort((a, b) =>
                    a.brand.localeCompare(b.brand)
                  )}
                  getOptionDisabled={(provider) => provider.blocked === true}
                  getOptionLabel={(provider) => provider.brand || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextInput {...params} label={t("provider")} />
                  )}
                  disabled={!state.editable}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <CenterSelect
                  value={state.form.centerId}
                  onChange={handleInputChange}
                  name="centerId"
                  autoWidth
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  label={t("paymentMethod")}
                  value={state.form.paymentMethodId}
                  onChange={handleInputChange}
                  name="paymentMethodId"
                  options={[
                    { value: "", label: "-" },
                    ...state.paymentMethods.map((method) => ({
                      label: t(method.name),
                      value: method.id,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpenseTypeSelect
                  label={t("expenseType")}
                  value={state.form.expenseTypeId}
                  onChange={handleInputChange}
                  name="expenseTypeId"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  options={[
                    { value: null, label: t("none") },
                    ...state.transferAccounts.map((account) => ({
                      value: account.id,
                      label: account.accountName,
                    })),
                  ]}
                  name="defaultTransferAccountId"
                  value={state.form.defaultTransferAccountId}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  label={t("defaultTransferAccount")}
                  autoWidth={true}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={2}>
                <TextInput
                  error={state.inputError.amount}
                  helperText={
                    state.inputError.amount
                      ? t("amount") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  type="number"
                  label={t("amount")}
                  name="amount"
                  onChange={(e) => handleAmountInputChange(e)}
                  value={state.form.amount}
                  disabled={!state.editable}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextInput
                  error={state.inputError.vat}
                  helperText={
                    state.inputError.vat
                      ? t("vat") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("vat")}
                  type="number"
                  name="vat"
                  onChange={(e) => handleVATInputChange(e)}
                  value={state.form.vat}
                  disabled={!state.editable}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextInput
                  error={state.inputError.personalIncomeTax}
                  helperText={
                    state.inputError.personalIncomeTax
                      ? t("personalIncomeTax") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("personalIncomeTax")}
                  type="number"
                  name="personalIncomeTax"
                  onChange={handleInputChange}
                  value={state.form.personalIncomeTax}
                  disabled={!state.editable}
                />
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  {t("total") +
                    ": " +
                    localeFormat(
                      Number(state.form.amount) +
                        Number(state.form.vat) -
                        Number(state.form.personalIncomeTax)
                    )}
                  €
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={t("isTicket")}
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(e) => {
                        handleInputChange({
                          target: { name: "isTicket", value: e.target.checked },
                        });
                      }}
                      name="isTicket"
                      checked={Boolean(state.form.isTicket)}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label={t("paid")}
                  control={
                    <Switch
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleInputChange({
                            target: {
                              name: "paidAt",
                              value: new Date().toISOString().slice(0, 10),
                            },
                          });
                        } else {
                          handleInputChange({
                            target: { name: "paidAt", value: null },
                          });
                        }
                      }}
                      checked={Boolean(
                        state.form.paidAt && state.form.paidAt !== ""
                      )}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                />

                {state.form.paidAt && state.form.paidAt !== "" && (
                  <TextInput
                    fullWidth={false}
                    error={state.inputError.paidAt}
                    helperText={
                      state.inputError.paidAt
                        ? t("paidAt") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    label={t("paidAt")}
                    type="date"
                    name="paidAt"
                    onChange={handleInputChange}
                    value={state.form.paidAt}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Center provider invoices */}

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{t("breakdownByCenter")}</Typography>
              </Grid>
              <Grid item>
                <Collapse
                  in={
                    !totalAmountIsRight(
                      state.form.providerInvoiceCenters,
                      state.form.amount
                    )
                  }
                >
                  <Alert severity="warning">
                    {t("sumOfCentersAmountsNotEqualToTotalAmount")}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CenterSelect
                  multiple
                  name="centerIds"
                  value={state.providerInvoiceCenters.centerIds}
                  onChange={handleProviderInvoiceCentersChange}
                  autoWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextInput
                  error={state.inputError.amountCenters}
                  helperText={
                    state.inputError.amountCenters
                      ? t("amount") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("totalAmount")}
                  type="number"
                  name="amountCenters"
                  value={state.providerInvoiceCenters.amountCenters}
                  onChange={handleProviderInvoiceCentersChange}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={createProviderInvoiceCenters}
                >
                  {state.providerInvoiceCenters.centerIds.length <= 1
                    ? t("addCenter")
                    : t("addCenters")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <ItemsSummary gridItems={ITEMS_SUMMARY} />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ height: 300, width: "100%" }}>
                  <DataGrid
                    rows={state.form.providerInvoiceCenters}
                    columns={PROVIDER_INVOICE_CENTER_COLUMNS}
                    density="compact"
                  />
                </Box>
              </Grid>
            </Grid>

            {/* Transfer payments table */}
            {user.hasAction("VIEW_TRANSFER_PAYMENTS") &&
              state.transferPayments?.length > 0 && (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t("transferPayments")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTable
                      columns={TRANSFER_PAYMENT_COLUMNS}
                      data={state.transferPayments}
                      options={state.options}
                    />
                  </Grid>
                </Grid>
              )}

            {/* Banking transactions table */}
            {user.hasAction("VIEW_BANKING_TRANSACTIONS") &&
              state.form.BankingTransactions?.length > 0 && (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t("bankingTransactions")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTable
                      columns={BANKING_TRANSACTION_COLUMNS}
                      data={state.form.BankingTransactions}
                    />
                  </Grid>
                </Grid>
              )}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Provider Invoice Items */}
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{t("breakdownByVAT")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  in={!totalAmountIsRight(state.form.items, state.form.amount)}
                >
                  <Alert severity="warning">
                    {t("sumOfItemsAmountsNotEqualToTotalAmount")}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  in={!totalVatIsRight(state.form.items, state.form.vat)}
                >
                  <Alert severity="warning">
                    {t("sumOfItemsVATsNotEqualToTotalVAT")}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <TextInput
                  type="number"
                  label={t("amount")}
                  name="amount"
                  onChange={handleItemInputChange}
                  value={state.providerInvoiceItem.amount}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Select
                  options={SELECT_VAT_OPTIONS}
                  name="vatPercentage"
                  value={state.providerInvoiceItem.vatPercentage}
                  onChange={handleItemInputChange}
                  label={t("vatPercentage")}
                  autoWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={createProviderInvoiceItem}
                >
                  {t("addItem")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <ItemsSummary gridItems={ITEMS_VAT_SUMMARY} />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ height: 300, width: "100%" }}>
                  <DataGrid
                    rows={state.form.items}
                    columns={PROVIDER_INVOICE_COLUMNS}
                    density="compact"
                  />
                </Box>
              </Grid>

              {/* Provider invoice items total summary */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1} justifyContent="space-around">
                      <Grid item>
                        <Typography>
                          {t("totalAmount")}:
                          <Typography
                            marginLeft={1}
                            variant="h6"
                            component="span"
                            fontWeight={600}
                          >
                            {localeFormat(
                              state.form.items.reduce(
                                (total, cur) => (total += Number(cur.amount)),
                                0
                              )
                            ) + "€"}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {t("totalVat")}:
                          <Typography
                            marginLeft={1}
                            variant="h6"
                            component="span"
                            fontWeight={600}
                          >
                            {localeFormat(
                              state.form.items.reduce(
                                (acc, item) => acc + item.vat,
                                0
                              )
                            ) + "€"}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {t("total")}:
                          <Typography
                            marginLeft={1}
                            variant="h6"
                            component="span"
                            fontWeight={600}
                          >
                            {localeFormat(
                              state.form.items.reduce(
                                (total, cur) => (total += cur.amountWithVat),
                                0
                              )
                            ) + "€"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Authorizations */}
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">{t("authorizations")}</Typography>
              </Grid>
              <Grid container item columnSpacing={1} xs={12}>
                <Grid item>
                  {state.form.isAuthorized === true ? (
                    <CheckIcon color="success" fontSize="small" />
                  ) : (
                    <CloseIcon color="error" fontSize="small" />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="caption" fontWeight="bold">
                    {state.form.isAuthorized === true
                      ? t("authorized")
                      : t("notAuthorized")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                {state.form.providerInvoiceAuthorizations?.length === 0 ? (
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {t("noRequestedAuthorizations")}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  state.form.providerInvoiceAuthorizations?.map(
                    (authorization, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                          sx={{
                            boxShadow: authorization.authorizedBy
                              ? "0px 3px 5px 2px rgba(76, 175, 80, .3)" // success.light color
                              : "0px 3px 5px 2px rgba(211, 47, 47, .3)", // error.light color
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant="body2" fontWeight="bold">
                                  {t("requestedTo") + ": "}
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    fontWeight="normal"
                                  >
                                    {authorization.specifiedAuthorizer?.name ||
                                      t("anyone")}
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                {authorization.authorizedBy ? (
                                  authorization.authorizerId ? (
                                    <Typography
                                      variant="body2"
                                      fontWeight="bold"
                                    >
                                      {t("authorized")}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      fontWeight="bold"
                                    >
                                      {t("authorizedBy") + ": "}
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        fontWeight="normal"
                                      >
                                        {authorization.authorizer?.name}
                                      </Typography>
                                    </Typography>
                                  )
                                ) : (
                                  <Typography variant="body2" fontWeight="bold">
                                    {t("notAuthorized")}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions sx={{ justifyContent: "flex-end" }}>
                            {showAuthorizeButton(authorization) && (
                              <Button
                                size="small"
                                onClick={() =>
                                  authorizeProviderInvoice(authorization.id)
                                }
                              >
                                {t("authorize")}
                              </Button>
                            )}
                            {showSendAuthorizationEmailIconButton(
                              authorization
                            ) && (
                              <Tooltip
                                title={t("sendEmailRequestingAuthorization")}
                              >
                                <IconButton
                                  color={
                                    authorization.isSent ? "success" : "primary"
                                  }
                                  size="small"
                                  onClick={() =>
                                    sendAuthorizationRequestEmail(authorization)
                                  }
                                >
                                  {state.sendAuthorizationRequestEmailLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <EmailIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            )}
                            {showUnauthorizeButton(authorization) && (
                              <Button
                                size="small"
                                onClick={() =>
                                  unauthorizeProviderInvoice(authorization.id)
                                }
                              >
                                {t("unauthorize")}
                              </Button>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  )
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container display="flex" spacing={1}>
              <Grid item>
                <FormControlLabel
                  label={t("fileRequired")}
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleInputChange({
                            target: { name: "fileRequired", value: true },
                          });
                        } else {
                          handleInputChange({
                            target: { name: "fileRequired", value: false },
                          });
                        }
                      }}
                      name="fieldRequired"
                      checked={state.form.fileRequired}
                    />
                  }
                />
              </Grid>
              {user.hasAction("EDIT_PROVIDER_INVOICES") &&
                !state.form.fileType &&
                state.form.fileRequired && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      component="label"
                      startIcon={<CloudUploadIcon />}
                    >
                      {t("uploadFile")}
                      <input type="file" hidden onChange={uploadFile} />
                    </Button>
                  </Grid>
                )}
              {state.form.fileType && state.form.fileRequired && (
                <Grid item>
                  <ButtonGroup variant="contained">
                    <Button
                      color="primary"
                      onClick={() => {
                        getFile(false);
                      }}
                      startIcon={<SearchIcon />}
                    >
                      {t("show")}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        getFile(true);
                      }}
                      startIcon={<DownloadIcon />}
                    >
                      {t("download")}
                    </Button>
                    {user.hasAction("EDIT_PROVIDER_INVOICES") && (
                      <Button
                        color="error"
                        onClick={openConfirmDeleteFile}
                        startIcon={<DeleteForeverIcon />}
                      >
                        {t("delete")}
                      </Button>
                    )}
                  </ButtonGroup>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {user.hasAction("EDIT_PROVIDER_INVOICES") && (
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!state.saved}
                  onClick={submitForm}
                >
                  {!state.saved ? t("saving") : t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <BankingTransactionsDialog
        open={state.bankingTransactionDialog}
        setOpen={setBankingTransactionModal}
        providerInvoice={state.form}
        providerBankingTransactions={state.form.BankingTransactions}
        setBankingTransactions={setBankingTransactions}
      />

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>

      {state.redirect && <Redirect to="/app/provider-invoices" />}
    </Container>
  );
}
