import { useState } from "react";
import CameraStreamDialog from "../../../global/surveillance/CameraStreamDialog";

function CameraManager(props) {
  const { cameras, onClose } = props;

  if (!cameras || !cameras.length) return <></>;
  return cameras.map((camera) => (
    <CameraStream key={camera.id} camera={camera} onClose={onClose} />
  ));
}

function CameraStream(props) {
  const { camera, onClose } = props;
  const [open, setOpen] = useState(true);

  return (
    <CameraStreamDialog
      open={open}
      onClose={(e) => {
        if (e.currentTarget.tagName === "BUTTON") {
          onClose(camera.id);
          setOpen(false);
        }
      }}
      camera={camera}
    />
  );
}

export default CameraManager;
