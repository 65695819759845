import {
  Grid,
  Stack,
  Typography,
  Card,
  Checkbox,
  TablePagination,
  CircularProgress,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import InvoiceCard from "./InvoiceCard";
import { useTranslation } from "react-i18next";

import {
  EXPIRED_INVOICE_STATE_ID,
  ISSUED_INVOICE_STATE_ID,
} from "../../../../data/constants";

function InvoiceStackList({
  invoices,
  selectedInvoicesIds,
  setSelectedInvoicesIds,
  beingProcessedIds,
  functions,
  loading,
}) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(5);

  useEffect(() => {
    setPage(0);
  }, [loading]);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <HeaderRow
          disable={loading || invoices.length < 1}
          invoices={invoices}
          selectedInvoicesIds={selectedInvoicesIds}
          setSelectedInvoicesIds={setSelectedInvoicesIds}
        />
      </Grid>
      {loading ? (
        <Box display="flex" justifyContent="center" width="100%" marginTop={4}>
          <CircularProgress />
        </Box>
      ) : invoices.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Stack spacing={1}>
              {invoices.slice(page * rows, (page + 1) * rows).map((invoice) => {
                return (
                  <InvoiceCard
                    key={invoice.id}
                    invoice={invoice}
                    functions={functions}
                    beingProcessedIds={beingProcessedIds}
                    selectedInvoicesIds={selectedInvoicesIds}
                    setSelectedInvoicesIds={setSelectedInvoicesIds}
                  />
                );
              })}
            </Stack>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        <FooterRow
          page={page}
          setPage={setPage}
          rows={rows}
          setRows={setRows}
          totalItems={invoices.length}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

const HeaderRow = ({
  disable,
  invoices,
  selectedInvoicesIds,
  setSelectedInvoicesIds,
}) => {
  const [t] = useTranslation("invoices");

  const headers = [
    { title: "invoiceNumber", xs: "1.5" },
    { title: "customerName", xs: "2" },
    { title: "payCard", xs: "2" },
    { title: "issueDate", xs: "1.5" },
    { title: "amount", xs: "1.25" },
    { title: "state", xs: "1.25" },
    { title: "actions", xs: "1.5" },
  ];

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      const newSelectedInvoicesIds = [];

      invoices.forEach((invoice) => {
        const allowedStates = [
          ISSUED_INVOICE_STATE_ID,
          EXPIRED_INVOICE_STATE_ID,
        ];
        const notAllowedPayment = !allowedStates.includes(invoice.state);

        if (!notAllowedPayment) {
          newSelectedInvoicesIds.push(invoice.id);
        }
      });

      setSelectedInvoicesIds(newSelectedInvoicesIds);
    } else {
      setSelectedInvoicesIds([]);
    }
  };

  let allSelected = true;
  const filteredInvoices = invoices.filter((invoice) => {
    const allowedStates = [ISSUED_INVOICE_STATE_ID, EXPIRED_INVOICE_STATE_ID];
    const notAllowedPayment = !allowedStates.includes(invoice.state);

    return !notAllowedPayment;
  });
  if (filteredInvoices.length === 0) allSelected = false;
  filteredInvoices.forEach((invoice) => {
    if (!selectedInvoicesIds.includes(invoice.id)) {
      allSelected = false;
    }
  });

  return (
    <Card variant="outlined" sx={{ height: 55 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          marginLeft={2}
          xs={0.5}
          height={"70px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Checkbox
            checked={allSelected}
            disabled={disable || !filteredInvoices.length}
            onChange={handleCheckBox}
          />
        </Grid>
        {headers.map((header) => (
          <Grid
            item
            xs={header.xs}
            height={"70px"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">{t(header.title)}</Typography>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const FooterRow = ({ page, setPage, rows, setRows, totalItems, loading }) => {
  return (
    <Grid container spacing={2} justifyContent={"flex-end"}>
      <Grid item>
        <Card>
          <TablePagination
            disabled={loading}
            size="small"
            component="div"
            count={totalItems}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => setRows(event.target.value)}
            rowsPerPage={rows}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default InvoiceStackList;
