import {
  Container,
  Checkbox,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper as MUIPaper,
} from "@mui/material";
import { useContext, useState } from "react";

import AppContext from "../../../../context/AppContext";
import Page from "../../../global/structure/Page";
import TextInput from "../../../Inputs/TextInput";

import { RolesContext } from "../RolesPage";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

function ActionsTab() {
  const { user } = useContext(AppContext);
  const { actions, editRoleAction, roles } = useContext(RolesContext);

  const [filter, setFilter] = useState("");

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });
    return isFound;
  };

  const roleHasAction = (role, actionId) => {
    let hasAction = false;
    role.Actions?.forEach((action) => {
      if (action.id === actionId) hasAction = true;
    });
    return hasAction;
  };

  return (
    <Page>
      <Container maxWidth={false}>
        <TableContainer component={MUIPaper} sx={{ maxHeight: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 3,
                    backgroundColor: "white",
                  }}
                >
                  <TextInput
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    sx={{ minWidth: 200 }}
                  />
                </TableCell>
                {roles?.map((role) => (
                  <TableCell align="left" key={role.id}>
                    {role.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {actions.length !== 0 &&
                actions.map((action, i) => (
                  <>
                    {(filter === "" ||
                      action.id
                        .toUpperCase()
                        .includes(filter.toUpperCase())) && (
                      <TableRow key={action.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            position: "sticky",
                            left: 0,
                            zIndex: 2,
                            backgroundColor: "white",
                          }}
                        >
                          {action.id}
                        </TableCell>
                        {roles.map((role, j) => (
                          <TableCell
                            align="left"
                            key={role.id + action.id}
                            sx={{
                              left: "auto",
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {hasAction("EDIT_ACTIONS") ? (
                              <Checkbox
                                id={"check" + i + j}
                                name={"check" + role.id + action.id}
                                checked={roleHasAction(role, action.id)}
                                onChange={(e) => {
                                  editRoleAction(
                                    e.target.checked,
                                    role,
                                    action
                                  );
                                }}
                                inputProps={{
                                  "aria-label": "primary checkbox" + i + j,
                                }}
                              />
                            ) : roleHasAction(role, action.id) ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default ActionsTab;
