import {
  Autocomplete,
  ButtonGroup,
  Chip,
  Container,
  Grid,
  IconButton,
  Typography,
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { formatDate } from "../../../utils/chartUtils";

import { FolderZipOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import WarningIcon from "@mui/icons-material/Warning";

import { CustomTable } from "../../CustomTable";
import { formatDayMonthYear } from "../../../utils/date";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import AppContext from "../../../context/AppContext";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CenterSelect from "../../Inputs/CenterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import CreateButton from "../../Inputs/CreateButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import MinDateDialog from "./MinDateDialog";
import ProviderInvoiceTotals from "./ProviderInvoiceTotals";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import TransferencesDialog from "./TransferencesDialog";
import Select from "../../global/inputs/Select";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  assetManagementSupplies: [],
  confirmDialog: {
    title: "",
    isOpen: false,
    callback: () => {},
  },
  providerInvoices: [],
  filters: {
    autoSearch: "false",
    centerId: [],
    dateFrom: "",
    dateUntil: "",
    defaultTransferAccountId: "",
    expenseType: [],
    file: "",
    importMax: "",
    importMin: "",
    isAuthorized: "",
    order: "asc",
    orderBy: "",
    paid: "",
    paymentMethodId: [],
    providerId: "",
    text: "",
    currentPage: 0,
    rowsPerPage: 10,
  },
  expenseTypes: [],
  provider: "",
  providers: [],
  paymentMethods: [],
  transferAccounts: [],
  options: {
    rowlink: "provider-invoice",
    loaded: true,
  },
  transferencesDialog: {
    isOpen: false,
  },
  minDateDialog: {
    isOpen: false,
    newDate: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "SET_EXPENSE_TYPES":
      return { ...state, expenseTypes: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PROVIDER_INVOICES":
      return { ...state, providerInvoices: action.payload };
    case "SET_PROVIDER":
      return {
        ...state,
        provider: action.payload,
      };
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    case "SET_ASSET_MANAGEMENT_SUPPLIES":
      return { ...state, assetManagementSupplies: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_PAGINATION":
      return {
        ...state,
        filters: {
          ...state.filters,
          currentPage: action.payload.currentPage,
          rowsPerPage: action.payload.rowsPerPage,
        },
      };
    case "OPEN_TRANSFERENCES_DIALOG":
      return {
        ...state,
        transferencesDialog: { ...state.transferencesDialog, isOpen: true },
      };
    case "CLOSE_TRANSFERENCES_DIALOG":
      return {
        ...state,
        transferencesDialog: { ...state.transferencesDialog, isOpen: false },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "OPEN_MIN_DATE_DIALOG":
      return {
        ...state,
        minDateDialog: { ...state.minDateDialog, isOpen: true },
      };
    case "RESET_MIN_DATE_DIALOG":
      return {
        ...state,
        minDateDialog: initialState.minDateDialog,
      };
    case "SET_TRANSFER_ACCOUNTS":
      return { ...state, transferAccounts: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function ProviderInvoicesPage() {
  const { api, user } = useContext(AppContext);
  const query = useQuery();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("providerInvoices");
  const [tErrors] = useTranslation("errors");
  const [anchorEl, setAnchorEl] = React.useState({ id: null, anchorEl: null });

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "centerId", type: "array" },
    { name: "currentPage", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "defaultTransferAccountId", type: "number" },
    { name: "expenseType", type: "array" },
    { name: "file", type: "boolean" },
    { name: "importMin", type: "number" },
    { name: "importMax", type: "number" },
    { name: "isAuthorized", type: "boolean" },
    { name: "text", type: "string" },
    { name: "paid", type: "number" },
    { name: "paymentMethodId", type: "array" },
    { name: "providerId", type: "number" },
    { name: "order", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "rowsPerPage", type: "number" },
  ];

  const CSV_HEADERS = [
    { label: t("title"), key: "title" },
    { label: t("number"), key: "invoiceNumber" },
    { label: t("date"), key: "date" },
    { label: t("dueDate"), key: "dueDate" },
    { label: t("createdAt"), key: "createdAt" },
    { label: t("amount"), key: "amount" },
    { label: t("vat"), key: "vat" },
    { label: t("personalIncomeTax"), key: "personalIncomeTax" },
    { label: t("total"), key: "totalRounded" },
    { label: t("expenseType"), key: "ExpenseType.name" },
    { label: t("provider"), key: "Provider.brand" },
    { label: t("providerBusinessName"), key: "Provider.businessName" },
    { label: t("paymentMethod"), key: "ProviderPaymentMethod.name" },
    { label: t("authorized"), key: "isAuthorizedString" },
    { label: t("paid"), key: "paidAt" },
    { label: t("reconciled"), key: "isReconciliatedString" },
  ];

  const CENTER_CSV_HEADERS = [
    { label: t("title"), key: "title" },
    { label: t("number"), key: "invoiceNumber" },
    { label: t("date"), key: "date" },
    { label: t("dueDate"), key: "dueDate" },
    { label: t("createdAt"), key: "createdAt" },
    { label: t("amount"), key: "amount" },
    { label: t("vat"), key: "vat" },
    { label: t("personalIncomeTax"), key: "personalIncomeTax" },
    { label: t("total"), key: "totalRounded" },
    { label: t("expenseType"), key: "ExpenseType.name" },
    { label: t("provider"), key: "Provider.brand" },
    { label: t("providerBusinessName"), key: "Provider.businessName" },
    { label: t("paymentMethod"), key: "ProviderPaymentMethod.name" },
    { label: t("authorized"), key: "isAuthorizedString" },
    { label: t("paid"), key: "paidAt" },
    { label: t("reconciled"), key: "isReconciliatedString" },
    { label: t("center"), key: "centerName" },
    { label: t("centerAmount"), key: "centerAmount" },
  ];

  const SUPPLIES_CSV_HEADERS = [
    { label: "AssetRef", key: "centerName" },
    { label: "Asset", key: "asset" },
    { label: "Utility type", key: "type" },
    { label: "Meter", key: "meterId" },
    { label: "FromDate", key: "dateFrom" },
    { label: "ToDate", key: "dateTo" },
    { label: "Consumption", key: "consumption" },
    { label: "Estimate", key: "estimate" },
    { label: "Origin", key: "origin" },
    { label: "Comment", key: "comment" },
  ];

  const PROVIDER_INVOICE_COLUMNS = [
    { key: "title", label: t("title"), sortType: "string" },
    { key: "invoiceNumber", label: t("invoiceNumber"), sortType: "string" },
    { key: "date", label: t("date"), sortType: "string" },
    {
      key: "dueDate",
      label: t("dueDate"),
      valueGetter: (value, row) => {
        return value ? value : "";
      },
      sortType: "string",
    },
    {
      key: "createdAt",
      label: t("createdAt"),
      sortType: "string",
      renderFunction: (value) => value.substring(0, 10),
    },
    {
      key: "amount",
      label: t("amount"),
      renderFunction: (value) => localeFormat(value) + "€",
      sortType: "number",
    },
    {
      key: "vat",
      label: t("vat"),
      renderFunction: (value) => localeFormat(value) + "€",
      sortType: "number",
    },
    {
      key: "personalIncomeTax",
      label: t("personalIncomeTax"),
      renderFunction: (value) => localeFormat(value) + "€",
      sortType: "number",
    },
    {
      key: "total",
      label: t("total"),
      renderFunction: (value) => localeFormat(value) + "€",
      sortType: "number",
    },
    { key: "ExpenseType.name", label: t("expenseType"), sortType: "string" },
    {
      key: "Provider.brand",
      label: t("provider"),
      sortType: "string",
      renderFunction: (value, item) =>
        value ? (
          <ButtonLink to={"/app/provider/" + item?.providerId}>
            {value}
          </ButtonLink>
        ) : (
          ""
        ),
    },
    {
      key: "Provider.businessName",
      label: t("providerBusinessName"),
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      key: "centerName",
      label: t("center"),
      sortType: "string",
      renderFunction: (value, item) => {
        const centers = item.CenterProviderInvoices;
        const itsRight = totalAmountIsRight(centers, item.amount);
        if (!centers.length) return "";
        if (centers.length === 1)
          return (
            <>
              {!itsRight && (
                <Tooltip title={t("incorrectCenterAmounts")}>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    size="small"
                  >
                    <WarningIcon color="warning" />
                  </IconButton>
                </Tooltip>
              )}
              {centers[0].Center.name}
            </>
          );
        return (
          <div>
            <Tooltip title={itsRight ? "" : t("incorrectCenterAmounts")}>
              <Button
                startIcon={!itsRight ? <WarningIcon color="warning" /> : null}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAnchorEl({ id: item.id, anchorEl: e.currentTarget });
                }}
              >
                {t("variousCenters")}
              </Button>
            </Tooltip>
            <Popover
              open={anchorEl.id === item.id}
              anchorEl={anchorEl.anchorEl}
              onClose={(e) => {
                e.stopPropagation();
                setAnchorEl({ id: null, anchorEl: null });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List dense>
                {centers.map((center) => (
                  <ListItem
                    key={center.id}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ListItemText primary={center.Center?.name} />
                  </ListItem>
                ))}
              </List>
            </Popover>
          </div>
        );
      },
    },
    {
      key: "ProviderPaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      key: "isAuthorized",
      label: t("authorized"),
      sortType: "number",
      renderFunction: (value) =>
        value ? (
          <Chip color="success" label={t("authorized")} size="small" />
        ) : (
          <Chip color="error" label={t("pending")} size="small" />
        ),
    },
    {
      key: "paidAt",
      label: t("paid"),
      sortType: "string",
      valueGetter: (value) => (value ? value : ""),
      renderFunction: (value) =>
        Boolean(value !== "") ? (
          <Chip color="success" label={value} size="small" />
        ) : (
          <Chip color="error" label={t("pending")} size="small" />
        ),
    },
    {
      key: "isReconciliated",
      label: t("reconciled"),
      sortType: "number",
      renderFunction: (value, item) => {
        const sum =
          item.BankingTransactions?.reduce(
            (accumulator, element) => accumulator + element.import,
            0
          ) ?? 0;
        const diff = Math.abs(sum) - Math.abs(item.total);

        const isTransferPaymentGroup =
          (item.TransferPayments &&
            item.TransferPayments[0]?.TransferPaymentGroup
              ?.BankingTransactions[0]?.bankingTransactionType ===
              "TRANSFER_PAYMENT_GROUP") ||
          false;
        const addText =
          diff && !isTransferPaymentGroup ? " " + localeFormat(diff) + "€" : "";
        return value === true ? (
          <Chip
            color={diff && !isTransferPaymentGroup ? "warning" : "success"}
            label={t("reconciled") + addText}
            size="small"
          />
        ) : (
          <Chip color="error" label={t("nonReconciled")} size="small" />
        );
      },
    },
    {
      key: "fileState",
      label: t("file"),
      sortType: "number",
      renderFunction: (value, item) => {
        if (value === 0)
          return (
            <IconButton>
              <InsertDriveFileIcon color="disabled" />
            </IconButton>
          );

        if (value === 1)
          return (
            <IconButton>
              <InsertDriveFileIcon color="error" />
            </IconButton>
          );

        if (value === 2)
          return (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                seeFile(item);
              }}
            >
              <InsertDriveFileIcon color="success" />
            </IconButton>
          );
      },
    },
  ];

  //Initialize state and get parameters from URL
  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const search = () => {
    if (state.filters.autoSearch === "true") getProviderInvoices();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  useEffect(() => {
    if (state.filters.autoSearch === "true") getProviderInvoices();
  }, [state.filters.autoSearch]);

  // Updates provider when providerId changes (necessary for Autocomplete)
  useEffect(() => {
    state.providers.length > 0 &&
      dispatch({
        type: "SET_PROVIDER",
        payload: state.providers.find(
          (provider) => provider.id === Number(state.filters.providerId)
        ),
      });
  }, [state.filters.providerId, state.providers]);

  const handleChangeFilter = (e) => {
    let value = e.target.value;
    if (e.target.name === "paymentMethodId") {
      if (e.target.value.find((ele) => ele === -2))
        value = [-2, -1, 1, 2, 3, 4];
      else if (
        state.filters.paymentMethodId.length === 6 &&
        e.target.value.length === 5
      )
        value = [];
    }
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  //Initial useEffect
  useEffect(() => {
    getPaymentMethods();
    getProviders();
    getExpenseTypes();
    getAssetManagementSupplies();
    getTransferAccounts();
  }, []);

  const setFiltersFromState = (filters) => {
    state.filters.dateFrom !== "" &&
      (filters.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (filters.dateUntil = state.filters.dateUntil);

    state.filters.importMin !== "" &&
      (filters.importMin = state.filters.importMin);
    state.filters.importMax !== "" &&
      (filters.importMax = state.filters.importMax);

    state.filters.file != "" && (filters.file = state.filters.file);

    state.filters.isAuthorized !== "" &&
      (filters.isAuthorized = state.filters.isAuthorized);

    state.filters.text !== "" && (filters.text = state.filters.text);

    state.filters.centerId !== "" &&
      (filters.centerId = state.filters.centerId);

    state.filters.providerId !== "" &&
      (filters.provider = state.filters.providerId);

    state.filters.paid !== "" && (filters.isPaid = state.filters.paid);

    state.filters.paymentMethodId !== "" &&
      (filters.paymentMethodId = state.filters.paymentMethodId);

    state.filters.expenseType.length > 0 &&
      (filters.expenseType = state.filters.expenseType);

    state.filters.defaultTransferAccountId !== "" &&
      (filters.defaultTransferAccountId =
        state.filters.defaultTransferAccountId);
  };

  const getProviderInvoices = () => {
    //Change url parameters:
    let url = generateURL("/app/provider-invoices", state.filters);
    history.push(url);

    // Set table and search button loading
    dispatch({ type: "SET_LOADED_FALSE" });

    let filters = {
      include: [
        "BankingTransaction",
        "Center",
        "CenterProviderInvoice",
        "PaymentMethod",
        "Provider",
        "TransferPayment",
      ],
    };

    setFiltersFromState(filters);
    api
      .get("/provider-invoices", {
        params: filters,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          // If no invoices found, warn via snackbar
          if (response.data.length == 0)
            enqueueSnackbar(t("noProviderInvoices"), { variant: "warning" });

          response.data.forEach((invoice) => {
            if (invoice.CenterProviderInvoices.length === 1)
              invoice.centerName =
                invoice.CenterProviderInvoices[0]?.Center.name;
            else if (invoice.CenterProviderInvoices.length === 0)
              invoice.centerName = "zzz";
            else invoice.centerName = "zz";
          });
          dispatch({ type: "SET_PROVIDER_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  //Fix max width providers
  const getProviders = () => {
    api
      .get("/providers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noProviders"), { variant: "warning" });
          dispatch({
            type: "SET_PROVIDERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getAssetManagementSupplies = () => {
    api
      .get("/asset-management-supplies")
      .then((response) => {
        if (response.data?.length > 0) {
          const csvData = response.data.map((supply) => ({
            centerName: supply.AssetManagementSupplyMeter.Center.name,
            asset: "",
            type: supply.type,
            meterId: supply.meterId,
            dateFrom: formatDayMonthYear(supply.dateFrom),
            dateTo: formatDayMonthYear(supply.dateTo),
            consumption: supply.consumption,
            estimate: "Actual",
            origin: "Invoices",
            comment: "",
          }));
          dispatch({
            type: "SET_ASSET_MANAGEMENT_SUPPLIES",
            payload: csvData,
          });
        }
      })
      .catch(() => {});
  };

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_TRANSFER_ACCOUNTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/providers/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noPaymentMethods"), { variant: "warning" });
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getExpenseTypes = () => {
    api
      .get("/expense-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_EXPENSE_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  //TODO: Change to use utils downloadFile
  const downloadFile = (invoiceFile) => {
    const file = new Blob([invoiceFile], {
      type: "application/zip",
    });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileURL.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getFiles = () => {
    let filters = {};
    setFiltersFromState(filters);

    api
      .get("/provider-invoices/get-files", {
        params: filters,
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          downloadFile(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleGetFiles = () => {
    if (state.providerInvoices.length === 0)
      enqueueSnackbar(t("noProviderInvoices"), { variant: "warning" });
    else if (
      state.providerInvoices.some(
        (providerInvoice) => !providerInvoice.filePath
      )
    ) {
      dispatch({
        type: "SET_CONFIRM_DIALOG",
        payload: {
          title: t("createZipWithMissingFilesQuestion"),
          isOpen: true,
          callback: (confirmed) => {
            confirmed && getFiles();
            resetConfirmDialog();
          },
        },
      });
    } else {
      getFiles();
    }
  };

  const openTransferenceDialog = () => {
    dispatch({ type: "OPEN_TRANSFERENCES_DIALOG" });
  };

  const closeTransferenceDialog = (selectedInvoices) => {
    if (selectedInvoices.length != 0) {
      //Update invoices if needed
      let newInvoices = state.providerInvoices.map((invoice) => {
        if (selectedInvoices.includes(invoice.id))
          invoice.paidAt = formatDate(new Date());
        return invoice;
      });
      dispatch({ type: "SET_PROVIDER_INVOICES", payload: newInvoices });
    }
    dispatch({ type: "CLOSE_TRANSFERENCES_DIALOG" });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_PROVIDER_INVOICES", payload: [] });
  };

  const seeFile = (item) => {
    api
      .get("/provider-invoice/" + item.id + "/get-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: response.data.type });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getDataForExtendedCSV = () => {
    let invoices = state.providerInvoices.map((invoice) => {
      return invoice?.CenterProviderInvoices.map((center) => {
        return {
          ...invoice,
          centerName: center?.Center?.name,
          centerAmount: center?.amount,
        };
      });
    });
    invoices = invoices.flat(1);

    return invoices;
  };

  const onPaginationCallback = (page, rowsPerPage) => {
    const url = generateURL("/app/provider-invoices", {
      ...state.filters,
      currentPage: page,
      rowsPerPage: rowsPerPage,
    });
    history.push(url);
    dispatch({
      type: "SET_PAGINATION",
      payload: { currentPage: page, rowsPerPage: rowsPerPage },
    });
  };

  const openMinDateDialog = () => {
    dispatch({ type: "OPEN_MIN_DATE_DIALOG" });
  };

  const closeMinDateDialog = () => {
    dispatch({ type: "RESET_MIN_DATE_DIALOG" });
  };

  return (
    <Page browserTitle={t("providerInvoices")} title={t("providerInvoices")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                name="text"
                value={state.filters.text}
                onChange={handleChangeFilter}
                label={t("search")}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <CustomDate
                value={state.filters.dateFrom}
                name="dateFrom"
                onChange={handleChangeFilter}
                label={t("dateFrom")}
              />,
              <CustomDate
                value={state.filters.dateUntil}
                name="dateUntil"
                onChange={handleChangeFilter}
                label={t("dateUntil")}
              />,
              <TextInput
                value={state.filters.importMin}
                name="importMin"
                onChange={handleChangeFilter}
                label={t("importMin")}
                type="number"
              />,
              <TextInput
                value={state.filters.importMax}
                name="importMax"
                onChange={handleChangeFilter}
                label={t("importMax")}
                type="number"
              />,
              <Select
                name="file"
                label={t("file")}
                value={state.filters.file}
                onChange={handleChangeFilter}
                options={[
                  { label: t("all"), value: "" },
                  { label: t("yes"), value: "true" },
                  { label: t("no"), value: "false" },
                ]}
              />,
              <Autocomplete
                size="medium"
                sx={{ minWidth: 200 }}
                options={state.providers}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id || null
                }
                getOptionLabel={(option) => {
                  let label = "";
                  if (option.brand) label += option.brand;
                  if (option.businessName) label += " - " + option.businessName;
                  return label;
                }}
                name="provider"
                value={state.provider || null}
                onChange={(e, provider) => {
                  handleChangeFilter({
                    target: {
                      name: "providerId",
                      value: provider ? provider.id : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextInput {...params} label={t("providers")} size="small" />
                )}
              />,
              <CenterSelect
                onChange={handleChangeFilter}
                name="centerId"
                multiple
                value={state.filters.centerId}
              />,
              <Select
                name="isAuthorized"
                label={t("authorized")}
                value={state.filters.isAuthorized}
                onChange={handleChangeFilter}
                options={[
                  { label: t("all"), value: "" },
                  { label: t("authorized"), value: true },
                  { label: t("nonAuthorized"), value: false },
                ]}
              />,
              <Select
                name="paid"
                label={t("paid")}
                value={state.filters.paid}
                onChange={handleChangeFilter}
                options={[
                  { label: t("all"), value: "all" },
                  { label: t("paid"), value: "paid" },
                  { label: t("pending"), value: "pending" },
                ]}
              />,
              <Select
                name="paymentMethodId"
                label={t("paymentMethod")}
                value={state.filters.paymentMethodId}
                multiple
                onChange={handleChangeFilter}
                options={[
                  { value: -2, label: t("all") },
                  { value: -1, label: t("notAssigned") },
                  ...state.paymentMethods.map((method) => ({
                    label: t(method.name),
                    value: method.id,
                  })),
                ]}
                sx={{ maxWidth: 230 }}
              />,
              <CustomSelect
                label={t("expenseType")}
                value={state.filters.expenseType}
                onChange={handleChangeFilter}
                name="expenseType"
                options={[
                  { value: -2, label: "", parentId: null },
                  { value: -1, label: t("notAssigned"), parentId: -2 },
                  ...state.expenseTypes?.map((expenseType) => ({
                    value: expenseType.id,
                    label: expenseType.name,
                    parentId: expenseType.parentId,
                  })),
                ]}
                grouping={true}
                multiple
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: "#c6e6f9",
                    },
                  },
                }}
              />,
              <Select
                options={[
                  { value: null, label: t("none") },
                  ...state.transferAccounts.map((account) => ({
                    value: account.id,
                    label: account.accountName,
                  })),
                ]}
                name="defaultTransferAccountId"
                value={state.filters.defaultTransferAccountId}
                onChange={handleChangeFilter}
                label={t("paymentAccount")}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ButtonCSV
              data={state.providerInvoices.map((e) => {
                e.isReconciliatedString = e.isReconciliated
                  ? t("reconciled")
                  : t("nonReconciled");
                e.totalRounded = e.total.toFixed(2);
                return e;
              })}
              headers={CSV_HEADERS}
              filename={t("providerInvoices")}
            />
            <ButtonCSV
              data={getDataForExtendedCSV()}
              headers={CENTER_CSV_HEADERS}
              filename={t("providerInvoices")}
              buttonLabel={t("extendedCSV")}
            />
            <Button startIcon={<FolderZipOutlined />} onClick={handleGetFiles}>
              ZIP
            </Button>
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item>
          <CreateButton
            action={"CREATE_PROVIDER_INVOICES"}
            label={t("createProviderInvoice")}
            link={"/provider-invoice/create"}
          />
        </Grid>

        {user.hasAction("CREATE_TRANSFER_PAYMENTS") && (
          <Grid item>
            <Button
              startIcon={<PriceCheckIcon />}
              onClick={openTransferenceDialog}
            >
              {t("transferences")}
            </Button>
          </Grid>
        )}

        <Grid item>
          {user.hasAction("SET_MIN_PROVIDER_INVOICE_DATE") && (
            <Button startIcon={<EditIcon />} onClick={openMinDateDialog}>
              {t("changeMinDate")}
            </Button>
          )}
          <ButtonCSV
            data={state.assetManagementSupplies}
            headers={SUPPLIES_CSV_HEADERS}
            filename={t("supplyManagement")}
            buttonLabel={t("suppliesCSV")}
          />
        </Grid>

        <Grid item xs={12}>
          <ProviderInvoiceTotals invoices={state.providerInvoices} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={PROVIDER_INVOICE_COLUMNS}
            currentPage={Number(state.filters.currentPage)}
            data={state.providerInvoices}
            linesPerPage={Number(state.filters.rowsPerPage)}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onPaginationCallback={onPaginationCallback}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/provider-invoices", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>

      {/* Transferences dialog */}
      <TransferencesDialog
        isOpen={state.transferencesDialog.isOpen}
        onClose={closeTransferenceDialog}
      />

      {/* Transferences dialog */}
      <MinDateDialog
        isOpen={state.minDateDialog.isOpen}
        onClose={closeMinDateDialog}
      />

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      />
    </Page>
  );
}

const totalAmountIsRight = (elements, total) => {
  let sum = 0;
  elements.forEach((element) => {
    sum += Number(element.amount);
  });
  let min = sum - 0.01 * elements.length;
  let max = sum + 0.01 * elements.length;

  return total >= min && total <= max;
};
