import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import TagIcon from "@mui/icons-material/Tag";
import PercentIcon from "@mui/icons-material/Percent";

function ValueFormatToggle({ value, onChange }) {
  const [t] = useTranslation("dashboard");

  const onChangeProtection = (event, newValue) => {
    if (newValue === null) return;
    onChange(event, newValue);
  };

  return (
    <ToggleButtonGroup exclusive value={value} onChange={onChangeProtection}>
      <ToggleButton size="small" value="value">
        <Tooltip title={t("value")}>
          <TagIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton size="small" value="percentage">
        <Tooltip title={t("percentage")}>
          <PercentIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ValueFormatToggle;
