import { useEffect, useState } from "react";
import { Grid, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ChartContainer } from "./ChartContainer";
import { colors } from "../Components/ColorGenerator";

const SHOW_INCOMES = 1;
const SHOW_EXPENSES = 2;

const labels = [];
for (let i = 1; i < 32; i++) labels.push(i);

const MovementsPerDayChart = ({ transactions, loading }) => {
  const [t] = useTranslation("dashboard");
  const [selected, setSelected] = useState(SHOW_INCOMES);
  const [movementsData, setMovementsData] = useState({
    incomes: [],
    expenses: [],
  });

  const setNewMovementsData = () => {
    const incomes = [];
    const expenses = [];
    labels.forEach((label) => {
      let totalIncomes = 0;
      let totalExpenses = 0;

      transactions
        .filter(
          (tr) =>
            Number(tr.transactionDate.slice(tr.transactionDate.length - 2)) ===
            label
        )
        .forEach((transaction) => {
          if (transaction.import > 0) totalIncomes += transaction.import;
          else totalExpenses += transaction.import;
        });
      incomes.push(Number(totalIncomes.toFixed(2)));
      expenses.push(Number((totalExpenses * -1).toFixed(2)));
    });
    setMovementsData({ incomes, expenses });
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: t(selected === SHOW_INCOMES ? "incomes" : "expenses"),
        data:
          selected === SHOW_INCOMES
            ? movementsData.incomes
            : movementsData.expenses,
        backgroundColor: colors.primary,
      },
    ],
  };

  const options = {
    // plugins: {
    //   tooltip: {
    //     callbacks: {
    //       label: (dataPoint) => {
    //         const index = labels.findIndex(
    //           (label) => label === dataPoint.label
    //         );
    //         const incomesText = `${t("incomes")}: ${incomes[index]} €`;
    //         const expensesText = `${t("expenses")}: ${expenses[index]} €`;
    //         return `${incomesText} | ${expensesText}`;
    //       },
    //     },
    //   },
    // },
  };

  useEffect(setNewMovementsData, [transactions]);

  return (
    <ChartContainer
      title={t("movementsPerDay")}
      chart={<Bar data={data} options={options} />}
      loading={loading}
    >
      <Grid item margin={2}>
        <ToggleButtonGroup exclusive value={selected}>
          <ToggleButton
            size="small"
            value={SHOW_INCOMES}
            onClick={() => setSelected(SHOW_INCOMES)}
          >
            {t("incomes")}
          </ToggleButton>
          <ToggleButton
            size="small"
            value={SHOW_EXPENSES}
            onClick={() => setSelected(SHOW_EXPENSES)}
          >
            {t("expenses")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </ChartContainer>
  );
};

export default MovementsPerDayChart;
