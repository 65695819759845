import { Grid, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthContext from "../../../../../context/AppContext";
import CenterReportContext from "../CenterReportContext";

import { CustomTable } from "../../../../CustomTable";
import ContractStateBox from "../../../Contracts/ContractStateBox";
import Filters from "../../../../global/structure/Filters";
import InformationBox from "../../Components/InformationBox";
import Select from "../../../../global/inputs/Select";

import { localeFormat } from "../../../../../utils/format";

import {
  ContractsPaymentMethodDoughnut,
  ContractsPeriodicitiesDoughnut,
  SignedContractsDoughnut,
} from "../../Charts/CenterReport";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import {
  // Periodicities
  MONTHLY_PERIODICITY_ID,
  QUARTERLY_PERIODICITY_ID,
  BIANNUAL_PERIODICITY_ID,
  ANNUAL_PERIODICITY_ID,

  // Payment methods
  CASH_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  CONSOLIDATION_PAYMENT_METHOD_ID,
  TOKENIZED_PAYCARD_PAYMENT_METHOD_ID,

  // States
  PENDING_CONTRACT_STATE_ID,
  ACTIVE_CONTRACT_STATE_ID,
  ENDED_CONTRACT_STATE_ID,
} from "../../../../../data/constants";

const periodicities = [
  { value: MONTHLY_PERIODICITY_ID, label: "monthly" },
  { value: QUARTERLY_PERIODICITY_ID, label: "quarterly" },
  { value: BIANNUAL_PERIODICITY_ID, label: "biannual" },
  { value: ANNUAL_PERIODICITY_ID, label: "annual" },
];

const paymentMethods = [
  { value: CASH_PAYMENT_METHOD_ID, label: "cash" },
  { value: RECEIPT_PAYMENT_METHOD_ID, label: "receipt" },
  { value: PAYCARD_PAYMENT_METHOD_ID, label: "paycard" },
  { value: TRANSFER_PAYMENT_METHOD_ID, label: "transfer" },
  { value: CONSOLIDATION_PAYMENT_METHOD_ID, label: "consolidation" },
  { value: TOKENIZED_PAYCARD_PAYMENT_METHOD_ID, label: "tokenizedPaycard" },
];

const states = [
  { value: PENDING_CONTRACT_STATE_ID, label: "pending" },
  { value: ACTIVE_CONTRACT_STATE_ID, label: "active" },
  { value: ENDED_CONTRACT_STATE_ID, label: "ended" },
];

function ContractsTab() {
  const [t] = useTranslation("dashboard");
  const { user } = useContext(AuthContext);
  const { contracts } = useContext(CenterReportContext);
  const [periodicity, setPeriodicity] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [state, setState] = useState([]);

  const filteredContracts = applyFilters(contracts, {
    periodicity: periodicity,
    paymentMethod: paymentMethod,
    state: state,
  });

  const CONTRACT_COLUMNS = [
    {
      label: t("box"),
      key: "Box.name",
      sortType: "string",
    },
    {
      label: t("customer"),
      key: "Customer.name",
      sortType: "string",
    },
    { label: t("startDate"), key: "startDate", sortType: "string" },
    { label: t("endDate"), key: "endDate", sortType: "string" },
    {
      label: t("createDate"),
      key: "createdAt",
      sortType: "string",
      renderFunction: (value) => value.split("T")[0],
    },
    {
      label: t("periodicity"),
      key: "Periodicity.name",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    { label: t("payDay"), key: "dateOfPayment", sortType: "number" },
    {
      label: t("finalPrice"),
      key: "finalPrice",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      label: t("formOfPayment"),
      key: "PaymentMethod.name",
      sortType: "string",
    },
    {
      label: t("state"),
      key: "state",
      sortType: "other",
      renderFunction: (value, item) => (
        <ContractStateBox
          state={value}
          isFrozen={item?.isFrozen}
          hasFreezePermission={user.hasAction("FREEZE_CONTRACT")}
        />
      ),
    },
    {
      label: t("signed"),
      key: "isSigned",
      sortType: "other",
      renderFunction: (value, item) =>
        value ? (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title={t("hasDigitalSignature")}>
                <CheckIcon
                  color={item.hasDigitalSignature ? "success" : "disabled"}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("hasSignedFile")}>
                <CheckIcon
                  color={item.hasSignedFile ? "success" : "disabled"}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Tooltip title={t("isNotSigned")}>
              <ClearIcon color="error" />
            </Tooltip>
          </Grid>
        ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Filters
          filters={[
            <Select
              multiple
              name={"periodicity"}
              label={t("periodicity")}
              value={periodicity}
              onChange={(e) => setPeriodicity(e.target.value)}
              options={periodicities.map((p) => ({ ...p, label: t(p.label) }))}
            />,
            <Select
              multiple
              name={"paymentMethod"}
              label={t("paymentMethod")}
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              options={paymentMethods.map((p) => ({ ...p, label: t(p.label) }))}
            />,
            <Select
              multiple
              name={"state"}
              label={t("state")}
              value={state}
              onChange={(e) => setState(e.target.value)}
              options={states.map((s) => ({ ...s, label: t(s.label) }))}
            />,
          ]}
        />
      </Grid>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={1}
      >
        <Grid item>
          <InformationBox
            mainValue={filteredContracts.length}
            title={t("contracts")}
            size={225}
            borderRadius={1}
          />
        </Grid>

        <Grid item>
          <InformationBox
            mainValue={
              filteredContracts.length > 0
                ? localeFormat(
                    filteredContracts.reduce(
                      (acc, c) => acc + c.contractDuration,
                      0
                    ) / filteredContracts.length
                  )
                : 0
            }
            title={t("medianDuration") + " (" + t("days") + ")"}
            size={225}
            borderRadius={1}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={1}
      >
        <Grid item xs={3.5}>
          <SignedContractsDoughnut contracts={filteredContracts} />
        </Grid>
        <Grid item xs={3.5}>
          <ContractsPaymentMethodDoughnut contracts={filteredContracts} />
        </Grid>
        <Grid item xs={3.5}>
          <ContractsPeriodicitiesDoughnut contracts={filteredContracts} />
        </Grid>
      </Grid>

      <Grid item xs={12} marginTop={2}>
        <CustomTable columns={CONTRACT_COLUMNS} data={filteredContracts} />
      </Grid>
    </Grid>
  );
}

const applyFilters = (contracts, filters) => {
  return contracts.filter((contract) => {
    return (
      (filters.periodicity.length === 0 ||
        filters.periodicity.includes(contract.periodicity)) &&
      (filters.paymentMethod.length === 0 ||
        filters.paymentMethod.includes(contract.paymentMethod)) &&
      (filters.state.length === 0 || filters.state.includes(contract.state))
    );
  });
};

export default ContractsTab;
