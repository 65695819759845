import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import AppContext from "../../../context/AppContext";

import Page from "../../global/structure/Page";
import Tabs from "../../global/structure/Tabs";

import EditTab from "./PromotionPageTabs/EditTab";
import StatisticsTab from "./PromotionPageTabs/StatisticsTab";

export const PromotionContext = createContext();

function PromotionPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { api } = useContext(AppContext);
  const { id } = useParams();

  const [t] = useTranslation("promotion");
  const [tErrors] = useTranslation("errors");

  const [promotion, setPromotion] = useState({});
  const [periodicities, setPeriodicities] = useState([]);
  const [contracts, setContracts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getPeriodicities();
    getPromotion();
  }, []);

  useEffect(() => {
    getContracts();
  }, [promotion]);

  const getPromotion = () => {
    if (!id) return;
    const params = { include: ["Center", "Periodicity"] };
    api
      .get("/promotions/" + id, { params })
      .then((res) => {
        if (res.data.error)
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        else {
          const { validFrom, validUntil } = res.data;
          const parseDate = (date) => date.slice(0, -8);
          const from = validFrom ? parseDate(validFrom) : null;
          const until = validUntil ? parseDate(validUntil) : null;
          const centers = res.data.Centers.map((c) => String(c.id));
          const periodicities = res.data.Periodicities;
          setPromotion({
            ...res.data,
            selectedCenters: centers,
            selectedPeriodicities: periodicities,
            validFrom: from,
            validUntil: until,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(tErrors(error), { variant: "error" });
      });
  };

  const getPeriodicities = () => {
    api
      .get("/periods")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else setPeriodicities(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContracts = () => {
    if (!promotion.id) return;
    const params = {
      promotionsIds: [promotion.id],
      include: ["Box", "Center", "Periodicity", "Customer"],
    };
    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else setContracts(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editPromotion = (data) => {
    const param = {
      active: data.active,
      code: data.code,
      description: data.description,
      discountType: data.discountType,
      name: data.name,
      numberOfPeriods: data.numberOfPeriods,
      restrictedCenterIds: data.restrictedByCenters
        ? data.selectedCenters
        : undefined,
      restrictedPeriodicitiesIds: data.restrictedByPeriodicity
        ? data.selectedPeriodicities.map((p) => p.id)
        : undefined,
      temporality: data.temporality,
      validFrom: data.validFrom !== "" ? data.validFrom : null,
      validUntil: data.validUntil !== "" ? data.validUntil : null,
      value: data.value,
      maxNumberOfUses:
        data.maxNumberOfUses !== "" ? data.maxNumberOfUses : null,
    };

    api
      .post("/promotions/" + id, param)
      .then((res) => {
        if (res.data.error)
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        else {
          enqueueSnackbar(t("promotionUpdated"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => {
        enqueueSnackbar(tErrors(error), { variant: "error" });
      });
  };

  return (
    <PromotionContext.Provider
      value={{ contracts, promotion, periodicities, editPromotion }}
    >
      <Page
        title={t("promotion")}
        browserTitle={t("promotionInfo")}
        maxWidth={"lg"}
        paper
      >
        <Tabs
          tabs={[
            {
              label: t("info"),
              content: <EditTab />,
            },
            {
              label: t("statistics"),
              content: <StatisticsTab />,
            },
          ]}
        />
      </Page>
    </PromotionContext.Provider>
  );
}

export default PromotionPage;
