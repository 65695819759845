import React, { useContext, useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MailIcon from "@mui/icons-material/Mail";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Phone } from "@mui/icons-material";

// Components & Utils
import AppContext from "../../../context/AppContext";
import CreditCardForm from "../../CreditCardForm";
import ConfirmDialog from "../../ConfirmDialog";
import CustomSelect from "../../Inputs/CustomSelect";
import CustomerForm from "../../pages/Customers/CustomerForm";
import ListCard from "../../ListCard";
import PhoneInput from "../../Inputs/PhoneInput";
import TextInput from "../../Inputs/TextInput";
import CustomButton from "../../Inputs/CustomButton";
import {
  CONTRACT_INVOICING_TYPE_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  NIF_ID_TYPE,
} from "../../../data/constants";

const initialState = {
  form: {
    address: "",
    birthdate: "",
    cards: [],
    city: null,
    cityId: "",
    customerTypeId: INDIVIDUAL_CUSTOMER_TYPE_ID,
    defaultIBAN: "",
    defaultPayCard: "",
    emails: [],
    gender: "",
    ibans: [],
    idType: NIF_ID_TYPE,
    invoicingType: CONTRACT_INVOICING_TYPE_ID,
    name: "",
    nif: "",
    phones: [],
    postalCode: "",
    residenceCountry: null,
    residenceCountryId: "",
    surnames: "",
    tinCountry: null,
    tinCountryId: "",
    sendEmail: true,
  },
  confirmDialog: {
    cancelText: "",
    callback: () => {},
    confirmText: "",
    childrenText: "",
    isOpen: false,
    title: "",
  },
  inputError: {
    address: false,
    birthdate: false,
    city: false,
    gender: false,
    name: false,
    nif: false,
    surnames: false,
  },
  addressModal: { isOpen: false },
  cardModal: { isOpen: false },
  emailModal: { isOpen: false },
  ibanModal: { isOpen: false },
  invoicingTypes: [],
  newEmail: { email: "", contact: "" },
  newIban: "",
  newPhone: { number: "", contact: "" },
  phoneModal: { isOpen: false },
  selectEmailModal: {
    isOpen: false,
    email: "",
  },
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_EMAILS":
      return {
        ...state,
        form: {
          ...state.form,
          emails: action.payload,
        },
      };
    case "SET_PHONES":
      return {
        ...state,
        form: {
          ...state.form,
          phones: action.payload,
        },
      };
    case "SET_PHONE_INPUT":
      return {
        ...state,
        newPhone: {
          ...state.newPhone,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICING_TYPES":
      return { ...state, invoicingTypes: action.payload };
    case "ADD_CARD":
      return {
        ...state,
        form: { ...state.form, cards: [...state.form.cards, action.payload] },
      };
    case "DELETE_CARD":
      return {
        ...state,
        form: {
          ...state.form,
          cards: [
            ...state.form.cards.filter((item) => item !== action.payload),
          ],
        },
      };
    case "ADD_IBAN":
      return {
        ...state,
        form: { ...state.form, ibans: [...state.form.ibans, action.payload] },
        newIban: initialState.newIban,
      };
    case "DELETE_IBAN":
      return {
        ...state,
        form: {
          ...state.form,
          ibans: [
            ...state.form.ibans.filter((item) => item !== action.payload),
          ],
        },
      };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_EMAIL_EMAIL_MODAL":
      return {
        ...state,
        selectEmailModal: {
          isOpen: state.selectEmailModal.isOpen,
          email: action.payload,
        },
      };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "RESET_FORM":
      return { ...state, [action.payload]: initialState[action.payload] };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_LOADING":
      return { ...state, loading: !state.loading };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function CreateCustomerPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar /* closeSnackBar */ } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("customers");
  const [tErrors] = useTranslation("errors");

  //Initial useEffect
  useEffect(() => {
    document.title = t("createCustomerPage");

    getInvoicingTypes();
  }, []);

  /* BACKEND CALLS */
  const submitForm = () => {
    let form = {};

    form.nif = state.form.nif;
    form.name = state.form.name;
    state.form.surnames && (form.surnames = state.form.surnames);
    state.form.gender && (form.gender = state.form.gender);
    state.form.birthdate && (form.birthdate = state.form.birthdate);
    form.address = state.form.address;
    form.customerTypeId = state.form.customerTypeId;
    form.invoicingType = state.form.invoicingType;
    state.form.invoicingType && (form.invoicingType = state.form.invoicingType);
    state.form.city && (form.cityId = state.form.city?.id);
    state.form.postalCode && (form.postalCode = state.form.postalCode);
    state.form.tinCountry && (form.tinCountryId = state.form.tinCountry?.id);
    state.form.residenceCountry &&
      (form.residenceCountryId = state.form.residenceCountry?.id);
    state.form.ibans.length > 0 && (form.ibans = state.form.ibans);
    state.form.cards.length > 0 && (form.cards = state.form.cards);
    state.form.defaultIBAN && (form.defaultIBAN = state.form.defaultIBAN);
    state.form.defaultPayCard &&
      (form.defaultPayCard = state.form.defaultPayCard);
    form.idType = state.form.idType;
    state.form.sendEmail && (form.sendEmail = state.form.sendEmail);

    form.emails = state.form.emails.map((email) => ({
      email: email.value1,
      contact: email.value2,
    }));

    form.email =
      form.emails.length > 1
        ? state.selectEmailModal.email
        : form.emails[0].email;

    form.phones = state.form.phones.map((phone) => ({
      number: phone.number,
      contact: phone.contact,
    }));

    dispatch({ type: "SET_LOADING" });
    api
      .post("/customers/create", form)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("customerCreateSuccess"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADING" }));
  };

  const getInvoicingTypes = () => {
    api
      .get("/invoicing-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else
          dispatch({ type: "SET_INVOICING_TYPES", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addCreditCard = (card) => {
    if (card) {
      let form = {
        name: card.name,
        number: card.number,
        cvv: card.cvc,
        expirationMonth: card.expiry.substring(0, 2),
        expirationYear: card.expiry.substring(3),
      };
      api
        .post("/pay-cards/validate", form)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            if (response.data.isValid) {
              dispatch({
                type: "ADD_CARD",
                payload: form,
              });
              enqueueSnackbar(t("cardAdded"), { variant: "success" });
              handleCloseCardModal();

              //If is the first:
              if (state.form.cards.length === 0)
                dispatch({
                  type: "SET_INPUT",
                  payload: {
                    inputname: "defaultPayCard",
                    value: card.number,
                  },
                });
            } else {
              enqueueSnackbar(t("invalidCreditCard"), {
                variant: "warning",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const addIban = () => {
    if (validateIban()) {
      api
        .get("/iban/" + state.newIban + "/validate")
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            if (response.data.isValid) {
              const iban = {
                number: state.newIban,
              };
              dispatch({
                type: "ADD_IBAN",
                payload: iban,
              });
              //If is the first:
              if (state.form.ibans.length === 0)
                dispatch({
                  type: "SET_INPUT",
                  payload: {
                    inputname: "defaultIBAN",
                    value: state.newIban,
                  },
                });
              enqueueSnackbar(t("ibanAdded"), { variant: "success" });
              handleCloseIbanModal();
            } else enqueueSnackbar(t("invalidIBAN"), { variant: "warning" });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  /* HANDLERS */
  const handleOpenCardModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "cardModal",
        value: true,
      },
    });
  };

  const handleCloseCardModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "cardModal",
        value: false,
      },
    });
  };

  const handleOpenSelectEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "selectEmailModal",
        value: true,
      },
    });
  };

  const handleCloseSelectEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "selectEmailModal",
        value: false,
      },
    });
  };

  const handleSetSelectedEmailEmailModal = (e) => {
    const email = e.target.value;
    dispatch({
      type: "SET_EMAIL_EMAIL_MODAL",
      payload: email,
    });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOpenAddressModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "addressModal",
        value: true,
      },
    });
  };

  const handleCloseAddressModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "addressModal",
        value: false,
      },
    });
    dispatch({
      type: "RESET_FORM",
      payload: "address",
    });
  };

  const deleteCard = (item) => {
    dispatch({
      type: "DELETE_CARD",
      payload: item,
    });
    enqueueSnackbar(t("cardDeleted"), { variant: "success" });
  };

  const handleChangeEmails = (emails) => {
    dispatch({
      type: "SET_EMAILS",
      payload: emails,
    });
    enqueueSnackbar(t("emailAdded"), { variant: "success" });
    handleCloseEmailModal();
  };

  const handleChangePhones = (e) => {
    dispatch({
      type: "SET_PHONE_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const addPhone = () => {
    const isPhoneRepeated = state.form.phones.some(
      (phone) => phone.number === state.newPhone.number
    );

    if (isPhoneRepeated)
      enqueueSnackbar(t("repeatedPhone"), { variant: "error" });
    else if (state.newPhone.number.length < 6 || !state.newPhone.number)
      enqueueSnackbar(t("invalidPhoneError"), { variant: "error" });
    else {
      dispatch({
        type: "SET_PHONES",
        payload: [...state.form.phones, state.newPhone],
      });
      enqueueSnackbar(t("phoneAdded"), { variant: "success" });
      handleClosePhoneModal();
    }
  };

  const deletePhone = (e) => {
    dispatch({
      type: "SET_PHONES",
      payload: state.form.phones.filter((phone) => phone.number != e.number),
    });
    enqueueSnackbar(t("phoneDeleted"), { variant: "success" });
  };

  const handleOpenIbanModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "ibanModal",
        value: true,
      },
    });
  };

  const handleCloseIbanModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "ibanModal",
        value: false,
      },
    });
    dispatch({
      type: "SET_NEW_IBAN",
      payload: "",
    });
  };

  const deleteIban = (iban) => {
    dispatch({
      type: "DELETE_IBAN",
      payload: iban,
    });
    enqueueSnackbar(t("ibanDeleted"), { variant: "success" });
  };

  const handleInputChangeIban = (e) => {
    dispatch({
      type: "SET_NEW_IBAN",
      payload: e.target.value,
    });
  };

  const handleOpenEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: true,
      },
    });
  };

  const handleCloseEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: false,
      },
    });
  };

  const handleOpenPhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: true,
      },
    });
  };

  const handleClosePhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: false,
      },
    });
    dispatch({
      type: "RESET_FORM",
      payload: "newPhone",
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirmEmail = (email) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteEmailQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          email.value1 + (email.value2 !== null ? " - " + email.value2 : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmail(email);
          resetConfirmDialog();
        },
      },
    });
  };

  const deleteEmail = (email) => {
    const update = state.form.emails.filter(
      (item) => email.value1 !== item.value1
    );
    dispatch({
      type: "SET_EMAILS",
      payload: update,
    });
    enqueueSnackbar(t("emailDeleted"), { variant: "success" });
  };

  const openConfirmPhone = (phone) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deletePhoneQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          phone.number + (phone.contact !== null ? " - " + phone.contact : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deletePhone(phone);
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmCard = (card) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteCreditCardQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          "**** **** **** " + card.number.substring(card.number.length - 4),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteCard(card);
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmIBAN = (iban) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteIbanQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: iban.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteIban(iban);
          resetConfirmDialog();
        },
      },
    });
  };

  /* VALIDATORS */

  const validateForm = () => {
    let isValid = true;

    const fields = ["name", "nif", "address", "tinCountry", "residenceCountry"];

    fields.forEach((field) => {
      if (!state.form[field]) {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), {
          variant: "error",
        });
        isValid = false;
      } else setInputErrorFalse(field);
    });

    if (Number(state.form.customerTypeId) === 1) {
      const individualFields = ["surnames"];

      individualFields.forEach((field) => {
        if (state.form[field] === "" || state.form[field] === null) {
          setInputErrorTrue(field);
          enqueueSnackbar(t(field) + " " + t("isRequired"), {
            variant: "error",
          });
          isValid = false;
        } else setInputErrorFalse(field);
      });
    }

    if (!state.form.emails || !state.form.emails.length) {
      enqueueSnackbar(t("email") + " " + t("cannotBeEmpty"), {
        variant: "error",
      });
      isValid = false;
    }

    return isValid;
  };

  const validateEmail = (value1) => {
    let isValid = true;

    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (!value1 || value1 === "" || !re.test(value1)) {
      isValid = false;
      enqueueSnackbar(t("invalidEmailError"), { variant: "error" });
    }

    if (value1 !== null && value1 !== "") {
      state.form.emails.forEach((email) => {
        if (email.value1.localeCompare(value1) === 0) {
          isValid = false;
          enqueueSnackbar(t("emailExists"), { variant: "error" });
        }
      });
    } else {
      enqueueSnackbar(t("emptyEmail"), { variant: "error" });
      isValid = false;
    }

    return isValid;
  };

  const validateIban = () => {
    let isValid = true;

    if (state.newIban) {
      if (/\s/.test(state.newIban)) {
        enqueueSnackbar(t("noSpacesAllowed"), { variant: "error" });
        isValid = false;
      }

      let exist = state.form.ibans.some((iban) => iban.number == state.newIban);
      if (exist) {
        enqueueSnackbar(t("ibanAlreadyExists"), { variant: "error" });
        isValid = false;
      }
    } else {
      enqueueSnackbar(t("emptyIBAN"), { variant: "error" });
      isValid = false;
    }

    return isValid;
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createCustomer")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomerForm
              customer={state.form}
              handleInputChange={handleInputChange}
              helperText={state.inputError}
              isCreate={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Emails */}

          <Grid item container xs={12}>
            <Grid item>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <MailIcon />
                {t("emails") + " *"}
              </Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Button startIcon={<AddIcon />} onClick={handleOpenEmailModal}>
                {t("addEmail")}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {state.form?.emails?.map((email) => (
              <List>
                <Card variant="outlined">
                  <CardContent>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("address") + ": "}
                        </Typography>
                        <Typography variant="body">{email?.value1}</Typography>
                        <Grid xs={12}></Grid>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("contact") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {email.value2 ? email.value2 : "---"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        flex={1}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <IconButton onClick={() => openConfirmEmail(email)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </List>
            ))}
            {state.form.emails == 0 && t("noEmails")}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Phones */}

          <Grid item container xs={12}>
            <Grid item>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <Phone />
                {t("phoneNumbers")}
              </Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Button startIcon={<AddIcon />} onClick={handleOpenPhoneModal}>
                {t("addPhone")}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {state.form.phones.map((phone) => (
              <List>
                <Card variant="outlined" xs={6}>
                  <CardContent>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("number") + ": "}
                        </Typography>
                        <Typography variant="body">{phone?.number}</Typography>
                        <Grid xs={12}></Grid>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("contact") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {phone.contact ? phone.contact : "---"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        flex={1}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <IconButton onClick={() => openConfirmPhone(phone)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </List>
            ))}
            {state.form.phones.length == 0 && t("noPhones")}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Credit cards */}

          <Grid item container xs={12}>
            <Grid item>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <CreditCardIcon />
                {t("creditCards")}
              </Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Button startIcon={<AddIcon />} onClick={handleOpenCardModal}>
                {t("addCreditCards")}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomSelect
              label={t("defaultPayCard")}
              value={state.form.defaultPayCard}
              onChange={(e) => handleInputChange(e)}
              name="defaultPayCard"
              options={state.form.cards.map((card) => ({
                label:
                  "**** **** **** " +
                  card.number.substring(card.number.length - 4),
                value: card.number,
              }))}
            />
          </Grid>

          <Grid item xs={12}>
            {state.form.cards.map((card) => (
              <List>
                <Card variant="outlined" xs={6}>
                  <CardContent>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("number") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {"**** **** **** " +
                            card.number.substring(card.number.length - 4)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        flex={1}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <IconButton onClick={() => openConfirmCard(card)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </List>
            ))}
            {state.form.cards.length == 0 && t("noCards")}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* IBANs */}

          <Grid item container xs={12}>
            <Grid item>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <CreditCardIcon />
                {t("ibans")}
              </Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Button startIcon={<AddIcon />} onClick={handleOpenIbanModal}>
                {t("addIban")}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomSelect
              label={t("defaultIBAN")}
              value={state.form.defaultIBAN}
              onChange={(e) => handleInputChange(e)}
              name="defaultIBAN"
              options={state.form.ibans.map((iban) => ({
                label: iban.number,
                value: iban.number,
              }))}
            />
          </Grid>

          <Grid item xs={12}>
            {state.form.ibans.map((iban) => (
              <List>
                <Card variant="outlined" xs={6}>
                  <CardContent>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("number") + ": "}
                        </Typography>
                        <Typography variant="body">{iban.number}</Typography>
                      </Grid>
                      <Grid
                        item
                        flex={1}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <IconButton onClick={() => openConfirmIBAN(iban)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </List>
            ))}
            {state.form.ibans.length == 0 && t("noIbans")}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container spacing={2}>
            <Grid item>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <ReceiptLongIcon />
                {t("invoicingOptions")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                label={t("invoicingType")}
                value={
                  state.invoicingTypes.length > 0
                    ? state.form.invoicingType
                    : ""
                }
                name="invoicingType"
                onChange={handleInputChange}
                options={state.invoicingTypes.map((type) => ({
                  value: type.id,
                  label: t(type.name),
                }))}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <FormControlLabel
                  control={<Checkbox name="sendEmail" />}
                  label={t("sendUserDataEmail")}
                  checked={state.form.sendEmail}
                  onChange={(e) => {
                    handleInputChange({
                      target: { name: e.target.name, value: e.target.checked },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <CustomButton
                color="success"
                onClick={() => {
                  if (!validateForm()) return;
                  if (state.form.emails.length > 1)
                    handleOpenSelectEmailModal();
                  else submitForm();
                }}
                loading={state.loading}
              >
                {t("create")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={state.cardModal.isOpen}
        onClose={handleCloseCardModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addCreditCards")}
          <IconButton onClick={handleCloseCardModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreditCardForm onSubmit={addCreditCard} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.ibanModal.isOpen}
        onClose={handleCloseIbanModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addIban")}
          <IconButton onClick={handleCloseIbanModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12} sm="auto">
              <TextInput
                label={t("newIban")}
                name="newIban"
                value={state.newIban}
                onChange={handleInputChangeIban}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                variant="contained"
                onClick={() => {
                  addIban(state.newIban);
                }}
                color="success"
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.emailModal.isOpen}
        onClose={handleCloseEmailModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addEmail")}
          <IconButton onClick={handleCloseEmailModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <ListCard
              text1={t("email")}
              text2={t("contact")}
              value={state.form.emails}
              name="emails"
              onChange={handleChangeEmails}
              validator1={validateEmail}
              errorText1={t("invalidEmail")}
              showList={false}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.phoneModal.isOpen}
        onClose={handleClosePhoneModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addPhoneNumber")}
          <IconButton onClick={handleClosePhoneModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item>
              <PhoneInput
                inputProps={{
                  label: t("phoneNumber"),
                  name: "number",
                  onChange: (e) => {
                    handleChangePhones(e);
                  },
                }}
                value={state.newPhone.number}
              />
            </Grid>

            <Grid item>
              <TextInput
                label={t("contact")}
                name="contact"
                onChange={(e) => {
                  handleChangePhones(e);
                }}
                value={state.newPhone.contact}
              />
            </Grid>

            <Grid item>
              <Button
                onClick={addPhone}
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.selectEmailModal.isOpen}
        onClose={handleCloseSelectEmailModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("selectEmailForUser")}
          <IconButton onClick={handleCloseSelectEmailModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item>
              <CustomSelect
                value={state.selectEmailModal.email}
                label={t("emails")}
                onChange={handleSetSelectedEmailEmailModal}
                options={state.form.emails?.map((email) => ({
                  value: email.value1,
                  label: email.value1,
                }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseSelectEmailModal} color="error">
            {t("cancel")}
          </CustomButton>
          <CustomButton
            onClick={() => {
              handleCloseSelectEmailModal();
              submitForm();
            }}
            color="primary"
          >
            {t("confirm")}
          </CustomButton>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
