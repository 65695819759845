import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import { useContext } from "react";
import AppContext from "../../../../context/AppContext";
import { useEffect } from "react";
import { useState } from "react";
import { months } from "../../../../utils/date";
import ButtonCSV from "../../../Inputs/ButtonCSV";
import { useTranslation } from "react-i18next";

const color1 = "#FFF";
const color2 = "#e6e6e6";

const TableCell = (props) => {
  return (
    <MuiTableCell
      {...props}
      sx={{
        textOverflow: "ellipsis",
        textAlign: "center",
        padding: 0.5,
        borderColor: "#000",
        ...props.sx,
      }}
    ></MuiTableCell>
  );
};

const CenterHistoricalPanel = () => {
  const { api } = useContext(AppContext);

  const [centers, setCenters] = useState([]);
  const [t] = useTranslation("dashboard");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api
      .get("/dashboard/centers-historical")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setCenters(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  // Format date
  const headerDate = (date) => {
    let d = new Date(date);
    return months[d.getMonth()] + ", " + d.getFullYear();
  };

  const totals = [];
  // Calc totals
  Object.keys(centers[0]?.data || []).forEach((date) => {
    let income = centers.reduce((sum, c) => sum + c.data[date].income, 0);
    let occupiedMeters = centers.reduce(
      (sum, c) => sum + c.data[date].occupiedMeters,
      0
    );
    let totalSqm = centers.reduce((sum, c) => sum + c.data[date].totalSqm, 0);
    let occupancy = totalSqm != 0 ? (occupiedMeters / totalSqm) * 100 : 0;
    let averageRent = occupiedMeters != 0 ? income / occupiedMeters : 0;

    totalSqm = Math.round(totalSqm);
    occupiedMeters = Math.round(occupiedMeters);
    income = Math.round(income);
    occupancy = Math.round(occupancy);
    averageRent = Math.round(averageRent);

    totals.push({ totalSqm, occupiedMeters, income, occupancy, averageRent });
  });

  // Format data to show CSV headers
  const csvHeaders = [
    t("centers"),
    Object.entries(centers[0]?.data || [])
      .map((key) =>
        Object.keys(key[1]).map((prop) => t(prop) + " - " + headerDate(key[0]))
      )
      .flat(1),
  ].flat(1);

  // Format data to adapt to CSV
  const csvData = centers.map((center) =>
    [
      center.name,
      ...Object.values(center.data).map((data) => Object.values(data)),
    ].flat(1)
  );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item>
            <ButtonCSV
              data={csvData}
              filename="centers-tracking.csv"
              headers={csvHeaders}
              disabled={!centers.length}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ maxHeight: "800px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: 1,
                      position: "sticky",
                      left: 0,
                      zIndex: 4,
                      backgroundColor: "white",
                    }}
                  ></TableCell>
                  {Object.keys(centers[0]?.data || [])?.map((date, i) => (
                    <TableCell
                      key={i}
                      colSpan={Object.keys(centers[0]?.data[date]).length || 0}
                      sx={{ borderRight: 1 }}
                    >
                      {headerDate(date)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 5,
                      borderRight: 1,
                      backgroundColor: "white",
                    }}
                  >
                    {t("centers")}
                  </TableCell>
                  {Object.keys(centers[0]?.data || [])?.map((date, i) => (
                    <>
                      {Object.keys(centers[0]?.data[date] || []).map(
                        (prop, j) => (
                          <TableCell
                            sx={{
                              borderRight:
                                j ===
                                Object.keys(centers[0]?.data[date] || [])
                                  .length -
                                  1
                                  ? 1
                                  : 0,
                            }}
                          >
                            {t(prop)}
                          </TableCell>
                        )
                      )}
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {centers.map((center) => (
                  <TableRow key={center.id}>
                    <TableCell
                      sx={{
                        minWidth: "300px",
                        borderRight: 1,
                        position: "sticky",
                        left: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                      }}
                    >
                      {center.name}
                    </TableCell>
                    {Object.keys(center.data).map((date, i) => (
                      <>
                        {Object.keys(center.data[date]).map((prop, j) => (
                          <TableCell
                            sx={{
                              backgroundColor: i % 2 === 1 ? color1 : color2,
                              borderRight:
                                j === Object.keys(center.data[date]).length - 1
                                  ? 1
                                  : 0,
                            }}
                          >
                            {center.data[date][prop]}
                          </TableCell>
                        ))}
                      </>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      minWidth: "300px",
                      borderRight: 1,
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      backgroundColor: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {t("totals")}
                  </TableCell>
                  {totals?.map((date, i) => (
                    <>
                      {Object.keys(date).map((prop, j) => (
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: i % 2 === 1 ? color1 : color2,
                            borderRight:
                              j === Object.keys(date).length - 1 ? 1 : 0,
                          }}
                        >
                          {date[prop]}
                        </TableCell>
                      ))}
                    </>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderRight: 1 }} />
                  {Object.keys(centers[0]?.data || [])?.map((date, i) => (
                    <TableCell key={i} sx={{ borderRight: 1 }}>
                      {headerDate(date)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(totals[0] || {}).map((prop) => (
                  <TableRow>
                    <TableCell sx={{ borderRight: 1 }}>{prop}</TableCell>
                    {totals.map((total) => (
                      <TableCell sx={{ borderRight: 1 }}>
                        {total[prop]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CenterHistoricalPanel;
