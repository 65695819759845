import { Rect as RectTile, Group, Text } from "react-konva";

const Rect = (props) => {
  let {
    tileRef,
    x,
    y,
    width,
    height,
    color,
    onClick,
    onTransformEnd,
    onDragEnd,
    draggable,
    opacity = 0.5,
    text,
  } = props;

  const TEXT_MARGIN = 2;

  return (
    <Group>
      <RectTile
        x={x}
        y={y}
        strokeWidth={2}
        stroke={"#FFFFFF"}
        width={width}
        height={height}
        fill={color || "grey"}
        onClick={onClick}
        ref={tileRef}
        onTransformEnd={onTransformEnd}
        onDragEnd={onDragEnd}
        draggable={draggable}
        opacity={opacity}
      />
      {text && (
        <Text
          x={x + TEXT_MARGIN / 2}
          y={y + TEXT_MARGIN / 2}
          width={width - TEXT_MARGIN * 2}
          height={height - TEXT_MARGIN * 2}
          text={text}
          onClick={onClick}
          align="center"
          verticalAlign="middle"
          wrap="char"
        />
      )}
    </Group>
  );
};

export default Rect;
