import AppContext from "../../../context/AppContext";
import workerURL from "../../../pdf.worker.min.data";
import * as pdfjs from "pdfjs-dist";

import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { generateURL, getParams } from "../../../utils/url";

//Mui
import {
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

//Icons
import DownloadIcon from "@mui/icons-material/Download";
import { UploadFile } from "@mui/icons-material";

//Components
import Button from "../../Inputs/CustomButton";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import ItemsSummary from "../../ItemsSummary";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";

import Dialog from "../../global/Dialog";
import Filters from "../../global/structure/Filters";
import Page from "../../global/structure/Page";
import TabPanel from "../../global/structure/TabPanel";
import { lastMonthDate } from "../../../utils/date";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GROUP_RECEIPTS_TAB = 0;
const RECEIPTS_TAB = 1;

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_FILE_MODAL":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: initialState.receiptGroup.fileModal,
        },
      };
    case "RESET_RECEIPTS_FILTERS":
      return {
        ...state,
        receiptFilters: initialState.receiptFilters,
      };
    case "RESET_RECEIPT_GROUP_FILTERS":
      return {
        ...state,
        receiptGroupFilters: initialState.receiptGroupFilters,
      };
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: action.payload };
    case "SET_FILE_MODAL_COLUMNS":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: {
            ...state.receiptGroup.fileModal,
            columns: action.payload,
          },
        },
      };
    case "SET_FILE_MODAL_FILE":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: { ...state.receiptGroup.fileModal, file: action.payload },
        },
      };
    case "SET_FILE_MODAL_LOADING":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: {
            ...state.receiptGroup.fileModal,
            loading: action.payload,
          },
        },
      };
    case "SET_FILE_MODAL_RETURN_RECEIPTS_LOADING":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: {
            ...state.receiptGroup.fileModal,
            returnReceiptsLoading: action.payload,
          },
        },
      };
    case "SET_FILE_MODAL_MISSING_RECEIPTS":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: {
            ...state.receiptGroup.fileModal,
            missingReceipts: action.payload,
          },
        },
      };
    case "SET_FILE_MODAL_OPEN":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: { ...state.receiptGroup.fileModal, isOpen: true },
        },
      };
    case "SET_FILE_MODAL_RECEIPTS":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: {
            ...state.receiptGroup.fileModal,
            receipts: action.payload,
          },
        },
      };
    case "SET_FILE_MODAL_RETURNED_RECEIPTS":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          fileModal: {
            ...state.receiptGroup.fileModal,
            returnedReceipts: action.payload,
          },
        },
      };
    case "SET_RECEIPTS_DATA":
      return {
        ...state,
        receipts: {
          ...state.receipts,
          data: action.payload,
        },
      };
    case "SET_RECEIPTS_FILTER":
      return {
        ...state,
        receiptFilters: {
          ...state.receiptFilters,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_RECEIPTS_LOADING":
      return {
        ...state,
        receipts: {
          ...state.receipts,
          options: {
            ...state.receipts.options,
            loaded: action.payload,
          },
        },
      };
    case "SET_RECEIPT_GROUP_DATA":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          data: action.payload,
        },
      };
    case "SET_RECEIPT_GROUP_FILTER":
      return {
        ...state,
        receiptGroupFilters: {
          ...state.receiptGroupFilters,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_RECEIPT_GROUP_LOADING":
      return {
        ...state,
        receiptGroup: {
          ...state.receiptGroup,
          options: {
            ...state.receiptGroup.options,
            loaded: action.payload,
          },
        },
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  currentTab: GROUP_RECEIPTS_TAB,
  receiptGroup: {
    data: [],
    fileModal: {
      columns: [],
      file: "",
      isOpen: false,
      loading: false,
      receipts: [],
      returnedReceipts: [],
      missingReceipts: false,
      returnReceiptsLoading: false,
    },
    options: {
      loaded: true,
    },
  },
  receiptGroupFilters: {
    autoSearch: false,
    amountMax: "",
    amountMin: "",
    dateFrom: "",
    dateUntil: "",
  },
  receipts: {
    data: [],
    options: {
      loaded: true,
    },
  },
  receiptFilters: {
    autoSearch: false,
    amountMax: "",
    amountMin: "",
    dateFrom: "",
    dateUntil: "",
    filterDateBy: "returnedAt",
    groupId: "",
    receiptReturnedGroupId: "",
    text: "",
  },
};

export default function ReturnedReceiptsPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("receipts");
  const [tErrors] = useTranslation("errors");

  let filters;
  let target;
  let params = getParams(new URLSearchParams(useLocation().search), [
    { name: "tab", type: "number" },
  ]);

  if (Number(params.tab) === GROUP_RECEIPTS_TAB) {
    filters = [
      { name: "autoSearch", type: "boolean" },
      { name: "amountMax", type: "number" },
      { name: "amountMin", type: "number" },
      { name: "dateFrom", type: "string" },
      { name: "dateUntil", type: "string" },
    ];
    target = "receiptGroupFilters";
  } else {
    filters = [
      { name: "autoSearch", type: "boolean" },
      { name: "amountMax", type: "number" },
      { name: "amountMin", type: "number" },
      { name: "dateFrom", type: "string" },
      { name: "dateUntil", type: "string" },
      { name: "filterDateBy", type: "string" },
      { name: "groupId", type: "number" },
      { name: "receiptReturnedGroupId", type: "number" },
      { name: "text", type: "string" },
    ];
    target = "receiptFilters";
  }

  const initState = (state) => ({
    ...state,
    [target]: {
      ...state.receiptGroupFilters,
      ...getParams(query, filters),
    },
    currentTab: parseInt(query.get("tab") ?? state.currentTab),
    autoSearch: query.get("autoSearch") ?? state.autoSearch,
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const RECEIPT_COLUMNS = [
    {
      label: t("remittance"),
      key: "Remittance.name",
      sortType: "string",
      renderFunction: (value, item) =>
        user.hasAction("VIEW_REMITTANCES") && item.Remittance ? (
          <ButtonLink
            to={"/app/remittance/" + item.Remittance.id}
            size="small"
            sx={{ padding: 0 }}
          >
            {value}
          </ButtonLink>
        ) : (
          value
        ),
    },
    { label: t("customerName"), key: "customerName", sortType: "string" },
    {
      label: t("accountingAccount"),
      key: "Customer.accountingAccount",
      sortType: "string",
    },
    { label: t("concept"), key: "concept", sortType: "string" },
    {
      label: t("amount"),
      key: "amount",
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { label: t("dueDate"), key: "dueDate", sortType: "string" },
    { label: t("returnedAtDate"), key: "returnedAt", sortType: "string" },
    {
      label: t("groupId"),
      key: "receiptReturnedGroupId",
      sortType: "number",
    },
  ];

  const RECEIPT_CSV_HEADERS = [
    { label: t("remittance"), key: "Remittance.name" },
    { label: t("customerName"), key: "customerName" },
    { label: t("customerAccount"), key: "Customer.accountingAccount" },
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    { label: t("dueDate"), key: "dueDate" },
    { label: t("returnedAtDate"), key: "returnedAt" },
    { label: t("groupId"), key: "receiptReturnedGroupId" },
  ];

  const RECEIPT_GROUP_COLUMNS = [
    {
      key: "id",
      label: t("id"),
      sortType: "number",
      renderFunction: (value) => (
        <Button onClick={() => handleReceiptGroupIdClick(value)} variant="text">
          {value}
        </Button>
      ),
    },
    { key: "date", label: t("date"), sortType: "string" },
    {
      key: "numberOfReceipts",
      label: t("numberOfReceipts"),
      sortType: "number",
    },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "isReconciled",
      label: t("reconciled"),
      sortType: "number",
      renderFunction: (value) =>
        value === true ? (
          <Chip color="success" label={t("reconciled")} size="small" />
        ) : (
          <Chip color="error" label={t("nonReconciled")} size="small" />
        ),
    },
    {
      key: "filePath",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <ButtonGroup onClick={(e) => e.stopPropagation()}>
          <Tooltip title={t("viewPdf")} placement="top">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                showFile(item.id);
              }}
              disabled={value ? false : true}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <ButtonCSV
            data={item?.Receipts}
            headers={RECEIPT_CSV_HEADERS}
            filename={t("returnedReceipts") + "_" + item.id + ".csv"}
            icon
            tooltipTitle={t("downloadReceiptsCsv")}
            disabled={item?.Receipts?.length > 0 ? false : true}
          />
        </ButtonGroup>
      ),
    },
  ];

  const RECEIPT_GROUP_CSV_HEADERS = [
    { label: t("id"), key: "id" },
    { label: t("date"), key: "date" },
    { label: t("numberOfReceipts"), key: "numberOfReceipts" },
    { label: t("amount"), key: "amount" },
  ];

  const RETURNED_RECEIPT_COLUMNS = [
    { field: "id", headerName: t("receiptId"), flex: 1 },
    {
      field: "Remittance",
      headerName: t("remittance"),
      flex: 1,
      valueGetter: ({ value }) => value?.name,
    },
    { field: "customerName", headerName: t("customerName"), flex: 1 },
    { field: "concept", headerName: t("concept"), flex: 1 },
    { field: "dueDate", headerName: t("dueDate"), flex: 1 },
    {
      field: "amount",
      headerName: t("amount"),
      valueFormatter: ({ value }) => localeFormat(value) + "€",
      flex: 1,
    },
    {
      field: "fee",
      headerName: t("fee"),
      valueFormatter: ({ value }) => localeFormat(value) + "€",
      flex: 1,
    },
  ];

  const FILE_MODAL_ITEMS_SUMMARY = [
    {
      translatedText: t("returnedReceipts"),
      value: state.receiptGroup.fileModal.receipts.length,
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.receiptGroup.fileModal.receipts.reduce(
            (total, item) => total + item.amount,
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalFee"),
      value:
        localeFormat(
          state.receiptGroup.fileModal.receipts.reduce(
            (total, item) => total + item.fee,
            0
          )
        ) + "€",
    },
  ];

  useEffect(() => {
    updateQuery();
  }, [state.currentTab]);

  //Auto search
  useEffect(() => {
    if (state.receiptGroupFilters.autoSearch) getReceiptGroups();
  }, [state.receiptGroupFilters.autoSearch]);

  useEffect(() => {
    if (state.receiptFilters.autoSearch) getReturnedReceipts();
  }, [state.receiptFilters.autoSearch]);

  const updateQuery = () => {
    let filters;
    state.currentTab === GROUP_RECEIPTS_TAB
      ? (filters = state.receiptGroupFilters)
      : (filters = state.receiptFilters);
    const url = generateURL("/app/returned-receipts/", {
      tab: state.currentTab,
      ...filters,
    });
    history.push(url);
  };

  //Backend calls

  const getReceiptGroups = () => {
    updateQuery();
    dispatch({
      type: "SET_RECEIPT_GROUP_FILTER",
      payload: { name: "autoSearch", value: true },
    });

    dispatch({ type: "SET_RECEIPT_GROUP_LOADING", payload: false });

    let params = {
      include: ["Customer", "Receipt", "BankingTransaction"],
    };

    state.receiptGroupFilters.dateFrom !== "" &&
      (params.dateFrom = state.receiptGroupFilters.dateFrom);
    state.receiptGroupFilters.dateUntil !== "" &&
      (params.dateUntil = state.receiptGroupFilters.dateUntil);
    state.receiptGroupFilters.amountMin !== "" &&
      (params.amountMin = state.receiptGroupFilters.amountMin);
    state.receiptGroupFilters.amountMax !== "" &&
      (params.amountMax = state.receiptGroupFilters.amountMax);

    api
      .get("/receipts/receipt-returned-groups", { params })
      .then((response) => {
        dispatch({ type: "SET_RECEIPT_GROUP_LOADING", payload: true });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noReturnedReceiptGroups"), {
              variant: "warning",
            });
          }
          dispatch({
            type: "SET_RECEIPT_GROUP_DATA",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getReturnedReceipts = () => {
    updateQuery();
    dispatch({
      type: "SET_RECEIPTS_FILTER",
      payload: { name: "autoSearch", value: true },
    });

    dispatch({ type: "SET_RECEIPTS_LOADING", payload: false });

    let params = {
      include: ["Remittance", "Invoice", "Customer"],
    };

    state.receiptFilters.text !== "" &&
      (params.text = state.receiptFilters.text);
    state.receiptFilters.dateFrom !== "" &&
      (state.receiptFilters.filterDateBy === "returnedAt"
        ? (params.dateReturnedFrom = state.receiptFilters.dateFrom)
        : (params.dateFrom = state.receiptFilters.dateFrom));
    state.receiptFilters.dateUntil !== "" &&
      (state.receiptFilters.filterDateBy === "returnedAt"
        ? (params.dateReturnedUntil = state.receiptFilters.dateUntil)
        : (params.dateUntil = state.receiptFilters.dateUntil));
    state.receiptFilters.amountMin !== "" &&
      (params.amountMin = state.receiptFilters.amountMin);
    state.receiptFilters.amountMax !== "" &&
      (params.amountMax = state.receiptFilters.amountMax);
    state.receiptFilters.groupId !== "" &&
      (params.groupId = state.receiptFilters.groupId);

    params.state = 3; //Only returned receipts

    api
      .get("/receipts", { params })
      .then((response) => {
        dispatch({ type: "SET_RECEIPTS_LOADING", payload: true });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noReturnedReceipts"), { variant: "warning" });
          }
          dispatch({ type: "SET_RECEIPTS_DATA", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getReturnedReceiptsData = (importedReturnedReceipts) => {
    if (importedReturnedReceipts) {
      let params = {
        include: ["Remittance"],
      };
      params.ids = importedReturnedReceipts.map((receipt) =>
        Number(receipt.id)
      );
      if (params.ids.length !== 0) {
        api
          .get("/receipts", { params })
          .then((response) => {
            dispatch({ type: "SET_FILE_MODAL_LOADING", payload: false });
            if (response.data.error) {
              enqueueSnackbar(tErrors(response.data.error), {
                variant: "error",
              });
            } else {
              const receiptsWithFee = response.data.map((receipt) => {
                const fee = importedReturnedReceipts.find((returnedReceipt) => {
                  return returnedReceipt.id === receipt.id;
                })?.fee;
                return { ...receipt, fee };
              });
              const nonFoundReceipts = importedReturnedReceipts
                .filter((returnedReceipt) => {
                  return !receiptsWithFee.find(
                    (receipt) => receipt.id === returnedReceipt.id
                  );
                })
                .map((returnedReceipt) => {
                  return {
                    id: returnedReceipt.id,
                    amount: returnedReceipt.amount,
                    fee: returnedReceipt.fee,
                  };
                });
              if (nonFoundReceipts.length !== 0) {
                enqueueSnackbar(t("someReceiptsNotFound"), {
                  variant: "warning",
                });
                dispatch({
                  type: "SET_FILE_MODAL_MISSING_RECEIPTS",
                  payload: true,
                });
              }
              dispatch({
                type: "SET_FILE_MODAL_RECEIPTS",
                payload: receiptsWithFee.concat(nonFoundReceipts),
              });
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: "error" });
          });
      } else {
        dispatch({ type: "SET_FILE_MODAL_LOADING", payload: false });
      }
    }
  };

  const setReceiptsAsReturned = () => {
    const receiptIds = state.receiptGroup.fileModal.returnedReceipts.map(
      (receipt) => receipt.id
    );
    const returnedAt =
      state.receiptGroup.fileModal.returnedReceipts[0].returnedAt;
    const file = state.receiptGroup.fileModal.file;
    const receiptReasonCodes =
      state.receiptGroup.fileModal.returnedReceipts.map(
        (receipt) => receipt.returnedReasonCode
      );

    let formData = new FormData();

    formData.append("fileUpload", file);
    receiptIds.forEach((receiptId) => {
      formData.append("receiptIds[]", receiptId);
    });
    returnedAt.forEach((item) => {
      formData.append("returnedAt[]", item);
    });
    receiptReasonCodes.forEach((item) => {
      formData.append("receiptReasonCode[]", item);
    });

    if (receiptIds.length !== 0) {
      dispatch({
        type: "SET_FILE_MODAL_RETURN_RECEIPTS_LOADING",
        payload: true,
      });
      api
        .post("/receipts/set-as-returned", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          timeout: 120000,
        })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("receiptsSetAsReturnedSuccess"), {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({
            type: "SET_FILE_MODAL_RETURN_RECEIPTS_LOADING",
            payload: false,
          });
          dispatch({ type: "RESET_FILE_MODAL" });
        });
    }
  };

  const showFile = (returnedReceiptGroupId) => {
    api
      .get(
        "/receipts/" + returnedReceiptGroupId + "/get-receipt-returned-file",
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  // Handlers and functions
  const handleReceiptGroupIdClick = (groupId) => {
    handleReceiptsFilterChange({
      target: { name: "groupId", value: groupId },
    });
    getReturnedReceipts();
    handleTabChange(null, RECEIPTS_TAB);
  };

  const closeFileModal = () => {
    dispatch({ type: "RESET_FILE_MODAL" });
  };

  const openFileModal = () => {
    dispatch({
      type: "SET_FILE_MODAL_COLUMNS",
      payload: RETURNED_RECEIPT_COLUMNS,
    });
    dispatch({ type: "SET_FILE_MODAL_OPEN" });
  };

  const handleFileChange = (e) => {
    dispatch({ type: "SET_FILE_MODAL_FILE", payload: e.target.files[0] });
  };

  const handleReceiptGroupFilterChange = (e) => {
    dispatch({
      type: "SET_RECEIPT_GROUP_FILTER",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handleReceiptsFilterChange = (e) => {
    dispatch({
      type: "SET_RECEIPTS_FILTER",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSetReceiptsAsReturned = () => {
    setReceiptsAsReturned();
  };

  const handleTabChange = (event, newValue) => {
    dispatch({ type: "SET_CURRENT_TAB", payload: newValue });
  };

  const processFile = async () => {
    try {
      const file = state.receiptGroup.fileModal.file;
      if (file) {
        dispatch({ type: "SET_FILE_MODAL_LOADING", payload: true });
        state.receiptGroup.fileModal.missingReceipts === true &&
          dispatch({ type: "SET_FILE_MODAL_MISSING_RECEIPTS", payload: false });
        state.receiptGroup.fileModal.receipts.length !== 0 &&
          dispatch({ type: "SET_FILE_MODAL_RECEIPTS", payload: [] });

        pdfjs.GlobalWorkerOptions.workerSrc = workerURL;
        const pdfArrayBuffer = await readFileAsync(file);
        const pdf = await pdfjs.getDocument(pdfArrayBuffer).promise;
        const numPages = pdf.numPages;

        const tablePages = [];

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          if (
            textContent.items.some((item) => item.str.includes("Adeudo")) &&
            textContent.items.some((item) => item.str.includes("Pág."))
          ) {
            tablePages.push(textContent.items);
          }
        }

        let invalidFileFlag = false;
        const returnedReceipts = [];
        let returnedAt = null;
        tablePages.forEach((page) => {
          page.forEach((item, i) => {
            if (item.str.includes("Pág.") && !returnedAt) {
              returnedAt = page[i - 2]?.str.split(".");
            } else if (item.str.includes("Recibo")) {
              const receipt = {
                id: Number(item?.str.match(/\d+/g).join("")),
                amount: parseFloat(page[i + 4]?.str.replace(",", ".")),
                fee: parseFloat(page[i + 8]?.str.replace(",", ".")) + 1.1,
                returnedAt: returnedAt,
                returnedReasonCode: page[i + 10]?.str,
              };
              if (receipt.id && receipt.fee) {
                returnedReceipts.push(receipt);
              } else {
                invalidFileFlag = true;
              }
            }
          });
        });
        if (invalidFileFlag || tablePages.length === 0) {
          enqueueSnackbar(t("invalidFile"), { variant: "error" });
          dispatch({ type: "SET_FILE_MODAL_LOADING", payload: false });
        } else {
          dispatch({
            type: "SET_FILE_MODAL_RETURNED_RECEIPTS",
            payload: returnedReceipts,
          });
          getReturnedReceiptsData(returnedReceipts);
        }
      } else {
        enqueueSnackbar(t("noFileSelected"), { variant: "error" });
      }
    } catch (error) {
      dispatch({ type: "SET_FILE_MODAL_LOADING", payload: false });
      enqueueSnackbar(error.toString(), { variant: "error" });
    }
  };

  const readFileAsync = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.readAsArrayBuffer(file, "UTF-8");
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const resetReceiptGroupFilters = () => {
    dispatch({ type: "RESET_RECEIPT_GROUP_FILTERS" });
    dispatch({ type: "SET_RECEIPT_GROUP_DATA", payload: [] });
  };

  const resetReceiptsFilters = () => {
    dispatch({ type: "RESET_RECEIPTS_FILTERS" });
    dispatch({ type: "SET_RECEIPTS_DATA", payload: [] });
  };

  return (
    <Page
      browserTitle={t("returnOfReceipts")}
      title={t("returnOfReceipts")}
      paper={true}
    >
      <TabPanel
        selectedTab={state.currentTab}
        onChange={handleTabChange}
        tabs={[
          {
            label: t("receiptGroups"),
            children: (
              <Grid container item xs={12} spacing={1}>
                <Grid item>
                  <Filters
                    filters={[
                      <CustomDate
                        label={t("dateFrom")}
                        InputLabelProps={{ shrink: true }}
                        value={state.receiptGroupFilters.dateFrom}
                        onChange={handleReceiptGroupFilterChange}
                        name="dateFrom"
                      />,
                      <CustomDate
                        label={t("dateUntil")}
                        InputLabelProps={{ shrink: true }}
                        value={state.receiptGroupFilters.dateUntil}
                        onChange={handleReceiptGroupFilterChange}
                        name="dateUntil"
                      />,
                      <TextInput
                        label={t("amountMin")}
                        value={state.receiptGroupFilters.amountMin}
                        onChange={handleReceiptGroupFilterChange}
                        name="amountMin"
                        type="number"
                      />,
                      <TextInput
                        label={t("amountMax")}
                        value={state.receiptGroupFilters.amountMax}
                        onChange={handleReceiptGroupFilterChange}
                        name="amountMax"
                        type="number"
                      />,
                    ]}
                  />
                </Grid>

                <Grid item sm="auto">
                  <ButtonGroup variant="contained" color="primary">
                    <Button onClick={resetReceiptGroupFilters}>
                      {t("reset")}
                    </Button>
                    <ButtonCSV
                      filename={t("receiptGroups")}
                      data={state.receiptGroup.data}
                      headers={RECEIPT_GROUP_CSV_HEADERS}
                    />
                    <SearchButton
                      onClick={getReceiptGroups}
                      loading={!state.receiptGroup.options.loaded}
                    />
                  </ButtonGroup>
                </Grid>

                {user.hasAction("EDIT_RECEIPTS") && (
                  <Grid item sm="auto">
                    <Button
                      onClick={openFileModal}
                      color="success"
                      startIcon={<UploadFile />}
                    >
                      {t("importPdf")}
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <CustomTable
                    columns={RECEIPT_GROUP_COLUMNS}
                    data={state.receiptGroup.data}
                    options={state.receiptGroup.options}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: t("receipts"),
            children: (
              <Grid container item xs={12} spacing={1}>
                <Grid item>
                  <Filters
                    filters={[
                      <TextInput
                        label={t("search")}
                        value={state.receiptFilters.text}
                        name="text"
                        onChange={handleReceiptsFilterChange}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            getReturnedReceipts();
                          }
                        }}
                      />,
                      <CustomDate
                        label={t("dateFrom")}
                        InputLabelProps={{ shrink: true }}
                        value={state.receiptFilters.dateFrom}
                        onChange={handleReceiptsFilterChange}
                        name="dateFrom"
                      />,
                      <CustomDate
                        label={t("dateUntil")}
                        InputLabelProps={{ shrink: true }}
                        value={state.receiptFilters.dateUntil}
                        onChange={handleReceiptsFilterChange}
                        name="dateUntil"
                      />,
                      <CustomSelect
                        label={t("filterBy")}
                        value={state.receiptFilters.filterDateBy}
                        onChange={handleReceiptsFilterChange}
                        name="filterDateBy"
                        options={[
                          {
                            value: "returnedAt",
                            label: t("returnedAtDate"),
                          },
                          { value: "dueDate", label: t("dueDate") },
                        ]}
                      />,
                      <TextInput
                        label={t("amountMin")}
                        value={state.receiptFilters.amountMin}
                        onChange={handleReceiptsFilterChange}
                        name="amountMin"
                        type="number"
                      />,
                      <TextInput
                        label={t("amountMax")}
                        value={state.receiptFilters.amountMax}
                        onChange={handleReceiptsFilterChange}
                        name="amountMax"
                        type="number"
                      />,
                      <TextInput
                        label={t("groupId")}
                        value={state.receiptFilters.groupId}
                        onChange={handleReceiptsFilterChange}
                        name="groupId"
                        type="number"
                      />,
                    ]}
                  />
                </Grid>

                <Grid item sm="auto">
                  <ButtonGroup variant="contained" color="primary">
                    <Button onClick={resetReceiptsFilters}>{t("reset")}</Button>
                    <ButtonCSV
                      filename={t("receipts")}
                      data={state.receipts.data}
                      headers={RECEIPT_CSV_HEADERS}
                    />
                    <SearchButton
                      onClick={getReturnedReceipts}
                      loading={!state.receipts.options.loaded}
                    />
                  </ButtonGroup>
                </Grid>

                <Grid item xs={12}>
                  <CustomTable
                    columns={RECEIPT_COLUMNS}
                    data={state.receipts.data}
                    options={state.receipts.options}
                  />
                </Grid>
              </Grid>
            ),
          },
        ]}
      />

      <Grid item container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button onClick={() => history.goBack()}>{t("back")}</Button>
        </Grid>
      </Grid>

      {/* File Modal */}
      <Dialog
        onClose={closeFileModal}
        open={state.receiptGroup.fileModal.isOpen}
        title={t("importReturnedReceipts")}
        maxWidth="lg"
        children={
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Button component="label">
                {t("selectFile")}
                <input
                  type="file"
                  name="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".pdf"
                />
              </Button>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                fontWeight="fontWeightMedium"
                display="inline"
              >
                {t("uploadedFile")}
                {": "}
              </Typography>
              <Typography variant="body2" display="inline">
                {state.receiptGroup.fileModal.file
                  ? state.receiptGroup.fileModal.file.name
                  : t("noFile")}
              </Typography>
            </Grid>
            <Grid item>
              <SearchButton
                disabled={!state.receiptGroup.fileModal.file}
                loading={state.receiptGroup.fileModal.loading}
                loadingText={t("processingFile")}
                onClick={processFile}
                text={t("processFile")}
              />
            </Grid>
            <Grid item xs={12}>
              <ItemsSummary gridItems={FILE_MODAL_ITEMS_SUMMARY} />
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  columns={state.receiptGroup.fileModal.columns}
                  rows={state.receiptGroup.fileModal.receipts}
                  loading={state.receiptGroup.fileModal.loading}
                />
              </div>
            </Grid>
          </Grid>
        }
        actions={
          <Button
            color="primary"
            onClick={handleSetReceiptsAsReturned}
            disabled={
              state.receiptGroup.fileModal.receipts?.length === 0 ||
              state.receiptGroup.fileModal.missingReceipts === true
            }
            loading={state.receiptGroup.fileModal.returnReceiptsLoading}
            loadingText={t("processingAndSendingEmails")}
          >
            {t("setAsReturned")}
          </Button>
        }
      />
    </Page>
  );
}
