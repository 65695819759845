import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CentersContext } from "../Centers";
import AppContext from "../../../../../context/AppContext";

import {
  NutCentersInvoicingScatterChart,
  NutObjectivesCompareBarChart,
} from "../../Charts/CentersCharts";

function ObjectivesTab() {
  const [t] = useTranslation("dashboard");
  const { api } = useContext(AppContext);
  const { filteredCenters, centers } = useContext(CentersContext);

  useEffect(() => {}, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NutCentersInvoicingScatterChart centers={filteredCenters} />
      </Grid>
      <Grid item xs={12}>
        <NutObjectivesCompareBarChart centers={filteredCenters} />
      </Grid>
    </Grid>
  );
}

export default ObjectivesTab;
